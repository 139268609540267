import ReactDOMServer from "react-dom/server";
import React, {ReactElement} from "react";
import {LAUNCH_GUIDE} from "../../../../../common/utils/consts";

export const Tooltip = (width: number, content: string | ReactElement) => {
    return <i className={'ico-Information-outlined'}
              style={{position: "relative", zIndex: 1}}
              data-html={true}
              data-tip={ReactDOMServer.renderToStaticMarkup(
                  <div className={"common-tooltip"} style={{maxWidth: width}}>
                      {content}
                  </div>)}/>
}

export const OfferTypeTooltip =  (width: number, content: string | ReactElement) => {
    return <i className={"ico-Information-outlined"}
              style={{ position: "relative", zIndex: 1}}
              data-html={true}
              data-place={"bottom"}
              data-tip={ReactDOMServer.renderToStaticMarkup(
                  <div className={"common-tooltip"} style={{maxWidth: width}}>
                      {content}
                  </div>)}/>
}

export const ActiveIcon     = <i className={"material-icons-outlined active-icon"}> arrow_forward</i>
export const LockIcon   = <i className={"material-icons lock-icon"}
                             data-html={true}
                             data-place={"right"}
                             data-tip={ReactDOMServer.renderToString(<>
                                 Fill out all prior required fields<br/> to access locked sections.
                             </>)}>lock</i>
export const CompletedIcon  = <i className={"material-icons"}
                                 style={{color: "#3FDE7F"}}
                                 data-html={true}
                                 data-place={"right"}
                                 data-tip={ReactDOMServer.renderToString(<>
                                     All required items required <br/> for submission are complete.
                                 </>)}>
                                    check_circle
                                </i>
export const IncompleteIcon = <i className="material-icons-outlined" style={{color: "#8799A2"}}>circle</i>
export const AdjustedIcon = <i className="material-icons"
                               style={{color: "#8799A2"}}
                               data-html={true}
                               data-place={"right"}
                               data-tip={ReactDOMServer.renderToString(<>
                                   Adjustments to default <br/> settings have been made.
                               </>)}>
                                    circle
                                </i>


export const GUIDE_LINK = <div className='need-help'>Need help? Check out our
                            <a href={LAUNCH_GUIDE} target={'_blank'}
                               rel={'noreferrer'}>&nbsp;launch guide&nbsp;</a>
                                to help get started!
                            </div>


export const destinationParamsList = [
    '{clickid}',
    '{pid}',
    '{linkid}',
    '{subid1}',
    '{subid2}',
    '{subid3}',
    '{subid4}',
    '{subid5}',
    '{subid6}',
    '{payout_main}',
    '{payout_event}',
    '{creative_id}',
    '{source_id}',
    '{country_code}',
    '{user_agent}',
    '{os}',
    '{device_os_version}',
    '{ip}',
    '{device_type}',
    '{device_model}',
    '{device_brand}',
    '{isp}',
    '{creative_name}',
    '{google_aid}',
    '{ios_ifa}',
    '{media_type:appsflyer}',
    '{media_type:kochava}',
    '{media_type:singular}',
    '{media_type:taptica}',
    '{encrypted_cost}'
]

export const destinationParamsListTooltips : {[key: string] : string} = {
    '{linkid}': 'This token is recommended. It populates the partner linkids running your offer. You can optimize and right price based on these partner linkids.',
    '{clickid}': 'This token is required for postback tracking. This token populates the unique clickid to you, which you will then post back that same information to us through the &clickid=parameter of the postback pixel.',
    '{payout_main}': 'This is an optional token that will populate the Main ConversionCost associated to the partner linkid that is running your offer.',
    '{google_aid}': 'This is an optional token that will populate the GoogleAdvertising Identifier from the partners running your offer,should they choose to pass that information.',
    '{ios_ifa}': 'This is an optional token that will populate the iOS AdvertisingIdentifier from the partners running your offer, should they choose to pass that information.',
    '{payout_event}': 'This is an optional token that will populate the Event Cost associated to the partner linkid that is running your offer.',
    '{source_id}': 'This is an optional token that will populate the Source ID from the partners running your offer, should they choose to pass that information.',
    '{subid1}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{subid2}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{subid3}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{subid4}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{subid5}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{subid6}': 'This is an optional token that will populate values from the partners running your offer, should they choose to pass that information.',
    '{creative_id}': 'This is an optional token that will populate creative information from partner, should they choose to pass that information.',
    '{encrypted_cost}': 'This token is recommended. It populates publishers ids running your offer'
}