import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../../common/hooks";
import OffersService, {IFetchAllTagsResponse, IFetchCampaignsResponse, ITag, IAddTagsParams} from "../../Offers.service";

import {Wrapper, TagsDropdownContainer, TagSearchWrapper, TagSearchLabel} from "./EditTagsModal.style";
import {InputSingle} from "../../../../../../../common/components/input-single-line/InputSingleLine.style";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../../../common/styled-components";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import {tokenStore} from "../../../../../../../store/auth.slice";

import SelectionPills from "../../../../../../../common/components/selection-pills/SelectionPills";

interface Props {
    tags: IFetchAllTagsResponse[]
    selectedOffer: IFetchCampaignsResponse
    closeModal: () => void,
    fetchData: (direction?: string | null, sort_by?: string | null, selectNodes? : boolean  ) => void,
    setAlert: (params: {
                   opened: boolean,
                   message: string,
                   type: 'success' | 'error'
               }
    ) => void
    deselectRows: ()=> void,
}

const EditTagsModal: React.FC<Props> = ({tags,
                                            selectedOffer,
                                            closeModal,
                                            fetchData,
                                            setAlert,
                                            deselectRows}) => {
    const token = useAppSelector(tokenStore)
    const [tagsSearchValue, setTagsSearchValue] = useState('')
    const [tagsToAdd, setTagsToAdd] = useState<IFetchAllTagsResponse[]>(selectedOffer.tags)
    const [isTagDropdownOpened, setIsTagDropdownOpened] = useState(false)
    const [filteredTags, setFilteredTags] = useState<IFetchAllTagsResponse[]>([])
    const [loading, setLoading] = useState(false)

    const tagRef = useRef(null)

    const checkIfTagIsSelected = (selectedTag: ITag | IFetchAllTagsResponse) => {
        return tagsToAdd.some(tag => tag.id === selectedTag.id)
    }

    const handleTagSelect = (tag: ITag | IFetchAllTagsResponse) => {
        if(checkIfTagIsSelected(tag)){
            const newTags = tagsToAdd.filter(selectedTag => selectedTag.id !== tag.id)
            setTagsToAdd(newTags)
        } else {
            setTagsToAdd([...tagsToAdd, tag])
        }
    }

    useEffect(() => {
        if(tags){
            setFilteredTags(
                tags.filter(tag => tag.name.toLowerCase().includes(tagsSearchValue.toLowerCase()))
            )
        }
    }, [tagsSearchValue, tags])

    useOnClickOutside(tagRef, () => setIsTagDropdownOpened(false))

    const handleSubmit = async () => {
        deselectRows()
        const addTagsParams: IAddTagsParams = {
            property_ids: [selectedOffer.id],
            type: 'offer',
            tag_ids: tagsToAdd.map(tag => tag.id)
        }
        try {
            setLoading(true)
            await OffersService.deleteTags(token, {tps: selectedOffer.tags})
            await OffersService.addTags(token, addTagsParams)
            setLoading(false)
            setAlert({
                opened: true,
                message: 'Tags successfully edited!',
                type: 'success'
            })
        } catch (e) {
            setLoading(false)
            setAlert({
                opened: true,
                message: 'Something went wrong',
                type: 'error'
            })
        }
        fetchData(null, null, false)
        closeModal()
    }

    return <Wrapper>
        <TagSearchLabel>Using the field below, type out a tag you would like to use.</TagSearchLabel>
        <TagSearchWrapper ref={tagRef}>
            <InputSingle
                className={'search-tags-input'}
                onChange={(e) => setTagsSearchValue(e.target.value)}
                placeholder={'Type a tag to add'}
                value={tagsSearchValue}
                onFocus={() => setIsTagDropdownOpened(true)}
            />
            <TagsDropdownContainer opened={isTagDropdownOpened} >
                {filteredTags && filteredTags.map(tag => <div key={`${tag.id}-${tag.name}`} className={'tag'}><Checkbox onChange={() => handleTagSelect(tag)} checked={tagsToAdd.some(selectedTag => tag.id === selectedTag.id)} label={tag.name} /></div>)}
            </TagsDropdownContainer>
        </TagSearchWrapper>
        {tagsToAdd.map(tag => <SelectionPills key={tag.id} text={tag.name} onRemove={() => handleTagSelect(tag)} />)}
        <ModalFooter>
            <ButtonStyled className='btn-cancel' onClick={closeModal}>Cancel</ButtonStyled>
            <ButtonStyled
                className='btn-orange-filled'
                disabled={tagsToAdd.length < 1 || loading}
                onClick={handleSubmit}
            >
                <span>{loading && <BtnLoadingSpinner/>}Save Tags</span>
            </ButtonStyled>
        </ModalFooter>
    </Wrapper>
}

export default EditTagsModal