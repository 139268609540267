import {PageID} from "../../../../store/filters.slice";
import {IDeviceFilter, FilterID} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {DEVICE_TYPES} from "../../../utils/consts";

export const useDeviceTypeCacheFilter = (pageId: IPagePresetsID) => {
    const [devices, setDevices] = useState<IDeviceFilter[]>(DEVICE_TYPES)
    const [devicesSearchValue, setDevicesSearchValue] = useState('')

    const dispatch = useAppDispatch()

    const id : FilterID = 'device-type'

    useEffect(() => {
        let filteredTypes = DEVICE_TYPES.filter(device => (
                device.value.toLowerCase()).includes(devicesSearchValue.toLowerCase())
            ||
            device.id.toString().includes(devicesSearchValue.toLowerCase())
        )

        setDevices(filteredTypes)

    }, [devicesSearchValue])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IDeviceFilter) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }


    return {
        id: id,
        options: devices,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: 0,
        search: {
            value: devicesSearchValue,
            setSearch: setDevicesSearchValue
        },
        loading : false
    }
}