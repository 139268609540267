export const allPaginationColumnsList: {
    name: string,
    id: number,
    checked: boolean,
    column_key?: string
}[] = [
    {
        name: "Fee/Group Name & Tier",
        id: 1,
        checked: true,
        column_key: "fee_group_name_tier",
    },
    {
        name: "Source",
        id: 2,
        checked: true,
        column_key: "source"
    },
    {
        name: "Fee/Group Type",
        id: 3,
        checked: true,
        column_key: "fee_group_type"
    },
    {
        name: "Amount",
        id: 4,
        checked: true,
        column_key: "amount"
    },
    {
        name: "Start Date",
        id: 5,
        checked: true,
        column_key: "start_date"
    },
    {
        name: "End Date",
        id: 6,
        checked: true,
        column_key: "end_date"
    },
    {
        name: "Recurring",
        id: 7,
        checked: true,
        column_key: "recurring"
    },
    {
        name: "Monthly Min. Charges",
        id: 8,
        checked: true,
        column_key: "month_min_charges"
    },
    {
        name: "Calculated By",
        id: 9,
        checked: true,
        column_key: "calculated_by"
    },
    {
        name: "Partners Incl./Excl.?",
        id: 10,
        checked: true,
        column_key: "par_incl_excl"
    },
    {
        name: "Ind. Fee Visibility",
        id: 11,
        checked: true,
        column_key: "ind_fee_visibility"
    },
    {
        name: "pinned",
        id: 1232,
        checked: true,
        column_key: "pinned"
    },
]