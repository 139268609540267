import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

const allOptions: any[] = [
    {
        value: "Admin Adjustment",
        id: "ADMIN_ADJUSTMENT"
    },
    {
        value: "Bank Transfer",
        id: "BANK_TRANSFER"
    },
    {
        value: "Billed Click",
        id: "BILLED_CLICK"
    },
    {
        value: "Billed Traffic",
        id: "BILLED_TRAFFIC"
    },
    {
        value: "Check",
        id: "CHECK"
    },
    {
        value: "Credit Card",
        id: "CREDIT_CARD"
    },
    {
        value: "PayPal",
        id: "PAYPAL"
    }
];

export const useCurrencyTypeFilter = (pageId: PageID): IFilterHookReturn<any> => {
    const dispatch = useAppDispatch()

    const id = 'currency-type'

    const currencyTypeFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (status: any) => {
        if(currencyTypeFilter.selected.some((stat)  => stat.id === status.id)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: currencyTypeFilter.exclude,
                    selected: currencyTypeFilter.selected.filter(stat => stat.id !== status.id)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: currencyTypeFilter.exclude,
                    selected: [...currencyTypeFilter.selected, status]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: currencyTypeFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allOptions,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: currencyTypeFilter.selected,
        exclude: currencyTypeFilter.exclude,
        setSelectedData: setSelectedData,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}