import React, {useState, useRef, useEffect} from 'react'

import SelectFilterDropdown from '../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import IncludeExcludeFilter from "../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter";

import {FilterID, IAccountManagerFilter} from "filters";

import {Wrapper} from './Filters.style'
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../common/hooks";

import {
    useAccountManagerFilter,
    useSalesExecutiveFilter,
    useTagsFilter
} from "../../../../../../common/hooks/filters";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import {removeAllFilters, setFilter} from "../../../../../../store/filters.slice";
import {ButtonStyled} from "../../../../../../common/styled-components";
import { useReferrerFilter } from '../../../../../../common/hooks/filters/useReferrerFilter';
import { usePartnerAccountManagerFilter } from '../../../../../../common/hooks/filters/usePartnerAccountManager';
import { useAutoFundingFilter } from '../../../../../../common/hooks/filters/useAutoFundingFilter';
import { useMarketerStatusFilter } from '../../../../../../common/hooks/filters/useMarketerStatusFilter';
import { usePauseRiskFilter } from '../../../../../../common/hooks/filters/usePauseRiskFilter';
import { useMarketerTypeFilter } from '../../../../../../common/hooks/filters/useMarketerTypeFilter';
import { useInvoicingCycleFilter } from '../../../../../../common/hooks/filters/useInvoicingCycleFilter';
import { usePaymentTermsFilter } from '../../../../../../common/hooks/filters/usePaymentTermsFilter';
import { useMarketerVerticalFilter } from '../../../../../../common/hooks/filters/useMarketersVertical';
import ClickAgainToConfirmTooltip from '../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip';

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'auto-funding',
        value: 'Auto-Funding'
    },
    {
        id: "invoicing-cycle",
        value:"Invoicing Cycle"
    },
    {
        id: "account-manager",
        value:"Engine Growth Manager"
    },
    {
        id: "marketer-status",
        value:"Marketer Status"
    },
    {
        id: "tags",
        value:"Marketer Tags"
    },
    {
        id: "marketer-type",
        value:"Marketer Type"
    },
    {
        id: "partner-account-manager",
        value:"Partner Account Manager"
    },
    {
        id: "pause-risk",
        value:"Pause Risk"
    },
    {
        id: "payment-terms",
        value:"Payment Terms"
    },
    {
        id: "referrer",
        value:"Referrer"
    },
    {
        id: 'sales-executive',
        value: 'Sales Executive'
    },
    // {
    //     id: 'niche',
    //     value: 'Vertical'
    // },
    {
        id: 'marketer-vertical',
        value: 'Vertical'
    }
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const [fromAngular, setFromAngular] = useState<boolean>(false)

    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    const autoFundingFilter = useAutoFundingFilter('admin/billing/marketer-funding-monitoring');
    const invoicingCycleFilter = useInvoicingCycleFilter('admin/billing/marketer-funding-monitoring', 50);
    const marketerAccountManagerFilter = useAccountManagerFilter('admin/billing/marketer-funding-monitoring', 50)
    const partnerAccountManagerFilter = usePartnerAccountManagerFilter('admin/billing/marketer-funding-monitoring', 50)
    const marketerTypeFilter = useMarketerTypeFilter('admin/billing/marketer-funding-monitoring');
    const pauseRiskFilter = usePauseRiskFilter('admin/billing/marketer-funding-monitoring');
    const marketerStatusFilter = useMarketerStatusFilter('admin/billing/marketer-funding-monitoring');
    const paymentTermsFilter = usePaymentTermsFilter('admin/billing/marketer-funding-monitoring', 50);
    const tagsFilter = useTagsFilter('admin/billing/marketer-funding-monitoring', 50);
    const referrerFilter = useReferrerFilter('admin/billing/marketer-funding-monitoring', 50);
    const salesExecutiveFilter = useSalesExecutiveFilter('admin/billing/marketer-funding-monitoring', 50);
    // const verticalFilter = useNicheFilter('admin/billing/marketer-funding-monitoring', 50);
    const marketerVerticalFilter = useMarketerVerticalFilter('admin/billing/marketer-funding-monitoring');

    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "auto-funding":
                return (
                    <IncludeExcludeFilter selectedFilterText={autoFundingFilter.selectedData[0] ? autoFundingFilter.selectedData[0].name : ''} filter={autoFundingFilter} filterTitle={'Auto-Funding'}>
                        {autoFundingFilter.data.map(option =>  
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={autoFundingFilter.selectedData.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    autoFundingFilter.setSelectedData(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        )
                        }
                    </IncludeExcludeFilter>
                )
            case 'invoicing-cycle':
                return (
                        <IncludeExcludeFilter selectedFilterText={invoicingCycleFilter.selectedData[0] ? invoicingCycleFilter.selectedData[0] : ''} filter={invoicingCycleFilter} filterTitle={'Invoicing Cycle'}>
                            {invoicingCycleFilter.data.map(cycle =>  (
                                <Checkbox
                                    wrapperClassName={'checkbox-filter-wrapper'}
                                    key={cycle+Math.random()}
                                    checked={invoicingCycleFilter.selectedData.some(selected => cycle === selected)}
                                    onChange={() => {
                                        invoicingCycleFilter.setSelectedData(cycle)
                                        setDiscoverDisplayed(true)
                                    }}
                                    label={cycle}
                                />
                            ))}
                        </IncludeExcludeFilter>
                    )
            case 'account-manager':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerAccountManagerFilter.selectedData[0] ? marketerAccountManagerFilter.selectedData[0].name + '' +  marketerAccountManagerFilter.selectedData[0].lastname: ''} filter={marketerAccountManagerFilter} filterTitle={'Engine Growth Manager'}>
                        {marketerAccountManagerFilter.data.map(manager =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={manager.id}
                                checked={marketerAccountManagerFilter.selectedData.some(selected => manager.id === selected.id)}
                                onChange={() => {
                                    marketerAccountManagerFilter.setSelectedData(manager)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${manager.name} ${manager.lastname}`}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            case 'marketer-status':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerStatusFilter.selectedData[0] ? marketerStatusFilter.selectedData[0].name : ''} filter={marketerStatusFilter} filterTitle={'Marketer Status'}>
                        {marketerStatusFilter.data.map(status =>  {                            
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={status.id}
                                checked={marketerStatusFilter.selectedData.some(selected => status.id === selected.id)}
                                onChange={() => {
                                    marketerStatusFilter.setSelectedData(status)
                                    setDiscoverDisplayed(true)
                                }}
                                label={status.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case 'tags':
                return (
                    <IncludeExcludeFilter selectedFilterText={tagsFilter.selectedData[0] ? tagsFilter.selectedData[0].name : ''} filter={tagsFilter} filterTitle={'Marketer Tags'}>
                        {tagsFilter.data.map(tag =>                       
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={tag.id}
                                checked={tagsFilter.selectedData.some(selected => tag.id === selected.id)}
                                onChange={() => {
                                    tagsFilter.setSelectedData(tag)
                                    setDiscoverDisplayed(true)
                                }}
                                label={tag.name}
                            />
                                
                        )}
                    </IncludeExcludeFilter>
                )
            case 'partner-account-manager':
                return (
                    <IncludeExcludeFilter selectedFilterText={partnerAccountManagerFilter.selectedData[0] ? partnerAccountManagerFilter.selectedData[0].name + ' ' +  partnerAccountManagerFilter.selectedData[0].lastname : ''} filter={partnerAccountManagerFilter} filterTitle={'Partner Account Managers'}>
                        {partnerAccountManagerFilter.data.map(manager =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={manager.id}
                                checked={partnerAccountManagerFilter.selectedData.some(selected => manager.id === selected.id)}
                                onChange={() => {
                                    partnerAccountManagerFilter.setSelectedData(manager)
                                    setDiscoverDisplayed(true)
                                }}
                                label={manager.name + " " + manager.lastname}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            case 'marketer-type':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerTypeFilter.selectedData[0] ? marketerTypeFilter.selectedData[0].name : ''} filter={marketerTypeFilter} filterTitle={'Marketer Type'}>
                        {marketerTypeFilter.data.map(type =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={type.name}
                                checked={marketerTypeFilter.selectedData.some(selected => type.name === selected.name)}
                                onChange={() => {
                                    marketerTypeFilter.setSelectedData(type)
                                    setDiscoverDisplayed(true)
                                }}
                                label={type.name}
                            />
                        ))}
                    </IncludeExcludeFilter>
        )
                // )
            case 'pause-risk':
                return (
                    <IncludeExcludeFilter selectedFilterText={pauseRiskFilter.selectedData[0] ? pauseRiskFilter.selectedData[0].name : ''} filter={pauseRiskFilter} filterTitle={'Pause Risk'}>
                        {pauseRiskFilter.data.map(risk =>  {                            
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={risk.id}
                                checked={pauseRiskFilter.selectedData.some(selected => risk.id === selected.id)}
                                onChange={() => {
                                    pauseRiskFilter.setSelectedData(risk)
                                    setDiscoverDisplayed(true)
                                }}
                                label={risk.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case 'payment-terms':
                return (
                    <IncludeExcludeFilter selectedFilterText={paymentTermsFilter.selectedData[0] ? paymentTermsFilter.selectedData[0] : ''} filter={paymentTermsFilter} filterTitle={'Payment Terms'}>
                        {paymentTermsFilter.data.map(term =>  {                            
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={term+Math.random()}
                                checked={paymentTermsFilter.selectedData.some(selected => term === selected)}
                                onChange={() => {
                                    paymentTermsFilter.setSelectedData(term)
                                    setDiscoverDisplayed(true)
                                }}
                                label={term}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case "referrer":
                return (
                    <IncludeExcludeFilter selectedFilterText={referrerFilter.selectedData[0] ? referrerFilter.selectedData[0].name + ' ' + referrerFilter.selectedData[0].lastname : ''} filter={referrerFilter} filterTitle={'Referrer'}>
                        {referrerFilter.data.map(referrer =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={referrer.id+referrer.name}
                                checked={referrerFilter.selectedData.some(selectedManager => referrer.id === selectedManager.id)}
                                onChange={() => {
                                    referrerFilter.setSelectedData(referrer)
                                    setDiscoverDisplayed(true)
                                }}
                                label={referrer.name + ' ' + referrer.lastname}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            case 'sales-executive':
                return (
                    <IncludeExcludeFilter selectedFilterText={salesExecutiveFilter.selectedData[0] ? salesExecutiveFilter.selectedData[0].name + ' ' + salesExecutiveFilter.selectedData[0].lastname : ''} filter={salesExecutiveFilter} filterTitle={'Sales Executive'}>
                        {salesExecutiveFilter.data.map(executive =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={executive.id+executive.name}
                                checked={salesExecutiveFilter.selectedData.some(selected => executive.id === selected.id)}
                                onChange={() => {
                                    salesExecutiveFilter.setSelectedData(executive)
                                    setDiscoverDisplayed(true)
                                }}
                                label={executive.name + " " + executive.lastname}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            // case 'niche':
            //     return (
            //         <IncludeExcludeFilter filter={verticalFilter} filterTitle={'Vertical'}>
            //             {verticalFilter.data.map(vertical =>  {                            
            //                 return <Checkbox
            //                     wrapperClassName={'checkbox-filter-wrapper'}
            //                     key={vertical.id}
            //                     checked={verticalFilter.selectedData.some(selected => vertical.id === selected.id)}
            //                     onChange={() => {
            //                         verticalFilter.setSelectedData(vertical)
            //                         setDiscoverDisplayed(true)
            //                     }}
            //                     label={vertical.name}
            //                 />
            //                 })}
            //         </IncludeExcludeFilter>
            //     )
            case 'marketer-vertical':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerVerticalFilter.selectedData[0] ? marketerVerticalFilter.selectedData[0].name : ''} filter={marketerVerticalFilter} filterTitle={'Vertical'}>
                        {marketerVerticalFilter.data.map(vertical =>  {                            
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={vertical.id}
                                checked={marketerVerticalFilter.selectedData.some(selected => vertical.id === selected.id)}
                                onChange={() => {
                                    marketerVerticalFilter.setSelectedData(vertical)
                                    setDiscoverDisplayed(true)
                                }}
                                label={vertical.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/billing/marketer-funding-monitoring'))
        }
    }


    return <Wrapper>
        <i className={'ico-Filters'}/>
        {filters.filter((filter: any) => filter.pageID === 'admin/billing/marketer-funding-monitoring')[0]?.filters.map((filter: any) => <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
        <SelectFilterDropdown allFilters={filterList} pageId={'admin/billing/marketer-funding-monitoring'}/>
        {filters.filter((filter: any) => filter.pageID === 'admin/billing/marketer-funding-monitoring')[0]?.filters.length > 1 &&
        <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
            {
                displayClearFilterTooltip
                &&
                <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
            }
            Clear All Filters
        </ButtonStyled>}
    </Wrapper>
}


export default Filters
