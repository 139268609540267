import styled from "styled-components";

interface IInputMultiLineWrapperProps {
    $width: string
}

export const InputMultiLineWrapper = styled.div<IInputMultiLineWrapperProps>`
    background: ${({theme})=>theme.colors.pureWhite};
    width: ${({$width}) => $width};
`;
// ${({theme})=>theme.colors}
export const InputMulti = styled.textarea`
    width: 100%;
    min-height: 70px;
    resize: vertical;
    padding: 16px;
    outline: none;
    font-size: 12px;
    color: ${({theme})=>theme.colors.deepBlue[4]};
    border: 1px solid ${({theme})=>theme.colors.coolGray[3]};
    border-radius: 8px;
    transition: .2s ease;
    font-family: ${({theme})=>theme.fonts.AvenirLight};

    &:hover {
        border: 1px solid ${({theme})=>theme.colors.coolGray[4]};
        color: ${({theme})=>theme.colors.deepBlue[5]};
        &::placeholder {
            color: ${({theme})=>theme.colors.coolGray[5]};
        }
    }

    &:focus {
        border-color: ${({theme})=>theme.colors.performBlue[5]};
        caret-color: ${({theme})=>theme.colors.performBlue[5]};
        color: ${({theme})=>theme.colors.deepBlue[4]};
        &::placeholder {
            color: ${({theme})=>theme.colors.coolGray[5]}50;
        }
    }

    &::placeholder {
        color: ${({theme})=>theme.colors.coolGray[4]};
        transition: .3s ease;
    }
`;
