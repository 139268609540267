import {useCallback} from 'react';
import {useAppSelector} from "../../../../../common/hooks";

/**
 * If offer type = CPE, then check Offer Tracking URL (Destination URL) for &af_enc_data={encrypted_cost} parameter
 * when user hits Next, Save Draft or Submit Offer.
 * If the parameter is found, alert the user to remove it.
 */

export const useCheckMacro = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    return useCallback((macro: string, value: string) => {
        if (offer.detailed_pricing_model === "cpe"){
            return value.includes(macro)
        }
        else {
            return false
        }

        
    }, [offer.detailed_pricing_model])
};
