import {FilterID} from "filters";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {useAppDispatch} from "../../useAppDispatch";

export interface IAutoFundingFilterValue {
    name: string,
    id: boolean
}

const allOptions: IAutoFundingFilterValue[] = [
    {name: 'Enabled', id: true},
    {name: 'Disabled', id: false}
];

export const useAutoFundingCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'auto-funding'

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (status: IAutoFundingFilterValue) => {
        if(filter.selected.some(selected => selected.name === status.name)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== status.id)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, status]}
            }))
        }

    }

    const setInclude = (include: boolean) => {

    }

    const setMatch = (match: 'any' | 'all' | 'empty') => {

    }

    return {
        id: id,
        options: allOptions,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        match: {
            value: filter.match,
            setMatch
        }
    }
}