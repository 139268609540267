import { IInputMultiLineProps } from "./InputMultiLine.props";
import { InputMulti, InputMultiLineWrapper } from "./InputMultiLine.style";

const InputMultiLine = ({onChange, value, placeholder, width = "100%", readOnly}: IInputMultiLineProps ) => {
    const inputHandler = (val: string) => {
        onChange(val);
    }
    return(
        <>
            <InputMultiLineWrapper $width={width} >
                <InputMulti 
                    placeholder={placeholder} 
                    value={value} 
                    onChange={(e:any) => inputHandler(e.target.value)} 
                    readOnly={readOnly}
                />
            </InputMultiLineWrapper>
        </>
    )
}

export default InputMultiLine;