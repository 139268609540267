import React, {useEffect, useMemo, useState} from "react";
import {ColDef} from "ag-grid-community";
import {DeleteDropdownItem, Separator, Tag, Wrapper} from "./NonMerged.style"
import FiltersRow from "../../../../../../common/components/filters/filters-row/FiltersRow";
import {
    FiltersRowLeft,
    FiltersRowRight
} from "../../../../../../common/components/filters/filters-row/FiltersRow.style";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import Grid from "../../../../../../common/components/grid/Grid";
import Search from "../../../../../../common/components/search/Search";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import MergingService, {
    IAssignTag,
    INonMergedPartner,
    INonMergedPartnersParams,
    IPartnerTrafficType,
    ITagNonMerged,
    NonMergedUser,
    PLATFORMS,
    SORT
} from "../Merging.service";
import {tokenStore} from "../../../../../../store/auth.slice";
import {useAppSelector} from "../../../../../../common/hooks";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import {TSortType} from "../../../../../../common/components/table/sortable-header-v2/sortableHeaderV2.props";
import Actions from "../../../../../../common/components/actions/Actions";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import Modal from "../../../../../../common/components/modal/Modal";
import ModalWindow from "../../../../../../common/components/modalWindow/ModalWindow";
import AddTagsModal from "../../../../../../common/components/tags/add-tags-modal/AddTagsModal";
import RemoveTagsModal from "../../../../../../common/components/tags/remove-tags-modal/RemoveTagsModal";
import {ITag} from "../../../../../../common/models/tag.type";
import _ from "lodash";
import FiltersService from "../../../../../../common/services/filters.service";
import MergePartnersModal from "./merge-partners-modal/MergePartnersModal";
import MergeWithExistingPartnersModal from "./merge-with-existing-partners-modal/MergeWithExistingPartnersModal";
import EditDetailsModal from "./edit-details-modal/EditDetailsModal";

const NonMerged: React.FC = () => {
    const token = useAppSelector(tokenStore)

    const perPageCustomList: number[] = [5, 10, 20, 50];
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [lastPage, setLastPage] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<string>(SORT[0]);
    const [order, setOrder] = useState<TSortType>('desc');
    const [isAddTagModalOpened, setIsAddTagModalOpened] = useState(false);
    const [isEditDetailsModalOpened, setIsEditDetailsModalOpened] = useState(false);
    const [isDeleteTagModalOpened, setIsDeleteTagModalOpened] = useState(false)
    const [isMergingPartnersOpened, setIsMergingPartnersOpened] = useState<boolean>(false)
    const [isMergeWithExistingPartnersOpened, setIsMergeWithExistingPartnersOpened] = useState<boolean>(false)

    const [tags, setTags] = useState<ITag[]>([])
    const [addTag, setAddTag] = useState<boolean>(false)
    const [addedTags, setAddedTags] = useState<ITag[]>([])

    const [selectedRows, setSelectedRows] = useState<INonMergedPartner[]>([])

    const [tagsFromPartners, setTagsFromPartners] = useState<ITag[]>([])
    const [trafficTypeFromPartner, setTrafficTypeFromPartner] = useState<IPartnerTrafficType>({
        id: 0,
        name: "- Select traffic type -"
    })

    const [tableData, setTableData] = useState<INonMergedPartner[]>([])
    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), [])


    const indeterminate = () => {
        const list = selectedRows.filter((item: INonMergedPartner) => tableData.some((it: INonMergedPartner) => it.id === item.id));
        return list.length > 0 && list.length < tableData.length;
    }

    const selectAllRows = () => {
        const currentState = selectedRows.filter((item: INonMergedPartner) => tableData.some((it: INonMergedPartner) => it.id === item.id));
        if(currentState.length === 0) {
            setSelectedRows([...selectedRows, ...tableData])

        }
        if(currentState.length === tableData.length) {
            const newList = selectedRows.filter((item: INonMergedPartner) => !tableData.some((it: INonMergedPartner) => it.id === item.id));
            setSelectedRows(newList)
        }
    }

    const selectRow = (offer: INonMergedPartner) => {
        if(!selectedRows.some((item: INonMergedPartner) => offer.id === item.id)){
            setSelectedRows([...selectedRows, offer])
        } else {
            const newOffersList = selectedRows.filter((item: INonMergedPartner) => item.id !== offer.id);
            setSelectedRows(newOffersList)
        }
    }

    const columnDefs = useMemo<ColDef[]>(() => [
            {
                pinned: "left", width: 50, headerComponent: () => {
                    if(indeterminate()) {
                        const onChange = () => {
                            const newList = selectedRows.filter((item: INonMergedPartner) => !tableData.some((it: INonMergedPartner) => it.id === item.id))
                            setSelectedRows(newList)
                        }

                        return <Checkbox indeterminate={indeterminate()} checked={true} onChange={onChange} />
                    }
                    const check = selectedRows.filter((item: INonMergedPartner) => tableData.some((it: INonMergedPartner) => it.id === item.id)).length === tableData.length;
                    return <Checkbox checked={check} onChange={() => selectAllRows()} />
                },
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return <div className={'cell-item'}>
                        <Checkbox
                            checked={selectedRows.some(selectedRow => selectedRow.id === data.id)}
                            onChange={() => selectRow(data)}/>
                    </div>
                }
            },
            {
                field: "id", headerName: "PARTNER ID", width: 150,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return <span>{data.platform_partner_id}</span>
                }
            },
            {
                field: "name", width: 300,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return <span>{data.platform_partner_name}</span>
                },
                headerComponent: (row: any) => {
                    return <SortableHeaderV2
                        keyId={SORT[1]}
                        sortDirection={order}
                        sortedBy={sort}
                        onClick={(event: any) => {
                            setSort(SORT[1])
                            setOrder(order === "desc" ? "asc" : "desc")
                        }}
                    >PARTNER NAME</SortableHeaderV2>
                }
            },
            {
                field: "company", headerName: "PARTNER COMPANY NAME", width: 300,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return <span>{data.platform_partner_company}</span>
                }
            },
            {
                field: "platform", headerName: "PLATFORM", width: 150,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    let id: number = data.platform_id
                    if (id) {
                        return <span>{PLATFORMS[id]}</span>
                    } else
                        return <span>{PLATFORMS[0]}</span>
                }
            },
            {
                field: "type", headerName: "TRAFFIC TYPE", width: 150,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return <span>{data.partner_traffic_type?.map((type: IPartnerTrafficType) => {
                        return <span key={type.name + Math.random()}>{type.name}</span>
                    })}</span>
                }
            },
            {
                field: "tags", headerName: "TAGS", width: 300,
                cellRenderer: ({data}: { data: INonMergedPartner }) => {
                    return data.tags && data.tags.map((tag: ITagNonMerged) => {
                        return <Tag key={tag.tag_id + tag.name}>{tag.name}</Tag>
                    })
                }
            }
        ]
        , [selectedRows, order, sort, tableData]
    )

    const exportCsv = async () => {
        let params: INonMergedPartnersParams = {
            sort_by: sort,
            order: order,
            action: "export_count"
        }
        if (search !== "") params.q = search
        if (perPage !== 50) params.paginate_by = perPage
        if (currentPage !== 1) params.page = currentPage

        let {data} = await MergingService.fetchNonMergedPartners(token, params)
        if (data.export_to_email) {
            alert("Your export will be sent to your email!")

        } else {
            let paramsTemp: {
                sort_by: string,
                order: string,
                action: string,
                q?: string,
                paginate_by?: string,
                page?: string
            } = {
                sort_by: sort,
                order: order,
                action: "export_csv"
            }
            if (search !== "") paramsTemp.q = search
            if (perPage !== 50) paramsTemp.paginate_by = perPage.toString()
            if (currentPage !== 1) paramsTemp.page = currentPage.toString()


            let urlParams = new URLSearchParams(paramsTemp)
            window.open([process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                process.env.REACT_APP_API_NON_MERGED].join("/") + "?" + urlParams)
        }
    }


    const fetchNonMergedPartnersTableData = async () => {
        setLoading(true);
        let params: INonMergedPartnersParams = {
            sort_by: sort,
            order: order
        }
        if (search !== "") params.q = search
        if (perPage !== 50) params.paginate_by = perPage
        if (currentPage !== 1) params.page = currentPage
        let {data} = await MergingService.fetchNonMergedPartners(token, params)
        setTableData(data.data)
        setFrom(data.from);
        setLastPage(data.last_page);
        setTo(data.to);
        setTotal(data.total);
        setLoading(false);
    }

    const memoFetch = _.memoize(fetchNonMergedPartnersTableData)

    useEffect(() => {
        setSort(SORT[0])
        setOrder('desc')
        memoFetch()
    }, [search])

    useEffect(() => {
        memoFetch()
    }, [perPage, currentPage, sort, order])


    const fetchTags = async () => {
        let {data} = await FiltersService.fetchTags(token)
        setTags(data)
    }
    useEffect(() => {
        fetchTags()
    }, [])

    const openAddTagsModal = () => {
        setAddTag(true)
        setAddedTags([])
        setIsAddTagModalOpened(true)
    }
    const openEditDetailsModal = () => {
        setAddTag(false)
        let tags: ITag[] = selectedRows[0].tags && selectedRows[0].tags.map((tag: ITagNonMerged) => {
            return {id: tag.tag_id, name: tag.name}
        })
        setAddedTags(tags)
        setIsEditDetailsModalOpened(true)
    }
    const openRemoveTagsModal = () => {
        setIsDeleteTagModalOpened(true)
    }

    const openMergePartnersModal = () => {
        let mergedTags: ITag[] = []
        let sameTrafficTypeForAllPartners: boolean = true
        let trafficType: IPartnerTrafficType = selectedRows[0].partner_traffic_type ?
            selectedRows[0].partner_traffic_type[0] : {id: 0, name: "- Select traffic type -"}
        selectedRows.forEach((user: INonMergedPartner) => {
            if (user.partner_traffic_type) {
                if (trafficType.id !== user.partner_traffic_type[0].id) {
                    sameTrafficTypeForAllPartners = false
                }
            } else{
                sameTrafficTypeForAllPartners = false
            }
            user.tags.forEach((tag: ITagNonMerged) => {
                mergedTags.push({
                    id: tag.tag_id,
                    name: tag.name
                })
            })
        })
        const filteredTags = mergedTags.reduce((selectedTags: ITag[], currentTag) => {
            const tempTag = selectedTags.find((item: ITag) => item.id === currentTag.id);
            if (!tempTag) {
                return selectedTags.concat([currentTag]);
            } else {
                return selectedTags;
            }
        }, []);
        setTagsFromPartners(filteredTags)
        if (sameTrafficTypeForAllPartners)
            setTrafficTypeFromPartner(trafficType)
        else
            setTrafficTypeFromPartner({
                id: 0,
                name: "- Select traffic type -"
            })
        setIsMergingPartnersOpened(true)
    }

    const openMergeWithExPartnerModal = () => {
        setIsMergeWithExistingPartnersOpened(true)
    }


    const saveDetails = async (selectedTagsNum: number[]) => {
        let users: NonMergedUser[] = selectedRows.map((user: INonMergedPartner) => {
            let tempUser: NonMergedUser = {
                id: user.platform_partner_id,
                platform_id: user.platform_id
            }
            return tempUser
        })
        let params: IAssignTag = {
            add: addTag,
            tags: selectedTagsNum,
            users: users
        }
        await MergingService.addTags(token, params)
        await fetchNonMergedPartnersTableData()
        setSelectedRows([])
    }
    const removeTags = async () => {
        let users: NonMergedUser[] = selectedRows.map((user: INonMergedPartner) => {
            let tempUser: NonMergedUser = {
                id: user.platform_partner_id,
                platform_id: user.platform_id
            }
            return tempUser
        })
        let params: IAssignTag = {
            users: users
        }
        await MergingService.removeTags(token, params)
        await fetchNonMergedPartnersTableData()
    }

    const closeAddTagsModal = () => {
        setSelectedRows([])
        setIsAddTagModalOpened(false)
    }
    const closeEditDetailsModal = () => {
        setSelectedRows([])
        setIsEditDetailsModalOpened(false)
    }
    const closeRemoveTagsModal = () => {
        setSelectedRows([])
        setIsDeleteTagModalOpened(false)
    }
    const closeMergePartnersModal = () => {
        setSelectedRows([])
        setIsMergingPartnersOpened(false)
    }
    const closeMergeWithExModal = () => {
        setSelectedRows([])
        setIsMergeWithExistingPartnersOpened(false)
    }
    return (
        <Wrapper>
            <FiltersRow>
                <FiltersRowLeft>
                    <Actions selectedRows={selectedRows.map(item => {
                        return {
                            displayName: item.platform_partner_name,
                            id: item.platform_partner_id,
                            platform_partner_company: item.platform_partner_company,
                            platform_id: item.platform_id ? item.platform_id : 0,
                            non_merged: true
                        }
                    })}>
                        <DropdownItem className={selectedRows.length < 2 ? "disabled" : ""}
                                      onClick={openMergePartnersModal}>Merge
                            Partners
                        </DropdownItem>
                        <DropdownItem className={selectedRows.length < 1 ? "disabled" : ""}
                        onClick={openMergeWithExPartnerModal}>
                            Merge with Existing Merged Partner</DropdownItem>
                        <Separator/>
                        <DropdownItem
                            className={selectedRows.length === 0 || selectedRows.length > 1 ? "disabled" : ""}
                            onClick={openEditDetailsModal}>Edit Details</DropdownItem>

                        <DropdownItem onClick={openAddTagsModal}>Add Tags to Selected</DropdownItem>
                        <DeleteDropdownItem onClick={openRemoveTagsModal}>Remove All Tags from
                            Selected</DeleteDropdownItem>
                        <Modal opened={isAddTagModalOpened}
                               title={addTag ? "Add Tags to selected Partner(s)" : "Edit Tags"}
                               closeModal={closeAddTagsModal}>
                            <AddTagsModal
                                addTag={addTag}
                                addedTags={addedTags}
                                selectedUsers={selectedRows.map(user => {
                                    return {id: user.platform_partner_id, platform_id: user.platform_id}
                                })}
                                saveTags={saveDetails}
                                fetchData={fetchNonMergedPartnersTableData}
                                closeModal={closeAddTagsModal}
                                tags={tags}/>
                        </Modal>
                        <Modal opened={isEditDetailsModalOpened} title={"Edit Details"}
                               closeModal={closeEditDetailsModal}>
                            <EditDetailsModal
                                selectedPartners={selectedRows[0]}
                                addedTags={addedTags} tags={tags}
                                saveDetails={saveDetails}
                                trafficTypeFromPartners={trafficTypeFromPartner}
                                closeModal={()=> setIsEditDetailsModalOpened(false)}
                                clearSelectedRow={()=> setSelectedRows([])} />
                        </Modal>
                        <Modal opened={isDeleteTagModalOpened}
                               title={"Remove All Tags from Selected Partner(s)?"}
                               closeModal={closeRemoveTagsModal}>
                            <RemoveTagsModal
                                content={"Are you sure you want to remove all tags from the selected partner(s)? " +
                                "This is a global change and will affect all users in the platform. " +
                                "All reports and filters related to these partners will be updated " +
                                "immediately upon removing the tags.\n" +
                                "\n" +
                                "This action cannot be undone."}
                                removeTags={removeTags}
                                fetchData={fetchNonMergedPartnersTableData}
                                closeModal={closeRemoveTagsModal}/>
                        </Modal>

                        <ModalWindow modalWidth={784} opened={isMergingPartnersOpened}>
                            <MergePartnersModal
                                selectedPartners={selectedRows}
                                tagsFromPartners={tagsFromPartners}
                                tags={tags}
                                trafficTypeFromPartners={trafficTypeFromPartner}
                                closeModal={closeMergePartnersModal}
                                fetchData={fetchNonMergedPartnersTableData} />
                        </ModalWindow>

                        <ModalWindow
                            modalWidth={784}
                            opened={isMergeWithExistingPartnersOpened}>
                            <MergeWithExistingPartnersModal
                                fetchData={fetchNonMergedPartnersTableData}
                                selectedPartners={selectedRows}
                                closeModal={closeMergeWithExModal}/>
                        </ModalWindow>

                    </Actions>
                    <Search onChange={setSearch} initialValue=""
                            placeholder="Search by ID, Partner Name, Company Name"/>
                </FiltersRowLeft>
                <FiltersRowRight>
                    <Pagination
                        perPageCustomList={perPageCustomList}
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        onRefresh={fetchNonMergedPartnersTableData}
                        exportCsv={exportCsv}
                    />
                </FiltersRowRight>
            </FiltersRow>
            <Grid

                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowData={tableData}
                rowHeight={40}
                domLayout='autoHeight'
                loading={loading}
            />
            <FiltersRow>
                <FiltersRowLeft>
                </FiltersRowLeft>
                <FiltersRowRight>
                    <Pagination
                        perPageCustomList={perPageCustomList}
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                    />
                </FiltersRowRight>
            </FiltersRow>
        </Wrapper>
    )
}

export default NonMerged
