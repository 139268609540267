import styled from "styled-components";

export const ShareWidgetContainer = styled.div`
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 16px;
    position: relative;
    > * {
        z-index: 2;
        position: relative;
    }
    &:after, &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: opacity 300ms ease-in-out;
        border-radius: 16px;
    }
    &:before {
        opacity: 1;
        z-index: 1;
        background: linear-gradient(89.97deg, #3FBFC7 0.03%, #1D728D 99.97%);
    }
    &:after {
        opacity: 1;
        z-index: 0;
        background: linear-gradient(89.97deg, #40C1C9 34.87%, #0E5B74 99.97%);
    }
    &:hover {
        &:before {
            opacity: 0;
        }
    }
`

export const ShareWidgetTitle = styled.div`
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 8px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
`

export const ShareBox = styled.div`
    display: flex;
`

export const ShareBoxInput = styled.div`
    position: relative;
    padding-left: 12px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E7ECEE;
    border-radius: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    color: ${({theme}) => theme.colors.deepBlue[4]};
`

export const ShareBoxCopy = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    margin: 0;
    border: none;
    cursor: pointer;
    background: #ffffff;
    &:hover {
        svg {
            color: ${({theme}) => theme.colors.deepBlue[5]};
        }
    }
`

export const ShareBoxResponse = styled.small`
    position: absolute;
    right: 35px;
    font-size: 10px;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 5px;
    &.text-success {
        color: ${({theme}) => theme.colors.success[4]};
    }
    &.text-danger {
        color: ${({theme}) => theme.colors.critical[3]};
    }
`

export const ShareBoxSocialToolbar = styled.div`
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 105px;
    margin-left: 12px;
    button {
        display: flex;
        svg circle { transition: all .2s ease-in-out; }
        :hover {
            svg circle {
                fill: ${({theme}) => theme.colors.performBlue[2]}!important;
            }
        }
        :active {
            svg circle {
                fill: ${({theme}) => theme.colors.performBlue[4]}!important;
            }
        }
    }
`

