import styled from 'styled-components'

export const Wrapper = styled.div`
  .actions-toggle-button {
    min-height: 32px;
    line-height: 1;
    gap: 0;
    &.active {
      background: ${({theme}) => theme.colors.deepBlue[2]};
      border-color: ${({theme}) => theme.colors.deepBlue[3]};
      .selected-rows-count{
        color: ${({theme}) => theme.colors.performBlue[7]};
        border-color: ${({theme}) => theme.colors.performBlue[7]};
      }
    }
    .ico-AngleDown {
      margin-left: 10px;
    }
  }
  .actions-header{
    min-height: 32px;
    height: 32px;
    display: flex;
    
    span > div {
      display: flex;
    }
  }
  
  .actions-container {
    width: 161px;
    overflow-y: visible;
  }
  
  .item-separator{
    height: 1px;
    background: ${({theme}) => theme.colors.coolGray[3]};
    margin: 8px;
  }
`
export const SelectedOffersCount = styled.div`
  height: 16px;
  //padding: 4px 8px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.performBlue[5]};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
  background: ${({theme}) => theme.colors.performBlue[1]};
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 12px;
  margin-left: 8px;
  
  i{
    font-size: 11px;
  }
`

export const ActionHoverNotShown = styled.p`
  font-family: ${({theme}) => theme.fonts.AvenirItalic};
  color: ${({theme}) => theme.colors.coolGray[5]};
`

export const SelectedRowsCount = styled.div`
  height: 14px;
  //padding: 4px 8px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.performBlue[5]};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
  background: ${({theme}) => theme.colors.performBlue[1]};
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 12px;
  margin-left: 8px;
  
  i {
    font-size: 11px;
  }
`

export const SelectedRowsItem = styled.p`
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }
`