import {api} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";
import qs from "qs";


export interface IUpdateBillingNotesParams {
    user_id: number
    billing_notes: string | null
}

export interface IEditStayLiveLimitParams {
    user_id: number
    credit: number | 'unlimited'
}

export interface IIntacctInvoice {
    invoice_number: string;
    customer_id: string;
    customer_name: string;
    invoice_total: string;
    amount_paid: string;
    amount_due: string;
    due_date: string;
    invoice_state: string;
    paid_date: string | null;
}

export interface ILatestReassessmentStatus {
    advertiser_id: number
    id: number
    issue_id: number
    issue_key: string
    last_updated_at: string
    num: number
    priority: number
    request_submitted_at: string
    resolution_date: string
    resolution_status: string
    status: string
    ticket_resolution: string
}

export interface IIFetchMarketerFundingDataResponse {
    id: number;
    name: string;
    lastname: string;
    user_type: string;
    company: string;
    balance: number;
    credit?: number;
    niche_id?: number;
    niche_name: string | null;
    auto_funding: number;
    auto_funding_amount?: number;
    executive_id: number;
    account_manager_name: string;
    account_manager_lastname: string;
    account_manager_email: string;
    marketer_total_revenue: string;
    intacct_credit_deposit?: any;
    intacct_credit_limit: string;
    intacct_invoicing_cycle: string;
    intacct_payment_term: string;
    total_credit_limit: number;
    credit_used: number;
    billing_notes: string;
    funding_days_remaining?: number;
    funding_stay_live_days_remaining?: number;
    intacct_invoices: IIntacctInvoice[];
    days_until_payment?: number;
    pause_risk: string;
    credit_request_status: ILatestReassessmentStatus;
}

class MarketerFundingMonitoringService {

    static fetchMarketerFundingData = async (token: string, params: {
        paginate_by: number,
        sort_by: string,
        order: "desc" | "asc",
        page: number
    }) => {        
        return await api.get<IPagination<IIFetchMarketerFundingDataResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params,
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            }
        )
    }

    static exportToCsv = (params: {
            paginate_by: number;
            sort_by: string;
            order: "desc" | "asc";
            page: number;
            columns?: string[];
            intacct_invoicing_cycle?: { ids: string[]; include: boolean };
            intacct_payment_terms?: { ids: any; include: boolean };
            advertiser_niche?: { ids: number[]; include: boolean };
            referrer?: { ids: number[]; include: boolean };
            pause_risk?: { ids: string[]; include: boolean };
            account_manager?: { ids: number[]; include: boolean };
            affiliate_account_manager?: { ids: number[]; include: boolean };
            auto_funding?: number;
            advertiser_status?: { ids: string[]; include: boolean };
            marketer_tags?: { ids: number[]; include: boolean };
            sales_executives?: { ids: number[]; include: boolean };
            marketer_type?: { ids: string[]; include: boolean } }
    ) => {
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            'funding-monitoring'
        ].join('/') + "?" + qs.stringify(params) + "&" + "action=export_csv", "_blank");
    }
    

    static updateBillingNotes = async (token: string, params: IUpdateBillingNotesParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring'
            ].join('/'),
            {
                action: 'update_billing_notes',
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },

            })
    }

    static editStayLiveLimit = async (token: string, params: IEditStayLiveLimitParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring'
            ].join('/'),
            {
                action: 'update_stay_live_credit',
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },

            })
    }

}

export default MarketerFundingMonitoringService