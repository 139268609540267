import React from 'react';
import {useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";
import {MainConversionAndEventsWrapper} from "../Summary.style";

interface Props {}

const MainConversion: React.FC<Props> = () => {
    const {offer, cpcMainConversionPanelVisibility, navState} = useAppSelector(state => state.createOffer)

    if(navState['outcome-funnel'] === 'disabled') return null

    if(offer.detailed_pricing_model === 'cpc') {
        return <>
            <MainConversionAndEventsWrapper>
                <div className="header">Click</div>
                <div className="content">
                    <table className={'payouts'}>
                        <thead>
                        <tr>
                            <th>Payout</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{offer.max_bid ? '$'+offer.max_bid : '$0.00'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MainConversionAndEventsWrapper>

            {(cpcMainConversionPanelVisibility) &&
                <MainConversionAndEventsWrapper>
                    <div className="header">Main Conversion</div>
                    <div className="content">
                        <table className={'payouts'}>
                            <thead>
                            <tr>
                                <th>Target CPA</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{offer.cpa ? '$'+offer.cpa : '$0.00'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </MainConversionAndEventsWrapper>
            }
        </>
    }

    return (
        <>
            <MainConversionAndEventsWrapper>
                <div className="header">
                    Main Conversion
                    {offer.detailed_pricing_model === 'ppc' && ' - Call'}
                    {(offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') && ' - Install'}
                </div>
                <div className={'content'}>
                    <table className={'payouts'}>
                        <thead>
                        <tr>
                            {(offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe') &&
                                <th>Payout Type</th>
                            }
                            {!offer.is_revshare &&
                                <th>Payout</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {(offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe') &&
                                <td>{offer.is_revshare ? 'Revshare' : 'Standard'}</td>
                            }
                            {!offer.is_revshare &&
                                <td>{offer.cpa ? '$'+offer.cpa : '$0.00'}</td>
                            }
                        </tr>
                        </tbody>
                    </table>
                    {offer.is_revshare &&
                        <table style={{marginTop: 8}} className={'payouts'}>
                            <thead>
                            <tr>
                                <th>Payout Description</th>
                                <th>Payout</th>
                            </tr>
                            </thead>
                            <tbody>
                            {offer.revshare.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {item.name ? item.name : '-'}
                                        </td>
                                        <td>
                                            {item.value ? '$'+item.value : '$0.00'}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    }
                </div>
            </MainConversionAndEventsWrapper>
        </>
    )
}

export default MainConversion