import { useEffect, useState } from 'react';
import Grid from '../../../../../common/components/grid/Grid';
import Icon from '../../../../../common/components/icon/Icon';
import SortableHeaderV2 from '../../../../../common/components/table/sortable-header-v2/SortableHeaderV2';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { ButtonStyled } from '../../../../../common/styled-components';
import { formatNumberToCurrency } from '../../../../../common/utils';
import PartnerTransactionsDateRange from './date-range/PartnerTransactionsDateRange';
import PartnerTransactionseService, { IPartnerTransactionsResponse } from './PartnerTransactions.service';
import { PartnerTransactionsWrapper } from './PartnerTransactions.style';
import { setPerPage, setSort } from "./PartnerTransactions.slice";
import { format } from 'date-fns';
import Pagination from '../../../../../common/components/table/pagination/Pagination';
import Filters from './filters/Filters';
import { PageID } from '../../../../../store/filters.slice';
import { FilterID } from 'filters';
import Modal from '../../../../../common/components/modal/Modal';
import { IFetchAffiliatesResponse } from '../partner-payments/PartnerPayment.service';
import AddTransactionModal from './add-transaction-modal/AddTransactionModal';
import { IPartnerResponse } from '../../partners/leap-partners/LeapPartners.service';
import AlertModal from '../../../../../common/components/alert-modal/AlertModal';

const PartnerTransactions:React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<IPartnerTransactionsResponse[]>([]);
    const [sumData, setSumData] = useState<any>([]);
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);

    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(1)
    const [total, setTotal] = useState(1)
    const [lastPage, setLastPage] = useState(1);

    const [affiliates, setAffiliates] = useState<IFetchAffiliatesResponse[]>([])

    const targetOrigin = process.env.REACT_APP_API_BASE_URL

    const [addModal, setAddModal] = useState({
        opened: false,
        data: null
    })

    const [alertModal, setAlertModal] = useState<{
        message: string,
        type: 'error' | 'success',
        opened: boolean
    }>({
        message: '',
        type: 'success',
        opened: false
    })

    const {token} = useAppSelector(state => state.auth);
    const {sort, date_from, date_to, per_page} = useAppSelector(state => state.partnerTransactions);
    const dispatch = useAppDispatch();

    const sortHandler = (by: string) => {
        dispatch(setSort(
                {
                    by: by,
                    direction: sort.by === by && sort.direction === "desc" ? "asc" : "desc"
                }
            )
        );
    };

    const handleLoginAs = (user: any) => {
        targetOrigin && window.parent.postMessage({id: 1, user}, targetOrigin );
    }

    const colDefs: any[] = [
        {
            field: 'id',
            headerName: 'TRANSACTION ID',
        },
        {
            field: 'date_formated',
            headerComponent: () => {
                return(
                    <SortableHeaderV2
                        onClick={() => sortHandler("date")}
                        keyId={'date'}
                        sortDirection={sort.direction}
                        sortedBy={sort.by}
                    >
                        DATE
                    </SortableHeaderV2>
                )
            },
        },
        {
            field: 'user_id',
            headerName: 'PARTNER ID',
        },
        {
            cellRenderer: (data: any) => {
                return <div className="login-as-partner" onClick={() => handleLoginAs(data.data.user)}>{data.data.user?.full_name}</div>
            },
            headerName: 'PARTNER',
        },
        {
            cellRenderer: (data: any) => {
                return <div>{data.data.user?.company}</div>
            },
            headerName: 'COMPANY NAME',
        },
        {
            cellRenderer: (data: any) => {
                return <div>{data.data.user?.executive?.display_name}</div>
            },
            headerName: 'ACCOUNT MANAGER',
        },
        {
            field: 'note',
            headerName: 'NOTE',
        },
        {
            field: 'type_name',
            headerName: 'TYPE',
        },
        {
            cellRenderer: (data: any) => {
                return <div>
                    {formatNumberToCurrency(data.data.credit)}
                </div>
            },
            headerComponent: () => {
                return(
                    <SortableHeaderV2
                        onClick={() => sortHandler("credit")}
                        keyId={'credit'}
                        sortDirection={sort.direction}
                        sortedBy={sort.by}
                    >
                        CREDIT
                    </SortableHeaderV2>
                )
            },
        },
        {
            cellRenderer: (data: any) => {
                return <div>
                    {formatNumberToCurrency(data.data.debit)}
                </div>
            },
            headerComponent: () => {
                return(
                    <SortableHeaderV2
                        onClick={() => sortHandler("debit")}
                        keyId={'debit'}
                        sortDirection={sort.direction}
                        sortedBy={sort.by}
                    >
                        DEBIT
                    </SortableHeaderV2>
                )
            },
        },
    ]

    const {filters} = useAppSelector((state:any) => state.filters)

    const readFilter = (page: PageID, filterId: FilterID) => {
        return filters?.find((el: any) => 
            el.pageID === page)?.
            filters.find((filter: any) => filter.id === filterId) || null
    }

    const affiliateFilter = readFilter('admin/billing/partner-transactions', 'affiliate');
    const typeFilter = readFilter('admin/billing/partner-transactions', 'partner-transaction-type');

    useEffect(()=>{        
        setDiscoverDisplayed(true)
    }, [
        typeFilter, affiliateFilter
    ])

    const fetchData = async () => {
        setLoading(true);
        setDiscoverDisplayed(false)
        let params: {
            paginate_by?: number,
            sort_by?: string,
            order?: "desc" | "asc",
            page?: number,
            from?: string,
            to?: string,
            affiliate_id?: number,
            type?: number,
            partner_payment_preference?: string,
            partner_payment_status?: string,
            partner_payment_cycle?: string,
            partner_has_w8?: boolean
        } = {
        }

        if(affiliateFilter?.selected.length) {
            params.affiliate_id = affiliateFilter.selected[0].id
        }

        if(typeFilter?.selected.length && typeFilter?.selected[0].id !== 0) {
            params.type = typeFilter.selected[0].id
        }

        if(date_from){
            params.from = format(new Date(date_from ? date_from : new Date()), "y-MM-dd")
        }

        if(date_to){
            params.to = format(new Date(date_to ? date_to : new Date()), "y-MM-dd")
        }

        if(sort.by !== '') {            
            params.sort_by = sort.by;
            params.order = sort.direction;
        }

        if(page > 1){
            params.page = page
        }

        if(per_page !== 50) {
            params.paginate_by = per_page
        }

        try {
            const response = await PartnerTransactionseService.fetchData(token, params)
            setTableData(response.data.data)
            setSumData(response.data.sums)
            setFrom(response.data.from)
            setTo(response.data.to)
            setTotal(response.data.total)
            setLastPage(response.data.last_page)

        } catch (error) {
            console.error(error)
        }
        setLoading(false);   
    }

    const exportCsv = () => {
        let params: {
            paginate_by?: number,
            sort_by?: string,
            order?: "desc" | "asc",
            page?: number,
            from?: string,
            to?: string,
            affiliate_id?: number,
            type?: number,
            partner_payment_preference?: string,
            partner_payment_status?: string,
            partner_payment_cycle?: string,
            partner_has_w8?: boolean
        } = {
        }

        if(affiliateFilter?.selected.length) {
            params.affiliate_id = affiliateFilter.selected[0].id
        }

        if(typeFilter?.selected.length && typeFilter?.selected[0].id !== 0) {            
            params.type = typeFilter.selected[0].id
        }

        if(date_from){
            params.from = format(new Date(date_from ? date_from : new Date()), "y-MM-dd")
        }

        if(date_to){
            params.to = format(new Date(date_to ? date_to : new Date()), "y-MM-dd")
        }

        if(sort.by !== '') {            
            params.sort_by = sort.by;
            params.order = sort.direction;
        }

        if(page > 1){
            params.page = page
        }

        if(per_page !== 50) {
            params.paginate_by = per_page
        }
        PartnerTransactionseService.exportToCsv(params)
    }

    const fetchAffiliates = async () => {
        try{
            const {data} = await PartnerTransactionseService.fetchAffiliates(token)
            setAffiliates(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        token && fetchData()
    }, [token, sort, per_page, page])

    useEffect(() => {
        token && fetchAffiliates()
    }, [token])

    useEffect(()=>{        
        setDiscoverDisplayed(false)
    }, [])

    return (
        <PartnerTransactionsWrapper>
            <div className="top-row">
                <div className="date-range-discover-results">
                    <PartnerTransactionsDateRange fetchData={fetchData} />
                    {   
                        discoverDisplayed &&
                        <ButtonStyled onClick={fetchData} className={'btn-discover-results'}>
                            Discover Result 
                            <Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/>
                        </ButtonStyled>
                    }
                </div>
            </div>
            <div className="filters-pagination">
                <Filters setDiscoverDisplayed={setDiscoverDisplayed} />
                <div className="pagination-add-transaction">
                    <Pagination 
                        current_page={page}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setPage}
                        setPerPage={(n: number) => dispatch(setPerPage(n))}
                        last_page={lastPage}
                        perPage={per_page}
                        loading={loading}
                        onRefresh={() => fetchData()} 
                        perPageCustomList={[3,50,100,250,500]}
                        exportCsv={exportCsv}
                    />
                    <ButtonStyled className={'btn-blue-filled'} onClick={() => setAddModal({opened: true, data: null})}>Add Transaction</ButtonStyled>
                </div>
            </div>
            <Grid
                loading={loading}
                rowData={tableData}
                columnDefs={colDefs}
                rowHeight={40}
                domLayout='autoHeight'
                fixedScroll={true}
                pinnedBottomRowData={loading || tableData.length === 0 ? undefined : [sumData]}
            />
            <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => setAlertModal({...alertModal, opened: false})}>
                {alertModal.message}
            </AlertModal>
            <Modal opened={addModal.opened} closeModal={() => setAddModal({data: null, opened: false})} title={'Add Transaction'}>
                <AddTransactionModal
                    affiliates={affiliates}
                    setAlertModal={setAlertModal} 
                    fetchData={fetchData}
                    closeModal={() => setAddModal({data: null, opened: false})}
                />
            </Modal>
        </PartnerTransactionsWrapper>
    )
}

export default PartnerTransactions;