import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    max-width: 704px;
`

export const ModalHeaderContent = styled.div`
  padding-bottom: 5px;
  p {
    margin-bottom: 14px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    strong {
      font-family: ${({theme}) => theme.fonts.AvenirRoman};
      span {
        color: ${({theme}) => theme.colors.performBlue[6]};
      }
    }
  }
`

export const InfoBox = styled.div`
  color: ${({theme}) => theme.colors.information[7]};
  display: flex;
  align-items: center;

  i {
    color: ${({theme}) => theme.colors.information[6]};
    font-size: 24px;
    margin-right: 18px;
  }

`

export const ModalHeader = styled.div`
    padding-bottom: 5px;
  p {
    margin-bottom: 14px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    strong {
      font-family: ${({theme}) => theme.fonts.AvenirRoman};
      span {
        color: ${({theme}) => theme.colors.performBlue[6]};
      }
    }
  }
`

export const TablePanel = styled.div`
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 8px;
`
export const TablePanelHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.coolGray[1]};

  .headerTitle {
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[5]};
  }
`
export const TablePanelBody = styled.div`
  padding: 16px;

`
export const Optional = styled.span`
  color : ${({theme}) => theme.colors.deepBlue[4]};
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
`
