import React, {ChangeEvent, useEffect, useState} from 'react'
import _ from "lodash";
import Input from "../../../../../common/components/input/Input";
import {useAppSelector, useAppDispatch, useFeatureFlags} from "../../../../../common/hooks";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import ReactDOMServer from "react-dom/server";
import {setOffer, setValidation} from "../create-offer.slice";
import {StyledSelect} from "../../../../../common/components/select/Select";

import PayoutModifiers from "./PayoutModifiers";
import {
    CONVERSION_PAGE_URL,
    INVALID_URL_MSG,
    OFFER_DESCRIPTION_TOOLTIP, PAYOUT_DESCRIPTION, PAYOUT_TYPE,
    URL_PATTERN
} from "../shared-components/consts";

import {PostbackWrapper, PostbackParamButton} from "../layout/main/Main.style"
import {Tooltip} from "../shared-components/Shared";
import ReactTooltip from "react-tooltip";

interface Props {

}

const MainConversion: React.FC<Props> = () => {
    const dispatch = useAppDispatch()
    
    const {offer, validation} = useAppSelector(state => state.createOffer)
    
    const payoutModifiersFlag = useFeatureFlags('payout_modifiers', offer.niche ? offer.niche.network_id : undefined)
    const domainTrackingFlag = useFeatureFlags('domain-tracking-code', offer.niche ? offer.niche.network_id : undefined)
    const [init, setInit] = useState(true)
    
    useEffect(() => {
        ReactTooltip.rebuild()
    })
    
    const handleConversionPageChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setOffer({
            campaign_tracking_settings: {
                ...offer.campaign_tracking_settings,
                conversion_page_url: e.target.value
            }
        }))
        
        // Field Validations
        if ((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking & Redirectless
            || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem === 'google-analytics') // Offer Type = Clicks & Tracking System = Google Analytics
        ) {
            // required
            if (e.target.value &&
                e.target.value!.length > 0) {
                if (!URL_PATTERN.test(e.target.value!) && validation.validateConversionPage !== INVALID_URL_MSG) {
                    dispatch(setValidation({
                        validateConversionPage: INVALID_URL_MSG
                    }))
                } 
                if(URL_PATTERN.test(e.target.value!) 
                    && validation.validateConversionPage !== undefined) {
                    dispatch(setValidation({validateConversionPage: undefined}))
                }
            } else {
                dispatch(setValidation({validateConversionPage: "Conversion Page URL is required"}))
            }
        }else {
            // optional
            if (e.target.value &&
                e.target.value!.length > 0) {
                if (!URL_PATTERN.test(e.target.value!) 
                    && validation.validateConversionPage !== INVALID_URL_MSG) {
                    dispatch(setValidation({
                        validateConversionPage:INVALID_URL_MSG
                    }))
                } 
                if(URL_PATTERN.test(e.target.value!) && validation.validateConversionPage !== undefined) {
                    dispatch(setValidation({validateConversionPage: undefined}))
                }
            } else {
                dispatch(setValidation({validateConversionPage: undefined}))
            }
        }
    }
    
    const handleValidateConversionPageOnBlur = () => {
        if ((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
            || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem === 'google-analytics') // Offer Type = Clicks & Tracking System = Google Analytics
        ) {
            if (offer.campaign_tracking_settings.conversion_page_url === null 
                || offer.campaign_tracking_settings.conversion_page_url!.length === 0) {
                dispatch(setValidation({
                    validateConversionPage: "Conversion Page URL is required"
                }))
            }
        }
    }
    
    const handleConversionPostbackUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setOffer({
            ...offer,
            campaign_tracking_settings: {
                ...offer.campaign_tracking_settings,
                conversion_tracking_url: e.target.value
            }
        }))
        
        // Field Validations
        if ((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
        ) {
            // required
            if (e.target.value &&
                e.target.value!.length > 0) {
                if (!URL_PATTERN.test(e.target.value!) 
                    && validation.validateConversionPostbackUrl !== INVALID_URL_MSG) {
                    dispatch(setValidation({
                        validateConversionPostbackUrl: INVALID_URL_MSG
                    }))
                } 
                if(URL_PATTERN.test(e.target.value!) && validation.validateConversionPostbackUrl !== undefined) {
                    dispatch(setValidation({validateConversionPostbackUrl: undefined}))
                }
            } else {
                dispatch(setValidation({
                    validateConversionPostbackUrl: "Conversion Postback URL is required"
                }))
            }
        }else {
            // optional
            if (e.target.value &&
                e.target.value!.length > 0) {
                if (!URL_PATTERN.test(e.target.value!) 
                    && validation.validateConversionPostbackUrl !== INVALID_URL_MSG) {
                    dispatch(setValidation({
                        validateConversionPostbackUrl:INVALID_URL_MSG
                    }))
                } 
                if(URL_PATTERN.test(e.target.value!) && validation.validateConversionPostbackUrl !== undefined) {
                    dispatch(setValidation({validateConversionPostbackUrl: undefined}))
                }
            } else {
                dispatch(setValidation({validateConversionPostbackUrl: undefined}))
            }
        }
    }

    const handleConversionPostbackUrlOnBlur = () => {
        if ((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
        ) {
            if (offer.campaign_tracking_settings.conversion_tracking_url === null 
                || offer.campaign_tracking_settings.conversion_tracking_url!.length === 0) {
                dispatch(setValidation({
                    validateConversionPostbackUrl: "Conversion Postback URL is required"
                }))
            }
        }
    }
    
    const handlePostbackParameter = () => {
      switch (offer.trackingSystem) {
            case 'tune':
                return 'transaction_id='
            case 'impact':
                return 'actionid='
            case 'cake':
                return 'reqid='
            case 'everflow':
                return 'transaction_id='
            case 'other':
                return ''
            default:
                return ''
        }
    }
    
    const appendMacro = () => {
        if(offer.campaign_tracking_settings.conversion_tracking_url !== null) {
            const macro = '{trackingclickid}'
            
            let postbackUrl:string = offer.campaign_tracking_settings.conversion_tracking_url
            
            let temp = handlePostbackParameter()
            
            let postbackParam = temp ? temp : ''
            
            // console.log('trackingSystem', trackingSystem)
            // console.log('postbackParam', postbackParam)
            
            let parameterIndex = postbackUrl.indexOf(postbackParam) + postbackParam.length
            
            postbackUrl = postbackUrl.slice(0, parameterIndex) + macro + postbackUrl.slice(parameterIndex)
            
            if(postbackUrl.includes(postbackParam) && offer.trackingSystem) {
                dispatch(setOffer({
                    ...offer,
                    campaign_tracking_settings: {
                        ...offer.campaign_tracking_settings,
                        conversion_tracking_url: postbackUrl
                    }
                }))
            }
        }
    }
    
    const handlePayoutChange = (e: string | undefined) => {
        dispatch(setOffer({
            ...offer,
            cpa: e
        }))
    }
    
    const handleAddPayout = () => {
        dispatch(setOffer({
            ...offer,
            revshare: [...offer.revshare, {id: _.uniqueId('MAIN_REVSHARE_'), name: '', value: ''}]
        }))
    }
    
    const handleRemovePayout = (index: number) => {
        const newPayouts = [...offer.revshare]
        newPayouts.splice(index, 1)
        dispatch(setOffer({
            ...offer,
            revshare: newPayouts
        }))
    }
    
    const handlePayoutDescChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const payout = {...offer.revshare[index]}
        payout.description = e.target.value
        
        const rev = {...offer.revshare[index]}
        rev.name = e.target.value
        
        const newRevshares = [
            ...offer.revshare.map((revshare: any, currIndex: number) => currIndex === index ? rev : revshare)
        ]
        dispatch(setOffer({
            ...offer,
            revshare: [...newRevshares]
        }))
    }
    
    const handlePayoutValueChange = (e: string | undefined, index: number) => {
        const rev = {...offer.revshare[index]}
        rev.value = e
        
        const newRevshares = [
            ...offer.revshare.map((revshare: any, currIdex: number) => currIdex === index ? rev : revshare)
        ]
        
        dispatch(setOffer({
            ...offer,
            revshare: [...newRevshares]
        }))
    }
    
    const payoutTypeOptions = [
        {
            label: 'Standard',
            value: 0
        },
        {
            label: 'Revshare',
            value: 1
        }
    ]
    
    const handlePayoutTypeSelect = (option:any) => {
        if(option.value == 1) {
            dispatch(setOffer({
                ...offer,
                is_revshare: true
            }))
        }else {
            dispatch(setOffer({
                ...offer,
                is_revshare: false
            }))
        }
    }
    
    useEffect(()=> {
        if(offer.detailed_pricing_model === 'cpe') {
            if(!offer.cpa) {
                dispatch(setOffer({
                    cpa: '0.00'
                }))
            }

        }
    },[offer.mobile_type])
    
    return (
        <>
            {offer.detailed_pricing_model === 'cpc' &&
                <div className={'inner-panel-body-description'}>
                    Setting a target cost per action helps set an ideal success metric for click to conversion rate.
                </div>
            }
            {offer.detailed_pricing_model === 'cpe' &&
                <div className={'inner-panel-body-description'}>
                    Adding a payout for the install on an App Engagement campaign is optional.
                </div>
            }
            <div className="form-group" 
                 style={!offer.is_revshare ? {marginBottom: 0} : undefined}>
                {(offer.detailed_pricing_model !== 'cpc' && (offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe')) &&
                    <StyledSelect
                        selectWrapperClassname={'form-control'}
                        isSearchable={false}
                        defaultValue={offer.is_revshare ? {label: 'Revshare', value: 1} : {label: 'Standard', value: 0}}
                        selectLabel={
                            <>Payout Type {Tooltip(214, PAYOUT_TYPE)}</>
                        }
                        options={payoutTypeOptions}
                        onChange={handlePayoutTypeSelect}
                        classNamePrefix={'styled-select'}
                    />
                }
                {!offer.is_revshare &&
                    <CurrencyInput
                        wrapperClassName={`form-control${offer.detailed_pricing_model === 'cpc' || (offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') ? '' : ' form-control-payout'}`}
                        prefix={'$'}
                        value={offer.cpa}
                        onValueChange={(e) => handlePayoutChange(e)}
                        placeholder={'$0.00'}
                        disabled={offer.is_revshare}
                        decimalScale={2}
                        label={
                            <>{offer.detailed_pricing_model === 'cpc' ?
                                <>Target CPA <span className={"optional"}>(optional)</span></> : 'Payout'}
                            </>
                        }
                    />
                }
            </div>
            {offer.is_revshare && <>
                {offer.revshare.map((revshare: any, index: number) => {
                    return (
                        <div 
                            key={revshare.id} 
                            className="form-group" 
                            style={{margin: offer.revshare?.length === index + 1 ? 0 : ''}}>
                            <Input
                                value={revshare.name}
                                label={
                                    <>Payout Description
                                        <span className={"optional"}>(optional)</span>
                                        {Tooltip(154, PAYOUT_DESCRIPTION)}
                                    </>
                                }                                
                                wrapperClassName={'form-control'}
                                placeholder={'Enter a description of what this payout is for'}
                                onChange={(e) => handlePayoutDescChange(e, index)}
                            />
        
                            <CurrencyInput
                                wrapperClassName={'form-control form-control-payout'}
                                prefix={'$'}
                                label={<>Payout</>}
                                value={revshare.value}
                                onValueChange={(e) => handlePayoutValueChange(e, index)}
                                placeholder={'$0.00'}
                                decimalScale={2}
                            />
                            <button 
                                className={'btn-add-remove-payout'}
                                onClick={index === 0 ? handleAddPayout : ()=> handleRemovePayout(index)}>
                                <i className={index === 0 ? 'ico-PlusCircleFilled' : 'ico-Exit'}/>
                            </button>
                        </div>
                    )
                })}
            </>}
            
            {(offer.detailed_pricing_model !== 'ppc' && (offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe' && domainTrackingFlag)) &&
                <div 
                    className={'form-group'} 
                    style={{margin: '32px 0 0'}}>
                    <Input 
                        value={offer.campaign_tracking_settings.conversion_page_url 
                            ? offer.campaign_tracking_settings.conversion_page_url 
                            : ''
                        }
                        onChange={(e)=> handleConversionPageChange(e)}
                        wrapperClassName={'form-control'}
                        placeholder={'Enter the URL for the page that this conversion takes place'}
                        error={validation.validateConversionPage}
                        onBlur={handleValidateConversionPageOnBlur}
                        label={
                            <>Conversion Page
                                {((offer.direct_linking === false && offer.legacy_tracking) 
                                        || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem !== 'google-analytics')) &&
                                    <span className={"optional"}>(optional)</span>
                                }
                                {Tooltip(232, CONVERSION_PAGE_URL)}
                            </>
                        }
                    />
                </div>
            }
            
            {/*Redirectless*/}
            {(offer.direct_linking === true && offer.legacy_tracking === false && domainTrackingFlag) && <>
                {(offer.trackingSystem !== 'none' && offer.trackingSystem !== 'google-analytics') && <>
                    <PostbackWrapper style={{margin: '32px 0 0'}} 
                        className={offer.trackingSystem + ''}>
                        <Input
                            value={offer.campaign_tracking_settings.conversion_tracking_url 
                                ? offer.campaign_tracking_settings.conversion_tracking_url : ''}
                            onChange={(e)=> handleConversionPostbackUrlChange(e)}
                            onBlur={handleConversionPostbackUrlOnBlur}
                            error={validation.validateConversionPostbackUrl}
                            wrapperClassName={'form-control'}
                            placeholder={
                                offer.trackingSystem !== 'other' ? 
                                    `Enter the postback URL for this conversion provided by ${offer.trackingSystem!.charAt(0).toUpperCase() + offer.trackingSystem!.slice(1)}` 
                                    : `Enter the postback URL for this conversion provided by your tracking system`
                            }
                            label={<>Conversion Postback URL</>}
                        />
                        <div>
                            {offer.trackingSystem !== 'other' ? 
                                <div className={'postback-url-description'}>
                                    This is the URL provided by<span className={"tracking-system-span"}> {offer.trackingSystem} </span> 
                                    that your server fires when this conversion happens to provide 
                                    them with the conversion info. After pasting the URL use the xbutton 
                                    below to place the {'{trackingclickid}'} macro after {handlePostbackParameter()} parameter. 
                                </div> : 
                                <div className={'postback-url-description'}>
                                    This is the URL provided by your tracking system that your server fires when this 
                                    conversion happens to provide them with the conversion info. After pasting the URL 
                                    use the button below to place the {'{trackingclickid}'} macro after the parameter 
                                    your tracking system uses for click id / transaction id.
                                </div>
                            }
                            <div>
                                <PostbackParamButton onClick={()=> appendMacro()} className={'tracking-system-btn'}>
                                    <i className="material-symbols-outlined">add</i>
                                    {'{trackingclickid}'}
                                </PostbackParamButton>
                            </div>
                        </div>
                    </PostbackWrapper>
                </>}
            </>}
            
            {/*Payout Modifiers*/}
            {(!offer.is_revshare && payoutModifiersFlag) &&
                <PayoutModifiers 
                    modifiers={offer.payout_modifiers} 
                    payout={offer.cpa}
                    setModifiers={
                        (modifiers) => dispatch(setOffer({
                            ...offer,
                            payout_modifiers: [...modifiers.map((mod:any) => ({...mod, campaign_id: offer.id}))]
                        })) 
                    }
                />
            }
        </>
    )
}

export default MainConversion