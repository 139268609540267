import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {SelectOption, StyledSelect} from "../../../../../common/components/select/Select";
import {setOffer, setSelectedBudgetAmountType} from "../create-offer.slice";
import styled from "styled-components";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {
    BUDGET_AMOUNT_TYPES, BUDGET_TYPES, DOLLAR_CURRENCY,
    TEST_BUDGET_TYPES
} from "../shared-components/consts";


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    > div {
        flex: 1;
    }
`

const OfferBudgetTestGroup: React.FC = ({}) => {
    const dispatch = useAppDispatch()
    const {
        config
    } = useAppSelector(state => state.auth)
    const {offer, selectedBudgetAmountType} = useAppSelector(state => state.createOffer)
    
    const [testBudget, setTestBudget] = useState<string>('0')
    const [testBudgetType, setTestBudgetType] = useState<SelectOption>(TEST_BUDGET_TYPES[3])
    
    const handleOnChange = (e: any) => {
        setTestBudgetType(e)
        dispatch(setOffer({
            link_test_budget_type: e.value.toUpperCase(),
        }))
    }
    
    const handleTestBudgetIncrease = () => {
        if (offer.budget_value_type === 0) {
            dispatch(setOffer({
                link_test_budget_value: parseFloat((offer.link_test_budget_value + .01).toFixed(2))
            }))
            // setTestBudget((prevState) => {
            //     return (parseFloat(prevState) + .01).toFixed(2)
            // })
        } else {
            dispatch(setOffer({
                link_test_budget_value: parseFloat((offer.link_test_budget_value + 1).toFixed(2))
            }))
            // setTestBudget((prevState) => {
            //     return (parseFloat(prevState) + 1).toString()
            // })
        }
    }
    
    const handleTestBudgetDecrease = () => {
        if (offer.budget_value_type === 0) {
            if (offer.link_test_budget_value > 0) {
                dispatch(setOffer({
                    link_test_budget_value: parseFloat((offer.link_test_budget_value - .01).toFixed(2))
                }))
            }
        } else {
            if (offer.link_test_budget_value > 0) {
                dispatch(setOffer({
                    link_test_budget_value: parseFloat((offer.link_test_budget_value - 1).toFixed(2))
                }))
            }
        }
    }
    
    const handleTestBudgetChange = (e: string | undefined) => {
        if(e){
            dispatch(setOffer({link_test_budget_value: parseFloat(e)}))
        }
        else{
            dispatch(setOffer({link_test_budget_value: 0}))
        }
    }
    
    const handleTestBudgetTypeChange = (option: SelectOption) => {
        if (option.value === 0) {
            dispatch(setOffer({
                budget: parseFloat((offer.link_test_budget_value).toFixed(2)),
                budget_value_type: option.value
            }))
        } else {
            dispatch(setOffer({
                budget: Math.floor((offer.link_test_budget_value)),
                budget_value_type: option.value
            }))
        }
        // dispatch(setSelectedBudgetAmountType(option))
        // dispatch(setOffer({budget_type: option.value}))
    }
    
    return (
        <Wrapper>
            <StyledSelect
                // @ts-ignore
                value={TEST_BUDGET_TYPES.find(type => type.value === offer.link_test_budget_type.toLocaleLowerCase())}
                placeholder={"None"}
                selectLabel={<>Test Budget <span className={"optional"}>(optional)</span></>}
                options={TEST_BUDGET_TYPES}
                onChange={handleOnChange}
            />
            {
                offer.link_test_budget_type?.toLowerCase() !== 'disabled' &&
                <>
                    {
                        (offer.detailed_pricing_model !== 'cpc' && offer.budget_type === 'unlimited') &&
                        <StyledSelect options={BUDGET_AMOUNT_TYPES}
                            // @ts-ignore
                                      value={BUDGET_AMOUNT_TYPES.find(type => type.value === offer.budget_value_type)}
                                      placeholder={DOLLAR_CURRENCY}
                                      selectLabel={"Currency"}
                                      onChange={(e) => handleTestBudgetTypeChange(e as SelectOption)}
                                      isOptionDisabled={(option) => !!option.disabled}
                        />
                    }
                    <div style={offer.detailed_pricing_model === 'cpc' ? {maxWidth: 150} : undefined}>
                        <CurrencyInput
                            label={"Amount"}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            decimalsLimit={2}
                            step={offer.budget_value_type === 0 ? .01 : 1}
                            placeholder={offer.budget_value_type === 0 ? '0.00' : '0'}
                            prefix={offer.budget_value_type === 0 ? '$' : ''}
                            value={offer.link_test_budget_value}
                            allowDecimals={offer.budget_value_type === 0}
                            allowNegativeValue={false}
                            inputControl={{up: handleTestBudgetIncrease, down: handleTestBudgetDecrease}}
                            onValueChange={(e) => handleTestBudgetChange(e)}
                        />
                    </div>
             


                </>
            }
        </Wrapper>
    );
};

export default OfferBudgetTestGroup