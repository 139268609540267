import React, {useState, useRef, useEffect} from 'react'

import SelectFilterDropdown from '../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import IncludeExcludeFilter from "../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {
    useAdvertisersFilter,
} from "../../../../../../common/hooks/filters";

import {removeAllFilters, setFilter, singleSelect} from "../../../../../../store/filters.slice";
import {ButtonStyled} from "../../../../../../common/styled-components";
import {
    FilterID, IAdvertisersFilter,
    IAffiliatesFilter,
    IVendorIdFilter
} from "filters";

import {Wrapper} from "./Filters.style";

import ClickAgainToConfirmTooltip
    from "../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip";
import {
    useAppDispatch,
    useAppSelector,
    useOnClickOutside
} from "../../../../../../common/hooks";
import {useTransactionTypeFilter} from "../../../../../../common/hooks/filters/useTransactionTypeFilter";
import {useTransactionStatusesFilter} from "../../../../../../common/hooks/filters/useTransactionStatusesFilter";
import {setSummarize} from "../MarketersTransactions.slice";

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'advertiser',
        value: 'Marketer'
    },
    {
        id: "transaction-type",
        value: "Type"
    },
    {
        id: 'transaction-statuses',
        value: 'Status'
    }
]

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {

    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    const advertiserFilter = useAdvertisersFilter('admin/billing/marketer-transactions', 'company_name', 50);
    const transactionTypeFilter = useTransactionTypeFilter('admin/billing/marketer-transactions');
    const transactionStatusFilter = useTransactionStatusesFilter('admin/billing/marketer-transactions')

    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const handleTypeSelect = (type: any) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/marketer-transactions',
            filterId: 'transaction-type',
            data: type
        }))
    }

    const handleStatusSelect = (status: any) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/marketer-transactions',
            filterId: 'transaction-statuses',
            data: status
        }))
    }

    const handleAdvertiserSelect = (advertiser: IAdvertisersFilter) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/marketer-transactions',
            filterId: 'advertiser',
            data: advertiser
        }))
    }

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case 'advertiser':
                return (
                    <IncludeExcludeFilter
                        selectedFilterText={
                            advertiserFilter.selectedData[0] ?
                                advertiserFilter.selectedData[0].company +
                                ` (${advertiserFilter.selectedData[0].id})`
                                : ''
                        }
                        filter={advertiserFilter}
                        filterTitle={'Marketer'}
                        hasInclude={false}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {advertiserFilter.data.map(advertiser => {
                            return <DropdownItem
                                key={advertiser.id}
                                selected={
                                    advertiserFilter.selectedData.length > 0 &&
                                    advertiserFilter.selectedData[advertiserFilter.selectedData.length - 1].id === advertiser.id
                                }
                                onClick={() => handleAdvertiserSelect(advertiser)}
                            >
                                {advertiser.company} ({advertiser.id})
                            </DropdownItem>
                        })}
                    </IncludeExcludeFilter>
                )
            case "transaction-type":
                return (
                    <IncludeExcludeFilter
                        selectedFilterText={transactionTypeFilter.selectedData[0] ? transactionTypeFilter.selectedData[0].value : ''}
                        filter={transactionTypeFilter}
                        filterTitle={'Type'}
                        hasInclude={false}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {transactionTypeFilter.data.map(option => {
                            if(option.id !== 'REFERRAL_CREDIT') {
                                return (
                                    <DropdownItem
                                        key={option.id}
                                        selected={
                                            transactionTypeFilter.selectedData.length > 0 &&
                                            transactionTypeFilter.selectedData[transactionTypeFilter.selectedData.length - 1].id === option.id
                                        }
                                        onClick={() => handleTypeSelect(option)}
                                    >
                                        {option.value}
                                    </DropdownItem>
                                )
                            }
                            return null
                        }
                        )
                        }
                    </IncludeExcludeFilter>
                )
            case "transaction-statuses":
                return (
                    <IncludeExcludeFilter
                        selectedFilterText={transactionStatusFilter.selectedData[0] ? transactionStatusFilter.selectedData[0].value : ''}
                        filter={transactionStatusFilter}
                        filterTitle={'Status'}
                        hasInclude={false}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {transactionStatusFilter.data.map(option =>
                            <DropdownItem
                                key={option.id}
                                selected={
                                    transactionStatusFilter.selectedData.length > 0 &&
                                    transactionStatusFilter.selectedData[transactionStatusFilter.selectedData.length - 1].id === option.id
                                }
                                onClick={() => handleStatusSelect(option)}
                            >
                                {option.value}
                            </DropdownItem>
                        )
                        }
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }
    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/billing/marketer-transactions'))
            dispatch(setSummarize(false))
        }
    }


    return <Wrapper>
        <i className={'ico-Filters'}/>
        {filters.filter(
            (filter: any) =>
                filter.pageID === 'admin/billing/marketer-transactions')[0]?.filters
                    .map((filter: any) => <div key={filter.id}>{renderFilter(filter.id)}</div>)}
        <SelectFilterDropdown allFilters={filterList} pageId={'admin/billing/marketer-transactions'}/>
        {filters.filter((filter: any) => filter.pageID === 'admin/billing/marketer-transactions')[0]?.filters.length > 1 &&
            <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                {
                    displayClearFilterTooltip
                    &&
                    <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
                }
                Clear All Filters
            </ButtonStyled>}
    </Wrapper>
}

export default Filters