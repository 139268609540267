import { useEffect, useMemo, useState } from "react";
import Grid from "../../../../../../../common/components/grid/Grid"
import { useColumnsOptionHook } from "../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import SortableHeader from "../../../../../../../common/components/table/sortable-header/SortableHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/hooks";
import { formatNumberToCurrency } from "../../../../../../../common/utils";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../NetworkView.service";
import { setAccountManagersPartnersCurrentPage, setAccountManagersPartnersPaginationColumnsList, setAccountManagersPartnersPerPage, setAccountManagersPartnersSortDirection, setPagination, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../NetworkView.slice";
import { NetworkViewSortIconDown, NetworkViewSortIconUp, TopPerformersTable } from "../TopPerformers.style";
import { allAccountManagersPaginationColumns } from "../topPerformersLists";

const TopPerformersPartnerAccountManagers = () => {

    const {
        accountManagersPartnersPaginationColumnsList,
        accountManagersPartnersPerPage,
        accountManagersPartnersCurrentPage,
        accountManagersPartnersSort,
        topPerformersLoading,
        topPerformersTableData,
        topPerformersTableSum,
        selectedManagers,
        includeOrExclude, filterType
    } = useAppSelector(state => state.networkView);

    const {token} = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch();

    const sortHandler = (key: string, event?: any,) => { 
        dispatch(setAccountManagersPartnersSortDirection({
            direction: accountManagersPartnersSort.by !== key ? "asc" : accountManagersPartnersSort.direction === "desc" ? "asc" : "desc",
            by: key
        }))
    }

    const commaTransform = (value: string) => {
        return Number(value).toLocaleString('en-US')
    }

    const allAccountManagersPartnersColumnDefs: any = [
        {
            colId: 1, 
            headerComponent: (data: any) =>  {
               return <SortableHeader 
                            keyId={'name'} 
                            sortDirection={accountManagersPartnersSort.direction} 
                            sortedBy={accountManagersPartnersSort.by} 
                            onClick={(event: any) => !data.column.isMoving() && sortHandler("name", event)}
                            icon={accountManagersPartnersSort.by === "name"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                        >ACCOUNT MANAGER</SortableHeader>
            },
            cellRenderer: (data:any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.name} {data.data.lastname}</span>
            }
        },
        {
            colId: 2, 
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){            
                    return <span>{commaTransform(data.data.clicks)}</span>;
                }
                return <span>{commaTransform(data.data.clicks)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'clicks'} 
                             sortDirection={accountManagersPartnersSort.direction} 
                             sortedBy={accountManagersPartnersSort.by} 
                             onClick={(event: any) => !data.column.isMoving() && sortHandler("clicks", event)}
                             icon={accountManagersPartnersSort.by === "clicks"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CLICKS</SortableHeader>
             },
        
        },
        {
            colId: 3,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{commaTransform(data.data.conversions)}</span>;
                }
                return <span>{commaTransform(data.data.conversions)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'conversions'} 
                             sortDirection={accountManagersPartnersSort.direction} 
                             sortedBy={accountManagersPartnersSort.by} 
                             onClick={(event: any) => !data.column.isMoving() && sortHandler("conversions", event)}
                             icon={accountManagersPartnersSort.by === "conversions"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CONVERSIONS</SortableHeader>
             },
        
        },
        {
            colId: 4,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{data.data.cvr.replaceAll('.', ',')}%</span>;
                }
                return <span>{data.data.cvr.replaceAll('.', ',')}%</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'cvr'} 
                             sortDirection={accountManagersPartnersSort.direction} 
                             sortedBy={accountManagersPartnersSort.by} 
                             onClick={(event: any) => !data.column.isMoving() && sortHandler("cvr", event)}
                             icon={accountManagersPartnersSort.by === "cvr"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CVR</SortableHeader>
             },
        
        },
        {
            colId: 5,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.revenue)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.revenue)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'revenue'} 
                             sortDirection={accountManagersPartnersSort.direction} 
                             sortedBy={accountManagersPartnersSort.by} 
                             onClick={(event: any) => !data.column.isMoving() && sortHandler("revenue", event)}
                             icon={accountManagersPartnersSort.by === "revenue"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >REVENUE</SortableHeader>
             },
        
        },
        {
            colId: 6,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.profit)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.profit)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'profit'} 
                             sortDirection={accountManagersPartnersSort.direction} 
                             sortedBy={accountManagersPartnersSort.by} 
                             onClick={(event: any) => !data.column.isMoving() && sortHandler("profit", event)}
                             icon={accountManagersPartnersSort.by === "profit"  ? accountManagersPartnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >PROFIT</SortableHeader>
             },
        
        },
        
    ];

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));


        const params = {
            analyze_by: "top_partner_acc_managers",
            page: accountManagersPartnersCurrentPage,
            per_page: accountManagersPartnersPerPage,
            sort_by: accountManagersPartnersSort.by,
            order: accountManagersPartnersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_sale_exec'] = true
            }
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);

        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setAccountManagersPartnersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setAccountManagersPartnersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false))
    }

    const accountManagerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setAccountManagersPartnersPaginationColumnsList(list));
    }

    const [accountManagersPartnersColumnDefs, setAccountManagersPartnersColumnDefs] = useState(allAccountManagersPartnersColumnDefs);
    const [colWidths, setColWidths] = useState(allAccountManagersPartnersColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId}
    }));

    const [enableAllAccountManagersPartnersHandler, disableAllAccountManagersPartnersHandler, enableDisableAccountManagersPartnersColumnHandler]: any = useColumnsOptionHook(
        allAccountManagersPartnersColumnDefs, 
        accountManagersPartnersColumnDefs, 
        setAccountManagersPartnersColumnDefs,
        allAccountManagersPaginationColumns,
        accountManagersPartnersPaginationColumnsList, 
        accountManagerPaginationColumnsListHandler
    );

    useEffect(() => {
        const checkedColumns = accountManagersPartnersPaginationColumnsList.filter((item: any) => item.checked);
        if (accountManagersPartnersPaginationColumnsList.length === 6) {
            if(accountManagersPartnersPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allAccountManagersPartnersColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                })
                setAccountManagersPartnersColumnDefs(newDefs);
            } else {
                setAccountManagersPartnersColumnDefs([]);
            }
        }
        
    }, [accountManagersPartnersPaginationColumnsList, accountManagersPartnersSort])

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allAccountManagersPartnersColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allAccountManagersPaginationColumns.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });
        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })
        
        setColWidths(widths);
        setAccountManagersPartnersColumnDefs(widthToCol);
        const rest = accountManagersPartnersPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        accountManagerPaginationColumnsListHandler([...newPaginCol, ...rest]);
    }

    const defaultColDef = useMemo(() => ({
        resizable: true
    }), []);

    useEffect( () => {
        token && fetchData();
    }, [accountManagersPartnersPerPage, accountManagersPartnersCurrentPage, accountManagersPartnersSort]);


    return(
        <TopPerformersTable>
            <Grid 
                loading={topPerformersLoading}
                columnDefs={accountManagersPartnersColumnDefs}
                defaultColDef={defaultColDef}
                rowData={topPerformersTableData}
                rowHeight={40}
                domLayout='autoHeight'
                enableAllColumns={enableAllAccountManagersPartnersHandler}
                pinnedBottomRowData={topPerformersLoading || topPerformersTableData.length === 0 ? undefined : topPerformersTableSum}
                fixedHeader={false}
                onDragStopped={onColumnDrop}
            />
        </TopPerformersTable>
    )
};

export default TopPerformersPartnerAccountManagers;