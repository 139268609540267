import styled from "styled-components";

export const ButtonStyled = styled.button`
    //defaults
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    background-color: transparent;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 9px 16px;
    font-size: 12px;
    line-height: 1;
    border-radius: 8px;
    user-select: none;
    font-family: ${({theme}) => theme.fonts.AvenirMedium};
    color: ${({theme}) => theme.colors.deepBlue["4"]};
    transition: all .2s ease-in-out;

    &[disabled] {
        color: ${({theme}) => theme.colors.coolGray[4]};
        pointer-events: none;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.coolGray[2]};
        border-color: ${({theme}) => theme.colors.coolGray[2]};
    }

    &:active {
        color: ${({theme}) => theme.colors.deepBlue[6]};
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
        border-color: ${({theme}) => theme.colors.deepBlue[2]};
    }

    //sizing
    &.btn-size-lg {
        font-size: 16px;
    }

  &.btn-size-sm {
    padding: 5px 12px;
  }
    &.btn-block {
        width: 100%;
    }
    // border-radius
    &.full-radius {
        border-radius: 100px;
    }

    //color variations todo
    &.btn-orange-filled {
        background-color: ${({theme}) => theme.colors.performOrange[3]};
        border-color: ${({theme}) => theme.colors.performOrange[3]};
        color: #ffffff;

        &:hover {
            background-color: ${({theme}) => theme.colors.performOrange[4]};
            border-color: ${({theme}) => theme.colors.performOrange[4]};
        }

        &:active {
            background-color: ${({theme}) => theme.colors.performOrange[6]};
            border-color: ${({theme}) => theme.colors.performOrange[6]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }

    &.btn-red-filled {
        background-color: ${({theme}) => theme.colors.critical[3]};
        border-color: ${({theme}) => theme.colors.critical[3]};
        color: #ffffff;

        &:hover {
            background-color: ${({theme}) => theme.colors.performOrange[4]};
            border-color: ${({theme}) => theme.colors.performOrange[4]};
        }

        &:active {
            background-color: ${({theme}) => theme.colors.performOrange[6]};
            border-color: ${({theme}) => theme.colors.performOrange[6]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }

    &.btn-blue-filled {
        background-color: ${({theme}) => theme.colors.performBlue[6]};
        border-color: ${({theme}) => theme.colors.performBlue[6]};
        color: #ffffff;

        &:hover {
            background-color: ${({theme}) => theme.colors.performBlue[7]};
            border-color: ${({theme}) => theme.colors.performBlue[7]};
        }

        &:active {
            background-color: ${({theme}) => theme.colors.performBlue[8]};
            border-color: ${({theme}) => theme.colors.performBlue[8]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};

        }
    }

    &.btn-orange-outlined {
        background-color: ${({theme}) => theme.colors.pureWhite};
        border-color:  ${({theme}) => theme.colors.performOrange[3]};
        color: ${({theme}) => theme.colors.performOrange[3]};

        &:hover {
            background-color: ${({theme}) => theme.colors.performOrange[1]};
        }

        &:active {
            background-color: ${({theme}) => theme.colors.performOrange[2]};
            border-color: ${({theme}) => theme.colors.performOrange[6]};
            color: ${({theme}) => theme.colors.performOrange[6]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }
        &.btn-gray-outlined {
        background-color: ${({theme}) => theme.colors.pureWhite};
        border-color:  ${({theme}) => theme.colors.coolGray[3]};
        color: ${({theme}) => theme.colors.deepBlue[4]};

        &:hover {
            background-color: ${({theme}) => theme.colors.coolGray[2]};
            border-color:  ${({theme}) => theme.colors.deepBlue[2]};
            color: ${({theme}) => theme.colors.deepBlue[4]}
        }

        &:active {
            background-color: ${({theme}) => theme.colors.deepBlue[2]};
            border-color: ${({theme}) => theme.colors.deepBlue[3]};
            color: ${({theme}) => theme.colors.deepBlue[6]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }

    &.btn-red-outlined {

    }

    &.btn-blue-outlined {
        background-color: white;
        border-color: ${({theme}) => theme.colors.performBlue[6]};
        color: ${({theme}) => theme.colors.performBlue[6]};

        &:hover {
            background-color: ${({theme}) => theme.colors.performBlue[1]};
            border-color: ${({theme}) => theme.colors.performBlue[6]};
            color: ${({theme}) => theme.colors.performBlue[6]};
        }

        &:active {
            background-color: ${({theme}) => theme.colors.performBlue[2]};
            border-color: ${({theme}) => theme.colors.performBlue[7]};
            color: ${({theme}) => theme.colors.performBlue[7]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }

    &.btn-default-outlined {
        background-color: white;
        border-color: ${({theme}) => theme.colors.coolGray[3]};
        color: ${({theme}) => theme.colors.deepBlue[4]};

        &:hover {
            background-color: ${({theme}) => theme.colors.coolGray[2]};

        }

        &:active {
            background-color: ${({theme}) => theme.colors.deepBlue[2]};
            border-color: ${({theme}) => theme.colors.deepBlue[4]};
            color: ${({theme}) => theme.colors.deepBlue[6]};
        }

        &[disabled] {
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            border-color: ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.coolGray[5]};
        }
    }


    //additional styles
    &.btn-cancel {
        padding-left: 0;
        padding-right: 0;

        :hover {
            color: ${({theme}) => theme.colors.deepBlue[7]};
        }

        :active {
            color: ${({theme}) => theme.colors.deepBlue[9]};
        }

        :hover, :active {
            background: transparent;
            border-color: transparent;
        }
    }

    &.btn-discover-results {
        position: relative;
        color: white;
        background-image: linear-gradient(to right, #1D728D 0, #00F0FF 50%, #1D728D 100%);
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        background-size: 300% auto;
        overflow: hidden;

        :before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            box-shadow: 0px 0px 16px rgba(114, 230, 253, 0.62);
        }

        :hover {
            background-image: linear-gradient(to right, rgb(58, 210, 220) 0, rgba(29, 114, 141, 1) 50%, rgba(58, 210, 220, 1) 100%);
            //background-size: 300% auto;
        }

    :active {
      background: linear-gradient(89.97deg, rgb(75, 203, 203) 0.03%, rgb(3, 84, 96) 99.97%);
      background-size: 300% auto;
      border: 1px solid #25C0D5;
      box-shadow: inset 0 0 0 1px #05daeb;
    }
      &[disabled] {
          background-image: none;
          background-color: ${({theme}) => theme.colors.coolGray[3]};
          border-color: ${({theme}) => theme.colors.coolGray[3]};
          color: ${({theme}) => theme.colors.coolGray[5]};
          pointer-events: none;
      }
  }
  
  &.clear-filters{
    background: none;
    border: none;
    padding: 0;
    
    color: ${({theme}) => theme.colors.performOrange[3]};
    
    :hover{
      color: ${({theme}) => theme.colors.performOrange[5]};
    }
    
    :active{
      color: ${({theme}) => theme.colors.performOrange[7]};
    }
  }
    &.btn-uppercase{
        text-transform: uppercase;
    }
  
`;

export const BtnLoadingSpinner = styled.span`
    margin-right: 5px;
    display: inline-flex;
    width: 10px;
    height: 10px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[5]};
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s ease-in-out infinite;
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
