import React, {useMemo, useState} from 'react'
import {format} from "date-fns";
import {ColDef} from "ag-grid-community";
import Grid from "../../../../../../common/components/grid/Grid";
import PartnerPaymentsService, {IFetchPartnerPaymentsResponse} from "../PartnerPayment.service";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import GridRowOptions from "../../../../../../common/components/grid/grid-row-options/GridRowOptions";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import {setSortBy, setSortDirection} from "../PartnerPayments.slice";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import {Advertiser} from "../../../../../../common/models/advertiser.type";
import Modal from "../../../../../../common/components/modal/Modal";
import {CustomModalFooter, DeleteDomainModalBody} from "../PartnerPayments.style";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../../common/styled-components";
import AlertModal from "../../../../../../common/components/alert-modal/AlertModal";

interface Props {
    data: IFetchPartnerPaymentsResponse[],
    loading: boolean,
    selectedRows: IFetchPartnerPaymentsResponse[],
    setSelectedRows: (data: IFetchPartnerPaymentsResponse[]) => void,
    fetchData: () => void
    setEditModal: (a: {opened: boolean, data: IFetchPartnerPaymentsResponse}) => void
}

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const Table: React.FC<Props> = ({data, loading, selectedRows, setSelectedRows, fetchData, setEditModal}) => {
    const {sort_direction, sort_by} = useAppSelector(state => state.partnerPayment)
    const {token} = useAppSelector(state => state.auth)
    const [deleteModal, setDeleteModal] = useState(false)
    const [idToDelete, setIdToDelete] = useState<number | undefined>()
    const [alertModal, setAlertModal] = useState<{
        message: string,
        type: 'error' | 'success',
        opened: boolean
    }>({
        message: '',
        type: 'success',
        opened: false
    })
    const [loadingDeletePayment, setLoadingDeletePayment] = useState(false)
    const dispatch = useAppDispatch()

    const handleRowSelect = (data: IFetchPartnerPaymentsResponse) => {
        if(selectedRows.some(row => row.payment_id === data.payment_id)){
            setSelectedRows(selectedRows.filter(row => row.payment_id !== data.payment_id))
        } else {
            setSelectedRows([...selectedRows, data])
        }
    }

    const handleAllRowsSelect = () => {
        if(selectedRows.length === data.length){
            setSelectedRows([])
        }
        if(selectedRows.length < data.length){
            setSelectedRows(data)
        }
    }

    const handleSort = (direction: 'asc' | 'desc', by: string) => {
        dispatch(setSortBy(by))
        dispatch(setSortDirection(direction))
    }

    const handleLoginAs = (partner: IFetchPartnerPaymentsResponse) => {
        targetOrigin && window.parent.postMessage({id: 7, data: partner}, targetOrigin );
    }

    const deletePayment = async () => {
        try {
            if(idToDelete){
                setLoadingDeletePayment(true)
                await PartnerPaymentsService.deletePayment(token, idToDelete)
                setLoadingDeletePayment(false)
                setDeleteModal(false)
                fetchData()
                setAlertModal({
                    type: 'success',
                    opened: true,
                    message: 'Payment successfully deleted!'
                })
                setIdToDelete(undefined)
            }
        } catch (e) {
            setDeleteModal(false)
            setIdToDelete(undefined)
            setAlertModal({
                type: 'error',
                opened: true,
                message: 'Something went wrong!'
            })
        }
    }

    const handleDelete = (id: number) => {
        setIdToDelete(id)
        setDeleteModal(true)
    }

    const colDef = useMemo<ColDef[]>(() => [
        {
            headerComponent: () => {
                return <Checkbox checked={selectedRows.length === data.length && data.length > 0} onChange={() => handleAllRowsSelect()}/>
            },
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <Checkbox checked={selectedRows.some(row => row.payment_id === data.payment_id)} onChange={() => handleRowSelect(data)}/>
            },
            width: 50,
            resizable: false
        },
        {
            headerComponent: () => {
                return <SortableHeaderV2 keyId={'paid_at'} sortedBy={sort_by} sortDirection={sort_direction} onClick={() => handleSort(sort_direction === 'desc' && sort_by === 'paid_at' ? 'asc' : 'desc', 'paid_at')}>DATE</SortableHeaderV2>
            },
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span>{format(new Date(data.paid_at.replaceAll('-', '/')), 'MM-dd-yyyy')}</span>
            }
        },
        {
            headerName: 'PARTNER ID',
            field: 'affiliate_id'
        },
        {
            headerName: 'PARTNER',
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span onClick={() => handleLoginAs(data)}>{data.partner_name} {data.partner_lastname}</span>
            }
        },
        {
            headerName: 'VENDOR ID',
            field: 'vendor_id'
        },
        {
            headerName: 'COMPANY',
            field: 'partner_company'
        },
        {
            headerName: 'ACCOUNT MANAGER',
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span>{data.account_manager_name} {data.account_manager_lastname}</span>
            }
        },
        {
            headerName: 'AMOUNT',
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span>{formatNumberToCurrency(parseFloat(data.amount))}</span>
            }
        },
        {
            headerName: 'START DATE',
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span>{format(new Date(data.start_date.replaceAll('-', '/')), 'MM-dd-yyyy')}</span>
            }
        },
        {
            headerName: 'END DATE',
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <span>{format(new Date(data.end_date.replaceAll('-', '/')), 'MM-dd-yyyy')}</span>
            }
        },
        {
            headerName: 'NOTES',
            field: 'note'
        },
        {
            cellRenderer: ({data}: {data: IFetchPartnerPaymentsResponse}) => {
                return <GridRowOptions>
                    <DropdownItem
                        onClick={() => {
                            setEditModal({
                                opened: true,
                                data: data
                            })
                        }
                        }
                    >Edit</DropdownItem>
                    <DropdownItem
                        isDelete={true}
                        onClick={() => handleDelete(data.payment_id)}
                    >
                        Delete
                    </DropdownItem>
                </GridRowOptions>
            },
            pinned: 'right',
            width: 50,
            resizable: false
        }
    ], [selectedRows, data.length, sort_by, sort_direction])

    return <>
        <Grid
            loading={loading}
            rowData={data}
            columnDefs={colDef}
            domLayout='autoHeight'
            rowHeight={40}
        />
        <Modal opened={deleteModal} title={'Delete Payment?'} closeModal={() => setDeleteModal(false)}>
            <DeleteDomainModalBody>
                <p>Are you sure you want to delete selected payment?</p>
            </DeleteDomainModalBody>
            <CustomModalFooter>
                <ButtonStyled
                    className={'btn-cancel'}
                    onClick={() => setDeleteModal(false)}>
                    Cancel
                </ButtonStyled>
                <ButtonStyled
                    disabled={loadingDeletePayment}
                    className={'btn-red-filled'}
                    onClick={deletePayment}
                    style={{marginLeft: 30}}>
                    {loadingDeletePayment && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}
                    Yes, delete payment.
                </ButtonStyled>
            </CustomModalFooter>
        </Modal>
        <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => setAlertModal({...alertModal, opened: false})}>
            {alertModal.message}
        </AlertModal>
    </>
}

export default Table