import React, {useCallback, useEffect} from "react";
import {Outlet, useParams, useLocation} from "react-router-dom";

import TagsList from "../../../../common/components/tags-list/TagsList";

import {Wrapper, ContentWrapper} from "./AdvertisersEdit.style";

import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {getEditingUser} from "./AdvertisersEdit.slice";
import {tokenStore} from "../../../../store/auth.slice";

const AdvertisersEdit: React.FC = () => {
    const {editingUser, error, loading} = useAppSelector(state => state.advertisersEdit)
    const token = useAppSelector(tokenStore)
    const {id} = useParams()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const fetchEditingUser = useCallback(async () => {
        id && await dispatch(getEditingUser({token: token, id: parseInt(id)}))
    }, [id, token, dispatch])

    useEffect(() => {
        fetchEditingUser()
    }, [fetchEditingUser])
    return (
        <>
            {location.pathname.split('/')[location.pathname.split('/').length - 1] === 'credit' ? (
                <Outlet/>
            ) : (
                <Wrapper>
                    <TagsList id={editingUser?.id} type={editingUser?.user_type}/>
                    <ContentWrapper>
                        <Outlet/>
                    </ContentWrapper>
                </Wrapper>
            )}
        </>

    )
}

export default AdvertisersEdit