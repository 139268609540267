import styled, {css} from 'styled-components'

interface IChangeMonthButtonProps {
    disabled: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .month{
    padding: 4px 8px;
    border-radius: 8px;
    transition: all .2s ease-in-out;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    line-height: 16px;
    font-size: 12px;
    :hover{
      background-color: ${({theme}) => theme.colors.coolGray[2]};
      cursor: pointer;
    }
    
    :hover:active{
      background-color: ${({theme}) => theme.colors.deepBlue[5]};
      color: ${({theme}) => theme.colors.pureWhite};
      cursor: pointer;
    }
    
    span{
      display: block;
      color: ${({theme}) => theme.colors.deepBlue[4]};
      font-family: ${({theme}) => theme.fonts.AvenirLight};
      font-size: 10px;
      margin-top: 5px;
    }
  }
`

export const MonthChangeButton = styled.div<IChangeMonthButtonProps>`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    transition: all .2s ease-in-out;
    
    ${(props) => props.disabled && css`
      pointer-events: none;
      color: ${({theme}) => theme.colors.deepBlue[1]};
    `}
  
    :hover{
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[5]};
      background-color: ${({theme}) => theme.colors.deepBlue[1]};
    }
    
    :hover:active{
      color: ${({theme}) => theme.colors.deepBlue[7]};
      background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }
`