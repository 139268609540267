import { NoDataOverlay } from "./NoDataOverlay.style";
import {ReactElement, JSXElementConstructor, useEffect} from "react";

interface IFetchLoadingProps {
    noDataComponent?: ReactElement | undefined,
    noDataOpacity?: number | undefined;
}

const NoDataComponent: React.FC<IFetchLoadingProps> = ({ noDataOpacity, noDataComponent}) => {
    return(
        <NoDataOverlay $noDataOpacity={noDataOpacity}>
            {noDataComponent ?
                noDataComponent :
                    <div style={{color: 'rgb(239, 74, 36)'}}>No Results</div>
            }
        </NoDataOverlay>
    )
}

export default NoDataComponent;
