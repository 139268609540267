import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {useState} from "react";

const ratings: any[] = [
    {
        id: 1,
        value: "*"
    },
    {
        id: 2,
        value: "**"
    },
    {
        id: 3,
        value: "***"
    }
]

export const usePartnerRatingCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'partner-ratings'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (rating: any) => {
        if(filter.selected.some((selected)  => selected.value === rating.value)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.value !== rating.value)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, rating]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: ratings,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        }
    }
}