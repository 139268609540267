import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import Theme from './styles/themes/Theme'
import GlobalStyle from './styles/global.style'
import LeapIcons from './styles/icon.style'

const persistor = persistStore(store);
const version = localStorage.getItem('version');

if(!version){
    localStorage.setItem('version', '')
}

if(version !== process.env.REACT_APP_VERSION){
    process.env.REACT_APP_STORAGE_TO_REMOVE && localStorage.removeItem(process.env.REACT_APP_STORAGE_TO_REMOVE)
    process.env.REACT_APP_STORAGE_TO_REMOVE_2 && localStorage.removeItem(process.env.REACT_APP_STORAGE_TO_REMOVE_2)
    process.env.REACT_APP_VERSION && localStorage.setItem('version', process.env.REACT_APP_VERSION)
}



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Theme>
                    <LeapIcons />
                    <GlobalStyle/>
                    <App />
                </Theme>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
