import {navigationList} from "../interfaces/interfaces";
import {useAppSelector} from "../../../../../common/hooks";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

interface IReturn {
    handleNextPage: () => void,
    handlePrevPage: () => void,
    nextButtonState: ButtonState,
    prevButtonState: ButtonState,
}

type ButtonState = 'disabled' | 'enabled' | 'hidden'

export const usePageNavigate = (): IReturn => {
    const {requiredToSubmit, optionalToSubmit, navState} = useAppSelector(state => state.createOffer)

    const navigate = useNavigate()
    const location = useLocation()

    const [nextButtonState, setNextButtonState] = useState<ButtonState>('disabled')
    const [prevButtonState, setPrevButtonState] = useState<ButtonState>('disabled')

    const pageList: typeof navigationList[number][] =
        useMemo(() => [
            ...requiredToSubmit,
            ...optionalToSubmit
        ], [requiredToSubmit, optionalToSubmit])


    const handleNextPage = useCallback(() => {
        const currPageIndex = pageList.findIndex(page => {
            return page === location.pathname.split('/').pop()
        })

        if(currPageIndex < pageList.length){
            navigate(pageList[currPageIndex + 1])
        }

    }, [
        location.pathname,
        navigate,
        pageList
    ])

    const handlePrevPage = useCallback(() => {
        const currPageIndex = pageList.findIndex(page => {
            return page === location.pathname.split('/').pop()
        })
        if(currPageIndex > 0) {
            navigate(pageList[currPageIndex - 1])
        }
    }, [
        location.pathname,
        navigate,
        pageList
    ])

    useEffect(() => {
        // const currPage = location.pathname.split('/').pop()

        const currPageIndex = pageList.findIndex(page => {
            return page === location.pathname.split('/').pop()
        })


        if(currPageIndex >= pageList.length - 1){

            setNextButtonState('hidden')
        } else {
            if(navState[pageList[currPageIndex + 1]] === 'disabled'){
                setNextButtonState('disabled')
            } else {
                setNextButtonState('enabled')
            }
        }

        if(currPageIndex === 0){
            setPrevButtonState('hidden')
        } else {
            setPrevButtonState('enabled')
        }

    }, [
        location.pathname,
        navState,
        pageList
    ])

    return {
        handleNextPage,
        handlePrevPage,
        nextButtonState,
        prevButtonState
    }
}