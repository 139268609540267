import {useCallback} from "react";

export const useCheckDestinationURL = () => {
    /**
     * removes ? from URL if URL doesn't contain any parameters
     */
    return useCallback((url : string)=>{
        let url_parts = url.split("?")
        if (url_parts[1] !== ""){
            return url
        } else
        {
            return url.split("?")[0]
        }
        
    },[])
}