import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle, TableDisplay} from "../Summary.style";
import {IOfferSchedule} from "../../../interfaces/interfaces";
import {daysMap} from "../../main/scheduling/schedule-entry/ScheduleEntry";


interface Props {}

const convertTo12hFormat = ( from_hour : number, from_minute : number) => {
    return from_hour > 12 ?
        (from_hour-12).toString() + ":" + (from_minute ? from_minute.toString() : "00") + " PM" :
        from_hour.toString() + ":" + (from_minute ? from_minute.toString() : "00") + " AM"
}

const Scheduling: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(navState['scheduling'] === 'disabled') return null

    return (
        <>
            {(offer.start_date || offer.end_date) ? <>
                <TableDisplay>
                    <div>
                        <Subtitle>Start Date</Subtitle>
                        <Item>{offer.start_date}</Item>
                    </div>
                    <div>
                        <Subtitle>End Date</Subtitle>
                        <Item>{offer.end_date}</Item>
                    </div>
                </TableDisplay>
            </> : null}

            {offer.campaign_schedule.some(((entry : IOfferSchedule)=> entry.from_day !== '')) ? <>
                <TableDisplay>
                    <div>
                        <Subtitle>
                            Day(s)
                        </Subtitle>
                        {offer.campaign_schedule.map((day) => (
                            <Item key={day.id} style={{ marginBottom: "2px"}}>
                                {daysMap.get(day.from_day.toString())} </Item> ))}
                    </div>
                    { offer.campaign_schedule.some((entry: IOfferSchedule) => entry.from_hour !== '') ?
                        <div>
                            <Subtitle>
                                Time
                            </Subtitle>
                            {offer.campaign_schedule.map((day) => (
                                <Item key={day.id} style={{ marginBottom: "2px"}}>
                                    {convertTo12hFormat(Number(day.from_hour), Number(day.from_minute))}
                                    { day.to_hour ? " to "+ convertTo12hFormat(Number(day.to_hour), Number(day.to_minute)) : ""}
                                </Item> ))}
                        </div> : null

                    }
                </TableDisplay>
            </> : null}
        </>
    )
}

export default Scheduling