import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {Wrapper, ControlsWrapper, ProgressBar} from './VideoPlayer.style'
import {VideoRewind, VideoPause, VideoForward, VideoPlay} from "../../../assets/svgs/SvgIcons";
import {formatSecondsToTimeFormat} from "../../utils/formatSecondsToTimeFormat";

interface Props {
    videoSource: string
}

interface ControlsProps {
    onPlayPause: () => void
    playing: boolean
    fastForward: () => void
    rewind: () => void
    played: number
    playedSeconds: number
    seek: (value: number) => void
    seekMouseUp: (value: number) => void

}

const VideoPlayer: React.FC<Props> = ({videoSource}) => {
    const videoPlayerRef = useRef<ReactPlayer>(null)
    const [videoState, setVideoState] = useState({
        playing: true,
        muted: false,
        volume: 0.5,
        played: 0,
        playedSeconds: 0,
        playbackRate: 0,
        seeking: false,
        buffer : true
    });

    const {
        playing,
        muted,
    } = videoState

    const playPauseHandler = () => {
        setVideoState({ ...videoState, playing: !videoState.playing });
    };

    const rewindHandler = () => {
        if(videoPlayerRef.current){
            videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);

        }

    };

    const fastForwardHandler = () => {
        if(videoPlayerRef.current) {
            videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 5);
        }
    };

    const onProgressHandler = (state: any) => {
        setVideoState({
            ...videoState,
            played: state.played,
            playedSeconds: state.playedSeconds
        })
    }


    const seekHandler = (value: number) => {
        setVideoState({ ...videoState, played: value});
        videoPlayerRef.current && videoPlayerRef.current.seekTo(value)
    };

    const seekMouseUpHandler = (value: number ) => {
        setVideoState({ ...videoState, seeking: false });
        videoPlayerRef.current && videoPlayerRef.current.seekTo(value / 100);
    };


    return <>
        <Wrapper>
            <Controls
                onPlayPause={playPauseHandler}
                playing={playing}
                fastForward={fastForwardHandler}
                rewind={rewindHandler}
                played={videoState.played}
                playedSeconds={videoState.playedSeconds}
                seek={seekHandler}
                seekMouseUp={seekMouseUpHandler}
            />
            <ReactPlayer
                ref={videoPlayerRef}
                url={videoSource}
                controls={false}
                width={'100%'}
                height={'100%'}
                playing={playing}
                muted={muted}
                progressInterval={0.1}
                onProgress = {(state) => onProgressHandler(state)}
            />
        </Wrapper>
    </>
}

const Controls: React.FC<ControlsProps> = (
    {
        playing,
        onPlayPause,
        fastForward,
        rewind,
        played,
        playedSeconds,
        seek,
        seekMouseUp
    }
) => {
    const [isDragging, setIsDragging] = useState(false)
    const progressBarRef = useRef<HTMLDivElement | null>(null)
    const mousePositionRef = useRef<{x: number, y: number}>(null)

    const [showCursor, setShowCursor] = useState(true);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setShowCursor(false);
        }, 3000); // 3 seconds of inactivity
    };

    const handleMouseMove = () => {
        setShowCursor(true);
        resetTimeout();
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (!showCursor) {
            document.body.style.cursor = "none";
        } else {
            document.body.style.cursor = "auto";
        }
    }, [showCursor]);

    function mouseMoveWhilstDown(target: EventTarget, whileMove: any) {
        const endMove = () => {
            setIsDragging(false)
            window.removeEventListener('mousemove', whileMove);
            window.removeEventListener('mouseup', endMove);
        };

        target.addEventListener('mousedown',  (event: any) => {
            event.stopPropagation();
            setIsDragging(true)
            window.addEventListener('mousemove', whileMove);
            window.addEventListener('mouseup', endMove);
        });
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        mouseMoveWhilstDown(e.target, (event: React.MouseEvent<HTMLDivElement>) => {
            const mouseX = event.clientX
            const progressBarStart = progressBarRef.current?.getBoundingClientRect().x
            const progressBarWidth = progressBarRef.current?.getBoundingClientRect().width

            if(
                progressBarWidth &&
                progressBarStart && mouseX - progressBarStart > 0 &&
                mouseX - progressBarStart < progressBarWidth
            ){
                seek((mouseX - progressBarStart) / progressBarWidth)
            }

        })
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const mouseX = e.clientX
        const progressBarStart = progressBarRef.current?.getBoundingClientRect().x
        const progressBarWidth = progressBarRef.current?.getBoundingClientRect().width

        if(
            progressBarWidth &&
            progressBarStart && mouseX - progressBarStart > 0 &&
            mouseX - progressBarStart < progressBarWidth
        ){
            seek((mouseX - progressBarStart) / progressBarWidth)
        }
    }



    return <ControlsWrapper isDragging={isDragging} showCursor={showCursor}>
        <VideoRewind className={'control-icon'} onClick={rewind}/>
        <div className={'mid-item'}>
            {playing ?
                <VideoPause className={'control-icon'} onClick={onPlayPause}/> :
                <VideoPlay className={'control-icon'} onClick={onPlayPause}/>
            }
        </div>
        <VideoForward className={'control-icon'} onClick={fastForward}/>
        <ProgressBar
            showCursor={showCursor}
            isDragging={isDragging}
            ref={progressBarRef}
            onMouseDown={(e) => {
                handleMouseDown(e)
            }}
            onClick={(e) => handleClick(e)}
        >
            <div
                className="progress"
                style={{
                    width: `${played * 100}%`
                }}

            >
                <div className="time">
                    {formatSecondsToTimeFormat(playedSeconds)}
                </div>
            </div>
        </ProgressBar>
    </ControlsWrapper>
}

export default VideoPlayer