import React, {useEffect, useRef, useState} from "react";
import {IPaginationProps} from "./pagination.props";
import {PageSelect, PerPage, PerPageDropdown, PerPageSelect, Toolbar, Wrapper} from "./Pagination.style";
import {useAppDispatch, useOnClickOutside} from "../../../hooks";
import Icon from "../../icon/Icon";
import TableColumnsList from "./TableColumnsList/TableColumnsList";
import {formatNumberToCommaSeparated} from "../../../utils/formatNumberToCommaSeparated";
import {setSearchValue} from "../../../../modules/admin/pages/partners/leap-partners/LeapPartners.slice";

const perPageOptions: number[] = [1, 10, 50, 100, 250, 500]

const Pagination: React.FC<IPaginationProps> = (
    {
        current_page,
        from,
        to,
        total,
        last_page,
        setCurrentPage,
        setPerPage,
        exportCsv,
        perPage,
        onRefresh,
        loading,
        perPageCustomList,
        showChart,
        hideRows,
        gridColumnApi,
        id,
        gridApi,
        openUp,
        paginationV2,
        setPaginationV2,
        sortBy,
        direction
    }) => {
    const [perPageSelected, setPerPageSelected] = useState<number>(perPage)
    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false)
    const perPageSelectRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(perPageSelectRef, () => {
        setDropdownOpened(false)
    })

    useEffect(() => {
        if(!paginationV2)
            setPerPage(perPageSelected)
        //stay on the same page if !(total < setPaginationV2.page * perPageSelected)
        if(paginationV2 && !(total < paginationV2.page * perPageSelected))
            return setPaginationV2 && setPaginationV2({
                ...paginationV2,
                per_page: perPageSelected
            });

        let calculatedPage = last_page

        if(calculatedPage>=1)
        {
            for(let i=0; calculatedPage-1 > 0 && (calculatedPage * perPageSelected) - perPageSelected > total; i++)
            {
                calculatedPage = calculatedPage - 1;
            }
            setPaginationV2 && setPaginationV2({
                page: calculatedPage,
                per_page: perPageSelected
            });
        }
    }, [perPageSelected])

    const handlePrevPage = () => {
        let prevPage = current_page - 1;
        if (current_page > 1) setPaginationV2 && paginationV2 ? setPaginationV2({...paginationV2, page:prevPage}) : setCurrentPage(prevPage)
    }

    const handleNextPage = () => {
        let nextPage = current_page + 1;
        if (current_page < last_page) setPaginationV2 && paginationV2 ? setPaginationV2({...paginationV2, page:nextPage}) : setCurrentPage(nextPage)
    }

    const handleRefresh = () => {
        onRefresh && onRefresh()
    }
    const handleShowChart = () => {
        showChart && showChart()
    }

    const handleOpenUp = (perPageOptionsList: number[]) => {
        if (openUp) {
            return perPageOptionsList.slice(0).reverse()
        }
        return perPageOptionsList
    }

    return <Wrapper>
        <Toolbar $loading={loading}>
            {exportCsv &&
                <button disabled={loading}
                        onClick={() => exportCsv()}
                        className={'item'}>
                    <i className="ico-Download"/>
                </button>}
            {showChart && <button disabled={loading}
                                  onClick={handleShowChart}
                                  className={'item'}>
                <Icon icon="graph-outlined" size={15}/>
            </button>}
            {gridColumnApi && gridApi &&
                <TableColumnsList gridColumnApi={gridColumnApi}
                                  gridApi={gridApi}
                                  id={id}
                                  loading={loading}
                                  sortBy={sortBy}
                                  direction={direction}
                />}
            {onRefresh && <button disabled={loading} className={'item'}>
                <i onClick={handleRefresh} className="ico-Refresh"/>
            </button>}
        </Toolbar>
        {!hideRows &&
            <PerPage>
                Rows
                <PerPageSelect className={dropdownOpened ? 'active' : ''}
                               disabled={loading} active={dropdownOpened}
                               ref={perPageSelectRef}
                               onClick={() => setDropdownOpened(!dropdownOpened)}>
                    {perPage}
                    <i className={'ico-AngleDown'}/>
                    <PerPageDropdown opened={dropdownOpened} openUp={openUp}>
                        {perPageCustomList?.length ?
                            handleOpenUp(perPageCustomList).map((option, index) => (
                                <div onClick={() => setPerPageSelected(handleOpenUp(perPageCustomList)[index])}
                                     className={`option ${option === perPage ? 'selected' : ''}`}
                                     key={option}>{option}</div>
                            ))
                            :
                            handleOpenUp(perPageOptions).map((option, index) => (
                                <div onClick={() => setPerPageSelected(handleOpenUp(perPageOptions)[index])}
                                     className={`option ${option === perPage ? 'selected' : ''}`}
                                     key={option}>{option}</div>
                            ))
                        }
                    </PerPageDropdown>
                </PerPageSelect>
            </PerPage>
        }
        <PageSelect>
            <span style={{whiteSpace: 'nowrap', letterSpacing: '0.75px'}}>
                {from} - {to} of {formatNumberToCommaSeparated(total)}
            </span>

            <button onClick={handlePrevPage} className={'item'}
                    disabled={loading ? true : current_page === 1}>
                <i className='ico-ChevronLeft'/>
            </button>
            <button onClick={handleNextPage}
                    className={'item'}
                    disabled={loading ? true : current_page === last_page}>
                <i className='ico-ChevronRight'/>
            </button>
        </PageSelect>
    </Wrapper>
}

export default Pagination
