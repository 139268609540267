import styled from "styled-components";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

export const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const Tag = styled.span`
  padding: 4px 8px;
  margin: 9px 5px 0 0;
  display: inline-flex;
  /* Cool Gray/200 */

  background: ${({theme}) => theme.colors.coolGray[2]};
  /* Cool Gray/300 */

  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 16px;
  line-height: 1;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
`
export const Red = styled.span`
  color: ${({theme}) => theme.colors.critical[3]}
`

export const Separator = styled.div`
  height: 1px;
  background: ${({theme}) => theme.colors.coolGray[3]};
  margin: 8px;
`

export const DeleteDropdownItem = styled(DropdownItem)`
  color: ${({theme}) => theme.colors.negative[5]};
  user-select: none;

  :hover {
    background: ${({theme}) => theme.colors.negative[5]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  :hover:active {
    background-color: ${({theme}) => theme.colors.negative[7]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
`
export const CellToggle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
    .ico-ChevronDown, .ico-ChevronUp {
        margin-right: 8px;
        color: ${({theme}) => theme.colors.deepBlue[5]}
    }
    .ico-Connected {
        margin-left: 8px;
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[5]}
    }
`
export const CellDetailsItem = styled.div`
    color: ${({theme}) => theme.colors.deepBlue[5]}
`

export const CellDetails = styled.div`
    margin-bottom: 14px;
    //animation: .3s ease 0s normal forwards 1 fadein;
    @keyframes fadein{
        0% { opacity:0; }
        66% { opacity:0; }
        100% { opacity: 1; }
    }
`
