import React, {ReactElement} from "react";

import {Wrapper, LabelStyledFloatable} from "./InputButton.style";

interface IInputButtonProps {
    value?: string | number | undefined,
    opened: boolean,
    datepickerRef: any,
    width?: string,
    placeholder?: string,
    label?: string | ReactElement
}

const InputButton = React.forwardRef<
    HTMLInputElement,
    IInputButtonProps
    > (({value,  datepickerRef, opened, width = '215px', placeholder = '--/--/----', label}, ref) => {

        const handleClick = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if(!opened) {
                datepickerRef.current.setOpen(true)
            }
        }

        return (
            <Wrapper $withLabel={!!label} $width={width} $opened={opened} ref={ref} onClick={(e) => handleClick(e)}>
                {label && <LabelStyledFloatable $focused={opened}>{label}</LabelStyledFloatable>}
                <div className={'date-value'}>{value ? value : <span className={'placeholder'}>{placeholder}</span>}</div>
                <div className={'calendar-icon-container'}><i className={'ico-Calendar'}/></div>
            </Wrapper>
        )
}

)


export default InputButton