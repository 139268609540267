import { FiltersColumn, FiltersList } from "./Filters.style";

const Filters: React.FC = ({children}) => {
    return(
        <FiltersColumn>
            <FiltersList>
                {children}
            </FiltersList>
        </FiltersColumn>
    )
} 

export default Filters;