import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, IAccountManagerFilter} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";

export const useAccountManagerCacheFilter = (pageId: IPagePresetsID, displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IAccountManagerFilter[]>([])
    const [accountManagers, setAccountManagers] = useState<IAccountManagerFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [accountManagerFilterSearchValue, setAccountManagerFilterSearchValue] = useState('')
    const [accountManagersFilterNotDisplayed, setAccountManagersFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID ='account-manager'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IAccountManagerFilter[]>(
                'account-managers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data.sort((a: IAccountManagerFilter, b: IAccountManagerFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredAccManagers = fetchedData.filter(manager => (
                manager.name.toLowerCase() + ' ' + manager.lastname.toLowerCase()).includes(accountManagerFilterSearchValue.toLowerCase())
            ||
            manager.id.toString().includes(accountManagerFilterSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredAccManagers].slice(0, displayCount)
            if(filteredAccManagers.length > displayCount){
                setAccountManagersFilterNotDisplayed(filteredAccManagers.length - displayCount)
            } else {
                setAccountManagersFilterNotDisplayed(0)
            }
            setAccountManagers(displayedAcc)
        } else {
            setAccountManagers(
                filteredAccManagers
            )
        }
    }, [accountManagerFilterSearchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (manager: IAccountManagerFilter) => {
        if(filter.selected.some((filterManager)  => filterManager.id === manager.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== manager.id)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, manager]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }


    return {
        id: id,
        options: accountManagers,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: accountManagersFilterNotDisplayed,
        search: {
            value: accountManagerFilterSearchValue,
            setSearch: setAccountManagerFilterSearchValue
        },
        loading
    }
}

