import styled, {css} from "styled-components";
import {DropdownContainer} from "../../dropdown/Dropdown.style";
import {InputSingle} from "../../input-single-line/InputSingleLine.style";

interface IIncludeExcludeItemProps {
    $active: boolean
}

interface IHeaderStyleProps {
    $active: boolean
}

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`

export const Header = styled.div<IHeaderStyleProps>`
  height: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid ${({theme, $active}) => $active ? theme.colors.deepBlue[3] : theme.colors.coolGray[3]};
  background: ${({theme, $active}) => $active ? theme.colors.deepBlue[2] : theme.colors.coolGray[2]};
  font-size: 12px;
  
  .title{
    font-size: 12px;
    line-height: 16px;
    color: ${({theme}) => theme.colors.performBlue[6]};
    margin-right: 8px;
  }
  
  .ico-AngleDown, .ico-AngleUp{
    color: ${({theme, $active}) => $active ? theme.colors.deepBlue[6] :  theme.colors.deepBlue[4]};
    font-size: 16px;
    line-height: 16px;
    margin-right: 8px;
  }
  
  .ico-Exit{
    color: ${({theme, $active}) => $active ? theme.colors.performOrange[5] : theme.colors.performOrange[3]};
    font-size: 16px;
    line-height: 16px;
  }

  .include-exclude{
    font-family: ${({theme}) => theme.fonts.AvenirLightItalic};
    line-height: 16px;
    margin-right: 4px;
  }
  
  .selected{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[6]};
    line-height: 16px;
    margin-right: 8px;
  }
  
  :hover{
    cursor: pointer;
  }

  ${({$active}) => !$active && css`
    :hover{
      border: 1px solid ${({theme}) => theme.colors.deepBlue[2]};
      background: ${({theme}) => theme.colors.coolGray[3]};
      cursor: pointer;
    }
  `}
  
`

export const IncludeExcludeWrapper = styled.div`
  width: 100%;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  display: flex;
`

export const IncludeExcludeItem = styled.div<IIncludeExcludeItemProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  
  :hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.performBlue[1]};
  }
  
  ${(props) => props.$active && css`
    background-color: ${props.theme.colors.performBlue[5]};
    color: white;

    :hover{
      cursor: default;
      background-color: ${({theme}) => theme.colors.performBlue[5]};
    }
  `}
`

export const ClearSelected = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};

  background-color: white;
  span{
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    
    :hover {
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[5]};
    }
    
    :hover:active{
      color: ${({theme}) => theme.colors.deepBlue[8]};
    }
  }
  padding-bottom: 16px;
  transform: translateY(16px);
  position: sticky;
  bottom: 0;
`

export const TopStickyContainer = styled.div`
  position: sticky;
  top: 0;
  background: white;
  padding-top: 16px;
  padding-bottom: 5px;
  transform: translateY(-16px);
  z-index: 2;

  .filter-type-wrapper{
    display: flex;
    gap: 5px;
    
    .match-dropdown{
      width: 45%;
    }
    
  }
  
  .matches-container{
    left: auto;
    right: 0
  }
  
  .matches-header{
    max-height: 32px;
    min-height: 32px;
    gap: 3px;
    span{
      white-space: nowrap;
    }
  }
`

export const Container = styled(DropdownContainer)`
  width: 291px;
  top: 26px;
  max-height: 350px;
  overflow-y: auto;
`

export const FilterSearch = styled(InputSingle)`
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  
  .checkbox-wrapper{
    padding: 9px;
    border-radius: 8px;
    :hover{
      background-color: ${({theme}) => theme.colors.performBlue[1]};
    }
  }
`

export const SingleSelectItem = styled.div<{active: boolean}>`
  background-color: ${({active, theme}) => active  &&  theme.colors.performBlue[8]};
  color: #8799A2;
  color: ${({active, theme}) => active  &&  theme.colors.pureWhite};
  cursor: pointer;

  &:hover {
    background-color: ${({active, theme}) => active &&  theme.colors.performBlue[8]} !important;
  }
`;

export const NotShown = styled.div`
  font-family: ${({theme}) => theme.fonts.AvenirItalic};
  color: ${({theme}) => theme.colors.coolGray[5]};
  margin-top: 3px;
`