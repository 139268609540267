import React, {useState, useEffect} from 'react';
import {AlertStyledContent, EventLoading, InnerPanel, Panel} from "../Main.style";
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hooks";
import {
    setNextPage,
    setOffer,
    setPrevPage,
    setCpcMainConversionPanelVisibility,
} from "../../../create-offer.slice";
import {OutcomeFunnelPanelBody} from "./OutcomeFunnel.style";
import {
    AppStoreIcon, 
    OutcomeFunnelEyeIcon,
    OutcomeFunnelFinishIcon,
    OutcomeFunnelStartIcon,
    PlayStoreIcon
} from "../../../../../../../assets/svgs/SvgIcons";
import MainConversion from "../../../components/MainConversion";
import Event from "../../../components/Event";
import CreateOfferService from "../../../CreateOffer.service";
import {AlertStyled} from "../../../../../../../common/styled-components";
import ClickPayoutInput from "../../../components/ClickPayoutInput";
import {
    ADJUST, APPSFLYER, BRANCH,
    CPE,CPS, CPL,CPC,
    CPI,
    IOS, ANDROID,
    CREATIVES, KOCHAVA,
    LAND_AND_TRACK,
    OFFER_INFO,
    PPC, SINGULAR,
    TRACKING_CODE
} from "../../../interfaces/interfaces";
import {
    AdjustEventMacro,
    AppsFlyerEventMacro,
    BranchEventMacro,
    KochavaEventMacro,
    SingularEventMacro,
    OUTCOME_FUNNEL_DESCRIPTION_CLICKS,
    OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_ANDROID,
    OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_DEFAULT,
    OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_IOS, OUTCOME_FUNNEL_DESCRIPTION_PPC
} from "../../../shared-components/consts";
import {useCheckDestinationURL} from "../../../hooks/useCheckDestinationURL";

const OutcomeFunnel : React.FC = ({}) => {
    const {offer, cpcMainConversionPanelVisibility} = useAppSelector(state => state.createOffer)
    const {token} = useAppSelector(state => state.auth)
    
    const dispatch = useAppDispatch()
    const checkDestinationURL = useCheckDestinationURL()
    
    const [eventLoading, setEventLoading] = useState<boolean>(false)
    
    const handleAddEvent = async () => {
        try {
        // if (offer.id) {
            setEventLoading(true)
            const {data} = await CreateOfferService.createUpsell(token, offer.id)
            
            let upsells = [
                ...offer.upsells,
                {
                    ...data,
                    is_new: true,
                    pixel_group: null,
                    payout: '',
                    revshare: [{
                        name: '',
                        value: ''
                    }],
                    is_revshare: false,
                    payout_modifiers: [],
                    upsell_tracking_settings: {
                        event_tracking_goal_id: ''
                    }
                }
            ]
            
            //CPI & CPE Offer Events
            if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) {
                let eventMacro = ""

                if(offer.trackingSystem === ADJUST) {
                    dispatch(setOffer({
                        upsells: upsells,
                    }))
                }

                if(offer.trackingSystem === APPSFLYER) {
                    upsells = upsells.map((upsell, index) => {
                        eventMacro += AppsFlyerEventMacro(index+1, upsell.id)
                        return {
                            ...upsell,
                            upsell_tracking_settings: {
                                ...upsell.upsell_tracking_settings,
                                event_tracking_goal_id: `$$click(goalid_${index+1})`
                            }
                        }
                    })

                    dispatch(setOffer({
                        upsells: upsells,
                        destination_url_event_macros: eventMacro,
                        destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")) ,
                    }))
                }

                if (offer.trackingSystem === BRANCH) {
                    upsells = upsells.map((upsell, index) => {
                        eventMacro += BranchEventMacro(upsell.id)
                        return {...upsell}
                    })

                    if (upsells.length > 1) {
                        dispatch(setOffer({
                            upsells: upsells,
                            destination_url: checkDestinationURL(offer.destination_url_copy),
                            destination_url_event_macros: ''
                        }))
                    } else {
                        dispatch(setOffer({
                            upsells: upsells,
                            destination_url: checkDestinationURL((offer.destination_url_copy + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")),
                            destination_url_event_macros: eventMacro
                        }))
                    }
                }

                if(offer.trackingSystem === KOCHAVA) {
                    upsells = upsells.map((upsell, index) => {
                        eventMacro += KochavaEventMacro(index+1, upsell.id)
                        return {
                            ...upsell,
                            upsell_tracking_settings: {
                                ...upsell.upsell_tracking_settings,
                                event_tracking_goal_id: index+1
                            }
                        }
                    })

                    dispatch(setOffer({
                        upsells: upsells,
                        destination_url_event_macros: eventMacro,
                        destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")),
                    }))
                }
                if(offer.trackingSystem === SINGULAR) {
                    upsells = upsells.map((upsell, index) => {
                        eventMacro += SingularEventMacro(index+1, upsell.id)
                        return {
                            ...upsell,
                            upsell_tracking_settings: {
                                ...upsell.upsell_tracking_settings,
                                event_tracking_goal_id: `goal_id${index+1}`
                            }
                        }
                    })

                    dispatch(setOffer({
                        upsells: upsells,
                        destination_url_event_macros: eventMacro,
                        destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")),
                    }))
                }
            }else {
                //all other offer types
                dispatch(setOffer({
                    upsells: upsells
                }))
            }
            setEventLoading(false)
        }catch (error) {
            console.log(error)
        }
    }
    
    const handleEventMacroOnLoad = () => {
        let upsells = [...offer.upsells]
        let eventMacro = ""

        upsells = upsells.map((upsell, index) => {
            eventMacro +=
                offer.trackingSystem === APPSFLYER ? AppsFlyerEventMacro(index+1, upsell.id)
                    : offer.trackingSystem === BRANCH ? BranchEventMacro(upsell.id)
                        : offer.trackingSystem === KOCHAVA ? KochavaEventMacro(index+1, upsell.id)
                            : offer.trackingSystem === SINGULAR ? SingularEventMacro(index+1, upsell.id)
                                : offer.trackingSystem === ADJUST ? AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id)
                                    : ''
            return {
                ...upsell,
                upsell_tracking_settings: {
                    ...upsell.upsell_tracking_settings,
                    event_tracking_goal_id:
                        offer.trackingSystem === APPSFLYER ? `$$click(goalid_${index+1})`
                            : offer.trackingSystem === BRANCH ? ''
                                : offer.trackingSystem === KOCHAVA ? index+1
                                    : offer.trackingSystem === SINGULAR ? `goal_id${index+1}`
                                        : offer.trackingSystem === ADJUST ? upsell.upsell_tracking_settings.event_tracking_goal_id
                                            : ''
                }
            }
        })

        if(offer.trackingSystem !== BRANCH) {
            dispatch(setOffer({
                upsells: [...upsells],
                destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")),
                destination_url_event_macros: eventMacro,
            }))
        }else {
            if (upsells.length < 2) {
                dispatch(setOffer({
                    upsells: [...upsells],
                    destination_url: checkDestinationURL((offer.destination_url_copy + offer.destination_custom_macros + offer.destination_url_ts_macros + eventMacro).replace("?&", "?")),
                    destination_url_event_macros: eventMacro,
                }))
            }
        }
    }

    const handleRemoveEventMacroFromTrackingURL = (index: number, id:number, goalID:any) => {
        let upsells = [...offer.upsells]

        upsells.splice(index, 1)
        let newMacro = ""

        upsells = upsells.map((upsell, index) => {
            newMacro +=
                offer.trackingSystem === APPSFLYER ? AppsFlyerEventMacro(index+1, upsell.id)
                    : offer.trackingSystem === BRANCH ? BranchEventMacro(upsell.id)
                        : offer.trackingSystem === KOCHAVA ? KochavaEventMacro(index+1, upsell.id)
                            : offer.trackingSystem === SINGULAR ? SingularEventMacro(index+1, upsell.id)
                                : offer.trackingSystem === ADJUST ? AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id)
                                    : ''

            if (offer.trackingSystem === ADJUST) {
                return {...upsell}
            }else {
                return {
                    ...upsell,
                    upsell_tracking_settings: {
                        ...upsell.upsell_tracking_settings,
                        event_tracking_goal_id:
                            offer.trackingSystem === APPSFLYER ? `$$click(goalid_${index+1})`
                                : offer.trackingSystem === BRANCH ? ''
                                    : offer.trackingSystem === KOCHAVA ? index+1
                                        : offer.trackingSystem === SINGULAR ? `goal_id${index+1}`
                                            : ''
                    }
                }
            }
        })
        if(offer.trackingSystem === BRANCH && upsells.length > 1) {
            dispatch(setOffer({
                destination_url_event_macros: '',
                destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros).replace("?&", "?")),
                upsells: upsells
            }))
        }else {
            dispatch(setOffer({
                destination_url_event_macros: newMacro,
                destination_url: checkDestinationURL((offer.destination_url_copy  + offer.destination_custom_macros + offer.destination_url_ts_macros + newMacro).replace("?&", "?")),
                upsells: upsells
            }))
        }
    }

    const handleDeleteEvent = (index: number, id:number, event: any) => {
        let upsells = [...offer.upsells]
    
        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) {
            if(offer.trackingSystem === ADJUST) {
                handleRemoveEventMacroFromTrackingURL(index, event.id, event.upsell_tracking_settings.event_tracking_goal_id)
            }
            if(offer.trackingSystem === APPSFLYER) {
                handleRemoveEventMacroFromTrackingURL(index, event.id, `$$click(goalid_${index+1})`)
            }
            if(offer.trackingSystem === KOCHAVA) {
                handleRemoveEventMacroFromTrackingURL(index, event.id, index+1)
            }
            if(offer.trackingSystem === BRANCH) {
                handleRemoveEventMacroFromTrackingURL(index, event.id, '')
            }
            if(offer.trackingSystem === SINGULAR) {
                handleRemoveEventMacroFromTrackingURL(index, event.id, `goal_id${index+1}`)
            }            
        }else {
            // all other offer types
            upsells.splice(index, 1)

            dispatch(setOffer({
                upsells: upsells
            }))
        }
    }
    
    useEffect(()=>{
        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) {
            /*
                - Making a copy of 'destination_url' every time on OutcomeFunnel component is mounted
                - The 'destination_url_copy' is used for handling event macros and should need to be updated every time
                  if user changed the 'destination_url' on Tracking Systems Section
            */
            dispatch(setOffer({
                // destination_url_copy: offer.destination_url,
                destination_url_event_macros: offer.destination_url_event_macros
            }))
        }

        if (offer.detailed_pricing_model === PPC){
            dispatch(setPrevPage("../" + OFFER_INFO))
            dispatch(setNextPage("../" + CREATIVES))
        } else {
            dispatch(setPrevPage("../" + LAND_AND_TRACK))
            dispatch(setNextPage("../" + TRACKING_CODE))
        }

    },[dispatch])
    
    useEffect(()=> {
        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) {
            handleEventMacroOnLoad()
        }
    },[offer.trackingSystem, offer.detailed_pricing_model])
    
    useEffect(()=> {
        //Automatically add the first event in any CPE (App Engagement) offer that can not be removed
        if(offer.detailed_pricing_model === CPE) {
            if(offer.upsells.length < 1) {
                handleAddEvent()
            }
        }
    },[offer.detailed_pricing_model])
    
    return (
        <>
            <Panel>
                <div className={"title"}>Outcome Funnel</div>
                <div className={'description'}>
                    {offer.detailed_pricing_model === PPC ? OUTCOME_FUNNEL_DESCRIPTION_PPC
                        :(offer.detailed_pricing_model === CPC) ? OUTCOME_FUNNEL_DESCRIPTION_CLICKS
                            :((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                offer.mobile_platform === IOS) ? OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_IOS
                            : ((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                offer.mobile_platform === ANDROID) ? OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_ANDROID
                                : OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_DEFAULT
                    }
                </div>
                {offer.detailed_pricing_model === CPC &&
                    <AlertStyled type={"info"} style={{marginBottom: 36}}>
                        <AlertStyledContent className={'info'}>
                            <i className={'ico-Information'}/>
                            When adding a main conversion or event(s) below, you are required to navigate to the 
                            tracking codes section and place the code(s) provided there on the corresponding conversion 
                            or event page of your domain to properly track them.
                        </AlertStyledContent>
                    </AlertStyled>
                }
                <OutcomeFunnelPanelBody>
                    {offer.detailed_pricing_model === CPC &&
                        <div>
                            <InnerPanel>
                                <div className="inner-panel-title">
                                    Click
                                </div>
                                <div className="inner-panel-body">
                                    <ClickPayoutInput/>
                                </div>
                            </InnerPanel>
                        </div>
                    }
                    {((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                            offer.impression_tracking_url !== '') &&
                        <div className="funnel-start" style={{maxWidth: 235}}>
                            Customer impression tracked
                            <div className="start-icon-holder"><OutcomeFunnelEyeIcon/></div>
                        </div>
                    }
                    {offer.detailed_pricing_model !== PPC &&
                        <div className="funnel-start"
                             style={offer.detailed_pricing_model === CPC ? {marginTop: 32} : undefined}>
                            Customer arrives at the
                            {((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                offer.mobile_platform === IOS) ? ' App Store'
                                : ((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                    offer.mobile_platform === ANDROID) ? ' Google Play Store'
                                    : ' Landing Page URL'}
                            <div className={"start-icon-holder"}>
                                {((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                    offer.mobile_platform === IOS) ? <AppStoreIcon/>
                                    : ((offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) &&
                                        offer.mobile_platform === ANDROID) ? <PlayStoreIcon/>
                                        : <OutcomeFunnelStartIcon/>}
                            </div>
                        </div>}
                    <div>
                        {/*Main Conversion*/}
                        {offer.detailed_pricing_model === CPC ? <>
                            {cpcMainConversionPanelVisibility &&
                                <InnerPanel>
                                    <div className="inner-panel-title">
                                        <span>Main Conversion</span>
                                        {offer.detailed_pricing_model === CPC &&
                                            <button 
                                                className={"remove-item"}
                                                onClick={()=> {
                                                    dispatch(setCpcMainConversionPanelVisibility(false))
                                                    dispatch(setOffer({
                                                        cpa: null,
                                                        upsells: []
                                                    }))
                                                }}>
                                                <i className="ico-Delete"/>
                                            </button>
                                        }
                                    </div>
                                    <div className="inner-panel-body">
                                        <MainConversion/>
                                    </div>
                                </InnerPanel>
                            }</> :
                            <InnerPanel>
                                <div className="inner-panel-title">
                                    Main Conversion 
                                    {offer.detailed_pricing_model === PPC && ' - Call'}
                                    {(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) && ' - Install'}
                                </div>
                                <div className="inner-panel-body">
                                    <MainConversion/>
                                </div>
                            </InnerPanel>
                        }
                        
                        {/*Events*/}
                        {offer.upsells.map((event: any, index: number)=> 
                        <InnerPanel key={event.id}>
                            <div className="inner-panel-title">
                                <span>Event ({event.id}) {event.name ? ' - ' + event.name : '' }</span>
                                {(offer.detailed_pricing_model !== CPE || index !== 0) &&
                                    <button className={"remove-item"} onClick={()=>handleDeleteEvent(index, event.id, event)}>
                                        <i className="ico-Delete"/>
                                    </button>
                                }
                            </div>
                            <div className="inner-panel-body">
                                <Event index={index} event={event}/>
                            </div>
                        </InnerPanel>)}
                        
                        {offer.detailed_pricing_model === CPC ?
                            <div className={'add-btn-holder'}>
                                {eventLoading && <EventLoading/>}
                                <button className={'add-btn'}
                                        disabled={eventLoading}
                                        onClick={() => {
                                            !cpcMainConversionPanelVisibility 
                                                ? dispatch(setCpcMainConversionPanelVisibility(true)) 
                                                : handleAddEvent()
                                        }}>
                                    <i className={'ico-plus'}/>
                                </button>
                            </div> : <>
                                {offer.detailed_pricing_model !== PPC &&
                                    <div className={'add-btn-holder'}>
                                        {eventLoading && <EventLoading/>}
                                        <button
                                            disabled={eventLoading}
                                            className={'add-btn'}
                                            onClick={handleAddEvent}>
                                            <i className={'ico-plus'}/>
                                        </button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    
                    <div className={"funnel-finish"}>
                        <OutcomeFunnelFinishIcon/>
                    </div>
                </OutcomeFunnelPanelBody>
            </Panel>
        </>
    );
};

export default OutcomeFunnel