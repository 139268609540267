import styled, {css} from "styled-components";

interface IWrapperProps {
    checked: boolean,
    disabled: boolean
}



export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<IWrapperProps>`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  position: relative;
  width: 12.3px;
  height: 12.3px;
  border-radius: 3px;

  .ico-Check{
    display: ${props => props.checked ? 'inline-block' : 'none'};
    font-size: 8px;
    position: absolute;
    left: 1px;
    top: 1px;
  }

  .indeterminate {
    display: block;
    position: absolute;
    top: 5px;
    left: 2px;
    width: 7px;
    height: 1px;
    border-radius: 1px;
    background-color: ${({theme}) => theme.colors.performBlue['5']};
  }
`

export const Label = styled.span`
  margin-left: 8px;
  font-size: 12px;
  line-height: 16px;
`

export const Wrapper = styled.label<IWrapperProps>`
  display: flex;
  //width: 100%;
  align-items: center;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  cursor: pointer;
  ${StyledCheckbox}  {
    border: 1px solid ${({theme}) => theme.colors.deepBlue['4']};
  }

  :hover:active{
    color: ${({theme}) => theme.colors.performBlue['5']};

    ${StyledCheckbox}  {
      border: 1px solid ${({theme}) => theme.colors.performBlue['5']};
      background-color: ${({theme}) => theme.colors.performBlue['1']};
    }
  }
  :hover{
    color: ${({theme}) => theme.colors.performBlue['4']};
    cursor: pointer;
    ${StyledCheckbox}  {
      border: 1px solid ${({theme}) => theme.colors.performBlue['4']};
      background-color: ${({theme}) => theme.colors.performBlue['1']};
    }
  }

  ${props => {
    if(props.checked) {
      return css`
        ${StyledCheckbox}  {
          border: 1px solid ${({theme}) => theme.colors.performBlue['5']};
          background-color: ${({theme}) => theme.colors.performBlue['1']};
        }
        color: ${props.theme.colors.performBlue['5']};
        :hover:active{
          color: ${({theme}) => theme.colors.performBlue['7']};
          ${StyledCheckbox}  {
            border: 1px solid ${({theme}) => theme.colors.performBlue['7']};
          }
        }
        :hover{
          color: ${({theme}) => theme.colors.performBlue['6']};
          ${StyledCheckbox}  {
            border: 1px solid ${({theme}) => theme.colors.performBlue['6']};
          }

        }
      `
    }
  }
  }

  ${props => {
    if(props.disabled){
      return css`
        pointer-events: none;
        color: ${({theme}) => theme.colors.coolGray['5']};
        ${StyledCheckbox} {
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
          background-color: ${({theme}) => theme.colors.coolGray['3']};

          .ico-Check{
            color: ${({theme}) => theme.colors.coolGray['4']};
          }
        }
      `
    }
  }}
`
export const FormCheckbox = styled.div `
  margin-bottom: 20px;
  display:flex;
  input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    ~ label {
      color: ${({theme}) => theme.colors.deepBlue['4']};
    }
    &:before {
      background-color: ${({theme}) => theme.colors.pureWhite};
      border: 1px solid ${({theme}) => theme.colors.deepBlue['4']};
    }
    &:after {
      color: ${({theme}) => theme.colors.deepBlue['4']};
    }
    &:checked {
      ~ label {
        color: ${({theme}) => theme.colors.performBlue['5']};
        &:before {
          background-color: ${({theme}) => theme.colors.performBlue['1']};
          border: 1px solid ${({theme}) => theme.colors.performBlue['5']};
          &:hover{
            color: ${({theme}) => theme.colors.performBlue['10']};
            border: 1px solid ${({theme}) => theme.colors.performBlue['10']};
            &:active{
              color: ${({theme}) => theme.colors.performBlue['11']};
              border: 1px solid ${({theme}) => theme.colors.performBlue['11']};
            }
          }
        }
        &:hover{
          color: ${({theme}) => theme.colors.performBlue['10']};
          &:active{
            color: ${({theme}) => theme.colors.performBlue['11']};
          }
        }
        &:after {
          opacity: 1;
          left: 2px;
          bottom: 2px;
        }
      }
    }
    &:disabled {
      ~ label {
        pointer-events: none;
        color: ${({theme}) => theme.colors.coolGray['5']};
        &:before {
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
          background-color: ${({theme}) => theme.colors.coolGray['3']};
        }
      }
    }
    
    &:hover {
       ~ label {
         color: ${({theme}) => theme.colors.performBlue['4']};
         &:before {
           cursor: pointer;
           border: 1px solid ${({theme}) => theme.colors.performBlue['4']};
         }
         cursor: pointer;
         &:active {
           color: ${({theme}) => theme.colors.performBlue['5']};
           &:before{
             background-color: ${({theme}) => theme.colors.coolGray['1']};
             border: 1px solid ${({theme}) => theme.colors.performBlue['5']};
           }
         }
       }
      
    }
    
  }
  label {
    position: relative;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 12px;
    margin-left: 8px;
    display: flex;
    // color: ${({theme}) => theme.colors.performBlue['4']};
    line-height: 1.2;
    &:after {
      position: absolute;
      display: block;
      content: "\\e900";
      font-size: 9px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      font-family: 'leap' !important;
      opacity: 0;
      // &:disabled{
      //   color: ${({theme}) => theme.colors.coolGray['4']};
      // }
    }
    &:before {
      position: relative;
      width: 13px;
      height: 13px;
      border-radius: 3px;
      display: block;
      pointer-events: none;
      user-select: none;
      content: '';
      border: 1px solid ${({theme}) => theme.colors.deepBlue['4']};
      margin-right: 10px;
      color: ${({theme}) => theme.colors.performBlue['4']};
      &:hover{
        border: 1px solid ${({theme}) => theme.colors.performBlue['5']};
        cursor: pointer;
        &:active{
          background-color: red;
        }
      }
      &:active{
        background-color: ${({theme}) => theme.colors.performBlue['1']};
      }
    }
    // &:checked{
    //  
    // }
    // &:hover {
    //   color: ${({theme}) => theme.colors.performBlue['5']};
    //   &:active {
    //     color: #007383
    //   }
    // }
    
  }
`
