import styled  from "styled-components";
import {ButtonStyled} from "../../../../../../../common/styled-components";

export const OutcomeFunnelPanelBody = styled.div`
    position: relative;
    &:before {
        z-index: 0;
        content: '';
        width: 1px;
        height: 100%;
        bottom: 0;
        background: ${({theme}) => theme.colors.coolGray[3]};
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
    }
    .funnel-start {
        position: relative;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        padding: 16px 24px 18px;
        max-width: 280px;
        margin: 0 auto 32px;
        border-radius: 16px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        background: white;
        text-align: center;
        .start-icon-holder {
            display: flex;
            justify-content: center;
            margin-top: 18px;
        }
    }
    .funnel-finish {
        position: relative;
        width: 64px;
        height: 64px;
        margin: 32px auto 0;
        border-radius: 100%;
        background: ${({theme}) => theme.colors.positive[1]};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .add-btn-holder {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 32px;
        .add-btn {
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
            background: white;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .2s ease all;
            &:hover {
                border-color: ${({theme}) => theme.colors.deepBlue[2]};
                background: ${({theme}) => theme.colors.coolGray[2]};
            }
            &:active {
                border-color: ${({theme}) => theme.colors.deepBlue[3]};
                background: ${({theme}) => theme.colors.deepBlue[2]};
                color: ${({theme}) => theme.colors.deepBlue[6]};
            }
        }
    }
    
`