import React, {useState, useEffect} from 'react';
import styled, {css} from "styled-components";
import SwitchToggleButton from "../../../../../common/components/switch-toggle-button/SwitchToggleButton";

export interface Props {
    title: string,
    locked: boolean,
    switchToggle?: boolean,
    opened?: boolean,
    setOpened?: (opened: boolean) => void,
    expandable?: boolean
}

const Container = styled.div`
    margin-top: 24px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 8px;
`

const Header = styled.div<{ opened: boolean, locked: boolean }>`
    background-color: ${({theme}) => theme.colors.coolGray[1]};
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    pointer-events: ${({locked}) => locked ? 'none' : 'all'};
    
    .toggle-wrapper{
        opacity: ${({locked}) => locked ? 0.5 : 1};
        transform: scale(1.2);
        margin-right: 7px;
    }
    
    .toggle-label {
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 12px;
    }

    .material-symbols-outlined {
        border-radius: 50%;
        cursor: pointer;

        :hover {
            background-color: ${({theme}) => theme.colors.deepBlue[1]};
        }

        :hover:active {
            background-color: ${({theme}) => theme.colors.deepBlue[2]};
        }
    }

    ${({opened}) => opened && css`
        border-radius: 8px 8px 0 0;
    `}
    .ico-Locked {
        font-size: 18px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }

    .material-symbols-outlined {
        font-size: 22px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }


    h2 {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 16px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
        line-height: 22px;
    }
`

export const Body = styled.div`
    padding: 24px;
    border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};

    p {
        color: #8799A1;
    }

`


export const Expandable: React.FC<Props> = (
    {
        title,
        locked,
        switchToggle,
        opened,
        setOpened,
        expandable = true,
        children
    }) => {
    const [expanded, setExpanded] = useState<boolean>(false)

    return (
        <Container>
            <Header opened={opened ? opened : expanded} locked={locked}>
                <h2>{title}</h2>
                {switchToggle ? <>
                    <SwitchToggleButton
                        // disabled={true}
                        label={(opened ? opened : expanded) ? 'ON' : 'OFF'}
                        active={opened ? opened : expanded}
                        setActive={setOpened ? setOpened :setExpanded}
                        labelFirst={true}
                        toggleButtonClassName={'toggle'}
                        labelClassName={'toggle-label'}
                        wrapperClassName={'toggle-wrapper'}
                    />
                </> : <>
                    {
                        locked ?
                            <i className="ico-Locked"/> :
                            (opened ? opened : expanded) ?
                                <span onClick={() => setOpened ? setOpened(false) : setExpanded(false)}
                                      className="material-symbols-outlined"> expand_less </span> :
                                <span onClick={() => setOpened ? setOpened(true) : setExpanded(true)}
                                      className="material-symbols-outlined"> expand_more </span>
                    }
                </>}
            </Header>
            {!expandable ? <></> : <>
                {
                    (opened ? opened : expanded) &&
                    <Body>
                        {children}
                    </Body>
                }
            </>}

        </Container>
    );
};