import React, {useEffect, useState} from "react";
import {ITag} from "../../../models/tag.type";
import {Wrapper} from "./AddTagsModal.style";
import Dropdown from "../../dropdown/Dropdown";
import {DropdownItem} from "../../dropdown/Dropdown.style";
import SelectionPills from "../../selection-pills/SelectionPills";
import {ModalFooter} from "../../modal/container/Container.style";
import {
    ButtonStyled,
    InputStyledContainer,
} from "../../../styled-components";


interface IProps {
    addTag: boolean
    selectedUsers: any[],
    addedTags: ITag[],
    tags: ITag[],
    saveTags: (selectedTagsNum: number[]) => void,
    fetchData?: ()=> void
    closeModal: () => void
}

const AddTagsModal: React.FC<IProps> = ({selectedUsers,
                                            addTag,
                                            tags,
                                            addedTags,
                                            saveTags,
                                            closeModal}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [searchTag, setSearchTag] = useState<string>("");
    const [filteredTags, setFilteredTags] = useState<ITag[]>(tags)
    const [selectedTags, setSelectedTags] = useState<ITag[]>(addedTags)

    const filterTags = () => {
        setFilteredTags(tags.filter(tag => {
                return tag.name.toLowerCase().includes(searchTag.toLowerCase()) ||
                    tag.id.toString().includes(searchTag.toLowerCase())
            })
        )
    }
    const checkIfTagIsSelected = (selectedTag: ITag) => {
        return selectedTags.some(tag => tag.id === selectedTag.id)
    }

    const handleTagSelect = (tag: ITag) => {
        if (checkIfTagIsSelected(tag)) {
            const newTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id)
            setSelectedTags(newTags)
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    useEffect(() => {
        filterTags()
    }, [searchTag])

    // this function could possibly be changed according to page
    // for now its adjusted for adding tags on partner merging page
    const save = () => {
        saveTags(selectedTags.map((tag: ITag) => {
            return tag.id
        }))
        setSelectedTags([])
        closeModal()
    }

    return (
        <Wrapper>
            <InputStyledContainer>
            <Dropdown dropdownHeightLimit={6}
                      text={"Type a tag to add"}
                      isOpened={open}
                      setOpened={setOpen}
                      helpBlock={"Using the field above, type out a tag you would like to use."}
                      search={{
                          value: searchTag,
                          onChange: setSearchTag,
                          placeholder: "Type a tag to add"
                      }}
                      headerClassName={'hide-caret'}
                      containerClassName={searchTag.length < 1 ? 'hidden' : ''}>
                {
                    filteredTags.map(tag => (
                        <DropdownItem
                            onClick={()=> {
                                handleTagSelect(tag)
                                setOpen(false)
                            }}
                            disabled={selectedTags.some(selectedTag => tag.id === selectedTag.id)}
                            key={tag.id}>
                            {tag.name}
                        </DropdownItem>
                    ))
                }
            </Dropdown>
            <div style={{marginTop: 32, marginBottom: 10}}>
                {selectedTags.map(tag => <SelectionPills key={tag.id}
                                                         text={tag.name}
                                                         onRemove={() => handleTagSelect(tag)}/>)}
            </div>
            </InputStyledContainer>
            <ModalFooter>
                <ButtonStyled className={'btn-cancel'} onClick={closeModal}>Cancel</ButtonStyled>
                <ButtonStyled className={"btn-orange-filled"}
                              disabled={selectedTags.length < 1} onClick={save}>
                    {addTag ? "Add Tags " : "Save Tags"}
                </ButtonStyled>
            </ModalFooter>
        </Wrapper>
    )
}

export default AddTagsModal
