import React, { useState} from "react";

import {Wrapper, Option} from "./Presets.style";

import {IPreset, IPresetProps, presets} from "./presets.props";

const Presets: React.FC<IPresetProps> = ({preset, setPreset}) => {

    const handlePresetSelect = (option: IPreset) => {
        setPreset(option)
    }

    return (
        <Wrapper>
            <div className={'presets-title'}>Date Presets</div>
            {presets.map(item => {
                return <Option key={item.value} active={preset.value === item.value} onClick={() => handlePresetSelect(item)}>{item.name}</Option>
            })}
        </Wrapper>
    )
}

export default Presets