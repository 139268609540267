import styled from 'styled-components'

export const Wrapper = styled.div`
    //width: 472px;

    .top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .title {
            font-size: 16px;
            color: ${({theme}) => theme.colors.deepBlue[7]};
        }
        margin-bottom: 8px;
    }
    
    .device-row {
        margin-top: 16px;
    }
    
    .delete-device-modal{
        max-width: 200px;
    }
    
    .no-devices-warning{
        color: ${({theme}) => theme.colors.performBlue[5]};
        margin-top: 16px;
    }
`