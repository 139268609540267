import styled, { css } from "styled-components";


export const Wrapper = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  &:hover{
    cursor: pointer;
  }
  
  span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sort-icon-down {
    display: ${({active}) => active ? "block" : "none"};
    position: relative;
    &:after {
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 0;
        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }
  }

  .sort-icon-up {
    display: ${({active}) => active ? "block" : "none"};
    position: relative;
    &:after {
        display: block;
        position: absolute; 
        top: -10px; 
        left: 0; 
        transform: rotate(180deg);

        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }
  }

  ${({active}) => !active && css`
  :hover {
    .sort-icon-down {
      display: block;
    }

    .sort-icon-up {
      display: block;
    }
  }
  `}
`

export const SortButtons = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  
  font-size: 9px;
  margin-left: 10px;
`

// export const SortIconDown = styled.div`
    /* position: relative; */
    /* &:after { */
        /* display: inline-block; */
        /* position: absolute; */
        /* top: -5px; */
        /* left: 0; */
        /* content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A"); */
    /* } */
// `;

// export const SortIconUp = styled.div`
    /* position: relative; */
    /* &:after { */
        /* display: block; */
        /* position: absolute;  */
        /* top: -10px;  */
        /* left: 0;  */
        /* transform: rotate(180deg); */
/*  */
        /* content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A"); */
    /* } */
// `;