import * as React from 'react';
import {FilterID, IAdminUserStatus} from "filters";
import {PageID} from "../../../../../store/filters.slice";
import {useAppSelector} from "../../../../../common/hooks";
import FilterSelect from "../../../../../common/components/filters/filter/filter-select/FilterSelect";
import {useUserRolesCacheFilter} from "../../../../../common/hooks/filters/cache-filters";
import Filter from "../../../../../common/components/filters/filter/Filter";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import {
    useAdminUserStatusCacheFilter
} from "../../../../../common/hooks/filters/cache-filters";
import {IRole} from "../../../../../common/models/user.type";

export interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: { id: FilterID, value: string }[] = [
    {
        id: 'user_status',
        value: 'Status',
    },
    {
        id: 'user_role',
        value: 'User Role'
    }
]
export const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const pageId: PageID = 'admin/users/admin'
    const {filters} = useAppSelector((state) => state.userPreferences[pageId])

    const userRolesCacheFilter = useUserRolesCacheFilter(pageId)
    const userStatusCacheFilter = useAdminUserStatusCacheFilter(pageId)

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case 'user_role':
                return (
                    <Filter
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        pageId={pageId}
                        filterId={userRolesCacheFilter.id}
                        selected={userRolesCacheFilter.selected.value}
                        filterTitle={"User Roles"}
                        selectedFilterText={userRolesCacheFilter.selected.value[0] ?
                            userRolesCacheFilter.selected.value[0].name : ''}
                        notDisplayed={userRolesCacheFilter.notDisplayed}
                        hasTopStickyContainer={false}
                        search={userRolesCacheFilter.search}>
                        {userRolesCacheFilter.options.map((option: IRole) => {
                            return <DropdownItem
                                key={option.id}
                                selected={userRolesCacheFilter.selected.value.some(
                                    selected => option.id === selected.id
                                )}
                                onClick={() => {
                                    userRolesCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}>
                                {option.name}
                            </DropdownItem>
                        })}

                    </Filter>
                )
            case 'user_status':
                return (
                    <Filter
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        hasTopStickyContainer={false}
                        notDisplayed={userStatusCacheFilter.notDisplayed}
                        selectedFilterText={userStatusCacheFilter.selected.value[0] ?
                            userStatusCacheFilter.selected.value[0].name : ""}
                        pageId={pageId}
                        filterId={userStatusCacheFilter.id}
                        selected={userStatusCacheFilter.selected.value}
                        filterTitle={"User Status"}>
                        {
                            userStatusCacheFilter.options.map((option: IAdminUserStatus) => {
                                return <DropdownItem
                                    key={option.id}
                                    selected={userStatusCacheFilter.selected.value.some(
                                        selected => option.id === selected.id
                                    )}
                                    onClick={() => {
                                        userStatusCacheFilter.selected.setSelected(option)
                                        setDiscoverDisplayed(true)
                                    }}>
                                    {option.name}
                                </DropdownItem>
                            })}
                    </Filter>
                )
            default:
                return <></>

        }
    }
    return (
        <FilterSelect
            setDiscoverDisplayed={setDiscoverDisplayed}
            allFilters={filterList}
            pageId={pageId}>
            {
                Object.keys(filters).map((filterKey: any) => {
                    return <div key={filterKey}>{renderFilter(filterKey)}</div>
                })}
        </FilterSelect>
    );
};