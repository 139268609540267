import { useEffect, useState } from "react";
import InformationTable from "../../../../../../common/components/information-table/InformationTable"
import { InfoLabel } from "../../../../../../common/components/information-table/InformationTable.style"

const FirstSectionInfoTable = ({editingUser}: any) => {
        const [user, setUser] = useState(editingUser);

        useEffect(() => {
            setUser(editingUser);
        },[editingUser]);

        const dateFormat = (date: any) => {
            //regex for safari browser
            return date.replace(/-/g, "/")
        }

        const getCakeID = (networks: any) => {
                if (typeof networks != "undefined" && networks.length > 0) {
                        let cake_ids = '';
                        networks.forEach((value: any, key:any) => {
                                cake_ids = cake_ids + value['affiliate_id'];
                                if (networks.length - 1 !== key) {
                                        cake_ids = cake_ids + ", ";
                                }
                        });
                        return cake_ids;
                }
                return null;
        }

        if(!user)
        {
            return (
                <InformationTable>
                    <InfoLabel>
                        <span>Cake ID:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Account Created On:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Account Last Login On:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Account ID:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Source:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Address:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Requested account manager:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Accepted Terms & Conditions on</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>GDPR Consent Given:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>IP:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Country:</span>
                        <span>""</span>
                    </InfoLabel>
                    <InfoLabel>
                        <span>Browser / Device:</span>
                        <span>""</span>
                    </InfoLabel>
                </InformationTable>
            )
        }


        return (
            <InformationTable>
                <InfoLabel>
                        <span>Cake ID:</span>
                        <span>{user.clickbooth_networks ? getCakeID(user.clickbooth_networks) : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Account Created On:</span>
                        <span>{
                        user.created_at ? dateFormat(user.created_at) : ""
                        }</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Account Last Login On:</span>
                        <span>{user.last_login_on ? dateFormat(user.last_login_on) : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Account ID:</span>
                        <span>{user.id ? user.id : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Source:</span>
                        <span>{user.registration_info ? user.registration_info.source ? user.registration_info.source : "" : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Address:</span>
                        <span>{user.paymentinfo ? user.paymentinfo.address ? user.paymentinfo.address : "" : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Requested account manager:</span>
                        <span>{ user.account_executive ? user.account_executive : "" }</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Accepted Terms & Conditions on</span>
                        <span>{user.created_at ? dateFormat(user.created_at) : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>GDPR Consent Given:</span>
                        <span>{  user.registration_info ? user.registration_info.gdpr_consent === true ? 'TRUE' : 'FALSE' : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>IP:</span>
                        <span>{user.registration_info ? user.registration_info.remote_addr ? user.registration_info.remote_addr : "" : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Country:</span>
                        <span>{user.registration_info ? user.registration_info.country ? user.registration_info.country : "" : ""}</span>
                </InfoLabel>
                <InfoLabel>
                        <span>Browser / Device:</span>
                        <span>{user.registration_info ? user.registration_info.user_agent ? user.registration_info.user_agent : "" : ""}</span>
                </InfoLabel>
            </InformationTable>
        )
}

const SecondSectionInfoTable = ({editingUser}: any) => {
        const [user, setUser] = useState(editingUser);  

        useEffect(()=>setUser(editingUser), [editingUser]);
        
        if(!user) return null;
        
        return (
            <InformationTable>
                    <InfoLabel>
                            <span>Facebook</span>
                            <span>{user.registration_info ? user.registration_info.facebook ? user.registration_info.facebook : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>Linkedin</span>
                            <span>{user.registration_info ? user.registration_info.linkedin ? user.registration_info.linkedin : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>IM Type</span>
                            <span>{user.registration_info ? user.paymentinfo.im_type ? user.paymentinfo.im_type : "" : "" }</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>IM</span>
                            <span>{ user.registration_info ? user.paymentinfo.im ? user.paymentinfo.im : "" : "" }</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>How did you hear about us?:</span>
                            <span>{user.registration_info ? user.registration_info.heard_from ? user.registration_info.heard_from : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>How long have you been running:</span>
                            <span>{user.registration_info ? user.registration_info.longevity ? user.registration_info.longevity : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>Which other networks do you run with:</span>
                            <span>{user.registration_info ? user.registration_info.other_networks ? user.registration_info.other_networks : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>What other Tracking Platform(s) do you use:</span>
                            <span>
                                {
                                user.registration_info ? 
                                        user.registration_info.tracking_platform  ? 
                                                typeof(user.registration_info.tracking_platform) === "string" ?
                                                user.registration_info.tracking_platform :
                                                user.registration_info.tracking_platform.map((item: string, index: number) => 
                                                        `${item}${index === (user.registration_info.tracking_platform.length - 1) ? "" : ", "}`) : "" : ""
                                }
                            </span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>What traffic sources do you accept:</span>
                            <span>{user.registration_info ? user.registration_info.traffic_sources ? user.registration_info.traffic_sources : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>Additional information (payouts, budgets, etc):</span>
                            <span>{user.registration_info ? user.registration_info.additional_information ? user.registration_info.additional_information : "" : ""}</span>
                    </InfoLabel>
                    <InfoLabel>
                            <span>What best describes you:</span>
                            <span>{user.registration_info ? user.registration_info.description ? user.registration_info.description : "" : ""}</span>
                    </InfoLabel>
                </InformationTable>
        )
}

export {FirstSectionInfoTable, SecondSectionInfoTable};