import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";

interface Props {}

const TrackingMethode: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    const domainTrackingFlag = useFeatureFlags('domain-tracking-code', offer.niche ? offer.niche.network_id : undefined)

    if(offer.legacy_tracking === null || offer.detailed_pricing_model === 'ppc') return null

    return (
        <>
            <Subtitle>Tracking Method</Subtitle>
            {offer.direct_linking === true ?
                <>
                    <Item>Universal Outcome Pixel</Item>
                </> : <>
                    <Item>{offer.legacy_tracking === true ? 'Legacy' : 'Universal Outcome Pixel'}</Item>
                </>
            }
        </>
    )
}

export default TrackingMethode