import styled from "styled-components";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Tag = styled.span`
    padding: 4px 8px;
    margin: 0 5px 0 0;
    display: inline-flex;
    /* Cool Gray/200 */
    background: ${({theme}) => theme.colors.coolGray[2]};
    /* Cool Gray/300 */
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    line-height: 1;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
`

export const Separator = styled.div`
  height: 1px;
  background: ${({theme}) => theme.colors.coolGray[3]};
  margin: 8px 0;
`

export const DeleteDropdownItem = styled(DropdownItem)`
  color: ${({theme}) => theme.colors.negative[5]};
  user-select: none;

  :hover {
    background: ${({theme}) => theme.colors.negative[5]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  :hover:active {
    background-color: ${({theme}) => theme.colors.negative[7]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
`
