import styled, {css} from 'styled-components'
import {DropdownContainer} from "../../../../../../common/components/dropdown/Dropdown.style";
import {InputSingle} from "../../../../../../common/components/input-single-line/InputSingleLine.style";

interface IWrapperStyleProps {
    $opened: boolean,
    $overflow: boolean,
    $disabled: boolean,
    $noneSelected: boolean
}

interface IFilterTypeHeaderProps {
    $opened: boolean
}

interface IFilterTypeContainerProps {
    $top: number
    $left: number | undefined
}

interface IIncludeExcludeItemProps {
    $active: boolean
}

export const Wrapper = styled.div<IWrapperStyleProps>`
  position: relative;
  display: flex;
  align-items: center;

  .ico-Exit {
    position: absolute;
    color: #8799A2;
    font-size: 15px;
    right: 12px;
    top: 18px;
    cursor: pointer;
    &:hover {
      color: #EF4A24;
    }
  }
  
  .filter-header{
    min-height: 32px;
    height: 32px;
    font-size: 12px;
    //width: 72px;
    background-color: white;
    gap: 12px;

    ${props => props.$disabled && css`
        border: 1px solid #e2e2e2;
        color: #b9b9b9;
        background: #e8e8e8;
        pointer-events: none;
  `}
    

    :hover{
      background-color: #F3F7F8;
      transition: all .2s ease-in-out;
      cursor: pointer;
      border-color: ${({theme}) => theme.colors.coolGray[4]};
      color: ${({theme}) => theme.colors.deepBlue[4]};
    }

    ${(props) => props.$opened && css`
      background-color: ${props.theme.colors.deepBlue[2]};
      border: 1px solid ${props.theme.colors.deepBlue[3]};
      color: ${props.theme.colors.deepBlue[6]};
      
      :hover {
        background-color: ${props.theme.colors.deepBlue[2]};
        border: 1px solid ${props.theme.colors.deepBlue[3]};
        color: ${props.theme.colors.deepBlue[6]};
      }
    `}
    
    
    .acc-manager{
      color: ${({theme, $opened}) => $opened ? theme.colors.performBlue[7] : theme.colors.performBlue[5]};
      /* margin-left: 10px; */
    }
    
    .include-exclude--filter{
      font-family: ${({theme}) => theme.fonts.AvenirLightItalic};
    }
    
    .selected--manager{
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      color: ${({theme}) => theme.colors.deepBlue[6]};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .ico-FiltersOutlined{
      font-size: 13px;
      
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 897px) {
      min-width: unset !important;
      width: 100%;
    }
  }
  
  .filter-container{
    width: ${({$noneSelected})=> $noneSelected ? '272': '320'}px;
    max-height: 370px;
    overflow-y: ${({$overflow}) => $overflow ? 'visible' : 'auto'};
    .filter-container--filter-by{
      display: flex;
      justify-content: space-between;
      position: relative;
      h3{
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 897px) {
     flex-direction: column;
     align-items: flex-start;
     gap: 15px;
  }
`

export const FilterTypeWrapper = styled.div`
  position: relative;
`

export const FilterTypeHeader = styled.div<IFilterTypeHeaderProps>`
  height: 24px;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 8px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  display: flex;
  align-items: center;
  gap: 12px;
  
  :hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.coolGray[2]};
  }
  
  ${props => props.$opened && css`
    background-color: ${({theme}) => theme.colors.deepBlue[2]};
    color: ${({theme}) => theme.colors.deepBlue[6]};
    :hover{
      background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }
  `}
`

export const FilterButtonWrapper = styled.div`
  display: flex;
  gap: 5px;

  @media screen and (max-width: 897px) {
    width: 100%;
  }
`;

export const FilterTypeContainer = styled(DropdownContainer)<IFilterTypeContainerProps>`
  position: absolute;
  width: fit-content;
  z-index: 300;
  top: 25px;
  right: 0;
  left: auto;
  //transform: translate(-25px, 25px);
`

export const AccManagerSearchInput = styled(InputSingle)`
  height: 32px;
  margin-top: 10px;
  margin-bottom: 8px;
`



export const ManagerListContainer = styled.div`
  width: 100%;
  .manager-checkbox-wrapper{
    padding: 9px;
    border-radius: 8px;
    :hover{
      background-color: ${({theme}) => theme.colors.performBlue[1]};
    }
  }
`

export const ClearSelected = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  position: sticky;
  bottom: 0;
  background-color: white;
  span{
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    
    :hover {
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[5]};
    }
    
    :hover:active{
      color: ${({theme}) => theme.colors.deepBlue[8]};
    }
  }
  padding-bottom: 16px;
  transform: translateY(16px);
`

export const IncludeExcludeWrapper = styled.div`
  width: 100%;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  display: flex;
`

export const IncludeExcludeItem = styled.div<IIncludeExcludeItemProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  
  :hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.performBlue[1]};
  }
  
  ${(props) => props.$active && css`
    background-color: ${props.theme.colors.performBlue[5]};
    color: white;

    :hover{
      cursor: default;
      background-color: ${({theme}) => theme.colors.performBlue[5]};
    }
  `}
`

export const StickyFilterWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 16px;
  transform: translateY(-16px);
  z-index: 2;
  
`

export const AccountManagersNotShown = styled.div`
  font-family: ${({theme}) => theme.fonts.AvenirItalic};
  color: ${({theme}) => theme.colors.coolGray[5]};
  margin-left: 6px;
  margin-top: 3px;
`

export const AccountManagerListTooltipWrapper = styled.div`
  z-index: 1000;
  background: white;
  position: absolute;
  top: 38px;
  right: 20px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  
  .item{
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-size: 12px;
  }
`

export const ClearFiltersButton = styled.div`
  white-space:nowrap;
  margin-left: 8px;
  color: ${({theme}) => theme.colors.performOrange[3]};
  font-size: 12px;
  
  :hover{
    color: ${({theme}) => theme.colors.performOrange[5]};
    cursor: pointer;
  }
  
  :hover:active{
    color: ${({theme}) => theme.colors.performOrange[7]};
  }
`

