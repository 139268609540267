import {IFilter, IFiltersInitialState, PageID} from "../../store/filters.slice";
import {useAppSelector} from "./useAppSelector";
import {useCallback, useState} from "react";
import {FilterID} from "filters";

export const useFilter = (pageID: PageID) => {
    const {filters}: IFiltersInitialState<unknown> = useAppSelector<IFiltersInitialState<unknown>>(state => state.filters)
    return useCallback(<T>(filterID: FilterID) => {
        if(filters.some(filter => filter.pageID === pageID)){
            const pageIndex = filters.findIndex(filter => filter.pageID === pageID)
            //Check if there is filter on selected page with selected filterID
            if(filters[pageIndex].filters.some(filter => filter.id === filterID)){
                const filterIndex = filters[pageIndex].filters.findIndex(filter => filter.id === filterID)
                return filters[pageIndex].filters[filterIndex] as IFilter<T>
            }
        }
        return null
    }, [filters, pageID])
}