import { useAppSelector } from "../../../../../../common/hooks";
import { WidgetWrapper } from "../../agency-view/AgencyView.style";
import TopPerformersMarketer from "./top-performers-tables/TopPerformersMarketer";
import TopPerformersOffer from "./top-performers-tables/TopPerformersOffer";
import TopPerformersPartner from "./top-performers-tables/TopPerformersPartner";
import TopPerformersToolbar from "./top-performers-toolbar/TopPerformersToolbar";
import { TopPerformersWrapper } from "./TopPerformers.style";
import TopPerformersMarketerAccountManager from "./top-performers-tables/TopPerformersMarketerAccountManager";
import TopPerformersPartnerAccountManagers from "./top-performers-tables/TopPerformersPartnerAccountManagers";

const TopPerformers = () => {

    const {
        topPerformersAnalyzeBy
    } = useAppSelector(state => state.networkView);

    return(
        <WidgetWrapper>
            <TopPerformersWrapper>
                <h3 className="title">Top Performers <span className="heading" >(Month to Date)</span></h3>
                <TopPerformersToolbar />
                {topPerformersAnalyzeBy === "advertisers" && <TopPerformersMarketer />}
                {topPerformersAnalyzeBy === "affiliates" && <TopPerformersPartner />}
                {topPerformersAnalyzeBy === "offers" && <TopPerformersOffer />}
                {topPerformersAnalyzeBy === "top_marketer_acc_managers" && <TopPerformersMarketerAccountManager />}
                {topPerformersAnalyzeBy === "top_partner_acc_managers" && <TopPerformersPartnerAccountManagers />}
            </TopPerformersWrapper>
        </WidgetWrapper>
    )
}

export default TopPerformers;