import styled from 'styled-components'
import {TNicheName} from "../NetworkView.service";

interface ILegendItemProps {
    $type: TNicheName
}

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 24px;
  position: relative;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);
  /* height: fit-content; */

  .title {
    line-height: 21px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 24px;
  }

`

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.85);
  border-radius: 16px;
`

export const ChartSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 760px) {
        align-items: center;
    }

`

export const Chart = styled.div`
  width: 100%;
    margin-bottom: 50px;
  
  .highcharts-tooltip {
    z-index: 99999!important;
    
    .hc-tooltip-wrapper {
      position: relative;
      padding: 5px;
      z-index: 1;
      
      &:before {
        content: "";
        position: absolute;
        height: calc(100% + 16px);
        width: calc(100% + 30px);
        top: -8px;
        left: -8px;
        background: #fafafa;
        z-index: 0;
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
      
      .hc-tooltip-inner {
        .hc-tooltip-title {
          font-size: 10px;
          font-family: ${({theme}) => theme.fonts.AvenirLight};
          color: #31383B;
          margin-bottom: 4px;
        }
        
        .tooltip-value-item {
          display: flex;
          align-items: center;
          font-size: 10px;
          position: relative;
          z-index: 1;
          margin-top: 4px;
          .marker {
            border-radius: 2px;
            background: gray;
            margin-right: 5px;
            height: 8px;
            width: 8px;
          }
          
          
          
          strong{
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};

          }
        }
        .tooltip-value{
          margin-left: 13px;
        }
      }
      
      h1 {
        position: relative;
        z-index: 1;
      }
      
      div {
        position: relative;
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 760px) {
    width: max-content;
  }
`

export const LegendSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 250px;
  padding: 0 10px;
  justify-content: space-between;
  @media screen and (max-width: 760px) {
    width: 80%;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
    & > div {
      width: 48%;
    }
  }

  @media screen and (max-width: 630px) {
    width: 100%;
    & > div {
      width: 48%;
    }
  }

  @media screen and (max-width: 474px) {
    display: block;
    height: unset;
    & > div {
      width: 200px;
      margin: 0 auto 10px;
    }
  }
`

export const LegendItem = styled.div<ILegendItemProps>`
  display: flex;
  gap: 8px;
  .box {
    margin-top: 4px;
    height: 12px;
    width: 12px;
    //background: linear-gradient(180deg, #9563FF 0%, #0B05AC 100%);
    background: ${({$type}) => {
      if($type === 'Downloads & Software'){
          return 'linear-gradient(180deg, #9563FF 0%, #0B05AC 100%)'
      }
      if($type === 'Entertainment & Lifestyle'){
        return 'linear-gradient(0deg, #002482 7.41%, #0038FF 100%)'
      }
      if($type === 'Financial'){
        return 'linear-gradient(0deg, #006A97 0%, #00E0FF 100%)'
      }
      if($type === 'Healthy & Beauty'){
        return 'linear-gradient(0deg, #009718 0%, #BFF103 100%)'
      }
      if($type === 'E-Commerce'){
        return 'linear-gradient(0deg, #A84000 0%, #FFA800 100%)'
      }
       return 'linear-gradient(360deg, #616E6F 0%, #B5C1C2 100%)'   
    }};
    border-radius: 2px;
  }
  
  .legend-name{
    font-size: 14px;
    color: ${({theme}) => theme.colors.deepBlue[6]};
  }
  
  .legend-value{
    font-size: 16px;
    color: #5F35FF;
  }
  
  .legend-percentage{
    font-size: 14px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
`