import React, {useEffect, useState} from 'react'

import {Wrapper, } from './CreatePixelGroup.style'
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../../../../common/styled-components";
import {ModalFooter} from "../../../../../../../../common/components/modal/container/Container.style";
import Input from "../../../../../../../../common/components/input/Input";
import Dropdown from "../../../../../../../../common/components/dropdown/Dropdown";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import CreateOfferService from "../../../../CreateOffer.service";
import {fetchPixelGroups, setOffer} from "../../../../create-offer.slice";

interface Props {
    groupId: number | undefined,
    type: 'upsell' | 'offer'
    closeModal: () => void,
    eventId?: number
}

const EditPixelGroup: React.FC<Props> = ({groupId, closeModal, type, eventId}) => {
    const [groupName, setGroupName] = useState('')
    const [groupNameError, setGroupNameError] = useState<string>()

    const [group, setGroup] = useState<any>()

    const [loading, setLoading] = useState(false)

    const {offer} = useAppSelector(state => state.createOffer)
    const {token} = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === ''){
            setGroupNameError('The name field is required')
        } else {
            setGroupNameError(undefined)
        }
        setGroupName(e.target.value)
    }

    const handleGroupNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if(e.target.value === ''){
            setGroupNameError('The name field is required')
        }
    }

    const fetchPixelGroup = async () => {
        if(groupId) {
            try{
                setLoading(true)
                const {data} = await CreateOfferService.fetchPixelGroup(token, groupId)
                setGroup(data)
                setGroupName(data.name)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        }
    }

    const editGroup = async () => {
        if(groupId) {
            const params = {
                ...group,
                name: groupName,
            }
            try{
                setLoading(true)
                const {data} = await CreateOfferService.editPixelGroup(token, groupId, params)

                const {payload} = await dispatch(fetchPixelGroups({}))
                if(type === 'offer'){
                    const newSelectedOfferGroup = payload.offer.filter((group: any) => group.id === data.id)

                    if(newSelectedOfferGroup){
                        dispatch(setOffer({
                            pixel_group_id: newSelectedOfferGroup[0].id,
                            pixel_group: newSelectedOfferGroup[0]
                        }))
                    }
                }

                if(type === 'upsell'){
                    const newSelectedOfferGroup = payload.upsell.filter((group: any) => group.id === data.id)
                    const newEvents = [...offer.upsells]
                    const index = newEvents.findIndex(event => event.id === eventId)
                    newEvents[index] = {...offer.upsells[index], pixel_group: newSelectedOfferGroup[0], pixel_group_id: newSelectedOfferGroup[0].id}
                    dispatch(setOffer({
                        upsells: [...newEvents]
                    }))
                }

                closeModal()
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        void fetchPixelGroup()
    }, [])

    return <Wrapper>
        <Input
            label={'Group Name'}
            value={groupName}
            onChange={(e) => handleGroupNameChange(e)}
            error={groupNameError}
            onBlur={(e) => handleGroupNameBlur(e)}
        />

        <Dropdown text={type === 'offer' ? 'Offer' : 'Event'} disabled={true} label={'Group Type'} />

        <div className="description">
            <p>This will create global pixels that can be used for all {type === 'offer' ? 'offers' : 'events'} in this Group.</p>
            <p><br/></p>
            <p>These pixels must be placed on your conversion pages in order for our system to track the conversions.</p>
        </div>

        <ModalFooter>
            <ButtonStyled onClick={() => closeModal()}>Cancel</ButtonStyled>
            <ButtonStyled
                className={'btn-orange-filled'}
                disabled={groupName === '' || loading}
                onClick={() => editGroup()}
            >
                {loading && <BtnLoadingSpinner/>}Update
            </ButtonStyled>
        </ModalFooter>
    </Wrapper>
}

export default EditPixelGroup