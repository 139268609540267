import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {SingleValue} from "react-select";
import {IUser} from "../../../../../common/models/user.type";
import {IM_TYPES, QF_VISIBILITY, SHIELD_VISIBILITY} from "../../../../../common/utils/consts";
import {IAlertModal} from "../../../../../common/models/common.type";
import {ErrorStyled, StyledInput, Wrapper} from "../../../../../common/components/input/Input.style";
import {ButtonStyled} from "../../../../../common/styled-components";
import FiltersService from "../../../../../common/services/filters.service";
import {useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import avatar from "../../../../../../src/assets/images/avatar.png"
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";
import {SelectOption, StyledSelect} from "../../../../../common/components/select/Select";
import {FormCheckbox} from "../../../../../common/components/checkbox/Checkbox.style";
import {
    Avatar,
    AvatarThumbnail,
    Form,
    FormColumn,
    FormRow,
    GeneratePassword,
    UploadContainer
} from "./CreateOrEditUserModal.style";
import UsersService, {ICreateUser} from "../Users.service";
import {isNullOrUndefined} from "util";


export interface Props {
    createUser: boolean;
    user: IUser;
    closeModal: () => void;
    setAlertModal: Dispatch<SetStateAction<IAlertModal>>,
    fetchUsers: () => void,
}

// upload image url
// http://localhost:8080/api/v1/users/undefined/photo_upload

// full_profile_image  : "http://localhost:8080/uploads/user_images/2022/12//167231316487.jpg"
// profile_image : 2022/12/167231316487.jpg


export const CreateOrEditUserModal: React.FC<Props> = ({
                                                           createUser,
                                                           user,
                                                           closeModal,
                                                           setAlertModal,
                                                           fetchUsers
                                                       }) => {
    const token = useAppSelector(tokenStore)
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [roles, setRoles] = useState<{ value: number, label: string } []>([])
    const [mediaChecked, setMediaChecked] = useState<boolean>(createUser ? false
        : user.approve_sector ? !!user.approve_sector.media : false)
    const [complianceChecked, setComplianceChecked] = useState<boolean>(createUser ? false
        : user.approve_sector ? !!user.approve_sector.compliance : false)

    const checkQFVisibility = (user: IUser): number => {
        if (user.role.other) {
            if (user.role.other.adjust_throttling.full?.indexOf(user.id) >= 0)
                return 0
            if (user.role.other.adjust_throttling.readonly.indexOf(user.id) >= 0)
                return 1
        }
        return 2
    }

    const onButtonClick = () => {
        document.getElementById('inputAvatarHidden')!.click();
    };


    const checkShieldVisibility = (user: IUser): number => {
        if (user.role.other) {
            if (user.role.other.adjust_shield.full.indexOf(user.id) >= 0) {
                return 0
            }
        }
        return 1
    }

    const checkUserAvatar = (path: string): boolean => {
        return path.endsWith('.jpg') || path.endsWith('.jpeg') || path.endsWith('.png');
    }

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        getValues,
        setError,
        formState: {isDirty, errors, dirtyFields},
    } = useForm<ICreateUser>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            name: createUser ? "" : user.name ? user.name : "",
            lastname: createUser ? "" : user.lastname ? user.lastname : "",
            new_password: '',
            email: createUser ? "" : user.email ? user.email : "",
            role_id: createUser ? 0 : user.role_id ? user.role_id : 0,
            phone: createUser ? "" : user.phone ? user.phone : "",
            qf_visibility: createUser ? {
                is_selected: false,
                name: 'None (default for new users)',
                value: 'none'
            } : {
                is_selected: false,
                name: QF_VISIBILITY[checkQFVisibility(user)].label,
                value: QF_VISIBILITY[checkQFVisibility(user)].value
            },
            shield_visibility: createUser ? {
                is_selected: false,
                name: 'Read-Only permission',
                value: 'readonly'
            } : {
                is_selected: false,
                name: SHIELD_VISIBILITY[checkShieldVisibility(user)].label,
                value: SHIELD_VISIBILITY[checkShieldVisibility(user)].value
            },
            approve_sector: {
                media: createUser ? 0 : (user.approve_sector === null || user.approve_sector === undefined) ? 0 : user.approve_sector.media ? 1 : 0,
                compliance: createUser ? 0 : (user.approve_sector === null || user.approve_sector === undefined) ? 0 : user.approve_sector.compliance ? 1 : 0
            },
            paymentinfo: createUser ? {
                imType: '',
                im: ''
            } : user.paymentinfo ? {
                imType: user.paymentinfo.im_type,
                im: user.paymentinfo.im
            } : {
                imType: '',
                im: ''
            },
            profile_image: createUser ? "" : user.profile_image ? user.profile_image : "",
            full_profile_image: createUser ? avatar : checkUserAvatar(user.full_profile_image) ? user.full_profile_image : avatar,
            compliance_checked: (createUser ? 0 : (user.approve_sector === null || user.approve_sector === undefined) ? 0 : user.approve_sector.compliance ? 1 : 0) === 1
        },
        criteriaMode: 'firstError',
        shouldFocusError: true,
    });

    const fullImage:any = watch('full_profile_image')

    const onSubmit: SubmitHandler<ICreateUser> = async data => {
        if (createUser) {
            let params: ICreateUser = {
                name: data.name,
                lastname: data.lastname,
                email: data.email,
                new_password: data.new_password,
                phone: data.phone,
                role_id: data.role_id,
                qf_visibility: {
                    is_selected: false,
                    name: QF_VISIBILITY.find(qf => qf.value === data.qf_visibility.value)!.label,
                    value: data.qf_visibility.value
                },
                shield_visibility: {
                    is_selected: false,
                    name: SHIELD_VISIBILITY.find(shield => shield.value === data.shield_visibility.value)!.label,
                    value: data.shield_visibility.value
                }
            }
            if (data.paymentinfo) {
                params.paymentinfo = {
                    imType: data.paymentinfo.imType,
                    im: data.paymentinfo.im
                }
            }

            if (mediaChecked || getValues('compliance_checked')) {
                params.approve_sector = {
                    media: mediaChecked ? 1 : 0,
                    compliance: getValues('compliance_checked') ? 1 : 0
                }
            }
            if (data.profile_image) {
                params.profile_image = data.profile_image
            }
            if (data.full_profile_image) {
                params.full_profile_image = data.full_profile_image
            }

            try {
                let response = await UsersService.createUser(token, params)
                if (response.status === 200) {
                    setAlertModal({
                        opened: true,
                        message: 'User successfully created!',
                        type: 'success'
                    })
                    fetchUsers()
                    closeModal()
                } else {
                    setAlertModal({
                        opened: true,
                        message: 'Missing data!',
                        type: 'error'
                    })
                }
            } catch (error) {
                console.log(error)
                setAlertModal({
                    opened: true,
                    message: 'Error while updating!',
                    type: 'error'
                })
            }

        } else {
            let tempUser: IUser = {...user}
            tempUser.name = data.name
            tempUser.lastname = data.lastname
            tempUser.email = data.email
            tempUser.role_id = data.role_id
            tempUser._method = "PATCH"
            if (data.phone != null) {
                tempUser.phone = data.phone
            }
            tempUser.new_password = data.new_password
            tempUser.shield_visibility = {
                is_selected: true,
                name: SHIELD_VISIBILITY.find(shield => shield.value === data.shield_visibility.value)!.label,
                value: data.shield_visibility.value
            }
            tempUser.qf_visibility = {
                is_selected: true,
                name: QF_VISIBILITY.find(qf => qf.value === data.qf_visibility.value)!.label,
                value: data.qf_visibility.value
            }
            if (data.paymentinfo) {
                if(tempUser.paymentinfo)
                {
                    tempUser.paymentinfo.im_type = data.paymentinfo.imType
                    tempUser.paymentinfo.im = data.paymentinfo.im
                }else{
                    tempUser = {...tempUser,paymentinfo:{im_type:data.paymentinfo.imType,im:data.paymentinfo.im}}
                }
            }

            tempUser.approve_sector = {
                media: mediaChecked ? 1 : 0,
                compliance: getValues('compliance_checked') ? 1 : 0
            }

            if (data.profile_image) {
                tempUser.profile_image = data.profile_image
            }
            if (data.full_profile_image) {
                tempUser.full_profile_image = data.full_profile_image + "/" + data.profile_image
            }
            try {
                let response = await UsersService.updateUser(token, tempUser)
                if (response.status === 200) {
                    setAlertModal({
                        opened: true,
                        message: 'User successfully created!',
                        type: 'success'
                    })
                    fetchUsers()
                    closeModal()
                } else {
                    setAlertModal({
                        opened: true,
                        message: 'Missing data!',
                        type: 'error'
                    })
                }
            } catch (e) {
                console.log(e)
                setAlertModal({
                    opened: true,
                    message: 'Error while updating!',
                    type: 'error'
                })
            }
        }

    }

    const generatePassword = () => {
        setValue('new_password', Math.random().toString(36).slice(-12),{ shouldDirty: true })
    }

    // IIFE
    useEffect(() => {
        (async () => {
                const {data} = await FiltersService.fetchUserRoles(token)
                setRoles(data.map(item => ({value: item.id, label: item.name})))
            }
        )()
    }, [])

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.files && event.target.files.length > 0) {
                const response = await UsersService.uploadImage(token, user.id, event.target.files[0])
                if (response.status === 200) {
                    setValue('full_profile_image', response.data.path + "/" + response.data.profile_image,{ shouldDirty: true })
                    setValue('profile_image', response.data.profile_image,{ shouldDirty: true })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Form>
                <FormRow>
                    <FormColumn>
                        <Wrapper className={'form-control'} error={!!errors.name}>
                            <StyledInput placeholder={"First name"}
                                         {...register("name", {required: true})} />
                            {errors.name && <ErrorStyled leftAlign={true}>This field is required</ErrorStyled>}
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                        <Wrapper className={'form-control'} error={!!errors.lastname}>
                            <StyledInput placeholder={"Last name"}
                                         {...register("lastname", {required: true})} />
                            {errors.lastname && <ErrorStyled leftAlign={true}>This field is required</ErrorStyled>}
                        </Wrapper>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <Wrapper className={'form-control'} error={!!errors.new_password}>
                            <StyledInput placeholder={"New password"}
                                         {...register("new_password")} />
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                        <GeneratePassword>
                            <a onClick={() => generatePassword()} href={"#!"}>Generate password</a>
                        </GeneratePassword>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <Wrapper className={'form-control'} error={!!errors.email}>
                            <StyledInput placeholder={"Email"}
                                         {...register("email", {
                                             required: true,
                                             pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                         })}/>
                            {errors.email && <ErrorStyled leftAlign={true}>This field is required</ErrorStyled>}
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                        <Wrapper className={'form-control'} error={!!errors.role_id}>
                            <Controller
                                name={"role_id"}
                                control={control}
                                render={
                                    ({
                                         field: {onChange, value, name, ref}
                                    }) => (
                                        <StyledSelect
                                            defaultValue={createUser ? {
                                                label: "Role",
                                                value: undefined
                                            } : {
                                                label: user.role.name,
                                                value: user.role.id,
                                            }}
                                            placeholder={"Role"}
                                            options={roles}
                                            onChange={(val) => onChange((val as SingleValue<SelectOption>)?.value)}
                                        />
                                    )}
                            />
                            {errors.role_id && <ErrorStyled leftAlign={true}>This field is required</ErrorStyled>}
                        </Wrapper>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <Wrapper error={!!errors.paymentinfo?.imType} className={'form-control'}>
                            <Controller
                                control={control}
                                name={"paymentinfo.imType"}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <StyledSelect
                                        classNamePrefix={'styled-select'}
                                        placeholder={"IM Type"}
                                        required={true}
                                        options={IM_TYPES}
                                        onChange={(val) => onChange((val as SingleValue<SelectOption>)?.value)}/>
                                )
                                }/>
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                        <Wrapper error={!!errors.paymentinfo} className={'form-control'}>
                            <StyledInput placeholder={"IM"} {...register('paymentinfo.im')} />
                        </Wrapper>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <Wrapper error={!!errors.phone} className={'form-control'}>
                            <StyledInput placeholder={"Phone"} {...register('phone')} />
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                        <Wrapper error={!!errors.qf_visibility?.value} className={'form-control'}>
                            <Controller
                                control={control}
                                name={'qf_visibility.value'}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <StyledSelect
                                        selectLabel={"QF Visibility"}
                                        classNamePrefix={'styled-select'}
                                        defaultValue={createUser ? QF_VISIBILITY[2] : QF_VISIBILITY[checkQFVisibility(user)]}
                                        placeholder={'QF Visibility'}
                                        options={QF_VISIBILITY}
                                        onChange={(val) => onChange((val as SingleValue<SelectOption>)?.value)}/>
                                )}/>
                        </Wrapper>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <Wrapper error={!!errors.shield_visibility?.value} className={'form-control'}>
                            <Controller
                                control={control}
                                name={'shield_visibility.value'}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <StyledSelect
                                        selectLabel={"Shield Visibility"}
                                        classNamePrefix={'styled-select'}
                                        defaultValue={createUser ?
                                            SHIELD_VISIBILITY[1] :
                                            SHIELD_VISIBILITY[checkShieldVisibility(user)]
                                        }
                                        placeholder={'Shield Visibility'}
                                        options={SHIELD_VISIBILITY}
                                        onChange={(val) => onChange((val as SingleValue<SelectOption>)?.value)}/>
                                )}/>
                        </Wrapper>
                    </FormColumn>
                    <FormColumn>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <FormCheckbox>
                            <input {...register("compliance_checked")} type="checkbox" className="custom-control-input" id="compliance_checked" />
                            <label className="custom-control-label" htmlFor="compliance_checked">Compliance</label>
                        </FormCheckbox>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        <UploadContainer>
                            <AvatarThumbnail>
                                <Avatar id={"avatarPlaceholder"} {...register("full_profile_image")} style={{backgroundImage: `url(${getValues('full_profile_image')})`}}/>
                            </AvatarThumbnail>
                            <input
                                style={{display: "none"}}
                                accept=".jpg,.jpeg,.png"
                                id={"inputAvatarHidden"}
                                onChange={handleFileUpload}
                                type="file"
                            />
                            <ButtonStyled className={"btn-blue-filled"} type={'button'}
                                          onClick={onButtonClick}>Browse</ButtonStyled>
                        </UploadContainer>
                    </FormColumn>
                </FormRow>
                <ModalFooter>
                    <ButtonStyled className={'btn-close'} onClick={closeModal}>Close</ButtonStyled>
                    <ButtonStyled className={'btn-orange-filled'} type="submit" disabled={!isDirty}>
                        {createUser ? "Create user" : "Update user data"}
                    </ButtonStyled>
                </ModalFooter>
            </Form>
        </form>
    );
};