import React, {useEffect, useMemo} from 'react'
import Highcharts, {SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {Wrapper, LoadingOverlay} from './AnalyzePerformance.style'
import {formatNumberToCommaSeparated} from "../../../../../../common/utils/formatNumberToCommaSeparated";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import {format} from "date-fns";
import Loading from "../../../../../../common/components/loading/Loading";

interface Props {
    thisYearProfit: number[],
    lastYearProfit: number[],
    loading: boolean
}

const AnalyzePerformance: React.FC<Props> = ({thisYearProfit, lastYearProfit, loading}) => {

    const series: SeriesOptionsType[] = useMemo(() => [
        {
            name: 'Profit (Last Year)',
            data: lastYearProfit,
            type: 'column',
            yAxis: 0,
            color: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, 'rgba(47, 230, 255, 1)'],
                    [1, 'rgba(0, 127, 182, 1)'],
                ]
            },
            states: {
                hover: {
                    color: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0, 'rgba(0, 127, 182, 1)'],
                            [1, 'rgba(47, 230, 255, 1)']
                        ]
                    },
                }
            },
        },
        {
            name: 'Profit (This Year)',
            data: thisYearProfit,
            type: 'column',
            yAxis: 0,
            color: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, 'rgba(137, 96, 255, 1)'],
                    [1, 'rgba(0, 47, 168, 1)'],
                ],
            },
            states: {
                hover: {
                    color: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0, 'rgba(0, 47, 168, 1)'],
                            [1, 'rgba(137, 96, 255, 1)']
                        ],
                    },
                }
            },

        }
    ], [lastYearProfit, thisYearProfit])

    //@ts-ignore
    const chartOptions: Highcharts.Options = useMemo(() => ({
        chart: {
            type: 'column',
            height: 360,
            animation: true
        },
        title: {
            text: ``
        },
        credits: {
            enabled: false
        },
        legend: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'bottom',
            x: 50,
            y: 0,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 2,
            itemStyle: {
                color: '#31383B',
                fontWeight: 'light',
                fontSize: '14px',
                lineHeight: '22px',
                paddingLeft: '5px',
                whiteSpace: 'normal',
            },
            labelFormatter: function () {
                return `${this.name} <div style="color: ${this.name === 'Profit (Last Year)' ? '#00A5CB' : '#3E45CF'};">${formatNumberToCurrency(this.name === 'Profit (Last Year)' ? lastYearProfit.reduce((partialSum, a) => partialSum + a, 0) : thisYearProfit.reduce((partialSum, a) => partialSum + a, 0))}</div>`;
            }
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            crosshair: true
        },
        yAxis: {
            title: {
                text: 'Dollars'
            }

        },
        plotOptions: {
            series: {
                pointPadding: 0.03,
                groupPadding: 0.07,
                borderRadius: 8,
                borderWidth: 0,
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        series: series,
        tooltip: {
            borderWidth: 0,
            backgroundColor: '#ffffff',
            padding: 0,
            shadow: false,
            borderRadius: 16,
            useHTML: true,
            formatter: function (): any {
                // @ts-ignore
                return `<div class="hc-tooltip-wrapper">
                            <div class="hc-tooltip-inner">
                                <div class="hc-tooltip-title">${this.point.category}, ${this.series.name === 'Profit (Last Year)' ? new Date().getFullYear() - 1 : new Date().getFullYear()}</div>
                                <div class="tooltip-value-item">
                                    <span class="marker" style="background-color: ${this.series.name === 'Profit (Last Year)' ? '#00A5CB' : '#3E45CF'};"></span>
                                    <strong>${this.series.name}</strong>: ${this.point.y && formatNumberToCurrency(this.point.y)}
                                </div>
                           
                            </div>
                        </div>`
            }
        },
    }), [series])

    return <Wrapper>
        {loading && <LoadingOverlay><Loading/></LoadingOverlay>}
        <h3 className="title">Analyze Performance</h3>
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    </Wrapper>
}

export default AnalyzePerformance