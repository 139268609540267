import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";

interface Props {}

const OfferDescription: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(offer.description.length <= 0) return null

    return (
        <>
            <Subtitle> Campaign Description </Subtitle>
            <Item><pre>{offer.description}</pre></Item>
        </>
    )
}

export default OfferDescription