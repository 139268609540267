import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 650px;
    max-width: 100%;
`

export const UploadedFiles = styled.div`
    width: 100%;
    margin-top: 24px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[5]};
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    .file{
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ico-Exit {
            color: ${({theme}) => theme.colors.performOrange['3']};
            font-size: 20px;
            margin-left: 5px;

            :hover {
                cursor: pointer;
            }
        }
    }
`