import { useRef } from 'react';
import { useEffect } from 'react';

export const useFirstRender = () => {
    const firstRender = useRef(true);
  
    useEffect(() => {
      firstRender.current = false;
    }, []);
  
    return firstRender.current;
}