import React, {ReactNode, useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks";
import {MoreIcon, Wrapper} from "../../table/table-row-more-options/TableRowMoreOptions.style";
import DropdownContainer from "./DropdownContainer/DropdownContainer";

interface IGridRowOptionsProps {
    disabled?: boolean
}

const GridRowOptions: React.FC<IGridRowOptionsProps> = ({disabled, children}) => {
    const [opened, setOpened] = useState(false)
    const [dropdownPosition, setDropdownPosition] = useState({
        bottom: 0,
        right: 0
    })
    const ref = useRef(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    // useOnClickOutside(wrapperRef, () => setOpened(false))
    useOnClickOutside(ref, (e) => {
        if(wrapperRef.current?.contains(e.target as Node)){
            return
        }
        setOpened(false)
    })

    const handleOpenDropdownClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(wrapperRef.current){
            const {bottom, right} = wrapperRef.current.getBoundingClientRect()
            setDropdownPosition({
                bottom: bottom + window.scrollY,
                right: right
            })
            setOpened(!opened)
        }

    }

    return (
        <Wrapper id={"wrapper"} ref={wrapperRef} >
            <MoreIcon onClick={(e) => handleOpenDropdownClick(e)} disabled={disabled} active={opened}><i className='ico-More' /></MoreIcon>
            {opened &&
                <DropdownContainer ref={ref} opened={opened} position={dropdownPosition}>
                    {children}
                </DropdownContainer>
            }

        </Wrapper>
    )
}

export default GridRowOptions
