import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {MainConversionAndEventsWrapper} from "../Summary.style";


interface Props {}

const AppStoreUrl: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(
       offer.upsells.length === 0 || navState['outcome-funnel'] === 'disabled'
    ) return null

    return (
        <>
            {offer.upsells.map((item) => {
                return (
                    <MainConversionAndEventsWrapper key={item.id}>
                        <div className="header"
                             style={offer.detailed_pricing_model === 'cpc' ? {borderRadius: 8} : undefined}>
                            Event ({item.id}) {item.name ? ' - ' + item.name : '' }
                        </div>
                        {/*todo*/}
                        {offer.detailed_pricing_model !== 'cpc' &&
                            <div className="content">
                                <table className="payouts">
                                    <thead>
                                    <tr>
                                        {(offer.detailed_pricing_model !== 'cpi' &&
                                                offer.detailed_pricing_model !== 'cpe') &&
                                            <th>Payout Type</th>
                                        }
                                        {!item.is_revshare && <th>Payout</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        {(offer.detailed_pricing_model !== 'cpi' &&
                                                offer.detailed_pricing_model !== 'cpe') &&
                                            <td>{item.is_revshare ? 'Revshare' : 'Standard'}</td>
                                        }
                                        {!item.is_revshare &&
                                            <td>{item.payout ? '$'+ item.payout : '$0.00'}</td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                                {item.is_revshare &&
                                    <table style={{marginTop: 8}} className="payouts">
                                        <thead>
                                        <tr>
                                            <th>Payout Description</th>
                                            <th>Payout</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.revshare.map((it: any, index: any) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{it.name ? it.name : '-'}</td>
                                                    <td>{it.value ? '$'+it.value : '$0.00'}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        }
                    </MainConversionAndEventsWrapper>
                )
            })}
        </>
    )
}

export default AppStoreUrl