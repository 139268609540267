import React from 'react'

import {Wrapper, Image, Footer, CloseButton} from './VideoPreviewModalContent.style'
import VideoPlayer from "../video-player/VideoPlayer";

interface Props {
    videoSource: string,
    videoName: string,
    closeModal: () => void
    wrapperClassName?: string
}

const VideoPreviewModalContent: React.FC<Props> = ({
                                                       videoSource,
                                                       videoName,
                                                       wrapperClassName,
                                                       closeModal
                                                   }) => {
    return <Wrapper className={wrapperClassName}>
        <CloseButton onClick={closeModal}>
            <i className={'ico-Exit-outlined'}/>
        </CloseButton>
        <VideoPlayer videoSource={videoSource}/>
        <Footer>{videoName}</Footer>
    </Wrapper>
}

export default VideoPreviewModalContent