import styled from "styled-components";

export const FaqWidgetContainer = styled.div`
    padding: 24px;
    margin-bottom: 24px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
`
export const FaqWidgetTitle = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    cursor: pointer;
    user-select: none;
    i {color: ${({theme}) => theme.colors.deepBlue[5]};}
`

export const FaqAccordion = styled.div `
    margin-top: 15px;
`

export const FaqAccordionItemInner = styled.div `
    margin-bottom: 8px;
`

export const FaqAccordionItemTitle = styled.div `
    display: flex;
    padding: 8px 12px;
    border-radius: 8px;
    justify-content: space-between;
    user-select: none;
    align-items: center;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    background: ${({theme}) => theme.colors.coolGray[1]};
    font-size: 14px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    cursor: pointer;
    transition: all .15s ease-in-out;
    i {color: ${({theme}) => theme.colors.deepBlue[5]};}
    :hover {
        background: ${({theme}) => theme.colors.coolGray[2]};
    }
    :active {
        background: #E7ECEE;
    }
`

export const FaqAccordionItemContent = styled.div `
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    padding: 8px 20px;
`


