import styled from "styled-components";

export const Container = styled.div`
    min-width: 336px;
    @media (max-width: ${({theme}) => theme.screen.lg}) {
        min-width: 0;
    }
`

export const SummaryContainer = styled.div<{displayed: boolean}>`
    position: fixed;
    height: auto;
    width: 336px;
    max-height: calc(100% - 145px);
    display: flex;
    flex-direction: column;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    padding: 24px;
    font-size: 12px;
    
    .label {
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }
    
    .value {
        margin-top: 2px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
    }
    
    .row{
        margin-top: 16px;
    }
    
    .truncate-wrapper{
        margin-bottom: 16px;
    }
    
    .flex-row{
        display: flex;
        gap: 16px;
        > div {
            flex: 1;
        }
    }
    
    @media (max-width: ${({theme}) => theme.screen.lg}) {
        z-index: 4;
        max-height: 100%;
        right: ${({displayed}) => displayed ? '-336px' : '0'};
        top: 0;
        height: 100%;
        border-radius: 0;
        transition: right .18s ease-out;
    }
`

export const SummaryToggleButton = styled.button<{displayed: boolean}>`
    display: none;
    position: fixed;
    right: ${({displayed}) => !displayed ? '0' : '335px'};
    transition: right .18s ease-out;
    z-index: 5;
    top: 40px;
    width: 32px;
    height: 80px;
    background: #FFFFFF;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    i.ico-Hamburger-Menu {
        transform: rotate(90deg);
        display: flex;
        justify-content: center;
    }
    @media (max-width: ${({theme}) => theme.screen.lg}) {
        display: block;
    }
    @media (max-width: ${({theme}) => theme.screen.md}) {
        top: 104px;
    }
`

export const SummaryBackdrop = styled.div<{displayed: boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    transition: opacity .18s ease-in;
    opacity: ${({displayed}) => displayed ? '0' : '1'};;
    visibility: ${({displayed}) => displayed ? 'hidden' : 'visible'};
    background: rgba(49, 56, 59, 0.5);
`

export const SummaryHeader = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    margin-bottom: 16px;
    .title {
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[6]};
        margin-bottom: 5px;
    }
    
    .apps-offer-type{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 22px;
        div{
            display: flex;
            align-items: center;
        }
    }
`

export const SummaryBody = styled.div`
    flex: 1;
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
`

export const Subtitle = styled.div`
    font-size: 12px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[5]};
    margin-bottom: 2px;
`

export const Item = styled.div`
    max-width: 288px;
    width: 100%;
    word-break: break-word;
    display: flex;
    font-size: 12px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    color: ${({theme}) => theme.colors.deepBlue[4]};
    margin-bottom: 16px;
    pre{
        white-space: pre-wrap;
    }
`

export const Description = styled.div`
    display: flex;
    align-items: center;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 14px;
    margin-bottom: 24px;
    svg{
        width: 38px;
        margin-right: 8px;
    }
    
    .apps-offer-type{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`

export const MainConversionAndEventsWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    width: 100%;
    margin-bottom: 16px;
    
    .header {
        padding: 8px 16px;
        background-color: ${({theme}) => theme.colors.coolGray[1]};
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        border-radius: 8px 8px 0 0;
        margin: -1px;
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[6]};
        word-break: break-all;
    }
    
    .content {
        padding: 8px 16px;
        table.payouts {
            width: 100%;
            th, td {
                font-size: 12px;
                &:first-child {
                    width: 70%;
                }
            }
            thead {
                th {
                    text-align: left;
                    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                    color: ${({theme}) => theme.colors.deepBlue[5]};
                    padding-bottom: 4px;
                }
            }
            tbody {
                td {
                    font-family: ${({theme}) => theme.fonts.AvenirRoman};
                    color: ${({theme}) => theme.colors.deepBlue[4]};
                }
            }
        }
    }
`

export const TableDisplay = styled.div`
    display: flex;
    gap: 30px;
`


