import React, {} from 'react'

import {Wrapper, StyledTextArea, LabelStyledFloatable, LabelStyledDefault, ErrorStyled} from "./TextArea.style";

interface Props extends React.TextareaHTMLAttributes<any>{
    labelDefault?: boolean
    label?: string | React.FC | any
    error?: string
    labelClassName?: string
    textAreaClassName?: string
    wrapperClassName?: string
    errorClassName?: string
}

const TextArea: React.FC<Props> = (
    {
        label,
        error,
        children,
        wrapperClassName,
        labelClassName,
        errorClassName,
        textAreaClassName,
        labelDefault= false,
        ...rest
    }
    ) => {

    return <Wrapper className={wrapperClassName} error={!!error}>
        {label && <>
            {labelDefault ? (<LabelStyledDefault className={labelClassName}>{label}</LabelStyledDefault>) :
                (<LabelStyledFloatable $disabled={rest.disabled} className={labelClassName}>{label}</LabelStyledFloatable>)
            }
        </>
        }
        <StyledTextArea className={textAreaClassName} {...rest}/>
        {error && <ErrorStyled className={errorClassName}>{error}</ErrorStyled>}
    </Wrapper>
}

export default TextArea