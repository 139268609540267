import styled from "styled-components";

export const LatestReassessmentStatusWrapper = styled.div`
    .above-text {
        padding-bottom: 8px;
        border-bottom: 1px solid #E7ECEE;
        margin-bottom: 8px;
    }
    .reassessment-statuses {
        .heading {
            font-family: "Avenir LT W05_85 Heavy", sans-serif;
            margin-bottom: 10px;
        }
        .statuses {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .status {
                display: flex;
                align-items: center;
                gap: 8px;
                .circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                }
            }
        }
    }
`;