import {api} from '../../../../common/services/api';
import axios, {AxiosResponse} from "axios";
import {IPagination} from "../../../../common/models/pagination.type";
import qs from "qs";

export interface IAssignTagsParams {
    property_ids: number[],
    type: string,
    tag_ids: number[]
}

interface ITps {
    property_id: number,
    tps_id: number,
    id: number,
    name: string,
    is_selected?: boolean
}

interface IDeleteTagsParams {
    tps: ITps[]
}

interface IFilter  {
    ids: number[],
    include: boolean
}

export interface IFetchMarketersParams {
    paginate_by? : number,
    page?: number,
    sort_by? : string,
    order?: string,
    auto_funding? : number,
    search?: string,
    account_manager?: any,
    marketer_status? : any,
    tags? : any
    adv_type? : any,
    intacct_payment_term? : any,
    intacct_invoicing_cycle? : any,
    referrer?: any,
    sales_executive?: any,
    sensitivity? : any,
    niche?: any,
    status? : any
}


class AdvertisersService{

    static fetchTableData = async (token: string, params: IFetchMarketersParams ): Promise<AxiosResponse<IPagination<any>>> => {
        try{
            return await api.get([process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS].join("/") +
                "?with_campaigns_total=true&user_type=advertiser&with_niche=true&original_match=1",

                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params,
                    paramsSerializer: params => {
                return qs.stringify(params);
            },
                })
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static fetchNiches = async (token: string): Promise<AxiosResponse<any>> => {
        try {
            return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_NICHES
            ].join('/'),
                {

                }, {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static assignTags = async (token: string, params: IAssignTagsParams): Promise<AxiosResponse<[]>> => {
        try{
            return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ASSIGN_TAGS
            ].join('/'),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
                )
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static deleteTags = async (token: string, params: IDeleteTagsParams): Promise<AxiosResponse<[]>> => {
        try{
            return await api.post([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_ASSIGN_TAGS,
                    process.env.REACT_APP_API_DELETE_TAGS
                ].join('/'),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

}

export default AdvertisersService