import {SVGAttributes} from 'react'

export const IconPriorityHigh: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...rest}>
            <path
                d="M3.5 9.9c-.5.3-1.1.1-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3L8 7.2 3.5 9.9z"
                fill="#ff5630"/>
        </svg>
    )
}

export const IconPriorityHighest: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...rest}>
            <path
                d="M3.47876 7.9c-.5.3-1.1.1-1.4-.4s-.1-1 .4-1.3l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.2.4-.8.6-1.3.3l-4.5-2.7-4.5 2.7z"
                fill="#ff5630"/>
            <path
                d="M3.47876 12.2c-.5.3-1.1.2-1.4-.3s-.1-1.1.4-1.4l5-3c.3-.2.7-.2 1 0l5 3c.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3l-4.4-2.7-4.5 2.7z"
                fill="#ff7452"/>
        </svg>
    )
}

export const IconPriorityLow: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...rest}>
            <path
                d="M12.5 6.1c.5-.3 1.1-.1 1.4.4.3.5.1 1.1-.3 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.6-.2-.7-.9-.4-1.3.2-.5.9-.7 1.3-.4L8 8.8l4.5-2.7z"
                fill="#0065ff"/>
        </svg>
    )
}

export const IconPriorityLowest: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...rest}>
            <path
                d="M12.504883 8.14541c.5-.3 1.1-.1 1.4.4s.1 1-.4 1.3l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.2-.4.8-.6 1.3-.3l4.5 2.7 4.5-2.7z"
                fill="#0065ff"/>
            <path
                d="M12.504883 3.84541c.5-.3 1.1-.2 1.4.3s.1 1.1-.4 1.4l-5 3c-.3.2-.7.2-1 0l-5-3c-.5-.3-.6-.9-.3-1.4.3-.5.9-.6 1.4-.3l4.4 2.7 4.5-2.7z"
                fill="#2684ff"/>
        </svg>
    )
}

export const IconPriorityMedium: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 16 16" {...rest}>
            <g fill='#FFAB00'>
                <g fill='#FFAB00'>
                    <path fill='#FFAB00;' className="st0" d="M3,4h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3C2.4,6,2,5.6,2,5S2.4,4,3,4z M3,10h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3
                        c-0.6,0-1-0.4-1-1S2.4,10,3,10z"/>
                </g>
            </g>
        </svg>
    )
}


