import styled, {css} from "styled-components";
import {NavLink, Outlet} from "react-router-dom";

export const Container = styled.div`
    min-width: 272px;
    @media (max-width: ${({theme}) => theme.screen.md}) {
        min-width: 0;
    }
`

export const NavigationMenu = styled.div<{displayed: boolean}>`
    position: fixed;
    height: auto;
    width: 272px;
    max-height: calc(100% - 145px);
    display: flex;
    flex-direction: column;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    padding: 24px;
    
    @media (max-width: ${({theme}) => theme.screen.md}) {
        left: 0;
        right: 0;
        width: 100%;
        z-index: 6;
        top: 63px;
        border-radius: 0;
        padding: 24px 40px;
        transition: opacity .15s ease-out;
        opacity: ${({displayed}) => displayed ? '0' : '1'};;
        visibility: ${({displayed}) => displayed ? 'hidden' : 'visible'};
    }
`

export const NavigationToggleButton = styled.button<{displayed: boolean}>`
    padding: 16px 40px;
    outline: none;
    text-align: left;
    display: none;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
    height: 64px;
    background: #FFFFFF;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.coolGray[3]};
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    > i {
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    .selected-title {
        display: flex;
        align-items: center;
        gap: 16px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        > i {
            color: ${({theme}) => theme.colors.performBlue[5]};
        }
        .title {
            font-size: 14px;
            text-transform: capitalize;
            .label {
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                text-transform: uppercase;
                color: ${({theme}) => theme.colors.deepBlue[3]};
                font-size: 12px;
                margin-left: 6px;
            }
        }
    }
    &:after {
        position: fixed;
        content: '';
        background: rgba(49, 56, 59, 0.5);
        width: 100%;
        height: 100%;
        left: 0;
        top: 63px;
        transition: opacity .15s ease-in;
        opacity: ${({displayed}) => displayed ? '0' : '1'};;
        visibility: ${({displayed}) => displayed ? 'hidden' : 'visible'};
    }
    @media (max-width: ${({theme}) => theme.screen.md}) {
        display: flex;
    }
`

export const NavigationSection = styled.div`
    width: 100%;
    flex: 1;
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
    .title {
        position: relative;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 10px;
        text-transform: uppercase;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        z-index: 0;
        line-height: 1;
        margin-bottom: 16px;

        span {
            background-color: ${({theme}) => theme.colors.pureWhite};
            padding-right: 5px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            width: 100%;
            height: 1px;
            background-color: ${({theme}) => theme.colors.coolGray[3]};
            z-index: -1;
        }
    }
`

interface NavigationProps {
    disabled: boolean;

}


export const NavigationItem = styled(NavLink)<NavigationProps>`
    width: 100%;
    min-height: 40px;
    margin-bottom: 8px;
    display: flex;
    border-radius: 100px;
    align-items: center;
    padding: 8px 16px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 14px;
    background-color: transparent;
    transition: .2s ease all;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    i {
        margin-right: 16px;
        color: ${({theme}) => theme.colors.performBlue[5]};
        ${props => {
            if (props.disabled) {
                return css`
                color: ${({theme}) => theme.colors.coolGray[4]};
                pointer-events: none;
                user-select: none;
            `}
        }
        }
        &.active-icon {
            display: none;
        }
    }

    span {
        text-transform: capitalize;
    }

    &.active {
        .active-icon{
            display: block;
            + i {
                display: none;
            }
        }
        background-color: ${({theme}) => theme.colors.performBlue[1]};
    }

    :hover {
        background-color: ${({theme}) => theme.colors.coolGray[2]};
    }

    ${props => {
        if (props.disabled) {
            return css`
                background-color: ${({theme}) => theme.colors.pureWhite} !important;
                color: ${({theme}) => theme.colors.coolGray[4]};
                pointer-events: none;
                user-select: none;
                .lock-icon{
                        //pointer-events: auto;
                }
            `}
        }
    }
    .draft-icon{
        margin-left: auto;
    }
`


export const Icon = styled.div`


`

export const NavigationItemStyled = styled(NavLink)<NavigationProps>`
    width: 100%;
    min-height: 40px;
    margin-bottom: 8px;
    display: flex;
    border-radius: 100px;
    align-items: center;
    padding: 8px 16px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 14px;
    background-color: transparent;
    transition: .2s ease all;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    i {
        margin-right: 16px;
        color: ${({theme}) => theme.colors.performBlue[5]};
        ${props => {
    if (props.disabled) {
        return css`
                color: ${({theme}) => theme.colors.coolGray[4]};
                pointer-events: none;
                user-select: none;
            `}
}
}
        &.active-icon {
            display: none;
        }
    }

    span {
        text-transform: capitalize;
    }

    &.active {
        .active-icon{
            display: block;
            + i {
                display: none;
            }
        }
        .draft-icon{
            display: block;
            pointer-events: auto;
            :active{
                pointer-events: none;
            }
        }
        background-color: ${({theme}) => theme.colors.performBlue[1]};
    }

    :hover {
        background-color: ${({theme}) => theme.colors.coolGray[2]};
    }

    ${props => {
    if (props.disabled) {
        return css`
                background-color: ${({theme}) => theme.colors.pureWhite} !important;
                color: ${({theme}) => theme.colors.coolGray[4]};
                pointer-events: none;
                user-select: none;
                .lock-icon{
                    pointer-events: auto;
                    :active{
                        pointer-events: none;
                    }
                }
                .draft-icon{
                    pointer-events: auto;
                    :active{
                        pointer-events: none;
                    }
                }
            `}
}
}
    .draft-icon{
        margin-left: auto;
    }
`