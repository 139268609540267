import styled from 'styled-components'
import {DropdownContainer} from "../../../../../../common/components/dropdown/Dropdown.style";

export const Wrapper = styled.div`
    .actions-toggle-button {
        min-height: 32px;
        line-height: 1;
        gap: 0;
        &.active {
            background: ${({theme}) => theme.colors.deepBlue[2]};
            border-color: ${({theme}) => theme.colors.deepBlue[3]};
            color: ${({theme}) => theme.colors.deepBlue[8]};
        }
        .ico-AngleDown {
            margin-left: 10px;
        }
    }
    .actions-header{
        min-height: 32px;
        height: 32px;
        display: flex;

        span > div {
            display: flex;
        }
    }

    .actions-container {
        width: 216px;
        overflow-y: visible;
    }

    .item-separator{
        height: 1px;
        background: ${({theme}) => theme.colors.coolGray[3]};
        margin: 8px;
    }

  @media screen and (max-width: 897px) {
    margin-right: 0;
  }
`

export const ApprovalHoverItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  white-space:nowrap;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['5']};
  transition: all .2s ease-in-out;

  :hover{
    background-color: ${({theme}) => theme.colors.performBlue['1']};
    transition: all .2s ease-in-out;
    cursor: pointer;
    
    .hover-container{
      opacity: 1;
      visibility: visible;
    }
  }
  
  i{
    font-size: 14px;
  }
`

export const HoverContainer = styled(DropdownContainer)`
  left: auto;
  position: absolute;
  overflow-y: visible;
  right: -100%;
  top: -16px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-in-out;
  
  :before{
    content: '';
    width: 20px;
    position: absolute;
    height: 100%;
    left: -20px;
    top: 0;
  }
`

export const SelectedOffersCount = styled.div`
  height: 14px;
  //padding: 4px 8px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.performBlue[5]};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
  background: ${({theme}) => theme.colors.performBlue[1]};
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 12px;
  margin-left: 8px;
  .active & {
      border-color: ${({theme}) => theme.colors.performBlue[7]};
      color: ${({theme}) => theme.colors.performBlue[7]};
  }
  
  i{
    font-size: 11px;
  }
`

export const ActionHoverNotShown = styled.p`
  font-family: ${({theme}) => theme.fonts.AvenirItalic};
  color: ${({theme}) => theme.colors.coolGray[5]};
`