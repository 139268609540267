import React from 'react';
import {Item, Subtitle, TableDisplay} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";

interface Props {}

const Budget: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(navState['budget'] === 'disabled') return null

    return (
        <>
            {offer.budget_type ? <>
                <div>
                    { offer.budget_type === 'unlimited' ? null :
                        <TableDisplay>
                            <div>
                                <Subtitle>Campaign Budget</Subtitle>
                                <Item style={{textTransform: 'capitalize'}}>{offer.budget_type?.toLowerCase()}</Item>
                            </div>
                            <div>
                                <Subtitle>Currency</Subtitle>
                                <Item>{offer.budget_value_type === 0 ? 'US ($)' : 'Conversion'}</Item>
                            </div>
                            <div>
                                <Subtitle>Amount</Subtitle>
                                <Item>{offer.budget}</Item>
                            </div> </TableDisplay>     }
                </div>
            </> : null}

            {offer.budget_group_id ? <>
                <Subtitle>Budget Group</Subtitle> <Item>{offer.budget_group?.name}</Item>
            </> : null}

            {offer.link_test_budget_type ? <>
                <div>
                    { offer.link_test_budget_type?.toLowerCase() === 'disabled' ? null :
                        <TableDisplay>
                            <div>
                                <Subtitle>Test Budget</Subtitle>
                                <Item style={{textTransform: 'capitalize'}}>{offer.link_test_budget_type?.toLowerCase()}</Item>
                            </div>
                            <div>
                                <Subtitle>Currency</Subtitle>
                                <Item>{offer.budget_value_type === 0 ? 'US ($)' : 'Conversion'}</Item>
                            </div>
                            <div>
                                <Subtitle>Amount</Subtitle>
                                <Item>{offer.link_test_budget_value}</Item>
                            </div> </TableDisplay>}
                </div>
            </> : null}
        </>
    )
}

export default Budget