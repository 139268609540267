import styled from "styled-components";

export const ModalBody = styled.div`
  width: 420px;
  p {
    font-size: 14px;
    color: #31383B;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    margin-bottom: 20px;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  `
