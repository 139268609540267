import styled from 'styled-components'
import {AgGridReact} from "ag-grid-react";

export const GridWrapper = styled(AgGridReact)`
  .ag-root-wrapper {
    border: 1px solid #E9ECED;
    border-radius: 8px;
    min-height: 80px;
  }
    
    .ag-cell-last-left-pinned{
        box-shadow: 4px 0 10px 0 rgb(0 0 0 / 4%) !important;
    }
  
  .ag-header{
    background: #F9F9F9;
    font-size: 12px;
    color: #31383B;
    z-index: 10;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    min-height: 40px !important;
      border-bottom: 1px solid #E7ECEE;
  }

  .ag-header-row{
    min-height: 40px !important;
  }

  .ag-header-cell-resize{
    height: 100%;
  }

  .ag-header-cell{
    border-right: 1px solid #E9ECED;
    padding: 12px 0px 12px 16px;
  }

  .ag-pinned-right-header{
    border-left: 1px solid #E9ECED;
  }

  .ag-row{
    min-height: 40px !important;
  }

  .ag-cell{
    border-right: 1px solid #E9ECED;
    box-shadow: -1px 1px 1px #E9ECED;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    .ag-cell-wrapper {
      width: 100%;
        > span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
      .cell-content-right {
        text-align: right;
        width: 100%;
      }
      & > div {
        width: 100%;
      }
    }
  }

  .ag-row{
    border-bottom: 1px solid #E9ECED;
  }

  .ag-row-hover{
    transition: none;
    background-color: #EFF7F9;
    .ag-cell {
      background-color: #EFF7F9;
    }
  }

  .ag-center-cols-container{
    width: inherit !important;
  }

  .ag-cell-first-right-pinned{
    border-left: 1px solid #E9ECED;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    background-color: white;
    padding: 0;
    min-width: 50px;
    border-right: none;

    .ag-cell-wrapper{
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  .ag-body-viewport {
    font-family: ${({theme}) => theme.fonts.AvenirLight};
  }

  .ag-floating-bottom-viewport {
    background: #f3f7f8;
    color: #0496AA;
    .ag-cell {
      border-right: 0;
      box-shadow: unset;
      background: #f3f7f8;
      color: #0496AA;
      transition: .2s ease-in-out;
    }
    &:hover {
        background: #eafafd;
      .ag-cell {background: #eafafd;}
      .ag-cell-first-right-pinned {
        background-color: #ffffff;
      }
    }
  }

  .ag-body-viewport {
    font-family: ${({theme}) => theme.fonts.AvenirLight};
  }

  .ag-floating-bottom {
    height: 39px !important;
    min-height: 39px !important;
  }

  .ag-center-cols-viewport {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  /* .ag-body-horizontal-scroll {
    display: none;
    height: 0 !important;
    max-height: 0 !important;
  } */
    
  &.ag-grid-align-items-baseline {
      .ag-cell {
          align-items: baseline;
          .cell-item {
              padding-top: 12px;
              width: auto;
          }
      }
  }

    .ag-floating-bottom-viewport .ag-cell {
        transition: none !important;
    }
    .ag-input-wrapper.ag-checkbox-input-wrapper {
        width: 14px;
        height: 14px;
        border:  1px solid ${({theme}) => theme.colors.deepBlue[4]};
        border-radius: 3px;
        text-align: center;
        position: relative;
        &:after {
            content: "\\e900";
            font-family: 'leap' !important;
            line-height: 1;
            width: 14px;
            position: absolute;
            left: -1px;
            right: 0;
            margin: auto;
            font-size: 10px;
            pointer-events: none;
            opacity: 0;
            z-index: 0;
        }
        &.ag-checked {
            border-color: ${({theme}) => theme.colors.performBlue['5']};
            background-color: ${({theme}) => theme.colors.performBlue['1']};
            color: ${({theme}) => theme.colors.performBlue['4']};
            &:after {
                opacity: 1;
            }
            &:hover {
                border-color: ${({theme}) => theme.colors.performBlue['6']};
                &:after {
                    color: ${({theme}) => theme.colors.performBlue['6']};
                }
            }
            &:hover:active {
                border-color: ${({theme}) => theme.colors.performBlue['7']};
                &:after {
                    color: ${({theme}) => theme.colors.performBlue['7']};
                }
            }
        }
        &.ag-indeterminate {
            border-color: ${({theme}) => theme.colors.performBlue['5']};
            &:after {
                content: "";
                opacity: 1;
                position: absolute;
                top: 5px;
                left: 0px;
                width: 7px;
                height: 2px;
                border-radius: 1px;
                background-color: ${({theme}) => theme.colors.performBlue['5']};
            }
            &:hover {
                border-color: ${({theme}) => theme.colors.performBlue['6']};
                &:after {
                    color: ${({theme}) => theme.colors.performBlue['6']};
                }
            }
            &:hover:active {
                border-color: ${({theme}) => theme.colors.performBlue['7']};
                &:after {
                    color: ${({theme}) => theme.colors.performBlue['7']};
                }
            }
        }
        .ag-input-field-input {
            margin: 0;
            opacity: 0;
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
        &:hover {
            border-color: ${({theme}) => theme.colors.performBlue['5']};
        }
        &:hover:active {
            border-color: ${({theme}) => theme.colors.performBlue['5']};
            background-color: ${({theme}) => theme.colors.performBlue['1']};
        }
    }
`

export const GridNoColumns = styled.div`
  background: #fff;
  padding-top: 10px;
  font-size: 12px;
  border: 1px solid #E9ECED;
  border-radius: 8px;
  .text {
    color: #ef4a24;
    text-align: center;
  }
  .enable {
    .enable-btn {
      margin: 8px auto;
      background-color: #8799A0;
      color: #fff;
      border-radius: 8px;
      transition: .2s ease-in-out;
      padding: 6px 16px;
      width: max-content;
      cursor: pointer;
      &:hover {
        background-color: #31383B;
      }
    }
  }
`;
