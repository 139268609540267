import { WysiwygWrapper } from './TextareaWysiwyg.style';
import { useRef, useState }               from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useOnClickOutside } from '../../hooks';

const TextareaWysiwyg = ({html,  setHtml}: any) => {
    const [focus, setFocus] = useState(false);
    const ref = useRef(null);   
    
    const contentBlock = html ? htmlToDraft(html) : false;
    const [editorState, setEditorState] = useState<any>(
        contentBlock ? EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)) : EditorState.createEmpty()
    );
    
    const onChange = (state: any) => {
      setEditorState(state);
      setHtml(draftToHtml(convertToRaw(state.getCurrentContent())));
    };

    // useEffect(()=>onChange(), [html]);

    useOnClickOutside(ref, () => setFocus(false))
    
    return (
      <WysiwygWrapper ref={ref} isPlaceholderFocus={focus}>
          <Editor 
            editorState={editorState} 
            onEditorStateChange={onChange} 
            onFocus = {() => setFocus(true)}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right'],
              },
              link: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                popupClassName: undefined,
                dropdownClassName: undefined,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_self',
                options: ['link', 'unlink'],
              },
            }}
          />
      </WysiwygWrapper>
    )
}

export default TextareaWysiwyg;