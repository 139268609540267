import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IFilterHookReturn} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";

export const usePaymentTermsFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<any> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<any[]>([])
    const [paymentTerms, setPaymentTerms] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id ='payment-terms';

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<string[]>(
                'intacct-payment-terms',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )            
            setLoading(false)
            setPaymentTerms(data)
            setFetchedData(data)
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredPaymentTerms = fetchedData.filter(manager => (
                manager.toLowerCase()).includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedCycles = [...filteredPaymentTerms].slice(0, displayCount)
            if(filteredPaymentTerms.length > displayCount){
                setNotDisplayed(filteredPaymentTerms.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setPaymentTerms(displayedCycles)
        } else {
            setPaymentTerms(
                filteredPaymentTerms
            )
        }
    }, [searchValue, fetchedData, displayCount])

    const paymentTermsFilter = useFilterSelector<any>(pageId, id)

    const setSelected = (cycle: any) => {
        if(paymentTermsFilter.selected.some((filterCycle)  => filterCycle === cycle)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: paymentTermsFilter.exclude,
                    selected: paymentTermsFilter.selected.filter(filterCycle => filterCycle !== cycle)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: paymentTermsFilter.exclude,
                    selected: [...paymentTermsFilter.selected, cycle]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: paymentTermsFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: paymentTerms,
        setSearchValue: setSearchValue,
        notDisplayed: notDisplayed,
        selectedData: paymentTermsFilter.selected,
        exclude: paymentTermsFilter.exclude,
        setSelectedData: setSelected,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}

