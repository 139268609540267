enum EStatuses {
    ACTIVE_STATUS = "active",
    INACTIVE_STATUS = "inactive",
    TERMINATED_STATUS = "terminated",
    DELETED_STATUS = "deleted"
}

enum EApprovalStatuses {
    PENDING_REVIEW_STATUS = "pending review",
    REVIEWED_STATUS = "reviewed",
    DENIED_STATUS = "denied",
}

interface IMinimumUser {
    status: string,
    cpc_status: string
}

export const getStatus = <T extends IMinimumUser> (user: T, advertisers_page: boolean) => {
    let stat;
    if(advertisers_page){
        if(user.status === EStatuses.INACTIVE_STATUS || user.status === EStatuses.TERMINATED_STATUS){
            if (user.status === EStatuses.INACTIVE_STATUS) {
                switch (user.cpc_status) {
                    case 'rejected' :
                        stat = EStatuses.INACTIVE_STATUS;
                        break;
                    case 'pending' :
                        stat = EApprovalStatuses.PENDING_REVIEW_STATUS;
                        break;
                    case 'approved' :
                        stat = EApprovalStatuses.REVIEWED_STATUS;
                        break;
                }
            }
            else if (user.status === EStatuses.TERMINATED_STATUS) {
                if (user.cpc_status === 'rejected') {
                    stat = EApprovalStatuses.DENIED_STATUS;
                } else {
                    stat = EStatuses.TERMINATED_STATUS;
                }
            }
        }
        else {
            stat = user.status
        }
    }
    else {
        stat = user.status
    }

    return stat
}