import React, {useMemo, useState} from 'react'
import {IFetchIspListResponse} from "../../../../interfaces/interfaces";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {
    TargetingSection,
    SelectedCountries,
    SelectedCountryPill
} from "../Targeting.style";
import {setOffer} from "../../../../create-offer.slice";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import MultiOptionSwitch, {
    OptionType
} from "../../../../../../../../common/components/multi-option-switch/MultiOptionSwitch";
import {StyledSelect} from "../../../../../../../../common/components/select/Select";

const switchOptions: OptionType[] = [
    {
        value: 'All',
        id: 'all'
    },
    {
        value: 'Include',
        id: 'include'
    },
    {
        value: 'Exclude',
        id: 'exclude'
    }
]

const IspTracking: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    const {data} = useAppSelector(state => state.createOffer.isps)
    const [search, setSearch] = useState('')
    const dispatch = useAppDispatch()

    const {isps} = data

    const filteredIsp = useMemo(() => [
        ...isps.filter(isp => isp.isp.toLowerCase().includes(search.toLowerCase()))
    ], [isps, search])

    const handleIspSelect = (isp: IFetchIspListResponse) => {
        if(offer.selected_isp.some((selected: any) => selected.isp_id === isp.isp_id)){
            dispatch(setOffer({selected_isp: [...offer.selected_isp.filter((selected: any) => selected.isp_id !== isp.isp_id)]}))

        } else {
            dispatch(setOffer({selected_isp: [...offer.selected_isp, isp]}))
        }
    }

    const options = useMemo(() => {
        return isps && [...isps
            .filter(city => city.isp?.toLowerCase().includes(search.toLowerCase())
            )
        ]
    }, [isps, search])


    return <div>
        <TargetingSection>
            <h3>ISP Targeting</h3>
            <MultiOptionSwitch
                options={switchOptions}
                selected={offer.isp_type}
                onSelect={(option) => dispatch(setOffer({isp_type: option.id as string}))}
                wrapperClassName={'targeting-select'}
            />
        </TargetingSection>
        {offer.isp_type !== 'all' ?
            <>
                <StyledSelect
                    value={null}
                    filterOption={() => true}
                    selectWrapperClassname={'select-wrapper'}
                    onInputChange={(newValue) => { setSearch(newValue) }}
                    // @ts-ignore
                    onChange={(value) => value && handleIspSelect(value.value)}
                    options={[
                        ...options
                            .slice(0, 50)
                            .filter(isp => !offer.selected_isp.some(isp_selected => isp_selected.isp_id === isp.isp_id))
                            .map(isp => ({
                                label: isp.isp,
                                value: isp
                            })
                        )]}
                    placeholder={`Choose a isp to ${offer.isp_type}`}
                />
            </> :
            null
        }
        {offer.selected_isp.length > 0 &&
            <SelectedCountries>
                {offer.selected_isp.map((isp: IFetchIspListResponse, index) => {
                    return <SelectedCountryPill  style={{border: index === offer.selected_isp.length - 1 ? 'none' : ''}} key={isp.isp_id}>
                        {isp.isp}
                        <i className={'ico-Exit'} onClick={() => handleIspSelect(isp)}/>
                    </SelectedCountryPill>
                })}
            </SelectedCountries>
        }
        {offer.selected_isp.length > 0 &&
            <div style={{textAlign: "right", marginTop: '8px'}}>
                <ButtonStyled
                    className={'btn-default-outlined'}
                    onClick={() => dispatch(setOffer({selected_isp: []}))}
                >
                    Clear All
                </ButtonStyled>
            </div>
        }
    </div>
}

export default IspTracking