import {api} from '../../../../../common/services/api';
import {IPagination} from "../../../../../common/models/pagination.type";

export interface IUpdateBillingNotesParams {
    user_id: number
    billing_notes: string | null
}

export interface IEditStayLiveLimitParams {
    user_id: number
    credit: number | 'unlimited'
}

export interface IFetchBillingParams {
    advertiser_id: number,
    paginate_by: number,
    page: number,
    group: any,
    type: string,
}
interface IFetchBillingFees {
    id: number,
    paginate_by: number,
    page: number

}

export interface IFetchAffiliatesResponse{
    "id": number,
    "name": string,
    "lastname": string,
    "company": string,
    "network_id": number,
    "network_name": string
}

export interface ITier {
    order: number,
    from: string;
    to: string;
    percentage: string;
}

export interface ISaveFeeParams {
    tiers: ITier[],
    amount: null | string,
    calculate_by: null | string,
    name: string;
    description: string;
    type: number;
    pass_through: number | boolean;
    recurring: number | null;
    start_date: Date | undefined | null;
    end_date: Date | undefined | null;
    traffic_source_id: number | null;
    data_source: string | null;
    include_users: number | null;
    selected_users: any[];
    monthly_minimum_charge: string | null;
}

export interface IFee {
    id: number;
    user_id: number;
    name: string;
    description: string;
    fee_group_id?: number;
    type: number;
    pass_through: boolean;
    recurring: number;
    start_date: string;
    end_date: string;
    traffic_source_id?: number;
    data_source: string;
    calculate_by: string;
    amount: number;
    monthly_minimum_charge: number;
    include_users: number;
    created_at: string;
    updated_at: string;
    source: string;
    type_string: string;
    start_date_formatted: string;
    end_date_formatted: string;
}

export interface IGetFeesForGroupResponse {
    data: IFee[]
}

export interface ISaveFeeGroupParams {
    name: string;
    description?: string;
    type: number | undefined;
    visibility: number | undefined;
    monthly_charges?: string | number;
    tiers: ITier[];
    fees: number[];
    id?: number;
    user_id?: number | string;
}

export interface IntacctInvoice {
    record_number: number;
    invoice_number: string;
    customer_id: string;
    customer_id_int: number;
    customer_name: string;
    invoice_total: string;
    amount_paid: string;
    amount_due: string;
    due_date: string;
    invoice_state: string;
    num: number;
    paid_date: string | null;
}

export interface IFetchDetailsResponse {
    id: number;
    name: string;
    lastname: string;
    user_type: string;
    company: string;
    balance: number;
    credit?: any;
    niche_id?: any;
    auto_funding: number;
    auto_funding_amount?: any;
    executive_id: number;
    account_manager_name: string;
    account_manager_lastname: string;
    account_manager_email: string;
    marketer_total_revenue?: any;
    intacct_credit_deposit?: any;
    intacct_credit_limit: string;
    intacct_invoicing_cycle: string;
    intacct_payment_term: string;
    total_credit_limit: number;
    credit_used: number;
    billing_notes?: any;
    funding_days_remaining?: any;
    funding_stay_live_days_remaining?: any;
    days_until_payment: number;
    pause_risk: number;
    intacct_invoices: IntacctInvoice[];
    processing_fee_percentage: string;
}


class BillingService {
    static fetchBillings = async (token: string, params : IFetchBillingParams) => {
        return await api.get<IPagination<any>>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            process.env.REACT_APP_API_BILLING
        ].join('/'),
            {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
                params: params
            }
        )
    }

    static fetchBillingFees = async (token: string, params : IFetchBillingFees) => {
        return await api.get([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            "fees",
            "fees_and_groups"
        ].join('/'),
            {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
                params: params
            }
        )
    }

    static deleteFee = async (token: string, id: number) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            "fees",
            "delete",
            id
        ].join('/'),
        null,
            {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'text/html'
            },
                params: {}
            }
        )
    }

    static deleteGroup = async (token: string, id: number) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            "fees",
            "delete-group",
            id
        ].join('/'),
        null,
            {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'text/html'
            },
                params: {}
            }
        )
    }

    static fetchAffiliates = async (token: string) => {
        return await api.post<IFetchAffiliatesResponse[]>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_FILTERS,
            'affiliates'
        ].join('/'),
            null,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
            )
    }

    static checkIfFeeNameExists = async (token: string, user_id: number | string, name: string, model: 'fee' | 'group') => {
        return await api.post<{exists: boolean}>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FEES,
                'name-exists'
            ].join('/'),
            {
                _method: 'get'
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    user_id,
                    name,
                    model
                }
            }
        )
    }

    static saveFee = async (token: string, user_id: number | string, params: ISaveFeeParams) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_FEES,
            'save',
            user_id
        ].join('/'),
            params,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
            )
    }

    static getFeesForGroupModal = async (token: string, user_id: number | string, id?: number | null, tiered?: number | null) => {
        return await api.get<IGetFeesForGroupResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FEES,
                'group-fees',
                user_id
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params: {
                    id: id,
                    tiered: tiered
                }
            }
        )
    }

    static saveFeeGroup = async (token: string, user_id: number | string, params: ISaveFeeGroupParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FEES,
                'save-group',
                user_id
            ].join('/'),
            params,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static updateBillingNotes = async (token: string, params: IUpdateBillingNotesParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring'
            ].join('/'),
            {
                action: 'update_billing_notes',
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },

            })
    }

    static editStayLiveLimit = async (token: string, params: IEditStayLiveLimitParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring'
            ].join('/'),
            {
                action: 'update_stay_live_credit',
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },

            })
    }

    static fetchDetails = async (token: string, user_id: number) => {
        return await api.get<IFetchDetailsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'funding-monitoring',
                user_id
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                }
            }
        )
    }
}

export default BillingService