import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";
export {};

const allVerticals = [
    {
        name: "Financial",
        id: 1
    },
    {
        name: "Health & Beauty",
        id: 0
    },
    {
        name: "Entertainment & Lifestyle",
        id: 2
    },
    {
        name: "Downloads & Software",
        id: 5
    },
    {
        name: "E-Commerce",
        id: 6
    },
    {
        name: "Other",
        id: 4
    }
];

export const useMarketerVerticalFilter = (pageId: PageID): IFilterHookReturn<any> => {

    const dispatch = useAppDispatch()

    const id = 'marketer-vertical'

    const marketerVerticalFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (risk: any, exclude?: boolean) => {        
        if(marketerVerticalFilter.selected.some((ri)  => ri.id === risk.id)){            
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: exclude !== undefined ? exclude : marketerVerticalFilter.exclude,
                    selected: marketerVerticalFilter.selected.filter(ri => ri.id !== risk.id)
                }
            }))
        } else {
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: exclude !== undefined ? exclude : marketerVerticalFilter.exclude,
                    selected: [...marketerVerticalFilter.selected, risk]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: marketerVerticalFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }
    
    const addMultiple = (selected: any) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: marketerVerticalFilter.exclude,
                selected: selected
            }
        }))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allVerticals,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: marketerVerticalFilter.selected,
        exclude: marketerVerticalFilter.exclude,
        setSelectedData: setSelectedData,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter,
        addMultiple: addMultiple
    }
}