export const useColumnsOptionHook = (
    allColumnDefs: any[], 
    columnDefs: any[], 
    setColumnDefs: (columnDefs: any) => void, 
    allPaginationColumns: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string | undefined
    }[],
    paginationColumnsList: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string | undefined
    }[], 
    setPaginationColumnsList: (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string | undefined
    }[]) => void
    ) => {

    const enableAllHandler = () => {
        setPaginationColumnsList([...allPaginationColumns]);
        setColumnDefs([...allColumnDefs]);
    }

    const disableAllHandler = () => {
        const copyData = [...allPaginationColumns];
        const newList = copyData.map((item: {
            name: string,
            id: number,
            checked: boolean,
            column_key?: string | undefined
        }) => {
            return {
                ...item,
                checked: item.column_key === 'pinned'
            }
        })
        setPaginationColumnsList(newList);
        setColumnDefs([]);
    }
    const enableDisableColumnHandler = (column: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string | undefined
    }) => {
        if(columnDefs.length === 1 && columnDefs[0].pinned) { 
            disableAllHandler();
            return;
        }
        if(column.checked && column.name !== "pinned") {
            if(columnDefs.length === 2) {
                disableAllHandler();
                return;
            } else {
                const newColList = columnDefs.filter((item: any) => item.colId !== column.id);
                setColumnDefs(newColList);
                const newList = paginationColumnsList.map((item: {
                    name: string,
                    id: number,
                    checked: boolean
                    column_key?: string | undefined
                }) => {
                    if(item.id === column.id) {
                        return {
                            ...item,
                            checked: false
                        }
                    } else return item;
                })
                setPaginationColumnsList(newList);
            }
        } else if(columnDefs.length === 0 && column.name !== "pinned"){
            const newList = paginationColumnsList.map((item: {
                name: string,
                id: number,
                checked: boolean,
                column_key?: string | undefined
            }) => {
                if(item.id === column.id) {
                    return {
                        ...item,
                        checked: true
                    }
                } else return item;
            })
            setPaginationColumnsList(newList);
            const items = allColumnDefs.filter((item: any) => (item.colId === column.id) || (item.pinned === "right"));
            setColumnDefs(items);
        } else {
            const item = allColumnDefs.find((item: any) => item.colId === column.id);
            let newCols = [...columnDefs, item];
            newCols.sort((a, b) => {
                return a.colId - b.colId;
            })
            setColumnDefs(newCols);
            const newList = paginationColumnsList.map((item: {
                name: string,
                id: number,
                checked: boolean,
                column_key?: string | undefined
            }) => {
                if(item.id === column.id) {
                    return {
                        ...item,
                        checked: true
                    }
                } else return item;
            })
            setPaginationColumnsList(newList);
        }
    }   
    return [enableAllHandler, disableAllHandler, enableDisableColumnHandler];
}