import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";

interface Props {}

const BrandUrl: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(offer.detailed_pricing_model !== 'ppc' || offer.preview_url.length <= 0) return null

    return (
        <>
            <Subtitle> Brand URL </Subtitle>
            <TruncateContent
                wrapperClassName={'truncate-wrapper'}
                numberOfLines={3}
            >
                {offer.preview_url}
            </TruncateContent>
        </>
    )
}

export default BrandUrl