import styled, {css} from 'styled-components'

interface Props {
    isDragging: boolean,
    showCursor: boolean
}



export const ProgressBar = styled.div<Props>`
    position: absolute;
    bottom: ${({isDragging}) => isDragging ? '20px' : '-20px'};
    left: 20px;
    cursor: pointer;
    background-color: white;
    width: calc(100% - 40px);
    height: 4px;
    border-radius: 2px;
    
    :hover {
        height: 8px;
        border-radius: 4px;
        border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
        
        .progress {
            border-radius: 4px;
        }
    }
    
    ${(props) => props.isDragging && css`
        height: 8px;
        border-radius: 4px;
        border: 1px solid ${({theme}) => theme.colors.performBlue[5]};

        .progress {
            border-radius: 4px;
        }
    `}
    
    
    .progress {
        background-color: ${({theme}) => theme.colors.performBlue[5]};
        height: 100%;
        border-radius: 2px;
        position: relative;
    }
    
    .time {
        position: absolute;
        background-color: ${({theme}) => theme.colors.deepBlue[7]};
        top: -30px;
        right: -18px;
        color: white;
        padding: 3px 7px;
        opacity: 0;
    }
`

export const ControlsWrapper = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
    .control-icon{
        cursor: pointer;
        filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
        opacity: ${({isDragging}) => isDragging ? 1 : 0};
        path{
            fill: white;
        }
        
        :hover {
            path{
                fill: ${({theme}) => theme.colors.performBlue[1]};
            }
        }
        
        :active {
            path{
                fill: ${({theme}) => theme.colors.performBlue[4]};
            }
        }
    }
    
    .mid-item{
        margin: 0 27px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    ${(props) => props.showCursor && css`
        :hover {
            .control-icon{
                opacity: 1;
                transition: .2s all ease-in-out ;
            }

            ${ProgressBar} {
                transition: 0.2s all ease-in-out ;
                transform: ${ props.isDragging ? 'none' : 'translateY(-40px)'};
                .time {
                    opacity: 1;
                }
            }
        }
    `}
    
`

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
`