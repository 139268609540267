import React, {memo, ReactElement, useCallback, useEffect, useRef, useState, useMemo} from "react";
import {AgGridReactProps} from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {GridNoColumns, GridWrapper} from "./DataGrid.style";
import FetchLoading from "../fetch-loading/FetchLoading";
import NoDataComponent from "./no-data-overlay/NoDataOverlay";
import {GridSizeChangedEvent} from "ag-grid-community/dist/lib/events";
import ReactTooltip from "react-tooltip";

interface IDataGridProps extends AgGridReactProps {
    loading?: boolean
    enableAllColumns?: () => void,
    fixedHeader?: boolean
    noDataComponent?: ReactElement,
    noDataOpacity?: number;
    fixedScroll?: boolean,
    allColumnsDisabled?: boolean,
    handleEnableAllColumns?: ()=> void,
    tooltipDelay?: number,
}

const DataGrid: React.FC<IDataGridProps> = (
    {
        loading = true,
        noDataOpacity,
        noDataComponent,
        enableAllColumns,
        fixedHeader = true,
        fixedScroll = false,
        allColumnsDisabled,
        handleEnableAllColumns,
        tooltipDelay = 450,
        children,
        ...rest
    }) => {
    const headerElementRef = useRef<any>(null);
    const bodyElementRef = useRef<any>(null);
    const scrollElementRef = useRef<any>(null)
    const stickyRef = useRef(false);
    const originalStyles = useRef({ position: "", top: "", zIndex: "", width: '' });
    const gridRef = useRef<any>(null)
    const [gridHeight, setGridHeight] = useState<number>(0)
    const [delay, setDelay] = useState<number>(tooltipDelay)


    // left in case we need tooltip delay on first hover!!!

    const header : HTMLDivElement | null= document.querySelector('.ag-header');
    if (header)  {
        header.onmouseleave = () => {
            setDelay(tooltipDelay)
        }
        header.onmouseenter =  () => {

            if (delay === tooltipDelay){
                setTimeout(()=> {setDelay(50)}, delay)
            }
        }
    }

    //Get references to table header and body elements and check initial position of the header
    useEffect(() => {
        headerElementRef.current = document.querySelector('.ag-header');
        bodyElementRef.current = document.querySelector('.ag-body-viewport');
        scrollElementRef.current = document.querySelector('.ag-body-horizontal-scroll')

        const header = headerElementRef.current;
        const body = bodyElementRef.current
        const scroll = scrollElementRef.current

        if(header && body) {
            if(header.getBoundingClientRect().top <= 0){
                stickyRef.current = true
            }
            if(body.getBoundingClientRect().top - header.getBoundingClientRect().height > 0){
                originalStyles.current.position = header.style.position;
                originalStyles.current.top = header.style.top;
                originalStyles.current.zIndex = header.style.zIndex;
                originalStyles.current.width = header.style.width;
                stickyRef.current = false
            }
        }

        if(scroll && fixedScroll) {
            if(body.getBoundingClientRect().bottom -  document.body.offsetHeight > 0){
                scroll.style.position = 'fixed'
                scroll.style.bottom = '0'
                scroll.style.width = body.getBoundingClientRect().width
            } else {
                scroll.style.position = 'relative'
            }
        }
    });

    const onScroll = () => {
        const header = headerElementRef.current;
        const body = bodyElementRef.current;
        const scroll = scrollElementRef.current

        if (!header || !body) return;

        let shouldStick = false;
        let shouldUnstick = false;

        if(scroll && fixedScroll) {
            if(body.getBoundingClientRect().bottom -  document.body.offsetHeight > 0){
                scroll.style.position = 'fixed'
                scroll.style.bottom = '0'
                scroll.style.width = body.getBoundingClientRect().width
            } else {
                scroll.style.position = 'relative'
            }
        }

        //Check if header should stick on top based on header and body position
        if (!stickyRef.current) {
            shouldStick = header.getBoundingClientRect().top <= 0;
            if (shouldStick){
                stickyRef.current = true;
            }
        } else {
            shouldUnstick =
                body.getBoundingClientRect().top -
                header.getBoundingClientRect().height >
                0;
            if (shouldUnstick){
                stickyRef.current = false;
            }
        }

        //Set header to fixed
        if (shouldStick) {
            header.style.position = "fixed";
            header.style.top = "0";
            header.style.zIndex = "2";
            header.style.width = body.getBoundingClientRect().width.toString() + 'px'
            body.style.marginTop = '40px'
        }

        //Set header to initial state
        if (shouldUnstick) {
            const original = originalStyles.current;
            header.style.position = original.position;
            header.style.top = original.top;
            header.style.zIndex = original.zIndex;
            header.style.width = original.width
            body.style.marginTop = '0'
        }
    };

    //Set header width on window resize
    const onResize = useCallback(() => {
        const header = headerElementRef.current
        const body = bodyElementRef.current
        if (!header || !body) return;
        if(stickyRef.current){
            header.style.width = body.getBoundingClientRect().width.toString() + 'px'
        }
    }, [])

    useEffect(() => {
        if(fixedHeader){
            window.addEventListener("scroll", onScroll);
            window.addEventListener('resize', onResize)
            return () => {
                window.removeEventListener("scroll", onScroll);
                window.removeEventListener('resize', onResize)
            }
        }
    });

    const defaultColDef = useMemo(() => ({
        resizable: true
    }), [])

    // simple hack for displaying horizontal scroll
    // set state will trigger rerender
    const onGridSizeChanged = (event: GridSizeChangedEvent) =>{
        setGridHeight(event.clientHeight)
    }

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[delay])

    useEffect(()=>{
        ReactTooltip.rebuild()
    })

    return (
        <>
            <ReactTooltip
                class={"react-tooltip-container"}
                arrowColor={"white"}
                place={"top"}
                delayShow={delay}
                effect="solid"
                type={'light'}
                multiline={true}/>
            {!allColumnsDisabled &&
                <GridNoColumns>
                    <div className="text">
                        Uh oh, it looks like you have no columns selected!
                    </div>
                    <div className="enable">
                        <div className="enable-btn" onClick={() => handleEnableAllColumns && handleEnableAllColumns()}>
                            Enable All Columns
                        </div>
                    </div>
                </GridNoColumns>
            }
            <div style={{display: allColumnsDisabled  ? 'revert' : 'none'}}>
                <GridWrapper
                    onGridSizeChanged={onGridSizeChanged}
                    ref={gridRef}
                    loadingOverlayComponent={FetchLoading}
                    noRowsOverlayComponent={() => <NoDataComponent noDataComponent={noDataComponent} noDataOpacity={noDataOpacity} />}
                    ensureDomOrder={true}
                    enableCellTextSelection={true}
                    defaultColDef={defaultColDef}
                    {...rest}
                />
            </div>
        </>
    )
}

export default memo(DataGrid)
