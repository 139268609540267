import {api} from '../../../../../common/services/api';
import {IPagination} from "../../../../../common/models/pagination.type";

export interface ISubcategory {
    id: number;
    name: string;
    parent_id: number;
    created_at: string;
    updated_at: string;
    description: string;
    status: string;
    min_cpc_bid: number;
    restrictions: string;
    first_n_clicks_count: number;
    after_n_clicks_count: number;
    campaign_name: string;
    campaign_url: string;
    brand_name: string;
    brand_campaign_url: string;
    default_conversion_rate: number;
    access: number;
    test_click_threshold?: number;
    shipping_value: string;
    currency_id?: number;
    network_id: number;
    forensiq_score?: number;
    financial_group: number;
    big_niche_id: number;
}

export interface IFetchNichesResponse {
    id: number;
    name: string;
    parent_id: number;
    created_at: string;
    updated_at: string;
    description: string;
    status: string;
    min_cpc_bid: number;
    restrictions: string;
    first_n_clicks_count?: number;
    after_n_clicks_count: number;
    campaign_name: string;
    campaign_url: string;
    brand_name: string;
    brand_campaign_url: string;
    default_conversion_rate: number;
    access: number;
    test_click_threshold?: number;
    shipping_value: string;
    currency_id?: number;
    network_id: number;
    forensiq_score?: any;
    financial_group: number;
    big_niche_id: number;
    min_bid: string;
    max_bid: string;
    shipping_and_handling_available: boolean;
    fields: any[];
    subcategories: ISubcategory[];
}

export interface ICustomDomainsResponse {
    is_default: number;
    domain: string;
    id: number;
    name_servers: string;
    status: string;
    user_id: number
}

export interface ICountry {
    id: number;
    code: string;
    code_3c: string;
    name: string;
    order: number;
}

export interface IState {
    id: number;
    code: string;
    name: string;
    country_id: number;
}

export interface IPivot {
    location_id: number;
    region_id: number;
}

export interface Region {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    long_name: string;
    pivot: IPivot;
}

export interface ILocation {
    id: number;
    country_id: number;
    state_id?: number;
    country_code: string;
    state_code: string;
    name: string;
    continent_code: string;
    code: string;
    type: string;
    simple_name: string;
    country: ICountry;
    state: IState;
    regions: Region[];
    pivot: IPivot;
}

export interface IFetchCountryResponse {
    countries: ICountry[];
    states: IState[];
    locations: ILocation[];
}

export interface IFetchContinentsResponse {
    id: number;
    name: string;
    code: string;
    long_name: string;
    locations: ILocation[];
}

export interface IFetchRegionsResponse extends IFetchContinentsResponse {

}

export interface IFetchCitiesResponse {
    id: number;
    name: string;
    state_name: string;
    maxmind_id: number;
    metro_code: string;
    state: string;
}

export interface ITrafficType {
    id: number;
    traffic_group_id: number;
    name: string;
    created_at: string;
    updated_at: string;
    selected: boolean
}


export interface IFetchTrafficTypesResponse {
    id: number;
    name: string;
    user_type: string;
    created_at: string;
    updated_at: string;
    types: ITrafficType[];
}

export interface IFetchCampaignRanges {
    types: string[];
    devices: string[];
    clicks: number;
    epc: number;
}

export interface IFetchAccountBalanceResponse {
    balance: boolean,
    card: boolean
}

export interface IFetchCurrenciesResponse {
    id: number;
    code: string;
    sign: string;
    pretty_name: string;
}

export interface IFetchBudgetGroupsResponse {
    id: number;
    advertiser_id: number;
    name: string;
    budget_type: string;
    budget: number;
    max_daily_spike?: any;
    approaching_daily_spike: boolean;
    paused_daily_spike_reached: number;
    budget_value_type: number;
    test_budget_link_type: string;
    test_budget_link: number;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
    budget_spent: boolean;
    budget_spent_until?: any;
    approaching_25_percent: boolean;
    delayed_actions?: any;
    meta_data?: any;
    campaigns: any[];
}

export interface ICreateBudgetGroupParams {
    budget_type: string;
    test_budget_link_type: string;
    budget_value_type: number;
    name: string;
    budget: string;
    test_budget_link: string;
}

export interface ICreateUpsellResponse {
    id: number;
    campaign_id: number;
    name: string;
    payout: number;
    created_at: string;
    updated_at: string;
    network_campaign_group_id?: any;
    edit_locked: number;
    pixel_group_id?: any;
    delayed_pixel_group_id?: any;
    deleted_at?: any;
    rev?: any;
}

export interface IFetchPixelGroupsResponse {
    id: number;
    advertiser_id: number;
    name: string;
    group_type: string;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
    offers_upsells: number;
    upsells: any[];
    offers: any[];
    postback_whitelist_ips: any[];
}

export interface ICreatePixelGroupParams {
    campaign_id: number,
    group_type: 'upsell' | 'offer'
    name: string,
    only_upsell?: boolean
    only_offer?: boolean
}

export interface IClickInfo {
    "User-Agent": string;
    click_id: string;
    date: Date;
    host: string;
    referer?: any;
    ts: number;
    "utc-date": Date;
}

export interface ITestTrackingPixelItems {
    ip: string;
    _id: {
        "$oid": string
    };
    tid: string;
    type: string;
    device_type: string;
    click_info: IClickInfo;
}

export interface ITestTrackingPixelResponse {
    items: ITestTrackingPixelItems[],
    time: number
}

export interface IFetchIspListResponse {
    isp: string,
    isp_id: string
}


class CpaService {
    static fetchCampaignsEmpty = async (token: string) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "campaigns/init/empty"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    pricing_model: 'cpa'
                }
            },)

    }

    static fetchCampaignRanges = async (token: string) => {

        return await api.get<IFetchCampaignRanges>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "campaigns/ranges/1"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchNiches = async (token: string) => {

        return await api.get<IFetchNichesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "niches-campaign"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchDomains = async (token: string, user_id: number, status: string = 'Verified') => {

        return await api.get<IPagination<ICustomDomainsResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    user_id,
                    status
                }
            },)

    }

    static fetchCountryState = async (token: string) => {

        return await api.get<IFetchCountryResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "country-state"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchTrafficTypes = async (token: string) => {

        return await api.get<IFetchTrafficTypesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "traffic-groups"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    with_types: true
                }
            },)

    }

    static fetchAccountBalance = async (token: string, id: number) => {

        return await api.get<IFetchAccountBalanceResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "users/account_balance"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    id
                }
            },)

    }

    static fetchContinents = async (token: string) => {

        return await api.post<IFetchContinentsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "filters/continents"
            ].join("/"),
            {

            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
            ,)

    }

    static fetchRegions = async (token: string) => {

        return await api.post<IFetchRegionsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "filters/regions"
            ].join("/"),
            {

            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchCities = async (token: string) => {

        return await api.get<IFetchCitiesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "cities"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchPixelGroups = async (token: string, group_type: 'upsell' | 'offer') => {

        return await api.get<IFetchPixelGroupsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    group_type,
                    no_pagination: true
                }
            },)

    }

    static fetchPixelGroup = async (token: string, id: number) => {
        return await api.get<IFetchPixelGroupsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups", id
            ].join("/"),
            {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                },
            )

    }

    static editPixelGroup = async (token: string, id: number, params: IFetchPixelGroupsResponse ) => {
        return await api.post<IFetchPixelGroupsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups", id
            ].join("/"),
            {
                ...params,
                _method: 'PATCH'
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },
        )

    }

    static fetchCurrencies = async (token: string) => {

        return await api.get<IFetchCurrenciesResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "currencies"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)

    }

    static fetchBudgetGroups = async (token: string) => {
        return await api.get<IFetchBudgetGroupsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "budget-groups"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    no_pagination: true
                }
            },)

    }

    static fetchIspList = async (token: string, campaign_id: number) => {

        return await api.get<IFetchIspListResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "isp_list"
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    campaign_id
                }
            },)

    }

    static createBudgeGroup = async (token: string, params: ICreateBudgetGroupParams) => {
        return await api.post<IFetchBudgetGroupsResponse>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            "budget-groups"
        ].join("/"),
            {
                ...params,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
            )
    }

    static createOffer = async (token: string, params: any) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "campaigns",
                params.id
            ].join("/"),
            {
                ...params,
                _method:"PATCH"
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static uploadZipcodes = async (token: string, campaign_id: number, file: File) => {
        const formData = new FormData()
        formData.append('file',file)
        formData.append('campaign_id',campaign_id.toString())
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "zipcode_upload"
            ].join("/"),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            },)
    }

    static deleteZipCode = async (token: string, campaign_id: number, fileName: string) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "zipcode_delete"
            ].join("/"),
            {
                campaign_id,
                file: fileName
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }

    static createUpsell = async (token: string, campaign_id: number) => {
        return await api.post<ICreateUpsellResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "upsells"
            ].join("/"),
            {
                campaign_id,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static createPixelGroup = async (token: string, params: ICreatePixelGroupParams) => {
        return await api.post<ICreateUpsellResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "pixel-groups"
            ].join("/"),
            {
                ...params,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static testTrackingPixel = async (token: string, campaign_id: number, loadPrevious: boolean, time: number) => {
        return await api.post<ITestTrackingPixelResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "campaigns/test",
                campaign_id
            ].join("/"),
            {
                id: campaign_id,
                time: !loadPrevious ? time : Math.floor(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime() / 1000)
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static estimateTraffic = async (token: string, niche_id: number) => {
        return await api.get<{estimated_traffic: number}>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                `campaigns/estimates/${niche_id}`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }

    static fetchEditOffer = async (token: string, id: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                `campaigns/${id}/edit`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            },)
    }

    static deleteThumbnail = async (token: string, id: number) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            'file/thumbnail',
            id
        ].join("/"),
            {
                _method: 'DELETE'
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
            )
    }

    static getThumbnailInfo = async (token: string, id: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'file/thumbnail',
                id,
                'info'
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static replicateCreatives = async (token: string, id_edit: number, id_new: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                `campaigns/${id_edit}/creatives/replicate`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    new_campaign_id: id_new
                }
            }
        )
    }

    static replicateUpsells = async (token: string, id_edit: number, id_new: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                `campaigns/${id_edit}/upsells/replicate`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    new_campaign_id: id_new
                }
            }
        )
    }

    static getCampaignsInfo = async (token: string, budget_group_id: number) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                `budget-groups/get-campaigns-info/${budget_group_id}`
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

}

export default CpaService