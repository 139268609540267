import styled, {css} from "styled-components";

export const TrackingSystemHolder = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px 16px;
`

interface ITrackingSystemBox {
    active: boolean;
}

export const TrackingSystemBox = styled.div<ITrackingSystemBox>`
    width: 25%;
    position: relative;
    padding: 8px;
    @media (max-width: ${({theme}) => theme.screen.xs}) {
        width: 33.3333%;
    }
    @media (max-width: 520px) {
        width: 50%;
    }
    > div {
        display: flex;
        height: 188px;
        border-radius: 24px;
        background-color: ${({theme}) => theme.colors.pureWhite};
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        flex-direction: column;
        cursor: pointer;
        transition: .2s ease-in-out all;
        overflow: hidden;
        color: ${({theme}) => theme.colors.deepBlue[6]};
        @media (max-width: ${({theme}) => theme.screen.xl}) {
            height: 140px;
        }
        @media (max-width: ${({theme}) => theme.screen.lg}) {
            height: 168px;
        }
        @media (max-width: ${({theme}) => theme.screen.md}) {
            height: 195px;
        }
        @media (max-width: ${({theme}) => theme.screen.sm}) {
            height: 138px;
            border-radius: 18px;
        }
        .checked-icon {
            position: absolute;
            top: 15px;
            right: 18px;
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            box-shadow: inset 0 0 0 1px #E7ECEE;
            border-radius: 24px;
            transition: .2s ease-in-out all;
            @media (max-width: ${({theme}) => theme.screen.sm}) {
                border-radius: 18px;
            }
        }

        ${props => props.active ?
            css`
                box-shadow: 0 0 20px rgba(99, 231, 249, 0.34);

                &:before {
                    box-shadow: inset 0 0 0 2px ${({theme}) => theme.colors.performBlue[4]};
                }
            `
            :
            css`  :hover {
                &:before {
                    box-shadow: inset 0 0 0 1px ${({theme}) => theme.colors.performBlue[3]};
                }

                box-shadow: 0 0 16px rgba(156, 243, 255, 0.29);
            }`
        }
        .tracking-system-other{
            margin-top: 12px;
            font-size: 16px;
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            color: ${({theme}) => theme.colors.deepBlue[5]};
        }
        .none {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            color: ${({theme}) => theme.colors.deepBlue[5]};
            font-size: 16px;
        }
        > img {
            width: 100%;
            padding: 0 15px;
        }
    }


    svg {
        margin-bottom: 14px;
    }

    i {
        margin-top: 14px;
        display: inline-block;
        color: ${({theme}) => theme.colors.deepBlue[3]};
    }
`

export const CheckboxesGroup = styled.div`
    font-family: ${({theme}) => theme.fonts.AvenirRoman} !Important;
    font-size: 24px;
    display: flex;
    justify-content: left;
    .checkbox-with-external-tooltip {
        margin-right: 20px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        i.ico-Information-outlined {
            margin-left: 4px;
            color: ${({theme}) => theme.colors.coolGray[5]};
        };
        
    }
`