import styled, {css} from 'styled-components'

export const LabelStyledFloatable = styled.label<{$disabled?: boolean}>`
  position: absolute;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  line-height: 16px;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  padding: 0 4px;
  top: -8px;
  left: 8px;
    z-index: 1;
    transition: color .2s ease;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 8px;
        height: 1px;
        background: #ffffff;
        z-index: -1;
    }
`

export const LabelStyledDefault = styled.label`
    display: block;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    font-size: 12px;
    margin-bottom: 8px;
`

export const Wrapper = styled.div<{error: boolean}>`
  margin-bottom: 24px;
  position: relative;
  margin-top: 20px;
    
    .optional{
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
    }
  :focus-within{
    ${LabelStyledFloatable} {
      color: ${({theme, error}) => !error ? theme.colors.performBlue[5] : '#F23842'};
    }
  }
  
  ${({error}) => error && css`
    ${LabelStyledFloatable} {
      color: #F23842;
      
    }

    ${StyledTextArea} {
      border-color: #F23842;

      :hover{
        border-color: #F23842;
      }
      
      :focus{
        border-color: #F23842;
      }
    }
  `}
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 16px 12px;
  outline: none;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  resize: vertical;
    min-height: 50px;
    transition: border-color .2s ease;
    
  ::placeholder {
    color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  :hover{
    border: 1px solid ${({theme}) => theme.colors.coolGray[5]};
  }

  :focus{
    border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
  }
    :disabled {
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        background-color: white;
        color: ${({theme}) => theme.colors.coolGray[4]};
    }
`

export const ErrorStyled = styled.label`
  position: absolute;
  bottom: -12px;
  right: 12px;
  color: #F23842;
`