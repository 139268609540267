import {SVGAttributes} from 'react'

export const IconSent: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M23.409 0.012508L4.13776 1.1904C3.378 1.23827 2.65126 1.52416 2.05662 2.00912C1.46198 2.49407 1.02838 3.15448 0.81494 3.9003C0.601499 4.64612 0.618604 5.44106 0.863921 6.17673C1.10924 6.9124 1.57083 7.55301 2.18576 8.0112L8.06174 12.3566C8.2 12.4639 8.31593 12.5983 8.40278 12.7518C8.48963 12.9053 8.54565 13.075 8.56758 13.2508L9.31676 20.6173C9.38135 21.2433 9.59816 21.8429 9.94748 22.3617C10.2968 22.8805 10.7676 23.302 11.317 23.5879C11.8664 23.8738 12.4771 24.015 13.0935 23.9987C13.7099 23.9825 14.3125 23.8092 14.8465 23.4947C15.3437 23.1949 15.7689 22.7853 16.091 22.2961L26.7261 5.85126C27.1024 5.26629 27.312 4.58604 27.3317 3.88642C27.3513 3.18679 27.1801 2.49531 26.8372 1.88911C26.4942 1.28292 25.9931 0.785852 25.3897 0.453375C24.7862 0.120898 24.1042 -0.0339136 23.4197 0.00621406L23.409 0.012508ZM24.6606 4.46572L14.0255 20.9105C13.8836 21.1225 13.6816 21.2851 13.4466 21.3765C13.2116 21.4679 12.9548 21.4838 12.7106 21.422C12.4665 21.3602 12.2466 21.2236 12.0806 21.0307C11.9146 20.8378 11.8103 20.5977 11.7817 20.3425L11.0218 12.9824C11.0106 12.8847 10.9939 12.7878 10.9716 12.6921L18.3307 8.35567C18.6139 8.18875 18.8206 7.91377 18.9052 7.59123C18.9898 7.26869 18.9455 6.92501 18.7819 6.6358C18.6183 6.34658 18.3488 6.13552 18.0328 6.04904C17.7169 5.96257 17.3802 6.00776 17.0969 6.17469L9.73783 10.5111C9.6678 10.4436 9.59395 10.3803 9.51667 10.3216L3.65137 5.96984C3.44919 5.81703 3.29759 5.60479 3.2169 5.36157C3.13621 5.11834 3.13028 4.85578 3.19992 4.60907C3.26956 4.36236 3.41143 4.1433 3.60651 3.98127C3.80159 3.81924 4.04055 3.72198 4.29136 3.70254L23.5626 2.52464C23.7893 2.51306 24.0148 2.56557 24.2142 2.6764C24.4136 2.78723 24.5792 2.95207 24.6927 3.15277C24.8062 3.35347 24.8633 3.58225 24.8576 3.81391C24.852 4.04556 24.7838 4.27111 24.6606 4.46572Z" fill="url(#paint0_linear_427_12168)"/>
            <defs>
                <linearGradient id="paint0_linear_427_12168" x1="13.9998" y1="0" x2="13.9998" y2="24" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFD02B"/>
                    <stop offset="1" stopColor="#FF5C00"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export const IconOffer: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M26.5465 13.44L14.5465 1.43996C14.0665 0.959959 13.3998 0.666626 12.6665 0.666626H3.33317C1.8665 0.666626 0.666504 1.86663 0.666504 3.33329V12.6666C0.666504 13.4 0.959837 14.0666 1.45317 14.56L13.4532 26.56C13.9332 27.04 14.5998 27.3333 15.3332 27.3333C16.0665 27.3333 16.7332 27.04 17.2132 26.5466L26.5465 17.2133C27.0398 16.7333 27.3332 16.0666 27.3332 15.3333C27.3332 14.6 27.0265 13.92 26.5465 13.44ZM15.3332 24.68L3.33317 12.6666V3.33329H12.6665V3.31996L24.6665 15.32L15.3332 24.68Z" fill="url(#paint0_linear_427_5042)"/>
            <path d="M6.6665 8.66663C7.77107 8.66663 8.6665 7.7712 8.6665 6.66663C8.6665 5.56206 7.77107 4.66663 6.6665 4.66663C5.56193 4.66663 4.6665 5.56206 4.6665 6.66663C4.6665 7.7712 5.56193 8.66663 6.6665 8.66663Z" fill="url(#paint1_linear_427_5042)"/>
            <defs>
                <linearGradient id="paint0_linear_427_5042" x1="13.9998" y1="0.666626" x2="13.9998" y2="27.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#61C8FF"/>
                    <stop offset="1" stopColor="#0081C6"/>
                </linearGradient>
                <linearGradient id="paint1_linear_427_5042" x1="13.9998" y1="0.666626" x2="13.9998" y2="27.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#61C8FF"/>
                    <stop offset="1" stopColor="#0081C6"/>
                </linearGradient>
            </defs>
        </svg>

    )
}

export const IconMoney: React.FC<SVGAttributes<any>> = ({...rest}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9998 0.666626C11.3628 0.666626 8.7849 1.44861 6.59224 2.9137C4.39958 4.37878 2.69062 6.46116 1.68145 8.89751C0.672281 11.3339 0.408237 14.0148 0.922706 16.6012C1.43718 19.1876 2.70705 21.5634 4.57175 23.4281C6.43645 25.2928 8.81223 26.5626 11.3986 27.0771C13.9851 27.5916 16.6659 27.3275 19.1023 26.3184C21.5386 25.3092 23.621 23.6002 25.0861 21.4076C26.5512 19.2149 27.3332 16.637 27.3332 14C27.3332 12.249 26.9883 10.5152 26.3182 8.89751C25.6482 7.27984 24.666 5.80998 23.4279 4.57187C22.1898 3.33375 20.72 2.35163 19.1023 1.68157C17.4846 1.0115 15.7508 0.666626 13.9998 0.666626ZM8.07376 22.869C9.82788 24.041 11.8902 24.6666 13.9998 24.6666C16.8288 24.6666 19.5419 23.5428 21.5423 21.5424C23.5427 19.542 24.6665 16.8289 24.6665 14C24.6665 11.8903 24.0409 9.828 22.8689 8.07388C21.6968 6.31975 20.0309 4.95258 18.0818 4.14524C16.1327 3.33791 13.988 3.12667 11.9189 3.53825C9.84975 3.94983 7.94913 4.96573 6.45737 6.45749C4.96561 7.94925 3.94971 9.84987 3.53813 11.919C3.12656 13.9881 3.33779 16.1328 4.14513 18.0819C4.95246 20.031 6.31964 21.6969 8.07376 22.869Z" fill="url(#paint0_linear_427_541)"/>
            <path d="M13.9998 12.6666C11.3332 12.6666 11.3332 11.8266 11.3332 11.3333C11.3332 10.84 12.2665 9.99996 13.9998 9.99996C15.7332 9.99996 15.8532 10.8533 15.8665 11.3333H18.5332C18.5152 10.425 18.1885 9.54983 17.6068 8.8519C17.0252 8.15397 16.2234 7.67482 15.3332 7.49329V5.99996H12.6665V7.45329C9.99984 7.89329 8.66651 9.61329 8.66651 11.3333C8.66651 12.8266 9.35984 15.3333 13.9998 15.3333C16.6665 15.3333 16.6665 16.24 16.6665 16.6666C16.6665 17.0933 15.8398 18 13.9998 18C11.5465 18 11.3332 16.8533 11.3332 16.6666H8.66651C8.66651 17.8933 9.54651 20.0666 12.6665 20.56V22H15.3332V20.56C17.9998 20.1066 19.3332 18.3866 19.3332 16.6666C19.3332 15.1733 18.6398 12.6666 13.9998 12.6666Z" fill="url(#paint1_linear_427_541)"/>
            <defs>
                <linearGradient id="paint0_linear_427_541" x1="13.9998" y1="0.666626" x2="13.9998" y2="27.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00ED7B"/>
                    <stop offset="1" stopColor="#05C76A"/>
                </linearGradient>
                <linearGradient id="paint1_linear_427_541" x1="13.9998" y1="0.666626" x2="13.9998" y2="27.3333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00ED7B"/>
                    <stop offset="1" stopColor="#05C76A"/>
                </linearGradient>
            </defs>
        </svg>
    )
}


