import React from 'react'

import {EmailCreativeThumbnailItemWrapper} from "../../CreativesAndEmail.style";
import {HtmlIcon, TxtIcon} from "../../../../../../../../../assets/svgs/SvgIcons";

interface Props {
    backgroundUrl?: string,
    html?: boolean
    txt?: boolean
    removeFile: () => void
}

const EmailCreativeThumbnail: React.FC<Props> = ({
    backgroundUrl,
    html,
    txt,
    removeFile
                                                 }) => {
    return <EmailCreativeThumbnailItemWrapper style={{
        backgroundImage: backgroundUrl ? `url("${backgroundUrl}")` : 'none'
    }}>
        <i className={'ico-Exit'} onClick={removeFile}/>

        {html ? <HtmlIcon/> : null}
        {txt ? <TxtIcon/> : null}
    </EmailCreativeThumbnailItemWrapper>
}

export default EmailCreativeThumbnail

/*
* TODO:
*  - add delete functionality
* */