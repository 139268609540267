import React from "react";

import {Wrapper, HiddenCheckbox, StyledCheckbox, Label} from "./Checkbox.style";

import {ICheckboxProps} from "./checkbox.props";

const Checkbox: React.FC<ICheckboxProps> = (
    {
        checked,
        onChange,
        label,
        disabled= false,
        indeterminate = false,
        wrapperClassName,
        labelClassName,
        checkboxClassName,
        ...props
    }) => {
    if(indeterminate) {
        return <Wrapper checked={checked} disabled={disabled}>
            <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
            <StyledCheckbox checked={checked} disabled={disabled}>
                <span className="indeterminate"></span>
            </StyledCheckbox>
        </Wrapper>
    }
    return (
        <Wrapper className={wrapperClassName} checked={checked} disabled={disabled}>
            <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
            <StyledCheckbox className={checkboxClassName} checked={checked} disabled={disabled}>
                <i className="ico-Check" />
            </StyledCheckbox>
            {label && <Label className={labelClassName}>{label}</Label>}
        </Wrapper>
    )
}

export default Checkbox