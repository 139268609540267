import React, {useRef, useState} from "react";

import AddTrackingPlatformModal from "../add-tracking-platform-modal/AddTrackingPlatformModal";

import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {Wrapper, DropdownContainerStyled} from "./TrackingPlatform.style";

import {ITrackingPlatformProps} from "./trackingPlatform.props";
import {useOnClickOutside} from "../../../../../../common/hooks";
import Modal from "../../../../../../common/components/modal/Modal";
import DeleteTrackingPlatformModal from "../delete-tracking-platform-modal/DeleteTrackingPlatformModal";

const TrackingPlatform: React.FC<ITrackingPlatformProps> = ({platform, fetchPlatforms}) => {
    const [isOptionsDropdownOpened, setIsOptionsDropdownOpened] = useState(false)
    const [isEditTrackingPlatformModalOpened, setIsEditTrackingPlatformModalOpened] = useState(false)
    const [isDeleteTrackingPlatformModalOpened, setIsDeleteTrackingPlatformModalOpened] = useState(false)

    const ref = useRef<HTMLDivElement | null>(null)
    useOnClickOutside(ref, () => setIsOptionsDropdownOpened(false))
    return (
        <Wrapper >
            <div  className="platform-name">Affluent ({platform.affluent_advertiser_id})</div>
            <div ref={ref} className="more-button" onClick={() => setIsOptionsDropdownOpened(!isOptionsDropdownOpened)}>
                <i className={'ico-More'} />
                <DropdownContainerStyled opened={isOptionsDropdownOpened}>
                    <DropdownItem onClick={() => setIsEditTrackingPlatformModalOpened(true)}>
                        Edit Tracking Platform
                    </DropdownItem>
                    <hr/>
                    <DropdownItem onClick={() => setIsDeleteTrackingPlatformModalOpened(true)}
                                  style={{color: '#FA1E3A'}}>
                        Remove Tracking Platform
                    </DropdownItem>
                </DropdownContainerStyled>
            </div>
            <Modal opened={isEditTrackingPlatformModalOpened}
                   title={"Edit Tracking Platform"}
                   closeModal={() => setIsEditTrackingPlatformModalOpened(false)}>
                <AddTrackingPlatformModal fetchPlatforms={fetchPlatforms}
                                          platform={platform}
                                          closeModal={() => setIsEditTrackingPlatformModalOpened(false)} />
            </Modal>
            <Modal opened={isDeleteTrackingPlatformModalOpened}
                   title={"Delete Tracking Platform?"}
                   closeModal={() => setIsDeleteTrackingPlatformModalOpened(false)}>
                <DeleteTrackingPlatformModal fetchPlatforms={fetchPlatforms}
                                             closeModal={() => setIsDeleteTrackingPlatformModalOpened(false)}
                                             platform={platform}/>
            </Modal>
        </Wrapper>
    )
}
export default TrackingPlatform