import styled from 'styled-components'
import {ButtonStyled} from "../../../../../../../common/styled-components";

export const Wrapper = styled.div`
    .form{
        display: flex;
        gap: 80px;
        margin-top: 24px;
        .date-form{
            .form-label{
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                color: ${({theme}) => theme.colors.deepBlue[5]};
                margin-bottom: 16px;
            }
            .form-row {
                margin-bottom: 16px;
            }
        }

        @media (max-width: 700px){
            flex-direction: column;
            gap: 24px;
        }
    }
`

export const AddEntryButton = styled(ButtonStyled).attrs({
    className: 'btn-default-outlined row',
})`
    display: flex;
    align-items: center;
    gap: 5px;
    span{
        font-size: 18px;
    }
`

export const EntryContainer = styled.div`
    > div {
        &:last-child {
            &:after {
                 display: none;
            }
        }
    }
`
export const EntryRow = styled.div`
    display: flex;
    //justify-content: space-between;
    margin-top: 24px;
    align-items: center;
    
    .to{
        padding: 0 16px;
        @media (max-width: ${({theme}) => theme.screen.sm}) {
            padding: 0;
        }
    }
    .entry{
        display: flex;
        gap: 8px;
        align-items: center;
        

        .ico-Exit{
            color: red;
            font-size: 16px;
            cursor: pointer;
        }
    }
    
    .select-day-wrapper {
        width: 120px;
    }

    .select-hour-wrapper {
        width: 80px;
    }

    .select-minute-wrapper {
        width: 80px;
    }

    @media (max-width: ${({theme}) => theme.screen.sm}) {
        position: relative;
        padding-top: 24px;
        flex-wrap: wrap;
        gap: 16px;
        &:after {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            background: ${({theme}) => theme.colors.coolGray[3]};
            bottom: -24px;
        }
    }
`