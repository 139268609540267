import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";
import {isNullOrUndefined} from "../../../../../../../common/utils";

interface Props {}

const TrackingSystem: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(isNullOrUndefined(offer.trackingSystem)) return null

    return (
        <>
            <Subtitle> Tracking System</Subtitle>
            <Item style={{ textTransform: "capitalize"}}>{offer.trackingSystem.replace('-', ' ')}</Item>
        </>
    )
}

export default TrackingSystem