import styled from "styled-components";

export const Container = styled.div`
    padding: 40px 40px 80px 40px;
    background: ${props => props.theme.colors.pureWhite};
    &:before {
        content: "";
        background: ${props => props.theme.colors.pureWhite};
        z-index: -1;
        height: 100%;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
    }
    @media screen and (max-width: 897px) {
        padding: 40px 20px 80px 20px;
    }
`