import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface InitStateAdminUsers {
    usersSearchValue: string,
    // sort: {
    //     direction: 'desc' | 'asc',
    //     by: string
    // },
    perPage: number,

}

const initialState: InitStateAdminUsers = {
    usersSearchValue: '',
    // sort: {
    //     direction: "desc",
    //     by: 'id'
    // },
    perPage: 50
}

const usersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        setUsersSearchValue: (state : InitStateAdminUsers, action: PayloadAction<string>) => {
            state.usersSearchValue = action.payload;
        },
        setPerPage: (state:InitStateAdminUsers, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        }
    }
})

export default usersSlice.reducer
export const {
    setUsersSearchValue,
    setPerPage
} = usersSlice.actions