import React, {ChangeEvent, useState} from "react";

import DatePicker from "../../../../../../../../../common/components/date-picker/DatePicker";
import Dropdown from "../../../../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../../../../common/components/dropdown/Dropdown.style";

import {Wrapper, InputSingleStyled} from "./FlatFee.style";
import {Label} from "../CreateNewFeeModal.style";
import {IFlatFeeProps} from "./flatFee.props";
import {formatNumberToCurrency} from "../../../../../../../../../common/utils";

const FlatFee:React.FC<IFlatFeeProps> = (
    {
        feeAmount,
        setFeeAmount,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        recurring,
        setRecurring
    }
    ) => {

    const [recurringOpened, setRecurringOpened] = useState(false)

    const handleRecurringSelect = (type: number) => {
        setRecurring(type)
        setRecurringOpened(false)
    }

    const handleFeeAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid) {
            setFeeAmount(e.target.value)
        }
    }

    const handleFeeAmountBlur = () => {
        feeAmount && setFeeAmount(formatNumberToCurrency(parseFloat(feeAmount.replace(/[^0-9.]+/g, ''))))
    }

    return (
        <Wrapper>
            <Label>Fee Amount</Label>
            <InputSingleStyled
                placeholder={'$'}
                value={feeAmount ? feeAmount : ''}
                onChange={(e) => handleFeeAmountChange(e)}
                onBlur={() => handleFeeAmountBlur()}
            />
            <Label>Fee date</Label>
            <div className="fee-date-wrapper">
                <div>
                    <span className={'fee-date--label'}>Recurring</span>
                    <Dropdown
                        text={recurring === 0 ? 'No' : 'Monthly'}
                        isOpened={recurringOpened}
                        setOpened={setRecurringOpened}
                    >
                        <DropdownItem
                            onClick={() => handleRecurringSelect(0)}
                            selected={recurring === 0}
                        >
                            No
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => handleRecurringSelect(1)}
                            selected={recurring === 1}
                        >
                            Monthly
                        </DropdownItem>
                    </Dropdown>
                </div>
                <div style={{
                    width: '100%'
                }}>
                    <span className={'fee-date--label'}>Start date</span>
                    <DatePicker
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        date={startDate}
                        setDate={setStartDate}
                        inputWidth={'100%'}
                        placeholder={'Add start date'}
                    />
                </div>

                {recurring === 1 && <div style={{
                    width: '100%'
                }}>
                    <span className={'fee-date--label'}>
                        End date
                        <span className={'optional'}>
                            (Optional)
                        </span>
                    </span>
                    <DatePicker
                        minDate={startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1)) : new Date(new Date().setDate(new Date().getDate() + 1))}
                        date={endDate}
                        setDate={setEndDate}
                        inputWidth={'100%'}
                        placeholder={'Add end date'}
                    />
                </div>}
            </div>
        </Wrapper>

    )
}

export default FlatFee