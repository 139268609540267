import styled, { css } from "styled-components";

export const FiltersRowWrapper = styled.div<{$width?: number}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0px;
    min-height: 36px;
    ${({$width}) => ($width && ($width < 1024)) && css`
        flex-direction: column;
        align-items: start;
        gap: 10px;
        & > div:not(:first-child) {
            align-self: flex-end;
        }
    `}
`;

export const FiltersRowLeft = styled.div`
    display: flex;
    align-items: center;
    & > div {
        margin: 0 4px;
    }

    & > div:first-child {
        margin-left: 0;
    }
    
    & > div:last-child {
        margin-right: 0;
    }
`;

export const FiltersRowRight = styled.div`
    display: flex;
    align-items: center;
`;