import styled from "styled-components";

interface IContainerProps {
    opened: boolean,
    position: {
        bottom: number,
        right: number
    }
}

interface IOptionProps {
    disabled: boolean
}

export const Container = styled.div<IContainerProps>`
  display: ${(props) => props.opened ? 'block' : 'none'};
  padding: 16px;
  border: 1px solid #E9ECED;
  min-width: 175px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  background-color: white;
  top: ${props => props.position.bottom}px;
  z-index: 5;
  left: ${props => props.position.right}px;
  transform: translateX(-100%);

  .options-line {
    border-top: 1px solid #cdcdcd;
    margin: 5px 0;
  }
`

export const Option = styled.div<IOptionProps>`
  display: block;
  white-space:nowrap;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  font-size: 12px;
  
  :hover{
    background-color: ${({theme}) => theme.colors.performBlue['1']};
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.performBlue['8']};
    color: white;
  }
  
`