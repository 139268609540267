import React, {ChangeEvent, useEffect, useState} from 'react'
import {IEditStayLiveLimitParams, IIFetchMarketerFundingDataResponse} from "../../MarketerFundingMonitoring.service";
import Modal from "../../../../../../../common/components/modal/Modal";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../../../common/styled-components";
import {NotesModalHeader} from "../../add-edit-notes-modal/AddEditNotesModal.style";
import Input from "../../../../../../../common/components/input/Input";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {formatNumberToCurrency} from "../../../../../../../common/utils";

interface Props {
    modal: {
        opened: boolean,
        data: IIFetchMarketerFundingDataResponse | null
    },
    closeModal: () => void
    fetchTableData: () => void
    editStayLiveLimit: (params: IEditStayLiveLimitParams) => void
}

const EditStayLiveLimitModal: React.FC<Props> = ({
                                                        modal,
                                                        closeModal,
                                                        fetchTableData,
                                                        editStayLiveLimit
                                                 }) => {
    const [stayLiveLimitValue, setStayLiveLimitValue] = useState('')
    const [unlimited, setUnlimited] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(modal.data?.credit){
            setStayLiveLimitValue(
                formatNumberToCurrency(
                    parseFloat(
                        modal.data?.credit.toString().replace(/[^0-9.]+/g, '')
                    )
                )
            )
        } else {
            setStayLiveLimitValue('')
        }

        if(modal.data?.credit === null) {
            setUnlimited(true)
        } else {
            setUnlimited(false)
        }

    }, [modal])

    const handleSave = async () => {
        if(modal.data){
            const params: IEditStayLiveLimitParams = {
                user_id: modal.data.id,
                credit: unlimited ? 'unlimited' :
                    stayLiveLimitValue === '' ? 0 :
                        parseFloat(stayLiveLimitValue.replace(/\$|,/g, ''))
            }
            setLoading(true)
            await editStayLiveLimit(params)
            fetchTableData()
            closeModal()
        }
        setLoading(false)

    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid){
            setStayLiveLimitValue(e.target.value)
        }
    }

    const handleInputBlur = () => {
        const formatedInput = formatNumberToCurrency(
            parseFloat(
                stayLiveLimitValue.toString().replace(/[^0-9.]+/g, '')
            )
        );
        setStayLiveLimitValue(formatedInput)
    }

    const modalHeader = <NotesModalHeader>
        <h2>Edit Stay Live Limit</h2>
        <p>Marketer ID: <span className={'notes-header-value'}>{modal.data?.id}</span></p>
        <p>Marketer Company Name: <span className={'notes-header-value'}>{modal.data?.company}</span></p>
        <p>Marketer Name: <span className={'notes-header-value'}>{modal.data?.name}</span></p>
        <p>Engine Growth Manager: <span className={'notes-header-value'}>{modal.data?.account_manager_name + ' ' + modal.data?.account_manager_lastname}</span></p>
    </NotesModalHeader>

    return <Modal opened={modal.opened} closeModal={closeModal} title={modalHeader}>
        <div style={{marginTop: '32px'}}></div>
        <Input
            disabled={unlimited}
            value={stayLiveLimitValue}
            onChange={(e) => handleInputChange(e)}
            pattern={"[0-9, $, .]*"}
            label={'Stay Live Limit'}
            onBlur={handleInputBlur}
            placeholder={'$0.00'}
        />
        <Checkbox
            checked={unlimited}
            onChange={() => setUnlimited(!unlimited)}
            label={'Unlimited Stay Live'}
        />
        <ModalFooter>
            <ButtonStyled onClick={closeModal}>Cancel</ButtonStyled>
            <ButtonStyled
                disabled={loading}
                onClick={handleSave}
                className={'btn-orange-filled'}
            >
                {loading && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}Save
            </ButtonStyled>
        </ModalFooter>
    </Modal>
}

export default EditStayLiveLimitModal