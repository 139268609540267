import styled, {css} from "styled-components";

interface IButtonProps {
    disabled?: boolean
}

export const Wrapper = styled.div`
  width: 465px;
  max-width: 100%;
`

export const TagSearchLabel = styled.p`
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  font-size: 12px;
  margin-bottom: 16px;
`

export const Cancel = styled.div`
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  :hover{
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue['7']};
  }
  
  :hover:active{
    color: ${({theme}) => theme.colors.deepBlue['9']};
  }

`

export const Button = styled.div<IButtonProps>`
  padding: 8px 16px;
  color: white;
  font-size: 12px;
  background-color: ${({theme}) => theme.colors.negative['5']};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  line-height: 1.55;
  
  :hover{
    background-color: ${({theme}) => theme.colors.negative['5']};
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.negative['7']};
  }
  
  ${props => {
    if(props.disabled){
        return css`
          pointer-events: none;
          background-color: ${({theme}) => theme.colors.coolGray['2']};
          color: ${({theme}) => theme.colors.coolGray['4']};
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
        `
    }
    return
}}
`

export const DeleteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  `