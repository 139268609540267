import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";

interface Props {}

const TrackingCodePlaced: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(
        navState['tracking-codes'] === 'disabled' ||
        offer.detailed_pricing_model === 'cpi' ||
        offer.detailed_pricing_model === 'cpe'  ||
        offer.detailed_pricing_model === 'ppc'
    ) return null

    return (
        <>
            <Subtitle>Tracking code(s) placed</Subtitle>
            <Item>{offer.tracking_code_placed ? 'Yes' : 'No'}</Item>
        </>
    )
}

export default TrackingCodePlaced