import axios, { AxiosResponse } from "axios";
import { api } from "./api";
import {ITag} from "../models/tag.type";
import {IRole} from "../models/user.type";
class FiltersService {
   
    static fetchReferrers = async (token: string): Promise<AxiosResponse> => {
        try{
            const response = await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_REFERRALS
                ].join("/"), {},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params: {}
            })
            return response;
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    
    }

    static fetchAccountManagers = async (token: string): Promise<AxiosResponse> => {
        try{
            const response = await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_ACCOUNT_MANAGERS
                ].join("/"), {},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params: {}
            })
            return response;
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    
    }

    static fetchTags = async (token: string): Promise<AxiosResponse<ITag[]>> => {
        try{
            const response = await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_TAGS
                ].join("/"),
                {},
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params: {}
                })
            return response;
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    
    }

    static fetchUserRoles = async (token : string): Promise<AxiosResponse<IRole[]>> =>{
        try {
            return await api.get([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                process.env.REACT_APP_API_USER_ROLES
            ].join("/"),
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
        }
        catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
}

export default FiltersService;