import styled from "styled-components";
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";


export const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .domain-filter {
        margin-bottom: 0;
    }
`

export const ContentWrapper = styled.div`
    background: #ffffff;
    padding: 40px 40px 25px;
`

export const CustomModalFooter = styled(ModalFooter)`
    justify-content: flex-end;
    border-top: none;
`

export const DeleteDomainModalBody = styled.div`
    min-width: 420px;
    max-width: 420px;
    p {
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`
export const DeleteDropdownItem = styled(DropdownItem) `
    color: ${({theme}) => theme.colors.performOrange[3]};
    user-select: none;
    :hover {
        background: ${({theme}) => theme.colors.performOrange[1]};
    }
    :hover:active{
        background-color: ${({theme}) => theme.colors.performOrange[2]};
        color: ${({theme}) => theme.colors.performOrange[7]};
    }
    &.disabled {
        pointer-events: none;
        opacity: .3;
    }
`

export const DomainUrl = styled.span `
    color: ${({theme}) => theme.colors.performBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    cursor: pointer;
`
