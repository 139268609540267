import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IPreset } from "../../../../../common/components/range-date-picker/presets/presets.props";

export interface IInitialState  {
    sort: {
        direction: 'desc' | 'asc',
        by: string
    },
    per_page: number,
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset
}

const initialState: IInitialState = {
    sort: {
        direction: "desc",
        by: ''
    },
    per_page: 50,
    date_from: null,
    date_to: null,
    date_range_preset: {
        value: "last_7_days",
        name: 'Last 7 days'
    }
}

const partnerTransactionsSlice = createSlice({
    name: 'partnerTransactions',
    initialState,
    reducers: {
        setSort: (state: IInitialState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.sort = action.payload
        },
        setPerPage: (state: IInitialState, action:PayloadAction<number>) => {
            state.per_page = action.payload;
        },
        setDateFrom: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setDateRangePreset: (state: IInitialState, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
    }
})
export const {
    setSort,
    setPerPage,
    setDateFrom,
    setDateTo,
    setDateRangePreset
} = partnerTransactionsSlice.actions

export default partnerTransactionsSlice.reducer
