import {api} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";
import {CancelTokenSource} from "axios";
import qs from "qs";

export interface IFetchLeapPartnersTableDataParams {
    with_link_count: boolean
    with_w8w9_status: boolean
    user_type: string
    with_concat_networks: 'all'
    sort_by: string
    order: string
    original_match: number,
    paginate_by?: number,
    page?: number,
    export_type?: string,
    q?: string,
    account_manager?: any,
    subtype?: any,
    partner_rating?: any,
    partner_payment_preference?: any,
    status?: any,
    cpc_status?: any,
    partner_payment_status?: any,
    partner_payment_cycle?: any,
    referrer?: any,
    tags?: any,
    partner_has_w8?: any
    columns?:any,
    params?:any
}

export interface ITag {
    id: number;
    name: string;
    tps_id: number;
    property_id: number;
}

export interface IFetchAllTagsResponse {
    id: number,
    name: string
}

export interface IAddTagsParams {
    property_ids: number[],
    type: string,
    tag_ids: number[]
}

export interface ITps {
    property_id: number,
    tps_id: number,
    id: number,
    name: string,
    is_selected?: boolean
}

interface IDeleteTagsParams {
    tps: ITps[]
}


export interface IPartnerResponse {
    id: number,
    name: string,
    lastname: string,
    email: string,
    rating: number,
    company: string,
    subtype: 'media_buyer' | 'mailer',
    created_at: string,
    status: string,
    status_name: string,
    cpc_status: string
    executive: {
        id: number
        name: string
        lastname: string
    }
    payment_cycle: string
    link_count: number
    tags: {
        property_id: number,
        tps_id: number,
        id: number,
        name: string
    }[],
    cpc_approval_status: any
}

class LeapPartnersService {
    static fetchLeapPartnersTableData = async (params: IFetchLeapPartnersTableDataParams,
                                               token: string,
                                               cancelToken?: CancelTokenSource) => {
        return await api.get<IPagination<IPartnerResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params: {
                    ...params
                },
                cancelToken: cancelToken?.token,
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            })
    }

    static clonePartner = async (token: string, email: string, id: number, cancelToken?: CancelTokenSource) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                id,
                'clone'
            ].join('/'),
            {
                email
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                cancelToken: cancelToken?.token
            }
            )
    }

    static exportToCsv = async (token: string, params: IFetchLeapPartnersTableDataParams) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'export'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params: {
                    ...params
                },
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            })
    }

    static fetchAllTags = async (token: string) => {
        return await api.post<IFetchAllTagsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_TAGS
            ].join('/'),
            null,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                }
            }
        )
    }

    static addTags = async (token: string, params: IAddTagsParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ASSIGN_TAGS
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static deleteTags = async (token: string, params: IDeleteTagsParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ASSIGN_TAGS,
                process.env.REACT_APP_API_DELETE_TAGS
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

}

export default LeapPartnersService