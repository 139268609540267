import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import TextArea from "../../../../../common/components/textarea/TextArea";
import {setOffer} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {OFFER_DESCRIPTION_TOOLTIP} from "../shared-components/consts";

export const OfferDescription : React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
    } = useAppSelector(state => state.createOffer)
    return (
        <TextArea
            rows={2}
            label={<>Campaign Description <span className={"optional"}>(optional)</span> {Tooltip(168, OFFER_DESCRIPTION_TOOLTIP)}</>}
            placeholder={"Enter any relevant information related to the campaign"}
            value={offer.description}
            onChange={(event) =>
                dispatch(setOffer({description : event.target.value}))}/>
    );
};