import styled from "styled-components";


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  &:before {
    content: "";
    /* background: ; */
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  &:hover{
    cursor: pointer;
  }
  
  span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const SortButtons = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  
  font-size: 9px;
  margin-left: 10px;
`