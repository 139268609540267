import React, {useEffect, useLayoutEffect, useState} from "react";
import ReactModal from 'react-modal'

import Container from './container/Container'

import {Overlay} from "./Modal.style";

import {IModalProps} from "./modal.props";

ReactModal.setAppElement('#root');

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const Modal: React.FC<IModalProps> = ({
                                          modalHeaderClassName,
                                          title,
                                          afterClose,
                                          afterOpen,
                                          children,
                                          opened,
                                          closeModal,
                                          modalCustomClass,
                                          noHeaderUnderline,
                                          marginTop
                                      }) => {
    const [isModalOpened, setIsModalOpened] = useState(opened)

    useLayoutEffect(() : () => void => {
        // Get original body overflow
        const originalStyle: string = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = opened ? "hidden" : 'visible';
        // Re-enable scrolling when component unmounts
        return () => (document.body.style.overflow = originalStyle);
    }, [opened]);

    useEffect(() => {
        setIsModalOpened(opened)
    }, [opened])

    const onOpen = () => {
        targetOrigin && window.parent.postMessage({id: 5, opened: true}, targetOrigin)
        afterOpen && afterOpen()
    }

    const onClose = () => {
        targetOrigin && window.parent.postMessage({id: 6, opened: false}, targetOrigin)
        afterClose && afterClose()
    }
    return (
        <ReactModal
            className={modalCustomClass}
            onAfterClose={onClose}
            onAfterOpen={onOpen}
            isOpen={isModalOpened}
            contentElement={(props) => <Container
                onClose={closeModal}
                modalHeaderClassName={modalHeaderClassName}
                noHeaderUnderline={!!noHeaderUnderline}
                title={title}
                marginTop={marginTop}
                {...props}
            >
                {children}
            </Container>
        }
            overlayElement={(props, contentElement) => <Overlay {...props}>{contentElement}</Overlay>}
            overlayClassName={'overlay'}
        >
        </ReactModal>
    )
}

export default Modal