import React, {ChangeEvent, useState, useEffect} from 'react'
import {format} from 'date-fns'

import {Wrapper, DateInputStyled} from './DateRangeInput.style'
import {useAppDispatch} from "../../hooks";
import {setAnalyzePerformanceDateRangePreset} from "../../../modules/admin/pages/dashboard/agency-view/AgencyView.slice";

interface Props {
    startDate: Date | null | undefined,
    setStartDate: (date: Date | null | undefined) => void
    endDate: Date | null | undefined,
    setEndDate: (date: Date | null | undefined) => void
    className?: string
}

const DateRangeInput:React.FC<Props> = ({startDate, endDate, setEndDate, setStartDate, className = ""}) => {
    const [isFocused, setIsFocused] = useState(false)
    const [dateFromValue, setDateFromValue] = useState('')
    const [dateToValue, setDateToValue] = useState('')
    const [isValid, setIsValid] = useState(true)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if(startDate){
            setDateFromValue(format(new Date(startDate), 'MM/dd/yyyy'))
        }
        if(endDate){
            setDateToValue(format(new Date(endDate), 'MM/dd/yyyy'))
        }
    }, [startDate, endDate])

    const isValidDate = (dateString: string): boolean =>
    {
        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
            return false;

        // Parse the date parts to integers
        let parts = dateString.split("/");
        let day = parseInt(parts[1], 10);
        let month = parseInt(parts[0], 10);
        let year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month === 0 || month > 12)
            return false;

        let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };

    const handleDateFromChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length > 10) return
        let currentValue = e.target.value

        setIsValid(isValidDate(e.target.value))
        setDateFromValue(currentValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,''))
    }

    const handleDateToChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length > 10) return
        let currentValue = e.target.value

        setIsValid(isValidDate(currentValue))
        setDateToValue(currentValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,''))
    }

    const handleFromDateInputBlur = () => {
        setIsFocused(false)
        if(isValid){
            dispatch(setAnalyzePerformanceDateRangePreset({value:'custom', name: 'Custom'}))
            setStartDate(new Date(dateFromValue))
        } else {
            startDate && setDateFromValue(format(new Date(startDate), 'MM/dd/yyyy'))
        }

    }

    const handleToDateInputBlur = () => {
        setIsFocused(false)
        if(isValid){
            setEndDate(new Date(dateToValue))
            dispatch(setAnalyzePerformanceDateRangePreset({value:'custom', name: 'Custom'}))
        } else {
            endDate && setDateToValue(format(new Date(endDate), 'MM/dd/yyyy'))
        }

    }

    return <Wrapper $valid={isValid} $focused={isFocused} className={className}>
        <DateInputStyled
            type="text"
            placeholder={'MM/DD/YYYY'}
            onFocus={() => setIsFocused(true)}
            onBlur={handleFromDateInputBlur}
            onChange={(e) => handleDateFromChange(e)}
            value={dateFromValue}

        />
        <span>-</span>
        <DateInputStyled
            type="text"
            placeholder={'MM/DD/YYYY'}
            onFocus={() => setIsFocused(true)}
            onBlur={handleToDateInputBlur}
            onChange={(e) => handleDateToChange(e)}
            value={dateToValue}
        />
    </Wrapper>
}

export default DateRangeInput