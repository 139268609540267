import React, {ChangeEvent, useCallback, useMemo, useState} from 'react'

import {CreativeWrapper, EmailCreativeThumbnailsWrapper} from "../../CreativesAndEmail.style";
import EmailCreativeNameInput from "./EmailCreativeNameInput";
import UploadEmailCreativesDropbox from "./UploadEmailCreativesDropbox";
import EmailCreativeThumbnail from "./EmailCreativeThumbnail";
import {ICreative} from "../../../../../interfaces/interfaces";
import {setOffer} from "../../../../../create-offer.slice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../common/hooks";
import ImagePreviewModalContent
    from "../../../../../../../../../common/components/image-preview-modal-content/ImagePreviewModalContent";
import ModalWindow from "../../../../../../../../../common/components/modalWindow/ModalWindow";
import CreateOfferService from "../../../../../CreateOffer.service";

interface Props {
    creative: ICreative
    index: number
}

const EmailCreative: React.FC<Props> = ({creative, index}) => {

    const {offer} = useAppSelector(state => state.createOffer)
    const {config, token} = useAppSelector(state => state.auth)

    const [previewModal, setPreviewModal] = useState({
        opened: false,
        imgSource: ''
    })

    const dispatch = useAppDispatch()

    const handleCreativeNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const newCreatives = [...offer.creatives]
        const newCreative = {...newCreatives[index]}
        newCreative.name = e.target.value
        newCreatives[index] = newCreative
        dispatch(setOffer({
            creatives: [...newCreatives ]
        }))
    }

    const createThumbnailPreviewUrl = useCallback((index: number) : string => {
        return encodeURI(
            config.apiBase +
            'file/creative/' +
            (creative.files ? (creative.id > 0 ?
                    creative.files[index]?.id :
                    `temp/${offer.id}?creative_id=${creative.id}&name=${creative.files[index]?.name}`
            ) : ''))
    }, [
        config.apiBase,
        creative,
        offer.id
    ])

    function makeid()
    {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for( var i=0; i < 8; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    const createPreviewUrl = useMemo(() => {
        let src = encodeURI(
            config.apiBase +
            'file/creative/screenshot_preview/' + offer.id + '/' + creative.id)
        return src + '?' + makeid()
    }, [
        config.apiBase,
        creative,
        offer.id
    ])

    const removeCreative = () => {
        const newCreatives = [...offer.creatives]

        const creativeIndex = offer.creatives.findIndex(cre => creative.id === cre.id)

        newCreatives.splice(creativeIndex, 1)
        dispatch(setOffer({
            creatives: [...newCreatives ]
        }))
    }

    const removeFileFromCreative = (fileIndex: number) => {
        if(creative.files){
            const newFiles = [...creative.files]
            newFiles.splice(fileIndex, 1)
            const newCreative = {...creative, files: [...newFiles]}
            const newCreatives = [...offer.creatives]
            newCreatives[index] = newCreative
            dispatch(setOffer({
                creatives: [...newCreatives]
            }))
        }

    }

    const handlePreview = async () => {
        try {
            await CreateOfferService.makeCreativePreview(token, offer.id, creative.id, creative.files)
            setPreviewModal({imgSource: createPreviewUrl, opened: true})
        } catch (e) {

        }
    }

    return <CreativeWrapper>
        <div className="header">
            Email Creative
            <div className="creative-toolbar">
                <button
                    className="download-icon-wrapper preview"
                    onClick={handlePreview}>
                    <i className="ico-EyeFilled" />
                </button>
                {creative.id > 0 ? <button className="download-icon-wrapper">
                    <a href={`${config.apiBase}offer/${creative.id}/creative`} target={'_blank'} rel={'noreferrer'}>
                        <i className="ico-Download" />
                    </a>
                </button> : null}
                <button className="delete-icon-wrapper">
                    <i className="ico-Delete"  onClick={removeCreative}/>
                </button>
            </div>
        </div>
        <div className="body">
            <EmailCreativeNameInput
                value={creative.name}
                onChange={handleCreativeNameChange}
            />
            <UploadEmailCreativesDropbox
                creativeIndex={index}
            />
            <EmailCreativeThumbnailsWrapper>
                {creative.files && creative.files.map((creative, index) => {
                    if(creative.extension === 'html'){
                        return <EmailCreativeThumbnail
                            key={creative.name}
                            html
                            removeFile={() => removeFileFromCreative(index)}
                        />
                    }
                    if(creative.extension === 'txt'){
                        return <EmailCreativeThumbnail
                            key={creative.name}
                            txt
                            removeFile={() => removeFileFromCreative(index)}
                        />
                    }

                    return <EmailCreativeThumbnail
                        key={creative.name}
                        backgroundUrl={createThumbnailPreviewUrl(index)}
                        removeFile={() => removeFileFromCreative(index)}
                    />
                })}
            </EmailCreativeThumbnailsWrapper>
        </div>
        <ModalWindow
            modalCustomClass={'image-video-preview-modal'}
            opened={previewModal.opened}
            enableScroll={false}
            contentElement={ <ImagePreviewModalContent
                imageSource={previewModal.imgSource}
                imageName={creative.name}
                closeModal={() => setPreviewModal({imgSource: '', opened: false})}
            />}
        />

    </CreativeWrapper>
}

export default EmailCreative