import {useAppSelector, useFeatureFlags} from "../../../../../common/hooks";
import {useMemo} from "react";
import {isNullOrUndefined} from "../../../../../common/utils";

export const useCreateOfferParams = () => {
    const {offer, trafficGroups, cpcMainConversionPanelVisibility} = useAppSelector(state => state.createOffer)
    const domainFlag = useFeatureFlags('domain-tracking-code')

    const cpcAdditionalParams = {} as any

    if(offer.detailed_pricing_model === 'cpc') {
        cpcAdditionalParams.main_conversion_added = cpcMainConversionPanelVisibility
        cpcAdditionalParams.upsells_added = offer.upsells.length > 0
    }



    const trackingSettings = useMemo(() => {

        const parallelTrackingEnabled = offer.direct_linking === true && (offer.trackingSystem !== 'google-analytics' && offer.trackingSystem !== 'none')

        if(!parallelTrackingEnabled){
            return {
                ...offer.campaign_tracking_settings,
                campaign_id: offer.id,
                parallel_tracking_enabled: parallelTrackingEnabled,
                click_tracking_url: null,
                tracking_clickid_parameter: null,
                tracking_domain: null,
                conversion_tracking_url: null
            }
        }

        return {
            ...offer.campaign_tracking_settings,
            campaign_id: offer.id,
            click_tracking_url: offer.direct_linking && (offer.trackingSystem !== 'google-analytics' && offer.trackingSystem !== 'none') ?
                offer.destination_url : null,
            parallel_tracking_enabled: parallelTrackingEnabled
        }

    }, [offer.campaign_tracking_settings, offer.destination_url, offer.direct_linking, offer.id, offer.trackingSystem])

    const destinationUrl = useMemo(() => {
        if(offer.detailed_pricing_model === 'ppc') {
            return offer.preview_url
        }
        if(offer.direct_linking && (offer.trackingSystem !== 'google-analytics' && offer.trackingSystem !== 'none')){
            return offer.preview_url
        }

        return offer.destination_url
    }, [offer.destination_url, offer.detailed_pricing_model, offer.direct_linking, offer.preview_url, offer.trackingSystem])

    return {
        ...offer,
        traffic_types: trafficGroups.data ? [...trafficGroups.data.map(group => {
            return {
                ...group,
                types: offer.traffic_types ? [...offer.traffic_types.filter(type => type.traffic_group_id === group.id)] : []
            }
        })] : [],
        upsells: [...offer.upsells.map((upsell: any) => {
            return {
                ...upsell,
                payout: upsell.payout === '' ? 0 : parseFloat(upsell.payout),
                campaign_id: offer.id,
                revshare: [...upsell.revshare?.map(({id, ...rest}: any) => {
                    return {
                        ...rest,
                        value: parseFloat(rest.value)
                    }
                })],
                payout_modifiers: [...upsell.payout_modifiers],
                upsell_tracking_settings: !isNullOrUndefined(upsell.upsell_tracking_settings) ? {
                    ...upsell.upsell_tracking_settings,
                    conversion_tracking_url:
                        offer.direct_linking === true && (offer.trackingSystem !== 'google-analytics' && offer.trackingSystem !== 'none') ?
                        upsell.upsell_tracking_settings.conversion_tracking_url : null
                } : null
            }
        })],
        cpa: offer.cpa === '' ? 0 : parseFloat(offer.cpa),
        schedules: [...offer.campaign_schedule],
        campaign_email_setting: {
            ...offer.campaign_email_setting,
            campaign_id: offer.id,
            unsubscribe_type: 'url',
            unsubscribe_type_aff: 'url',
            supression_list: 'optizmo',
        },
        campaign_tracking_settings: trackingSettings,
        destination_url: destinationUrl.slice(0, 4) !== 'http' ? 'http://' + destinationUrl : destinationUrl,
        preview_url: (offer.preview_url.slice(0, 4) !== 'http' && offer.preview_url.length > 0)  ? 'http://' + offer.preview_url : offer.preview_url,
        // added for case when creating or editing PPC offer
        trackingSystem: offer.detailed_pricing_model === 'ppc' ? "ringba" : offer.trackingSystem,
        tracking_system_id: offer.detailed_pricing_model === 'ppc' ? 13 : offer.tracking_system_id,
        tracking_method: !domainFlag ? false : !isNullOrUndefined(offer.legacy_tracking) ? !offer.legacy_tracking : null,
        operating_system_id: (offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') ? (offer.mobile_platform === 'ios' ? 2 : 1) : null,
        budget_group_id: offer.budget_group_id === 0 || offer.budget_group_id === -1 ? null : offer.budget_group_id,
        detailed_pricing_model: offer.detailed_pricing_model === 'cpc' ? null : offer.detailed_pricing_model,
        ...cpcAdditionalParams
    }

}