import styled from 'styled-components'

export const Wrapper = styled.div`
    margin: auto;
    background-color: white;
    position: relative;
    max-width: 800px;
    z-index: 2000;
    pointer-events: auto;
`

export const Image = styled.img`
    max-width: 100%;
    min-width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
`

export const Footer = styled.div`
    padding: 8px;
    line-height: 16px;
    color: ${({theme}) => theme.colors.deepBlue[5]};
`

export const CloseButton = styled.div`
    color: ${({theme}) => theme.colors.deepBlue['4']};
    font-size: 16px;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: white;
    z-index: 1;
    cursor: pointer;
    :hover{
        cursor: pointer;
        color: ${({theme}) => theme.colors.deepBlue['5']};
        background-color: ${({theme}) => theme.colors.deepBlue['1']};
    }
    :hover:active{
        color: ${({theme}) => theme.colors.deepBlue['7']};
        background-color: ${({theme}) => theme.colors.deepBlue['2']};
    }
`