import {FilterID, ISensitivity} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import { SENSITIVITY} from "../../../utils/consts";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";


export const useSensitivityCacheFilter = (pageId: IPagePresetsID) => {
    const id: FilterID ='sensitivity'
    const dispatch = useAppDispatch()
    const filter =  useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (sensitivity : ISensitivity) => {
        if (filter.selected.some((sen : ISensitivity)=>
            sen.id === sensitivity.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== sensitivity.id)}
            }))
        }else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, sensitivity]}
            }))
        }
    }
    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }
    return {
        id: id,
        options: SENSITIVITY,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
    }
}