import styled from "styled-components";

interface WrapperProps {
    $height: number,
    $width: number,
    expandInput: boolean
}

export const SearchWrapper = styled.div<WrapperProps>`
    position: relative;
    border-radius: 8px;
    input {
        width: ${props => props.expandInput === true ? "260px" : `${props.$width}px`};
        height: ${props => props.$height}px;
        transition: 0.3s;
        caret-color: ${({theme}) => theme.colors.performBlue[5]};
        outline: none;
        border-radius: 8px;
        font-size: 12px;
        padding: 10px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        &:hover {
            border-color: ${({theme}) => theme.colors.deepBlue[2]};
        }
        &:focus {
            border-color: ${({theme}) => theme.colors.performBlue[5]};
            box-shadow: 0 0 5px rgb(37 192 213 / 67%);
        }
        ::placeholder {
            color: ${({theme}) => theme.colors.coolGray[4]};
            opacity: 1; /* Firefox */
        }
    }

    .ico-Exit {
        font-size: 16px;
        padding-bottom: 2px;
        position: absolute;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        right: 38px;
        top: calc(50% - 8px);
        cursor: pointer;
        &:hover {
            color: ${({theme}) => theme.colors.performOrange[6]};
        }
    }
    
    .search-button {
        position: absolute;
        z-index: 2;
        right: 1px;
        top: 0px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        background-color: ${props => props.expandInput ? "transparent" : ({theme}) => theme.colors.pureWhite};
        cursor: ${props => props.expandInput === true ? "text" : "pointer"};
        border: 1px solid ${props => props.expandInput ? "transparent" : ({theme}) => theme.colors.coolGray[3]};
        i {
            color: ${props => props.expandInput === true ? ({theme}) => theme.colors.performBlue[5] : 
                    ({theme}) => theme.colors.deepBlue[4] };
            font-size: 15px;
        }
    }
    :hover{
        .search-button{
            background-color: ${props => props.expandInput ?  `none` : ({theme}) => theme.colors.coolGray[2]};
        }
    }
`;