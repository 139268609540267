import styled from "styled-components";

export const SectionLabel = styled.span`
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 16px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  margin-bottom: 16px;
  display: flex;
`

export const CreditLabel = styled.span`
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  display: flex;
    `

export const BudgetLabel =styled.span`
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  font-size: 12px;
  color: ${({theme}) => theme.colors.performBlue[5]};
  margin-bottom: 16px;
  margin-left: 5px;
  display: flex;
  strong {
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
`

interface IHiddenProps{
    $hidden: boolean
}

export const HiddenDisplay = styled.div<IHiddenProps>`
  display: ${props => props.$hidden ? 'none' : 'block'};

`

export const BillingNav = styled.div`
  margin-top: 27px;
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[2]};
  gap: 1px;

`;

export const BillingNavItem = styled.div<{active: boolean}>`
  width: 101px;
  height: 40px;
  background-color: ${({active, theme}) => active ? theme.colors.performOrange[6] : theme.colors.coolGray[3]};
  color: ${({active, theme}) => active ? theme.colors.pureWhite : theme.colors.deepBlue[5]};
  border-radius: 8px 8px 0px 0px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
`;

export const BillingTableContent = styled.div`
  margin: 27px 0;
`;


export const CreditWrapper = styled.div`
  padding: 20px 0px 24px 26px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[4]};
  border-radius: 6px;

  .loading-dots {
    text-align: center;

    span {
      animation: loading-dots 1s infinite;
      width: 5px;
      height: 5px;
      background: #EF4A24;
      margin: 0 3px;
      display: inline-block;
      border-radius: 100%;
    }

    span:nth-child(2) {
      animation-delay: 0.1s;
    }

    span:nth-child(3) {
      animation-delay: 0.3s;
    }

    @keyframes loading-dots {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

////////////////////////////////////////////////////////////////

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.colors.coolGray[1]};
  padding: 40px;
  display: flex;
  gap: 24px;

  @media screen and (max-width: 1366px) {
    padding: 30px 10px;
    flex-direction: column;
  }
`

export const WidgetWrapper = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
`

export const LeftSection = styled.div`
  min-width: 512px;
    .details-tooltip{
      vertical-align: middle;
      margin-left: 5px;

        transform: translateY(1px);
      
    }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    .label {
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      color: ${({theme}) => theme.colors.deepBlue[5]};
      font-size: 12px;
    }
    
    .value {
      font-family: ${({theme}) => theme.fonts.AvenirRoman};
      color: ${({theme}) => theme.colors.deepBlue[4]};
    }
  }

  @media screen and (max-width: 1366px) {
    min-width: unset;
    width: 100%;
  }
`

export const RightSection = styled.div`
  flex-basis: 100%;
`

export const SectionTitle = styled.h3`
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  color: ${({theme}) => theme.colors.deepBlue[7]};
  font-size: 16px;
  margin-bottom: 16px;
`

export const TrafficPauseRiskPill = styled.div`
  width: 112px;
  height: 16px;
  text-align: center;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  color: white;
  font-size: 12px;
  background-color: #CE1E29;
  border-radius: 8px;
`

export const RecentInvoicesTooltipWrapper = styled.div`
    .above-text {
        padding-bottom: 8px;
        border-bottom: 1px solid #E7ECEE;
        margin-bottom: 8px;
    }

    .invoice-states {
        .heading {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .invoices{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .invoice {
                display: flex;
                gap: 8px;
                .box {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                }

                .text {

                }
        }
        }
    }
`;

export const PauseRiskTooltip = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .column {
        display: flex;
        gap: 16px;
        .info {
            width: 112px;
            height: 16px;
            border-radius: 100px;
            text-align: center;
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            color: white;
            font-size: 12px;
        }

        .text {
          
        }
    }
`;