import React, {useEffect, useMemo, useState} from "react";
import {AddPartnerButton, Header, Red, Wrapper} from "./Transparency.style";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import {useAppSelector} from "../../../../../common/hooks";
import {useParams} from "react-router-dom";
import {tokenStore} from "../../../../../store/auth.slice";
import {IPartnerTransparency} from "../../../../../common/models/advertiser-edit.type";
import Modal from "../../../../../common/components/modal/Modal";
import AddModal from "./add-modal/AddModal";
import RemoveModal from "./remove-modal/RemoveModal";
import AdvertisersEditService from "../AdvertisersEdit.service";
import GridRowOptions from "../../../../../common/components/grid/grid-row-options/GridRowOptions";
import Grid from "../../../../../common/components/grid/Grid";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";

enum TYPE { DEFAULT = "", ADD = "add", REMOVE = "remove" }

interface ModalProps {
    opened: boolean,
    type: TYPE,
    partner_id : number
    marketer_id : string | undefined
}

interface RowProps {
    partner_id: number,
    marketer_id: string | undefined,
    setModal: React.Dispatch<React.SetStateAction<ModalProps>>
}

const RowOptions = ({partner_id, marketer_id, setModal}: RowProps) => {
    return (
        <GridRowOptions>
            <DropdownItem onClick={() => setModal({opened: true,
                type: TYPE.REMOVE,
                partner_id: partner_id,
                marketer_id: marketer_id })}><Red>Remove
                Partner</Red></DropdownItem>
        </GridRowOptions>
    )
}

const Transparency: React.FC = (): JSX.Element => {
    const {id} = useParams<{ id: string }>()
    const token = useAppSelector(tokenStore)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [perPage, setPerPage] = useState<number>(50)
    const [lastPage, setLastPage] = useState<number>(0)
    const [partners, setPartners] = useState<IPartnerTransparency[]>([])
    const [addedPartners, setAddedPartners] = useState<IPartnerTransparency[]>([])
    const [modal, setModal] = useState<ModalProps>({
        opened: false,
        type: TYPE.DEFAULT,
        partner_id : 0,
        marketer_id : ""
    })

    const [loading, setLoading] = useState<boolean>(true)

    const fetchPartners = async () => {
        let allPartners = await AdvertisersEditService.fetchPartners(token)
        setPartners(allPartners.data)
    }

    const fetchSelectedPartners = async () => {
        setLoading(true)
        if (id) {
            let addedPartners = await AdvertisersEditService.fetchAddedPartners(token,
                id, perPage, currentPage)
            setAddedPartners(addedPartners.data.data)
            setCurrentPage(addedPartners.data.current_page)
            setFrom(addedPartners.data.from)
            setLastPage(addedPartners.data.last_page)
            setTo(addedPartners.data.to)
            setTotal(addedPartners.data.total)
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetchSelectedPartners()
    }, [ perPage, currentPage])

    useEffect(() => {
        token !== "" && id  && fetchSelectedPartners()
        fetchPartners()
    }, [token, id])

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const columnDefs = useMemo(() => [
        {field: "id", headerName: "PARTNER ID" , width: 300},
        {
            headerName: "PARTNER NAME", width: 400, cellRenderer: ({data}: { data: IPartnerTransparency }) => {
                return <span>{data.name + " " + data.lastname}</span>
            }
        },
        {field: "company", headerName: "PARTNER COMPANY" ,width: 400},
        {
            headerName: "ACCOUNT MANAGER", width: 400, cellRenderer: ({data}: { data: IPartnerTransparency }) => {
                return <span>{data.account_manager_name + " " + data.account_manager_lastname}</span>
            }
        },
        {field: "referral_date", headerName: "REFERRAL DATE", width: 300},
        {field: "note", headerName: "NOTE", width: 300},
        {
            pinned: "right",
            width: 50,
            resizable: false,
            cellRenderer: ({data}: { data: IPartnerTransparency }) => {
                return <RowOptions setModal={setModal} partner_id={data.id} marketer_id={id}/>
            }
        }
    ], [])

    return (
        <Wrapper>
            <Header>
                <AddPartnerButton onClick={() => setModal({opened: true, type: TYPE.ADD, partner_id: 0, marketer_id: ""})}>
                    Add Partner
                </AddPartnerButton>
                <Pagination
                    current_page={currentPage}
                    setCurrentPage={setCurrentPage}
                    from={from}
                    to={to}
                    total={total}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    last_page={lastPage}
                    loading={loading}
                    onRefresh={() => {
                        if (id) {
                            return fetchSelectedPartners()
                        }
                    }}/>
            </Header>
            <Grid columnDefs={columnDefs}
                  defaultColDef={defaultColumnDefs}
                  rowData={addedPartners}
                  rowHeight={40}
                  domLayout='autoHeight'
                  loading={loading}
            >
            </Grid>
            <Modal
                opened={modal.opened}
                title={modal.type === TYPE.ADD ? "Add Partner Referrals" : "Remove Partner?"}
                closeModal={() => setModal({opened: false, type: TYPE.DEFAULT, partner_id: 0, marketer_id: ""})}>
                {modal.type === TYPE.ADD && <AddModal
                    closeModal={() => setModal({opened: false, type: TYPE.DEFAULT, partner_id: 0, marketer_id: ""})}
                    partners={partners}
                    addedPartners={addedPartners}
                    refreshTable={fetchSelectedPartners}
                />}
                {modal.type === TYPE.REMOVE &&
                <RemoveModal closeModal={() => setModal({opened: false, type: TYPE.DEFAULT, partner_id: 0, marketer_id: ""})}
                             partner_id={modal.partner_id.toString()}
                             marketer_id={modal.marketer_id}
                             refreshTable={fetchSelectedPartners}
                />
                }
            </Modal>
        </Wrapper>
    )
}

export default Transparency