import { useEffect, useState } from "react";
import { useColumnsOptionHook } from "../../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import Pagination from "../../../../../../../../common/components/table/pagination/Pagination";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/hooks";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../../NetworkView.service";
import { setMarketersCurrentPage, setMarketersPaginationColumnsList, setMarketersPerPage, setPagination, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../../NetworkView.slice";
import { allMarketersPaginationColumns } from "../../topPerformersLists";

const MarketersPagination = () => {

    const {
        marketersPaginationColumnsList,
        marketersPerPage,
        marketersCurrentPage,
        marketersSort,
        pagination,
        topPerformersLoading,
        selectedManagers,
        includeOrExclude,
        filterType
    } = useAppSelector(state => state.networkView)

    const dispatch = useAppDispatch();
    const {token} = useAppSelector(state => state.auth)

    const marketerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setMarketersPaginationColumnsList(list));
    }

    const [enableAllMarketersHandler, disableAllMarketersHandler, enableDisableMarketersColumnHandler]: any = useColumnsOptionHook(
        [], 
        [], 
        () => {},
        allMarketersPaginationColumns,
        marketersPaginationColumnsList, 
        marketerPaginationColumnsListHandler
    );

    const exportCsv = async () => {
        const accMngs = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
            return item.id;
        })

        const params = {
            analyze_by: "marketers",
            page: marketersCurrentPage,
            per_page: marketersPerPage,
            sort_by: marketersSort.by,
            order: marketersSort.direction,
        } as any

        switch (filterType){
            case 'account-manager':
                params['AccountManager'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }
                break;
            case 'partner-account-manager':
                params['PartnerAccountManager'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }
                break;
            case 'sales-executive':
                params['SalesExecutive'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_sale_exec'] = true
                }
                break
        }
        NetworkViewService.exportCsvTopPerformersData(params, marketersPaginationColumnsList);
    };

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));
        const accMngs = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
            return item.id;
        })
        const params = {
            analyze_by: "marketers",
            page: marketersCurrentPage,
            per_page: marketersPerPage,
            sort_by: marketersSort.by,
            order: marketersSort.direction,
        } as any

        switch (filterType){
            case 'account-manager':
                params['AccountManager'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }
                break;
            case 'partner-account-manager':
                params['PartnerAccountManager'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }
                break;
            case 'sales-executive':
                params['SalesExecutive'] = accMngs
                if (includeOrExclude === 'excl'){
                    params['exclude_sale_exec'] = true
                }
                break
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setMarketersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setMarketersPerPage(parseInt(response.data.per_page)))
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    return(
        <Pagination
            current_page={marketersCurrentPage}
            from={pagination.from}
            to={pagination.to}
            total={pagination.total}
            last_page={pagination.last_page}
            setCurrentPage={(number: number) => dispatch(setMarketersCurrentPage(number))}
            setPerPage={(number: number) => dispatch(setMarketersPerPage(number))}
            exportCsv={() => exportCsv()}
            columnsOptionProps={
                {
                    allPaginationColumns: allMarketersPaginationColumns,
                    enableDisableColumnHandler: (column: any) => enableDisableMarketersColumnHandler(column),
                    enableAllHandler: () => enableAllMarketersHandler(),
                    paginationColumnsList: marketersPaginationColumnsList,
                    setPaginationColumnsList: (list: any) => marketerPaginationColumnsListHandler(list),
                    disableAllHandler: () => disableAllMarketersHandler()
                }
            }
            perPage={marketersPerPage}
            onRefresh={() => fetchData()}
            loading={topPerformersLoading}
            perPageCustomList={[5, 10, 20, 50, 100]}
        />
    )
}

export default MarketersPagination;