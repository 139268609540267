import styled from 'styled-components'

export const InfoWidgetContainer = styled.div`
    margin-bottom: 24px;
`
export const InfoWidgetIntro = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-basis: 50%;
    @media (max-width: 1280px) {
        flex-direction: column;
    }
    > .text, .image {
        flex-basis: 50%;
        min-width: 50%;
        gap: 24px;
        @media (max-width: 1280px) {
            flex-basis: 100%;
        }
    }
    .text {
        padding-top: 20px;
        h2 {
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            font-size: 24px;
            margin-bottom: 8px;
        }
        p {
            color: ${({theme}) => theme.colors.deepBlue[5]};
            line-height: 1.3;
            strong {
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            }
        }
    }
    .image {
        text-align: right;
        img {
            width: 100%;
            max-width: 356px;
        }
        @media (max-width: 1280px) {
            text-align: left;
        }
        @media (max-width: 1024px) {
            img {
                max-width: 100%;
            }
        }
    }
`
export const InfoWidgetTiles = styled.div`
    display: flex;
    gap: 24px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const Tile = styled.div`
    flex-basis: 33.3333%;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    padding: 26px 22px 22px;
    box-shadow: 4px 4px 0 transparent;
    transition: box-shadow 300ms ease-in-out;
    :hover {
        box-shadow: 4px 4px 0 #E5EFF0;
    }
    .tile-icon {
        margin-bottom: 15px;
    }
    .tile-title {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 1.3;
    }
    .tile-description {
        color: ${({theme}) => theme.colors.deepBlue[5]};
    }
`
