import React, {useEffect, useRef, useState} from 'react'

import {Wrapper, Header, IncludeExcludeWrapper, IncludeExcludeItem, ClearSelected, Container, FilterSearch, ItemList, TopStickyContainer, NotShown} from "./IncludeExcludeFilter.style";
import {useAppDispatch, useOnClickOutside} from "../../../hooks";
import {IFilterHookReturn} from "filters";
import {removeFilter, setMatch} from "../../../../store/filters.slice";
import Dropdown from "../../dropdown/Dropdown";
import {DropdownItem} from "../../dropdown/Dropdown.style";


interface Props {
    filterTitle: string,
    filter: IFilterHookReturn<any>
    wrapperClassName?: string,
    headerClassName?: string,
    containerClassName?: string,
    selectedFilterText: string,
    setDisplayDiscoverResults?: (inc: boolean) => void,
    matches?: 'any' | 'all' | 'empty',
    hasInclude?: boolean,
    hasTopStickyContainer?: boolean
}

const IncludeExcludeFilter: React.FC<Props> = ({

                                                   filterTitle,
                                                   wrapperClassName,
                                                   containerClassName,
                                                   headerClassName,
                                                   children,
                                                   filter,
                                                   selectedFilterText,
                                                   setDisplayDiscoverResults,
                                                   matches,
                                                   hasInclude= true,
                                                   hasTopStickyContainer = true
                                               }) => {
    const [opened, setOpened] = useState(false)
    const [matchOpened, setMatchOpened] = useState(false)
    const [filterSearchValue, setFilterSearchValue] = useState('')

    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const removeFilterIconRef = useRef<HTMLElement | null>(null)
    const dispatch = useAppDispatch()



    const handleMatchSelect = (match: 'any' | 'all' | 'empty') => {
        dispatch(setMatch({match, pageId: filter.pageId, filterId: filter.id}))
        setDisplayDiscoverResults && setDisplayDiscoverResults(true)
        setMatchOpened(false)
    }

    useEffect(() => {
        if(matches) {
            dispatch(setMatch({match: matches, pageId: filter.pageId, filterId: filter.id}))
        }
    }, [])

    useOnClickOutside(wrapperRef, () => {
        filter.setSearchValue && filter.setSearchValue('')
        setFilterSearchValue('')
        setOpened(false)
    })

    const handleOpenDropdown = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(e.target === removeFilterIconRef.current){
            return
        }
        setTimeout(() => {
            setOpened(!opened)
        })

    }

    const handleRemoveFilter = () => {
        dispatch(removeFilter({pageId: filter.pageId, filterId: filter.id}))
        setDisplayDiscoverResults && setDisplayDiscoverResults(true)
    }

    const handleClearSelected = () => {
        filter.clearSelected()
        setDisplayDiscoverResults && setDisplayDiscoverResults(true)
    }

    return <Wrapper ref={wrapperRef} className={wrapperClassName}>
        <Header className={headerClassName} $active={opened} onClick={(e) => handleOpenDropdown(e)}>
            <span className={'title'}>
                {filterTitle}{filter.selectedData.length > 0 && ':'}
            </span>
            {filter.selectedData.length > 0 ? (
                <>
                {hasInclude && <>{filter.setExclude && <span className={'include-exclude'}>{filter.exclude ? 'Exclude' : 'Include'}</span>}</>}
                    {matches && <span className={'include-exclude'}>{filter.match === 'any' ? 'matches any' : filter.match === 'all' ? 'matches all' : 'is empty'}</span>}
                    {
                        filter.match !== 'empty' && (filter.selectedData.length === 1 ?
                            <span className={'selected'}>{selectedFilterText ? selectedFilterText : ''}</span> :
                            <span className={'selected'}>{filter.selectedData.length} selected</span>)
                    }
                </>
            ) :  <span className={'include-exclude'}/>}
            {opened ? <i className={'ico-AngleUp'}/> : <i className={'ico-AngleDown'}/>}
            <i ref={removeFilterIconRef} className={'ico-Exit'} onClick={handleRemoveFilter}/>
        </Header>

        <Container style={{maxHeight: filter.selectedData.length > 0 ? '392px' : '344px', overflowY: filter.match === 'empty' || filter.data.length < 5  ? 'visible' : 'auto'}} opened={opened} className={containerClassName}>
            {hasTopStickyContainer && <TopStickyContainer>
                {filter.setSearchValue && <FilterSearch placeholder={'Search'} onChange={(e) => {filter.setSearchValue && filter.setSearchValue(e.target.value); setFilterSearchValue(e.target.value)}} value={filterSearchValue} />}
                {filter.setExclude && <div className={'filter-type-wrapper'} >
                    {hasInclude &&
                        <IncludeExcludeWrapper>
                            <IncludeExcludeItem onClick={() => {filter.setExclude && filter.setExclude(false); setDisplayDiscoverResults && setDisplayDiscoverResults(true)}} $active={!filter.exclude}>Include</IncludeExcludeItem>
                            <IncludeExcludeItem onClick={() => {filter.setExclude && filter.setExclude(true); setDisplayDiscoverResults && setDisplayDiscoverResults(true)}} $active={filter.exclude}>Exclude</IncludeExcludeItem>
                        </IncludeExcludeWrapper>
                    }
                    {matches && <div className={'match-dropdown'}><Dropdown containerClassName={'matches-container'}  headerClassName={'matches-header'} text={filter.match === 'any' ? 'matches any' : filter.match === 'all' ? 'matches all' : 'is empty'} isOpened={matchOpened} setOpened={setMatchOpened} >
                        <DropdownItem onClick={() => handleMatchSelect('all')} selected={filter.match === 'all'}>matches all</DropdownItem>
                        <DropdownItem onClick={() => handleMatchSelect('any')} selected={filter.match === 'any'}>matches any</DropdownItem>
                        <DropdownItem onClick={() => handleMatchSelect('empty')} selected={filter.match === 'empty'}>is empty</DropdownItem>
                    </Dropdown></div>}
                </div>}
            </TopStickyContainer>}
            {filter.match !== 'empty' && (
                <>
                    <ItemList>
                        {children}
                        {filter.notDisplayed > 0 && <NotShown>{filter.notDisplayed} not shown, use search.</NotShown>}
                    </ItemList>
                    {filter.selectedData.length > 0 && <ClearSelected onClick={handleClearSelected}><span>Clear Selected</span></ClearSelected>}
                </>
            )}

        </Container>

    </Wrapper>
}

export default IncludeExcludeFilter
