import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
    token: string
    user: any,
    wasadmin?: string
    config?: any
}

const initialState = {
    token: "",
    user: {},
    wasadmin: "",
    config: {}
} as AuthState;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        getAuth (state, action) {
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.wasadmin = action.payload.wasadmin;
            state.config = action.payload.config;
        }
    },
    extraReducers: {}
});

export const tokenStore = (state: any) => state.auth.token;
export const userStore = (state: any) => state.auth.user;
export const wasAdminStore = (state: any) => state.auth.wasadmin;

export const { getAuth } = authSlice.actions;

export default authSlice.reducer;
