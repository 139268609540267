import React, { useRef, useState } from 'react'

import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../common/hooks";


import { usePartnerTypeFilter } from '../../../../../../common/hooks/filters/usePartnerType';
import { useRatingsFilter } from '../../../../../../common/hooks/filters/useRatingFilter';
import { usePaymentPreferencesFilter } from '../../../../../../common/hooks/filters/usePaymentPreferencesFilter';
import { usePartnerStatusFilter } from '../../../../../../common/hooks/filters/useStatusPartnersFilter';
import { usePartnerRegistrationStatusFilter } from '../../../../../../common/hooks/filters/usePartnerRegistrationStatus';
import { usePartnerPaymentStatusFilter } from '../../../../../../common/hooks/filters/usePartnerPaymentStatusFilter';
import { usePartnerPaymentCycleFilter } from '../../../../../../common/hooks/filters/usePartnerPaymentCycleFilter';
import { useApprovedW8Filter } from '../../../../../../common/hooks/filters/useApprovedW8W9filter';
import { useAffiliatesFilter, usePartnerAccountManagerFilter, useReferrerFilter, useTagsFilter } from '../../../../../../common/hooks/filters';
import { FilterID, IAffiliatesFilter } from 'filters';
import IncludeExcludeFilter from '../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter';
import Checkbox from '../../../../../../common/components/checkbox/Checkbox';
import { ButtonStyled } from '../../../../../../common/styled-components';
import SelectFilterDropdown from '../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import ClickAgainToConfirmTooltip from '../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip';
import { removeAllFilters, singleSelect } from '../../../../../../store/filters.slice';
import { SingleSelectItem } from '../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter.style';
import { Wrapper } from './Filters.style';
import { IReferrerFilter } from '../../../../../../common/hooks/filters/useReferrerFilter';
import { usePartnerTransactionTypeFilter } from '../../../../../../common/hooks/filters/usePartnerTransactionTypeFilter';

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'affiliate',
        value: 'Partner'
    },
    {
        id: 'partner-transaction-type',
        value: 'Type'
    }
]

const Filters: React.FC<Props> = (
    {
        setDiscoverDisplayed
    }
) => {
    const {token} = useAppSelector(state => state.auth)
    const {filters} = useAppSelector((state:any) => state.filters)
    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    const affiliateFilter = useAffiliatesFilter('admin/billing/partner-transactions', 50);
    const partnerTransactionTypeFilter = usePartnerTransactionTypeFilter("admin/billing/partner-transactions")

    const dispatch = useAppDispatch();

    const handleSingleSelect = (affiliate: IAffiliatesFilter, id: FilterID) => {        
        dispatch(singleSelect({
            pageId: "admin/billing/partner-transactions",
            filterId: id,
            data: affiliate
        }))
    }

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case 'affiliate':
                return (
                        <IncludeExcludeFilter 
                            hasInclude={false}
                            selectedFilterText={
                                affiliateFilter.selectedData[0] ? 
                                affiliateFilter.selectedData[0].name + " " +
                                affiliateFilter.selectedData[0].lastname + " " +
                                `(${affiliateFilter.selectedData[0].id })`
                                : 
                                ''
                            } 
                            filter={affiliateFilter} 
                            filterTitle={"Partner"}>
                            {affiliateFilter.data.map(affiliate =>  (                       
                                <SingleSelectItem
                                    className='checkbox-filter-wrapper'
                                    key={affiliate.id+Math.random()}
                                    active={affiliateFilter.selectedData.some(selected => affiliate.id === selected.id)}
                                    onClick={() => {
                                        handleSingleSelect(affiliate, "affiliate")
                                        setDiscoverDisplayed(true)
                                    }}
                                >
                                    {affiliate.name + " " + affiliate.lastname + " " + `(${affiliate.id})`}
                                </SingleSelectItem>
                            ))}
                        </IncludeExcludeFilter>
                    )
            case 'partner-transaction-type':
                return (
                        <IncludeExcludeFilter hasInclude={false} selectedFilterText={partnerTransactionTypeFilter.selectedData[0] ? partnerTransactionTypeFilter.selectedData[0].value : ''} filter={partnerTransactionTypeFilter} filterTitle={'Type'}>
                            {partnerTransactionTypeFilter.data.map(type =>  (
                                <SingleSelectItem
                                    className='checkbox-filter-wrapper'
                                    key={type.id+Math.random()}
                                    active={partnerTransactionTypeFilter.selectedData.some(selected => type.id === selected.id)}
                                    onClick={() => {
                                        handleSingleSelect(type, "partner-transaction-type")
                                        setDiscoverDisplayed(true)
                                    }}
                                >{type.value}</SingleSelectItem>
                            ))}
                        </IncludeExcludeFilter>
                    )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/billing/partner-transactions'))
        }
    }

    return (
    <Wrapper>
       <i className={'ico-Filters'}/>
            {filters.filter((filter: any) => filter.pageID === 'admin/billing/partner-transactions')[0]?.filters.map((filter: any) => 
            <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
            <SelectFilterDropdown setDiscoverDisplayed={setDiscoverDisplayed} allFilters={filterList} pageId={'admin/billing/partner-transactions'}/>

            {filters.filter((filter: any) => 
                filter.pageID === 'admin/billing/partner-transactions')[0]?.filters.length > 1 && 
                <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                    {   displayClearFilterTooltip && 
                         <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
                    }
                    Clear All Filters
                </ButtonStyled>}
    </Wrapper>)
}


export default Filters