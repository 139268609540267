import React from 'react';
import {useAppSelector, useAppDispatch, useFeatureFlags} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {setOffer, setValidation} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {GOOGLE_STORE_URL_INPUT, APP_STORE_URL_TOOLTIP, URL_PATTERN, INVALID_URL_MSG} from "../shared-components/consts";
import {CPE, CPI} from "../interfaces/interfaces";
import {useTestingLink} from "../hooks/useTestingLink";

export const AppUrlInput: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        validation
    } = useAppSelector(state => state.createOffer)
    
    const getTestingLink = useTestingLink()
    
    const handleOnChange = (value: string) => {
        let testing_link = ""
        if (offer.direct_linking && !offer.legacy_tracking){
            if (value.includes("?")){
                testing_link = value + "&oid=" + offer.id + "&testing=1"
            } else {
                testing_link = value + "?oid=" + offer.id + "&testing=1"
            }
        } else
        {
            testing_link = getTestingLink(offer.detailed_pricing_model!, offer.id, offer.destination_url)
        }
        dispatch(setOffer({
            preview_url: value,
            testing_link: testing_link
        }))
        if(value) {
            if (value.length <= 0) {
                dispatch(setValidation({validateAppOrGoogleStoreUrl : "Store url is required"}))
            } else {
                if (!URL_PATTERN.test(value) && !validation.validateAppOrGoogleStoreUrl) {
                    dispatch(setValidation({validateAppOrGoogleStoreUrl :
                        INVALID_URL_MSG }
                    ))
                } else if (URL_PATTERN.test(value) && validation.validateAppOrGoogleStoreUrl){
                    dispatch(setValidation({validateAppOrGoogleStoreUrl : undefined}))
                }
            }
        }
    }

    return (
        <Input
            label={<>{offer.mobile_platform === 'android' ? 'Google Play Store URL' : 'App Store URL'} {Tooltip(174, offer.mobile_platform === 'android' ? GOOGLE_STORE_URL_INPUT : APP_STORE_URL_TOOLTIP)}</>}
            placeholder={"Enter the URL for your app"}
            value={offer.preview_url}
            onChange={(event) => handleOnChange(event.target.value)}
            error={validation.validateAppOrGoogleStoreUrl}
        />
    );
};