import React, {ReactElement} from 'react'
import {Wrapper, Option} from './MultiOptionSwitch.style'

export type OptionType = {
    id: string | number,
    value: string | ReactElement
}

interface Props {
    options: OptionType[],
    selected: OptionType["id"],
    onSelect: (option: OptionType) => void
    wrapperClassName?: string,
    optionClassName?: string,
}

const MultiOptionSwitch: React.FC<Props> = (
    {
        optionClassName,
        options,
        onSelect,
        wrapperClassName,
        selected
    }
) => {
    return <Wrapper className={wrapperClassName}>
        {options.map(option => {
            return <Option
                onClick={() => onSelect(option)}
                className={optionClassName}
                $selected={selected === option.id}
                key={option.id}
            >
                {option.value}
            </Option>
        })}
    </Wrapper>
}

export default MultiOptionSwitch

