import styled, { css } from "styled-components";

interface ITooltipInfoProps {
    position: string,
    opened: boolean,
    $width?: number
    $left: number,
    $top: number,
}

export const CardsTooltipInfo = styled.div<ITooltipInfoProps>`
  display: ${(props) => props.opened ? 'block' : 'none'};
  padding: 16px;
  border: 1px solid #E9ECED;
  min-width: fit-content;
  width: ${props => props.$width}px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  background-color: white;
  z-index: 50;
  left: ${({$left}) => $left}px;
  top:  ${({$top}) => $top}px;
  font-size: 10px;
  color: ${({theme}) => theme.colors.deepBlue['5']};
  transition: all 1s ease-in-out;
  ${props => {
      if(props.position === 'left') {
          return css`
            transform: translate(calc(-100% - 7px), calc(-50% + 7px));
          `
      }

        if(props.position === 'right') {
          return css`
                transform: translate(20px, calc(-50% + 7px));
              `
        }

        if(props.position === 'bottom') {
          return css`
                    transform: translate(calc(-50% + 7px), 20px);
                  `
        }

        if(props.position === 'top') {
          return css`
                    transform: translate(calc(-50% + 7px), calc(-100% - 7px));
          `
        }
  }}
`