import {
    ContentWrapper,
    CustomDomainContent,
    CustomDomainNav,
    CustomDomainNavItem,
    Wrapper
} from './CustomDomain.style';
import React, {useState} from "react";
import PartnerCustomDomain from "./partner-custom-domains/PartnerCustomDomain";
import MarketerCustomDomain from "./marketer-custom-domain/MarketerCustomDomain";


const CustomDomain : React.FC = () =>{
    const [activeTab, setActiveTab] = useState<string>("partner");

    const tabSwitchHandler = (tab: string) => {
        if (tab === "partner") {
            setActiveTab("partner");
        } else {
            setActiveTab("marketer");
        }
    }
    return  (
        <Wrapper>
            <ContentWrapper>
                <CustomDomainNav>
                    <CustomDomainNavItem active={activeTab === "partner"} onClick={() => tabSwitchHandler("partner")}>
                        Partner Custom Domains
                    </CustomDomainNavItem>
                    <CustomDomainNavItem active={activeTab === "marketer"} onClick={() => tabSwitchHandler("marketer")}>
                        Marketer Custom Domains
                    </CustomDomainNavItem>
                </CustomDomainNav>
                <CustomDomainContent>
                    {activeTab === "partner" &&
                    <PartnerCustomDomain/>
                    }
                    {activeTab === "marketer" &&
                    <MarketerCustomDomain/>
                    }
                </CustomDomainContent>
            </ContentWrapper>
        </Wrapper>
    )
}

export default CustomDomain
