import React from "react";
import {Wrapper, SortButtons} from "./SortableHeaderV2.style";
import {ISortableHeaderProps} from "./sortableHeaderV2.props";

const SortableHeaderV2: React.FC<ISortableHeaderProps> = ({keyId, sortDirection, sortedBy, onClick, tooltip, icon = true, children}) => { 
    return <Wrapper onClick={onClick} active={keyId === sortedBy}>
        <span>{children}</span>
       {icon && <SortButtons>
            {keyId !== sortedBy ? <div className="sort-icon-down"></div> : sortDirection === 'desc' ? <div className="sort-icon-down"></div> : <div className="sort-icon-up"></div>}
        </SortButtons>}
        {tooltip && tooltip}
    </Wrapper>
}

export default SortableHeaderV2;
