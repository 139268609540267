import styled from "styled-components";

export const PauseRiskWrapper = styled.div`
    
    div {
        width: 112px;
        padding: 0 8px;
        border-radius: 100px;
        color: ${({theme}) => theme.colors.pureWhite};
        text-align: center;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }
`;