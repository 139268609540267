import styled from "styled-components";

export const TableWrapper = styled.div`
    max-height: 400px;
    width: 100%;
    border: 1px solid ${({theme})=>theme.colors.coolGray[3]};
    border-radius: 8px;
    margin-bottom: 16px;
    
    div:first-child{
        height: 100%;
    }

    .pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        margin-bottom: 16px;
    }
    .no-data {
        text-align: center;
        padding-top: 10px;
        color: ${({theme}) => theme.colors.performOrange[5]};
    }
    .gridtable {
        .right-border {
            border-right: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        }
    }
    
    .gridtable tbody {
        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: ${({theme}) => theme.colors.coolGray[2]};
            border-radius: 10px;
            height: 8px;
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${({theme}) => theme.colors.coolGray[3]};
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #6E6E6E;
        }

        &::-webkit-scrollbar-thumb:active {
            background: #585858;
        }

        display: block;
        max-height: 358px;
        overflow-y: scroll;
    }

    .gridtable thead, .gridtable tbody tr {
        display: table;
        table-layout: fixed;
        width: 100%;
        font-size: 12px;

        td {
            .conversion-info-label {
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            }

            border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        }

        td, th {
            text-align: left;
            font-size: 10px;
            padding: 10px 16px;
            color: ${({theme}) => theme.colors.deepBlue[5]};
        }

        th {
            font-size: 12px;
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
            vertical-align: middle;
            height: 40px;
        }
    }

    .gridtable thead {
        width: calc(100% - 8px);
        background: #F9F9F9;
        border-radius: 8px 8px 0 0;
    }

    .gridtable thead {
        position: relative;
    }

    .gridtable thead th:last-child:after {
        content: ' ';
        position: absolute;
        background-color: #F9F9F9;
        width: 1.3em;
        height: 40px;
        right: -8px;
        top: 0;
            //border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        border-top-right-radius: 8px;
    }
`