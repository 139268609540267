import styled, {css} from'styled-components'
import {InputSingle} from "../../../../../../../../../common/components/input-single-line/InputSingleLine.style";

interface ITierInputProps {
    error?: boolean
}

export const Wrapper = styled.div`
  .row{
    margin-bottom: 30px;
  }

  .fee-date--label{
    font-size: 12px;
    display: inline-block;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    margin-bottom: 8px;
  }

  .optional{
    font-family: ${({theme}) => theme.fonts.AvenirItalic};
  }

`

export const Label = styled.div`
  display: flex;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 14px;
  margin-bottom: 16px;
  align-items: center;
  gap: 7px;
`

export const SearchWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  transform: translateY(-16px);
  height: 50px;
  display: flex;
  align-items: flex-end;
`

export const SearchPartnersInput = styled(InputSingle)`
  height: 32px;
  width: 100%;

`

export const ClearAllPartner = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding-top: 17px;
  position: sticky;
  bottom: 0;
  background-color: white;
  transform: translateY(16px);
  height: 50px;
  .clear-all-button{
    color: ${({theme}) => theme.colors.deepBlue[4]};
    
    :hover{
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[7]};
    }
  }
`

export const AffiliatesListDropdownWrapper = styled.div`
`

export const AffiliatesListItem = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`

export const Tier = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  
  .material-icons-outlined{
    font-size: 14px;
    color: ${({theme}) => theme.colors.performBlue[6]};
    
    :hover{
      cursor: pointer;
    }
  }
  
  .material-icons{
    font-size: 14px;
    color: ${({theme}) => theme.colors.critical[3]};

    :hover{
      cursor: pointer;
    }
  }
`

export const TierInput = styled(InputSingle)<ITierInputProps>`
  height: 40px;
  width: 100%;
  padding: 8px 12px;
  ${props => props.error && css`
    border-color: ${({theme}) => theme.colors.critical[3]};
    &:hover {
        border: 1px solid ${({theme}) => theme.colors.critical[3]};
    }

    &:focus {
        border-color: red;
    }
  `}
`

export const InputStyled = styled(InputSingle)`
  height: 40px;
  width: 100%;
`