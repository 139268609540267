import styled from "styled-components";

export const Loading = styled.div`
    background: #ffffff;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    &:before {
        display: inline-flex;
        width: 26px;
        height: 26px;
        border-top: 2px solid ${({theme}) => theme.colors.coolGray[2]};
        border-right: 2px solid ${({theme}) => theme.colors.coolGray[2]};
        border-bottom: 2px solid ${({theme}) => theme.colors.coolGray[2]};
        border-left: 2px solid ${({theme}) => theme.colors.coolGray[4]};
        border-radius: 50%;
        content: '';
        transform: translateZ(0);
        animation: spin 1s infinite linear, fadeIn .2s ease-in;
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
`