import React from 'react'

import {Wrapper} from "./Billing.style";
import {Outlet} from "react-router-dom";

const Billing:React.FC = () => {
    return <Wrapper>
        <Outlet/>
    </Wrapper>
}

export default Billing
