import styled from "styled-components";

export const TableColumnsListBody = styled.div`
    max-height: 330px;
    overflow-y: auto;
`;

export const TableColumnsListFooter = styled.div`
    border-top: 1px solid #E9ECED;
    padding: 18px 0;
    justify-content: space-between;
    button {
        cursor: pointer;
        border: none;
        background: transparent;
        font-size: 12px;
        color: #8799A2;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        :hover {
            color: #5A686F;
        }
    }
`

export const TableColumnItem = styled.div`
    display: flex;
    align-items: center;
    .checkbox-filter-wrapper{
        padding: 9px;
        width: 100%;
        border-radius: 8px;
        :hover{
            background-color: ${({theme}) => theme.colors.performBlue[1]};
        }
        :active:hover{
            background-color: ${({theme}) => theme.colors.performBlue[2]};
        }
    }
`;

export const TableColumnsPinButton = styled.button`
    font-size: 15px;
    width: 31px;
    background: transparent;
    border: none;
    padding: 0 14px 0 0;
    transition: .2s all ease-in-out;
    line-height: 1;
    cursor: pointer;
    i {vertical-align: -2px;}
    color: ${({theme})=> theme.colors.deepBlue[2]};
    :hover{
        color: ${({theme})=> theme.colors.deepBlue[4]};
    }
    :active:hover {
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
    &.pin{
        color: ${({theme})=> theme.colors.performOrange[3]};
        :hover{
            color: ${({theme})=> theme.colors.performOrange[6]};
        }
        :active:hover{
            color: ${({theme})=> theme.colors.performOrange[8]};
        }
    }
`

export const TableColumnsListDropdown = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    min-width: 295px;
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border: 1px solid #E9ECED;
    background-color: ${({theme}) => theme.colors.pureWhite};
    padding: 22px 20px 5px;

    @media screen and (max-width: 769px) {
        right: auto;
        left: 0;
    }
`;

export const TableColumnsSearch = styled.div`
    padding-bottom: 5px;
    input {
        height: 32px;
    }
`

export const TableColumnsButton = styled.button`
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    margin-left: 5px;
    transition: all .2s ease-in-out;
    position: relative;

    :hover {
        cursor: pointer;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        background-color: ${({theme}) => theme.colors.deepBlue[1]};
    }

    :hover:active {
        color: ${({theme}) => theme.colors.deepBlue[7]};
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }

    &.active {
        color: ${({theme}) => theme.colors.deepBlue[7]};
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }
`

export const TableColumns = styled.div`
    position: relative;
`


