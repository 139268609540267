import styled from "styled-components";

export const AdvertisersWrapper = styled.div`
    .login-field {
        display: flex;
        gap: 10px;
        align-items: center;
        pointer-events: all;
        width: calc(100% );
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .tooltip {
            display: none;
            cursor: pointer;
            line-height: 1;
            i {
                display: block;
                transform: rotate(-90deg);
                color: ${({theme}) => theme.colors.performOrange[3]};
            }
        }
        &:hover{
            /* width: calc(100% - 20px); */
            .tooltip {
                display: inline-block;
            }
        }
    }
`;
