import React, { useState } from "react"
import { useRef } from "react";
import { useEffect } from "react";
import {useOnClickOutside} from "../../hooks";
import { ISelectDropdownProps } from "./select-dropdown.props";
import {
    OptionsFooter,
    Option,
    SelectDropdownWrapper,
    SubOptions,
    OptionName,
    Options,
    OptionsLabel,
    SelectDropdownContainer,
    OptionsList,
    OptionsSearch,
    SubOption,
    SelectedDropdownText,
    SelectDropdownTextWrapp,
    ItemsNotDisplayed
} from "./SelectDropdown.style";

const SelectDropdown: React.FC<ISelectDropdownProps> = ({
            setOpen,
            open,
            options,
            isMultiselect = false,
            setSelection,
            selection,
            labelText,
            isLabelInside = false,
            isSearchable = true,
            groupName = "group",
            hasClearSelectionButton = false,
            loading
        }) => {

    const [listData, setListData] = useState(options);
    const ref = useRef(null);
    
    useEffect(()=>{
        setListData(options);
    }, [options, open]);

    const selectHandler = (item: any) => {
        
        if(!selection.some(current => current.id === item.id)){
            if(!isMultiselect){
                setSelection([item]);
                setOpen(false);
            } else if (isMultiselect){
                setSelection([...selection, item]);
            }
        } else{
            let copySelection = [...selection];
            !isMultiselect && setOpen(false);
            if (isMultiselect) {
                let filteredSelection = copySelection.filter(current => current.id !== item.id);
                setSelection([...filteredSelection]);
            }
        }
    }

    const isSelected = (id: number) => {
        return selection.find(item => item.id === id) ? true : false;
    }
    
    const clearSelectionHandler = () => {
        setSelection([]);
        setOpen(false);
    };

    const checkNameText = (item: any) => {
        if(item.full_name !== undefined) return `${item.full_name}`; //  item name comes in different forms (name, name + lastname, full_name)
        if(item.lastname !== undefined) return `${item.name} ${item.lastname}`;
        return `${item.name}`;
    }

    const textHandler = () => {
        if(!isMultiselect){
            if(selection.length === 0) return `All ${labelText}`;
            return `${checkNameText(selection[0])}`
        } else if(isMultiselect) {
            if(selection.length === 0){
                return `All ${labelText}`;
            } else if(selection.length === 1){
                return `${checkNameText(selection[0])}`;
            } else {
                return `${selection.length} selected`;
            }
        }
    }

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
        const filter = (data: any[], value: string) => {
            const filtered: any[] = [];
            data.forEach((item: any)=>{
              const children = item.group?.filter((child: any) => (child.name && child.name.toLowerCase().includes(value.toLowerCase())) ||
                  (child.lastname  && child.lastname.toLowerCase().includes(value.toLowerCase())) ||
                  (child.full_name  && child.full_name.toLowerCase().includes(value.toLowerCase())) ||
                  (child.name && child.lastname) &&  (child.name.toLowerCase()+ " " + child.lastname.toLowerCase()).includes(value.toLowerCase()) ||
                  child.id && child.id.toString().includes(value.toLowerCase()));

              if (children?.length > 0 || (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                  (item.lastname  && item.lastname.toLowerCase().includes(value.toLowerCase())) ||
                  (item.full_name  && item.full_name.toLowerCase().includes(value.toLowerCase())) ||
                  (item.name && item.lastname) &&  (item.name.toLowerCase()+ " " + item.lastname.toLowerCase()).includes(value.toLowerCase()) ||
                    item.id && item.id.toString().includes(value.toLowerCase())) {
                filtered.push({
                  ...item, group:children
                })
              };
            })
            return filtered;
        }

        let value = e.target.value;
        
        const filteredData = filter(data, value);
        
        setListData(filteredData);
    }

    useOnClickOutside(ref, ()=>{
        setOpen(false);
    });

    if(loading){
        return  <SelectDropdownWrapper ref={ref} open={open}>
                    {!isLabelInside &&
                      <OptionsLabel>{labelText}</OptionsLabel>
                    }
                    <SelectDropdownContainer open={open} >
                        <SelectDropdownTextWrapp open={open} onClick={()=>setOpen(!open)} >
                            {isLabelInside && <OptionsLabel>{labelText}: </OptionsLabel>} 
                            <SelectedDropdownText open={open}>
                                {textHandler()} 
                                {!open && <><span className="arrow-down"><i className="ico-AngleDown"></i></span></>}
                            </SelectedDropdownText>
                        </SelectDropdownTextWrapp>
                        { open && 
                        <Options>
                            <OptionsList>
                                <Option $loading={loading} multiselect={false} isActive={false}>
                                    <OptionName>Loading...</OptionName>
                                </Option>
                            </OptionsList> 
                        </Options>
                        }
                    </SelectDropdownContainer>
                </SelectDropdownWrapper>
}
    return(
        <SelectDropdownWrapper ref={ref} open={open}>
            {!isLabelInside &&
              <OptionsLabel>{labelText}</OptionsLabel>
            }
            <SelectDropdownContainer open={open} >
                <SelectDropdownTextWrapp open={open} onClick={()=>setOpen(!open)} >
                    {isLabelInside && <OptionsLabel>{labelText}: </OptionsLabel>}
                    <SelectedDropdownText open={open}>
                        {textHandler()}
                        {!open && <><span className="arrow-down"><i className="ico-AngleDown"></i></span></>}
                    </SelectedDropdownText>
                </SelectDropdownTextWrapp>
                { open &&
                <Options>
                    {isSearchable &&
                        <OptionsSearch >
                            <input className="input" type="text" onChange={(e)=>searchHandler(e, options)} placeholder="Search" />
                        </OptionsSearch>
                    }
                    <OptionsList>
                        {listData.slice(0,50).map((item: any) => {
                            return  <div key={item.id.toString()}>
                                        <Option multiselect={isMultiselect} isActive={isSelected(item.id)} key={item.id.toString()} onClick={()=>selectHandler(item)}>
                                            {
                                            isMultiselect &&
                                            <div>
                                                <div className="checkbox" >
                                                    { isSelected(item.id) && <i className="ico-Check check"></i>}
                                                </div>
                                            </div>
                                            }
                                            <OptionName>{checkNameText(item)}</OptionName>

                                        </Option>
                                        {item[groupName] &&
                                            <SubOptions>
                                                {item[groupName].map((it: any) => {
                                                return  <SubOption  isActive={isSelected(it.id)} multiselect={isMultiselect} key={it.id.toString()} onClick={()=>selectHandler(it)}>
                                                            {
                                                                isMultiselect &&
                                                                <div>
                                                                    <div className="checkbox" >
                                                                       { isSelected(it.id) && <i className="ico-Check check"></i>}
                                                                    </div>
                                                                </div>
                                                            }
                                                                <OptionName>{checkNameText(it)}</OptionName>
                                                        </SubOption>
                                                })}
                                            </SubOptions>
                                        }
                                    </div>
                            })
                        }
                        {
                            listData.length > 50 && <ItemsNotDisplayed>{listData.length-50} items not displayed</ItemsNotDisplayed>
                        }
                    </OptionsList>
                    {hasClearSelectionButton &&
                    <OptionsFooter className="footer"  onClick={() => clearSelectionHandler()}>
                        Clear Selected
                    </OptionsFooter>
                    }
                </Options>
                }
            </SelectDropdownContainer>
        </SelectDropdownWrapper>
    )
}

export default SelectDropdown;

