import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {
    setValidation,
    setOffer
} from "../create-offer.slice";
import {
    AdjustEventMacro,
    AppsFlyerEventMacro,
    BranchEventMacro,
    INVALID_URL_MSG,
    KochavaEventMacro,
    OFFER_TRACKING_URL,
    SingularEventMacro,
    URL_PATTERN,
    INVALID_MACRO_MSG
} from "../shared-components/consts";
import {Tooltip} from "../shared-components/Shared";
import {useCheckMacro} from "../hooks/useCheckMacro";
import {ENCRYPTED_COST_MACRO} from "../../../../../common/utils/consts";
import {isNullOrUndefined} from "../../../../../common/utils";

export const TrackingUrl: React.FC = () => {
    const dispatch = useAppDispatch()
    const checkMacro = useCheckMacro()
    const {
        offer,
        validation,
    } = useAppSelector(state => state.createOffer)

    const handleOnChange = (value: string) => {
        let dest_url_parts = value.split("?")
        // let url_macros = dest_url_parts.length > 1 ? "?" + dest_url_parts[1] : ""
        let url_macros = dest_url_parts[1]
        let event_macros = ""
        if (!isNullOrUndefined(url_macros)) {
            if (offer.upsells.length > 0) {
                offer.upsells.map((upsell: any, index: number) => {
                    url_macros = offer.trackingSystem === 'appsflyer' ? url_macros.replace(AppsFlyerEventMacro(index + 1, upsell.id), "")
                        : offer.trackingSystem === 'branch' ? url_macros.replace(BranchEventMacro(upsell.id), "")
                            : offer.trackingSystem === 'kochava' ? url_macros.replace(KochavaEventMacro(index + 1, upsell.id), "")
                                : offer.trackingSystem === 'singular' ? url_macros.replace(SingularEventMacro(index + 1, upsell.id), "")
                                    : offer.trackingSystem === 'adjust' ? url_macros.replace(AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id), "")
                                        : ''
                    url_macros = offer.trackingSystem === 'appsflyer' ? url_macros.replace(AppsFlyerEventMacro(index + 1, upsell.id).replace('&', ''), "")
                        : offer.trackingSystem === 'branch' ? url_macros.replace(BranchEventMacro(upsell.id).replace('&', ''), "")
                            : offer.trackingSystem === 'kochava' ? url_macros.replace(KochavaEventMacro(index + 1, upsell.id).replace('&', ''), "")
                                : offer.trackingSystem === 'singular' ? url_macros.replace(SingularEventMacro(index + 1, upsell.id).replace('&', ''), "")
                                    : offer.trackingSystem === 'adjust' ? url_macros.replace(AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id).replace('&', ''), "")
                                        : ''
                    event_macros += offer.trackingSystem === 'appsflyer' ? AppsFlyerEventMacro(index + 1, upsell.id)
                        : offer.trackingSystem === 'branch' ? BranchEventMacro(upsell.id)
                            : offer.trackingSystem === 'kochava' ? KochavaEventMacro(index + 1, upsell.id)
                                : offer.trackingSystem === 'singular' ? SingularEventMacro(index + 1, upsell.id)
                                    : offer.trackingSystem === 'adjust' ? AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id)
                                        : ''
                })
            }
        }
        dispatch(setOffer({
            destination_url: value,
            destination_url_copy: value.split("?")[0] + "?",
            destination_url_ts_macros : url_macros ? url_macros : "",
            destination_url_event_macros : event_macros ? event_macros : "",
            destination_custom_macros: url_macros ? url_macros : "",
        }))
        if(value) {
            if (checkMacro(ENCRYPTED_COST_MACRO, value) && !validation.validateOfferTrackingUrl){
                dispatch(setValidation({validateOfferTrackingUrl : INVALID_MACRO_MSG}))
            } else if(!URL_PATTERN.test(value) && !validation.validateOfferTrackingUrl) {
                dispatch(setValidation({validateOfferTrackingUrl :
                    INVALID_URL_MSG }
                ))
            } else if (URL_PATTERN.test(value) && !checkMacro(ENCRYPTED_COST_MACRO, value) && validation.validateOfferTrackingUrl ) {
                dispatch(setValidation({validateOfferTrackingUrl : undefined}))
            }
        }
        else {
            dispatch(setValidation({validateOfferTrackingUrl : "Offer Tracking Url is required"}))
        }
    }
    
    return (
        <Input
            label={<>Campaign Tracking URL {Tooltip( 246, OFFER_TRACKING_URL)} </>}
            placeholder={ offer.trackingSystem ? offer.trackingSystem !== "other" ? "Enter the URL from " +
                offer.trackingSystem.replace(offer.trackingSystem.charAt(0), offer.trackingSystem.charAt(0).toUpperCase()):
                "Enter the URL from your tracking system" : "Enter the URL from your tracking system" }
            value={offer.destination_url}
            onChange={(event) => handleOnChange(event.target.value)}
            error={validation.validateOfferTrackingUrl}
        />
    );
};