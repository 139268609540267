import styled from 'styled-components'

export const TableStyled = styled.table`
    margin-top: 15px;
    background-color: white;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    
    th{
        text-align: left;
        padding: 10px 16px;
        color: #31383B;
        text-transform: uppercase;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }
    
    td{
        padding: 10px 16px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
    }
    
    .material-symbols-outlined{
        font-size: 15px;
    }
    
`

export const Wrapper = styled.div`
    margin-top: 20px;
`