import styled from "styled-components";

export const LatestReassessmentStatusWrapper = styled.div`
    .status {
        display: flex;
        align-items: center;
        gap: 8px;
        .text {
            text-transform: capitalize;
        }
        .circle {
            width: 12px;
            height: 12px;
            border-radius: 100%;
        }
        .open-button {
            visibility: hidden;
            opacity: 0;
            transition: .1s ease-in;
            display: flex;
            align-items: center;
            color: #0093D3;
            text-decoration: none;

            i {
                font-size: 12px;
            }
        }

        &:hover {
            .open-button {
                visibility: visible;
                opacity: 1;
            }
        }
    }
`;

export const StatusTooltipBody = styled.div`
    .status-data {
        padding-bottom: 8px;
        > div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            &.item-priority {
                .priority {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
            &.item-status {
                .status {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .text {
                        text-transform: capitalize;
                    }
                    .circle {
                        width: 9px;
                        height: 9px;
                        border-radius: 100%;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .status-ticket-resolution {
        margin-top: 8px;
        padding-top: 16px;
        border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        strong {
            display: block;
            margin-bottom: 8px;
        }
    }
`;