import React, {useEffect, useMemo, useRef, useState} from "react";
import qs from "qs";
import DataGrid from "../../../../common/components/data-grid/DataGrid";
import GridRowOptions from "../../../../common/components/data-grid/grid-row-options/GridRowOptions";
import {
    ColDef,
    Column,
    ColumnApi,
    ColumnState,
    GridApi,
    GridReadyEvent,
    IHeaderParams,
    RowClickedEvent
} from "ag-grid-community";
import Pagination from "../../../../common/components/data-grid/pagination/Pagination";
import AdvertisersService, {IFetchMarketersParams} from "./Advertisers.service";
import {
    ButtonStyled,
    TableToolbarBottom,
    TableToolbarTop,
    TableToolbarTopBottomRow,
    TableToolbarTopUpperRow,
    TableWrapper,
    Tag
} from '../../../../common/styled-components';
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {tokenStore} from "../../../../store/auth.slice";
import {withPageContainer} from "../../../../common/hoc/withPageContainer/withPageContainer";
import {Advertiser, IAdvertiserTag} from "../../../../common/models/advertiser.type";
import {ITag} from "../../../../common/models/tag.type";
import Search from "../../../../common/components/search/Search";
import {setPerPage, setSearchMarketerValue} from './Advertisers.slice';
import Actions from "./actions/Actions";
import {DeleteDropdownItem, DropdownItem} from "../../../../common/components/dropdown/Dropdown.style";
import {formatNumberToCurrency, getStatus} from "../../../../common/utils";
import {WebsiteLink} from "./advertisers-table/AdvertiserTable.style";
import Modal from "../../../../common/components/modal/Modal";
import AddTagsModal from "../../../../common/components/tags/add-tags-modal/AddTagsModal";
import RemoveTagsModal from "../../../../common/components/tags/remove-tags-modal/RemoveTagsModal";
import FiltersService from "../../../../common/services/filters.service";
import Icon from "../../../../common/components/icon/Icon";
import Filters from './filters/Filters';
import GridSortableHeader from "../../../../common/components/data-grid/grid-sortable-header/GridSortableHeader";
import Tooltip from "../../../../common/components/tooltip/Tooltip";
import {AdvertisersWrapper} from "./Advertisers.style";
import {
    IWithUserPreferencesProps,
    withUserPreferences
} from "../../../../common/hoc/withUserPreferences/withUserPreferences";
import {IFilter, saveUserPreferences, saveUserTablePreferences} from "../../../../store/userPreferences.slice";

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const defaultNiches = [
    {id: 1, name: 'Financial'},
    {id: 0, name: 'Health & Beauty'},
    {id: 2, name: 'Entertainment & Lifestyle'},
    {id: 5, name: 'Downloads & Software'},
    {id: 6, name: 'E-Commerce'},
    {id: 4, name: 'Other'},
]

interface IMap {
    [key: string]: Advertiser[]
}

const Advertisers: React.FC<IWithUserPreferencesProps> = ({
                                                              filterPreferences,
                                                              tablePreferences,
                                                              saveTablePreferences
                                                          }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenStore)
    const pageId = 'admin/users/marketers'
    const {
        marketerSearchValue,
        per_page
    } = useAppSelector(state => state.advertisers);

    const filterRef = useRef(filterPreferences)
    const [niches, setNiches] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);

    //Pagination data
    const [page, setPage] = useState<number>(1)
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)

    const [lastPage, setLastPage] = useState<number>(0)

    // modals functions
    const [isAddTagModalOpened, setIsAddTagModalOpened] = useState(false);
    const [isEditTagsModalOpened, setIsEditTagsModalOpened] = useState(false);
    const [isRemoveTagModalOpened, setIsRemoveTagModalOpened] = useState(false)
    const [addTag, setAddTag] = useState<boolean>(false)
    const [addedTags, setAddedTags] = useState<ITag[]>([])
    const [tags, setTags] = useState<ITag[]>([])

    // table api
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
    const [gridApi, setGridApi] = useState<GridApi>()

    // selected checkboxes
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState<IMap>()

    const setSearch = (input: string) => {
        dispatch(setSearchMarketerValue(input));
    }

    const getNicheName = (advertiser: Advertiser) => {
        let name = niches.find(niche => niche.id === advertiser.niche_id)?.name
        let i = defaultNiches.findIndex(niche => niche.id === advertiser.niche_id);
        if (i !== -1) {
            name = defaultNiches[i].name
        }
        return name
    }

    const getSelectedRows = () => {
        if(selectedRowsPerPage){
            const selectedValues = Object.values(selectedRowsPerPage)
            return selectedValues.reduce(function(prev, next) {
                return prev.concat(next);
            });
        } else {
            return []
        }
    }

    const fetchData = async (direction?: string | null, sort_by?: string | null) => {
        try {
            setLoading(true)
            gridApi?.showLoadingOverlay()
            setDiscoverDisplayed(false)
            const params = setFilterParams(direction, sort_by)
            const {data} = await AdvertisersService.fetchTableData(token, params)
            dispatch(setPerPage(data.per_page))
            setPage(data.current_page)
            gridApi?.setRowData(data.data)
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLastPage(data.last_page)
            setLoading(false);
            gridApi?.forEachNode((node) => {
                const websites = node.data.registration_info.websites
                if (websites) {
                    node.setRowHeight(websites.length < 2 ? 40 : 40 + websites.length * 12)
                    gridApi?.onRowHeightChanged()
                }
                node.setSelected( getSelectedRows().some((row : Advertiser) => {
                       return node.data.id === row.id
                   }
                ))
            })
        } catch (e) {
            console.log(e)
        }
    }

    const fetchTags = async () => {
        let {data} = await FiltersService.fetchTags(token)
        setTags(data)
    }

    const fetchNiches = async () => {
        const {data} = await AdvertisersService.fetchNiches(token)
        setNiches(data)
    }

    useEffect(() => {
        if (token && gridApi) {
            fetchData();
        }
    }, [token, per_page, page, gridApi]);

    useEffect(() => {
        if (page === 1) {
            fetchData()
        } else {
            setPage(1)
        }
    }, [marketerSearchValue])

    useEffect(() => {
        if (token) {
            fetchNiches()
            fetchTags()
        }
    }, [token]);

    useEffect(() => {
        if (Object.keys(filterPreferences).length < Object.keys(filterRef.current).length) {
            setDiscoverDisplayed(true)
        }
    }, [
        filterPreferences
    ])

    useEffect(() => setDiscoverDisplayed(false), [])

    const setFilterParams = (direction?: string | null, sort_by?: string | null) => {
        let params: IFetchMarketersParams = {
            sort_by: sort_by ? sort_by : 'id',
            order: direction ? direction : 'desc',
            paginate_by: per_page,
            page: page,
            search: marketerSearchValue
        }
        if (filterPreferences['auto-funding'] && filterPreferences['auto-funding'].selected.length > 0) {
            params.auto_funding = filterPreferences['auto-funding'].selected[0].name === "Enabled" ? 1 : 0;
        }
        if (filterPreferences['invoicing-cycle'] && filterPreferences['invoicing-cycle'].selected.length > 0) {
            params = {
                ...params,
                intacct_invoicing_cycle: {
                    ids: [...filterPreferences['invoicing-cycle'].selected],
                    include: filterPreferences['invoicing-cycle'].include
                }
            }
        }
        if (filterPreferences['account-manager'] && filterPreferences['account-manager'].selected.length > 0) {
            params = {
                ...params,
                account_manager: {
                    ids: [...filterPreferences['account-manager'].selected.map(selected => selected.id)],
                    include: filterPreferences['account-manager'].include
                }
            }
        }
        if (filterPreferences['marketer-status-multiselect'] &&
            filterPreferences['marketer-status-multiselect'].selected.length > 0) {
            params = {
                ...params,
                status: {
                    ids: [...filterPreferences['marketer-status-multiselect'].selected.map(selected =>
                        selected.status_name)],
                    include: filterPreferences['marketer-status-multiselect'].include
                }
            }
        }
        if (filterPreferences['tags'] && filterPreferences['tags'].selected.length > 0) {
            params = {
                ...params,
                tags: {
                    ids: [...filterPreferences['tags'].selected.map(selected => selected.id)],
                    include: filterPreferences['tags'].include
                }
            }
        }
        if (filterPreferences['marketer-type'] && filterPreferences['marketer-type'].selected.length > 0) {
            params = {
                ...params,
                adv_type: {
                    ids: [...filterPreferences['marketer-type'].selected.map(selected => selected.name)],
                    include: filterPreferences['marketer-type'].include
                }
            }
        }
        if (filterPreferences['payment-terms'] && filterPreferences['payment-terms'].selected.length > 0) {
            params = {
                ...params,
                intacct_payment_term: {
                    ids: [...filterPreferences['payment-terms'].selected],
                    include: filterPreferences['payment-terms'].include
                }
            }
        }
        if (filterPreferences['referrer'] && filterPreferences['referrer'].selected.length > 0) {
            params = {
                ...params,
                referrer: {
                    ids: [...filterPreferences['referrer'].selected.map(selected => selected.id)],
                    include: filterPreferences['referrer'].include
                }
            }
        }
        if (filterPreferences['sales-executive'] && filterPreferences['sales-executive'].selected.length > 0) {
            params = {
                ...params,
                sales_executive: {
                    ids: [...filterPreferences['sales-executive'].selected.map(selected => selected.id)],
                    include: filterPreferences['sales-executive'].include
                }
            }
        }
        if (filterPreferences['sensitivity'] && filterPreferences['sensitivity'].selected.length > 0) {
            params = {
                ...params,
                sensitivity: {
                    ids: [...filterPreferences['sensitivity'].selected.map(selected => selected.id)],
                    include: filterPreferences['sensitivity'].include
                }
            }
        }
        if (filterPreferences['marketer-vertical'] && filterPreferences['marketer-vertical'].selected.length > 0) {
            params = {
                ...params,
                niche: {
                    ids: [...filterPreferences['marketer-vertical'].selected.map(selected => selected.id)],
                    include: filterPreferences['marketer-vertical'].include
                }
            }
        }
        return params
    }

    function handleLoginAs(user: Advertiser) {
        targetOrigin && window.parent.postMessage({id: 1, user, login_type: "marketer"}, targetOrigin);
    }

    function handleLoginAsAdmin(user: Advertiser) {
        targetOrigin && window.parent.postMessage({id: 1, user}, targetOrigin);
    }

    function handleViewEdit(user: Advertiser) {
        targetOrigin && window.parent.postMessage({id: 2, user}, targetOrigin);
    }

    const handleDiscover = () => {
        if (page === 1)
            fetchData()
        else
            setPage(1)
        dispatch(saveUserPreferences(pageId))
        filterRef.current = filterPreferences
    }

    const exportCsv = () => {
        let params = qs.stringify(setFilterParams());
        let columns: string[] = []
        let columnsNumber: number = 0
        if (tablePreferences) {
            if (tablePreferences.filter((col: ColumnState) => col.hide === true).length >= 18) {
                alert("No columns selected. Please select at least one column")
            }
        }
        tablePreferences?.forEach((column: ColumnState) => {
                if (column.colId !== 'check' && column.colId !== 'option' && column.hide === false) {
                    columnsNumber++
                    column.colId && columns.push(column.colId)
                }
            }
        )
        if (columnsNumber !== 18) {
            let cols = {
                columns: columns
            }
            params = params + "&" + qs.stringify(cols)
        }
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            'export'
        ].join('/') + "?user_type=advertiser&not_from_drilldown=true&" + params, "_blank");
    }

    const tooltipContainerStyle: React.CSSProperties = {
        padding: '8px 12px'
    }

    //Define columns for the Advertisers table
    const defaultColumnDefs = useMemo<ColDef>(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const columnDefs = useMemo<ColDef[]>(() => [
        {
            colId: "check",
            resizable: false,
            pinned: "left",
            lockPinned: true,
            lockPosition: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 50,
        },
        {
            pinned: "left",
            field: 'id',
            headerName: "ID",
            colId: "id",
            width: 150,
            sortable: true,
            sort: 'desc',
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >MARKETER ID</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.id}</span>
            },
        },
        {
            pinned: "left",
            field: 'company',
            headerName: 'Marketer Company Name',
            colId: "company",
            width: 220,
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>MARKETER COMPANY NAME</div>
            },
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.company}</span>
            },
        },
        {
            field: 'lastname',
            headerName: 'Marketer Name',
            colId: "lastname",
            width: 220,
            cellRenderer: ({data}: { data: any }) => {
                return <div className={'login-field'}>
                    <span className={'name'}>{data.name} {data.lastname}</span>
                    <div className={'tooltip'}>
                    <Tooltip position={"top"}
                             width={105}
                             positionLeft={0}
                             positionTop={-4}
                             containerStyle={tooltipContainerStyle}
                             icon={
                                 <span className={'icon'} style={{fontSize: "16px"}}>
                                     <i className="ico-Download" onClick={() => handleLoginAsAdmin(data)}/>
                                 </span>
                                 }>
                       <span style={{
                           fontSize: '12px',
                           position: 'relative',
                           zIndex: 20
                       }}>
                            Log in as Admin
                        </span>
                        </Tooltip>
                    </div>
                </div>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>MARKETER NAME</div>
            }
        },
        {
            field: 'accountManager',
            colId: "accountManager",
            headerName: "Engine Growth Manager",
            width: 250,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.executive ? data.executive.full_name : ""}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>ENGINE GROWTH MANAGER</div>
            }
        },
        {
            field: 'createdAt',
            colId: "created_at",
            headerName: "Date Created",
            width: 140,
            sortable: true,
            comparator: () => 0,
            cellRenderer: ({data}: { data: Advertiser }) => {
                const dateArray = data.created_at.split(' ')[0].split('-')
                const date = `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`
                return <span>{date}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <GridSortableHeader
                    headerParams={data}
                >DATE CREATED</GridSortableHeader>
            }
        },
        {
            field: 'type',
            colId: "type",
            headerName: "Type",
            width: 160,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.registration_info?.description || ""}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>TYPE</div>
            }
        },
        {
            field: 'status',
            colId: "status",
            headerName: "Marketer Status",
            width: 160,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span style={{textTransform: 'capitalize'}}>{getStatus((data), true)}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>MARKETER STATUS</div>
            }
        },
        {
            field: 'vertical',
            colId: "vertical",
            headerName: "Vertical",
            width: 170,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{getNicheName(data)}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>VERTICAL</div>
            }
        },
        {
            field: 'website',
            colId: "website",
            headerName: "Website",
            width: 220,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <div style={{whiteSpace: 'nowrap', overflow: "hidden", textOverflow: 'ellipsis',}}>
                    {data.registration_info?.websites?.map((website, i) =>
                        <WebsiteLink key={i} href={website} target="_blank">{website}</WebsiteLink>)}</div>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>WEBSITE</div>
            }
        },
        {
            field: 'offers',
            colId: "offers",
            headerName: "Offers",
            width: 115,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.campaigns_total}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>OFFERS</div>
            }
        },
        {
            field: 'invoiceCycle',
            colId: "invoiceCycle",
            headerName: "Invoicing Cycle",
            width: 200,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.intacct_invoicing_cycle}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>INVOICING CYCLE</div>
            }
        },
        {
            field: 'payment',
            colId: "payment",
            headerName: "Payment Terms",
            width: 200,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.intacct_payment_term}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>PAYMENT TERMS</div>
            }
        },
        {
            field: 'autoFunding',
            colId: "autoFunding",
            headerName: "Auto-Funding",
            width: 280,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span>{data.auto_funding_bool === 0 ? "Disabled" :
                    data.auto_funding_amount ?
                        data.auto_funding_amount > 0 ?
                            "Enabled / " + formatNumberToCurrency(data.auto_funding_amount) + " at " +
                            formatNumberToCurrency(data.auto_funding_amount / 10) + " Funding Remaining" :
                            "Enabled / $0.00 at $0.00 Funding Remaining"
                            : "Enabled / $0.00 at $0.00 Funding Remaining"}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>AUTO-FUNDING</div>
            }
        },
        {
            field: 'creditDeposit',
            colId: "creditDeposit",
            headerName: "Credit Deposit",
            sortable: true,
            width: 200,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span className='cell-content-right'>{data.intacct_credit_deposit ?
                    formatNumberToCurrency(data.intacct_credit_deposit) : ''}</span>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>CREDIT DEPOSIT</div>
            }
        },
        {
            field: 'creditLimit',
            colId: "creditLimit",
            headerName: "Credit Limit",
            width: 200,
            cellRenderer: ({data}: {data: any}) => {
                if(data.intacct_credit_limit === null) return <div/>
                return <div className='cell-content-right' >{formatNumberToCurrency(data.intacct_credit_limit)}</div>
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>CREDIT LIMIT</div>
            }
        },
        {
            field: 'stayLiveLimit',
            colId: "stay_live_limit",
            headerName: "Stay Live Limit",
            sortable: true,
            width: 200,
            comparator: () => 0,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <span className='cell-content-right'>{
                    data.stay_live_limit === null ? "Unlimited" : formatNumberToCurrency(data.stay_live_limit)
                }
                </span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >STAY LIVE LIMIT</GridSortableHeader>
                </div>
            }
        },
        {
            field: 'tags',
            colId: "tags",
            headerName: "Marketer Tags",
            width: 300,
            cellRenderer: ({data}: { data: Advertiser }) => {
                return data.tags && data.tags.map((tag: IAdvertiserTag) => {
                    return <Tag key={tag.id + tag.name}>{tag.name}</Tag>
                })
            },
            headerComponent: ({data}: {data: Advertiser}) =>{
                return <div>MARKETER TAGS</div>
            }
        },
        {
            pinned: "right",
            field: "",
            width: 50,
            colId: "option",
            resizable: false,
            suppressMovable: true,
            // lockPinned: true,
            // lockPosition: true,
            //initialPinned: 'right',
            cellRenderer: ({data}: { data: Advertiser }) => {
                return <GridRowOptions>
                    <DropdownItem onClick={() => handleLoginAs(data)}>Log in as Marketer</DropdownItem>
                    <DropdownItem onClick={() => handleViewEdit(data)}>Edit Marketer Details</DropdownItem>
                    <DropdownItem onClick={() => handleLoginAsAdmin(data)}>Log in as Admin</DropdownItem>
                </GridRowOptions>
            }
        }
    ], [])

    const openAddTagsModal = () => {
        setAddTag(true)
        setAddedTags([])
        setIsAddTagModalOpened(true)
    }

    const closeAddOrEditTagsModal = () => {
        if (addTag) {
            setIsAddTagModalOpened(false)
        } else {
            setIsEditTagsModalOpened(false)
        }
    }

    const openEditTagsModal = () => {
        setAddTag(false)
        let tags: ITag[] = getSelectedRows()[0].tags && getSelectedRows()[0].tags.map((tag: IAdvertiserTag) => {
            return {id: tag.id, name: tag.name}
        })
        setAddedTags(tags)
        setIsEditTagsModalOpened(true)
    }

    const openRemoveTagsModal = () => {
        setIsRemoveTagModalOpened(true)
    }

    const closeRemoveTagsModal = () => {
        setIsRemoveTagModalOpened(false)
    }


    const saveTags = async (selectedTagsNum: number[]) => {
        if (!addTag) {
            await AdvertisersService.deleteTags(token, tagsForRemove())
        }
        const params = {
            property_ids: getSelectedRows().map(marketer => marketer.id),
            type: 'advertiser',
            tag_ids: selectedTagsNum
        }
        await AdvertisersService.assignTags(token, params)
        await fetchData()
        setSelectedRowsPerPage({"":[]})
        gridApi?.deselectAll()
    }

    const removeTags = async () => {
        await AdvertisersService.deleteTags(token, tagsForRemove())
        await fetchData()
        setSelectedRowsPerPage({"":[]})
        gridApi?.deselectAll()
    }

    const tagsForRemove = () => {
        interface TempTags {
            property_id: number,
            tps_id: number,
            id: number,
            name: string,
            is_selected?: boolean
        }
        const tps = [] as TempTags[]
        getSelectedRows().forEach((adv: Advertiser) => {
            adv.tags.forEach((tag: IAdvertiserTag) => {
                let tmp: TempTags = {
                    property_id: adv.id,
                    tps_id: tag.tps_id,
                    id: tag.id,
                    name: tag.name,
                    is_selected: true
                }
                tps.push(tmp)
            })
        })
        return {
            tps: tps
        }
    }

    const onRowSelected = (e : RowClickedEvent) =>{
        /**
         * this function will fire on every checkbox selection (even on select all checkbox)
         * logic below checks for  selected rows through pages including selected rows from search
         * filter function remove duplicated rows per page.
         * for large number of selection it has small delay on actions button (where number of selected rows is
         * displayed)
         * TODO: optimization
         */
        if(selectedRowsPerPage){
            let rowSelected = e.node.isSelected()
            let selectedRowId = e.data.id
            let selectedRows = selectedRowsPerPage[page.toString()]
            if (!selectedRows){
                selectedRows = []
            }
            if (rowSelected){
                selectedRows.push(e.data)
                selectedRows = selectedRows.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                )
            } else {
                selectedRows = selectedRows.filter((item)=>{
                    return item.id !== selectedRowId
                })
            }
            let tempRows = {...selectedRowsPerPage}
            tempRows[page] = selectedRows
            setSelectedRowsPerPage(tempRows)
        } else{
            setSelectedRowsPerPage({
                [page] : e.api.getSelectedRows()
            })
        }
    }

    const orderColumns = (columns: ColumnState[]): ColumnState[] => {
        const newColState = [] as ColumnState[]
        if (columns) {

            columns.forEach(column => {
                if (column.pinned === 'left') {
                    newColState.push(column)
                }
            })
            columns.forEach(column => {
                if (column.pinned !== 'left') {
                    newColState.push(column)
                }
            })

        }
        return newColState

    }

    const onGridReady = (api: GridReadyEvent) => {
        setGridApi(api.api)
        setGridColumnApi(api.columnApi)
        if (tablePreferences.length > 0) {
            api.columnApi.applyColumnState({state: orderColumns(tablePreferences)})
            api.columnApi.moveColumns(orderColumns(tablePreferences).map(column => column.colId) as string[],0)
        } else {
            saveTablePreferences(api.columnApi.getColumnState())
        }
    }
    
    const handleEnableAllColumns = () => {
       if ( gridColumnApi ){
           gridColumnApi.setColumnsVisible(gridColumnApi.getAllColumns() as Column[], true)
           dispatch(saveUserTablePreferences({pageId: 'admin/users/marketers', data: gridColumnApi.getColumnState()}))
       }
       gridApi &&gridApi.hideOverlay()
   }

    return (
        <AdvertisersWrapper>
            <TableWrapper>
                <TableToolbarTop>
                    <TableToolbarTopUpperRow>
                        <Actions selectedRows={getSelectedRows().map((item: Advertiser) => {
                            return {
                                displayName: item.display_name,
                                id: item.id,
                            }
                        })}>
                            <DropdownItem onClick={openEditTagsModal}
                                          className={getSelectedRows().length === 0 || getSelectedRows().length > 1 ?
                                              "disabled" : ""}>
                                Edit Tags
                            </DropdownItem>
                            <DropdownItem onClick={openAddTagsModal}>
                                Add Tags to Selected
                            </DropdownItem>
                            <DeleteDropdownItem onClick={openRemoveTagsModal}>
                                Remove All Tags from Selected
                            </DeleteDropdownItem>

                        </Actions>
                        <Search width={30}
                                wrapperClassName={"table-search"}
                                onChange={setSearch}
                                initialValue={marketerSearchValue}/>
                        {
                            discoverDisplayed &&
                            <ButtonStyled onClick={() => handleDiscover()} className={'btn-discover-results'}>
                                Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/>
                            </ButtonStyled>
                        }
                    </TableToolbarTopUpperRow>
                    <TableToolbarTopBottomRow>
                        <Filters setDiscoverDisplayed={setDiscoverDisplayed}/>
                        <Pagination loading={loading}
                                    exportCsv={exportCsv}
                                    current_page={page}
                                    from={from}
                                    to={to}
                                    total={total}
                                    last_page={lastPage}
                                    setCurrentPage={setPage}
                                    perPage={per_page}
                                    setPerPage={(n: number) => dispatch(setPerPage(n))}
                                    onRefresh={fetchData}
                                    gridColumnApi={gridColumnApi}
                                    gridApi={gridApi}
                                    id={'admin/users/marketers'}
                        />
                    </TableToolbarTopBottomRow>
                </TableToolbarTop>
                <DataGrid
                    onRowSelected={(e)=> onRowSelected(e)}
                    fixedScroll={true}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDefs}
                    rowHeight={40}
                    domLayout='autoHeight'
                    loading={loading}
                    suppressRowClickSelection={true}
                    onSortChanged={(e) => {
                        const direction = e.columnApi.getColumnState().filter(column => column.sort !== null)[0].sort
                        const sort_by = e.columnApi.getColumnState().filter(column => column.sort !== null)[0].colId
                        fetchData(direction, sort_by)
                        saveTablePreferences(e.columnApi.getColumnState())
                    }}
                    rowSelection={'multiple'}
                    onGridReady={onGridReady}
                    onDragStopped={(api) => {
                        saveTablePreferences(api.columnApi.getColumnState())
                    }}
                    allColumnsDisabled={tablePreferences.some(column => {
                        return column.hide === false
                    })}
                    handleEnableAllColumns={handleEnableAllColumns}
                />
                <TableToolbarBottom>
                    <Pagination loading={loading}
                                perPage={per_page}
                                current_page={page}
                                from={from} to={to}
                                total={total}
                                last_page={lastPage}
                                setCurrentPage={setPage}
                                setPerPage={(n: number) => dispatch(setPerPage(n))}
                                openUp={true}/>
                </TableToolbarBottom>
            </TableWrapper>
            <Modal opened={isAddTagModalOpened || isEditTagsModalOpened}
                   title={addTag ? "Add Tags to selected Marketer(s)" : "Edit Tags"}
                   closeModal={closeAddOrEditTagsModal}>
                <AddTagsModal addTag={addTag}
                              selectedUsers={getSelectedRows().map((adv: Advertiser) => {
                                      return {id: adv.id, name: adv.display_name}
                                  }
                              )}
                              addedTags={addedTags}
                              tags={tags}
                              saveTags={saveTags}
                              closeModal={closeAddOrEditTagsModal}/>
            </Modal>
            <Modal opened={isRemoveTagModalOpened}
                   title={"Remove All Tags from Selected Marketers(s)?"}
                   closeModal={closeRemoveTagsModal}>
                <RemoveTagsModal
                    content={"Are you sure you want to remove all tags from the selected marketer(s)? " +
                    "This is a global change and will affect all users in the platform. " +
                    "All reports and filters related to these partners will be updated " +
                    "immediately upon removing the tags.\n" +
                    "\n" +
                    "This action cannot be undone."}
                    removeTags={removeTags} closeModal={closeRemoveTagsModal}/>
            </Modal>
        </AdvertisersWrapper>
    )
}

export default withUserPreferences('admin/users/marketers', withPageContainer(Advertisers));