import styled from "styled-components";

export const Wrapper = styled.div`
    background: #ffffff;
    height: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 25px 40px;
  background: #ffffff;
`;

export const Nav = styled.div`
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[2]};
  gap: 4px;
`;

export const NavItem = styled.div<{active :boolean}>`
      text-shadow: ${({active, theme}) => active ? '0px 0px 0px #ffffff;' : 'none'};
  background-color: ${({active, theme}) => active ? theme.colors.performBlue[5] : theme.colors.coolGray[3]};
  color: ${({active, theme}) => active ? theme.colors.pureWhite : theme.colors.deepBlue[5]};
  border-radius: 8px 8px 0 0;
  padding: 12px 12px;
  line-height: 1.24;
  transition: all .15s ease-in-out;
  cursor: pointer; 
  :hover {
    background-color: ${({active, theme}) => active ? theme.colors.performBlue[6] : theme.colors.coolGray[4]};
  }
  :active {
    background-color: ${({active, theme}) => active ? theme.colors.performBlue[7] : theme.colors.coolGray[5]};
    color: ${({active, theme}) => active ? theme.colors.pureWhite : theme.colors.deepBlue[7]};
  }
`;

export const Content = styled.div`
  //margin: 27px 0;
`;
