import styled from "styled-components";

export const UiFilterWrapper = styled.div`
    display: flex;
    border: 1px solid #E9ECED;
    border-radius: 8px;
    padding: 20px 25px;
    margin-bottom: 20px;
    position: relative;
`;

export const CloseButtonUiFilter = styled.div`
    position: absolute;
    display: flex;
    right: 20px;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover {
        background: #DFE7EB;
        color: #5A686F;
        .close-icon {
            color: #404041;
        }
    }

    .close-icon {
        font-weight: 900;
        color: #8799A2;
        font-size: 16px;
        /* font-family: ${({theme}) => theme.fonts} */
    }
`;