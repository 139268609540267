import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {
    setOffer,
    setValidation
} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {OFFER_NAME_TOOLTIP} from "../shared-components/consts";

export const OfferName: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        validation,
    } = useAppSelector(state => state.createOffer)

    const handleOnChange = (value : string) => {
        dispatch(setOffer({
        name: value}))
    }

    const handleLeaveOfferName = () => {
        if (offer.name.length <= 0) {
            dispatch(setValidation({validateOfferName : "Offer name is required"}))
        } else if (validation.validateOfferName){
            dispatch(setValidation({validateOfferName : undefined}))
        }
    }

    return (
        <Input
            label={<>Campaign Name {Tooltip(188, OFFER_NAME_TOOLTIP)}</>}
            placeholder={'Enter a name for the campaign'}
            value={offer.name}
            onChange={(event) => handleOnChange(event.target.value)}
            onBlur={handleLeaveOfferName}
            error={validation.validateOfferName}
        />
    );
};