import styled from 'styled-components'

export const Wrapper = styled.div`
`

export const DataSourceInfoTooltip = styled.div`
  max-width: 182px;
  strong{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
`