import React, {useMemo} from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";


interface Props {}

const TrafficTypes: React.FC<Props> = () => {
    const {offer, navState, trafficTypes} = useAppSelector(state => state.createOffer)

    const displayTrafficType = useMemo(()=>{

        const selectedTypes = offer.traffic_types?.filter(type => type.traffic_group_id === 1 && type.selected)

        return selectedTypes?.length !== trafficTypes.filter(type => type.traffic_group_id === 1).length ||
            offer.traffic_types?.some(type => type.traffic_group_id === 2 && type.selected)

    }, [offer.traffic_types, trafficTypes])

    if(!displayTrafficType || navState['targeting'] === 'disabled') return null

    return (
        <>
            <Subtitle>Traffic Types</Subtitle>
            <Item style={{display: 'block'}}>{offer.traffic_types && offer.traffic_types.map((type, index) => {
                return type.selected ? <span key={type.name}>{type.name}{index !== offer.traffic_types!.length - 1 ? ', ' : ''}</span> : null
            })}
            </Item>
        </>
    )
}

export default TrafficTypes