import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 8px;
  .ico-Filters {
    font-size: 15px;
    color: ${({theme}) => theme.colors.deepBlue[2]};
  }
  
  .checkbox-filter-wrapper{

      padding: 9px;
      border-radius: 8px;
      :hover{
        background-color: ${({theme}) => theme.colors.performBlue[1]};
      }
    
  }
  
  .filter-group-title{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    margin: 4px 0;
  }
  
  .clear-filters{
    position: relative;
    
    .clear-filters-tooltip{
      position: absolute;
      background: white;
      z-index: 100;
      top: -45px;
      left: -25px;
      border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
      padding: 8px 12px;
      color: ${({theme}) => theme.colors.deepBlue[5]};
      border-radius: 8px;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
  }
`
