import React, {ReactElement, useState} from 'react';
import Select, {Props, Options, GroupBase} from "react-select";
import {SelectLabel, SelectWrapper} from "./select.style";

export type SelectOption = {
    label: string,
    value: any,
    disabled?: boolean
}

interface IProps extends Props<SelectOption> {
    options: SelectOption[]
    selectLabel?: string | ReactElement
    maxMenuHeight?: number,
    selectWrapperClassname?: string,
    isSelectDisabled? : boolean
    menuAutoWidth?: boolean
}

export interface IGroupOptions extends Props<SelectOption>{
    options: SelectOption[],
    label : string,
    group_id :  number | string
}


export const StyledSelect: React.FC<IProps> = (props) => {
    const [isMenuOpened, setMenuOpened] = useState<boolean>(false)
    return (
        <SelectWrapper className={props.selectWrapperClassname} $autoWidth={props.menuAutoWidth}>
            {props.selectLabel &&
                <SelectLabel isSelectDisabled={props.isSelectDisabled}
                             isMenuOpened={isMenuOpened}>
                    {props.selectLabel}
                </SelectLabel>}
            <Select
                isDisabled={props.isSelectDisabled}
                {...props}
                menuIsOpen={isMenuOpened}
                onMenuOpen={() => setMenuOpened(true)}
                onMenuClose={() => setMenuOpened(false)}
                classNamePrefix={'styled-select'}
                menuPlacement={"auto"}
                maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : 150}

            />
        </SelectWrapper>
    );
};