import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";
import {isNullOrUndefined} from "../../../../../../../common/utils";

interface Props {}

const DomainIdParameter: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(
        offer.campaign_tracking_settings?.tracking_clickid_parameter === '' ||
        isNullOrUndefined(offer.campaign_tracking_settings?.tracking_clickid_parameter) ||
        isNullOrUndefined(offer.direct_linking) ||
        !offer.direct_linking ||
        navState['land-and-track'] === 'disabled'
    ) return null

    return (
        <>
            <Subtitle>Domain ID Parameter</Subtitle>
            <Item>{offer.campaign_tracking_settings?.tracking_clickid_parameter}</Item>
        </>
    )
}

export default DomainIdParameter