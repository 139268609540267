import React, {useState} from 'react'

import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {Wrapper, ApprovalHoverItem, HoverContainer, SelectedOffersCount, ActionHoverNotShown} from "./Actions.style";
import OffersService, {IFetchAllTagsResponse, IFetchCampaignsResponse, ITag} from "../Offers.service";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import {tokenStore} from "../../../../../../store/auth.slice";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import AddTagsModal from "./add-tags-modal/AddTagsModal";
import Modal from "../../../../../../common/components/modal/Modal";
import EditTagsModal from "./edit-tags-modal/EditTagsModal";
import RemoveAllTagsModal from "./remove-all-tags-modal/RemoveAllTagsModal";
import AlertModal from "../../../../../../common/components/alert-modal/AlertModal";

interface Props {
    tags: IFetchAllTagsResponse[],
    fetchData: () => void,
    selected_offers: IFetchCampaignsResponse[]
    deselectRows: ()=> void,
}

interface IAlertModal {
    opened: boolean,
    message: string,
    type: 'success' | 'error'
}

const Actions: React.FC<Props> = ({tags, fetchData, selected_offers, deselectRows}) => {

    const [isActionOpened, setIsActionsOpened] = useState(false)
    const [isAddTagsModalOpened, setIsAddTagsModalOpened] = useState(false)
    const [isEditTagsModalOpened, setIsEditTagsModalOpened] = useState(false)
    const [isRemoveAllTagsModalOpened, setIsRemoveAllTagsModalOpened] = useState(false)

    const [alertModal, setAlertModal] = useState<IAlertModal>(
        {
            opened: false,
            message: '',
            type: 'success'
        }
    )

    const token = useAppSelector(tokenStore)

    const handleOfferApprovalSelect = async (type: 'rep' | 'media' | 'compliance') => {
        setIsActionsOpened(false)
        await OffersService.campaignSectorApprove([...selected_offers.map(offer => offer.id)], type, token)
    }

    const handleRemoveAllTags = async () => {
        let tps: ITag[] = []
        selected_offers.forEach(offer => {
            tps = [...tps, ...offer.tags]
        })
        const params = {
            tps
        }
        await OffersService.deleteTags(token, params)
    }

    const tooltipContainerStyle: React.CSSProperties = {
        transform: 'translate(-20px, 20px)',
        fontSize: '12px',
    }

    return <Wrapper>
        <Dropdown
            headerClassName={isActionOpened ? 'actions-toggle-button active' : 'actions-toggle-button'}
            containerClassName={'actions-container'}
            disabled={selected_offers.length === 0}
            text={<div>
                Actions
                {selected_offers.length > 0 && (
                    <Tooltip
                        width={194}
                        position={'bottom'}
                        icon={
                            <SelectedOffersCount>{selected_offers.length > 99 ? '99+' :
                                selected_offers.length}<i className={'ico-Check'}/></SelectedOffersCount>
                        }
                        containerStyle={tooltipContainerStyle}
                    >
                        {selected_offers.slice(0, 5).map(offer => {
                            return (
                                <p key={offer.id} style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    marginBottom: '8px'
                                }}>{offer.id} - {offer.user_name} {offer.user_lastname}</p>
                            )
                        })}
                        {selected_offers.length > 5 && <ActionHoverNotShown>
                            {selected_offers.length - 5} not shown
                        </ActionHoverNotShown>}
                    </Tooltip>
                )}
            </div>}
            isOpened={isActionOpened}
            setOpened={setIsActionsOpened}
        >
            <ApprovalHoverItem>
                <span>Offer Approval</span>
                <i className={'ico-ChevronRight'}/>
                <HoverContainer className={'hover-container'} opened={true}>
                    <DropdownItem onClick={() => handleOfferApprovalSelect('rep')}>
                        Rep Approve
                    </DropdownItem>
                    <DropdownItem onClick={() => handleOfferApprovalSelect('media')}>
                        Media Approve
                    </DropdownItem>
                    <DropdownItem onClick={() => handleOfferApprovalSelect('compliance')}>
                        Compliance Approve
                    </DropdownItem>
                </HoverContainer>
            </ApprovalHoverItem>
            <div className={'item-separator'}></div>
            <DropdownItem disabled={selected_offers.length !== 1} onClick={() => setIsEditTagsModalOpened(true)}>
                Edit Tags
            </DropdownItem>
            <DropdownItem onClick={() => setIsAddTagsModalOpened(true)}>
                Add Tags to Selected
            </DropdownItem>
            <DropdownItem isDelete onClick={() => setIsRemoveAllTagsModalOpened(true)}>
                Remove All Tags from Selected
            </DropdownItem>
        </Dropdown>

        <Modal
            opened={isAddTagsModalOpened}
            title={'Add Tags to Selected Partner(s)'}
            closeModal={() => setIsAddTagsModalOpened(false)}
        >
            <AddTagsModal
                selected_offers={selected_offers}
                fetchData={fetchData}
                tags={tags}
                closeModal={() => setIsAddTagsModalOpened(false)}
                setAlert={setAlertModal}
                deselectRows= {deselectRows}
            />
        </Modal>

        <Modal
            opened={isEditTagsModalOpened}
            title={'Edit Tags'}
            closeModal={() => setIsEditTagsModalOpened(false)}
        >
            {selected_offers[0] &&
                <EditTagsModal
                    closeModal={() => setIsEditTagsModalOpened(false)}
                    fetchData={fetchData}
                    selectedOffer={selected_offers[0]}
                    tags={tags}
                    setAlert={setAlertModal}
                    deselectRows= {deselectRows}
                />
            }
        </Modal>
        <Modal
            opened={isRemoveAllTagsModalOpened}
            title={"Remove all tags from selected offers?"}
            closeModal={()=> setIsRemoveAllTagsModalOpened(false)}
        >
        <RemoveAllTagsModal
            closeModal={() => setIsRemoveAllTagsModalOpened(false)}
            deleteAction={handleRemoveAllTags}
            opened={isRemoveAllTagsModalOpened}
            setAlert={setAlertModal}
            fetchData={fetchData}
            deselectRows= {deselectRows}
        />
        </Modal>
        <AlertModal
            isOpen={alertModal.opened}
            type={alertModal.type}
            closeModal={() => setAlertModal({opened: false, message: '', type: 'success'})}
        >
            {alertModal.message}
        </AlertModal>
    </Wrapper>
}

export default Actions