import styled from "styled-components";

export const FlitersListItemBox = styled.div<{width: number}>`
    padding: 0 15px 5px;
    width: 215px;
    box-sizing: border-box;
    ${({width}) => width <= 775 && 
    `
    width: 100%;
    `
    }
    /* @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0 5px 5px;
    } */
`;