import React, {useState} from "react";

import {Wrapper, DataSourceInfoTooltip} from './PaidMedia.style'
import Dropdown from "../../../../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../../../../common/components/dropdown/Dropdown.style";
import {Label} from "../CreateNewFeeModal.style";
import {IPaidMediaProps} from "./paidMedia.props";
import Tooltip from "../../../../../../../../../common/components/tooltip/Tooltip";

const PaidMedia:React.FC<IPaidMediaProps> = ({dataSource, setDataSource}) => {
    const [isDatasourceDropdownOpened, setIsDatasourceDropdownOpened] = useState(false)

    const handleDatasourceSelect = (source: string) => {
        setDataSource(source)
        setIsDatasourceDropdownOpened(false)
    }

    return <Wrapper>
        <Label>
            Datasource
            <Tooltip position={'top'}>
                <DataSourceInfoTooltip>
                    <p>Select where the marketer's paid media data should be pulled from.</p>
                    <br/>
                    <p><strong>Note:</strong> If the data source that is required isn’t visible here, visit the home tab under edit marketer and add it in the data sources section.</p>
                </DataSourceInfoTooltip>
            </Tooltip>
        </Label>
        <Dropdown
            text={dataSource ? dataSource.charAt(0).toUpperCase() + dataSource.slice(1) : ''}
            isOpened={isDatasourceDropdownOpened}
            setOpened={setIsDatasourceDropdownOpened}
            placeholder={'Select Data Source'}
        >
            <DropdownItem
                onClick={() => handleDatasourceSelect('salesforce')}
                selected={dataSource === 'salesforce'}
            >
                Salesforce
            </DropdownItem>
        </Dropdown>
    </Wrapper>
}

export default PaidMedia