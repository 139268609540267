import React, {useEffect, useRef, useState, ReactElement} from 'react'

import {
    ClearSelected,
    Container,
    FilterChip,
    FilterSearch,
    IncludeExcludeItem,
    IncludeExcludeWrapper,
    ItemList,
    NotShown,
    TopStickyContainer,
    Wrapper,
} from "./Filter.style";
import {clearSelectedValues, IPagePresetsID, removeFilterChip,} from "../../../../store/userPreferences.slice";
import {FilterID} from "filters";
import {useAppDispatch, useOnClickOutside} from "../../../hooks";
import Dropdown from "../../dropdown/Dropdown";
import {DropdownItem} from "../../dropdown/Dropdown.style";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
interface IFilterProps {
    pageId: IPagePresetsID,
    filterId: FilterID,
    selected: any[],
    search?: {
        setSearch: (term: string) => void,
        value: string,
    },
    filterTitle: string,
    selectedFilterText: string,
    setDisplayDiscoverResults?: (inc: boolean) => void,
    notDisplayed: number,
    hasTopStickyContainer: boolean,
    containerClassName?: string,
    include?: {
        value?: boolean,
        setInclude: (value: boolean) => void
    } ,
    match?: {
        value?: 'any' | 'all' | 'empty',
        setMatch: (value: 'any' | 'all' | 'empty') => void
    } ,
    tooltip?: string | ReactElement | boolean,
}

const Filter: React.FC<IFilterProps> = ({
    pageId,
    filterId,
    selected,
    search,
    filterTitle,
    selectedFilterText,
    setDisplayDiscoverResults,
    notDisplayed,
    containerClassName,
    hasTopStickyContainer,
    include,
    match,
    children,
                                            tooltip
                                        }) => {

    const [opened, setOpened] = useState(false)
    const [matchOpened, setMatchOpened] = useState(false)
    const [hovered,setHovered] = useState(false)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const dispatch = useAppDispatch()

    const removeFilterIconRef = useRef<HTMLElement | null>(null)

    const handleOpenDropdown = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(e.target === removeFilterIconRef.current){
            return
        }
        setTimeout(() => {
            setOpened(!opened)
        })
    }

    const handleRemoveFilter = () => {
        ReactTooltip.hide()
        dispatch(removeFilterChip({pageId: pageId, filterId: filterId}))
        selected.length && setDisplayDiscoverResults && setDisplayDiscoverResults(true)
    }

    const handleClearSelected = () => {
        dispatch(clearSelectedValues({
            pageId,
            filterId
        }))
        setDisplayDiscoverResults && setDisplayDiscoverResults(true)
    }

    const handleIncludeSelect = (value: boolean) => {
        include && include.setInclude(value)
    }

    const handleMatchSelect = (value: 'any' | 'all' | 'empty') => {
        match && match.setMatch(value)
        setDisplayDiscoverResults && setDisplayDiscoverResults(true)
        setMatchOpened(false)
    }

    useOnClickOutside(wrapperRef, () => {
        search && search.setSearch('')
        setOpened(false)
    })
    const focusSearch = useRef<HTMLInputElement | null>(null)

    useEffect(()=>{
        focusSearch.current?.focus()
    }, [opened])

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[hovered])

    useEffect(()=>{
        ReactTooltip.rebuild()
    })

    return <Wrapper ref={wrapperRef}>
        <FilterChip
            $active={opened}
            onClick={(e) => handleOpenDropdown(e)}
            onLoad={()=>{
                ReactTooltip.rebuild()
            }}
            data-html={true}
            data-padding={'30px'}
            data-tip={selected.length > 1 ? ReactDOMServer.renderToStaticMarkup(
                 <div><div style={{maxWidth: 170}}>
                        {tooltip}
            </div></div>):''}
        >

            <span className={'title'}>
                {filterTitle}{selected.length > 0 && ':'}
            </span>

            {selected.length > 0 ? (
                <>
                    {include && <>{<span className={'include-exclude'}>{include.value ? 'Include' : 'Exclude'}</span>}</>}
                    {match && <span className={'include-exclude'}>
                        {match.value === 'any' ? 'matches any' : match.value === 'all' ? 'matches all' : 'is empty'}
                    </span>}
                    {
                        match?.value !== 'empty' && (selected.length === 1 ?
                            <span className={'selected'}>{selectedFilterText ? selectedFilterText : ''}</span> :
                            <span className={'selected'}>{selected.length} selected</span>)
                    }
                </>
            ) :  <span className={'include-exclude'}/>}
            <i className={'ico-AngleDown'}/>
            <i ref={removeFilterIconRef} className={'ico-Exit'} onClick={handleRemoveFilter}/>
        </FilterChip>
        <Container hasTopStickyContainer={hasTopStickyContainer}
            ref={containerRef}
            style={{
                maxHeight: selected.length > 0 ? '392px' : '344px',
                overflowY: match?.value === 'empty' ? 'visible' : 'auto'
            }}
            opened={opened}
            className={containerClassName}>
            {hasTopStickyContainer && <TopStickyContainer>
                {search && <FilterSearch
                    ref={focusSearch}
                    placeholder={'Search'}
                    onChange={(e) => {
                        search.setSearch(e.target.value);
                        // setFilterSearchValue(e.target.value)
                    }}
                    value={search.value}
                />}
                {include && <div className={'filter-type-wrapper'} >
                    {include &&
                        <IncludeExcludeWrapper>
                            <IncludeExcludeItem
                                onClick={() => {
                                    handleIncludeSelect(true);
                                    setDisplayDiscoverResults && setDisplayDiscoverResults(true)
                                }}
                                $active={include.value}
                            >
                                Include
                            </IncludeExcludeItem>
                            <IncludeExcludeItem
                                onClick={() => {
                                    handleIncludeSelect(false)
                                    setDisplayDiscoverResults && setDisplayDiscoverResults(true)}}
                                $active={!include.value}
                            >
                                Exclude
                            </IncludeExcludeItem>
                        </IncludeExcludeWrapper>
                    }
                    {match && <div className={'match-dropdown'}>
                        <Dropdown
                            containerClassName={'matches-container'}
                            headerClassName={'matches-header'}
                            text={match.value === 'any' ?
                                'matches any' : match.value === 'all' ?
                                    'matches all' : 'is empty'
                            }
                            isOpened={matchOpened}
                            setOpened={setMatchOpened}
                        >
                            <DropdownItem
                                onClick={() => handleMatchSelect('all')}
                                selected={match.value === 'all'}
                            >
                                matches all
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handleMatchSelect('any')}
                                selected={match.value === 'any'}
                            >
                                matches any
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handleMatchSelect('empty')}
                                selected={match.value === 'empty'}
                            >
                                is empty
                            </DropdownItem>
                        </Dropdown></div>}
                </div>}
            </TopStickyContainer>}
            {match?.value !== 'empty' && (
                <>
                    <ItemList>
                        {children}
                        {notDisplayed > 0 && <NotShown>{notDisplayed} not shown, use search.</NotShown>}
                    </ItemList>
                    {selected.length > 0 &&
                        <ClearSelected onClick={handleClearSelected}>
                            <span>Clear Selected</span>
                        </ClearSelected>}
                </>
            )}

        </Container>
    </Wrapper>
}

export default Filter