import { ReactNode, useEffect, useState } from "react";
import { Radio, RadioButtonLabel, RadioButtonWrapper } from "./RadioButton.style"

interface IRadioButtonProps {
    option: string,
    activeOption: string,
    setActiveOption: (activeOption: string) => void,
    children?: ReactNode | React.FC,
    label?: string,
}

const RadioButton = ({ option, activeOption, setActiveOption, children, label}:IRadioButtonProps) => {

    const [checked, setChecked] = useState(option === activeOption);

    useEffect(()=>setChecked(option === activeOption), [activeOption]);

    return(
        <>
        <RadioButtonWrapper onClick={()=>setActiveOption(option)}>
            <Radio $checked={checked}>
                {
                    checked && <div className="default-check"></div>
                }
            </Radio>
            {
                children ?
                    <RadioButtonLabel>
                        {children}
                    </RadioButtonLabel>
                :
                label ? 
                    <RadioButtonLabel>
                        {label}
                    </RadioButtonLabel>
                    :
                    null
            }
            {/* <RadioButtonLabel>
                {children ? children : label ? label : null}
            </RadioButtonLabel> */}
        </RadioButtonWrapper>
        </>
    )
}

export default RadioButton;