import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";

interface Props {}

const EmailTraffic: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(
        !offer.is_email ||
        navState['creatives-and-email'] === 'disabled'
    ) return null

    return (
        <>
            <div className={'email-creatives'}>
                <div>
                    <Subtitle>Email Traffic</Subtitle>
                    <Item>Yes</Item>
                </div>
                {offer.creatives.filter(creative => creative.is_email).length > 0 ? <div>
                    <Subtitle>Email Creatives</Subtitle>
                    <Item>{offer.creatives.filter(creative => creative.is_email).length}</Item>
                </div> : null}
            </div>
        </>
    )
}

export default EmailTraffic