import React, {useEffect, useRef, useState,useMemo} from 'react'
import qs from "qs";
import LeapPartnersService, {
    IFetchAllTagsResponse,
    IFetchLeapPartnersTableDataParams,
    IPartnerResponse,
    ITps
} from "./LeapPartners.service";

import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import Pagination from "../../../../../common/components/data-grid/pagination/Pagination";
import {setPerPage, setSearchValue} from "./LeapPartners.slice";
import Actions from "./actions/Actions";
import Search from '../../../../../common/components/search/Search';
import {
    BtnLoadingSpinner,
    ButtonStyled, InputStyled, InputStyledContainer, InputStyledLabel, TableToolbarBottom,
    TableToolbarTop,
    TableToolbarTopBottomRow,
    TableToolbarTopUpperRow, Tag, TableWrapper
} from '../../../../../common/styled-components';
import Icon from '../../../../../common/components/icon/Icon';
import Filters from './filters/Filters';
import {DeleteDropdownItem, DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import DataGrid from "../../../../../common/components/data-grid/DataGrid";
import {
    ColDef,
    Column,
    ColumnApi,
    ColumnState,
    GridApi,
    GridReadyEvent,
    IHeaderParams,
    RowClickedEvent
} from "ag-grid-community";
import {
    IWithUserPreferencesProps,
    withUserPreferences
} from "../../../../../common/hoc/withUserPreferences/withUserPreferences";
import {
    IPagePresetsID,
    saveUserPreferences,
    saveUserTablePreferences
} from "../../../../../store/userPreferences.slice";
import GridSortableHeader from "../../../../../common/components/data-grid/grid-sortable-header/GridSortableHeader";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";
import GridRowOptions from "../../../../../common/components/data-grid/grid-row-options/GridRowOptions";
import {
    CloneModalWrapper,
    ErrorMsg,
    LeapPartnersWrapper,
    RatingStar,
    RatingStarFiled,
} from "./LeapPartners.style";
import {format} from "date-fns";
import {getStatus} from "../../../../../common/utils";
import {withPageContainer} from "../../../../../common/hoc/withPageContainer/withPageContainer";
import Modal from "../../../../../common/components/modal/Modal";
import AddTagsModal from "../../../../../common/components/tags/add-tags-modal/AddTagsModal";
import RemoveTagsModal from "../../../../../common/components/tags/remove-tags-modal/RemoveTagsModal";
import FiltersService from "../../../../../common/services/filters.service";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";
import axios, {CancelTokenSource} from "axios";
import {validateEmail} from "../../../../../common/utils/validateEmail";

interface IMap {
    [key: string]: IPartnerResponse[]
}

interface IExportAlertModal {
    opened: boolean,
    type: 'success'  | 'error',
    message: string
}

interface ILocalSort {
    order: string | null,
    sort_by: string | null
}

const targetOrigin = process.env.REACT_APP_API_BASE_URL;

const LeapPartners: React.FC<IWithUserPreferencesProps> = ({
                                                               filterPreferences,
                                                               tablePreferences,
                                                               saveTablePreferences
                                                           }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenStore);
    const pageId: IPagePresetsID = 'admin/users/partners';

    const filterRef = useRef(filterPreferences)
    const [loading, setLoading] = useState<boolean>(true);
    const [discoverDisplayed, setDiscoverDisplayed] = useState<boolean>(false);
    const {
        searchValue,
        per_page
    } = useAppSelector(state => state.leapPartners);
    //Pagination data
    const [page, setPage] = useState<number>(1);
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const [lastPage, setLastPage] = useState<number>(0);

    // modals functions
    const [isAddTagModalOpened, setIsAddTagModalOpened] = useState(false);
    const [isEditTagsModalOpened, setIsEditTagsModalOpened] = useState(false);
    const [isRemoveTagModalOpened, setIsRemoveTagModalOpened] = useState(false)
    const [addTag, setAddTag] = useState<boolean>(false)
    const [addedTags, setAddedTags] = useState<IFetchAllTagsResponse[]>([])
    const [tags, setTags] = useState<IFetchAllTagsResponse[]>([])

    // table api
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
    const [gridApi, setGridApi] = useState<GridApi>();

    // selected checkboxes
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState<IMap>()

    const [paginationV2, setPaginationV2] = useState({
        page: page,
        per_page:per_page
    });

    const cancelTokenSource = useRef<CancelTokenSource | undefined>();

    const setSearch = (input: string) => {
        cancelTokenSource.current?.cancel();
        dispatch(setSearchValue(input));
    }

    const partnerNameTooltipStyle: React.CSSProperties = {
        padding: '8px 12px'
    };

    const paymentCycles = [
        {id: "twice_weekly", name: "Twice weekly"},
        {id: "weekly", name: "Weekly"},
        {id: "semi_monthly", name: "Bi-Weekly"},
        {id: "monthly_net_7", name: "Monthly Net 7"},
        {id: "monthly_net_15", name: "Monthly Net 15"},
        {id: "monthly", name: "Monthly Net 30"}
    ];

    const [exportCsvAlertModal, setExportCsvAlertModal] = useState<IExportAlertModal>({
        opened: false,
        message: '',
        type: 'success'
    })

    interface IClonePartnerModal {
        data: IPartnerResponse | null,
        opened: boolean,
        error: boolean,
        inputValue: string,
        loading: boolean
    }


    const getPaymentCycleValue = (id: string) => {
        if(paymentCycles.filter(cycle => cycle.id === id)[0]){
            return paymentCycles.filter(cycle => cycle.id === id)[0].name
        } else return ''
    };

    const onRowSelected = (e : RowClickedEvent) => {
        /**
         * this function will fire on every checkbox selection (even on select all checkbox)
         * logic below checks for  selected rows through pages including selected rows from search
         * filter function remove duplicated rows per page.
         * for large number of selection it has small delay on actions button (where number of selected rows is
         * displayed)
         * TODO: optimization
         */
        if(selectedRowsPerPage){
            let rowSelected = e.node.isSelected()
            let selectedRowId = e.data.id
            let selectedRows = selectedRowsPerPage[page.toString()]
            if (!selectedRows){
                selectedRows = []
            }
            if (rowSelected){
                selectedRows.push(e.data)
                selectedRows = selectedRows.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                )
            } else {
                selectedRows = selectedRows.filter((item)=>{
                    return item.id !== selectedRowId
                })
            }
            let tempRows = {...selectedRowsPerPage}
            tempRows[page] = selectedRows
            setSelectedRowsPerPage(tempRows)
        } else{
            setSelectedRowsPerPage({
                [page] : e.api.getSelectedRows()
            })
        }
    };

    useEffect(() => {
        if (Object.keys(filterPreferences).length < Object.keys(filterRef.current).length) {
            //stop displaying of discover button if an empty filter gets removed
            //find the key which is missing in filterPreferences against filterRef and check did it had any selected items
            let removedFilterKey = Object.keys(filterRef.current).filter(x => !Object.keys(filterPreferences).includes(x)).pop();
            filterRef.current[removedFilterKey!].selected.length && setDiscoverDisplayed(true)
        }
    }, [
        filterPreferences
    ])

    const setFilterParams = (direction?: string | null, sort_by?: string | null, export_type?:string | null) => {
        let params: IFetchLeapPartnersTableDataParams = {
            with_link_count: true,
            with_w8w9_status: true,
            user_type: 'affiliate',
            with_concat_networks: 'all',
            original_match: 1,
            paginate_by: paginationV2?paginationV2.per_page:per_page,
            page: paginationV2?paginationV2.page:page,
            sort_by: sort_by ? sort_by : 'created_at',
            order: direction ? direction : 'desc',
            q:searchValue
        }

        if(export_type)
            params.export_type = export_type

        if(filterPreferences['account-manager'] && filterPreferences['account-manager'].selected.length > 0) {
            params = {
                ...params,
                account_manager: {
                    ids: [...filterPreferences['account-manager'].selected.map(selected => selected.id)],
                    include: filterPreferences['account-manager'].include
                }
            }
        }

        if(filterPreferences['partner-type'] && filterPreferences['partner-type'].selected.length > 0 && filterPreferences['partner-type'].selected[0].id !== "all") {
            params = {
                ...params,
                subtype: {
                    ids: [...filterPreferences['partner-type'].selected.map(selected => selected.id)],
                    include: filterPreferences['partner-type'].include
                }
            }
        }

        if(filterPreferences['partner-ratings'] && filterPreferences['partner-ratings'].selected.length > 0) {
            params = {
                ...params,
                partner_rating: {
                    ids: [...filterPreferences['partner-ratings'].selected.map(selected => selected.id)],
                    include: filterPreferences['partner-ratings'].include
                }
            }
        }

        if(filterPreferences['payment-preference'] && filterPreferences['payment-preference'].selected.length > 0) {
            params = {
                ...params,
                partner_payment_preference: {
                    ids: [...filterPreferences['payment-preference'].selected.map(selected => selected.value)],
                    include: filterPreferences['payment-preference'].include
                }
            }
        }

        if(filterPreferences['partner-status-multiselect'] && filterPreferences['partner-status-multiselect'].selected.length > 0) {
            params = {
                ...params,
                status: {
                    ids: [...filterPreferences['partner-status-multiselect'].selected.map(selected => selected.status_name)],
                    include: filterPreferences['partner-status-multiselect'].include
                }
            }
        }

        if(filterPreferences['partner-registration-status'] && filterPreferences['partner-registration-status'].selected.length > 0) {
            params = {
                ...params,
                cpc_status: {
                    ids: [...filterPreferences['partner-registration-status'].selected.map(selected => selected.id)],
                    include: filterPreferences['partner-registration-status'].include
                }
            }
        }

        if(filterPreferences['partner-payment-status'] && filterPreferences['partner-payment-status'].selected.length > 0) {
            params = {
                ...params,
                partner_payment_status: {
                    ids: [...filterPreferences['partner-payment-status'].selected.map(selected => selected.id)],
                    include: filterPreferences['partner-payment-status'].include
                }
            }
        }

        if(filterPreferences['partner-payment-cycle'] && filterPreferences['partner-payment-cycle'].selected.length > 0) {
            params = {
                ...params,
                partner_payment_cycle: {
                    ids: [...filterPreferences['partner-payment-cycle'].selected.map(selected => selected.id)],
                    include: filterPreferences['partner-payment-cycle'].include
                }
            }
        }

        if(filterPreferences['referrer'] && filterPreferences['referrer'].selected.length > 0) {
            params = {
                ...params,
                referrer: {
                    ids: [...filterPreferences['referrer'].selected.map(selected => selected.id)],
                    include: filterPreferences['referrer'].include
                }
            }
        }
        if (filterPreferences['tags'] && filterPreferences['tags'].selected.length > 0) {
            params = {
                ...params,
                tags: {
                    ids: filterPreferences['tags'].match !== 'empty' ? [...filterPreferences['tags']
                        .selected.map(selected => selected.id)] : null,
                    include: filterPreferences['tags'].include,
                    match: filterPreferences['tags'].match
                }
            }
        }
        if(filterPreferences['approved-w8-w9'] && filterPreferences['approved-w8-w9'].selected.length > 0) {
            params = {
                ...params,
                partner_has_w8: {
                    ids: [...filterPreferences['approved-w8-w9'].selected.map(selected => selected.id)],
                    include: filterPreferences['approved-w8-w9'].include
                }
            }
        }

        return params;
    };

    const getSelectedRows = () => {
        if(selectedRowsPerPage){
            const selectedValues = Object.values(selectedRowsPerPage)
            return selectedValues.reduce(function(prev, next) {
                return prev.concat(next);
            });
        } else {
            return []
        }
    }
    const openAddTagsModal = () => {
        setAddTag(true)
        setAddedTags([])
        setIsAddTagModalOpened(true)
    }

    const openEditTagsModal = () => {
        setAddTag(false)
        let tags: IFetchAllTagsResponse[] = getSelectedRows()[0].tags && getSelectedRows()[0].tags.map((tag: IFetchAllTagsResponse) => {
            return {id: tag.id, name: tag.name}
        })
        setAddedTags(tags)
        setIsEditTagsModalOpened(true)
    }

    const openRemoveTagsModal = () => {
        setIsRemoveTagModalOpened(true)
    }

    const fetchData = async (direction?: string | null, sort_by?: string | null) => {
        try {
            setLoading(true)
            gridApi?.showLoadingOverlay()
            setDiscoverDisplayed(false)
            const params = setFilterParams(direction, sort_by)
            cancelTokenSource.current = axios.CancelToken.source()
            const {data} = await LeapPartnersService.fetchLeapPartnersTableData(params,token, cancelTokenSource.current)
            //handles page setting logic based on total number of returned records and set per page
            let calculatedPage = data.current_page;
            if(calculatedPage>=1)
            {
                for(let i=0; calculatedPage-1 > 0 && (calculatedPage * per_page) - per_page > data.total; i++)
                {
                    calculatedPage = calculatedPage - 1;
                }
                if(paginationV2 && paginationV2.page!=calculatedPage)
                    setPaginationV2 && setPaginationV2({
                        ...paginationV2,
                        page: calculatedPage
                    });
            }
            setPage(data.current_page)
            gridApi?.setRowData(data.data)
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLoading(false);
            setLastPage(data.last_page);
            dispatch(setPerPage(data.per_page))
            gridApi?.forEachNode((node) => {
                node.setSelected( getSelectedRows().some((row : IPartnerResponse) => {
                        return node.data.id === row.id
                    }
                ))
            })
        } catch (e) {
            console.log(e)
        }
    };

    const fetchTags = async () => {
        let {data} = await FiltersService.fetchTags(token)
        setTags(data)
    }

    useEffect(() => {
        if (token && gridColumnApi) {
            cancelTokenSource.current?.cancel();
            const direction = gridColumnApi.getColumnState().filter(column => column.sort !== null)[0].sort
            const sort_by = gridColumnApi.getColumnState().filter(column => column.sort !== null)[0].colId
            fetchData(direction, sort_by);
        }
    }, [token, paginationV2,gridColumnApi]);

    useEffect(()=>{
        cancelTokenSource.current?.cancel();
        fetchData()
    }, [searchValue])


    useEffect(() => {
        if (token) {
            cancelTokenSource.current?.cancel();
            fetchTags()
        }
    }, [token]);

    const orderColumns = (columns: ColumnState[]): ColumnState[] => {
        const newColState = [] as ColumnState[]
        if (columns) {

            columns.forEach(column => {
                if (column.pinned === 'left') {
                    newColState.push(column)
                }
            })
            columns.forEach(column => {
                if (column.pinned !== 'left') {
                    newColState.push(column)
                }
            })

        }
        return newColState

    }

    const onGridReady = (api: GridReadyEvent) => {
        setGridApi(api.api)
        setGridColumnApi(api.columnApi)
        if (tablePreferences.length > 0) {
            api.columnApi.applyColumnState({state: orderColumns(tablePreferences)})
            api.columnApi.moveColumns(orderColumns(tablePreferences).map(column => column.colId) as string[],0)
        } else {
            saveTablePreferences(api.columnApi.getColumnState())
        }
    }

    const handleEnableAllColumns = () => {
        if ( gridColumnApi ){
            gridColumnApi.setColumnsVisible(gridColumnApi.getAllColumns() as Column[], true)
            dispatch(saveUserTablePreferences({pageId: 'admin/users/partners', data: gridColumnApi.getColumnState()}))
        }
        gridApi &&gridApi.hideOverlay()
    }

    function handleLoginAs(user: IPartnerResponse) {
        targetOrigin && window.parent.postMessage({id: 1, user, login_type: "partner"}, targetOrigin);
    }

    function handleViewEdit(user: IPartnerResponse) {
        targetOrigin && window.parent.postMessage({id: 2, user}, targetOrigin);
    }

    const handleDiscover = () => {
        if (page === 1)
        {
            let sortedColumn = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0];
            const direction = sortedColumn?.hide === false ? sortedColumn.sort : null;
            const sort_by = sortedColumn?.hide === false ? sortedColumn.colId : null;
            fetchData(direction, sort_by);
        }else
            setPage(1)
        dispatch(saveUserPreferences(pageId))
        filterRef.current = filterPreferences
    }

    const exportCsv = async () => {
        try{
            let params = setFilterParams(null, null, 'affiliate_tab');
            let columns: string[] = []
            let columnsNumber: number = 0
            if (tablePreferences) {
                if (tablePreferences.filter((col: ColumnState) => col.hide === true).length >= 18) {
                    alert("No columns selected. Please select at least one column")
                }
            }
            tablePreferences?.forEach((column: ColumnState) => {
                    if (column.colId !== 'check' && column.colId !== 'option' && column.hide === false) {
                        columnsNumber++
                        column.colId && columns.push(column.colId)
                    }
                }
            )
            if (columnsNumber !== 18) {
                params = {
                    ...params,
                    columns: columns
                }
            }

            const {data} = await LeapPartnersService.exportToCsv(token, params)

            if(data.export_to_email){
                setExportCsvAlertModal({
                    opened: true,
                    message: "Your export will be sent to your email!",
                    type: 'success'
                })
            } else {
                window.open([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    'export'].join("/") + "?" + qs.stringify(params))
            }

        } catch (e) {
            console.log(e)
            setExportCsvAlertModal({
                opened: true,
                message: "Something went wrong!",
                type: 'error'
            })
        }

    }

    const closeRemoveTagsModal = () => {
        setIsRemoveTagModalOpened(false)
    }

    const [clonePartnerModal, setClonePartnerModal] = useState<IClonePartnerModal>({
        data: null,
        opened: false,
        error: false,
        inputValue: '',
        loading: false
    })

    const handleOpenClonePartnerModal = (partner: IPartnerResponse) => {
        setClonePartnerModal({
            data: partner,
            opened: true,
            error: false,
            inputValue: '',
            loading: false
        })
    }

    const closeAddOrEditTagsModal = () => {
        if (addTag) {
            setIsAddTagModalOpened(false)
        } else {
            setIsEditTagsModalOpened(false)
        }
    }

    const tagsForRemove = () => {
        interface TempTags {
            property_id: number,
            tps_id: number,
            id: number,
            name: string,
            is_selected?: boolean
        }
        const tps = [] as TempTags[]
        getSelectedRows().forEach((item: IPartnerResponse) => {
            item.tags.forEach((tag: ITps) => {
                let tmp: TempTags = {
                    property_id: item.id,
                    tps_id: tag.tps_id,
                    id: tag.id,
                    name: tag.name,
                    is_selected: true
                }
                tps.push(tmp)
            })
        })
        return {
            tps: tps
        }
    }

    const removeTags = async () => {
        await LeapPartnersService.deleteTags(token, tagsForRemove())
        let sortedColumn = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0];
        const direction = sortedColumn?.hide === false ? sortedColumn.sort : null;
        const sort_by = sortedColumn?.hide === false ? sortedColumn.colId : null;
        await fetchData(direction, sort_by);
        setSelectedRowsPerPage({"":[]})
        gridApi?.deselectAll()
    }

    const saveTags = async (selectedTagsNum: number[]) => {
        if (!addTag) {
            await LeapPartnersService.deleteTags(token, tagsForRemove())
        }
        const params = {
            property_ids: getSelectedRows().map(partner => partner.id),
            type: 'affiliate',
            tag_ids: selectedTagsNum
        }

        await LeapPartnersService.addTags(token, params)
        const direction = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].sort
        const sort_by = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].colId
        await fetchData(direction, sort_by);
        setSelectedRowsPerPage({"":[]})
        gridApi?.deselectAll()
    }

    //Define columns for the Advertisers table
    const defaultColumnDefs = useMemo<ColDef>(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const handleCloneModalSubmit = async () => {
        try {
            setClonePartnerModal({
                ...clonePartnerModal,
                loading: true
            })
            cancelTokenSource.current = axios.CancelToken.source();
            clonePartnerModal.data && await LeapPartnersService.clonePartner(token, clonePartnerModal.inputValue, clonePartnerModal.data?.id, cancelTokenSource.current)
            setClonePartnerModal({
                data: null,
                opened: false,
                error: false,
                inputValue: '',
                loading: false
            })
            const direction = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].sort
            const sort_by = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].colId
            fetchData(direction, sort_by);
        } catch (e) {
            setClonePartnerModal({
                data: null,
                opened: false,
                error: false,
                inputValue: '',
                loading: false
            })
            console.log(e)
        }
    }

    const handleCloneModalInputChange = (value: string) => {

        setClonePartnerModal({
            ...clonePartnerModal,
            inputValue: value,
            error: value.length > 0 && (value === clonePartnerModal.data?.email || !validateEmail(value))
        })
    }
    const handleCloseClonePartnerModal = () => {
        setClonePartnerModal({
            data: null,
            opened: false,
            error: false,
            inputValue: '',
            loading: false
        })
        cancelTokenSource.current?.cancel();
    }
    const columnDefs = useMemo<ColDef[]>(() => [
        {
            colId: "check",
            resizable: false,
            pinned: "left",
            lockPinned: true,
            lockPosition: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 50,
        },
        {
            pinned: "left",
            headerName: 'Partner ID',
            sortable: true,
            colId: "id",
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER ID</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IPartnerResponse }) => {
                return <span>{data.id}</span>
            }
        },
        {
            headerName: 'Rating',
            sortable: true,
            colId: "rating",
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                const stars: number[] = [0, 0, 0]
                return stars.map((star, index) => {
                    if(index < data.rating){
                        return <RatingStarFiled key={index} className="material-symbols-outlined">star</RatingStarFiled>
                    } else {
                        return <RatingStar key={index} className="material-symbols-outlined">star</RatingStar>
                    }
                })
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >RATING</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Partner Company Name',
            sortable: true,
            pinned: "left",
            colId: "company",
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <span>{data.company}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER COMPANY NAME</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Partner Name',
            sortable: true,
            colId: "name",
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <div className="partner-field" >
                    <span className="name">{data.name} {data.lastname}</span>
                    <div className={'tooltip'}>
                        <Tooltip
                            delay={400}
                            position="top"
                            width={105}
                            positionLeft={0}
                            positionTop={-4}
                            containerStyle={partnerNameTooltipStyle}
                            icon={
                                <span className={'icon'} style={{fontSize: "16px"}}><i className="ico-Download" onClick={() => handleLoginAs(data)}/></span>
                            }
                        >
                            <span style={{
                                fontSize: '12px',
                                position: 'relative',
                                zIndex: 20
                            }}>
                                    Log in as Partner
                            </span>

                        </Tooltip>
                    </div>
                </div>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER NAME</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Partner Account Manager',
            sortable: true,
            colId: "executive_id",
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <span>{data.executive?.name} {data.executive?.lastname}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER ACCOUNT MANAGER</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Date Created',
            sortable: true,
            colId: "created_at",
            sort: 'desc',
            comparator: () => 0,
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <span>{format(new Date(data.created_at.replaceAll("-", "/")), 'MM/dd/yyyy')}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >DATE CREATED</GridSortableHeader>
                </div>
            },
        },
        {
            headerName: 'Partner Type',
            colId: 'subtype',
            sortable: true,
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <span>{data.subtype === 'media_buyer' ? 'Media Buyer' : data.subtype === 'mailer' ? 'Mailer' : ''}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER TYPE</GridSortableHeader>
                </div>
            },
        },
        {
            headerName: 'Partner Status',
            colId: 'status',
            sortable: true,
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                const status = getStatus(data, false)
                return <span>{status && status.slice()[0].toUpperCase() + status.slice(1)}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PARTNER STATUS</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Registration Status',
            colId:'cpc_status',
            sortable: true,
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                if(data.cpc_status === ''){
                    return <span>-</span>
                }
                return <span>{data.cpc_status.includes('Denied') ? data.cpc_status : data.cpc_status.slice()[0].toUpperCase() + data.cpc_status.slice(1)}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >REGISTRATION STATUS</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Links',
            colId:'link_count',
            sortable: true,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >LINKS</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IPartnerResponse }) => {
                return <span>{data.link_count}</span>
            }
        },
        {
            headerName: 'Payment Cycle',
            sortable: true,
            colId:'payment_cycle',
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return <span>{getPaymentCycleValue(data.payment_cycle)}</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'>
                    <GridSortableHeader
                        headerParams={data}
                    >PAYMENT CYCLE</GridSortableHeader>
                </div>
            }
        },
        {
            headerName: 'Partner Tags',
            sortable: true,
            colId: 'tag',
            cellRenderer: ({data}: {data: IPartnerResponse}) => {
                return data.tags.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
            },
            headerComponent: () => {
                return <div className='header-custom'>
                    <span>PARTNER TAGS</span>
                </div>
            }
        },
        {
            cellRenderer: ({data}:{data: IPartnerResponse}) => {
                return <GridRowOptions>
                    <DropdownItem onClick={() => handleLoginAs(data)}>Log in as Partner</DropdownItem>
                    <DropdownItem onClick={() => handleViewEdit(data)}>Edit Partner Details</DropdownItem>
                    <DropdownItem onClick={() => handleOpenClonePartnerModal(data)}>Clone Partner</DropdownItem>
                </GridRowOptions>
            },
            pinned: 'right',
            width: 50,
            resizable: false

        }
    ], [])

    return (
        <LeapPartnersWrapper>
            <TableWrapper>
                <TableToolbarTop>
                    <TableToolbarTopUpperRow>
                        <Actions selectedRows={getSelectedRows().map((item: IPartnerResponse) => {
                            return {
                                displayName: item.name + ' '+item.lastname,
                                id: item.id,
                            }
                        })}>
                    <DropdownItem onClick={openEditTagsModal}
                                  className={getSelectedRows().length === 0 || getSelectedRows().length > 1 ?
                                      "disabled" : ""}>
                        Edit Tags
                    </DropdownItem>
                    <DropdownItem onClick={openAddTagsModal}>
                        Add Tags to Selected
                    </DropdownItem>
                    <DeleteDropdownItem onClick={openRemoveTagsModal}>
                        Remove All Tags from Selected
                    </DeleteDropdownItem>

                </Actions>
                <Search width={30}
                        wrapperClassName={"table-search"}
                        onChange={setSearch}
                        initialValue={searchValue}/>
                {
                    discoverDisplayed &&
                    <ButtonStyled onClick={() => handleDiscover()} className={'btn-discover-results'}>
                        Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/>
                    </ButtonStyled>
                }
            </TableToolbarTopUpperRow>
            <TableToolbarTopBottomRow>
                <Filters setDiscoverDisplayed={setDiscoverDisplayed}/>
                <Pagination loading={loading}
                            exportCsv={exportCsv}
                            current_page={page}
                            from={from}
                            to={to}
                            total={total}
                            last_page={lastPage}
                            setCurrentPage={setPage}
                            perPage={per_page}
                            setPerPage={(n: number) => dispatch(setPerPage(n))}
                            onRefresh={()=>{
                                let sortedColumn = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0];
                                const direction = sortedColumn?.hide === false ? sortedColumn.sort : null;
                                const sort_by = sortedColumn?.hide === false ? sortedColumn.colId : null;
                                fetchData(direction, sort_by);
                            }}
                            gridColumnApi={gridColumnApi}
                            gridApi={gridApi}
                            id={'admin/users/partners'}
                            paginationV2={paginationV2}
                            setPaginationV2={setPaginationV2}
                            sortBy={"created_at"}
                            direction={"desc"}
                />
            </TableToolbarTopBottomRow>
        </TableToolbarTop>
        <DataGrid
            onRowSelected={(e)=> onRowSelected(e)}
            fixedScroll={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefs}
            rowHeight={40}
            domLayout='autoHeight'
            loading={loading}
            suppressRowClickSelection={true}
            onSortChanged={(e) => {
                let sortedColumn = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0];
                const direction = sortedColumn?.hide === false ? sortedColumn.sort : null;
                const sort_by = sortedColumn?.hide === false ? sortedColumn.colId : null;
                cancelTokenSource.current?.cancel();
                fetchData(direction, sort_by)
                if(JSON.stringify(e.columnApi.getColumnState()) != JSON.stringify(tablePreferences))
                    saveTablePreferences(e.columnApi.getColumnState())
            }}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            onDragStopped={(api) => {
                saveTablePreferences(api.columnApi.getColumnState())
            }}
            allColumnsDisabled={tablePreferences.some(column => {
                return column.hide === false
            })}
            handleEnableAllColumns={handleEnableAllColumns}
        />
        <TableToolbarBottom>
            <Pagination loading={loading}
                        perPage={per_page}
                        current_page={page}
                        from={from} to={to}
                        total={total}
                        last_page={lastPage}
                        setCurrentPage={setPage}
                        setPerPage={(n: number) => dispatch(setPerPage(n))}
                        openUp={true}
                        paginationV2={paginationV2}
                        setPaginationV2={setPaginationV2}
            />
        </TableToolbarBottom>
    </TableWrapper>
    <Modal opened={isAddTagModalOpened || isEditTagsModalOpened}
           title={addTag ? "Add Tags to selected Marketer(s)" : "Edit Tags"}
           closeModal={closeAddOrEditTagsModal}>
        <AddTagsModal addTag={addTag}
                      selectedUsers={getSelectedRows().map((item: IPartnerResponse) => {
                              return {id: item.id, name: item.name+' '+item.lastname}
                          }
                      )}
                      addedTags={addedTags}
                      tags={tags}
                      saveTags={saveTags}
                      closeModal={closeAddOrEditTagsModal}/>
    </Modal>
    <Modal opened={isRemoveTagModalOpened}
           title={"Remove all tags from selected Partner(s)?"}
           closeModal={closeRemoveTagsModal}>
        <RemoveTagsModal
            className="linebreak"
            content={"Are you sure you want to remove all tags from selected partners?\n\r" +
                "\n\r" +
                "This action cannot be undone."}
            removeTags={removeTags} closeModal={closeRemoveTagsModal}/>
    </Modal>
        <Modal opened={clonePartnerModal.opened} title={`Clone User ${clonePartnerModal.data?.name + ' ' + clonePartnerModal.data?.lastname}`} closeModal={handleCloseClonePartnerModal}>
            <CloneModalWrapper>
                <p>The new cloned account must have a different email address from the original. Enter the email address for the new account:</p>
                <InputStyledContainer className={clonePartnerModal.error ? 'has-error' : ''} style={{marginTop: '15px'}}>
                    <InputStyledLabel>Email</InputStyledLabel>
                    <InputStyled
                        placeholder={'Email'}
                        value={clonePartnerModal.inputValue}
                        onChange={(e) => handleCloneModalInputChange(e.target.value)}
                    />
                    {clonePartnerModal.error && <ErrorMsg>Email is invalid!</ErrorMsg>}
                </InputStyledContainer>

            </CloneModalWrapper>
            <ModalFooter>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <ButtonStyled onClick={handleCloseClonePartnerModal} className={'btn-cancel'}>Cancel</ButtonStyled>
                    <ButtonStyled
                        disabled={clonePartnerModal.error || clonePartnerModal.inputValue === '' || clonePartnerModal.loading}
                        className={'btn-orange-filled'}
                        onClick={handleCloneModalSubmit}
                    >
                        {clonePartnerModal.loading && <BtnLoadingSpinner style={{marginRight: '5px'}}/>}Clone
                    </ButtonStyled>
                </div>
            </ModalFooter>
        </Modal>
     <AlertModal isOpen={exportCsvAlertModal.opened} type={exportCsvAlertModal.type} closeModal={() => setExportCsvAlertModal({
            opened: false,
            message: '',
            type: 'success'
        })}>
            {exportCsvAlertModal.message}
     </AlertModal>
    </LeapPartnersWrapper>
            )
}

export default withUserPreferences('admin/users/partners', withPageContainer(LeapPartners));