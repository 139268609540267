import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

const partnerRegistrationStatuses: any[] = [
    {value: 'Pending', id: 'pending'},
    {value: 'Approved', id: 'approved'},
    {value: 'Business Denied', id: 'business_denied'},
    {value: 'Compliance Denied', id: 'compliance_denied'},
    {value: 'Not Requested', id: 'not_requested'}
];

export const usePartnerRegistrationStatusCacheFilter = (pageId: IPagePresetsID) => {
    const dispatch = useAppDispatch()

    const id: FilterID = 'partner-registration-status'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (status: any) => {
        if(filter.selected.some((selected)  => selected.value === status.value)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.value !== status.value)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, status]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: partnerRegistrationStatuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        }
    }
}