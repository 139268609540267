import styled from "styled-components";

export const PartnerTransactionsWrapper = styled.div`
    padding: 40px 40px 80px;

    .login-as-partner {
        color: #25C0D5;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            color: #EF4A24;
        }
    }

    .top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date-range-discover-results {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 24px;
        }
    }

    .pagination-add-transaction {
        display: flex;
        gap: 8px;

        @media screen and (max-width: 520px){
            flex-direction: column;
            gap: 15px;
        }
    }

    .filters-pagination {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media screen and (max-width: 1046px){
            flex-direction: column;
            gap: 24px;
        }
    }

    @media screen and (max-width: 970px) {
        padding: 40px 15px 80px;
    }
`;