import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IInitialState {
    view: 'agency' | 'network'
}

const initialState: IInitialState = {
    view: 'network'
}

const adminDashboardSlice = createSlice({
    name: 'adminDashboardSlice',
    initialState,
    reducers: {
        setView: (state, action: PayloadAction<'agency' | 'network'>) => {
            state.view = action.payload
        }
    }
})

export default adminDashboardSlice.reducer
export const {setView} = adminDashboardSlice.actions