import React, {useEffect, Dispatch, SetStateAction} from 'react';
import {IOffer} from "../../../interfaces/interfaces";
import {useAppSelector} from "../../../../../../../common/hooks";
import {ITestingPagination} from "./Testing";
import CreateOfferService from "../../../CreateOffer.service";


export interface Props {
    tableData: any[],
    setTableData: Dispatch<SetStateAction<any[]>>,
    setPagination: Dispatch<SetStateAction<ITestingPagination>>
    pagination: any,
    offer: IOffer,
    loadPrevious: boolean
}

export const Table: React.FC<Props> = ({
                                           tableData,
                                           setTableData,
                                           pagination,
                                           setPagination,
                                           offer,
                                           loadPrevious
                                       }) => {

    const {
        token
    } = useAppSelector(state => state.auth)

    const testTrackingPixel = async () => {
        try {
            if (token && offer.id) {
                const {data} = await CreateOfferService.testTrackingPixel(token, offer.id, loadPrevious)
                setTableData(data.items)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: data.items.filter((item: any) => item.type === 'click').length
                    }
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => testTrackingPixel(), 3000)
        return () => clearInterval(interval)
    }, [testTrackingPixel])

    return (
        <div className="gridtable">
            <table>
                <thead>
                <tr>
                    <th className={'right-border'}>CLICK INFO</th>
                    <th>CONVERSION INFO</th>
                </tr>
                </thead>
                <tbody>
                {tableData.length > 0 ?
                    tableData.filter(item => item.type === 'click').slice(pagination.from - 1, pagination.to).map(row => {
                        const conversions = tableData.filter((item: any) => item.tid === row.tid && (item.type === 'conversion'
                            || item.type === 'upsell-conversion'))
                        return <tr>
                            <td className={'right-border'}>
                                <p><span className={'conversion-info-label'}>Date/Time: </span>{row.click_info.date}</p>
                                <p><span className={'conversion-info-label'}>IP: </span>{row.ip}</p>
                                <p><span className={'conversion-info-label'}>Device Type: </span>{row.device_type}</p>
                                <p><span className={'conversion-info-label'}>UA: </span>{row.click_info['User-Agent']}
                                </p>
                                <p><span className={'conversion-info-label'}>ClickID: </span>{row.click_info.click_id}
                                </p>
                            </td>
                            <td>
                                {(conversions.length > 0 && row.type === 'click') &&
                                    <div className={'conversion-cell-wrapper'}>{
                                        conversions.map(conversion => {
                                            return <div className={'conversion-info-block'}>
                                                <p>
                                                    <span className={'conversion-info-label'}>Type:</span>
                                                    {conversion.type === 'conversion' ? conversion.shared_conversion ?
                                                            'Pixel Group Main Conversion' :
                                                            'Single Offer Main Conversion' :
                                                        `Upsell ${offer.upsells[conversion.upsell_id].name} 
                                                        ${conversion.shared_conversion ? 'Conversion' : 'Pixel Group  Conversion'}`
                                                    }
                                                </p>
                                                <p><span className={'conversion-info-label'}>Date/Time: </span>
                                                    {conversion.click_info.date}</p>
                                                <p><span className={'conversion-info-label'}>IP: </span>
                                                    {conversion.ip}</p>
                                                <p><span className={'conversion-info-label'}>Device Type: </span>
                                                    {conversion.device_type}</p>
                                                <p><span className={'conversion-info-label'}>Conversion Type: </span>
                                                    {conversion.conversion_type}</p>
                                                <p><span className={'conversion-info-label'}>UA: </span>
                                                    {conversion.click_info['User-Agent']}</p>
                                                <p><span className={'conversion-info-label'}>TransactionID: </span></p>
                                                <p><span className={'conversion-info-label'}>Traffic Type: </span>
                                                    {conversion.traffic_type === 2 ? 'Email' : 'Display'}</p>
                                                <p><span className={'conversion-info-label'}>Browser: </span>
                                                    {conversion.browser}</p>
                                                <p><span className={'conversion-info-label'}>OS: </span>{conversion.os}
                                                </p>
                                                <p><span className={'conversion-info-label'}>Pixel Type: </span>
                                                    {conversion.conversion_type && conversion.conversion_type}
                                                </p>
                                            </div>
                                        })}
                                    </div>
                                }
                            </td>
                        </tr>
                    }) : <tr>
                        <td>
                            <div className={'no-data'}>There is no data for this view</div>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    );
};