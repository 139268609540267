import React, {useEffect, useMemo, useState} from "react";

import {CellDetailsItem, CellToggle, DeleteDropdownItem, Separator, Tag, Wrapper, CellDetails} from "./Merged.style"
import FiltersRow from "../../../../../../common/components/filters/filters-row/FiltersRow";
import {
    FiltersRowLeft,
    FiltersRowRight
} from "../../../../../../common/components/filters/filters-row/FiltersRow.style";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import Grid from "../../../../../../common/components/grid/Grid";
import Search from "../../../../../../common/components/search/Search";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import {tokenStore} from "../../../../../../store/auth.slice";
import {useAppSelector} from "../../../../../../common/hooks";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import {TSortType} from "../../../../../../common/components/table/sortable-header-v2/sortableHeaderV2.props";
import Actions from "../../../../../../common/components/actions/Actions";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import Modal from "../../../../../../common/components/modal/Modal";
import AddTagsModal from "../../../../../../common/components/tags/add-tags-modal/AddTagsModal";
import {ITag} from "../../../../../../common/models/tag.type";
import _ from "lodash";
import MergingService, {
    IAssignTag,
    IMergedPartner,
    IMergedPartnerList,
    IMergedPartnersParams,
    IPartnerTrafficType,
    ITagNonMerged,
    PLATFORMS,
    SORT
} from "../Merging.service";
import FiltersService from "../../../../../../common/services/filters.service";
import RemoveTagsModal from "../../../../../../common/components/tags/remove-tags-modal/RemoveTagsModal";
import UnMergeModal from "../unmerge-modal/UnMergeModal";
import EditDetailsModal from "./edit-details-modal/EditDetailsModal";
import {CellClickedEvent} from "ag-grid-community";


const Merged : React.FC = () =>{
    const token = useAppSelector(tokenStore)

    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [lastPage, setLastPage] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<string>(SORT[0])
    const [order, setOrder] = useState<TSortType>('desc')
    const [isAddTagModalOpened, setIsAddTagModalOpened] = useState(false);
    const [isDeleteTagModalOpened, setIsDeleteTagModalOpened] = useState(false)
    const [isUnmergeModalOpened, setIsUnmergeModalOpened] = useState(false)
    const [isEditDetailsModalOpened, setIsEditDetailsModalOpened] = useState(false);

    const [tags, setTags] = useState<ITag[]>([])
    const [addTag, setAddTag] = useState<boolean>(false)
    const [addedTags, setAddedTags] = useState<ITag[]>([])

    const [selectedRows, setSelectedRows] = useState<IMergedPartner[]>([])

    const [tableData, setTableData] = useState<IMergedPartner[]>([])


    const indeterminate = () => {
        const list = selectedRows.filter((item: IMergedPartner) => tableData.some((it: IMergedPartner) => it.id === item.id));
        return list.length > 0 && list.length < tableData.length;
    }

    const selectAllRows = () => {
        const currentState = selectedRows.filter((item: IMergedPartner) => tableData.some((it: IMergedPartner) => it.id === item.id));
        if(currentState.length === 0) {
            setSelectedRows([...selectedRows, ...tableData])

        }
        if(currentState.length === tableData.length) {
            const newList = selectedRows.filter((item: IMergedPartner) => !tableData.some((it: IMergedPartner) => it.id === item.id));
            setSelectedRows(newList)
        }
    }

    const selectRow = (offer: IMergedPartner) => {
        if(!selectedRows.some((item: IMergedPartner) => offer.id === item.id)){
            setSelectedRows([...selectedRows, offer])
        } else {
            const newOffersList = selectedRows.filter((item: IMergedPartner) => item.id !== offer.id);
            setSelectedRows(newOffersList)
        }
    }

    const defaultColDef = useMemo(() => ({
        resizable: true,
        minWidth: 50,
        autoHeight: true
    }), [tableData])

    const columnDefs = useMemo(() =>[
        {
            pinned: "left", width: 50, headerComponent: () => {
                if(indeterminate()) {
                    const onChange = () => {
                        const newList = selectedRows.filter((item: IMergedPartner) => !tableData.some((it: IMergedPartner) => it.id === item.id))
                        setSelectedRows(newList)
                    }

                    return <Checkbox indeterminate={indeterminate()} checked={true} onChange={onChange} />
                }
                const check = selectedRows.filter((item: IMergedPartner) => tableData.some((it: IMergedPartner) => it.id === item.id)).length === tableData.length;
                return <Checkbox checked={check} onChange={() => selectAllRows()} />
            },
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <Checkbox
                        checked={selectedRows.some(selectedRow => selectedRow.id === data.id)}
                        onChange={() => selectRow(data)}/>
                </div>
            }
        },
        {
            field: "partner_id", headerName: "PARTNER ID", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <CellToggle>Multiple</CellToggle>
                    {data.partnerList && data.expanded &&
                        <CellDetails>
                            {data.partnerList.map((item: IMergedPartnerList)=> {
                                return (
                                    <CellDetailsItem key={item.merged_partner_id + Math.random()}>
                                        {item.platform_partner_id}
                                    </CellDetailsItem>
                                )
                            })}
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "partner_name", width: 300, colId: "partnerList",headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <CellToggle>
                        <i className={`${data.expanded ? "ico-ChevronUp" : "ico-ChevronDown"}`}/> {data.name} <i className={"ico-Connected"}/>
                    </CellToggle>
                    {data.partnerList && data.expanded &&
                        <CellDetails>
                            {data.partnerList.map((item: IMergedPartnerList)=> {
                                return (
                                    <CellDetailsItem style={{paddingLeft: 20}} key={item.merged_partner_id + Math.random()}>
                                        {item.platform_partner_name}{item.platform_partner_name ? ', ' : ''}{item.platform_partner_company}
                                    </CellDetailsItem>
                                )
                            })}
                        </CellDetails>
                    }
                </div>
            },
            headerComponent: (row: any) => {
                return <SortableHeaderV2
                    keyId={SORT[1]}
                    sortDirection={order}
                    sortedBy={sort}
                    onClick={(event: any) => {
                        setSort(SORT[1])
                        setOrder(order === "desc" ? "asc" : "desc")
                    }}
                >PARTNER NAME</SortableHeaderV2>
            }
        },
        {
            field: "platform", headerName: "PLATFORM", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <CellToggle>Multiple</CellToggle>
                    {data.partnerList && data.expanded &&
                        <CellDetails>
                            {data.partnerList.map((item: IMergedPartnerList)=> {
                                return (
                                    <CellDetailsItem key={item.merged_partner_id + Math.random()}>
                                        {PLATFORMS[item.platform_id]}
                                    </CellDetailsItem>
                                )
                            })}
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "number_of_partners", headerName: "# OF PARTNERS", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>{data.count}</div>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>{data.partner_traffic_type && data.partner_traffic_type[0].name}</div>
            }
        },
        {
            field: "id", headerName: "TAGS", width: 300,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return data.tags && data.tags.map((tag: ITagNonMerged) => {
                    return  <Tag key={tag.tag_id + tag.name + Math.random()}>{tag.name}</Tag>
                })
            }
        }
    ],[selectedRows, order, sort, tableData])

    const gridOptions = useMemo(() => ({
        columnDefs: columnDefs,
        defaultColDef: defaultColDef,
        onCellClicked: async (event: CellClickedEvent) => {
            if (event.colDef.colId === 'partnerList') {
                if (event.data.partnerList){
                    event.node.setData({ ...event.data, expanded: !event.data.expanded})
                }
                else {
                    const {data} = await MergingService.fetchMergedPartnersList(token, event.data.id)
                    event.node.setData({...event.data, partnerList: data, expanded: !event.data.expanded})
                }
            }
        },
    }), [tableData]);

    const exportCsv = async () => {
        let params: IMergedPartnersParams = {
            sort_by: sort,
            order: order,
            action: "export_count"
        }
        if (search !== "") params.q = search
        if (perPage !== 50) params.paginate_by = perPage
        if (currentPage !== 1) params.page = currentPage

        let {data} = await MergingService.fetchMergedPartners(token, params)

        let paramsTemp: {
            sort_by: string,
            order: string,
            action: string,
            q?: string,
            paginate_by?: string,
            page?: string
        } = {
            sort_by: sort,
            order: order,
            action: "export_csv"
        }
        if (search !== "") paramsTemp.q = search
        if (perPage !== 50) paramsTemp.paginate_by = perPage.toString()
        if (currentPage !== 1) paramsTemp.page = currentPage.toString()


        let urlParams = new URLSearchParams(paramsTemp)
        window.open([process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_MERGING_PARTNERS,
            process.env.REACT_APP_API_MERGED].join("/") + "?" + urlParams)
    }

    const fetchMergedPartnersTableData = async () => {
        setLoading(true)
        let params: IMergedPartnersParams = {
            sort_by: sort,
            order: order
        }
        if (search !== "") params.q = search
        if (perPage !== 50) params.paginate_by = perPage
        if (currentPage !== 1) params.page = currentPage
        let {data} = await MergingService.fetchMergedPartners(token, params);
        let rowData = data.data.map((item:IMergedPartner)=> {
            return {...item, expanded: false}
        })
        setTableData(rowData)
        setFrom(data.from)
        setLastPage(data.last_page)
        setTo(data.to)
        setTotal(data.total)
        setLoading(false)
    }

    const memoFetch = _.memoize(fetchMergedPartnersTableData)

    useEffect(()=> {
        setSort(SORT[0])
        setOrder('desc')
        memoFetch()
    },[search])

    useEffect(()=> {
        memoFetch()
    }, [perPage, currentPage, sort, order])

    const fetchTags = async () => {
        let {data} = await FiltersService.fetchTags(token)
        setTags(data)
    }
    useEffect(() => {
        fetchTags()
    }, [])

    const openAddTagsModal = () => {
        setAddTag(true)
        setAddedTags([])
        setIsAddTagModalOpened(true)
    }

    // <---------- EDIT DETAILS MODAL START --------->
    const [trafficTypeFromPartner, setTrafficTypeFromPartner] = useState<IPartnerTrafficType>({
        id: 0,
        name: "- Select traffic type -"
    })
    const openEditDetailsModal = () => {
        setAddTag(false)
        let tags: ITag[] = selectedRows[0].tags && selectedRows[0].tags.map((tag: ITagNonMerged) => {
            return {id: tag.tag_id, name: tag.name}
        })
        setAddedTags(tags)
        setIsEditDetailsModalOpened(true)
    }

    const closeEditDetailsModal = () => {
        setSelectedRows([])
        setIsEditDetailsModalOpened(false)
    }
    // <---------- EDIT DETAILS MODAL END --------->

    const openRemoveTagsModal = () => {
        setIsDeleteTagModalOpened(true)
    }

    const openUnMergeModal = () => {
      setIsUnmergeModalOpened(true)
    }

    const closeUnMergeModal = () => {
        setIsUnmergeModalOpened(false)
        setSelectedRows([])
    }

    const unMergePartners = async () => {
        let ids = selectedRows.map((row:IMergedPartner) => {
            return row.id
        })
        try {
            await MergingService.unMergePartners(token, ids)
            fetchMergedPartnersTableData()
        }
        catch (err) {
            console.log(err)
        }
    }

    const saveDetails = async (selectedTagsNum: number[]) => {
        let users: number[] = selectedRows.map((user: IMergedPartner) => {
            return user.id
        })
        let params: IAssignTag = {
            add: addTag,
            tags: selectedTagsNum,
            merged_partner_ids: users
        }
        await MergingService.addTags(token, params)
        await fetchMergedPartnersTableData()
        setSelectedRows([])
    }

    // Remove all tags from selected
    const removeTags = async () => {
        let ids: number[] = selectedRows.map((user: IMergedPartner) => {
            return user.id
        })
        let params: IAssignTag = {
            merged_partner_ids: ids
        }
        await MergingService.removeTags(token, params)
        await fetchMergedPartnersTableData()
        setSelectedRows([])
    }

    return (
        <Wrapper>
            <FiltersRow>
                <FiltersRowLeft>
                    <Actions selectedRows={selectedRows.map(item => {
                        return {
                            displayName: item.name,
                            id: item.id,
                            non_merged: false
                        }
                    })}>
                        <DropdownItem
                            onClick={openEditDetailsModal}
                            className={selectedRows.length === 0 || selectedRows.length > 1 ? "disabled" : ""}>
                            Edit Details
                        </DropdownItem>

                        <DropdownItem onClick={openAddTagsModal}>Add Tags to Selected</DropdownItem>

                        <DeleteDropdownItem onClick={openRemoveTagsModal}>
                            Remove All Tags from Selected
                        </DeleteDropdownItem>

                        <Separator/>

                        <DeleteDropdownItem onClick={openUnMergeModal}>
                            Un-Merge Selected
                        </DeleteDropdownItem>

                        <Modal opened={isAddTagModalOpened}
                               title={addTag ? "Add Tags to selected Partner(s)" : "Edit Tags"}
                               closeModal={() => setIsAddTagModalOpened(false)}>
                            <AddTagsModal
                                addTag={addTag}
                                addedTags={addedTags}
                                selectedUsers={selectedRows.map(user => {
                                    return user.id
                                })}
                                saveTags={saveDetails}
                                fetchData={fetchMergedPartnersTableData}
                                closeModal={() => setIsAddTagModalOpened(false)}
                                tags={tags}/>
                        </Modal>

                        <Modal opened={isDeleteTagModalOpened}
                               title={"Remove All Tags from Selected Partner(s)?"}
                               closeModal={() => setIsDeleteTagModalOpened(false)}>
                            <RemoveTagsModal
                                content={"Are you sure you want to remove all tags from the selected partner(s)? " +
                                    "This is a global change and will affect all users in the platform. " +
                                    "All reports and filters related to these partners will be updated " +
                                    "immediately upon removing the tags.\n" +
                                    "\n" +
                                    "This action cannot be undone."}
                                removeTags={removeTags}
                                fetchData={fetchMergedPartnersTableData}
                                closeModal={() => setIsDeleteTagModalOpened(false)}/>
                        </Modal>

                        <Modal
                            opened={isEditDetailsModalOpened} title={'Edit Details'}
                            closeModal={closeEditDetailsModal}>
                            <EditDetailsModal selectedPartners={selectedRows[0]}
                                              tags={tags}
                                              addedTags={addedTags}
                                              saveDetails={saveDetails}
                                              clearSelectedRow={()=> setSelectedRows([])}
                                              trafficTypeFromPartners={trafficTypeFromPartner}
                                              closeModal={() => setIsEditDetailsModalOpened(false)} />
                        </Modal>

                        <Modal
                            opened={isUnmergeModalOpened}
                            title={'Un-Merge Selected Partner(s)?'}
                            closeModal={()=> setIsUnmergeModalOpened(false)}>
                            <UnMergeModal
                                fetchData={fetchMergedPartnersTableData}
                                closeModal={closeUnMergeModal}
                                unMergePartners={unMergePartners} />
                        </Modal>
                    </Actions>
                    <Search onChange={setSearch}
                            initialValue=""
                            placeholder="Search by Partner Name, Company Name"/>
                </FiltersRowLeft>
                <FiltersRowRight>
                    <Pagination
                        current_page={currentPage}
                        from={from} to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        onRefresh={memoFetch}
                        exportCsv={exportCsv}
                        perPageCustomList={[5, 10, 20, 50]}
                    />
                </FiltersRowRight>
            </FiltersRow>
            <Grid
                className={'ag-grid-align-items-baseline'}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={tableData}
                rowHeight={40}
                domLayout='autoHeight'
                loading={loading}
                gridOptions={gridOptions}
            />
            <FiltersRow>
                <FiltersRowLeft />
                <FiltersRowRight>
                    <Pagination
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        perPageCustomList={[5, 10, 20, 50]}
                    />
                </FiltersRowRight>
            </FiltersRow>
        </Wrapper>
    )
}

export default Merged
