import React, {useState, useEffect} from "react";
import ReactDOMServer from "react-dom/server";
import {destinationParamsList, destinationParamsListTooltips} from "../shared-components/Shared";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import {useAppSelector} from "../../../../../common/hooks";

interface Params {
    onSelect: (macro: string) => void,
    mobileTS? : boolean
}

export const DestinationParamsSelect: React.FC<Params> = ({onSelect, mobileTS}) => {
    const {offer} = useAppSelector(state => state.createOffer)
    const [showMoreDestinationParams, setShowMoreDestinationParams] = useState(false)

    useEffect(()=>{
        ReactTooltip.rebuild()
    })

    return <DestinationUrlParamPillsContainer>
        {destinationParamsList.slice(0, 7).map(param => {
            return <DestinationUrlParamPills
                data-html={true}
                data-tip-disable={!destinationParamsListTooltips[param]}
                data-tip={ReactDOMServer.renderToStaticMarkup(
                    <div className={"dest-param-pill-tooltip"}>
                        {destinationParamsListTooltips[param]}
                    </div>
                )}
                key={param}
                onClick={()=>onSelect(param)}>
                     <span className="material-symbols-outlined">
                        add
                    </span>
                {param}
            </DestinationUrlParamPills>
        })}
        {showMoreDestinationParams && destinationParamsList.slice(7, mobileTS ?
            offer.trackingSystem === 'appsflyer' ? undefined : 30 : 24 ).map(param => {
            return <DestinationUrlParamPills
                data-html={true}
                data-tip-disable={!destinationParamsListTooltips[param]}
                data-tip={ReactDOMServer.renderToStaticMarkup(
                    <div className={"dest-param-pill-tooltip"}>
                        {destinationParamsListTooltips[param]}
                    </div>
                )}
                key={param}
                onClick={()=>onSelect(param)}>
                     <span className="material-symbols-outlined">
                        add
                    </span>
                {param}
            </DestinationUrlParamPills>
        })}
        <ShowMoreParamsButton
            onClick={() => setShowMoreDestinationParams(!showMoreDestinationParams)}>
            Show {showMoreDestinationParams ? 'Less' : 'More'}
        </ShowMoreParamsButton>
    </DestinationUrlParamPillsContainer>
}

const DestinationUrlParamPillsContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`

const DestinationUrlParamPills = styled.button`
    outline: none;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    padding: 4px 12px;
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: white;
    .material-symbols-outlined{
        font-size: 17px;
    }
    
    :hover {
        cursor: pointer;
        background-color: ${({theme}) => theme.colors.coolGray[2]};
        border: 1px solid ${({theme}) => theme.colors.deepBlue[2]};
    }
    
    :hover:active{
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
        border: 1px solid ${({theme}) => theme.colors.deepBlue[3]};
        color: ${({theme}) => theme.colors.deepBlue[6]};
    }
`

const ShowMoreParamsButton = styled.button`
    cursor: pointer;
    outline: none;
    padding: 4px 12px;
    border: none;
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: ${({theme}) => theme.colors.coolGray[2]};
    
    :hover{
        background-color: ${({theme}) => theme.colors.deepBlue[1]};
    }

    :hover:active{
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }
`