import React, {useRef, useState} from 'react'

import SelectFilterDropdown
    from '../../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import IncludeExcludeFilter
    from "../../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter";

import {FilterID} from "filters";

import {Wrapper} from './Filters.style'
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../../common/hooks";

import {
    useAutoFundingFilter,
    useInvoicingCycleFilter,
    useMarketerStatusFilter,
    useMarketerTypeFilter,
    useMarketerVerticalFilter,
    usePauseRiskFilter,
    usePaymentTermsFilter,
    useReferrerFilter,
    useSalesExecutiveFilter,
    useTagsFilter
} from "../../../../../../../common/hooks/filters";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {removeAllFilters} from "../../../../../../../store/filters.slice";
import {ButtonStyled} from "../../../../../../../common/styled-components";

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'auto-funding',
        value: 'Auto-Funding'
    },
    {
        id: "invoicing-cycle",
        value:"Invoicing Cycle"
    },
    {
        id: "marketer-status",
        value:"Marketer Status"
    },
    {
        id: "tags",
        value:"Marketer Tags"
    },
    {
        id: "marketer-type",
        value:"Marketer Type"
    },
    {
        id: "pause-risk",
        value:"Pause Risk"
    },
    {
        id: "payment-terms",
        value:"Payment Terms"
    },
    {
        id: "referrer",
        value:"Referrer"
    },
    {
        id: 'sales-executive',
        value: 'Sales Executive'
    },
    {
        id: 'marketer-vertical',
        value: 'Vertical'
    }
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    
    const dashboardPageId = 'admin/dashboard/marketer-funding-monitoring'
    
    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

  
    
    const autoFundingFilterDashboard = useAutoFundingFilter(dashboardPageId);
    const invoicingCycleFilterDashboard = useInvoicingCycleFilter(dashboardPageId, 50);
    const marketerTypeFilterDashboard = useMarketerTypeFilter(dashboardPageId);
    const pauseRiskFilterDashboard = usePauseRiskFilter(dashboardPageId);
    const marketerStatusFilterDashboard = useMarketerStatusFilter(dashboardPageId);
    const paymentTermsFilterDashboard = usePaymentTermsFilter(dashboardPageId, 50);
    const tagsFilterDashboard = useTagsFilter(dashboardPageId, 50);
    const referrerFilterDashboard = useReferrerFilter(dashboardPageId, 50);
    const salesExecutiveFilterDashboard = useSalesExecutiveFilter(dashboardPageId, 50);
    const marketerVerticalFilterDashboard = useMarketerVerticalFilter(dashboardPageId);
    

    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "auto-funding":
                return (
                    <IncludeExcludeFilter selectedFilterText={autoFundingFilterDashboard.selectedData[0] ? autoFundingFilterDashboard.selectedData[0].name : ''} filter={autoFundingFilterDashboard} filterTitle={'Auto-Funding'}>
                        {autoFundingFilterDashboard.data.map(option =>
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={autoFundingFilterDashboard.selectedData.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    autoFundingFilterDashboard.setSelectedData(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        )
                        }
                    </IncludeExcludeFilter>
                )
            case 'invoicing-cycle':
                return (
                        <IncludeExcludeFilter selectedFilterText={invoicingCycleFilterDashboard.selectedData[0] ? invoicingCycleFilterDashboard.selectedData[0] : ''} filter={invoicingCycleFilterDashboard} filterTitle={'Invoicing Cycle'}>
                            {invoicingCycleFilterDashboard.data.map(cycle =>  (
                                <Checkbox
                                    wrapperClassName={'checkbox-filter-wrapper'}
                                    key={cycle+Math.random()}
                                    checked={invoicingCycleFilterDashboard.selectedData.some(selected => cycle === selected)}
                                    onChange={() => {
                                        invoicingCycleFilterDashboard.setSelectedData(cycle)
                                        setDiscoverDisplayed(true)
                                    }}
                                    label={cycle}
                                />
                            ))}
                        </IncludeExcludeFilter>
                    )
            case 'marketer-status':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerStatusFilterDashboard.selectedData[0] ? marketerStatusFilterDashboard.selectedData[0].name : ''} filter={marketerStatusFilterDashboard} filterTitle={'Marketer Status'}>
                        {marketerStatusFilterDashboard.data.map(status =>  {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={status.id}
                                checked={marketerStatusFilterDashboard.selectedData.some(selected => status.id === selected.id)}
                                onChange={() => {
                                    marketerStatusFilterDashboard.setSelectedData(status)
                                    setDiscoverDisplayed(true)
                                }}
                                label={status.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case 'tags':
                return (
                    <IncludeExcludeFilter selectedFilterText={tagsFilterDashboard.selectedData[0] ? tagsFilterDashboard.selectedData[0].name : ''} filter={tagsFilterDashboard} filterTitle={'Marketer Tags'}>
                        {tagsFilterDashboard.data.map(tag =>
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={tag.id}
                                checked={tagsFilterDashboard.selectedData.some(selected => tag.id === selected.id)}
                                onChange={() => {
                                    tagsFilterDashboard.setSelectedData(tag)
                                    setDiscoverDisplayed(true)
                                }}
                                label={tag.name}
                            />

                        )}
                    </IncludeExcludeFilter>
                )
            case 'marketer-type':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerTypeFilterDashboard.selectedData[0] ? marketerTypeFilterDashboard.selectedData[0].name : ''} filter={marketerTypeFilterDashboard} filterTitle={'Marketer Type'}>
                        {marketerTypeFilterDashboard.data.map(type =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={type.name}
                                checked={marketerTypeFilterDashboard.selectedData.some(selected => type.name === selected.name)}
                                onChange={() => {
                                    marketerTypeFilterDashboard.setSelectedData(type)
                                    setDiscoverDisplayed(true)
                                }}
                                label={type.name}
                            />
                        ))}
                    </IncludeExcludeFilter>
        )
                // )
            case 'pause-risk':
                return (
                    <IncludeExcludeFilter selectedFilterText={pauseRiskFilterDashboard.selectedData[0] ? pauseRiskFilterDashboard.selectedData[0].name : ''} filter={pauseRiskFilterDashboard} filterTitle={'Pause Risk'}>
                        {pauseRiskFilterDashboard.data.map(risk =>  {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={risk.id}
                                checked={pauseRiskFilterDashboard.selectedData.some(selected => risk.id === selected.id)}
                                onChange={() => {
                                    pauseRiskFilterDashboard.setSelectedData(risk)
                                    setDiscoverDisplayed(true)
                                }}
                                label={risk.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case 'payment-terms':
                return (
                    <IncludeExcludeFilter selectedFilterText={paymentTermsFilterDashboard.selectedData[0] ? paymentTermsFilterDashboard.selectedData[0] : ''} filter={paymentTermsFilterDashboard} filterTitle={'Payment Terms'}>
                        {paymentTermsFilterDashboard.data.map(term =>  {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={term+Math.random()}
                                checked={paymentTermsFilterDashboard.selectedData.some(selected => term === selected)}
                                onChange={() => {
                                    paymentTermsFilterDashboard.setSelectedData(term)
                                    setDiscoverDisplayed(true)
                                }}
                                label={term}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            case "referrer":
                return (
                    <IncludeExcludeFilter selectedFilterText={referrerFilterDashboard.selectedData[0] ? referrerFilterDashboard.selectedData[0].name + ' ' + referrerFilterDashboard.selectedData[0].lastname : ''} filter={referrerFilterDashboard} filterTitle={'Referrer'}>
                        {referrerFilterDashboard.data.map(referrer =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={referrer.id+referrer.name}
                                checked={referrerFilterDashboard.selectedData.some(selectedManager => referrer.id === selectedManager.id)}
                                onChange={() => {
                                    referrerFilterDashboard.setSelectedData(referrer)
                                    setDiscoverDisplayed(true)
                                }}
                                label={referrer.name + ' ' + referrer.lastname}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            case 'sales-executive':
                return (
                    <IncludeExcludeFilter selectedFilterText={salesExecutiveFilterDashboard.selectedData[0] ? salesExecutiveFilterDashboard.selectedData[0].name + ' ' + salesExecutiveFilterDashboard.selectedData[0].lastname : ''} filter={salesExecutiveFilterDashboard} filterTitle={'Sales Executive'}>
                        {salesExecutiveFilterDashboard.data.map(executive =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={executive.id+executive.name}
                                checked={salesExecutiveFilterDashboard.selectedData.some(selected => executive.id === selected.id)}
                                onChange={() => {
                                    salesExecutiveFilterDashboard.setSelectedData(executive)
                                    setDiscoverDisplayed(true)
                                }}
                                label={executive.name + " " + executive.lastname}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            case 'marketer-vertical':
                return (
                    <IncludeExcludeFilter selectedFilterText={marketerVerticalFilterDashboard.selectedData[0] ? marketerVerticalFilterDashboard.selectedData[0].name : ''} filter={marketerVerticalFilterDashboard} filterTitle={'Vertical'}>
                        {marketerVerticalFilterDashboard.data.map(vertical =>  {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={vertical.id}
                                checked={marketerVerticalFilterDashboard.selectedData.some(selected => vertical.id === selected.id)}
                                onChange={() => {
                                    marketerVerticalFilterDashboard.setSelectedData(vertical)
                                    setDiscoverDisplayed(true)
                                }}
                                label={vertical.name}
                            />
                            })}
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters(dashboardPageId))
        }
    }


    return <Wrapper>
        <i className={'ico-Filters'}/>
        {filters.filter((filter: any) => filter.pageID === dashboardPageId)[0]?.filters.map((filter: any) => <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
        <SelectFilterDropdown allFilters={filterList} pageId={dashboardPageId}/>
        {filters.filter((filter: any) => filter.pageID === dashboardPageId)[0]?.filters.length > 1 && 
            <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                {displayClearFilterTooltip && <div onClick={() => setDiscoverDisplayed(true) } className={'clear-filters-tooltip'}>
                        Click Again to Confirm</div>}
                Clear All Filters
            </ButtonStyled>
        }
    </Wrapper>
}


export default Filters
