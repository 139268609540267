import React from 'react'
import { FetchLoadingWrapper } from "./FetchLoading.style";

const FetchLoading: React.FC = () => {
    return(
        <FetchLoadingWrapper>
            <div className="loading-dots">
                <span/><span/><span/>
            </div>
        </FetchLoadingWrapper>
    )
}

export default FetchLoading;
