import React, {useEffect} from 'react'
import {addDays} from "date-fns";
import {Panel} from "../Main.style";
import {Wrapper, AddEntryButton, EntryContainer} from './Scheduling.style'
import {Expandable} from "../../../components/Expandable";
import RadioButton from "../../../../../../../common/components/radio-button/RadioButton";
import {setOffer, setPrevPage} from "../../../create-offer.slice";
import DatePicker from "../../../../../../../common/components/date-picker/DatePicker";
import {format} from "date-fns";
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hooks";
import _ from "lodash";
import ScheduleEntry from "./schedule-entry/ScheduleEntry";
import {AlertStyled} from "../../../../../../../common/styled-components";
import {TARGETING} from "../../../interfaces/interfaces";

const Scheduling: React.FC = () => {
    const {offer, advancedSchedulingExpanded} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleAddEntry = () => {
        dispatch(setOffer({
            campaign_schedule: [...offer.campaign_schedule, {
                id: _.uniqueId('SCHEDULE_'),
                campaign_id: offer.id,
                from_day: '',
                from_hour: '',
                from_minute: '',
                to_hour: '',
                to_minute: '',
            }]
        }))
    }
    useEffect(()=>{
        dispatch(setPrevPage("../" + TARGETING))
    },[dispatch])

    return <>
        <Panel>
            <h2 className="title">Scheduling</h2>
            <p className="description">
                Define the time frame that this campaign should start and end,
                by default it will start immediately and have no end date.
            </p>
            <Wrapper>
                <div className="form">
                    <div className='date-form'>
                        <div className="form-label">
                            Start Date:
                        </div>
                        <div className="form-row">
                            <RadioButton
                                option={'0'}
                                activeOption={offer.start_date_option.toString()}
                                setActiveOption={() => dispatch(setOffer({
                                    start_date_option: 0
                                }))}
                            >
                                Immediately
                            </RadioButton>
                        </div>
                        <div className="form-row">
                            <RadioButton
                                option={'1'}
                                activeOption={offer.start_date_option.toString()}
                                setActiveOption={() => offer.start_date_option !== 1 &&  dispatch(setOffer({
                                    start_date_option: 1
                                }))}
                            >
                                <DatePicker
                                    date={offer.start_date}
                                    setDate={(date) => dispatch(setOffer({
                                        start_date: date && format(new Date(date), 'yyyy-MM-dd')
                                    }))}
                                    placeholder={'MM/DD/YYYY'}
                                    maxDate={offer.end_date ? new Date(offer.end_date) : null}
                                    minDate={new Date()}
                                />
                            </RadioButton>
                        </div>
                    </div>
                    <div className='date-form'>
                        <div className="form-label">
                            End Date:
                        </div>
                        <div className="form-row">
                            <RadioButton
                                option={'0'}
                                activeOption={offer.end_date_option.toString()}
                                setActiveOption={() => dispatch(setOffer({
                                    end_date_option: 0
                                }))}
                            >
                                None
                            </RadioButton>
                        </div>
                        <div className="form-row">
                            <RadioButton
                                option={'1'}
                                activeOption={offer.end_date_option.toString()}
                                setActiveOption={() => offer.end_date_option !== 1 && dispatch(setOffer({
                                    end_date_option: 1
                                }))}
                            >
                                <DatePicker
                                    date={offer.end_date}
                                    setDate={(date) => dispatch(setOffer({
                                        end_date: date && format(new Date(date), 'yyyy-MM-dd')
                                    }))}
                                    placeholder={'MM/DD/YYYY'}
                                    minDate={offer.start_date ? addDays(new Date(offer.start_date), 1) : addDays(new Date(), 1)}
                                />
                            </RadioButton>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <Expandable title={'Advanced Scheduling'} locked={false} switchToggle={advancedSchedulingExpanded}>
                <p className="description">
                    Define specific days and hours that the campaign should run.
                    Time entered is in Eastern Standard Time (this cannot be changed).
                </p>
                <EntryContainer>
                    {offer.campaign_schedule.map((entry, index: number) => {
                        return <ScheduleEntry key={entry.id} entry={entry} index={index}/>
                    })}
                </EntryContainer>
                <AddEntryButton
                    onClick={handleAddEntry}
                >
                    Add Entry
                    <span className="material-symbols-outlined">
                    add
                </span>
                </AddEntryButton>
            </Expandable>
        </Panel>
    </>
}

export default Scheduling