import React, {useEffect} from 'react';
import {ButtonHolder, OfferTitle, OfferTypeBox, OfferTypeHolder} from "./CreateOffers.style";
import {AndroidIcon, CheckedIcon, IosIcon} from "../../../../assets/svgs/SvgIcons";
import {useNavigate, useParams} from "react-router-dom";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {setNextPage, setOffer, setPrevPage, setShowExit} from "./create-offer.slice";
import {IOS, ANDROID, MOBILE_PLATFORM, OFFER_INFO} from "./interfaces/interfaces";
import {useInitialDataFetch} from "./hooks/useInitialDataFetch";


const Mobile : React.FC = ({}) => {
    const { offer, nextPage, prevPage } = useAppSelector(state => state.createOffer)

    const {id} = useParams()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {loading, fetch} = useInitialDataFetch()
    useEffect(()=>{
        dispatch(setShowExit(true))
        dispatch(setNextPage("../i/" + OFFER_INFO))
        dispatch(setPrevPage("type/apps"))
    }, [])

    const handleOnClick = async (platform : MOBILE_PLATFORM) => {
        dispatch(setOffer({
            mobile_platform: platform,
            operating_system_id: platform === IOS ? 2 : 1
        }))
        dispatch(setNextPage("../i/" + OFFER_INFO))

    }

    const handleContinue = async () => {
        try{
            if(!id) {
                await fetch()
            }
            navigate("../i/" + OFFER_INFO)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <OfferTitle>
                Which app ecosystem will this campaign be sending customers to?
            </OfferTitle>
            <OfferTypeHolder>
                <OfferTypeBox active={offer.mobile_platform === IOS} onClick={() => handleOnClick(IOS)}>
                    <div>
                        <IosIcon/>
                        <div>iOS</div>
                    </div>
                    <CheckedIcon display={offer.mobile_platform === IOS ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox active={offer.mobile_platform === ANDROID} onClick={() => handleOnClick(ANDROID)}>
                    <div>
                        <AndroidIcon/>
                        <div>Android </div>
                    </div>
                    <CheckedIcon display={offer.mobile_platform === ANDROID ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
            </OfferTypeHolder>
            <ButtonHolder>
                {offer.mobile_platform !== "" &&
                        <ButtonStyled
                            disabled={loading}
                            onClick={handleContinue}
                            className={"btn-blue-filled btn-block btn-size-lg full-radius"}
                        >{loading ? <BtnLoadingSpinner/> : null}Continue</ButtonStyled>
                }
            </ButtonHolder>
        </div>
    );
};

export default Mobile