import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IPreset } from "../../../../../../common/components/range-date-picker/presets/presets.props";
import { allPaginationColumnsList } from "./allPaginationColumnsList";

export interface IInitialState  {
    billingFeesPaginationColumnsList: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]
}

const initialState: IInitialState = {
    billingFeesPaginationColumnsList: allPaginationColumnsList
}

const billingFeesSlice = createSlice({
    name: 'billingTransactionsSlice',
    initialState,
    reducers: {
        setBillingFeesPaginationColumnsList: (state: IInitialState, action:PayloadAction<{name: string,
            id: number,
            checked: boolean,
            column_key?: string}[]>) => {
            state.billingFeesPaginationColumnsList = action.payload;
        }
    }
})

export const {
    setBillingFeesPaginationColumnsList,
} = billingFeesSlice.actions

export default billingFeesSlice.reducer
