import React, {MouseEventHandler} from "react";
import ReactDOM from "react-dom";
import {Container} from "./DropdownContainer.style";

interface IDropdownContainerPortalProps {
    opened: boolean,
    position: {
        bottom: number,
        right: number
    },
    children:  React.ReactNode
}

const DropdownContainer = React.forwardRef<React.MutableRefObject<HTMLDivElement>, IDropdownContainerPortalProps>( ({opened, position, children}, ref) => {
    return ReactDOM.createPortal(
        // @ts-ignore
        <Container ref={ref} position={position} opened={opened}>
            {children}
        </Container>,
        document.body
    )
})

export default DropdownContainer