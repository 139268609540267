import styled, {css} from 'styled-components'


type TInvoiceBoxType = 'no-invoice' | 'posted' | 'overdue' | 'paid' | 'paid-late'

interface IInvoiceBoxProps {
    type: TInvoiceBoxType
}

interface ITooltipWrapperProps {
    $x: number,
    $y: number,
    isTop: boolean,

}

interface IWrapperProps {
    $hasOpacity: boolean
}

const handleInvoiceBoxBackgroundColor = (type :TInvoiceBoxType) => {
    switch (type) {
        case "no-invoice":
            return '#E7ECEE'
        case "overdue":
            return '#F23842'
        case "paid":
            return '#3FDE7F'
        case "posted":
            return '#3479FF'
        case "paid-late":
            return '#F3C215'
        default:
            return '#E7ECEE'
    }
}

export const InvoiceBox = styled.div<IInvoiceBoxProps>`
    height: 16px;
    width: 16px;
    border-radius: 2px;
    position: relative;
    background-color: ${props => handleInvoiceBoxBackgroundColor(props.type)};
    :hover{
        opacity: 1 !important;
    }

    :after{
        content: '';
        height: 100%;
        position: absolute;
        right: -3px;
        width: 3px;
        background-color: transparent;
    }

`

export const Wrapper = styled.div<IWrapperProps>`
    display: flex;
    gap: 2px;
    
    ${InvoiceBox} {
        :hover {
            opacity: 0.2;
        }
    }
    :hover{
        div{
            opacity: ${({$hasOpacity}) => $hasOpacity ? '0.2' : '1'};
        }
    }
`


export const TooltipWrapper = styled.div<ITooltipWrapperProps>`
  z-index: 1000;
  width: 240px;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: ${props => props.$y}px;
  left: ${props => props.$x - 120}px;
  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    
    left: 0;
    right: 0;
    margin: auto;
    
    ${({isTop}) => isTop ? `
        top: -8px;
        transform: rotate(180deg);
    ` : `
        bottom: -8px;
    `}
  }
  
`

export const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  
  .label{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
  
`

export const InvoiceState = styled.div`
  display: flex;
  align-items: center;
  span{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 2px;
  }
`

