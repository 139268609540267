import React from "react";

import {Wrapper} from "./SelectionPills.style";

import {ISelectionPillsProps} from "./selectionPills.props";

const SelectionPills: React.FC<ISelectionPillsProps> = ({text, onRemove, wrapperClassName}) => {
    return (
        <Wrapper className={wrapperClassName}><span>{text}</span><i onClick={onRemove} className={'ico-Exit'}/></Wrapper>
    )
}

export default SelectionPills
