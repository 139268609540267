import React from "react";
import { InfoSectionWrapper } from "./InfoSection.style";

interface IInfoSectionProps {
    children: any
}

const InfoSection: React.FC<IInfoSectionProps> = ({children}) => {
    return(
        <InfoSectionWrapper>
            <i className="ico-Information-outlined"></i>
            <p className="text">
                {children}
            </p>
        </InfoSectionWrapper>
    )
}

export default InfoSection;