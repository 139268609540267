import axios, { AxiosResponse } from "axios";
import {api} from "./api";

export interface IUserPreferencesParams {
    user_id: number,
    page_id: string
}

class UserPreferencesCacheService {
    static fetchUserPreferencesCache = async (
        token: string,
        user_id: number, 
        page_id: string,
    ) : Promise<AxiosResponse> => {
        try {
            return await api.get([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "web-cache"
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'key': user_id.toString()  + ':' + page_id 
                    }
                });
        } catch (e:any) {
            if(axios.isAxiosError(e)) {
                console.log(e.message, 'axios error')
            }
            return e.message;
        }
    }
    static addUserPreferencesCache = async (
        token:string, 
        user_id: number, 
        page_id: string,
        data: string
    ) : Promise<AxiosResponse> => {
        try {
            return await api.post([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "web-cache"
                ].join("/"),
                {
                    data:  data
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                       'key': user_id.toString()  + ':' + page_id 
                   }
                },
            )
        } catch (e: any) {
            if(axios.isAxiosError(e)) {
                console.log(e.message, 'axios error')
            }
            return e.message;
        }
    }
    static removeUserPreferencesCache = async (
        token: string,
        user_id: number,
        page_id: string
    ): Promise<AxiosResponse> => {
        try {
            return await api.post([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "web-cache"
                ].join("/"),
                {
                    "_method": "DELETE"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        'key': user_id.toString()  + ':' + page_id
                    }
                });
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
}

export default UserPreferencesCacheService