import React from 'react';
import { useWidth } from "../../../hooks/useWidth";
import { FlitersListItemBox } from "./FiltersListItem.style";

const FiltersListItem: React.FC = ({children}) => {
    const width = useWidth();
    
    return(
        <FlitersListItemBox width={width}>
            {children}
        </FlitersListItemBox>
    )
}

export default FiltersListItem;