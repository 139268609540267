import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DropdownItem } from "../../../../../common/components/dropdown/Dropdown.style";
import Grid from "../../../../../common/components/grid/Grid";
import GridRowOptions from "../../../../../common/components/grid/grid-row-options/GridRowOptions";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import { useAppSelector } from "../../../../../common/hooks";
import { tokenStore } from "../../../../../store/auth.slice";
import AdvertisersEditService from "../AdvertisersEdit.service";
import { getEditingUser } from "../AdvertisersEdit.slice";
import CardsTooltip from "./cards-tooltip/CardsTooltip";
import { CardsWrapper, ExpandCardsRow } from "./Cards.style";

const Cards = () => {
    const token = useAppSelector(tokenStore);
    const { editingUser } = useAppSelector(state => state.advertisersEdit);
    const {id} = useParams<{id :string}>()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [cards, setCards] = useState<any>();
    const [cardsList, setCardsList] = useState<any>();

    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        if(cards){
            setTotal(cards.length)
            const numOfPages = Math.floor(cards.length / perPage)
            const slicedData = []

            let index: number = 0
            while(index <= numOfPages){
                slicedData.push(cards.slice(index * perPage, index * perPage + perPage))
                index++
            }
            setFrom((currentPage - 1) * perPage)
            setTo((currentPage - 1) * perPage + perPage <= cards.length ? (currentPage - 1) * perPage + perPage : cards.length)
            setLastPage(slicedData.length)
            setCardsList(slicedData[currentPage - 1])
        }

    }, [editingUser, perPage, total, currentPage])

    const fetchInitialData = useCallback(async () => {
        try{
            setLoading(true);    
            let [cards, editingUsr] = await Promise.all([
                AdvertisersEditService.fetchCards(token, Number(id)),
                id && dispatch(getEditingUser({token: token, id: parseInt(id)}))
            ]);

            const cardsExpand = cards.data.map((card: any, index: number) => {
                return {
                    ...card, 
                    expanded: false, 
                    card_history: card.card_history.map((item: any, index: number) => {
                        return {
                            ...item,
                            created_at: index === 0 ? card.created_at : card.card_history[index - 1].created_at,
                            parent: card};
                    }).reverse(),
                };
            });
            setCardsList(cardsExpand);
            setCards(cardsExpand);
            setLoading(false)
        } catch (e){
            console.log(e)
        }
    }, [id, token, dispatch]);

    useEffect(() => {
        token && id && fetchInitialData();
    }, [token, id]);

    const expandData = (row: any) => {        
        if(!row.expanded) {
            const indexOfParent = cardsList.findIndex((obj: any) => obj.id === row.id);
            const copyCardsList = cardsList.map((item: any, index: number) => {
                if(index === indexOfParent) {
                    return {...item, expanded : true}
                } else return { ...item, expanded: false};
            });
            const newList = [...copyCardsList.slice(0, indexOfParent+1), ...row.card_history, ...copyCardsList.slice(indexOfParent+1)];           
            setCardsList(newList);
        } else {
            setCardsList([...cards]);
        }
    };
    
    const columnDefs = useMemo(() => [
        {field: '', headerName: '', width: 100, cellRenderer: ({data}: any) => {           
            let history = data.card_history ? true : false;
            let expanded = data.expanded ? true : false;
            if(history) {
                if(data.card_history.length === 0) {
                    return <ExpandCardsRow $disabled >
                                <CardsTooltip width={80} position="top" >No history</CardsTooltip>
                                <i className="ico-ChevronDown"></i>
                           </ExpandCardsRow>
                }
                return expanded ? <ExpandCardsRow onClick={() => expandData(data)}>
                                    <i className="ico-ChevronUp"></i>
                                  </ExpandCardsRow> 
                                  : 
                                  <ExpandCardsRow  onClick={() => expandData(data)} >
                                    <i className="ico-ChevronDown"></i>
                                  </ExpandCardsRow>
            }
            return <span></span>
        }},
        {field: 'alias', headerName: 'NICKNAME', minWidth: 150, flex: 1},
        {field: 'name', headerName: 'CARD NAME', minWidth: 150, flex: 1},
        {field: 'type', headerName: 'CARD TYPE', minWidth: 150, flex: 1},
        {field: 'friendly_number', headerName: 'CARD NUMBER', minWidth: 150, flex: 1},
        {field: 'status', headerName: 'STATUS', minWidth: 150, flex: 1},
        {field: '', headerName: 'DATE', minWidth: 150, flex: 1, cellRenderer: ({data}: any) => {
            let history = data.card_history ? true : false;            
            if(history) {
                return <span>{data.updated_at}</span>;
            }
            return <span>{data.created_at}</span>;
        }},
        { pinned: 'right', width: 50, resizable: false, cellRenderer: ({data}: any) => {
            let history = data.card_history ? true : false;
            if(history) {
                return <GridRowOptions>
                    <DropdownItem disabled={(data.status === "deleted")} onClick={() => (data.status !== "deleted") && AdvertisersEditService.exportCardPdf(data.id, "export-card-to-pdf")}>Export PDF</DropdownItem>
                </GridRowOptions> 
                
            };

            return <GridRowOptions>
                <DropdownItem disabled={(data.status === "deleted")} onClick={() => (data.status !== "deleted") && AdvertisersEditService.exportCardPdf(data.id, "export-card-history-to-pdf")}>Export PDF</DropdownItem>
            </GridRowOptions> 
        
        }}
    ], [cards]);

    const defaultColumnDefs = useMemo(() => ({
        resizable: false,
        minWidth: 70
    }), []);

    return(
        <CardsWrapper>
            <div className="pagination">
                <Pagination
                    current_page={currentPage}
                    from={from}
                    to={to}
                    total={total}
                    setCurrentPage={setCurrentPage}
                    setPerPage={setPerPage}
                    last_page={lastPage}
                    perPage={perPage}
                    loading={loading}
                    onRefresh={() => fetchInitialData()} 
                />
            </div>

                <Grid 
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDefs}
                    rowData={cardsList}
                    rowHeight={40}
                    domLayout='autoHeight'
                    loading={loading}
                />

        </CardsWrapper>
    )
}

export default Cards;