import React, {ReactElement} from "react";

export const OFFER_NAME_TOOLTIP: string = "The campaign name is used to uniquely define the campaign. It should be written " +
    "in a way to help partners quickly get an understanding what your campaign is providing."

export const BRAND_NAME_TOOLTIP: string = "The brand that this campaign is associated with when presented to partners " +
    "and customers."

export const BRAND_URL_TOOLTIP: string = "The URL for your brand that can be viewed by partners. Providing a brand URL " +
    "helps align creatives to your brand style when presenting ads directed to customers."

export const OFFER_DESCRIPTION_TOOLTIP: string = "Any relevant information about the campaign that should be shown " +
    "through to partners."

export const VERTICAL_TOOLTIP: string = "The industry channel that the campaign provides goods and services within."

export const CUSTOM_DOMAIN_TOOLTIP: string = "A custom domain allows you to add your own purchased domain to our " +
    "platform, where we will return unique name server records for you to point that domain to our servers."

export const OFFER_TRACKING_URL : ReactElement = <>
                                <p>The URL that is required to successfully capture the click data, including the
                                partner source associated with them in your tracking system.</p>
                                <p>This URL is provided by your tracking system and should be unique to Perform[cb].</p>
                                <p>An https version of the URL is recommended.</p>
                                </>

export const CONVERSION_PAGE_URL : ReactElement = <>
                                <p>
                                    The point on your domain that this conversion takes place.
                                    The following examples are formats that are acceptable:
                                </p>
                                <p/>
                                <ul className={'tooltip-list'}>
                                   <li>https://www.offer.com/en/product/B01</li>
                                   <li>http://www.offer.com/en/product/B01</li>
                                   <li>www.offer.com/en/product/B01</li>
                                   <li>offer.com/en/product/B01</li>
                                </ul>
                            </>

export const PAYOUT_TYPE : ReactElement = <>
                                <p>
                                    A standard payout type is the default
                                    option and is used when you have a
                                    single payout for a conversion or event.
                                </p>
                                <p/>
                                <p>
                                    Revshare is used when you have
                                    different payouts for a conversion or
                                    event based on specific conditions.
                                </p>
                            </>

export const PAYOUT_DESCRIPTION : ReactElement = <>
                                <p>
                                    When using the revshare
                                    payout type, the payout
                                    description is used to define
                                    what that payout is for.
                                </p>
                            </>

export const EVENT_PAGE : ReactElement = <>
                                <p>
                                    The point on your domain that this event takes place.
                                    The following examples are formats that are acceptable:
                                </p>
                                <p/>
                                <ul className={'tooltip-list'}>
                                   <li>https://www.offer.com/en/product/B01</li>
                                   <li>http://www.offer.com/en/product/B01</li>
                                   <li>www.offer.com/en/product/B01</li>
                                   <li>offer.com/en/product/B01</li>
                                </ul>
                            </>

export const TESTING_DESCRIPTION_WEB = "To test that your campaign is tracking properly, click the link below. " +
    "After clicking the link a new tab will be opened. Once you have completed the required step(s) to trigger the " +
    "conversion/event you want to test you can navigate back to this window to view the results in the table below."

export const TESTING_DESCRIPTION_MOBILE = "To test that your campaign is tracking properly, open the link below on a " +
    "device using the same app ecosystem selected for this campaign. Once you have completed the required step(s) to " +
    "trigger the conversion/event you want to test you can navigate back to this window to view the results in the " +
    "table below."

export const CALLS_TOOLTIP: ReactElement = <>
    <p>Choose this outcome type when your goal for this campaign is
        to generate inbound customer calls for your business.</p></>


export const APP_INSTALL_TOOLTIP: ReactElement =  <>
    <p>Choose this outcome type when your primary goal for this campaign is to generate installs and opening  of your
        businesses mobile app. You will still have the option to track engagements.</p>
    <p>Also commonly referred to as a CPI (Cost Per Install) campaign.</p>
</>

export const APP_ENGAGEMENT_TOOLTIP: ReactElement = <>
    <p>
        Choose this outcome type when your primary goal for this campaign is to generate specific
        engagements in your businesses mobile app. You will still have the option to track
        the install and opening of your app.
    </p>
    <p>Also commonly referred to as a CPE (Cost Per Engagement) campaign.</p></>

export const IMPRESSION_TRACKING_URL_TOOLTIP: ReactElement = <>
    <p> The URL that is required to successfully capture the impression data, including the partner
        source associated with them in your tracking system.</p>
    <p> This URL is provided by your tracking system and should be unique to Perform[cb].</p>
    <p>An https version of the URL is recommended.</p>
</>


export const LANDING_PAGE_URL: string = "The final location customers are sent to after clicking an ad for your campaign. " +
    "There should be no redirects when visiting this point of the outcome funnel."

export const DOMAIN: string = "This is the domain that your tracking system passes click id or transaction id to.\n" +
    "\n" +
    "By default it will be auto populated from your Campaign Tracking URL."

export const DOMAIN_PARAMETER_INPUT: string = "This is the parameter that your domain uses to receive the click id or " +
    "transaction id from your tracking system. Some examples of possible parameters could be:\n" +
    "\n" +
    "click_id\n" +
    "transaction_id"

export const URL_PATTERN = /^((?:http(s)?:\/\/)|(?:applenews:\/\/))?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/{}?#%[\]@!\$&'\(\)\*\+,;=.]+$/

export const APP_STORE_URL_TOOLTIP: string = 'The unique URL for your app on the app store that customers land on after ' +
    'clicking an ad for your campaign. There should be no redirects when visiting this point of the outcome funnel.'

export const GOOGLE_STORE_URL_INPUT: string = 'The unique URL for your app on the google play store that customers land ' +
    'on after clicking an ad for your campaign. There should be no redirects when visiting this point of the outcome funnel.'

export const REQUIRE_IMPRESSION_TRACKING = "When enabled, partners will be required to place a pixel specifically for " +
    "capturing impression data to run this campaign."

export const ENABLE_VIEW = "When enabled, outcomes will be allowed to be attributed through impressions as well as clicks."

const trackingCode = process.env.REACT_APP_DOMAIN_TRACKING_CODE_URL

export const TRACKING_CODE = `<script type="text/javascript" src="${trackingCode}"></script>
<script type="text/javascript">
        PCBpixel.track();
</script>`

export const MAIN_CONVERSION_PIXEL_GROUP_TOOLTIP = 'Assign this conversion to a pixel group to utilize a shared pixel. ' +
    'By default the conversion won’t be assigned to a pixel group. If assigned to a group, the pixels above will be ' +
    'updated to reflect a new group id instead of campaign id.'


export const INVALID_URL_MSG =  'Sorry not a valid URL, supported protocols: https:// (recommended), http://, applenewss://'

export const INVALID_MACRO_MSG = 'The parameter &af_enc_data={encrypted_cost} can not be included on CPE campaigns. Please remove.'

export const OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_DEFAULT = 'Map out and organize the steps you want to either ' +
    'pay out for or track in your outcome funnel. Tracking additional events beyond your main conversion can help ' +
    'find potential areas for growth that lead to more desired outcomes.'

export const OUTCOME_FUNNEL_DESCRIPTION_CLICKS = 'Provide how much you would like to payout per click below. ' +
    'Additionally you can add a main conversion and event(s) you want to track in your outcome funnel. Tracking ' +
    'additional steps beyond a customer’s click can help find potential areas for growth that lead to more ' +
    'desired outcomes.'

export const OUTCOME_FUNNEL_DESCRIPTION_PPC = 'Provide how much you would like to payout per call.'

export const OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_IOS = 'Provide how much you would like to payout per install ' +
    'and opening of your app. Additionally you can add events you want to track in your outcome funnel. Tracking ' +
    'additional steps beyond a customer’s install can help find potential areas for growth that lead to ' +
    'more desired outcomes.'

export const OUTCOME_FUNNEL_DESCRIPTION_MOBILE_PLATFORM_ANDROID = 'Provide how much you would like to payout for the ' +
    'event in your app. Additionally you can track the install or other events in your outcome funnel. Tracking ' +
    'additional steps beyond a single event can help find potential areas for growth that lead ' +
    'to more desired outcomes.'

export const KOCHAVA_TOOLTIP_CONTENT : ReactElement = <> 
        Copy the provided ID below and add it to the corresponding Event in Kochava in the "GOAL ID" field.
    </>
export const SINGULAR_TOOLTIP_CONTENT : ReactElement = <> 
        Copy the provided ID below and add it to the corresponding Event in Singular in the "GOAL ID" field.
    </>
export const APPSFLYER_TOOLTIP_CONTENT : ReactElement = <> 
        Copy the provided ID below and add it to the corresponding Event in Appsflyer in the 
        "mapped to partner event" field.
    </>


export const BUDGET_TYPES = [
    {
        label: 'Daily',
        value: 'daily',
        disabled: true
    },
    {
        label: 'Weekly',
        value: 'weekly',
        disabled: false
    },
    {
        label: 'Monthly',
        value: 'monthly',
        disabled: false
    },
    {
        label: 'Unlimited',
        value: 'unlimited',
        disabled: false
    }
]

export const TEST_BUDGET_TYPES = [
    {
        label: 'Daily',
        value: 'daily',
        disabled: true
    },
    {
        label: 'Weekly',
        value: 'weekly',
        disabled: false
    },
    {
        label: 'Monthly',
        value: 'monthly',
        disabled: false
    }, {
        label: 'Disabled',
        value: 'disabled',
        disabled: false
    }
]

export const DOLLAR_CURRENCY : string = "US ($)"

export const BUDGET_AMOUNT_TYPES = [
    {
        label: DOLLAR_CURRENCY,
        value: 0,
        disabled: false
    }, {
        label: "Conversion",
        value: 1,
        disabled: false
    }
]

export const income = {
    type: 'income',
    settings: [
        {
            from: 0,
            to: 15000
        },
        {
            from: 15001,
            to: 20000
        },
        {
            from: 20001,
            to: 30000
        },
        {
            from: 30001,
            to: 40000
        },
         {
            from: 40001,
            to: 50000
        },
         {
            from: 50001,
            to: 60000
        },
         {
            from: 60001,
            to: 70000
        },
         {
            from: 70001,
            to: 80000
        },
         {
            from: 80001,
            to: 90000
        },
         {
            from: 90001,
            to: 100000
        },
        {
            from: 100001,
            to: 125000
        },
        {
            from: 125001,
            to: 150000
        },
        {
            from: 150001,
            to: null
        },
    ]
}

export const gender = {
    type: 'gender',
    settings: [
        {
            id: 0,
            gender: 'male'
        },
        {
            id: 1,
            gender: 'female'
        }
    ]
}

export const attributes = [
    'age',
    'income',
    'gender'
]

export const AdjustEventMacro = (val1:string, val2:string) =>  {
    return `&event_callback_${val1}=http%3A%2F%2Ftrksontrks.com%2Fap%2Fconv%3Fclickid%3D{clickid}%26cbuid%3D${val2}`
    // return `/${val1}=${val2}/`
}

export const AppsFlyerEventMacro = (val1:number, val2:string) =>  {
    return `&goalid_${val1}=${val2}`
}

export const KochavaEventMacro = (val1:number, val2:string) =>  {
    return `&goal_id${val1}=${val2}`
}

export const BranchEventMacro = (val:string) =>  {
    return `&cbuid=${val}`
}

export const SingularEventMacro = (val1:number, val2: string) =>  {
    return `&${val1}=${val2}`
}

/**
 * field names and models for delayed actions
 */

export const FIELD_NAME_ADVANCED_SCHEDULING = "advanced_scheduling";
export const FIELD_NAME_TRAFFIC_TYPE = "traffic_type";
export const FIELD_NAME_DEVICE_OS_ENABLED = "device_os_enabled";
export const FIELD_NAME_DEVICE_TYPE_DESKTOP = "device_type_desktop";
export const FIELD_NAME_DEVICE_TYPE_MOBILE = "device_type_mobile";
export const FIELD_NAME_DEVICE_TYPE_TABLET = "device_type_tablet";
export const FIELD_NAME_PAYOUT = "payout";
export const FIELD_NAME_CPA = "cpa";
export const FIELD_NAME_MAX_BID= "max_bid";
export const FIELD_NAME_PAYOUT_REVSHARE = "payout_revshare";
export const FIELD_NAME_PAYOUT_LINK_ID = "payout_link_id";
export const FIELD_NAME_PAYOUT_AFFILIATE_ID = "payout_affiliate_id";
export const FIELD_NAME_UPSELL_PAYOUT_AFFILIATE_ID = "upsell_payout_affiliate_id";
export const FIELD_NAME_REVENUE_LINK_ID = "revenue_link_id";
export const FIELD_NAME_BUDGET = "budget";
export const FIELD_NAME_ADVANCED_BUDGET = "advanced_budget";
export const FIELD_NAME_BUDGET_GROUP_ID = "budget_group_id";
export const FIELD_NAME_PIXEL_GROUP_ID = "pixel_group_id";
export const FIELD_NAME_STATUS = "status";
export const FIELD_NAME_BLOCK_LINK_ID = "block_link_id";
export const FIELD_NAME_DESTINATION_URL = "destination_url";
export const FIELD_NAME_BRAND_NAME = 'brand_name';
export const FIELD_NAME_DESCRIPTION = "description";
export const FIELD_NAME_RESTRICTIONS = "restrictions";
export const FIELD_NAME_UPSELL = "upsell";
export const FIELD_NAME_UPSELL_PIXEL_GROUP_ID = "upsell_pixel_group_id";
export const FIELD_NAME_UPSELL_PAYOUT = "upsell_payout";
export const FIELD_NAME_UPSELL_PAYOUT_REVSHARE = "upsell_payout_revshare";
export const FIELD_NAME_UPSELL_PAYOUT_LINK_ID = "upsell_payout_link_id";
export const FIELD_NAME_UPSELL_REVENUE_LINK_ID = "upsell_revenue_link_id";
export const FIELD_NAME_NICHE = "niche";
export const FIELD_NAME_LOCATIONS = "locations";
export const FIELD_NAME_CITY = "cities";
export const FIELD_NAME_ZIP = "zips";
export const FIELD_NAME_ISP = "isp";
export const FIELD_NAME_CREATIVE = "creative";
export const FIELD_NAME_SUPPRESSION = "campaign_suppression";
export const FIELD_NAME_UNSUBSCRIBE = "campaign_unsubscribe";
export const FIELD_FROM_LINES = "campaign_from_lines";
export const FIELD_SUBJECT_LINES = "campaign_subject_lines";
export const FIELD_NAME_CREATIVE_NAME = "creative_name";
export const FIELD_NAME_CREATIVE_TEXT = "creative_text";
export const FIELD_NAME_CREATIVE_TYPE = "creative_type";
export const FIELD_NAME_APPROVED_LINK = "approved";
export const FIELD_NAME_CAMPAIGN_PAYOUT_MODIFIER = "payout_modifier";
export const FIELD_NAME_UPSELL_PAYOUT_MODIFIER = "upsell_payout_modifier";
export const FIELD_NAME_WHITELISTED_SOURCEIDS = "whitelisted_sourceids";

export const MODEL_CAMPAIGN = "CAMPAIGN";
export const MODEL_UPSELL = "UPSELL";
export const MODEL_CAMPAIGN_LINK_SETTINGS = "CAMPAIGN_LINK_SETTINGS";
export const MODEL_UPSELL_LINK_SETTINGS = "UPSELL_LINK_SETTINGS";
export const MODEL_UPSELL_REVSHARE_SETTINGS = "UPSELL_REVSHARE_SETTINGS";
export const MODEL_CAMPAIGN_LOCATIONS = "CAMPAIGN_LOCATIONS";
export const MODEL_CAMPAIGN_SCHEDULE = "CAMPAIGN_SCHEDULE";
export const MODEL_CAMPAIGN_TRAFFIC = "CAMPAIGN_TRAFFIC";
export const MODEL_CAMPAIGN_DEVICE_SETTINGS = "CAMPAIGN_DEVICE_SETTINGS";
export const MODEL_CAMPAIGN_CREATIVE = "CAMPAIGN_CREATIVE";
export const MODEL_CAMPAIGN_REVSHARE_SETTINGS = "CAMPAIGN_REVSHARE_SETTINGS";
export const MODEL_CAMPAIGN_EMAIL_SETTINGS = "CAMPAIGN_EMAIL_SETTINGS";
export const MODEL_CAMPAIGN_PRIVATE_SETTINGS = "CAMPAIGN_PRIVATE_SETTINGS";
export const MODEL_CAMPAIGN_PAYOUT_MODIFIERS = "CAMPAIGN_PAYOUT_MODIFIERS";
export const MODEL_UPSELL_PAYOUT_MODIFIERS = "UPSELL_PAYOUT_MODIFIERS";
export const MODEL_CAMPAIGN_WHITELISTED_SOURCEIDS = "CAMPAIGN_WHITELISTED_SOURCEIDS";
export const MODEL_CAMPAIGN_ISP = 'CAMPAIGN_ISP';