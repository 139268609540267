import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

export const allMarketerStatuses = [
    {
        name: "All Statuses",
        id: 6,
        status_name: "all_statuses"
        // group: [{name: "activee111", id: 1234}]
    },
    {
        name: "Active",
        id: 1,
        status_name: "active"
        // group: [{name: "activee111", id: 1234}]
    },
    {
        name: "Inactive",
        id: 2,
        status_name: "inactive"
    },
    {
        name: "Terminated",
        id: 3,
        status_name: "terminated"
    },
    {
        name: "Deleted",
        id: 4,
        status_name: "deleted"
    }
];

export const useMarketerStatusFilter = (pageId: PageID): IFilterHookReturn<any> => {

    const dispatch = useAppDispatch()

    const id = 'marketer-status'

    const marketerStatusFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (status: any) => {
        if(marketerStatusFilter.selected.some((filterManager)  => filterManager.id === status.id)){
            return
        } else {
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: marketerStatusFilter.exclude,
                    selected: [status]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: marketerStatusFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allMarketerStatuses,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: marketerStatusFilter.selected,
        exclude: marketerStatusFilter.exclude,
        setSelectedData: setSelectedData,
        // setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}