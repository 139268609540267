import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 400px;
    
    p{
        color: ${({theme}) => theme.colors.deepBlue[6]};
    }
    
    .description{
        margin-top: 24px;
    }

    .postback-ip-info{
        h4{
            margin-top: 16px;
        }
    }
`