import React from 'react'

import {EntryRow} from "../Scheduling.style";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";

import {setOffer} from "../../../../create-offer.slice";
import {IOfferSchedule} from "../../../../interfaces/interfaces";
import {StyledSelect} from "../../../../../../../../common/components/select/Select";

interface Props {
    entry: IOfferSchedule,
    index: number
}

const minutes = [
    {
        "label": "00",
        "value": 0
    },
    {
        "label": "15",
        "value": 15
    },
    {
        "label": "30",
        "value": 30
    },
    {
        "label": "45",
        "value": 45
    },
]
const hours = [
    {
        "label": "12 AM",
        "value": 12
    },
    {
        "label": "1 AM",
        "value": 1
    },
    {
        "label": "2 AM",
        "value": 2
    },
    {
        "label": "3 AM",
        "value": 3
    },
    {
        "label": "4 AM",
        "value": 4
    },
    {
        "label": "5 AM",
        "value": 5
    },
    {
        "label": "6 AM",
        "value": 6
    },
    {
        "label": "7 AM",
        "value": 7
    },
    {
        "label": "8 AM",
        "value": 8
    },
    {
        "label": "9 AM",
        "value": 9
    },
    {
        "label": "10 AM",
        "value": 10
    },
    {
        "label": "11 AM",
        "value": 11
    },
    {
        "label": "12 PM",
        "value": 12
    },
    {
        "label": "1 PM",
        "value": 13
    },
    {
        "label": "2 PM",
        "value": 14
    },
    {
        "label": "3 PM",
        "value": 15
    },
    {
        "label": "4 PM",
        "value": 16
    },
    {
        "label": "5 PM",
        "value": 17
    },
    {
        "label": "6 PM",
        "value": 18
    },
    {
        "label": "7 PM",
        "value": 19
    },
    {
        "label": "8 PM",
        "value": 20
    },
    {
        "label": "9 PM",
        "value": 21
    },
    {
        "label": "10 PM",
        "value": 22
    },
    {
        "label": "11 PM",
        "value": 23
    }
]
const days = [

    {
        "label": "All Days",
        "value": "all_days"
    },
    {
        "label": "Monday to Friday",
        "value": "monday_to_friday"
    },
    {
        "label": "Monday",
        "value": "monday"
    },
    {
        "label": "Tuesday",
        "value": "tuesday"
    },
    {
        "label": "Wednesday",
        "value": "wednesday"
    },
    {
        "label": "Thursday",
        "value": "thursday"
    },
    {
        "label": "Friday",
        "value": "friday"
    },
    {
        "label": "Saturday",
        "value": "saturday"
    },
    {
        "label": "Sunday",
        "value": "sunday"
    }
]

export const daysMap : Map<string, string> = new Map([
    ["all_days","All Days" ],
    ["monday_to_friday", "Monday to Friday"],
    ["monday", "Monday"],
    ["tuesday", "Tuesday"],
    ["wednesday", "Wednesday"],
    ["thursday", "Thursday"],
    ["friday", "Friday"],
    ["saturday", "Saturday"],
    ["sunday", "Sunday"],
])


const Entry: React.FC<Props> = ({entry, index}) => {
    // const {campaign} = useAppSelector(state => state.advertiserOffersCpa)
    const {offer} = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()

    const handleDayFromSelect = (day: string) => {
        const newEntries = [...offer.campaign_schedule]
        const newEntry = {
            ...entry,
            from_day: day
        }
        newEntries[index] = {...newEntry}
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }

    const handleHourFromSelect = (hour: string | number) => {
        const newEntries = [...offer.campaign_schedule]
        const newEntry = {
            ...entry,
            from_hour: hour
        }
        newEntries[index] = {...newEntry}
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }

    const handleMinuteFromSelect = (minute: string) => {
        const newEntries = [...offer.campaign_schedule]
        const newEntry = {
            ...entry,
            from_minute: parseInt(minute)
        }
        newEntries[index] = {...newEntry}
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }


    const handleHourToSelect = (hour: string | number) => {
        const newEntries = [...offer.campaign_schedule]
        const newEntry = {
            ...entry,
            to_hour: hour
        }
        newEntries[index] = {...newEntry}
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }

    const handleMinuteToSelect = (minute: string) => {
        const newEntries = [...offer.campaign_schedule]
        const newEntry = {
            ...entry,
            to_minute: parseInt(minute)
        }
        newEntries[index] = {...newEntry}
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }

    const handleRemoveEntry = () => {
        const newEntries = [...offer.campaign_schedule]
        newEntries.splice(index, 1)
        dispatch(setOffer({
            campaign_schedule: [...newEntries]
        }))
    }

    return <EntryRow>
        <div className={'entry'}>
            <div className="day-select">
                <StyledSelect
                    menuAutoWidth={true}
                    selectWrapperClassname={'select-wrapper select-day-wrapper'}
                    value={days.find(day => day.value === entry.from_day)}
                    selectLabel={'Day'}
                    options={days}
                    placeholder={'Choose'}
                    //@ts-ignore
                    onChange={(value) => value && handleDayFromSelect(value.value)}
                />
            </div>
            <div className="hour-select">
                <StyledSelect
                    menuAutoWidth={true}
                    selectWrapperClassname={'select-wrapper select-hour-wrapper'}
                    selectLabel={'Hour'}
                    value={hours.find(hour => hour.value === entry.from_hour)}
                    options={hours}
                    placeholder={'00'}
                    //@ts-ignore
                    onChange={(value) => value && handleHourFromSelect(value.value)}
                />
            </div>
            <div className="minute-select">
                <StyledSelect
                    menuAutoWidth={true}
                    selectWrapperClassname={'select-wrapper select-minute-wrapper'}
                    selectLabel={'Minute'}
                    value={minutes.find(minute => minute.value === entry.from_minute)}
                    options={minutes}
                    placeholder={'00'}
                    //@ts-ignore
                    onChange={(value) => value && handleMinuteFromSelect(value.value)}
                />
            </div>
        </div>
        <span className={'to'}>to</span>
        <div className={'entry'}>
            <div className="hour-select">
                <StyledSelect
                    menuAutoWidth={true}
                    selectWrapperClassname={'select-wrapper select-hour-wrapper'}
                    selectLabel={'Hour'}
                    options={hours.slice(hours.findIndex((hour) => hour.value === entry.from_hour))}
                    value={hours.find(hour => hour.value === entry.to_hour)}
                    placeholder={'00'}
                    //@ts-ignore
                    onChange={(value) => value && handleHourToSelect(value.value)}
                />
            </div>
            <div className="minute-select">
                <StyledSelect
                    menuAutoWidth={true}
                    selectWrapperClassname={'select-wrapper select-minute-wrapper'}
                    selectLabel={'Minute'}
                    options={minutes.slice(entry.to_hour === entry.from_hour ?
                        minutes.findIndex((minute) => minute.value === entry.from_minute) : 0
                    )}
                    value={minutes.find(minute => minute.value === entry.to_minute)}
                    placeholder={'00'}
                    //@ts-ignore
                    onChange={(value) => value && handleMinuteToSelect(value.value)}
                />
            </div>

            <i className="ico-Exit" onClick={handleRemoveEntry}/>

        </div>
    </EntryRow>
}

export default Entry