import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks";
import useDebounce from "../../hooks/useDebounce";
import { ISearchProps } from "./search.props";
import { SearchWrapper } from "./Search.style";

const Search: React.FC<ISearchProps> = ({
                                            onChange,
                                            placeholder = "Search",
                                            initialValue,
                                            height= 37,
                                            width= 37,
                                            wrapperClassName= ''}
) => {
    
    const [value, setValue] = useState<string | undefined>(initialValue);
    const debouncedValue = useDebounce(value ? value : "", 500);
    const [expandInput, setExpandInput] = useState(false);
    const ref = useRef(null);
    const [input, setInput] = useState<HTMLInputElement | null>();

    useEffect(()=>{
        onChange(debouncedValue);
    }, [debouncedValue]);

    useEffect(()=>{
        expandInput && input?.focus();
        if(value !== "" ) {
            setExpandInput(true);
        }
    }, [expandInput, value]);

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
       setValue(e.target.value);
    }
    
    useOnClickOutside(ref, () => value === "" && setExpandInput(false));
    

    return(
        <SearchWrapper $height={height}
                       $width={width}
                       className={wrapperClassName}
                       expandInput={expandInput}
                       ref={ref}>
            <input ref={inputEl => setInput(inputEl)}
                   type="text"
                   onChange={(e) => searchHandler(e)}
                   value={value}
                   placeholder={placeholder} />
            { (expandInput && value && (value.length > 0)) && <i className="ico-Exit" onClick={()=> setValue("")} ></i>}
            <button className="search-button" onClick={() => setExpandInput(true)}>
                <i className="ico-Search"></i>
            </button>
        </SearchWrapper>
    )
}

export default Search;
