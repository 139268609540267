import {api} from '../../../../../common/services/api';

export interface IFetchOverviewStatsResponse {
    marketers: {
        active: string;
        productive: string;
    };
    partners: {
        active: string;
        productive: string;
    };
    sale: number;
    cost: number;
    fees: number;
    clicks: string;
    conversions: string;
    ecpa: number;
}

export interface IFetchAnalyzePerformanceDataParams {
    analyze_by: string,
    analyze_by_vs: string,
    from: Date | null | undefined | string,
    to:  Date | null | undefined | string,
    date_range: string,
    marketer_id?: number | null,
    affiliate_id?: number | null,
    platform_id?: number | null,
    merged_partner_id?: number | null
}

export interface IFetchAllMarketersResponse {
    affluent_advertiser_id : number,
    company : string,
    full_name : string,
    leap_marketer_id : number
}

export interface IFetchAllPartnersResponse {
    platform_partner_id: number | null,
    merged_partner_id: number | null,
    platform_partner_name: string | null,
    platform_partner_company: string | null,
    platform_id: number | null
}

export interface IFetchOverviewStatsParams {
    from: Date | string,
    to: Date | string,
    platform_id?: number | null,
    partner_id?: number | null,
    marketer_id?: number | null,
    merged: boolean | null
}

export interface IFetchAnalyzePerformanceResponse {
    name: string,
    sum: string | number,
    data: {
        [key: string]: string | number
    }

}

class AgencyViewService {

    static fetchOverviewStats = async (token: string, params: IFetchOverviewStatsParams) => {
        return await api.get<IFetchOverviewStatsResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_AGENCY_DASHBOARD,
            'overview'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/x-www-form-urlencoded'
                },
                params: {
                    ...params
                }
            }
        )
    }

    static fetchAllMarketers = async (token: string) => {
        return await api.get<IFetchAllMarketersResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_AGENCY_DASHBOARD,
                'get-marketers'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                }
            }
        )
    }

    static fetchAllPartners = async (token: string) => {
        return await api.get<IFetchAllPartnersResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                'all-partners'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params: {
                    only_agency: 1
                }
            }
        )
    }

    static fetchReports = async (
        token: string,
        type: string,
        params: {
            paginate_by: number,
            fromDate: string
            untilDate: string,
            page: number,
            order?: string,
            sort_by?: string
            platform_id?: number | null,
            platform_partner_id?: number | null,
            merged_partner_id?: number | null,
            marketer_id?: number | null
        },
        sum: boolean) => {
        let parameters: {
            type: string
            paginate_by: number,
            fromDate: string
            untilDate: string,
            page?: number,
            sum?: boolean,
            order?: string,
            sort_by?: string,
            platform_id?: number,
            platform_partner_id?: number,
            merged_partner_id?: number | null,
            marketer_id?: number
        } = {
            type: type,
            paginate_by: params.paginate_by,
            fromDate: params.fromDate,
            untilDate: params.untilDate,
        }
        if(params.page > 1) {
            parameters.page = params.page;
        }
        if(sum) {
            parameters.sum = sum;
        }
        if(params.order) {
            parameters.order = params.order;
            parameters.sort_by = params.sort_by;
        }

        if(params.platform_id && params.platform_partner_id) {
            parameters.platform_id = params.platform_id;
            parameters.platform_partner_id = params.platform_partner_id;
        }

        if(params.merged_partner_id) {
            parameters.merged_partner_id = params.merged_partner_id
        }

        if(params.marketer_id) {
            parameters.marketer_id = params.marketer_id;
        }
        
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "agency-dashboard/agency-report"
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params: parameters
            }
        )
    }

    static exportReportsCsv = (
        paginationColumnlist: {
            name: string,
            id: number,
            checked: boolean
        }[],
        params: {
            type: string,
            fromDate: string,
            untilDate: string,
            paginate_by: string,
            action: string,
            page?: string,
            // visible_columns?: string[],
            merged_partner_id?: string,
            platform_id?: string,
            platform_partner_id?: string,
            marketer_id?: string
        }) => {

            const filteredColumns = paginationColumnlist.filter((item: {
                name: string,
                id: number,
                checked: boolean
            }) => item.checked === true);
            const selectedColumns = filteredColumns.map((column: {
                name: string,
                id: number,
                checked: boolean
            }) => {
                return column.name;
            })

            const paramsUrl = new URLSearchParams(params);
            let columns = selectedColumns.map(function(el, idx) {
                let column = 'visible_columns[' + idx + ']=' + el
                return column.replace(/\s/g, "%20");
            }).join('&');
            
            window.open(  [
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "agency-dashboard/agency-report?"
            ].join('/') + paramsUrl + "&" + columns, "_blank");
    }

    static fetchAnalyzePerformanceData = async (token: string, params: IFetchAnalyzePerformanceDataParams) => {
        return await api.get<IFetchAnalyzePerformanceResponse[]>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_AGENCY_DASHBOARD,
            'analyze-performance'
        ].join('/'),
        {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'application/x-www-form-urlencoded'
            },
            params: {
                ...params
            }
        }
        )
    }

}

export default AgencyViewService