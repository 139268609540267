import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {getAuth, tokenStore, userStore, wasAdminStore} from "../../../../store/auth.slice";
import {
    Wrapper,
    ContentWrapper,
    PageTitle,
    ReferralsNav,
    ReferralsNavItem, ReferralsContent
} from "./Referrals.style"
import Overview from "./overview/Overview";
import ReferralReport from "./referral-report/ReferralReport";
import { init, track, setUserId } from '@amplitude/analytics-browser';

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY
amplitudeApiKey && init(amplitudeApiKey)

const Referrals : React.FC = () => {
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState<string>("overview");

    interface data {
        id: number,
        token?: string,
        data?: {},
        wasadmin?: string
    }

    // window.addEventListener("message", (event: any) => {
    //     let auth_data: data = event.data;
    //     auth_data.id === 1 && dispatch(getAuth(auth_data));
    // }, false);

    const token = useAppSelector(tokenStore)
    const user = useAppSelector(userStore);
    const wasadmin = useAppSelector(wasAdminStore);

    setUserId(user.id)

    useEffect(()=>{
        track("Referrals page visit")
    },[])

    const tabSwitchHandler = (tab: string) => {
        if (tab === "overview") {
            setActiveTab("overview");
        } else {
            setActiveTab("report");
        }
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <PageTitle>My Referrals</PageTitle>

                <ReferralsNav>
                    <ReferralsNavItem active={activeTab === "overview"} onClick={() => tabSwitchHandler("overview")}>
                        Overview
                    </ReferralsNavItem>
                    <ReferralsNavItem active={activeTab === "report"} onClick={() => tabSwitchHandler("report")}>
                        Referral Report
                    </ReferralsNavItem>
                </ReferralsNav>
                <ReferralsContent>
                    {activeTab === "overview" &&
                        <Overview/>
                    }
                    {activeTab === "report" &&
                        <ReferralReport />
                    }
                </ReferralsContent>
            </ContentWrapper>
        </Wrapper>
    )

}

export default Referrals
