import React, {useEffect, useState} from 'react'

import {
    Column, 
    ColumnRow,
    ToolbarWrapper, 
    WidgetsWrapper, 
    Wrapper
} from './NetworkView.style'
import SwitchViewDropdown from "../switch-view-dropdown/SwitchViewDropdown";
import Filters from "./filters/Filters";
import RevenueAndProfits from "./revenue-and-profits/RevenueAndProfits";
import NetworkViewService, {
    IFetchAllAccountManagersResponse,
    IFetchDashboardStats,
    IFetchDashboardStatsProps, 
    IFetchPendingPartnerRegistration,
    IPendingPartnerRegistrationParams
} from "./NetworkView.service";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import AnalyzePerformance from "./analyze-performance/AnalyzePerformance";
import TopPerformers from './top-performers/TopPerformers';
import ProfitByVertical from "./profit-by-vertical/ProfitByVertical";
import {
    setAccountManagersMarketersCurrentPage,
    setAccountManagersMarketersPerPage,
    setAccountManagersPartnersCurrentPage,
    setAccountManagersPartnersPerPage,
    setMarketersCurrentPage,
    setMarketersPerPage,
    setOffersCurrentPage,
    setOffersPerPage,
    setPagination,
    setPartnersCurrentPage,
    setPartnersPerPage,
    setTopPerformersLoading,
    setTopPerformersTableData,
    setTopPerformersTableSum
} from './NetworkView.slice';
import MarketerFundingMonitoring from "./marketer-funding-monitoring/MarketerFundingMonitoring";
import PendingPartnerRegistration from "./pending-partner-registration/PendingPartnerRegistration";

interface Props {

}

const NetworkView: React.FC<Props> = () => {
    const token = useAppSelector(
        tokenStore
    )
    const {
        selectedManagers, 
        filterType, 
        includeOrExclude,
        accountManagersMarketersPaginationColumnsList,
        accountManagersMarketersPerPage,
        accountManagersMarketersCurrentPage,
        accountManagersMarketersSort,
        accountManagersPartnersPaginationColumnsList,
        accountManagersPartnersPerPage,
        accountManagersPartnersCurrentPage,
        accountManagersPartnersSort,
        partnersPaginationColumnsList,
        partnersPerPage,
        partnersCurrentPage,
        partnersSort,
        topPerformersLoading,
        topPerformersTableData,
        topPerformersTableSum,
        marketersPaginationColumnsList,
        marketersPerPage,
        marketersCurrentPage,
        marketersSort,
        offersPaginationColumnsList,
        offersPerPage,
        offersCurrentPage,
        offersSort,
        topPerformersAnalyzeBy,
        pendingPartnerRegistrationsDays
    } = useAppSelector(state => state.networkView)

    const [dashboardData, setDashboardData]= useState<IFetchDashboardStats>({
        profit_ytd: 0,
        profit_mtd: 0,
        profit_td: 0,
        profit_yestd: 0,
        monthly_profit: [[]],
        revenue_ytd: 0,
        revenue_mtd: 0,
        revenue_td: 0,
        revenue_yestd: 0,
        last_year_profit: [[]],
        big_niches: [
            {
                name: "Downloads & Software",
                y: 0
            },
            {
                name: "Entertainment & Lifestyle",
                y: 0
            },
            {
                name: "Financial",
                y: 0
            },
            {
                name: "E-Commerce",
                y: 0
            },
            {
                name: "Healthy & Beauty",
                y: 0
            },
            {
                name: "Other",
                y: 0
            },

        ]
    })

    const [thisYearProfit, setThisYearProfit] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [lastYearProfit, setLastYearProfit] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

    const [loadingDashboardData, setLoadingDashboardData] = useState(false)
    
    const [pendingPartners, setPendingPartners] = useState<IFetchPendingPartnerRegistration>({} as IFetchPendingPartnerRegistration)

    const dispatch = useAppDispatch();

    const fetchDashboardData = async () => {
        try {

            let params = {} as IFetchDashboardStatsProps

            if(filterType === 'account-manager'){
                params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                    return item.id;
                })
                if(includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }

            }
            if(filterType === 'partner-account-manager'){
                params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                    return item.id;
                })
                if(includeOrExclude === 'excl'){
                    params['exclude_acc_mngr'] = true
                }

            }

            if(filterType === 'sales-executive'){
                params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                    return item.id;
                })
                if(includeOrExclude === 'excl'){
                    params['exclude_sale_exec'] = true
                }
            }
            // const params: IFetchDashboardStatsProps = {
            //     AccountManager: selectedManagers.map((manager: IFetchAllAccountManagersResponse) => manager.id),
            // }

            setLoadingDashboardData(true)
            const {data} = await NetworkViewService.fetchDashboardStats(params ,token)
            setDashboardData({...data, big_niches: dashboardData.big_niches.map((initialNiche, index) => {
                    let niche = {name: initialNiche.name, y: 0}
                    data.big_niches.forEach(newNiche => {
                        if(initialNiche.name === newNiche.name){
                            niche = newNiche
                        }
                    })
                    return niche
                })})

            //Get this year profit
            const tempThisYearProfit = thisYearProfit
            data.monthly_profit.forEach(profit => {
                const monthIndex = new Date(profit[0]).getUTCMonth()
                tempThisYearProfit[monthIndex] = profit[1]
            })
            setThisYearProfit([...tempThisYearProfit])

            //Get last year profit
            const tempLastYearProfit = lastYearProfit
            data.last_year_profit.forEach(profit => {
                const monthIndex = new Date(profit[0]).getUTCMonth()
                tempLastYearProfit[monthIndex] = profit[1]
            })
            setLastYearProfit([...tempLastYearProfit])


            setLoadingDashboardData(false)
        } catch (e) {
            setLoadingDashboardData(false)
            console.log(e)
        }
    }

    const fetchTopPerformersMarketersAccManagersData = async () => {
        dispatch(setTopPerformersLoading(true));

        const accMngs = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
            return item.id;
        })

        const params = {
            analyze_by: "top_marketer_acc_managers",
            page: accountManagersMarketersCurrentPage,
            per_page: accountManagersMarketersPerPage,
            sort_by: accountManagersMarketersSort.by,
            order: accountManagersMarketersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_sale_exec'] = includeOrExclude === 'excl'
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);

        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setAccountManagersMarketersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setAccountManagersMarketersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false))
    }

    const fetchTopPerformersPartnersAccManagersData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "top_partner_acc_managers",
            page: accountManagersPartnersCurrentPage,
            per_page: accountManagersPartnersPerPage,
            sort_by: accountManagersPartnersSort.by,
            order: accountManagersPartnersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_sale_exec'] = includeOrExclude === 'excl'
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);

        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setAccountManagersPartnersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setAccountManagersPartnersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false))
    }

    const fetchTopPerformersPartnersData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "partners",
            page: partnersCurrentPage,
            per_page: partnersPerPage,
            sort_by: partnersSort.by,
            order: partnersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_sale_exec'] = includeOrExclude === 'excl'
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setPartnersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setPartnersPerPage(parseInt(response.data.per_page)))
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    const fetchTopPerformersMarketersData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "marketers",
            page: marketersCurrentPage,
            per_page: marketersPerPage,
            sort_by: marketersSort.by,
            order: marketersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_sale_exec'] = includeOrExclude === 'excl'
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setMarketersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setMarketersPerPage(parseInt(response.data.per_page)))
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    const fetchTopPerformersOffersData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "offers",
            page: offersCurrentPage,
            per_page: offersPerPage,
            sort_by: offersSort.by,
            order: offersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['accountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_sale_exec'] = includeOrExclude === 'excl'
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setOffersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setOffersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }
    const fetchPendingPartnersRegistration = async () => {
        let params = {
            date_range: pendingPartnerRegistrationsDays
        } as IPendingPartnerRegistrationParams
        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            params['exclude_acc_mngr'] = includeOrExclude === 'excl'
        }
        if (pendingPartnerRegistrationsDays > 30)
        {
            params['group_by_week'] = true
        }
        const pendingPartners = await NetworkViewService.fetchPendingPartnersRegistration(token, params)
        setPendingPartners(pendingPartners.data)
    }

    const fetchPerformers = () => {
        topPerformersAnalyzeBy === "top_marketer_acc_managers" && fetchTopPerformersMarketersAccManagersData()
        topPerformersAnalyzeBy === "top_partner_acc_managers" && fetchTopPerformersPartnersAccManagersData()
        topPerformersAnalyzeBy === "affiliates" && fetchTopPerformersPartnersData()
        topPerformersAnalyzeBy === "advertisers" && fetchTopPerformersMarketersData()
        topPerformersAnalyzeBy === "offers" && fetchTopPerformersOffersData()
    }

    const fetchData = async () => {
        await Promise.all([fetchDashboardData(), fetchPerformers(), fetchPendingPartnersRegistration()]);
    }

    const fetchOnLoadData = async () => {
        await fetchDashboardData()
        
    }

    useEffect(() => {
        fetchOnLoadData()
    }, [])

    useEffect(() => {
        fetchPendingPartnersRegistration()
    }, [pendingPartnerRegistrationsDays])

    return <Wrapper>
        <ToolbarWrapper>
            <Filters fetchData={fetchData}/>
            <SwitchViewDropdown/>
        </ToolbarWrapper>
        <RevenueAndProfits data={dashboardData} loading={loadingDashboardData}/>
        <MarketerFundingMonitoring />
        <WidgetsWrapper>
            <Column>
                <TopPerformers />
                <ColumnRow>
                    <ProfitByVertical data={dashboardData.big_niches} loading={loadingDashboardData}/>
                    <PendingPartnerRegistration pendingPartners={pendingPartners}/>
                </ColumnRow>
            </Column>
            <Column>
                <AnalyzePerformance loading={loadingDashboardData} thisYearProfit={thisYearProfit} lastYearProfit={lastYearProfit}/>
            </Column>
        </WidgetsWrapper>
    </Wrapper>
}

export default NetworkView