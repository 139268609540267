import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

const allOptions: any[] = [
    {
        id: "APPROVED",
        value: "Approved"
    },
    {
        id: "PENDING",
        value: "Review"
    },
    {
        id: "REJECTED",
        value: "Denied"
    }
];

export const useTransactionStatusesFilter = (pageId: PageID): IFilterHookReturn<any> => {
    const dispatch = useAppDispatch()

    const id = 'transaction-statuses'

    const transactionStatusesFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (status: any) => {
        if(transactionStatusesFilter.selected.some((stat)  => stat.id === status.id)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: transactionStatusesFilter.exclude,
                    selected: transactionStatusesFilter.selected.filter(stat => stat.id !== status.id)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: transactionStatusesFilter.exclude,
                    selected: [...transactionStatusesFilter.selected, status]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: transactionStatusesFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allOptions,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: transactionStatusesFilter.selected,
        exclude: transactionStatusesFilter.exclude,
        setSelectedData: setSelectedData,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}