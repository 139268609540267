import { InformationTableWrapper } from "./InformationTable.style";

const InformationTable = ({children}: any) => {
    return (
        <InformationTableWrapper>
                {children}
        </InformationTableWrapper>
    )
}

export default InformationTable;