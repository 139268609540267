import styled from 'styled-components'
import {DropdownContainer} from "../../../dropdown/Dropdown.style";
import {InputSingle} from "../../../input-single-line/InputSingleLine.style";

export const DropdownWrapper = styled.div<{$opened: boolean}>`
  position: relative;
  /* display: inline-block; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  .open-dropdown-icon{
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid ${({theme, $opened}) => !$opened ?  theme.colors.coolGray[3] : theme.colors.deepBlue[3]};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      color: ${({theme, $opened}) => !$opened ? theme.colors.deepBlue[4] : theme.colors.deepBlue[6]};
      background: ${({theme, $opened}) => !$opened ? 'none' : theme.colors.deepBlue[2]};
      i{
          font-size: 12px;
      }
      
      :hover{
        border: 1px solid ${({theme, $opened}) => !$opened ? theme.colors.deepBlue[2] : theme.colors.deepBlue[3]};
        background: ${({theme, $opened}) => !$opened ? theme.colors.coolGray[2] : theme.colors.deepBlue[2]};
        cursor: pointer;
      }
  }
`

export const FilterSearch = styled(InputSingle)`
  height: 32px;
  width: 100%;
  margin-bottom: 8px;
`

export const SelectFilterContainer = styled(DropdownContainer)`
  top: 28px;
  width: 205px;
`

export const FilterList = styled.div`
  max-height: 320px;
  overflow-y: auto;
  padding-right: 2px;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background:  ${({theme}) => theme.colors.coolGray[2]};
    border-radius: 10px;
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6E6E6E;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #585858;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 8px;
  .ico-Filters {
    font-size: 15px;
    color: ${({theme}) => theme.colors.deepBlue[2]};
  }
  
  .checkbox-filter-wrapper{

      padding: 9px;
      border-radius: 8px;
      :hover{
        background-color: ${({theme}) => theme.colors.performBlue[1]};
      }
    
  }
  
  .filter-group-title{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    margin: 4px 0;
  }
  
  .clear-filters{
    position: relative;
    
    .clear-filters-tooltip{
      position: absolute;
      background: white;
      z-index: 100;
      top: -45px;
      left: -25px;
      border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
      padding: 8px 12px;
      color: ${({theme}) => theme.colors.deepBlue[5]};
      border-radius: 8px;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
  }
`