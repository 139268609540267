import React, {ReactElement} from "react";
import {CurrencyInputProps} from "react-currency-input-field";
import {Wrapper, StyledInput, LabelStyledFloatable, LabelStyledDefault, ErrorStyled, InputControl} from './CurrencyInput.style'

interface Props extends CurrencyInputProps{
    label?: string | ReactElement
    labelDefault?: boolean
    error?: string | undefined
    labelClassName?: string
    textAreaClassName?: string
    wrapperClassName?: string
    errorClassName?: string
    inputControl?: {
        up: () => void
        down: () => void
    }
}

const Input: React.FC<Props> = ({
                                    label,
                                    error,
                                    labelClassName,
                                    textAreaClassName,
                                    wrapperClassName,
                                    errorClassName,
                                    labelDefault = false,
                                    inputControl,
                                    ...rest
                                }) => {
    return <Wrapper className={wrapperClassName} error={!!error}>
        {label && <>
            {labelDefault ? (<LabelStyledDefault className={labelClassName}>{label}</LabelStyledDefault>) :
                (<LabelStyledFloatable $disabled={rest.disabled} className={labelClassName}>{label}</LabelStyledFloatable>)
            }
        </>
        }
        {inputControl && <InputControl>
            <div className="angle" onClick={inputControl.up}>
                <i className="ico-ChevronUp"/>
            </div>
            <div className="angle" onClick={inputControl.down}>
                <i className="ico-ChevronDown"/>
            </div>
        </InputControl>}
        <StyledInput className={textAreaClassName} {...rest}/>
        {error && <ErrorStyled className={errorClassName}>{error}</ErrorStyled>}
    </Wrapper>
}

export default Input

