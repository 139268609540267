import {useEffect, useMemo, useState} from "react";
import AgencyViewService from "../AgencyView.service";
import {AgencyViewTableWrapper, AnalyzeHeading} from "./styles/AgencyViewTable.style";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import {useColumnsOptionHook} from "../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import GridRowOptions from "../../../../../../common/components/grid/grid-row-options/GridRowOptions";
import Grid from "../../../../../../common/components/grid/Grid";

const allPaginationColumns = [
    {
        name: "Event Name",
        id: 1,
        checked: true,
        column_key: "event_name",
    },
    {
        name: "Company Name",
        id: 2,
        checked: true,
        column_key: "marketer_company"
    },
    {
        name: "Approved Conversions",
        id: 3,
        checked: true,
        column_key: "approved_conversions"
    },
    {
        name: "Total Conversions",
        id: 4,
        checked: true,
        column_key: "total_conversions"
    },
    {
        name: "CVR",
        id: 5,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Sale Amount",
        id: 6,
        checked: true,
        column_key: "sale_amount"
    },
    {
        name: "Cost",
        id: 7,
        checked: true,
        column_key: "cost"
    },
    {
        name: "ROAS",
        id: 8,
        checked: true,
        column_key: "roas"
    },
    {
        name: "AOV",
        id: 9,
        checked: true,
        column_key: "aov"
    },
    {
        name: "EPC",
        id: 10,
        checked: true,
        column_key: "epc"
    },
    {
        name: "ECPA",
        id: 11,
        checked: true,
        column_key: "ecpa"
    },
    {
        name: "CAC",
        id: 12,
        checked: true,
        column_key: "cac"
    },
    {
        name: "pinned",
        id: 13,
        checked: false,
        column_key: "pinned"
    },
];

const AgencyViewEventTable = ({
                                  filter_cache, sortBy, sortDirection, toggleSortDirection, tableData, tableSum,
                                  total, currentPage, setCurrentPage, from, to, perPage, setPerPage,
                                  lastPage, loading, fetchReport
                              }: any) => {

    const allColumnDefs = [
        {
            colId: 1, field: 'event_name', headerName: 'EVENT NAME', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'event_name'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('event_name');
                    }
                    }
                >EVENT NAME</SortableHeaderV2>
            },
        },
        {
            colId: 2, headerName: 'COMPANY NAME', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'marketer_company'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('marketer_company');
                    }
                    }
                >COMPANY NAME</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{data.marketer_company ? data.marketer_company : data.partner_company_name}</span>
            }
        },
        {
            colId: 3, field: 'approved_conversions', headerName: 'APPROVED CONVERSIONS', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'approved_conversions'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('approved_conversions');
                    }
                    }
                >APPROVED CONVERSIONS</SortableHeaderV2>
            },
        },
        {
            colId: 4, field: 'conversions', headerName: 'TOTAL CONVERSIONS', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'conversions'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('conversions');
                    }
                    }
                >TOTAL CONVERSIONS</SortableHeaderV2>
            },
        },
        {
            colId: 5, field: 'cvr', headerName: 'CVR', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'cvr'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('cvr');
                    }
                    }
                >CVR</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{data.cvr}%</span>
            }
        },
        {
            colId: 6, field: 'sale_amount', headerName: 'SALE/REVENUE AMOUNT', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'sale_amount'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('sale_amount');
                    }
                    }
                >SALE/REVENUE AMOUNT</SortableHeaderV2>
            },
            cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.sale_amount)}</span>
            }
        },
        {
            colId: 7, field: 'cost', headerName: 'COST', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'cost'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('cost');
                    }
                    }
                >COST</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.cost)}</span>
            }
        },
        {
            colId: 8, field: 'roas', headerName: 'ROAS', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'roas'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('roas');
                    }
                    }
                >ROAS</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{data.roas}%</span>
            }
        },
        {
            colId: 9, field: 'aov', headerName: 'AOV', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'aov'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('aov');
                    }
                    }
                >AOV</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.aov)}</span>
            }
        },
        {
            colId: 10, field: 'epc', headerName: 'EPC', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'epc'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('epc');
                    }
                    }
                >EPC</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.epc)}</span>
            }
        },
        {
            colId: 11, field: 'ecpa', headerName: 'ECPA', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'ecpa'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('ecpa');
                    }
                    }
                >ECPA</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.ecpa)}</span>
            }
        },
        {
            colId: 12, field: 'cac', headerName: 'CAC', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'cac'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('cac');
                    }
                    }
                >CAC</SortableHeaderV2>
            }, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.cac)}</span>
            }
        },
        {
            colId: 13, pinned: 'right', maxWidth: 50, resizable: false, cellRenderer: (data: any) => {
                if (data.node.rowPinned === "bottom") return <span></span>
                return <GridRowOptions>
                    <AnalyzeHeading>Analyze Partner By</AnalyzeHeading>
                    <DropdownItem onClick={() => toDynamic(data.data, "day")}>Day</DropdownItem>
                    <DropdownItem onClick={() => toDynamic(data.data, "affiliate")}>Partners</DropdownItem>
                </GridRowOptions>
            }
        }
    ]

    const [columnDefs, setColumnDefs] = useState<any[]>([...allColumnDefs]);
    const [paginationColumnsList, setPaginationColumnsList] = useState<any[]>([...allPaginationColumns]);
    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]: any = useColumnsOptionHook(
        allColumnDefs,
        columnDefs,
        setColumnDefs,
        allPaginationColumns,
        paginationColumnsList,
        setPaginationColumnsList
    );

    useEffect(() => {
        if (paginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
            setColumnDefs(allColumnDefs.filter(item => paginationColumnsList.some((it: any) => (it.id === item.colId && it.checked) || item.pinned === "right")))
        } else {
            setColumnDefs([]);
        }
    }, [sortBy, sortDirection, filter_cache, paginationColumnsList]);

    const toDynamic = (item: any = null, type: string | null = null) => {
        const api = process.env.REACT_APP_API_BASE_URL;

        let group_by: any = false;
        let from: any = false;
        let to: any = false;
        // let affiliate: any = false;
        // let advertiser: any = false;
        let event: any = false;
        let affiliates: any = null;
        let advertisers: any = false;

        if (item) {
            event = {
                id: item.event_id,
                platform_id: item.platform_id,
                name: item.event_name
            }
        }

        if (filter_cache && filter_cache.platform_partner_id) {
            // affiliate = {
            //     partner_id : filter_cache.platform_partner_id,
            //     platform_id : filter_cache.platform_id,
            //     merged_partner_id : filter_cache.merged_partner_id,
            //     merged_partner_name : filter_cache.platform_partner_company,
            //     partner_company_name : filter_cache.platform_partner_company || filter_cache.platform_partner_name
            // };
            affiliates = [{
                partner_id: filter_cache.platform_partner_id,
                platform_id: filter_cache.platform_id,
                merged_partner_id: filter_cache.merged_partner_id,
                merged_partner_name: filter_cache.platform_partner_company,
                partner_company_name: filter_cache.platform_partner_company || filter_cache.platform_partner_name
            }];
        }

        if (filter_cache && filter_cache.leap_marketer_id) {
            // advertiser = {
            //     leap_id: filter_cache.leap_marketer_id,
            //     leap_marketer_company: filter_cache.company,
            //     leap_marketer_name: filter_cache.full_name,
            // platform_advertiser_id: this.filter_cache.platform_advertiser_id,
            // platform_advertiser_name: this.filter_cache.platform_advertiser_name,
            // agency_platform_id: this.filter_cache.platform_id
            // };

            advertisers = [{
                leap_id: filter_cache.leap_marketer_id,
                leap_marketer_company: filter_cache.company,
                leap_marketer_name: filter_cache.full_name,
                // platform_advertiser_id: this.filter_cache.platform_advertiser_id,
                // platform_advertiser_name: this.filter_cache.platform_advertiser_name,
                // agency_platform_id: this.filter_cache.platform_id
            }];
        }

        if (filter_cache.fromDate) {
            from = filter_cache.fromDate;
        }

        if (filter_cache.untilDate) {
            to = filter_cache.untilDate;
        }

        if (type === 'day') {
            group_by = 'group_by_date_day_timestamp';
        } else if (type === 'affiliate') {
            group_by = 'group_by_partner_id';
        } else {
            group_by = 'group_by_upsell_id';
        }

        let all_columns = {
            approved_conversions: 'Approved Conversions',
            total_conversions: 'Total Conversions',
            revenue_amount: 'Sale Amount',
            cost: 'Cost',
            epc: 'EPC',
            roas: 'ROAS',
            aov: 'AOV',
            ecpa: 'ECPA',
            cac: 'CAC'
        };

        let additional_groups = [];

        if (paginationColumnsList && paginationColumnsList.filter((item: any) => item.checked === true && item.column_key !== "date").length > 0) {
            for (let i in all_columns) {
                if (paginationColumnsList && paginationColumnsList.some((item: any) => item.checked && item.column_key === i)) {
                    additional_groups.push(i);
                }
            }
        } else {
            additional_groups = Object.keys(all_columns);
        }

        window.parent.postMessage(
            {
                id: 4,
                params: {
                    group_by: group_by,
                    from: from,
                    to: to,
                    additional_groups: additional_groups,
                    affiliates: affiliates,
                    advertisers: advertisers,
                    event: event,
                    sort_by : sortBy,
                    sort_direction: sortDirection
                }
            }, `${api}`);
    }

    const defaultColumnDefs = useMemo(() => ({
        resizable: false,
        minWidth: 200
    }), []);

    const exportCsv = () => {
        let params: {
            type: string,
            fromDate: string,
            untilDate: string,
            paginate_by: string,
            action: string,
            page?: string,
            merged_partner_id?: string,
            platform_id?: string,
            platform_partner_id?: string,
            marketer_id?: string
        } = {
            type: "upsell",
            fromDate: filter_cache.fromDate,
            untilDate: filter_cache.untilDate,
            paginate_by: perPage.toString(),
            action: "export_csv",
        }

        if (currentPage > 1) {
            params.page = currentPage.toString();
        }

        if (filter_cache.merged_partner_id) {
            params.merged_partner_id = filter_cache.merged_partner_id.toString();
        }
        if (filter_cache.platform_id) {
            params.platform_id = filter_cache.platform_id.toString();
        }
        if (filter_cache.platform_partner_id) {
            params.platform_partner_id = filter_cache.platform_partner_id.toString();
        }
        if (filter_cache.leap_marketer_id) {
            params.marketer_id = filter_cache.leap_marketer_id.toString();
        }

        AgencyViewService.exportReportsCsv(paginationColumnsList, params);
    }

    return (
        <AgencyViewTableWrapper>
            <div className="row">
                <div className="left">
                    <div className="heading">Event</div>
                    <div className="full-report">
                        <div className="full-report-btn" onClick={() => toDynamic()}>View Full Report</div>
                    </div>
                </div>
                <div className="right">
                    <Pagination
                        current_page={parseInt(currentPage)}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={parseInt(perPage)}
                        loading={loading}
                        onRefresh={() => fetchReport()}
                        exportCsv={exportCsv}
                        columnsOptionProps={{
                            allPaginationColumns: allPaginationColumns,
                            enableDisableColumnHandler: (column: any) => enableDisableColumnHandler(column),
                            enableAllHandler: () => enableAllHandler(),
                            paginationColumnsList: paginationColumnsList,
                            setPaginationColumnsList: (paginationColumnsList: any) => setPaginationColumnsList(paginationColumnsList),
                            disableAllHandler: () => disableAllHandler()
                        }
                        }
                        perPageCustomList={[5, 10, 20, 50, 100]}
                    />
                </div>
            </div>
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowData={tableData}
                rowHeight={40}
                domLayout='autoHeight'
                loading={loading}
                enableAllColumns={enableAllHandler}
                pinnedBottomRowData={loading || tableData.length === 0 ? undefined : [tableSum]}
                fixedHeader={false}
            />
        </AgencyViewTableWrapper>
    )
}

export default AgencyViewEventTable;
