import {
    IAdminUserStatus,
    ICreativeTypeFilter,
    IDetailedStatus,
    IDeviceFilter, IOfferTypeFilter,
    IOperatingSystem, IRisks,
    ISensitivity,
    IStatusesFilter
} from "filters";


export type TSortType = 'asc' | 'desc'

export const SENSITIVITY: ISensitivity[] = [{
    id : 0,
    name: 'LOW'
},
    { id : 1,
        name: 'MEDIUM'},
    {
        id : 2,
        name: 'HIGH'
    }]

export const OFFER_STATUSES: IStatusesFilter[] = [
    {
        _id: 1,
        id: 'active',
        value: 'Active'
    },
    {
        _id: 2,
        id: 'pending',
        value: 'Pending Rep',
        rep: 0
    },
    {
        _id: 3,
        id: 'pending',
        value: 'Pending Media',
        media: 0
    },
    {
        _id: 4,
        id: 'pending',
        value: 'Pending Compliance',
        compliance: 0
    },
    {
        _id: 5,
        id: 'denied',
        value: 'Rep Denied',
        rep: -1
    },
    {
        _id: 6,
        id: 'denied',
        value: 'Compliance Denied',
        compliance: -1
    },
    {
        _id: 7,
        id: 'paused',
        value: 'Paused'
    },
    {
        _id: 8,
        id: 'inactive',
        value: 'Inactive'
    },
    {
        _id: 9,
        id: 'archived',
        value: 'Archived'
    },
]

export const CREATIVE_TYPES: ICreativeTypeFilter[] = [
    {
        id: 'product_image',
        value: 'Product Image'
    },
    {
        id: '300x250',
        value: '300x250'
    },
    {
        id: '160x600',
        value: '160x600'
    },
    {
        id: '120x600',
        value: '120x600'
    },
    {
        id: '728x90',
        value: '728x90'
    },
    {
        id: '468x60',
        value: '468x60'
    },
    {
        id: '120x60',
        value: '120x60'
    },
    {
        id: '320x50',
        value: '320x50'
    },
    {
        id: '300x50',
        value: '300x50'
    },
    {
        id: '216x36',
        value: '216x36'
    },
    {
        id: '168x28',
        value: '168x28'
    },
    {
        id: '120x20',
        value: '120x20'
    },
    {
        id: 'other_banners',
        value: 'Custom Banners'
    },
    {
        id: 'icon',
        value: 'App Icon'
    },
]

export const OPERATING_SYSTEMS: IOperatingSystem[] = [
    {
        id: 1,
        value: 'Android'
    },
    {
        id: 2,
        value: 'iOS'
    },
    {
        id: 3,
        value: 'Windows'
    },
    {
        id: 4,
        value: 'Mac'
    },
    {
        id: 5,
        value: 'Linux'
    },
    {
        id: 6,
        value: 'Undefined'
    },
]

export const STATUSES: IDetailedStatus[] = [
    {
        id: 'active_negative_balance',
        value: 'Active - Replenish Balance'
    },
    {
        id: 'paused_schedule',
        value: 'Paused - Schedule'
    },
    {
        id: 'paused_no_balance',
        value: 'Paused - No Balance'
    },
    {
        id: 'budget_exceeded',
        value: 'Paused - No Budget'
    },
    {
        id: 'paused_low_bid',
        value: 'Paused - Bid Too Low'
    },
    {
        id: 'paused_daily_spike_reached',
        value: 'Paused - Daily Limit Reach'
    },
]

export const DEVICE_TYPES: IDeviceFilter[] = [
    {
        id: "DESKTOP",
        value: 'Desktop'
    },
    {
        id: 'PHONE',
        value: 'Mobile'
    },
    {
        id: 'TABLET',
        value: 'Tablet'
    }
]

export const VERTICALS = [
    {
        name: "Financial",
        id: 1
    },
    {
        name: "Health & Beauty",
        id: 0
    },
    {
        name: "Entertainment & Lifestyle",
        id: 2
    },
    {
        name: "Downloads & Software",
        id: 5
    },
    {
        name: "E-Commerce",
        id: 6
    },
    {
        name: "Other",
        id: 4
    }
];

export const OFFER_TYPES: IOfferTypeFilter[] = [
    {
        id: "cpc",
        value: "Clicks(CPC)"
    },
    {
        id: "cpa",
        value: "Action(CPA)"
    },
    {
        id: "cpi",
        value: "App Installs(CPI)"
    },
    {
        id: "cpe",
        value: "Post App Install Engagement(CPE)"
    },
    {
        id: "cps",
        value: "Sales/Subscriptions(CPS)"
    },
    {
        id: "cpl",
        value: "Leads/Form Submission(CPL)"
    },
    {
        id: "ppc",
        value: "Customer Calls(PPC)"
    },
]

export const RISKS : IRisks[] = [
    {
        id: 0,
        name: "No Risk"
    },
    {
        id: 1,
        name: "Low"
    },
    {
        id: 2,
        name: "Medium"
    },
    {
        id: 3,
        name: "High"
    },
    {
        id: 4,
        name: "Very High"
    },
    {
        id: 5,
        name: "Paused"
    }
];

export const ADMIN_USER_STATUSES : IAdminUserStatus[] = [
    {
        id: 1,
        value: 'active',
        name: 'Active'
    },
    {
        id: 2,
        value: 'inactive',
        name: 'Inactive'
    }
    ]

export const ADMIN_USER_STATUS = {
    ACTIVE : 'active',
    INACTIVE : 'inactive',
    TERMINATED : 'terminated',
    DELETED : 'deleted'
}
export const ADMIN_USER_ACTION = {
    DISABLE : 'disable',
    ENABLE : 'enable',
    DELETE : 'delete',
    TERMINATE : 'terminate'
}

export const ADMIN_USER_STATUS_ACTIVE = 'active'
export const ADMIN_USER_STATUS_INACTIVE = 'inactive'
export const ADMIN_USER_STATUS_TERMINATED = 'terminated'
export const ADMIN_USER_STATUS_DELETED = 'deleted'

export const ADMIN_USER_ACTION_DISABLE = 'disable'
export const ADMIN_USER_ACTION_ENABLE = 'enable'
export const ADMIN_USER_ACTION_DELETE = 'delete'
export const ADMIN_USER_ACTION_TERMINATE = 'terminate'


export const IM_TYPES = [
    {value: 'facebook', label: "Facebook"},
    {value: 'twitter', label: "Twitter"},
    {value: 'skype', label: "Skype"},
    {value: 'hangout', label: "Hangout"},
    {value: 'other', label: "Other"}
]

export const QF_VISIBILITY = [
    {label: 'Full permission', value: 'full'},
    {label: 'Read-only permission', value: 'readonly'},
    {label: 'None (default for new users)', value: 'none'},
]

export const SHIELD_VISIBILITY = [
    {label: 'Full permission', value: 'full'},
    {label: 'Read-only permission', value: 'readonly'},
]

export const TRACKING_SYSTEMS = [
    {
        id: 0,
        name: "Tune"
    },
    {
        id: 1,
        name: "Impact"
    },
    {
        id: 2,
        name: "Cake"
    },
    {
        id: 3,
        name: "Everflow"
    },
    {
        id: 4,
        name: "Google Analytics"
    },
    {
        id: 5,
        name: "Other"
    },
    {
        id: 6,
        name: "None"
    },
    {
        id: 7,
        name: "Adjust"
    },
    {
        id: 8,
        name: "AppsFlyer"
    },
    {
        id: 9,
        name: "Branch"
    },
    {
        id: 10,
        name: "Kochava"
    },
    {
        id: 11,
        name: "Singular"
    },
    {
        id: 12,
        name: "Ringba"
    }
]

export const IOS_NETWORK_ID = 376
export const ANDROID_NETWORK_ID = 377


export const LAUNCH_GUIDE = "https://support.performcb.com/support/solutions/articles/4000154053-welcome-to-perform-cb-marketer-quick-start-guide#First-Things,-First!-(FAQ)"

export const ADJUST_INSTRUCTIONS = "https://docs.google.com/presentation/d/1Oei1P70fN-r-7Dw7lxXCZv0Y4_NfxgQJktC2R6DVYGY/edit?usp=share_link"
export const APPSFLYER_INSTRUCTIONS = "https://docs.google.com/presentation/d/1VPh_96rF2kn7CyxpnbHbUZ6MYENg-KrXJ7rS_Mzs6-A/edit?usp=share_link"
export const BRANCH_INSTRUCTIONS = "https://docs.google.com/presentation/d/1xGlY6Hu0ka_irO8aLk3CR0vhQwSEyHaIEQsZuyili8E/edit?usp=share_link"
export const KOCHAVA_INSTRUCTIONS = "https://docs.google.com/presentation/d/1mjRbktcdg1JgEHKtlTPwQX05uq4oLsxUCUbrcVoGLBo/edit?usp=share_link"
export const SINGULAR_INSTRUCTIONS = ""

export const ENCRYPTED_COST_MACRO = "&af_enc_data={encrypted_cost}"
