import React, {useEffect, useState} from 'react'
import {
    fetchUserPreferences,
    saveUserTablePreferences,
    IFilter,
    IPagePresetsID, IDatePreset,
} from "../../../store/userPreferences.slice";
import { ColumnState } from "ag-grid-community";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {Loading} from "./withUserPreferences.style";

export interface IWithUserPreferencesProps {
    filterPreferences: {
        [key: string] : IFilter
    },
    tablePreferences: ColumnState[],
    saveTablePreferences: (data: ColumnState[]) => void,
    datePreferences : IDatePreset
}

export const withUserPreferences = <T extends IWithUserPreferencesProps>(
    pagePresetId: IPagePresetsID,
    WrappedComponent: React.ComponentType<T>,
    defaultFilters?: {[key: string]: IFilter},
) => {

    const UserPreferences: (props: (T & IWithUserPreferencesProps)) =>
        (JSX.Element) =
        (props: T & IWithUserPreferencesProps) => {

        const [loading, setLoading] = useState(true)
        const dispatch = useAppDispatch()
        const preferences = useAppSelector(state => state.userPreferences)

        const fetchPreferences = async () => {
            setLoading(true)
            await dispatch(fetchUserPreferences({pageId: pagePresetId, filters: defaultFilters}))
            setLoading(false)
        }

        const saveTablePreferences = async (data: ColumnState[]) => {
            await dispatch(saveUserTablePreferences({
                pageId: pagePresetId,
                data
            }))
        }

        useEffect(() => {
            fetchPreferences()
        },[])

        if(loading) {
            return <Loading />
        }

        return <WrappedComponent
            {...(props as T)}
            filterPreferences={preferences[pagePresetId].filters}
            tablePreferences={preferences[pagePresetId].table}
            datePreferences={preferences[pagePresetId].date}
            saveTablePreferences={saveTablePreferences}
        />
    }

    return UserPreferences
}


