import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../common/hooks";
import {setOffer} from "../../../../../create-offer.slice";
import TextArea from "../../../../../../../../../common/components/textarea/TextArea";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";

const tooltip = <i
    className={'ico-Information-outlined'}
    data-html={true}
    // data-multiline={true}
    data-tip={ReactDOMServer.renderToString(
        <>
            <p>One entry per line</p>
        </>)}
/>

const FromLineInput: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setOffer({
            campaign_email_setting: {
                ...offer.campaign_email_setting,
                from_lines: e.target.value,
                from_lines_aff: e.target.value,
            }
        }))
    }

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return <TextArea
            style={{
                resize: 'vertical'
            }}
            label={<>From Lines <span className="input-label-optional">(optional)</span> {tooltip}</>}
            placeholder={'Enter any from lines using one line per entry'}
            value={offer.campaign_email_setting.from_lines}
            onChange={(e) => handleOnChange(e)}
        />
}

export default FromLineInput

/*
* TODO:
*  - value and onChange
* */