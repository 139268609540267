import {useCallback, useEffect, useState} from "react";

import {filterApi} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, IComplianceRepFilter} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";


export const useComplianceRepCacheFilter = (pageId: IPagePresetsID ,
                                            displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IComplianceRepFilter[]>([])
    const [complianceReps, setMediaReps] = useState<IComplianceRepFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [complianceRepsFilterSearchValue, setComplianceRepsFilterSearchValue] = useState('')
    const [complianceRepsFilterNotDisplayed, setComplianceRepsFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID = 'compliance-rep'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IComplianceRepFilter[]>(
                'admins',
                {
                    approve_sector: true
                },
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)

            const users: IComplianceRepFilter[] = []

            data.forEach(admin => {
                if(admin.approve_sector !== null && admin.approve_sector !== 'null'){
                    if(JSON.parse(admin.approve_sector)['compliance'] === 1){
                        users.push(admin)
                    }
                }
            })
            setFetchedData(
                users
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredMediaReps = fetchedData.filter(admin => {
            return (
                (admin.name + ' ' + admin.lastname).toLowerCase().includes(complianceRepsFilterSearchValue.toLowerCase())
                ||
                admin.id.toString().includes(complianceRepsFilterSearchValue.toLowerCase())
            )
        })

        if(displayCount){
            const displayedMedia = [...filteredMediaReps].slice(0, displayCount)
            if(filteredMediaReps.length > displayCount){
                setComplianceRepsFilterNotDisplayed(filteredMediaReps.length - displayCount)
            } else {
                setComplianceRepsFilterNotDisplayed(0)
            }
            setMediaReps(displayedMedia)
        } else {
            setMediaReps(
                filteredMediaReps
            )
        }
    }, [complianceRepsFilterSearchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (advertiser: IComplianceRepFilter) => {
        if(filter.selected.some((filterAdvertiser)  => filterAdvertiser.id === advertiser.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter :{ ...filter, selected: filter.selected.filter(filterAdvertiser => filterAdvertiser.id !== advertiser.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: { ...filter, selected: [...filter.selected, advertiser]
                }

            }))
        }
    }

    const setInclude = (include : boolean) => {
        dispatch(setFilterValue({
            pageId,
                filterId : id,
                filter: { ...filter, include: include}
        }))
    }


    return {
        id: id,
        options: complianceReps,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        loading: loading,
        notDisplayed: complianceRepsFilterNotDisplayed,
        search: {
            value: complianceRepsFilterSearchValue,
            setSearch: setComplianceRepsFilterSearchValue
        }
    }
}