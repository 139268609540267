import React, {useState, useRef, RefObject, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {isFirstDayOfMonth} from 'date-fns'

import {PresetDropdownToggleButton, PresetsContainer} from "./DropdownPreset.style";
import {DropdownItem} from "../../dropdown/Dropdown.style";
import {IPreset} from "../presets/presets.props";
import {useOnClickOutside} from "../../../hooks";

//Presets
const weekly: IPreset[] = [
    {
        value: 'today',
        name: 'Today'
    },
    {
        value: 'yesterday',
        name: 'Yesterday'
    },
    {
        value: 'last_7_days',
        name: 'Last 7 days'
    },
]

const monthly: IPreset[] = [
    {
        value: 'this_month',
        name: 'Month to Date',
    },
    {
        value: 'month_to_yesterday',
        name: 'Month to Yesterday',
    },
    {
        value: 'last_month',
        name: 'Last Month',
    },
]

const quarterly: IPreset[] = [
    {
        value: 'quarter_to_date',
        name: 'Quarter to Date',
    },
    {
        value: 'last_quarter',
        name: 'Last Quarter',
    },
]

const yearly: IPreset[] = [
    {
        value: 'this_year',
        name: 'Year to Date',
    },
    {
        value: 'year_to_yesterday',
        name: 'Year to Yesterday',
    },
    {
        value: 'year_to_last_full_month',
        name: 'Year to Last Full Month',
    },
    {
        value: 'last_year',
        name: 'Last Year',
    },
]

interface IDropdownPresetProps {
    preset: IPreset,
    setPreset: (preset: IPreset) => void,
    containerRef: RefObject<HTMLDivElement>,
    opened: boolean,
    setOpened: (opened: boolean) => void
}

const DropdownPreset: React.FC<IDropdownPresetProps> = ({opened, setOpened ,preset, setPreset, containerRef}) => {
    const [containerPosition, setContainerPosition] = useState({
        top: 0,
        left: 0
    })

    const toggleButtonRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(toggleButtonRef.current){
            setContainerPosition({
                top: toggleButtonRef.current.getBoundingClientRect().top  + toggleButtonRef.current.clientHeight + 5 + window.scrollY,
                left: toggleButtonRef.current.getBoundingClientRect().left + window.scrollX,
            })
        }
    }, [opened])

    useOnClickOutside(containerRef, (e) => {
        if(toggleButtonRef.current?.contains(e.target as Node)){
            return
        }
        setOpened(false)
    })

    const handlePresetSelect = (preset: IPreset) => {
        setPreset(preset)
        setOpened(false)
    }

    const isMonthlyPresetDisabled = (preset: IPreset) => {
        if(preset.value === 'month_to_yesterday' && isFirstDayOfMonth(new Date())) return true
    }

    const isYearlyPresetDisabled = (preset: IPreset) => {
        if(
            (new Date().getDate() === 1 && new Date().getMonth() === 0) &&
            (preset.value === 'year_to_yesterday' || preset.value === 'year_to_last_full_month')
        ) return true
    }

    return <>
        <PresetDropdownToggleButton onClick={() => setOpened(!opened)} ref={toggleButtonRef} active={opened}>
            {preset.name}{opened ? <i className='ico-AngleUp'/> : <i className='ico-AngleDown'/>}
        </PresetDropdownToggleButton>
        {opened && createPortal(
            <PresetsContainer style={containerPosition} ref={containerRef} opened={true}>
                <h4>Weekly</h4>
                    {weekly.map(presetValue =>
                        <DropdownItem
                            key={presetValue.value}
                            selected={presetValue.value === preset.value}
                            onClick={() => handlePresetSelect(presetValue)}
                        >
                        {presetValue.name}
                    </DropdownItem>
                    )}
                <h4>Monthly</h4>
                    {monthly.map(presetValue =>
                        !isMonthlyPresetDisabled(presetValue) && <DropdownItem
                            key={presetValue.value}
                            selected={presetValue.value === preset.value}
                            onClick={() => handlePresetSelect(presetValue)}
                            //disabled={isMonthlyPresetDisabled(presetValue)}
                        >
                            {presetValue.name}
                        </DropdownItem>
                    )}
                <h4>Quarterly</h4>
                    {quarterly.map(presetValue =>
                        <DropdownItem
                            key={presetValue.value}
                            selected={presetValue.value === preset.value}
                            onClick={() => handlePresetSelect(presetValue)}
                        >
                            {presetValue.name}
                        </DropdownItem>
                    )}
                <h4>Yearly</h4>
                    {yearly.map(presetValue =>
                        !isYearlyPresetDisabled(presetValue) && <DropdownItem
                            key={presetValue.value}
                            selected={presetValue.value === preset.value}
                            onClick={() => setPreset(presetValue)}
                            //disabled={isYearlyPresetDisabled(presetValue)}
                        >
                            {presetValue.name}
                        </DropdownItem>
                    )}
                <div className={'break'}/>
                <DropdownItem
                    onClick={() => setPreset({value: 'custom', name: 'Custom'})}
                    selected={preset.value === 'custom'}
                >
                    Custom
                </DropdownItem>
            </PresetsContainer>,
            document.body
        )
        }
    </>
}

export default DropdownPreset