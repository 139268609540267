import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 24px;
  position: relative;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);

  .title {
    line-height: 21px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 24px;
  }

  .highcharts-label, .highcharts-tooltip, .highcharts-color-undefined{
    background: red !important;
  }

  .highcharts-tooltip {
    z-index: 99999!important;
    .hc-tooltip-wrapper {
      position: relative;
      padding: 5px;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        height: calc(100% + 16px);
        width: calc(100% + 16px);
        top: -8px;
        left: -8px;
        background: #fafafa;
        z-index: 0;
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }

      .hc-tooltip-inner {
        .hc-tooltip-title {
          font-size: 10px;
          font-family: ${({theme}) => theme.fonts.AvenirLight};
          color: #31383B;
          margin-bottom: 4px;
        }
        .tooltip-value-item {
          display: flex;
          align-items: center;
          font-size: 10px;
          position: relative;
          z-index: 1;
          margin-top: 4px;
          .marker {
            border-radius: 2px;
            background: gray;
            margin-right: 5px;
            height: 8px;
            width: 8px;
          }
          strong{
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};

          }
        }
      }

      h1 {
        position: relative;
        z-index: 1;
      }
      div {
        position: relative;
        z-index: 1;
      }
    }

  }
`
export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.85);
  border-radius: 16px;
`