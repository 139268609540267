import  styled from 'styled-components';

export const ClickAgainWrapper = styled.div`
    display: inline-block;
    position: relative;

    .carret {
        position: absolute;
        z-index: 101;
        background-color: white;
        width: 10px;
        height: 10px;
        top: -30px;
        left: 40px;
        transform: rotate(45deg);
        /* box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.45); */
    }

    .tooltip {
        position: absolute;
        background: white;
        z-index: 100;
        top: -54px;
        left: -25px;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        padding: 8px 12px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
`;