import React from 'react'

import {FilterID} from "filters";
import FilterSelect from "../../../../../../common/components/filters/filter/filter-select/FilterSelect";
import {useAppSelector} from "../../../../../../common/hooks";
import {
    useAccountManagerCacheFilter,
    useAdvertisersCacheFilter,
    useComplianceRepCacheFilter,
    useCountryCacheFilter,
    useCreativeTypeCacheFilter,
    useDetailedStatusesCacheFilter,
    useDeviceTypeCacheFilter,
    useMediaRepCacheFilter,
    useNicheCacheFilter,
    useNicheGroupCacheFilter,
    useOfferStatusCacheFilter,
    useOfferTypeCacheFilter,
    useOfferVisibilityCacheFilter,
    useOperatingSystemCacheFilter,
    useSalesExecutiveCacheFilter,
    useTagsCacheFilter,
    useTrackingSystemCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import Filter from "../../../../../../common/components/filters/filter/Filter";

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: { id: FilterID, value: string }[] = [
    {
        id: 'compliance-rep',
        value: 'Compliance Rep'
    },
    {
        id: 'country-availability',
        value: 'Country Availability',
    },
    {
        id: 'creative-type',
        value: 'Creative Type'
    },
    {
        id: 'detailed-statuses',
        value: 'Offer Status',
    },
    {
        id: 'device-type',
        value: 'Device Type'
    },
    {
        id: 'account-manager',
        value: 'Engine Growth Manager'
    },
    {
        id: 'advertiser',
        value: 'Marketer Company Name'
    },
    {
        id: 'media-rep',
        value: 'Media Rep'
    },
    {
        id: 'offer-type',
        value: 'Offer Type'
    },
    // {
    //     id: 'tracking-system',
    //     value: 'Tracking System'
    // },
    {
        id: 'offer-status',
        value: 'Approval Status'
    },
    {
        id: 'offer-visibility',
        value: 'Offer Visibility'
    },
    {
        id: 'operating-system',
        value: 'Operating System'
    },
    {
        id: 'sales-executive',
        value: 'Sales Executive',
    },
    {
        id: 'tags',
        value: 'Tags'
    },
    {
        id: 'niche',
        value: 'Vertical'
    },
    {
        id: 'niche-group',
        value: 'Vertical Group'
    },
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const pageId = 'admin/offers'
    const accountManagerCacheFilter = useAccountManagerCacheFilter(pageId, 50)
    const marketerCompanyNameCacheFilter = useAdvertisersCacheFilter(pageId, 'company_name', 50)
    const countryCacheFilter = useCountryCacheFilter(pageId, 50)
    const detailedStatusesCacheFilter = useDetailedStatusesCacheFilter(pageId)
    const deviceTypeCacheFilter = useDeviceTypeCacheFilter(pageId)
    const nicheCacheFilter = useNicheCacheFilter(pageId, 50)
    const nicheGroupCacheFilter = useNicheGroupCacheFilter(pageId)
    const tagsCacheFilter = useTagsCacheFilter(pageId, 50)
    const offerTypeCacheFilter = useOfferTypeCacheFilter(pageId)
    const trackingSystemCacheFilter = useTrackingSystemCacheFilter(pageId)
    const offerVisibilityCacheFilter = useOfferVisibilityCacheFilter(pageId, 50)
    const salesExecutiveCacheFilter = useSalesExecutiveCacheFilter(pageId, 50)
    const operatingSystemCacheFilter = useOperatingSystemCacheFilter(pageId)
    const offerStatusCacheFilter = useOfferStatusCacheFilter(pageId)
    const creativeTypeCacheFilter = useCreativeTypeCacheFilter(pageId)
    const mediaRepCacheFilter = useMediaRepCacheFilter(pageId, 50)
    const complianceRepsCacheFilter = useComplianceRepCacheFilter(pageId, 50)

    const {filters} = useAppSelector((state) => state.userPreferences[pageId])

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case 'account-manager':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={accountManagerCacheFilter.id}
                        notDisplayed={accountManagerCacheFilter.notDisplayed}
                        selected={accountManagerCacheFilter.selected.value}
                        filterTitle={'Engine Growth Manager'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            accountManagerCacheFilter.selected.value[0] ?
                                accountManagerCacheFilter.selected.value[0].name + ' ' +
                                accountManagerCacheFilter.selected.value[0].lastname :
                                ''}
                        include={accountManagerCacheFilter.include}
                        search={accountManagerCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {accountManagerCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={accountManagerCacheFilter.selected.value.some(selected =>
                                    option.id === selected.id)}
                                onChange={() => {
                                    accountManagerCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                )
            case 'compliance-rep':
                return (
                    <Filter pageId={pageId}
                            filterId={complianceRepsCacheFilter.id}
                            selected={complianceRepsCacheFilter.selected.value}
                            filterTitle={'Compliance Rep'}
                            selectedFilterText={
                                complianceRepsCacheFilter.selected.value[0] ?
                                    complianceRepsCacheFilter.selected.value[0].name + ' ' +
                                    complianceRepsCacheFilter.selected.value[0].lastname :
                                    ''}
                            notDisplayed={complianceRepsCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={complianceRepsCacheFilter.include}
                            search={complianceRepsCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            complianceRepsCacheFilter.options.map(option => {
                                return <Checkbox wrapperClassName={'checkbox-filter-wrapper'}
                                                 key={option.id}
                                                 checked={complianceRepsCacheFilter.selected.value.some(selected =>
                                                     option.id === selected.id)}
                                                 onChange={() => {
                                                     complianceRepsCacheFilter.selected.setSelected(option)
                                                     setDiscoverDisplayed(true)
                                                 }}
                                                 label={option.name + " " + option.lastname}/>
                            })}
                    </Filter>
                )
            case 'media-rep':
                return (
                    <Filter pageId={pageId}
                            filterId={mediaRepCacheFilter.id}
                            selected={mediaRepCacheFilter.selected.value}
                            filterTitle={'Media Rep'}
                            selectedFilterText={mediaRepCacheFilter.selected.value[0] ?
                                mediaRepCacheFilter.selected.value[0].name + ' ' +
                                mediaRepCacheFilter.selected.value[0].lastname :
                                ''}
                            notDisplayed={mediaRepCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={mediaRepCacheFilter.include}
                            search={mediaRepCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            mediaRepCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={mediaRepCacheFilter.selected.value.some(selected =>
                                                        option.id === selected.id)}
                                                 onChange={() => {
                                                     mediaRepCacheFilter.selected.setSelected(option)
                                                     setDiscoverDisplayed(true) } }
                                                 label={option.name + " " + option.lastname}/>
                            })
                        }
                    </Filter>
                )
            case 'advertiser':
                return (
                    <Filter pageId={pageId}
                            filterId={marketerCompanyNameCacheFilter.id}
                            selected={marketerCompanyNameCacheFilter.selected.value}
                            filterTitle={'Marketer Company Name'}
                            selectedFilterText={marketerCompanyNameCacheFilter.selected.value[0] ?
                                marketerCompanyNameCacheFilter.selected.value[0].name + ' ' +
                                marketerCompanyNameCacheFilter.selected.value[0].lastname :
                                ''}
                            notDisplayed={marketerCompanyNameCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={marketerCompanyNameCacheFilter.include}
                            search={marketerCompanyNameCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            marketerCompanyNameCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={marketerCompanyNameCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      marketerCompanyNameCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.company}/>
                            })
                        }
                    </Filter>

                )
            case 'niche-group':
                return (
                    <Filter pageId={pageId}
                            filterId={nicheGroupCacheFilter.id}
                            selected={nicheGroupCacheFilter.selected.value}
                            filterTitle={'Vertical Group'}
                            selectedFilterText={nicheGroupCacheFilter.selected.value[0] ?
                                nicheGroupCacheFilter.selected.value[0].name + ' ' +
                                nicheGroupCacheFilter.selected.value[0].lastname :
                                ''}
                            notDisplayed={nicheGroupCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={nicheGroupCacheFilter.include}
                            search={nicheGroupCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            nicheGroupCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={nicheGroupCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      nicheGroupCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name}/>
                            })
                        }
                    </Filter>

                )
            case 'sales-executive':
                return (
                    <Filter pageId={pageId}
                            filterId={salesExecutiveCacheFilter.id}
                            selected={salesExecutiveCacheFilter.selected.value}
                            filterTitle={'Sales Executive'}
                            selectedFilterText={salesExecutiveCacheFilter.selected.value[0] ?
                                salesExecutiveCacheFilter.selected.value[0].name + ' ' +
                                salesExecutiveCacheFilter.selected.value[0].lastname :
                                ''}
                            notDisplayed={salesExecutiveCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={salesExecutiveCacheFilter.include}
                            search={salesExecutiveCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            salesExecutiveCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={salesExecutiveCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      salesExecutiveCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name + " " + option.lastname}/>
                            })
                        }
                    </Filter>
                )
            case "country-availability":
                return (
                    <Filter pageId={pageId}
                            filterId={countryCacheFilter.id}
                            selected={countryCacheFilter.selected.value}
                            filterTitle={'Country Availability'}
                            selectedFilterText={countryCacheFilter.selected.value[0] ?
                                countryCacheFilter.selected.value[0].name +
                                ` -  ${!('code_3c' in countryCacheFilter.selected.value[0]) ? 'US - ' : ''}` +
                                countryCacheFilter.selected.value[0].code :
                                ''}
                            notDisplayed={countryCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={countryCacheFilter.include}
                            search={countryCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            countryCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id + option.name}
                                                  checked={countryCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      countryCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name + ` -  ${!('code_3c' in option) ? 'US - ' : ''}` + option.code}/>
                            })
                        }
                    </Filter>
                )
            case 'detailed-statuses':
                return (
                    <Filter pageId={pageId}
                            filterId={detailedStatusesCacheFilter.id}
                            selected={detailedStatusesCacheFilter.selected.value}
                            filterTitle={'Offer Status'}
                            selectedFilterText={detailedStatusesCacheFilter.selected.value[0] ?
                                detailedStatusesCacheFilter.selected.value[0].value : ''}
                            notDisplayed={detailedStatusesCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={detailedStatusesCacheFilter.include}
                            search={detailedStatusesCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            detailedStatusesCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={detailedStatusesCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      detailedStatusesCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            case "device-type":
                return (
                    <Filter pageId={pageId}
                            filterId={deviceTypeCacheFilter.id}
                            selected={deviceTypeCacheFilter.selected.value}
                            filterTitle={'Device Type'}
                            selectedFilterText={deviceTypeCacheFilter.selected.value[0] ?
                                deviceTypeCacheFilter.selected.value[0].value :
                                ''}
                            notDisplayed={deviceTypeCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={deviceTypeCacheFilter.include}
                            search={deviceTypeCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            deviceTypeCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={deviceTypeCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      deviceTypeCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            case 'niche':
                return (
                    <Filter pageId={pageId}
                            filterId={nicheCacheFilter.id}
                            selected={nicheCacheFilter.selected.value}
                            filterTitle={'Vertical'}
                            selectedFilterText={nicheCacheFilter.selected.value[0] ?
                                nicheCacheFilter.selected.value[0].name  :
                                ''}
                            notDisplayed={nicheCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={nicheCacheFilter.include}
                            search={nicheCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            nicheCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={nicheCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      nicheCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name}/>
                            })
                        }
                    </Filter>
                )
            case 'tags':
                return (
                    <Filter pageId={pageId}
                            match={tagsCacheFilter.match}
                            filterId={tagsCacheFilter.id}
                            selected={tagsCacheFilter.selected.value}
                            filterTitle={'Tags'}
                            selectedFilterText={tagsCacheFilter.selected.value[0] ?
                                tagsCacheFilter.selected.value[0].name : ''
                    }
                            notDisplayed={tagsCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={tagsCacheFilter.include}
                            search={tagsCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            tagsCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={tagsCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      tagsCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name}/>
                            })
                        }
                    </Filter>
                )
            case 'offer-type':
                return (
                    <Filter pageId={pageId}
                            filterId={offerTypeCacheFilter.id}
                            selected={offerTypeCacheFilter.selected.value}
                            filterTitle={'Offer type'}
                            selectedFilterText={offerTypeCacheFilter.selected.value[0] ?
                                offerTypeCacheFilter.selected.value[0].value  :
                                ''}
                            notDisplayed={offerTypeCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={offerTypeCacheFilter.include}
                            search={offerTypeCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            offerTypeCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={offerTypeCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      offerTypeCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            // case 'tracking-system':
            //     return (
            //         <Filter pageId={pageId}
            //                 filterId={trackingSystemCacheFilter.id}
            //                 selected={trackingSystemCacheFilter.selected.value}
            //                 filterTitle={'Tracking System'}
            //                 selectedFilterText={trackingSystemCacheFilter.selected.value[0] ?
            //                     trackingSystemCacheFilter.selected.value[0].name  :
            //                     ''}
            //                 notDisplayed={trackingSystemCacheFilter.notDisplayed}
            //                 hasTopStickyContainer={true}
            //                 include={trackingSystemCacheFilter.include}
            //                 search={trackingSystemCacheFilter.search}
            //                 setDisplayDiscoverResults={setDiscoverDisplayed}
            //         >
            //             {
            //                 trackingSystemCacheFilter.options.map(option => {
            //                     return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
            //                                       key={option.id}
            //                                       checked={trackingSystemCacheFilter.selected.value.some(selected =>
            //                                           option.id === selected.id)}
            //                                       onChange={() => {
            //                                           trackingSystemCacheFilter.selected.setSelected(option)
            //                                           setDiscoverDisplayed(true) } }
            //                                       label={option.name}/>
            //                 })
            //             }
            //         </Filter>
            //     )
            case 'offer-visibility':
                return (
                    <Filter pageId={pageId}
                            filterId={offerVisibilityCacheFilter.id}
                            selected={offerVisibilityCacheFilter.selected.value}
                            filterTitle={'Offer Visibility'}
                            selectedFilterText={offerVisibilityCacheFilter.selected.value[0] ?
                                offerVisibilityCacheFilter.selected.value[0].value  :
                                ''}
                            notDisplayed={offerVisibilityCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={offerVisibilityCacheFilter.include}
                            search={offerVisibilityCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            offerVisibilityCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.name}
                                                  checked={offerVisibilityCacheFilter.selected.value.some(selected =>
                                                      option.name === selected.name)}
                                                  onChange={() => {
                                                      offerVisibilityCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name}/>
                            })
                        }
                    </Filter>
                )
            case 'operating-system':
                return (
                    <Filter pageId={pageId}
                            filterId={operatingSystemCacheFilter.id}
                            selected={operatingSystemCacheFilter.selected.value}
                            filterTitle={'Operating System'}
                            selectedFilterText={operatingSystemCacheFilter.selected.value[0] ?
                                operatingSystemCacheFilter.selected.value[0].value  :
                                ''}
                            notDisplayed={operatingSystemCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={operatingSystemCacheFilter.include}
                            search={operatingSystemCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            operatingSystemCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={operatingSystemCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      operatingSystemCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            case 'offer-status':
                return (
                    <Filter pageId={pageId}
                            filterId={offerStatusCacheFilter.id}
                            selected={offerStatusCacheFilter.selected.value}
                            filterTitle={'Approval Status'}
                            selectedFilterText={offerStatusCacheFilter.selected.value[0] ?
                                offerStatusCacheFilter.selected.value[0].value  :
                                ''}
                            notDisplayed={offerStatusCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={offerStatusCacheFilter.include}
                            search={offerStatusCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            offerStatusCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option._id}
                                                  checked={offerStatusCacheFilter.selected.value.some(selected =>
                                                      option._id === selected._id)}
                                                  onChange={() => {
                                                      offerStatusCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            case 'creative-type':
                return (
                    <Filter pageId={pageId}
                            filterId={creativeTypeCacheFilter.id}
                            selected={creativeTypeCacheFilter.selected.value}
                            filterTitle={'Creative Type'}
                            selectedFilterText={creativeTypeCacheFilter.selected.value[0] ?
                                creativeTypeCacheFilter.selected.value[0].value  :
                                ''}
                            notDisplayed={creativeTypeCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={creativeTypeCacheFilter.include}
                            search={creativeTypeCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {
                            creativeTypeCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={creativeTypeCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      creativeTypeCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.value}/>
                            })
                        }
                    </Filter>
                )
            default:
                return <></>
        }

    }

    return (
        <FilterSelect allFilters={filterList} pageId={pageId}>
            {Object.keys(filters).map((filterKey: any) => {
                return <div key={filterKey}>{renderFilter(filterKey)}</div>
            })}
        </FilterSelect>
    )
}


export default Filters