import styled, {css} from 'styled-components'

interface ITargetingSelectorOptionProps {
    $selected: boolean
}

export const Wrapper = styled.div`
    .types-section{
        display: flex;
        margin-top: 24px;
        gap: 70px;
        @media (max-width: ${({theme}) => theme.screen.xs}) {
            flex-direction: column;
            gap: 24px;
        }
        
        .traffic-types-list {
            @media (max-width: ${({theme}) => theme.screen.xs}) {
                display: flex;
                gap: 40px;
            }
        }
        .label{
            color: ${({theme}) => theme.colors.deepBlue[5]};
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            margin-bottom: 16px;
        }

        .checkbox-wrapper{
            margin: 5px 0;

            .ico-Information-outlined{
                color: ${({theme}) => theme.colors.deepBlue[5]};
            }
        }

        .device-types .device-types-list{
            display: flex;
            gap: 30px;

            @media (max-width: 700px){
                flex-direction: column;
                gap: 16px;
            }
            @media (max-width: ${({theme}) => theme.screen.xs}) {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 16px;
            }
        }

        .device-type-section > ul{
            padding-left: 20px;
        }
        
        .os-targeting-label{
            text-transform: capitalize;
        }
    }

    .include-exclude-section{
        margin-top: 24px;
        display: flex;
        gap: 24px;

        .zip-code-input-wrapper{
            margin: 0;
        }

        .btn-orange-filled{
            margin-right: 8px;
        }

        @media (max-width: 700px){
            flex-direction: column;
            gap: 24px;
        }
    }

    .include-exclude-section > div{
        width: 50%;

        h3{
            margin: 7px 0;
        }

        @media (max-width: 700px){
            width: 100%;
        }
    }

    .checkbox-wrapper-class{
        padding: 9px;
        border-radius: 8px;
        :hover{
            background-color: ${({theme}) => theme.colors.performBlue[1]};
        }
        
    }
`

export const SelectedCountries = styled.div`
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    background-color: ${({theme}) => theme.colors.coolGray[1]};
    color: ${({theme}) => theme.colors.coolGray[4]};
    border-radius: 8px;
    margin-top: 8px;
    max-height: 166px;
    overflow-x: hidden;
    overflow-y: auto;
`

export const SelectedCountryPill = styled.div`
    font-size: 12px;
    line-height: 16px;
    padding: 8px;
    border-bottom: 1px solid ${({theme}) => theme.colors.coolGray['3']};
    align-items: center;
    margin-right: 5px;
    width: 100%;
    color: ${({theme}) => theme.colors.deepBlue[3]};
    position: relative;
    transition: all .2s ease-in-out;
    display: flex;
    justify-content: space-between;
    .ico-Exit {
        color: ${({theme}) => theme.colors.performOrange['3']};
        font-size: 16px;
        margin-left: 5px;

        :hover {
            cursor: pointer;
        }
    }
`

export const TargetingSection = styled.div`
    h3 {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 12px;
        margin-bottom: 8px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
    }
    
    .targeting-select{
        width: 100%;
        height: 32px;
        font-size: 12px;
        margin-bottom: 8px;
    }
`

export const TargetingSelector = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  display: flex;
  padding: 4px;
    margin-bottom: 8px;
`

export const TargetingSelectorOption = styled.button<ITargetingSelectorOptionProps>`
  border-radius: 4px;
  flex-grow: 1;
  border: none;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: center;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  color: ${({theme}) => theme.colors.deepBlue[4]};
  background-color: ${({theme}) => theme.colors.pureWhite};
  :hover{
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    cursor: pointer;
  }
  
  ${props => props.$selected && css`
    background-color: ${({theme}) => theme.colors.performBlue[5]};
    color: white;

    :hover{
      background-color: ${({theme}) => theme.colors.performBlue[5]};
      cursor: default;
    }
  `}
`