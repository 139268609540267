import React, {useEffect, useState} from 'react'

import {useAppDispatch, useAppSelector, useFilter} from "../../../../../common/hooks";

import {Wrapper, TopSection, FiltersPaginationSection, BottomSection} from "./MarketersTransactions.style";
import DateRangeFilter from "./date-range-filter/DateRangeFilter";

import {
    ButtonStyled,
    TableWrapper,
    TableToolbarBottom,
    TableToolbarTop,
    TableToolbarTopBottomRow,
    TableToolbarTopUpperRow,

} from "../../../../../common/styled-components";
import Icon from "../../../../../common/components/icon/Icon";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import MarketersTransactionsService, {
    IFetchTransactionsResponse,
    IFetchTransactionsParams, IFetchCardsResponse
} from "./MarketersTransactions.service";
import {setPaginateBy, setSummarize} from "./MarketersTransactions.slice";
import Table from "./table/Table";
import ModalWindow from "../../../../../common/components/modalWindow/ModalWindow";
import AddTransactionModal from "./add-transaction-modal/AddTransactionModal";
import {IAdvertisersFilter} from "filters";
import Filters from "./filters/Filters";
import {format} from "date-fns";

import query from "query-string";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import {withPageContainer} from "../../../../../common/hoc/withPageContainer/withPageContainer";

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const MarketersTransactions: React.FC = () => {
    const [discoverResultDisplayed, setDiscoverResultDisplayed] = useState(false)
    const [tableData, setTableData] = useState<IFetchTransactionsResponse[]>([])
    const [sums, setSums] = useState<{credit: number, debit: number}>({
            credit: 0,
            debit: 0
    })
    const [advertisers, setAdvertisers] = useState<IAdvertisersFilter[]>([])
    const [cards, setCards] = useState<IFetchCardsResponse>({} as IFetchCardsResponse)

    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)

    const [transactionModalOpened, setTransactionModalOpened] = useState(false)

    //Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [total, setTotal] = useState(0)
    const [lastPage, setLastPage] = useState(0)

    const {token} = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const {
        paginate_by,
        sort_by,
        sort_direction,
        date_from,
        date_to,
        summarize
    } = useAppSelector(state => state.marketersTransactions)

    const filter = useFilter('admin/billing/marketer-transactions')

    const advertiserFilter = filter<IAdvertisersFilter>('advertiser')
    const transactionTypeFilter = filter<{id: string, value: string}>('transaction-type')
    const transactionStatusFilter = filter<{id: string, value: string}>('transaction-statuses')

    useEffect(() => {
        setDiscoverResultDisplayed(true)
    }, [
        advertiserFilter,
        transactionStatusFilter,
        transactionTypeFilter,
        summarize
    ])

    const generateParams = (): IFetchTransactionsParams => {
        const params: IFetchTransactionsParams = {
            paginate_by: paginate_by,
            from: date_from ? format(new Date(date_from), 'yyyy-MM-dd') : new Date(),
            to: date_to ? format(new Date(date_to), 'yyyy-MM-dd') : new Date(),
            page: currentPage
        }

        if(sort_by){
            params.sort_by = sort_by
            params.order = sort_direction
        }

        if(advertiserFilter && advertiserFilter.selected.length > 0){
            params.advertiser_id = advertiserFilter.selected[0].id
        }

        if(transactionStatusFilter && transactionStatusFilter.selected.length > 0){
            params.status = transactionStatusFilter.selected[0].id
        }

        if(transactionTypeFilter && transactionTypeFilter.selected.length > 0){
            params.type = transactionTypeFilter.selected[0].id
        }

        if(summarize){
            params.summarize = 1
            params.concat = 1
        } else {
            params.group = 0
        }

        return params
    }

    const fetchTransactions = async () => {
        setLoading(true)
        setDiscoverResultDisplayed(false)

        const params = generateParams()

        try {
            const {data} = await MarketersTransactionsService.fetchTransactions(token, params)
            setTableData(data.data)
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLastPage(data.last_page)
            setSums(data.sums)
        } catch (e){
            console.log(e)
        }
        setLoading(false)
    }

    const exportCsv = () => {
        let params = generateParams()

        params = {...params, action: 'export_csv'}

        const urlQuery = query.stringify(params)

        window.open(`${targetOrigin}/api/v1/users/billing?${urlQuery}`, '_blank')
    }

    const updateCosts = async () => {
        setUpdating(true)
        try {
            await MarketersTransactionsService.updateCosts(token)
            await fetchTransactions()
        } catch (e) {
            console.log(e)
            await fetchTransactions()
        }
        setUpdating(false)
    }

    const fetchAdvertisers = async () => {
        try {
            const {data} = await MarketersTransactionsService.fetchAdvertisers(token)
            setAdvertisers(data)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchCards = async () => {
        try {
            const {data} = await MarketersTransactionsService.fetchCards(token)
            setCards(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setDiscoverResultDisplayed(false)
        fetchCards()
        fetchAdvertisers()
    }, [])

    useEffect(() => {
        fetchTransactions()
    }, [currentPage, sort_direction, sort_by, paginate_by])

    return <TableWrapper>
        <TableToolbarTop>
            <TableToolbarTopUpperRow>
                <DateRangeFilter fetchData={fetchTransactions}/>
                <Checkbox
                    checkboxClassName={'summarize-checkbox'}
                    labelClassName={'summarize-checkbox-label'}
                    checked={summarize}
                    label={'Summarize charges by date range'}
                    onChange={() => dispatch(setSummarize(!summarize))}
                />
                {discoverResultDisplayed &&
                    <ButtonStyled
                        onClick={fetchTransactions}
                        className={'btn-discover-results'}
                    >
                        Discover Result
                        <Icon
                            size={13}
                            style={{marginLeft: '10px'}}
                            icon={'Rocket'}
                        />
                    </ButtonStyled>
                }
            </TableToolbarTopUpperRow>

            {/*<div style={{marginTop: '24px', display: 'flex'}}>*/}

            {/*</div>*/}
            <TableToolbarTopBottomRow>
                <Filters setDiscoverDisplayed={setDiscoverResultDisplayed}/>
                <div style={{
                    display: 'flex',
                    gap: '12px'
                }}>
                    <Pagination
                        current_page={currentPage}
                        from={from} to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={(perPage) => dispatch(setPaginateBy(perPage))}
                        last_page={lastPage}
                        perPage={paginate_by}
                        loading={loading}
                        perPageCustomList={[3, 50, 100, 250, 500]}
                        onRefresh={fetchTransactions}
                        exportCsv={exportCsv}
                    />
                    <ButtonStyled
                        className="btn-blue-outlined"
                        onClick={updateCosts}
                        disabled={updating}
                    >
                        {updating ? 'Updating...' : 'Recalculate'}
                    </ButtonStyled>
                    <ButtonStyled
                        className="btn-blue-filled"
                        onClick={() => setTransactionModalOpened(true)}
                    >
                        Add Transaction
                    </ButtonStyled>
                </div>
            </TableToolbarTopBottomRow>
        </TableToolbarTop>
        <Table loading={loading} data={tableData} sums={sums}/>
        <BottomSection>
            <Pagination
                current_page={currentPage}
                from={from} to={to}
                total={total}
                setCurrentPage={setCurrentPage}
                setPerPage={(perPage) => dispatch(setPaginateBy(perPage))}
                last_page={lastPage}
                perPage={paginate_by}
                loading={loading}
                perPageCustomList={[3, 50, 100, 250, 500]}
                onRefresh={fetchTransactions}
                exportCsv={() => {}}
            />
        </BottomSection>
        <ModalWindow modalWidth={600} opened={transactionModalOpened}>
            <AddTransactionModal
                fetchTransactions={fetchTransactions}
                advertisers={advertisers}
                cards={cards}
                closeModal={() => setTransactionModalOpened(false)}
            />
        </ModalWindow>
    </TableWrapper>
}

export default withPageContainer(MarketersTransactions)