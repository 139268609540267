import React from 'react';
import {FooterWrapper} from "./Footer.style";
import {GUIDE_LINK} from "../../../shared-components/Shared";
import {ButtonStyled} from "../../../../../../../common/styled-components";


export interface Props {
    showBack?: boolean,
    showNext?: boolean,
    disabled: boolean,
    onNext?: () => void,
    onBack?: () => void
}

const Footer: React.FC<Props> = ({disabled, showBack = true, showNext = true, onNext, onBack}) => {

    return (
        <FooterWrapper>
            {GUIDE_LINK}
            <div className={"footer-btn-group"}>
                {
                    showBack &&
                        <ButtonStyled onClick={onBack} className={"btn-gray-outlined"}>
                            Back
                        </ButtonStyled>

                }
                {
                    showNext ?
                    disabled ? <ButtonStyled
                        className={"btn-blue-filled"}
                        disabled={disabled}
                        onClick={onNext}
                    > Next
                    </ButtonStyled> :
                        <ButtonStyled
                            className={"btn-blue-filled"}
                            disabled={disabled}
                            onClick={onNext}
                        >
                            Next
                        </ButtonStyled>
                        : null
                }
            
            </div>
        </FooterWrapper>
    );
};

export default Footer

// style={ disabled ? {pointerEvents: "none"} : undefined}