import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterID } from "filters";

export type PageID = 'admin/offers/offers'
    | 'admin/offers'
    | 'admin/users/partners'
    | 'admin/billing/marketer-funding-monitoring'
    | 'admin/dashboard/marketer-funding-monitoring'
    | 'admin/users/marketers/id/credit'
    | 'admin/billing/partner-transactions'
    | 'admin/billing/partner-payments'
    | 'admin/billing/marketer-balance'
    | 'admin/billing/marketer-transactions'
    | 'admin/users/marketers'
    | 'admin/users/admin'

export interface IFilter<T> {
    id: FilterID
    selected: T[]
    exclude: boolean
    match?: 'any' | 'all' | 'empty'
}

export type IFiltersInitialState<T> = {
    filters:
    {
        pageID: PageID,
        data_from?: string
        date_to?: string
        filters: IFilter<T>[]
    }[]
}

const initialState: IFiltersInitialState<any> = {
    filters: [
        {
            pageID: "admin/billing/marketer-funding-monitoring",
            filters: [
                {
                    id: "auto-funding",
                    exclude: false,
                    selected: [
                        {
                            name: "Disabled",
                            id: false
                        }
                    ]
                },
                {
                    id: "pause-risk",
                    exclude: true,
                    selected: [
                        {
                            id: 0,
                            name: "No Risk"
                        }
                    ]
                },
                {
                    id: "marketer-status",
                    exclude: false,
                    selected: [
                        {
                            name: "Active",
                            id: 1,
                            status_name: "active"
                        }
                    ]
                },
                {
                    id: "account-manager",
                    exclude: false,
                    selected: [

                    ]
                }
            ]
        },
    ]
}

const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setDateFrom: (
            state: IFiltersInitialState<any>,
            action: PayloadAction<{
                date_from: string,
                pageID: PageID
            }>
        ) => {
            if (state.filters.some(filter => filter.pageID === action.payload.pageID)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageID)
                state.filters[pageIndex] = { ...state.filters[pageIndex], data_from: action.payload.date_from }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageID,
                        data_from: action.payload.date_from,
                        filters: []
                    }
                ]
            }
        },

        setDateTo: (
            state: IFiltersInitialState<any>,
            action: PayloadAction<{
                date_to: string,
                pageID: PageID
            }>
        ) => {
            if (state.filters.some(filter => filter.pageID === action.payload.pageID)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageID)
                state.filters[pageIndex] = { ...state.filters[pageIndex], date_to: action.payload.date_to }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageID,
                        date_to: action.payload.date_to,
                        filters: []
                    }
                ]
            }
        },

        setFilter: (
            state: IFiltersInitialState<any>,
            action: PayloadAction<{
                pageID: PageID,
                filter: IFilter<any>
            }>
        ) => {
            if (state.filters.some(filter => filter.pageID === action.payload.pageID)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageID)
                const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filter.id)
                if (filterIndex === -1) {
                    state.filters[pageIndex].filters.push(action.payload.filter)
                } else {
                    state.filters[pageIndex].filters[filterIndex] = action.payload.filter
                }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageID,
                        filters: [action.payload.filter]
                    }
                ]
            }
        },

        clearSelected: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)

            state.filters[pageIndex].filters[filterIndex].selected = []
        },

        singleSelect: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID, data: any }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            state.filters[pageIndex].filters[filterIndex].selected = [action.payload.data]
        },

        addFilter: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            const filter: IFilter<any> = {
                id: action.payload.filterId,
                selected: [],
                exclude: false
            }
            if (state.filters.some(filter => filter.pageID === action.payload.pageId)) {
                const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
                const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
                if (filterIndex === -1) {
                    state.filters[pageIndex].filters.push(filter)
                }
            } else {
                state.filters = [
                    ...state.filters,
                    {
                        pageID: action.payload.pageId,
                        filters: [filter]
                    }
                ]
            }
        },

        removeFilter: (state: IFiltersInitialState<any>, action: PayloadAction<{ pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            if (pageIndex === -1) return
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            if (filterIndex === -1) return
            state.filters[pageIndex].filters.splice(filterIndex, 1)
        },

        removeAllFilters: (state: IFiltersInitialState<any>, action: PayloadAction<PageID>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload)
            if (pageIndex !== -1) {
                state.filters[pageIndex].filters = []
            }
        },

        setMatch: (state: IFiltersInitialState<any>, action: PayloadAction<{ match: 'any' | 'all' | 'empty', pageId: PageID, filterId: FilterID }>) => {
            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.pageId)
            if (pageIndex === -1) return
            const filterIndex = state.filters[pageIndex].filters.findIndex(filter => filter.id === action.payload.filterId)
            state.filters[pageIndex].filters[filterIndex].match = action.payload.match
        },
        copyFilters: (state: IFiltersInitialState<any>, action: PayloadAction<{ copyFrom: PageID, copyTo: PageID, excludeFilters?: FilterID[] }>) => {
            const filtersToCopy = state.filters.find((filter) => filter.pageID === action.payload.copyFrom)

            if (!filtersToCopy) return

            const pageIndex = state.filters.findIndex(filter => filter.pageID === action.payload.copyTo)

            if (pageIndex === -1) {
                state.filters.push({
                    pageID: action.payload.copyTo,
                    filters: filtersToCopy.filters
                })
            } else {
                state.filters[pageIndex].filters = filtersToCopy.filters
            }
        }
    },
})

export default filterSlice.reducer

export const {
    setDateFrom,
    setDateTo,
    clearSelected,
    addFilter,
    removeFilter,
    removeAllFilters,
    setMatch,
    copyFilters,
    singleSelect
} = filterSlice.actions

export const setFilter = filterSlice.actions.setFilter as <T>(filter: {
    pageID: PageID,
    filter: IFilter<T>
}) => PayloadAction<{
    pageID: PageID,
    filter: IFilter<T>
}>
