import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    padding-top: 40px;

    .info {
        margin-top: 24px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: ${({theme}) => theme.colors.deepBlue[6]};
        
    }
`

export const ContentWrapper = styled.div`
    padding: 24px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    height: fit-content;
`

export const DeleteDeviceModalContainer = styled.div`
    max-width: 420px;
`

export const DeleteDeviceFooter = styled.div`
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
`

export const DeleteDeviceContent = styled.div`
    margin-top: 30px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    font-size: 14px;
`