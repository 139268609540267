import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "./common/hooks"
import { tokenStore, userStore } from "./store/auth.slice"

const PrivateRoute = ({user_type} : {user_type: string} ) => {
    const user = useAppSelector(userStore);
    const token = useAppSelector(tokenStore);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        token && setLoading(false);
    }, [token]);

    if(token && user.user_type === user_type) {
        return <Outlet />
    } else return <div style={{background: "#ffffff", height: "100vh", width: "100%"}}></div>

}

export default PrivateRoute;