import styled, {css} from "styled-components";

interface IPerPageDropdown {
    opened: boolean,
    openUp?: boolean
}

interface IPerPageSelectProps {
    active: boolean
}

interface IToolbarProps {
    $loading: boolean
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  margin-left: auto;
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 4px;
  }

  .item{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    margin-left: 5px;
      margin-right: 3px;
    transition: all .2s ease-in-out;
    position: relative;
      border: none;
      background-color: transparent;
    :hover{
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[5]};
      background-color: ${({theme}) => theme.colors.deepBlue[1]};
    }
    
    :hover:active{
      color: ${({theme}) => theme.colors.deepBlue[7]};
      background-color: ${({theme}) => theme.colors.deepBlue[2]};
    }
      &[disabled]{
          cursor: default;
          pointer-events: none;
          color: ${({theme}) => theme.colors.coolGray[4]};
      }
  }
  
  
`

export const Toolbar = styled.div<IToolbarProps>`
  display: flex;
  align-items: center;
  margin-right: 20px;
  .ico-Refresh, .ico-Download, .ico-Columns{
    font-size: 16px;
  }
  
  .ico-Refresh{
    ${props => props.$loading && css`
      animation: rotate 1s linear infinite;
    `}
  }

  @keyframes rotate {
    100% {
      transform: rotate(-360deg);
    }
  }
  
`;

export const PerPage = styled.div`
  display: flex;
  align-items: center;
    margin-left: 17px;
  
`

export const PerPageSelect = styled.button<IPerPageSelectProps>`
    font-size: 12px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray['3']};
    background-color: transparent;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;
    color: ${({theme}) => theme.colors.deepBlue['4']};
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover{
        background-color: #F3F7F8;
        transition: all .2s ease-in-out;
        cursor: pointer;
        border-color: ${({theme}) => theme.colors.coolGray[4]};
    }
    &:active{
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
        color:  ${({theme}) => theme.colors.deepBlue[6]};
        border-color: ${({theme}) => theme.colors.coolGray[5]};
    }
    &.active {
        background-color: ${({theme}) => theme.colors.deepBlue[2]};
        color:  ${({theme}) => theme.colors.deepBlue[6]};
        border-color: ${({theme}) => theme.colors.coolGray[5]};;
    }
    &[disabled]{
        cursor: default;
        pointer-events: none;
        color: ${({theme}) => theme.colors.coolGray[4]};
    }
    
  .ico-AngleDown, .ico-AngleUp{
      font-size: 8px;
    margin-left: 5px;
  }
`

export const PerPageDropdown = styled.div<IPerPageDropdown>`
  display: ${props => props.opened ? 'block' : 'none'};
  position: absolute;
  top: ${props => props.openUp ? 'auto' : '27px'};
    bottom:${props => props.openUp ? '29px' : 'auto'};
  left: 0;
  z-index: 50;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E9ECED;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  color: ${({theme}) => theme.colors.deepBlue['5']};
  
  .option{
    line-height: 16px;
    height: 32px;
    padding: 8px;
    border-radius: 8px;
    :hover{
      background-color: ${({theme}) => theme.colors.performBlue['1']};
      transition: all .2s ease-in-out;
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue['5']};
    }

    :hover:active{
      background-color: ${({theme}) => theme.colors.performBlue['8']};
      color: white;
    }
  }
  
  .selected {
    background-color: ${({theme}) => theme.colors.performBlue['8']};
    color: white;
  }
`

export const PageSelect = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;

    button{ 
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        &[disabled] {
            cursor: default;
            pointer-events: none;
            i{
                opacity: 0.4;
            }
        }
    }
  
  .ico-ChevronLeft, .ico-ChevronRight{
    font-size: 13.3px;
    :hover{
      cursor: pointer;
    }
  }
`

export const ColumnOptionDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  min-width: 228px;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border: 1px solid #E9ECED;
  background-color: ${({theme}) => theme.colors.pureWhite};
  padding: 22px 20px 5px;

  @media screen and (max-width: 769px) {
      right: auto;
      left: 0;
  }
`;