import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .column {
        display: flex;
        gap: 16px;
        .info {
            width: 112px;
            border-radius: 100px;
            text-align: center;
            font-weight: bold;
            color: #ffffff;
        }

        .text {

        }
    }
`;