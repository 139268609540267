import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`

  //Reset start
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  //Reset end

  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body{
    font-size: 12px;
    height: 100%;
    line-height: 1.42857143;
    font-family: 'Avenir LT W01_55 Roman1475520', sans-serif;
    background-color: #F9F9F9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
      &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
      }
      &::-webkit-scrollbar-track {
          background:  ${({theme}) => theme.colors.coolGray[2]};
          border-radius: 10px;
          height: 8px;
          width: 8px;
      }
      &::-webkit-scrollbar-thumb {
          background: ${({theme}) => theme.colors.coolGray[3]};
          border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
          background: #6E6E6E;
      }
      &::-webkit-scrollbar-thumb:active {
          background: #585858;
      }
  }
  
  sup {
      font-size: 75%;
      vertical-align: 0.45em;
      line-height: 1;
  }
  
  #root {
    height: 100%;
  }
  
  .modal-opened {
      &:before {
          content: "";
          position: fixed;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          z-index: 999;
      }
  }
  
  .truncate-text{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  //helpers
  .hidden, .hide {
      display: none!important;
  }
  
  a { text-decoration: none; }
  
  .link-orange {
      color: ${({theme}) => theme.colors.performOrange[6]};
      :hover {
          color: ${({theme}) => theme.colors.performOrange[7]};
      }
  }

  /**
  AG Grid horizontal scrollbar design
   */
  .ag-body-horizontal-scroll-viewport{
      min-height: 8px !important;
      max-height: 8px !important;
      height: 8px !important;
      &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
      }
      &::-webkit-scrollbar-track {
          background:  ${({theme}) => theme.colors.coolGray[2]};
          border-radius: 10px;
          height: 8px;
          width: 8px;
      }
      &::-webkit-scrollbar-thumb {
          background: ${({theme}) => theme.colors.coolGray[3]};
          border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
          background: ${({theme}) => theme.colors.deepBlue[2]};
      }
      &::-webkit-scrollbar-thumb:active {
          background: ${({theme}) => theme.colors.deepBlue[3]};
      }
  }
  .ag-body-horizontal-scroll, .ag-horizontal-left-spacer, .ag-horizontal-right-spacer  {
      overflow: auto;
      min-height: 8px !important;
      max-height: 8px !important;
      height: 8px !important;
  }
  
  strong {
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
  
  .text-capitalize {
      text-transform: capitalize;
  }
  
  
  
  b {
      font-family: ${({theme}) => theme.fonts.AvenirMedium};
  }
  
  .react-tooltip-container {
      background-color: ${({theme}) => theme.colors.pureWhite} !important;
      opacity: 1 !important;
      z-index: 99999!important;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border: 1px solid #E9ECED;
      font-size: 10px !important;
      color: ${({theme}) => theme.colors.deepBlue['5']} !important;
      padding: 16px !important;
      border-radius: 8px !important;
      strong {
          font-family: ${({theme}) => theme.fonts.AvenirHeavy} !important;
      }
  }  
  
  strong {
      font-family: ${({theme}) => theme.fonts.AvenirHeavy}
  }

  .custom-scrollbar {
      &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
      }

      &::-webkit-scrollbar-track {
          border-radius: 10px;
          height: 8px;
          width: 8px;
      }

      &::-webkit-scrollbar-thumb {
          background: ${({theme}) => theme.colors.coolGray[3]};
          border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
          background: ${({theme}) => theme.colors.deepBlue[2]};
      }

      &::-webkit-scrollbar-thumb:active {
          background: ${({theme}) => theme.colors.deepBlue[3]};
      }
  }
`
