import styled from "styled-components";


export const Container = styled.div`
    background-color: ${({theme}) => theme.colors.coolGray[1]};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    padding: 20px;
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    gap: 24px;
    @media (max-width: ${({theme}) => theme.screen.md}) {
        display: block;
        padding: 84px 20px 20px;
    }
    .react-tooltip-container {
        font-size: 12px !important;
        padding: 16px;
    }
`

export const OutletContainer = styled.div`
    width: 100%;
    @media (max-width: ${({theme}) => theme.screen.lg}) {
        margin-right: -8px;
    }
    @media (max-width: ${({theme}) => theme.screen.md}) {
        margin-right: auto;
        padding-right: 16px;
        padding-bottom: 32px;
    }
`

const handleColorType = (color: string) => {
    switch (color) {
        case "tune":
            return "#0068FF";
        case "impact":
            return "#D60E1B";
        case "cake":
            return "#0089BF";
        case "everflow":
            return "#5900CF";
        default:
            return "#fff";
    }
};

interface TrackingSystemSpanProps {
    color: string;
}

export const TrackingSystemSpan = styled.span<TrackingSystemSpanProps>`
    color: ${({color}) => handleColorType(color)};
    text-transform: capitalize;
`

export const Panel = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    border-radius: 16px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    padding: 40px;
    @media (max-width: ${({theme}) => theme.screen.md}) {
        padding: 32px;
    }
    .title {
        font-size: 24px;
        color: ${({theme}) => theme.colors.deepBlue[6]};
        text-transform: capitalize;
        margin-bottom: 8px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
    }

    .description {
        font-size: 16px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        margin-bottom: 56px;
    }
    
    .warning-alert{
        font-size: 14px;
        color: ${({theme}) => theme.colors.warning[7]};
        display: flex;
        align-items: center;
        .ico-Information {
            font-size: 20px;
            margin-right: 18px;
        }
    }
    
    .row{
        margin-top: 24px;
    }
    
    .text-description{
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-size: 16px;
    }
    
    .link {
        text-decoration: none;
        color: ${({theme}) => theme.colors.url.default};
    }

    .input-label {
    }
    
    .content-editable{
        width: 100%;
        margin: 0 8px 0 0;
        .text-area-content-editable{
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            padding: 12px;
            min-height: auto;
        }
        
        .testing {
            cursor: pointer;
            color: ${({theme}) => theme.colors.url['default']};
        }
    }

    ol {
        list-style: none;
        counter-reset: item;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: ${({theme}) => theme.colors.deepBlue[6]};

        li {
            position: relative;
            display: flex;
            counter-increment: item;
            padding-bottom: 16px;

            .list-item {
                padding-top: 8px;
                width: 100%;

                .input-wrapper {
                    margin-bottom: 0;

                    input {
                        width: 100%;
                    }
                }

                .list-item-label {
                    font-size: 16px;
                    margin-bottom: 24px;
                }

                .list-item-content{
                    display: flex;
                    align-items: center;
                    > div {
                        flex: 1;
                        margin-right: 8px;
                    }
                }

                .list-item-select-box-container {
                    display: flex;
                    @media (max-width: 767px) {
                        flex-direction: column;
                        > div {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
            &:before {
                box-shadow: 0 0 0 5px white;
                z-index: 1;
                margin-right: 19px;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                content: counter(item);
                background: ${({theme}) => theme.colors.deepBlue[3]};
                border-radius: 100%;
                color: white;
                min-width: 32px;
                min-height: 32px;
                width: 32px;
                height: 32px;
                flex-basis: 32px;
                text-align: center;
                font-family: ${({theme}) => theme.fonts.AvenirMedium};
                font-size: 18px;
            }
            
            &:after {
                z-index: 0;
                left: calc(16px);
                content: "";
                width: 1px;
                height: 100%;
                background: ${({theme}) => theme.colors.coolGray[3]};
                position: absolute;
            }
        }
    }

    
    .copy-icon-wrapper{
        position: relative;
        border-radius: 100%;
        padding: 4px 8px;
        cursor: pointer;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        :hover{
            background-color: ${({theme}) => theme.colors.coolGray[2]};
        }
        :hover:active{
            background-color: ${({theme}) => theme.colors.deepBlue[2]};
            color: ${({theme}) => theme.colors.deepBlue[6]};
        }
    }

    .tracking-system-btn {
        text-transform: uppercase;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }
    .tracking-system-span {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        text-transform: capitalize;
    }

    .tune {
        .tracking-system-span {
            color: #0068FF;
        }
        .tracking-system-btn {
            background-color: #FAFCFF;
            border-color: #0086FF;
            color: #0068FF;
            &:hover {
                background-color: #E7F3FE;
            }
            &:active {
                background-color: #BCDFFF;
            }
        }
    }

    .impact, .kochava {
        .tracking-system-span {
            color: #D60E1B;
        }
        .tracking-system-btn {
            background-color: #FFF3F6;
            border-color: #FF004D;
            color: #D60E1B;
            &:hover {
                background-color: #FFDDE5;
            }
            &:active {
                background-color: #FFB4C6;
            }
        }
    }

    .cake {
        .tracking-system-span {
            color: #0089BF;
        }
        .tracking-system-btn {
            background-color: #F3FBFF;
            border-color: #00B6FF;
            color: #0089BF;
            &:hover {
                background-color: #DBF2FF;
            }
            &:active {
                background-color: #A8E0FF;
            }
        }
    }

    .everflow {
        .tracking-system-span {
            color: #5900CF;
        }
        .tracking-system-btn {
            background-color: #F8F3FF;
            border-color: #9647FF;
            color: #5900CF;
            &:hover {
                background-color: #F0E3FF;
            }
            &:active {
                background-color: #DCC0FF;
            }
        }
    }
    
    .adjust {
        .tracking-system-span {
            color: #0029FF;
        }
        .tracking-system-btn {
            background-color: #FAFBFF;
            border-color: #4E8AFF;
            color: #0029FF;
            &:hover {
                background-color: #E5EBFF;
            }
            &:active {
                background-color: #BCCFFF;
            }
        }
    }

    .appsflyer {
        .tracking-system-span {
            color: #3FA300;
        }
        .tracking-system-btn {
            background-color: #F1FFE9;
            border-color: #7AD143;
            color: #3FA300;
            &:hover {
                background-color: #D2FFB8;
            }
            &:active {
                background-color: #A7EB80;
            }
        }
    }
    
    .branch{
        .tracking-system-span {
            color: #0089BF;
        }
        .tracking-system-btn {
            background-color: #F3FBFF;
            border-color: #00B6FF;
            color: #0089BF;
            &:hover {
                background-color: #DBF2FF;
            }
            &:active {
                background-color: #A8E0FF;
            }
        }
    }
    
    .google-analytics {
        .tracking-system-span {
            color: #E37300;
        }
        .tracking-system-btn {
            background-color: #FFF4DB;
            border-color: #F8AB00;
            color: #E37300;
            &:hover {
                background-color: #FFE6AD;
            }
            &:active {
                background-color: #FFD576;
            }
        }
    }
    
    .pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        margin-bottom: 16px;
    }
    
    .no-data {
        text-align: center;
        padding-top: 10px;
        color: ${({theme}) => theme.colors.performOrange[5]};
    }
    
    .url-content-row{
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 32px;
    }
    
    .url-content-editable{
        width: 100%;
        margin-right: 12px;
        max-width: 746px;
    }
    
    .input-label-optional {
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    
    .select-wrapper {
        margin-bottom: 0px;
    }
`

export const PanelFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 100px;
    .need-help {
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: ${({theme}) => theme.colors.deepBlue[7]};
        margin-left: 40px;

        a {
            color: #0093D3;
            cursor: pointer;
        }
    }
    .footer-btn-group{
        a:last-child {
            margin-left: 8px;
        }
    }
`

interface IDnDZoneWrapperProps {
    dragActive: boolean
}

export const DnDZoneWrapper = styled.div<IDnDZoneWrapperProps>`
    cursor: pointer;
    height: 240px;
    border: 1px dashed ${({theme, dragActive}) => dragActive ? theme.colors.performBlue[5] : theme.colors.coolGray[5]};
    background-color: ${({theme, dragActive}) => dragActive ? theme.colors.performBlue[1] : theme.colors.coolGray[2]};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h4{
        font-size: 24px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        
        span{
            color: ${({theme}) => theme.colors.performBlue[5]};
        }
    }
    
    .ico-Upload{
        font-size: 35px;
        color: ${({theme, dragActive}) => dragActive ? theme.colors.performBlue[5] : theme.colors.deepBlue[4]};
    }
    
    .supported{
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        font-size: 12px;
        margin-top: 8px;
    }
    
    .maximum{
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 12px;
        margin-top: 8px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`

export const InnerPanel = styled.div`
    margin-top: 32px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    position: relative;
    .inner-panel-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 48px;
        padding: 8px 16px;
        background-color: ${({theme}) => theme.colors.coolGray[1]};
        font-size: 14px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[5]};
        border-radius: 8px 8px 0 0;
        .remove-item {
            width: 32px;
            height: 32px;
            font-size: 16px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            background: transparent;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            transition: .2s ease all;
            &:hover {
                color: ${({theme}) => theme.colors.performOrange[5]};
                background: ${({theme}) => theme.colors.performOrange[1]};
            }
            &:active {
                color: ${({theme}) => theme.colors.performOrange[7]};
                background: ${({theme}) => theme.colors.performOrange[2]};
            }
        }
    }
    
    .inner-panel-body {
        background: #ffffff;
        border-radius: 0 0 8px 8px;
        padding: 24px 16px;
        border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        
        .inner-panel-body-description {
            font-size: 16px;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            margin-bottom: 32px;
        }
        
        .btn-add-remove-payout {
            cursor: pointer;
            margin: 0;
            padding: 0;
            border: none;
            line-height: 1;
            background: transparent;
            i {
                font-size: 15px;
                &.ico-PlusCircleFilled {
                    color: ${({theme}) => theme.colors.performBlue[5]};
                }
                &.ico-Exit {
                    color: ${({theme}) => theme.colors.performOrange[3]};
                }
            }
            &:hover {
                .ico-PlusCircleFilled {
                    color: ${({theme}) => theme.colors.performBlue[6]};
                }
                .ico-Exit {
                    color: ${({theme}) => theme.colors.performOrange[5]};
                }
            }
            &:active {
                 .ico-PlusCircleFilled {
                    color: ${({theme}) => theme.colors.performBlue[7]};
                }
                .ico-Exit {
                    color: ${({theme}) => theme.colors.performOrange[7]};
                }
            }
        }
        
        .postback-ip-info{
            h4{
                margin-top: 16px;
            }
        }
        //handle form elements
        .form-group {
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            gap: 8px;
            .form-control {
                margin: 0;
                flex: 1;
                &.form-control-payout {
                    max-width: 160px;
                }
            }
        }
    }
`

export const EventLoading =styled.div`
    position: absolute;
    top: -2px;
    display: inline-flex;
    width: 28px;
    height: 28px;
    border: 3px solid ${({theme}) => theme.colors.performOrange[4]};
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin .7s ease-in-out infinite;
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`

export const PostbackWrapper = styled.div`
    .form-control {
        margin-bottom: 8px;
    }
    .postback-url-description {
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: ${({theme}) => theme.colors.deepBlue[5]};
        margin-bottom: 10px;
    }
`

export const PostbackParamButton = styled.button`
    outline: none;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    padding: 4px 12px;
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: white;
    cursor: pointer;
    &.tracking-system-btn {
        text-transform: none;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
    }
    .material-symbols-outlined{
        font-size: 17px;
    }
`

export const AlertStyledContent = styled.div`
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 14px;
    display: flex;
    i {
        font-size: 24px;
        margin-right: 18px;
    }
    &.info {
        color: ${({theme}) => theme.colors.information[7]};
        i {
            color: ${({theme}) => theme.colors.information[6]};
        }
    }
    &.warning {
        color: ${({theme}) => theme.colors.warning[7]};
        i {
            color: ${({theme}) => theme.colors.warning[6]};
        }
    }
    //todo add more cases if needed
`

export const ServerErrorAlert = styled.div`
    position: fixed;
    bottom: 80px;
    right: 40px;
    
    .server-error-alert-wrapper{
        position: relative;
        opacity: 0.7;
        
        .ico-Exit{
            opacity: 1;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 15px;
            color: ${props => props.theme.colors.critical[2]};
            cursor: pointer;
        }
    }
`