import { useEffect, useState } from "react";
import { Option, OptionName } from "../../../select-dropdown/SelectDropdown.style";
import { ColumnItem, ColumnsOptionsBody } from "./ColumnsOption.style";

const ColumnsOption: React.FC<{
    setColumnDropdownOpened: (option: boolean) => void,
    enableDisableColumnHandler: (column: {
        name: string,
        id: number,
        checked: boolean
    }) => void,
    allPaginationColumns: {name: string, id: number, checked: boolean}[],
    enableAllHandler: () => void,
    paginationColumnsList: {
        name: string,
        id: number,
        checked: boolean
    }[],
    setPaginationColumnsList: (list: {
        name: string,
        id: number,
        checked: boolean
    }[]) => void,
    disableAllHandler: () => void
    }> = ({setColumnDropdownOpened, enableDisableColumnHandler, allPaginationColumns, enableAllHandler,
        paginationColumnsList, setPaginationColumnsList, disableAllHandler}) => {

    const [localColumnsList, setLocalColumnsList] = useState([...paginationColumnsList]);
    
    useEffect(() => {
        const newLocalList = paginationColumnsList.filter(item => localColumnsList.some(it => it.id === item.id));
        setLocalColumnsList(newLocalList);
    }, [paginationColumnsList])


    const searchHandler = (value: string) => {
        const copyColumnsList = [...paginationColumnsList];
        const newList = copyColumnsList.filter((column: any) => column.name.toLowerCase().includes(value.toLowerCase()));
        setLocalColumnsList(newList);
    }

    return(
        <ColumnsOptionsBody>
            <div className="heading">
                Adjust Columns
            </div>
            <div className="search">
                <input type="text" placeholder="Search" onChange={(e) => searchHandler(e.target.value)} />
            </div>
            <div className="list">
                {localColumnsList.map((column: {name: string, id: number, checked: boolean}) => {
                    if(column.name === "pinned") return;
                    return(
                        <ColumnItem key={column.id} onClick={() => enableDisableColumnHandler(column)}>
                            <Option isActive={column.checked} multiselect>
                                <div>
                                    <div className="checkbox" >
                                        { column.checked && <i className="ico-Check check"></i>}
                                    </div>
                                </div>
                                <OptionName>{column.name}</OptionName>
                            </Option> 
                        </ColumnItem>
                    )
                })}
            </div>
            <div className="footer">
                <div className="buttons">
                    <div className="button" onClick={() => {
                        disableAllHandler();
                        setColumnDropdownOpened(false);
                    }}>Disable all</div>
                    <div className="button" onClick={() => {
                            setColumnDropdownOpened(false);
                            enableAllHandler();
                        }}>Enable all</div>
                    <div className="button" onClick={() => {
                        setColumnDropdownOpened(false);
                        enableAllHandler();
                    }}>Reset</div>
                </div>
            </div>
        </ColumnsOptionsBody>
    )
}

export default ColumnsOption;
