import {api} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";

export interface IFetchPartnerPaymentsResponse {
    payment_id: number;
    paid_at: string;
    affiliate_id: number;
    partner_name: string;
    partner_lastname: string;
    partner_company: string;
    account_manager_name: string;
    account_manager_lastname: string;
    amount: string;
    user_created_at: string;
    start_date: string;
    end_date: string;
    vendor_id: string;
    note: string;
}

export interface IFetchPartnerPaymentsParams {
    sort_by: string,
    order: 'asc' | 'desc',
    page: number,
    paginate_by: number,
    action?: string,
    from?: string | Date,
    to?: string | Date,
    affiliate_id?: number,
    vendor_id?: string
}

export interface IFetchAffiliatesResponse {
    id: number,
    name: string,
    lastname: string,
    company: string,
    network_name: string
}

export interface IEditAddPaymentParams {
    payment_id?: number;
    paid_at?: string;
    affiliate_id?: number;
    partner_name?: string;
    partner_lastname?: string;
    partner_company?: string;
    account_manager_name?: string;
    account_manager_lastname?: string;
    amount?: number;
    user_created_at?: string;
    start_date?: string;
    end_date?: string;
    vendor_id?: string;
    note?: string;
}

class PartnerPaymentsService {
    static fetchTableData = async (token: string, params: IFetchPartnerPaymentsParams) => {
        return await api.get<IPagination<IFetchPartnerPaymentsResponse>>([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'partner_payments'
            ].join("/"),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`
                },
                params
            })
    }

    static deleteSelected = async (token: string, ids: number[]) => {
        return await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'partner_payments',
                'mass_delete'
            ].join("/"),
            ids,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static deletePayment = async (token: string, id: number) => {
        return await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'partner_payments',
                'mass_delete'
            ].join("/"),
            id,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static fetchAffiliates = async (token: string) => {
        return await api.post<IFetchAffiliatesResponse[]>([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                'affiliates',
            ].join("/"),
            {
                c: 1
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static editAddPayment = async (token: string, params: any) => {
        return await api.post<IPagination<IFetchPartnerPaymentsResponse>>([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'partner_payments'
            ].join("/"),
                params,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
    }
}

export default PartnerPaymentsService