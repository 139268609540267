import {useEffect, useState} from "react";

import {FilterID, IDetailedStatus} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {STATUSES} from "../../../utils/consts";

export const useDetailedStatusesCacheFilter = (pageId: IPagePresetsID) => {
    const [statuses, setStatuses] = useState<IDetailedStatus[]>(STATUSES)
    const [statusesSearchValue, setStatusesSearchValue] = useState('')

    const dispatch = useAppDispatch()

    const id : FilterID = 'detailed-statuses'

    useEffect(() => {
        let filteredStatuses = STATUSES.filter(status => (
                status.value.toLowerCase()).includes(statusesSearchValue.toLowerCase())
            ||
            status.id.toString().includes(statusesSearchValue.toLowerCase())
        )

        setStatuses(filteredStatuses)


    }, [statusesSearchValue])


    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IDetailedStatus) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: statuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: 0,
        search: {
            value: statusesSearchValue,
            setSearch: setStatusesSearchValue
        },
        loading : false
    }
}