import styled, {css} from "styled-components";

import {InputSingle} from "../input-single-line/InputSingleLine.style";

interface IDropdownStyleProps{
    opened: boolean,
    width?: number | undefined,
    topOffset?: number | undefined,
    heightLimit?: number | undefined,
    $overflow?: boolean | undefined
}

interface IDropdownToggleButtonProps{
    disabled?: boolean,
    active: boolean,
    $optionSelect?: boolean,
    $hasLabel? : boolean
}

interface IDropdownItemProps{
    disabled?: boolean,
    selected?: boolean,
    isDelete?: boolean
}

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

export const DropdownContainer = styled.div<IDropdownStyleProps>`
    margin-top: 2px;
  display: ${(props) => props.opened ? 'block' : 'none'};
  padding: 16px;
  ${props => props.width && css`
    width: ${props.width}px;
    top: ${props.topOffset}px;
  `}
  ${props => props.heightLimit && css`
    max-height: calc(32px * ${props.heightLimit} + 20px);
  `}
  overflow-y: ${(props) => props.$overflow ? 'auto' : 'hidden'};
  min-width: fit-content;
  border: 1px solid #E9ECED;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  background-color: white;
  //top: 35px;
  z-index: 60;
  left: 0;
`

export const DropdownItem = styled.div<IDropdownItemProps>`
  display: block;
  white-space:nowrap;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  font-size: 12px;
  color: ${({isDelete, theme}) => !isDelete ? theme.colors.deepBlue['5'] : "#FA1E3A"};
  user-select: none;
  :hover{
    background-color: ${({theme, isDelete}) => isDelete ? '#F23842' : theme.colors.performBlue['1']};
    cursor: pointer;
      color: ${({theme, isDelete}) => isDelete && 'white'}
  }

  :hover:active{
    background-color: ${({theme, isDelete}) => isDelete ? '#9D1119' : theme.colors.performBlue['8']};
    color: white;
  }
  
  ${props => props.disabled && css`
    pointer-events: none;
    color: ${({theme}) => "#D2D7D9"};
  `}

  ${props => props.selected && css`
    background-color: ${({theme}) => theme.colors.performBlue['8']};
    color: white;
    :hover{
      background-color: ${({theme}) => theme.colors.performBlue['8']};
    }
  `}
  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
`

export const DropdownItemsSeparator = styled.div `
  height: 1px;
  background-color: ${({theme}) => theme.colors.coolGray['3']};  
  margin: 10px 8px;  
`

export const DropdownToggleButton = styled.div<IDropdownToggleButtonProps>`
  display: flex;
  width: 100%;
  min-width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  min-height: 40px;
  padding: 8px 16px;
  border: 1px solid #E9ECED;
  border-radius: 8px;
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  
  .placeholder{
    color: ${({theme}) => theme.colors.coolGray[4]};
  }

  :hover{
    background-color: ${props => props.$optionSelect ? 'white' : '#F3F7F8'};
    transition: all .2s ease-in-out;
    cursor: pointer;
    border-color: ${({theme}) => theme.colors.coolGray[4]};
  }

  ${props => props.active && css`
    background-color: #F3F7F8;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    border: 1px solid ${({theme}) => theme.colors.coolGray[4]};
    
    ${props.$optionSelect && css`
      border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
      background-color: white;
      
      :hover{
        border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
        background-color: white;
      }
    `}
  `}
  
  ${props => props.disabled && css`
    border: 1px solid #e2e2e2;
    color: #b9b9b9;
    background: #e8e8e8;
    pointer-events: none;
      ${props.$hasLabel && css `
        color: ${({theme}) => theme.colors.coolGray[4]};
        border-color: ${({theme}) => theme.colors.coolGray[3]};
        background: transparent;
      `}
  `}

  &.hide-caret {
      i {
          display: none;
      }
  }
`

export const ItemsNotDisplayed = styled.div`
  padding: 4px 8px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.coolGray[3]};
  color: ${({theme}) => theme.colors.coolGray[5]};
  font-size: 10px;
  line-height: 13px;
  margin-top: 10px;
`

export const DropdownSearchInput = styled(InputSingle)`
  margin: 0;
  line-height: 22px;
`

export const HelpBlock= styled.div`
  position: absolute;
  padding: 2px 0 0 12px;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  font-size: 10px;
`

export const LabelStyled = styled.label<IDropdownToggleButtonProps>`
    position: absolute;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    line-height: 16px;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    padding: 0 4px;
    top: -8px;
    left: 8px;
    z-index: 1;
    transition: color .2s ease;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 8px;
        height: 1px;
        background: #ffffff;
        z-index: -1;
    }
    ${props => props.active && css`
        color: ${({theme}) => theme.colors.performBlue[5]};
   `}
    ${props => props.disabled && css`
        color: ${({theme}) => theme.colors.coolGray[4]};
   `}
`
export const DeleteDropdownItem = styled(DropdownItem)`
  color: ${({theme}) => theme.colors.negative[5]};
  user-select: none;

  :hover {
    background: ${({theme}) => theme.colors.negative[5]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  :hover:active {
    background-color: ${({theme}) => theme.colors.negative[7]};
    color: ${({theme}) => theme.colors.pureWhite};
  }

  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
`