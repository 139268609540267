import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import TagsList from "../../../../../common/components/tags-list/TagsList";
import React, {useEffect, useState} from "react";
import {Button, Footer, GenPassButton, Label, Wrapper} from "../home/Home.style";
import {InputWrapper, PasswordLabel} from "./Password.style";
import {PasswordInput} from "../home/inputs/Inputs";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import {tokenStore} from "../../../../../store/auth.slice";
import {closeAlert, getEditingUser, openAlert, saveUser} from "../AdvertisersEdit.slice";
import {useParams} from "react-router-dom";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";


const Password: React.FC = (): JSX.Element => {
    const {id} = useParams<{ id: string }>()
    const token = useAppSelector(tokenStore)
    const {editingUser, error, alertModal} = useAppSelector(state => state.advertisersEdit)
    const dispatch = useAppDispatch()
    const [password, setPassword] = useState<string>("")
    const [passwordConf, setPasswordConf] = useState<string>("")
    const [checked, setChecked] = useState<boolean>(false)
    const generatePassword = () => {
        const pass = Math.random().toString(36).slice(-12);
        setPassword(pass)
        setPasswordConf(pass)
    }
    const toggleCheckbox = () => {
        setChecked(!checked)
    }

    const checkChanges = () => {
        return password === passwordConf && password.length > 0
    }

    const savePassword = async () => {
        let tempEditingUser: any = {
            ...editingUser,
            all_niches: [],
            new_password: password,
            confirm_new_password: passwordConf,
        }

        if (checked) {
            tempEditingUser = {
                ...tempEditingUser,
                new_password_notify: 1
            }
        }
        if (tempEditingUser && id) {
            const response = await dispatch(saveUser({
                id: parseInt(id),
                token: token,
                user: tempEditingUser
            }))
            if (response.payload !== undefined || true) {
                dispatch(openAlert({
                    opened: true,
                    type: 'success',
                    message: "Password changed!"
                }))
                setPasswordConf("")
                setPassword("")
            } else {
                dispatch(openAlert({
                    opened: true,
                    type: 'error',
                    message: "Password not changed!"
                }))
            }

        }
    }
    // remove when contacts tabs is done
    useEffect(()=> {
        id && dispatch(getEditingUser({token: token, id: parseInt(id)}))
    },[])

    return (
            <Wrapper>
                <PasswordLabel>Change Password</PasswordLabel>
                <InputWrapper>
                    <Label>New password</Label>
                    <PasswordInput
                        password={password}
                        setPassword={setPassword}/>
                </InputWrapper>
                <InputWrapper>
                    <Label>Password Confirmation</Label>
                    <PasswordInput
                        password={passwordConf}
                        setPassword={setPasswordConf}/>
                </InputWrapper>
                <InputWrapper>
                    <Checkbox checked={checked}
                              disabled={false}
                              label={"Send new password by email to (" + editingUser?.email + ")"}
                              onChange={toggleCheckbox}>
                    </Checkbox>
                </InputWrapper>
                <Footer>
                    <GenPassButton onClick={generatePassword}>Generate Random Password</GenPassButton>
                    <Button disabled={!checkChanges()} onClick={savePassword}>Save</Button>
                </Footer>
                <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => dispatch(closeAlert())}>
                    {alertModal.message}
                </AlertModal>
            </Wrapper>
    )
}

export default Password
