import styled, {css} from "styled-components";

export const TableColumnItem = styled.div<{
    isDragging: boolean
}>`
    display: flex;
    align-items: center;
    .checkbox-filter-wrapper{
        width: 100%;
        padding: 9px;
        border-radius: 8px;
        :hover{
            background-color: ${({theme}) => theme.colors.performBlue[1]};
            padding-right: 20px;
        }
        :active:hover{
            background-color: ${({theme}) => theme.colors.performBlue[2]};
            padding-right: 20px;
        }

        background-color: ${({isDragging, theme}) => isDragging ? theme.colors.performBlue[2] : 'none'};
        border: 1px solid ${({isDragging, theme}) => isDragging ? theme.colors.performBlue[5] : 'transparent'};
        ${({isDragging}) => isDragging && css`
            box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        `}
    }
   
`;

export const TableColumnsPinButton = styled.button`
    font-size: 15px;
    background: transparent;
    border: none;
    padding: 0 8px 0 0;
    transition: .2s all ease-in-out;
    line-height: 1;
    cursor: pointer;
    i {vertical-align: -2px;}
    color: ${({theme})=> theme.colors.deepBlue[2]};
    :hover{
        color: ${({theme})=> theme.colors.deepBlue[4]};
    }
    :active:hover {
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
    &.pin{
        color: ${({theme})=> theme.colors.performOrange[3]};
        :hover{
            color: ${({theme})=> theme.colors.performOrange[6]};
        }
        :active:hover{
            color: ${({theme})=> theme.colors.performOrange[8]};
        }
    }
`

export const TableColumnItemCheckboxWrapper = styled.div<{
    isDragging: boolean
}>`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .ico-Hamburger-Menu{
        position: absolute;
        right: 12px;
        top: calc(50% - 8px);
        pointer-events: none;
        font-size: 16px;
        display: ${({isDragging}) => isDragging ? 'block' : 'none'};
        color: ${({theme}) => theme.colors.performBlue[7]};
    }
    
    :hover{
        .ico-Hamburger-Menu{
            display: block;
            color: ${({theme}) => theme.colors.performBlue[6]};
        }
    }
`