import styled from 'styled-components'

export const Wrapper = styled.div`
    .creative-label{
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        line-height: 16px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        margin-bottom: 16px;
        margin-top: 24px;
    }
    
    .horizontal-break-line{
        margin-top: 24px;
        height: 1px;
        background: ${({theme}) => theme.colors.coolGray[3]};
    }
    
    .warning{
        align-items: center;
    }
`

export const CreativesInfoWrapper = styled.div`
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    font-size: 14px;
    color: ${({theme}) => theme.colors.information[7]};
    display: flex;
    i {
        color: ${({theme}) => theme.colors.information[6]};
        font-size: 24px;
        margin-right: 18px;
    }
    
    .label {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    }
`

export const CreativeWrapper = styled.div`
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
    .header {
        height: 48px;
        align-items: center;
        padding: 0 16px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[5]};
        border-radius: 8px 8px 0 0;
        font-size: 14px;
        border-bottom: 1px solid ${({theme}) => theme.colors.performBlue[5]};
        background: linear-gradient(89.98deg, #EAFCFF 0.01%, rgba(255, 255, 255, 0) 56.45%);
        display: flex;
        justify-content: space-between;
        
        .creative-toolbar {
            display: flex;
            gap: 20px;
            button{
                background-color: transparent;
                border-width: 0;
                padding: 0;
                font-size: 16px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                cursor: pointer;
                color: ${({theme}) => theme.colors.deepBlue[4]};
            }
            
            .delete-icon-wrapper {

                :hover{
                    background-color: ${({theme}) => theme.colors.performOrange[1]};
                    color: ${({theme}) => theme.colors.performOrange[5]};
                }

                :active{
                    background-color: ${({theme}) => theme.colors.performOrange[2]};
                    color: ${({theme}) => theme.colors.performOrange[7]};
                }
            }
            
            .download-icon-wrapper {
                a {
                    text-decoration: none;
                    color: inherit;
                }
                :hover{
                    background-color: ${({theme}) => theme.colors.deepBlue[1]};
                }
                
                :active{
                    background-color: ${({theme}) => theme.colors.deepBlue[2]};
                    color: ${({theme}) => theme.colors.deepBlue[6]};
                }
            }
            
        }
    }
    
    .body{
        padding: 16px;
        
        .email-creative-name-wrapper{
            margin-bottom: 0;
            margin-top: 8px;
        }
        
        .creative-select-wrapper {
            margin-bottom: 0;
            margin-top: 24px;
        }
        
        .visual-creative-details-wrapper {
            display: flex;
            .image-thumbnail {
                width: 80px;
                height: 80px;
                border-radius: 16px;
                overflow: hidden;
                margin-right: 16px;
                transition: 1s ease all;
                position: relative;
                
                .image{
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                }

                .hover-overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transition: .2s ease-in-out all;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                }

                .ico-EyeFilled {
                    color: transparent;
                    font-size: 30px;
                }
                
                path {
                    fill: transparent;
                }

                :hover {
                    cursor: pointer;
                    .hover-overlay {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(49, 56, 59, 0.75);
                    }

                    .ico-EyeFilled {
                        color: white;
                    }
                    
                    path {
                        fill: white;
                    }
                }
            }

            .creative-details {
                line-height: 16px;
                
                .file-name {
                    color: ${({theme}) => theme.colors.deepBlue[6]};
                }
                
                .details {
                    color: ${({theme}) => theme.colors.deepBlue[4]};
                    margin-top: 4px;

                    .dot-separator{
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        margin-bottom: 2px;
                        border-radius: 50%;
                        background: ${({theme}) => theme.colors.deepBlue[4]};
                    }
                }
                
               
            }
            
        }
        
        
    }
    
    .app-icon-input-wrapper{
        margin-top: 24px;
        margin-bottom: 0;
    }
`

export const EmailCreativeThumbnailsWrapper = styled.div`
    display: flex;
    margin: 0 -12px;
    flex-wrap: wrap;
`

export const EmailCreativeThumbnailItemWrapper = styled.div`
    margin: 24px 12px 0 12px;
    height: 108px;
    width: 108px;
    border-radius: 16px;
    position: relative;
    background-color: ${({theme}) => theme.colors.deepBlue[1]};
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
    :after {
        position: absolute;
        content: '';
        top: -3px;
        right: -3px;
        width: 12px;
        height: 12px;
        background-color: white;
    }
    
    .ico-Exit{
        z-index: 1;
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 24px;
        color: ${({theme}) => theme.colors.performOrange[3]};
        
        cursor: pointer;
        
        :hover {
            color: ${({theme}) => theme.colors.performOrange[4]};
        }

        :active {
            color: ${({theme}) => theme.colors.performOrange[6]};
        }
    }
`