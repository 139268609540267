import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import DateRangeInput from "../../../../../../common/components/daterange-input/DateRangeInput";
import DropdownPreset from "../../../../../../common/components/range-date-picker/dropdown-preset/DropdownPreset";
import { IPreset } from "../../../../../../common/components/range-date-picker/presets/presets.props";
import RangeDatePicker from "../../../../../../common/components/range-date-picker/RangeDatePicker";
import { useAppDispatch, useAppSelector, useOnClickOutside } from "../../../../../../common/hooks";
import { setDateFrom, setDateRangePreset, setDateTo } from "../MarketerBalance.slice";
import { DateFilterWrapper, DatePresetWrapper, DropdownWrapper, FilterWrapper, RangeWrapper } from "./MarketerBalanceDateRange.style";

interface IMarketerBalanceDateRangeProps {
    fetchData: () => void
}

const MarketerBalanceDateRange = ({fetchData} : IMarketerBalanceDateRangeProps) => {

    const presetContainerRef = useRef<HTMLDivElement | null>(null)
    const dateRangeRef = useRef<HTMLDivElement | null>(null)

    const [presetDropdownOpened, setPresetDropdownOpened] = useState(false)
    const [dateFilterOpened, setDateFilterOpened] = useState(false)

    const {date_from, date_to, date_range_preset} = useAppSelector(state => state.marketerBalance);

    const dispatch = useAppDispatch();

    const setPreset = (preset: IPreset) => {
        dispatch(setDateRangePreset(preset));
    };

    const tempDateFrom = useRef<Date | null | undefined>(null)
    const tempDateTo = useRef<Date | null | undefined>(null)

    useEffect(() => {
        tempDateFrom.current = date_from
        tempDateTo.current = date_to
    }, [])
    

    useOnClickOutside(dateRangeRef, (e) => {
        const dateFormater = (date: string | Date | undefined | null) => {
            return format(new Date(date ? date : new Date()), "E..EEE LLL dd")
        }
        if(presetContainerRef.current?.contains(e.target as Node)){
            return
        }
        if(dateFilterOpened){
            if(tempDateFrom || tempDateTo){
                if (dateFormater(tempDateFrom.current) !== dateFormater(date_from) || 
                    dateFormater(tempDateTo.current) !== dateFormater(date_to)) {
                    fetchData()
                    tempDateFrom.current = date_from
                    tempDateTo.current = date_to
                }
            }
        }
        setPresetDropdownOpened(false)
        setDateFilterOpened(false)
    });
    
    return(
        <DropdownWrapper ref={dateRangeRef}>
                <FilterWrapper
                    onClick={(e) => setDateFilterOpened(!dateFilterOpened)}
                    $opened={dateFilterOpened}
                >
                    <i className={'ico-Calendar'}/><span>{format(date_from ? typeof date_from === 'string' ? new Date(date_from) : date_from : new Date(), 'MMM dd, y')} - {format(date_to ? typeof date_to === 'string' ? new Date(date_to) : date_to : new Date(), 'MMM dd, y')}</span>
                </FilterWrapper>
                {dateFilterOpened && 
                    <RangeWrapper>
                        <DateFilterWrapper>
                            <DatePresetWrapper>
                                <h3>Date Presets</h3>
                                <DropdownPreset
                                    containerRef={presetContainerRef}
                                    preset={date_range_preset}
                                    setPreset={setPreset}
                                    opened={presetDropdownOpened}
                                    setOpened={setPresetDropdownOpened}
                                />
                                <div className="date-range">
                                    <DateRangeInput 
                                        startDate={date_from} 
                                        setStartDate={(date: Date | null | undefined) => dispatch(setDateFrom(date))} 
                                        endDate={date_to} 
                                        setEndDate={(date: Date | null | undefined) => dispatch(setDateTo(date))}/>
                                </div>
                            </DatePresetWrapper>
                            <RangeDatePicker
                                startDate={typeof date_from === 'string' ? new Date(date_from) : date_from}
                                setStartDate={(date: Date | null | undefined) => dispatch(setDateFrom(date))} 
                                endDate={typeof date_to === 'string' ? new Date(date_to) : date_to}
                                setEndDate={(date: Date | null | undefined) => dispatch(setDateTo(date))}
                                preset={date_range_preset.value}
                                setPreset={setPreset}
                            />
                        </DateFilterWrapper>
                    </RangeWrapper>
                }
            </DropdownWrapper>
    )
}

export default MarketerBalanceDateRange;