import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    padding: 24px;
    position: relative;
    box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);
    margin-top: 24px;
    h3.title {
        line-height: 21px;
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[7]};
        margin-bottom: 24px;
        .open-report-button {
            font-size: 15px;
            padding: 3px;
            margin-left: 8px;
        }
    }
`
