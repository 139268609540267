import React, {useEffect, useState} from "react";
import InfoWidget from "./info-widget/InfoWidget";
import ShareWidget from "./share-widget/ShareWidget";
import {OverviewColLeft, OverviewColRight, OverviewContainer, WidgetHolder} from "./Overview.style";
import FaqWidget from "./faq-widget/FaqWidget";
import StatsWidget from "./stats-widget/StatsWidget";

const Overview = () => {
    return (
        <OverviewContainer>
            <OverviewColLeft>
                <InfoWidget />
                <ShareWidget />
                <FaqWidget />
            </OverviewColLeft>
            <OverviewColRight>
                <WidgetHolder>
                    <StatsWidget />
                </WidgetHolder>
            </OverviewColRight>
        </OverviewContainer>
    )
}

export default Overview;
