import {api} from '../../../../common/services/api';
import axios, {AxiosResponse} from "axios";
import {IPagination} from "../../../../common/models/pagination.type";

export interface ICustomDomainsData {
    is_default: number;
    domain: string;
    id: number;
    name_servers: string;
    status: string;
    user_id: number
}

export interface ICustomDomainsDataParams {
    current_page?: number,
    paginate_by? : number,
    search? : string,
    status? : string,
    user_id? : number,
    export_csv? : boolean,
}

export interface IAddCustomDomainResponse {
    code: number;
    name_servers: string;
    success: boolean,
    id: number
}

class CustomDomainService {
    static fetchCustomDomainsData = async (params : ICustomDomainsDataParams, token: string) => {
        try {
            return await api.get<IPagination<ICustomDomainsData>>([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    "domains"
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params
                },)
        }
        catch (e : any) {
            if(axios.isAxiosError(e)) {
                console.log(e.message, 'axios error')
            }
            return e.message
        }
    }

    static setDefaultDomain = async (token: string, id: number, is_default: number) => {
        return await api.post<ICustomDomainsData>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains",
                id,
            ].join('/'),
            { _method: 'PUT', is_default: is_default},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    static deleteDomain = async (token: string, id: number) => {
        return await api.put<ICustomDomainsData>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains",
                id,
            ].join('/'),
            { _method: 'DELETE'},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    static addCustomDomain = async (token: string, domainValue: string) => {
        return await api.post<IAddCustomDomainResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains",
            ].join('/'),
            {
                domain: domainValue
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
}

export default CustomDomainService
