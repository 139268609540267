import React, {FormEvent, useEffect, useRef, useState} from 'react'

import {Wrapper, SubmitButton} from '../TwoFactorAuthentication.style'
import logo from "../../../../assets/images/logo-home.svg";
import PhoneNumberInput from "../../../../common/components/phone-number-input/PhoneNumberInput";
import {CountryData} from "react-phone-input-2";
import Input from "../../../../common/components/input/Input";
import {useAppSelector} from "../../../../common/hooks";
import TwoFactorAuthenticationService from "../../../../common/services/2fa.service";
import {BtnLoadingSpinner} from "../../../../common/styled-components";

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const AddDevice: React.FC = () => {

    const [phoneNumber, setPhoneNumber] = useState('')
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('')
    const [dialCode, setDialCode] = useState('')
    const [code, setCode] = useState('')
    const {token, user} = useAppSelector(state => state.auth)
    const [loadingSendCode, setLoadingSendCode] = useState(false)
    const [loadingVerifyCode, setLoadingVerifyCode] = useState(false)
    const [errorVerifyCode, setErrorVerifyCode] = useState<string | null>(null)
    const [codeSent, setCodSent] = useState(false)

    const handleSendCode = async () => {
        setLoadingSendCode(true)
        try {
            const {data} = await TwoFactorAuthenticationService.sendOtpSms(token, {
                phone_number: '+' + phoneNumber,
                dial_code: dialCode
            })
            if(data.sensitive_data){
                setCode(data.sensitive_data)
            }
            setCodSent(true)
        } catch (e) {
            console.error(e)
        } finally {
            setLoadingSendCode(false)
        }

    }

    const handleVerifyCode = async () => {
        setLoadingVerifyCode(true)
        setErrorVerifyCode(null)
        try {
            const {data} = await TwoFactorAuthenticationService.verifyOtp(token, {
                phone_number: '+' + phoneNumber,
                otp: code,
                dial_code: dialCode
            })
            console.log(data)
            if(data.token){
                targetOrigin && window.parent.postMessage({id: 9, data: data.token}, targetOrigin)
            }

        } catch (e) {
            setErrorVerifyCode('The code entered is invalid, try again or send a new code.')
        } finally {
            setLoadingVerifyCode(false)
        }
    }

    return <Wrapper>
        <div>
            <img className={'perform-logo'} src={logo} alt=""/>
        </div>
        <h3 className={'authentication-setup-title'}>Two-Factor Authentication Setup</h3>
        <div className={'form-container'}>
            <div className="row">
                {codeSent ?
                    <VerifyCodeContent
                        formattedPhoneNumber={formattedPhoneNumber}
                        code={code}
                        setCode={setCode}
                        sendCode={handleSendCode}
                        error={errorVerifyCode}
                    /> :
                    <SendCodeContent
                        setFormattedPhoneNumber={setFormattedPhoneNumber}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setDialCode={setDialCode}
                    />
                }
            </div>
            <div className="row">
                {codeSent ?
                    <SubmitButton
                        onClick={handleVerifyCode}
                        disabled={code?.length < 6 || loadingVerifyCode}
                    >
                        {loadingVerifyCode && <BtnLoadingSpinner/>}Verify Code
                    </SubmitButton> :
                    <SubmitButton
                        onClick={handleSendCode}
                        disabled={phoneNumber.length < 6 || loadingSendCode}
                    >
                        {loadingSendCode && <BtnLoadingSpinner/>} Send Code
                    </SubmitButton>
                }
                {!codeSent && <p>Standard carrier message rates apply</p>}
            </div>

        </div>
    </Wrapper>
}


const SendCodeContent: React.FC<{
    phoneNumber: string,
    setPhoneNumber: (value: string) => void,
    setFormattedPhoneNumber: (value: string) => void,
    setDialCode: (value: string) => void,

}> = ({phoneNumber, setPhoneNumber, setFormattedPhoneNumber, setDialCode}) => {

    const handlePhoneNumberChange = (phoneNumber: string, formattedPhoneNumber: string, data: CountryData) => {
        setPhoneNumber(phoneNumber)
        setFormattedPhoneNumber(formattedPhoneNumber)
        if(data.dialCode ) {
            setDialCode(data.dialCode)
        }
    }

    return <>

        <div className="row" style={{textAlign: 'center'}}>
            <p>It looks like it’s your first time logging in, you need to setup a device to use <br/>
                for two-factor authentication to proceed. A security code will be sent via text <br/>
                message to your device to proceed.
            </p>
            <br/>
            <p>If multiple users access your account, each one will need to add a <br/>
                phone number to verify access.
            </p>
        </div>
        <div className="row">
            <PhoneNumberInput
                value={phoneNumber}
                onChange={(value, data, e, formattedValue) => {
                    handlePhoneNumberChange(value, formattedValue, data as CountryData)
                }}
                country={'us'}
            />
        </div>
    </>
}

const VerifyCodeContent: React.FC<{
    code: string,
    setCode: (value: string) => void,
    formattedPhoneNumber: string,
    sendCode: () => void,
    error: string | null
}> = ({code, setCode, formattedPhoneNumber, sendCode,error}) => {
    const [resendCodeEnabled, setResetCodeEnabled] = useState(true)
    const [counter, setCounter] = useState(0)
    const timer = useRef<NodeJS.Timer>()

    const handleResendCode = () => {
        setCounter(30)
        sendCode()
        setResetCodeEnabled(false)
    }

    useEffect(() => {
        if(counter > 0){
            timer.current = setInterval(() => {
                setCounter(prev => prev - 1)
            }, 1000)
        }
        if(counter === 0){
            setResetCodeEnabled(true)
            timer.current && clearInterval(timer.current)
        }

        return () => {
            timer.current && clearInterval(timer.current)
        }
    }, [counter])

    return <>
        <div className="row">
            <p>A text message has been sent with your code to: </p>
            <p>{formattedPhoneNumber}</p>
        </div>
        <div className="row">
            <Input
                wrapperClassName={'input-styled'}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                label={'6-Digit Code'}
                placeholder={'Enter code'}
                error={error ? error : ''}
                leftAlignErrorMessage={true}
            />
        </div>
        <div className={'row'}>
            <p>It may take a minute to receive your code. <br/>
                Haven’t received it yet? {resendCodeEnabled ?
                    <span className={'resend-code'} onClick={handleResendCode}>Resend Code</span> :
                    <span className={'resend-code-again'}>
                        <span className={'resend-code-disabled'}>Resend code</span> again in {counter} seconds</span>}
            </p>
        </div>
    </>
}

export default AddDevice