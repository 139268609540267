import React from 'react';
import {TrackingSystemBox, TrackingSystemHolder} from "../TrackingSystem.style";
import tuneImage from "../../../../../../../../assets/images/tracking-system/tune.png";
import {CheckedIcon} from "../../../../../../../../assets/svgs/SvgIcons";
import impactImage from "../../../../../../../../assets/images/tracking-system/impact.png";
import cakeImage from "../../../../../../../../assets/images/tracking-system/cake.png";
import everflowImage from "../../../../../../../../assets/images/tracking-system/everflow.png";
import googleImage from "../../../../../../../../assets/images/tracking-system/google.png";
import otherImage from "../../../../../../../../assets/images/tracking-system/other.svg";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {
    CAKE,
    EVERFLOW,
    GOOGLE_ANALYTICS,
    IFetchTrackingSystems,
    IMPACT, NONE, OTHER,
    TRACKING_SYSTEMS,
    TUNE
} from "../../../../interfaces/interfaces";
import {setOffer} from "../../../../create-offer.slice";
import {TrackingUrl} from "../../../../components/TrackingUrl";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {DestinationParamsSelect} from "../../../../components/DestinationParamsSelect";

export const TrackingSystemWeb: React.FC = () => {
    const dispatch = useAppDispatch()
    const {offer, trackingSystems} = useAppSelector(state => state.createOffer)
    const findTrackingSystem = (slug : TRACKING_SYSTEMS) : number | null =>{
        let ts : IFetchTrackingSystems | undefined = trackingSystems
            .find((ts : IFetchTrackingSystems) => ts.slug === slug)
        return ts ? ts.tracking_system_id : null
    }
    const handleOnClick = (type: TRACKING_SYSTEMS) => {
        type !== offer.trackingSystem && dispatch(setOffer({
            trackingSystem: type,
            preview_url: '',
            tracking_system_id : findTrackingSystem(type)
        }))
    }
    const handleAddMacro = (param: string) => {
        dispatch(setOffer({
            destination_url: offer.destination_url + param
        }))
    }
    
    const appendMarcos = () => {
        let macrosToAdd = trackingSystems.find(system => system.slug === offer.trackingSystem)?.tracking_params_and_macros
        
        // let indexQuestionMark = offer.destination_url.indexOf('?')
        //
        // if(macrosToAdd && !offer.destination_url.includes('?')) {
        //     macrosToAdd = macrosToAdd.replace('&', "?")
        // }else if (macrosToAdd){
        //     // if exist '?' in url
        //     if(indexQuestionMark == offer.destination_url.length - 1) {
        //         macrosToAdd = macrosToAdd.replace('&', "")
        //     }
        // }
        dispatch(setOffer({
            destination_url: (offer.destination_url_copy + offer.destination_custom_macros + (macrosToAdd ? macrosToAdd : '') + offer.destination_url_event_macros).replace("?&", "?"),
            destination_url_copy: offer.destination_url.split("?")[0] + "?",
            destination_url_ts_macros: macrosToAdd
        }))
    }

    return (
        <>
            <TrackingSystemHolder>
                <TrackingSystemBox active={offer.trackingSystem === TUNE}
                                   onClick={() => handleOnClick(TUNE)}>
                    <div>
                    <img src={tuneImage} alt="tune" style={{maxWidth: 126}}/>
                    <CheckedIcon display={offer.trackingSystem === TUNE ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>

                <TrackingSystemBox active={offer.trackingSystem === IMPACT}
                                   onClick={() => handleOnClick(IMPACT)}>
                    <div>
                    <img src={impactImage} alt="impact" style={{maxWidth: 134}}/>
                    <CheckedIcon display={offer.trackingSystem === IMPACT ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === CAKE}
                                   onClick={() => handleOnClick(CAKE)}>
                    <div>
                    <img src={cakeImage} alt="cake" style={{maxWidth: 124}}/>
                    <CheckedIcon display={offer.trackingSystem === CAKE ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === EVERFLOW}
                                   onClick={() => handleOnClick(EVERFLOW)}>
                    <div>
                    <img src={everflowImage} alt="everflow" style={{maxWidth: 108}}/>
                    <CheckedIcon display={offer.trackingSystem === EVERFLOW ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === GOOGLE_ANALYTICS}
                                   onClick={() => handleOnClick(GOOGLE_ANALYTICS)}>
                    <div>
                    <img src={googleImage} alt="google" style={{maxWidth: 134}}/>
                    <CheckedIcon display={offer.trackingSystem === GOOGLE_ANALYTICS ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === OTHER}
                                   onClick={() => handleOnClick(OTHER)}>
                    <div>
                        <img src={otherImage} alt="other" style={{maxWidth: 86}}/>
                        <div className={"tracking-system-other"}>
                            Other
                        </div>
                    
                    <CheckedIcon display={offer.trackingSystem === OTHER ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === NONE}
                                   onClick={() => handleOnClick(NONE)}>
                    <div>
                    <span className={"none"}>None</span>
                    <CheckedIcon display={offer.trackingSystem === NONE ? 'block' : 'none'}
                                 className={"checked-icon"}/>
                        </div>
                </TrackingSystemBox>
            </TrackingSystemHolder>
            {(offer.trackingSystem !== NONE && offer.trackingSystem !== GOOGLE_ANALYTICS && offer.trackingSystem) &&
                <div className={offer.trackingSystem}>
                    <ol>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>Visit
                                    <span className="text-capitalize"> {offer.trackingSystem}</span> and find your
                                    tracking link for this campaign and copy it to your clipboard
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>Using the field below paste the link from
                                    {offer.trackingSystem === OTHER ? <> your tracking system.</> : <span className="text-capitalize"> {offer.trackingSystem}</span>}
                                </div>
                                <TrackingUrl/>
                            </div>
                        </li>
                        <li>
                            <div className={"list-item"}>
                                {offer.trackingSystem !== OTHER ? <div style={{marginBottom: 16}}>
                                        <div className={"list-item-label"}>
                                            Use the button below specifically made for
                                            <span className={"tracking-system-span"}> {offer.trackingSystem} </span>
                                            after pasting your URL above to add the
                                            parameters and macros needed to successfully track your clicks & conversions
                                            or enter your own parameters and use the other buttons to place the
                                            associated macros for them
                                        </div>
                                        <ButtonStyled
                                            onClick={() => appendMarcos()}
                                            className={"btn-blue-outlined btn-block btn-size-lg full-radius tracking-system-btn btn-uppercase"}>
                                            add {offer.trackingSystem} parameters & macros</ButtonStyled>
                                    </div> :
                                    <div className={"list-item-label"}>
                                        After pasting your URL above enter your own parameters and use the other
                                        buttons to place the associated macros for them
                                    </div>
                                }
                                <DestinationParamsSelect onSelect={(param) => handleAddMacro(param)} mobileTS={false}/>
                            </div>
                        </li>
                    </ol>
                </div>
            } </>
    );
};