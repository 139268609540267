import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {ColumnState} from "ag-grid-community";
import {FilterID} from "filters";
import UserPreferencesCacheService from '../common/services/userPreferencesCache.service'
import {RootState} from "./store";
import {IPreset} from "../common/components/range-date-picker/presets/presets.props";

export type IPagePresetsID = 'admin/offers/offers'
    | 'admin/offers'
    | 'admin/users/partners'
    | 'admin/billing/marketer-funding-monitoring'
    | 'admin/dashboard/marketer-funding-monitoring'
    | 'admin/users/marketers/id/credit'
    | 'admin/billing/partner-transactions'
    | 'admin/billing/partner-payments'
    | 'admin/billing/marketer-balance'
    | 'admin/billing/marketer-transactions'
    | 'admin/users/marketers'
    | 'admin/users/admin'

export interface IFilter {
    selected: any[]
    include?: boolean
    match?: 'any' | 'all' | 'empty'
}

export interface IDatePreset {
    dateTo: Date | null | undefined
    dateFrom: Date | null | undefined
    compareDateTo?: string,
    compareDateFrom?: string,
    datePreset?: IPreset,
    compareDatePreset?: IPreset
}

interface IInitialState {
    [key: string]: {
        table: ColumnState[],
        filters: {
            [key: string]: IFilter
        },
        date?: IDatePreset,
        pagination?: {
            perPage: number
        }
    }
}

interface IFetchUserPreferencesParams {
    pageId: IPagePresetsID,
    filters?: {
        [key: string]: IFilter
    },
}

const initialState: IInitialState = {

}

export const fetchUserPreferences = createAsyncThunk<any, IFetchUserPreferencesParams, {state: RootState}>(
    'presets/fetchUserPreferences',
    async ({pageId, filters= {}}, {getState, dispatch}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        dispatch(setInitialFilters({pageId, filters}))
        const {data} = await UserPreferencesCacheService.fetchUserPreferencesCache(token, user_id, pageId)
        return {
            data: JSON.parse(data.data),
            pageID: pageId
        }
    }
)

export const saveUserPreferences = createAsyncThunk<any, IPagePresetsID, {state: RootState}>(
    'presets/saveUserPreferences',
    async (pageId, {getState}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        await UserPreferencesCacheService.addUserPreferencesCache(token, user_id, pageId, JSON.stringify(state.userPreferences[pageId]))
    }
)

export const saveUserTablePreferences = createAsyncThunk<any, {pageId: IPagePresetsID, data: ColumnState[]}, {state: RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, data},
        {getState, dispatch}) => {
        const state = getState()
        const user_id = state.auth.user.id
        const token = state.auth.token
        dispatch(setUserTablePreferences({pageId, data: data}))
        const newState = {...state.userPreferences[pageId], table: data}
        await UserPreferencesCacheService.addUserPreferencesCache(
            token,
            user_id,
            pageId,
            JSON.stringify(newState)
        )
    }
)

export const saveUserDatePreferences = createAsyncThunk<any, {pageId : IPagePresetsID, date: IDatePreset }, {state : RootState}>(
    'presets/saveUserTablePreferences',
    async (
        {pageId, date}, {getState}) => {
        const state = getState()
        const userID = state.auth.user.id
        const token = state.auth.token
        const newState = {...state.userPreferences[pageId], date : date}
        await UserPreferencesCacheService.addUserPreferencesCache(token, userID, pageId,
            JSON.stringify(newState))
})

const userPreferencesSlice = createSlice(
    {
        name: 'userPreferences',
        initialState: initialState,
        reducers: {
            setUserTablePreferences(state, action: PayloadAction<{pageId: IPagePresetsID, data: ColumnState[]}>){
                state[action.payload.pageId] = {...state[action.payload.pageId], table: action.payload.data}
            },
            //FILTERS
            setInitialFilters(state, action: PayloadAction<{pageId: IPagePresetsID, filters: {[key: string]: IFilter}}>){
                const {pageId, filters} = action.payload
                // console.log(state[pageId].filters)
                state[pageId] = {filters: filters, table: []}
            },
            addFilterChip(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){
                const {pageId, filterId} = action.payload
                state[pageId].filters[filterId] = {
                    selected: [],
                    include: true,
                    match: 'any'
                }
            },
            removeFilterChip(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){

                const {pageId, filterId} = action.payload
                // delete state[pageId].filters[filterId]
                const {[filterId]: removed, ...newState} = state[pageId].filters
                state[pageId].filters = newState
            },
            clearAllFilterChips(state, action: PayloadAction<{pageId: IPagePresetsID}>){
                const {pageId} = action.payload
                state[pageId].filters = {}
            },
            setFilterValue(state, action: PayloadAction<{
                filterId: FilterID,
                pageId: IPagePresetsID,
                filter: IFilter
            }>){
                const {filterId, pageId, filter} = action.payload
                state[pageId].filters= {...state[pageId].filters, [filterId]: filter}
            },
            clearSelectedValues(state, action: PayloadAction<{pageId: IPagePresetsID, filterId: FilterID}>){
                const {pageId, filterId} = action.payload
                state[pageId].filters[filterId].selected = []
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(
                    fetchUserPreferences.fulfilled,
                    (state, action) => {
                        state[action.payload.pageID] = {...state[action.payload.pageID], ...action.payload.data}
                    }
                )
        }
    }

)

export default userPreferencesSlice.reducer
export const {
    setUserTablePreferences,
    setFilterValue,
    // setInclude,
    // setMatch,
    clearAllFilterChips,
    clearSelectedValues,
    removeFilterChip,
    setInitialFilters,
    addFilterChip
} = userPreferencesSlice.actions

