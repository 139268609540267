import { ClickAgainWrapper } from "./ClickAgainToConfirmTooltip.style";

interface IProps {
    onClick: () => void
}

const ClickAgainToConfirmTooltip = ({onClick}: IProps) => {
    return(
        <ClickAgainWrapper>
            <div onClick={onClick} className={'tooltip'}>
                Click Again to Confirm
            </div>
            <div className="carret"></div>
        </ClickAgainWrapper>
    )
}

export default ClickAgainToConfirmTooltip;