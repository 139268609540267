import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Panel} from "../Main.style";
import {TrackingCode} from "../../../components/TrackingCode";
import {DomainTrackingCodePlacedCheckbox} from "../../../components/DomainTrackingCodePlacedCheckbox";
import {PixelGroups} from "./pixel-groups/PixelGroups";
import MultiOptionSwitch, {
    OptionType
} from "../../../../../../../common/components/multi-option-switch/MultiOptionSwitch";
import ReactDOMServer from "react-dom/server";
import {useAppDispatch, useAppSelector, useCopy} from "../../../../../../../common/hooks";
import {fetchPixelGroups, setNextPage, setPrevPage} from "../../../create-offer.slice";
import {useGetTrackingUrls} from "../../../../offers/cpa/hooks/useGetTrackingUrls";
import {CPC, CPL, CPS, GOOGLE_ANALYTICS, IMPACT, OUTCOME_FUNNEL, TESTING} from "../../../interfaces/interfaces";
import TextArea from "../../../../../../../common/components/textarea/TextArea";


export interface Props {

}

const options: OptionType[] = [
    {
        id: 'postback',
        value: 'Postback URL'
    },
    {
        id: 'iframe',
        value: 'iFrame Pixel'
    },
    {
        id: 'image',
        value: 'Image Pixel'
    }
]

export const TrackingCodes : React.FC<Props> = () => {

    const {offer} = useAppSelector(state => state.createOffer)

    const [selected, setSelected] = useState<string | number>('postback')
    const [urlToCopy, setUrlToCopy] = useState('')

    const [copied, copy, setCopied] = useCopy(urlToCopy)

    const dispatch = useAppDispatch()

    const getCampaignConversionUrls = useGetTrackingUrls()

    useEffect(() => {
        if(urlToCopy !== ''){
            copy()
        }
    }, [copy, urlToCopy])

    useEffect(()=>{
        dispatch(setPrevPage("../" + OUTCOME_FUNNEL))
        dispatch(setNextPage("../" + TESTING))
    },[dispatch])

    const handleMainConversionUrlCopy = (upsell?: any) => {
        selected === 'postback' && setUrlToCopy(getCampaignConversionUrls(offer, upsell)?.postback_url)
        selected === 'image' && setUrlToCopy(getCampaignConversionUrls(offer, upsell)?.image_pixel)
        selected === 'iframe' && setUrlToCopy(getCampaignConversionUrls(offer, upsell)?.iframe_pixel)
    }

    useEffect(() => {
        if(offer.trackingSystem === IMPACT){
            setSelected('postback')
        }
    }, [offer.trackingSystem])

    useEffect(() => {
        dispatch(fetchPixelGroups({}))
    }, [dispatch])

    const mainConversionLabel = useCallback((upsell?: any) => {
        switch (selected) {
            case 'postback':
                return  <>
                    {upsell ? <>Event - {upsell.name} Postback URL</> : <>
                        Main Conversion Postback URL
                        <i
                            className={"ico-Information-outlined"}
                            data-html={true}
                            data-tip={ReactDOMServer.renderToString(
                                <>
                                    For postback URL to work, you need to <br/>
                                    do the following: <br/>
                                    <br/>
                                    1. Include {`clickid`} in your campaign URL <br/>
                                    2. Replace TRANSACTIONID with <br/>
                                    your Transaction ID <br/>
                                    3. Replace CLICKID with the same <br/>
                                    {`clickid`} passed from the Campaign URL
                                </>)}
                        />
                    </>}

                </>
            case 'iframe':
                return <>
                    {upsell ? <>Event - {upsell.name} iFrame Pixel (change to http for non-SSL)</> : <>
                        Main Conversion iFrame Pixel (change to http for non-SSL)
                        <i
                            className={"ico-Information-outlined"}
                            data-html={true}
                            data-tip={ReactDOMServer.renderToString(
                                <>
                                    For iFrame pixel to work, you need to <br/>
                                    do the following: <br/>
                                    <br/>
                                    1. Include {`clickid`} in your offer URL <br/>
                                    2. Replace TRANSACTIONID with <br/>
                                    your Transaction ID <br/>
                                    3. Replace CLICKID with the same <br/>
                                    {`clickid`} passed from the Offer URL
                                </>)}
                        />
                    </>}

                </>
            case 'image':
                return <>
                    {upsell !== undefined ? <>Event - {upsell.name} Image Pixel (change to http for non-SSL)</> : <>
                        Main Conversion Image Pixel (change to http for non-SSL)
                        <i
                            className={"ico-Information-outlined"}
                            data-html={true}
                            data-tip={ReactDOMServer.renderToString(
                                <>
                                    For image pixel to work, you need to <br/>
                                    do the following: <br/>
                                    <br/>
                                    1. Include {`clickid`} in your campaign URL <br/>
                                    2. Replace TRANSACTIONID with <br/>
                                    your Transaction ID <br/>
                                    3. Replace CLICKID with the same <br/>
                                    {`clickid`} passed from the Campaign URL
                                </>)}
                        />
                    </>}

                </>
            default:
                return '';
        }
    }, [selected])


    const [mainConvValue, setMainConvValue] = useState('')
    const [upsellValues, setUpsellValues] = useState<any[]>([])

    useEffect(() => {
        switch (selected) {
            case 'postback':
                setMainConvValue(getCampaignConversionUrls(offer)?.postback_url)
                break;
            case 'image':
                setMainConvValue(getCampaignConversionUrls(offer)?.image_pixel)
                break;
            case 'iframe':
                setMainConvValue(getCampaignConversionUrls(offer)?.iframe_pixel)
        }
    }, [selected, offer])

    useEffect(() => {
        setUpsellValues([...offer.upsells.map(upsell => {
            switch (selected) {
                case 'postback':
                    return {
                        ...upsell,
                        value: getCampaignConversionUrls(offer, upsell)?.postback_url,
                    }
                case 'image':
                    return {
                        ...upsell,
                        value: getCampaignConversionUrls(offer, upsell)?.image_pixel,
                    }
                case 'iframe':
                    return {
                        ...upsell,
                        value: getCampaignConversionUrls(offer, upsell)?.iframe_pixel,

                    }
                default:
                    return {id: 0, value: '', name: ''}
            }

        })])
    }, [selected, offer])


    // const mainConvValue = useMemo(() => {
    //     if(selected === 'postback') return getCampaignConversionUrls(offer)?.postback_url
    //     if(selected === 'image') return getCampaignConversionUrls(offer)?.image_pixel
    //     if(selected === 'iframe') return getCampaignConversionUrls(offer)?.iframe_pixel
    //     return ''
    // }, [])

    const handleUpsellEditableChange = (value: string, index: number) => {
        const upsell = upsellValues[index]
        upsell.value = value
        const newUpsells = [...upsellValues]
        newUpsells[index] = upsell
        setUpsellValues([...newUpsells])
    }

    const postbackIframeImageUrl = useMemo(() => {
        return <>
            <div className={'url-content-row'}>

                <TextArea
                    label={<>{mainConversionLabel()}</>}
                    wrapperClassName={'content-editable custom-scrollbar'}
                    className={'text-area-content-editable custom-scrollbar'}
                    style={{
                        resize: 'none'
                    }}
                    rows={1}
                    value={mainConvValue}
                    onChange={(e) => setMainConvValue(e.target.value)}
                />

                <span
                    data-html={true}
                    onMouseDown={() => handleMainConversionUrlCopy(undefined)}
                    data-event={'click'}
                    data-event-off={'click'}
                    data-delay-hide={3000}
                    data-place={'top'}
                    data-tip={ReactDOMServer.renderToStaticMarkup(<div>
                        <i className={"material-icons"} style={{color: "#3FDE7F", fontSize: '13px', transform: 'translateY(1px)'}}>check_circle</i> Copied!
                    </div>)}
                    className={'copy-icon-wrapper'}

                >

                        <i className="ico-copy" />
                    </span>
            </div>
            {upsellValues.map((upsell, index) => {
                return <div key={upsell.id} className="url-content-row">
                    <TextArea
                        label={<>{mainConversionLabel(upsell)}</>}
                        wrapperClassName={'content-editable custom-scrollbar'}
                        className={'text-area-content-editable custom-scrollbar'}
                        style={{
                            resize: 'none'
                        }}
                        rows={1}
                        value={upsell.value}
                        onChange={(e) => handleUpsellEditableChange(e.target.value, index)}
                        // onChange={(e) => setMainConvValue(e.target.value)}
                    />
                    <span
                        onMouseDown={() => handleMainConversionUrlCopy(upsell)}
                        data-html={true}
                        data-event={'click'}
                        data-event-off={'click'}
                        data-delay-hide={3000}
                        data-place={'top'}
                        data-tip={ReactDOMServer.renderToStaticMarkup(<div>
                            <i className={"material-icons"} style={{color: "#3FDE7F", fontSize: '13px', transform: 'translateY(1px)'}}>check_circle</i> Copied!
                        </div>)}
                        className={'copy-icon-wrapper'}

                    >
                                <i className="ico-copy"/>
                            </span>
                    </div>
            })}
        </>
    }, [
        getCampaignConversionUrls,
        handleMainConversionUrlCopy,
        mainConversionLabel,
        offer,
        selected,
        mainConvValue
    ])

    //OFFER TYPE = CLICKS (CPC)
    const clicksContent = useMemo(() => {
        if((offer.cpa !== null && offer.cpa !== '') || offer.upsells.length > 0){
            if(offer.trackingSystem !== GOOGLE_ANALYTICS && offer.trackingSystem !== IMPACT){
                return <>
                    <div className={'description'}>
                        It is recommended to utilize both Postback & iFrame for the best results.
                    </div>
                    <div className="row">
                        <MultiOptionSwitch
                            options={options}
                            selected={selected}
                            onSelect={(option) => setSelected(option.id)}
                        />
                    </div>
                    {postbackIframeImageUrl}
                    <div className="row">
                        <PixelGroups/>
                    </div>
                    <div className="row">
                        <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                    </div>
                    <div className="row">
                        <DomainTrackingCodePlacedCheckbox/>
                    </div>
                </>
            } else if(offer.trackingSystem === GOOGLE_ANALYTICS) {
                return <>
                    <div className="description">
                        Place this code in the header of the top level domain that conversions & events will take place on.
                        If you have already placed this code for another campaign using the same domain you do not need to place it again.
                        <br/><br/>
                        If you are a marketer using Google Tag Manager (aka GTM), it’s simple to add Perform[cb]'s universal outcome pixel,
                        as Perform[cb] is part of GTM's Template Community. Click <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className={'link'}
                            href="https://support.performcb.com/support/solutions/articles/4000185610-adding-perform-cb-pixels-to-google-tag-manager-gtm-">here</a> to learn more.
                        <br/><br/>
                        If you have a website with a URL that does not change when navigating between pages click <a target="_blank" rel="noopener noreferrer" className={'link'} href="https://support.performcb.com/support/solutions/articles/4000193955-universal-outcome-pixel-setup-for-single-page-web-application/">here</a> to
                        learn more about customizing the code to work for your needs.
                    </div>
                    <TrackingCode/>
                    <div className="row">
                        <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                    </div>
                    <div className="row">
                        <DomainTrackingCodePlacedCheckbox/>
                    </div>
                </>
            } else if (offer.trackingSystem === IMPACT){
                return <>
                    {postbackIframeImageUrl}
                    <div className="row">
                        <PixelGroups/>
                    </div>
                    <div className="row">
                        <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                    </div>
                    <div className="row">
                        <DomainTrackingCodePlacedCheckbox/>
                    </div>
                </>
            }
        } else {
            return <div className={'description'}>
                This section only applies when a main conversion or event(s) are added to your outcome funnel.
                When you are tracking only clicks, no code placement is necessary.
            </div>
        }
    }, [
        offer.cpa,
        offer.trackingSystem,
        offer.upsells.length,
        postbackIframeImageUrl,
        selected
    ])

    //OFFER TYPE = SALES(CPS) AND LEADS(CPL)
    const salesAndLeadsContent = useMemo(() => {
        if(offer.legacy_tracking === false) {
            return <>
                <div className="description">
                    Place this code in the header of the top level domain that conversions & events will take place on.
                    If you have already placed this code for another offer using the same domain you do not need to place it again.
                    <br/><br/>
                    If you are a marketer using Google Tag Manager (aka GTM), it’s simple to add Perform[cb]'s universal outcome pixel,
                    as Perform[cb] is part of GTM's Template Community. Click <a className={'link'} target="_blank" rel="noopener noreferrer" href="https://support.performcb.com/support/solutions/articles/4000185610-adding-perform-cb-pixels-to-google-tag-manager-gtm-">here</a> to learn more.
                    <br/><br/>
                    If you have a website with a URL that does not change when navigating between pages click <a target="_blank" rel="noopener noreferrer" className={'link'} href="https://support.performcb.com/support/solutions/articles/4000193955-universal-outcome-pixel-setup-for-single-page-web-application/">here</a> to
                    learn more about customizing the code to work for your needs.
                </div>
                <TrackingCode/>
                <div className="row">
                    <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                </div>
                <div className="row">
                    <DomainTrackingCodePlacedCheckbox/>
                </div>
            </>
        } else {
            if(offer.trackingSystem === GOOGLE_ANALYTICS){
                return <>
                    <div className="description">
                        Place this code in the header of the top level domain that conversions & events will take place on.
                        If you have already placed this code for another campaign using the same domain you do not need to place it again.
                        <br/><br/>
                        If you are a marketer using Google Tag Manager (aka GTM), it’s simple to add Perform[cb]'s universal outcome pixel,
                        as Perform[cb] is part of GTM's Template Community. Click <a className={'link'} target="_blank" rel="noreferrer" href="https://support.performcb.com/support/solutions/articles/4000185610-adding-perform-cb-pixels-to-google-tag-manager-gtm-">here</a> to learn more.
                        <br/><br/>
                        If you have a website with a URL that does not change when navigating between pages click <a className={'link'} href="https://support.performcb.com/support/solutions/articles/4000193955-universal-outcome-pixel-setup-for-single-page-web-application/" target="_blank" rel="noopener noreferrer">here</a> to
                        learn more about customizing the code to work for your needs.
                    </div>
                    <TrackingCode/>
                    <div className="row">
                        <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                    </div>
                    <div className="row">
                        <DomainTrackingCodePlacedCheckbox/>
                    </div>
                </>
            } else if (offer.trackingSystem === IMPACT){
                    return <>
                        {offer.detailed_pricing_model === CPS ? <>
                            <div className="description">
                                For Perform[cb] to accurately report your return on ad spend, enter your sale amounts following the parameters below.
                            </div>
                        </> : <>

                        </>}

                        {postbackIframeImageUrl}
                        <div className="row">
                            <PixelGroups/>
                        </div>
                        <div className="row">
                            <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                        </div>
                        <div className="row">
                            <DomainTrackingCodePlacedCheckbox/>
                        </div>
                    </>

            } else {
                return <>
                    {offer.detailed_pricing_model === CPS ? <>
                        <div className="description">
                            It is recommended to utilize both Postback & iFrame for the best results when using legacy tracking.
                            For Perform[cb] to accurately report your return on ad spend, enter your sale amounts following the parameters below.
                        </div>
                    </> : <>
                        <div className="description">
                            It is recommended to utilize both Postback & iFrame for the best results when using legacy tracking.
                        </div>
                    </>}
                    <div className="row">
                        <MultiOptionSwitch
                            options={options}
                            selected={selected}
                            onSelect={(option) => setSelected(option.id)}
                        />
                    </div>
                    {postbackIframeImageUrl}
                    <div className="row">
                        <PixelGroups/>
                    </div>
                    <div className="row">
                        <p className={'text-description'}>After placing your code, check the box below to complete this section.</p>
                    </div>
                    <div className="row">
                        <DomainTrackingCodePlacedCheckbox/>
                    </div>
                </>
            }
        }
    }, [
        offer.legacy_tracking,
        offer.trackingSystem,
        offer.detailed_pricing_model,
        postbackIframeImageUrl,
        selected
    ])

    return <>
            <Panel>
                <div className={"title"}>Tracking Codes</div>
                {offer.detailed_pricing_model === CPC ? <>

                    {clicksContent}

                </> : null}

                {offer.detailed_pricing_model === CPS || offer.detailed_pricing_model === CPL ? <>

                    {salesAndLeadsContent}

                </> : null}

            </Panel>
        </>

};

export default TrackingCodes