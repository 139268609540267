import styled from 'styled-components'

export const Wrapper = styled.div``

export const WidgetsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
`

export const ToolbarWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   @media screen and (max-width: 897px) {
     flex-direction: column-reverse;
     gap: 15px;
   }
`;

export const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`

export const ColumnRow = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;
    > div {
        height: max-content;
    }
    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
`