import {useCallback, useEffect, useMemo, useState} from "react";
import {api} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, IVisibilityPresetsFilter} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";


interface IDefaultVisibility {
    star: number,
    visibility: {
        id: number,
        type: 'Public' | 'Private' | 'Apply To Run'
    },
    name: string

}

export const useOfferVisibilityCacheFilter = (pageId: IPagePresetsID, displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [loading, setLoading] = useState(false)
    const [fetchedData, setFetchedData] = useState<(IVisibilityPresetsFilter | IDefaultVisibility)[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState<(IVisibilityPresetsFilter | IDefaultVisibility)[]>([])
    const [notDisplayed, setNotDisplayed] = useState(0)
    const defaultVisibilities = useMemo<IDefaultVisibility[]>(() => [
        {
            star: 1,
            visibility: {
                id: 1,
                type: 'Public'
            },
            name: '1 Star: Public'
        },
        {
            star: 1,
            visibility: {
                id: 2,
                type: 'Private'
            },
            name: '1 Star: Private'
        },
        {
            star: 1,
            visibility: {
                id: 3,
                type: 'Apply To Run'
            },
            name: '1 Star: Apply To Run'
        },
        {
            star: 2,
            visibility: {
                id: 1,
                type: 'Public'
            },
            name: '2 Star: Public'
        },
        {
            star: 2,
            visibility: {
                id: 2,
                type: 'Private'
            },
            name: '2 Star: Private'
        },
        {
            star: 2,
            visibility: {
                id: 3,
                type: 'Apply To Run'
            },
            name: '2 Star: Apply To Run'
        },
        {
            star: 3,
            visibility: {
                id: 1,
                type: 'Public'
            },
            name: '3 Star: Public'
        },
        {
            star: 3,
            visibility: {
                id: 2,
                type: 'Private'
            },
            name: '3 Star: Private'
        },
        {
            star: 3,
            visibility: {
                id: 3,
                type: 'Apply To Run'
            },
            name: '3 Star: Apply To Run'
        },
    ], [])

    const dispatch = useAppDispatch()

    const id : FilterID = 'offer-visibility'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await api.get<IVisibilityPresetsFilter[]>([
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    'visibility_presets'
                ].join('/'),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params: {
                        paginate_by: 'all'
                    }
                }
            )
            setFetchedData([...defaultVisibilities, ...data])
            setLoading(false)

        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        let filteredTypes = fetchedData.filter(type => (
            type.name.toLowerCase()).includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedTypes = [...filteredTypes].slice(0, displayCount)
            if(filteredTypes.length > displayCount){
                setNotDisplayed(filteredTypes.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setData(displayedTypes)
        } else {
            setData(filteredTypes)
        }




    }, [searchValue, fetchedData])

    useEffect(() => {
        if(token){
            fetch()
        }
    }, [token, fetch])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IVisibilityPresetsFilter | IDefaultVisibility) => {
        if (filter.selected.some((selected) => selected.name === item.name)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.name !== item.name)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }



    return {
        id: id,
        options: fetchedData,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: notDisplayed,
        search: {
            value: searchValue,
            setSearch: setSearchValue
        },
        loading : loading
    }
}