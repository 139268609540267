import styled from "styled-components";

interface IRow{
    empty?: boolean;
}

export const Form = styled.div`
    max-width: 500px;
    min-width: 500px;
    margin: 0 -8px;
`
export const FormColumn = styled.div`
    width: 50%;
    padding: 0 8px;
    .form-control {
        margin-bottom: 20px;
    }
    vertical-align: middle;
`

export const FormRow = styled.div<IRow>`
    display: flex;    
`

export const GeneratePassword = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    
`

export const UploadContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
`

export const AvatarThumbnail = styled.div`
    width: 60px;
    height: 60px;
    background: ${({theme}) => theme.colors.coolGray[4]};
    border: 1px solid ${({theme}) => theme.colors.coolGray[5]};
    border-radius: 100%;
    overflow: hidden;
    margin-right: 20px;
`
export const Avatar = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
`

// display: block;
// white-space: nowrap;
// height: 32px;
// padding: 8px;
// border-radius: 8px;
// font-family: "Avenir LT W01_55 Roman1475520", sans-serif;
// font-size: 12px;