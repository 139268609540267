import React from 'react'
import {RedirectMethodSelectWrapper} from "../layout/main/land-and-track/LandAndTrack.style";
import {SelectBox} from "./SelectBox";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer, } from "../create-offer.slice";
import {IMPACT} from "../interfaces/interfaces";

export const TrackingMethodSelect: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleTrackingMethodSelect = (legacy: boolean) => {
        dispatch(setOffer({
            legacy_tracking: legacy
        }))
    }

    return <>
        <RedirectMethodSelectWrapper>
            <SelectBox
                active={offer.legacy_tracking === false}
                onClick={() => handleTrackingMethodSelect(false)}
                disabled={false}
            >
                <div>
                    <h3 className="select-box-title">Universal Outcome Pixel</h3>
                    <p className={'select-box-content'}>
                        Our specialized JavaScript code
                    </p>
                    <p className="recommended">(Recommended)</p>
                </div>
            </SelectBox>
            <SelectBox
                active={offer.legacy_tracking === true}
                onClick={() => handleTrackingMethodSelect(true)}
                disabled={false}
            >
                <div>
                    <h3 className="select-box-title">Legacy Tracking</h3>
                    <p className={'select-box-content'}>
                        {offer.trackingSystem === IMPACT ? <>
                            Postbacks
                        </> : <>
                            Postbacks, iFrame Pixels, or Image Pixels
                        </>}

                    </p>
                </div>
            </SelectBox>
        </RedirectMethodSelectWrapper>
    </>
}