import {useEffect, useState, useCallback} from "react";

import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import FiltersService from "../../../services/filters.service";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

export interface IReferrerFilter {
    id: number;
    name: string;
    lastname: string;
    full_name: string;
}

export const useReferrerCacheFilter = (pageId: IPagePresetsID, displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IReferrerFilter[]>([])
    const [referrers, setReferrers] = useState<IReferrerFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(50);

    const dispatch = useAppDispatch()

    const id: FilterID ='referrer'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const response = FiltersService.fetchReferrers(token);
            const data: IReferrerFilter[] = (await response).data;
            setLoading(false)
            setFetchedData(
                data.sort((a: IReferrerFilter, b: IReferrerFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredReferrers = fetchedData.filter(manager => (
                manager.name.toLowerCase() + ' ' + manager.lastname.toLowerCase()).includes(searchValue.toLowerCase())
            ||
            manager.id.toString().includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredReferrers].slice(0, displayCount)
            if(filteredReferrers.length > displayCount){
                setNotDisplayed(filteredReferrers.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setReferrers(displayedAcc)
        } else {
            setReferrers(
                filteredReferrers
            )
        }
    }, [searchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (referrer: IReferrerFilter) => {
        if(filter.selected.some(selected  => selected.id === referrer.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== referrer.id)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, referrer]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }


    return {
        id: id,
        options: referrers,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: notDisplayed,
        search: {
            value: searchValue,
            setSearch: setSearchValue
        },
        loading
    }
}

