import React, {useEffect, useMemo, useState} from "react";
import AgencyViewService from "../AgencyView.service";
import {
    AgencyViewSortIconDown,
    AgencyViewSortIconUp,
    AgencyViewTableWrapper,
    AnalyzeHeading
} from "./styles/AgencyViewTable.style";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import {format} from "date-fns";
import {useColumnsOptionHook} from "../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
// import SortableHeader from "../../../../../../common/components/table/sortable-header/SortableHeader";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import GridRowOptions from "../../../../../../common/components/grid/grid-row-options/GridRowOptions";
import Grid from "../../../../../../common/components/grid/Grid";

const allPaginationColumns = [
    {
        name: "Date",
        id: 1,
        checked: true,
        column_key: "date"
    },
    {
        name: "Day",
        id: 2,
        checked: true,
        column_key: "date"
    },
    {
        name: "Clicks",
        id: 3,
        checked: true,
        column_key: "clicks"
    },
    {
        name: "Approved Conversions",
        id: 4,
        checked: true,
        column_key: "approved_conversions"
    },
    {
        name: "Total Conversions",
        id: 5,
        checked: true,
        column_key: "total_conversions"
    },
    {
        name: "CVR",
        id: 6,
        checked: true,
        column_key: "cvr"
    },
    {
        name: "Sale Amount",
        id: 7,
        checked: true,
        column_key: "revenue_amount"
    },
    {
        name: "Cost",
        id: 8,
        checked: true,
        column_key: "cost"
    },
    {
        name: "ROAS",
        id: 9,
        checked: true,
        column_key: "roas"
    },
    {
        name: "AOV",
        id: 10,
        checked: true,
        column_key: "aov"
    },
    {
        name: "EPC",
        id: 11,
        checked: true,
        column_key: "epc"
    },
    {
        name: "ECPA",
        id: 12,
        checked: true,
        column_key: "ecpa"
    },
    {
        name: "CAC",
        id: 13,
        checked: true,
        column_key: "cac"
    },
    {
        name: "pinned",
        id: 14,
        checked: false,
        column_key: "pinned"
    },
];

const AgencyViewDateTable = ({
                                 filter_cache, sortBy, sortDirection, toggleSortDirection, tableData, tableSum,
                                 total, currentPage, setCurrentPage, from, to, perPage, setPerPage,
                                 lastPage, loading, fetchReport
                             }: any) => {

    const allColumnDefs = [
        {
            colId: 1, field: 'date', headerName: 'DATE', flex: 1,
            headerComponent: ({data}: any) => {
                return <SortableHeaderV2
                    keyId={'date'}
                    sortDirection={sortDirection}
                    sortedBy={sortBy}
                    onClick={() => {
                        toggleSortDirection('date')
                    }
                    }
                >DATE</SortableHeaderV2>
            },
            cellRenderer: (data: any) => {
                if (data.node.rowPinned === "bottom") {
                    return "";
                }
                let date = data.data.date.replace(/\-/g, "/");
                return <span>{data.data.date ? format(new Date(date), "PP") : ""}</span>
            }
        },
        {
            colId: 2, field: 'day', headerName: 'DAY', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"day"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('day')}
                                       }
                >
                    DAY
                </SortableHeaderV2>
            },
            cellRenderer: (data: any) => {
                if (data.node.rowPinned === "bottom") {
                    return "";
                }
                let date = data.data.date.replace(/\-/g, "/");
                return <span>{data.data.date ? format(new Date(date), "eee").toUpperCase() : ""}</span>
            }
        },
        {
            colId: 3, field: 'clicks', headerName: 'CLICKS', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"clicks"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                       toggleSortDirection('clicks')}
                                       }>
                    CLICKS
                </SortableHeaderV2>
            }
        },
        {colId: 4, field: 'approved_conversions', headerName: 'APPROVED CONVERSIONS', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"approved_conversions"}
                                         sortedBy={sortBy}
                                         sortDirection={sortDirection}
                                         onClick={()=>{
                                             toggleSortDirection('approved_conversions')}
                                         }
                >
                    APPROVED CONVERSIONS
                </SortableHeaderV2>
            }
        },
        {colId: 5, field: 'conversions', headerName: 'TOTAL CONVERSIONS', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"conversions"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('conversions')}
                                       }
                >
                    TOTAL CONVERSIONS
                </SortableHeaderV2>
            }
        },
        {
            colId: 6, field: 'cvr', headerName: 'CVR', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"cvr"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('cvr')}
                                       }
                >
                    CVR
                </SortableHeaderV2>
            },cellRenderer: ({data}: any) => {
                return <span>{data.cvr}%</span>
            }
        },
        {
            colId: 7, field: 'sale_amount', headerName: 'SALE/REVENUE AMOUNT', flex: 1,
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"sale_amount"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('sale_amount')}
                                       }
                >
                    SALE/REVENUE AMOUNT
                </SortableHeaderV2>
            },cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.sale_amount)}</span>
            }
        },
        {
            colId: 8, field: 'cost', headerName: 'COST', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.cost)}</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"cost"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('cost')}
                                       }
                >
                    COST
                </SortableHeaderV2>
            }
        },
        {
            colId: 9, field: 'roas', headerName: 'ROAS', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{data.roas}%</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"roas"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('roas')}
                                       }
                >
                    ROAS
                </SortableHeaderV2>
            }
        },
        {
            colId: 10, field: 'aov', headerName: 'AOV', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.aov)}</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"aov"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('aov')}
                                       }
                >
                    AOV
                </SortableHeaderV2>
            }
        },
        {
            colId: 11, field: 'epc', headerName: 'EPC', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.epc)}</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"epc"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('epc')}
                                       }
                >
                    EPC
                </SortableHeaderV2>
            }
        },
        {
            colId: 12, field: 'ecpa', headerName: 'ECPA', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.ecpa)}</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"ecpa"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('ecpa')}
                                       }
                >
                    ECPA
                </SortableHeaderV2>
            }
        },
        {
            colId: 13, field: 'cac', headerName: 'CAC', flex: 1, cellRenderer: ({data}: any) => {
                return <span>{formatNumberToCurrency(data.cac)}</span>
            },
            headerComponent: ({data} : any) => {
                return <SortableHeaderV2 keyId={"cac"}
                                       sortedBy={sortBy}
                                       sortDirection={sortDirection}
                                       onClick={()=>{
                                           toggleSortDirection('cac')}
                                       }
                >
                    CAC
                </SortableHeaderV2>
            }
        },
        {
            colId: 14, pinned: 'right', maxWidth: 50, resizable: false, cellRenderer: (data: any) => {
                if (data.node.rowPinned === "bottom") return <span></span>
                return <GridRowOptions>
                    <AnalyzeHeading>Analyze Day By</AnalyzeHeading>
                    <DropdownItem onClick={() => toDynamic(data.data.date, "advertiser")}>Marketers</DropdownItem>
                    <DropdownItem onClick={() => toDynamic(data.data.date, "affiliate")}>Partners</DropdownItem>
                    <DropdownItem onClick={() => toDynamic(data.data.date, "event")}>Events</DropdownItem>
                </GridRowOptions>
            }
        }
    ]

    const [columnDefs, setColumnDefs] = useState<any[]>([...allColumnDefs]);

    const [paginationColumnsList, setPaginationColumnsList] = useState<any>([...allPaginationColumns]);

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]: any = useColumnsOptionHook(
        allColumnDefs,
        columnDefs,
        setColumnDefs,
        allPaginationColumns,
        paginationColumnsList,
        setPaginationColumnsList
    );



    useEffect(() => {
        if (paginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
            setColumnDefs(allColumnDefs.filter(item => paginationColumnsList.some((it: any) => (it.id === item.colId && it.checked) || item.pinned === "right")))
        } else {
            setColumnDefs([]);
        }
    }, [sortBy, sortDirection, filter_cache, paginationColumnsList]);

    const toDynamic = (date: any = null, type: string | null = null) => {
        const api = process.env.REACT_APP_API_BASE_URL;

        let group_by: any = false;
        let from = false;
        let to = false;
        let affiliate: any = false;
        let advertiser: any = false;
        let affiliates: any = null;
        let advertisers: any = false;

        if (filter_cache && filter_cache.platform_partner_id) {
            affiliate = {
                partner_id: filter_cache.platform_partner_id,
                platform_id: filter_cache.platform_id,
                merged_partner_id: filter_cache.merged_partner_id,
                merged_partner_name: filter_cache.platform_partner_company,
                partner_company_name: filter_cache.platform_partner_company || filter_cache.platform_partner_name
            };
            affiliates = [{
                partner_id: filter_cache.platform_partner_id,
                platform_id: filter_cache.platform_id,
                merged_partner_id: filter_cache.merged_partner_id,
                merged_partner_name: filter_cache.platform_partner_company,
                partner_company_name: filter_cache.platform_partner_company || filter_cache.platform_partner_name
            }];
        }

        if (filter_cache && filter_cache.leap_marketer_id) {
            advertiser = {
                leap_id: filter_cache.leap_marketer_id,
                leap_marketer_company: filter_cache.company,
                leap_marketer_name: filter_cache.full_name,
                // platform_advertiser_id: this.filter_cache.platform_advertiser_id,
                // platform_advertiser_name: this.filter_cache.platform_advertiser_name,
                // agency_platform_id: this.filter_cache.platform_id
            };

            advertisers = [{
                leap_id: filter_cache.leap_marketer_id,
                leap_marketer_company: filter_cache.company,
                leap_marketer_name: filter_cache.full_name,
                // platform_advertiser_id: this.filter_cache.platform_advertiser_id,
                // platform_advertiser_name: this.filter_cache.platform_advertiser_name,
                // agency_platform_id: this.filter_cache.platform_id
            }];
        }

        let group_by_upsell_name = false;

        if (type === 'advertiser') {
            group_by = 'group_by_marketer_id';
        } else if (type === 'affiliate') {
            group_by = 'group_by_partner_id';
        } else if (type === 'event') {
            group_by = 'group_by_upsell_id';
            group_by_upsell_name = true;
        } else {
            //view full report
            group_by = 'group_by_date_day_timestamp';
        }

        if (filter_cache && filter_cache.fromDate) {
            from = filter_cache.fromDate;
        }

        if (filter_cache && filter_cache.untilDate) {
            to = filter_cache.untilDate;
        }

        let all_columns = {
            clicks: 'Clicks',
            approved_conversions: 'Approved Conversions',
            total_conversions: 'Total Conversions',
            cvr: 'CVR',
            revenue_amount: 'Sale Amount',
            cost: 'Cost',
            epc: 'EPC',
            roas: 'ROAS',
            aov: 'AOV',
            ecpa: 'ECPA',
            cac: 'CAC'
        };

        let additional_groups = [];

        if (paginationColumnsList && paginationColumnsList.filter((item: any) => item.checked === true && item.column_key !== "date").length > 0) {
            for (let i in all_columns) {
                if (paginationColumnsList && paginationColumnsList.some((item: any) => item.checked && item.column_key === i)) {
                    additional_groups.push(i);
                }
            }
        } else {
            additional_groups = Object.keys(all_columns);
        }

        window.parent.postMessage(
            {
                id: 1,
                params: {
                    group_by: group_by,
                    date: date,
                    from: from,
                    to: to,
                    additional_groups: additional_groups,
                    affiliates: affiliates,
                    advertisers: advertisers,
                    group_by_upsell_name: group_by_upsell_name,
                    sort_by : sortBy,
                    sort_direction: sortDirection
                }
            }, `${api}`);
    }


    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 200
    }), []);

    const exportCsv = () => {

        // let all_columns: any = {
        //     date: 'Date',
        //     day: 'Day',
        //     clicks : 'Clicks',
        //     conversions : 'Conversions',
        //     cvr : 'CVR',
        //     revenue_amount : 'Sale Amount',
        //     cost : 'Cost',
        //     epc : 'EPC',
        //     roas : 'ROAS',
        //     aov : 'AOV',
        //     ecpa : 'ECPA',
        //     cac : 'CAC'
        // };

        // let visible_columns = [];

        // if( paginationColumnsList && paginationColumnsList.filter((item: any) => item.checked === true && item.column_key !== "date").length > 0) {
        //     for (let i in all_columns) {
        //         if (paginationColumnsList && paginationColumnsList.some((item: any) => item.checked && item.column_key === i)) {
        //             visible_columns.push(all_columns[i]);
        //         }
        //     }
        // } else {
        //     visible_columns = Object.values(all_columns);
        // }

        let params: {
            type: string,
            fromDate: string,
            untilDate: string,
            paginate_by: string,
            action: string,
            page?: string,
            // visible_columns?: string[],
            merged_partner_id?: string,
            platform_id?: string,
            platform_partner_id?: string,
            marketer_id?: string
        } = {
            type: "date",
            fromDate: filter_cache.fromDate,
            untilDate: filter_cache.untilDate,
            paginate_by: perPage.toString(),
            action: "export_csv",
            // visible_columns: undefined
        }

        if (currentPage > 1) {
            params.page = currentPage.toString();
        }
        if (filter_cache.merged_partner_id) {
            params.merged_partner_id = filter_cache.merged_partner_id.toString();
        }
        if (filter_cache.platform_id) {
            params.platform_id = filter_cache.platform_id.toString();
        }
        if (filter_cache.platform_partner_id) {
            params.platform_partner_id = filter_cache.platform_partner_id.toString();
        }
        if (filter_cache.leap_marketer_id) {
            params.marketer_id = filter_cache.leap_marketer_id.toString();
        }

        // if(visible_columns.length < 11) {
        //     params.visible_columns = visible_columns;
        // }
        AgencyViewService.exportReportsCsv(paginationColumnsList, params);
    }

    return (
        <AgencyViewTableWrapper>
            <div className="row">
                <div className="left">
                    <div className="heading">Performance by Date</div>
                    <div className="full-report">
                        <div className="full-report-btn" onClick={() => toDynamic()}>View Full Report</div>
                    </div>
                </div>
                <div className="right">
                    <Pagination
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        onRefresh={() => fetchReport()}
                        exportCsv={exportCsv}
                        columnsOptionProps={{
                            allPaginationColumns: allPaginationColumns,
                            enableDisableColumnHandler: (column: any) => enableDisableColumnHandler(column),
                            enableAllHandler: () => enableAllHandler(),
                            paginationColumnsList: paginationColumnsList,
                            setPaginationColumnsList: (list: any) => setPaginationColumnsList(list),
                            disableAllHandler: () => disableAllHandler()
                        }
                        }
                        perPageCustomList={[5, 10, 20, 50, 100]}
                    />
                </div>
            </div>
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowData={tableData}
                rowHeight={40}
                domLayout='autoHeight'
                loading={loading}
                enableAllColumns={enableAllHandler}
                pinnedBottomRowData={loading || tableData.length === 0 ? undefined : [tableSum]}
                fixedHeader={false}
            />
        </AgencyViewTableWrapper>
    )
}

export default AgencyViewDateTable;
