import React, {useEffect, useLayoutEffect, useState} from "react";
import ReactModal from 'react-modal'

import Container from './container/Container'

import {Overlay} from "./ModalWindow.style";

import {IModalWindowProps} from "./modalWindow.props";

ReactModal.setAppElement('#root');

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const ModalWindow: React.FC<IModalWindowProps> = ({
                                                      opened,
                                                      afterClose,
                                                      afterOpen,
                                                      children,
                                                      modalCustomClass,
                                                      modalWidth,
                                                      contentElement,
                                                      headerContent,
                                                      enableScroll
}) => {
    const [isModalOpened, setIsModalOpened] = useState(opened)

    useLayoutEffect(() : () => void => {
        // Get original body overflow
        const originalStyle: string = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = (opened) ? "hidden" : 'visible';
        if(enableScroll){
            document.body.style.overflow = 'visible'
        }
        // Re-enable scrolling when component unmounts
        return () => (document.body.style.overflow = originalStyle);
    }, [opened]);

    useEffect(() => {
        setIsModalOpened(opened)
    }, [opened])

    const onOpen = () => {
        targetOrigin && window.parent.postMessage({id: 5, opened: true}, targetOrigin)
        afterOpen && afterOpen()
    }

    const onClose = () => {
        targetOrigin && window.parent.postMessage({id: 6, opened: false}, targetOrigin)
        afterClose && afterClose()
    }
    return (
        <ReactModal
            bodyOpenClassName={'modal-opened'}
            preventScroll={false}
            portalClassName={modalCustomClass}
            onAfterClose={onClose}
            onAfterOpen={onOpen}
            isOpen={isModalOpened}
            contentElement={(props) => contentElement ? contentElement : <Container modalWidth={modalWidth} {...props}>
                {children}
            </Container>}
            overlayElement={(props, contentElement) => <Overlay style={{background: 'red'}} {...props}>{contentElement}</Overlay>}
            overlayClassName={'overlay'}
        >
        </ReactModal>

    )
}

export default ModalWindow
