import React, {useEffect} from 'react';
import ReactTooltip from "react-tooltip";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer, setValidation} from "../create-offer.slice";
import {IMPRESSION_TRACKING_URL_TOOLTIP, INVALID_URL_MSG, URL_PATTERN} from "../shared-components/consts";
import Input from "../../../../../common/components/input/Input";
import {Tooltip} from "../shared-components/Shared";


export const ImpressionTrackingUrl: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        validation,
    } = useAppSelector(state => state.createOffer)
    
    useEffect(()=>{
        ReactTooltip.rebuild()
    })

    const handleOnChange = (value: string) => {
        dispatch(setOffer({impression_tracking_url: value}))
      
        if (value){
            if (!URL_PATTERN.test(value) && !validation.validateImpressionTrackingUrl) {
                dispatch(setValidation({validateImpressionTrackingUrl :
                    INVALID_URL_MSG }
                ))
            } else if (URL_PATTERN.test(value) && validation.validateImpressionTrackingUrl ) {
                dispatch(setValidation({validateImpressionTrackingUrl : undefined}))
            }
        }
        else if (validation.validateImpressionTrackingUrl){
            dispatch(setValidation({
                validateImpressionTrackingUrl : undefined
            }))
            dispatch(setOffer({convert_impression: undefined,
                impression_tracking_required: undefined}))
        }
    }
    
    return (
        <Input
            wrapperClassName={"input-wrapper"}
            label={<>Impression Tracking URL <span className={"optional"}>(optional)</span>
                {Tooltip(246,IMPRESSION_TRACKING_URL_TOOLTIP )}
                </>}
            placeholder={"Enter the URL from " + offer.trackingSystem}
            value={offer.impression_tracking_url}
            onChange={(event) => handleOnChange(event.target.value)}
            error={validation.validateImpressionTrackingUrl}
        />
    );
};