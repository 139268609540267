import React from 'react';
import {NotificationsModalContent} from "../layout/footer/Footer.style";
import {ButtonStyled} from "../../../../../common/styled-components";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";

interface Props {
    opened: boolean,
    actions: {
        time_delayed: string[],
        compliance_delayed: string[],
        immediately_applied: string[]
    } | null
    redirectToOffersPage: () => void
}

const NotificationsModal: React.FC<Props> = ({opened, actions, redirectToOffersPage}) => {
    return (
        <AlertModal
            isOpen={opened}
            type={'success-rocket'}
            closeButtonClassName={"hide"}
            closeModal={() => {}}
        >
            <NotificationsModalContent>
                {(actions && actions.immediately_applied.length > 0) ? <div className={'notifications'}>
                    <div>The following changes will take effect immediately:</div>
                    <ul>
                        {actions.immediately_applied.map((notification) => {
                            return <li key={notification}>{notification}</li>
                        })}
                    </ul>
                </div> : null}
                {(actions && actions.time_delayed.length > 0) ? <div className={'notifications'}>
                    <div>The following changes will be implemented in 24 hours:</div>
                    <ul>
                        {actions.time_delayed.map((notification) => {
                            return <li key={notification}>{notification}</li>
                        })}
                    </ul>
                </div> : null}
                {(actions && actions.compliance_delayed.length > 0) ? <div className={'notifications'}>
                    <div>The following changes needs to be reviewed in order to be applied:</div>
                    <ul>
                        {actions.compliance_delayed.map((notification) => {
                            return <li key={notification}>{notification}</li>
                        })}
                    </ul>
                </div> : null}
            </NotificationsModalContent>

            <ButtonStyled style={{width: '100%'}} className={'btn-blue-filled'}  onClick={redirectToOffersPage}>Proceed to your campaigns</ButtonStyled>
        </AlertModal>
    )
}

export default NotificationsModal