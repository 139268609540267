import styled from "styled-components";

export const TableWrapper = styled.div`
    margin-bottom: 24px;
    
    .ag-header-cell-resize{
      z-index: 9999;
    }
    
    .header-content-right {
        width: 100%;
        span {
            width: 100%;
            text-align: right;
        }
    }

    .header-custom {
        .custom-tooltip {
            position: absolute;
            &:before {
                content: "";
                position: absolute;
                top: -40px;
                bottom: -20px;
                left: -20px;
                right: -1000px;
                z-index: 999999999;
            }
        }
    }
  
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  .view-edit-notes{
    color: ${({theme}) => theme.colors.performBlue[6]};
    
    :hover{
      cursor: pointer;
      color: ${({theme}) => theme.colors.performBlue[7]};
    }
    
    :hover:active{
      color: ${({theme}) => theme.colors.performBlue[8]};
    }
  }
  
  .add-notes{
    color: ${({theme}) => theme.colors.deepBlue[3]};

    :hover{
      cursor: pointer;
      color: ${({theme}) => theme.colors.deepBlue[5]};
    }

    :hover:active{
      color: ${({theme}) => theme.colors.deepBlue[7]};
    }
  }
`;
