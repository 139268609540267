import { useWidth } from "../../../hooks/useWidth";
import { FiltersRowWrapper } from "./FiltersRow.style"

const FiltersRow: React.FC = ({children}) => {
    const width = useWidth();

    return(
        <FiltersRowWrapper $width={width}>
            {children}
        </FiltersRowWrapper>
    )
}

export default FiltersRow;