import React from "react";
import {format} from 'date-fns'

import {Wrapper, MonthChangeButton} from "./Header.style";

import {IHeaderProps} from "./header.props";

const Header: React.FC<IHeaderProps> = ({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            nextMonthButtonDisabled,
                                            prevMonthButtonDisabled,
                                            datepickerType,
                                            setDatepickerType,
                                            decreaseYear,
                                            increaseYear,
                                            rangeType
}) => {

    const getYearsPickerRangeStart = (date: Date) => {
        const dateYear = date.getFullYear();
        let rangeStart = dateYear;
        let count = 0;
        while (!Number.isInteger((dateYear - count) / 12)) { count++; }
        rangeStart = dateYear - count + 1;
        return [rangeStart, rangeStart + 11];
    }

    const handleIncreaseClick = () => {
        switch (datepickerType){
            case "day":
                increaseMonth()
                break
            case "month":
                increaseYear();
                break
            case "year":
                increaseYear();
                break
        }
    }

    const handleDecreaseClick = () => {
        switch (datepickerType){
            case "day":
                decreaseMonth()
                break
            case "month":
                decreaseYear();
                break
            case "year":
                decreaseYear();
                break
        }
    }

    const handleHeaderClick = () => {
        switch (datepickerType){
            case "day":
                setDatepickerType('month')
                break
            case "month":
                setDatepickerType('year')
                break
            case 'year':
                setDatepickerType('day')
                break
        }
    }

    const getHeaderDateFormat = () => {
        switch (datepickerType){
            case "day":
                return format(typeof date === 'string' ? new Date(date) : date, 'LLL yyyy')
            case "month":
                return format(typeof date === 'string' ? new Date(date) : date, 'yyyy')
            case 'year':
                return `${getYearsPickerRangeStart(date)[0]} - ${getYearsPickerRangeStart(date)[1]}`
        }
    }

    return <Wrapper>
        <MonthChangeButton disabled={false} onClick={handleDecreaseClick}>
            <i className={'ico-ChevronLeft'}/>
        </MonthChangeButton>
        <div onClick={(e) => handleHeaderClick()} className={'month'}>
            {getHeaderDateFormat()}
            {rangeType && <span>{rangeType === 'end' ? 'End Date' : 'Start Date'}</span>}
        </div>
        <MonthChangeButton disabled={false} onClick={handleIncreaseClick}>
            <i className={'ico-ChevronRight'}/>
        </MonthChangeButton>
    </Wrapper>
}

export default Header