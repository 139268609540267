import styled from 'styled-components'
import {InputSingle} from "../../../../../../../../common/components/input-single-line/InputSingleLine.style";

export const Wrapper = styled.div`
  max-width: 560px;
  
  .row{
    margin-bottom: 30px;
    
    .input-error{
      font-size: 10px;
      color: ${({theme}) => theme.colors.critical[3]};
    }
  }

  .fee-type{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 17px;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

export const InputSingleStyled = styled(InputSingle)`
  width: 100%;
  height: 40px;
`

export const Label = styled.div`
  color: ${({theme}) => theme.colors.deepBlue[7]};
  font-size: 14px;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  margin-bottom: 16px;
`

export const FeeTypeTooltip = styled.div`
  width: 353px;
  
  .fee-type--tooltip{
    text-decoration: underline;
    font-weight: bold;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
`