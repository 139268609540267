import {FeatureFlag} from "feature-flags";
import {useAppSelector} from "./useAppSelector";
import {useEffect, useState} from "react";

//Checks if logged-in user has feature flag that is passed in the function enabled and return boolean
export const useFeatureFlags = (flag: FeatureFlag, network_id?: number): boolean => {
    const {user, config} = useAppSelector(state => state.auth)

    const [allowed, setAllowed] = useState(false)

    useEffect(() => {
        if(user && config) {
            if(!network_id){
                network_id = (user.network_id == null ? 1 : user.network_id);
            }
            setAllowed(config.features.includes(`${network_id}_${flag}`))
        }
    }, [
            user,
            config,
            flag,
            network_id
        ]
    )

    return allowed
}