import React, {useEffect, useLayoutEffect, useState} from "react";

import ReactModal from "react-modal";

import {IAlertModalProps} from "./alertModal.props";
import {Overlay} from "../modal/Modal.style";

import AlertModalContainer from "./alert-modal-container/AlertModalContainer";

ReactModal.setAppElement('#root');

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const AlertModal: React.FC<IAlertModalProps> = ({
                                                    isOpen,
                                                    type,
                                                    afterOpen,
                                                    afterClose,
                                                    closeModal,
                                                    iconClassName,
                                                    title,
                                                    titleClassName,
                                                    closeButtonClassName,
                                                    children}) => {
    const [isModalOpened, setIsModalOpened] = useState(isOpen)

    useLayoutEffect(() : () => void => {
        // Get original body overflow
        const originalStyle: string = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = isModalOpened ? "hidden" : 'visible';
        // Re-enable scrolling when component unmounts
        return () => (document.body.style.overflow = originalStyle);
    }, [isModalOpened]);

    useEffect(() => {
        setIsModalOpened(isOpen)
    }, [isOpen])

    const onOpen = () => {
        targetOrigin && window.parent.postMessage({id: 5, opened: true}, targetOrigin)
        afterOpen && afterOpen()
    }

    const onClose = () => {
        targetOrigin && window.parent.postMessage({id: 6, opened: false}, targetOrigin)
        afterClose && afterClose()
    }
    return <ReactModal
        isOpen={isOpen}
        onAfterOpen={onOpen}
        onAfterClose={onClose}
        contentElement={(props) => <AlertModalContainer
                type={type}
                onClose={closeModal}
                iconClassName={iconClassName}
                title={title}
                titleClassName={titleClassName}
                closeButtonClassName={closeButtonClassName}
                {...props}
            >
                {children}
            </AlertModalContainer>
        }
        overlayElement={(props, contentElement) => <Overlay {...props}>{contentElement}</Overlay>}
        overlayClassName={'overlay'}
    />

}

export default AlertModal