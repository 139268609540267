import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import { DropdownItem } from "../../../../../../common/components/dropdown/Dropdown.style";
import { advertisersEditStatuses } from "../../../../../../common/components/filters/statuses";
import InputSingleLine from "../../../../../../common/components/input-single-line/InputSingleLine";
import SelectionPills from "../../../../../../common/components/selection-pills/SelectionPills";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import { getStatus } from "../../../../../../common/utils";
import { Label } from "../Home.style";
import {
    IAccountManagerInputProps,
    ICompanyNameInputProps,
    IMarketerSubtypeInputProps,
    IDomainsInputProps,
    ILeapAccountTypeInput,
    IMarketingSensitivityInputProps,
    IMarketingSpendInputProps,
    INetworkInputProps,
    INicheInputProps, IPasswordInputProps,
    ISalesExecutiveInputProps,
    ISoftRejectConversionProps,
    IStatusInputProps,
    ITierInputProps,
    IPartnerInputProps
} from "./inputs.props";
import { DomainInputPills, LeapAccountTypeWrapper } from "./Inputs.style";
import {IPartnerTransparency} from "../../../../../../common/models/advertiser-edit.type";
import {useAppSelector} from "../../../../../../common/hooks";


export const StatusInput = ({editingUser, activeStatus, setActiveStatus, activeCpc, setActiveCpc}: IStatusInputProps) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [displayActiveStatus, setDisplayActiveStatus] = useState(""); 
    
    const [statusList, setStatusList] = useState(advertisersEditStatuses);
    const [listData, setListData] = useState(statusList);

    const statusSelectedSwitch = (status:string) => {

        const inactiveExecution = () => {
            const newStatusList = advertisersEditStatuses.filter((item: any) => {
                return item.id !== 5 && item.id !== 6;
            })
            
            setStatusList(newStatusList);
            setListData(newStatusList);
        }
        
        const deletedExecution = () => {
            const newStatusList = advertisersEditStatuses.filter((item: any) => {
                return item.id !== 5 && item.id !== 6;
            })
            // setStatusList(newStatusList);
            setListData(newStatusList);
        }

        const reviewedExecution = () => {
            const newStatusList = advertisersEditStatuses.filter((item: any) => {
                return item.id !== 5;
            })
            
            setStatusList(newStatusList);
            setListData(newStatusList)
        }

        switch (status) {
            case "active":
                const newStatusList = advertisersEditStatuses.filter((item: any) => {
                    return item.id !== 5 && item.id !== 6;
                });
                setStatusList(newStatusList);
                setListData(newStatusList);
                setDisplayActiveStatus("active");
                break;
            case "inactive":
                if(editingUser && activeCpc === "approved"){
                    const newStatusList = advertisersEditStatuses.filter((item: any) => {
                        return item.id !== 5;
                    })
                    setDisplayActiveStatus("reviewed");
                    setStatusList(newStatusList);
                    setListData(newStatusList)
                    return;
                }else if(editingUser && activeCpc === "pending"){
                    setStatusList(advertisersEditStatuses);
                    setListData(advertisersEditStatuses);
                    setDisplayActiveStatus("pending review");
                } else {
                    const newStatusList = advertisersEditStatuses.filter((item: any) => {
                        return item.id !== 5 && item.id !== 6;
                    });
                    setDisplayActiveStatus("inactive");
                    setStatusList(newStatusList);
                    setListData(newStatusList);
                }
                break;
            case "terminated":
                if(editingUser && activeCpc === "rejected"){
                    const newStatusList = advertisersEditStatuses.filter((item: any) => {
                        return item.id !== 5 && item.id !== 6;
                    })
                    setDisplayActiveStatus("denied");
                    setStatusList(newStatusList);
                    setListData(newStatusList)
                } else {
                    const newStatusList = advertisersEditStatuses.filter((item: any) => {
                        return item.id !== 5 && item.id !== 6;
                    })
                    setDisplayActiveStatus("terminated");
                    setStatusList(newStatusList);
                    setListData(newStatusList)
                }
                break;
            case "pending review":
                
                break;
            case "reviewed":
                reviewedExecution();
                break;
            case "deleted":
                deletedExecution();
                break;
            default:
                break;
        }
    }

    
    useEffect(()=>{
        if(editingUser){            
            setActiveStatus(editingUser.status);
        }
    }, [editingUser]);

    useEffect(()=>{
        activeStatus && statusSelectedSwitch(activeStatus);
    }, [activeStatus, activeCpc]);

    const searchHandler = (value: string) => {
        setSearchTerm(value);
        let searchValue = value;
        const result = statusList.filter((status: any) => status.name.toLowerCase().includes(searchValue.toLowerCase()));
        setListData(result);
    }

    const isSelected = (status: string) => {     
        return displayActiveStatus === status ? true : false;
    }

    const firstLetterToUpperCase = (string: string) => {
        const arr = string.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const newString = arr.join(" ");

        return newString;
    }

    const changeStatusHandler = (status: string) => {
        if (status == "inactive") {
            setActiveCpc("rejected");
            setActiveStatus("inactive")
        } else if (status == "pending review") {
            setActiveStatus("inactive");
            setActiveCpc("pending");
        } else if (status == "reviewed") {
            setActiveStatus("inactive");
            setActiveCpc("approved");
        } else if (status == "active") {
            setActiveCpc("approved");
            setActiveStatus("active");
        } else if (status == "denied") {
            setActiveStatus("terminated");
            setActiveCpc("rejected");
        } else if (status == "terminated") {
            setActiveCpc("approved");
            setActiveStatus("terminated")
        } else if (status == "deleted") {
            setActiveStatus("deleted");
        }
    }

    return(
        <Dropdown
            search={{
                value: searchTerm,
                onChange: (value: string) => searchHandler(value),
                placeholder: "Select status"
            }}
            dropdownHeightLimit={listData.length + 1}
            text={activeStatus ? firstLetterToUpperCase(displayActiveStatus) : "Status"}
            placeholder={'Select who referred this account'}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
          >
              {listData && listData.map(status => (
                  <DropdownItem
                      selected={isSelected(status.status_data.status)}
                      key={status.id+status.name}
                      onClick={() => {
                          changeStatusHandler(status.status_data.status)
                          setOpen(false);
                      }
                      }
                  >
                    {status.name}
                  </DropdownItem>
              ))}

        </Dropdown>
    )
}

export const TierInput: React.FC<ITierInputProps> = ({tier, setTier, activeTier, tempEditingUser}) => {
    const {editingUser} = useAppSelector(state => state.advertisersEdit)

    const tiers = [
        {
            name: "Enterprise",
            id: 1,
            tier: "enterprise"
        },
        {
            name: "Prime",
            id: 2,
            tier: "prime"
        },
    ]
    const [open, setOpen] = useState(false);
    const [localTier, setLocalTier] = useState(tiers[0]);

    useEffect(()=>{
        if(activeTier === "enterprise") {
            setTier(tiers[0].tier);
            setLocalTier(tiers[0]);
        } else if (activeTier === "prime") {
            setTier(tiers[1].tier);
            setLocalTier(tiers[1]);
        }
    }, [activeTier, editingUser]);

    const isSelected = (testTier: string) => {     
        return tier === testTier ? true : false;
    }
    
    return(
        <Dropdown
            search={{
                value: localTier.name,
                onChange: () => {},
                placeholder: localTier.name
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={4}
            text={tempEditingUser && tempEditingUser.advertiser_tier == null ? 'Unassigned' : localTier.name}
            placeholder={localTier.name}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
            readOnly
        >
            {tiers && tiers.map(tier => (
                <DropdownItem
                    selected={isSelected(tier.tier)}
                    key={tier.id}
                    onClick={() => {
                        setTier(tier.tier);
                        setLocalTier(tier);
                        setOpen(false);
                        }   
                    }
                >
                    {tier.name}
                </DropdownItem>
            ))}

        </Dropdown>
        )
}

export const AccountManagerInput: React.FC<IAccountManagerInputProps> = ({
        activeAccountManager,
        accountManager,
        setAccountManager,
        accountManagerId,
        setAccountManagerId,
        allAccountManagers
    }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [listData, setListData] = useState(allAccountManagers);
        
    useEffect(()=>{
        activeAccountManager !== null && setAccountManager(activeAccountManager);
    }, [activeAccountManager]);

    const isSelected = (id: number) =>  accountManager && accountManager.id === id;

    const searchHandler = (value: string) => {
        setSearchValue(value);
        let searchValue = value;
        const result = allAccountManagers.filter((status: any) => status.name.toLowerCase().includes(searchValue.toLowerCase()));
        setListData(result);
    }

    const unselectAccountManager = () => {
        const manager = allAccountManagers.find((manager: any) => manager.lastname === "Marketer Support");
        
        if(manager) {
            setAccountManager(manager);
            setAccountManagerId(manager.id);
        }
        setOpen(false);
    }

    return(
        <Dropdown
            search={{
                value: searchValue,
                onChange: (value: string) => searchHandler(value),
                placeholder: "Search Account Managers"
            }}
            itemsNotDisplayed={listData.slice(50, listData.length-1).length}
            dropdownHeightLimit={6}
            text={accountManager ? `${accountManager.name} ${accountManager.lastname}` : "No account manager assigned"}
            placeholder={'Select Account Manager'}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
        >
            <>
            {!searchValue && <DropdownItem key="noAssigned" selected={isSelected(52151)} onClick={() => unselectAccountManager()}>No account manager assigned</DropdownItem>}
            {listData && listData.slice(0, 50).map((manager: any) => (
                <DropdownItem
                    selected={isSelected(manager.id)}
                    key={manager.id}
                    onClick={() => {
                        setAccountManager(manager);
                        setAccountManagerId(manager.id);
                        setOpen(false);
                    }}
                >
                    {manager.name} {manager.lastname ? manager.lastname : null}
                </DropdownItem>
            ))}
            </>
        </Dropdown>
    )
}

export const SalesExecutiveInput: React.FC<ISalesExecutiveInputProps> = ({
        salesExecutive,
        setSalesExecutive,
        activeSalesExecutive,
        setSalesExecutiveId,
        salesExecutiveId,
        allAccountManagers
    }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [listData, setListData] = useState(allAccountManagers);

    useEffect(()=>{
        setSalesExecutive(activeSalesExecutive);
        activeSalesExecutive ? setSalesExecutiveId(activeSalesExecutive?.id) : setSalesExecutiveId(null);
    }, [activeSalesExecutive]);

    const isSelected = (id: number) =>  salesExecutive ? salesExecutive.id === id : false;

    const searchHandler = (value: string) => {
        setSearchValue(value);
        let searchValue = value;
        const result = allAccountManagers.filter((status: any) => status.name.toLowerCase().includes(searchValue.toLowerCase()));
        setListData(result);
    }

    const unselectSalesExecutive = () => {
        const manager = allAccountManagers.find((manager: any) => manager.lastname === "Marketer Support");
        if(manager) {
            setSalesExecutive(manager);
            setSalesExecutiveId(manager.id);
        }
        setOpen(false);
    }
    
    return(
        <Dropdown
            search={{
                value: searchValue,
                onChange: (value: string) => searchHandler(value),
                placeholder: "Search Account Managers"
            }}
            itemsNotDisplayed={listData.slice(50, listData.length-1).length}
            dropdownHeightLimit={6}
            text={salesExecutive  ? `${salesExecutive.name} ${salesExecutive.lastname}` : "No sales executive assigned"}
            placeholder={'Select Account Manager'}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
        >
            <>
                {!searchValue && <DropdownItem key="noAssigned" selected={isSelected(52151)} onClick={() => unselectSalesExecutive()}>No sales executive assigned</DropdownItem>}
                {listData && listData.slice(0, 50).map((manager: any) => (
                <DropdownItem
                    selected={isSelected(manager.id)}
                    key={manager.id}
                    onClick={() => {
                        setSalesExecutive(manager);
                        setSalesExecutiveId(manager.id);
                        setOpen(false);
                    }}
                >
                   {manager.name} {manager.lastname ? manager.lastname : null}
                </DropdownItem>
            ))}
            </>
        </Dropdown>
    )
}

export const CompanyNameInput: React.FC<ICompanyNameInputProps> = ({companyName, setCompanyName}) => {
    return(
        <InputSingleLine
            width={"100%"}
            value={companyName ? companyName : ""}
            onChange={setCompanyName}
            placeholder={"Company Name"}
            height={"40px"}
        />
    )
}

export const NetworkInput: React.FC<INetworkInputProps> = ({networkId, setNetworkId, isNetworkSwitchable}) => {

    const networks = [
        {
            name: "Clickbooth",
            id: 1
        },
        {
            name: "Adperio",
            id: 2
        }
    ];

    const activeNetwork = networks.find((item => item.id === networkId));

    const [open, setOpen] = useState(false);

    const isSelected = (id: number) => networkId === id;

    return (
        <Dropdown
            search={{
                value: activeNetwork ? activeNetwork.name : "",
                onChange: () => {},
                placeholder: activeNetwork ? activeNetwork.name : ""
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={3}
            text={activeNetwork ? activeNetwork.name : ""}
            placeholder={activeNetwork ? activeNetwork.name : ""}
            isOpened={open && isNetworkSwitchable} setOpened={setOpen}
            disabled={!isNetworkSwitchable}
            optionSelect={true}
            readOnly
        >
            {networks && networks.map(network => (
                <DropdownItem
                    selected={isSelected(network.id)}
                    key={network.id}
                    onClick={() => {
                        setNetworkId(network.id);
                        setOpen(false);
                        }   
                    }
                >
                    {network.name}
                </DropdownItem>
            ))}

        </Dropdown>
    )
}

export const MarketingSpendInput: React.FC<IMarketingSpendInputProps> = ({
    marketingSpend,
    setMarketingSpend
}) => {

    const monthlyTotals = [
        {value: "$0-$9,999", name: "$0-$9,999", id: 1},
		{value: "$10,000-$49,999", name: "$10,000-$49,999", id: 2 },
		{value: "$50,000-$149,999", name: "$50,000-$149,999", id:3 },
		{value: "$150,000-$499,999", name: "$150,000-$499,999", id:4 },
        {value: "$500,000-$999,999", name: "$500,000-$999,999", id:5 },
		{value: "$1,000,000", name: "$1,000,000+", id: 6 }
    ]

    const [open, setOpen] = useState(false);
    const isSelected = (total: string) =>  marketingSpend === total;

    return(
        <Dropdown
            search={{
                value: marketingSpend ? marketingSpend : "",
                onChange: () => {},
                placeholder: marketingSpend ? marketingSpend : ""
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={7}
            text={marketingSpend ? marketingSpend : ""}
            placeholder={marketingSpend ? marketingSpend : ""}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
            readOnly
        >
            {monthlyTotals && monthlyTotals.map(total => (
                <DropdownItem
                    selected={isSelected(total.value)}
                    key={total.id}
                    onClick={() => {
                        setMarketingSpend(total.value);
                        setOpen(false);
                    }   
                    }
                >
                    {total.value}
                </DropdownItem>
            ))}

        </Dropdown>
    )
}

export const SoftRejectConversionInput: React.FC<ISoftRejectConversionProps> = ({
    softRejectConversion,
    setSoftRejectConversion
}) => {
    const rejections = [
        { value: 0, name: "Approve All Conversions (Default)"},
        { value: 1, name: "Reject Conversions if Offer is Unavailable"}
    ];
    const [open, setOpen] = useState(false);
    const [activeRejection, setActiveRejection] = useState<undefined | string>("Approve All Conversions (Default)")

    useEffect(()=>{
        let active = rejections.find(item => item.value === softRejectConversion);
        setActiveRejection(active ? active.name : rejections[0].name);
    }, [softRejectConversion])

    const isSelected = (rejection: number) =>  softRejectConversion === rejection;

    return(
        <Dropdown
            search={{
                value: activeRejection ? activeRejection : "",
                onChange: () => {},
                placeholder: activeRejection ? activeRejection : ""
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={7}
            text={activeRejection ? activeRejection : ""}
            placeholder={activeRejection ? activeRejection : ""}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
            readOnly
        >
            {rejections && rejections.map(rejection => (
                <DropdownItem
                    selected={isSelected(rejection.value)}
                    key={rejection.value}
                    onClick={() => {
                        setSoftRejectConversion(rejection.value);
                        setOpen(false);
                    }   
                    }
                >
                    {rejection.name}
                </DropdownItem>
            ))}

        </Dropdown>
    )
}

export const MarketerSubtypeInput: React.FC<IMarketerSubtypeInputProps> = ({activeMarketerSubtype} : {activeMarketerSubtype: string}) => {

    const text = activeMarketerSubtype === "direct_advertiser" ? "Direct Marketer" : "Network";

    return(
        <Dropdown
            search={{
                value: text,
                onChange: () => {},
                placeholder: text
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={7}
            text={text}
            placeholder={text}
            isOpened={false} setOpened={()=>{}}
            disabled={true}
            optionSelect={true}
            readOnly
        ></Dropdown>
    )
}

export const LeapAccountTypeInput: React.FC<ILeapAccountTypeInput> = ({
    leapAccountType,
    setLeapAccountType,
    parentOrChild,
    setParentOrChild,
    parent,
    setParent,
    parentUsers
}) => {

    const types = [
        {
            name: "Parent",
            id: 1
        },
        {
            name: "Child",
            id: 2
        }
    ];

    useEffect(()=>{
        if(parentOrChild) {
            setLeapAccountType("Child");
            setParent(parentUsers?.find((user: any) => user.id === parentOrChild));
        }else {
            setLeapAccountType("Parent");
        }
    }, [parentOrChild]);

    useEffect(()=>{
        leapAccountType !== "Parent" && setParent(parentUsers?.find((user: any) => user.id === parentOrChild));
    }, [leapAccountType]);

    const [searchParentTerm, setSearchParentTerm] = useState("");
    const [listData, setListData] = useState(parentUsers);
    const [parentsOpen, setParentsOpen] = useState(false);

    const setType = (type: string) => {
        setLeapAccountType(type);
    }

    const text = leapAccountType === "Parent" ? "Parent (Default)" : "Child"

    const [open, setOpen] = useState(false);

    const isSelected = (type: string) =>  leapAccountType === type;
    const isParentSelected = (id: number) =>  parent && (parent.id === id);

    const searchHandler = (value: string) => {
        setSearchParentTerm(value);
        let searchValue = value;
        const result = parentUsers.filter((user: any) => user.display_name.toLowerCase().includes(searchValue.toLowerCase()));
        setListData(result);
    }
    
    return(
        <LeapAccountTypeWrapper $isChild={leapAccountType === "Child" ? true : false}>
            <div>
                <Label>
                    LEAP Account Type 
                    <Tooltip position="top" width={200}>
                        <div className="tooltip-text">For a marketer that has multiple LEAP accounts, 
                            choose whether the account is a parent or child account.
                            <br /><br />
                            <strong>Note: </strong>Billing fees can only be setup on the parent account, child accounts are set for reporting data.
                        </div>
                    </Tooltip>
                </Label>
                <Dropdown
                    search={{
                        value: text,
                        onChange: () => {},
                        placeholder: text
                    }}
                    itemsNotDisplayed={0}
                    dropdownHeightLimit={7}
                    text={text}
                    placeholder={text}
                    isOpened={open} setOpened={setOpen}
                    disabled={false}
                    optionSelect={true}
                    readOnly
                >
                    {types.map(type => (
                        <DropdownItem
                            selected={isSelected(type.name)}
                            key={type.id}
                            onClick={() => {
                                    setType(type.name);
                                    setOpen(false);
                                }   
                            }
                        >
                            {type.name} {type.name === "Parent" ? "(Default)" : null}
                        </DropdownItem>
                    ))}

                </Dropdown>
            </div>
        {
        leapAccountType === "Child" &&
        <div>
            <Label>Select Parent Account</Label>
            <Dropdown
                search={{
                    value: searchParentTerm,
                    onChange: (value: string) => searchHandler(value),
                    placeholder: "Search Parent"
                }}
                itemsNotDisplayed={listData.slice(50, listData.length-1).length}
                dropdownHeightLimit={6}
                text={parent ? parent.display_name : ""}
                placeholder={'Select Parent'}
                isOpened={parentsOpen} setOpened={setParentsOpen}
                disabled={false}
                optionSelect={true}
            >
                {parentUsers && listData.slice(0, 50).map((parent: any) => (
                    <DropdownItem
                        selected={isParentSelected(parent.id)}
                        key={parent.id}
                        onClick={() => {
                            setParent(parent);
                            setParentsOpen(false);
                        }}
                    >
                        {parent.display_name}
                    </DropdownItem>
                ))
            }
            </Dropdown>
        </div>
        }

        </LeapAccountTypeWrapper>
    )
}

export const NicheInput: React.FC<INicheInputProps> = ({niche, setNiche}) => {

    const niches = [
        {
            name: "Financial",
            id: 1
        },
        {
            name: "Health & Beauty",
            id: 0
        },
        {
            name: "Entertainment & Lifestyle",
            id: 2
        },
        {
            name: "Downloads & Software",
            id: 5
        },
        {
            name: "E-Commerce",
            id: 6
        },
        {
            name: "Other",
            id: 4
        }
    ];

    const [activeNiche, setActiveNiche] = useState<any>("");

    useEffect(()=>{
        const active = niches.find((item: any) => item.id === niche);
        setActiveNiche(active);
    }, [niche])

    const [open, setOpen] = useState(false);

    const isSelected = (id: number) =>  niche === id;
    return(
        <Dropdown
            search={{
                value: activeNiche ? activeNiche.name : "",
                onChange: () => {},
                placeholder: activeNiche ? activeNiche.name : "",
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={7}
            text={activeNiche ? activeNiche.name : ""}
            placeholder={"Search Vertical"}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
            readOnly
        >
            {niches.map(niche => (
                <DropdownItem
                    selected={isSelected(niche.id)}
                    key={niche.name}
                    onClick={() => {
                        setNiche(niche.id);
                        setOpen(false);
                    }   
                    }
                >
                    {niche.name}
                </DropdownItem>
            ))}

        </Dropdown>
    )
}

export const MarketingSensitivityInput: React.FC<IMarketingSensitivityInputProps> = ({
    marketingSensitivity,
    setMarketingSensitivity
}) => {
    const sensitivities = [
        {
            name: "LOW",
            id: 0
        },
        {
            name: "MEDIUM",
            id: 1
        },
        {
            name: "HIGH",
            id: 2
        }
    ];

    const [activeSensitivity, setActiveSensitivity] = useState<any>(sensitivities[0]);
    const [open, setOpen] = useState(false);
    const isSelected = (id: number) =>  marketingSensitivity === id;
    
    useEffect(() => {
        const newSensitivity = marketingSensitivity ? sensitivities.find((object: any) => object.id === marketingSensitivity) : sensitivities[0];
        setActiveSensitivity(newSensitivity);
    }, [marketingSensitivity]);

    return(
        <Dropdown
            search={{
                value: activeSensitivity.id === 0 ? `${activeSensitivity.name} (DEFAULT)` : activeSensitivity.name,
                onChange: () => {},
                placeholder: activeSensitivity.id === 0 ? `${activeSensitivity.name} (DEFAULT)` : activeSensitivity.name,
            }}
            itemsNotDisplayed={0}
            dropdownHeightLimit={7}
            text={activeSensitivity.id === 0 ? `${activeSensitivity.name} (DEFAULT)` : activeSensitivity.name }
            placeholder={"Search Vertical"}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
            readOnly
        >
            {sensitivities.map(sensitivity => (
                <DropdownItem
                    selected={isSelected(sensitivity.id)}
                    key={sensitivity.id}
                    onClick={() => {
                        setMarketingSensitivity(sensitivity.id);
                        setOpen(false);
                    }   
                    }
                >
                    {sensitivity.name}
                </DropdownItem>
            ))}

        </Dropdown>
    )
}

export const DomainsInput: React.FC<IDomainsInputProps> = ({websites, setWebsites}) => {
    const [inputValue, setInputValue] = useState("");

    const addTag = (text: any) => {
        setInputValue(text)
    }

    const onEnterPress = (event: any) => {
        let url = event.target.value;
        
        url = url.replace(/^((:)?\/{0,2})/,"");


        if(!url.match(/^[a-zA-Z]+:\/\//)) {
            url = 'http://' + url;
        }
        if(event.key === 'Enter'){
            if(/^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/.test(url)) {
                setWebsites([...websites, url])
                setInputValue("");
            }
        }
    }

    const removeWebsite = (index: number) => {
        const newList = websites.splice(index, 1);
        setWebsites(newList);
    }
    
    return( 
        <>
        <InputSingleLine 
            onChange={(text: string) => addTag(text)}
            onKeyDown={onEnterPress}
            value={inputValue}
            placeholder="Add a Domain"
            width={"100%"}
            height={"40px"}
        />
        
        {websites && 
        <DomainInputPills>
            {
                websites.map((website:string, index: number) => {
                    return <SelectionPills
                    key={`${website+Math.random()}`}
                    onRemove={() => removeWebsite(index)}
                    text={website} />
                })
            }
        </DomainInputPills>
        }
        </>
    )
}

export const PasswordInput : React.FC<IPasswordInputProps> = ({password, setPassword}) => {
    return (
        <InputSingleLine
            type={"password"}
            useDebauncedValue={false}
            width={"20%"}
            value={password ? password : ""}
            onChange={setPassword}
            placeholder={"Enter New Password"}
            height={"40px"}
        />
    )
}

export const PartnerInput: React.FC<IPartnerInputProps> = ({ partner,
                                                               setPartner,
                                                               allPartners }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [listData, setListData] = useState(allPartners);
    const isSelected = (id: number) =>  partner && partner.id === id;
    const searchHandler = (value: string) => {
        setSearchValue(value);
        let searchValue = value;
        const result = allPartners.filter((partner: IPartnerTransparency) =>
            (partner.name.toLowerCase() + " " + partner.lastname.toLowerCase()).includes(searchValue.toLowerCase()) ||
            partner.id.toString().includes(searchValue));
        setListData(result);
    }

    return(
        <Dropdown
            search={{
                value: searchValue,
                onChange: (value: string) => searchHandler(value),
                placeholder: "Search Partner"
            }}
            itemsNotDisplayed={listData.slice(50, listData.length-1).length}
            dropdownHeightLimit={6}
            text={partner.id ? `${partner.name} ${partner.lastname} ${partner.id}` : "No partner assigned"}
            placeholder={'Select Partner'}
            isOpened={open} setOpened={setOpen}
            disabled={false}
            optionSelect={true}
        >
                {listData && listData.slice(0, 50).map((partner: any) => (
                    <DropdownItem
                        selected={isSelected(partner.id)}
                        key={partner.id}
                        onClick={() => {
                            setPartner(partner);
                            setOpen(false);
                        }}
                    >
                        {partner.name} {partner.lastname ? partner.lastname : null} {partner.id}
                    </DropdownItem>
                ))}
        </Dropdown>
    )
}