import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {ButtonHolder, OfferTitle, OfferTypeBox, OfferTypeHolder} from "./CreateOffers.style";
import {setNextPage, setOffer, setPrevPage, setShowBack, setShowExit} from "./create-offer.slice";
import {AppEngageIcon, AppInstallIcon, CheckedIcon} from "../../../../assets/svgs/SvgIcons";
import {ButtonStyled} from "../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {CPE, CPI, ENGAGEMENT, INSTALL, MOBILE_TYPE} from "./interfaces/interfaces";
import {OfferTypeTooltip} from "./shared-components/Shared";
import {APP_ENGAGEMENT_TOOLTIP, APP_INSTALL_TOOLTIP} from "./shared-components/consts";


const OfferTypeApps: React.FC = () => {
    const {offer, nextPage} = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const handleOnClick = (type : MOBILE_TYPE) =>{
        dispatch(setOffer({
            mobile_type: type,
            detailed_pricing_model: type === ENGAGEMENT ? CPE : CPI
        }))
        dispatch(setNextPage("mobile"))
    }
    useEffect(()=>{
        dispatch(setShowExit(true))
        dispatch(setShowBack(true))
        dispatch(setNextPage([location.pathname, "mobile"].join("/") ))
        dispatch(setPrevPage("type"))
    },[])

    return (
        <div>
            <OfferTitle>
                Which primary type of outcome are you seeking for with your app?
            </OfferTitle>
            <OfferTypeHolder>
                <OfferTypeBox active={offer.mobile_type === INSTALL} onClick={() => handleOnClick(INSTALL)}>
                    <div>
                        <AppInstallIcon/>
                        <div>App Installs</div>
                        {OfferTypeTooltip(176, APP_INSTALL_TOOLTIP)}
                    </div>
                    <CheckedIcon display={offer.mobile_type === INSTALL ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox active={offer.mobile_type === ENGAGEMENT} onClick={() => handleOnClick(ENGAGEMENT)}>
                    <div>
                        <AppEngageIcon/>
                        <div>
                            App Engagements
                        </div>
                        {OfferTypeTooltip(196, APP_ENGAGEMENT_TOOLTIP)}
                    </div>
                    <CheckedIcon display={offer.mobile_type === ENGAGEMENT ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
            </OfferTypeHolder>
            <ButtonHolder>
                {offer.mobile_type !== ""  &&
                    <Link to={nextPage}>
                        <ButtonStyled
                            className={"btn-blue-filled btn-block btn-size-lg full-radius"}
                        >Continue</ButtonStyled>
                    </Link>
                }
            </ButtonHolder>
        </div>
    );
};

export default OfferTypeApps