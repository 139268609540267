import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../../../../common/hooks";
import Alert from "../../../../../../../../common/components/alert/Alert";
import {TableStyled, Wrapper} from "./BudgetTypeChangeWarning.style";


const BudgetTypeChangeWarning: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    const [displayed, setDisplayed] = useState(false)
    const [hasCpc, setHasCpc] = useState(false)

    useEffect(() => {
        if(offer.campaign_budget_group_info && offer.campaign_budget_group_info?.length > 0){
            setHasCpc(offer.campaign_budget_group_info?.some((cam: any) => cam.pricing_model === 'cpc'))
        }
    }, [offer.campaign_budget_group_info])

    useEffect(() => {
        if(
            offer.campaign_budget_group_info &&
            offer.budget_group_id === offer.orig_budget_group_id &&
            offer.campaign_budget_group_info?.length > 0 &&
            offer.orig_budget_value_type !== null &&
            offer.orig_budget_value_type !== offer.budget_value_type
        ) {
            setDisplayed(true)
        } else {
            setDisplayed(false)
        }
    }, [
        offer.budget_group_id,
        offer.budget_value_type,
        offer.campaign_budget_group_info,
        offer.orig_budget_group_id,
        offer.orig_budget_value_type
    ])

    if(!displayed) return <></>

    if(hasCpc && offer.budget_value_type === 1){
        return <Alert alertType={'warning'}>
             <span>
                Since you changed Budget Type from { offer.orig_budget_value_type === 1 ? 'Conversions to US $' : 'US $ to Conversion' },
                we need to convert all Budget Types on Campaigns in Budget Group assigned to { offer.orig_budget_value_type === 1 ? 'US $' : 'Conversions' },
                but we have noticed that you have CPC Campaigns ih this group, and CPC Campaigns don't allow Conversions Budget Type.
                Please remove all CPC Campaigns from Budget Group assigned in order to change Budget Type.
             </span>
        </Alert>
    }

    return <Wrapper>
    <Alert alertType={'warning'}>
        <span>
            Since you changed Budget Type from {offer.orig_budget_value_type === 1 ? 'Conversions to US $' : 'US $ to Conversion'},
            we need to convert all Campaigns in the Budget Group assigned to {offer.orig_budget_value_type === 1 ? 'US $' : 'Conversions'}.
            When you save this Campaigns, all changes to other campaigns in the same Budget Group will be applied automatically.
        </span>
        <TableStyled>
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Conv.</th>
                <th></th>
                <th>US $</th>
            </tr>
            </thead>
            <tbody>
            {offer.campaign_budget_group_info?.filter((cmp: any) => cmp.id !== offer.id)
                .map((cmp: any) => {
                return  <tr data-ng-repeat="cmp in ctrl.budget_group_campaigns">
                    <td>{cmp.id}</td>
                    <td>{cmp.name}</td>
                    <td>
                        {cmp.budget_type !== 'unlimited' && <span >{cmp.budget_conv_value}</span>}
                        {cmp.budget_type === 'unlimited' && <span><i className="ti-infinite"></i></span>}
                    </td>
                    <td>
                        {offer.budget_value_type !== cmp.budget_value_type &&
                            <span className="material-symbols-outlined">
                                {cmp.budget_value_type === 0 ? 'arrow_back' : 'arrow_forward'}
                            </span>}
                        {/*<i ng-if="ctrl.campaign.budget_value_type != cmp.budget_value_type"*/}
                        {/*   ng-class="{'ti-arrow-left':(cmp.budget_value_type==0), 'ti-arrow-right':(cmp.budget_value_type==1)}"></i>*/}
                    </td>
                    <td>
                        {cmp.budget_type !== 'unlimited' && <span >{cmp.budget_value > 0 ? '$' + cmp.budget_value : 0}</span>}
                        {cmp.budget_type === 'unlimited' && <span ><i className="ti-infinite"></i></span>}
                    </td>
                </tr>
            })}

            </tbody>
        </TableStyled>
    </Alert>
    </Wrapper>
}

export default BudgetTypeChangeWarning