import React, {useMemo} from 'react'
import {RedirectMethodSelectWrapper} from "../layout/main/land-and-track/LandAndTrack.style";
import {SelectBox} from "./SelectBox";
import {RedirectlessIcon, StandardRedirectsIcon} from "../../../../../assets/svgs/SvgIcons";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer, setValidation} from "../create-offer.slice";
import {GOOGLE_ANALYTICS, IMPACT, NONE} from "../interfaces/interfaces";
import {useTestingLink} from "../hooks/useTestingLink";

export const RedirectTypeSelect: React.FC = () => {
    const {offer, validation} = useAppSelector(state => state.createOffer)
    const getTestingLink = useTestingLink()
    const dispatch = useAppDispatch()

    const handleDirectLinkingSelect = (type: boolean) => {
        if(offer.trackingSystem !== GOOGLE_ANALYTICS && offer.trackingSystem !== NONE){
            dispatch(setOffer({
                direct_linking: type,
                legacy_tracking: type ? false : offer.legacy_tracking,
                testing_link : type ? offer.preview_url.includes("?") ?
                        offer.preview_url + "&oid=" + offer.id + "&testing=1"
                            : offer.preview_url + "?oid=" + offer.id + "&testing=1"
                                :  getTestingLink(offer.detailed_pricing_model!, offer.id, offer.destination_url)
            }))
        } else {
            dispatch(setOffer({
                direct_linking: type,
                legacy_tracking: false,
                testing_link : type ? offer.preview_url.includes("?") ?
                        offer.preview_url + "&oid=" + offer.id + "&testing=1"
                        : offer.preview_url + "?oid=" + offer.id + "&testing=1"
                    :  getTestingLink(offer.detailed_pricing_model!, offer.id, offer.destination_url)
            }))
        }

    }

    const standardRedirectsContent = useMemo(() => {
        if(offer.trackingSystem === IMPACT){
            return 'Use either JavaScript code placed on your domain or use legacy' +
                'postbacks with potentially lower conversion rates'
        }
        if(offer.trackingSystem === GOOGLE_ANALYTICS){
            return 'Choose this option if there are redirects in the flow users take to get to your offer. ' +
                'Use JavaScript code placed on your domain allowing for seamless tracking. '
        }

        return 'Use either JavaScript code placed on your domain or use legacy' +
            'postbacks & pixels with potentially lower conversion rates'
    }, [offer.trackingSystem])

    const redirectlessContent = useMemo(() => {
        if(offer.trackingSystem === GOOGLE_ANALYTICS){
            return 'Choose this option if there are no redirects in the flow users take to get to your offer. ' +
                'Use JavaScript code placed on your domain allowing for seamless tracking. '
        }

        return 'Use only JavaScript code placed on your domain allowing ' +
            'for no redirects and potentially increased conversion rates'
    }, [offer.trackingSystem])

    return <>
        <RedirectMethodSelectWrapper>
            <SelectBox
                active={offer.direct_linking === false}
                onClick={() => handleDirectLinkingSelect(false)}
                disabled={false}
            >
                <div>
                    <StandardRedirectsIcon />
                    <h3 className="select-box-title">Standard Redirects</h3>
                    <p className={'select-box-content'}>
                        {standardRedirectsContent}
                    </p>
                </div>
            </SelectBox>
            <SelectBox
                active={offer.direct_linking === true}
                onClick={() => handleDirectLinkingSelect(true)}
                disabled={false}
            >
                <div>
                    <RedirectlessIcon/>
                    <h3 className="select-box-title">Redirectless</h3>
                    <p className={'select-box-content'}>
                        {redirectlessContent}
                    </p>
                </div>
            </SelectBox>
        </RedirectMethodSelectWrapper>
    </>
}