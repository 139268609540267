import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

const partnerPaymentStatuses: any[] = [
    {value: "Twice weekly", id: "twice_weekly"},
    {value: "Weekly", id: "weekly"},
    {value: "Bi-Weekly", id: "semi_monthly"},
    {value: "Monthly Net 7", id: "monthly_net_7"},
    {value: "Monthly Net 15", id: "monthly_net_15"},
    {value: "Monthly Net 30", id: "monthly"}
];

export const usePartnerPaymentCycleCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'partner-payment-cycle'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (item: any) => {
        if(filter.selected.some((selected)  => selected.value === item.value)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.value !== item.value)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, item]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: partnerPaymentStatuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        }
    }
}