import styled from 'styled-components'

export const OverviewContentWrapper = styled.div`
  margin: 0 -8px;
`

export const OverviewContentRow = styled.div<{second?: boolean}>`
  display: flex;
  
  @media (max-width: 1291px){
    flex-wrap: ${({second}) => second ? "wrap" : "none"};
  }

  @media (max-width: 900px){
    flex-wrap: wrap;
  }
`;

export const OverviewTile = styled.div<{second?: boolean}>`
  padding: 0 8px;
  flex: 1;
  display: flex;
  
  @media (max-width: 1290px){
    flex: ${({second}) => second && "1"};
    width: ${({second}) => second && "50%"};
  }

  @media (max-width: 900px){
    flex: none;
    width: 100%;
  }
`

export const OverviewTileContent = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 16px;
  width: 100%;
  margin-bottom: 16px;
  
  .overview-cell--title{
    line-height: 22px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[5]};
    margin-bottom: 8px;
    display: inline-block;
    margin-right: 10px;
  }
  
  .overview-cell--value{
    line-height: 46px;
    font-size: 34px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[7]};
  }
`