import React, {useEffect, useState} from 'react'

import Table from "./table/Table";
import Search from "../../../../../common/components/search/Search";
import MarketerFundingMonitoringService, {
    IEditStayLiveLimitParams,
    IIFetchMarketerFundingDataResponse,
    IUpdateBillingNotesParams
} from "./MarketerFundingMonitoring.service";
import {useAppDispatch, useAppSelector, useFilter} from "../../../../../common/hooks";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import Pagination from '../../../../../common/components/table/pagination/Pagination';
import {setPage, setPagination, setPaginationColumnsList, setPerPage} from './MarketerFundingMonitoring.slice';
import {allPaginationColumnsList} from './table/paginationColumnsList';
import {useColumnsOptionHook} from '../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook';
import Filters from './filters/Filters';
import Icon from '../../../../../common/components/icon/Icon';
import {
    ButtonStyled, 
    TableWrapper, 
    TableToolbarTop, 
    TableToolbarTopUpperRow, 
    TableToolbarTopBottomRow,
    TableToolbarBottom
} from '../../../../../common/styled-components';
import Alert from "../../../../../common/components/alert/Alert";
import {useFirstRender} from '../../../../../common/hooks/useFirstRender';
import {withPageContainer} from "../../../../../common/hoc/withPageContainer/withPageContainer";
import _ from "lodash";

export interface ITempData {
    billing_notes: string | null
    id: number,
    name: string
    company: string
    acc_manager: string
}

const MarketerFundingMonitoring: React.FC = () => {
    const [tableData, setTableData] = useState<IIFetchMarketerFundingDataResponse[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const {token} = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch();
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);
    const {
        sort,
        pagination,
        paginationColumnsList
    } = useAppSelector(state => state.marketerFundingMonitoring);

    const {
        filters
    } = useAppSelector(state => state.filters);
    const [search, setSearch] = useState<string>("");

    const filter = useFilter('admin/billing/marketer-funding-monitoring')

    const autoFundingFilter = filter<any>('auto-funding')
    const invoicingCycleFilter  = filter<any>('invoicing-cycle')
    const marketerAccountManagerFilter = filter<any>('account-manager')
    const marketerStatusFilter = filter<any>('marketer-status')
    const marketerTagsFilter = filter<any>('tags')
    const marketerTypeFilter = filter<any>('marketer-type')
    const partnerAccountManagerFilter = filter<any>('partner-account-manager')
    const pauseRiskFilter = filter<any>('pause-risk')
    const paymentTermsFilter = filter<any>('payment-terms')
    const referrerFilter = filter<any>('referrer')
    const salesExecutiveFilter = filter<any>('sales-executive')
    const verticalFilter = filter<any>('marketer-vertical')

    const [fromAngular, setFromAngular] = useState(false);

    const {
        selectedManagers,
        filterType,
        includeOrExclude
    } = useAppSelector(state => state.networkView);

    useEffect(()=>{
        setDiscoverDisplayed(true)
    }, [
        autoFundingFilter,
        invoicingCycleFilter,
        marketerAccountManagerFilter,
        marketerStatusFilter,
        marketerTagsFilter,
        marketerTypeFilter,
        marketerTypeFilter,
        partnerAccountManagerFilter,
        pauseRiskFilter,
        paymentTermsFilter,
        referrerFilter,
        salesExecutiveFilter,
        verticalFilter,
        // firstRender
    ])

    useEffect(()=>{
        setDiscoverDisplayed(false)
        memoFetch()

    }, [fromAngular])

    const firstRender = useFirstRender();



    const [alertModal, setAlertModal] = useState<{opened: boolean, type: 'success' | 'error', message: string}>({
        opened: false,
        type: 'success',
        message: ''
    })

    const fetchTableData = async () => {
        setLoading(true)
        let params: {
            paginate_by: number,
            sort_by: string,
            order: "desc" | "asc",
            page: number,
            intacct_invoicing_cycle?: {
                ids: string[],
                include: boolean
            },
            intacct_payment_terms?: {
                ids: any,
                include: boolean
            },
            advertiser_niche?: {
                ids: number[],
                include: boolean
            },
            referrer?: {
                ids: number[],
                include: boolean
            },
            pause_risk?: {
                ids: string[],
                include: boolean
            },
            account_manager?: {
                ids: number[],
                include: boolean
            },
            affiliate_account_manager?: {
                ids: number[],
                include: boolean
            },
            auto_funding?: number,
            advertiser_status?: {
                ids: string[],
                include: boolean
            },
            marketer_tags?: {
                ids: number[],
                include: boolean
            },
            sales_executives?: {
                ids: number[],
                include: boolean
            },
            marketer_type?: {
                ids: string[],
                include: boolean
            },
            q? : string, // search param
        } = {
            paginate_by: pagination.per_page,
            page: pagination.page,
            sort_by: sort.by,
            order: sort.direction,

        }
        if(autoFundingFilter?.selected.length === 1) {
            params.auto_funding = autoFundingFilter.selected[0].name === "Enabled" ? 1 : 0 ;
        }
        if(invoicingCycleFilter?.selected.length) {
            params.intacct_invoicing_cycle = {
                ids: invoicingCycleFilter.selected,
                include: !invoicingCycleFilter.exclude
            };
        }
        if(marketerAccountManagerFilter?.selected.length) {
            params.account_manager = {
                ids: marketerAccountManagerFilter.selected.map(mng => mng.id),
                include: !marketerAccountManagerFilter.exclude
            }
        }
        if(marketerStatusFilter?.selected.length) {
            params.advertiser_status = {
                ids: marketerStatusFilter.selected.map( status => status.status_name),
                include: !marketerStatusFilter.exclude
            }
        }
        if(marketerTagsFilter?.selected.length){
            params.marketer_tags = {
                ids: marketerTagsFilter.selected.map(tag => tag.id),
                include: !marketerTagsFilter.exclude
            };
        }
        if(pauseRiskFilter?.selected.length) {
            params.pause_risk = {
                ids: pauseRiskFilter.selected.map(risk => risk.id),
                include: !pauseRiskFilter.exclude
            };
        }
        if(paymentTermsFilter?.selected.length) {
            params.intacct_payment_terms = {
                ids: paymentTermsFilter.selected,
                include: !paymentTermsFilter.exclude
            }
        }
        if(referrerFilter?.selected.length) {
            params.referrer = {
                ids: referrerFilter.selected.map(referrer => referrer.id),
                include: !referrerFilter.exclude
            };
        }
        if(salesExecutiveFilter?.selected.length) {
            params.sales_executives = {
                ids: salesExecutiveFilter.selected.map(executive => executive.id),
                include: !salesExecutiveFilter.exclude
            };
        }
        if(verticalFilter?.selected.length){
            params.advertiser_niche = {
                ids: verticalFilter.selected.map(vertical => vertical.id),
                include: !verticalFilter.exclude
            }
        }
        if(partnerAccountManagerFilter?.selected.length) {
            params.affiliate_account_manager = {
                ids: partnerAccountManagerFilter.selected.map(vertical => vertical.id),
                include: !partnerAccountManagerFilter.exclude
            }
        }
        if(marketerTypeFilter?.selected.length) {
            params.marketer_type = {
                ids: marketerTypeFilter.selected.map(type => type.name),
                include: !marketerTypeFilter.exclude
            }
        }
        if (search !== ""){
            params.q = search
        }

        try{
            setError(false)
            setDiscoverDisplayed(false)
            const {data} = await MarketerFundingMonitoringService.fetchMarketerFundingData(token, params)
            dispatch(setPage(Number(data.current_page)));
            dispatch(setPerPage(Number(data.per_page)));
            dispatch(setPagination({
                from: data.from,
                to: data.to,
                last_page: data.last_page,
                total: data.total
            }))
            setTableData(data.data)
            setLoading(false)
        }catch (e: any) {
            setLoading(false)
            setTableData([])
            setError(true)
        }
    }

    const memoFetch = _.memoize(fetchTableData)

    const updateBillingNotes = async (params: IUpdateBillingNotesParams) => {
        try {
            await MarketerFundingMonitoringService.updateBillingNotes(token, params)
            setAlertModal({
                opened: true,
                type: 'success',
                message: 'The billing notes have been updated.'
            })
        } catch (e) {
            setAlertModal({
                opened: true,
                type: 'error',
                message: 'Something went wrong!'
            })
        }
    }

    const editStayLiveLimit = async (params: IEditStayLiveLimitParams) => {
        try {
            await MarketerFundingMonitoringService.editStayLiveLimit(token, params)
            setAlertModal({
                opened: true,
                type: 'success',
                message: 'The stay live limit has been updated.'
            })
        } catch (e) {
            setAlertModal({
                opened: true,
                type: 'error',
                message: 'Something went wrong!'
            })
            console.log(e)
        }
    }

    useEffect(() => {
        memoFetch()
    }, [sort, pagination.page, pagination.per_page, search])

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]:any = useColumnsOptionHook(
        [],
        [],
        () => {},
        allPaginationColumnsList,
        paginationColumnsList,
        (list: {name: string, id: number, checked: boolean, column_key?: string}[]) => 
            dispatch(setPaginationColumnsList(list))
    )

    const exportToCsv = () => {

        let params: {
            paginate_by: number,
            sort_by: string,
            order: "desc" | "asc",
            page: number,
            columns?: string[],
            intacct_invoicing_cycle?: {
                ids: string[],
                include: boolean
            },
            intacct_payment_terms?: {
                ids: any,
                include: boolean
            },
            advertiser_niche?: {
                ids: number[],
                include: boolean
            },
            referrer?: {
                ids: number[],
                include: boolean
            },
            pause_risk?: {
                ids: string[],
                include: boolean
            },
            account_manager?: {
                ids: number[],
                include: boolean
            },
            affiliate_account_manager?: {
                ids: number[],
                include: boolean
            },
            auto_funding?: number,
            advertiser_status?: {
                ids: string[],
                include: boolean
            },
            marketer_tags?: {
                ids: number[],
                include: boolean
            },
            sales_executives?: {
                ids: number[],
                include: boolean
            },
            marketer_type?: {
                ids: string[],
                include: boolean
            },
            q? : string // search param
        } = {
            paginate_by: pagination.per_page,
            page: pagination.page,
            sort_by: sort.by,
            order: sort.direction,

        }
        if(autoFundingFilter?.selected.length === 1) {
            params.auto_funding = autoFundingFilter.selected[0].name === "Enabled" ? 1 : 0 ;
        }
        if(invoicingCycleFilter?.selected.length) {
            params.intacct_invoicing_cycle = {
                ids: invoicingCycleFilter.selected,
                include: !invoicingCycleFilter.exclude
            };
        }
        if(marketerAccountManagerFilter?.selected.length) {
            params.account_manager = {
                ids: marketerAccountManagerFilter.selected.map(mng => mng.id),
                include: !marketerAccountManagerFilter.exclude
            }
        }
        if(marketerStatusFilter?.selected.length) {
            params.advertiser_status = {
                ids: marketerStatusFilter.selected.map( status => status.status_name),
                include: !marketerStatusFilter.exclude
            }
        }
        if(marketerTagsFilter?.selected.length){
            params.marketer_tags = {
                ids: marketerTagsFilter.selected.map(tag => tag.id),
                include: !marketerTagsFilter.exclude
            };
        }
        if(pauseRiskFilter?.selected.length) {
            params.pause_risk = {
                ids: pauseRiskFilter.selected.map(risk => risk.id),
                include: !pauseRiskFilter.exclude
            };
        }
        if(paymentTermsFilter?.selected.length) {
            params.intacct_payment_terms = {
                ids: paymentTermsFilter.selected,
                include: !paymentTermsFilter.exclude
            }
        }
        if(referrerFilter?.selected.length) {
            params.referrer = {
                ids: referrerFilter.selected.map(referrer => referrer.id),
                include: !referrerFilter.exclude
            };
        }
        if(salesExecutiveFilter?.selected.length) {
            params.sales_executives = {
                ids: salesExecutiveFilter.selected.map(executive => executive.id),
                include: !salesExecutiveFilter.exclude
            };
        }
        if(verticalFilter?.selected.length){
            params.advertiser_niche = {
                ids: verticalFilter.selected.map(vertical => vertical.id),
                include: !verticalFilter.exclude
            }
        }
        if(partnerAccountManagerFilter?.selected.length) {
            params.affiliate_account_manager = {
                ids: partnerAccountManagerFilter.selected.map(vertical => vertical.id),
                include: !partnerAccountManagerFilter.exclude
            }
        }
        if(marketerTypeFilter?.selected.length) {
            params.marketer_type = {
                ids: marketerTypeFilter.selected.map(type => type.name),
                include: !marketerTypeFilter.exclude
            }
        }

        if (search !== ""){
            params.q = search
        }

        if(paginationColumnsList.filter(column => column.checked).length !== allPaginationColumnsList.length){
            params.columns = paginationColumnsList.filter(column => column.checked && column.column_key !== 'pinned' &&
                column.column_key !== 'intacct_invoices').map(column => column.column_key) as string[]
        }

        MarketerFundingMonitoringService.exportToCsv(params)
    }

    return <TableWrapper>
            <TableToolbarTop>
                <TableToolbarTopUpperRow>
                    <Search onChange={setSearch}
                            wrapperClassName='table-search'
                            width={30}
                            initialValue=""
                            placeholder="Search by ID or Company name"/>
                    
                    {discoverDisplayed &&
                        <ButtonStyled onClick={memoFetch}
                                      className={'btn-discover-results'}>
                            Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/>
                        </ButtonStyled>
                    }
                </TableToolbarTopUpperRow>
                <TableToolbarTopBottomRow>
                    <Filters setDiscoverDisplayed={setDiscoverDisplayed}/>
                    <Pagination
                        current_page={pagination.page}
                        perPage={pagination.per_page}
                        from={pagination.from}
                        to={pagination.to}
                        total={pagination.total}
                        last_page={pagination.last_page}
                        loading={loading}
                        setCurrentPage={(n: number) => dispatch(setPage(n))}
                        setPerPage={(n: number) => dispatch(setPerPage(n))}
                        onRefresh={() => memoFetch()}
                        exportCsv={() => exportToCsv()}
                        columnsOptionProps={
                            {
                                allPaginationColumns: allPaginationColumnsList,
                                enableDisableColumnHandler: (column: any) => enableDisableColumnHandler(column),
                                enableAllHandler: () => enableAllHandler(),
                                paginationColumnsList: paginationColumnsList,
                                setPaginationColumnsList: (list: {
                                    name: string,
                                    id: number,
                                    checked: boolean,
                                    column_key?: string
                                }[]) => dispatch(setPaginationColumnsList(list)),
                                disableAllHandler: () => disableAllHandler()
                            }
                        }
                        perPageCustomList={[20, 50, 100]}
                    />
                </TableToolbarTopBottomRow>
            </TableToolbarTop>
            {error ?
                <Alert alertType={'error'}>
                    <div>Something went wrong!</div>
                </Alert> :
                <Table
                    loading={loading}
                    tableData={tableData}
                    updateBillingNotes={updateBillingNotes}
                    fetchTableData={memoFetch}
                    editStayLiveLimit={editStayLiveLimit}
                />
            }
            <TableToolbarBottom>
                <Pagination 
                    current_page={pagination.page}
                    perPage={pagination.per_page}
                    from={pagination.from}
                    to={pagination.to}
                    total={pagination.total}
                    last_page={pagination.last_page}
                    loading={loading}
                    setCurrentPage={(n: number) => dispatch(setPage(n))}
                    setPerPage={(n: number) => dispatch(setPerPage(n))}
                    perPageCustomList={[20, 50, 100]}
                />
            </TableToolbarBottom>
            <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => setAlertModal({
                    opened: false,
                    type: 'success',
                    message: ''
                })}>
                {alertModal.message}
            </AlertModal>
    </TableWrapper>
}

export default withPageContainer(MarketerFundingMonitoring)