import React, {useState, useRef, useEffect} from 'react'

import {FilterID} from "filters";

import {Wrapper} from './Filters.style'
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../../common/hooks";

import {
    useAccountManagerFilter,
} from "../../../../../../../common/hooks/filters";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {removeAllFilters} from "../../../../../../../store/filters.slice";
import {ButtonStyled} from "../../../../../../../common/styled-components";
import { useAutoFundingFilter } from '../../../../../../../common/hooks/filters/useAutoFundingFilter';
import { useInvoicingCycleFilter } from '../../../../../../../common/hooks/filters/useInvoicingCycleFilter';
import IncludeExcludeFilter from '../../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter';
import SelectFilterDropdown from '../../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import { useTransactionStatusesFilter } from '../../../../../../../common/hooks/filters/useTransactionStatusesFilter';
import { useCurrencyTypeFilter } from '../../../../../../../common/hooks/filters/useCurrencyTypeFilter';
import { useTransactionTypeFilter } from '../../../../../../../common/hooks/filters/useTransactionTypeFilter';
import ClickAgainToConfirmTooltip from '../../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip';

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'currency-type',
        value: 'Currency Type'
    },
    {
        id: "transaction-statuses",
        value:"Status"
    },
    {
        id: "transaction-type",
        value:"Transaction Type"
    },
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    
    const currencyTypeFilter = useCurrencyTypeFilter('admin/users/marketers/id/credit');
    const transactionStatusesFilter = useTransactionStatusesFilter('admin/users/marketers/id/credit');
    const transactionTypeFilter = useTransactionTypeFilter('admin/users/marketers/id/credit')

    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "currency-type":
                return (
                    <IncludeExcludeFilter selectedFilterText={currencyTypeFilter.selectedData[0] ? currencyTypeFilter.selectedData[0].value : ''} filter={currencyTypeFilter} filterTitle={'Currency Type'} setDisplayDiscoverResults={setDiscoverDisplayed}>
                        {currencyTypeFilter.data.map(option =>  
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={currencyTypeFilter.selectedData.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    currencyTypeFilter.setSelectedData(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        )
                        }
                    </IncludeExcludeFilter>
                )
            case 'transaction-statuses':
                return (
                        <IncludeExcludeFilter setDisplayDiscoverResults={setDiscoverDisplayed} selectedFilterText={transactionStatusesFilter.selectedData[0] ? transactionStatusesFilter.selectedData[0].value : ''} filter={transactionStatusesFilter} filterTitle={'Status'}>
                            {transactionStatusesFilter.data.map(status =>  
                                <Checkbox
                                 wrapperClassName={'checkbox-filter-wrapper'}
                                 key={status.value+Math.random()}
                                 checked={transactionStatusesFilter.selectedData.some(selected => status.id === selected.id)}
                                 onChange={() => {
                                     transactionStatusesFilter.setSelectedData(status)
                                     setDiscoverDisplayed(true)
                                 }}
                                 label={status.value}
                             />
                            )}
                        </IncludeExcludeFilter>
                    )
            case 'transaction-type':
                return (
                    <IncludeExcludeFilter setDisplayDiscoverResults={setDiscoverDisplayed} selectedFilterText={transactionTypeFilter.selectedData[0] ? transactionTypeFilter.selectedData[0].value : ''} filter={transactionTypeFilter} filterTitle={'Transaction Type'}>
                        {transactionTypeFilter.data.map(type =>  (
                            <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={type.id}
                                checked={transactionTypeFilter.selectedData.some(selected => type.id === selected.id)}
                                onChange={() => {
                                    transactionTypeFilter.setSelectedData(type)
                                    setDiscoverDisplayed(true)
                                }}
                                label={type.value}
                            />
                        ))}
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/users/marketers/id/credit'))
        }
    }


    return (
        <Wrapper>
            <i className={'ico-Filters'}/>
            {filters.filter((filter: any) => filter.pageID === 'admin/users/marketers/id/credit')[0]?.filters.map((filter: any) => 
            <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
            <SelectFilterDropdown setDiscoverDisplayed={setDiscoverDisplayed} allFilters={filterList} pageId={'admin/users/marketers/id/credit'}/>

            {filters.filter((filter: any) => 
                filter.pageID === 'admin/users/marketers/id/credit')[0]?.filters.length > 1 && 
                <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                    {   displayClearFilterTooltip && 
                         <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
                    }
                    Clear All Filters
                </ButtonStyled>}
        </Wrapper>
    )
}


export default Filters
