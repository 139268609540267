import React, {useEffect} from 'react';
import ReactTooltip from "react-tooltip";
import {Panel} from "../Main.style";
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hooks";
import { setNextPage, setPrevPage} from "../../../create-offer.slice";
import {TrackingSystemMobile} from "./mobile/TrackingSystemMobile";
import {TrackingSystemWeb} from "./web/TrackingSystemWeb";
import {CPE, CPI, LAND_AND_TRACK, OFFER_INFO} from "../../../interfaces/interfaces";

const TrackingSystem: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        appsSelected
    } = useAppSelector(state => state.createOffer)
    
    useEffect(()=>{
        dispatch(setPrevPage("../" + OFFER_INFO))
        dispatch(setNextPage("../" + LAND_AND_TRACK))
    },[])
    
    useEffect(() => {
        ReactTooltip.rebuild()
    })
    
    return (
        <>
            <Panel>
                <div className={"title"}>tracking system</div>
                <div className={'description'}>
                    { appsSelected ? "Choose the tracking system you use below and follow the steps to proceed."
                    : "The most commonly used tracking systems are listed below, if the tracking system you use isn’t " +
                        "listed below or you don’t use a tracking system choose Other or None."}
                </div>
                {(offer.detailed_pricing_model !== CPI && offer.detailed_pricing_model !== CPE) && <TrackingSystemWeb/>}
                {(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) && <TrackingSystemMobile/>}
            </Panel>
        </>
    );
};

export default TrackingSystem