import React, {useState} from 'react'

import Dropdown from '../../../../../common/components/dropdown/Dropdown'
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";

import {Wrapper} from  './SwitchViewDropdown.style'

import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setView} from "../Dashboard.slice";

interface Props {

}

const SwitchViewDropdown: React.FC<Props> = () => {
    const {view} = useAppSelector(state => state.adminDashboard)
    const [opened, setOpened] = useState(false)

    const dispatch = useAppDispatch()

    const handleViewSelect = (view: 'agency' | 'network') => {
        dispatch(setView(view))
    }

    return <Wrapper $opened={opened}>
        <Dropdown
            isOpened={opened}
            setOpened={setOpened}
            text={<span style={{whiteSpace: "nowrap"}}><i className={"ico-Eye"} />{view === 'agency' ? 'Agency View' : 'Engine View'}</span>}
            headerClassName={'view-switch-header'}
        >
            <DropdownItem
                selected={view === 'network'}
                onClick={() => handleViewSelect('network')}
            >
                Engine view
            </DropdownItem>
            <DropdownItem
                selected={view === 'agency'}
                onClick={() => handleViewSelect('agency')}
            >
                Agency view
            </DropdownItem>
        </Dropdown>
    </Wrapper>
}

export default SwitchViewDropdown