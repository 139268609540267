// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bump
import {ResponsiveLine} from '@nivo/line'
import {format} from "date-fns";
import { ReferralChartProps} from "./ReferralChart.props";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

export const ReferralsResponsiveLine = ({ data, days} :ReferralChartProps ) => (
    <ResponsiveLine
        data={data}
        colors={["#FD8825"]}
        animate={true}
        margin={{
            top: 50,
            right: 20,
            bottom: 50,
            left: 60
        }}
        xScale={{
            type: "time",
            format: "%Y-%m-%d"
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
            min: "auto",
            nice: true,
            type: "linear",
            max: "auto"

        }}
        axisLeft={{
            legend: "Values",
            legendPosition: "middle",
            legendOffset: -50,
        }}
        axisBottom={{
            format: "%b %d",
            tickValues: days,
            legend: "Dates",
            legendOffset: 35,
            legendPosition: 'middle',
        }}
        curve="monotoneX"
        enableGridX={false}
        enableGridY={true}
        motionConfig="slow"
        pointSize={5}
        pointColor={{ theme: "background" }}
        pointBorderWidth={5}
        pointBorderColor={{ from: "serieColor" }}
        enableCrosshair={false}
        pointLabelYOffset={-12}
        crosshairType="x"
        useMesh={true}
        theme={{
            fontFamily: ""
        }}
        tooltip={({ point }) => (
            <div
                style={{
                    background: "white",
                    padding: '10px 12px',
                    border: '1px solid #E7ECEE',
                    fontSize: 11,
                    color: '#5A686F',
                    boxShadow: '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
                    borderRadius: 8
                }}
            >
                <strong>{format(new Date(point.data.xFormatted.toString()), "iiii, PP")}</strong> <br/>
                <strong>Revenue:</strong> {point.data.yFormatted}
            </div>
        )}
    />
)
