import React, {useEffect, useState} from "react";
import {InfoWidgetContainer, InfoWidgetIntro, InfoWidgetTiles, Tile} from "./InfoWidget.style";
import image from '../../../../../../assets/images/ReferralGif_Final.gif';
import {IconMoney, IconOffer, IconSent} from "./InfoTileIcons";
import {useAppSelector} from "../../../../../../common/hooks";
import {tokenStore, userStore} from "../../../../../../store/auth.slice";
import ReferralsService from "../../Referrals.service";

const InfoWidget = () => {
    const token = useAppSelector(tokenStore)
    const [commission, setCommission] = useState<number>()
    const getReferralToken = async () => {
        try {
            const {data} = await ReferralsService.fetchReferralToken(token)
            setCommission(data.comission === null ? 1 : data.comission)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getReferralToken()
    }, [])

    return (
        <InfoWidgetContainer>
            <InfoWidgetIntro>
                <div className="text">
                    <h2>Earn even more money!</h2>
                    <p>
                        Receive up to an additional <strong>
                        {
                            commission + "%"
                        }
                    </strong> commission once the newly-referred user begins
                        generating revenue with Perform<sup>[cb]</sup>
                    </p>
                </div>
                <div className="image">
                    <img src={image} alt="Earn even more money!"/>
                </div>
            </InfoWidgetIntro>
            <InfoWidgetTiles>
                <Tile>
                    <IconSent className="tile-icon" width={28} height={24}/>
                    <div className="tile-title">
                        1. You invite your friends
                    </div>
                    <div className="tile-description">
                        Send your referral link or share on social using the options below.
                    </div>
                </Tile>
                <Tile>
                    <IconOffer className="tile-icon" width={30} height={25}/>
                    <div className="tile-title">
                        2. They generate revenue
                    </div>
                    <div className="tile-description">
                        After being approved they can run offers and begin to generate revenue.
                    </div>
                </Tile>
                <Tile>
                    <IconMoney className="tile-icon" width={26} height={26}/>
                    <div className="tile-title">
                        3. You get paid
                    </div>
                    <div className="tile-description">
                        When they generate revenue you get paid up to
                        <span> {
                            commission + "% "
                        }</span>
                        commission on their traffic
                    </div>
                </Tile>
            </InfoWidgetTiles>
        </InfoWidgetContainer>
    )
}

export default InfoWidget;
