import React, {ReactElement, useEffect, useState} from "react";
import Modal from "../../../../../common/components/modal/Modal";
import {useCopy} from "../../../../../common/hooks";
import {AddDomainModalBody} from "../add-domain-modal/AddDomainModal.style";
import {ICustomDomainsData} from "../CustomDomain.service";
import Icon from "../../../../../common/components/icon/Icon";
import {InputStyledLabel} from "../../../../../common/styled-components";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";

interface Props {
    previewDomainModal: {
        opened: boolean,
        data: ICustomDomainsData | undefined
    }
    setPreviewDomainModal: (modal: {
        opened: boolean
        data: any
    }) => void
}

const PreviewDomainModal:React.FC<Props> = (props) => {

    const [nameServerCopy, setNameServerCopy] = useState('');
    const [copied, copy, setCopied] = useCopy(nameServerCopy);

    useEffect(()=> {
        nameServerCopy !== '' && copy();
    },[nameServerCopy])

    return(
        <>
            <Modal
                opened={props.previewDomainModal.opened}
                title={'Preview Domain'}
                closeModal={() => props.setPreviewDomainModal({opened: false, data: ''}) }>

                <AddDomainModalBody>
                    <div className={"row"}>
                        <InputStyledLabel $size={'large'}>Type</InputStyledLabel>
                        <p>Name Server</p>
                    </div>
                    <div className={"row"}>
                        <InputStyledLabel $size={'large'}>Domain</InputStyledLabel>
                        <p>{props.previewDomainModal.data?.domain}</p>
                    </div>
                    <div className={'domain-details'}>
                        <p>
                            Add the following records to the DNS configuration of your domain directly in your domain registrar <strong>(Do not use Cloudfare)</strong>. Each DNS service
                            provider will have a different procedure for adding these records. Reference our
                            <a className={'link-orange'} target={'_blank'} href="https://support.performcb.com/support/solutions/articles/4000183700-change-nameservers-with-your-dns-provider-marketers"> How To guide</a> to learn more.
                        </p>
                        <div className={'table-wrapper'}>
                            <table>
                                <thead>
                                <tr>
                                    <th>Host</th>
                                    <th>
                                        <label
                                            className={'name-server-header'}
                                            onClick={()=> props.previewDomainModal.data && setNameServerCopy(props.previewDomainModal.data.name_servers)}>
                                            Name Servers
                                            <Tooltip width={90} position={'right'} icon={<Icon icon="copy" color="#8799A2" size={10} />}>
                                                Copy to clipboard
                                            </Tooltip>
                                        </label>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'domain-column'}>
                                        {props.previewDomainModal.data?.domain}
                                    </td>
                                    <td>
                                        {props.previewDomainModal.data && props.previewDomainModal.data.name_servers.split(',').map((elem, index) => (
                                            <label onClick={()=> setNameServerCopy(elem)} className={'name-server'} key={index}>
                                                <div>{elem}</div>
                                                <Tooltip width={90} position={'right'} icon={<Icon icon="copy" color="#8799A2" size={10} />}>
                                                    Copy to clipboard
                                                </Tooltip>
                                            </label>
                                        ))}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </AddDomainModalBody>
            </Modal>
        </>
    )
}

export default PreviewDomainModal
