import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

const marketerTypes: any[] = [
    {name: 'App Developer'},
    {name: 'Agency'},
    {name: 'Service Provider'},
    {name: 'Network'}
];

export const useMarketerTypeFilter = (pageId: PageID): IFilterHookReturn<any> => {
    const [types, setTypes] = useState<any[]>(marketerTypes)

    const dispatch = useAppDispatch()

    const id = 'marketer-type'

    const marketerTypesFilter = useFilterSelector<any>(pageId, id)

    const setSelectedMarketerTypes = (type: any) => {
        if(marketerTypesFilter.selected.some((selected)  => selected.name === type.name)){
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: marketerTypesFilter.exclude,
                    selected: marketerTypesFilter.selected.filter(accManager => accManager.name !== type.name)
                }
            }))
        } else {
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: marketerTypesFilter.exclude,
                    selected: [...marketerTypesFilter.selected, type]
                }

            }))
        }
    }

    const setExcludedMarketerTypes = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: marketerTypesFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: types,
        // setSearchValue: setPartnerTypesSearchValue,
        notDisplayed: 0,
        selectedData: marketerTypesFilter.selected,
        exclude: marketerTypesFilter.exclude,
        setSelectedData: setSelectedMarketerTypes,
        setExclude: setExcludedMarketerTypes,
        clearSelected: clearSelectedFilter
    }
}