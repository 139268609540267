import styled from 'styled-components'

interface IWrapperProps {
    $focused: boolean
    $valid: boolean
}

export const Wrapper = styled.div<IWrapperProps>`
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${({theme, $focused, $valid}) => $valid ?  $focused ? theme.colors.performBlue[5] : theme.colors.coolGray[3] : theme.colors.critical[3]};
  display: flex;
  align-items: center;
  padding: 0 12px;
  
  span{
    margin-right: 2px;
  }
  
`

export const DateInputStyled = styled.input`
  border: none;
  font-size: 12px;
  color: ${({theme}) => theme.colors.performBlue[5]};
  width: 10ch;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({theme}) => theme.colors.coolGray[3]};
    font-family: ${({theme}) => theme.fonts.AvenirLight};
  }
  
  :placeholder-shown{
    width: 13ch;
  }
  
  :focus{
    outline: none;
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
`