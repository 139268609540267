import React from "react";

import {Wrapper, Content} from "./Container.style";

import {IContainerProps} from "./container.props";

const Container: React.FC<IContainerProps> = React.forwardRef<HTMLDivElement, IContainerProps>(({
                                                           children,
                                                           modalWidth
                                                       },
ref) => {
    return (
        <Wrapper style={{maxWidth: modalWidth}} ref={ref}>
            <Content>
                {children}
            </Content>
        </Wrapper>
    )
})

export default Container
