import React, {useState, useEffect} from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
import {
    ShareWidgetContainer,
    ShareWidgetTitle,
    ShareBox,
    ShareBoxInput,
    ShareBoxCopy,
    ShareBoxSocialToolbar,
    ShareBoxResponse
} from "./ShareWidget.style";
import ReferralsService from "../../Referrals.service";
import {useAppSelector, useCopy} from "../../../../../../common/hooks";
import {tokenStore, userStore} from "../../../../../../store/auth.slice";
import {init, track, setUserId} from "@amplitude/analytics-browser";
import Icon from "../../../../../../common/components/icon/Icon";

const baseURLEndpoint = process.env.REACT_APP_API_BASE_URL;
const referralURLEndpoint = process.env.REACT_APP_API_REFERRALS;
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY
amplitudeApiKey && init(amplitudeApiKey)


const ShareWidget = () => {
    const [referralUrl, setReferralUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [copied, copy, setCopied] = useCopy(referralUrl);

    const user = useAppSelector(userStore)
    setUserId("00" + user.id)

    const copyUrl = () => {
        copy();
        track("Referral link copied")
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const token = useAppSelector(tokenStore)
    const referralShareTitle = 'If you’re not already generating revenue through affiliate marketing, what are you waiting for? Use my referral link to apply to join Perform[cb] and get that money!';

    const getReferralToken = async () => {
        try {
            const {data} = await ReferralsService.fetchReferralToken(token)
            if (baseURLEndpoint && referralURLEndpoint) {
                setReferralUrl(baseURLEndpoint + '/' + referralURLEndpoint + '/' + data.referral_token);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(()=> {
        getReferralToken();
    }, [])

    return(
        <ShareWidgetContainer>
            <ShareWidgetTitle>
                Share your referral link
            </ShareWidgetTitle>

            <ShareBox>
                <ShareBoxInput>
                    {isLoading ? 'Loading...' : <>{referralUrl}</>}
                    <ShareBoxCopy onClick={copyUrl}>
                        <Icon icon="copy" color="#8799A2" size={13} />
                    </ShareBoxCopy>
                    {copied && <ShareBoxResponse className="text-success">Copied!</ShareBoxResponse>}
                </ShareBoxInput>

                <ShareBoxSocialToolbar>
                    <FacebookShareButton
                        url={referralUrl}
                        quote={referralShareTitle}>
                        <FacebookIcon
                            size={28}
                            round={true}
                            iconFillColor={'#1D728D'}
                            bgStyle={{fill: '#ffffff'}} />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={referralUrl}
                        title={referralShareTitle}>
                       <TwitterIcon
                           size={28}
                           round={true}
                           iconFillColor={'#1D728D'}
                           bgStyle={{fill: '#ffffff'}} />
                    </TwitterShareButton>

                    <LinkedinShareButton
                        url={referralUrl}
                        summary={referralShareTitle}
                        title={referralShareTitle}>
                        <LinkedinIcon
                            size={28}
                            round={true}
                            iconFillColor={'#1D728D'}
                            bgStyle={{fill: '#ffffff'}} />
                    </LinkedinShareButton>
                </ShareBoxSocialToolbar>
            </ShareBox>
        </ShareWidgetContainer>
    )
}

export default ShareWidget
