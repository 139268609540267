import styled from "styled-components";
import {DropdownContainer} from "../../../../../../common/components/dropdown/Dropdown.style";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
  
  hr{
    height: 0;
    border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    margin: 5px 0;
  }
  
  .ico-Check{
    color: #00CE2D;
  }

  :before {
    font-family: 'Material Icons Outlined', sans-serif;
    content: '\\e86c';
    font-size: 16px;
    color: #00CE2D;
  }
  
  .platform-name{
    height: 40px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[4]};
    padding: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    line-height: 16px;
    pointer-events: none;
    flex-grow: 1;

    
  }
  
  .more-button {
    width: 16px;
    font-size: 16px;
    position: relative;
    
    .ico-More{
      color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    
    :hover{
      cursor: pointer;
    }
  }
`

export const DropdownContainerStyled = styled(DropdownContainer)`
  top: 20px;
  transform: translateX(-170px);
`