import React from "react";
import {Wrapper} from "./RemoveModal.style";
import {RemoveButton, Cancel, ModalFooter} from "../remove-modal/RemoveModal.style"
import {useAppSelector} from "../../../../../../common/hooks";
import {tokenStore} from "../../../../../../store/auth.slice";
import AdvertisersEditService from "../../AdvertisersEdit.service";

interface RemoveModalProps {
    partner_id: string | undefined,
    marketer_id: string | undefined,
    closeModal: () => void,
    refreshTable: () => void
}

const RemoveModal: React.FC<RemoveModalProps> = ({ partner_id,
                                                     marketer_id,
                                                     closeModal,
                                                     refreshTable}): JSX.Element => {
    const token = useAppSelector(tokenStore)
    const removePartner = async () =>{
        try {
            if (partner_id && marketer_id) {
                const response = await AdvertisersEditService.removePartner(token, marketer_id, partner_id)
                refreshTable()
                closeModal()
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
       <Wrapper>
           Are you sure you want to remove this partner from the marketer's referrals?
           <br/> <br/>
           If you delete them from the marketer's referrals the marketer will no longer have transparency of the partner.

           <ModalFooter>
              <Cancel onClick={closeModal}>Cancel</Cancel>
               <RemoveButton onClick={removePartner}>Yes, remove Partner</RemoveButton>
           </ModalFooter>
       </Wrapper>
    )
}

export default RemoveModal