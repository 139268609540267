import styled, {css} from 'styled-components'

export const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    border: 1px solid red;
    border-radius: 16px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    padding: 24px;
    position: relative;
    box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);
    h3.title {
        line-height: 21px;
        font-size: 16px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[7]};
        margin-bottom: 24px;
        .open-report-button {
            font-size: 15px;
            padding: 3px;
            margin-left: 8px;
        }
    }
  .pending {
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[5]};;
  }
  .bold{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[5]};
  }
  .circle-tooltip {
    margin-right: 4px;
    display: inline-block;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    background: ${({theme}) => theme.colors.performBlue[5]};
  }

  .tooltip-value{
    margin-top: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .hc-tooltip-wrapper{
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    width: max-content;
  }
`

interface IDaySelectorOptionProps {
    $selected: boolean
}
export const DaysSelector = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  display: flex;
  padding: 4px;
`

export const DaySelectorOption = styled.button<IDaySelectorOptionProps>`
  border-radius: 4px;
  flex-grow: 1;
  border: none;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: center;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  color: ${({theme}) => theme.colors.deepBlue[4]};
  background-color: ${({theme}) => theme.colors.pureWhite};
  :hover{
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    cursor: pointer;
  }
  
  ${props => props.$selected && css`
    background-color: ${({theme}) => theme.colors.performBlue[5]};
    color: white;

    :hover{
      background-color: ${({theme}) => theme.colors.performBlue[5]};
      cursor: default;
    }
  `}
`

export const PendingRegistrations = styled.div`
  font-family: ${({theme})=> theme.fonts.AvenirRoman};
  font-size: 34px;
`
export const PendingRegistrationsInTooltip = styled.span`
  font-family: ${({theme})=> theme.fonts.AvenirRoman};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[5]};
`