import {api} from '../../../../../common/services/api';
import {IContacts} from "../../../../../common/models/advertiser-edit.type";

class ContactsService {
    static addContact = async (token: string ,contact: IContacts) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_CONTACTS,
            ].join('/'),
            {...contact},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    static editContact = async (token: string, contact: IContacts) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_CONTACTS,
            contact.id
        ].join('/'),
            {...contact, _method: 'PATCH'},
            {
                   headers: {
                        'Authorization' : `Bearer ${token}`,
                       'Content-Type': 'application/json'
                    }
            }
        )
    }

    static deleteContact = async (token: string, id: number) => {
        return await api.post<IContacts>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_CONTACTS,
                id
            ].join('/'),
            { _method: 'DELETE'},
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
}

export default ContactsService