import { useState } from "react";
import { useColumnsOptionHook } from "../../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import Pagination from "../../../../../../../../common/components/table/pagination/Pagination";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/hooks";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../../NetworkView.service";
import { setAccountManagersMarketersCurrentPage, setAccountManagersMarketersPaginationColumnsList, setAccountManagersMarketersPerPage, setPagination, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../../NetworkView.slice";
import { allAccountManagersPaginationColumns } from "../../topPerformersLists";

const AccountsManagersMarketersPagination = () => {
    const {
        accountManagersMarketersPaginationColumnsList,
        accountManagersMarketersPerPage,
        accountManagersMarketersCurrentPage,
        accountManagersMarketersSort,
        pagination,
        topPerformersLoading,
        selectedManagers,
        includeOrExclude
    } = useAppSelector(state => state.networkView);
    const dispatch = useAppDispatch();
    const {token} = useAppSelector(state => state.auth)

    const accountManagerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setAccountManagersMarketersPaginationColumnsList(list));
    }

    const [enableAllAccountManagersMarketersHandler, disableAllAccountManagersMarketersHandler, enableDisableAccountManagersMarketersColumnHandler]: any = useColumnsOptionHook(
        [], 
        [], 
        () => {},
        allAccountManagersPaginationColumns,
        accountManagersMarketersPaginationColumnsList, 
        accountManagerPaginationColumnsListHandler
    );

    const exportCsv = async () => {
        const params = {
            analyze_by: "top_marketer_acc_managers",
            page: accountManagersMarketersCurrentPage,
            per_page: accountManagersMarketersPerPage,
            AccountManager: [],
            sort_by: accountManagersMarketersSort.by,
            order: accountManagersMarketersSort.direction,
            exclude_acc_mngr: includeOrExclude === 'excl' ? true : false
        }
        NetworkViewService.exportCsvTopPerformersData(params, accountManagersMarketersPaginationColumnsList);
    }

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));
        const accMngs = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
            return item.id;
        })
        
        const params = {
            analyze_by: "top_marketer_acc_managers",
            page: accountManagersMarketersCurrentPage,
            per_page: accountManagersMarketersPerPage,
            AccountManager: [],
            sort_by: accountManagersMarketersSort.by,
            order: accountManagersMarketersSort.direction,
            exclude_acc_mngr: includeOrExclude === 'excl' ? true : false
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);

        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setAccountManagersMarketersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setAccountManagersMarketersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false))
    }

    return (
        <Pagination
            current_page={accountManagersMarketersCurrentPage}
            from={pagination.from}
            to={pagination.to}
            total={pagination.total}
            last_page={pagination.last_page}
            setCurrentPage={(number: number) => dispatch(setAccountManagersMarketersCurrentPage(number))}
            setPerPage={(number: number) => dispatch(setAccountManagersMarketersPerPage(number))}
            exportCsv={() => exportCsv()}
            columnsOptionProps={
                {
                    allPaginationColumns: allAccountManagersPaginationColumns,
                    enableDisableColumnHandler: (column: any) => enableDisableAccountManagersMarketersColumnHandler(column),
                    enableAllHandler: () => enableAllAccountManagersMarketersHandler(),
                    paginationColumnsList: accountManagersMarketersPaginationColumnsList,
                    setPaginationColumnsList: (list: any) => setAccountManagersMarketersPaginationColumnsList(list),
                    disableAllHandler: () => disableAllAccountManagersMarketersHandler()
                }
            }
            perPage={accountManagersMarketersPerPage}
            onRefresh={() => fetchData()}
            loading={topPerformersLoading}
            perPageCustomList={[5, 10, 20, 50, 100]}
        />
    )
}

export default AccountsManagersMarketersPagination;