import { CloseButtonUiFilter, UiFilterWrapper } from "./UiFilter.style";

const UiFilter: React.FC<{setOpenFilters: any, uiFilterClassName?: string | undefined}> = ({uiFilterClassName, children, setOpenFilters}) => {
    return(
        <UiFilterWrapper className={uiFilterClassName}>
            {children}
            <CloseButtonUiFilter onClick={()=> setOpenFilters((prev: boolean) => !prev)}>
                <i className="ico-Exit-outlined close-icon"></i>
            </CloseButtonUiFilter>
        </UiFilterWrapper>
    )
}

export default UiFilter;
