import React, {useEffect, useState} from "react";

import {Wrapper} from "./ManageVerifiedDevices.style";
import {ButtonStyled} from "../../../styled-components";
import Device from "./device/Device";
import AddDeviceModal from "../add-device-modal/AddDeviceModal";
import AlertModal from "../../alert-modal/AlertModal";
import TwoFactorAuthenticationService, {FetchUsersDevicesResponse} from "../../../services/2fa.service";
import {useAppSelector} from "../../../hooks";

type Props = {
    initialOpenAddDeviceModal?: boolean,
    manageTooltip?:boolean
}

const MenageVerifiedDevices: React.FC<Props> = ({initialOpenAddDeviceModal,manageTooltip = true}) => {
    const [addDeviceModalOpened, setAddDeviceModalOpened] = useState(false)
    const [verifyPhoneSuccessModal, setVerifyPhoneSuccessModal] = useState(false)
    const [devices, setDevices] = useState<FetchUsersDevicesResponse[]>([])

    const {token, user} = useAppSelector(state => state.auth)

    const fetchDevices = async () => {
        try {
            const {data} = await TwoFactorAuthenticationService.fetchUsersDevices(token)
            setDevices(data)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if(initialOpenAddDeviceModal){
            setAddDeviceModalOpened(initialOpenAddDeviceModal)
        }
    }, [initialOpenAddDeviceModal])

    useEffect(() => {
        void fetchDevices()
    }, [])

    return <Wrapper>
        <div className={'top-section'}>
            <h3 className={'title'}>Manage Verified Devices</h3>
            <ButtonStyled
                className={'btn-default-outlined'}
                onClick={() => setAddDeviceModalOpened(true)}
            >
                Add New Device
            </ButtonStyled>
        </div>
        {devices.length > 0 ? devices.map(device => {
            return <div key={device.id} className={'device-row'}>
                <Device
                    device={device}
                    fetchDevices={fetchDevices}
                    deleteDisabled={devices.length === 1}
                    manageShowTooltip={manageTooltip}
                />
            </div>
        }) : <>
            <p className={'no-devices-warning'}>No devices have been added for verification yet, click the button above to add one.</p>
        </>}
        <AddDeviceModal
            closeModal={() => setAddDeviceModalOpened(false)}
            opened={addDeviceModalOpened}
            openSuccessModal={setVerifyPhoneSuccessModal}
            fetchDevices={fetchDevices}
        />
        <AlertModal
            isOpen={verifyPhoneSuccessModal}
            type={'success'}
            closeModal={() => setVerifyPhoneSuccessModal(false)}
            closeButtonClassName={'btn-blue-filled'}
        >
            <p>The new device has been added to your account to <br/> use for additional security verification.</p>
        </AlertModal>
    </Wrapper>
}

export default MenageVerifiedDevices