import {FilterID, IOperatingSystem} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {OPERATING_SYSTEMS} from "../../../utils/consts";

export const useOperatingSystemCacheFilter = (pageId: IPagePresetsID) => {
    const [operatingSystems, setOperatingSystem] = useState<IOperatingSystem[]>(OPERATING_SYSTEMS)
    const [operatingSystemsSearchValue, setOperatingSystemSearchValue] = useState('')
    const dispatch = useAppDispatch()
    const id :FilterID = 'operating-system'

    useEffect(() => {
        let filteredSystems = OPERATING_SYSTEMS.filter(system => (
                system.value.toLowerCase()).includes(operatingSystemsSearchValue.toLowerCase()) ||
                system.id.toString().includes(operatingSystemsSearchValue.toLowerCase())
        )
        setOperatingSystem(filteredSystems)
    }, [operatingSystemsSearchValue])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IOperatingSystem) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: operatingSystems,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: 0,
        search: {
            value: operatingSystemsSearchValue,
            setSearch: setOperatingSystemSearchValue
        },
        loading : false
    }
}