import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../common/hooks";
import {setOffer} from "../../../../../create-offer.slice";
import TextArea from "../../../../../../../../../common/components/textarea/TextArea";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";

const tooltip = <i
    className={'ico-Information-outlined'}
    data-html={true}
    // data-multiline={true}
    data-tip={ReactDOMServer.renderToString(
        <>
            <p>One entry per line</p>
        </>)}
/>

const SubjectLinesInput: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setOffer({
            campaign_email_setting: {
                ...offer.campaign_email_setting,
                subject_lines: e.target.value,
                subject_lines_aff: e.target.value,
            }
        }))
    }


    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return <TextArea
        style={{
            resize: 'vertical'
        }}
        label={<>Subject Lines <span className="input-label-optional">(optional)</span> {tooltip}</>}
        placeholder={'Enter any subject lines using one line per entry'}
        value={offer.campaign_email_setting.subject_lines}
        onChange={(e) => handleOnChange(e)}
    />
}

export default SubjectLinesInput

/*
* TODO:
*  - value and onChange
* */