import axios, {AxiosResponse} from "axios";
import {api} from "../../../../../common/services/api";

export enum PLATFORMS {
    'Leap',
    'AvantLink',
    'AWIN',
    'CommissionJunction',
    'Impact',
    'PepperJam',
    'Rakuten',
    'ShareASale'
}

export enum SORT {
    'relevance_score_a',
    'name',
}

export interface INonMergedPartner {
    id: number,
    platform_id: number,
    platform_partner_name?: string,
    platform?: string,
    platform_partner_company?: string,
    platform_partner_id: number,
    partner_traffic_type : IPartnerTrafficType[] | null
    tags: ITagNonMerged[]
}

export interface ITagNonMerged {
    tag_id: number,
    name: string,
    is_selected: boolean,
}

export interface INonMergedPartnersParams {
    from?: string | null | undefined,
    to?: string | null | undefined,
    page?: number,
    paginate_by?: number,
    sort_by: string,
    order: string
    q?: string,
    action?: string
}

export interface NonMergedUser {
    id: number,
    platform_id: number
}

export interface IAssignTag {
    add?: boolean,
    tags?: number[],
    users?: NonMergedUser[],
    merged_partner_ids?: number[]
}

export interface IMergedPartnerList {
    platform_partner_id: number,
    platform_partner_name: string,
    platform_partner_company: string,
    platform_id: number,
    merged_partner_id: number
}

export interface IMergedPartner {
    id: number,
    name: string,
    count: number,
    tags: ITagNonMerged[],
    expanded?: boolean,
    partnerList?: IMergedPartnerList[],
    partner_traffic_type : IPartnerTrafficType[] | null
}

export interface ITag {
    name: string,
    id: number
}

export interface IMergedPartnersParams {
    from?: string | null | undefined,
    to?: string | null | undefined,
    page?: number,
    paginate_by? : number,
    sort_by: string,
    order: string
    q? : string,
    action? : string
}

export interface IPartnerTrafficType {
    id : number,
    name : string
}

export interface IUserForMerging {
    company_name: string,
    id: number,
    model_id: number
    name: string
    platform_id: number,
    tags : ITag[],
    ptt? : IPartnerTrafficType | null
}

export interface IUsersForMerging {
    merged_partner_id?: number
    count?: number,
    name?: string,
    traffic_types?: number[] | null,
    tags?: ITag[],
    users: IUserForMerging[],

}

export interface IMergedPreview {
    ids: number[],
    names : string[],
    platforms: string[],
    tags: ITag[],
    trafficType: IPartnerTrafficType,
    expanded? : boolean
}

export interface IExistingMergedPartners {
    id : number,
    partnersList: IMergedPartnerList[]
}

class MergingService {
    static fetchNonMergedPartners = async (token: string, params: INonMergedPartnersParams):
        Promise<AxiosResponse> => {
        return await api.get<INonMergedPartner>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                process.env.REACT_APP_API_NON_MERGED,
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params
            })
    }
    static addTags = async (token: string, params: IAssignTag): Promise<AxiosResponse> => {
        try {
            return await api.post([process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "save-tags"].join("/"),
                {
                    ...params
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }

    static removeTags = async (token: string, params: IAssignTag): Promise<AxiosResponse> => {
        try {
            return await api.post([process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "remove-partner-tags"].join("/"),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )

        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
    static fetchMergedPartners = async (token: string, params: IMergedPartnersParams):
        Promise<AxiosResponse> => {
        return await api.get<IMergedPartner>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                process.env.REACT_APP_API_MERGED,
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params
            })
    }
    static fetchMergedPartnersList = async (token: string, id: number):
        Promise<AxiosResponse> => {
        return await api.get<IMergedPartnerList[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                'get-partners'
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    merged_partner_id: id
                }
            })
    }
    static unMergePartners = async (token: string, ids: number[]):
        Promise<AxiosResponse> => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                'unmerge-partners'
            ].join("/"),
            {
                merged_partner_ids:ids
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
    }

    static fetchTrafficTypes = async (token: string) => {
        try {
            return await api.get<IPartnerTrafficType>([
                process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "get-partner-traffic-types"].join("/"),
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
    static mergePartners = async (token: string, params: IUsersForMerging ) =>{
        try{
            return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_MERGING_PARTNERS,
                "save-merged-partner"
            ].join("/"),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
                )

        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }

    }
    static mergeExistingPartners = async (token: string, params: IUsersForMerging ) =>{
        try{
            return await api.post([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "save-existing-merged-partner"
                ].join("/"),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )

        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }

    }

    static saveMergedPartnerDetails = async (token: string, params: IUsersForMerging ) =>{
        try{
            return await api.post([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "save-existing-merged-partner"
                ].join("/"),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )

        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
    static saveNonMergedPartnerDetails = async (token: string, params: IUsersForMerging ) =>{
        try{
            return await api.post([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_MERGING_PARTNERS,
                    "save-partner-traffic-types"
                ].join("/"),
                {
                    ...params
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )

        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }

    }
}

export default MergingService
