import styled from "styled-components";

export const SwitchToggleButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1;
`;

export const SwitchToggle = styled.div<{$active: boolean, $labelFirst: boolean | undefined}>`
    background-color: ${({$active})=> $active ? "#25C0D5" : "#D0D5D7"};
    width: 20px;
    height: 12px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    transition: .2s ease;
    margin-left: ${({$labelFirst}) => $labelFirst ? "8px" : "0"};
    &:hover {
        background-color: ${({$active})=> $active ? "#0496AA" : "#BEC1C2"};
    }
    div{
        background-color: #FFFFFF;
        width: 8px;
        height: 8px;
        margin-left: ${({$active}) => $active ? "10px" : "2px"};
        transition: .2s ease;
        border-radius: 100%;
    }
`;

export const SwitchToggleLabel = styled.div`
    margin-left: 8px;
`;

// Disabled Styles
export const SwitchToggleLabelDisabled = styled.div`
    margin-left: 8px;
    color: ${({theme}) => theme.colors.coolGray[4]};
    cursor: default;
`;

export const SwitchToggleDisabled = styled.div<{$active: boolean, $labelFirst: boolean | undefined}>`
    background-color: #E9ECED;
    width: 20px;
    height: 12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: default;
    margin-left: ${({$labelFirst}) => $labelFirst ? "8px" : "0"};
    div{
        background-color: #FFFFFF;
        width: 8px;
        height: 8px;
        margin-left: ${({$active}) => $active ? "10px" : "2px"};
        border-radius: 100%;
    }
`;
