import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {IFilterHookReturn, ITagFilter} from "filters";
import {useFilterSelector} from "../useFilterSelector";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useDispatch} from "react-redux";

export const useTagsFilter = (pageId: PageID,displayCount?: number): IFilterHookReturn<ITagFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<ITagFilter[]>([])
    const [tags, setTags] = useState<ITagFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [tagsSearchValue, setTagsFilterSearchValue] = useState('')
    const [tagsFilterNotDisplayed, setTagsFilterNotDisplayed] = useState(0)

    const dispatch = useDispatch()

    const id = 'tags'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<ITagFilter[]>(
                'tags',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data.sort((a: ITagFilter, b: ITagFilter) => (
                    a.name.toLowerCase()).localeCompare(b.name.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredTags = fetchedData.filter(tag => (
                tag.name.toLowerCase()).includes(tagsSearchValue.toLowerCase())
            ||
            tag.id.toString().includes(tagsSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredTags].slice(0, displayCount)
            if(filteredTags.length > displayCount){
                setTagsFilterNotDisplayed(filteredTags.length - displayCount)
            } else {
                setTagsFilterNotDisplayed(0)
            }
            setTags(displayedAcc)
        } else {
            setTags(
                filteredTags
            )
        }


    }, [tagsSearchValue, fetchedData, displayCount])


    const tagsFilter = useFilterSelector<ITagFilter>(pageId, id)

    const setSelectedTags = (tag: ITagFilter) => {
        if(tagsFilter.selected.some((filterTag)  => filterTag.id === tag.id)){
            dispatch(setFilter<ITagFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: tagsFilter.exclude,
                    selected: tagsFilter.selected.filter(tagFilter => tagFilter.id !== tag.id)
                }
            }))
        } else {
            dispatch(setFilter<ITagFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: tagsFilter.exclude,
                    selected: [...tagsFilter.selected, tag]
                }

            }))
        }
    }

    const setExcludeTags = (exclude: boolean) => {
        dispatch(setFilter<ITagFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: tagsFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: 'tags'}))
    }


    return {
        id,
        pageId: pageId,
        loading,
        data: tags,
        setSearchValue: setTagsFilterSearchValue,
        notDisplayed: tagsFilterNotDisplayed,
        selectedData: tagsFilter.selected,
        exclude: tagsFilter.exclude,
        setSelectedData: setSelectedTags,
        setExclude: setExcludeTags,
        clearSelected: clearSelectedFilter,
        match: tagsFilter.match
    }
}