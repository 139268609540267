import styled, {css} from 'styled-components'
import {InputSingle} from "../../../../../../common/components/input-single-line/InputSingleLine.style";

interface IButtonProps {
    disabled?: boolean
}

export const Wrapper = styled.div`
  width: 560px;
  min-width: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .row{
    margin-top: 32px;
  }
`

export const Label = styled.label`
  display: inline-block;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  margin-bottom: 8px;
`

export const TextInput = styled(InputSingle)`
  width: 100%;
  height: 40px;
`

export const Cancel = styled.div`
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  :hover{
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue['7']};
  }
  
  :hover:active{
    color: ${({theme}) => theme.colors.deepBlue['9']};
  }

`

export const Button = styled.div<IButtonProps>`
  padding: 8px 16px;
  color: white;
  font-size: 12px;
  background-color: ${({theme}) => theme.colors.performOrange['3']};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  line-height: 1.55;
  
  :hover{
    background-color: ${({theme}) => theme.colors.performOrange['4']};
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.performOrange['7']};
  }
  
  ${props => {
    if(props.disabled){
        return css`
          pointer-events: none;
          background-color: ${({theme}) => theme.colors.coolGray['2']};
          color: ${({theme}) => theme.colors.coolGray['4']};
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
        `
    }
    return
}}
`