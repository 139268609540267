import React, {useState} from "react";
import {Wrapper, ContentWrapper, Nav, NavItem, Content} from "../Merging.style";
import Merged from "./merged/Merged";
import NonMerged from "./non_merged/NonMerged";

const Merging : React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>("merged");
    const tabSwitchHandler = (tab: string) => {
        if (tab === "merged") {
            setActiveTab("merged");
        } else {
            setActiveTab("non-merged");
        }
    }
    return(
        <Wrapper>
            <ContentWrapper>
                <Nav>
                    <NavItem active={activeTab === 'merged'} onClick={()=> tabSwitchHandler('merged')}>
                        Merged Partners
                    </NavItem>
                    <NavItem active={activeTab === 'non-merged'} onClick={()=> tabSwitchHandler('non-merged')}>
                        Non-merged Partners
                    </NavItem>
                </Nav>
                <Content>
                    {
                        activeTab === 'merged' ?
                            <Merged/> : <NonMerged/>
                    }
                </Content>
            </ContentWrapper>
        </Wrapper>
    )
}

export default Merging