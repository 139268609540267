import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import AdvertisersEditService from "./AdvertisersEdit.service";
import {IAdvertiserEdit, IContacts} from "../../../../common/models/advertiser-edit.type";
import {AlertModalType} from "../../../../common/components/alert-modal/alertModal.props";
import ContactsService from "./contacts/Contacts.service";

export const getEditingUser = createAsyncThunk<IAdvertiserEdit, {
    token: string,
    id: number
}, {
    rejectValue: string
}>(
    'users/getEditingUser',
    async (params, {rejectWithValue}) => {
        try {
            const response = await AdvertisersEditService.fetchEditingUser(params.token, params.id)
            return response.data
        } catch (err){
            return rejectWithValue("Failed to get the user")
        }

    }
)

export const saveUser = createAsyncThunk<IAdvertiserEdit, {
    token: string,
    id: number,
    user: IAdvertiserEdit
}, {
    rejectValue: string
}>(
    'users/saveUser',
    async (params, {rejectWithValue}) => {
        try {
            const response = await AdvertisersEditService.saveUser(params.token, params.user, params.id )
            return response.data
        } catch (err){
            return rejectWithValue("Failed to save the user")
        }
    }
)

export const deleteContact = createAsyncThunk(
    'users/deleteContact',
    async (params: {token: string, id: number}, {rejectWithValue}) => {
        try {
            const {data} = await ContactsService.deleteContact(params.token, params.id)
            return data
        } catch (err){
            console.log(err)
        }
    }
)

interface IAdvertisersEditState {
    editingUser: null | IAdvertiserEdit
    loading: boolean,
    error: null | string | undefined,
    saveUserError: null | string | undefined,
    alertModal: {
        opened: boolean,
        type: AlertModalType,
        message: string
    }
}

const initialState: IAdvertisersEditState = {
    editingUser: null,
    loading: false,
    error: null,
    saveUserError: null,
    alertModal: {
        opened: false,
        type: 'success',
        message: ''
    }
}

const advertisersEditSlice = createSlice({
    name: 'advertisersEdit',
    initialState,
    reducers: {
        openAlert: (state ,action) => {
            state.alertModal = action.payload
        },
        closeAlert: (state) => {
            state.alertModal = {...state.alertModal, opened: false}
        },
        setUser: (state, action ) =>{
            state.editingUser = action.payload
        },
        editContact: (state, action:PayloadAction<IContacts>) => {
            if(state.editingUser){
                const contactIndex = state.editingUser.contacts.findIndex(contact => contact.id === action.payload.id)
                state.editingUser.contacts[contactIndex] = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            //Fetch user
            .addCase(getEditingUser.fulfilled, (state, action) => {
                state.editingUser = {...action.payload,
                    contacts: [
                        {
                            company_name: action.payload.company,
                            name: action.payload.name,
                            contact_type: 'Account Owner',
                            im_type: 'other',
                            work_phone: '',
                            fax: '',
                            job_title: '',
                            lastname: action.payload.lastname,
                            email: action.payload.email,
                            im: '',
                            cell_phone: action.payload.phone,
                            newsletter: 1,
                            include_compliance_emails: true
                        }
                        ,...action.payload.contacts
                    ]}
                state.loading = false
                state.error = null
            })
            .addCase(getEditingUser.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getEditingUser.rejected, (state, action) => {
                state.editingUser = null
                state.loading = false
                state.error = action.payload
            })
            //Save user
            .addCase(saveUser.fulfilled, (state, action) => {
                state.editingUser = {...action.payload,
                    contacts: [
                        {
                            company_name: action.payload.company,
                            name: action.payload.name,
                            contact_type: 'Account Owner',
                            im_type: 'other',
                            work_phone: '',
                            fax: '',
                            job_title: '',
                            lastname: action.payload.lastname,
                            email: action.payload.email,
                            im: '',
                            cell_phone: action.payload.phone,
                            newsletter: 1,
                            include_compliance_emails: true
                        }
                        ,...action.payload.contacts
                    ]}
                state.loading = false
                state.saveUserError = null
                state.alertModal = {
                    type: 'success',
                    message: 'User Saved',
                    opened: true
                }
            })
            .addCase(saveUser.pending, (state, action) => {
                state.loading = true
                state.saveUserError = null
            })
            .addCase(saveUser.rejected, (state, action) => {
                state.saveUserError = action.payload
                state.loading = false
            })
            .addCase(deleteContact.fulfilled, (state, action) => {
                state.loading = false
                if(state.editingUser){
                    state.editingUser = {
                        ...state.editingUser,
                        contacts: state.editingUser?.contacts.filter(contact => {
                            return contact.id !== action.payload?.id
                        })
                    }
                }
            })
            .addCase(deleteContact.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteContact.rejected, (state, action) => {
                state.loading = false
                state.alertModal = {
                    type: 'error',
                    message: 'Contact not deleted',
                    opened: true
                }
            })
    }
})

export default advertisersEditSlice.reducer
export const {openAlert, closeAlert, setUser, editContact} = advertisersEditSlice.actions
