import React, {useState, useEffect, useRef} from 'react'
import {Wrapper} from './TruncateContent.style'

interface Props {
    numberOfLines: number
    wrapperClassName?: string
    showMoreButtonClassName?: string
}

const TruncateContent: React.FC<Props> = ({
    numberOfLines= 1,
    wrapperClassName,
    showMoreButtonClassName,
    children
                                          }) => {

    const [showMore, setShowMore] = useState(false)

    const handleShowMore = () => {
        setShowMore(prev => !prev)
    }

    const ref = useRef<HTMLDivElement | null>(null);
    const [numLines, setNumLines] = useState(0);



    useEffect(() => {

        const calculateNumberOfLines = () => {
            const node = ref.current;

            if (node) {
                // const fontSize = window.getComputedStyle(node).getPropertyValue('font-size');
                const lineHeight = window.getComputedStyle(node).getPropertyValue('line-height');
                const height = node.getBoundingClientRect().height;
                const numLines = Math.round(height / parseFloat(lineHeight));
                setNumLines(numLines);
            }
        }

        calculateNumberOfLines()

        window.addEventListener('resize', calculateNumberOfLines)

        return () => {
            window.removeEventListener('resize', calculateNumberOfLines)
        }
    }, [children]);

    return <Wrapper
        className={wrapperClassName}
        numberOfLines={numberOfLines}
        showMore={showMore}
    >
        <div ref={ref} style={{visibility: 'hidden',position: 'absolute', width: '100%'}}>{children}</div>
        <div className={showMore ? '' : 'truncate'}>

            {children}
            {numLines > numberOfLines ?
                <button
                    className={showMoreButtonClassName}
                    onClick={handleShowMore}
                >
                    {showMore ? <>&nbsp;</> : ''} {showMore ? 'Show Less' : '... Show More'}
                </button> : null
            }

        </div>

    </Wrapper>
}

export default TruncateContent