import styled from "styled-components";

export const TagsListWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  & > div {
    margin-top: 0;
  }

  .loading-dots {
    text-align: center;
    span {
      animation: loading-dots 1s infinite;
      width: 5px;
      height: 5px;
      background: #EF4A24;
      margin: 0 3px;
      display: inline-block;
      border-radius: 100%;
    }

    span:nth-child(2) {
      animation-delay: 0.1s;
    }

    span:nth-child(3) {
      animation-delay: 0.3s;
    }

    @keyframes loading-dots {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
`
export const TagLabel = styled.label`
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  font-size: 12px;
  line-height: 16px;
  margin-right: 5px;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  display: flex;
  align-items: center;
  :before {
    font-family: 'leap';
    content: "\\e915";
    font-size: 18px;
    margin-right: 8px;
  }
`
export const TagAddButton = styled.span<{$open: boolean}>`
  letter-spacing: -1px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 24px;
  height: 24px;
  background: ${({$open, theme}) => $open ? theme.colors.coolGray[4] : theme.colors.pureWhite };
  color: ${({$open, theme}) => $open ? theme.colors.deepBlue[8] : theme.colors.deepBlue[4] };
  border: 1px solid ${({$open}) => $open ?  "#E9ECED" :  "#BEC1C2"};
  border-radius: 100%;
`

export const TagAddButtonWrapper = styled.div`
    position: relative;
    `