import {PageID, IFilter, IFiltersInitialState} from "../../store/filters.slice";
import {FilterID} from 'filters'
import {useAppSelector} from "./useAppSelector";
import {useEffect, useState} from "react";

export const useFilterSelector = <T>(pageID: PageID, filterID: FilterID) => {
    const {filters}: IFiltersInitialState<T> = useAppSelector<IFiltersInitialState<T>>(state => state.filters)
    const [filter, setFilter] = useState<IFilter<T>>({id: filterID, selected: [], exclude: false})

    useEffect(() => {
        //Check if there are filters for selected page
        if(filters.some(filter => filter.pageID === pageID)){
            const pageIndex = filters.findIndex(filter => filter.pageID === pageID)
            //Check if there is filter on selected page with selected filterID
            if(filters[pageIndex].filters.some(filter => filter.id === filterID)){
                const filterIndex = filters[pageIndex].filters.findIndex(filter => filter.id === filterID)
                setFilter(filters[pageIndex].filters[filterIndex])
            }
        }
    }, [pageID, filterID, filters])

    return filter
}