


export type TPreset =
    "today" |
    "yesterday" |
    "this_week"  |
    "last_week" |
    "this_month" |
    "last_month" |
    "this_year" |
    "last_7_days" |
    "month_to_yesterday" |
    "quarter_to_date" |
    "last_quarter" |
    "year_to_yesterday" |
    "year_to_last_full_month" |
    "last_year" |
    "custom";

export interface IPreset {
    value: TPreset,
    name: string
}

export const presets : IPreset[] = [
    {
        value: "today",
        name: "Today"
    },
    {
        value: "yesterday",
        name: "Yesterday"},
    {
        value: "this_week",
        name: "This Week"
    },    {
        value: "last_week",
        name: "Last Week"
    },    {
        value: "this_month",
        name: "This Month"
    },    {
        value: "last_month",
        name: "Last Month"
    },
    {
        value: "this_year",
        name: "This Year"
    },    {
        value: "custom",
        name: "Custom"
    },
]

export interface IPresetProps {
    preset: IPreset
    setPreset: (preset: IPreset) => void
}