import React from 'react'
import {Body, Footer} from "../../../../../../../../common/components/modalWindow/container/Container.style";
import {DnDZoneWrapper} from "../../Main.style";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {useDropzone} from "react-dropzone";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import CreateOfferService from "../../../../CreateOffer.service";

import {Wrapper, UploadedFiles} from './UploadZipCode.style'
import Loading from "../../../../../../../../common/components/loading/Loading";

import {setOffer} from "../../../../create-offer.slice";

interface Props {
    closeModal: () => void
}


const UploadZipCode: React.FC<Props> = ({closeModal}) => {
    const {token} = useAppSelector(state => state.auth)
    const {offer} = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    const {
        getRootProps,
        getInputProps,
        isDragReject,
        isDragActive,

    } = useDropzone({
        maxFiles: 10,
        accept: {
            'text/csv': ['.csv', '.txt']
        },
        onDrop: (acceptedFiles, fileRejections, event) => {
            acceptedFiles.forEach((file) => {
                void uploadZipcodes(file)
            })

        },
    });

    const uploadZipcodes = async (file: File) => {
        dispatch(setOffer({zipcode_files: [...offer.zipcode_files, {
                error: false,
                name: file.name,
                uploaded: false,
                loading: true,
                data: []
            }]}))
        try{
            //TODO: Add campaign id
            const {data} = await CreateOfferService.uploadZipcodes(token, 100, file)
            dispatch(setOffer({zipcode_files: [...offer.zipcode_files, {
                    error: false,
                    name: file.name,
                    uploaded: true,
                    data: data.data,
                    loading: false
                }]}))
        } catch (e: any) {
            dispatch(setOffer({zipcode_files: [...offer.zipcode_files, {
                    data: [],
                    name: file.name,
                    error: e.response.data.error as string,
                    uploaded: false,
                    loading: false
                }]}))
        }
    }

    const deleteZipCode = async (name: string) => {
        try {
            await CreateOfferService.deleteZipCode(token, 100, name)

            const newZipcodes = [...offer.zipcode_files]
            const index = newZipcodes.findIndex(zip => zip.name === name)
            newZipcodes.splice(index, 1)
            dispatch(setOffer({zipcode_files: [...newZipcodes]}))
        } catch (e) {
            console.log(e)
        }
    }

    const handleInsertZipCodes = () => {
        let newZipcodes = [] as string[]
        offer.zipcode_files.forEach((file: any) => {
            newZipcodes = [...newZipcodes, ...file.data]
        })
        dispatch(setOffer({selected_zipcodes: [...offer.selected_zipcodes, ...newZipcodes]}))
        closeModal()
    }

    return <Wrapper>
        <Body>
            <DnDZoneWrapper dragActive={isDragActive} {...getRootProps({
                style: {
                    borderColor: isDragReject ? 'red' : '',
                }
            })}>
                <input {...getInputProps()}/>
                <div style={{
                    transition: 'all .2s ease-in-out'
                }}>
                    <div>
                        <i className="ico-Upload"/>
                    </div>
                    <h4>Drag & Drop file here or <span>Choose File</span> to upload</h4>
                    <p className="supported">
                       Upload list of Zipcodes in TXT.
                    </p>
                    <p className={'supported'}>
                        Zipcodes must be separated by a comma or a new line.
                    </p>
                </div>
            </DnDZoneWrapper>
            {offer.zipcode_files.length > 0 &&
                <UploadedFiles>
                    {offer.zipcode_files.map((file: any) => {
                        return <div className={'file'} key={file.name}>
                            <div style={{
                                color: file.error ? 'red' : ''
                            }}>
                                <div>{file.name} </div>
                                {file.error && <div>({file.error})</div>}
                            </div>
                            <div>
                                {file.loading ?
                                    <Loading size={6}/> :
                                    <i className={'ico-Exit'} onClick={() => deleteZipCode(file.name)}/>
                                }
                            </div>
                        </div>
                    })}
                </UploadedFiles>
            }
        </Body>
        <Footer>
            <ButtonStyled onClick={closeModal}>Close</ButtonStyled>
            {offer.zipcode_files.length > 0 &&
                <ButtonStyled
                    className={'btn-orange-filled'}
                    onClick={handleInsertZipCodes}
                >
                    Insert {offer.zipcode_files.length} files
                </ButtonStyled>
            }
        </Footer>
    </Wrapper>
}

export default UploadZipCode