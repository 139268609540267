import styled, { css } from "styled-components";

export const BillingFeesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    .group {
        color: #0496AA;
        margin-right: 3px;
    }

    .fee {
        padding-left: 25px;
        .fee-text {
            color: #EF4A24;
            margin-right:3px;
        }
    }

    .ico-ChevronUp, .ico-ChevronDown {
        margin-right: 3px;
    }

    .delete-option {
        color: #FA1E3A;
    }

    .p-icon {
        background: #0496AA;
        line-height: 16px;
        width: 16px;
        height: 16px;
        -webkit-border-radius: 16px;
        border-radius: 16px;
        font-size: 12px;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-weight: 700;
        margin-left: 5px;
    }

    .ag-row {
        cursor: pointer;
    }

    .ag-cell {
        padding: 0;
    }

    .ag-cell-wrapper {
        width: 100%;
        min-height: 100%;
    }

    .custom-cell {
        width: 100%;
        min-height: 30px;
        padding: 5px 0 5px 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        color: #31383B;
    }

    .tiers-list {
        width: 100%;
        padding-left: 60px;
    }

    .amounts {
        width: 100%;
        padding-left: 15px;
        /* margin-top: 10px; */
    }

    .amounts-list {
        width: 100%;
        padding-left: 15px;
        margin-top: 10px;
    }

    .tier-in {
        padding-left: 40px;
    }
`;

export const BillingFeesCreateNewSection = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 16px;
    }
`;

export const BillingFeesTableSection = styled.div`

`;

export const BilingFeesDeleteModalBody = styled.div<{isDeleteDisabled: boolean}>`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    /* color: #8799A2; */

    .question { 
        padding-right: 50px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: #31383B;
        width: 420px;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .button {
            cursor: pointer;
            .cancel {
                padding: 8px 10px 8px 0;
                font-size: 12px;
                color: #8799A2;
                transition: .2s ease-in-out;
                &:hover {
                    color: #31383B;
                }
            }
            .delete {
                border-radius: 8px;
                color: ${({isDeleteDisabled, theme}) => isDeleteDisabled ? theme.colors.coolGray[5] : theme.colors.pureWhite};
                background-color: ${({isDeleteDisabled, theme}) => isDeleteDisabled ? theme.colors.coolGray[3] : theme.colors.performOrange[3]};
                padding: 8px 10px;
                transition: .2s ease-in-out;
                font-size: 12px;
                ${({isDeleteDisabled}) => !isDeleteDisabled && css`
                &:hover {
                    background: #E96142;
                    border-color: #FE7E60;
                }
                `}
            }
        }
    }

`;