import { format } from "date-fns";
import { FilterID } from "filters";
import { useEffect, useMemo, useState } from "react";
import Grid from "../../../../../common/components/grid/Grid";
import Icon from "../../../../../common/components/icon/Icon";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import SortableHeaderV2 from "../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import { ButtonStyled } from "../../../../../common/styled-components";
import { formatNumberToCurrency } from "../../../../../common/utils";
import { PageID } from "../../../../../store/filters.slice";
import MarketerBalanceDateRange from "./date-range/MarketerBalanceDateRange";
import Filters from "./filters/Filters";
import MarketerBalanceService, { IMarketerBalanceResponse } from "./MarketerBalance.service";
import { setPerPage, setSort } from "./MarketerBalance.slice";
import { MarketerBalanceWrapper } from "./MarketerBalance.style";

const MarketerBalance = () => {
    const [tableData, setTableData] = useState<IMarketerBalanceResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);

    const {filters} = useAppSelector((state:any) => state.filters)

    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(1)
    const [total, setTotal] = useState(1)
    const [lastPage, setLastPage] = useState(1);

    const readFilter = (page: PageID, filterId: FilterID) => {
        return filters?.find((el: any) => 
            el.pageID === page)?.
            filters.find((filter: any) => filter.id === filterId) || null
    }

    const advertisersFilter = readFilter('admin/billing/marketer-balance', 'advertiser')
    const statusFilter = readFilter('admin/billing/marketer-balance', 'marketer-status')

    // useEffect(() => {
    //     console.log(advertisersFilter);
    //     console.log(statusFilter);
        
        
    // }, [advertisersFilter, statusFilter])

    const {token} = useAppSelector(state => state.auth)
    const {per_page, sort, date_from, date_to} = useAppSelector(state => state.marketerBalance);

    const fetchData = async () => {
        setLoading(true)
        setDiscoverDisplayed(false)
        try {
            let params: {
                paginate_by?: number,
                sort_by?: string,
                order?: "desc" | "asc",
                page?: number,
                from: string,
                to: string,
                advertiser_id?: number,
                user_status?: string 
            } = {
                from: format(new Date(date_from ? date_from : new Date()), "y-MM-dd"),
                to: format(new Date(date_to ? date_to : new Date()), "y-MM-dd"),
            }

            if(advertisersFilter && advertisersFilter.selected.length) {                
                params.advertiser_id = advertisersFilter.selected[0].id
            }

            if(statusFilter && statusFilter.selected.length) {
                params.user_status = statusFilter.selected[0].status_name
            }
            
            if(sort.by !== ""){
                params.sort_by = sort.by;
                params.order = sort.direction;
            }

            if(page > 1){
                params.page = page
            }

            if(per_page !== 50) {
                params.paginate_by = per_page
            }

            const response = await MarketerBalanceService.fetchMarketerBalanceData(token, params)

            setTableData(response.data.data)
            setPage(response.data.current_page)
            dispatch(setPerPage(response.data.per_page))
            setFrom(response.data.from)
            setTo(response.data.to)
            setTotal(response.data.total)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.log(error);
            
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [sort, page, per_page])

    const sortHandler = (sort: {
        direction: 'desc' | 'asc',
        by: string
    }) => {
        dispatch(setSort(sort));
    }

    const columnDefs:any = [
        {   
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                // return <div>{format(new Date(transaction.created_at.replace(/-/g, "/")), "MM/dd/y")}</div>
                return <div>{marketer.id}</div>
            },
            // headerName: "DATE"
            headerName: "MARKETER ID"
        },
        {   
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                return <div>{marketer.full_name}</div>
            },
            headerName: "MARKETER NAME"
        },
        {
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                return <div>{marketer.status_name}</div>
            },
            headerName: "ACCOUNT STATUS"
        },
        {
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                return <div>{formatNumberToCurrency(marketer.start_balance)}</div>
            },
            headerComponent: (data: any ) => {
                return <SortableHeaderV2 
                    onClick={() => sortHandler({
                        by: "start_balance",
                        direction: sort.by === "start_balance" && sort.direction === "desc" ? "asc" : "desc"
                    })}   
                    keyId="start_balance" 
                    sortedBy={sort.by} 
                    sortDirection={sort.direction}
                >
                    STARTING BALANCE
                </SortableHeaderV2>
            }
        },
        {
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                return <div>{formatNumberToCurrency(marketer.sum_credit)}</div>
            },
            headerComponent: (data: any ) => {
                return <SortableHeaderV2 
                    onClick={() => sortHandler({
                        by: "sum_credit",
                        direction: sort.by === "sum_credit" && sort.direction === "desc" ? "asc" : "desc"
                    })}   
                    keyId="sum_credit" 
                    sortedBy={sort.by} 
                    sortDirection={sort.direction}
                >
                    CREDITS
                </SortableHeaderV2>
            }
        },
        {
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;
                return <div>{formatNumberToCurrency(marketer.sum_debit)}</div>
            },
            headerComponent: (data: any ) => {
                return <SortableHeaderV2 
                    onClick={() => sortHandler({
                        by: "sum_debit",
                        direction: sort.by === "sum_debit" && sort.direction === "desc" ? "asc" : "desc"
                    })}   
                    keyId="sum_debit" 
                    sortedBy={sort.by} 
                    sortDirection={sort.direction}
                >
                    DEBITS
                </SortableHeaderV2>
            }
        },
        {
            flex: 1,
            cellRenderer: (data: any) => {
                const marketer: IMarketerBalanceResponse = data.data;        
                return <div>{formatNumberToCurrency(marketer.ending_balance)}</div>
            },
            headerComponent: (data: any ) => {
                return <SortableHeaderV2 
                    onClick={() => sortHandler({
                        by: "ending_balance",
                        direction: sort.by === "ending_balance" && sort.direction === "desc" ? "asc" : "desc"
                    })}   
                    keyId="ending_balance" 
                    sortedBy={sort.by} 
                    sortDirection={sort.direction}
                >
                    ENDING BALANCE
                </SortableHeaderV2>
            }
        }
    ]

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50,
        autoHeight: true
    }), []);

    const exportCsv = () => {
        let params: {
            paginate_by?: number,
            sort_by?: string,
            order?: "desc" | "asc",
            page?: number,
            from: string,
            to: string,
            advertiser_id?: number,
            user_status?: string 
        } = {
            from: format(new Date(date_from ? date_from : new Date()), "y-MM-dd"),
            to: format(new Date(date_to ? date_to : new Date()), "y-MM-dd"),
        }

        if(advertisersFilter && advertisersFilter.selected?.length) {
            params.advertiser_id = advertisersFilter.selected[0].id
        }

        if(statusFilter && statusFilter.selected?.length) {
            params.user_status = statusFilter.selected[0].status_name
        }
        
        // if(sort.by !== ""){
        //     params.sort_by = sort.by;
        //     params.order = sort.direction;
        // }

        // if(page > 1){
        //     params.page = page
        // }

        // if(per_page !== 50) {
        //     params.paginate_by = per_page
        // }
        
        MarketerBalanceService.exportToCsv(params)
    }

    useEffect(() => {        
        setDiscoverDisplayed(false)
    }, [])
    
    return(
        <MarketerBalanceWrapper>
            <div className="date-range_discover-button">
            <MarketerBalanceDateRange fetchData={fetchData} />
            {
                discoverDisplayed && <div className="discover-results-button-container">
                    <ButtonStyled onClick={fetchData} className={'btn-discover-results'}>Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/></ButtonStyled>
                </div>
            }
            </div>
            <div className="filters-pagination">
                <Filters setDiscoverDisplayed={setDiscoverDisplayed} />
                <Pagination 
                    current_page={page}
                    from={from}
                    to={to}
                    total={total}
                    setCurrentPage={setPage}
                    setPerPage={(n: number) => dispatch(setPerPage(n))}
                    last_page={lastPage}
                    perPage={per_page}
                    loading={loading}
                    onRefresh={() => fetchData()} 
                    perPageCustomList={[3,50,100,250,500]}
                    exportCsv={exportCsv}
                />
            </div>
            <Grid 
                rowData={tableData}  
                defaultColDef={defaultColumnDefs} 
                columnDefs={columnDefs}
                rowHeight={40}
                domLayout='autoHeight'
                loading={loading}
            />
        </MarketerBalanceWrapper>
    )
}

export default MarketerBalance;