import React, {useEffect, useRef, useState} from "react";
import {IIntacctInvoice} from "../../MarketerFundingMonitoring.service";

import {Wrapper, InvoiceBox, TooltipWrapper, TooltipRow, InvoiceState} from "./InvoiceColumn.style";
import {formatNumberToCurrency, isDateInThePast} from "../../../../../../../../common/utils";
import {createPortal} from "react-dom";
import {compareAsc, format} from "date-fns";


interface Props {
    invoices: IIntacctInvoice[]
}

interface IInvoiceHovered {
    hovered: boolean,
    invoice: null | IIntacctInvoice,
    x: number,
    y: number,
    isTop: boolean
}

const InvoiceColumn: React.FC<Props> = ({invoices}) => {
    const [hasOpacity, setHasOpacity] = useState(false)
    const [invoiceHovered, setInvoiceHovered] = useState<IInvoiceHovered>({
        hovered: false,
        invoice: null,
        x: 0,
        y: 0,
        isTop: false
    })
    const [hasDelay, setHasDelay] = useState(true)

    const timerRef = useRef<any>()
    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const handleInvoiceHover = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>, invoice: IIntacctInvoice) => {
        setHasOpacity(true)
        if(wrapperRef.current){
            const {top} = wrapperRef.current?.getBoundingClientRect()
            const x = e.currentTarget.getBoundingClientRect().x
            if(hasDelay){
                timerRef.current = setTimeout(() => {
                    setInvoiceHovered({
                        hovered: true,
                        invoice: invoice,
                        x: x + 8,
                        y: top + window.scrollY - (top < 200 ? (invoice.paid_date ? -55 : -30) : 190) - (invoice.paid_date ? 25 : 0) ,
                        isTop: top < 200
                    })
                    setHasDelay(false)
                }, 400)
            } else {
                setInvoiceHovered({
                    hovered: true,
                    invoice: invoice,
                    x: x + 8,
                    y: top + window.scrollY - (top < 200 ? (invoice.paid_date ? -55 : -30) : 190) - (invoice.paid_date ? 25 : 0) ,
                    isTop: top < 200
                })
            }
        }
    }

    const handleInvoiceMouseLeave = () => {
        // setHasOpacity(false)
        timerRef.current && clearTimeout(timerRef.current)
        setInvoiceHovered({
            hovered: false,
            invoice: null,
            x: 0,
            y: 0,
            isTop: false
        })
    }

    const tooltipRef = document.getElementById('tooltip') as HTMLElement

    const getInvoiceState = (invoice: IIntacctInvoice) => {
        if(invoice.invoice_state === 'Paid'){
            if (invoice.paid_date) {
                if (compareAsc(new Date(invoice.due_date), new Date(invoice.paid_date)) == -1) {
                    return <InvoiceState><span style={{background: '#F3C215'}}/>Paid Late</InvoiceState>
                }
                else {
                    return <InvoiceState><span style={{background: '#3FDE7F'}}/>Paid</InvoiceState>
                }
            }
            else {
                return <InvoiceState><span style={{background: '#3FDE7F'}}/>Paid</InvoiceState>
            }
        }
        if(isDateInThePast(new Date(invoice.due_date)) && (invoice.invoice_state === 'Open' ||
            invoice.invoice_state === 'Partially Paid')){
            return <InvoiceState> <span style={{background: '#F23842'}}/>Overdue</InvoiceState>
        }
        if(!isDateInThePast(new Date(invoice.due_date)) && (invoice.invoice_state === 'Open' ||
            invoice.invoice_state === 'Partially Paid')){
            return <InvoiceState><span style={{background: '#3479FF'}}/>Posted</InvoiceState>
        }
    }

    return <Wrapper onMouseEnter={() => setHasOpacity(true)}
                    onMouseLeave={() => {setHasDelay(true);
                    setHasOpacity(false)}}
                    ref={wrapperRef} $hasOpacity={hasOpacity}>
        {invoices.slice(0, 12).map((invoice, index) => {
            if(invoice.invoice_state === 'Paid'){
                if (invoice.paid_date){
                    if (compareAsc(new Date(invoice.due_date), new Date(invoice.paid_date)) == -1) {
                        return <InvoiceBox
                            onMouseEnter={(e) => handleInvoiceHover(e, invoice)}
                            onMouseLeave={(e) => handleInvoiceMouseLeave()}
                            key={index}
                            type={'paid-late'}
                        />
                    }
                    else {
                        return <InvoiceBox
                            onMouseEnter={(e) => handleInvoiceHover(e, invoice)}
                            onMouseLeave={(e) => handleInvoiceMouseLeave()}
                            key={index}
                            type={'paid'}
                        />
                    }
                }  else {
                    return <InvoiceBox
                        onMouseEnter={(e) => handleInvoiceHover(e, invoice)}
                        onMouseLeave={(e) => handleInvoiceMouseLeave()}
                        key={index}
                        type={'paid'}
                    />
                }
            }
            if(isDateInThePast(new Date(invoice.due_date)) && (invoice.invoice_state === 'Open' ||
                invoice.invoice_state === 'Partially Paid')){
                return <InvoiceBox
                    onMouseEnter={(e) => handleInvoiceHover(e, invoice)}
                    onMouseLeave={(e) => handleInvoiceMouseLeave()}
                    key={index}
                    type={'overdue'}
                />
            }
            if(!isDateInThePast(new Date(invoice.due_date)) && (invoice.invoice_state === 'Open' ||
                invoice.invoice_state === 'Partially Paid')){
                return <InvoiceBox
                    onMouseEnter={(e) => handleInvoiceHover(e, invoice)}
                    onMouseLeave={(e) => handleInvoiceMouseLeave()}
                    key={index}
                    type={'posted'}
                />
            }
            return ''
        })}
        {Array(12 - invoices.slice(0, 12).length).fill(null).map((invoice, index) => {
            return <InvoiceBox onMouseEnter={() => setHasOpacity(false)}
                               key={index}
                               className={'no-invoice'}
                               type={'no-invoice'}/>
        })}


        {invoiceHovered.hovered && createPortal(
            <TooltipWrapper isTop={invoiceHovered.isTop}
                            $y={invoiceHovered.y}
                            $x={invoiceHovered.x}>
                <TooltipRow>
                    <span className='label'>Invoice Total</span>
                    <span>{invoiceHovered.invoice &&
                    formatNumberToCurrency(parseFloat(invoiceHovered.invoice.invoice_total))}
                    </span>
                </TooltipRow>
                <TooltipRow> <span className='label'>Amount Paid</span>
                    <span>{invoiceHovered.invoice &&
                    formatNumberToCurrency(parseFloat(invoiceHovered.invoice.amount_paid))}
                    </span>
                </TooltipRow>
                <TooltipRow>
                    <span className='label'>Amount Due</span>
                    <span>{invoiceHovered.invoice &&
                    formatNumberToCurrency(parseFloat(invoiceHovered.invoice.amount_due))}
                    </span>
                </TooltipRow>
                <TooltipRow><span className='label'>Due Date</span>
                    <span>{invoiceHovered.invoice &&
                    // IMPORTANT
                    // blank space is stupid hack for problem with timezone. without blank space date for users in
                    // america will we one day behind
                    format(new Date(invoiceHovered.invoice.due_date.replaceAll("-", "/") + " "), 'MM/dd/yyyy')}
                    </span>
                </TooltipRow>
                {invoiceHovered.invoice?.paid_date &&
                    <TooltipRow>
                        <span className={'label'}>Paid Date</span>
                        <span>{invoiceHovered.invoice &&
                            format(new Date(invoiceHovered.invoice.paid_date.replaceAll("-", "/") + " "), 'MM/dd/yyyy')}</span>
                    </TooltipRow>
                }
                <TooltipRow>
                    <span className='label'>Invoice State</span>{invoiceHovered.invoice &&
                getInvoiceState(invoiceHovered.invoice)}
                </TooltipRow>
                <TooltipRow>
                    <span className='label'>Invoice Number</span>
                    {invoiceHovered.invoice &&invoiceHovered.invoice.invoice_number}
                </TooltipRow>
            </TooltipWrapper>,
            tooltipRef
        )}
    </Wrapper>
}

export default InvoiceColumn
