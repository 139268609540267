import styled from "styled-components";
import {AlertModalType} from "../alertModal.props";
import {ButtonStyled} from "../../../styled-components";

interface IAlertModalContainerStyleProps {
    type: AlertModalType
}

export const Wrapper = styled.div`
    text-align: center;
    width: 440px;
    height: fit-content;
    position: relative;
    padding: 40px;
    margin-top: 75px;
    border-radius: 24px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 6px 16px r  gba(0, 0, 0, 0.08));
    background-color: white;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-size: 14px;
    line-height: 19px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
`

export const CloseButton = styled(ButtonStyled)`
    height: 32px;
    width: 360px;
    margin-top: 40px;
`

export const Title = styled.h1<IAlertModalContainerStyleProps>`
    color: ${props => props.type === 'success' ? '#00CE2D' : props.type === 'error' ? '#FE7E60' : props.type === 'success-rocket' ? '#0496AA'  : '#F6C000'};
    line-height: 46px;
    margin-top: 25px;
    font-size: 34px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    margin-bottom: 8px;
`

export const Icon = styled.div<IAlertModalContainerStyleProps>`
    width: ${props => props.type === 'success-rocket' ? null  : '64px' };
    height: ${props => props.type === 'success-rocket' ? '80px'  : '64px' };
    border-radius: 50%;
    background-color: ${props => props.type === 'success' ? '#D4FFD9' : props.type === 'error' ? '#FFC7C7' : props.type === 'success-rocket' ? 'transparent'  :'#FFECA6'};
    position: absolute;
    top: ${props => props.type === 'success-rocket' ? '-40px'  : '-30px' };
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: ${props => props.type === 'success' ? '#00CE2D' : props.type === 'error' ? '#FE7E60' : props.type === 'success-rocket' ? '#0496AA'  : '#F6C000'};

    .ico-Information-outlined {
        font-size: 32px;
    }
`