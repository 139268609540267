import React, {ChangeEvent} from 'react'
import Input from "../../../../../../../../../common/components/input/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../common/hooks";
import {setOffer} from "../../../../../create-offer.slice";

const UnsubscribeLinkInput: React.FC = () => {

    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setOffer({
            campaign_email_setting: {
                ...offer.campaign_email_setting,
                unsubscribe_type_value: e.target.value,
                unsubscribe_type_value_aff: e.target.value,
            }
        }))
    }

    return <Input
        label={<>Unsubscribe Link <span className="optional">(optional)</span></>}
        placeholder={'Enter a URL for unsubscribing'}
        value={offer.campaign_email_setting.unsubscribe_type_value}
        onChange={(e) => handleOnChange(e)}
    />
}

export default UnsubscribeLinkInput
