import styled, {css} from "styled-components";

interface IInputNumberWrapperProps {
    $width: string,
    $height?: string,
}

export const InputNumberWrapper = styled.div<IInputNumberWrapperProps>`
    background: ${({theme})=>theme.colors.pureWhite};
    width: ${({$width}) => $width};
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    ${props => props.$height && css`
      height: ${props.$height};
    `}
    /* input:hover {
        appearance: none;
        border: 1px solid ${({theme})=>theme.colors.coolGray[4]};
        color: ${({theme})=>theme.colors.deepBlue[5]};
    }

    input:focus {
        border-color: ${({theme})=>theme.colors.performBlue[5]};
        caret-color: ${({theme})=>theme.colors.performBlue[5]};
        color: ${({theme})=>theme.colors.deepBlue[4]};
    } */

    .arrows {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        top: 0;
        right: 0;
        bottom: 0;
        padding-left: 8px;
        padding-right: 8px;
        color: ${({theme})=>theme.colors.deepBlue[4]};
        
        i{
            color: #5A686F;
            font-size: 12px;
            border-radius: 0.5px;
            cursor: pointer;
            /* border: 1px solid red; */
        }

        .ico-AngleUp {
            margin-bottom: -4px;
            padding-top: 3px;
        }

        .ico-AngleDown{
            margin-top: -4px;
            padding-bottom: 3px;
        }
    }
`;

export const NumberInputField = styled.input`
    appearance: none;
    width: 100%;
    padding: 8px 16px;
    outline: none;
    font-size: 12px;
    color: ${({theme})=>theme.colors.deepBlue[4]};
    border: 1px solid ${({theme})=>theme.colors.coolGray[3]};
    border-radius: 8px;
    transition: .2s ease;
    font-family: ${({theme})=>theme.fonts.AvenirLight};
    height: 100%;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }

    &:hover {
        appearance: none;
        border: 1px solid ${({theme})=>theme.colors.coolGray[4]};
        color: ${({theme})=>theme.colors.deepBlue[5]};
    }

    &:focus {
        border-color: ${({theme})=>theme.colors.performBlue[5]};
        caret-color: ${({theme})=>theme.colors.performBlue[5]};
        color: ${({theme})=>theme.colors.deepBlue[4]};
    }
   
`;