export const statuses = [
    {
        name: "Active",
        id: 1,
        status_name: "active"
        // group: [{name: "activee111", id: 1234}]
    },
    {
        name: "Inactive",
        id: 2,
        status_name: "inactive"
    },
    {
        name: "Terminated",
        id: 3,
        status_name: "terminated"
    },
    {
        name: "Deleted",
        id: 4,
        status_name: "deleted"
    },
    {
        name: "Pending Review",
        id: 5,
        status_name: "pending_review"
    },
    {
        name: "Reviewed",
        id: 6,
        status_name: "reviewed"
    },
    {
        name: "Denied",
        id: 7,
        status_name: "denied"
    },
];

export const advertisersEditStatuses = [
    {
        name: "Active",
        id: 1,
        status: "active",
        cpc_status: "approved",
        status_data: {
            status: "active"
        }
    },
    {
        name: "Inactive",
        id: 2,
        status_data: {
            status: "inactive"
        }
    },
    {
        name: "Terminated",
        id: 3,
        status_data: {
            status: "terminated"
        }
    },
    {
        name: "Deleted",
        id: 4,
        status_data: {
            status: "deleted"
        }
    },
    {
        name: "Pending review",
        id: 5,
        status: "inactive",
        cpc_status: "pending",
        status_data: {
            status: "pending review"
        }
    },
    {
        name: "Reviewed",
        id: 6,
        status: "inactive",
        cpc_status: "approved",
        status_data: {
            status: "reviewed"
        }
    },
    {
        name: "Denied",
        id: 7,
        status_data: {
            status: "denied"
        }
    },
];


export interface ICustomDomainStatuses {
    id: string,
    name: string
}

export const customDomainStatuses = [
    {
        id: 'all',
        name: "All",
    },
    {
        id: 'pending',
        name: "Pending",
    },
    {
        id: 'nonverified',
        name: "Nonverified",
    },
    {
        id: 'verified',
        name: "Verified",
    },
    {
        id: 'expired',
        name: "Expired",
    },
];

export const leapPartnersStatuses = [
    {
        name: "Active",
        id: 1,
        status_name: "active"
    },
    {
        name: "Inactive",
        id: 2,
        status_name: "inactive"
    },
    {
        name: "Terminated",
        id: 3,
        status_name: "terminated"
    },
    {
        name: "Deleted",
        id: 4,
        status_name: "deleted"
    },
]
