import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 544px;
  min-width: 100%;
  .row{
    margin-bottom: 30px;
  }

  .row-add-contact{
    display: flex;
    gap: 15px;
    
  }
`

export const Label = styled.p`
  color: ${({theme}) => theme.colors.deepBlue[7]};
  font-size: 14px;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  margin-bottom: 16px;
`