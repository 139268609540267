import React, {useEffect, useState} from 'react'
import queryString from 'query-string'
import Grid from "../../../../../../common/components/grid/Grid";
import SortableHeaderV2 from '../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2';
import {formatNumberToCurrency, roundNumber} from '../../../../../../common/utils';
import {TableWrapper} from './Table.style';
import {DropdownItem} from '../../../../../../common/components/dropdown/Dropdown.style';
import GridRowOptions from '../../../../../../common/components/grid/grid-row-options/GridRowOptions';
import PauseRiskTooltip from './tooltips-special-columns/pause-risk-tooltip/PauseRiskTooltip';
import RecentInvoicesTooltip from './tooltips-special-columns/recent-invoices-tooltip/RecentInvoicesTooltip';
import Invoices from "../../../advertisers-edit/billing/details/invoices/Invoices";

import AddEditNotesModal from "../add-edit-notes-modal/AddEditNotesModal";
import {
    IEditStayLiveLimitParams,
    IIFetchMarketerFundingDataResponse,
    IUpdateBillingNotesParams
} from "../MarketerFundingMonitoring.service";
import {useAppDispatch, useAppSelector} from '../../../../../../common/hooks';
import {setPaginationColumnsList, setSort} from '../MarketerFundingMonitoring.slice';
import PauseRisk from './pause-risk/PauseRisk'
import {allPaginationColumnsList} from './paginationColumnsList';
import {
    useColumnsOptionHook
} from '../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook';
import EditStayLiveLimitModal from "./edit-stay-live-limit-modal/EditStayLiveLimitModal";
import LatestReassessmentStatusTooltip
    from "./tooltips-special-columns/latest-reassessment-status-tooltip/LatestReassessmentStatusTooltip";
import LatestReassessmentStatus from "./latest-reassessment-status/LatestReassessmentStatus";
import ReactDOMServer from "react-dom/server";

interface Props {
    loading: boolean,
    tableData: IIFetchMarketerFundingDataResponse[],
    updateBillingNotes: (params: IUpdateBillingNotesParams) => void
    fetchTableData: () => void
    editStayLiveLimit: (params: IEditStayLiveLimitParams) => void
}

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const Table: React.FC<Props> = ({
                                    loading,
                                    tableData,
                                    updateBillingNotes,
                                    fetchTableData,
                                    editStayLiveLimit
                                }) => {

    const {user} = useAppSelector(state => state.auth)

    const {
        sort,
        paginationColumnsList
    } = useAppSelector(state => state.marketerFundingMonitoring);

    const [addEditNotesModal, setAddEditNotesModal] = useState<{
        data: IIFetchMarketerFundingDataResponse | null,
        opened: boolean
    }>({
        data: null,
        opened: false
    })

    const [editStayLiveLimitModal, setEditStayLiveLimitModal] = useState<{
        data: IIFetchMarketerFundingDataResponse | null,
        opened: boolean
    }>({
        data: null,
        opened: false
    })

    const dispatch = useAppDispatch();

    const handleOpenAddEditModal = (data: IIFetchMarketerFundingDataResponse) => {
        setAddEditNotesModal({
            data: data,
            opened: true
        })
    }

    const handleCloseAddEditModal = () => {
        setAddEditNotesModal({
            data: null,
            opened: false
        })
    }

    const handleEditStayLiveLimit = (data: IIFetchMarketerFundingDataResponse) => {
        setEditStayLiveLimitModal({
            data: data,
            opened: true
        })
    }

    const handleRedirectToJiraService = (data: IIFetchMarketerFundingDataResponse) => {
        const params = {
            //Marketer ID
            customfield_10754: data.id,
            //Marketer Company Name
            customfield_10741: data.company,
            //Marketer Rep's Email
            customfield_10698: data.account_manager_email,
            customfield_10776: (data.intacct_invoicing_cycle ? data.intacct_invoicing_cycle + ', ' : '') +
                (data.intacct_payment_term ? data.intacct_payment_term + ', ' : '') +
                (formatNumberToCurrency(parseFloat(data.intacct_credit_limit))) + ", " +
                formatNumberToCurrency(data.intacct_credit_deposit),
            //Vertical
            customfield_10680: 'Dating'
        }
        const query = queryString.stringify(params)
        window.open('https://clickbooth.atlassian.net/servicedesk/customer/portal/6/group/14/create/86?' +
            query, '_blank')
    }

    const viewMarketerBilling = (id: number) => {
        targetOrigin && window.parent.postMessage({id: 7, marketerId: id}, targetOrigin)
    }

    const allColDefs: any = [
        {
            field: 'id',
            colId: 1,
            pinned: "left",
            width: 130,
            lock: 'left',
            lockPosition: true,
            suppressMovable: true,
            lockVisible: true,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The unique identification number assigned to a marketer.
                                </div>
                            )}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "id" ? 'asc' : 'desc',
                            by: 'id'
                        }))}
                        sortedBy={sort.by}
                        keyId={"id"}
                        sortDirection={sort.direction}
                    >MARKETER ID</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            colId: 2,
            pinned: 'left',
            width: 220,
            lockPosition: true,
            suppressMovable: true,
            lockVisible: true,
            cellRenderer: ({data}: { data: any }) => {
                return <div>{data.company}</div>
            },
            headerComponent: (data: any) => {
                return <div className='header-custom' data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The name of the company this marketer is associated with.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "company" ? 'asc' : 'desc',
                            by: 'company'
                        }))}
                        sortedBy={sort.by}
                        keyId={'company'}
                        sortDirection={sort.direction}
                    >MARKETER COMPANY NAME</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div>{data.name} {data.lastname}</div>
            },
            colId: 3,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The name of the marketer that this account belongs to.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "name" ? 'asc' : 'desc',
                            by: 'name'
                        }))}
                        sortedBy={sort.by}
                        keyId={"name"}
                        sortDirection={sort.direction}
                    >MARKETER NAME</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div>{data.account_manager_name} {data.account_manager_lastname}</div>
            },
            colId: 4,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 230}}>
                                    The Perform[cb] employee associated with managing this marketer's account.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "account_manager_name" ?
                                'asc' : 'desc',
                            by: 'account_manager_name'
                        }))}
                        sortedBy={sort.by}
                        keyId={"account_manager_name"}
                        sortDirection={sort.direction}
                    >ENGINE GROWTH MANAGER</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            resizable: false,
            width: 148,
            cellRenderer: ({data}: { data: any }) => {
                return <PauseRisk risk={data.pause_risk}/>
            },
            colId: 5,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 390}}>
                                    <PauseRiskTooltip/>
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "pause_risk" ? 'asc' : 'desc',
                            by: 'pause_risk'
                        }))}
                        sortedBy={sort.by}
                        keyId={"pause_risk"}
                        sortDirection={sort.direction}
                    >PAUSE RISK</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div className='cell-content-right'>{formatNumberToCurrency(data.marketer_total_revenue)}</div>
            },
            colId: 6,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The daily average revenue or spend a marketer has generated over the
                                    last 3 days (including QF & rejected).
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "marketer_total_revenue" ?
                                'asc' : 'desc',
                            by: 'marketer_total_revenue'
                        }))}
                        sortedBy={sort.by}
                        keyId={"marketer_total_revenue"}
                        sortDirection={sort.direction}
                    >MARKETER TOTAL REVENUE (DAILY AVG LAST 3 DAYS)</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div className='cell-content-right'>{formatNumberToCurrency(data.balance)}</div>
            },
            colId: 7,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The current funding a marketer has available to spend. This includes any deposits
                                    made to the account.
                                    If a marketer has depleted their funds and has stay live available it will be
                                    reflected below with a “-” next to the dollar value based on how much stay live they
                                    have spent.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "balance" ? 'asc' : 'desc',
                            by: 'balance'
                        }))}
                        sortedBy={sort.by}
                        keyId={"balance"}
                        sortDirection={sort.direction}
                    >FUNDING AVAILABLE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                if (data.funding_days_remaining !== null) {
                    return <div className='cell-content-right'>
                        {roundNumber(data.funding_days_remaining, 1)}
                    </div>
                }
                return <div className='cell-content-right'>-</div>
            },
            colId: 8,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    <div style={{marginBottom: 10}}>
                                        The days remaining until a marketer runs out of
                                        funding based on their daily average
                                        total revenue of the past 3 days.
                                    </div>
                                    <div>The calculation used to determine this:
                                        (Funding Available) / (Marketer Total Revenue (Daily Avg Last 3 Days))
                                    </div>
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "funding_days_remaining" ?
                                'asc' : 'desc',
                            by: 'funding_days_remaining'
                        }))}
                        sortedBy={sort.by}
                        keyId={"funding_days_remaining"}
                        sortDirection={sort.direction}
                    >FUNDING DAYS REMAINING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            colId: 9,
            cellRenderer: ({data}: { data: any }) => {
                if (data.funding_stay_live_days_remaining !== null) {
                    return <div className='cell-content-right'>
                        {roundNumber(data.funding_stay_live_days_remaining, 1)}
                    </div>
                }
                return <div className='cell-content-right'>-</div>
            },
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    <div style={{marginBottom: 10}}>
                                        The days remaining until a marketer runs out of funding based on their daily
                                        average
                                        total revenue of the past 3 days, this includes any stay live added to the
                                        account.
                                    </div>
                                    <div> The calculation used to determine this:
                                        (Funding Available + Stay Live Limit) / (Marketer Total Revenue (Daily Avg Last
                                        3 Days))
                                    </div>
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "funding_stay_live_days_remaining" ?
                                'asc' : 'desc',
                            by: 'funding_stay_live_days_remaining'
                        }))}
                        sortedBy={sort.by}
                        keyId={"funding_stay_live_days_remaining"}
                        sortDirection={sort.direction}
                    >FUNDING & STAY LIVE DAYS REMAINING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                if (data.auto_funding) {
                    return <div>
                        Enabled / {formatNumberToCurrency(data.auto_funding_amount)}
                        at {formatNumberToCurrency(data.auto_funding_amount / 10)}
                        Funding Remaining
                    </div>
                }
                return <div>Disabled</div>
            },
            colId: 10,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    <div style={{marginBottom: 10}}>
                                        A feature, which if enabled will automatically add funds to the marketer’s
                                        account when their Funding Available reaches 10% of the entered auto-funding
                                        amount.
                                    </div>
                                    <div>
                                        For example if a marketer decides to enable auto-funding and enters an amount
                                        of $1,000 their credit card will be charged the $1,000 when their Funding
                                        Available
                                        reaches 10% of that amount ($100 in this example).
                                    </div>
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "auto_funding" ? 'asc' : 'desc',
                            by: 'auto_funding'
                        }))}
                        sortedBy={sort.by}
                        keyId={"auto_funding"}
                        sortDirection={sort.direction}
                    >AUTO-FUNDING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                if (data.intacct_credit_deposit === null) return <div/>
                return <div className='cell-content-right'>{formatNumberToCurrency(data.intacct_credit_deposit)}</div>
            },
            colId: 11,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    A deposit, usually made when the marketer signs up with Perform[cb] as a way
                                    to be given a line of credit. This amount is included in the marketer’s
                                    Funding Available.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "intacct_credit_deposit" ? 'asc' : 'desc',
                            by: 'intacct_credit_deposit'
                        }))}
                        sortedBy={sort.by}
                        keyId={"intacct_credit_deposit"}
                        sortDirection={sort.direction}
                    >CREDIT DEPOSIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                if (data.intacct_credit_limit === null) return <div/>
                return <div className='cell-content-right'>{formatNumberToCurrency(data.intacct_credit_limit)}</div>
            },
            colId: 12,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The amount of credit a marketer is extended from Perform[cb].
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "intacct_credit_limit" ? 'asc' : 'desc',
                            by: 'intacct_credit_limit'
                        }))}
                        sortedBy={sort.by}
                        keyId={"intacct_credit_limit"}
                        sortDirection={sort.direction}

                    >CREDIT LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                if (data.credit === null) return <div className='cell-content-right'>Unlimited</div>
                return <div className='cell-content-right'>{formatNumberToCurrency(data.credit)}</div>
            },
            colId: 13,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The amount of additional credit extended to a marketer from Perform[cb].
                                    This is used as a preventative to keep a marketer’s traffic from pausing if
                                    they reach their credit limit.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "credit" ? 'asc' : 'desc',
                            by: 'credit'
                        }))}
                        sortedBy={sort.by}
                        keyId={"credit"}
                        sortDirection={sort.direction}
                    >STAY LIVE LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div className='cell-content-right'>{formatNumberToCurrency(data.total_credit_limit)}</div>
            },
            colId: 14,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The total amount of credit a marketer is extended from Perform[cb] including both
                                    their base credit limit and stay live.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "total_credit_limit" ? 'asc' : 'desc',
                            by: 'total_credit_limit'
                        }))}
                        sortedBy={sort.by}
                        keyId={"total_credit_limit"}
                        sortDirection={sort.direction}
                    >TOTAL CREDIT LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div className='cell-content-right'>{formatNumberToCurrency(data.credit_used)}</div>
            },
            colId: 15,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The amount of credit a marketer currently has in-use from Perform[cb], including
                                    both base credit and stay live.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "credit_used" ? 'asc' : 'desc',
                            by: 'credit_used'
                        }))}
                        sortedBy={sort.by}
                        keyId={"credit_used"}
                        sortDirection={sort.direction}
                    >CREDIT USED</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            resizable: false,
            headerName: '',
            width: 250,
            cellRenderer: ({data}: { data: IIFetchMarketerFundingDataResponse }) => {
                return <Invoices invoices={data.intacct_invoices}/>
            },
            colId: 16,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToString(
                                <div style={{maxWidth: 200}}>
                                    <RecentInvoicesTooltip/>
                                </div>
                            )}>
                    <SortableHeaderV2
                        onClick={() => {
                        }}
                        sortedBy={sort.by}
                        keyId={""}
                        icon={false}
                        sortDirection={sort.direction}
                    >RECENT INVOICES</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div className='cell-content-right'>{data.days_until_payment}</div>
            },
            colId: 17,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The days until a marketer has a payment due. The number of days listed is reflective
                                    of the oldest unpaid invoice.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "days_until_payment" ? 'asc' : 'desc',
                            by: 'days_until_payment'
                        }))}
                        sortedBy={sort.by}
                        keyId={"days_until_payment"}
                        sortDirection={sort.direction}
                    >DAYS UNTIL PAYMENT DUE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div>{data.intacct_invoicing_cycle}</div>
            },
            colId: 18,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The frequency that a marketer will receive an invoice.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "intacct_invoicing_cycle" ? 'asc' : 'desc',
                            by: 'intacct_invoicing_cycle'
                        }))}
                        sortedBy={sort.by}
                        keyId={"intacct_invoicing_cycle"}
                        sortDirection={sort.direction}
                    >INVOICING CYCLE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: { data: any }) => {
                return <div>{data.intacct_payment_term}</div>
            },
            colId: 19,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The amount of days a marketer has to pay an invoice from the time it is received.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "intacct_payment_term" ? 'asc' : 'desc',
                            by: 'intacct_payment_term'
                        }))}
                        sortedBy={sort.by}
                        keyId={"intacct_payment_term"}
                        sortDirection={sort.direction}

                    >PAYMENT TERMS</SortableHeaderV2>
                </div>
            }
        },
        {
            field: 'billing_notes',
            headerName: 'BILLING NOTES',
            cellRenderer: ({data}: { data: IIFetchMarketerFundingDataResponse }) => {
                return (data.billing_notes !== null && data.billing_notes !== '') ?
                    <span onClick={() => handleOpenAddEditModal(data)}
                          className={'view-edit-notes'}>View/Edit Notes</span> :
                    <span onClick={() => handleOpenAddEditModal(data)} className={'add-notes'}>Add Notes</span>
            },
            colId: 21,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    Any notes added by a Perform[cb] employee related to the billing of this marketer.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => {
                        }}
                        sortedBy={""}
                        keyId={""}
                        icon={false}
                        sortDirection={sort.direction}
                    >BILLING NOTES</SortableHeaderV2>
                </div>
            }
        },
        {
            field: 'latest_reassessment_status',
            headerName: 'LATEST REASSESSMENT STATUS',
            width: 217,
            colId: 23,
            cellRenderer: ({data}: { data: IIFetchMarketerFundingDataResponse }) => {
                return (
                    <LatestReassessmentStatus status={data.credit_request_status}/>
                )
            },
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 210}}>
                                    <LatestReassessmentStatusTooltip/>
                                </div>
                            )}>
                    <SortableHeaderV2
                        onClick={() => {
                        }}
                        sortedBy={""}
                        keyId={""}
                        icon={false}
                        sortDirection={sort.direction}
                    >LATEST REASSESSMENT STATUS</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',

            cellRenderer: ({data}: { data: any }) => {
                if (data.niche_id === 0) return <div>Health & Beauty</div>
                if (data.niche_id === 1) return <div>Financial</div>
                if (data.niche_id === 2) return <div>Entertainment & Lifestyle</div>
                if (data.niche_id === 4) return <div>Other</div>
                if (data.niche_id === 5) return <div>Downloads & Software</div>
                if (data.niche_id === 6) return <div>E-Commerce</div>
                return <div/>
            },
            colId: 20,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 200}}>
                                    The industry channel that the marketer provides goods and services within.
                                </div>)}>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setSort({
                            direction: sort.direction === 'desc' && sort.by === "niche_id" ? 'asc' : 'desc',
                            by: 'niche_id'
                        }))}
                        sortedBy={sort.by}
                        keyId={"niche_id"}
                        sortDirection={sort.direction}
                    >VERTICAL</SortableHeaderV2>
                </div>
            }
        },
        {
            pinned: "right",
            field: '',
            width: 50,
            colId: 22,
            resizable: false,
            cellRenderer: ({data}: { data: IIFetchMarketerFundingDataResponse }) => {
                return (
                    <GridRowOptions>
                        <DropdownItem disabled={
                            data.credit_request_status?.status == 'Submitted'
                            || data.credit_request_status?.status == 'In Progress'
                            || data.credit_request_status?.status == 'More info needed'
                        }
                                      onClick={() => handleRedirectToJiraService(data)}>
                            Request Credit Reassessment
                        </DropdownItem>

                        {(user.role.other.change_credit_amount === 'all' || (user.role.other.change_credit_amount === 'managed'
                                && user.id === data.executive_id)) &&
                            <DropdownItem onClick={() => handleEditStayLiveLimit(data)}>Edit Stay Live
                                Limit</DropdownItem>
                        }
                        <DropdownItem onClick={() => viewMarketerBilling(data.id)}>
                            View Marketer Billing
                        </DropdownItem>
                    </GridRowOptions>
                )
            }
        }
    ]

    const [colDefs, setColDefs] = useState(allColDefs);
    const [colWidths, setColWidths] = useState(allColDefs.map((item: any) => {
        return {
            width: item.width ? item.width : item.minWidth,
            minWidth: item.minWidth ? item.minWidth : item.width,
            id: item.colId
        }
    }));

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]: any = useColumnsOptionHook(
        allColDefs,
        colDefs,
        setColDefs,
        allPaginationColumnsList,
        paginationColumnsList,
        (
            list: {
                name: string,
                id: number,
                checked: boolean,
                column_key?: string
            }[]
        ) => dispatch(setPaginationColumnsList(list))
    )

    useEffect(() => {
        const check = allPaginationColumnsList.filter(item =>
            !paginationColumnsList.some(it => it.id === item.id))
        check.length && dispatch(setPaginationColumnsList(allPaginationColumnsList))
    }, [])

    useEffect(() => {
        const checkedColumns = paginationColumnsList.filter((item: any) => item.checked);
        if (paginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
            let newDefs: any = [];
            checkedColumns.forEach((it: any) => {
                const colDef = allColDefs.find((item: any) => item.colId === it.id);
                newDefs.push(
                    {
                        ...colDef,
                        minWidth: colWidths.find((item: any) => item.id === it.id)?.minWidth ?
                            colWidths.find((item: any) => item.id === it.id)?.minWidth :
                            colWidths.find((item: any) => item.id === it.id)?.width
                    }
                );
            })
            setColDefs(newDefs);
        } else {
            setColDefs([]);
        }
    }, [paginationColumnsList, sort])

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs: any = [];
        let newPaginCol: any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allColDefs.find((item: any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allPaginationColumnsList.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });

        const widthToCol = newColDefs.map((item: any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })

        setColWidths(widths);
        setColDefs(widthToCol);

        const rest = paginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        dispatch(setPaginationColumnsList([...newPaginCol, ...rest]));
    }

    return <TableWrapper>
        <Grid
            loading={loading}
            rowData={tableData}
            columnDefs={colDefs}
            rowHeight={40}
            domLayout='autoHeight'
            // onDragStopped={onColumnDrop}
            enableAllColumns={() => enableAllHandler({})}
            fixedScroll={true}
        />
        <AddEditNotesModal modal={addEditNotesModal}
                           closeModal={handleCloseAddEditModal}
                           updateBillingNotes={updateBillingNotes}
                           fetchTableData={fetchTableData}/>
        <EditStayLiveLimitModal editStayLiveLimit={editStayLiveLimit}
                                modal={editStayLiveLimitModal}
                                closeModal={() => setEditStayLiveLimitModal({data: null, opened: false})}
                                fetchTableData={fetchTableData}/>
    </TableWrapper>


}

export default Table