import styled, { css } from 'styled-components';

interface IRadioProps {
    $checked: boolean
}

export const RadioButtonWrapper = styled.div`
    cursor: pointer;
    display: flex;
    width: max-content;
    align-items: center;
`;

export const Radio = styled.div<IRadioProps>`
    border: 1px solid ${({$checked})=> $checked ? "#25C0D5" : "#D0D5D7"};
    border-radius: 100px;
    background: ${({$checked}) => $checked ? "#E7F9FB" : "#F3F7F8"};
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &:hover {
        ${({$checked}) => !$checked && css`
            background-color: #E9ECED;
            border: 1px solid #D0D5D7;
        `};   
    }

    .default-check {
        background: #25C0D5;
        border-radius: 100%;
        width: 10px;
        height: 10px;
    }
`;

export const RadioButtonLabel = styled.div`
    margin-left: 10px;
`;