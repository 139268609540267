import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";

interface Props {}

const RedirectType: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    const domainTrackingFlag = useFeatureFlags('domain-tracking-code', offer.niche ? offer.niche.network_id : undefined)

    if(offer.direct_linking === null || !domainTrackingFlag || offer.detailed_pricing_model === 'ppc') return null

    return (
        <>
            <Subtitle>Redirect Type</Subtitle>
            <Item>{offer.direct_linking === false ? 'Standard Redirect' : 'Redirectless'}</Item>
        </>
    )
}

export default RedirectType