import styled, {css} from 'styled-components';
import Select from "react-select";

interface IStyledSelectProps {
    $autoWidth: boolean | undefined
}

export const StyledSelect = styled(Select)`
    .styled-select {
        &__control {
            border-radius: 10px;
            transition: all .2s ease-in-out;
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.deepBlue[4]};
            box-shadow: none;

            &:hover {
                cursor: pointer;
                border-color: ${({theme}) => theme.colors.coolGray[4]};
            }

            &--menu-is-open, &--is-focused {
                border-color: #25c0d5 !important;

                &:hover {
                    border-color: #25c0d5;
                }
            }

            &--is-focused {
                border-color: #25c0d5;
                background-color: white;

                :hover {
                    background-color: white;
                }
            }
        }

        &__indicator-separator {
            display: none;
        }

        &__indicator {
            &:before {
                font-family: 'leap';
                content: '\\e91f';
            }

            svg {
                display: none;
            }
        }

        &__menu {
            border-radius: 8px;
            background-color: white;
            padding: 16px;
            margin-top: 4px;

            &--list {
                padding: 0;
            }
        }

        &__option {
            padding: 8px;
            border-radius: 8px;
            font-family: ${({theme}) => theme.fonts.AvenirRoman};
            font-size: 12px;
            background-color: white;
            color: #5a686f;

            :hover {
                background-color: #e7f9fb;
            }

            &--is-selected {
                color: white;
                background-color: #2d4548;

                &:hover {
                    background-color: #2d4548;
                }
            }
        }
    }
`;

export const SelectWrapper = styled.div<IStyledSelectProps>`
    position: relative;
    margin-bottom: 32px;
    width: 100%;
    
    .optional {
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    .styled-select {
        &__placeholder {
            color: ${({theme}) => theme.colors.coolGray[4]};
        }
        &__control {
            border-radius: 10px;
            transition: all .2s ease-in-out;
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
            color: ${({theme}) => theme.colors.deepBlue[4]};
            box-shadow: none;

            &:hover {
                cursor: pointer;
                border-color: ${({theme}) => theme.colors.coolGray[4]};
            }

            &--menu-is-open {
                .styled-select__single-value {
                    color: ${({theme}) => theme.colors.coolGray[3]};
                }
            }

            &--menu-is-open, &--is-focused {
                border-color: #25c0d5 !important;
                &:hover {
                    border-color: #25c0d5;
                }
            }

            &--is-focused {
                border-color: #25c0d5;
                background-color: white;

                :hover {
                    background-color: white;
                }
            }
            &--is-disabled {
                background-color: white;
            }
        }

        &__indicator-separator {
            display: none;
        }

        &__indicator {
            &:before {
                font-family: 'leap';
                content: '\\e91f';
            }

            svg {
                display: none;
            }
        }

        &__menu {
            border-radius: 8px;
            background-color: white;
            padding: 16px;
            margin-top: 4px;
            z-index: 2;

           

            &--list {
                padding: 0;
            }

            ${(props) => props.$autoWidth ?  css`
                width: auto;
                white-space: nowrap;
            ` : null}
        }

        &__option {
            padding: 8px;
            border-radius: 8px;
            font-family: ${({theme}) => theme.fonts.AvenirRoman};
            font-size: 12px;
            background-color: white;
            color: #5a686f;
            cursor: pointer;
            :hover {
                background-color: #e7f9fb;
            }

            &--is-selected {
                color: white;
                background-color: #2d4548;

                &:hover {
                    background-color: #2d4548;
                }
            }
            &--is-disabled {
                opacity: .4;
                pointer-events: none;
            }
        }
        &__single-value {
            color: ${({theme}) => theme.colors.deepBlue[4]};
            &--is-disabled {
                color: #D2D7D9;
            }
        }
        &__input-container {
            input {
                color: #8799a2!important;
            }
        }
        &__group {
            &-heading {
                color: ${({theme}) => theme.colors.deepBlue[7]};
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                font-size: 12px;
                text-transform: capitalize;
                padding-left: 0;
                
            }
        }
        &--is-disabled {
        
        }
        
    }
`

export interface ISelectStyleProps {
    isMenuOpened: boolean,
    isSelectDisabled? : boolean
}


export const SelectLabel = styled.label<ISelectStyleProps>`
    color: ${(props) => props.isSelectDisabled ? "#D2D7D9" : props.isMenuOpened ? "#25c0d5" : "#5a686f"};
    position: absolute;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    line-height: 16px;
    padding: 0 4px;
    top: -8px;
    left: 8px;
    z-index: 1;
    transition: color .2s ease;
    display: flex;
    align-items: center;
    i { 
        margin-left: 4px;
    }
    .optional {
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        color: ${(props) => props.isSelectDisabled ? "#D2D7D9" : props.isMenuOpened ? "#25c0d5" : "#5a686f"};
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 8px;
        height: 1px;
        background: #ffffff;
        z-index: -1;
    }
`