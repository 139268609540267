import {api} from '../../../../common/services/api';
import axios, {AxiosResponse} from "axios";

export interface IReferralReportData {
    id: number;
    name: string;
    lastname: string;
    user_type: string;
    executive_id: number;
    start_date: string,
    end_date: string;
    full_name: string;
    account_executive: string;
    revenue: string;
}

export interface IReferralParams {
    from?: string | null | undefined,
    to?: string | null | undefined,
    page?: number,
    paginate_by? : number,
    user_type? : string,
    show_expired? : number
    search? : string,
    active_referrals? : boolean
}

export interface IChartData {
    referral_sum: number,
    ts: number
}
export interface IFetchReferralTokenResponse {
    referral_token: string,
    comission? : number
}

export interface IReferralOverviewSummary {
    total_lifetime_commission: number;
    last_months_commission: number;
    this_months_commission: number;
    active_referrals: number;
    total_referrals: number;
}


class ReferralsService{
    static fetchReferralToken = async (token: string) => {
        return await api.get<IFetchReferralTokenResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_REFERRALS,
                'user_referral_info'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                }
            }

        )
    }
    static fetchReferralReportTable = async (token : string, params : IReferralParams): Promise<AxiosResponse> => {
        try{
             return await api.get<IReferralReportData>([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_REFERRALS,
                "referral_report_table"
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params: params
                },)
        }
        catch (e : any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }
    static fetchReferralReportChart = async (token : string, params : IReferralParams): Promise<AxiosResponse> => {
        try{
            return await api.get<IChartData[]>([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_REFERRALS,
                    "referral_report_chart"
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params: params
                },)
        }
        catch (e : any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static fetchReferralOverviewSummary = async (token : string): Promise<AxiosResponse> => {
        try{
            return await api.get<IReferralOverviewSummary>([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_REFERRALS,
                    "referral_overview_summary"
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                },)
        }
        catch (e : any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }
}

export default ReferralsService
