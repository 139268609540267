import { useEffect, useMemo, useState } from "react";
import Grid from "../../../../../../../common/components/grid/Grid"
import { useColumnsOptionHook } from "../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import SortableHeader from "../../../../../../../common/components/table/sortable-header/SortableHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/hooks";
import { formatNumberToCurrency } from "../../../../../../../common/utils";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../NetworkView.service";
import { setPagination, setPartnersCurrentPage, setPartnersPaginationColumnsList, setPartnersPerPage, setPartnersSortDirection, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../NetworkView.slice";
import { NetworkViewSortIconDown, NetworkViewSortIconUp, TopPerformersTable } from "../TopPerformers.style"
import { allPartnersPaginationColumns } from "../topPerformersLists";

const TopPerformersPartner = ({loading}: any) => {

    const {
        partnersPaginationColumnsList,
        partnersPerPage,
        partnersCurrentPage,
        partnersSort,
        topPerformersLoading,
        topPerformersTableData,
        topPerformersTableSum,
        selectedManagers,
        includeOrExclude, filterType
    } = useAppSelector(state => state.networkView);

    const {token} = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch();

    const sortHandler = (key: string, event?: any,) => { 
        dispatch(setPartnersSortDirection({
            direction: partnersSort.by !== key ? "asc" : partnersSort.direction === "desc" ? "asc" : "desc",
            by: key
        }))
    }

    const commaTransform = (value: string) => {
        return Number(value).toLocaleString('en-US')
    }

    const allPartnersColumnDefs: any = [
        {
            colId: 1, 
            sortable: true,
            headerComponent: (params: any) =>  {
               return <SortableHeader 
                            keyId={'id'} 
                            sortDirection={partnersSort.direction} 
                            sortedBy={partnersSort.by} 
                            onClick={(event: any) => !params.column.isMoving() && sortHandler("id", event)}
                            icon={partnersSort.by === "id"  ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                        >PARTNER ID</SortableHeader>
            },
            cellRenderer: (data:any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                
                return <span>{data.data.id}</span>
            }
        },
        {
            colId: 2,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.company_name}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                            keyId={'company_name'} 
                            sortDirection={partnersSort.direction} 
                            sortedBy={partnersSort.direction} 
                            onClick={(event: any) =>!params.column.isMoving() &&  sortHandler("company_name", event)}
                            icon={ partnersSort.by === "company_name" ? partnersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span>}
                         >PARTNER COMPANY NAME</SortableHeader>
             },
        
        },
        {
            colId: 3, 
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.name} {data.data.lastname}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'name'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) =>!params.column.isMoving() && sortHandler("name", event)}
                             icon={partnersSort.by === "name" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >PARTNER NAME</SortableHeader>
             },
        
        },
        {
            colId: 4,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){            
                    return <span>{commaTransform(data.data.clicks)}</span>;
                }
                return <span>{commaTransform(data.data.clicks)}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'clicks'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) =>!params.column.isMoving() && sortHandler("clicks", event) }
                             icon={partnersSort.by === "clicks" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >CLICKS</SortableHeader>
             },
        
        },
        {
            colId: 5,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{commaTransform(data.data.conversions)}</span>;
                }
                return <span>{commaTransform(data.data.conversions)}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'conversions'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) => !params.column.isMoving() && sortHandler("conversions", event)}
                             icon={partnersSort.by === "conversions" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >CONVERSIONS</SortableHeader>
             },
        
        },
        {
            colId: 6,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{data.data.cvr.replaceAll('.', ',')}%</span>;
                }
                return <span>{data.data.cvr.replaceAll('.', ',')}%</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'cvr'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) => !params.column.isMoving() &&  sortHandler("cvr", event)}
                             icon={partnersSort.by === "cvr" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >CVR</SortableHeader>
             },
        
        },
        {
            colId: 7,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.revenue)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.revenue)}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'revenue'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) =>!params.column.isMoving() &&  sortHandler("revenue", event)}
                             icon={partnersSort.by === "revenue" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >REVENUE</SortableHeader>
             },
        
        },
        {
            colId: 8,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.profit)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.profit)}</span>
            },
            headerComponent: (params: any) =>  {
                return <SortableHeader 
                             keyId={'date'} 
                             sortDirection={partnersSort.direction} 
                             sortedBy={partnersSort.by} 
                             onClick={(event: any) => !params.column.isMoving() && sortHandler("profit") }
                             icon={partnersSort.by === "profit" ? partnersSort.direction === "asc" ?  <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <div></div> }
                         >PROFIT</SortableHeader>
             },
        
        },
        
    ];

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));

        const accMngs = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
            return item.id;
        })

        const params = {
            analyze_by: "partners",
            page: partnersCurrentPage,
            per_page: partnersPerPage,
            sort_by: partnersSort.by,
            order: partnersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_sale_exec'] = true
            }
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setPartnersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setPartnersPerPage(parseInt(response.data.per_page)))
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    const [partnersColumnDefs, setPartnersColumnDefs] = useState(allPartnersColumnDefs);

    const partnerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setPartnersPaginationColumnsList(list));
    }

    const [colWidths, setColWidths] = useState(allPartnersColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId};
    }));

    const [enableAllPartnersHandler, disableAllPartnersHandler, enableDisablePartnersColumnHandler]: any = useColumnsOptionHook(
        allPartnersColumnDefs, 
        partnersColumnDefs, 
        setPartnersColumnDefs,
        allPartnersPaginationColumns,
        partnersPaginationColumnsList, 
        partnerPaginationColumnsListHandler
    );

    const onColumnDrop = (columns: any) => { 
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        });
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        });

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allPartnersColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allPartnersPaginationColumns.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });
        
        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        });
        
        setColWidths(widths);
        setPartnersColumnDefs(widthToCol);
        const rest = partnersPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        partnerPaginationColumnsListHandler([...newPaginCol, ...rest]);
    };

    useEffect(() => {
        const checkedColumns = partnersPaginationColumnsList.filter((item: any) => item.checked);
        if (partnersPaginationColumnsList.length === 8) {
            if(partnersPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allPartnersColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                });
                setPartnersColumnDefs(newDefs);
            } else {
                setPartnersColumnDefs([]);
            };
        };
    }, [partnersPaginationColumnsList, partnersSort]);

    useEffect( () => {
        token && fetchData();
    }, [partnersPerPage, partnersCurrentPage, partnersSort]);

    return(
        <TopPerformersTable>
            <Grid 
                loading={topPerformersLoading}
                columnDefs={partnersColumnDefs}
                rowData={topPerformersTableData}
                rowHeight={40}
                domLayout='autoHeight'
                enableAllColumns={enableAllPartnersHandler}
                pinnedBottomRowData={topPerformersLoading || topPerformersTableData.length === 0 ? undefined : topPerformersTableSum}
                fixedHeader={false}
                onDragStopped={onColumnDrop}
            />
        </TopPerformersTable>
    );
};

export default TopPerformersPartner;