import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 25px;
  padding: 2px 4px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.coolGray['3']};
  background-color: ${({theme}) => theme.colors.coolGray['2']};
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  line-height: 18px;
  margin-top: 4px;
  color: ${({theme}) => theme.colors.deepBlue['5']};
  font-size: 12px;
  position: relative;
  transition: all .2s ease-in-out;
  
  span{
    margin-left: 4px;
    margin-right: 4px;
    label {
        color: ${({theme}) => theme.colors.performBlue['6']};
    }  
  }
  :hover{
    background-color: ${({theme}) => theme.colors.coolGray['3']};
  }
  
  .ico-Exit{
    color: ${({theme}) => theme.colors.performOrange['3']};
    font-size: 16px;
    margin-left: 5px;
    
    :hover{
      cursor: pointer;
    }
  }
`
