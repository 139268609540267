import React, {useEffect, useMemo, useState} from 'react'
import queryString from 'query-string'
import Grid from "../../../../../../../common/components/grid/Grid";
import {ColDef} from "ag-grid-community";
import SortableHeaderV2 from '../../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2';
import {formatNumberToCurrency, roundNumber} from '../../../../../../../common/utils';
import { TableWrapper } from './Table.style';
import { DropdownItem } from '../../../../../../../common/components/dropdown/Dropdown.style';
import GridRowOptions from '../../../../../../../common/components/grid/grid-row-options/GridRowOptions';
import Tooltip from '../../../../../../../common/components/tooltip/Tooltip';
import PauseRiskTooltip from './tooltips-special-columns/pause-risk-tooltip/PauseRiskTooltip';
import RecentInvoicesTooltip from './tooltips-special-columns/recent-invoices-tooltip/RecentInvoicesTooltip';
import InvoiceColumn from "./invoice-column/InvoiceColumn";

import AddEditNotesModal from "../add-edit-notes-modal/AddEditNotesModal";
import {IIFetchMarketerFundingDataResponse, IUpdateBillingNotesParams} from "../MarketerFundingMonitoring.service";
import { useAppDispatch, useAppSelector } from '../../../../../../../common/hooks';
import { setFundingMonitoringPaginationColumnsList, setFundingMonitoringSort } from '../../NetworkView.slice';
import PauseRisk from './pause-risk/PauseRisk'
import { allPaginationColumnsList } from './paginationColumnsList';
import { 
    useColumnsOptionHook 
} from '../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook';
import {
    IEditStayLiveLimitParams,
} from "../MarketerFundingMonitoring.service";
import EditStayLiveLimitModal from "./edit-stay-live-limit-modal/EditStayLiveLimitModal";
import LatestReassessmentStatus from "./latest-reassessment-status/LatestReassessmentStatus"; 
import LatestReassessmentStatusTooltip
    from "./tooltips-special-columns/latest-reassessment-status-tooltip/LatestReassessmentStatusTooltip";

interface Props {
    loading: boolean,
    tableData: IIFetchMarketerFundingDataResponse[],
    updateBillingNotes: (params: IUpdateBillingNotesParams) => void
    fetchTableData: () => void
    editStayLiveLimit: (params: IEditStayLiveLimitParams) => void
}

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const Table: React.FC<Props> = ({loading, tableData, updateBillingNotes, fetchTableData, editStayLiveLimit}) => {

    const {user} = useAppSelector(state => state.auth)

    const {
        fundingMonitoringSort,
        fundingMonitoringPaginationColumnsList
    } = useAppSelector(state => state.networkView);

    const [addEditNotesModal, setAddEditNotesModal] = useState<{data: IIFetchMarketerFundingDataResponse | null, opened: boolean}>({
        data: null,
        opened: false
    })

    const [editStayLiveLimitModal, setEditStayLiveLimitModal] = useState<{data: IIFetchMarketerFundingDataResponse | null, opened: boolean}>({
        data: null,
        opened: false
    })

    const dispatch = useAppDispatch();

    const handleOpenAddEditModal = (data: IIFetchMarketerFundingDataResponse) => {
        setAddEditNotesModal({
            data: data,
            opened: true
        })
    }

    const handleCloseAddEditModal = () => {
        setAddEditNotesModal({
            data: null,
            opened: false
        })
    }

    const handleEditStayLiveLimit = (data: IIFetchMarketerFundingDataResponse) => {
        setEditStayLiveLimitModal({
            data: data,
            opened: true
        })
    }

    const handleRedirectToJiraService = (data: IIFetchMarketerFundingDataResponse) => {
        const params = {
            //Marketer ID
            customfield_10754: data.id,
            //Marketer Company Name
            customfield_10741: data.company,
            //Marketer Rep's Email
            customfield_10698: data.account_manager_email,
            customfield_10776: (data.intacct_invoicing_cycle ? data.intacct_invoicing_cycle + ', ' : '') +
                (data.intacct_payment_term ? data.intacct_payment_term + ', ' : '') +
                (formatNumberToCurrency(parseFloat(data.intacct_credit_limit)))+ ", " +
                formatNumberToCurrency(data.intacct_credit_deposit),
            //Vertical
            customfield_10680: 'Dating'
        }
        const query = queryString.stringify(params)
        window.open('https://clickbooth.atlassian.net/servicedesk/customer/portal/6/group/14/create/86?' + query, '_blank')
    }

    const viewMarketerBilling = (id : number) =>{
        targetOrigin && window.parent.postMessage({id: 9, marketerId: id}, targetOrigin)
    }

    const allColDefs: any = [
        {
            field: 'id',
            // cellRenderer: ({data}: {data: any}) => {
            // return <div>test</div>
            // },
            colId: 1,
            pinned: "left",
            width: 130,
            lock: 'left',
            lockPosition: true,
            suppressMovable: true,
            lockVisible: true,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "id" ? 'asc' : 'desc',
                            by: 'id'
                        }))
                        }
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"id"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={100} positionTop={-20} delay={400} width={260} position='top' className='custom-tooltip' icon={<span></span>}>
                                The unique identification number assigned to a marketer.
                            </Tooltip>
                            :
                            undefined
                        }
                    >MARKETER ID</SortableHeaderV2>
                    {/* {!data.column.moving && !data.column.isMoving() && <Tooltip positionTop={-70} delay={400} width={260} position='right' className='custom-tooltip' icon={<span></span>}> */}
                    {/* The unique identification number assigned to a marketer. */}
                    {/* </Tooltip>} */}
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            colId: 2,
            pinned: 'left',
            width: 220,
            lockPosition: true,
            suppressMovable: true,
            lockVisible: true,
            cellRenderer: ({data}: {data: any}) => {
                return <div>{data.company}</div>
            },
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "company"  ? 'asc' : 'desc',
                            by: 'company'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={'company'}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ? <Tooltip positionLeft={90} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                            The name of the company this marketer is associated with.
                        </Tooltip> : undefined}
                    >MARKETER COMPANY NAME</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div>{data.name} {data.lastname}</div>
            },
            colId: 3,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "name"  ? 'asc' : 'desc',
                            by: 'name'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"name"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The name of the marketer that this account belongs to.
                            </Tooltip>
                            :
                            undefined
                        }
                    >MARKETER NAME</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div>{data.account_manager_name} {data.account_manager_lastname}</div>
            },
            colId: 4,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "account_manager_name"  ? 'asc' : 'desc',
                            by: 'account_manager_name'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"account_manager_name"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={90} positionTop={-20} delay={400} width={230} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The Perform[cb] employee associated with managing this marketer's account.
                            </Tooltip>
                            :
                            undefined
                        }
                    >ENGINE GROWTH MANAGER</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            resizable: false,
            width: 148,
            cellRenderer: ({data}: {data: any}) => {
                return <PauseRisk risk={data.pause_risk} />
            },
            colId: 5,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "pause_risk"  ? 'asc' : 'desc',
                            by: 'pause_risk'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"pause_risk"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={30} positionTop={4} delay={400} width={390} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                <PauseRiskTooltip />
                            </Tooltip>
                            :
                            undefined
                        }
                    >PAUSE RISK</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div className='cell-content-right' >{formatNumberToCurrency(data.marketer_total_revenue)}</div>
            },
            colId: 6,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "marketer_total_revenue"  ? 'asc' : 'desc',
                            by: 'marketer_total_revenue'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"marketer_total_revenue"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={160} positionTop={-20} delay={400} width={390} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The daily average revenue or spend a marketer has generated over the last 3 days (including QF & rejected).
                            </Tooltip>
                            :
                            undefined
                        }
                    >MARKETER TOTAL REVENUE (DAILY AVG LAST 3 DAYS)</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div className='cell-content-right' >{formatNumberToCurrency(data.balance)}</div>
            },
            colId: 7,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "balance"  ? 'asc' : 'desc',
                            by: 'balance'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"balance"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={75} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}> 
                                The current funding a marketer has available to spend. This includes any deposits made to the account.
                                If a marketer has depleted their funds and has stay live available it will be
                                reflected below with a “-” next to the dollar value based on how much stay live they have spent.
                            </Tooltip>
                            :
                            undefined
                        }
                    >FUNDING AVAILABLE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.funding_days_remaining !== null){
                    return <div className='cell-content-right' >{roundNumber(data.funding_days_remaining, 1)}</div>
                }
                return <div className='cell-content-right'>-</div>
            },
            colId: 8,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "funding_days_remaining"  ? 'asc' : 'desc',
                            by: 'funding_days_remaining'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"funding_days_remaining"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={85} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                The days remaining until a marketer runs out of funding based on their daily average total revenue of the past 3 days. <br /><br />
                                The calculation used to determine this:
                                (Funding Available) / (Marketer Total Revenue (Daily Avg Last 3 Days))
                            </Tooltip>
                            :
                            undefined
                        }
                    >FUNDING DAYS REMAINING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.funding_stay_live_days_remaining !== null){
                    return <div className='cell-content-right'>{roundNumber(data.funding_stay_live_days_remaining, 1)}</div>
                }
                return <div className='cell-content-right'>-</div>
            },
            colId: 9,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "funding_stay_live_days_remaining"  ? 'asc' : 'desc',
                            by: 'funding_stay_live_days_remaining'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"funding_stay_live_days_remaining"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={126} positionTop={4} delay={400} width={260} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                The days remaining until a marketer runs out of funding based on their daily average total revenue of the past 3 days,
                                this includes any stay live added to the account.
                                <br /><br />
                                The calculation used to determine this:
                                (Funding Available + Stay Live Limit) / (Marketer Total Revenue (Daily Avg Last 3 Days))
                            </Tooltip>
                            :
                            undefined
                        }
                    >FUNDING & STAY LIVE DAYS REMAINING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.auto_funding) {
                    return <div>Enabled / {formatNumberToCurrency(data.auto_funding_amount)} at {formatNumberToCurrency(data.auto_funding_amount / 10)} Funding Remaining</div>
                }
                return <div>Disabled</div>
            },
            colId: 10,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "auto_funding"  ? 'asc' : 'desc',
                            by: 'auto_funding'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"auto_funding"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={4} delay={400} width={180} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                A feature, which if enabled will automatically add funds to the marketer’s account when their
                                Funding Available reaches 10% of the entered auto-funding amount.
                                <br /><br />
                                For example if a marketer decides to enable auto-funding and enters an amount of $1,000 their credit card will be
                                charged the $1,000 when their Funding Available reaches 10% of that amount ($100 in this example).
                            </Tooltip>
                            :
                            undefined
                        }
                    >AUTO-FUNDING</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.intacct_credit_deposit === null) return <div></div>
                return <div className='cell-content-right' >{formatNumberToCurrency(data.intacct_credit_deposit)}</div>
            },
            colId: 11,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "intacct_credit_deposit"  ? 'asc' : 'desc',
                            by: 'intacct_credit_deposit'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"intacct_credit_deposit"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                A deposit, usually made when the marketer signs up with Perform[cb] as a way to be given a line of credit.
                                This amount is included in the marketer’s Funding Available.
                            </Tooltip>
                            :
                            undefined
                        }
                    >CREDIT DEPOSIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.intacct_credit_limit === null) return <div></div>
                return <div className='cell-content-right' >{formatNumberToCurrency(data.intacct_credit_limit)}</div>
            },
            colId: 12,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "intacct_credit_limit"  ? 'asc' : 'desc',
                            by: 'intacct_credit_limit'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"intacct_credit_limit"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                The amount of credit a marketer is extended from Perform[cb].
                            </Tooltip>
                            :
                            undefined
                        }
                    >CREDIT LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.credit === null) return <div className='cell-content-right'>Unlimited</div>
                return <div className='cell-content-right' >{formatNumberToCurrency(data.credit)}</div>
            },
            colId: 13,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "credit"  ? 'asc' : 'desc',
                            by: 'credit'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"credit"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                The amount of additional credit extended to a marketer from Perform[cb].
                                This is used as a preventative to keep a marketer’s traffic from pausing if they reach their credit limit.
                            </Tooltip>
                            :
                            undefined
                        }
                    >STAY LIVE LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div className='cell-content-right' >{formatNumberToCurrency(data.total_credit_limit)}</div>
            },
            colId: 14,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "total_credit_limit"  ? 'asc' : 'desc',
                            by: 'total_credit_limit'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"total_credit_limit"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The total amount of credit a marketer is extended from Perform[cb] including both their base credit limit and stay live.
                            </Tooltip>
                            :
                            undefined
                        }
                    >TOTAL CREDIT LIMIT</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div className='cell-content-right' >{formatNumberToCurrency(data.credit_used)}</div>
            },
            colId: 15,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "credit_used"  ? 'asc' : 'desc',
                            by: 'credit_used'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"credit_used"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The amount of credit a marketer currently has in-use from Perform[cb], including both base credit and stay live.
                            </Tooltip>
                            :
                            undefined
                        }
                    >CREDIT USED</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            resizable: false,
            headerName: '',
            width: 250,
            cellRenderer: ({data}: {data: IIFetchMarketerFundingDataResponse}) => {
                return <InvoiceColumn invoices={data.intacct_invoices}/>
            },
            colId: 16,
            headerComponent: (data : any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => {}}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={""}
                        icon={false}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={100} positionTop={4} delay={400} width={200} position='bottom' className='custom-tooltip'  icon={<span></span>}>
                                <RecentInvoicesTooltip />
                            </Tooltip>
                            :
                            undefined
                        }
                    >RECENT INVOICES</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div className='cell-content-right'>{data.days_until_payment}</div>
            },
            colId: 17,
            headerComponent: (data: any) => {
                return <div className='header-custom header-content-right'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "days_until_payment"  ? 'asc' : 'desc',
                            by: 'days_until_payment'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"days_until_payment"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The days until a marketer has a payment due. The number of days listed is reflective of the oldest unpaid invoice.
                            </Tooltip>
                            :
                            undefined
                        }
                    >DAYS UNTIL PAYMENT DUE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div>{data.intacct_invoicing_cycle}</div>
            },
            colId: 18,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "intacct_invoicing_cycle"  ? 'asc' : 'desc',
                            by: 'intacct_invoicing_cycle'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"intacct_invoicing_cycle"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The frequency that a marketer will receive an invoice.
                            </Tooltip>
                            :
                            undefined
                        }
                    >INVOICING CYCLE</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                return <div>{data.intacct_payment_term}</div>
            },
            colId: 19,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "intacct_payment_term"  ? 'asc' : 'desc',
                            by: 'intacct_payment_term'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"intacct_payment_term"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The amount of days a marketer has to pay an invoice from the time it is received.
                            </Tooltip>
                            :
                            undefined
                        }
                    >PAYMENT TERMS</SortableHeaderV2>
                </div>
            }
        },
        {
            field: 'billing_notes',
            headerName: 'BILLING NOTES',
            cellRenderer: ({data}: {data: IIFetchMarketerFundingDataResponse}) => {
                return (data.billing_notes !== null && data.billing_notes !== '') ? <span onClick={() => handleOpenAddEditModal(data)}
                                                                                          className={'view-edit-notes'}>View/Edit Notes</span> :
                    <span onClick={() => handleOpenAddEditModal(data)} className={'add-notes'}>Add Notes</span>
            },
            colId: 21,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => {}}
                        sortedBy={""}
                        keyId={""}
                        icon={false}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip' icon={<span></span>}>
                                Any notes added by a Perform[cb] employee related to the billing of this marketer.
                            </Tooltip>
                            :
                            undefined
                        }
                    >BILLING NOTES</SortableHeaderV2>
                </div>
            }
        },
        {
            field: 'latest_reassessment_status',
            headerName: 'LATEST REASSESSMENT STATUS',
            width: 217,
            colId: 23,
            cellRenderer: ({data}: { data: IIFetchMarketerFundingDataResponse }) => {
                return (
                    <LatestReassessmentStatus status={data.credit_request_status}/>
                )
            },
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => {
                        }}
                        sortedBy={""}
                        keyId={""}
                        icon={false}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={85}
                                     positionTop={4}
                                     delay={400}
                                     width={210}
                                     position='bottom'
                                     className='custom-tooltip'
                                     icon={<span/>}>
                                <LatestReassessmentStatusTooltip/>
                            </Tooltip>
                            :
                            undefined
                        }
                    >LATEST REASSESSMENT STATUS</SortableHeaderV2>
                </div>
            }
        },
        {
            field: '',
            headerName: '',
            cellRenderer: ({data}: {data: any}) => {
                if(data.niche_id === 0) return <div>Health & Beauty</div>
                if(data.niche_id === 1) return <div>Financial</div>
                if(data.niche_id === 2) return <div>Entertainment & Lifestyle</div>
                if(data.niche_id === 4) return <div>Other</div>
                if(data.niche_id === 5) return <div>Downloads & Software</div>
                if(data.niche_id === 6) return <div>E-Commerce</div>
                return <div/>
            },
            colId: 20,
            headerComponent: (data: any) => {
                return <div className='header-custom'>
                    <SortableHeaderV2
                        onClick={() => !data.column.moving && !data.column.isMoving() && dispatch(setFundingMonitoringSort({
                            direction: fundingMonitoringSort.direction === 'desc' && fundingMonitoringSort.by === "niche_id"  ? 'asc' : 'desc',
                            by: 'niche_id'
                        }))}
                        sortedBy={fundingMonitoringSort.by}
                        keyId={"niche_id"}
                        sortDirection={fundingMonitoringSort.direction}
                        tooltip={!data.column.moving && !data.column.isMoving() ?
                            <Tooltip positionLeft={80} positionTop={-20} delay={400} width={200} position='top' className='custom-tooltip'  icon={<span></span>}>
                                The industry channel that the marketer provides goods and services within.
                            </Tooltip>
                            :
                            undefined
                        }
                    >VERTICAL</SortableHeaderV2>
                </div>
            }
        },
        {
            pinned: "right",
            field: '',
            width: 50,
            colId: 22,
            resizable: false,
            cellRenderer: ({data}: {data: IIFetchMarketerFundingDataResponse}) => {
                return <GridRowOptions>
                    <DropdownItem disabled={
                        data.credit_request_status?.status == 'Submitted'
                        || data.credit_request_status?.status == 'In Progress'
                        || data.credit_request_status?.status == 'More info needed'
                    }
                                  onClick={() => handleRedirectToJiraService(data)}>
                        Request Credit Reassessment
                    </DropdownItem>

                    {(user.role.other.change_credit_amount === 'all' 
                            || (user.role.other.change_credit_amount === 'managed' && user.id === data.executive_id)) &&
                        <DropdownItem onClick={() => handleEditStayLiveLimit(data)}>Edit Stay Live Limit</DropdownItem>
                    }
                    <DropdownItem onClick={()=> viewMarketerBilling(data.id)}>
                        View Marketer Billing
                    </DropdownItem>
                </GridRowOptions>
            }
        }
    ]

    const [colDefs, setColDefs] = useState(allColDefs);
    const [colWidths, setColWidths] = useState(allColDefs.map((item: any) => {
        return {width: item.width ? item.width : item.minWidth, minWidth: item.minWidth ? item.minWidth : item.width, id: item.colId}
    }));

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]:any = useColumnsOptionHook(
        allColDefs,
        colDefs,
        setColDefs,
        allPaginationColumnsList,
        fundingMonitoringPaginationColumnsList,
        (
            list: {
                name: string, 
                id: number, 
                checked: boolean, 
                column_key?: string}[]
        ) => dispatch(setFundingMonitoringPaginationColumnsList(list))
    )

    useEffect(() => {
        const check = allPaginationColumnsList.filter(item => 
            !fundingMonitoringPaginationColumnsList.some(it => it.id === item.id))
        check.length && dispatch(setFundingMonitoringPaginationColumnsList(allPaginationColumnsList))
    }, [])

    useEffect(() => {
        const checkedColumns = fundingMonitoringPaginationColumnsList.filter((item: any) => item.checked);
        if(fundingMonitoringPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
            let newDefs:any = [];
            checkedColumns.forEach((it: any) => {
                const colDef = allColDefs.find((item:any) => item.colId === it.id);
                newDefs.push({...colDef, minWidth: colWidths.find((item: any) => item.id === it.id )?.minWidth ? colWidths.find((item: any) => item.id === it.id )?.minWidth : colWidths.find((item: any) => item.id === it.id )?.width });
            })
            setColDefs(newDefs);
        } else {
            setColDefs([]);
        }
    }, [fundingMonitoringPaginationColumnsList, fundingMonitoringSort])

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allColDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allPaginationColumnsList.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });

        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })

        setColWidths(widths);
        setColDefs(widthToCol);

        const rest = fundingMonitoringPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        dispatch(setFundingMonitoringPaginationColumnsList([...newPaginCol, ...rest]));
    }

    return <TableWrapper>
        <Grid
            loading={loading}
            rowData={tableData}
            columnDefs={colDefs}
            rowHeight={40}
            domLayout='autoHeight'
            // onDragStopped={onColumnDrop}
            enableAllColumns={() => enableAllHandler({})}
        />
        <AddEditNotesModal modal={addEditNotesModal} closeModal={handleCloseAddEditModal} updateBillingNotes={updateBillingNotes} fetchTableData={fetchTableData}/>
        <EditStayLiveLimitModal editStayLiveLimit={editStayLiveLimit} modal={editStayLiveLimitModal} closeModal={() => setEditStayLiveLimitModal({data: null, opened: false})}  fetchTableData={fetchTableData}/>
    </TableWrapper>


}

export default Table
