import {clearSelected, PageID, setFilter} from "../../../../store/filters.slice";
import {FilterID, IAdvertisersFilter, IFilterHookReturn, IOfferTypeFilter, IStatusesFilter} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterSelector} from "../../useFilterSelector";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {OFFER_STATUSES} from "../../../utils/consts";


export const useOfferStatusCacheFilter = (pageId: IPagePresetsID) => {
    const [statuses, setStatuses] = useState<IStatusesFilter[]>(OFFER_STATUSES)
    const [statusesSearchValue, setStatusesSearchValue] = useState('')

    const dispatch = useAppDispatch()

    const id: FilterID = 'offer-status'

    useEffect(() => {
        let filteredStatuses = OFFER_STATUSES.filter(status => (
                status.value.toLowerCase()).includes(statusesSearchValue.toLowerCase())
            ||
            status.id.toString().includes(statusesSearchValue.toLowerCase())
        )

        setStatuses(filteredStatuses)


    }, [statusesSearchValue])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IStatusesFilter) => {
        if (filter.selected.some((selected) => selected._id === item._id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected._id !== item._id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }



    return {
        id: id,
        options: statuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: 0,
        search: {
            value: statusesSearchValue,
            setSearch: setStatusesSearchValue
        },
        loading : false
    }
}