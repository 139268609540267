import {useEffect, useState, useCallback} from "react";

import {api} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IAccountManagerFilter, IFilterHookReturn, IVendorIdFilter} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";

export const useVendorIdFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<IVendorIdFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IVendorIdFilter[]>([])
    const [vendors, setVendors] = useState<IVendorIdFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [vendorIdSearchValue, setVendorIdSearchValue] = useState('')
    const [vendorsNotDisplayed, setVendorsNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID ='vendor_id'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await api.get<IVendorIdFilter[]>(
                [process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    'user_vendors'
                ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    }
                }
            )
            setLoading(false)
            setFetchedData(
                data
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredVendors = fetchedData.filter(vendor => (
                vendor.vendor_id.toLowerCase().includes(vendorIdSearchValue.toLowerCase())
        ))

        if(displayCount){
            const displayedAcc = [...filteredVendors].slice(0, displayCount)
            if(filteredVendors.length > displayCount){
                setVendorsNotDisplayed(filteredVendors.length - displayCount)
            } else {
                setVendorsNotDisplayed(0)
            }
            setVendors(displayedAcc)
        } else {
            setVendors(
                filteredVendors
            )
        }
    }, [vendorIdSearchValue, fetchedData, displayCount])

    const vendorIdsFilter = useFilterSelector<IVendorIdFilter>(pageId, id)

    const setSelectedVendors = (vendor: IVendorIdFilter) => {
        if(vendorIdsFilter.selected.some((filterVendor)  => filterVendor.vendor_id === vendor.vendor_id)){
            dispatch(setFilter<IVendorIdFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: vendorIdsFilter.exclude,
                    selected: vendorIdsFilter.selected.filter(accManager => accManager.vendor_id !== vendor.vendor_id)
                }
            }))
        } else {
            dispatch(setFilter<IVendorIdFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: vendorIdsFilter.exclude,
                    selected: [...vendorIdsFilter.selected, vendor]
                }

            }))
        }
    }

    const setExcludeVendorId = (exclude: boolean) => {
        dispatch(setFilter<IVendorIdFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: vendorIdsFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: vendors,
        setSearchValue: setVendorIdSearchValue,
        notDisplayed: vendorsNotDisplayed,
        selectedData: vendorIdsFilter.selected,
        exclude: vendorIdsFilter.exclude,
        setSelectedData: setSelectedVendors,
        // setExclude: setExcludeVendorId,
        clearSelected: clearSelectedFilter
    }
}

