import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

const partnerPaymentStatuses: any[] = [
    {value: "Approved", id: "approved"},
    {value: "Under Compliance Review", id: "under_compliance_review"},
    {value: "Pending Payment Forms", id: "pending_payment_forms"},
    {value: "Incomplete/Invalid Payment Forms", id: "invalid_payment_forms"},
    {value: "Pending Review", id: "pending_review"},
    {value: "On hold", id: "on_hold"}
];

export const usePartnerPaymentStatusCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'partner-payment-status'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (status: any) => {
        if(filter.selected.some((selected)  => selected.value === status.value)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.value !== status.value)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, status]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: partnerPaymentStatuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },

    }
}