import styled from 'styled-components'

export const ContentWrapper = styled.div`
    width: 433px;
    max-width: 100%;
    
    .input-styled{
        margin: 0;
    }
    
    .row{
        margin-bottom: 24px;
    }
    
    p{
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        font-size: 12px;
        
    }
    
    .resend-code {
        color: #0093D3;
        cursor: pointer;
    }
    
    .resend-code-disabled {
        color: ${({theme}) => theme.colors.coolGray[4]};
    }

    .resend-code-again {
        color: ${({theme}) => theme.colors.performBlue[5]};
    }
`