import React, {useState, useRef, useEffect} from 'react'

import {FilterID, IAdvertisersFilter} from "filters";

import {Wrapper} from './Filters.style'
import { useAdvertisersFilter, useMarketerStatusFilter } from '../../../../../../common/hooks/filters';
import { useAppDispatch, useAppSelector, useOnClickOutside } from '../../../../../../common/hooks';
import IncludeExcludeFilter from '../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter';
import { SingleSelectItem } from '../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter.style';
import { removeAllFilters, singleSelect } from '../../../../../../store/filters.slice';
import SelectFilterDropdown from '../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import { ButtonStyled } from '../../../../../../common/styled-components';
import ClickAgainToConfirmTooltip from '../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip';

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'marketer-status',
        value: 'Status'
    },
    {
        id: 'advertiser',
        value: 'Marketer'
    }
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    
    const marketerStatusFilter = useMarketerStatusFilter('admin/billing/marketer-balance');
    const advertisersFilter = useAdvertisersFilter('admin/billing/marketer-balance', 'company_name', 50)

    // const advertisersFilter = 
    //     {
    //         id: tempAdvertisersFilter.id,
    //         pageId: tempAdvertisersFilter.pageId,
    //         loading: tempAdvertisersFilter.loading,
    //         data: tempAdvertisersFilter.data,
    //         setSearchValue: tempAdvertisersFilter.setSearchValue,
    //         notDisplayed: tempAdvertisersFilter.notDisplayed,
    //         selectedData: tempAdvertisersFilter.selectedData,
    //         setSelectedData: tempAdvertisersFilter.setSelectedData,
    //         clearSelected: tempAdvertisersFilter.clearSelected
        // }
    
    // console.log(advertisersFilter);
    
    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const handleAdvertiserSelect = (advertiser: IAdvertisersFilter) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/marketer-balance',
            filterId: 'advertiser',
            data: advertiser
        }))
    }


    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "marketer-status":
                return (
                    <IncludeExcludeFilter hasTopStickyContainer={false} selectedFilterText={marketerStatusFilter.selectedData[0] ? marketerStatusFilter.selectedData[0].name : ''} filter={marketerStatusFilter} filterTitle={'Status'} setDisplayDiscoverResults={setDiscoverDisplayed}>
                        {marketerStatusFilter.data.map(status =>  
                            <SingleSelectItem
                                className={'checkbox-filter-wrapper'}
                                key={status.id}
                                active={marketerStatusFilter.selectedData.some(selected => status.id === selected.id)}
                                onClick={() => {
                                    marketerStatusFilter.setSelectedData(status)
                                    setDiscoverDisplayed(true)
                                }}
                            >{status.name}</SingleSelectItem>
                        )
                        }
                    </IncludeExcludeFilter>
                )
            case "advertiser":
                return (
                    <IncludeExcludeFilter hasInclude={false} selectedFilterText={advertisersFilter.selectedData[0] ? advertisersFilter.selectedData[0].company : ''} filter={advertisersFilter} filterTitle={'Marketer'} setDisplayDiscoverResults={setDiscoverDisplayed}>
                        {advertisersFilter.data.map(advertiser =>  
                            <SingleSelectItem
                                className={'checkbox-filter-wrapper'}
                                key={advertiser.id}
                                active={advertisersFilter.selectedData.some(selected => advertiser.id === selected.id)}
                                onClick={() => {
                                    handleAdvertiserSelect(advertiser)
                                    setDiscoverDisplayed(true)
                                }}
                            >{advertiser.company}</SingleSelectItem>
                        )
                        }
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/billing/marketer-balance'))
        }
    }


    return (
        <Wrapper>
            <i className={'ico-Filters'}/>
            {filters.filter((filter: any) => filter.pageID === 'admin/billing/marketer-balance')[0]?.filters.map((filter: any) => 
            <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
            <SelectFilterDropdown setDiscoverDisplayed={setDiscoverDisplayed} allFilters={filterList} pageId={'admin/billing/marketer-balance'}/>

            {filters.filter((filter: any) => 
                filter.pageID === 'admin/billing/marketer-balance')[0]?.filters.length > 1 && 
                <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                    {   displayClearFilterTooltip && 
                         <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
                    }
                    Clear All Filters
                </ButtonStyled>}
        </Wrapper>
    )
}


export default Filters
