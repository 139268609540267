import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 40px 50px 40px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 769px) {
    padding: 24px 20px 50px 20px;
  }
`

export const HeadSection = styled.div`
  margin-bottom: 24px;
  h1{
    line-height: 43px;
    font-size: 32px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
  }

  p{
    color: ${({theme}) => theme.colors.deepBlue[5]};
    line-height: 19px;
    font-size: 14px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    margin-top: 8px;
  }
`