import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IAccountManagerFilter, IFilterHookReturn} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";
import FiltersService from "../../services/filters.service";

export interface IReferrerFilter {
    id: number;
    name: string;
    lastname: string;
    full_name: string;
}

export const useReferrerFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<IReferrerFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IReferrerFilter[]>([])
    const [referrers, setReferrers] = useState<IReferrerFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(50);

    const dispatch = useAppDispatch()

    const id ='referrer'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const response = FiltersService.fetchReferrers(token);
            const data: IReferrerFilter[] = (await response).data;
            setLoading(false)
            setFetchedData(
                data.sort((a: IReferrerFilter, b: IReferrerFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredReferrers = fetchedData.filter(manager => (
                manager.name.toLowerCase() + ' ' + manager.lastname.toLowerCase()).includes(searchValue.toLowerCase())
            ||
            manager.id.toString().includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredReferrers].slice(0, displayCount)
            if(filteredReferrers.length > displayCount){
                setNotDisplayed(filteredReferrers.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setReferrers(displayedAcc)
        } else {
            setReferrers(
                filteredReferrers
            )
        }
    }, [searchValue, fetchedData, displayCount])

    const referrerFilter = useFilterSelector<IReferrerFilter>(pageId, id)

    const setSelectedReferrers = (referrer: IReferrerFilter) => {
        if(referrerFilter.selected.some((ref)  => ref.id === referrer.id)){
            dispatch(setFilter<IReferrerFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: referrerFilter.exclude,
                    selected: referrerFilter.selected.filter(accManager => accManager.id !== referrer.id)
                }
            }))
        } else {
            dispatch(setFilter<IReferrerFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: referrerFilter.exclude,
                    selected: [...referrerFilter.selected, referrer]
                }

            }))
        }
    }

    const setExcludeReferrer = (exclude: boolean) => {
        dispatch(setFilter<IReferrerFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: referrerFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: referrers,
        setSearchValue: setSearchValue,
        notDisplayed: notDisplayed,
        selectedData: referrerFilter.selected,
        exclude: referrerFilter.exclude,
        setSelectedData: setSelectedReferrers,
        setExclude: setExcludeReferrer,
        clearSelected: clearSelectedFilter
    }
}

