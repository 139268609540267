import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";


interface Props {}

const SelectedCities: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(offer.selected_cities.length === 0 || navState['targeting'] === 'disabled') return null

    return (
        <>
            <Subtitle>{offer.cities_type === 'include' && 'Include'}{offer.cities_type === 'exclude' && 'Exclude'}
                Cities</Subtitle>
            <TruncateContent wrapperClassName={'truncate-wrapper'}
                             numberOfLines={3}>{offer.selected_cities.map((loc, index) => {
                return <span key={loc.name}>{loc.name}{index !== offer.selected_cities!.length - 1 ? ', ' : ''}</span>
            })}
            </TruncateContent>
        </>
    )
}

export default SelectedCities