import React, {useEffect, useRef, useState} from 'react'
import {DnDZoneWrapper} from "../../Main.style";
import {useDropzone} from "react-dropzone";
import Flow from "@flowjs/flow.js";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {addCreative, setLastCreativeId, setOffer} from "../../../../create-offer.slice";

const UploadCreativesDropbox: React.FC = () => {
    const dispatch = useAppDispatch()
    const {offer, lastCreativeId} = useAppSelector(state => state.createOffer)
    const {token, config} = useAppSelector(state => state.auth)

    const {
        getRootProps,
        getInputProps,
        isDragReject,
        isDragActive
    } = useDropzone({
        maxFiles: 20,
        accept: {
            'image/*': ['.png', '.jpg', '.gif'],
            'video/*': ['.mp4', '.m4v', '.mov', '.wmv', '.avi', '.mpg', '.ogv', '.3gp', '.3g2']
        },
        onDrop: (acceptedFiles) => {
            let smallestId = lastCreativeId - 1

            acceptedFiles.forEach(async (file, index) => {
                const flow = new Flow({
                    chunkSize: (1024 * 1024),// size in MB
                    progressCallbacksInterval: 1000,
                    simultaneousUploads: 3,
                    maxChunkRetries: 1,
                    testChunks: false,
                    forceChunkSize: true,
                    allowDuplicateUploads: true,
                    target: config.apiBase + 'creative_upload',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                flow.opts.query = {
                    id: smallestId,
                    is_test: false,
                    is_icon: false,
                    campaign_id: offer.id,
                    multiple_files: false
                }
                flow.addFile(file)
                flow.upload()
                flow.on('fileSuccess', (flowFile, response) => {
                    const data = JSON.parse(response)
                    const extension = flowFile.name.split('.').pop()
                    const files = flowFile.flowObj.files.map(file => {
                        const isBanner = config.creativesConfig.banner_sizes.map((banner: number[]) => {
                            return banner.join('x')
                        })
                            .includes(`${data.meta?.size?.width}x${data.meta?.size?.height}`)
                        const newFile: any = {
                            id: data.temp_id,
                            campaign_id: offer.id,
                            type: ['png', 'jpg', 'gif'].includes(file.name.split('.').pop()!) ? isBanner ? 'banner' : "product_image" : "product_video",
                            status: "pending",
                            name: file.name.split('.')[0],
                            files: [{
                                id: 0,
                                name: file.name,
                                extension: extension ? extension : '',
                                width: data.meta?.size?.width,
                                height: data.meta?.size?.height,
                                size: Math.ceil(file.size / 1000),
                                created_at: data.uploaded_at,
                            }],
                            upsell_id: null
                        }
                        return newFile
                    })

                    if(!flow.isUploading()){
                        dispatch(addCreative(files[0]))
                        dispatch(setLastCreativeId(
                            data.last_id
                        ))
                    }

                })
                smallestId = smallestId - 1

                flow.on('fileError', (flowFile) => {

                })
            })
        }
    });

    return <>
        <DnDZoneWrapper dragActive={isDragActive} {...getRootProps({
            style: {
                borderColor: isDragReject ? 'red' : ''
            }
        })}>
            <input {...getInputProps()}/>
            <div style={{
                transition: 'all .2s ease-in-out'
            }}>
                <div>
                    <i className="ico-Upload"/>
                </div>
                {isDragActive ? <>
                    <h4>Drop File to upload</h4>
                </> : <>
                    <h4>Drag & Drop file here or <span>Choose File</span> to upload</h4>
                    <p className="supported">
                        Upload product images. banners, or videos as <br/>
                        PNG, JPG, GIF, MP4, M4V, MOV, WMV, AVI, MPG, OGV, 3GP,  or 3G2.
                    </p>
                    <p className={'supported'}>
                        Product images should have a transparent background.
                    </p>
                    <p className="maximum">
                        Maximum Uploads: 21
                    </p>
                </>}

            </div>
        </DnDZoneWrapper>
    </>
}

export default UploadCreativesDropbox