import { IInputSingleLineProps } from "./InputSingleLine.props";
import { InputSingle, InputSingleLineWrapper } from "./InputSingleLine.style";
import useDebounce from "../../hooks/useDebounce";
import {useEffect, useState} from "react";

const InputSingleLine = ({onChange, useDebauncedValue = false, value, placeholder, width = "310px", height, onKeyDown, type, readonly = false, delay = 0}: IInputSingleLineProps ) => {

    const [initialValue, setInitialValue] = useState<string>(value);
    const debouncedValue = useDebounce(initialValue ? initialValue : "", delay);
    const inputHandler = (val: string) => {
        setInitialValue(val);
    }
    useEffect(()=>{
        useDebauncedValue && onChange(debouncedValue);
    }, [debouncedValue]);

    useEffect(()=>{
        !useDebauncedValue && onChange(initialValue);
    }, [initialValue]);

    return(
        <>
            <InputSingleLineWrapper $width={width}>
                <InputSingle 
                    placeholder={placeholder} 
                    value={useDebauncedValue ? initialValue : value}
                    onChange={(e) => inputHandler(e.target.value)}
                    onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                    $height={height}
                    readOnly={readonly}
                    type={type}
                />
            </InputSingleLineWrapper>
        </>
    )
}

export default InputSingleLine;