import {useCallback, useEffect, useState} from "react";
import {filterApi} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, INicheFilter} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";

export const useNicheCacheFilter = (pageId: IPagePresetsID, displayCount?: number, network_id: null | number = null) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<INicheFilter[]>([])
    const [allNiches, setAllNiches] = useState<INicheFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [nichesSearchValue, setNichesFilterSearchValue] = useState('')
    const [nichesFilterNotDisplayed, setNichesFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id :FilterID = 'niche'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<INicheFilter[]>(
                'niches',
                {
                    network_id
                },
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(data)
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let niches = fetchedData.filter(niche => (
                niche.name.toLowerCase()).includes(nichesSearchValue.toLowerCase())
            ||
            niche.id.toString().includes(nichesSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...niches].slice(0, displayCount)
            if(niches.length > displayCount){
                setNichesFilterNotDisplayed(niches.length - displayCount)
            } else {
                setNichesFilterNotDisplayed(0)
            }
            setAllNiches(displayedAcc)
        } else {
            setAllNiches(
                niches
            )
        }


    }, [nichesSearchValue, fetchedData, displayCount])


    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: INicheFilter) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }



    return {
        id: id,
        options: allNiches,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: nichesFilterNotDisplayed,
        search: {
            value: nichesSearchValue,
            setSearch: setNichesFilterSearchValue
        },
        loading
    }
}