import React, {useEffect, useMemo, useState} from "react";
import {
    InfoBox,
    SearchInfo,
    ModalHeaderContent,
    TablePanel,
    TablePanelBody,
    TablePanelHeader,
} from "./MergeWithExistingPartnersModal.style"
import {AlertStyled, BtnLoadingSpinner, ButtonStyled} from "../../../../../../../common/styled-components";
import MergingService, {
    IMergedPartner,
    IMergedPartnerList,
    IMergedPartnersParams,
    IMergedPreview,
    INonMergedPartner,
    IPartnerTrafficType,
    ITagNonMerged,
    IUserForMerging,
    IUsersForMerging,
    PLATFORMS,
    SORT
} from "../../Merging.service";
import SwitchToggleButton from "../../../../../../../common/components/switch-toggle-button/SwitchToggleButton";
import Grid from "../../../../../../../common/components/grid/Grid";
import {CellClickedEvent, ColDef} from "ag-grid-community";
import {Tag} from "../NonMerged.style";
import {CellDetailsItem, CellToggle, CellDetails} from "../../merged/Merged.style";
import {ITag} from "../../../../../../../common/models/tag.type";
import Pagination from "../../../../../../../common/components/table/pagination/Pagination";
import InputSingleLine from "../../../../../../../common/components/input-single-line/InputSingleLine";
import {TSortType} from "../../../../../../../common/components/table/sortable-header-v2/sortableHeaderV2.props";
import {useAppSelector} from "../../../../../../../common/hooks";
import {tokenStore} from "../../../../../../../store/auth.slice";
import _ from "lodash";
import RadioButton from "../../../../../../../common/components/radio-button/RadioButton";
import {CloseButton, Header, Body, Footer} from "../../../../../../../common/components/modalWindow/container/Container.style";
import {ModalHeader} from "../merge-partners-modal/MergePartnersModal.style";
import SortableHeaderV2 from "../../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";

interface IMergeWithExistingPartnersModal {
    selectedPartners: INonMergedPartner[],
    closeModal: () => void,
    fetchData: () => void,
}

const MergeWithExistingPartnersModal: React.FC<IMergeWithExistingPartnersModal> = ({
                                                                                       selectedPartners,
                                                                                       closeModal,
                                                                                       fetchData
                                                                                   }) => {
    /**
     * STATES START
     */
    const token = useAppSelector(tokenStore)
    const [mergePreview, setMergePreview] = useState<boolean>(false)

    const perPageCustomList: number[] = [5, 10, 20, 50];
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [lastPage, setLastPage] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(true);

    const [sort, setSort] = useState<string>(SORT[1])
    const [order, setOrder] = useState<TSortType>('asc')

    const [selectedExistingPartner, setSelectedExistingPartner] = useState<string>("")
    const [selectedExistingPartnerObj, setSelectedExistingPartnerObj] = useState<IMergedPartner>()

    const [tableData, setTableData] = useState<IMergedPartner[]>([])

    const [mergedPartners, setMergedPartners] = useState<IMergedPreview>({
        ids: [],
        names: [],
        platforms: [],
        tags: [],
        trafficType: {} as IPartnerTrafficType
    })
    /**
     * STATES END
     */

    /**
     * TABLES START
     */

        // <---------- EXISTING MERGED TABLE START --------->
    const columnDefsExistingMerged = useMemo(() =>[
        {
            field: "radio_button", headerName: "", width: 50, colId: "radio",
            cellRenderer: ({data}: {data: IMergedPartner}) => {
                return (
                    <div className={'cell-item'}>
                        <RadioButton
                            option={data.id.toString()}
                            activeOption={selectedExistingPartner}
                            setActiveOption={(option)=> {
                                setSelectedExistingPartner(option)
                            }}
                        />
                    </div>
                )
            }
        },
        {
            field: "partner_id", headerName: "PARTNER ID", width: 150,
            cellRenderer: () => {
                return <div className={'cell-item'}>Multiple</div>
            }
        },
        {
            field: "partner_name", width: 300, colId: "partnerList",headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <CellToggle>
                        <i className={`${data.expanded ? "ico-ChevronUp" : "ico-ChevronDown"}`}/> {data.name} <i className={"ico-Connected"}/>
                    </CellToggle>
                    {data.partnerList && data.expanded &&
                        <CellDetails>
                            {data.partnerList.map((item: IMergedPartnerList)=> {
                                return (
                                    <CellDetailsItem style={{paddingLeft: 20}} key={item.merged_partner_id + Math.random()}>
                                        {item.platform_partner_name}{item.platform_partner_name ? ', ' : ''}{item.platform_partner_company}
                                    </CellDetailsItem>
                                )
                            })}
                        </CellDetails>
                    }
                </div>
            },
            headerComponent: (row: any) => {
                return <SortableHeaderV2
                    keyId={SORT[1]}
                    sortDirection={order}
                    sortedBy={sort}
                    onClick={(event: any) => {
                        setSort(SORT[1])
                        setOrder(order === "desc" ? "asc" : "desc")
                    }}
                >PARTNER NAME</SortableHeaderV2>
            }
        },
        {
            field: "platform", headerName: "PLATFORM", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>
                    <CellToggle>
                        Multiple
                    </CellToggle>
                    <div style={{marginBottom: 14}}>
                        {data.partnerList && data.expanded &&
                            <CellDetails>
                                {data.partnerList.map((item)=> {
                                    return (
                                        <CellDetailsItem key={item.merged_partner_id + Math.random()}>
                                            {PLATFORMS[item.platform_id]}
                                        </CellDetailsItem>
                                    )
                                })}
                            </CellDetails>
                        }
                    </div>
                </div>
            }
        },
        {
            field: "number_of_partners", headerName: "# OF PARTNERS", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>{data.count}</div>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return <div className={'cell-item'}>{data.partner_traffic_type && data.partner_traffic_type[0].name}</div>
            }
        },
        {
            field: "id", headerName: "TAGS", width: 300,
            cellRenderer: ({data}: { data: IMergedPartner }) => {
                return data.tags && data.tags.map((tag: ITagNonMerged) => {
                    return <div className={'cell-item'} key={tag.tag_id + tag.name + Math.random()}>
                        <Tag>{tag.name}</Tag>
                    </div>
                })
            }
        }
    ],[order, sort, tableData, selectedExistingPartner, mergedPartners])
    // <---------- EXISTING MERGED TABLE END --------->

    // <---------- NONMERGED TABLE START --------->
    const columnDefsNonMerged = useMemo<ColDef[]>(() => [
        {
            field: "id", headerName: "PARTNER ID", width: 110,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_id}</span>
            }
        },
        {
            field: "name", width: 200, headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_name}</span>
            },
        },
        {
            field: "company", headerName: "PARTNER COMPANY NAME", width: 200,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_company}</span>
            }
        },
        {
            field: "platform", headerName: "PLATFORM", width: 150,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                let id: number = data.platform_id
                if (id) {
                    return <span key={Math.random()}>{PLATFORMS[id]}</span>
                } else
                    return <span key={Math.random()}>{PLATFORMS[0]}</span>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                if (data.partner_traffic_type) {
                    return <span key={data.partner_traffic_type[0].id + Math.random()}>
                    {data.partner_traffic_type[0].name}</span>
                } else
                    return <span/>
            }
        },
        {
            field: "tags", headerName: "TAGS", width: 300,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return data.tags && data.tags.map((tag: ITagNonMerged) => {
                    return <Tag key={tag.tag_id + tag.name}>{tag.name}</Tag>
                })
            }
        }
    ], [])
    // <---------- NONMERGED MERGED TABLE END --------->

    // <---------- MERGED (PREVIEW) TABLE START --------->
    const columnDefsMerged = useMemo<ColDef[]>(() => [
        {
            field: "partner_id", headerName: "PARTNER ID", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return (
                    <div className={'cell-item'}>
                        <CellToggle>Multiple</CellToggle>
                        {expanded &&
                            <CellDetails>
                                {data.ids.map((id: number) => {
                                    return <CellDetailsItem key={id}>{id}</CellDetailsItem>
                                })}
                            </CellDetails>
                        }
                    </div>
                )
            }
        },
        {
            field: "partner_name", width: 200, headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: IMergedPreview }) => {

                return <div className={'cell-item'}>
                    <CellToggle onClick={()=> setExpanded(!expanded)}>
                        <i className={`dropdown ${expanded ? "ico-ChevronUp" : "ico-ChevronDown"}`}/> {selectedExistingPartnerObj?.name} <i className={"ico-Connected"}/>
                    </CellToggle>
                    {expanded &&
                        <CellDetails>
                            {data.names &&
                                data.names.map((name) => {
                                    return <CellDetailsItem style={{paddingLeft: 20}}
                                                            key={name + Math.random()}>
                                        {name}
                                    </CellDetailsItem>
                                })
                            }
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "platform",
            headerName: "PLATFORM",
            width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'}>
                    <CellToggle>Multiple</CellToggle>
                    {expanded &&
                        <CellDetails>
                            {data.platforms.map((platform : string) =>{
                                return <CellDetailsItem key={platform + Math.random()}>{platform}</CellDetailsItem>
                            })}
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "number_of_partners",
            headerName: "# OF PARTNERS",
            width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'}>{
                    mergedPartners.names.length
                }</div>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'} key={data.trafficType.name + Math.random()}>
                    {mergedPartners.trafficType.name}
                </div>
            }
        },
        {
            field: "tags", headerName: "TAGS", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return data.tags && data.tags.map((tag: ITag) => {
                    return <div className={'cell-item'} key={tag.id + tag.name + Math.random()}>
                        <Tag>{tag.name}</Tag>
                    </div>
                })
            }
        }
    ], [mergedPartners.names, expanded])
    // <---------- MERGED (PREVIEW) TABLE END --------->

    const defaultColDef = useMemo(() => ({
        resizable: true,
        minWidth: 50,
        autoHeight: true
    }), [])

    const gridOptions = useMemo(() => ({
        columnDefs: columnDefsExistingMerged,
        defaultColDef: defaultColDef,
        onCellClicked: async (event: CellClickedEvent) => {
            if (event.colDef.colId === 'partnerList') {
                if (event.data.partnerList){
                    event.node.setData({ ...event.data, expanded: !event.data.expanded})
                }
                else {
                    const {data} = await MergingService.fetchMergedPartnersList(token, event.data.id)
                    event.node.setData({...event.data, partnerList: data, expanded: !event.data.expanded})
                }
            }
            else if (event.colDef.colId === 'radio') {
                if (event.data.partnerList){
                    event.node.setData({ ...event.data})
                }
                else {
                    const {data} = await MergingService.fetchMergedPartnersList(token, event.data.id)
                    event.node.setData({...event.data, partnerList: data})
                    setSelectedExistingPartnerObj({...event.data, partnerList: data})
                }
            }
        },
    }), [tableData]);

    /**
     * TABLES END
     */


    /**
     * FUNCTIONS START
     */

    const fetchMergedPartnersTableData = async () => {
        setLoading(true)
        let params: IMergedPartnersParams = {
            sort_by: sort,
            order: order
        }
        if (search !== "") params.q = search
        if (perPage !== 50) params.paginate_by = perPage
        if (currentPage !== 1) params.page = currentPage
        let {data} = await MergingService.fetchMergedPartners(token, params);
        let rowData = data.data.map((item:IMergedPartner)=> {
            return {...item, expanded: false}
        })
        setTableData(rowData)
        setFrom(data.from)
        setLastPage(data.last_page)
        setTo(data.to)
        setTotal(data.total)
        setLoading(false)
    }

    useEffect(()=>{
        let temp: IMergedPreview = {
            ids: [],
            names: [],
            platforms: [],
            tags: [],
            trafficType: {} as IPartnerTrafficType
        }
        selectedExistingPartnerObj && console.log(selectedExistingPartnerObj.partnerList)
        if (selectedExistingPartnerObj && selectedExistingPartnerObj.partnerList) {
            temp.ids.push(...selectedExistingPartnerObj.partnerList.map((item: IMergedPartnerList) => {
                return item.platform_partner_id
            }))
            temp.names.push(...selectedExistingPartnerObj.partnerList.map((item:IMergedPartnerList)=>{
                return item.platform_partner_name ? (item.platform_partner_name + (item.platform_partner_company ? ", " + item.platform_partner_company : "")) :
                    item.platform_partner_company
            }))
            temp.platforms.push(...selectedExistingPartnerObj.partnerList.map((item: IMergedPartnerList)=>{
                return PLATFORMS[item.platform_id]
            }))
            if (selectedExistingPartnerObj.partner_traffic_type) {
                temp.trafficType = selectedExistingPartnerObj.partner_traffic_type[0]
            }
            temp.tags = selectedExistingPartnerObj.tags.map((tag: ITagNonMerged) => {
                return {
                    id : tag.tag_id,
                    name :tag.name
                }
            })
        }

        selectedPartners.forEach((partner: INonMergedPartner) => {
            temp.ids.push(partner.platform_partner_id)
            temp.names.push(partner.platform_partner_name ? partner.platform_partner_name : "")
            temp.platforms.push(PLATFORMS[partner.platform_id])
        });
        setMergedPartners(temp)
    },[selectedExistingPartnerObj])

    const memoFetch = _.memoize(fetchMergedPartnersTableData)

    useEffect(()=> {
        memoFetch()
    }, [perPage, currentPage, search, sort, order])

    const saveMergedWithExPartners = async () => {
        let mergedPartners: IUsersForMerging = {} as IUsersForMerging
        mergedPartners.merged_partner_id = selectedExistingPartnerObj?.id
        if (selectedExistingPartnerObj?.partner_traffic_type)
        mergedPartners.traffic_types = selectedExistingPartnerObj.partner_traffic_type[0].id !== 0 ?
            [selectedExistingPartnerObj.partner_traffic_type[0].id] : null
        mergedPartners.users = []
        selectedPartners.forEach((partner: INonMergedPartner) => {
            let mergedUser: IUserForMerging = {
                name: partner.platform_partner_name ? partner.platform_partner_name : "",
                company_name: partner.platform_partner_company ? partner.platform_partner_company : "",
                id: partner.platform_partner_id,
                model_id: partner.id,
                platform_id: partner.platform_id,
                tags: partner.tags.map((tag: ITagNonMerged) => {
                    return {id: tag.tag_id, name: tag.name}
                }),
                ptt: partner.partner_traffic_type ? partner.partner_traffic_type[0] : null
            }
            mergedPartners.users.push(mergedUser)
        })
        try {
            setLoading(true)
            await MergingService.mergeExistingPartners(token, mergedPartners)
            fetchData()
            setLoading(false)
            closeModal()
        } catch (e) {
            console.log(e)
        }
    }

    const closeMergedWithExPartners = () => {
        fetchData()
        closeModal()
    }

    /**
     * FUNCTIONS END
     */

    return (
        <>
            <Header>
                <div className={'title'}>
                    Merge Partners with existing partners <CloseButton onClick={closeMergedWithExPartners}><i  className={'ico-Exit-outlined'}/></CloseButton>
                </div>
                <div className={'content'}>
                    <ModalHeaderContent>
                        <p>
                            Merging partners is a global change and will affect all users in the platform.
                            All reports and filters related to these partners will be updated immediately
                            upon merging
                        </p>
                        <p>This action can be undone from the merged partners tab on this page at any time.</p>
                        <p>
                            <strong>
                                Merge with Existing Merged Partner: <span>{selectedPartners.length}</span>
                            </strong>
                        </p>
                    </ModalHeaderContent>
                </div>
            </Header>

            <Body>
                <AlertStyled type={'info'} border={false} style={{margin: '0 0 32px'}}>
                    <InfoBox>
                        <i className={'ico-Information'}/>
                        <span>The traffic type and tags applied here will overwrite any that were previously applied to an individual partner.</span>
                    </InfoBox>
                </AlertStyled>

                <TablePanel>
                    <TablePanelHeader>
                        <div className={"headerTitle"}>Existing Merged Partners</div>
                    </TablePanelHeader>
                    <TablePanelBody>
                        <SearchInfo>
                            <p>Using the table below, select an existing merged partner to add the individual partner(s) to.</p>
                            <div className={"body"}>
                                <InputSingleLine
                                    width={"100%"}
                                    onChange={setSearch}
                                    placeholder={"Search"}
                                    value={search}
                                    delay={500}
                                    useDebauncedValue
                                    type={"search"}/>
                                <Pagination
                                    hideRows
                                    perPageCustomList={perPageCustomList}
                                    current_page={currentPage}
                                    from={from}
                                    to={to}
                                    total={total}
                                    setCurrentPage={setCurrentPage}
                                    setPerPage={setPerPage}
                                    last_page={lastPage}
                                    perPage={perPage}
                                    loading={loading}
                                />
                            </div>
                        </SearchInfo>
                        <Grid
                            columnDefs={columnDefsExistingMerged}
                            defaultColDef={defaultColDef}
                            gridOptions={gridOptions}
                            rowData={tableData}
                            rowHeight={40}
                            loading={loading}
                            className={'ag-grid-align-items-baseline'}
                            domLayout={'autoHeight'}/>
                    </TablePanelBody>
                </TablePanel>

                <TablePanel style={{marginBottom: 0}}>
                    <TablePanelHeader>
                        <div className={"headerTitle"}>Merge Partners</div>
                        <SwitchToggleButton label={"Merged Preview"}
                                            labelFirst
                                            active={mergePreview}
                                            disabled={ selectedExistingPartner === ""}
                                            setActive={() => setMergePreview(!mergePreview)}/>
                    </TablePanelHeader>
                    <TablePanelBody>
                        <Grid
                            columnDefs={columnDefsMerged}
                            defaultColDef={defaultColDef}
                            rowData={[mergedPartners]}
                            rowHeight={40}
                            domLayout='autoHeight'
                            className={`ag-grid-align-items-baseline ${mergePreview ? "" : "hidden"}`}
                            loading={loading}
                        />
                        <Grid
                            columnDefs={columnDefsNonMerged}
                            defaultColDef={defaultColDef}
                            rowData={selectedPartners}
                            rowHeight={40}
                            domLayout='autoHeight'
                            className={`${!mergePreview ? "" : "hidden"}`}
                            loading={loading}
                        />
                    </TablePanelBody>
                </TablePanel>
            </Body>

            <Footer>
                <ButtonStyled onClick={closeMergedWithExPartners} className={"btn-cancel"}>Cancel</ButtonStyled>
                <ButtonStyled
                    disabled={loading || selectedExistingPartner === ""}
                    onClick={saveMergedWithExPartners} className={"btn-orange-filled"}>
                    <span className={"btn-label"}>
                        {loading && <BtnLoadingSpinner style={{marginRight: 5}} />}
                        Merge Partners
                    </span>
                </ButtonStyled>
            </Footer>
        </>
    )
}

export default MergeWithExistingPartnersModal
