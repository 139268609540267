import styled from 'styled-components'


export const Wrapper = styled.div`
 .stay-live-input-wrapper{
   margin-bottom: 7px;
 }
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,.85);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`