import React from 'react'
import UploadCreativesDropbox from "../components/UploadCreativesDropbox";
import ReactDOMServer from "react-dom/server";
import VisualCreative from "../components/VisualCreative";
import {useAppSelector} from "../../../../../../../../common/hooks";

const tooltips = {
    visualCreatives: <span
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
            <>
                <p>If you have banners, product images or</p>
                <p> video creatives that you wish to provide</p>
                <p> for our partners to use, you may upload </p>
                <p>and manage them here.</p>
                <br/>
                <p>These will be reviewed and approved by </p>
                <p>PerformCB's Compliance Team.</p>
            </>)}>
        <i  className={'ico-Information-outlined'} />
    </span>,

}

const VisualCreatives: React.FC = () => {

    const {offer} = useAppSelector(state => state.createOffer)

    return <>
        <h3 className="creative-label">Visual Creatives <span className="optional">(optional)</span> {tooltips.visualCreatives}</h3>
        {offer.creatives.length < 21 ? <UploadCreativesDropbox/> : null}
        {offer.creatives
            .map((creative, index) => {
                return creative.type !== null && <VisualCreative key={creative.id} creative={creative} index={index}/>
            })
        }

    </>
}

export default VisualCreatives