import React, {useState, useEffect} from 'react';
import ReactDOMServer from "react-dom/server";
import {SelectBox} from "../../../components/SelectBox";
import {AlertStyledContent, Panel} from "../Main.style";
import {TableWrapper} from "./Testing.style";
import {AlertStyled} from "../../../../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hooks";
import {setNextPage, setOffer, setPrevPage} from "../../../create-offer.slice";
import {TestingLink} from "../../../components/TestingLink";
import Pagination from "../../../../../../../common/components/data-grid/pagination/Pagination";
import {Table} from "./Table";
import {ButtonStyled} from "../../../../../../../common/styled-components";
import {useCopy} from "../../../../../../../common/hooks";
import {CPE, CPI, CREATIVES, TRACKING_CODE} from "../../../interfaces/interfaces";
import {TESTING_DESCRIPTION_MOBILE, TESTING_DESCRIPTION_WEB} from "../../../shared-components/consts";

export interface ITestingPagination {
    current_page: number,
    from: number,
    to: number,
    total: number,
    last_page: number,
    perPage: number,
}

const Testing: React.FC = () => {
    const dispatch = useAppDispatch()
    const [tableData, setTableData] = useState<any[]>([])
    const [loadPrevious, setLoadPrevious] = useState(false)
    const [urlToCopy, setUrlToCopy] = useState('')
    const [pagination, setPagination] = useState<ITestingPagination>({
        current_page: 1,
        from:0,
        to:0,
        total:1,
        last_page: 1,
        perPage: 3,
    })
    const {
        offer
    } = useAppSelector(state => state.createOffer)

    const [copied, copy, setCopied] = useCopy(urlToCopy)

    useEffect(()=>{
        dispatch(setPrevPage("../" + TRACKING_CODE))
        dispatch(setNextPage("../"+ CREATIVES))

    },[])

    useEffect(() => {
        if(urlToCopy !== ''){
            copy()
        }
    }, [copy, urlToCopy])

    useEffect(() => {
        const last_page = Math.ceil(pagination.total / pagination.perPage)
        const from = (pagination.current_page - 1) * pagination.perPage + 1
        const to = (from + pagination.perPage) > pagination.total ? pagination.total : (from + pagination.perPage - 1)

        setPagination((prev) => {
            return {
                ...prev,
                last_page,
                from,
                to
            }
        })
    }, [pagination.perPage, pagination.current_page, pagination.total])
        return (
        <>
            <Panel>
                <div className={"title"}>testing</div>
                <div className={"description"}>Choose if you would like to test this campaign to ensure it tracks your
                    outcomes successfully.
                </div>
                <AlertStyled type={"info"} style={{marginBottom: 36}}>
                    <AlertStyledContent className={'info'}>
                        <i className={'ico-Information'}/>
                        As a marketer it is your responsibility to ensure an campaign has been tested and is tracking
                        properly.
                        Without testing to verify if an campaign is tracking properly, you may lose out on potential
                        outcomes.
                    </AlertStyledContent>
                </AlertStyled>
                <div style={{ marginBottom: 25}}>
                    <ol>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>
                                    Choose if you would like to test that your campaign is tracking properly
                                </div>
                                <div className={"list-item-select-box-container"}>
                                    <SelectBox active={offer.testing === "yes"} onClick={() =>
                                        dispatch(setOffer({testing: "yes"}))} disabled={false}>
                                        <h3 className="select-box-title">Yes, I want to test.</h3>
                                    </SelectBox>
                                    <SelectBox active={offer.testing === "no"} onClick={() =>
                                        dispatch(setOffer({testing: "no"}))} disabled={false}>
                                        <h3 className="select-box-title">No, l do not want to test.</h3>
                                    </SelectBox>
                                </div>
                            </div>
                        </li>
                        {offer.testing === "yes" && <li>
                            <div className="list-item">
                                <div className="list-item-label">
                                    {
                                        (offer.detailed_pricing_model === CPI ||
                                            offer.detailed_pricing_model === CPE ) ? TESTING_DESCRIPTION_MOBILE :
                                            TESTING_DESCRIPTION_WEB
                                    }
                                </div>
                                <div className={"list-item-content"}>
                                    <TestingLink/>
                                    <span className={'copy-icon-wrapper'}
                                          data-event={'click'}
                                          data-event-off={'click'}
                                          data-delay-hide={2000}
                                          data-html={true}
                                          data-place={"top"}
                                          data-tip={ReactDOMServer.renderToString(
                                              <div style={{
                                                  display: 'flex', alignItems: "center"
                                              }}><i className={"material-icons"}
                                                    style={{color: "#3FDE7F", fontSize: "13px", marginRight: "4px"}}>
                                                  check_circle
                                              </i> Copied!
                                              </div>
                                          )}
                                          onMouseDown={() => setUrlToCopy(offer.testing_link)}>
                                        <i className="ico-copy"/>
                                     </span>
                                </div>
                            </div>
                        </li>}
                    </ol>
                </div>
                {offer.testing === "yes" &&
                    <>
                        <div className={"pagination"}>
                            <h3>Test Results</h3>
                            <Pagination
                                current_page={pagination.current_page}
                                from={pagination.from}
                                to={pagination.to}
                                total={pagination.total}
                                setCurrentPage={(e) => setPagination(prev => ({...prev, current_page: e}))}
                                setPerPage={(e) => setPagination(prev => ({...prev, perPage: e}))}
                                last_page={pagination.last_page}
                                perPage={pagination.perPage}
                                loading={false}
                                perPageCustomList={[3, 50, 100, 250, 500]}
                            />
                        </div>
                        <TableWrapper  style={{
                            height: tableData.length === 0 ? '80px' : '',
                            overflow: tableData.length === 0 ? 'hidden' : ''
                        }}>
                            <Table tableData={tableData}
                                   setTableData={setTableData}
                                   pagination={pagination}
                                   setPagination={setPagination}
                                   offer={offer}
                                   loadPrevious={loadPrevious}/>
                        </TableWrapper>
                        {!loadPrevious &&
                            <ButtonStyled
                                onClick={() => {
                                    setLoadPrevious(true)
                                }}
                            >Load Previous Results</ButtonStyled>
                        }
                    </>
                }

            </Panel>
        </>
    );
};

export default Testing