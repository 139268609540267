import {FilterID, ICreativeTypeFilter} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../useAppDispatch";
import {CREATIVE_TYPES} from "../../../utils/consts";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";


export const useCreativeTypeCacheFilter = (pageId: IPagePresetsID) => {
    const [creativeType, setStatuses] = useState<ICreativeTypeFilter[]>(CREATIVE_TYPES)
    const [creativeTypeSearchValue, setCreativeTypeSearchValue] = useState('')

    const dispatch = useAppDispatch()

    const id : FilterID = 'creative-type'

    useEffect(() => {
        let filteredTypes = CREATIVE_TYPES.filter(type => (
                type.value.toLowerCase()).includes(creativeTypeSearchValue.toLowerCase())
            ||
            type.id.toString().includes(creativeTypeSearchValue.toLowerCase())
        )

        setStatuses(filteredTypes)


    }, [creativeTypeSearchValue])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: ICreativeTypeFilter) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }



    return {
        id: id,
        options: creativeType,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: 0,
        search: {
            value: creativeTypeSearchValue,
            setSearch: setCreativeTypeSearchValue
        },
        loading : false
    }
}