import styled from "styled-components";

export const PasswordLabel = styled.span`
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 16px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  margin-bottom: 21px;
  display: flex;
`
export const InputWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  padding-left: 0;
  margin-left: 0;
  display: block;
`
