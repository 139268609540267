import styled from 'styled-components'
import {ModalFooter} from "../../../../advertisers-edit/transparency/add-modal/AddModal.style";

export const Wrapper = styled.div``

export const DeleteDomainModalBody = styled.div`
    min-width: 420px;
    max-width: 420px;
    p {
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`

export const CustomModalFooter = styled(ModalFooter)`
    justify-content: flex-end;
    border-top: none;
`