import styled from 'styled-components'

export const OverviewContainer = styled.div`
    margin-top: 24px;
    display: flex;
    gap: 24px;
    @media (max-width: 1280px) {
        flex-direction: column;
        flex-basis: 100%;
    }
`

export const OverviewColLeft = styled.div`
    max-width: 705px;
`

export const OverviewColRight = styled.div`
    flex-basis: 100%;
`
export const WidgetHolder = styled.div`
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    padding: 32px;
`

