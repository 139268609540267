import {useState, useCallback} from 'react'

export const useTableSort = (initialKey : string, initialDirection: 'asc' | 'desc') => {
    const [sortBy, setSortBy] = useState(initialKey)
    const [sortDirection, setSortDirection] = useState(initialDirection)

    const toggleSortDirection = useCallback((key: string) => {
        if(key !== sortBy){
            setSortBy(key)
            setSortDirection(initialDirection)
        } else {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
        }
    }, [initialDirection, sortBy, sortDirection])

    const params: any[] = [
        sortBy,
        sortDirection,
        toggleSortDirection
    ]


    return params;
}