import React from "react";

import {Wrapper, CloseButton, Title, Icon} from "./AlertModalContainer.style";
import {AlertModalType} from "../alertModal.props";
import {RocketIcon} from "../../../../assets/svgs/SvgIcons";

interface IAlertModalContainerProps {
    type: AlertModalType,
    onClose: () => void,
    iconClassName?: string,
    title?: string,
    titleClassName?: string
    closeButtonClassName?:string
}

const AlertModalContainer: React.FC<IAlertModalContainerProps> = React.forwardRef<HTMLDivElement, IAlertModalContainerProps>(
    (
        {
            type,
            onClose,
            iconClassName,
            title,
            closeButtonClassName,
            titleClassName,
            children
        }
        ) => {
    return <Wrapper>
        <Icon
            type={type}
            className={iconClassName}
        >
            {type === 'success' ?
                <i className={'ico-Check'}/> : type === 'error' ?
                    <i className={'ico-Exit-outlined'}/> :
                    type === 'success-rocket' ? <RocketIcon></RocketIcon> :
                    <i className={'ico-Information-outlined'} />
            }
        </Icon>
        <Title
            className={titleClassName}
            type={type}
        >
            {title ? <>{title}</> : <>
                {type === 'success' ?
                    'Success!' :
                    type === 'error' ?
                        'Failure!' :
                        type === 'success-rocket' ? "Success!":
                        'Almost Done!'
                }
            </>}
        </Title>
        {children}
        <CloseButton
            onClick={() => onClose()}
            className={'btn-orange-filled ' + closeButtonClassName}
        >
            {type === 'success' ? 'Done' : 'Close'}
        </CloseButton>
    </Wrapper>
})

export default AlertModalContainer