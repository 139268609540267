import { IFetchAffiliatesResponse } from './../../advertisers-edit/billing/Billing.service';
import { IUser } from './../../advertisers-edit/AdvertisersEdit.service';
import {api} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";
import qs from "qs";


export interface IPartnerTransactionsResponse {
    id: number;
    user_id: number;
    date: string;
    type: number;
    credit?: number;
    debit?: number;
    note: string;
    created_at: string;
    updated_at: string;
    currency_type?: number;
    date_formated: string;
    type_name: string;
    user: IUser;
}

class PartnerTransactionseService {

    static fetchData = async (token: string, params?: {
        paginate_by?: number,
        sort_by?: string,
        order?: "desc" | "asc",
        page?: number,
        from?: string,
        to?: string
    }) => {        
        return await api.get<IPagination<IPartnerTransactionsResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'affiliate',
                'transactions?include=user.executive%2Cuser.network'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params,
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            }
        )
    }

    static exportToCsv = (params: {
        paginate_by?: number,
        sort_by?: string,
        order?: "desc" | "asc",
        page?: number,
        from?: string,
        to?: string
        }
    ) => {    
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            'affiliate',
            'transactions?include=user.executive%2Cuser.network'
        ].join('/') + "&" + qs.stringify(params) + "&" + "action=export_csv", "_blank");
    }

    static fetchAffiliates = async (token: string) => {
        return await api.post<IFetchAffiliatesResponse[]>([process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                'affiliates',
            ].join("/"),
            {
                c: 1
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static addTransaction = async (token: string, params?: {
        date: string,
        amount: string,
        credit: string,
        action: string,
        note?: string,
        type: number | null,
        user_id?: number
    }) => {        
        return await api.post<IPagination<IPartnerTransactionsResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'affiliate',
                'transactions'
            ].join('/'),
            
                params
            ,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
            },
           
       
        )
    }
}

export default PartnerTransactionseService;
