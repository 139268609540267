import React, {useState} from "react";
import {ActionHoverNotShown, SelectedRowsCount, SelectedRowsItem, Wrapper} from "./Actions.style";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";

interface ITooltipProps {
    id: number,
    name?: string,
    lastname?: string,
    displayName?: string,
}

interface Props {
    selectedRows: ITooltipProps[],
}


const Actions: React.FC<Props> = ({selectedRows, children}) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <Wrapper>
            <Dropdown
                headerClassName={open ? 'actions-toggle-button active' : 'actions-toggle-button'}
                disabled={selectedRows.length === 0}
                text={<div>
                    Actions
                    {selectedRows.length > 0 && (<Tooltip
                        positionLeft={110}
                        width={180}
                        position={'bottom'}
                        icon={
                            <SelectedRowsCount> {selectedRows.length > 99 ? "99+" : selectedRows.length}
                                <i className={'ico-Check'}/>
                            </SelectedRowsCount>
                        }>
                        {selectedRows.slice(0, 5).map(row => {
                            return (
                                <SelectedRowsItem key={row.id}>
                                    {row.id + ' -'} {row.displayName}
                                </SelectedRowsItem>
                            )
                        })}
                        {
                            selectedRows.length > 5 &&
                            <ActionHoverNotShown>
                                {selectedRows.length - 5} not shown
                            </ActionHoverNotShown>
                        }

                    </Tooltip>)}
                </div>}
                isOpened={open}
                setOpened={setOpen}>
                {children}
            </Dropdown>
        </Wrapper>
    )
}

export default Actions