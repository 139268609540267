import {useEffect, useState} from "react";
import {IFilter, IPagePresetsID} from "../../../store/userPreferences.slice";
import {useAppSelector} from "../useAppSelector";
import {FilterID} from "filters";

export const useFilterCacheSelector = ({pageId, filterId}: {pageId: IPagePresetsID, filterId: FilterID}) => {
    const [defaultFilter, setDefaultFilter] = useState<IFilter>({
        selected: [],
        match: 'any',
        include: true
    })

    const preferences = useAppSelector(state => state.userPreferences[pageId])

    useEffect(() => {
        if(preferences.filters && preferences.filters[filterId]){
            setDefaultFilter(preferences.filters[filterId])
        }
    }, [preferences, filterId, pageId])

    return defaultFilter
}