import React from 'react'

import {Wrapper, TileContent, RevenueRow, LoadingOverlay} from './RevenueAndProfits.style'
import {formatNumberToCurrency} from "../../../../../../common/utils";
import Loading from "../../../../../../common/components/loading/Loading";
import {IFetchDashboardStats} from "../NetworkView.service";

interface Props {
    data: IFetchDashboardStats,
    loading: boolean
}

const RevenueAndProfits: React.FC<Props> = ({data, loading}) => {
    return <Wrapper>
        {loading && <LoadingOverlay><Loading/></LoadingOverlay>}
        <h3 className={'title'}>Revenue & Profits</h3>
        <RevenueRow>
            <TileContent>
                <h2 className="cell-title">Revenue Today</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.revenue_td)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Revenue Yesterday</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.revenue_yestd)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Revenue MTD</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.revenue_mtd)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Revenue YTD</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.revenue_ytd)}</h2>
            </TileContent>
        </RevenueRow>
        <RevenueRow>
            <TileContent>
                <h2 className="cell-title">Profit Today</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.profit_td)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Profit Yesterday</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.profit_yestd)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Profit MTD</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.profit_mtd)}</h2>
            </TileContent>
            <TileContent>
                <h2 className="cell-title">Profit YTD</h2>
                <h2 className={'cell-value'}>{formatNumberToCurrency(data.profit_ytd)}</h2>
            </TileContent>
        </RevenueRow>
    </Wrapper>
}

export default RevenueAndProfits