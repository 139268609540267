import React, {useState} from 'react'
import {Wrapper, DeleteDomainModalBody, CustomModalFooter} from './RemoveAllTagsModal.style'
import {ButtonStyled} from "../../../../../../../common/styled-components";
import {BtnLoadingSpinner} from "../../../../../../../common/styled-components";


interface Props {
    closeModal: () => void,
    deleteAction: () => void,
    opened: boolean,
    setAlert: (params: {
        opened: boolean,
        message: string,
        type: 'success' | 'error'
               }
    ) => void,
    fetchData: (direction?: string | null, sort_by?: string | null, selectNodes? : boolean  ) => void,
    deselectRows: ()=> void,
}

const RemoveAllTagsModal: React.FC<Props> = ({closeModal,
                                                 deleteAction,
                                                 opened,
                                                 setAlert,
                                                 fetchData,
                                                 deselectRows}) => {
    const [loading, setLoading] = useState(false)
    const handleDelete = async () => {
        deselectRows()
        try {
            setLoading(true)
            await deleteAction()
            setLoading(false)
            setAlert({
                opened: true,
                message: 'Tags successfully deleted!',
                type: 'success'
            })
        } catch (e) {
            setLoading(false)
            setAlert({
                opened: true,
                message: 'Something went wrong!',
                type: 'error'
            })
        }
        fetchData(null, null, false)
        closeModal()

    }
    return <Wrapper>
            <DeleteDomainModalBody>
                <p>Are you sure you want to remove all tags from selected offers?</p>
                <br/>
                <p>This action cannot be undone.</p>
            </DeleteDomainModalBody>
            <CustomModalFooter>
                <ButtonStyled
                    className={'btn-cancel'}
                    onClick={closeModal}
                    >
                    Cancel
                </ButtonStyled>
                <ButtonStyled
                    className={'btn-red-filled'}
                    onClick={handleDelete}
                    style={{marginLeft: 30}}
                    disabled={loading}
                >
                    <span>{loading && <BtnLoadingSpinner/>}Yes, remove tags.</span>

                </ButtonStyled>
            </CustomModalFooter>
    </Wrapper>
}

export default RemoveAllTagsModal