import {api} from "../../../../../common/services/api";
import {IPagination} from "../../../../../common/models/pagination.type";
import qs from 'qs'
import {CancelTokenSource} from "axios";
export interface DISABLED {
    name: string;
    disable_budget: boolean;
    metis_value: number;
}

export interface DAILY {
    name: string;
    disable_budget: boolean;
    metis_value: number;
}

export interface WEEKLY {
    name: string;
    disable_budget: boolean;
    metis_value: number;
}

export interface MONTHLY {
    name: string;
    disable_budget: boolean;
    metis_value: number;
}

export interface LinkTestBudgetDetails {
    DISABLED: DISABLED;
    DAILY: DAILY;
    WEEKLY: WEEKLY;
    MONTHLY: MONTHLY;
}

export interface ClickTestCapBudget {
    type?: any;
    value?: any;
}

export interface ITag {
    id: number;
    name: string;
    tps_id: number;
    property_id: number;
}

export interface OtherStatuses {
    id: number;
    campaign_id: number;
    created_at: string;
    updated_at: string;
    ended: boolean;
    adv_scheduling: number;
    manually_paused: number;
    budget_exceeded: boolean;
    from?: any;
    until?: any;
    test_budget?: any;
    paused_internal: boolean;
    paused_user: boolean;
    paused_schedule: boolean;
    paused_low_bid: boolean;
    paused_no_balance: boolean;
    paused_daily_spike_reached: boolean;
    inactive_denied: boolean;
    last_field_updated?: any;
    status: string;
    active_negative_balance: boolean;
    default_status: string;
    budget_group_id?: number;
    manually_changed?: any;
    paused_click_cap: boolean;
    click_cap_until?: any;
    detailed: string;
}

export interface IFetchCampaignsResponse {
    id: number;
    user_id: number;
    niche_id: number;
    name: string;
    subtype: string;
    status: string;
    created_at: string;
    pricing_model: string;
    tracking_system: string;
    detailed_pricing_model?: any;
    is_default_pricing_model: number;
    pricing_type: string;
    budget: number;
    budget_type: string;
    budget_value_type: number;
    cap_type?: any;
    cap_value?: any;
    cap_interval?: any;
    preview_url?: string;
    destination_url: string;
    archived: boolean;
    campaign_creatives_count: number;
    upsells_count: number;
    network_id: number;
    company: string;
    user_name: string;
    user_lastname: string;
    network: string;
    executive_id: number;
    executive_name: string;
    executive_lastname: string;
    niche_name: string;
    cid: number;
    media_operator?: number;
    cid_rep: number;
    cid_media: number;
    cid_compliance: number;
    reports_clicks?: any;
    reports_impressions?: any;
    reports_return?: any;
    reports_profit?: any;
    reports_margin?: number;
    long_name: string;
    link_test_budget_type_name: string;
    link_test_budget_details: LinkTestBudgetDetails;
    click_test_cap_budget: ClickTestCapBudget;
    tags: ITag[];
    other_statuses: OtherStatuses;
    creative: string
}

export interface IFetchCampaignsParams {
    with_upsells?: boolean,
    requested_by_page?: string,
    sort_by?: string,
    order?: string,
    rep?: number,
    media?: number,
    compliance?: number,
    status?: any,
    country?: any,
    media_rep?: any
    from?: string | Date
    to?: string | Date
    original_match?: number,
    search?: string,
    paginate_by?: number,
    page?: number,
    account_manager?: any,
    sales_executives?: any,
    compliance_rep?: any,
    device?: any,
    operating_system?: any
    creative_type?: any
    niche?: any,
    niche_group?: any
    tags?: any
    offer_visibility?: any,
    offer_type?: any,
    other_statuses?: any
    advertiser_id?: any
    offerTypesInclude?: boolean
    detailed_campaign_type?: any
    tracking_systems?: any
}

export interface IFetchAllTagsResponse {
    id: number,
    name: string
}

export interface IAddTagsParams {
    property_ids: number[],
    type: string,
    tag_ids: number[]
}

interface ITps {
    property_id: number,
    tps_id: number,
    id: number,
    name: string,
    is_selected?: boolean
}

interface IDeleteTagsParams {
    tps: ITps[]
}

export interface IMap {
    [key: string]: IFetchCampaignsResponse[]
}

class OffersService {
    static campaignSectorApprove = async (offers: number[], sector: 'rep' | 'media' | 'compliance', token: string) => {
        return await api.post([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            'campaigns-sector-approve'
        ].join('/'),
            {
                sector,
                direction: 'approve',
                campaigns: offers
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                }
            }
        )
    }

    static fetchCampaigns = async (params: IFetchCampaignsParams, token: string, cancelToken? : CancelTokenSource) => {
        return await api.get<IPagination<IFetchCampaignsResponse>>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V3_URL,
            'campaigns'
        ].join('/'),
            {
                params,
                paramsSerializer: params => {
                    return qs.stringify(params);
                },
                headers: {
                    'Authorization' : `Bearer ${token}`,
                },
                cancelToken: cancelToken?.token
            },

            )
    }

    static fetchAllTags = async (token: string) => {
        return await api.post<IFetchAllTagsResponse[]>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_TAGS
            ].join('/'),
            null,
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                }
            }
        )
    }

    static addTags = async (token: string, params: IAddTagsParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ASSIGN_TAGS
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static deleteTags = async (token: string, params: IDeleteTagsParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ASSIGN_TAGS,
                process.env.REACT_APP_API_DELETE_TAGS
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
}

export default OffersService