import styled, {css} from "styled-components";

interface IMoreIconProps {
    active: boolean,
    disabled?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
 
`

export const MoreIcon = styled.div<IMoreIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 15px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  border-radius: 8px;
  
  .ico-More{
    transform: translateX(1px);
  }
  :hover{
    ${({disabled}) => !disabled && css`
      cursor: pointer;
    `};
    color: ${({theme}) => theme.colors.deepBlue['4']};
    background-color: ${({theme}) => theme.colors.coolGray['2']};
    transition: all .2s ease-in-out;
  }
    :active{
        background-color: ${({theme}) => theme.colors.deepBlue['2']};
        color: ${({theme}) => theme.colors.deepBlue['6']};
    }    
    &.active{
        background-color: ${({theme}) => theme.colors.deepBlue['2']};
        color: ${({theme}) => theme.colors.deepBlue['6']};
    }
    
  ${props => props.disabled && css`
    pointer-events: none;
    background: #e8e8e8;
  `}

`
