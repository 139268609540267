import React, {useState} from "react";
import {ActionHoverNotShown, SelectedRowsCount, Wrapper, SelectedRowsItem} from "./Actions.style";
import Dropdown from "../dropdown/Dropdown";
import Tooltip from "../tooltip/Tooltip";
import {PLATFORMS}  from "../../../modules/admin/pages/partners/partner_merging/Merging.service";

interface ITooltipProps {
    id: number,
    name?: string,
    lastname?: string,
    displayName?: string,
    platform_partner_company?: string,
    non_merged?: boolean,
    platform_id?: number
}

interface Props {
    selectedRows: ITooltipProps[],
}


const Actions: React.FC<Props> = ({selectedRows, children}) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <Wrapper>
            <Dropdown
                headerClassName={'actions-toggle-button'}
                disabled={selectedRows.length === 0}
                text={<div>
                Actions
                {selectedRows.length > 0 && (<Tooltip
                    positionLeft={110}
                    width={180}
                    position={'bottom'}
                    icon={<SelectedRowsCount>{selectedRows.length > 99 ? "99+" : selectedRows.length}<i className={'ico-Check'}/></SelectedRowsCount>}>
                    {selectedRows.slice(0, 5).map(row => {
                        let idPlatform: number = row.platform_id ? row.platform_id : 0
                        return (
                            <SelectedRowsItem key={row.id}>
                                {row.non_merged && row.id + ' -'} {row.displayName ? row.displayName : row.platform_partner_company} {row.non_merged && PLATFORMS[idPlatform]}
                            </SelectedRowsItem>
                        )
                    })}
                    {
                        selectedRows.length > 5 &&<ActionHoverNotShown>{selectedRows.length - 5} not shown</ActionHoverNotShown>
                    }

                </Tooltip>)}
            </div>}
                      isOpened={open}
                      setOpened={setOpen}>
                {children}
            </Dropdown>
        </Wrapper>
    )
}

export default Actions
