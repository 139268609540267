import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'

import {Wrapper} from "./AddEditPaymentModal.style";
import PartnerPaymentsService, {
    IEditAddPaymentParams,
    IFetchAffiliatesResponse,
    IFetchPartnerPaymentsResponse
} from "../PartnerPayment.service";
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import DatePicker from "../../../../../../common/components/date-picker/DatePicker";
import Input from "../../../../../../common/components/input/Input";
import {ModalFooter} from "../../../../../../common/components/modal/container/Container.style";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../../common/styled-components";
import {useAppSelector} from "../../../../../../common/hooks";
import {formatNumberToCurrency} from "../../../../../../common/utils";

interface Props {
    affiliates: IFetchAffiliatesResponse[],
    payment: null | IFetchPartnerPaymentsResponse,
    fetchData: () => void
    alertModal: (alert: {
        message: string,
        type: 'error' | 'success',
        opened: boolean
    }) => void
    editModal: (modal: {
        opened: boolean,
        data: null | IFetchPartnerPaymentsResponse
    }) => void
}

const AddEditPaymentModal: React.FC<Props> = ({affiliates, payment, alertModal, fetchData, editModal}) => {
    const {token} = useAppSelector(state => state.auth)
    const [affiliatesDropdownOpened, setAffiliatesDropdownOpened] = useState(false)
    const [affiliatesSearchValue, setAffiliatesSearchValue] = useState('')
    const [filteredAffiliates, setFilteredAffiliates] = useState<IFetchAffiliatesResponse[]>([])
    const [loading, setLoading] = useState(false)

    const [selectedAffiliate, setSelectedAffiliate] = useState<IFetchAffiliatesResponse>()

    const [paymentDate, setPaymentDate] = useState<Date | null | string | undefined>()
    const [startDate, setStartDate] = useState<Date | null | string | undefined>()
    const [endDate, setEndDate] = useState<Date | null | string | undefined>()
    const [noteValue, setNoteValue] = useState('')
    const [amountValue, setAmountValue] = useState('')

    const handleAffiliateSelect = (affiliate: IFetchAffiliatesResponse) => {
        setSelectedAffiliate(affiliate)
        setAffiliatesDropdownOpened(false)
    }

    useEffect(() => {
        setFilteredAffiliates(affiliates)

        if(payment){
            setEndDate(payment.end_date)
            setStartDate(payment.start_date)
            setPaymentDate(payment.paid_at)
            setSelectedAffiliate(affiliates.find(affiliate => affiliate.id === payment.affiliate_id))
            setNoteValue(payment.note)
            setAmountValue(formatNumberToCurrency(parseFloat(payment.amount.replace(/[^0-9.]+/g, ''))))
        }

    }, [affiliates, payment])

    const filterAffiliates = useCallback(() => {
        setFilteredAffiliates(affiliates.filter(affiliate => {
            return (affiliate.name.toLowerCase() + affiliate.lastname.toLowerCase()).includes(affiliatesSearchValue.toLowerCase()) ||
                affiliate.id.toString().includes(affiliatesSearchValue.toLowerCase())
        }))
    }, [affiliatesSearchValue, affiliates])

    useEffect(() => {
        filterAffiliates()
    }, [affiliatesSearchValue])

    const addEditPayment = async () => {
        let params: IEditAddPaymentParams = {
            amount: parseFloat(amountValue.replace('$', '').replace(/,/g, '')),
            note: noteValue,
        }

        if(startDate && endDate && paymentDate){
            params = {
                ...params,
                start_date: startDate.toString().replace(/\//g, '-'),
                end_date: endDate.toString().replace(/\//g, '-'),
                paid_at: paymentDate.toString().replace(/\//g, '-'),
            }
        }

        if(selectedAffiliate){
            params.affiliate_id = selectedAffiliate.id
        }

        if(payment) {
            params = {...payment, amount: parseFloat(payment.amount) ,...params}
        }

        try{
            setLoading(true)
            await PartnerPaymentsService.editAddPayment(token, params)
            alertModal({
                message: payment ? 'Payment successfully edited!' : 'Payment successfully added!',
                opened: true,
                type: "success"
            })
            editModal({
                opened: false,
                data: null
            })
            fetchData()
        } catch (e) {
            alertModal({
                message: 'Something went wrong!',
                opened: true,
                type: "error"
            })
            editModal({
                opened: false,
                data: null
            })
            console.log(e)
        }
    }

    const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid){
            setAmountValue(e.target.value)
        }
    }

    const handleAmountBlur = () => {
        setAmountValue(formatNumberToCurrency(parseFloat(amountValue.replace(/[^0-9.]+/g, ''))))
    }

    const checkIfSaveDisabled = () => {
        return amountValue === '' || !startDate || !paymentDate || !endDate || !selectedAffiliate || loading
    }

    return <Wrapper>
        <Dropdown
            search={
                {
                    value: affiliatesSearchValue,
                    onChange: setAffiliatesSearchValue,
                    placeholder: 'Search...'
                }
            }
            text={selectedAffiliate ? `(${selectedAffiliate.id}) ${selectedAffiliate.name} ${selectedAffiliate.lastname}` : 'Select Partner'}
            isOpened={affiliatesDropdownOpened}
            setOpened={setAffiliatesDropdownOpened}
            label={'Partner'}
            dropdownHeightLimit={7}
            itemsNotDisplayed={filteredAffiliates.length - 50}
        >
            {filteredAffiliates.slice(0, 50).map(affiliate => {
                return <DropdownItem
                    selected={selectedAffiliate?.id === affiliate.id}
                    key={affiliate.id}
                    onClick={() => handleAffiliateSelect(affiliate)}
                >
                    ({affiliate.id}) - {affiliate.name} {affiliate.lastname} - {affiliate.company}
                </DropdownItem>
            })}
        </Dropdown>
        <DatePicker
            label={'Payment Date'}
            date={paymentDate?.toString().replaceAll('-', '/')}
            setDate={setPaymentDate}
            inputWidth={'100%'}

        />
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '40px'
        }}>
            <DatePicker
                label={'Start Date'}
                date={startDate?.toString().replaceAll('-', '/')}
                setDate={setStartDate}
                inputWidth={'250px'}
            />
            <DatePicker
                label={'End Date'}
                date={endDate?.toString().replaceAll('-', '/')}
                setDate={setEndDate}
                inputWidth={'250px'}
            />
        </div>

        <Input
            pattern={"[0-9, $, .]*"}
            placeholder={'$0.00'}
            value={amountValue}
            onChange={(e) => handleAmountChange(e)}
            onBlur={handleAmountBlur}
            label={'Amount'}
            wrapperClassName={'amount-input-wrapper'}
        />
        <Input value={noteValue} onChange={(e) => setNoteValue(e.target.value)} label={'Note'} wrapperClassName={'amount-input-wrapper'} />
        <ModalFooter style={{
            marginTop: 0
        }}>
            <ButtonStyled className={'btn-cancel'}>Cancel</ButtonStyled>
            <ButtonStyled disabled={checkIfSaveDisabled()} className={'btn-orange-filled'} onClick={addEditPayment}>{loading && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}{payment ? 'Save Changes' : 'Create'}</ButtonStyled>
        </ModalFooter>
    </Wrapper>
}

export default AddEditPaymentModal