import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";
import TextArea from "../../../../../common/components/textarea/TextArea";
import {useFeatureFlags} from "../../../../../common/hooks";

export const TestingLink : React.FC = () => {
    const {
        offer
    } = useAppSelector(state => state.createOffer)
    
    return (
        <>
            <a href={offer.testing_link} target={"_blank"} rel={"noreferrer"} style={{display: 'block', width: '100%', marginRight: '8px'}}>
                <TextArea
                    label={<>Testing Link</>}
                    wrapperClassName={'content-editable custom-scrollbar'}
                    className={'text-area-content-editable custom-scrollbar testing'}
                    style={{
                        resize: 'none'
                    }}
                    rows={1}
                    defaultValue={offer.testing_link}
                />
            </a>
            {/*<ContentEditable*/}
            {/*    label={"Testing Link"}*/}
            {/*    contentEditable={false}>*/}
            {/*    <a href={offer.testing_link} target={"_blank"} rel={"noreferrer"}>*/}
            {/*        {offer.testing_link}*/}
            {/*    </a>*/}
            {/*</ContentEditable>*/}
        </>
    );
};