import React, {useEffect} from 'react';
import {Panel} from "../Main.style";
// import {Footer} from "../footer/Footer";
import {OfferBudget} from "../../../components/OfferBudget";
import {Expandable} from "../../../components/Expandable";
import OfferBudgetGroup from "../../../components/OfferBudgetGroup";
import OfferBudgetTestGroup from "../../../components/OfferBudgetTestGroup";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";
import {setNextPage, setPrevPage} from "../../../create-offer.slice";
import {CREATIVES, TARGETING} from "../../../interfaces/interfaces";
import BudgetTypeChangeWarning from "./budget-type-change-warning/BudgetTypeChangeWarning";

const Budget : React.FC = () => {
    const { offer } = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    useEffect(()=>{
        dispatch(setPrevPage("../" + CREATIVES))
        dispatch(setNextPage("../" + TARGETING))
    },[])

    const budgetGroupsFlag = useFeatureFlags('offer-budget-groups')

    return (
        <>
            <Panel>
                <div className={"title"}>budget</div>
                <div className={"description"}>Define the amount you would like to spend on this campaign, by default an
                    campaign will be set to unlimited and run until your funds are depleted.
                </div>
                <OfferBudget/>
                {offer.budget_type !== 'unlimited' && <BudgetTypeChangeWarning/>}
                <Expandable title={"Advanced Budget"} locked={false}>
                    <div className={"description"}>
                        A budget group allows you to set a shared budget across all campaigns within it.
                        The campaign specific budget will never be exceeded, even if it is assigned to a group with a
                        higher shared budget. <br/> <br/>
                        A test budget allows you to choose the amount you would like to spend to test new links for
                        your campaign. Your overall campaign budget will override your test budget.
                        For example if your test budget is $1,000 and your campaign budget is $5,000 you will only test a
                        maximum of 5 link IDs. <strong>
                        You are responsible for removing the test budget parameters for each
                        link ID after you have assessed the data. </strong>
                    </div>
                    {budgetGroupsFlag && <OfferBudgetGroup/>}
                    {budgetGroupsFlag && <OfferBudgetTestGroup/>}
                </Expandable>
            </Panel>
            {/*<Footer disabled={false} onNext={handleClickNext} showNext={offer.detailed_pricing_model !== 'ppc'}/>*/}
        </>
    );
};

export default Budget