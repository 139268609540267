import React, { useRef, useState } from 'react'
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../common/hooks";
import { RatingStar, RatingStarFiled } from '../leap-partners-table/LeapPartnersTable.style';
import { FilterID } from 'filters';
import Checkbox from '../../../../../../common/components/checkbox/Checkbox';
import { removeAllFilters, singleSelect } from '../../../../../../store/filters.slice';
import {useAccountManagerCacheFilter, useTagsCacheFilter} from "../../../../../../common/hooks/filters/cache-filters";
import Filter from "../../../../../../common/components/filters/filter/Filter";
import {
    usePartnerStatusMultiselectCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerStatusMultiselectCacheFilter";
import {useReferrerCacheFilter} from "../../../../../../common/hooks/filters/cache-filters/useReferrerCacheFilter";
import {
    usePartnerTypeCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerTypeCacheFilter";
import {
    usePartnerRegistrationStatusCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerRegistrationStatusCacheFilter";
import {
    usePartnerRatingCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerRatingCacheFilter";
import FilterSelect from "../../../../../../common/components/filters/filter/filter-select/FilterSelect";
import {
    usePartnerPaymentStatusCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerPaymentStatusCacheFilter";
import {useApprovedW8CacheFilter} from "../../../../../../common/hooks/filters/cache-filters/useApprovedW8CacheFilter";
import {
    usePaymentPreferenceCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePaymentPreferenceCacheFilter";
import {
    usePartnerPaymentCycleCacheFilter
} from "../../../../../../common/hooks/filters/cache-filters/usePartnerPaymentCycleCacheFilter";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'account-manager',
        value: 'Partner Account Managers'
    },
    {
        id: 'partner-status-multiselect',
        value:"Partner Status"
    },
    {
        id: 'referrer',
        value:"Referrer"
    },
    {
        id: 'partner-type',
        value: "Partner Type"
    },
    {
        id: 'partner-registration-status',
        value: 'Registration Status'
    },
    {
        id: 'partner-ratings',
        value:"Partner Rating"
    },
    {
        id: 'partner-payment-status',
        value: 'Payment Status'
    },
    {
        id: 'approved-w8-w9',
        value:"Approve W8/W9"
    },
    {
        id: 'payment-preference',
        value: "Payment Preference"
    },
    {
        id: 'partner-payment-cycle',
        value:"Payment Cycle"
    },
    {
        id: 'tags',
        value:"Partner Tags"
    }
]

const Filters: React.FC<Props> = (
    {
        setDiscoverDisplayed
    }
) => {

    const pageId = 'admin/users/partners'
    const {filters} = useAppSelector((state) => state.userPreferences[pageId])

    const {token} = useAppSelector(state => state.auth)
    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    const accountManagerCacheFilter = useAccountManagerCacheFilter(pageId, 50);
    const referrerCacheFilter = useReferrerCacheFilter(pageId, 50);
    const tagsCacheFilter = useTagsCacheFilter(pageId, 50);

    const partnerTypeCacheFilter = usePartnerTypeCacheFilter(pageId);
    const partnerRatingCacheFilter = usePartnerRatingCacheFilter(pageId)
    const paymentPreferenceCacheFilter = usePaymentPreferenceCacheFilter(pageId);
    const partnerStatusCacheFilter = usePartnerStatusMultiselectCacheFilter(pageId);
    const registrationStatusCacheFilter = usePartnerRegistrationStatusCacheFilter(pageId);
    const partnerPaymentStatusCacheFilter = usePartnerPaymentStatusCacheFilter(pageId);
    const partnerPaymentCycleCacheFilter = usePartnerPaymentCycleCacheFilter(pageId);
    const approwedW8CacheFilter = useApprovedW8CacheFilter(pageId);

    const dispatch = useAppDispatch();

    const ratingData = (id: number) => {
            // const stars: number[] = [0, 0, 0]
            if(id === 1) {
                return <div>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                        <RatingStar className="material-symbols-outlined">star</RatingStar>
                        <RatingStar className="material-symbols-outlined">star</RatingStar>
                    </div>
            } else if (id === 2) {
                return <div>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                        <RatingStar className="material-symbols-outlined">star</RatingStar>
                    </div>
            } else {
                return  <div>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                        <RatingStarFiled className="material-symbols-outlined">star</RatingStarFiled>
                    </div>
            }
    }

    const ratingTextHandler = (id: number) => {
        if(id === 1){
            return "One Star"
        }else
        if(id === 2) {
            return "Two Star"
        } else {
            return "Three Star"
        };
    }

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "account-manager":
                return (
                    <Filter
                        pageId={pageId}
                        filterId={accountManagerCacheFilter.id}
                        notDisplayed={accountManagerCacheFilter.notDisplayed}
                        selected={accountManagerCacheFilter.selected.value}
                        filterTitle={'Partner Account Manager'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            accountManagerCacheFilter.selected.value[0] ?
                                accountManagerCacheFilter.selected.value[0].name + ' ' + accountManagerCacheFilter.selected.value[0].lastname :
                                ''}
                        include={accountManagerCacheFilter.include}
                        search={accountManagerCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            accountManagerCacheFilter.selected.value.length > 1 && (<>
                                {accountManagerCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.id} - {item.name} {item.lastname}</div>
                                })}
                            </>)
                        }
                    >
                        {accountManagerCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={accountManagerCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    accountManagerCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                )
            case 'partner-status-multiselect':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={partnerStatusCacheFilter.id}
                        notDisplayed={0}
                        selected={partnerStatusCacheFilter.selected.value}
                        filterTitle={'Partner Status'}
                        hasTopStickyContainer={true}
                        include={partnerStatusCacheFilter.include}
                        selectedFilterText={partnerStatusCacheFilter.selected.value[0] ? partnerStatusCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {partnerStatusCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.name}</div>
                                })}
                            </>
                        }
                    >
                        {partnerStatusCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={partnerStatusCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    partnerStatusCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                    )
            case 'referrer':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={referrerCacheFilter.id}
                        notDisplayed={referrerCacheFilter.notDisplayed}
                        selected={referrerCacheFilter.selected.value}
                        filterTitle={'Referrer'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            referrerCacheFilter.selected.value[0] ?
                                referrerCacheFilter.selected.value[0].name + ' ' + referrerCacheFilter.selected.value[0].lastname :
                                ''}
                        include={referrerCacheFilter.include}
                        search={referrerCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {referrerCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.id} - {item.name} {item.lastname}</div>
                                })}
                            </>
                        }
                    >
                        {referrerCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={referrerCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    referrerCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                    )
            case 'partner-type':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={partnerTypeCacheFilter.id}
                        notDisplayed={0}
                        selected={partnerTypeCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == partnerTypeCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        include={partnerTypeCacheFilter.include}
                        selectedFilterText={partnerTypeCacheFilter.selected.value[0] ? partnerTypeCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {partnerTypeCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.name}</div>
                                })}
                            </>
                        }
                    >
                        {partnerTypeCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.name}
                                checked={partnerTypeCacheFilter.selected.value.some(selected => option.name === selected.name)}
                                onChange={() => {
                                    partnerTypeCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                    )
            case 'partner-registration-status':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={registrationStatusCacheFilter.id}
                        notDisplayed={0}
                        selected={registrationStatusCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id==registrationStatusCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        include={registrationStatusCacheFilter.include}
                        selectedFilterText={registrationStatusCacheFilter.selected.value[0] ? registrationStatusCacheFilter.selected.value[0].value : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {registrationStatusCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.value}</div>
                                })}
                            </>
                        }
                    >
                        {registrationStatusCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.value}
                                checked={registrationStatusCacheFilter.selected.value.some(selected => option.value === selected.value)}
                                onChange={() => {
                                    registrationStatusCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        })}
                    </Filter>
                )
            case 'tags':
                return (
                    <Filter pageId={pageId}
                            match={tagsCacheFilter.match}
                            filterId={tagsCacheFilter.id}
                            selected={tagsCacheFilter.selected.value}
                            filterTitle={filterList.filter(filter => filter.id==tagsCacheFilter.id)[0].value}
                            selectedFilterText={tagsCacheFilter.selected.value[0] ?
                                tagsCacheFilter.selected.value[0].name : ''
                            }
                            notDisplayed={tagsCacheFilter.notDisplayed}
                            hasTopStickyContainer={true}
                            include={tagsCacheFilter.include}
                            search={tagsCacheFilter.search}
                            setDisplayDiscoverResults={setDiscoverDisplayed}
                            tooltip={
                                <>
                                    {tagsCacheFilter.selected.value.map(item => {
                                        return <div key={item.id}>{item.name}</div>
                                    })}
                                </>
                            }
                    >
                        {
                            tagsCacheFilter.options.map(option => {
                                return <Checkbox  wrapperClassName={'checkbox-filter-wrapper'}
                                                  key={option.id}
                                                  checked={tagsCacheFilter.selected.value.some(selected =>
                                                      option.id === selected.id)}
                                                  onChange={() => {
                                                      tagsCacheFilter.selected.setSelected(option)
                                                      setDiscoverDisplayed(true) } }
                                                  label={option.name}/>
                            })
                        }
                    </Filter>
                )
            case 'partner-ratings':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={partnerRatingCacheFilter.id}
                        notDisplayed={0}
                        selected={partnerRatingCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == partnerRatingCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            partnerRatingCacheFilter.selected.value[0] ?
                                ratingTextHandler(partnerRatingCacheFilter.selected.value[0].id) :
                                ''}
                        include={partnerRatingCacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {partnerRatingCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{ratingTextHandler(item.id)}</div>
                                })}
                            </>
                        }
                    >
                        {partnerRatingCacheFilter.options.map(rating =>  (
                             <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={rating.id}
                                checked={partnerRatingCacheFilter.selected.value.some(selected => rating.id === selected.id)}
                                onChange={() => {
                                    partnerRatingCacheFilter.selected.setSelected(rating)
                                    setDiscoverDisplayed(true)
                                }}
                                label={ratingData(rating.id)}
                            />
                        ))}
                    </Filter>
                )
            case 'partner-payment-status':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={partnerPaymentStatusCacheFilter.id}
                        notDisplayed={0}
                        selected={partnerPaymentStatusCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == partnerPaymentStatusCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            partnerPaymentStatusCacheFilter.selected.value[0] ?
                                partnerPaymentStatusCacheFilter.selected.value[0].value :
                                ''}
                        include={partnerPaymentStatusCacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {partnerPaymentStatusCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.value}</div>
                                })}
                            </>
                        }
                    >
                        {partnerPaymentStatusCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={partnerPaymentStatusCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    partnerPaymentStatusCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        })}
                    </Filter>
                    )
            case 'approved-w8-w9':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={approwedW8CacheFilter.id}
                        notDisplayed={0}
                        selected={approwedW8CacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == approwedW8CacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            approwedW8CacheFilter.selected.value[0] ?
                                approwedW8CacheFilter.selected.value[0].value :
                                ''}
                        include={approwedW8CacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {approwedW8CacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.value}</div>
                                })}
                            </>
                        }
                    >
                        {approwedW8CacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={approwedW8CacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    approwedW8CacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        })}
                    </Filter>
                    )
            case 'payment-preference':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={paymentPreferenceCacheFilter.id}
                        notDisplayed={0}
                        selected={paymentPreferenceCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == paymentPreferenceCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            paymentPreferenceCacheFilter.selected.value[0] ?
                                paymentPreferenceCacheFilter.selected.value[0].value :
                                ''}
                        include={paymentPreferenceCacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {paymentPreferenceCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.value}</div>
                                })}
                            </>
                        }
                    >
                        {paymentPreferenceCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={paymentPreferenceCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    paymentPreferenceCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        })}
                    </Filter>
                    )
            case 'partner-payment-cycle':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={partnerPaymentCycleCacheFilter.id}
                        notDisplayed={0}
                        selected={partnerPaymentCycleCacheFilter.selected.value}
                        filterTitle={filterList.filter(filter => filter.id == partnerPaymentCycleCacheFilter.id)[0].value}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            partnerPaymentCycleCacheFilter.selected.value[0] ?
                                partnerPaymentCycleCacheFilter.selected.value[0].value :
                                ''}
                        include={partnerPaymentCycleCacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                        tooltip={
                            <>
                                {partnerPaymentCycleCacheFilter.selected.value.map(item => {
                                    return <div key={item.id}>{item.value}</div>
                                })}
                            </>
                        }
                    >
                        {partnerPaymentCycleCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={partnerPaymentCycleCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    partnerPaymentCycleCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.value}
                            />
                        })}
                    </Filter>
                    )
            default:
                return <></>
        }

    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/users/partners'))
        }
    }

    return (
        <>
                <FilterSelect allFilters={filterList} pageId={pageId}>
                    {Object.keys(filters).map((filterKey: any) => {
                        return  <div key={filterKey}>{renderFilter(filterKey)}</div>
                    })}
                </FilterSelect>
        </>

)
}


export default Filters