import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";
import {isNullOrUndefined} from "../../../../../../../common/utils";

interface Props {}

const Niche: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(offer.niche_id === 0 || isNullOrUndefined(offer.niche)) return null

    return (
        <>
            <Subtitle>Vertical </Subtitle>
            <Item>{offer.niche.name}</Item>
        </>
    )
}

export default Niche