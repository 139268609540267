import React from 'react'
import MenageVerifiedDevices from "../../../../../common/components/2fa/manage-verified-devices/ManageVerifiedDevices";

import {Wrapper, ContentWrapper} from '../../../../../common/components/2fa/2fa.style'

interface Props {
    twoFactorModalInitialOpened: boolean
}

const TwoFactorAuthentication: React.FC<Props> = ({twoFactorModalInitialOpened}) => {
    return <Wrapper>
        <div style={{
            maxWidth: '80%'
        }}>
            <ContentWrapper>
                <MenageVerifiedDevices initialOpenAddDeviceModal={twoFactorModalInitialOpened}/>
            </ContentWrapper>
            <p className={'info'}>If multiple users access your account, each one will need to add a phone number to verify access.</p>
        </div>
    </Wrapper>
}

export default TwoFactorAuthentication