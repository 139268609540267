import React, {useEffect, useMemo, useRef, useState} from 'react'
import Highcharts, {SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {endOfISOWeek, format, startOfISOWeek, startOfMonth} from 'date-fns'

import {AnalyzeBy, AnalyzeFilters, DateSelector, DateSelectorOption, Wrapper} from './AnalyzePerformance.style'
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {IFetchAnalyzePerformanceResponse} from "../AgencyView.service";
import {
    IAnalyzeBy,
    setAnalyzePerformanceAnalyzeBy,
    setAnalyzePerformanceAnalyzeByVs,
    setAnalyzePerformanceDateSelector,
    TDateSelector
} from "../AgencyView.slice";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import {formatNumberToCommaSeparated} from "../../../../../../common/utils/formatNumberToCommaSeparated";
import {formatNumberToCurrency} from "../../../../../../common/utils";

interface IAnalyzePerformanceProps {
    data: IFetchAnalyzePerformanceResponse[],

}

const analyzeBy: IAnalyzeBy[] = [
    {
        value: 'clicks',
        name: 'Clicks'
    },
    {
        value: 'sale_amount',
        name: "Sale/Revenue Amount"
    },
    {
        value: 'cvr',
        name: 'CVR'
    },
    {
        value: 'conversions',
        name: 'Conversions'
    },
    {
        value: 'roas',
        name: 'ROAS'
    },
    {
        value: 'cost',
        name: 'Cost'
    },
    {
        value: 'epc',
        name: 'EPC'
    },
    {
        value: 'aov',
        name: 'AOV'
    },
    {
        value: 'ecpa',
        name: 'eCPA'
    },
    {
        value: 'cac',
        name: 'CAC'
    },
]

const AnalyzePerformance: React.FC<IAnalyzePerformanceProps> = ({data}) => {
    const {
        analyze_performance_analyze_by_vs,
        analyze_performance_analyze_by,
        analyze_performance_date_selector
    } = useAppSelector(state => state.agencyView)
    const [firstDataSet, setFirstDataSet] = useState<(number | string)[]>([])
    const [secondDataSet, setSecondDataSet] = useState<(number | string)[]>([])
    const [xAxisValues, setXAxisValues] = useState<string[]>([])
    const [analyzeByOpened, setAnalyzeByOpened] = useState(false)
    const [analyzeByVsOpened, setAnalyzeByVsOpened] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        data[0] && setFirstDataSet(Object.values(data[0].data))
        data[1] && setSecondDataSet(Object.values(data[1].data))
        data[0] && setXAxisValues(Object.keys(data[0].data))
    }, [data])

    const handleAnalyzeBySelect = (item: IAnalyzeBy) => {
        dispatch(setAnalyzePerformanceAnalyzeBy(item))
        setAnalyzeByOpened(false)
    }

    const handleAnalyzeByVsSelect = (item: IAnalyzeBy) => {
        dispatch(setAnalyzePerformanceAnalyzeByVs(item))
        setAnalyzeByVsOpened(false)
    }

    const handleDateSelectorSelect = (item: TDateSelector) => {
        dispatch(setAnalyzePerformanceDateSelector(item))
    }

    const formatDateMonth = (date: string | undefined | number | Date | null) => {
        return format(date ?
            typeof date === 'string' ? new Date(date) : date
            : new Date(), 'MMMM, y')
    }

    const formatDate = (date: string | undefined | number | Date | null) => {
        return format(date ?
            typeof date === 'string' ? new Date(date) : date
            : new Date(), 'MMMM dd, y')
    }

    const result: SeriesOptionsType[] = useMemo(() => [
        {
            "name": analyze_performance_analyze_by.name,
            "data": firstDataSet,
            "type": "areaspline",
            "yAxis": 0,
            "color": "#ff5638",
            "fillColor": {
                "linearGradient": {
                    "x1": 0,
                    "x2": 0,
                    "y1": 0,
                    "y2": 1
                },
                "stops": [
                    [
                        0,
                        "rgba(252, 82, 52, 0.18)"
                    ],
                    [
                        1,
                        "rgba(252, 82, 52, 0)"
                    ]
                ]
            },
        },
        {
            "name": analyze_performance_analyze_by_vs.name,
            "data": secondDataSet,
            "type": "areaspline",
            "yAxis": 1,
            "color": "#ffab37",
            "fillColor": {
                "linearGradient": {
                    "x1": 0,
                    "x2": 0,
                    "y1": 0,
                    "y2": 1
                },
                "stops": [
                    [
                        0,
                        "rgba(255, 199, 0, 0.18)"
                    ],
                    [
                        1,
                        "rgba(255, 199, 0, 0)"
                    ]
                ]
            },
        }
    ], [firstDataSet, secondDataSet])

    const getPointDataFormat = (name: string, sum: number | null | undefined) : any => {
        if(sum) {
            if(['ROAS', "CVR"].includes(name)){
                return formatNumberToCommaSeparated(sum) + '%'
            } else  if(['Sale/Revenue Amount', 'Cost', 'EPC', 'AOV', 'eCPA', 'CAC'].includes(name)){
                return formatNumberToCurrency(sum)
            } else {
                return formatNumberToCommaSeparated(sum)
            }
        } else {
            return formatNumberToCommaSeparated(0)
        }

    }

    const options: Highcharts.Options = useMemo(() => ({
        chart: {
            height: 330,
            animation: true
        },
        title: {
            text: ``
        },
        credits: {
            enabled: false
        },
        legend: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'bottom',
            x: 50,
            y: 0,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 2,
            itemStyle: {
                color: '#31383B',
                fontWeight: 'light',
                fontSize: '14px',
                lineHeight: '22px',
                paddingLeft: '5px',
                whiteSpace: 'normal',
            },
            labelFormatter: function () {
                let sum = '0'
                //@ts-ignore
                const index = this.symbolIndex
                //@ts-ignore
                if(data.length > 0){
                    sum = data[index].sum as string
                }
                //%
                if(['ROAS', "CVR"].includes(this.name)){
                    sum = formatNumberToCommaSeparated(sum) + '%'
                } else  if(['Sale/Revenue Amount', 'Cost', 'EPC', 'AOV', 'eCPA', 'CAC'].includes(this.name)){
                    sum = formatNumberToCurrency(parseFloat(sum as string))
                } else {
                    sum = formatNumberToCommaSeparated(sum)
                }
                //$

                return `${this.name} <div style="color: #8799A1;">${sum}</div>`;
            }
        },
        xAxis: {
            categories: xAxisValues,
            labels: {
                formatter: function ({value}): string {
                    return value as string
                },
                style: {
                    color: '#5A686F',
                }
            },
            visible: true,
            tickmarkPlacement: "on",
            tickInterval: 1,
            minPadding: 0,
            maxPadding: 0,
            startOnTick: true,
            endOnTick: true,
        },
        yAxis: [{
            title: {
                text: analyze_performance_analyze_by.name,
                style: {
                    color: '#ff5638',
                    fontSize: '10',
                }
            },
        }, { //--- Secondary yAxis
            title: {
                text: analyze_performance_analyze_by_vs.name,
                style: {
                    color: '#ffab37',
                    fontSize: '10',
                }
            },
            opposite: true
        }],
        plotOptions: {
            series: {
                lineWidth: 3,
                pointPlacement: "on",
                marker: {
                    radius: 6,
                },
            }
        },
        series: result,
        tooltip: {
            shared: true,
            borderWidth: 0,
            backgroundColor: '#ffffff',
            padding: 0,
            shadow: false,
            borderRadius: 16,
            useHTML: true,
            formatter: function (): any {
                let date = this.points && this.points[0].x
                return `<div class="hc-tooltip-wrapper">
                            <div class="hc-tooltip-inner">
                                <div class="hc-tooltip-title ${analyze_performance_date_selector === 'day' ? '' : 'hidden' }">
                                   ${formatDate(date)}
                                </div>
                                <div class="hc-tooltip-title ${analyze_performance_date_selector === 'week' ? '' : 'hidden' }">
                                   ${ date && formatDate(startOfISOWeek(new Date(date))) + " - " + 
                                    formatDate(endOfISOWeek(new Date(date)))}
                                </div>
                                <div class="hc-tooltip-title ${analyze_performance_date_selector === 'month' ? '' : 'hidden' }">
                                   ${ date && formatDateMonth(startOfMonth(new Date(date)))}
                                </div>
                                <div class="tooltip-value-item">
                                    <span class="marker" style="background-color: #ff5638"></span>
                                    ${this.points && this.points[0].series.name}: &nbsp; 
                                    <strong>${this.points && getPointDataFormat(this.points[0].series.name, 
                                        this.points[0].y)}
                                    </strong>
                                </div>
                                <div class="tooltip-value-item">
                                    <span class="marker" style="background-color: #ffab37"></span>
                                    ${this.points && this.points[1].series.name}: &nbsp; 
                                    <strong>${this.points && getPointDataFormat(this.points[1].series.name, this.points[1].y)}
                                    </strong>
                                </div>
                            </div>
                        </div>`
            }
        },
    }), [result, xAxisValues, analyze_performance_analyze_by, analyze_performance_analyze_by_vs, data]);

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <Wrapper>
            <AnalyzeFilters>
                <AnalyzeBy>
                    <Dropdown
                        headerClassName={'analyzeBy-header'}
                        containerClassName={'analyzeBy-container'}
                        text={<div className={'analyze-by'}><label>Analyze by: </label>
                            <span>{analyze_performance_analyze_by.name}</span></div>}
                        isOpened={analyzeByOpened}
                        setOpened={setAnalyzeByOpened}
                    >
                        {analyzeBy.map(item => {
                            return <DropdownItem
                                selected={analyze_performance_analyze_by.value === item.value}
                                key={item.value}
                                disabled={analyze_performance_analyze_by_vs.value === item.value}
                                onClick={() => handleAnalyzeBySelect(item)}
                            >
                                {item.name}
                            </DropdownItem>
                        })}
                    </Dropdown>
                    <Dropdown
                        text={<div className={'analyze-by'}><label>vs: </label>
                            <span>{analyze_performance_analyze_by_vs.name}</span></div>}
                        isOpened={analyzeByVsOpened}
                        setOpened={setAnalyzeByVsOpened}
                        headerClassName={'analyzeBy-header'}
                        containerClassName={'analyzeBy-container'}
                    >
                        {analyzeBy.map(item => {
                            return <DropdownItem
                                selected={analyze_performance_analyze_by_vs.value === item.value}
                                key={item.value}
                                disabled={analyze_performance_analyze_by.value === item.value}
                                onClick={() => handleAnalyzeByVsSelect(item)}
                            >
                                {item.name}
                            </DropdownItem>
                        })}
                    </Dropdown>
                </AnalyzeBy>
                <div style={{width: "max-content"}}>
                    <DateSelector>
                        <DateSelectorOption onClick={() => handleDateSelectorSelect('day')}
                                            $selected={analyze_performance_date_selector === 'day'}>
                            Day
                        </DateSelectorOption>
                        <DateSelectorOption onClick={() => handleDateSelectorSelect('week')}
                                            $selected={analyze_performance_date_selector === 'week'}>
                            Week
                        </DateSelectorOption>
                        <DateSelectorOption onClick={() => handleDateSelectorSelect('month')}
                                            $selected={analyze_performance_date_selector === 'month'}>
                            Month
                        </DateSelectorOption>
                    </DateSelector>
                </div>
            </AnalyzeFilters>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
            />
        </Wrapper>
  );
}

export default AnalyzePerformance