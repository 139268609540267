import styled from "styled-components";

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 12;
    background: #ffffff99;
    padding-top: 50px;
`

export const ModalBody = styled.div`
    min-width: 420px;
    max-width: 420px;
`
export const UsersWrapper = styled.div`
   .tooltip {
     z-index: 99999!important;
   }
`

export const styledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    position: relative;
  width: 12.3px;
  height: 12.3px;
  border-radius: 3px;

  .ico-Check{
    display: ${props => props.checked ? 'inline-block' : 'none'};
    font-size: 8px;
    position: absolute;
    left: 1px;
    top: 1px;
  }

  .indeterminate {
    display: block;
    position: absolute;
    top: 5px;
    left: 2px;
    width: 7px;
    height: 1px;
    border-radius: 1px;
    background-color: ${({theme}) => theme.colors.performBlue['5']};
  }
`