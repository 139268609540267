import React from "react";
import {Wrapper} from "./DeleteModal.style";
import {useAppSelector, useAppDispatch} from "../../../../../../common/hooks";
import {Button, Cancel} from "../../home/add-tracking-platform-modal/AddTrackingPlatformModal.style";
import {IContacts} from "../../../../../../common/models/advertiser-edit.type";
import {deleteContact} from "../../AdvertisersEdit.slice";

interface Props {
    closeModal: () => void,
    contact: IContacts | null
}

const DeleteModal: React.FC<Props> = ({closeModal, contact}) => {
    const {loading} = useAppSelector(state => state.advertisersEdit)
    const {token} = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const handleDelete = async () => {
        if(contact?.id){
            const params = {
                token: token,
                id: contact.id
            }

            await dispatch(deleteContact(params))
            closeModal()
        }

    }

    return (
        <Wrapper>
            <p>Are you sure you want to delete this contact?</p>

            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: '50px'
            }}>
                <Cancel onClick={closeModal}>Cancel</Cancel><Button disabled={loading} onClick={handleDelete}>Yes, delete this contact!</Button>
            </div>
        </Wrapper>
    )
}

export default DeleteModal