import {LatestReassessmentStatusWrapper} from "./LatestReassessmentStatusTooltip.style";

const LatestReassessmentStatusTooltip = () => {
    return(
        <LatestReassessmentStatusWrapper>
            <div className="above-text">
                The status of the latest credit reassessment request submitted for a marketer. The status listed here 
                will remain for 30 days after it has been marked as resolved, rejected, or cancelled, unless a 
                new request is made before then.
            </div>
            <div className="reassessment-statuses">
                <div className="heading">Request Statuses</div>
                <div className="statuses">
                    <div className="status">
                        <div className="circle" style={{background: "#8799A2"}}/>
                        <div className="text">Submitted</div>
                    </div>
                    <div className="status">
                        <div className="circle" style={{background: "#1AA6F0"}}/>
                        <div className="text">In Progress</div>
                    </div>
                    <div className="status">
                        <div className="circle" style={{background: "#F3C315"}}/>
                        <div className="text">More Info Needed</div>
                    </div>
                    <div className="status">
                        <div className="circle" style={{background: "#05C76A"}}/>
                        <div className="text">Resolved</div>
                    </div>
                    <div className="status">
                        <div className="circle" style={{background: "#F23842"}}/>
                        <div className="text">Rejected</div>
                    </div>
                </div>
            </div>
        </LatestReassessmentStatusWrapper>
    )
}
export default LatestReassessmentStatusTooltip