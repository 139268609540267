
import React, {useEffect, useRef, useState} from 'react';
import Modal from "../../modal/Modal";
import PhoneNumberInput from "../../phone-number-input/PhoneNumberInput";
import Input from "../../input/Input";
import {Footer} from "../../modalWindow/container/Container.style";
import {ButtonStyled, BtnLoadingSpinner} from "../../../styled-components";
import {ContentWrapper} from "./AddDeviceModal.style";

import TwoFactorAuthenticationService from "../../../services/2fa.service";
import {useAppSelector} from "../../../hooks";
import {CountryData} from "react-phone-input-2";

const targetOrigin = process.env.REACT_APP_API_BASE_URL;

type Props = {
    closeModal: () => void
    opened: boolean
    openSuccessModal: (opened: boolean) => void
    fetchDevices: () => void
};

const AddDeviceModal:React.FC<Props> = ({
    closeModal,
    opened,
    openSuccessModal,
    fetchDevices
                                        }) => {

    const [phoneNumber, setPhoneNumber] = useState('')
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('')
    const [dialCode, setDialCode] = useState('')
    const [code, setCode] = useState('')
    const {token} = useAppSelector(state => state.auth)
    const [loadingSendCode, setLoadingSendCode] = useState(false)
    const [loadingVerifyCode, setLoadingVerifyCode] = useState(false)
    const [errorVerifyCode, setErrorVerifyCode] = useState<string | null>(null)
    const [errorAddPhoneNumber, setErrorAddPhoneNumber] = useState<string | null>(null)
    const [codeSent, setCodSent] = useState(false)
    const [alreadySent, setAlreadySent] = useState('')

    const handleSendCode = async (resend: boolean = false) => {
        if(phoneNumber === alreadySent && !resend)
        {
            setCodSent(true)
            return true
        }

        setLoadingSendCode(true)
        try {
            const {data} = await TwoFactorAuthenticationService.sendOtpSms(token, {
                phone_number: '+' + phoneNumber,
                dial_code: dialCode
            })
            if(data.sensitive_data){
                setCode(data.sensitive_data)
            }
            setCodSent(true)
        } catch (e: any) {
            if(e.response.data.message){
                setErrorAddPhoneNumber(e.response.data.message)
            } else {
                setErrorAddPhoneNumber('Phone number already added')
            }
            console.error(e.response.data.message)
        } finally {
            setLoadingSendCode(false)
        }
    }

    const handleVerifyCode = async () => {
        setLoadingVerifyCode(true)
        setErrorVerifyCode(null)
        try {
            const {data} = await TwoFactorAuthenticationService.verifyOtp(token, {
                phone_number: '+' + phoneNumber,
                otp: code,
                dial_code: dialCode,
                logged_in: true
            })
            if(data.token){
                targetOrigin && window.parent.postMessage({id: 11, data: data.token}, targetOrigin)
            }
            await fetchDevices()
            setPhoneNumber('')
            setFormattedPhoneNumber('')
            setCodSent(false)
            setDialCode('')
            setCode('')
            closeModal()
            openSuccessModal(true)
        } catch (e) {
            setErrorVerifyCode('The code entered is invalid, try again or send a new code.')
        } finally {
            setLoadingVerifyCode(false)
        }
    }

    useEffect(() => {
        return () => {
            setPhoneNumber('')
            setFormattedPhoneNumber('')
            setCodSent(false)
            setDialCode('')
            setCode('')
        }
    }, [])

    useEffect(() => {
        setAlreadySent(phoneNumber)
    }, [codeSent])

    const handleCancel = () => {
        if(codeSent)
        {
            setErrorVerifyCode('')
            setErrorAddPhoneNumber('')
            setCode('')
            setCodSent(false)
        }else{
            setErrorVerifyCode('')
            setErrorAddPhoneNumber('')
            setPhoneNumber('')
            setFormattedPhoneNumber('')
            setCodSent(false)
            setDialCode('')
            setCode('')
            closeModal()
        }

    }

    return (
        <Modal closeModal={handleCancel} opened={opened} title={'Add New Device'}>

            <ContentWrapper>

                <div className="row">
                    {codeSent ?
                        <VerifyCodeContent
                            formattedPhoneNumber={formattedPhoneNumber}
                            code={code}
                            setCode={setCode}
                            sendCode={handleSendCode}
                            error={errorVerifyCode}
                            setError={setErrorVerifyCode}
                        /> :
                        <SendCodeContent
                            setFormattedPhoneNumber={setFormattedPhoneNumber}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            setDialCode={setDialCode}
                            error={errorAddPhoneNumber}
                            setError={setErrorAddPhoneNumber}
                        />
                    }
                </div>
            </ContentWrapper>

            <Footer>
                <ButtonStyled
                    className={'btn-cancel'}
                    onClick={handleCancel}
                >
                    {codeSent ? 'Back' : 'Cancel'}
                </ButtonStyled>
                {codeSent ?
                    <ButtonStyled
                        className={'btn-blue-filled'}
                        onClick={handleVerifyCode}
                        disabled={code?.length < 6 || loadingVerifyCode}
                    >
                        {loadingVerifyCode && <BtnLoadingSpinner/>}Verify Code
                    </ButtonStyled> :
                    <ButtonStyled
                        className={'btn-blue-filled'}
                        onClick={() => handleSendCode(false)}
                        disabled={phoneNumber.length < 6 || loadingSendCode}
                    >
                        {loadingSendCode && <BtnLoadingSpinner/>} Send Code
                    </ButtonStyled>
                }
            </Footer>
        </Modal>
    );
};

const SendCodeContent: React.FC<{
    phoneNumber: string,
    setPhoneNumber: (value: string) => void,
    setFormattedPhoneNumber: (value: string) => void,
    setDialCode: (value: string) => void,
    error: string | null,
    setError: (e: string | null) => void

}> = ({phoneNumber, setPhoneNumber, setFormattedPhoneNumber, setDialCode, error, setError}) => {

    const handlePhoneNumberChange = (phoneNumber: string, formattedPhoneNumber: string, data: CountryData) => {
        setPhoneNumber(phoneNumber)
        setFormattedPhoneNumber(formattedPhoneNumber)
        setError('')
        if(data.dialCode ) {
            setDialCode(data.dialCode)
        }
    }

    return <>
        <div className="row">
            <p>Standard carrier message rates apply</p>
        </div>
        <div className="row">
            <PhoneNumberInput
                value={phoneNumber}
                onChange={(value, data, e, formattedValue) => {
                    handlePhoneNumberChange(value, formattedValue, data as CountryData)
                }}
                country={'us'}
                error={error ? error : ''}
                leftAlignErrorMessage={true}
            />
        </div>
    </>
}

const VerifyCodeContent: React.FC<{
    code: string,
    setCode: (value: string) => void,
    formattedPhoneNumber: string,
    sendCode: (resend?: boolean) => void,
    error: string | null,
    setError: (e: string | null) => void
}> = ({code, setCode, formattedPhoneNumber, sendCode, error, setError}) => {
    const [resendCodeEnabled, setResetCodeEnabled] = useState(true)
    const [counter, setCounter] = useState(0)
    const timer = useRef<NodeJS.Timer>()

    const handleResendCode = () => {
        setCounter(30)
        sendCode(true)
        setResetCodeEnabled(false)
    }

    useEffect(() => {
        if(counter > 0){
            timer.current = setInterval(() => {
                setCounter(prev => prev - 1)
            }, 1000)
        }
        if(counter === 0){
            setResetCodeEnabled(true)
            timer.current && clearInterval(timer.current)
        }

        return () => {
            timer.current && clearInterval(timer.current)
        }
    }, [counter])

    return <>
        <div className="row">
            <p>A text message has been sent with your code to: </p>
        </div>
        <div className="row">
            <p>{formattedPhoneNumber}</p>
        </div>
        <div className="row">
            <Input
                wrapperClassName={'input-styled'}
                value={code}
                onChange={(e) => {
                    setCode(e.target.value)
                    setError('')
                }}
                label={'6-Digit Code'}
                placeholder={'Enter code'}
                error={error ? error : ''}
                leftAlignErrorMessage={true}
            />
        </div>
        <div className={'row'}>
            <p>It may take a minute to receive your code. <br/>
                Haven’t received it yet? {resendCodeEnabled ?
                    <span className={'resend-code'} onClick={handleResendCode}>Resend Code</span> :
                    <span className={'resend-code-again'}>
                        <span className={'resend-code-disabled'}>Resend code</span> again in {counter} seconds</span>}
            </p>
        </div>
    </>
}

export default AddDeviceModal