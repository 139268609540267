import styled from "styled-components";

export const StatsWidgetContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #E7ECEE;
    overflow-x: auto;
    min-height: 110px;
    position: relative;
`
export const StatsWidgetLoading = styled.div`
    width: 100%;
    .loading-dots {
        text-align: center;
        .dot {
            animation: loading-dots 1s infinite;
            width: 8px;
            height: 8px;
            background: #EF4A24;
            margin: 0 3px;
            display: inline-block;
            border-radius: 100%;
        }

        .dot:nth-child(2) {
            animation-delay: 0.1s;
        }

        .dot:nth-child(3) {
            animation-delay: 0.3s;
        }

        @keyframes loading-dots {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
`


export const StatsWidgetItem = styled.div`
    flex: auto;
    border-right: 1px solid #E7ECEE;
    padding: 0 35px;
    
    .title {
        font-size: 16px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        > div {
            display: flex;
            margin-left: 5px;
            i {font-size: 16px;}
        }
    }
    .data {
        display: inline-flex;
        align-items: center;
        line-height: 1;
        .icon {
            width: 46px;
            height: 46px;
            border-radius: 30px;
            display: flex;
            flex: 0 0 46px;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            background: red;
            font-size: 23px;
            i {line-height: 46px;}
        }
        .value {
            font-size: 34px;
            font-family: ${({theme}) => theme.fonts.AvenirLight};
            color: ${({theme}) => theme.colors.deepBlue[7]};
        }
    }
    .stacked-data {
        > div {
            display: flex;
            justify-content: space-between;
            font-family: ${({theme}) => theme.fonts.AvenirLight};
            color: ${({theme}) => theme.colors.deepBlue[7]};
            strong, span {
                padding-left: 5px;
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            }
            &:first-child {
                margin-bottom: 15px;
            }
        }
    }
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        border-right: none;
        padding-right: 0;
    }
`

export const StatsTableContainer = styled.div`
    padding-top: 20px;
    .ag-active-referrals {
        &.no-active-referrals {
            .ag-layout-auto-height {
                overflow: visible;
            }
            .ag-root-wrapper {
                min-height: 150px;
            }
        }
        .no-active-referrals-message  {
            height: 110px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${({theme}) => theme.colors.deepBlue[4]};
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            i {
                margin-left: 5px;
            }
        }
    }
`

export const StatsTableTitle = styled.div`
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirMedium};
`
