import {LatestReassessmentStatusWrapper, StatusTooltipBody} from "./LatestReassessmentStatus.style";
import {ILatestReassessmentStatus} from "../../MarketerFundingMonitoring.service";
import React from "react";
import Tooltip from "../../../../../../../../common/components/tooltip/Tooltip";
import {
    IconPriorityHigh,
    IconPriorityHighest,
    IconPriorityLow, 
    IconPriorityLowest,
    IconPriorityMedium
} from "./LatestReassessmentStatusIcons";
import {format} from "date-fns";

interface Props {
    status: ILatestReassessmentStatus
}

const statuses = [
    {
        status_name: 'Submitted',
        background: "#8799A2"
    },
    {
        status_name: 'In Progress',
        background: "#1AA6F0"
    },
    {
        status_name: 'More info needed',
        background: "#F3C315"
    },
    {
        status_name: 'Resolved',
        background: "#05C76A"
    },
    {
        status_name: 'Rejected',
        background: "#F23842"
    },
]

const ticketBaseUrl = "https://clickbooth.atlassian.net/servicedesk/customer/portal/6/"

const LatestReassessmentStatus: React.FC<Props> = ({status}) => {
    return status ? (
        <LatestReassessmentStatusWrapper>
            <div className="status">
                <Tooltip className='status-tooltip'
                         position={(status.status == 'Resolved' || status.status == 'Rejected') ? 'bottom' : 'top'}
                         positionTop={(status.status == 'Resolved' || status.status == 'Rejected') ? 10 : -5}
                         positionLeft={40}
                         delay={400}
                         width={240}
                         icon={
                             <div className='status'>
                                 {statuses.map((item, index) => {
                                     if (item.status_name === status.status) {
                                         return <div key={index}
                                                     className="circle"
                                                     style={{background: item.background}}/>
                                     }
                                 })}
                                 <div className="text">{status.status}</div>
                                 <a href={ticketBaseUrl + status.issue_key} target='_blank' className='open-button'>
                                     <i className="material-icons">open_in_new</i>
                                 </a>
                             </div>}>
                    <StatusTooltipBody className='status-tooltip-body'>
                        <div className='status-data'>
                            <div>
                                <strong>Request Submitted</strong>
                                <span>
                                    {format(new Date(status.request_submitted_at.replaceAll("-", "/") + " "), "MM/dd/yyyy 'at' hh:mm a")}
                                </span>
                            </div>
                            <div>
                                <strong>Last Updated</strong>
                                <span>
                                    {format(new Date(status.last_updated_at.replaceAll("-", "/") + " "), "MM/dd/yyyy 'at' hh:mm a")}
                                </span>
                            </div>
                            <div className='item-priority'>
                                <strong>Priority</strong>
                                <div className='priority'>
                                    {status.priority == 1 && <><IconPriorityHighest width={11}/> Highest</>}
                                    {status.priority == 2 && <><IconPriorityHigh width={11}/> High</>}
                                    {status.priority == 3 && <><IconPriorityMedium width={11}/> Medium</>}
                                    {status.priority == 4 && <><IconPriorityLow width={11}/> Low</>}
                                    {status.priority == 5 && <><IconPriorityLowest width={11}/> Lowest</>}
                                </div>
                            </div>
                            <div className='item-status'>
                                <strong>Request Status</strong>
                                <div className='status'>
                                    {statuses.map((item, index) => {
                                        if (item.status_name === status.status) {
                                            return <div key={index}
                                                        className="circle"
                                                        style={{background: item.background}}/>
                                        }
                                    })}
                                    <div className='text'>{status.status}</div>
                                </div>
                            </div>
                        </div>
                        {(status.status == 'Resolved' || status.status == 'Rejected') &&
                            <div className='status-ticket-resolution'>
                                <strong className="heading">Ticket Resolution</strong>
                                {status.ticket_resolution}
                            </div>
                        }
                    </StatusTooltipBody>
                </Tooltip>
            </div>
        </LatestReassessmentStatusWrapper>
    ) : <></>
}

export default LatestReassessmentStatus