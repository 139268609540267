import {BrowserRouter, Route, Routes} from "react-router-dom";
import {getAuth} from "./store/auth.slice";

import {useAppDispatch} from "./common/hooks";
import PrivateRoute from "./PrivateRoute";

//Admin
import Advertisers from "./modules/admin/pages/advertisers/Advertisers";
import Home from "./modules/admin/pages/advertisers-edit/home/Home";
import Cards from "./modules/admin/pages/advertisers-edit/cards/Cards";
import Contacts from "./modules/admin/pages/advertisers-edit/contacts/Contacts";
import AdvertisersEdit from "./modules/admin/pages/advertisers-edit/AdvertisersEdit";
import Password from "./modules/admin/pages/advertisers-edit/password/Password";
import Credit from "./modules/admin/pages/advertisers-edit/billing/Billing";
import Transparency from "./modules/admin/pages/advertisers-edit/transparency/Transparency";
import Dashboard from "./modules/admin/pages/dashboard/Dashboard";
import Offers from "./modules/admin/pages/offers/offers/Offers";
import Merging from "./modules/admin/pages/partners/partner_merging/Merging";
import CustomDomainAdmin from "./modules/admin/pages/custom-domains/CustomDomain";
import LeapPartners from "./modules/admin/pages/partners/leap-partners/LeapPartners";
import Billing from './modules/admin/pages/billing/Billing'
import PartnerPayments from "./modules/admin/pages/billing/partner-payments/PartnerPayments";
import MarketerFundingMonitoring
    from "./modules/admin/pages/billing/marketer-funding-monitoring/MarketerFundingMonitoring";
import MarketerBalance from "./modules/admin/pages/billing/marketer-balance/MarketerBalance";
import MarketersTransactions from "./modules/admin/pages/billing/marketers-transactions/MarketersTransactions";
import PartnerTransactions from "./modules/admin/pages/billing/partner-transactions/PartnerTransactions";
import Users from "./modules/admin/pages/users/Users";

//Partners
import Referrals from "./modules/partner/pages/referrals/Referrals";
import CustomDomainAdvertiser from "./modules/advertiser/pages/custom-domains/CustomDomain";
import CustomDomainPartner from "./modules/partner/pages/custom-domains/CustomDomain";
import TwoFactorAuthenticationPartner from './modules/partner/pages/account/two-factor-authentication/TwoFactorAuthentication'

//Marketers
import TwoFactorAuthenticationMarketer
    from "./modules/advertiser/pages/account/two-factor-authentication/TwoFactorAuthentication";
import Cpa from "./modules/advertiser/pages/offers/cpa/Cpa";
import CreateOffers from "./modules/advertiser/pages/create-offer/CreateOffers";
import OfferType from "./modules/advertiser/pages/create-offer/OfferType";
import OfferTypeApps from "./modules/advertiser/pages/create-offer/OfferTypeApps";
import Mobile from "./modules/advertiser/pages/create-offer/Mobile";
import Main from "./modules/advertiser/pages/create-offer/layout/main/Main"
import OfferInfo from "./modules/advertiser/pages/create-offer/layout/main/offer-info/OfferInfo";
import TrackingSystem from "./modules/advertiser/pages/create-offer/layout/main/tracking-system/TrackingSystem";
import LandAndTrack from "./modules/advertiser/pages/create-offer/layout/main/land-and-track/LandAndTrack";
import OutcomeFunnel from "./modules/advertiser/pages/create-offer/layout/main/outcome-funnel/OutcomeFunnel";
import TrackingCodes from "./modules/advertiser/pages/create-offer/layout/main/tracking-codes/TrackingCodes";
import Testing from "./modules/advertiser/pages/create-offer/layout/main/testing/Testing";
import CreativesAndEmail from "./modules/advertiser/pages/create-offer/layout/main/creatives-and-email/CreativesAndEmail";
import Budget from "./modules/advertiser/pages/create-offer/layout/main/budget/Budget";
import Targeting from './modules/advertiser/pages/create-offer/layout/main/targeting/Targeting'
import Scheduling from "./modules/advertiser/pages/create-offer/layout/main/scheduling/Scheduling";
import {OFFER_INFO, TRACKING_SYSTEM , LAND_AND_TRACK, OUTCOME_FUNNEL, TRACKING_CODE,
TESTING, CREATIVES, BUDGET, TARGETING, SCHEDULING} from "./modules/advertiser/pages/create-offer/interfaces/interfaces";
//Auth
import Verify from "./pages/auth/two-factor-authentication/2fa-verify/Verify";
import AddDevice from "./pages/auth/two-factor-authentication/2fa-add-device/AddDevice";
import {useState} from "react";





const App = () => {
    const dispatch = useAppDispatch();

    const [twoFactorAuthenticationModalInitialState, setTwoFactorAuthenticationModalInitialState] = useState(false)

    interface data {
        id: number,
        token?: string,
        data?: {}
    }

    window.addEventListener("message", (event: any) => {
        if (event.origin === process.env.REACT_APP_API_BASE_URL) {
            let auth_data: data = event.data;
            auth_data.id === 1 && dispatch(getAuth(auth_data));
            if(event.data.open_two_factor_verification_modal){
                setTwoFactorAuthenticationModalInitialState(event.data.open_two_factor_verification_modal)
            }
        }
    }, false);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/test" element={<div>Test</div>}/>
                <Route path={'auth'}>
                    <Route path='login' />
                    <Route path="verify" element={<Verify/>}/>
                    <Route path="add-device" element={<AddDevice/>}/>
                </Route>
                <Route path="/admin" element={<PrivateRoute user_type="admin"/>}>
                    <Route path="custom-domain" element={<CustomDomainAdmin/>}/>
                    <Route path="users/marketers" element={<Advertisers/>}/>
                    <Route path="users/marketers/:id" element={<AdvertisersEdit/>}>
                        <Route path="home" element={<Home/>}/>
                        <Route path="cards" element={<Cards />}/>
                        <Route path="contacts" element={<Contacts/>}/>
                        <Route path="password" element={<Password/>}/>
                        <Route path="credit" element={<Credit/>}/>
                        <Route path="partner-transparency" element={<Transparency/>}/>
                    </Route>
                    <Route path="users/admin" element={<Users twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}/>}/>
                    <Route path={"users/partners"} element={<LeapPartners/>}/>
                    <Route path="users/partners/merging" element={<Merging/>}>
                    </Route>
                    <Route path='billing' element={<Billing/>}>
                        <Route path='marketer-balance' element={<MarketerBalance/>}/>
                        <Route path='partner-transactions' element={<PartnerTransactions/>}/>
                        <Route path='marketer-funding-monitoring' element={<MarketerFundingMonitoring/>}/>
                        <Route path='partner-payments' element={<PartnerPayments/>}/>
                        <Route path='marketers-transactions' element={<MarketersTransactions/>}/>
                    </Route>
                    <Route path='' element={<Dashboard/>}/>
                    <Route path='offers' element={<Offers/>}/>
                </Route>
                <Route path="/partners" element={<PrivateRoute user_type="affiliate"/>}>
                    <Route path="referrals" element={<Referrals/>}/>
                    <Route path="custom-domain" element={<CustomDomainPartner/>}/>
                    <Route path='account' element={<PrivateRoute user_type="affiliate"/>}>
                        <Route
                            path='two-factor-authentication'
                            element={
                                <TwoFactorAuthenticationPartner twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}
                                />
                            }
                        />
                    </Route>
                </Route>
                <Route path="/marketers" element={<PrivateRoute user_type="advertiser"/>}>
                    <Route path="referrals" element={<Referrals/>}/>
                    <Route path="custom-domain" element={<CustomDomainAdvertiser />}/>
                    <Route path='account' element={<PrivateRoute user_type="advertiser"/>}>
                        <Route
                            path='two-factor-authentication'
                            element={
                                <TwoFactorAuthenticationMarketer
                                    twoFactorModalInitialOpened={twoFactorAuthenticationModalInitialState}
                                />
                            }
                        />
                    </Route>

                    {/*<Route path="offers">*/}
                    {/*    /!*<Route path="create" element={<Cpa/>}/>*!/*/}
                    {/*    <Route path="edit/:id" element={<Cpa/>} />*/}
                    {/*    <Route path="replicate/:id" element={<Cpa replicate/>} />*/}
                    {/*    <Route path="cpa">*/}
                    {/*        <Route path="create" element={<Cpa/>} />*/}
                    {/*        <Route path="edit/:id" element={<Cpa/>} />*/}
                    {/*        <Route path="replicate/:id" element={<Cpa replicate/>} />*/}
                    {/*    </Route>*/}
                    {/*</Route>*/}
                    <Route path="offers" >
                        <Route path="create" element={<CreateOffers/>}>
                            <Route path="type" element={<OfferType/>}/>
                            <Route path="type/apps" element={<OfferTypeApps/>}/>
                            <Route path="type/apps/mobile" element={<Mobile/>}/>
                            <Route path="i" element={<Main/>}>
                                <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                <Route path={TESTING} element={<Testing/>}/>
                                <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                <Route path={BUDGET} element={<Budget/>}/>
                                <Route path={TARGETING} element={<Targeting/>}/>
                                <Route path={SCHEDULING} element={<Scheduling/>}/>
                            </Route>
                        </Route>
                        <Route path="edit/:id" element={<CreateOffers/>}>
                            <Route path="type/apps/mobile" element={<Mobile/>}/>
                            <Route path="i" element={<Main/>}>
                                <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                <Route path={TESTING} element={<Testing/>}/>
                                <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                <Route path={BUDGET} element={<Budget/>}/>
                                <Route path={TARGETING} element={<Targeting/>}/>
                                <Route path={SCHEDULING} element={<Scheduling/>}/>
                            </Route>
                        </Route>
                        <Route path="draft/:id" element={<CreateOffers draft/>}>
                            <Route path="i" element={<Main draft/>}>
                                <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                <Route path={TESTING} element={<Testing/>}/>
                                <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                <Route path={BUDGET} element={<Budget/>}/>
                                <Route path={TARGETING} element={<Targeting/>}/>
                                <Route path={SCHEDULING} element={<Scheduling/>}/>
                            </Route>
                        </Route>
                        <Route path="replicate/:id" element={<CreateOffers replicate/>}>
                            <Route path="i" element={<Main replicate/>}>
                                <Route path={OFFER_INFO} element={<OfferInfo/>}/>
                                <Route path={TRACKING_SYSTEM} element={<TrackingSystem/>}/>
                                <Route path={LAND_AND_TRACK} element={<LandAndTrack/>}/>
                                <Route path={OUTCOME_FUNNEL} element={<OutcomeFunnel/>}/>
                                <Route path={TRACKING_CODE} element={<TrackingCodes/>}/>
                                <Route path={TESTING} element={<Testing/>}/>
                                <Route path={CREATIVES} element={<CreativesAndEmail/>}/>
                                <Route path={BUDGET} element={<Budget/>}/>
                                <Route path={TARGETING} element={<Targeting/>}/>
                                <Route path={SCHEDULING} element={<Scheduling/>}/>
                            </Route>
                        </Route>
                    </Route>

                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App




