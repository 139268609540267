import styled from 'styled-components'

export const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 119px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 120px);
    top: 60px;
    width: 100%;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    @media (max-width: 769px) {
        max-width: calc(100% - 60px);
    }

    @media (max-width: 576px) {
        max-width: calc(100% - 30px);
        top: 10px;
    }
    
    .image-video-preview-modal & {
        position: fixed;
    }
`



