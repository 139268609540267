import React, {useEffect, useState} from 'react'
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {useAppSelector} from "../useAppSelector";

interface State<T> {
    data?: T
    error?: Error | AxiosError
    loading?: boolean
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_URL;

export const api = axios.create({
    baseURL: apiBaseUrl
});

export const useFetch = <T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
    afterFetch?: (data: T, setState:  React.Dispatch<React.SetStateAction<State<T>>>) => void
) => {
    const {token} = useAppSelector(state => state.auth)
    const [state, setState] = useState<State<T>>({
        data: undefined,
        error: undefined,
        loading: undefined
    })

    const [fetch, setFetch] = useState(false)

    useEffect(() => {
        const source = axios.CancelToken.source()
        const fetchData = async () => {
            setState(prev => ({...prev, loading: true}))

            try {
                const response = await api(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'get',
                    cancelToken: source.token,
                    ...config
                })
                if(afterFetch){
                    afterFetch(response.data, setState)
                }
                setState(prev => ({...prev, data: response.data as T}))
            } catch (e) {
                if(axios.isAxiosError(e)){
                    setState(prev => ({...prev, error: e as AxiosError}))
                } else {
                    setState(prev => ({...prev, error: e as Error}))
                }

            } finally {
                setState(prev => ({...prev, loading: false}))
            }
        }

        void fetchData()

        return () => {
            source.cancel()
        }
    }, [url, token, fetch])

    const triggerFetch = () => setFetch(prev => !prev)

    return {
        data: state.data,
        loading: state.loading,
        error: state.error,
        fetch: triggerFetch
    }
}
