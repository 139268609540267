import React, {useEffect, useState} from "react";

import FlatFee from "./flat-fee/FlatFee";
import PaidMedia from "./paid-media/PaidMedia";
import TrafficBased from "./traffic-based/TrafficBased";
import Dropdown from "../../../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../../../common/components/dropdown/Dropdown.style";
import {ModalFooter} from "../../../../../../../../common/components/modal/container/Container.style";
import {Cancel} from "../../../../home/add-tracking-platform-modal/AddTrackingPlatformModal.style";
import {Button} from "../../../../home/add-tracking-platform-modal/AddTrackingPlatformModal.style";
import InputMultiLine from "../../../../../../../../common/components/input-multi-line/InputMultiLine";
import Checkbox from "../../../../../../../../common/components/checkbox/Checkbox";

import {Wrapper, InputSingleStyled, Label, FeeTypeTooltip} from "./CreateNewFeeModal.style";
import Tooltip from "../../../../../../../../common/components/tooltip/Tooltip";
import BillingService, {IFetchAffiliatesResponse, ISaveFeeParams, ITier} from "../../../Billing.service";
import {IPlatformAffluent} from "../../../../AdvertisersEdit.service";
import {useAppSelector} from "../../../../../../../../common/hooks";
import {tokenStore} from "../../../../../../../../store/auth.slice";
import {useParams} from "react-router-dom";
import {AxiosError} from "axios";
import {formatNumberToCurrency} from "../../../../../../../../common/utils";

interface ICreateNewFeeModalProps {
    affiliates: IFetchAffiliatesResponse[]
    platforms: IPlatformAffluent[]
    fetchFees: () => void
    closeModal: () => void
    setAlertModal: (modal: {type: 'success' | 'error', message: string, opened: boolean}) => void
    data: any
}

const CreateNewFeeModal: React.FC<ICreateNewFeeModalProps> = ({
                                                                  affiliates,
                                                                  platforms,
                                                                  fetchFees,
                                                                  closeModal,
                                                                  setAlertModal,
                                                                  data
}) => {
    const {id} = useParams()
    const token = useAppSelector(tokenStore)
    const [feeType, setFeeType] = useState<number>(0)
    const [feeTypeDropdownOpened, setFeeTypeDropdownOpened] = useState(false)
    const [feeName, setFeeName] = useState<string>('')
    const [feeDescription, setFeeDescription] = useState<string>('')
    const [isPassThroughFee, setIsPassThroughFee] = useState(false)
    const [feeNameExists, setFeeNameExists] = useState<boolean>(false)

    //Paid Media
    const [dataSource, setDataSource] = useState<string | null>(null)

    //Flat Fee
    const [feeAmount, setFeeAmount] = useState<string | null>(null)
    const [startDate, setStartDate] = useState<Date | undefined | null>(null)
    const [endDate, setEndDate] = useState<Date | undefined | null>(null)
    const [recurring, setRecurring] = useState(1)

    //Traffic Based
    const [trafficSource, setTrafficSource] = useState<number |null>(null)
    const [partnersIncluded, setPartnersIncluded] = useState(1)
    const [selectedAffiliates, setSelectedAffiliates] = useState<IFetchAffiliatesResponse[]>([])
    const [monthlyMinimum, setMonthlyMinimum] = useState('')
    const [calculateBy, setCalculateBy] = useState<string | null>(null)

    const [saveFeeLoading, setSaveFeeLoading] = useState(false)

    const [tiers, setTiers] = useState<ITier[]>([{
        order: 1,
        from: '',
        to: '',
        percentage: ''
    }])

    useEffect(() => {
        if(data){
            setFeeName(data.name)
            setFeeDescription(data.description)
            setFeeType(data.type)
            setIsPassThroughFee(data.pass_through)
            setRecurring(data.recurring)
            setStartDate(data.start_date)
            setEndDate(data.end_date)
            setTrafficSource(data.traffic_source_id)
            setFeeAmount(formatNumberToCurrency(data.amount))
            setDataSource(data.data_source)
            setMonthlyMinimum(data.monthly_minimum_charge === 0 ? '' : formatNumberToCurrency(data.monthly_minimum_charge))
            setCalculateBy(data.calculate_by)
            setPartnersIncluded(data.include_users)
            setSelectedAffiliates(data.users ? data.users : [])

            if(data.tiers.length > 0){
                const formatedTiers = data.tiers.map((tier: any) => {
                    return {
                        ...tier,
                        from: formatNumberToCurrency(parseFloat(tier.from)),
                        to: tier.to === 0 ? '' : formatNumberToCurrency(parseFloat(tier.to)),
                        percentage: tier.percentage + '%'
                    }
                })
                setTiers(formatedTiers)
            }
        }
    }, [data])

    const checkFeeName = async (name: string) => {
        if(data && data.name === name) return
        if(id){
            const {data} = await BillingService.checkIfFeeNameExists(token, id, name, 'fee')
            setFeeNameExists(data.exists)
        }
    }

    const renderModalContent = () => {
        switch (feeType){
            case 1:
                return <FlatFee
                    feeAmount={feeAmount}
                    setFeeAmount={setFeeAmount}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    recurring={recurring}
                    setRecurring={setRecurring}
                />
            case 3:
                return <PaidMedia dataSource={dataSource} setDataSource={setDataSource}/>
            case 2:
                return <TrafficBased
                    trafficSource={trafficSource}
                    setTrafficSource={setTrafficSource}
                    partnersIncluded={partnersIncluded}
                    setPartnersIncluded={setPartnersIncluded}
                    tiers={tiers}
                    setTiers={setTiers}
                    allAffiliates={affiliates}
                    selectedAffiliates={selectedAffiliates}
                    setSelectedAffiliates={setSelectedAffiliates}
                    platforms={platforms}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    monthlyMinimum={monthlyMinimum}
                    setMonthlyMinimum={setMonthlyMinimum}
                    passThrough={isPassThroughFee}
                    calculateBy={calculateBy}
                    setCalculateBy={setCalculateBy}
                />
            default:
                return
        }
    }

    const handleFeeTypeSelect = (type: number) => {
        setFeeType(type)
        setFeeTypeDropdownOpened(false)
    }

    const isSaveButtonDisabled = () => {
        if (feeNameExists || feeName === '' || feeType === 0 || saveFeeLoading) return true

        switch (feeType) {
            case 1:
                if(feeAmount === undefined || startDate === null) return true
                break;
            case 3:
                if(!dataSource) return true
                break;
            case 2:
                if(trafficSource === null || !startDate || !calculateBy) return true
                break;

        }

        if(tiers.some((tier, index) =>
            parseFloat(tier.to.toString().replace(/[^0-9.]+/g, ''))
            <= parseFloat(tier.from.toString().replace(/[^0-9.]+/g, '')))){
            return true
        }

        return tiers.some((tier, index) => {
            if(tiers[index - 1]){
                return parseFloat(tier.from.toString().replace(/[^0-9.]+/g, ''))
                    <= parseFloat(tiers[index - 1].to.toString().replace(/[^0-9.]+/g, ''))
            }
            return false
        })

        // if(c){
        //     return true
        // }

        // tiers.forEach((tier, index) => {
        //     if(parseFloat(tier.to.toString().replace(/[^0-9.]+/g, '')) <= parseFloat(tier.from.toString().replace(/[^0-9.]+/g, ''))){
        //         return true
        //     }
        //     if(tiers[index - 1]){
        //         if(parseFloat(tier.from.toString().replace(/[^0-9.]+/g, '')) <= parseFloat(tiers[index - 1].to.toString().replace(/[^0-9.]+/g, ''))){
        //             return true
        //         }
        //     }
        //
        //     return false
        // })
    }

    const handleSubmit = async () => {
        const params: ISaveFeeParams = {
            tiers: tiers,
            amount: feeAmount,
            calculate_by: calculateBy,
            name: feeName,
            description: feeDescription,
            type: feeType,
            pass_through: isPassThroughFee,
            recurring: recurring,
            start_date: startDate,
            end_date: endDate,
            traffic_source_id: trafficSource,
            data_source: dataSource,
            include_users: partnersIncluded,
            selected_users: selectedAffiliates,
            monthly_minimum_charge: monthlyMinimum
        }
        let editParams: any = {}
        setSaveFeeLoading(true)

        if(id){
            try{
                if(data){
                    editParams = {...data, ...params}
                    await BillingService.saveFee(token, id, editParams)
                } else {
                    await BillingService.saveFee(token, id, params)
                }
                setSaveFeeLoading(false)
                setAlertModal({
                    opened: true,
                    message: `Success! ${feeName} has been ${data ? 'edited' : 'created'}.`,
                    type: 'success'
                })
                fetchFees()
            } catch (e: any) {
                setAlertModal({
                    opened: true,
                    message: `Fail! Fee has not been ${data ? 'edited' : 'created'}.`,
                    type: 'error'
                })
            }
        }

        closeModal()
    }

    return <Wrapper>
        {/*-----------FEE NAME-----------*/}
        <div className="row">
            <Label>Fee Name</Label>
            {feeNameExists && <span className={'input-error'}>A fee with that name already exists, please use a different name.</span>}
            <InputSingleStyled
                onChange={(e) => setFeeName(e.target.value)}
                placeholder={'Enter a fee name'}
                value={feeName}
                onBlur={(e) => checkFeeName(e.target.value)}
            />
        </div>

        {/*-----------FEE DESCRIPTION-------------*/}
        <div className={'row'}>
            <Label>Description for Internal Use</Label>
            <InputMultiLine
                onChange={setFeeDescription}
                value={feeDescription}
                placeholder={'Type a description for what this fee means'}
            />
        </div>
        {/*-----------FEE TYPE----------*/}

        <Label>
                Fee Type
                <Tooltip position={'top'}>
                    <FeeTypeTooltip>
                        <p>This option sets type of fee, some examples are:</p>
                        <br/>
                        <span className={'fee-type--tooltip'}>Flat:</span>
                        <p>Compliance Fee / Management Fee / Setup Fee / Additional Charges</p>
                        <br/>
                        <span className={'fee-type--tooltip'}>Traffic Based:</span>
                        <p>Tiered Ad Spend / Platform Pass-through / Non-tiered Ad Spend that will be combined with another fee such as paid media in a tiered group</p>
                        <br/>
                        <span className={'fee-type--tooltip'}>Paid Media:</span>
                        <p>Direct Media Placements generally from Salesforce</p>
                    </FeeTypeTooltip>
                </Tooltip>
        </Label>
        <div className="fee-type">
            <div style={{minWidth: '305px'}}>
                <Dropdown
                    text={`${feeType === 1 ? 'Flat Fee' : feeType === 2 ? 'Traffic Based' : feeType === 3 ? 'Paid Media' : ''}`}
                    placeholder={'Select fee type'}
                    isOpened={feeTypeDropdownOpened}
                    setOpened={setFeeTypeDropdownOpened}
                >
                    <DropdownItem
                        onClick={() => handleFeeTypeSelect(1)}
                        selected={feeType === 1}
                    >
                        Flat Fee
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => handleFeeTypeSelect(2)}
                        selected={feeType === 2}
                    >
                        Traffic Based
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => handleFeeTypeSelect(3)}
                        selected={feeType === 3}
                    >
                        Paid Media
                    </DropdownItem>
                </Dropdown>
            </div>
            <div>
                <div style={{width: 'fit-content', display: 'inline-block', marginRight: '3px'}}>
                    <Checkbox
                        disabled={!feeType}
                        checked={isPassThroughFee}
                        onChange={() => setIsPassThroughFee(!isPassThroughFee)}
                        label={'Set as pass-through fee'}
                    />
                </div>
                <Tooltip position={'top'}>
                    <div style={{width: '272px'}}>
                        Setting a fee as a pass-through fee will ignore the fee calculation in reports for PerformCB’s revenue.
                    </div>
                </Tooltip>
            </div>
        </div>

        {
            renderModalContent()
        }
        <ModalFooter>
            <Cancel onClick={() => closeModal()}>Cancel</Cancel>
            <Button onClick={handleSubmit} disabled={isSaveButtonDisabled()}>Save</Button>
        </ModalFooter>
    </Wrapper>
}

export default CreateNewFeeModal