import {createGlobalStyle} from "styled-components";
//@ts-ignore
import LeapTTF from '../assets/icons/leap-icons-v1.3/fonts/leap-icons.ttf'
import LeapSvg from '../assets/icons/leap-icons-v1.3/fonts/leap-icons.svg'
//@ts-ignore
import LeapWoff from '../assets/icons/leap-icons-v1.3/fonts/leap-icons.woff'

// const leapIconPath = '../assets/icons/leap-icons-v1.1/fonts/'

export default createGlobalStyle`
  @font-face {
    font-family: 'leap';
    src: url(${LeapTTF}) format('truetype'),
    url(${LeapWoff}) format('woff'),
    url(${LeapSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="ico-"], [class*=" ico-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'leap' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ico-plus:before {
      content: "\\e958";
  }
  .ico-ChevronLeft:before {
      content: "\\e902";
  }

  .ico-ChevronUp:before {
      content: "\\e904";
  }

  .ico-ChevronRight:before {
      content: "\\e903";
  }

  .ico-ChevronDown:before {
      content: "\\e901";
  }

  .ico-Notifications:before {
      content: "\\e913";
  }

  .ico-Hamburger-Menu:before {
      content: "\\e911";
  }

  .ico-Search:before {
      content: "\\e91b";
  }

  .ico-Gift:before {
      content: "\\e90f";
  }

  .ico-Exit-outlined:before {
      content: "\\e90d";
  }

  .ico-Email-Send:before {
      content: "\\e90c";
  }

  .ico-Download:before {
      content: "\\e90b";
  }

  .ico-Create-Link:before {
      content: "\\e90a";
  }

  .ico-Columns:before {
      content: "\\e905";
  }

  .ico-Refresh:before {
      content: "\\e91a";
  }

  .ico-OpenReport:before {
      content: "\\e945";
  }

  .ico-FiltersOutlined:before {
      content: "\\e94c";
  }

  .ico-Cost:before {
      content: "\\e909";
  }

  .ico-Conversion:before {
      content: "\\e908";
  }

  .ico-Conversion-Cost:before {
      content: "\\e907";
  }

  .ico-Partners:before {
      content: "\\e918";
  }

  .ico-Offer:before {
      content: "\\e917";
  }

  .ico-Offer-Add:before {
      content: "\\e914";
  }

  .ico-Offer-Group:before {
      content: "\\e915";
  }

  .ico-Offer-Paused:before {
      content: "\\e916";
  }

  .ico-Group:before {
      content: "\\e910";
  }

  .ico-Expand:before {
      content: "\\e90e";
  }

  .ico-Contract:before {
      content: "\\e906";
  }

  .ico-Information-outlined:before {
      content: "\\e912";
  }

  .ico-Check:before {
      content: "\\e900";
  }

  .ico-Upload:before {
      content: "\\e91c";
  }

  .ico-Question-outlined:before {
      content: "\\e919";
  }

  .ico-PlusCircle:before {
      content: "\\e94d";
  }

  .ico-Partnership:before {
      content: "\\e946";
  }

  .ico-DynamicReport:before {
      content: "\\e940";
  }

  .ico-Play:before {
      content: "\\e94b";
  }

  .ico-Calendar:before {
      content: "\\e94a";
  }

  .ico-Edit:before {
      content: "\\e941";
  }

  .ico-Eye:before {
      content: "\\e942";
  }

  .ico-Wrench:before {
      content: "\\e949";
  }

  .ico-Adjust:before {
      content: "\\e93a";
  }

  .ico-Rocket:before {
      content: "\\e948";
  }

  .ico-Connected:before {
      content: "\\e93e";
  }

  .ico-copy:before {
      content: "\\e952";
  }

  .ico-graph-outlined:before {
      content: "\\e953";
  }

  .ico-Dashboard:before {
      content: "\\e925";
  }

  .ico-Manage:before {
      content: "\\e92e";
  }

  .ico-Grow:before {
      content: "\\e92b";
  }

  .ico-Account:before {
      content: "\\e91d";
  }

  .ico-Control:before {
      content: "\\e923";
  }

  .ico-Person:before {
      content: "\\e931";
  }

  .ico-Email:before {
      content: "\\e928";
  }

  .ico-Skype:before {
      content: "\\e935";
  }

  .ico-Feedback:before {
      content: "\\e92a";
  }

  .ico-Pin:before {
      content: "\\e933";
  }

  .ico-Unpin:before {
      content: "\\e939";
  }

  .ico-Support:before {
      content: "\\e937";
  }

  .ico-Support-help:before {
      content: "\\e936";
  }

  .ico-Exit:before {
      content: "\\e929";
  }

  .ico-PlusCircleFilled:before {
      content: "\\e94e";
  }

  .ico-ToggleOff:before {
      content: "\\e94f";
  }

  .ico-ToggleOn:before {
      content: "\\e950";
  }

  .ico-AngleLeft:before {
      content: "\\e920";
  }

  .ico-AngleUp:before {
      content: "\\e922";
  }

  .ico-AngleRight:before {
      content: "\\e921";
  }

  .ico-AngleDown:before {
      content: "\\e91f";
  }

  .ico-More:before {
      content: "\\e92f";
  }

  .ico-Customize:before {
      content: "\\e924";
  }

  .ico-Information:before {
      content: "\\e92c";
  }

  .ico-Phone:before {
      content: "\\e932";
  }

  .ico-Locked:before {
      content: "\\e92d";
  }

  .ico-Unlocked:before {
      content: "\\e938";
  }

  .ico-Delete:before {
      content: "\\e926";
  }

  .ico-Diamond:before {
      content: "\\e927";
  }

  .ico-Asterisk:before {
      content: "\\e91e";
  }

  .ico-ColumnFilled:before {
      content: "\\e93c";
  }

  .ico-Filters:before {
      content: "\\e944";
  }

  .ico-ColumnsAndFilters:before {
      content: "\\e93d";
  }

  .ico-Question:before {
      content: "\\e934";
  }

  .ico-LetterP:before {
      content: "\\e951";
  }

  .ico-Drag:before {
      content: "\\e93f";
  }

  .ico-PersonPlus:before {
      content: "\\e947";
  }

  .ico-CalendarFilled:before {
      content: "\\e93b";
  }

  .ico-EyeFilled:before {
      content: "\\e943";
  }

  .ico-Performcb:before {
      content: "\\e930";
  }

  .ico-Facebook-outlined:before {
      content: "\\e954";
  }

  .ico-Instagram:before {
      content: "\\e955";
  }

  .ico-Linkedin-outlined:before {
      content: "\\e956";
  }

  .ico-Twitter-outlined:before {
      content: "\\e957";
  }
`
