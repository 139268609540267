import React from "react";

import {Wrapper} from "./DeleteTrackingPlatformModal.style";

import {IDeleteTrackingPlatformModalProps} from "./deleteTrackingPlatformModal.props";

import AdvertisersEditService from "../../AdvertisersEdit.service";
import {Button, Cancel} from "../add-tracking-platform-modal/AddTrackingPlatformModal.style";

import {useAppSelector} from "../../../../../../common/hooks";

const DeleteTrackingPlatformModal: React.FC<IDeleteTrackingPlatformModalProps> = ({
                                                                                      platform,
                                                                                      closeModal,
                                                                                      fetchPlatforms
                                                                                  }) => {
    const {token} = useAppSelector(state => state.auth)

    const handleSubmit = async () => {
        try {
            await AdvertisersEditService.deleteTrackingPlatform(token, platform)
            await fetchPlatforms()
            closeModal()
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Wrapper>
            <p>Are you sure you want to delete this tracking platform?</p>
            <br/>
            <p>This tracking platform could be used for billing fees, removing it could cause
                issues in the data reported.</p>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: '50px'
            }}>
                <Cancel onClick={closeModal}>
                    Cancel
                </Cancel>
                <Button onClick={handleSubmit}>
                    Yes, delete this tracking platform.
                </Button>
            </div>
        </Wrapper>
    )
}

export default DeleteTrackingPlatformModal