import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitState {
    marketerSearchValue: string,
    sort: {
        direction: 'desc' | 'asc',
        by: string
    },
    per_page: number,
        
}

const initialState: InitState = {
    marketerSearchValue: '',
    sort: {
        direction: "desc",
        by: 'id'
    },
    per_page: 50
}

const advertisersSlice = createSlice({
    name: 'advertisers',
    initialState,
    reducers: {
        setSearchMarketerValue: (state: InitState, action: PayloadAction<string>) => {
            state.marketerSearchValue = action.payload
        },
        setSort: (state: InitState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.sort = action.payload
        },
        setPerPage: (state: InitState, action:PayloadAction<number>) => {
            state.per_page = action.payload;
        },
    }
})

export default advertisersSlice.reducer
export const {
    setSearchMarketerValue,
    setPerPage,
    setSort
} = advertisersSlice.actions