import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  max-width: 532px;
`

export const ModalBody = styled.div`
  
`

export const Optional = styled.span`
  color : ${({theme}) => theme.colors.deepBlue[4]};
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
`
export const InfoBox = styled.div`
  color: ${({theme}) => theme.colors.information[7]};
  display: flex;
  align-items: center;

  i {
    color: ${({theme}) => theme.colors.information[6]};
    font-size: 24px;
    margin-right: 18px;
  }
`
