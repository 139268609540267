import React from 'react'
import {ButtonStyled} from "../../../../../common/styled-components";
import {DestinationParamsSelect} from "./DestinationParamsSelect";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";

export const GoogleAnalyticsSection: React.FC = () => {
    const {offer, trackingSystems} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const appendMacros = () => {
        const macrosToAdd = trackingSystems.find(system => system.slug === offer.trackingSystem)?.tracking_params_and_macros
        dispatch(setOffer({
            preview_url: offer.preview_url + macrosToAdd
        }))
    }

    const appendParams = (param: string) => {
        dispatch(setOffer({
            preview_url: offer.preview_url + param
        }))
    }

    return <div className={'google'}>
        <ButtonStyled
            style={{
                marginBottom: '16px'
            }}
            onClick={() => appendMacros()}
            className={"btn-blue-outlined btn-block btn-size-lg full-radius tracking-system-btn"}>
            add {offer.trackingSystem} parameters & macros
        </ButtonStyled>
        <DestinationParamsSelect onSelect={(param) => appendParams(param)}/>
    </div>
}