import styled, {css} from 'styled-components'

interface IDateSelectorOptionProps {
    $selected: boolean
}

export const Wrapper = styled.div`
  .highcharts-label, .highcharts-tooltip, .highcharts-color-undefined{
    background: red !important;
  }

  .highcharts-tooltip {
    z-index: 99999!important;
    .hc-tooltip-wrapper {
      position: relative;
      padding: 5px;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        height: calc(100% + 16px);
        width: calc(100% + 16px);
        top: -8px;
        left: -8px;
        background: #fafafa;
        z-index: 0;
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }

      .hc-tooltip-inner {
        .hc-tooltip-title {
          font-size: 10px;
          font-family: ${({theme}) => theme.fonts.AvenirLight};
          color: #31383B;
          margin-bottom: 4px;
        }
        .tooltip-value-item {
          display: flex;
          align-items: center;
          font-size: 10px;
          position: relative;
          z-index: 1;
          margin-top: 4px;
          .marker {
            border-radius: 2px;
            background: gray;
            margin-right: 5px;
            height: 8px;
            width: 8px;
          }
          strong{
            font-family: ${({theme}) => theme.fonts.AvenirHeavy};
            
          }
        }
      }

      h1 {
        position: relative;
        z-index: 1;
      }
      div {
        position: relative;
        z-index: 1;
      }
    }
   
  }
`

export const AnalyzeFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const DateSelector = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  display: flex;
  padding: 4px;

`

export const DateSelectorOption = styled.div<IDateSelectorOptionProps>`
  border-radius: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: center;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  color: ${({theme}) => theme.colors.deepBlue[4]};
  :hover{
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    cursor: pointer;
  }
  
  ${props => props.$selected && css`
    background-color: ${({theme}) => theme.colors.performBlue[5]};
    color: white;

    :hover{
      background-color: ${({theme}) => theme.colors.performBlue[5]};
      cursor: default;
    }
  `}
`

export const AnalyzeBy = styled.div`
  display: flex;
  gap: 10px;
  
  .analyzeBy-header {
    padding: 0 12px;
    min-height: 24px;
    max-height: 24px;
    font-size: 12px;
    .analyze-by{
      display: flex;
      label{
        white-space: nowrap;
        margin-right: 4px;
      }
      span{
        color: ${({theme}) => theme.colors.performBlue[7]};
        white-space: nowrap;
      }
      
    }
  }
  
  .analyzeBy-container{
    max-width: 180px;
    min-width: 180px;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }
`