import styled, {css} from 'styled-components'

export const Wrapper = styled.div<{error: boolean}>`
    position: relative;
    .special-label{
        color: ${({theme, error}) => !error ? theme.colors.performBlue[5] : '#F23842'};
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 12px;
    }
    
    :focus-within{
        .special-label{
            color: ${({theme, error}) => !error ? theme.colors.performBlue[5] : '#F23842'};
        }
    }
    
    .phone-input-styled {
        padding: 8px 12px;
        font-size: 14px;
        height: 40px;
        padding-left: 70px;
        width: 100%;
        outline: none;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        border-radius: 8px;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        color: ${({theme}) => theme.colors.deepBlue[4]};
        transition: border-color .2s ease;
        
        ::placeholder {
            color: ${({theme}) => theme.colors.coolGray[4]} !important;
        }

        :hover {
            border: 1px solid ${({theme}) => theme.colors.coolGray[5]} !important;
        }

        :focus {
            border: 1px solid ${({theme}) => theme.colors.performBlue[5]} !important;
            box-shadow: none;
            
        }

        :disabled {
            border: 1px solid ${({theme}) => theme.colors.coolGray[3]} !important;
            background-color: white;!important;
            color: ${({theme}) => theme.colors.coolGray[4]};!important;
        }
    }

    ${({error}) => error && css`
        .special-label {
            color: #F23842;
        }

        .phone-input-styled {
            border-color: #F23842;

            :hover {
                border-color: #F23842;
            }

            :focus {
                border-color: #F23842;
            }
        }
    `}
    
    .phone-code-select-container{
        min-width: fit-content;
        border: 1px solid #E9ECED;
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        position: absolute;
        background-color: white;
        top: 33px;
        width: 300px !important;
        //top: 35px;
    }
    
    .phone-code-select-button{
        width: 103px;
        
        border: 1px solid #E9ECED;
        border-radius: 8px;
        font-size: 12px;
        color: ${({theme}) => theme.colors.deepBlue['4']};
        background-color: transparent;
        
        .selected-flag{
            padding: 8px 16px;
            border-radius: 8px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        
        
    }

    .open{
        .selected-flag{
            border-radius: 8px;
        }
        .phone-code-select-button{
            border-radius: 8px;
        }
    }
    
    .country-search-input-box{
        padding: 8px 12px;
        font-size: 12px !important;
        width: calc(100% - 16px);
        height: 32px;
        outline: none;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        border-radius: 8px !important;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        color: ${({theme}) => theme.colors.deepBlue[4]};
        transition: border-color .2s ease;
        
        ::placeholder {
            color: ${({theme}) => theme.colors.coolGray[4]} !important;
        }

        :hover {
            border: 1px solid ${({theme}) => theme.colors.coolGray[5]} !important;
        }

        :focus {
            border: 1px solid ${({theme}) => theme.colors.performBlue[5]} !important;
            box-shadow: none;
            
        }
    }
    
    .country{
        height: 32px;
        padding: 8px;
        margin: 0 16px;
        border-radius: 8px;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        font-size: 12px;
        display: flex;
        align-items: center;

        :hover{
            background-color: ${({theme}) => theme.colors.performBlue['1']} !important;
            cursor: pointer;
        }
        
        .flag {
            margin: 0;
            top: 4px
        }
        
        
    }

    .highlight{
        background-color: ${({theme}) =>  theme.colors.performBlue['8']} !important;
        color: white !important;
        
        :hover{
            background-color: ${({theme}) =>  theme.colors.performBlue['8']} !important;
            color: white !important;
        }
        
        .dial-code{
            color: white !important;
        }
    }
    //.country-search-input{
    //    width: 100%;
    //}
`

export const ErrorStyled = styled.label<{ leftAlign: boolean }>`
    position: absolute;
    bottom: -14px;
    ${({leftAlign}) => leftAlign ? css`
        left: 12px;
    ` : css`
        right: 12px;
    `}

    font-size: 10px;
    color: #F23842;
`