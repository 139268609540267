import React, {useEffect, useState} from "react";
import {
    AlertStyled, ButtonStyled,
    InputStyledContainer,
} from "../../../../../../../common/styled-components";
import {BtnLoadingSpinner} from "../../../../../../../common/styled-components";
import Dropdown from "../../../../../../../common/components/dropdown/Dropdown";
import MergingService, {
    INonMergedPartner,
    IPartnerTrafficType, ITagNonMerged, IUserForMerging,
    IUsersForMerging,
} from "../../Merging.service";
import {DropdownItem} from "../../../../../../../common/components/dropdown/Dropdown.style";
import SelectionPills from "../../../../../../../common/components/selection-pills/SelectionPills";
import {ITag} from "../../../../../../../common/models/tag.type";
import {useAppSelector} from "../../../../../../../common/hooks";
import {tokenStore} from "../../../../../../../store/auth.slice";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import {ModalWrapper, ModalBody, InfoBox, Optional} from "./EditDetailsModal.style";

interface IEditDetailsModalProps {
    selectedPartners: INonMergedPartner,
    addedTags: ITag[],
    tags: ITag[],
    saveDetails: (selectedTagsNum: number[]) => void,
    trafficTypeFromPartners: IPartnerTrafficType
    closeModal: () => void,
    clearSelectedRow: () => void
}

const EditDetailsModal : React.FC<IEditDetailsModalProps> = ({
                                                                 selectedPartners,
                                                                 addedTags,
                                                                 tags,
                                                                 saveDetails,
                                                                 trafficTypeFromPartners,
                                                                 closeModal,
                                                                 clearSelectedRow,
                                                             }) => {
    const token = useAppSelector(tokenStore)

    const [loading, setLoading] = useState(false)

    // <---------- TAGS START --------->
    const [openTags, setOpenTags] = useState<boolean>(false);
    const [searchTag, setSearchTag] = useState<string>("");
    const [filteredTags, setFilteredTags] = useState<ITag[]>(tags);
    const [selectedTags, setSelectedTags] = useState<ITag[]>(addedTags);
    const filterTags = () => {
        setFilteredTags(tags.filter(tag => {
                return tag.name.toLowerCase().includes(searchTag.toLowerCase()) ||
                    tag.id.toString().includes(searchTag.toLowerCase())
            })
        )
    }
    const checkIfTagIsSelected = (selectedTag: ITag) => {
        return selectedTags.some(tag => tag.id === selectedTag.id)
    }
    const handleTagSelect = (tag: ITag) => {
        if (checkIfTagIsSelected(tag)) {
            const newTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id)
            setSelectedTags(newTags)
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }
    // <---------- TAGS END --------->

    // <---------- TRAFFIC TYPE START --------->
    const [openTrafficType, setOpenTrafficType] = useState<boolean>(false)
    const [selectedTrafficType, setSelectedTrafficType] = useState<IPartnerTrafficType>(trafficTypeFromPartners)
    const [trafficTypes, setTrafficTypes] = useState<IPartnerTrafficType[]>([])
    const isSelected = (id: number) => selectedTrafficType.id === id;
    const fetchPartnerTrafficTypes = async () => {
        let {data} = await MergingService.fetchTrafficTypes(token)
        data.unshift({
            id: 0,
            name: "- Select traffic type -"
        })
        setTrafficTypes(data)
    }
    // <---------- TRAFFIC TYPE END --------->

    useEffect(() => {
        filterTags()
    }, [searchTag])

    useEffect(() => {
        // setName(selectedPartners.name)
        setSelectedTrafficType(selectedPartners.partner_traffic_type ? selectedPartners.partner_traffic_type[0] : trafficTypeFromPartners)
    }, [])

    useEffect(() => {
        fetchPartnerTrafficTypes();
    }, [])


    const saveModalDetails = async () => {
        let nonMergedPartnerDetail: IUsersForMerging = {} as IUsersForMerging

        nonMergedPartnerDetail.tags = selectedTags
        nonMergedPartnerDetail.traffic_types = selectedTrafficType.id !== 0 ? [selectedTrafficType.id] : null
        nonMergedPartnerDetail.users = []
        let nonMergedUser: IUserForMerging = {
            name: selectedPartners.platform_partner_name ? selectedPartners.platform_partner_name : "",
            company_name: selectedPartners.platform_partner_company ? selectedPartners.platform_partner_company : "",
            id: selectedPartners.platform_partner_id,
            model_id: selectedPartners.id,
            platform_id: selectedPartners.platform_id,
            tags: selectedPartners.tags.map((tag: ITagNonMerged) => {
                return {id: tag.tag_id, name: tag.name}
            })
        }
        nonMergedPartnerDetail.users.push(nonMergedUser)

        try {
            setLoading(true)
            await MergingService.saveNonMergedPartnerDetails(token, nonMergedPartnerDetail)

            saveDetails(selectedTags.map((tag: ITag) => {
                return tag.id
            }))
            setSelectedTags([])
            setLoading(false)

            closeModal()
            clearSelectedRow()
        } catch (e) {
            console.log(e)
        }
    }

    return(
        <ModalWrapper>
            <ModalBody>
                <AlertStyled type={'info'} border={false} style={{marginBottom: 32}}>
                    <InfoBox>
                        <i className={'ico-Information'}/>
                        <span>Any details changed here are global and will affect all users in the platform. All reports and filters related to this partner will be updated immediately upon clicking save details.</span>
                    </InfoBox>
                </AlertStyled>

                <InputStyledContainer style={{paddingBottom: 32}}>
                    <Dropdown text={selectedTrafficType.name}
                              placeholder={"Select traffic type"}
                              dropdownHeightLimit={6}
                              isOpened={openTrafficType}
                              disabled={false}
                              optionSelect={true}
                              setOpened={setOpenTrafficType}
                              label={<>Merged Partner Traffic Type <Optional>(optional)</Optional></>}>
                        {
                            trafficTypes.map((type: IPartnerTrafficType) => (
                                <DropdownItem
                                    onClick={() => {
                                        setSelectedTrafficType(type)
                                        setOpenTrafficType(false)
                                    }}
                                    selected={isSelected(type.id)}
                                    key={type.id}>
                                    {type.name}
                                </DropdownItem>
                            ))
                        }
                    </Dropdown>
                </InputStyledContainer>
                <InputStyledContainer>
                    <Dropdown text={"Type a tag to add"}
                              isOpened={openTags}
                              setOpened={setOpenTags}
                              dropdownHeightLimit={6}
                              helpBlock={"Using the field above, type out a tag you would like to use."}
                              search={{
                                  value: searchTag,
                                  onChange: setSearchTag,
                                  placeholder: "Type a tag to add"
                              }}
                              headerClassName={'hide-caret'}
                              containerClassName={searchTag.length < 1 ? 'hidden' : ''}
                              label={<>Merged Partner Tags <Optional>(optional)</Optional></>}>
                        {
                            filteredTags.map(tag => (
                                <DropdownItem
                                    onClick={()=> {
                                        handleTagSelect(tag)
                                        setOpenTags(false)
                                    }}
                                    disabled={selectedTags.some(selectedTag => tag.id === selectedTag.id)}
                                    key={tag.id}>
                                    {tag.name}
                                </DropdownItem>
                            ))
                        }
                    </Dropdown>
                    {selectedTags.length > 0 &&
                        <div style={{marginTop: 32}}>
                            {selectedTags.map(tag =>
                                <SelectionPills key={tag.id}
                                                text={tag.name} onRemove={() => handleTagSelect(tag)}/>)}
                        </div>
                    }

                </InputStyledContainer>
            </ModalBody>

            <ModalFooter>
                <ButtonStyled className={"btn-cancel"}
                              onClick={closeModal}>Cancel</ButtonStyled>
                <ButtonStyled className={"btn-orange-filled"}
                              onClick={saveModalDetails}
                              disabled={loading}>
                    <span className={"btn-label"}>{loading && <BtnLoadingSpinner style={{marginRight: 5}} />}Save Details</span>
                </ButtonStyled>
            </ModalFooter>
        </ModalWrapper>
    )

}

export default EditDetailsModal
