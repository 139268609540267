import React from 'react';
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";

interface Props {
    opened: boolean
}

const SaveDraftSuccessModal: React.FC<Props> = ({opened}) => {
    return (
        <>
            <AlertModal isOpen={opened}
                        type={"success"}
                        closeButtonClassName={"hide"}
                        closeModal={()=>{}}>
                <div>
                    Your campaign has been saved.
                    <br/><br/>
                    Redirecting to your campaigns now...
                </div>
            </AlertModal>
        </>
    )
}

export default SaveDraftSuccessModal