import React, {useEffect} from 'react'

import {NavigationItemStyled} from "./Navigation.style";
import {IDelayedActionsSections, navigationList} from "../../interfaces/interfaces";
import {useAppSelector} from "../../../../../../common/hooks";
import {ActiveIcon, CompletedIcon, LockIcon, IncompleteIcon, AdjustedIcon} from "../../shared-components/Shared";
import {useLocation} from "react-router-dom";
import {DraftIcon} from "../../../../../../assets/svgs/SvgIcons";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import _ from "lodash"
interface Props {
    nav: typeof navigationList[number],
    children?: React.ReactNode
}

const NavigationItem: React.FC<Props> = ({
                                             nav,
                                             children}) => {
    const {offer, navState} = useAppSelector(state => state.createOffer)
    const location = useLocation()
    const showDelayedActionsTooltips = (delayedActions: IDelayedActionsSections, section: string) => {
        let content = <div></div>
        let hasDelayedActions = false
        // @ts-ignore
        if (delayedActions[section]?.compliance_delayed.length > 0 && delayedActions[section]?.time_delayed.length > 0) {
            hasDelayedActions = true
            content = <div className={"delayed-action-tooltip"} >The following changes are pending approval before taking effect:
                <ul className={"tooltip-list"}>
                    {
                        // @ts-ignore
                        delayedActions[section]?.compliance_delayed.map(action =>
                         <li key={_.uniqueId(action.fieldName)}>{action.description}</li> )
                    }
                </ul>
                The following changes will take effect in 24 hours from when they were submitted:
                <ul className={"tooltip-list"}>
                    {
                        // @ts-ignore
                        delayedActions[section]?.time_delayed.map(action =>
                            <li key={_.uniqueId(action.fieldName)}>{action.description}</li> )
                    }
                </ul>
            </div>
        }
        // @ts-ignore
        else if (delayedActions[section]?.compliance_delayed.length > 0) {
            hasDelayedActions = true
            content = <div className={"delayed-action-tooltip"} >The following changes are pending approval before taking effect:
                <ul className={"tooltip-list"}>
                    {
                        // @ts-ignore
                        delayedActions[section]?.compliance_delayed.map(action =>
                            <li key={_.uniqueId(action.fieldName)}>{action.description}</li> )
        
                    }
                </ul>
            </div>
            // @ts-ignore
        } else if (delayedActions[section]?.time_delayed.length > 0) {
            hasDelayedActions = true
            content = <div className={"delayed-action-tooltip"} >The following changes will take effect in 24 hours from when they were submitted:
                <ul className={"tooltip-list"}>
                    {
                        // @ts-ignore
                        delayedActions[section]?.time_delayed.map(action =>
                            <li key={_.uniqueId(action.fieldName)}>{action.description}</li> )
        
                    }
                </ul>
            </div>
        }
        
        return hasDelayedActions ? <DraftIcon className={"draft-icon"}
                                              data-html={true}
                                              data-place={"right"}
                                              data-tip={ReactDOMServer.renderToStaticMarkup(<>{content}</>)}/>
                                    : null
    }
    useEffect(()=>{
        ReactTooltip.rebuild()
    })
    
    if(location.pathname.split('/').pop() === nav && navState[nav] !== 'disabled'){
        return <NavigationItemStyled
            to={nav}
            disabled={navState[nav] === 'disabled'}
        >{ActiveIcon} {children} {offer.delayedActions ? showDelayedActionsTooltips(offer.delayedActions, nav) : null}
        </NavigationItemStyled>

    }

    return <NavigationItemStyled
        to={nav}
        disabled={navState[nav] === 'disabled'}
    >
        {navState[nav] === 'disabled' && LockIcon}
        {navState[nav] === 'completed' && CompletedIcon}
        {navState[nav] === 'incompleted' && IncompleteIcon}
        {navState[nav] === 'adjusted' && AdjustedIcon}
        {children}
    
        {
            offer.delayedActions ? showDelayedActionsTooltips(offer.delayedActions, nav) : null
        }
        
    </NavigationItemStyled>
}

export default NavigationItem