import React from "react";
import {Wrapper, SortButtons} from "./SortableHeader.style";
import {ISortableHeaderProps} from "./sortableHeader.props";

const SortableHeader: React.FC<ISortableHeaderProps> = ({keyId, sortDirection, sortedBy, onClick, icon = false, children}) => {

    return <Wrapper onMouseUp={onClick}>
        <span>{children}</span>
        {icon ? 
        <SortButtons >
           {icon}
        </SortButtons>
        :
        <SortButtons >
            {keyId === sortedBy ? (sortDirection === 'desc' ? <i className={'ico-ChevronDown'}/> : <i className={'ico-ChevronUp'}/>) : <><i className={'ico-ChevronUp'}/><i className={'ico-ChevronDown'}/></>}
        </SortButtons>}
    </Wrapper>
}

export default SortableHeader