export const allPaginationColumnsList: {
    name: string,
    id: number,
    checked: boolean,
    column_key?: string
}[] = [
    {
        name: "Date",
        id: 1,
        checked: true,
        column_key: "date",
    },
    {
        name: "Time",
        id: 2,
        checked: true,
        column_key: "time"
    },
    {
        name: "Transaction ID",
        id: 3,
        checked: true,
        column_key: "id"
    },
    {
        name: "Description",
        id: 4,
        checked: true,
        column_key: "description"
    },
    {
        name: "Transaction Type",
        id: 5,
        checked: true,
        column_key: "type"
    },
    {
        name: "Currency Type",
        id: 6,
        checked: true,
        column_key: "currency_type"
    },
    {
        name: "Status",
        id: 7,
        checked: true,
        column_key: "status"
    },
    {
        name: "Change",
        id: 8,
        checked: true,
        column_key: "change"
    },
    {
        name: "Funding Available",
        id: 9,
        checked: true,
        column_key: "funding_available"
    },
    {
        name: "Processed?",
        id: 10,
        checked: true,
        column_key: "payment_processed"
    },
]