import styled, {css} from 'styled-components'

import {InputSingle} from "../../../../../common/components/input-single-line/InputSingleLine.style";
import InputNumber from "../../../../../common/components/input-number/InputNumber";

interface IHiddenProps{
    $hidden: boolean
}
interface IButtonProps {
    disabled: boolean
}

export const Wrapper = styled.div`

  .loading-dots {
    text-align: center;
    .dot {
      animation: loading-dots 1s infinite;
      width: 8px;
      height: 8px;
      background: #EF4A24;
      margin: 0 3px;
      display: inline-block;
      border-radius: 100%;
    }

    .dot:nth-child(2) {
      animation-delay: 0.1s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.3s;
    }

    @keyframes loading-dots {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  
  #tracking-platforms-tooltip{
    position: absolute;
    top: 2px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #tracking-platforms--leap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    
    .open-tracking-platform{
      font-size: 16px;
      color: #0496AA;
      :hover{
        cursor: pointer;
      }
    }
  }
  
  #leap--add-icon{
    width: 13.3px;
    height: 13.3px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.performBlue[6]};
    display: inline-block;
    color: white;
    position: relative;
    
    :hover{
      cursor: pointer;
    }
    
    #leap--add-icon--plus{
      font-size: 14px;
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      position: absolute;
      display: inline-block;
      left: 2px;
      top: -3px;
    }
  }
  
  #leap-input{
    height: 40px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[4]};
    padding: 12px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    line-height: 16px;
    width: 100%;
    pointer-events: none;
  }
  
  .add-platform{
    font-size: 16px;
    color: #0496AA;
    
    :hover{
      cursor: pointer;
    }
  }
  
  #salesforce-marketer-label{
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    margin-top: 8px;
    display: inline-block;
  }
  
  .sections{
    display: flex;
    gap: 30px;
    
    .section{
      width: 33%;
    }
    
    @media (max-width: 800px) {
      flex-direction: column;
      .section{
        width: 100%;
      }
    }
    
  }
  
  
  .section--title{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    font-size: 16px;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 21px;
  }
  
  .section--description{
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    font-size: 12px;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    margin-bottom: 28px;
  }
  
  .row{
    margin-bottom: 30px;
    width: 100%;
    
    .commission-end{
      display: flex;
      gap: 15px;
      justify-content: space-between;
    }
    
    .commission{
      display: flex;
      gap: 15px;
      justify-content: space-between;
    }
  }
  
  .col{
    display: flex;
    align-items: end;
  }
`

export const Label = styled.label`
  display: inline-block;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  margin-bottom: 8px;
  position: relative;
`

export const Hidden = styled.div<IHiddenProps>`
  visibility: ${props => props.$hidden ? 'hidden' : 'visible'};

`

export const TextInput = styled(InputSingle)`
  width: 100%;
  height: 40px;
`

export const NumberInputStyled = styled(InputNumber)`
  height: 40px;
`

export const GoBackButton = styled.span`
    cursor: pointer;
  color: ${({theme}) => theme.colors.deepBlue[4]};
    `

export const Button = styled.div<IButtonProps>`
  padding: 8px 16px;
  color: white;
  font-size: 12px;
  background-color: ${({theme}) => theme.colors.performOrange[3]};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  line-height: 1.55;
  display: inline-block;
  :hover{
    background-color: ${({theme}) => theme.colors.performOrange[4]};
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.performOrange[7]};
  }
  
  ${props => {
    if(props.disabled){
        return css`
          pointer-events: none;
          background-color: ${({theme}) => theme.colors.coolGray['2']};
          color: ${({theme}) => theme.colors.coolGray['4']};
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
        `
    }
    return
}}
`

export const Footer = styled.div`
  margin-bottom: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`

export const FooterLeft = styled.div`
  margin-bottom: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

export const GenPassButton = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 12px;
  background-color: ${({theme}) => theme.colors.pureWhite};
  border: 1px solid #E9ECED;
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  line-height: 1.55;
  display: inline-block;
`