import React from "react";

import PhoneInput, {PhoneInputProps, CountryData} from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {Wrapper, ErrorStyled} from "./PhoneNumberInput.style";

interface Props extends PhoneInputProps {
    error?: string | undefined
    errorClassName?: string,
    leftAlignErrorMessage?: boolean
}

const PhoneNumberInput: React.FC<Props> = ({error, errorClassName, leftAlignErrorMessage = false, ...rest}) => {
    return <Wrapper error={!!error}>
        <PhoneInput
            inputClass={'phone-input-styled'}
            dropdownClass={'phone-code-select-container'}
            searchClass={'country-search-input'}
            enableSearch={true}
            searchPlaceholder={'Search'}
            {...rest}
        />
        {error && <ErrorStyled
            leftAlign={leftAlignErrorMessage}
            className={errorClassName}
        >
            {error}
        </ErrorStyled>}
    </Wrapper>
}

export default PhoneNumberInput