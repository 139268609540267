import React, {ChangeEvent, useState} from 'react'

import {Footer} from "../../../../../common/components/modalWindow/container/Container.style";
import styled from "styled-components";
import {ButtonStyled} from "../../../../../common/styled-components";
import {gender, income, attributes} from "../shared-components/consts";
import Input from "../../../../../common/components/input/Input";
import {formatNumberToCurrency} from "../../../../../common/utils";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {StyledSelect} from "../../../../../common/components/select/Select";

export const Wrapper = styled.div`
    min-width: 500px;
    .form-group {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;
        .form-control {
            margin-bottom: 0;
        }
    }
    
    .ico-Exit {
        font-size: 16px;
        color: ${({theme}) => theme.colors.negative[5]};
        margin-top: 12px;
        
        :hover{
            cursor: pointer;
        }
    }
    
    .payout-input {
        width: 200px;
    }
    
    .modifier-input {
        margin: 0;
        flex-basis: 100%;
    }
    
    .payout-info {
        font-size: 10px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
`

export const AddModifierButton = styled(ButtonStyled).attrs({className: 'btn-blue-outlined'})`
    display: flex;
    align-items: center;
    gap: 5px;
    span {
        font-size: 18px;
    }
`

export interface Props {
    closeModal: () => void,
    addModifiers: (modifiers: any) => void
    payout: any
}

export const AddPayoutModifierModal: React.FC<Props> = (
    {
        closeModal, 
        addModifiers, 
        payout
    }) => {
    
    const [attribute, setAttribute] = useState<string | null>(null)
    const [modifiers, setModifiers] = useState<any[]>([])
    
    const handleAddPayoutModifier = () => {
        switch (attribute) {
            case 'age':
                setModifiers(prev => [...prev, {
                    settings: {
                        from: null,
                        to: null
                    },
                    payout: null,
                    type: 'age',
                }])
                break;
            case 'income':
                setModifiers(prev => [...prev, {
                    settings: {
                        from: null,
                        to: null
                    },
                    payout: null,
                    type: 'income',
                }])
                break;
            case 'gender':
                setModifiers(prev => [...prev, {
                    settings: {
                        gender: null
                    },
                    payout: null,
                    type: 'gender',
                }])
                break;
        }
    }
    
    const handleAttributeSelect = (attr: any) => {
        setAttribute(attr.value)
        switch (attr.value) {
            case 'age':
                setModifiers([{
                    settings: {
                        from: null,
                        to: null
                    },
                    payout: null,
                    type: 'age',
                }])
                break;
            case 'income':
                setModifiers([{
                    settings: {
                        from: null,
                        to: null
                    },
                    payout: null,
                    type: 'income',
                }])
                break;
            case 'gender':
                setModifiers([{
                    settings: {
                        gender: null
                    },
                    payout: null,
                    type: 'gender',
                }])
                break;
        }
    }
    
    const handlePayoutModifierInput = (e: string | undefined, index: number) => {
        const newModifiers = [...modifiers]
        modifiers[index].payout = e
        setModifiers([...newModifiers])
    }
    
    const handleIncomeSelect = (setting: {from: number, to: number | null}, incomeId: number, index: number) => {
        const newModifiers = [...modifiers]
        newModifiers[index] = {...newModifiers[index], settings: {...setting}, income_id: incomeId}
        setModifiers([...newModifiers])
    }
    
    const handleAgeFromChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newModifiers = [...modifiers]
            const newSetting = {...newModifiers[index].settings}
            newSetting.from = e.target.value
            newModifiers[index].settings = {...newSetting}
            setModifiers([...newModifiers])
        }
    }
    
    const handleAgeToChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newModifiers = [...modifiers]
            const newSetting = {...newModifiers[index].settings}
            newSetting.to = e.target.value
            newModifiers[index].settings = {...newSetting}
            setModifiers([...newModifiers])
        }
    }
    
    const handleGenderSelect = (index: number, gender: any) => {
        const newModifiers = [...modifiers]
        modifiers[index].settings = {gender: gender}
        setModifiers([...newModifiers])
    }
    
    const handleRemoveModifier = (index: number) => {
        const newModifiers = [...modifiers]
        newModifiers.splice(index, 1)
        setModifiers([...newModifiers])
    }
    
    const isCreateDisabled = (): boolean => {
        let disabled = false
        if(attribute === null){
            disabled = true
        }
        switch (attribute) {
            case 'income':
                modifiers.forEach(modifier => {
                    if(modifier.payout === null || modifier.payout === ''  || modifier.settings.from ===  null){
                        disabled = true
                    }
                })
                break;
            case 'gender':
                modifiers.forEach(modifier => {
                    if(modifier.payout === null || modifier.payout === ''  || modifier.settings.gender ===  null){
                        disabled = true
                    }
                })
                break;
            case 'age':
                modifiers.forEach(modifier => {
                    if(modifier.payout === null || modifier.payout === '' || modifier.settings.from ===  null || modifier.settings.from ===  ''){
                        disabled = true
                    }
                })
                break;
        }
        return disabled
    }
    
    const handleSaveModifiers = () => {
        console.log(modifiers)
        addModifiers([...modifiers])
        closeModal()
    }
    
    return (
        <Wrapper>
            <div className={'modifier-row'}>
                <div>
                    <StyledSelect
                        selectLabel={'Attribute'}
                        placeholder={'Select Attribute'}
                        onChange={handleAttributeSelect}
                        isSearchable={false}
                        options={attributes.map(attr => {
                            return {
                                label: attr[0].toUpperCase() + attr.slice(1).toLowerCase(),
                                value: attr
                            }
                        })}
                    />
                </div>
                {attribute === 'age' ? <>
                    {modifiers.map((modifier, index) => {
                        return (
                            <div 
                                key={'age_' + index} 
                                className={'form-group'} 
                                style={{display: 'flex', gap: 16}}>
                                <div>
                                    <Input
                                        value={modifier.settings.from ? modifier.settings.from : ''}
                                        onChange={(e) => handleAgeFromChange(e, index)}
                                        label={'From'}
                                        wrapperClassName={'modifier-input'}
                                        pattern={"[0-9]*"}
                                    />
                                </div>
                                <div>
                                    <Input
                                        label={'To'}
                                        onChange={(e) => handleAgeToChange(e, index)}
                                        wrapperClassName={'modifier-input'}
                                        value={modifier.settings.to ? modifier.settings.to : ''}
                                        pattern={"[0-9]*"}
                                    />
                                    <span className={'payout-info'}>(empty for unlimited)</span>
                                </div>
                                <div>
                                    <CurrencyInput
                                        prefix={'$'}
                                        label={'Payout Modifier'}
                                        value={modifier.payout ? modifier.payout : ''}
                                        onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                        wrapperClassName={'modifier-input payout-input'}
                                        placeholder={'$0.00'}
                                        decimalScale={2}
                                    />
                                    <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                                </div>
                                <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                            </div>
                        )
                    })}
                </> : null}

                {attribute === 'gender' ? <>
                    {modifiers.map((modifier, index) => {
                        return (
                            <div 
                                key={'gender_' + index}  
                                className={'form-group'} 
                                style={{display: 'flex', gap: 16}}>
                                <div style={{flex: 1}}>
                                    <StyledSelect 
                                        selectLabel={'Gender'}
                                        placeholder={'Select Gender'}
                                        selectWrapperClassname={'form-control'}
                                        options={gender.settings.map(setting => {
                                            return {
                                                label: setting.gender[0].toUpperCase() 
                                                    + setting.gender.slice(1).toLowerCase(),
                                                value: setting.gender
                                            }
                                        })}
                                        //@ts-ignore
                                        onChange={(e) => handleGenderSelect(index, e.value)}
                                    />
                                </div>
                                <div>
                                    <CurrencyInput
                                        prefix={'$'}
                                        label={'Payout Modifier'}
                                        value={modifier.payout ? modifier.payout : ''}
                                        onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                        wrapperClassName={'modifier-input payout-input'}
                                        placeholder={'$0.00'}
                                        decimalScale={2}
                                    />
                                    <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                                </div>
                                <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                            </div>
                        )
                    })}
                </> : null}

                {attribute === 'income' ? <>
                    {modifiers.map((modifier, index) => {
                        return (
                            <div key={'income_' + index} 
                                 className={'form-group'} 
                                 style={{display: 'flex', gap: 16}}>
                                <div style={{flex: 1}}>
                                    <StyledSelect 
                                        placeholder={'Select Income'}
                                        selectLabel={'Income'}
                                        selectWrapperClassname={'form-control'}
                                        isSearchable={false}
                                        options={income.settings.map((setting, incomeId) => {
                                            return {
                                                label: (formatNumberToCurrency(setting.from) 
                                                    + ' - ' + formatNumberToCurrency(setting.to!)),
                                                value: {from: setting.from, to: setting.to}
                                            }
                                        })}
                                        isOptionDisabled={(option) => 
                                            modifiers.some(mod => mod.income_id === option.value)}
                                        //@ts-ignore
                                        onChange={(option) => handleIncomeSelect(option.value, option.value, index)}
                                    />
                                </div>
                                <div>
                                    <CurrencyInput
                                        prefix={'$'}
                                        label={'Payout Modifier'}
                                        value={modifier.payout ? modifier.payout : ''}
                                        onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                        wrapperClassName={'modifier-input payout-input'}
                                        placeholder={'$0.00'}
                                        decimalScale={2}
                                    />
                                    <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                                </div>
                                <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                            </div>
                        )
                    })}
                </> : null}
                
                {attribute && 
                    <div className="form-group" style={{marginBottom: 24}}>
                        <AddModifierButton onClick={handleAddPayoutModifier}>Add Payout Modifier</AddModifierButton>
                    </div>
                }
                
                <Footer>
                    <ButtonStyled onClick={closeModal} className={'btn-cancel'}>Cancel</ButtonStyled>
                    <ButtonStyled
                        disabled={isCreateDisabled()}
                        className={'btn-orange-filled'}
                        onClick={handleSaveModifiers}
                    >
                        Create
                    </ButtonStyled>
                </Footer>
                
            </div>
        </Wrapper>
    );
};

export default AddPayoutModifierModal