import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";
import {IGroupOptions, StyledSelect,} from "../../../../../common/components/select/Select";
import {Tooltip} from "../shared-components/Shared";
import {VERTICAL_TOOLTIP} from "../shared-components/consts";
import {SelectOption} from "../../../../../common/components/select/Select";
import {CPE, CPI} from "../interfaces/interfaces";

export const Vertical: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        niches
    } = useAppSelector(state => state.createOffer)

    const [groupOptions, setGroupOptions] = useState<IGroupOptions[]>([])

    const handleOnChange = (e: any) => {
        dispatch(setOffer({
            niche: e.value,
            niche_id: e.value.id
        }))
    }

    useEffect(() => {
        if (niches.length > 0) {
            /**
             * If App Install/Engagement filter Mobile verticals, in other cases filter Web verticals.
             * CPS/CPL/CPC/PPC = These offer types will show non-App verticals only
             * CPE/CPI = These offer types will show App verticals only
             */
            let groups: IGroupOptions[] = niches
                .filter(niche => (offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) ? niche.network_id === 2 : niche.network_id === 1)
                .filter(niche => niche.parent_id === 0)
                .map(parent_niche => (
                {
                    label: parent_niche.name,
                    group_id: parent_niche.id,
                    options: niches.filter(niche => niche.parent_id === parent_niche.id).map(niche => (
                        {
                            label: niche.name,
                            value: niche
                        }
                    ))
                }
            ))
            setGroupOptions(groups)
        }
    }, [niches])

    return (
        <StyledSelect
            value={ offer.niche ? {
                label: offer.niche.name,
                value:  offer.niche
            } : null}
            selectLabel={<>Vertical {Tooltip(140, VERTICAL_TOOLTIP)}</>}
            classNamePrefix={'styled-select'}
            placeholder={"Choose vertical for this campaign"}
            onChange={handleOnChange}
            // getOptionLabel={option => option.label}
            // getOptionValue={option => option.value.id}
            // @ts-ignore
            options={groupOptions}/>
    );
};