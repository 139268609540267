import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";

interface Props {}

const VisualCreatives: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(
        offer.creatives.filter(creative => (!creative.is_email && creative.type !== null)).length === 0 ||
        navState['creatives-and-email'] === 'disabled'
    ) return null

    return (
        <>
            <Subtitle>Visual Creatives</Subtitle>
            <Item>{offer.creatives.filter(creative => (!creative.is_email && creative.type !== null)).length}</Item>
        </>
    )
}

export default VisualCreatives