import React, {useEffect, useState} from "react";
import {format} from 'date-fns'
import {useAppSelector} from "../../../../common/hooks";
import {userStore} from "../../../../store/auth.slice";


import {Wrapper, HeadSection} from './Dashborad.style'
import AgencyView from "./agency-view/AgencyView";
import NetworkView from "./network-view/NetworkView";

const Dashboard: React.FC = () => {
    const user = useAppSelector(userStore)
    const {view} = useAppSelector(state => state.adminDashboard)
    return (
        <Wrapper>
            <HeadSection>
                <h1>Hello {user.name}</h1>
                <p>{format(new Date(), 'EEEE, MMMM do, yyyy')}</p>
            </HeadSection>
            {view === 'agency' ? <AgencyView /> : <NetworkView />}
        </Wrapper>
    )
}

export default Dashboard;

