import React, {useEffect} from "react";
import {Wrapper, SortButtons} from "./GridSortableHeader.style";

import {MouseEventHandler} from "react";
import {IHeaderParams} from "ag-grid-community";

export type TSortType = 'asc' | 'desc' | null | undefined

export interface Props{
    headerParams: IHeaderParams
    tooltip?: any | undefined,
    icon?: false,

}

const GridSortableHeader: React.FC<Props> = (
    {
        tooltip,
        headerParams,
        icon = true,
        children
    }) => {

    const sortDirection = headerParams.column.getSort()

    return <Wrapper onClick={() => headerParams.setSort(sortDirection ? sortDirection === 'asc' ? 'desc' : 'asc' : 'desc')} active={!!sortDirection}>
        <span>{children}</span>
       {icon && <SortButtons>
            {!sortDirection ?
                <div className="sort-icon-down"/> :
                sortDirection === 'desc' ?
                    <div className="sort-icon-down"/> :
                    <div className="sort-icon-up"/>}
        </SortButtons>}
        {tooltip && tooltip}
    </Wrapper>
}

export default GridSortableHeader;