import React, {useEffect, useRef} from 'react'
import {Expandable} from "../../../../components/Expandable";
import FromLineInput from "./components/FromLineInput";
import ReactTooltip from "react-tooltip";
import SubjectLinesInput from "./components/SubjectLinesInput";
import UnsubscribeLinkInput from "./components/UnsubscribeLinkInput";
import OptizmoAccessKeyInput from "./components/OptizmoAccessKeyInput";
import ReactDOMServer from "react-dom/server";
import EmailCreative from "./components/EmailCreative";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {setLastCreativeId, setOffer} from "../../../../create-offer.slice";

const tooltips = {
    emailCreatives: <span
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
            <>
                <p>If you have banners, product images or</p>
                <p> video creatives that you wish to provide</p>
                <p> for our partners to use for email traffic, </p>
                <p>you may upload and manage them here.</p>
                <br/>
                <p> Each creative needs to contain exactly</p>
                <p> one text file or HTML file. If you use</p>
                <p> images in HTML, upload them individually </p>
                <p> and use relative linking in HTML.</p>
                <br/>
                <p>These will be reviewed and approved by </p>
                <p>PerformCB's Compliance Team.</p>
            </>)}>
        <i  className={'ico-Information-outlined'} />
    </span>
}

const EmailTraffic: React.FC = () => {
    const {offer, lastCreativeId} = useAppSelector(state => state.createOffer)
    const {countriesState} = useAppSelector(state => state.createOffer.geoData.data)
    const dispatch = useAppDispatch()

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const handleEmailToggle = () => {
        if(countriesState && offer.traffic_types){
            if(offer.is_email){
                dispatch(setOffer({
                    is_email: false,
                    traffic_types: [...offer.traffic_types.map(type => {
                        if(type.traffic_group_id === 2){
                            return {
                                ...type,
                                selected: false
                            }
                        }
                        return type
                    })]
                }))
            } else {
                dispatch(setOffer({
                    is_email: true,
                    traffic_types: [...offer.traffic_types.map(type => {
                        if(type.traffic_group_id === 2){
                            return {
                                ...type,
                                selected: true
                            }
                        }
                        return type
                    })]
                }))
            }
        }
    }

    const handleAddEmailCreative = () => {
        dispatch(setOffer({
            creatives: [
                ...offer.creatives,
                {
                    files: [],
                    name: '',
                    id: lastCreativeId - 1,
                    campaign_id: offer.id,
                    type: null,
                    is_email: true
                }
            ]
        }))
        dispatch(setLastCreativeId(lastCreativeId - 1))
    }

    return <Expandable
        locked={false}
        title={'Email Traffic'}
        switchToggle={true}
        opened={offer.is_email}
        setOpened={handleEmailToggle}
    >

            <FromLineInput/>
            <SubjectLinesInput/>
            <UnsubscribeLinkInput/>
            <OptizmoAccessKeyInput/>
            <h3 className="creative-label">Email Creatives <span className="optional">(optional)</span> {tooltips.emailCreatives}</h3>
            {offer.creatives.map((creative, index) => {
                return creative.is_email ? <EmailCreative key={creative.id} creative={creative} index={index}/> : null
            }) }
            <ButtonStyled
                onClick={handleAddEmailCreative}
                className={'btn-blue-outlined'}
                style={{
                    marginTop: '16px',
                    width: '100%'
                }}
            >
                Add Email Creative
            </ButtonStyled>

    </Expandable>
}

export default EmailTraffic