import { Wrapper } from "../PauseRiskTooltip.style";

const PauseRiskTooltip = () => {
    return (
        <Wrapper>
            <div className="column">
                <div className="info" style={{background: "#740F15"}}>PAUSED</div>
                <div className="text">
                    No Funding Available for 1-7 days
                </div>
            </div>
            <div className="column">
                <div className="info" style={{background: "#CE1E29"}}>VERY HIGH</div>
                <div className="text">
                    1-2 Funding & Stay Live Days Remaining
                </div>
            </div>
            <div className="column">
                <div className="info" style={{background: "#FE9800"}}>HIGH</div>
                <div className="text">
                    3-5 Funding & Stay Live Days Remaining
                </div>
            </div>
            <div className="column">
                <div className="info" style={{background: "#F3C315"}}>MEDIUM</div>
                <div className="text">
                    6-9 Funding & Stay Live Days Remaining
                </div>
            </div>
            <div className="column">
                <div className="info" style={{background: "#0CAB5F"}}>LOW</div>
                <div className="text">
                    10+ Funding & Stay Live Days Remaining
                </div>
            </div>
            <div className="column">
                <div className="info" style={{background: "#8799A2"}}>NO RISK</div>
                <div className="text">
                    No Funding Available (8+ Days) / No Revenue (4+ Days)
                </div>
            </div>
        </Wrapper>
    )
}

export default PauseRiskTooltip;