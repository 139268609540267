import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 25px;
`
export const ContentWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 8px;
  padding: 26px;
  background-color: white;
`