import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const filterApi = axios.create({
    baseURL: [
        process.env.REACT_APP_API_BASE_URL,
        process.env.REACT_APP_API_URL,
        process.env.REACT_APP_API_V1_URL,
        process.env.REACT_APP_API_FILTERS,
    ].join('/')
})