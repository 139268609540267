import {createSlice} from "@reduxjs/toolkit";
import { IPartner} from "../../../../common/models/partner.type";

interface IPartnerState {
    partner: IPartner | null
}

const initialState : IPartnerState = {
    partner: null
}

const referralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers :{
        setUser: (state, action) =>{
            state.partner = action.payload
        }
    }
})

export default referralSlice.reducer
export const {setUser} = referralSlice.actions