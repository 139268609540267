import React, {useEffect, useRef, useState, useCallback} from "react";
import DP from "react-datepicker";
import {
    startOfYear,
    startOfMonth,
    startOfYesterday,
    endOfYesterday,
    startOfWeek,
    previousMonday,
    previousSunday,
    lastDayOfMonth,
    subDays,
    startOfQuarter,
    endOfQuarter,
    subQuarters,
    subMonths,
    subYears,
    endOfYear
} from 'date-fns'
import { TPreset, IPreset } from "./presets/presets.props";
import Header from '../date-picker/header/Header'

import {RangeDatePickerWrapper} from "./RangeDatePicker.style";


interface IRangeDatePickerProps {
    startDate: Date | null | undefined,
    setStartDate: (date: Date | null | undefined) => void
    endDate: Date | null | undefined,
    setEndDate: (date: Date | null | undefined) => void,
    preset?: TPreset,
    setPreset?: (preset: IPreset) => void
}


const RangeDatePicker = ({startDate, setStartDate, endDate, setEndDate, preset = "today" , setPreset} : IRangeDatePickerProps) => {
    const [startDatePickerType, setStartDatePickerType] = useState<'day' | 'month' | 'year'>('day')
    const [endDatePickerType, setEndDatePickerType] = useState<'day' | 'month' | 'year'>('day')

    useEffect(() =>{
        switch (preset){
            case "today":
                setStartDate(new Date())
                setEndDate(new Date())
                break;

            case "yesterday":
                setStartDate(startOfYesterday())
                setEndDate(endOfYesterday())
                break;

            case "this_week":
                setStartDate(startOfWeek(new Date(), {weekStartsOn: 1}))
                setEndDate(new Date())
                break;

            case "last_week":
                setStartDate(previousMonday(previousSunday(new Date())))
                setEndDate(previousSunday(new Date()))
                break;

            case "this_month":
                setStartDate(startOfMonth(new Date()))
                setEndDate(new Date())
                break;

            case "last_month":
                const d = startOfMonth(new Date())
                d.setDate(d.getDate() - 1)
                setStartDate(startOfMonth(d))
                setEndDate(lastDayOfMonth(d))
                break;

            case "this_year":
                setStartDate(startOfYear(new Date()))
                setEndDate(new Date())
                break;

            case "last_7_days":
                setStartDate(subDays(new Date(), 7))
                setEndDate(subDays(new Date(), 1))
                break;

            case "month_to_yesterday":
                setStartDate(startOfMonth(new Date()))
                setEndDate(subDays(new Date(), 1))
                break;

            case "quarter_to_date":
                setStartDate(startOfQuarter(new Date()))
                setEndDate(new Date())
                break;

            case "last_quarter":
                setStartDate(startOfQuarter(subQuarters(new Date(),1)))
                setEndDate(endOfQuarter(subQuarters(new Date(),1)))
                break;

            case "year_to_yesterday":
                setStartDate(startOfYear(new Date()))
                setEndDate(subDays(new Date(), 1))
                break;

            case "year_to_last_full_month":
                setStartDate(startOfYear(new Date()))
                setEndDate(lastDayOfMonth(subMonths(new Date(), 1)))
                break;

            case "last_year":
                setStartDate(startOfYear(subYears(new Date(), 1)))
                setEndDate(endOfYear(subYears(new Date(), 1)))
                break;

            case "custom":
                setStartDate(startDate)
                setEndDate(endDate)
                break;

            default:
                setStartDate(null)
                setEndDate(null)
        }

    }, [preset]);



    // useEffect(() => {
    //     if(datePickerStartRef.current){
    //         datePickerStartRef.current.setOpen(true)
    //     }
    //
    //     if(datePickerEndRef.current){
    //         datePickerEndRef.current.setOpen(true)
    //     }
    //
    // }, [datePickerStartRef, datePickerEndRef])

    const handleStartChange = (date: Date | null) => {
        if(startDatePickerType === 'day') setStartDate(date)
        if(startDatePickerType === 'month'){
            setStartDatePickerType('day')
        }
        if(startDatePickerType === 'year'){
            setStartDatePickerType('month')
        }

    }

    const handleEndChange = (date: Date | null) => {
        if(endDatePickerType === 'day') setEndDate(date)
        if(endDatePickerType === 'month'){
            setEndDatePickerType('day')
        }
        if(endDatePickerType === 'year'){
            setEndDatePickerType('month')
        }
    }

    const getMinDateForEndDateRangePicker = () => {
        if(endDatePickerType === 'year'){
            return startOfYear(startDate as Date)
        }
        if(endDatePickerType === 'month'){
            return startOfMonth(startDate as Date)
        }

        return startDate
    }

    const handleSelectStart = (startDate: Date) => {
        setStartDate(startDate)
        setPreset && setPreset({
            value: 'custom',
            name: 'Custom'
        })
    }

    const handleSelectEnd = (endDate: Date) => {
        setEndDate(endDate)
        setPreset && setPreset({
            value: 'custom',
            name: 'Custom'
        })
    }

    return <RangeDatePickerWrapper>
        <DP
            open={true}
            selected={startDate}
            onChange={(date) => handleStartChange(date)}
            customInput={<div />}
            calendarStartDay={1}
            selectsStart
            startDate={startDate}
            onSelect={(date) => handleSelectStart(date)}
            endDate={endDate}
            maxDate={endDate}
            disabledKeyboardNavigation
            showFourColumnMonthYearPicker={true}
            showYearPicker={startDatePickerType === 'year'}
            yearItemNumber={12}
            showTwoColumnMonthYearPicker={false}
            showMonthYearPicker={startDatePickerType === 'month'}
            dayClassName={(today) =>  today.getDate() === new Date().getDate() && today.getMonth() === new Date().getMonth() && today.getFullYear() === new Date().getFullYear() ? "today" : null}
            adjustDateOnChange={false}
            renderCustomHeader={
                ({
                     date,
                     changeMonth,
                     changeYear,
                     decreaseMonth,
                     increaseMonth,
                     prevMonthButtonDisabled,
                     nextMonthButtonDisabled,
                     increaseYear,
                     decreaseYear,

                 }) => <Header
                    date={date}
                    changeMonth={changeMonth}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    changeYear={changeYear}
                    decreaseYear={decreaseYear}
                    increaseYear={increaseYear}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                    setDatepickerType = {setStartDatePickerType}
                    datepickerType={startDatePickerType}
                    rangeType={'start'}
                />
            }
        />
        <DP
            open={true}
            selected={endDate}
            onChange={(date) => handleEndChange(date)}
            customInput={<div />}
            calendarStartDay={1}
            selectsEnd
            startDate={startDate}
            onSelect={(date) => handleSelectEnd(date)}
            endDate={endDate}
            minDate={getMinDateForEndDateRangePicker()}
            disabledKeyboardNavigation
            showFourColumnMonthYearPicker={true}
            showYearPicker={endDatePickerType === 'year'}
            yearItemNumber={12}
            showTwoColumnMonthYearPicker={false}
            showMonthYearPicker={endDatePickerType === 'month'}
            dayClassName={(today) =>  today.getDate() === new Date().getDate() && today.getMonth() === new Date().getMonth() && today.getFullYear() === new Date().getFullYear() ? "today" : null}
            adjustDateOnChange={false}
            renderCustomHeader={
                ({
                     date,
                     changeMonth,
                     changeYear,
                     decreaseMonth,
                     increaseMonth,
                     prevMonthButtonDisabled,
                     nextMonthButtonDisabled,
                     increaseYear,
                     decreaseYear
                 }) => <Header
                    date={date}
                    changeMonth={changeMonth}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    changeYear={changeYear}
                    decreaseYear={decreaseYear}
                    increaseYear={increaseYear}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                    setDatepickerType = {setEndDatePickerType}
                    datepickerType={endDatePickerType}
                    rangeType={'end'}
                />
            }
        />
    </RangeDatePickerWrapper>
}

export default RangeDatePicker