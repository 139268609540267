import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch} from "../../../../../common/hooks";

import Grid from '../../../../../common/components/grid/Grid'
import {useAppSelector} from "../../../../../common/hooks";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import {IContacts} from "../../../../../common/models/advertiser-edit.type";
import ContactsService from "./Contacts.service";
import {tokenStore} from "../../../../../store/auth.slice";
import {closeAlert, editContact, getEditingUser} from "../AdvertisersEdit.slice";
import GridRowOptions from "../../../../../common/components/grid/grid-row-options/GridRowOptions";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import Modal from "../../../../../common/components/modal/Modal";
import ViewEditModal from "./view-edit-modal/ViewEditModal";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import DeleteModal from "./delete-modal/DeleteModal";
import {AddContactButton, Wrapper} from "./Contacts.style";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import {useParams} from "react-router-dom";

const ReceiveAlertsHeader = ({data}: {data: IContacts}) => {
    const {editingUser} = useAppSelector(state => state.advertisersEdit)
    return (
        <div>
            RECEIVE ALERTS <Tooltip width={100} position={'top'}>
                <div>Notifications:</div>
                <br/>
                <ul style={{
                    marginLeft: '16px',
                    listStyle: 'disc',
                    fontFamily: '\'Avenir LT W01_55 Roman1475520\',sans-serif'
                }}>
                    {editingUser?.notifications?.map(notification => {
                        return <li key={notification.name}>{notification.name}</li>
                    })}
                </ul>
            </Tooltip>
        </div>
    )
}

interface RowOptionsProps {
    contact: IContacts,
    setModal: React.Dispatch<React.SetStateAction<{ opened: boolean; type: "add" | "view" | "edit" | 'delete'; data: IContacts | null; }>>
}

const RowOptions = ({contact, setModal} : RowOptionsProps) => {
    const {editingUser, loading} = useAppSelector(state => state.advertisersEdit)
    const handleClick = useCallback((type: "add" | "view" | "edit" | 'delete') => {
        setModal({
            opened: true,
            type: type,
            data: contact
        })
    }, [contact, setModal])
    return (
        <GridRowOptions >
            <DropdownItem onClick={() => handleClick('view')}>View</DropdownItem>
            <DropdownItem onClick={() => handleClick('edit')}>Edit</DropdownItem>
            {editingUser?.id === contact.user_id && <DropdownItem onClick={() => handleClick('delete')}>Remove</DropdownItem>}
        </GridRowOptions>
    )
}

const Contacts:React.FC = () => {
    const {id} = useParams()
    const {editingUser, loading, alertModal} = useAppSelector(state => state.advertisersEdit)
    const [loadingAlertChecked, setLoadingAlertChecked] = useState(false)
    const [modal, setModal] = useState<{opened: boolean, type: 'view' | 'edit' | 'add' | 'delete', data: IContacts | null}>({
        opened: false,
        type: 'view',
        data: null
    })

    const [tableData, setTableData] = useState<IContacts[]>([])

    //Pagination
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [perPage, setPerPage] = useState(50)
    const [lastPage, setLastPage] = useState(0)

    useEffect(() => {
        if(editingUser){
            setTotal(editingUser.contacts.length)
            const numOfPages = Math.floor(editingUser.contacts.length / perPage)
            const slicedData = []

            let index: number = 0
            while(index <= numOfPages){
                slicedData.push(editingUser.contacts.slice(index * perPage, index * perPage + perPage))
                index++
            }
            setFrom((currentPage - 1) * perPage + 1)
            setTo((currentPage - 1) * perPage + perPage <= editingUser.contacts.length ? (currentPage - 1) * perPage + perPage : editingUser.contacts.length)
            setLastPage(slicedData.length)
            setTableData(slicedData[currentPage - 1])
        }

    }, [editingUser, perPage, total, currentPage])


    const token = useAppSelector(tokenStore)
    const dispatch = useAppDispatch()
    const handleReceiveAlertsChange = async (contact: IContacts) => {
        try {
            setLoadingAlertChecked(true)
            const newContact = {...contact, include_compliance_emails: !contact.include_compliance_emails}
            const {data} = await ContactsService.editContact(token, newContact)
            dispatch(editContact(data))
            setLoadingAlertChecked(false)
        } catch (e) {
            setLoadingAlertChecked(false)
            console.log(e)
        }
    }

    const columnDefs = useMemo(() => [
        {field: 'company_name', headerName: 'COMPANY NAME'},
        {field: 'name', headerName: 'FIRST NAME'},
        {field: 'lastname', headerName: 'LAST NAME'},
        {field: 'job_title', headerName: 'JOB TITLE'},
        {field: 'email', headerName: 'EMAIL'},
        {
            field: 'contact_type',
            headerName: 'CONTACT TYPE',
            cellRenderer: ({data}: {data: IContacts}) => {
                return <span style={{textTransform: 'capitalize'}}>{data.contact_type}</span>
            }
        },
        {
            headerComponent: ({data}: {data: IContacts}) =>  <ReceiveAlertsHeader data={data}/>,
            cellRenderer: ({data}: {data: IContacts}) => {
                return <Checkbox disabled={data.contact_type === 'Account Owner'} checked={data.include_compliance_emails} onChange={() => handleReceiveAlertsChange(data)}/>
            },
        },
        {
            pinned: 'right',
            width: 50,
            resizable: false,
            cellRenderer: ({data}: {data: IContacts}) => {
                return <RowOptions contact={data} setModal={setModal}/>
            },

        }
    ], [])

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    return (
        <Wrapper>
            <div className="top-section">
                <AddContactButton onClick={() => setModal({
                    type: 'add',
                    data: null,
                    opened: true
                })}>Add Contact</AddContactButton>
                <Pagination
                    current_page={currentPage}
                    from={from}
                    to={to}
                    total={total}
                    setCurrentPage={setCurrentPage}
                    setPerPage={setPerPage}
                    last_page={lastPage}
                    perPage={perPage}
                    loading={loading}
                    onRefresh={() => {
                        if(id){
                            return dispatch(getEditingUser({token, id: parseInt(id)}))
                        }

                    }} />
            </div>
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowData={tableData}
                rowSelection='multiple'
                rowHeight={40}
                domLayout='autoHeight'
                loading={loadingAlertChecked || loading}
            />
            <Modal opened={modal.opened} title={`${modal.type.charAt(0).toUpperCase() + modal.type.slice(1)} Contact`} closeModal={() => setModal({opened: false, type: modal.type, data: null})}>
                {modal.type === 'delete' ? <DeleteModal closeModal={() => setModal({opened: false, type: modal.type, data: null})} contact={modal.data}/> : <ViewEditModal closeModal={() => setModal({opened: false, type: modal.type, data: null})} contact={modal.data} type={modal.type}/>}
            </Modal>
            <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => dispatch(closeAlert())}>
                {alertModal.message}
            </AlertModal>
        </Wrapper>
    )
}

export default Contacts