import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {IPartnerTransparency} from "../../../../../../common/models/advertiser-edit.type";
import {AddPartnerButton, Cancel, ModalFooter, Wrapper} from "./AddModal.style"
import InputMultiLine from "../../../../../../common/components/input-multi-line/InputMultiLine";
import {Label} from "../../home/Home.style";
import {PartnerInput} from "../../home/inputs/Inputs";
import AdvertisersEditService from "../../AdvertisersEdit.service";
import {tokenStore} from "../../../../../../store/auth.slice";
import {useAppSelector} from "../../../../../../common/hooks";
import Alert from "../../../../../../common/components/alert/Alert";

interface AddModalProps {
    partners: IPartnerTransparency[],
    closeModal: () => void,
    addedPartners: IPartnerTransparency[],
    refreshTable: () => void
}

const AddModal: React.FC<AddModalProps> = ({partners, closeModal, addedPartners, refreshTable}) => {
    const [notes, setNotes] = useState<string>("")
    const {id} = useParams<{ id: string }>()
    const token = useAppSelector(tokenStore)
    const [partner, setPartner] = useState<IPartnerTransparency>({
        id: 0,
        name: "",
        lastname: "",
        company: "",
        network_id: 0,
        network_name: "",
        account_manager_lastname: "",
        account_manager_name: "",
        note: "",
        referral_date: "",
        transparency_id: 0
    });

    const checkPartner = () => {
        if (partner.id === 0)
            return true
        return addedPartners.some(item => item.id === partner.id)
    }

    const addPartner = async () => {
        try {
            if (id) {
                const response = await AdvertisersEditService.addPartner(token, id, partner.id, notes)
                refreshTable()
                closeModal()
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Wrapper>
            {(checkPartner() && partner.id !== 0) ?
                <>
                    <Alert alertType={"error"}>
                        This partner is already transparent!
                    </Alert>
                    <br/>
                </> :

                <>
                    <Alert alertType={"warning"}>
                        <strong>Note: </strong>Adding a partner to this marketer’s referrals will give the marketer
                        transparency about the partner such as Partner Name & Partner Company.
                    </Alert>
                    <br/>
                </>

            }
            <Label>Partner</Label>
            <PartnerInput partner={partner}
                          allPartners={partners}
                          setPartner={setPartner}/>
            <br/> <br/>
            <Label>Referral Note: (optional)</Label>
            <InputMultiLine onChange={setNotes} value={notes ? notes : ""} placeholder={""}/>
            <ModalFooter>
                <Cancel onClick={closeModal}>Cancel</Cancel>
                <AddPartnerButton onClick={addPartner} disabled={checkPartner()}>Add Partner</AddPartnerButton>
            </ModalFooter>
        </Wrapper>
    )
}

export default AddModal