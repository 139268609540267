import styled, {css} from "styled-components";

interface IButtonProps  {
    disabled?: boolean
}

interface IHeaderProps  {
    noHeaderUnderline?: boolean
}

export const Wrapper = styled.div`
  background-color: white;
  position: relative;
  padding: 40px;
  max-width: 100%;
  width: fit-content;
  height: fit-content;
  margin-top: 75px;
  border-radius: 24px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  
`

export const Header = styled.div<IHeaderProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.coolGray['3']};
  padding-bottom: 20px;
  font-size: 20px;
  color: ${({theme}) => theme.colors.deepBlue['7']};
  margin-bottom: 20px;
  ${props => props.noHeaderUnderline && css`
     border-bottom: none;
     padding-bottom: 0;
     margin-bottom: 10px;
  `}
  &.custom-modal-header {
    border-bottom: none;
    color: ${({theme}) => theme.colors.deepBlue['5']};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    font-size: 24px;
  }
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.coolGray['3']};
  padding-top: 34px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled.div`
  color: ${({theme}) => theme.colors.deepBlue['4']};
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all .2s ease-in-out;
  :hover{
    cursor: pointer;
    color: #5a686f;
    background-color: #dfe7eb;
  }
  
  :hover:active{
    color: #31383B;
    background-color: #CBD6DB;
  }
`

export const Cancel = styled.button`
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  font-family: ${({theme}) => theme.fonts.AvenirMedium};
  border: none;
  background: transparent;
  :hover{
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue['7']};
  }
  
  :hover:active{
    color: ${({theme}) => theme.colors.deepBlue['9']};
  }

`

export const Button = styled.button<IButtonProps>`
  padding: 8px 16px;
  color: white;
  font-size: 12px;
  border: none;
  background-color: ${({theme}) => theme.colors.performOrange['3']};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirMedium};
  line-height: 1.55;
  
  :hover{
    background-color: ${({theme}) => theme.colors.performOrange['4']};
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.performOrange['7']};
  }
  
  ${props => {
    if(props.disabled){
        return css`
          pointer-events: none;
          background-color: ${({theme}) => theme.colors.coolGray['2']};
          color: ${({theme}) => theme.colors.coolGray['4']};
          border: 1px solid ${({theme}) => theme.colors.coolGray['4']};
        `
    }
    return
}}
`

