import { useEffect, useState } from 'react';
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

const allOptions: any[] = [
    {
        value: "Add Card",
        id: "ADD_CARD"
    },
    {
        value: "Admin Adjustment",
        id: "ADMIN_ADJUSTMENT"
    },
    {
        value: "Valid Clicks",
        id: "ADMIN_VALID_CLICKS"
    },
    {
        value: "Auto funding",
        id: "AUTO_FUNDING"
    },
    {
        value: "Bonus",
        id: "BONUS"
    },
    {
        value: "Cake Funds",
        id: "CAKE_FUNDS"
    },
    {
        value: "Courtesy Credit",
        id: "COURTESY_CREDIT"
    },
    {
        value: "Stay Live Limit change",
        id: "CREDIT_CHANGED"
    },
    {
        value: "Invalid Clicks",
        id: "INVALID_CLICKS"
    },
    {
        value: "Line of Credit",
        id: "LINE_OF_CREDIT"
    },
    {
        value: "Manual Funds",
        id: "MANUAL_FUNDS"
    },
    {
        value: "Other",
        id: "OTHER"
    },
    {
        value: "Prepayment",
        id: "PREPAYMENT"
    },
    {
        value: "Referral Credit",
        id: "REFERRAL_CREDIT"
    },
    {
        value: "Refund",
        id: "REFUND"
    },
    {
        value: "Removal",
        id: "REMOVAL"
    },
    {
        value: "Replace Card",
        id: "REPLACE_CARD"
    },
    {
        value: "Sign Up",
        id: "SIGNUP"
    },
    {
        value: "Valid Clicks",
        id: "VALID_CLICKS"
    },
    {
        value: "Valid Conversions",
        id: "VALID_CONVERSIONS"
    },
    {
        value: "Valid traffic",
        id: "VALID_TRAFFIC"
    }
];

export const useTransactionTypeFilter = (pageId: PageID): IFilterHookReturn<any> => {
    const dispatch = useAppDispatch()

    const id = 'transaction-type';

    const [types, setTypes] = useState(allOptions);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {        
        let filteredTypes = allOptions.filter(type => type.value.toLowerCase().includes(searchValue.toLowerCase()))

        setTypes(filteredTypes)
    }, [searchValue])

    const transactionTypeFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (type: any) => {
        if(transactionTypeFilter.selected.some((stat)  => stat.id=== type.id)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: transactionTypeFilter.exclude,
                    selected: transactionTypeFilter.selected.filter(stat => stat.id !== type.id)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: transactionTypeFilter.exclude,
                    selected: [...transactionTypeFilter.selected, type]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: transactionTypeFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: types,
        setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: transactionTypeFilter.selected,
        exclude: transactionTypeFilter.exclude,
        setSelectedData: setSelectedData,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}