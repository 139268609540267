import {api} from "../../../../../common/services/api";
import {IPagination} from "../../../../../common/models/pagination.type";
import axios from "axios";

export interface IMarketer {
    id: number,
    name: string,
    lastname: string,
    company: string,
    network_id: number,
    display_name: string
}

export interface IMarketerDomain {
    domain: string,
    user_id: number,
    status: string,
    default: number,
    name_servers: string,
    id: number,
    user_name: string,
    user_type: string
}

export interface ICustomDomainsDataParams {
    current_page_marketers?: number,
    paginate_by_marketers?: number,
    search?: string,
    status?: string,
    user_id?: number[],
    export_csv?: boolean,
    user_type?: string,
}

export interface IAddCustomDomainResponse {
    code: number;
    name_servers: string;
    success: boolean,
    id: number
}

export class MarketerCustomDomainService {
    static fetchMarketers = async (token: string) =>{
        try{
            return await api.post<IPagination<IMarketer>>([
                    process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_FILTERS,
                    'advertisers'
                ].join('/'),
                null,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }
            )
        }
        catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, 'axios error')
            }
            return e.message
        }
    }
    static fetchMarketerCustomDomains = async (token: string, params: ICustomDomainsDataParams) => {
        try {
            return await api.get<IPagination<IMarketerDomain>>(
                [process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_DOMAINS].join('/'),
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params
                }
            )
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, 'axios error')
            }
            return e.message
        }
    }

    static deleteDomain = async (token: string, domainId: number, marketerID: number) => {
        return await api.put<IMarketerDomain>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains",
                domainId,
            ].join('/'),
            {
                _method: 'DELETE',
                user_id: marketerID
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    static addCustomDomain = async (token: string, domainValue: string, marketerId: number) => {
        return await api.post<IAddCustomDomainResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                "domains",
            ].join('/'),
            {
                domain: domainValue,
                user_id : marketerId
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
}
