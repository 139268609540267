export const faqData = [
    {
        title: 'Who can I refer?',
        content: 'Anyone who has not already signed up on PerformLEAP before can be referred.'
    },
    {
        title: 'How many people can use my referral link?',
        content: 'There are no restrictions on how many people can use your referral link.'
    },
    {
        title: 'How do I know if a referral has signed up?',
        content: 'Once approved for PerformLEAP, the referral will show up on your active referrals list.'
    },
    {
        title: 'How long will I earn comission from a referral?',
        content: 'You will earn commission from a referral for 12 months after they have been approved for PerformLEAP.'
    },
    {
        title: 'How do I earn commission through referrals?',
        content: 'Commission from a referral is earned once they generate revenue in PerformLEAP.'
    },
    {
        title: 'How much commission can I earn through referrals?',
        content: 'Unlimited!'
    },
    {
        title: 'Where can I see the commission generated per referral?',
        content: 'You can see the lifetime commission generated from an active referral in the widget to the right of this screen. To see commission generated during a specific date range you can use the referral report.'
    }
];
