import React, {ChangeEvent} from 'react'
import Input from "../../../../../../../../../common/components/input/Input";

interface Props {
    value: string,
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

const EmailCreativeNameInput: React.FC<Props> = ({value, onChange}) => {
    return <Input
        label={'Creative Name'}
        placeholder={'Enter a name for the creative group'}
        value={value}
        onChange={(e) => onChange(e)}
        wrapperClassName={'email-creative-name-wrapper'}
    />
}

export default EmailCreativeNameInput

/*
* TODO:
*  - value and onChange
* */