import {useCallback, useEffect, useState} from "react";

import {api} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, ICountry, ICountryFilter, ILocation, IState} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";


export const useCountryCacheFilter = (pageId: IPagePresetsID,displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<(ICountry | IState)[]>([])
    const [countries, setCountries] = useState<(ICountry | IState)[]>([])
    const [states, setStates] = useState<IState[]>([])
    const [locations, setLocations] = useState<ILocation[]>([])
    const [loading, setLoading] = useState(false)
    const [countriesSearchValue, setCountriesFilterSearchValue] = useState('')
    const [countriesFilterNotDisplayed, setCountriesFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id :FilterID = 'country-availability'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await api.get<ICountryFilter>([
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'country-state'
            ].join('/'),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                [...data.states, ...data.countries ]
            )
            setStates(
                data.states
            )
            setLocations(
                data.locations
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredCountries = fetchedData.filter(country => (
                country.name.toLowerCase()).includes(countriesSearchValue.toLowerCase())
            ||
            country.id.toString().includes(countriesSearchValue.toLowerCase())
            ||
            country.code.toLowerCase().includes(countriesSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredCountries].slice(0, displayCount)
            if(filteredCountries.length > displayCount){
                setCountriesFilterNotDisplayed(filteredCountries.length - displayCount)
            } else {
                setCountriesFilterNotDisplayed(0)
            }
            setCountries(displayedAcc)
        } else {
            setCountries(
                filteredCountries
            )
        }


    }, [countriesSearchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: ICountry | IState) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: countries,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: countriesFilterNotDisplayed,
        search: {
            value: countriesSearchValue,
            setSearch: setCountriesFilterSearchValue
        },
        loading
    }

}