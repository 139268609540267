import React, {useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from "axios";
import {ColumnApi, GridApi, GridReadyEvent} from "ag-grid-community";
import {
    ButtonStyled,
    TableToolbarBottom,
    TableToolbarTop,
    TableToolbarTopBottomRow,
    TableToolbarTopUpperRow,
    TableWrapper
} from "../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {getAuth, tokenStore} from "../../../../store/auth.slice";
import Pagination from "../../../../common/components/data-grid/pagination/Pagination";
import Search from "../../../../common/components/search/Search";
import Icon from "../../../../common/components/icon/Icon";
import {withPageContainer} from "../../../../common/hoc/withPageContainer/withPageContainer";
import {
    IWithUserPreferencesProps,
    withUserPreferences
} from "../../../../common/hoc/withUserPreferences/withUserPreferences";
import {IPagePresetsID, saveUserPreferences} from "../../../../store/userPreferences.slice";
import {IUser} from "../../../../common/models/user.type";
import UsersService, {IUsersFetchParams} from "./Users.service";
import {setUsersSearchValue, setPerPage} from "./Users.slice";
import {Filters} from "./filters/Filters";
import {orderColumns} from "../../../../common/utils/orderColumns";
import {UsersTable} from "./users-table/UsersTable";
import Modal from "../../../../common/components/modal/Modal";
import {CreateOrEditUserModal} from "./modals/CreateOrEditUserModal";
import AlertModal from "../../../../common/components/alert-modal/AlertModal";
import {IAlertModal} from "../../../../common/models/common.type";
import ReactTooltip from "react-tooltip";
import {UsersWrapper} from "./Users.style";

const pageId: IPagePresetsID = 'admin/users/admin'

interface Props extends IWithUserPreferencesProps {
    twoFactorModalInitialOpened: boolean
}

const Users: React.FC<Props> = ({
                                                        filterPreferences,
                                                        tablePreferences,
                                                        saveTablePreferences,
                                                        twoFactorModalInitialOpened
                                                    }) => {

    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenStore)
    const {
        usersSearchValue,
        perPage
    } = useAppSelector(state => state.adminUsers)
    const [loading, setLoading] = useState<boolean>(true)
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);
    //Pagination data
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [lastPage, setLastPage] = useState<number>(0)
    const [isCreateUserModalOpened, setCreateUserModalOpened] = useState<boolean>(false)

    const [createUser, setCreateUser] = useState<boolean>(true)

    const cancelTokenSource = useRef<CancelTokenSource | undefined>();

    const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser)

    // table api
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
    const [gridApi, setGridApi] = useState<GridApi>()

    const [alertModal, setAlertModal] = useState<IAlertModal>(
        {
            opened: false,
            message: '',
            type: 'success'
        }
    )

    const setSearchValue = (value: string) => {
        cancelTokenSource.current?.cancel();
        dispatch(setUsersSearchValue(value))
    }

    const handleDiscover = () => {
        dispatch(saveUserPreferences(pageId))
        fetchUsers()
        setDiscoverDisplayed(false)
    }

    const onGridReady = (api: GridReadyEvent) => {
        setGridApi(api.api)
        setGridColumnApi(api.columnApi)
        if (tablePreferences.length > 0) {
            api.columnApi.applyColumnState({state: orderColumns(tablePreferences)})
            api.columnApi.moveColumns(orderColumns(tablePreferences).map(column => column.colId) as string[], 0)
        } else {
            saveTablePreferences(api.columnApi.getColumnState())
        }
    }
    const setFilterParams  = () : IUsersFetchParams =>{
        let params : IUsersFetchParams = {
            with_paymentinfos: true,
            sort_by: "created_at",
            order: "asc",

        }
        if (usersSearchValue !== "") {
            params.q = usersSearchValue
        }
        if (currentPage !== 1 ){
            params.page = currentPage
        }
        if (perPage !== 50) {
            params.paginate_by = perPage
        }
        if (filterPreferences['user_status']){
            if (filterPreferences['user_status'].selected.length > 0){
                params.status = filterPreferences['user_status'].selected[0].value
            }
        }
        if (filterPreferences['user_role']){
            if (filterPreferences['user_role'].selected.length > 0){
                params.user_roles = filterPreferences['user_role'].selected[0].id
            }
        }
        return  params
    }

    const fetchUsers = async () => {
        try {
            setLoading(true)
            setDiscoverDisplayed(false)
            gridApi?.showLoadingOverlay()
            cancelTokenSource.current = axios.CancelToken.source()
            let params = setFilterParams()
            const {data} = await UsersService.fetchUsers(token, params)
            dispatch(setPerPage(data.per_page))
            setCurrentPage(data.current_page)
            gridApi?.setRowData(data.data as IUser[])
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLastPage(data.last_page)
            setLoading(false)
            gridApi?.hideOverlay()
        } catch (error) {
            console.log(error)
            setDiscoverDisplayed(false)
        }
    }

    useEffect(() => {
        if (token && gridColumnApi) {
            fetchUsers()
        }
    }, [token, gridColumnApi, usersSearchValue, currentPage, perPage])

    return (
        <UsersWrapper>
            <ReactTooltip
                class={"react-tooltip-container tooltip"}
                effect="solid"
                type={'light'}
                multiline={true}
                html={true}
                delayShow={0}
            />
        <TableWrapper>
            <TableToolbarTop>
                <TableToolbarTopUpperRow>
                    <Search width={30}
                            wrapperClassName={'table-search'}
                            onChange={setSearchValue}
                            initialValue={usersSearchValue}
                    />
                    {
                        discoverDisplayed &&
                        <ButtonStyled
                            onClick={() => handleDiscover()}
                            className={'btn-discover-results'}>
                            Discover Result<Icon
                            icon={'Rocket'}
                            size={13}
                            style={{marginLeft: '10px'}}/>
                        </ButtonStyled>
                    }
                </TableToolbarTopUpperRow>
                <TableToolbarTopBottomRow>
                    <Filters setDiscoverDisplayed={setDiscoverDisplayed}>
                    </Filters>
                    <Pagination
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={(n: number) => dispatch(setPerPage(n))}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        onRefresh={fetchUsers}/>
                    <ButtonStyled className={"btn-blue-filled"}
                                  onClick={()=> {
                                      setCreateUserModalOpened(true)
                                      setCreateUser(true)
                                  }}>
                        Create User
                    </ButtonStyled>
                </TableToolbarTopBottomRow>
            </TableToolbarTop>
                <UsersTable
                    twoFactorModalInitialOpened={twoFactorModalInitialOpened}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    setCreateUser={setCreateUser}
                    isCreateUserModalOpened={isCreateUserModalOpened}
                    setCreateUserModalOpened={setCreateUserModalOpened}
                    fetchUsers={fetchUsers}
                    onGridReady={onGridReady}
                    gridApi={gridApi}
                    saveTablePreferences={saveTablePreferences}/>
            <TableToolbarBottom>
                <Pagination
                    current_page={currentPage}
                    from={from}
                    to={to}
                    total={total}
                    setCurrentPage={setCurrentPage}
                    setPerPage={(n: number) => dispatch(setPerPage(n))}
                    last_page={lastPage}
                    perPage={perPage}
                    openUp={true}
                    loading={loading}
                    onRefresh={fetchUsers}/>
            </TableToolbarBottom>
        </TableWrapper>
            <Modal opened={isCreateUserModalOpened}
                   title={createUser ? "Create new User" : "Edit user"}
                   closeModal={()=> setCreateUserModalOpened(false)}>
                <CreateOrEditUserModal
                    createUser={createUser}
                    user={selectedUser}
                    closeModal={()=> setCreateUserModalOpened(false)}
                    setAlertModal={setAlertModal}
                    fetchUsers={fetchUsers}
                >
                </CreateOrEditUserModal>

            </Modal>
            <AlertModal
                isOpen={alertModal.opened}
                type={alertModal.type}
                closeModal={()=>setAlertModal({opened:false, message : '', type: 'success'})}>
                {alertModal.message}
            </AlertModal>
        </UsersWrapper>
    );
};

export default withUserPreferences(pageId, withPageContainer(Users))