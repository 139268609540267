import React, {useEffect} from 'react'
import {Wrapper} from "./Targeting.style";
import {Panel} from "../Main.style";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {ITrafficType} from "../../../../offers/cpa/Cpa.service";
import {setNextPage, setOffer, setPrevPage} from "../../../create-offer.slice";
import ReactTooltip from "react-tooltip";
import {Expandable} from "../../../components/Expandable";
import LocationTargeting from "./components/LocationTargeting";
import CityTargeting from "./components/CityTargeting";
import IspTargeting from "./components/IspTargeting";
import ZipCodeTargeting from "./components/ZipCodeTargeting";
// import {Footer} from "../footer/Footer";
import {BUDGET, SCHEDULING} from "../../../interfaces/interfaces";
import {isNullOrUndefined} from "../../../../../../../common/utils";

const tooltips: {[key: string]: any} = {
    5: <span
        data-tip="Advertising through creatives that contain text, logos, images or text-only <br/>
        links. This kind of advertising can be promoted directly through a website<br/>
        owner, or the advertising space can be purchased through a media buy. This <br/>
        includes native advertising, but DOES NOT include any search traffic, including, <br/>
        but not limited to Organic or Paid Search.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    7: <span
        data-tip="Advertising on online communities such as Facebook through creatives that contain text, logos, images or text-only links.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    6: <span
        data-tip="A form of targeted advertising where traffic is driven by bidding<br/>
        on keywords to display ads on search engine results such as Google or Bing.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    4: <span
        data-tip="Targeted advertisements that appear on websites or other media, <br/>
        such as content displayed in mobile browsers, selected based on the identity <br/>
        of the user and the content displayed. Push notification is the most <br/>
        common form of contextual advertising. Pop-ups, pop-unders and in-text <br/>
        ads are also examples of contextual advertising.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    9: <span
        data-tip="A form of advertising where users are offered the ability to <br/>
        participate in a product trial as a result of completing a survey path.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    11: <span
        data-tip="A form of advertising in which users that have opted in to a <br/>
        marketing list receive a SMS message to their mobile phone containing <br/>
        text creatives and a link to the campaign or a presell page.<br/>

            *Note - SMS traffic must comply with all local laws and <br/>
            regulations (including, but not limited to the TCPA)."
    >
        <i  className={'ico-Information-outlined'} />
    </span>,
    10: <span
        data-tip="Advertising through in-app or mobile web placements, <br/>
        driving Android and iOS app installs.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    12: <span
        data-tip="This includes any promotion in which users are offered a <br/>
        form of compensation for purchasing a marketer's products or services <br/>
        or taking another specified action on a marketer's website.">
        <i  className={'ico-Information-outlined'} />
    </span>,
    13: <span
        data-tip="Promotion method in which a partner uses a click-to-call <br/>
        experience to generate high intent telephone calls for marketers.">
        <i  className={'ico-Information-outlined'} />
    </span>,
}

const Targeting: React.FC = () => {
    const {offer, advancedTargetingExpanded} = useAppSelector(state => state.createOffer)
    const {user, config} = useAppSelector(state => state.auth)

    const trafficTypeTargetingFlag = useFeatureFlags('offer-traffic-type-targeting')
    const targetByOsFlag = useFeatureFlags('target_by_os', offer.niche ? offer.niche.network_id : undefined)
    const zipCodeTargeting = useFeatureFlags('city-zip-targeting')
    const ispTargetingFlag = useFeatureFlags('isp_targeting')

    const dispatch = useAppDispatch()

    const handleTrafficTypeSelect = (type: ITrafficType) => {
        let newTypes = offer.traffic_types
        if(newTypes){
            newTypes = [...newTypes.map((traffType: any) => {
                if(traffType.id === type.id){
                    return {...traffType, selected: !traffType.selected}
                }
                return traffType
            })]
            dispatch(setOffer({
                traffic_types: [...newTypes]
            }))
        }
    }

    const handleMobileOsTargetingSelect = (os: string) => {
        dispatch(setOffer({
            os_targeting: {
                ...offer.os_targeting,
                mobile: {
                    ...offer.os_targeting.mobile,
                    [os]: offer.os_targeting.mobile[os] === 1 ? 0 : 1
                }
            }
        }))
    }

    const handleTabletOsTargetingSelect = (os: string) => {
        dispatch(setOffer({
            os_targeting: {
                ...offer.os_targeting,
                tablet: {
                    ...offer.os_targeting.tablet,
                    [os]: offer.os_targeting.tablet[os] === 1 ? 0 : 1
                }
            }
        }))
    }
    
    const handleClickNext = () => {
    
    }
    
    useEffect(()=>{
        dispatch(setPrevPage("../" + BUDGET))
        dispatch(setNextPage("../" + SCHEDULING))
    },[])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return <>
        <Panel>
            <h2 className="title">Targeting</h2>
            <p className="description">
                Define how you would like to target customers for this campaign, by default all are targeted.
            </p>
            <Wrapper>
                <div className="types-section">
                    {(trafficTypeTargetingFlag || !isNullOrUndefined(config.use_marketer_ui)) && <div className="traffic-types">
                        <h3 className="label">Traffic Types</h3>
                        <div className={'traffic-types-list'}>
                            <div>
                                {offer.traffic_types?.slice(0, 4).map((type) => {
                                    return <Checkbox
                                        wrapperClassName={'checkbox-wrapper'}
                                        label={<>{type.name} {tooltips[`${type.id}`]}</>}
                                        checked={type.selected}
                                        disabled={type.traffic_group_id === 2}
                                        key={type.id}
                                        onChange={() => handleTrafficTypeSelect(type)}
                                    />
                                })}
                            </div>
                            <div>
                                {offer.traffic_types?.slice(4).map((type) => {
                                    return <Checkbox
                                        wrapperClassName={'checkbox-wrapper'}
                                        label={<>{type.name} {tooltips[`${type.id}`]}</>}
                                        checked={type.selected}
                                        disabled={type.traffic_group_id === 2}
                                        key={type.id}
                                        onChange={() => handleTrafficTypeSelect(type)}
                                    />
                                })}
                            </div>
                        </div>
                    </div>}
                    <div className="device-types">
                        <h3 className="label" style={{marginBottom: 12}}>Device Types</h3>
                        <div className={'device-types-list'}>
                            <div className={'device-type-section'}>
                                <Checkbox
                                    wrapperClassName={'checkbox-wrapper'}
                                    label={<>Desktop</>}
                                    checked={offer.desktop}
                                    key={'desktop'}
                                    onChange={() => dispatch(setOffer({desktop: !offer.desktop}))}
                                />
                        
                            </div>
                            <div className={'device-type-section'}>
                                <Checkbox
                                    wrapperClassName={'checkbox-wrapper'}
                                    label={<>Tablet</>}
                                    checked={offer.tablet}
                                    key={'tablet'}
                                    onChange={() => dispatch(setOffer({tablet: !offer.tablet}))}
                                />
                                {(offer.tablet && (targetByOsFlag || !isNullOrUndefined(config.use_marketer_ui))) &&
                                    <ul>
                                        {
                                            Object.keys(offer.os_targeting.tablet).map(key => {
                                                return <li key={key}>
                                                    <Checkbox
                                                        onChange={() => handleTabletOsTargetingSelect(key)}
                                                        labelClassName={key !== 'ios' ? 'os-targeting-label' : ''}
                                                        label={key === 'ios' ? 'iOS' : key}
                                                        checked={offer.os_targeting.tablet[key] === 1}
                                                        wrapperClassName={'checkbox-wrapper'}
                                                    />
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                            <div className={'device-type-section'}>
                                <Checkbox
                                    wrapperClassName={'checkbox-wrapper'}
                                    label={<>Mobile</>}
                                    checked={offer.mobile}
                                    key={'mobile'}
                                    onChange={() => dispatch(setOffer({mobile: !offer.mobile}))}
                                />
                                {(offer.mobile && (targetByOsFlag || !isNullOrUndefined(config.use_marketer_ui))) &&
                                    <ul>
                                        {
                                            Object.keys(offer.os_targeting.mobile).map(key => {
                                                return <li key={key}>
                                                    <Checkbox
                                                        labelClassName={key !== 'ios' ? 'os-targeting-label' : ''}
                                                        onChange={() => handleMobileOsTargetingSelect(key)}
                                                        label={key === 'ios' ? 'iOS' : key}
                                                        checked={offer.os_targeting.mobile[key] === 1}
                                                        wrapperClassName={'checkbox-wrapper'}
                                                    />
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Expandable title={'Advanced Targeting'} locked={false} switchToggle={advancedTargetingExpanded}>
                    <LocationTargeting/>
                    <div className="include-exclude-section">
                        <div>
                            {(zipCodeTargeting || !isNullOrUndefined(config.use_marketer_ui)) && <CityTargeting/>}
                            {(ispTargetingFlag || !isNullOrUndefined(config.use_marketer_ui)) && <IspTargeting/>}
                        </div>
                        <div>
                            {(zipCodeTargeting || !isNullOrUndefined(config.use_marketer_ui)) && <ZipCodeTargeting/>}
                        </div>
                    </div>
                </Expandable>
            </Wrapper>
        </Panel>
        {/*<Footer disabled={false} onNext={handleClickNext}/>*/}
    </>
}

export default Targeting