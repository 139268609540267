import React, {useState, useRef, useEffect} from 'react'

import SelectFilterDropdown from '../../../../../../common/components/filters/select-filter-dropdown/SelectFilterDropdown';
import IncludeExcludeFilter from "../../../../../../common/components/filters/include-exclude-filter/IncludeExcludeFilter";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {
    useVendorIdFilter,
    useAffiliatesFilter
} from "../../../../../../common/hooks/filters";

import {removeAllFilters, setFilter, singleSelect} from "../../../../../../store/filters.slice";
import {ButtonStyled} from "../../../../../../common/styled-components";
import {
    FilterID,
    IAffiliatesFilter,
    IVendorIdFilter
} from "filters";

import {Wrapper} from "../../marketer-funding-monitoring/filters/Filters.style";

import ClickAgainToConfirmTooltip
    from "../../../../../../common/components/ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip";
import {
    useAppDispatch,
    useAppSelector,
    useOnClickOutside
} from "../../../../../../common/hooks";

const filterList: {id: FilterID, value: string}[] =  [
    {
        id: 'vendor_id',
        value: 'Vendor ID'
    },
    {
        id: "affiliate",
        value:"Partners"
    }
]

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {

    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterClickCount = useRef(0)

    const vendorIdsFilter = useVendorIdFilter('admin/billing/partner-payments', 50);
    const affiliateFilter = useAffiliatesFilter('admin/billing/partner-payments', 50);

    const {filters} = useAppSelector((state:any) => state.filters)
    const dispatch = useAppDispatch()

    const handleVendorSelect = (vendor: IVendorIdFilter) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/partner-payments',
            filterId: 'vendor_id',
            data: vendor
        }))
    }

    const handleAffiliateSelect = (affiliate: IAffiliatesFilter) => {
        dispatch(singleSelect({
            pageId: 'admin/billing/partner-payments',
            filterId: 'affiliate',
            data: affiliate
        }))
    }

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case "vendor_id":
                return (
                    <IncludeExcludeFilter
                        selectedFilterText={vendorIdsFilter.selectedData[0] ? vendorIdsFilter.selectedData[0].vendor_id : ''}
                        filter={vendorIdsFilter}
                        filterTitle={'Vendor ID'}
                        hasInclude={false}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                            {vendorIdsFilter.data.map(option =>
                                <DropdownItem
                                    key={option.vendor_id}
                                    selected={
                                        vendorIdsFilter.selectedData.length > 0 &&
                                        vendorIdsFilter.selectedData[vendorIdsFilter.selectedData.length - 1].vendor_id === option.vendor_id
                                    }
                                    onClick={() => handleVendorSelect(option)}
                                >
                                    {option.vendor_id}
                                </DropdownItem>
                            )
                        }
                    </IncludeExcludeFilter>
                )
            case 'affiliate':
                return (
                    <IncludeExcludeFilter
                        selectedFilterText={
                            affiliateFilter.selectedData[0] ?
                                affiliateFilter.selectedData[0].name + ' ' +
                                affiliateFilter.selectedData[0].lastname +
                                ` (${affiliateFilter.selectedData[0].id})`
                                : ''
                        }
                        filter={affiliateFilter}
                        filterTitle={'Partners'}
                        hasInclude={false}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {affiliateFilter.data.map(affiliate => {
                            return <DropdownItem
                                key={affiliate.id}
                                selected={
                                    affiliateFilter.selectedData.length > 0 &&
                                    affiliateFilter.selectedData[affiliateFilter.selectedData.length - 1].id === affiliate.id
                                }
                                onClick={() => handleAffiliateSelect(affiliate)}
                            >
                                {affiliate.name} {affiliate.lastname} ({affiliate.id})
                            </DropdownItem>
                        })}
                    </IncludeExcludeFilter>
                )
            default:
                return <></>
        }
    }

    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if(clearFilterClickCount.current === 1){
            setDisplayClearFilterTooltip(true)
        }
        if(clearFilterClickCount.current > 1){
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(removeAllFilters('admin/billing/partner-payments'))
        }
    }


    return <Wrapper>
        <i className={'ico-Filters'}/>
        {filters.filter((filter: any) => filter.pageID === 'admin/billing/partner-payments')[0]?.filters.map((filter: any) => <div key={filter.id}>{renderFilter(filter.id)}</div>)  }
        <SelectFilterDropdown allFilters={filterList} pageId={'admin/billing/partner-payments'}/>
        {filters.filter((filter: any) => filter.pageID === 'admin/billing/partner-payments')[0]?.filters.length > 1 &&
            <ButtonStyled ref={clearFilterButtonRef} className={'clear-filters'} onClick={handleClearFilters}>
                {
                    displayClearFilterTooltip
                    &&
                    <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed(true)} />
                }
                Clear All Filters
            </ButtonStyled>}
    </Wrapper>
}

export default Filters