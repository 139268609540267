import React, {useEffect} from 'react';
import ReactTooltip from "react-tooltip";
import {ButtonHolder, OfferTitle, OfferTypeBox, OfferTypeHolder} from "./CreateOffers.style";
import {setAppsSelected, setNextPage, setOffer, setPrevPage, setShowBack} from "./create-offer.slice";
import {
    AppInstallIcon,
    CallsIcon,
    CheckedIcon,
    ClicksIcon,
    LeadsIcon,
    SalesIcon
} from "../../../../assets/svgs/SvgIcons";
import {useLocation, useNavigate} from "react-router-dom";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {useInitialDataFetch} from "./hooks/useInitialDataFetch";
import {OfferTypeTooltip} from "./shared-components/Shared";
import {CPA, CPC, CPE, CPI, CPL, CPS, OFFER_INFO, PPC} from "./interfaces/interfaces";

const OfferType : React.FC = () => {
    const { offer, nextPage, appsSelected } = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const {loading, fetch} = useInitialDataFetch()
    useEffect(() => {
        ReactTooltip.rebuild()
    })

    useEffect(()=>{
        if (offer.detailed_pricing_model !== null){
            if (offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE ){
                dispatch(setNextPage([location.pathname, offer.type].join("/")))
            }
            else{
                dispatch(setNextPage(location.pathname.replace("type", "i/offer-info")))
            }
        } else {
            dispatch(setNextPage([location.pathname, offer.type].join("/") ))
        }
        dispatch(setPrevPage(""))
        dispatch(setShowBack(false))

        dispatch(setOffer({
            mobile_platform: '',
            mobile_type: '',
            operating_system_id: null
        }))

    }, [])

    const handleContinue = async () => {
        try{
            if(!appsSelected){
                await fetch()
                navigate(nextPage)
            } else {
                navigate('apps')
            }

        } catch (e) {
            console.log(e)
        }


    }


    return (

        <div>
            <OfferTitle>
                Which outcome are you seeking for this campaign?
            </OfferTitle>
            <OfferTypeHolder>
                <OfferTypeBox
                    disabled={loading}
                    active={offer.detailed_pricing_model === CPS} onClick={() => {
                    dispatch(setOffer({
                        pricing_model : CPA,
                        detailed_pricing_model: CPS}))
                    dispatch(setAppsSelected(false))
                    dispatch(setNextPage("../i/" + OFFER_INFO))
                    }
                }>
                    <div>
                        <SalesIcon/>
                        <div>Sales / Subscription</div>
                        {OfferTypeTooltip(168, <>
                            <p>Choose this outcome type when your goal for this campaign is to generate sales or
                                subscriptions for your business.</p>
                            <p>Also commonly referred to as a CPS (Cost Per Sale) campaign.</p>
                        </>)}
                    </div>
                    <CheckedIcon display={offer.detailed_pricing_model === CPS ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox
                    disabled={loading}
                    active={offer.detailed_pricing_model === CPL}
                    onClick={() => {
                        dispatch(setOffer({
                            pricing_model : CPA,
                            detailed_pricing_model: CPL
                        }))
                        dispatch(setNextPage("../i/" + OFFER_INFO))
                        dispatch(setAppsSelected(false))
                    }}
                >
                    <div>
                        <LeadsIcon/>
                        <div>
                            Leads / Form Submissions
                        </div>
                        {OfferTypeTooltip(168,<>
                           <p> Choose this outcome type when your goal for this campaign is to generate leads or
                               form submissions for your business.</p>
                            <p>Also commonly referred to as a CPL (Cost Per Lead) campaign.</p>
                        </>)}
                        
                    </div>
                    <CheckedIcon display={offer.detailed_pricing_model === CPL ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox
                    disabled={loading}
                    active={appsSelected}
                    onClick={() => {
                        dispatch(setAppsSelected(true))
                        dispatch(setOffer({
                            detailed_pricing_model: null,
                            pricing_model : CPA,
                        }))
                        dispatch(setNextPage("apps"))
                    }
                }>
                    <div>
                        <AppInstallIcon/>
                        <div>App Install / Engagement</div>
                        {OfferTypeTooltip(168,<>
                            <p>Choose this outcome type when your goal for this campaign is to generate an
                                install and opening or specific engagement(s) in your businesses mobile app.</p>
    
                            <p>Also commonly referred to as a CPI (Cost Per Install) or CPE (Cost Per
                                Engagement)
                                campaign.</p>
                        </>)}
                    </div>
                    <CheckedIcon display={(appsSelected) ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox
                    disabled={loading}
                    active={offer.detailed_pricing_model === CPC} onClick={() => {
                    dispatch(setAppsSelected(false))
                    dispatch(setOffer({
                        pricing_model : CPC,
                        detailed_pricing_model: CPC
                        }))
                    dispatch(setNextPage("../i/" + OFFER_INFO))
                }}>
                    <div>
                        <ClicksIcon/>
                        <div>Clicks</div>
                        {OfferTypeTooltip(168,<>
                            <p>Choose this outcome type when your goal for this campaign is to generate clicks
                                for a defined page of your businesses website.</p>
                            <p>Also commonly referred to as a CPC (Cost Per Click) or PPC (Pay Per Click)
                            campaign.</p></>)}
                    </div>
                    <CheckedIcon display={offer.detailed_pricing_model === CPC ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
                <OfferTypeBox
                    disabled={loading}
                    active={offer.detailed_pricing_model === PPC}
                    onClick={() => {
                        dispatch(setAppsSelected(false))
                        dispatch(setOffer({
                            pricing_model : CPA,
                            detailed_pricing_model: PPC}))
                            dispatch(setNextPage("../i/" + OFFER_INFO))}
                    }
                >
                    <div>
                        <CallsIcon/>
                        <div>Customer Calls</div>
                        {OfferTypeTooltip(168,<>
                            <p>Choose this outcome type when your goal for this campaign is
                                to generate inbound customer calls for your business.</p></>)}
                    </div>
                    <CheckedIcon display={offer.detailed_pricing_model === PPC ? 'block' : 'none'} className={"checked-icon"}/>
                </OfferTypeBox>
            </OfferTypeHolder>
            <ButtonHolder>
                {(offer.detailed_pricing_model || appsSelected) &&
                        <ButtonStyled
                            onClick={handleContinue}
                            className={"btn-blue-filled btn-block btn-size-lg full-radius"}
                            disabled={loading}
                        >{loading ? <BtnLoadingSpinner/> : null}Continue</ButtonStyled>
                }
            </ButtonHolder>
        </div>
    );
};

export default OfferType