import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {IAccountManagerFilter, IAdvertisersFilter, IFilterHookReturn} from "filters";
import {useFilterSelector} from "../useFilterSelector";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useAppDispatch} from "../useAppDispatch";

export const useAdvertisersFilter = (pageId: PageID ,filterBy: 'name' | 'company_name' = 'name', displayCount?: number): IFilterHookReturn<IAdvertisersFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IAdvertisersFilter[]>([])
    const [advertisers, setAdvertisers] = useState<IAdvertisersFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [advertisersFilterSearchValue, setAdvertiserFilterSearchValue] = useState('')
    const [advertisersFilterNotDisplayed, setAdvertisersFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id = 'advertiser'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IAdvertisersFilter[]>(
                'advertisers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredAdvertisers = fetchedData.filter(advertiser => {
            return (
                filterBy === 'name' ? advertiser.display_name?.toLowerCase().includes(advertisersFilterSearchValue.toLowerCase()) : advertiser.company?.toLowerCase().includes(advertisersFilterSearchValue.toLowerCase())
                    ||
                advertiser.id?.toString().includes(advertisersFilterSearchValue.toLowerCase())
            )
        })

        if(displayCount){
            const displayedAcc = [...filteredAdvertisers].slice(0, displayCount)
            if(filteredAdvertisers.length > displayCount){
                setAdvertisersFilterNotDisplayed(filteredAdvertisers.length - displayCount)
            } else {
                setAdvertisersFilterNotDisplayed(0)
            }
            setAdvertisers(displayedAcc)
        } else {
            setAdvertisers(
                filteredAdvertisers
            )
        }
    }, [advertisersFilterSearchValue, fetchedData, displayCount])

    const advertiserFilter = useFilterSelector<IAdvertisersFilter>(pageId, id)

    const setSelectedAdvertisers = (advertiser: IAdvertisersFilter) => {
        if(advertiserFilter.selected.some((filterAdvertiser)  => filterAdvertiser.id === advertiser.id)){
            dispatch(setFilter<IAdvertisersFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: advertiserFilter.exclude,
                    selected: advertiserFilter.selected.filter(filterAdvertiser => filterAdvertiser.id !== advertiser.id)
                }
            }))
        } else {
            dispatch(setFilter<IAdvertisersFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: advertiserFilter.exclude,
                    selected: [...advertiserFilter.selected, advertiser]
                }

            }))
        }
    }

    const setExcludeAdvertiser= (exclude: boolean) => {
        dispatch(setFilter<IAdvertisersFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: advertiserFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }


    return {
        id,
        pageId: pageId,
        loading,
        data: advertisers,
        setSearchValue: setAdvertiserFilterSearchValue,
        notDisplayed: advertisersFilterNotDisplayed,
        selectedData: advertiserFilter.selected,
        exclude: advertiserFilter.exclude,
        setSelectedData: setSelectedAdvertisers,
        setExclude: setExcludeAdvertiser,
        clearSelected: clearSelectedFilter
    }
}