import {useCallback, useEffect, useState} from "react";

import {filterApi} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID, IAdvertisersFilter} from "filters";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useAppDispatch} from "../../useAppDispatch";

export const useAdvertisersCacheFilter = (pageId: IPagePresetsID ,
                                          filterBy: 'name' | 'company_name' = 'name',
                                          displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IAdvertisersFilter[]>([])
    const [advertisers, setAdvertisers] = useState<IAdvertisersFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [advertisersFilterSearchValue, setAdvertiserFilterSearchValue] = useState('')
    const [advertisersFilterNotDisplayed, setAdvertisersFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id : FilterID= 'advertiser'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IAdvertisersFilter[]>(
                'advertisers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredAdvertisers = fetchedData.filter(advertiser => {
            return (
                filterBy === 'name' ? advertiser.display_name?.toLowerCase().includes(advertisersFilterSearchValue.toLowerCase()) : advertiser.company?.toLowerCase().includes(advertisersFilterSearchValue.toLowerCase())
                    ||
                advertiser.id?.toString().includes(advertisersFilterSearchValue.toLowerCase())
            )
        })

        if(displayCount){
            const displayedAcc = [...filteredAdvertisers].slice(0, displayCount)
            if(filteredAdvertisers.length > displayCount){
                setAdvertisersFilterNotDisplayed(filteredAdvertisers.length - displayCount)
            } else {
                setAdvertisersFilterNotDisplayed(0)
            }
            setAdvertisers(displayedAcc)
        } else {
            setAdvertisers(
                filteredAdvertisers
            )
        }
    }, [advertisersFilterSearchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (item: IAdvertisersFilter) => {
        if (filter.selected.some((selected) => selected.id === item.id)) {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter,
                    selected: filter.selected.filter(selected => selected.id !== item.id)
                }
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {
                    ...filter, selected: [...filter.selected, item]
                }

            }))
        }
    }

        const setInclude = (include: boolean) => {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, include: include}
            }))
        }



        return {
            id: id,
            options: advertisers,
            selected: {
                value: filter.selected,
                setSelected
            },
            include: {
                value: filter.include,
                setInclude
            },
            notDisplayed: advertisersFilterNotDisplayed,
            search: {
                value: advertisersFilterSearchValue,
                setSearch: setAdvertiserFilterSearchValue
            },
            loading
        }
}