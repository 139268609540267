import { PauseRiskWrapper } from "./PauseRisk.style";

const PauseRisk = ({risk} : {risk: number}) => {
    return(
        <PauseRiskWrapper>
            {risk === 5 && <div style={{background: "#740F15"}}>PAUSED</div> }
            {risk === 4 && <div style={{background: "#CE1E29"}}>VERY HIGH</div> }
            {risk === 3 && <div style={{background: "#FE9800"}}>HIGH</div> }
            {risk === 2 && <div style={{background: "#F3C315"}}>MEDIUM</div> }
            {risk === 1 && <div style={{background: "#0CAB5F"}}>LOW</div> }
            {risk === 0 && <div style={{background: "#8799A2"}}>NO RISK</div> }
        </PauseRiskWrapper>
    )
}

export default PauseRisk;