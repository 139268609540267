import React from 'react';
import styled from "styled-components";
import Modal from "../../../../../common/components/modal/Modal";
import {Footer} from "../../../../../common/components/modalWindow/container/Container.style";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../common/styled-components";

interface Props {
    opened: boolean,
    closeModal: () => void
    title: string
    onSave: () => void
    discardChanges: () => void
    isSaveDisabled: boolean,
    submitButtonContent: string,
    loading: boolean
}

const SaveChangesModal: React.FC<Props> = ({
    opened,
    closeModal,
    title,
    onSave,
    discardChanges,
    isSaveDisabled,
    submitButtonContent,
    loading
                                           }) => {
    return (
        <Modal opened={opened}
               noHeaderUnderline={true}
               title={<strong style={{fontSize: 24}}>{title}</strong>}
               closeModal={closeModal}>

            <Wrapper>
                <Content>
                    You have unsaved changes to this campaign draft, do you want to save them or discard them before exiting?
                </Content>
                <Footer style={{ border: "none"}}>
                    <ButtonStyled
                        onClick={closeModal}
                        className={"btn-cancel"}
                    >
                        Cancel
                    </ButtonStyled>
                    <div style={{gap: 8, display: "flex"}}>
                        <ButtonStyled
                            onClick={discardChanges}
                            className={"btn-orange-outlined"}
                        >
                            Discard Changes
                        </ButtonStyled>
                        <ButtonStyled
                            disabled={isSaveDisabled || loading}
                            onClick={onSave}
                            className={"btn-blue-filled"}
                        >
                            {loading && <BtnLoadingSpinner/>}{submitButtonContent}
                        </ButtonStyled>
                    </div>
                </Footer>
            </Wrapper>
        </Modal>
    )
}

const Wrapper = styled.div`
    margin-top: 40px;
    max-width: 452px;
`
const Content = styled.div`
    font-family: ${({theme})=> theme.fonts.AvenirLight};
    color: ${({theme})=> theme.colors.deepBlue[6]};
    font-size: 14px;
`

export default SaveChangesModal