import * as React from 'react';
import axios from "axios";
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {ColDef, ColumnState, DragStoppedEvent, GridApi} from "ag-grid-community";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";

import {IUser} from "../../../../../common/models/user.type";
import GridRowOptions from "../../../../../common/components/data-grid/grid-row-options/GridRowOptions";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import DataGrid from "../../../../../common/components/data-grid/DataGrid";
import Modal from "../../../../../common/components/modal/Modal";
import {useAppSelector, useCopy} from "../../../../../common/hooks";
import {tokenStore, userStore} from "../../../../../store/auth.slice";
import UsersService from "../Users.service";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import {
    ADMIN_USER_STATUS_ACTIVE,
    ADMIN_USER_STATUS_TERMINATED,
    ADMIN_USER_STATUS_INACTIVE,
    ADMIN_USER_STATUS_DELETED,
    ADMIN_USER_ACTION_DELETE,
    ADMIN_USER_ACTION_DISABLE,
    ADMIN_USER_ACTION_ENABLE,
    ADMIN_USER_ACTION_TERMINATE,
} from "../../../../../common/utils/consts";

import MenageVerifiedDevices from "../../../../../common/components/2fa/manage-verified-devices/ManageVerifiedDevices";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";
import {ButtonStyled, InputStyledLabel} from "../../../../../common/styled-components";
import {ModalBody} from "../Users.style";
import AddDeviceModal from "../../../../../common/components/2fa/add-device-modal/AddDeviceModal";

export interface UserListProps {
    onGridReady: (event: GridReadyEvent) => void;
    saveTablePreferences: (data: ColumnState[]) => void,
    fetchUsers: () => void,
    gridApi: GridApi | undefined,
    selectedUser : IUser,
    setSelectedUser: Dispatch<SetStateAction<IUser>>,
    isCreateUserModalOpened : boolean,
    setCreateUserModalOpened: Dispatch<SetStateAction<boolean>>
    setCreateUser: Dispatch<SetStateAction<boolean>>
    twoFactorModalInitialOpened: boolean
}

interface IAlertModal {
    opened: boolean,
    message: string,
    type: 'success' | 'error'
}

export const UsersTable: React.FC<UserListProps> = ({
                                                        onGridReady,
                                                        saveTablePreferences,
                                                        fetchUsers,
                                                        gridApi,
                                                        selectedUser,
                                                        setSelectedUser,
                                                        isCreateUserModalOpened,
                                                        setCreateUserModalOpened,
                                                        setCreateUser,
                                                        twoFactorModalInitialOpened
                                                    }) => {
    const token = useAppSelector(tokenStore)
    const user = useAppSelector(userStore);
    const [isModalOpened, setModalOpened] = useState(false)
    const [isAccManModalOpened, setAccManModalOpened] = useState<boolean>(false)
    const [accManDropdownOpened, setAccManDropdownOpened] = useState<boolean>(false)
    const [searchAccMan, setSearchAccMan] = useState<string>("")
    const [selectedAccMan, setSelectedAccMan] = useState<IUser>({} as IUser)
    // const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser)
    const [filteredAccMan, setFilteredAccMan] = useState<IUser[]>([])
    const [otherAccMan, setOtherAccMan] = useState<IUser[]>([])
    const [verifyPhoneSuccessModal, setVerifyPhoneSuccessModal] = useState(false)

    const [userAction, setUserAction] = useState<string>("")


    const [referralUrl, setReferralUrl] = useState('');
    const [copied, copy, setCopied] = useCopy(referralUrl);
    const [isManageVerifiedDevicesModalOpened, setIsManageVerifiedDevicesModalOpened] = useState(false)
    const [isAddDeviceModalOpened, setIsAddDeviceModalOpened] = useState(false)
    const [alertModal, setAlertModal] = useState<IAlertModal>(
        {
            opened: false,
            message: '',
            type: 'success'
        }
    )

    useEffect(() => {
        setIsAddDeviceModalOpened(twoFactorModalInitialOpened)
    }, [twoFactorModalInitialOpened])


    // window.addEventListener("message", (event: any) => {
    //     console.log('dasfa',event)
    //     if (event.origin === process.env.REACT_APP_API_BASE_URL) {
    //
    //         if(event.data.open2faModal){
    //             setIsManageVerifiedDevicesModalOpened(true)
    //         }
    //     }
    // }, false);


    useEffect(()=>{
       if (referralUrl !== ""){
           copy()
       }
    },[copy, referralUrl])

    const copyReferralLink = async (id: number) => {
        try {
            const {data} = await UsersService.fetchReferralLink(token, id)
            setReferralUrl(data)
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }

    }
    const handleAccMan = (accMan : IUser) => {
        setSelectedAccMan(accMan)
        setAccManDropdownOpened(false)
    }

    useEffect(()=>{
        let filteredAccTemp = otherAccMan.filter(acc => acc.id.toString().includes(searchAccMan.toLowerCase()) ||
            acc.name.includes(searchAccMan.toLowerCase()) || acc.lastname.includes(searchAccMan.toLowerCase()) ||
            acc.full_name?.includes(searchAccMan.toLowerCase()))
        setFilteredAccMan(filteredAccTemp)
    },[searchAccMan])


    const assignManager = async (action: string, user: IUser) => {
        let newUser = {...user}
        switch (action) {
            case ADMIN_USER_ACTION_DISABLE:
                newUser.status = ADMIN_USER_STATUS_INACTIVE
                newUser.is_enabled = 0
                break;
            case ADMIN_USER_ACTION_TERMINATE:
                newUser.status = ADMIN_USER_STATUS_TERMINATED
                break;
            case ADMIN_USER_ACTION_DELETE:
                newUser.status = ADMIN_USER_STATUS_DELETED
                break;
            case ADMIN_USER_ACTION_ENABLE:
                newUser.status = ADMIN_USER_STATUS_ACTIVE
                newUser.is_enabled = 1
                break;
            default:
                break;
        }
        try {
            await UsersService.disableOrDeleteOrAssignNew(token, newUser);
            await fetchUsers()
            setAlertModal({
                opened: true,
                message: 'User status set to ' + action,
                type: 'success'
            })
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }

    const assignToManager = (managers: IUser[], action: string, user: IUser) => {
        setSelectedUser(user)
        setAccManModalOpened(true)
    }

    const assignNewManager = async () => {
        let newUser = {...selectedUser, new_manager : selectedAccMan.id }
        setAccManModalOpened(false)
        await assignManager(userAction, newUser)
        setSelectedAccMan({} as IUser)
    }
    const openCreateOrEditModal = (user : IUser) => {
        setCreateUserModalOpened(true)
        setSelectedUser(user)
        setCreateUser(false)
    }

    const checkForDeleteAndDelete = async (action: string, status: string, user: IUser) => {
        gridApi?.showLoadingOverlay()
        setUserAction(action)
        switch (status) {
            case ADMIN_USER_STATUS_ACTIVE:
                const {data} = await UsersService.checkDelete(token, user.id)
                if (data.success) {
                    if (data.is_account_manager) {
                        if (!data.other_managers || data.other_managers.length <= 0) {
                            await assignManager(action, user)
                        } else {
                            setOtherAccMan(data.other_managers)
                            setFilteredAccMan(data.other_managers)
                            assignToManager(data.other_managers, action, user)
                        }
                    } else {
                        await assignManager(action, user)
                    }
                } else {
                    setAlertModal({
                        opened: true,
                        message: 'User cannot be deleted!',
                        type: 'error'
                    })
                }
                break;
            case ADMIN_USER_STATUS_INACTIVE:
                await assignManager(action, user)
                break
        }
    }

    //Define columns for the table
    const defaultColumnDefs = useMemo<ColDef>(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const columnDefs = useMemo<ColDef[]>(() => [
        {
            colId: 'id',
            field: 'id',
            headerName: 'id',
            width: 100,
            headerComponent: () => {
                return <span>ID</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.id}</span>
            }
        },
        {
            colId: 'username',
            field: 'username',
            headerName: 'username',
            width: 350,
            headerComponent: () => {
                return <span>USERNAME/EMAIL</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.email}</span>
            }

        },
        {
            colId: 'realName',
            field: 'realName',
            headerName: 'realName',
            width: 250,
            headerComponent: () => {
                return <span>REAL NAME</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.full_name ? data.full_name : data.name + " " + data.lastname}</span>
            }
        },
        {
            colId: 'role',
            field: 'role',
            headerName: 'role',
            width: 150,
            headerComponent: () => {
                return <span>ROLE</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.role ? data.role.name : ""}</span>
            }
        },
        {
            colid: 'job',
            field: 'job',
            headerName: 'job',
            width: 200,
            headerComponent: () => {
                return <span>JOB</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.approve_sector ? data.approve_sector.media ? 'Media rep ' : "" : ""}
                    {data.approve_sector ? data.approve_sector.compliance ? 'Compliance rep ' : "" : ""}</span>
            }
        },
        {
            colId: 'status',
            field: 'status',
            headerName: 'status',
            width: 100,
            headerComponent: () => {
                return <span>STATUS</span>
            },
            cellRenderer: ({data}: { data: IUser }) => {
                return <span>{data.status == 'active' ?
                    'Enabled' : data.status == 'terminated' ?
                        'Terminated' : 'Disabled'}</span>
            }
        },
        {
            colId: '3dots',
            pinned: 'right',
            width: 50,
            resizable: false,
            suppressMovable: false,
            lockPinned: true,
            cellRenderer: ({data}: { data: IUser }) => {
                return <GridRowOptions>
                    {data.role?.id === 2 &&
                        <DropdownItem onClick={() => copyReferralLink(data.id)}>
                            Copy Referral link
                        </DropdownItem>
                    }
                    {data.status === 'active' ?
                        <>
                            <DropdownItem onClick={() => openCreateOrEditModal(data)}>
                                Edit
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => checkForDeleteAndDelete(ADMIN_USER_ACTION_DISABLE, data.status, data)}>
                                Disable
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => checkForDeleteAndDelete(ADMIN_USER_ACTION_DELETE, data.status, data)}>
                                Delete
                            </DropdownItem>
                        </> :
                        <DropdownItem
                            onClick={() => checkForDeleteAndDelete(ADMIN_USER_ACTION_ENABLE, data.status, data)}>
                            Enable
                        </DropdownItem>
                    }
                    {user.id === data.id &&
                        <DropdownItem onClick={() => {
                            setIsManageVerifiedDevicesModalOpened(true)
                        }}>
                            2FA
                        </DropdownItem>
                    }
                </GridRowOptions>
            }
        }
    ], [checkForDeleteAndDelete])
    return (
        <>
            <DataGrid
                onGridReady={onGridReady}
                fixedScroll={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDefs}
                rowHeight={40}
                domLayout={"autoHeight"}
                allColumnsDisabled={true}
                onDragStopped={(api: DragStoppedEvent) => {
                    saveTablePreferences(api.columnApi.getColumnState())
                }}>
            </DataGrid>
            <Modal opened={isManageVerifiedDevicesModalOpened}
                   title={'Two Factor Authentication'} closeModal={() => setIsManageVerifiedDevicesModalOpened(false)}>
                <div style={{
                    width: '476px',
                    maxWidth: '100%'
                }}>
                    <MenageVerifiedDevices manageTooltip={false}/>
                </div>
            </Modal>
            {twoFactorModalInitialOpened &&
                <Modal opened={isAddDeviceModalOpened}
                       title={'Add device'} closeModal={() => setIsAddDeviceModalOpened(false)}>
                    <AddDeviceModal
                        closeModal={() => setIsAddDeviceModalOpened(false)}
                        opened={isAddDeviceModalOpened}
                        openSuccessModal={() => setVerifyPhoneSuccessModal(true)}
                        fetchDevices={() => {}}
                    />
                </Modal>
            }

            <Modal
                opened={isAccManModalOpened}
                title={"Assign Account Manager"}
                closeModal={() => setAccManModalOpened(false)}>
                <ModalBody>
                   <InputStyledLabel>
                       Do you want to assign all accounts from this account manager to someone else?
                   </InputStyledLabel>
                <Dropdown text={selectedAccMan?.id ?
                        selectedAccMan.name + " " + selectedAccMan.lastname + " (" + selectedAccMan.id + ")"
                        :
                        'Select Manager'}
                          search={{
                              value: searchAccMan,
                              onChange: setSearchAccMan,
                              placeholder: "Select Account Manager"
                          }}
                          dropdownHeightLimit={6}
                          isOpened={accManDropdownOpened}
                          setOpened={setAccManDropdownOpened}>
                    {
                        filteredAccMan.slice(0,50).map(accMan => (
                            <DropdownItem
                                selected={false}
                                onClick={()=> handleAccMan(accMan)}
                                key={accMan.id}
                            >
                                {accMan.name + " " + accMan.lastname + " (" + accMan.id + ")"}
                            </DropdownItem>
                        ))
                    }
                </Dropdown>
                </ModalBody>
                <ModalFooter>
                    <ButtonStyled className={'btn-cancel'}
                    onClick={()=> {
                        setAccManModalOpened(false)
                        setSelectedAccMan({} as IUser)
                    }}>
                    Cancel </ButtonStyled>
                    <ButtonStyled disabled={selectedAccMan.id === (0 || null || undefined)} className={'btn-orange-filled'}
                    onClick={()=>assignNewManager()}>
                    Save
                    </ButtonStyled>
                </ModalFooter>

            </Modal>
            <AlertModal isOpen={alertModal.opened}
                        type={alertModal.type}
                        closeModal={() => setAlertModal({opened: false, message: '', type: 'success'})}/>
            <AlertModal
                isOpen={verifyPhoneSuccessModal}
                type={'success'}
                closeModal={() => setVerifyPhoneSuccessModal(false)}
                closeButtonClassName={'btn-blue-filled'}
            >
                <p>The new device has been added to your account to <br/> use for additional security verification.</p>
            </AlertModal>

        </>

    );
};