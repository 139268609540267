import React, {useState} from 'react';
import styled from "styled-components";
import {StyledSelect, SelectOption} from "../../../../../common/components/select/Select";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {BUDGET_TYPES, BUDGET_AMOUNT_TYPES, DOLLAR_CURRENCY} from "../shared-components/consts";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    > div {
        flex: 1;
    }
`
export const OfferBudget: React.FC = ({}) => {
    const dispatch = useAppDispatch()
    const {offer, selectedBudgetAmountType} = useAppSelector(state => state.createOffer)
    const [budget, setBudget] = useState<string>('0')
    // const [budgetAmountType, setBudgetAmountType] = useState<SelectOption>(BUDGET_AMOUNT_TYPES[0])

    const handleOnChange = (e: any) => {
        dispatch(setOffer({
            budget_type: e.value
        }))
    }

    const handleBudgetIncrease = () => {
        if (offer.budget_value_type === 0) {
            dispatch(setOffer({
                budget: parseFloat((offer.budget + .01).toFixed(2))
            }))
        } else {
            dispatch(setOffer({
                budget: parseFloat((offer.budget + 1).toFixed(2))
            }))
        }
    }

    const handleBudgetDecrease = () => {
        if (offer.budget_value_type === 0) {
            if (offer.budget > 0) {
                dispatch(setOffer({
                    budget: parseFloat((offer.budget - .01).toFixed(2))
                }))
            }
        } else {
            if (offer.budget > 0) {
                dispatch(setOffer({
                    budget: parseFloat((offer.budget - 1).toFixed(2))
                }))
            }
        }
    }

    const handleBudgetChange = (e: string | undefined) => {
        if(e){
            dispatch(setOffer({budget: parseFloat(e)}))
        }
        else{
            dispatch(setOffer({budget: 0}))
        }
    }


    const handleBudgetAmountTypeChange = (option: SelectOption) => {
        /**
         * @todo modify this function to calculate correct values based on offer payout, add case when payout is zero
         */

        let test_budget_exists = typeof offer.link_test_budget_value !== 'undefined';

        let budget = offer.budget
        let link_test_budget_value = offer.link_test_budget_value
        let payout = 0;
        if(typeof offer.is_revshare != 'undefined' && offer.is_revshare === true && offer.revshare.length) {

            for (let i = 0; i < offer.revshare.length; i++) {
                if (offer.revshare[i].value && parseFloat(offer.revshare[i]?.value!) > payout) {
                    payout = parseFloat(offer.revshare[i].value!);
                }
            }
        } else {
            payout = offer.cpa
        }

        if(option.value === 1) {
            dispatch(setOffer({
                budget: Math.ceil(budget / payout),
                budget_value_type: option.value
            }))
            if(test_budget_exists) {
                dispatch(setOffer({
                    link_test_budget_value: Math.ceil(link_test_budget_value / payout)
                }))
            }
        } else {
            dispatch(setOffer({
                budget: budget * payout,
                budget_value_type: option.value
            }))
            if(test_budget_exists) {
                dispatch(setOffer({
                    link_test_budget_value: link_test_budget_value * payout
                }))

            }
        }

        // if (option.value === 0) {
        //     dispatch(setOffer({
        //         budget: parseFloat((offer.budget * payout).toFixed(2)),
        //         budget_value_type: option.value
        //     }))
        //     // setBudget((parseFloat(budget) * payout).toFixed(2))
        // } else {
        //     dispatch(setOffer({
        //         budget: Math.floor((offer.budget / payout)),
        //         budget_value_type: option.value
        //     }))
        //     // setBudget(Math.floor(parseFloat(budget) / payout).toString())
        // }
        // dispatch(setSelectedBudgetAmountType(option))
        // dispatch(setOffer({
        //     ...offer,
        //     budget_value_type: option.value
        // }))
    }


    return (
        <Wrapper>
            <StyledSelect
                options={BUDGET_TYPES}
                selectLabel={"Campaign Budget"}
                classNamePrefix={'styled-select'}
                // @ts-ignore
                value={BUDGET_TYPES.find(type => type.value === offer.budget_type.toLocaleLowerCase())}
                onChange={handleOnChange}
            />
            {
                offer.budget_type !== 'unlimited' &&
                <>
                    {offer.detailed_pricing_model !== 'cpc' &&
                        <StyledSelect options={BUDGET_AMOUNT_TYPES}
                            // @ts-ignore
                                      value={BUDGET_AMOUNT_TYPES.find(type => type.value === offer.budget_value_type)}
                                      placeholder={DOLLAR_CURRENCY}
                                      selectLabel={"Currency"}
                                      onChange={(e) => handleBudgetAmountTypeChange(e as SelectOption)}
                        />}
                    <div style={offer.detailed_pricing_model === 'cpc' ? {maxWidth: 150} : undefined}>
                        <CurrencyInput
                            label={"Amount"}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            decimalsLimit={2}
                            step={offer.budget_value_type === 0 ? .01 : 1}
                            placeholder={offer.budget_value_type === 0 ? '0.00' : '0'}
                            prefix={offer.budget_value_type === 0 ? '$' : ''}
                            value={offer.budget}
                            allowDecimals={offer.budget_value_type === 0}
                            allowNegativeValue={false}
                            inputControl={{up: handleBudgetIncrease, down: handleBudgetDecrease}}
                            onValueChange={(e) => handleBudgetChange(e)}
                        />
                    </div>
                </>
            }
        </Wrapper>
    );
};