import styled from "styled-components";

export const InfoSectionWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: flex-start;
    padding: 23px 18px;
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    border-radius: 8px;
    border: 1px solid #63E7F9;
    i {
        color: ${({theme}) => theme.colors.performBlue[5]};
        margin-top: 2px;
        font-size: 13px;
    }
    .text {
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`;