import {useEffect} from 'react'

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

export const useSimulateMouseClick = (element: HTMLElement | null, triggers: any[]) => {
    useEffect(() => {
        mouseClickEvents.forEach(mouseEventType =>
            element && element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }, [...triggers])
}