import styled from 'styled-components'
import {TNicheName} from "../../NetworkView.service";

interface ILegendItemProps{
    $type: TNicheName,
    $value: number
}

export const Wrapper = styled.div<ILegendItemProps>`
  display: flex;
  gap: 8px;
  .box {
    margin-top: 4px;
    height: 12px;
    width: 12px;
    background: ${({$type, $value, theme}) => {
      if($value === 0){
        return theme.colors.deepBlue[1]
      }
      if($type === 'Downloads & Software'){
        return 'linear-gradient(180deg, #9563FF 0%, #0B05AC 100%)'
      }
      if($type === 'Entertainment & Lifestyle'){
        return 'linear-gradient(0deg, #002482 7.41%, #0038FF 100%)'
      }
      if($type === 'Financial'){
        return 'linear-gradient(0deg, #006A97 0%, #00E0FF 100%)'
      }
      if($type === 'Healthy & Beauty'){
        return 'linear-gradient(0deg, #009718 0%, #BFF103 100%)'
      }
      if($type === 'E-Commerce'){
        return 'linear-gradient(0deg, #A84000 0%, #FFA800 100%)'
      }
     
      return 'linear-gradient(360deg, #616E6F 0%, #B5C1C2 100%)'
    }};
    border-radius: 2px;
  }

  .legend-name{
    font-size: 14px;
    color: ${({theme, $value}) => $value === 0 ? '#8799A1' : theme.colors.deepBlue[6]};
  }

  .legend-value{
    font-size: 16px;
    color: #5F35FF;
  }

  .legend-percentage{
    font-size: 14px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
`