import React, {ChangeEvent, useRef} from 'react'
import {DnDZoneWrapper} from "../../../Main.style";
import {useDropzone} from "react-dropzone";
import Flow from "@flowjs/flow.js";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../common/hooks";
import {setLastCreativeId, setOffer} from "../../../../../create-offer.slice";

interface Props {
    creativeIndex: number
}

const UploadEmailCreativesDropbox: React.FC<Props> = ({creativeIndex}) => {
    const dispatch = useAppDispatch()
    const {offer} = useAppSelector(state => state.createOffer)
    const {token, config} = useAppSelector(state => state.auth)

    const lastId = useRef(-1)

    const {
        getRootProps,
        getInputProps,
        isDragReject,
        isDragActive
    } = useDropzone({
        maxFiles: 20,
        accept: {
            'image/*': ['.png', '.jpg', '.gif'],
            'text/*': ['.txt', '.html']
        },
        onDrop: (acceptedFiles) => {
            const flow = new Flow({
                chunkSize: (1024 * 1024),// size in MB
                progressCallbacksInterval: 1000,
                simultaneousUploads: 3,
                maxChunkRetries: 1,
                testChunks: false,
                forceChunkSize: true,
                query: {
                    id: offer.creatives[creativeIndex].id,
                    campaign_id: offer.id
                },
                allowDuplicateUploads: true,
                target: config.apiBase + 'creative_upload',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            acceptedFiles.forEach((file, index) => {
                flow.addFile(file)
                flow.upload()
            })

            flow.on('fileSuccess', (flowFile, response) => {
                const newCreatives = [...offer.creatives]
                const newCreative = {...newCreatives[creativeIndex]}
                if(newCreative.files){
                    newCreative.files = [...newCreative.files, ...flowFile.flowObj.files.map(file => {
                        return {
                            id: 0,
                            name: file.name,
                            extension: file.name.split('.').pop()!,
                        }
                    })]
                }
                newCreatives[creativeIndex] = newCreative
                dispatch(setOffer({
                    creatives: [...newCreatives ]
                }))

            })

            flow.on('fileError', (flowFile) => {

            })
        }
    });

    return <>
        <DnDZoneWrapper dragActive={isDragActive} {...getRootProps({
            style: {
                borderColor: isDragReject ? 'red' : '',
                marginTop: '16px'
            }
        })}>
            <input {...getInputProps()}/>
            <div style={{
                transition: 'all .2s ease-in-out'
            }}>
                <div>
                    <i className="ico-Upload"/>
                </div>
                {isDragActive ? <>
                    <h4>Drop File to upload</h4>
                </> : <>
                    <h4>Drag & Drop file here or <span>Choose File</span> to upload</h4>
                    <p className="supported">
                        Upload html, text files or images as <br/>
                        HTML, TXT, PNG, JPG, or GIF.
                    </p>
                    <p className="maximum">
                        Maximum Uploads: 21
                    </p>
                </>}

            </div>
        </DnDZoneWrapper>
    </>
}

export default UploadEmailCreativesDropbox

/*
* TODO:
*  - handle upload file
*  - accepted file types
* */