import styled from "styled-components";

export const InformationTableWrapper = styled.div`
    width: 100%;
    border: 1px solid #D0D5D7;
    background: #FFFFFF;
    border-radius: 8px;
`;

export const InfoLabel = styled.div`
    border-bottom: 1px solid #D0D5D7;
    padding: 13px 16px;

    &:last-child {
        border: 0;
    }

    span {
        font-size: 12px;
        color: #8799A2;
        &:last-child {
            margin-left: 5px;
        }
    }
`;