import { useEffect, useState } from "react";
import { IDropdownMultiOptionsProps } from "./dropdown-multi-options.props";
import { DropdownMultiOptionsWrapper, Option, OptionName, OptionsFooter, OptionsList, OptionsSearch, SubOption, SubOptions } from "./DropdownMultiOptions.style";

const DropdownMultiOptions = ({
    isSearchable,
    options,
    isMultiselect, 
    selection,
    setSelection,
    open,
    setOpen,
    groupName,
    hasClearSelectionButton,
    width = "100%",
    isActiveOptionDisabled = false

}: IDropdownMultiOptionsProps) => {

    const [listData, setListData] = useState(options);

    useEffect(()=>setListData(options), [options, open]);

    const checkNameText = (item: any) => {
        if(item.full_name !== undefined) return `${item.full_name}`; //  item name comes in different forms (name, name + lastname, full_name)
        if(item.lastname !== undefined) return `${item.name} ${item.lastname}`;
        return `${item.name}`;
    }

    const selectHandler = (item: any) => {
        
        if(!selection.some((current: any) => current.id === item.id)){
            if(!isMultiselect){
                setSelection([item]);
                setOpen(false);
            } else if (isMultiselect){
                setSelection([...selection, item]);
            }
        } else{
            let copySelection = [...selection];
            !isMultiselect && setOpen(false);
            
            let filteredSelection = copySelection.filter(current => current.id !== item.id);
            setSelection([...filteredSelection]);
        }
    }

    const isSelected = (id: number) => {
        return selection.find((item: any) => item.id === id) ? true : false;
    }

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
        const filter = (data: any[], value: string) => {
            const filtered: any[] = [];
            data.forEach((item: any)=>{
              const children = item.group?.filter((child: any) => (child.name !== undefined && child.name.toLowerCase().includes(value.toLowerCase())) || (child.lastname !== undefined && child.lastname.toLowerCase().includes(value.toLowerCase())) || (child.full_name !== undefined && child.full_name.toLowerCase().includes(value.toLowerCase())));
              if (children?.length > 0 || (item.name !== undefined && item.name.toLowerCase().includes(value.toLowerCase())) || (item.lastname !== undefined && item.lastname.toLowerCase().includes(value.toLowerCase())) || (item.full_name !== undefined && item.full_name.toLowerCase().includes(value.toLowerCase()))) {
                filtered.push({
                  ...item, group:children
                })
              };
            })
            return filtered;
        }

        let value = e.target.value;
        
        const filteredData = filter(data, value);
        setListData(filteredData);
        
    }

    const clearSelectionHandler = () => {
        setSelection([]);
        setOpen(false);
    };
    const disableSelectHandler = (id: number) => isSelected(id) && isActiveOptionDisabled;
    return(
        <DropdownMultiOptionsWrapper $width={width}>
                    {isSearchable && 
                        <OptionsSearch >
                            <input className="input" type="text" onChange={(e)=>searchHandler(e, options)} placeholder="Search" />
                        </OptionsSearch>
                    }
                    <OptionsList>
                        {listData.map((item: any) => {
                            return  <div key={item.id.toString()}>
                                        <Option isDisabled={disableSelectHandler(item.id)} multiselect={isMultiselect} isActive={isSelected(item.id)} key={item.id.toString()} onClick={()=> {
                                            if(disableSelectHandler(item.id)) return;
                                            selectHandler(item)
                                        }
                                        }>
                                            {
                                            isMultiselect && 
                                            <div>
                                                <div className="checkbox" >
                                                    { isSelected(item.id) && <i className="ico-Check check"></i>}
                                                </div>
                                            </div>
                                            }
                                            <OptionName>{checkNameText(item)}</OptionName>
                                           
                                        </Option>
                                        {groupName && 
                                            <SubOptions>
                                                {item[groupName].map((it: any) => {
                                                return  <SubOption  isActive={isSelected(it.id)} multiselect={isMultiselect} key={it.id.toString()} onClick={()=>selectHandler(it)}>
                                                            {
                                                                isMultiselect && 
                                                                <div>
                                                                    <div className="checkbox" >
                                                                       { isSelected(it.id) && <i className="ico-Check check"></i>}
                                                                    </div>
                                                                </div>
                                                            }
                                                                <OptionName>{checkNameText(it)}</OptionName>
                                                        </SubOption>
                                                })}
                                            </SubOptions>
                                        }
                                    </div>
                            })
                        }
                    </OptionsList> 
                    {hasClearSelectionButton && 
                    <OptionsFooter className="footer"  onClick={() => clearSelectionHandler()}>
                        Clear Selected
                    </OptionsFooter>
                    }
                </DropdownMultiOptionsWrapper>
    )
}

export default DropdownMultiOptions;