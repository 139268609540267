import styled from 'styled-components'
import {InputSingle} from "../../../../../../../../../common/components/input-single-line/InputSingleLine.style";

export const Wrapper = styled.div`
    .fee-date--label{
      font-size: 12px;
      display: inline-block;
      color: ${({theme}) => theme.colors.deepBlue[5]};
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      margin-bottom: 8px;
    }
  
  .optional{
    font-family: ${({theme}) => theme.fonts.AvenirItalic};
  }

  .fee-date-wrapper {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
`

export const InputSingleStyled = styled(InputSingle)`
  width: 100%;
  height: 40px;
  margin-bottom: 30px;
`