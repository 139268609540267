import styled from "styled-components";

interface IInputStyledContainerProps {
    $width?: string
}

interface IInputStyledLabelProps {
    $size?: 'small'|'large',
    // $marginBottom?: string
}

export const InputStyled = styled.input`
    width: 100%;
    padding: 8px 16px;
    height: 40px;
    outline: none;
    font-size: 12px;
    color: ${({theme})=>theme.colors.deepBlue[4]};
    border: 1px solid ${({theme})=>theme.colors.coolGray[3]};
    border-radius: 8px;
    transition: .2s ease;
    font-family: ${({theme})=>theme.fonts.AvenirLight};
    &:hover {
        border: 1px solid ${({theme})=>theme.colors.coolGray[4]};
        color: ${({theme})=>theme.colors.deepBlue[5]};
        &::placeholder {
            color: ${({theme})=>theme.colors.coolGray[5]};
        }
    }

    &:focus {
        border-color: ${({theme})=>theme.colors.performBlue[5]};
        caret-color: ${({theme})=>theme.colors.performBlue[5]};
        color: ${({theme})=>theme.colors.deepBlue[4]};
        &::placeholder {
            color: ${({theme})=>theme.colors.coolGray[5]}50;
        }
    }

    &::placeholder {
        color: ${({theme})=>theme.colors.coolGray[4]};
        transition: .3s ease;
    }

    &:read-only{
        :hover {
            cursor: default;
        }
    }
`;

export const InputStyledLabel = styled.label<IInputStyledLabelProps>`
    display: block;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    font-size:${({$size}) => $size === 'small' ? '12px' : $size === 'large' ? '14px' : '12px'};
    margin-bottom: ${({$size}) => $size === 'small' ? '8px' : $size === 'large' ? '16px' : '8px'};
    .helper {
        display: block;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        padding: 2px 0;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-size: 10px;
    }
`

export const InputStyledContainer = styled.div<IInputStyledContainerProps>`
    width: ${({$width}) => $width};
    padding-bottom: 15px;
    position: relative;
    .help-block {
        font-size: 10px;
        position: absolute;
        right: 0;
        padding: 0 4px;
    }
    &.has-error {
        ${InputStyled} {
            border: 1px solid ${({theme})=>theme.colors.performOrange[4]};   
        }
        .help-block {
            color: ${({theme})=>theme.colors.performOrange[4]};
        }
    }
`;
