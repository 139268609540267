import React, {useEffect, useState} from 'react'

import {Wrapper, WidgetWrapper, FilterWrapper, LoadingOverlay, ToolbarWrapper} from "./AgencyView.style";

import AgencyViewService, {
    IFetchOverviewStatsResponse,
    IFetchAllMarketersResponse,
    IFetchAllPartnersResponse,
    IFetchOverviewStatsParams,
    IFetchAnalyzePerformanceDataParams,
    IFetchAnalyzePerformanceResponse
} from "./AgencyView.service";
import {useAppDispatch, useAppSelector, useTableSort} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import Loading from "../../../../../common/components/loading/Loading";
import Overview from "./Overview/Overview";
import AnalyzePerformance from "./analyze-performace/AnalyzePerformance";
import AgencyViewMarketersTable from './agency-view-tables/AgencyViewMarketerTable';
import AgencyViewDateTable from './agency-view-tables/AgencyViewDateTable';
import AgencyViewPartnersTable from './agency-view-tables/AgencyViewPartnersTable';
import AgencyViewEventTable from './agency-view-tables/AgencyViewEventTable';
import Filters from './filters/Filters'
import {startOfWeek, format} from "date-fns";
import {
    IAgencyViewDateTableData,
    IAgencyViewDateTableSumData,
    IAgencyViewEventTableData,
    IAgencyViewEventTableSumData,
    IAgencyViewMarketersTableData,
    IAgencyViewMarketersTableSumData,
    IAgencyViewPartnersTableData,
    IAgencyViewPartnersTableSumData,
} from './agency-view-tables/props/agenciViewTablesProps';
import SwitchViewDropdown from "../switch-view-dropdown/SwitchViewDropdown";
import {
    setDateReportPerPage,
    setDateReportCurrentPage,
    setDateReportSortDirection,
    setDateReportSortBy,
    setMarketerReportPerPage,
    setMarketerReportCurrentPage,
    setMarketerReportSortDirection,
    setMarketerReportSortBy,
    setPartnerReportPerPage,
    setPartnerReportCurrentPage,
    setPartnerReportSortDirection,
    setPartnerReportSortBy,
    setEventReportPerPage,
    setEventReportCurrentPage,
    setEventReportSortDirection,
    setEventReportSortBy
} from "./AgencyView.slice";

const AgencyView: React.FC = () => {
    const token = useAppSelector(tokenStore)
    const dispatch = useAppDispatch();

    const {
        analyze_performance_analyze_by_vs,
        analyze_performance_analyze_by,
        analyze_performance_date_selector,
        date_from,
        date_to,
        date_range_preset,
        selectedPartner,
        selectedMarketer,
        dateReportPerPage,
        dateReportCurrentPage,
        dateReportSortDirectionLs,
        dateReportSortByLs,
        marketerReportPerPage,
        marketerReportCurrentPage,
        marketerReportSortDirectionLs,
        marketerReportSortByLs,
        partnerReportPerPage,
        partnerReportCurrentPage,
        partnerReportSortDirectionLs,
        partnerReportSortByLs,
        eventReportPerPage,
        eventReportCurrentPage,
        eventReportSortDirectionLs,
        eventReportSortByLs
    } = useAppSelector(state => state.agencyView)

    //Filter state
    const [allMarketers, setAllMarketers] = useState<IFetchAllMarketersResponse[]>([])
    const [allPartners, setAllPartners] = useState<IFetchAllPartnersResponse[]>([])

    const [localSelectedMarketer, setLocalSelectedMarketer] = useState<IFetchAllMarketersResponse | null>(selectedMarketer);
    const [localSelectedPartner, setLocalSelectedPartner] = useState<IFetchAllPartnersResponse | null>(selectedPartner);

    const [filterCache, setFilterCache] = useState<any>(null);
    //Date table variables
    const [dateReportSortBy,
        dateReportSortDirection,
        dateReportToggleSortDirection] = useTableSort(dateReportSortByLs, dateReportSortDirectionLs);
    const [dateReportTableData, setDateReportTableData] = useState<IAgencyViewDateTableData[]>([]);
    const [dateReportTableSum, setDateReportTableSum] = useState<IAgencyViewDateTableSumData[]>([]);
    const [dateReportTotal, setDateReportTotal] = useState(0);
    const [dateReportFrom, setDateReportFrom] = useState(0);
    const [dateReportTo, setDateReportTo] = useState(0);
    const [dateReportLastPage, setDateReportLastPage] = useState(0);
    const [dateReportLoading, setDateReportLoading] = useState(false);

    //Marketer table variables
    const [marketerReportSortBy,
        marketerReportSortDirection,
        marketerReportToggleSortDirection] = useTableSort(marketerReportSortByLs, marketerReportSortDirectionLs);
    const [marketerReportTableData, setMarketerReportTableData] = useState<IAgencyViewMarketersTableData[]>([]);
    const [marketerReportTableSum, setMarketerReportTableSum] = useState<IAgencyViewMarketersTableSumData[]>([]);
    const [marketerReportTotal, setMarketerReportTotal] = useState(0);
    const [marketerReportFrom, setMarketerReportFrom] = useState(0);
    const [marketerReportTo, setMarketerReportTo] = useState(0);
    const [marketerReportLastPage, setMarketerReportLastPage] = useState(0);
    const [marketerReportLoading, setMarketerReportLoading] = useState(false);

    //Partner table variables
    const [partnerReportSortBy,
        partnerReportSortDirection,
        partnerReportToggleSortDirection] = useTableSort(partnerReportSortByLs, partnerReportSortDirectionLs);
    const [partnerReportTableData, setPartnerReportTableData] = useState<IAgencyViewPartnersTableData[]>([]);
    const [partnerReportTableSum, setPartnerReportTableSum] = useState<IAgencyViewPartnersTableSumData[]>([]);
    const [partnerReportTotal, setPartnerReportTotal] = useState(0);
    const [partnerReportFrom, setPartnerReportFrom] = useState(0);
    const [partnerReportTo, setPartnerReportTo] = useState(0);
    const [partnerReportLastPage, setPartnerReportLastPage] = useState(0);
    const [partnerReportLoading, setPartnerReportLoading] = useState(false);

    //Event table variables
    const [eventReportSortBy,
        eventReportSortDirection,
        eventReportToggleSortDirection] = useTableSort(eventReportSortByLs, eventReportSortDirectionLs);
    const [eventReportTableData, setEventReportTableData] = useState<IAgencyViewEventTableData[]>([]);
    const [eventReportTableSum, setEventReportTableSum] = useState<IAgencyViewEventTableSumData[]>([]);
    const [eventReportTotal, setEventReportTotal] = useState(0);
    const [eventReportFrom, setEventReportFrom] = useState(0);
    const [eventReportTo, setEventReportTo] = useState(0);
    const [eventReportLastPage, setEventReportLastPage] = useState(0);
    const [eventReportLoading, setEventReportLoading] = useState(false);

    const setPerPageDate = (pg: number) => {
        dispatch(setDateReportPerPage(pg));
    }

    const setCurrentPageDate = (pg: number) => {
        dispatch(setDateReportCurrentPage(pg));
    }

    const setPerPageMarketer = (pg: number) => {
        dispatch(setMarketerReportPerPage(pg));
    }

    const setCurrentPageMarketer = (pg: number) => {
        dispatch(setMarketerReportCurrentPage(pg));
    }

    const setPerPagePartner = (pg: number) => {
        dispatch(setPartnerReportPerPage(pg));
    }

    const setCurrentPagePartner = (pg: number) => {
        dispatch(setPartnerReportCurrentPage(pg));
    }

    const setPerPageEvent = (pg: number) => {
        dispatch(setEventReportPerPage(pg));
    }

    const setCurrentPageEvent = (pg: number) => {
        dispatch(setEventReportCurrentPage(pg));
    }

    const fetchDateReport = async () => {
        setDateReportLoading(true);
        const dateReportParams = {
            paginate_by: dateReportPerPage,
            fromDate: format(date_from ? typeof date_from === 'string' ?
                new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
            untilDate: format(date_to ? typeof date_to === 'string' ?
                new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
            page: dateReportCurrentPage,
            sort_by: dateReportSortByLs,
            order: dateReportSortDirectionLs,
            platform_id: selectedPartner ? selectedPartner.platform_id: null,
            platform_partner_id: selectedPartner ? selectedPartner.platform_partner_id : null,
            merged_partner_id: selectedPartner ? selectedPartner.merged_partner_id : null,
            marketer_id: selectedMarketer? selectedMarketer.leap_marketer_id : null,
        }
       try {
        const [dateReportResponse, dateReportResponseSum] = await Promise.all([
            AgencyViewService.fetchReports(token, "date", dateReportParams, false),
            AgencyViewService.fetchReports(token, "date", dateReportParams, true)]);
        if(dateReportResponse.data && dateReportResponseSum){
            let sum: any = {
                aov: dateReportResponseSum.data.aov,
                cac: dateReportResponseSum.data.cac,
                clicks: dateReportResponseSum.data.clicks,
                approved_conversions: dateReportResponseSum.data.approved_conversions,
                conversions: dateReportResponseSum.data.conversions,
                cost: dateReportResponseSum.data.cost,
                cvr: dateReportResponseSum.data.cvr,
                ecpa: dateReportResponseSum.data.ecpa,
                epc: dateReportResponseSum.data.epc,
                new_customers: dateReportResponseSum.data.new_customers,
                roas: dateReportResponseSum.data.roas,
                sale_amount: dateReportResponseSum.data.sale_amount
            }
            setDateReportTableData(dateReportResponse.data.data);
            setDateReportTableSum(sum);
            setDateReportTotal(Number(dateReportResponse.data.total));
            setDateReportCurrentPage(dateReportResponse.data.current_page);
            setDateReportFrom(dateReportResponse.data.from);
            setDateReportTo(dateReportResponse.data.to);
            setDateReportPerPage(Number(dateReportResponse.data.per_page));
            setDateReportLastPage(dateReportResponse.data.last_page);
        }
        setDateReportLoading(false);
       } catch (error) {
        setDateReportLoading(false);
       }
    }

    const fetchMarketerReport = async () => {
        setMarketerReportLoading(true);
        const marketerReportParams = {
            paginate_by: marketerReportPerPage,
            fromDate: format(date_from ? typeof date_from === 'string' ?
                new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
            untilDate: format(date_to ? typeof date_to === 'string' ?
                new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
            page: marketerReportCurrentPage,
            sort_by: marketerReportSortByLs,
            order: marketerReportSortDirectionLs,
            platform_id: selectedPartner? selectedPartner.platform_id: null,
            platform_partner_id: selectedPartner? selectedPartner.platform_partner_id : null,
            merged_partner_id: selectedPartner ? selectedPartner.merged_partner_id : null,
        }
        try {
            const [marketerReportResponse, marketerReportResponseSum] = await Promise.all([
                AgencyViewService.fetchReports(token, "advertiser", marketerReportParams, false),
                AgencyViewService.fetchReports(token, "advertiser", marketerReportParams, true)]);
            if(marketerReportResponse.data && marketerReportResponseSum){
                let sum: any = {
                    aov: marketerReportResponseSum.data.aov,
                    cac: marketerReportResponseSum.data.cac,
                    clicks: marketerReportResponseSum.data.clicks,
                    approved_conversions: marketerReportResponseSum.data.approved_conversions,
                    conversions: marketerReportResponseSum.data.conversions,
                    cost: marketerReportResponseSum.data.cost,
                    cvr: marketerReportResponseSum.data.cvr,
                    ecpa: marketerReportResponseSum.data.ecpa,
                    epc: marketerReportResponseSum.data.epc,
                    new_customers: marketerReportResponseSum.data.new_customers,
                    roas: marketerReportResponseSum.data.roas,
                    sale_amount: marketerReportResponseSum.data.sale_amount
                }
                setMarketerReportTableData(marketerReportResponse.data.data);
                setMarketerReportTableSum(sum);
                setMarketerReportTotal(Number(marketerReportResponse.data.total));
                setMarketerReportCurrentPage(marketerReportResponse.data.current_page);
                setMarketerReportFrom(marketerReportResponse.data.from);
                setMarketerReportTo(marketerReportResponse.data.to);
                setMarketerReportPerPage(Number(marketerReportResponse.data.per_page));
                setMarketerReportLastPage(marketerReportResponse.data.last_page);
            }
            setMarketerReportLoading(false);  
        } catch (error) {
            setMarketerReportLoading(false);
        }
    }

    const fetchPartnerReport = async () => {
        setPartnerReportLoading(true);
        const partnerReportParams = {
            paginate_by: partnerReportPerPage,
            fromDate: format(date_from ? typeof date_from === 'string' ?
                new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
            untilDate: format(date_to ? typeof date_to === 'string' ?
                new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
            page: partnerReportCurrentPage,
            sort_by: partnerReportSortByLs,
            order: partnerReportSortDirectionLs,
            marketer_id: selectedMarketer? selectedMarketer.leap_marketer_id : null,
        }
        try {
            const [partnerReportResponse, partnerReportResponseSum] = await Promise.all([
                AgencyViewService.fetchReports(token, "affiliate", partnerReportParams, false),
                AgencyViewService.fetchReports(token, "affiliate", partnerReportParams, true)]);
            if(partnerReportResponse.data && partnerReportResponseSum){
                let sum: any = {
                    aov: partnerReportResponseSum.data.aov,
                    cac: partnerReportResponseSum.data.cac,
                    clicks: partnerReportResponseSum.data.clicks,
                    approved_conversions: partnerReportResponseSum.data.approved_conversions,
                    conversions: partnerReportResponseSum.data.conversions,
                    cost: partnerReportResponseSum.data.cost,
                    cvr: partnerReportResponseSum.data.cvr,
                    ecpa: partnerReportResponseSum.data.ecpa,
                    epc: partnerReportResponseSum.data.epc,
                    new_customers: partnerReportResponseSum.data.new_customers,
                    roas: partnerReportResponseSum.data.roas,
                    sale_amount: partnerReportResponseSum.data.sale_amount
                }
                setPartnerReportTableData(partnerReportResponse.data.data);
                setPartnerReportTableSum(sum);
                setPartnerReportTotal(Number(partnerReportResponse.data.total));
                setPartnerReportCurrentPage(partnerReportResponse.data.current_page);
                setPartnerReportFrom(partnerReportResponse.data.from);
                setPartnerReportTo(partnerReportResponse.data.to);
                setPartnerReportPerPage(Number(partnerReportResponse.data.per_page));
                setPartnerReportLastPage(partnerReportResponse.data.last_page);
            }
            setPartnerReportLoading(false);
        } catch (error) {
            setPartnerReportLoading(false);
        }
    }

    const fetchEventReport = async () => {
        setEventReportLoading(true);
        const eventReportParams = {
            paginate_by: eventReportPerPage,
            fromDate: format(date_from ? typeof date_from === 'string' ?
                new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
            untilDate: format(date_to ? typeof date_to === 'string' ?
                new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
            page: eventReportCurrentPage,
            sort_by: eventReportSortByLs,
            order: eventReportSortDirectionLs,
            platform_id: selectedPartner? selectedPartner.platform_id: null,
            platform_partner_id: selectedPartner? selectedPartner.platform_partner_id : null,
            merged_partner_id: selectedPartner ? selectedPartner.merged_partner_id : null,
            marketer_id: selectedMarketer? selectedMarketer.leap_marketer_id : null
        }
        try {
            const [eventReportResponse, eventReportResponseSum] = await Promise.all([
                AgencyViewService.fetchReports(token, "upsell", eventReportParams, false),
                AgencyViewService.fetchReports(token, "upsell", eventReportParams, true)]);
            if(eventReportResponse.data && eventReportResponseSum){
                let sum: any = {
                    aov: eventReportResponseSum.data.aov,
                    cac: eventReportResponseSum.data.cac,
                    clicks: eventReportResponseSum.data.clicks,
                    approved_conversions: eventReportResponseSum.data.approved_conversions,
                    conversions: eventReportResponseSum.data.conversions,
                    cost: eventReportResponseSum.data.cost,
                    cvr: eventReportResponseSum.data.cvr,
                    ecpa: eventReportResponseSum.data.ecpa,
                    epc: eventReportResponseSum.data.epc,
                    new_customers: eventReportResponseSum.data.new_customers,
                    roas: eventReportResponseSum.data.roas,
                    sale_amount: eventReportResponseSum.data.sale_amount
                }
                setEventReportTableData(eventReportResponse.data.data);
                setEventReportTableSum(sum);
                setEventReportTotal(Number(eventReportResponse.data.total));
                setEventReportCurrentPage(eventReportResponse.data.current_page);
                setEventReportFrom(eventReportResponse.data.from);
                setEventReportTo(eventReportResponse.data.to);
                setEventReportPerPage(Number(eventReportResponse.data.per_page));
                setEventReportLastPage(eventReportResponse.data.last_page);
            }
            setEventReportLoading(false);
        } catch (error) {
            setEventReportLoading(false);
        }
        
    }
    
    useEffect(() => {
            if(selectedMarketer) {
                setFilterCache({
                    ...selectedMarketer,
                    dateRange: date_range_preset,
                    fromDate: format(date_from ? typeof date_from === 'string' ?
                        new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
                    untilDate: format(date_to ? typeof date_to === 'string' ?
                        new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),

                })
            } else if(selectedPartner) {
                setFilterCache({
                    ...selectedPartner,
                    dateRange: date_range_preset,
                    fromDate: format(date_from ? typeof date_from === 'string' ?
                        new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
                    untilDate: format(date_to ? typeof date_to === 'string' ?
                        new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),

                })
            } else {
                setFilterCache({
                    dateRange: date_range_preset,
                    fromDate: format(date_from ? typeof date_from === 'string' ?
                        new Date(date_from) : date_from : new Date(), "yyyy-MM-dd"),
                    untilDate: format(date_to ? typeof date_to === 'string' ?
                        new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),

                })
            }
    }, [selectedPartner, selectedMarketer, date_from, date_to])

    //Overview state
    const [loadingOverview, setLoadingOverview] = useState(false)

    const [analyzePerformanceData, setAnalyzePerformanceData] = useState<IFetchAnalyzePerformanceResponse[]>([])
    const [loadingAnalyzePerformance, setLoadingAnalyzePerformance] = useState(false)

    const [overviewData, setOverviewData] = useState<IFetchOverviewStatsResponse>(
        {
            marketers: {
                active: '0',
                productive: '0',
            },
            partners: {
                active: '0',
                productive: '0',
            },
            sale: 0,
            cost: 0,
            fees: 0,
            clicks: '0',
            conversions: '0',
            ecpa: 0
        }
    )


    const getOverviewStats = async () => {
            const params: IFetchOverviewStatsParams = {
                from: format(date_from ? typeof date_from === 'string' ?
                    new Date(date_from) : date_from : startOfWeek(new Date(), {weekStartsOn: 1}), "yyyy-MM-dd"),
                to: format(date_to ? typeof date_to === 'string' ?
                    new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
                platform_id: selectedPartner?.platform_id,
                marketer_id: selectedMarketer?.leap_marketer_id,
                partner_id: selectedPartner?.platform_partner_id,
                merged: selectedPartner ? selectedPartner.merged_partner_id !== null : null
            }
            try {
                setLoadingOverview(true)

                const {data} = await AgencyViewService.fetchOverviewStats(token, params)
                setOverviewData(data)

                setLoadingOverview(false)
            } catch(err) {
                setLoadingOverview(false)
            }
    }

    const getAnalyzePerformanceData = async () => {
        const params: IFetchAnalyzePerformanceDataParams = {
            analyze_by: analyze_performance_analyze_by.value,
            analyze_by_vs: analyze_performance_analyze_by_vs.value,
            from: format(date_from ? typeof date_from === 'string' ?
                new Date(date_from) : date_from : startOfWeek(new Date(), {weekStartsOn: 1}), "yyyy-MM-dd"),
            to: format(date_to ? typeof date_to === 'string' ?
                new Date(date_to) : date_to : new Date(), "yyyy-MM-dd"),
            date_range: analyze_performance_date_selector,
            marketer_id: selectedMarketer?.leap_marketer_id,
            affiliate_id: selectedPartner?.platform_partner_id,
            platform_id: selectedPartner?.platform_id,
            merged_partner_id: selectedPartner?.merged_partner_id

        }
        try {
            setLoadingAnalyzePerformance(true)
            const {data} = await AgencyViewService.fetchAnalyzePerformanceData(token, params)
            setLoadingAnalyzePerformance(false)
            setAnalyzePerformanceData(data)
        } catch (e) {
            setLoadingAnalyzePerformance(false)
        }
    }



    const getAllMarketers = async () => {
        try{
            const {data} = await AgencyViewService.fetchAllMarketers(token)
            setAllMarketers(data)
        } catch (e) {
        }
    }

    const getAllPartners = async () => {
        try{
            const {data} = await AgencyViewService.fetchAllPartners(token)
            setAllPartners(data)
        } catch (e) {
        }
    }

    useEffect(() => {
        getAnalyzePerformanceData()
    }, [analyze_performance_date_selector, analyze_performance_analyze_by_vs, analyze_performance_analyze_by])

    const fetchData = () => {
        getOverviewStats();
        getAnalyzePerformanceData()
        fetchDateReport();
        fetchMarketerReport();
        fetchPartnerReport();
        fetchEventReport();
    }

    useEffect(()=>{
        if (dateReportSortBy !== dateReportSortByLs){
            dispatch(setDateReportSortBy(dateReportSortBy))
            dispatch(setDateReportSortDirection("desc"))
        }
        else {
            dispatch(setDateReportSortDirection(dateReportSortDirection));
        }
    }, [dateReportSortDirection, dateReportSortBy]);

    useEffect(()=>{
            fetchDateReport();
    }, [dateReportCurrentPage, dateReportPerPage, dateReportSortDirectionLs, dateReportSortByLs]);

    useEffect(()=>{
        if(marketerReportSortBy !== marketerReportSortByLs){
            dispatch(setMarketerReportSortBy(marketerReportSortBy))
            dispatch(setMarketerReportSortDirection('desc'))
        }
        else {
            dispatch(setMarketerReportSortDirection(marketerReportSortDirection));
        }
    }, [marketerReportSortDirection, marketerReportSortBy])

    useEffect(()=>{
        fetchMarketerReport();
    },[marketerReportCurrentPage, marketerReportPerPage, marketerReportSortByLs, marketerReportSortDirectionLs])

    useEffect(()=>{
        if (partnerReportSortBy !== partnerReportSortByLs){
            dispatch(setPartnerReportSortBy(partnerReportSortBy))
            dispatch(setPartnerReportSortDirection('desc'));
        }
        else {
            dispatch(setPartnerReportSortDirection(partnerReportSortDirection));
        }

    }, [ partnerReportSortDirection, partnerReportSortBy])

    useEffect(()=>{
        fetchPartnerReport();
    },[partnerReportCurrentPage, partnerReportPerPage, partnerReportSortByLs, partnerReportSortDirectionLs])

    useEffect(()=>{
        if (eventReportSortBy !== eventReportSortByLs){
            dispatch(setEventReportSortBy(eventReportSortBy))
            dispatch(setEventReportSortDirection('desc'));
        }
        else{
            dispatch(setEventReportSortDirection(eventReportSortDirection));
        }

    }, [eventReportSortDirection, eventReportSortBy])

    useEffect(()=>{
        fetchEventReport();
    },[eventReportCurrentPage, eventReportPerPage, eventReportSortByLs, eventReportSortDirectionLs])

    useEffect(() => {
        getOverviewStats();
        getAnalyzePerformanceData()
        getAllMarketers()
        getAllPartners()
    }, [])

    return <Wrapper>
        <ToolbarWrapper>
            <Filters
                marketers={allMarketers}
                partners={allPartners}
                fetchData={fetchData}
                setLocalSelectedMarketer={setLocalSelectedMarketer}
                setLocalSelectedPartner={setLocalSelectedPartner}
            />
            <SwitchViewDropdown />
        </ToolbarWrapper>
        <WidgetWrapper>
            {loadingOverview && <LoadingOverlay ><Loading/></LoadingOverlay>}
            <h3 className="title">Overview</h3>
            <Overview overviewData={overviewData} selectedMarketer={localSelectedMarketer} selectedPartner={localSelectedPartner} />
        </WidgetWrapper>
        <WidgetWrapper>
            {loadingAnalyzePerformance && <LoadingOverlay ><Loading/></LoadingOverlay>}
            <h3 className="title">Analyze Performance</h3>
            <AnalyzePerformance data={analyzePerformanceData}/>
        </WidgetWrapper>
        <WidgetWrapper>
            <AgencyViewDateTable
                filter_cache={filterCache}
                sortBy={dateReportSortBy}
                sortDirection={dateReportSortDirection}
                toggleSortDirection={dateReportToggleSortDirection}
                tableData={dateReportTableData}
                setTableData={setDateReportTableData}
                tableSum={dateReportTableSum}
                setTableSum={setDateReportTableSum}
                total={dateReportTotal}
                setTotal={setDateReportTotal}
                currentPage={dateReportCurrentPage}
                setCurrentPage={setCurrentPageDate}
                from={dateReportFrom}
                setFrom={setDateReportFrom}
                to={dateReportTo}
                setTo={setDateReportTo}
                perPage={dateReportPerPage}
                setPerPage={setPerPageDate}
                lastPage={dateReportLastPage}
                setLastPage={setDateReportLastPage}
                loading={dateReportLoading}
                fetchReport={fetchDateReport}
            />
        </WidgetWrapper>
        {localSelectedMarketer === null && 
        <WidgetWrapper>
            <AgencyViewMarketersTable
                filter_cache={filterCache}
                sortBy={marketerReportSortBy}
                sortDirection={marketerReportSortDirection}
                toggleSortDirection={marketerReportToggleSortDirection}
                tableData={marketerReportTableData}
                setTableData={setMarketerReportTableData}
                tableSum={marketerReportTableSum}
                setTableSum={setMarketerReportTableSum}
                total={marketerReportTotal}
                setTotal={setMarketerReportTotal}
                currentPage={marketerReportCurrentPage}
                setCurrentPage={setCurrentPageMarketer}
                from={marketerReportFrom}
                setFrom={setMarketerReportFrom}
                to={marketerReportTo}
                setTo={setMarketerReportTo}
                perPage={marketerReportPerPage}
                setPerPage={setPerPageMarketer}
                lastPage={marketerReportLastPage}
                setLastPage={setMarketerReportLastPage}
                loading={marketerReportLoading}
                fetchReport={fetchMarketerReport}
            />
        </WidgetWrapper>
       }
       {localSelectedPartner === null && 
       <WidgetWrapper>
            <AgencyViewPartnersTable
                filter_cache={filterCache}
                sortBy={partnerReportSortBy}
                sortDirection={partnerReportSortDirection}
                toggleSortDirection={partnerReportToggleSortDirection}
                tableData={partnerReportTableData}
                setTableData={setPartnerReportTableData}
                tableSum={partnerReportTableSum}
                setTableSum={setPartnerReportTableSum}
                total={partnerReportTotal}
                setTotal={setPartnerReportTotal}
                currentPage={partnerReportCurrentPage}
                setCurrentPage={setCurrentPagePartner}
                from={partnerReportFrom}
                setFrom={setPartnerReportFrom}
                to={partnerReportTo}
                setTo={setPartnerReportTo}
                perPage={partnerReportPerPage}
                setPerPage={setPerPagePartner}
                lastPage={partnerReportLastPage}
                setLastPage={setPartnerReportLastPage}
                loading={partnerReportLoading}
                fetchReport={fetchPartnerReport}
            />
        </WidgetWrapper>}
        <WidgetWrapper>
            <AgencyViewEventTable
                filter_cache={filterCache}
                sortBy={eventReportSortBy}
                sortDirection={eventReportSortDirection}
                toggleSortDirection={eventReportToggleSortDirection}
                tableData={eventReportTableData}
                setTableData={setEventReportTableData}
                tableSum={eventReportTableSum}
                setTableSum={setEventReportTableSum}
                total={eventReportTotal}
                setTotal={setEventReportTotal}
                currentPage={eventReportCurrentPage}
                setCurrentPage={setCurrentPageEvent}
                from={eventReportFrom}
                setFrom={setEventReportFrom}
                to={eventReportTo}
                setTo={setEventReportTo}
                perPage={eventReportPerPage}
                setPerPage={setPerPageEvent}
                lastPage={eventReportLastPage}
                setLastPage={setEventReportLastPage}
                loading={eventReportLoading}
                fetchReport={fetchEventReport}
            />
        </WidgetWrapper>
    </Wrapper>
}

export default AgencyView