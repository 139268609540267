import React, {useRef, useState} from "react";
import ReactDOM from "react-dom";

import {Wrapper, TooltipInfo} from "./Tooltip.style";

import {ITooltipProps} from "./tooltip.props";
import {useOnClickOutside} from "../../hooks";


const Tooltip: React.FC<ITooltipProps> = ({
                                              containerStyle,
                                              position = 'left',
                                              triggerOnClick = false,
                                              width = 300,
                                              children,
                                              icon = false,
                                              className,
                                              delay,
                                              positionTop = 0,
                                              positionLeft = 0}) => {

    const [tooltipDisplayed, setTooltipDisplayed] = useState(false)
    const [infoPosition, setInfoPosition] = useState({
        left: 0,
        top: 0
    })
    const tooltipRef = useRef<HTMLDivElement | null>(null)
    const filterHoverTimerRef = useRef<any>()

    const handleFilterHover = (): void => {
        filterHoverTimerRef.current = setTimeout(() => {
            setTooltipDisplayed(true)
        }, 750)
    }

    const handleMouseLeave = () => {
        filterHoverTimerRef && clearTimeout(filterHoverTimerRef.current)
        setTooltipDisplayed(false)
    }


    useOnClickOutside(tooltipRef, () => triggerOnClick && setTooltipDisplayed(false))

    const handleMouseEnter = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(tooltipRef.current){
            const {top, left} = tooltipRef.current.getBoundingClientRect()
            setInfoPosition({
                top: top + window.scrollY + positionTop,
                left: left + positionLeft
            })
        }
        if(delay){
            handleFilterHover()
        }else {
            !triggerOnClick && setTooltipDisplayed(true)
        }
    }

    return <Wrapper
        ref={tooltipRef}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={() => {
            !delay && !triggerOnClick && setTooltipDisplayed(false)
            delay && handleMouseLeave();
        }}
        onClick={() => triggerOnClick && setTooltipDisplayed(!tooltipDisplayed)}
        className={className}
    >
        {icon ? icon : <i className={'ico-Information-outlined'} />}
        <Info style={containerStyle} width={width} opened={tooltipDisplayed} position={position} left={infoPosition.left} top={infoPosition.top}>
            <div style={{width: width}}>
                {children}
            </div>
        </Info>
    </Wrapper>
}

const Info: React.FC<{opened: boolean, position: string, left: number, top: number, width: number, style: React.CSSProperties | undefined}> = ({opened, position, left, top,width, style, children}) => {
    return ReactDOM.createPortal(
        <TooltipInfo style={style} $width={width} opened={opened} position={position} $left={left} $top={top}>
            {children}
            <div className="carret"></div>
        </TooltipInfo>,
        document.body
    )
}

export default Tooltip
