import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AlertModal from "../../../../../../common/components/alert-modal/AlertModal";
import { DropdownItem } from "../../../../../../common/components/dropdown/Dropdown.style";
import Grid from "../../../../../../common/components/grid/Grid";
import GridRowOptions from "../../../../../../common/components/grid/grid-row-options/GridRowOptions";
import Modal from "../../../../../../common/components/modal/Modal";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "../../../../../../common/hooks";
import { tokenStore } from "../../../../../../store/auth.slice";
import BillingService, {IFetchAffiliatesResponse} from "../Billing.service";
import CreateNewFeeModal from "./create-new-fee/create-new-fee-modal/CreateNewFeeModal";
import CreateNewFeeGroupModal from "./create-new-fee-group/create-new-fee-group-modal/CreateNewFeeGroupModal";
import {IPlatformAffluent} from "../../AdvertisersEdit.service";
import { BilingFeesDeleteModalBody, BillingFeesCreateNewSection, BillingFeesTableSection, BillingFeesWrapper } from "./BillingFees.style";
import {closeAlert, openAlert} from "../../AdvertisersEdit.slice";
import { formatNumberToCurrency } from "../../../../../../common/utils";
import { IAdvertiserEdit } from "../../../../../../common/models/advertiser-edit.type";
import { useColumnsOptionHook } from "../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import { allPaginationColumnsList } from "./allPaginationColumnsList";
import { setBillingFeesPaginationColumnsList } from "./BillingFees.slice";

interface IBillingFeesProps {
    affiliates: IFetchAffiliatesResponse[]
    platforms: IPlatformAffluent[]
    editingUser: IAdvertiserEdit | null
}


interface ISaveFeeAlertModal {
    opened: boolean,
    message: string,
    type: 'success' | 'error'
}

interface IModal {
    opened: boolean,
    data: any
}

const BillingFees: React.FC<IBillingFeesProps> = ({affiliates, platforms, editingUser}) => {
    const {id} = useParams<{ id: string }>();
    const token = useAppSelector(tokenStore);
    const {alertModal} = useAppSelector(state => state.advertisersEdit)
    const [page, setPage] = useState(1);
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [disableDelete, setDisableDelete] = useState(false);

    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [lastPage, setLastPage] = useState(0);

    const {billingFeesPaginationColumnsList} = useAppSelector(state => state.billingFees);

    const dispatch = useAppDispatch();

    const [deleteModal, setDeleteModal] = useState<{ type: string; data: any; opened: boolean;}>({
        type: "",
        data: "dataa",
        opened: false
    });

    const [isCreateNewFeeDropdownOpened, setIsCreateNewFeeDropdownOpened] = useState(false)
    const [isCreateNewFeeModalOpened, setIsCreateNewFeeModalOpened] = useState(false)

    const [editFeeModal, setEditFeeModal] = useState<IModal>({
        opened: false,
        data: null
    })

    const [feeGroupModal, setFeeModalGroup] = useState<IModal>({
        opened: false,
        data: null
    })

    const [saveFeeAlertModal, setSaveFeeAlertModal] = useState<ISaveFeeAlertModal>({
        opened: false,
        message: '',
        type: 'success'
    })

    function formatMoney(n: number) {
        return "$" + (Math.round(n * 100) / 100).toLocaleString();
    }

    const fetchInitialData = async () => {
        setLoading(true);
        const params = {
            id : Number(id), 
            paginate_by: perPage,
            page: page
        };
        const response = await BillingService.fetchBillingFees(token, params);
        const startData = response.data.data.map((fee: any) => {
            if(fee.group !== null) {
                return {
                    ...fee.group, 
                    isGroup: true, 
                    expanded: false,
                    fees: fee.group.fees.map((item: any) => {
                        return {...item, isFee: true, expanded: false,fee_users: item.fee_users,users:item.users};
                    }),
                    tiers: fee.group.tiers.map((item: any, index: number) => {
                        return {...item, isTier: true, expanded: false, name: `Tier ${index+1}`};
                    }),
                };
            } else {
                return {
                    ...fee, 
                    expanded: false, 
                    defaultFee: true, 
                    tiers: fee.tiers.map((tier: any, index: number) => {
                        return {...tier, name: `Tier ${index+1}`, isTier: true}
                    })
                };
            }
        });
        setPage(response.data.current_page);
        setFrom(response.data.from);
        setTo(response.data.to);
        setTotal(response.data.total);
        setLastPage(response.data.last_page);
        setData(startData);
        setLoading(false);
    }

    useEffect(() => {
        if(token && id) {
            fetchInitialData();
        }

    }, [token, id, perPage, page]);

    const handleOpenEditFeeModal = (fee: any) => {
        if(fee.defaultFee || fee.isFee) {
            setEditFeeModal({
                opened: true,
                data: fee
            })
        }
        if(fee.isGroup){
            setFeeModalGroup({
                opened: true,
                data: fee
            })
        }
    }

    const expandGroup = (row: any) => {
        if(!row.expanded) { 
            // (obj.fees || obj.tiers) --> this check is becouse of potential same id's in data list
            const indexOfParent = data.findIndex((obj: any) => obj.id === row.id && row.isGroup && row.name === obj.name && (obj.fees || obj.tiers));
            const copyList = data.map((item: any, index: number) => {
                if(index === indexOfParent) {                    
                    return {...item, expanded : true}
                } else return item;
            });
            const newList = [...copyList.slice(0, indexOfParent+1), ...row.fees, ...row.tiers, ...copyList.slice(indexOfParent+1)];           
            setData(newList);
        } else {
            const indexOfParent = data.findIndex((obj: any) => obj.id === row.id && row.isGroup && row.name === obj.name && (obj.fees || obj.tiers));
            const copyList = data.map((item: any, index: number) => {
                if(index === indexOfParent) {                    
                    return {...item, expanded : false}
                } else return item;
            });
            const newList = [...copyList.slice(0, indexOfParent+1), ...copyList.slice(indexOfParent+row.fees.length + row.tiers.length + 1)];           
            setData(newList);
        }
    };

    const expandTier = (row: any) => { 
        if(!row.expanded) { 
            // (obj.tiers) ---> this check is becouse of potential same id's in data list
            const indexOfParent = data.findIndex((obj: any) => obj.id === row.id && obj.name === row.name && obj.description === row.description);
            const copyList = data.map((item: any, index: number) => {
                if(index === indexOfParent) {                    
                    return {...item, expanded : true}
                } else return item;
            });
            const newList = [...copyList.slice(0, indexOfParent+1), ...row.tiers, ...copyList.slice(indexOfParent+1)];           
            row.defaultFee ? setData(newList): setData(copyList);
        } else {
            const indexOfParent = data.findIndex((obj: any) => obj.id === row.id && obj.name === row.name && obj.description === row.description);
            const copyList = data.map((item: any, index: number) => {
                if(index === indexOfParent) {                    
                    return {...item, expanded : false}
                } else return item;
            });
            const newList = [...copyList.slice(0, indexOfParent+1), ...copyList.slice(indexOfParent + row.tiers.length + 1)];           
            row.defaultFee ? setData(newList): setData(copyList);
        }
    };

    const rowExpandHandler = (data: any) => {
        if(data.isGroup){
            (data.tiers.length > 0 || data.fees.length > 0) && expandGroup(data);
        }else if(data.isFee){
            if(data.expanded) {
                expandTier(data);
            } else if(data.tiers.length > 0) {
                expandTier(data);
            }
            return;
        } else if(data.tiers && data.tiers.length > 0) {
            expandTier(data);
        }
    }

    const allColumnDefs = useMemo(() => [
        {field: '', headerName: "FEE/GROUP NAME & TIER", colId: 1, minWidth: 150, cellRenderer: ({data}:any) => {
            if(data.isGroup){
                return <div className="custom-cell" >
                        { data.expanded && <i className="ico-ChevronUp"></i>}
                        { !data.expanded && (data.tiers.length > 0 || data.fees.length > 0) && <i className="ico-ChevronDown"></i>}
                        <span className="group">Group: </span>
                        {data.name}
                    </div>
            }else if (data.isFee){
                if(data.expanded) {
                    const listOfTiers = data.tiers.map((tier: any, index: number) => {
                        return `Tier ${index+1}`;
                    })
                    return  <div className="custom-cell">
                                
                                <span className="fee">
                                    <i className="ico-ChevronUp"></i>
                                    <span className="fee-text">Fee: </span>
                                </span>{data.name}
                                <div className="tiers-list">
                                    {listOfTiers && listOfTiers.map((tier: string) => {
                                        return <div key={tier+Math.random()}>{tier}</div>
                                    })}
                                </div>
                            </div>;
                }
                return <div className="custom-cell" >
                            <span className="fee">
                                {(data.tiers.length > 0 ) && <i className="ico-ChevronDown"></i>}
                                <span className="fee-text">
                                    Fee: 
                                </span>
                                
                            </span>
                            {data.name}
                        </div>;
            }else if(data.isTier) {
                return <div className="custom-cell tier-in">
                    {data.expanded && (data.tiers && data.tiers.length > 0) && <i className="ico-ChevronUp"></i>}
                    {!data.expanded && (data.tiers && data.tiers.length > 0) && <i className="ico-ChevronDown"></i>}
                    {data.name}
                </div>
            }else {
                return <div className="custom-cell" >
                    {data.expanded && (data.tiers && data.tiers.length > 0) && <i className="ico-ChevronUp"></i>}
                    {!data.expanded && (data.tiers && data.tiers.length > 0) && <i className="ico-ChevronDown"></i>}
                    {data.name}
                </div>
            }
        }},
        {field: 'source', headerName: "SOURCE", colId: 2, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.source) return <div className="custom-cell">{data.source}</div>;
            return <div className="custom-cell"></div>;
        }},
        {field: 'type', headerName: "FEE/GROUP TYPE", colId: 3, minWidth: 150, cellRenderer: ({data}: any) => {
            const feeGroupType = (data: any) => {
                if(data.isGroup && data.type === 1){
                    return <div className="custom-cell">
                        Standard {data.pass_through ?
                        <Tooltip position="top" width={100} icon={<span className="p-icon">P</span>}>
                        Pass-through fee.</Tooltip> : null}
                    </div>;
                }else if(data.isGroup && data.type === 2) {
                    return <div className="custom-cell">
                        Tiered {data.pass_through ?
                        <Tooltip position="top" width={100} icon={<span className="p-icon">P</span>}>
                        Pass-through fee.</Tooltip> : null}
                    </div>
                    
                } else if(data.type === 1){
                    return <div className="custom-cell">
                        Flat {data.pass_through ?
                        <Tooltip position="top" width={100} icon={<span className="p-icon">P</span>}>
                        Pass-through fee.</Tooltip> : null}
                    </div>;
                }else if(data.type === 2) {
                    return <div className="custom-cell">
                        Traffic based {data.pass_through ?
                        <Tooltip position="top" width={100} icon={<span className="p-icon">P</span>}>
                        Pass-through fee.</Tooltip> : null}
                    </div>
                    
                } else if(data.type === 3) {
                    return <div className="custom-cell">
                        Paid media {data.pass_through ? <span>
                            <Tooltip position="top" width={100} icon={<span className="p-icon">P</span>}>
                            Pass-through fee.</Tooltip>
                        </span>  : null}
                    </div>
                } else return <div className="custom-cell"></div>
                
            }
            return data.type ? feeGroupType(data) : <div className="custom-cell"></div> ;
        }},
        {field: 'amount', headerName: "AMOUNT", colId:4, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.isFee && data.expanded) {
                const amounts = data.tiers.map((item: any) => {
                    return <div>
                        {`${formatNumberToCurrency(item.from)} to ${item.to > 0 ? formatNumberToCurrency(item.to) : "Unlimited"} = ${item.percentage}%`}
                    </div> 
                })
                return <div className="custom-cell">
                    <div className="amounts amounts-list">{amounts.map((amount: string) => {
                    return <div key={amount+Math.random()}>{amount}</div>;
                })}</div>
                </div>
            }else if(data.isTier){
                return <div className="custom-cell">
                    <div className="amounts">
                    {`${formatNumberToCurrency(data.from)} to ${data.to > 0 ? formatNumberToCurrency(data.to) : "Unlimited"} = ${data.percentage}%`}
                    </div>
                </div>
            }else {
                return <div className="custom-cell">
                    <div className="custom-cell">{data.amount && !data.isFee ? `${formatNumberToCurrency(data.amount)}` : ""}</div>
                </div>
            }
        }},
        {field: 'start_date', headerName: "START DATE", colId: 5, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.start_date){
                return <div className="custom-cell">
                    {format(new Date(data.start_date), "MMMM do, yyyy ")}
                    </div>;
            }else {
                return <div className="custom-cell"></div>;
            }
        }},
        {field: 'end_date', headerName: "END DATE", colId: 6, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.end_date){
                return <div className="custom-cell">
                {format(new Date(data.end_date), "MMMM do, yyyy ")}
                </div> ;
            }else {
                return <div className="custom-cell"></div>;
            }
        }},
        {field: 'recurring', headerName: "RECURRING", colId: 7, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.recurring) return <div className="custom-cell">Monthly</div>;
            return <div className="custom-cell"></div>;
        }},
        {field: 'monthly_minimum_charge', headerName: "MONTHLY MIN. CHARGES", colId: 8, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.monthly_minimum_charge) {
                return <div className="custom-cell">
                    {formatNumberToCurrency(data.monthly_minimum_charge)}
                </div>;
            } else return <div className="custom-cell"></div>;
        }},
        {field: 'calculate_by', headerName: "CALCULATED BY", colId: 9, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.calculate_by) {
                return <div className="custom-cell">
                    {`Total ${data.calculate_by} for marketer`}
                </div>;
            }else return <div className="custom-cell"></div>;
        }},
        {field: 'partners', headerName: "PARTNERS INCL./EXCL.", colId: 10, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.fee_users && data.fee_users.length > 0) {
                return <div className="custom-cell">
                    <Tooltip position="top" width={150} icon={<span>{data.fee_users.length} partners excluded</span>}>
                        {data.users.map((user: any) => {
                            return <div key={user.email}>
                               {user.id} - {user.name} {user.lastname}
                            </div>
                        })}
                    </Tooltip>
                </div>
            };
            return <div className="custom-cell"></div>;
        }},
        {field: 'visibility', headerName: "IND. FEE VISIBILITY", colId: 11, minWidth: 150, cellRenderer: ({data}: any) => {
            if(data.visibility || data.visibility === 0){
                if(data.visibility === 0) return <div className="custom-cell">Admin only</div>;
                return <div className="custom-cell">Admin & Marketer</div>;
            } else {
                return <div className="custom-cell"></div>;
            }
        }},
        { pinned: 'right', width: 50, resizable: false, cellRenderer: ({data}: any) => {
            if(data.defaultFee || data.isGroup || data.isFee){
                return <GridRowOptions disabled={editingUser?.parent_or_child} >
                        <DropdownItem onClick={() => handleOpenEditFeeModal(data)}>
                            Edit Fee {data.isGroup && "Group"}
                        </DropdownItem>
                        <div className="options-line" />
                        <DropdownItem isDelete onClick={() => setDeleteModal({
                                    type: data.defaultFee || data.isFee ? "fee" : "group",
                                    data: data,
                                    opened: true
                        })}>
                            Delete Fee {data.isGroup && "Group"}
                        </DropdownItem>
                       </GridRowOptions>
            }
            return <span></span>
        }}
    ], [data]);

    const [columnDefs, setColumnDefs] = useState<any[]>([...allColumnDefs]);
    const [colWidths, setColWidths] = useState(allColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId}
    }));

    const setPaginationColumnsListHandler = (list:{
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setBillingFeesPaginationColumnsList(list))
    }

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]: any = useColumnsOptionHook(
        allColumnDefs,
        columnDefs,
        setColumnDefs,
        allPaginationColumnsList,
        billingFeesPaginationColumnsList,
        setPaginationColumnsListHandler
    );

    useEffect(()=> {
        const checkedColumns = billingFeesPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned");
            if(billingFeesPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                })
                setColumnDefs([...newDefs, ...allColumnDefs.filter((item: any) => item.pinned === "left" || item.pinned === "right")]);
            } else {
                setColumnDefs([]);
            }
    }, [billingFeesPaginationColumnsList]);

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allPaginationColumnsList.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });

        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })

        setColWidths(widths);
        setColumnDefs(widthToCol);

        const rest = billingFeesPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        setPaginationColumnsListHandler([...newPaginCol, ...rest]);
    }

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 30,
        autoHeight: true
    }), [data]);

    const gridOptions = useMemo(() => ({
        // columnDefs: columnDefs,
        defaultColumnDefs: defaultColumnDefs,
        getRowHeight: ({data}: any) => {
            if(data.isFee && data.expanded){
                return 50 + data.tiers.length * 15;
            } else return 40;
        },
    }), [data, colWidths, defaultColumnDefs]);

    const deleteFee = async (data: any, type: string) => {
        setDisableDelete(true);
        type === "fee" && await BillingService.deleteFee(token, data.id);
        type === "group" && await BillingService.deleteGroup(token, data.id);
        setDeleteModal({...deleteModal, opened: false});
        dispatch(openAlert({
            opened: true,
            type: 'success',
            message: ''
        }));
        setDisableDelete(false);
        fetchInitialData();
    }

    return(
        <BillingFeesWrapper>
            <BillingFeesCreateNewSection>
                <div className="create-new">
                    <Dropdown text={'Create New'} disabled={editingUser?.parent_or_child} isOpened={isCreateNewFeeDropdownOpened} setOpened={setIsCreateNewFeeDropdownOpened} >
                        <DropdownItem onClick={() => setEditFeeModal({opened: true, data: null})}>Fee</DropdownItem>
                        <DropdownItem disabled={data.length < 2} onClick={() => setFeeModalGroup({opened: true, data: null})}>Fee Group</DropdownItem>
                    </Dropdown>
                </div>
                <Pagination
                    current_page={currentPage}
                    from={from}
                    to={to}
                    total={total}
                    setCurrentPage={setCurrentPage}
                    setPerPage={setPerPage}
                    last_page={lastPage}
                    perPage={perPage}
                    loading={loading}
                    onRefresh={() => fetchInitialData()} 
                    columnsOptionProps={
                        {
                            allPaginationColumns: allPaginationColumnsList,
                            enableDisableColumnHandler: (column: any) => enableDisableColumnHandler(column),
                            enableAllHandler: () => enableAllHandler(),
                            paginationColumnsList: billingFeesPaginationColumnsList,
                            setPaginationColumnsList: (paginationColumnsList: any) => setBillingFeesPaginationColumnsList(paginationColumnsList),
                            disableAllHandler: () => disableAllHandler()
                        }
                    }
                    perPageCustomList={[5,10,20,50]}
                />
            </BillingFeesCreateNewSection>
            <BillingFeesTableSection>
                <Grid 
                    rowData={data}  
                    // defaultColDef={defaultColumnDefs} 
                    columnDefs={columnDefs}
                    rowHeight={40}
                    domLayout='autoHeight'
                    gridOptions={gridOptions}
                    loading={loading}
                    onCellClicked={(event: any) => 
                        event.colDef.pinned !== "right" && rowExpandHandler(event.data)
                    }
                    onDragStopped={onColumnDrop}
                    enableAllColumns={enableAllHandler}
                />
            </BillingFeesTableSection>
            <Modal
                opened={deleteModal.opened}
                title={`Delete ${deleteModal.type.charAt(0).toUpperCase() + deleteModal.type.slice(1)}?`}
                closeModal={() => setDeleteModal(
                    {
                        opened: false, type: deleteModal.type, data: null
                    }
                )}>
                {/* {deleteModal.type === 'fee' ? <span >Fee Modal</span> : <span>Delete modal</span> } */}
                <BilingFeesDeleteModalBody isDeleteDisabled={disableDelete}>
                    <div className="question">
                        Are you sure you want to delete this fee {deleteModal.type === "group" && "group"} ?
                        <br />
                        <br />
                        This action cannot be undone.
                        <br />
                        <br />
                    </div>
                    <div className="buttons">
                        <div className="button">
                            <div className="cancel" onClick={() => setDeleteModal({...deleteModal, opened: false})}>Cancel</div>
                        </div>
                        <div className="button">
                            <div className="delete" onClick={() => deleteFee(deleteModal.data, deleteModal.type)}>
                                Yes, delete this fee{deleteModal.type === "group" && " group"}.
                            </div>
                        </div>
                    </div>

                </BilingFeesDeleteModalBody>
            </Modal>
            <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => dispatch(closeAlert())}>
                {alertModal.message}
            </AlertModal>
            <Modal
                opened={editFeeModal.opened}
                title={`${editFeeModal.data ? 'Update Fee' : 'Create New Fee'}`}
                closeModal={() => setEditFeeModal({opened: false, data: null})}
            >
                <CreateNewFeeModal
                    closeModal={() => setEditFeeModal({opened: false, data: null})}
                    fetchFees={fetchInitialData}
                    affiliates={affiliates}
                    platforms={platforms}
                    setAlertModal={setSaveFeeAlertModal}
                    data={editFeeModal.data}
                />
            </Modal>
            <Modal opened={feeGroupModal.opened} title={`${feeGroupModal.data ? 'Edit Fee Group' : 'Create Fee Group'}`} closeModal={() => setFeeModalGroup({opened: false, data: null})}>
                <CreateNewFeeGroupModal setAlertModal={setSaveFeeAlertModal} fetchFeesTable={fetchInitialData} closeModal={() => setFeeModalGroup({opened: false, data: null})} group={feeGroupModal.data}/>
            </Modal>
            <AlertModal
                isOpen={saveFeeAlertModal.opened}
                type={saveFeeAlertModal.type}
                closeModal={() => setSaveFeeAlertModal({
                    opened: false,
                    message: '',
                    type: 'success'
                })}
            >
                {saveFeeAlertModal.message}
            </AlertModal>
        </BillingFeesWrapper>
    )
}

export default BillingFees;