import styled from "styled-components";

export const WebsiteLink = styled.a`
  color: #0093D3;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  transition: .2s ease-in-out;
  text-decoration: underline;
  :hover{
    cursor: pointer;
    color: #0066BC;
  }
`

export const MarketerNameLink = styled.span`
  color: ${({theme}) => theme.colors.performBlue[5]};
  transition: .2s ease-in-out;
  
  :hover{
    cursor: pointer;
    color: #007383;
  }
`