import styled from 'styled-components'
import {ButtonStyled} from "../../../common/styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 154px;
    flex-direction: column;
    height: 100%;
    background-color: white;
    text-align: center;
    
    .content-container {
        width: 422px;
        max-width: 80%;
    }
    
    .perform-logo{
        width: 245px;
    }
    
    .form-container {
        width: 422px;
        max-width: 80%;
    }
    
    .label {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 12px;
        margin-bottom: 8px;
    }
    
    .btn-cancel {
        margin: auto;
    }
    
    .row{
        margin-top: 40px;
    }
    
    .submit-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
    }

    .input-styled{
        margin: 0;
    }

    p{
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        font-size: 12px;
    }

    .resend-code {
        color: #0093D3;
        cursor: pointer;
    }

    .resend-code-disabled {
        color: ${({theme}) => theme.colors.coolGray[4]};
    }

    .resend-code-again {
        color: ${({theme}) => theme.colors.performBlue[5]};
    }
    
    .remember-device-wrapper{
        display: flex;
        margin-top: 9px;
    }
    
    .authentication-setup-title{
        font-size: 24px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        margin-top: 64px;
    }
    
    .return-to-login{
        margin-top: 96px;
        span{
            cursor: pointer;
            color: ${({theme}) => theme.colors.performOrange[5]};
        }
    }
    
    .lost-device-content-wrapper {
        margin-top: 18px;
    }
`

export const SubmitButton = styled(ButtonStyled).attrs({
    type: 'submit',
    className: 'btn-orange-filled'
})`
    width: 100%;
    height: 45px;
`