import React, {useEffect, useState} from 'react'
import Modal from "../../../../../../../common/components/modal/Modal";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import TextArea from "../../../../../../../common/components/textarea/TextArea";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../../../common/styled-components";

import {NotesModalHeader} from "./AddEditNotesModal.style";
import {IIFetchMarketerFundingDataResponse, IUpdateBillingNotesParams} from "../MarketerFundingMonitoring.service";

interface Props {
    modal: {
        opened: boolean,
        data: IIFetchMarketerFundingDataResponse | null
    },
    closeModal: () => void
    updateBillingNotes: (params: IUpdateBillingNotesParams) => void
    fetchTableData: () => void
}

const AddEditNotesModal: React.FC<Props> = ({modal, closeModal, updateBillingNotes, fetchTableData}) => {
    const [textAreaValue, setTextAreaValue] = useState<string | null>('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(modal.data){
            setTextAreaValue(modal.data.billing_notes)
        }

    }, [modal])

    const update = async () => {
        try {
            if(modal.data){
                const params: IUpdateBillingNotesParams = {
                    user_id: modal.data.id,
                    billing_notes: textAreaValue
                }
                setLoading(true)
                await updateBillingNotes(params)
                fetchTableData()
                closeModal()
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            closeModal()
        }
    }

    const modalHeader = <NotesModalHeader>
        <h2>{modal.data?.billing_notes === null ? 'Add Billing Notes' : 'View/Edit Billing Notes'}</h2>
        <p>Marketer ID: <span className={'notes-header-value'}>{modal.data?.id}</span></p>
        <p>Marketer Company Name: <span className={'notes-header-value'}>{modal.data?.company}</span></p>
        <p>Marketer Name: <span className={'notes-header-value'}>{modal.data?.name}</span></p>
        <p>Engine Growth Manager: <span className={'notes-header-value'}>{modal.data?.account_manager_name + ' ' + modal.data?.account_manager_lastname}</span></p>
    </NotesModalHeader>

    return <Modal opened={modal.opened} closeModal={closeModal} title={modalHeader}>
        <div style={{marginTop: '32px'}}></div>
        <TextArea
            label={'Billing Note'}
            value={textAreaValue ? textAreaValue : ''}
            onChange={(e) => setTextAreaValue(e.target.value)}
            placeholder={'Enter any notes related to the marketer’s billing'}
            rows={6}
        />
        <ModalFooter>
            <ButtonStyled onClick={closeModal}>Cancel</ButtonStyled>
            <ButtonStyled disabled={loading} onClick={update} className={'btn-orange-filled'}>{loading && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}Save</ButtonStyled>
        </ModalFooter>
    </Modal>
}

export default AddEditNotesModal
