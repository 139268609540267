import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IMediaRepFilter, IAdvertisersFilter, IFilterHookReturn} from "filters";
import {useFilterSelector} from "../useFilterSelector";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useAppDispatch} from "../useAppDispatch";


export const useMediaRepFilter = (pageId: PageID ,displayCount?: number): IFilterHookReturn<IMediaRepFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IMediaRepFilter[]>([])
    const [mediaReps, setMediaReps] = useState<IMediaRepFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [mediaRepsFilterSearchValue, setMediaRepsSearchValue] = useState('')
    const [mediaRepsFilterNotDisplayed, setMediaRepsFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID = 'media-rep'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IMediaRepFilter[]>(
                'admins',
                {
                    approve_sector: true
                },
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)

            const users: IMediaRepFilter[] = []

            data.forEach(admin => {
                if(admin.approve_sector !== null && admin.approve_sector !== 'null'){
                    if(JSON.parse(admin.approve_sector)['media'] === 1){
                        users.push(admin)
                    }
                }
            })
            setFetchedData(
                users
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredMediaReps = fetchedData.filter(admin => {
            return (
                (admin.name + ' ' + admin.lastname).toLowerCase().includes(mediaRepsFilterSearchValue.toLowerCase())
                    ||
                    admin.id.toString().includes(mediaRepsFilterSearchValue.toLowerCase())
            )
        })

        if(displayCount){
            const displayedMedia = [...filteredMediaReps].slice(0, displayCount)
            if(filteredMediaReps.length > displayCount){
                setMediaRepsFilterNotDisplayed(filteredMediaReps.length - displayCount)
            } else {
                setMediaRepsFilterNotDisplayed(0)
            }
            setMediaReps(displayedMedia)
        } else {
            setMediaReps(
                filteredMediaReps
            )
        }
    }, [mediaRepsFilterSearchValue, fetchedData, displayCount])

    const mediaRepsFilter = useFilterSelector<IMediaRepFilter>(pageId, id)

    const setSelectedAdvertisers = (advertiser: IMediaRepFilter) => {
        if(mediaRepsFilter.selected.some((filterAdvertiser)  => filterAdvertiser.id === advertiser.id)){
            dispatch(setFilter<IMediaRepFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: mediaRepsFilter.exclude,
                    selected: mediaRepsFilter.selected.filter(filterAdvertiser => filterAdvertiser.id !== advertiser.id)
                }
            }))
        } else {
            dispatch(setFilter<IMediaRepFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: mediaRepsFilter.exclude,
                    selected: [...mediaRepsFilter.selected, advertiser]
                }

            }))
        }
    }

    const setExcludeAdvertiser= (exclude: boolean) => {
        dispatch(setFilter<IMediaRepFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: mediaRepsFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }


    return {
        id,
        pageId: pageId,
        loading,
        data: mediaReps,
        setSearchValue: setMediaRepsSearchValue,
        notDisplayed: mediaRepsFilterNotDisplayed,
        selectedData: mediaRepsFilter.selected,
        exclude: mediaRepsFilter.exclude,
        setSelectedData: setSelectedAdvertisers,
        setExclude: setExcludeAdvertiser,
        clearSelected: clearSelectedFilter
    }
}