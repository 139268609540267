import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {useCallback, useEffect, useState} from "react";
import {IFilterHookReturn, ISalesExecutiveFilter} from "filters";
import {useAppDispatch} from "../useAppDispatch";
import {filterApi} from "../../services/api";
import {useFilterSelector} from "../useFilterSelector";

export const useSalesExecutiveFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<ISalesExecutiveFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<ISalesExecutiveFilter[]>([])
    const [salesExecutives, setSalesExecutives] = useState<ISalesExecutiveFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [salesExecutiveSearchValue, setSalesExecutiveSearchValue] = useState('')
    const [salesExecutivesFilterNotDisplayed, setSalesExecutivesFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id ='sales-executive'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<ISalesExecutiveFilter[]>(
                'account-managers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data.sort((a: ISalesExecutiveFilter, b: ISalesExecutiveFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredSalesExecutives = fetchedData.filter(executive => (
                executive.name.toLowerCase() + ' ' + executive.lastname.toLowerCase()).includes(salesExecutiveSearchValue.toLowerCase())
            ||
            executive.id.toString().includes(salesExecutiveSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedExecutives = [...filteredSalesExecutives].slice(0, displayCount)
            if(filteredSalesExecutives.length > displayCount){
                setSalesExecutivesFilterNotDisplayed(filteredSalesExecutives.length - displayCount)
            } else {
                setSalesExecutivesFilterNotDisplayed(0)
            }
            setSalesExecutives(displayedExecutives)
        } else {
            setSalesExecutives(
                filteredSalesExecutives
            )
        }
    }, [salesExecutiveSearchValue, fetchedData, displayCount])

    const salesExecutiveFilter = useFilterSelector<ISalesExecutiveFilter>(pageId, id)

    const setSelectedSalesExecutives = (manager: ISalesExecutiveFilter) => {
        if(salesExecutiveFilter.selected.some((filterManager)  => filterManager.id === manager.id)){
            dispatch(setFilter<ISalesExecutiveFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: salesExecutiveFilter.exclude,
                    selected: salesExecutiveFilter.selected.filter(accManager => accManager.id !== manager.id)
                }
            }))
        } else {
            dispatch(setFilter<ISalesExecutiveFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: salesExecutiveFilter.exclude,
                    selected: [...salesExecutiveFilter.selected, manager]
                }

            }))
        }
    }

    const setExcludeSalesExecutives = (exclude: boolean) => {
        dispatch(setFilter<ISalesExecutiveFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: salesExecutiveFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }


    return {
        id,
        pageId: pageId,
        loading,
        data: salesExecutives,
        setSearchValue: setSalesExecutiveSearchValue,
        notDisplayed: salesExecutivesFilterNotDisplayed,
        selectedData: salesExecutiveFilter.selected,
        exclude: salesExecutiveFilter.exclude,
        setSelectedData: setSelectedSalesExecutives,
        setExclude: setExcludeSalesExecutives,
        clearSelected: clearSelectedFilter
    }
}