import { ReactNode } from "react";
import { SwitchToggle, SwitchToggleButtonWrapper, SwitchToggleDisabled, SwitchToggleLabelDisabled, SwitchToggleLabel } from "./SwitchToggleButton.style";

interface ISwitchToggleButtonProps {
    label?: string,
    disabled?: boolean,
    active: boolean,
    setActive: (active: boolean) => void,
    labelFirst?: boolean,
    toggleButtonClassName?: string,
    labelClassName?: string,
    wrapperClassName?: string,
    children?: ReactNode | React.FC | string,
}

const SwitchToggleButton = ({
                                label,
                                labelFirst,
                                disabled,
                                active,
                                setActive,
                                toggleButtonClassName,
                                labelClassName,
                                wrapperClassName,
                                children
}:ISwitchToggleButtonProps) => {
    if(disabled) {
        return (
            <SwitchToggleButtonWrapper className={wrapperClassName}>
                {!labelFirst &&
                    <SwitchToggleDisabled className={toggleButtonClassName} $active={active} $labelFirst={labelFirst}>
                        <div />
                    </SwitchToggleDisabled>
                }
                {label &&
                    <SwitchToggleLabelDisabled className={labelClassName}>
                        {label}
                    </SwitchToggleLabelDisabled>
                }
                {labelFirst &&
                    <SwitchToggleDisabled className={toggleButtonClassName} $active={active} $labelFirst={labelFirst}>
                        <div />
                    </SwitchToggleDisabled>
                }
            </SwitchToggleButtonWrapper>
        )
    }


    return(
        <SwitchToggleButtonWrapper className={wrapperClassName} onClick={() => setActive(!active)}>
            {!labelFirst &&
                <SwitchToggle className={toggleButtonClassName} $active={active} $labelFirst={labelFirst}>
                    <div />
                </SwitchToggle>
            }
            {
                children ?
                <SwitchToggleLabel className={labelClassName}>
                    {children}
                </SwitchToggleLabel>
                :
                label ?
                    <SwitchToggleLabel className={labelClassName}>
                    {label}
                    </SwitchToggleLabel>
                    :
                    null
            }
            {labelFirst &&
                <SwitchToggle className={toggleButtonClassName} $active={active} $labelFirst={labelFirst}>
                    <div />
                </SwitchToggle>
            }

        </SwitchToggleButtonWrapper>
    )
}

export default SwitchToggleButton;
