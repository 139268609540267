import styled, {css} from 'styled-components'



export const LabelStyledFloatable = styled.label<{$disabled?: boolean}>`
  position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  line-height: 16px;
  color: ${({theme, $disabled}) => !$disabled ? theme.colors.deepBlue[5] : theme.colors.coolGray[4]};
  padding: 0 4px;
  top: -8px;
  left: 8px;
  z-index: 1;
  transition: color .2s ease;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 8px;
        height: 1px;
        background: #ffffff;
        z-index: -1;
    }
    
    span{
        display: inline-flex;
    }
`

export const LabelStyledDefault = styled.label`
    display: block;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    font-size: 12px;
    margin-bottom: 8px;
`

export const Wrapper = styled.div<{ error: boolean }>`
    margin-bottom: 32px;
    position: relative;

    .optional{
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
    }

    :focus-within {
        ${LabelStyledFloatable} {
            color: ${({theme, error}) => !error ? theme.colors.performBlue[5] : '#F23842'};
        }
    }

    ${({error}) => error && css`
        ${LabelStyledFloatable} {
            color: #F23842;
        }

        ${StyledInput} {
            border-color: #F23842;

            :hover {
                border-color: #F23842;
            }

            :focus {
                border-color: #F23842;
            }
        }
    `}
`

export const StyledInput = styled.input`
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
    height: 40px;
    outline: none;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 8px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    color: ${({theme}) => theme.colors.deepBlue[4]};
    transition: border-color .2s ease;
  
    ::placeholder {
        color: ${({theme}) => theme.colors.coolGray[4]};
    }

    :hover {
        border: 1px solid ${({theme}) => theme.colors.coolGray[5]};
    }

    :focus {
        border: 1px solid ${({theme}) => theme.colors.performBlue[5]};
    }

    :disabled {
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        background-color: white;
        color: ${({theme}) => theme.colors.coolGray[4]};
    }
    :read-only {
        cursor: default;
    }
`

export const ErrorStyled = styled.label<{ leftAlign?: boolean }>`
    position: absolute;
    bottom: -14px;
    ${({leftAlign}) => leftAlign ? css`
        left: 12px;
    ` : css`
        right: 12px;
    `}

    font-size: 10px;
    color: #F23842;
`

export const InputControl = styled.div`
    position: absolute;
    top: 10px;
    right: 12px;
    
    .angle{
        cursor: pointer;
        line-height: 1;
        font-size: 11px;
        color: #5A686F;
        position: relative;
        width: 14px;
        height: 10px;
    }
`
