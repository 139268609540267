import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {getAuth, tokenStore, userStore, wasAdminStore} from "../../../../store/auth.slice";
import CustomDomainService, {
    IAddCustomDomainResponse,
    ICustomDomainsData,
    ICustomDomainsDataParams
} from "./CustomDomain.service";
import {useAppSelector} from "../../../../common/hooks";
import {
    Wrapper,
    ContentWrapper,
    PageTitle,
    DomainUrl,
    DeleteDropdownItem, SelectionPillsContainer,
} from "./CustomDomain.style";
import Grid from "../../../../common/components/grid/Grid";
import FiltersRow from "../../../../common/components/filters/filters-row/FiltersRow";
import {FiltersRowLeft, FiltersRowRight} from "../../../../common/components/filters/filters-row/FiltersRow.style";
import GridRowOptions from "../../../../common/components/grid/grid-row-options/GridRowOptions";
import {DropdownItem, DropdownItemsSeparator} from "../../../../common/components/dropdown/Dropdown.style";
import Tooltip from "../../../../common/components/tooltip/Tooltip";
import Icon from "../../../../common/components/icon/Icon";
import FiltersButton from "../../../../common/components/filters/filters-button/FiltersButton";
import Search from "../../../../common/components/search/Search";
import UiFilter from "../../../../common/components/filters/filters-ui/UiFilter";
import Filters from "../../../../common/components/filters/filters-container/Filters";
import FiltersListItem from "../../../../common/components/filters/filters-list-item/FiltersListItem";
import SelectDropdown from "../../../../common/components/select-dropdown/SelectDropdown";
import SelectionPills from "../../../../common/components/selection-pills/SelectionPills";
import {customDomainStatuses, ICustomDomainStatuses} from "../../../../common/components/filters/statuses";
import Pagination from "../../../../common/components/table/pagination/Pagination";
import {ButtonStyled} from "../../../../common/styled-components";
import AddDomainModal from "./add-domain-modal/AddDomainModal";
import DeleteDomainModal from "./delete-domain-modal/DeleteDomainModal";
import PreviewDomainModal from "./preview-domain-modal/PreviewDomainModal";

interface IModal {
    opened: boolean,
    data: any
}

const CustomDomain:React.FC = () => {
    const token = useAppSelector(tokenStore);
    const user = useAppSelector(userStore);
    const [loading, setLoading] = useState<boolean>(true)
    const [tableData, setTableData] = useState<ICustomDomainsData[]>([])
    const [search, setSearch] = useState<string>("");
    const [openFilters, setOpenFilters] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [statusSelection, setStatusSelection] = useState<ICustomDomainStatuses[]>([{ id: 'all', name: "All"}])
    const [selectedFilters, setSelectedFilter] = useState<string[]>(["all",""])

    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [perPage, setPerPage] = useState<number>(10)
    const [lastPage, setLastPage] = useState<number>(0)

    const [deleteModal, setDeleteModal] = useState<{opened: boolean; data: any;}>({
        opened: false,
        data: ""
    });

    const [deleteSuccessAlert, setDeleteSuccessAlert] = useState<{opened: boolean; type: 'success'|'error', message: string }>({
        opened: false,
        type: 'success',
        message: ''
    });


    const fetchCustomDomainsTable = async () => {
        try {
            setLoading(true)
            const params : ICustomDomainsDataParams = {
                search,
                current_page: currentPage,
                paginate_by: perPage
            }

            if (statusSelection[0].id !== "all")  params.status = statusSelection[0].id

            if (currentPage !== 1 ) params.current_page = currentPage
            if (perPage !== 50) params.paginate_by = perPage

            const {data} = await CustomDomainService.fetchCustomDomainsData(params, token)
            setTableData(data.data)
            setFrom(data.from)
            setLastPage(data.last_page)
            setTo(data.to)
            setTotal(data.total)

            if(perPage > data.total)
                setCurrentPage(1)
            else
                setCurrentPage(data.current_page)
            // console.log('Real Data', data)


            setLoading(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    const removeFilter = (item : string) => {
        setStatusSelection([customDomainStatuses[0]])
    }

    const handleDefaultDomain = async (id: number, is_default: number) => {
        setLoading(true)
        await CustomDomainService.setDefaultDomain(token, id, is_default)
        await fetchCustomDomainsTable();
    }

    const deleteDomain = async (id: number) => {
        setLoading(true)
        setDeleteModal({opened: false, data: ""})
        try {
            await CustomDomainService.deleteDomain(token, id)
            setDeleteSuccessAlert({opened: true, type: 'success', message: 'The domain has been deleted.'})
        }
        catch (err) {
            console.log(err)
            setDeleteSuccessAlert({opened: true, type: 'error', message: 'Something went wrong'})
        }
        await fetchCustomDomainsTable();
    }

    // Add New Domain Start //
    const [addDomainModal, setAddDomainModal] = useState<{opened: boolean}>({
        opened: false,
    });

    const [addDomainAlert, setAddDomainAlert] = useState<{opened: boolean; type: 'success'|'info', message: string | ReactElement }>({
        opened: false,
        type: 'info',
        message: ''
    });
    const [domain, setDomain] = useState<string>("");
    const REGEX_DOMAIN_VALIDATION = /^(www\.)?[\w-]{1,63}((\.[\w-]{1,63}){1,3})?\.[a-zA-Z]{2,}$/;
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [domainDetails, setDomainDetails] = useState<IAddCustomDomainResponse>()

    const handleSubmitNewDomain = async (domainValue: string) => {
        try {
            setSubmitLoading(true);
            const {data} = await CustomDomainService.addCustomDomain(token, domainValue);
            setDomainDetails(data);
            setSubmitLoading(false);
        }
        catch (err) {
            console.log('Error add new domain',err)
        }
    }

    const deleteNewlyDomain = async (id: number) => {
        try {
            await CustomDomainService.deleteDomain(token, id)
        }
        catch (err) {
            console.log(err)
        }
    }

    const AddDomainAlertBody = () => {
        return (
            <>
                Your new domain has been added. Please complete your DNS setup to make this domain verified and active.
                Reference our <a target={'_blank'} className={"link-orange"} href="https://support.performcb.com/support/solutions/articles/4000183700-change-nameservers-with-your-dns-provider-marketers">How To Guide </a> to learn more.
            </>
        )
    }

    const handleSaveAddNewDomainModal = () => {
        setAddDomainModal({opened: false})
        setDomain('')
        setDomainDetails(undefined)
        setAddDomainAlert({
            opened: true,
            type: 'info',
            message: <AddDomainAlertBody />
        })
        fetchCustomDomainsTable()
    }

    const handleCloseAddNewDomainModal = (id: number | undefined) => {
        id && deleteNewlyDomain(id)
        setDomain('')
        setDomainDetails(undefined)
        setAddDomainModal({opened: false})
    }
    // Add New Domain End //

    // Preview Domain Modal //
    const [previewDomainDetails, setPreviewDomainDetails] = useState<ICustomDomainsData>()
    const [previewDomainModal, setPreviewDomainModal] = useState<{opened: boolean, data: ICustomDomainsData | undefined}>({
        opened: false,
        data: undefined
    });
    // Preview Domain Modal End //

    const columnDefs = useMemo(() => [
        {
            field: "default",
            headerName: "",
            width: 46,
            resizable: false,
            lockVisible: true,
            suppressMovable: true,
            cellRenderer: ({data}: { data: ICustomDomainsData }) => {
                return (
                    <span style={{lineHeight: 1, cursor: 'pointer'}}>
                        {(data.is_default === 1) &&
                            <Tooltip icon={<Icon icon="Check" color="#00CE2D" size={14} />} position="right" width={190}>
                                This domain is set as the default domain
                            </Tooltip>
                        }
                    </span>
                )
            }
        },
        {
            field: "url", headerName: "DOMAIN", width: 400, lockVisible: true,
            cellRenderer: ({data}: { data: ICustomDomainsData }) => {
                return <DomainUrl onClick={()=> setPreviewDomainModal({opened: true, data: data})}>{data.domain}</DomainUrl>
            }
        },
        {
            field: "status", headerName: "STATUS", width: 220, lockVisible: true,
            cellRenderer: ({data}: { data: ICustomDomainsData }) => {
                return (
                    <span>{data.status}</span>
                )
            }
        },
        {
            pinned: 'right',
            lockVisible: true,
            width: 50,
            suppressMovable: true,
            resizable: false,
            cellRenderer: ({data}: {data: ICustomDomainsData}) => {
                return (
                    <GridRowOptions>
                        {data.status=='Verified' &&
                            <>
                            <DropdownItem onClick={() => handleDefaultDomain(data.id, data.is_default == 1 ? 0:1 )}>
                                {data.is_default == 1 ? 'Unset Default Domain' : 'Set as Default Domain' }
                            </DropdownItem>
                            <DropdownItemsSeparator />
                            </>
                        }

                        <DeleteDropdownItem
                            onClick={()=> setDeleteModal({opened: true, data: data})}>
                            Delete Domain
                        </DeleteDropdownItem>
                    </GridRowOptions>
                )
            },

        }
    ], [])

    useEffect(()=>{
        token && fetchCustomDomainsTable();
        let filters : string[] = []
        // console.log(statusSelection[0].id)
        if(statusSelection[0].id !== customDomainStatuses[0].id) {
            filters.push(statusSelection[0].name)
        }
        setSelectedFilter(filters)
    }, [token, statusSelection, currentPage, perPage, search])

    return (
        <Wrapper>
            <ContentWrapper>
                <PageTitle>Custom Domains</PageTitle>

                <FiltersRow>
                    <FiltersRowLeft>
                        <FiltersButton onClick={setOpenFilters} isOpen={openFilters}/>
                        <Search onChange={setSearch} initialValue="" placeholder="Search by Domain"/>
                    </FiltersRowLeft>
                    <FiltersRowRight>
                        <Pagination current_page={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    from={from}
                                    to={to}
                                    total={total}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    last_page={lastPage}
                                    loading={loading}
                                    onRefresh={fetchCustomDomainsTable} perPageCustomList={[5, 10, 20, 50]}
                        />
                        <ButtonStyled
                            className={'btn-blue-filled'}
                            onClick={()=> setAddDomainModal({opened: true})}
                            style={{marginLeft: 20}}>
                            Add Domain
                        </ButtonStyled>
                    </FiltersRowRight>
                </FiltersRow>
                <div style={{marginTop: 20, display: openFilters ? 'block' : 'none'}}>
                    <UiFilter setOpenFilters={setOpenFilters}>
                        <Filters>
                            <FiltersListItem>
                                <SelectDropdown open={openStatus}
                                                setOpen={setOpenStatus}
                                                setSelection={setStatusSelection}
                                                selection={statusSelection}
                                                options={customDomainStatuses}
                                                labelText={"Status"}
                                                loading={loading}
                                                isSearchable={false}/>
                            </FiltersListItem>
                        </Filters>
                    </UiFilter>
                </div>

                <SelectionPillsContainer>
                    { selectedFilters.map(item =>
                        <SelectionPills key={item}
                                        text={<><label>Status:</label> {item}</>}
                                        onRemove={() => removeFilter(item)} />

                    )}
                </SelectionPillsContainer>

                <Grid columnDefs={columnDefs}
                      domLayout='autoHeight'
                      rowData={tableData}
                      rowHeight={40}
                      loading={loading}
                />

                <AddDomainModal
                    addDomainModal={addDomainModal}
                    handleSaveAddNewDomainModal={handleSaveAddNewDomainModal}
                    handleCloseAddNewDomainModal={handleCloseAddNewDomainModal}
                    domainDetails={domainDetails}
                    setDomain={setDomain}
                    domain={domain}
                    handleSubmitNewDomain={handleSubmitNewDomain}
                    submitLoading={submitLoading}
                    addDomainAlert={addDomainAlert}
                    setAddDomainAlert={setAddDomainAlert}
                    REGEX_DOMAIN_VALIDATION={REGEX_DOMAIN_VALIDATION}/>

                <DeleteDomainModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    deleteDomain={deleteDomain}
                    deleteSuccessAlert={deleteSuccessAlert}
                    setDeleteSuccessAlert={setDeleteSuccessAlert} />

                <PreviewDomainModal
                    setPreviewDomainModal={setPreviewDomainModal}
                    previewDomainModal={previewDomainModal} />


            </ContentWrapper>
        </Wrapper>
    )
}

export default CustomDomain
