import React, {ChangeEvent} from 'react';
import {setOffer} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {DOMAIN_PARAMETER_INPUT} from "../shared-components/consts";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";

export const DomainIdParameterInput: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
    } = useAppSelector(state => state.createOffer)

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setOffer({
            ...offer,
            campaign_tracking_settings: {
                ...offer.campaign_tracking_settings,
                tracking_clickid_parameter: e.target.value
            }
        }))
    }

    return (
        <Input
            wrapperClassName={"input-wrapper"}
            label={<>Domain ID Parameter {Tooltip(188, DOMAIN_PARAMETER_INPUT)}</>}
            placeholder={"Enter the parameter used by your domain for ID’s"}
            value={offer.campaign_tracking_settings.tracking_clickid_parameter ? offer.campaign_tracking_settings.tracking_clickid_parameter : ''}
            onChange={(event) => handleOnChange(event)}
        />
    );
};