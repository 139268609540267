import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {Tooltip} from "../shared-components/Shared";
import {BRAND_URL_TOOLTIP, INVALID_URL_MSG, URL_PATTERN} from "../shared-components/consts";
import {setOffer, setValidation} from "../create-offer.slice";

const BrandUrl : React.FC = ({}) => {
    const dispatch = useAppDispatch()
    const {
        offer,
        validation
    } = useAppSelector(state => state.createOffer)
    const handleOnChange = (value: string) => {
        dispatch(setOffer({preview_url : value}))
        
        if(value) {
            if (!URL_PATTERN.test(value) && !validation.validatePreviewUrl) {
                dispatch(setValidation({validatePreviewUrl :
                    INVALID_URL_MSG }
                ))
            } else if (URL_PATTERN.test(value) && validation.validatePreviewUrl ) {
                dispatch(setValidation({validatePreviewUrl : undefined}))
            }
        }
        else {
            dispatch(setValidation({validatePreviewUrl : "Brand URL is required"}))
        }
    }
    
    return (
        <Input
            label={<>Brand Url {Tooltip(180, BRAND_URL_TOOLTIP)}</>}
            placeholder={"Enter the brand url this campaign represents"}
            // brand url is the same thing as preview url. CBLEAP-14475 comment by Brittany
            value={offer.preview_url}
            onChange={(event) => handleOnChange(event.target.value)}
            error={validation.validatePreviewUrl}
        />
    );
};

export default BrandUrl