import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector, useCopy, useFeatureFlags} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {setEventPostbackUrlValidation, setEventValidation, setOffer} from "../create-offer.slice";
import {StyledSelect} from "../../../../../common/components/select/Select";
import ReactDOMServer from "react-dom/server";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {uniqueId} from "lodash";
import PayoutModifiers from "./PayoutModifiers";
import {
    AdjustEventMacro,
    APPSFLYER_TOOLTIP_CONTENT, CONVERSION_PAGE_URL, EVENT_PAGE,
    INVALID_URL_MSG,
    KOCHAVA_TOOLTIP_CONTENT, PAYOUT_DESCRIPTION, PAYOUT_TYPE, SINGULAR_TOOLTIP_CONTENT,
    URL_PATTERN
} from "../shared-components/consts";
import {AlertStyledContent, PostbackParamButton, PostbackWrapper} from "../layout/main/Main.style";
import {AlertStyled} from "../../../../../common/styled-components";
import {Tooltip} from "../shared-components/Shared";
import ReactTooltip from "react-tooltip";

export interface Props {
    index: number,
    event: any
}

const Event: React.FC<Props> = ({index, event}) => {
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        ReactTooltip.rebuild()
    })
    
    const {offer, validation} = useAppSelector(state => state.createOffer)
    
    const payoutModifiersFlag = useFeatureFlags('payout_modifiers', offer.niche ? offer.niche.network_id : undefined)
    const domainTrackingFlag = useFeatureFlags('domain-tracking-code', offer.niche ? offer.niche.network_id : undefined)
    
    const [label, setLabel] = useState('')
    const [placeholder, setPlaceholder] = useState('')
    const [readonly, setReadonly] = useState<boolean>(false) 
    const [tooltip, setTooltip] = useState<boolean>(false)
    
    const [mapToCopy, setMapToCopy] = useState('')
    const [copied, copy, setCopied] = useCopy(mapToCopy)
    
    const handleEventPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const upsells = [...offer.upsells]
        upsells[index] = {
            ...event,
            upsell_tracking_settings: {
                ...event.upsell_tracking_settings,
                conversion_page_url: e.target.value
            }
        } 
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
        
        // Field Validations
        if(offer.upsells[index].upsell_tracking_settings == undefined) {
            if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
                || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking & Redirectless
                || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem === 'google-analytics') // Offer Type = Clicks & Tracking System = Google Analytics
            ) {
                dispatch(setEventValidation({
                    ...validation.validateEvent,
                    [event.id]: ''
                }))
            }
        }else {
            if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
                || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking & Redirectless
                || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem === 'google-analytics') // Offer Type = Clicks & Tracking System = Google Analytics
                && offer.upsells[index].upsell_tracking_settings) {
                // required
                if(e.target.value 
                    && e.target.value.length > 0) {
                    if(!URL_PATTERN.test(e.target.value) 
                        && validation.validateEvent !== {[event.id]: INVALID_URL_MSG}) {
                        dispatch(setEventValidation({
                            ...validation.validateEvent,
                            [event.id]: INVALID_URL_MSG 
                        }))
                    }
                    if (URL_PATTERN.test(e.target.value) && validation.validateEvent !== undefined) {
                        dispatch(setEventValidation({
                            ...validation.validateEvent,
                            [event.id]: ''
                        }))
                    }
                } else {
                    dispatch(setEventValidation({
                        ...validation.validateEvent,
                        [event.id]: 'Event Page URL is required '
                    }))
                }
            }else {
                // optional
                if(e.target.value &&
                    e.target.value!.length > 0) {
                    if(!URL_PATTERN.test(e.target.value) 
                        && validation.validateEvent !== {[event.id]: INVALID_URL_MSG}) {
                        dispatch(setEventValidation({
                            ...validation.validateEvent,
                            [event.id]: INVALID_URL_MSG
                        }))
                    }
                    if (URL_PATTERN.test(e.target.value) && validation.validateEvent !== undefined) {
                        dispatch(setEventValidation({
                            ...validation.validateEvent,
                            [event.id]: ''
                        }))
                    }
                }else {
                     dispatch(setEventValidation({
                        ...validation.validateEvent,
                        [event.id]: ''
                     }))
                }
            }
        }
    }
    
    const handleValidateEventPageOnBlur = () => {
        const upsells = [...offer.upsells]
        if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking & Redirectless
            || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem === 'google-analytics') // Offer Type = Clicks & Tracking System = Google Analytics
        ) {
            if(upsells[index].upsell_tracking_settings === undefined) {
                dispatch(setEventValidation({
                    ...validation.validateEvent,
                    [event.id]: 'Event Page URL is required'
                }))
            }else if (upsells[index].upsell_tracking_settings.conversion_page_url.length === 0) {
                dispatch(setEventValidation({
                    ...validation.validateEvent,
                    [event.id]: 'Event Page URL is required'
                }))
            }
        }
    }
    
    
    const handleEventPostbackUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const upsells = [...offer.upsells]
        upsells[index] = {
            ...event,
            upsell_tracking_settings: {
                ...event.upsell_tracking_settings,
                conversion_tracking_url: e.target.value
            }
        } 
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
        
        // Field Validations
        if(offer.upsells[index].upsell_tracking_settings == undefined) {
            if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
                ||(offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
            ) {
                dispatch(setEventPostbackUrlValidation({
                    ...validation.validateEventPostbackUrl,
                    [event.id]: ''
                }))
            }
        }else {
            if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
                || (offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
                && offer.upsells[index].upsell_tracking_settings) {
                // required
                if(e.target.value 
                    && e.target.value.length > 0) {
                    if(!URL_PATTERN.test(e.target.value) 
                        && validation.validateEventPostbackUrl !== {[event.id]: INVALID_URL_MSG}) {
                        dispatch(setEventPostbackUrlValidation({
                            ...validation.validateEventPostbackUrl,
                            [event.id]: INVALID_URL_MSG 
                        }))
                    }
                    if (URL_PATTERN.test(e.target.value) && validation.validateEventPostbackUrl !== undefined) {
                        dispatch(setEventPostbackUrlValidation({
                            ...validation.validateEventPostbackUrl,
                            [event.id]: ''
                        }))
                    }
                } else {
                    dispatch(setEventPostbackUrlValidation({
                        ...validation.validateEventPostbackUrl,
                        [event.id]: 'Event Postback URL is required'
                    }))
                }
            }else {
                // optional
                if(e.target.value &&
                    e.target.value!.length > 0) {
                    if(!URL_PATTERN.test(e.target.value) 
                        && validation.validateEventPostbackUrl !== {[event.id]: INVALID_URL_MSG}) {
                        dispatch(setEventPostbackUrlValidation({
                            ...validation.validateEventPostbackUrl,
                            [event.id]: INVALID_URL_MSG
                        }))
                    }
                    if (URL_PATTERN.test(e.target.value) && validation.validateEventPostbackUrl !== undefined) {
                        dispatch(setEventPostbackUrlValidation({
                            ...validation.validateEventPostbackUrl,
                            [event.id]: ''
                        }))
                    }
                }else {
                     dispatch(setEventPostbackUrlValidation({
                        ...validation.validateEventPostbackUrl,
                        [event.id]: ''
                     }))
                }
            }
        }
    }
    
    const handleEventPostbackUrlOnBlur = () => {
        const upsells = [...offer.upsells]
        if((offer.direct_linking === false && offer.legacy_tracking === false) //Standard Redirects + Domain Tracking Code
            ||(offer.direct_linking === true && offer.legacy_tracking === false) //Standard Redirects + Legacy Tracking && Redirectless
        ) {
            // console.log('upsells[index]', upsells[index])
            if (upsells[index].upsell_tracking_settings === undefined) {
                dispatch(setEventPostbackUrlValidation({
                    ...validation.validateEventPostbackUrl,
                    [event.id]: 'Event Postback URL is required'
                }))
            }else if (upsells[index].upsell_tracking_settings.conversion_tracking_url.length === 0) {
                dispatch(setEventPostbackUrlValidation({
                    ...validation.validateEventPostbackUrl,
                    [event.id]: 'Event Postback URL is required'
                }))
            }
        }
    }

    const handlePostbackParameter = () => {
        switch (offer.trackingSystem) {
            case 'tune':
                return 'transaction_id='
            case 'impact':
                return 'actionid='
            case 'cake':
                return 'reqid='
            case 'everflow':
                return 'transaction_id='
            case 'other':
                return ''
            default:
                return ''
        }
    }
    
    const appendMacro = () => {
        const upsells = [...offer.upsells]
        
        if(upsells[index].upsell_tracking_settings.conversion_tracking_url !== null) {
            const macro = '{trackingclickid}'
            
            let postbackUrl:string = upsells[index].upsell_tracking_settings.conversion_tracking_url
            
            let temp = handlePostbackParameter()
            
            let postbackParam = temp ? temp : ''
            
            let parameterIndex = postbackUrl.indexOf(postbackParam) + postbackParam.length
            
            postbackUrl = postbackUrl.slice(0, parameterIndex) + macro + postbackUrl.slice(parameterIndex)
            
            if(postbackUrl.includes(postbackParam) && offer.trackingSystem){
                upsells[index] = {
                    ...event,
                    upsell_tracking_settings: {
                        ...event.upsell_tracking_settings,
                        conversion_tracking_url: postbackUrl
                    }
                }
                dispatch(setOffer({
                    ...offer,
                    upsells: [...upsells]
                }))
            }
        }
    }

    const handleEventNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const upsells = [...offer.upsells]
        upsells[index] = {
            ...event,
            name: e.target.value
        }
        
        dispatch(setOffer({
            ...offer,
            upsells : [...upsells]
        }))
    }
    
    const handleEventPayoutChange = (e: string | undefined) => {
        const upsells = [...offer.upsells]
        upsells[index] = {
            ...event,
            payout: e
        }
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
    }
    
    const eventPayoutTypeOptions = [
        {
            label: 'Standard',
            value: 0
        },
        {
            label: 'Revshare',
            value: 1
        }
    ]
    
    const handleEventPayoutType = (option:any) => {
        const upsells = [...offer.upsells]
        if(option.value == 1) {
            upsells[index] = {
                ...event,
                is_revshare : true
            }
        }else {
            upsells[index] = {
                ...event,
                is_revshare : false
            }
        }
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
    }
    
    const handlePayoutNameChange = (payoutIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const upsells = [...offer.upsells]
        const newRevshares = [...event.revshare]
        
        newRevshares[payoutIndex] = {...newRevshares[payoutIndex], name: e.target.value}
        
        upsells[index] = {
            ...event,
            revshare: [...newRevshares]
        }
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
    }
    
    const handlePayoutValueChange = (payoutIndex: number, e: string | undefined) => {
        const upsells = [...offer.upsells]
        const newRevshares = [...event.revshare]
        
        newRevshares[payoutIndex] = {...newRevshares[payoutIndex], value: e}
        
        upsells[index] = {
            ...event,
            revshare: [...newRevshares]
        }
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
    }
    
    const handleAddPayout = () => {
        const upsells = [...offer.upsells]
        upsells[index] = {
            ...event,
            revshare: [...upsells[index].revshare, {name: '', value: '', id: uniqueId('UPSELL_REVSHARE_')}]
        }
        
        dispatch(setOffer({
            ...offer,
            upsells: [...upsells]
        }))
    }
    
    const handleRemovePayout = (payoutIndex: number) => {
        const upsells = [...offer.upsells]
        const newRevshares = [...event.revshare]
        
        newRevshares.splice(payoutIndex, 1)
        
        upsells[index] = {
            ...event,
            revshare: [...newRevshares]
        }
        
        dispatch(setOffer({
            upsells: [...upsells]
        }))
    }
    
    const setUpsellModifiers = (modifiers: any, index: number) => {
        let newEvent = {...event}
        newEvent = {...newEvent, payout_modifiers: [...modifiers.map((mod: any) => ({...mod, upsell_id: event.id}))]}
        const upsells = [...offer.upsells]
        upsells[index] = newEvent
        dispatch(setOffer({
            upsells: [...upsells]
        }))
    }
    
    //Apps
    const handleEventTrackingGoalIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        //For now this function works only if 'Adjust' is selected as Tracking System
        const upsells = [...offer.upsells]
        
        if((offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') && offer.trackingSystem === 'adjust') {
            upsells[index] = {
                ...event,
                upsell_tracking_settings: {
                    ...event.upsell_tracking_settings,
                    event_tracking_goal_id: e.target.value
                }
            }
            dispatch(setOffer({
                upsells: [...upsells]
            }))
            
            let eventMacro = ""
            
            upsells.forEach((upsell : any, index: number) => {
                eventMacro += AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id)
            })
            dispatch(setOffer({
                upsells: upsells,
                destination_url_event_macros: eventMacro,
                destination_url: (offer.destination_url_copy + offer.destination_url_ts_macros + eventMacro).replace("?&", "?"),
            }))
        }
    }
    
    const handleEventTrackingGoalIDLabelAndPlaceholder = () => {
        switch (offer.trackingSystem){
            case 'adjust':
                setLabel('Adjust Event Token')
                setPlaceholder('Enter the token number from Adjust')
                setTooltip(false)
                setReadonly(false)
                break;
            case 'appsflyer':
                setLabel('Partner Event Mapping for Appsflyer')
                setPlaceholder('Enter the ID number from Appsflyer')
                setTooltip(true)
                setReadonly(true)
                break;
            case 'branch':
                setLabel('Branch Postback ID')
                setPlaceholder('Enter the ID number from Branch')
                setTooltip(false)
                setReadonly(false)
                break;
            case 'kochava':
                setLabel('Kochava Goal ID')
                setPlaceholder('Enter the ID number from Kochava')
                setTooltip(true)
                setReadonly(true)
                break;
            case 'singular':
                setLabel('Singular Goal ID')
                setPlaceholder('Enter the ID number from Singular')
                setTooltip(true)
                setReadonly(true)
                break;
        }
    }
    
    useEffect(()=> {
        handleEventTrackingGoalIDLabelAndPlaceholder()
    },[offer.trackingSystem])

    const handleAppsflyerEventMappingCopy = (upsell: string) => {
        setMapToCopy(upsell)
        // console.log('item', upsell)
        // console.log('mapToCopy', mapToCopy)
    }
    
    useEffect(() => {
        if(mapToCopy !== ''){
            copy()
        }
    }, [mapToCopy])
    
    return (
        <>
            {((offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') && offer.trackingSystem === 'branch' && offer.upsells.length > 1) &&
                <AlertStyled type={"info"} style={{marginBottom: 36}}>
                    <AlertStyledContent className={'info'}>
                        <i className={'ico-Information'}/>
                        <div>
                            When more than one event is added you will need to manually place the 
                            event ID number <strong>({event.id})</strong> into the postback field in Branch to properly 
                            track this outcome. Reference&nbsp;      
                            <a href="https://help.branch.io/using-branch/page/performcb" target={'_blank'}>
                                <strong>these setup instructions</strong>
                            </a>.
                        </div>
                    </AlertStyledContent>
                </AlertStyled>
            }
            <div className="form-group">
                <Input
                    label={<>Event Name</>}
                    wrapperClassName={'form-control'}
                    placeholder={'Enter a name for this event'}
                    value={event.name}
                    onChange={(e)=> handleEventNameChange(e)}
                />
            </div>
            {offer.detailed_pricing_model !== 'cpc' &&
                <div className="form-group" 
                     style={(offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') && offer.trackingSystem === 'branch' ? {marginBottom: 0} : undefined}>
                    {(offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe') &&
                        <StyledSelect 
                            selectWrapperClassname={'form-control'}
                            isSearchable={false}
                            onChange={handleEventPayoutType}
                            options={eventPayoutTypeOptions}
                            defaultValue={event.is_revshare ? {label: 'Revshare', value: 1} : {label: 'Standard', value: 0}}
                            selectLabel={
                                <>Payout Type {Tooltip(214, PAYOUT_TYPE)}</>
                            }
                        />
                    }
                    {!event.is_revshare &&
                        <CurrencyInput
                            wrapperClassName={`form-control${(offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') ? '' : ' form-control-payout'}`}
                            prefix={'$'}
                            value={event.payout ? event.payout : ''}
                            placeholder={'$0.00'}
                            label={<>Payout</>}
                            decimalScale={2}
                            onValueChange={(e)=> handleEventPayoutChange(e)}
                        />
                    }
                </div>
            }
            {/* App Install Offers */}
            {(offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe') &&
                <div className={'form-group'} style={{marginBottom: 0}}>
                    {offer.trackingSystem !== 'branch' &&
                        <Input
                            readOnly={readonly}
                            type={offer.trackingSystem === 'kochava' ? 'number' : 'text'}
                            label={<>{label} 
                                {tooltip &&
                                    <>
                                        {Tooltip(185, offer.trackingSystem === 'appsflyer' ? APPSFLYER_TOOLTIP_CONTENT 
                                               : offer.trackingSystem === 'kochava' ? KOCHAVA_TOOLTIP_CONTENT 
                                                   : offer.trackingSystem === 'singular' ? SINGULAR_TOOLTIP_CONTENT 
                                                       : '')}
                                    </>
                                }
                            </>}
                            placeholder={placeholder}
                            wrapperClassName={'form-control'}
                            value={event.upsell_tracking_settings?.event_tracking_goal_id 
                            ? event.upsell_tracking_settings.event_tracking_goal_id : ''}
                            onChange={(e)=> handleEventTrackingGoalIDChange(e)}
                        />
                    }
                    {(offer.trackingSystem === 'appsflyer' 
                            || offer.trackingSystem === 'kochava' 
                            || offer.trackingSystem === 'singular')  &&
                        <span
                            style={event.upsell_tracking_settings?.event_tracking_goal_id! 
                                ? undefined : {pointerEvents: 'none'}}
                            onMouseDown={()=> handleAppsflyerEventMappingCopy(event.upsell_tracking_settings?.event_tracking_goal_id!)}
                            data-html={true}
                            data-event={'click'}
                            data-event-off={'click'}
                            data-delay-hide={3000}
                            data-place={'top'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(<div>
                                <i className={"material-icons"} style={{
                                    color: "#3FDE7F",
                                    fontSize: '13px',
                                    transform: 'translateY(1px)'
                                }}>check_circle</i> Copied!
                            </div>)
                            }
                            className={'copy-icon-wrapper'}>
                            <i className="ico-copy"/>
                        </span>
                    }
                </div>
            }

            {event.is_revshare && <>
                {event.revshare.map((revshare: any, index: number)=>{
                    return (
                        <div
                            key={revshare.id}    
                            className={'form-group'}
                            style={{}}>
                            <Input
                                value={revshare.name}
                                wrapperClassName={'form-control'}
                                placeholder={'Enter a description of what this payout is for'}
                                onChange={(e)=> handlePayoutNameChange(index, e)}
                                label={
                                    <>Payout Description
                                        <span className={"optional"}>(optional)</span>
                                        {Tooltip(154, PAYOUT_DESCRIPTION)}
                                    </>
                                }
                            />
                            <CurrencyInput 
                                wrapperClassName={'form-control form-control-payout'}
                                prefix={'$'}
                                label={<>Payout</>}
                                value={revshare.value}
                                onValueChange={(e)=> handlePayoutValueChange(index, e)}
                                placeholder={'$0.00'}
                                decimalScale={2}
                            />
                            <button 
                                className={'btn-add-remove-payout'}
                                onClick={index === 0 ? handleAddPayout : ()=> handleRemovePayout(index)}>
                                <i className={index === 0 ? 'ico-PlusCircleFilled' : 'ico-Exit'}/>
                            </button>
                        </div>
                    )
                })}
                </>
            }

            {(offer.detailed_pricing_model !== 'cpi' && offer.detailed_pricing_model !== 'cpe' && domainTrackingFlag) &&
                <div className={'form-group'} 
                    style={{margin: '32px 0 0'}}>
                    <Input 
                        value={event.upsell_tracking_settings?.conversion_page_url 
                            ? event.upsell_tracking_settings.conversion_page_url : ''}
                        wrapperClassName={'form-control'}
                        placeholder={'Enter the URL for the page that this event takes place'}
                        onChange={(e)=> handleEventPageChange(e)}
                        error={validation.validateEvent && validation.validateEvent[event.id]}
                        onBlur={handleValidateEventPageOnBlur}
                        label={
                            <>Event Page
                                {((offer.direct_linking === false && offer.legacy_tracking) 
                                        || (offer.detailed_pricing_model === 'cpc' && offer.trackingSystem !== 'google-analytics')) &&
                                    <span className={"optional"}>(optional)</span>
                                }
                                {Tooltip(232, EVENT_PAGE)}
                            </>
                        }
                    />
                </div>
            }
            
            {/*Redirectless*/}
            {(offer.direct_linking === true && offer.legacy_tracking === false && domainTrackingFlag) && <>
                {(offer.trackingSystem !== 'none' && offer.trackingSystem !== 'google-analytics') && <>
                    <PostbackWrapper style={{margin: '32px 0 0'}} 
                        className={offer.trackingSystem + ''}>
                        <Input
                            value={event.upsell_tracking_settings?.conversion_tracking_url 
                                ? event.upsell_tracking_settings.conversion_tracking_url : ''}
                            onChange={(e)=>handleEventPostbackUrlChange(e)}
                            onBlur={handleEventPostbackUrlOnBlur}
                            error={validation.validateEventPostbackUrl && validation.validateEventPostbackUrl[event.id]}
                            wrapperClassName={'form-control'}
                            placeholder={offer.trackingSystem !== 'other' ? 
                                `Enter the postback URL for this event provided by ${offer.trackingSystem!.charAt(0).toUpperCase() + offer.trackingSystem!.slice(1)}` 
                                : `Enter the postback URL for this event provided by your tracking system`}
                            label={<>Event Postback URL</>}
                        />
                        <div>
                            {offer.trackingSystem !== 'other' ? 
                                <div className={'postback-url-description'}>
                                    This is the URL provided by<span className={"tracking-system-span"}> {offer.trackingSystem} </span> 
                                    that your server fires when this conversion happens to provide 
                                    them with the conversion info. After pasting the URL use the button 
                                    below to place the {'{trackingclickid}'} macro after {handlePostbackParameter()} parameter.
                                </div> :
                                <div className={'postback-url-description'}>
                                    This is the URL provided by your tracking system that your server fires when this 
                                    event happens to provide them with the event info. After pasting the URL use the 
                                    button below to place the {'{trackingclickid}'} macro after the parameter your tracking 
                                    system uses for click id / transaction id.
                                </div>
                            }
                            <div>
                                <PostbackParamButton onClick={()=> appendMacro()} className={'tracking-system-btn'}>
                                    <i className="material-symbols-outlined">add</i>
                                    {'{trackingclickid}'}
                                </PostbackParamButton>
                            </div>
                        </div>
                    </PostbackWrapper>
                </>}
            </>}
            {(!event.is_revshare && payoutModifiersFlag) && <>
                <PayoutModifiers 
                    modifiers={event.payout_modifiers} 
                    setModifiers={(modifiers) => setUpsellModifiers(modifiers, index)}
                    payout={event.payout}/>
            </>}
            
        </>
    );
};

export default Event