import styled, {css} from 'styled-components'

interface ITooltipInfoProps {
    position: string,
    opened: boolean,
    $width?: number,
    $left: number,
    $top: number
}

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 50;
  .ico-Information-outlined{
    font-size: 13.33px;
    color: #8799A2;
    :hover{
      cursor: pointer;
    }
  }
`

export const TooltipInfo = styled.div<ITooltipInfoProps>`
  strong{
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
  opacity: ${(props) => props.opened ? '1' : '0'};
  visibility: ${(props) => props.opened ? 'visible' : 'hidden'};
  padding: 16px;
  border: 1px solid #E9ECED;
  min-width: fit-content;
  max-width: ${props => props.$width}px;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  background-color: white;
  z-index: 10000;
  left: ${props => props.$left}px;
  top: ${props => props.$top}px;
  font-size: 10px;
  color: ${({theme}) => theme.colors.deepBlue['5']};
  transition: opacity .2s ease-in-out;
  strong {
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
  ${props => {
      if(props.position === 'left') {
          return css`
            transform: translate(calc(-100% - 7px), calc(-50% + 7px));
          `
      }

        if(props.position === 'right') {
          return css`
                transform: translate(20px, calc(-50% + 7px));
              `
        }

        if(props.position === 'bottom') {
          return css`
                    transform: translate(calc(-50% + 7px), 20px);
                  `
        }

        if(props.position === 'top') {
          return css`
                    transform: translate(calc(-50% + 7px), calc(-100% - 7px));
          `
        }
  }}

  .tooltip-text {
    width: 200px;
  }
  
  .carret {
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 9;
    transform: rotate(45deg);
    border-radius: 4px;
    left: calc(50% - 10px);
    background-color: #fff;
    ${props => {
      if(props.position === 'left') {
          return css`
            display: none;
          `
      }

        if(props.position === 'right') {
          return css`
                display: none;
              `
        }

        if(props.position === 'bottom') {
          return css`
                    top: -7px;
                  `
        }

        if(props.position === 'top') {
          return css`
            bottom: -7px;
          `
        }
  }}
  }
`

