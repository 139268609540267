import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";


interface Props {}

const ExcludedLocations: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(offer.excluded_locations.length === 0 || navState['targeting'] === 'disabled') return null

    return (
        <>
            <Subtitle>Excluded Locations</Subtitle>
            <TruncateContent wrapperClassName={'truncate-wrapper'}
                             numberOfLines={3}>{offer.excluded_locations.map((loc, index) => {
                return <span key={loc.name}>{loc.name}{index !== offer.excluded_locations!.length - 1 ? ', ' : ''}</span>
            })}
            </TruncateContent>
        </>
    )
}

export default ExcludedLocations