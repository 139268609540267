
export interface ReferralUserTypes {
    id: string,
    name: string
}
export const user_types :ReferralUserTypes[] = [
    {
        id: 'all',
        name: 'All'
    },
    {
        id: 'advertiser',
        name: 'Marketer'
    },
    {
        id: 'affiliate',
        name: 'Partner'
    }
];