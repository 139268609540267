import { FiltersButtonWrapper } from "./FiltersButton.style";

const FiltersButton: React.FC<{onClick: any, isOpen: boolean}> = ({onClick, isOpen}) => {
    
    return(
        <FiltersButtonWrapper>
            <button className={isOpen ? 'opened filters_button': 'closed filters_button'} onClick={()=> onClick(!isOpen)}>
                <span>Filter</span> {isOpen ? <i className="ico-AngleUp"></i> : <i className="ico-AngleDown"></i>}</button>
        </FiltersButtonWrapper>
    )
}

export default FiltersButton;