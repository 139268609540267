import React, {useEffect} from 'react'
import styled from 'styled-components'
import {TRACKING_CODE} from "../shared-components/consts";
import {useCopy} from "../../../../../common/hooks";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";

export const TrackingCode: React.FC = () => {
    const [copied, copy, setCopied] = useCopy(TRACKING_CODE)

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return <Wrapper>
        <div>
            <pre>{TRACKING_CODE}</pre>
            <button
                onClick={copy}
                className="copy-to-clipboard"
                data-html={true}
                data-event={'click'}
                data-event-off={'click'}
                data-delay-hide={3000}
                data-place={'right'}
                data-tip={ReactDOMServer.renderToStaticMarkup(<div>
                    <i className={"material-icons"} style={{color: "#3FDE7F", fontSize: '13px', transform: 'translateY(1px)'}}>check_circle</i> Copied!
                </div>)}
            >
                <i className="ico-copy"></i>
            </button>
        </div>
    </Wrapper>
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background: linear-gradient(90deg, rgba(63, 191, 199, 1) 0%, rgba(231, 236, 238, 1) 100%);
    border-radius: 8px;
    color: ${({theme}) => theme.colors.deepBlue[4]};

    div {
        position: relative;
        border-radius: 7px;
        width: 100%;
        padding: 10px 80px 10px 10px;
        height: 100%;
        background-color: ${({theme}) => theme.colors.coolGray[1]};
        margin: 1px;
    }

    pre{
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .copy-to-clipboard {
        height: calc(100% - 20px);
        position: absolute;
        top: 10px;
        right: 10px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: ${({theme}) => theme.colors.deepBlue[1]};
        cursor: pointer;

        .ico-copy {
            font-size: 13px;
            color: ${({theme}) => theme.colors.deepBlue[4]};
        }

        :hover {
            background-color: ${({theme}) => theme.colors.deepBlue[2]};
        }

        :hover:active {
            background-color: ${({theme}) => theme.colors.deepBlue[3]};
            color: ${({theme}) => theme.colors.deepBlue[6]};
        }
    }
`
