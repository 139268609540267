import React, {ReactElement} from "react";

import {Wrapper, StyledInput, LabelStyledFloatable, LabelStyledDefault, ErrorStyled, InputControl} from './Input.style'

interface Props extends React.InputHTMLAttributes<any>{
    label?: string | ReactElement
    labelDefault?: boolean
    error?: string | undefined
    labelClassName?: string
    textAreaClassName?: string
    wrapperClassName?: string
    errorClassName?: string
    inputControl?: {
        up: () => void
        down: () => void
    }
    leftAlignErrorMessage?: boolean
}

const Input: React.FC<Props> = ({
                                    label,
                                    error,
                                    labelClassName,
                                    textAreaClassName,
                                    wrapperClassName,
                                    errorClassName,
                                    labelDefault = false,
                                    leftAlignErrorMessage = false,
                                    inputControl,
                                    ...rest
                                }) => {
    return <Wrapper className={wrapperClassName} error={!!error}>
        {label && <>
                {labelDefault ? (<LabelStyledDefault className={labelClassName}>{label}</LabelStyledDefault>) :
                    (<LabelStyledFloatable $disabled={rest.disabled} className={labelClassName}>{label}</LabelStyledFloatable>)
                }
            </>
        }
        {inputControl && <InputControl>
            <div className="angle" onClick={inputControl.up}>
                <i className="ico-ChevronUp"/>
            </div>
            <div className="angle" onClick={inputControl.down}>
                <i className="ico-ChevronDown"/>
            </div>
        </InputControl>}
        <StyledInput className={textAreaClassName} {...rest}/>
        {error && <ErrorStyled
            leftAlign={leftAlignErrorMessage}
            className={errorClassName}
        >
            {error}
        </ErrorStyled>}
    </Wrapper>
}

export default Input

