import {useEffect} from 'react'
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../common/hooks";
import {setNavState} from "../create-offer.slice";
import {isNullOrUndefined} from "../../../../../common/utils";
import {
    OFFER_INFO, TRACKING_CODE, TRACKING_SYSTEM, LAND_AND_TRACK, OUTCOME_FUNNEL,DISABLED, CPS,CPI, CPC, CPE,CPL,BRANCH,
    TESTING, CREATIVES, BUDGET, TARGETING, SCHEDULING, COMPLETED, PPC, INCOMPLETED,GOOGLE_ANALYTICS, NONE, ADJUSTED, IOS, ANDROID
} from "../interfaces/interfaces";

export const useNavigationItems = () => {
    const {offer, validation, navState, cpcMainConversionPanelVisibility} = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()

    const redirectlessFeatureFlag = useFeatureFlags('domain-tracking-code')

    const offerInfoState = navState[OFFER_INFO]
    const trackingSystemState = navState[TRACKING_SYSTEM]
    const landAndTrackState = navState[LAND_AND_TRACK]
    const outcomeFunnelState = navState[OUTCOME_FUNNEL]
    const trackingCodesState = navState[TRACKING_CODE]
    const testingState = navState[TESTING]
    const creativesState = navState[CREATIVES]
    const budgetState = navState[BUDGET]
    const targetingState = navState[TARGETING]
    const schedulingState = navState[SCHEDULING]

    //OFFER INFO (ALL OFFER TYPES)
    useEffect(() => {
        //completed
        if(offer.detailed_pricing_model === PPC) {
            if(
                offer.name !== '' &&
                offer.niche !== null && 
                offer.preview_url !== '' && 
                validation.validatePreviewUrl === undefined
            ) {
                dispatch(setNavState({
                [OFFER_INFO] : COMPLETED
                }))
            }else {
                //uncompleted
                offerInfoState !== INCOMPLETED && dispatch(setNavState({
                    [OFFER_INFO] : INCOMPLETED
                }))
            }
        }else {
            if(
                offer.name !== '' &&
                offer.niche !== null &&
                offerInfoState !== COMPLETED
            ){
                dispatch(setNavState({
                    [OFFER_INFO]: COMPLETED
                }))
            }
    
            //uncompleted
            if(offer.name === '' || offer.niche === null){
                offerInfoState !== INCOMPLETED && dispatch(setNavState({
                    [OFFER_INFO]: INCOMPLETED
                }))
            }
        }

    }, [dispatch, offer.name, offer.niche, offer.preview_url])


    //TRACKING SYSTEM
    useEffect(() => {
        if(offerInfoState === COMPLETED) {
            if(offer.trackingSystem === null){
                trackingSystemState !== INCOMPLETED && dispatch(setNavState({
                    [TRACKING_SYSTEM]: INCOMPLETED
                }))
            } else if(offer.trackingSystem !==GOOGLE_ANALYTICS && offer.trackingSystem !== NONE){
                //unlocked
                if(
                    offer.destination_url === '' ||
                    validation.validateOfferTrackingUrl !== undefined
                ){
                    trackingSystemState !== INCOMPLETED && dispatch(setNavState({
                        [TRACKING_SYSTEM]: INCOMPLETED
                    }))
                } else  {
                    trackingSystemState !== COMPLETED && dispatch(setNavState({
                        [TRACKING_SYSTEM]: COMPLETED
                    }))
                }
            } else {
                trackingSystemState !== COMPLETED && dispatch(setNavState({
                    [TRACKING_SYSTEM]: COMPLETED
                }))
            }
        } else {
            trackingSystemState !== DISABLED && dispatch(setNavState({
                [TRACKING_SYSTEM]: DISABLED
            }))
        }

    }, [
        dispatch,
        offerInfoState,
        offer.destination_url,
        offer.trackingSystem,
        validation.validateOfferTrackingUrl
    ])

    //LAND AND TRACK
    useEffect(() => {
        if(trackingSystemState === COMPLETED && offerInfoState === COMPLETED){
            if(offer.detailed_pricing_model !== CPI && offer.detailed_pricing_model !== CPE && offer.detailed_pricing_model !== CPC){
                if(
                    offer.preview_url === '' ||
                    (offer.direct_linking === null && redirectlessFeatureFlag) ||
                    (offer.legacy_tracking === null && redirectlessFeatureFlag) ||
                    validation.validateLandingPageUrl !== undefined
                ){
                    landAndTrackState !== INCOMPLETED && dispatch(setNavState({
                        [LAND_AND_TRACK]: INCOMPLETED
                    }))
                } else {
                    landAndTrackState !== COMPLETED && dispatch(setNavState({
                        [LAND_AND_TRACK]: COMPLETED
                    }))
                }
            } else {
                if(
                    offer.preview_url === '' ||
                    validation.validateAppOrGoogleStoreUrl !== undefined ||
                    validation.validateLandingPageUrl !== undefined
                ){
                    landAndTrackState !== INCOMPLETED && dispatch(setNavState({
                        [LAND_AND_TRACK]: INCOMPLETED
                    }))
                }else {
                    landAndTrackState !== COMPLETED && dispatch(setNavState({
                        [LAND_AND_TRACK]: COMPLETED
                    }))
                }
            }
        } else {
            landAndTrackState !== DISABLED && dispatch(setNavState({
                [LAND_AND_TRACK]: DISABLED
            }))
        }
    }, [
        dispatch,
        offer.direct_linking,
        redirectlessFeatureFlag,
        offer.legacy_tracking,
        offer.preview_url,
        offer.trackingSystem,
        offer.detailed_pricing_model,
        trackingSystemState,
        offerInfoState,
        validation.validateLandingPageUrl,
        validation.validateAppOrGoogleStoreUrl
    ])

    //OUTCOME FUNNEL
    useEffect(() => {
        //CALLS
        if(offer.detailed_pricing_model === PPC){
            if(offerInfoState === COMPLETED){

                if(offer.is_revshare){
                    if(offer.revshare.some(rev => rev.value === '')){
                        outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                            [OUTCOME_FUNNEL]: INCOMPLETED
                        }))
                    } else {
                        outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                            [OUTCOME_FUNNEL]: COMPLETED
                        }))
                    }
                } else {
                    if(offer.cpa === null || offer.cpa === '' || offer.cpa === undefined){
                        outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                            [OUTCOME_FUNNEL]: INCOMPLETED
                        }))
                    } else {
                        outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                            [OUTCOME_FUNNEL]: COMPLETED
                        }))
                    }
                }
            } else {
                outcomeFunnelState !== DISABLED && dispatch(setNavState({
                    [OUTCOME_FUNNEL]: DISABLED
                }))
            }


        }

        //CLICKS
        if(offer.detailed_pricing_model === CPC){

                if(
                    landAndTrackState === COMPLETED &&
                    offerInfoState === COMPLETED &&
                    trackingSystemState === COMPLETED
                ){
                    if(offer.trackingSystem !==GOOGLE_ANALYTICS) {
                        if (
                            offer.max_bid === null ||
                            offer.max_bid === undefined ||
                            offer.max_bid === '' ||
                            offer.upsells.some(upsell => upsell.name === '')
                        ) {
                            outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: INCOMPLETED
                            }))
                        } else {
                            outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: COMPLETED
                            }))
                        }
                    } else {
                        if(
                            offer.max_bid === null ||
                            offer.max_bid === undefined ||
                            offer.max_bid === ''
                        ) {
                            outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: INCOMPLETED
                            }))
                        } else if (
                            cpcMainConversionPanelVisibility &&
                            (
                                redirectlessFeatureFlag && (
                                    offer.campaign_tracking_settings.conversion_page_url === null ||
                                    offer.campaign_tracking_settings.conversion_page_url === '' ||
                                    validation.validateConversionPage !== undefined
                                )
                            )
                        ) {
                            outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: INCOMPLETED
                            }))

                        } else if (
                            offer.upsells.length > 0 &&
                            offer.upsells.some(upsell => (
                                (upsell.upsell_tracking_settings === undefined && redirectlessFeatureFlag) ||
                                (upsell.upsell_tracking_settings.conversion_page_url === undefined && redirectlessFeatureFlag) ||
                                (upsell.upsell_tracking_settings.conversion_page_url === '' && redirectlessFeatureFlag) ||
                                upsell.name === ''
                            ))
                        ) {
                            outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: INCOMPLETED
                            }))
                        } else {
                            outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                [OUTCOME_FUNNEL]: COMPLETED
                            }))
                        }
                    }
                } else {
                    outcomeFunnelState !== DISABLED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: DISABLED
                    }))
                }

        }

        //APPS
        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE){
            if(
                landAndTrackState === COMPLETED &&
                offerInfoState === COMPLETED &&
                trackingSystemState === COMPLETED
            ){
                if(offer.cpa === null || offer.cpa === undefined || offer.cpa === ''){
                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                } else if(
                    offer.upsells.length > 0 &&
                    offer.upsells.some(upsell => (
                        upsell.name === '' ||
                        upsell.payout === '' ||
                        upsell.payout === null ||
                        (upsell.upsell_tracking_settings === undefined && redirectlessFeatureFlag) ||
                        (offer.trackingSystem !== BRANCH && upsell.upsell_tracking_settings?.event_tracking_goal_id && redirectlessFeatureFlag) === undefined ||
                        (offer.trackingSystem !== BRANCH && upsell.upsell_tracking_settings?.event_tracking_goal_id  && redirectlessFeatureFlag) === ''
                    ))
                ){
                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                } else {
                    outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: COMPLETED
                    }))
                }

            } else {
                outcomeFunnelState !== DISABLED && dispatch(setNavState({
                    [OUTCOME_FUNNEL]: DISABLED
                }))
            }
        }

        //SALES AND LEADS
        if(offer.detailed_pricing_model === CPS || offer.detailed_pricing_model  === CPL){
            if(
                landAndTrackState === COMPLETED &&
                offerInfoState === COMPLETED &&
                trackingSystemState === COMPLETED
            ){

                const areEventsPayoutValid = (): boolean => {
                    let valid = true
                    if(offer.upsells.length > 0){
                        offer.upsells.forEach(upsell => {
                            if(!upsell.is_revshare){
                                if(upsell.payout === null || upsell.payout === '' || upsell.payout === undefined){
                                    valid = false
                                }
                            } else {

                                upsell.revshare.forEach((rev: any) => {
                                    if(rev.value === '' || rev.value === undefined){
                                        valid = false
                                    }
                                })
                            }
                        })
                    }

                    return valid
                }



                if(!offer.is_revshare && (isNullOrUndefined(offer.cpa) || offer.cpa === '')){

                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                } else if (offer.is_revshare && offer.revshare.some(rev => rev.value === '')) {

                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                } else if(!areEventsPayoutValid()){

                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                }else if (offer.upsells.some(upsell => {

                    return upsell.name === ''
                })) {

                    outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: INCOMPLETED
                    }))
                } else if(redirectlessFeatureFlag) {

                    if(!offer.direct_linking) {
                        if(offer.legacy_tracking){
                            if(offer.trackingSystem !==GOOGLE_ANALYTICS){
                                outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: COMPLETED
                                }))
                            }
                        } else {
                            if(offer.upsells.some(upsell => {
                                return upsell.upsell_tracking_settings === undefined ||
                                    upsell.upsell_tracking_settings.conversion_page_url === undefined ||
                                    upsell.upsell_tracking_settings.conversion_page_url === ''
                            }) || offer.campaign_tracking_settings.conversion_page_url === undefined ||
                                offer.campaign_tracking_settings.conversion_page_url === ''){
                                outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: INCOMPLETED
                                }))
                            } else {
                                outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: COMPLETED
                                }))
                            }
                        }
                    } else {
                        if(offer.trackingSystem !==GOOGLE_ANALYTICS && offer.trackingSystem !== NONE) {
                            if(offer.upsells.some(upsell => {
                                    return upsell.name === '' ||
                                        upsell.upsell_tracking_settings === undefined ||
                                        upsell.upsell_tracking_settings.conversion_page_url === undefined ||
                                        upsell.upsell_tracking_settings.conversion_page_url === '' ||
                                        upsell.upsell_tracking_settings.conversion_tracking_url === undefined ||
                                        upsell.upsell_tracking_settings.conversion_tracking_url === ''
                                }) ||
                                offer.campaign_tracking_settings.conversion_page_url === undefined ||
                                offer.campaign_tracking_settings.conversion_page_url === '' ||
                                offer.campaign_tracking_settings.conversion_tracking_url === undefined ||
                                offer.campaign_tracking_settings.conversion_tracking_url === ''
                            ){
                                outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: INCOMPLETED
                                }))
                            } else {
                                outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: COMPLETED
                                }))
                            }
                        } else {
                            if(offer.upsells.some(upsell => {
                                    return upsell.name === '' ||
                                        upsell.upsell_tracking_settings === undefined ||
                                        upsell.upsell_tracking_settings.conversion_page_url === undefined ||
                                        upsell.upsell_tracking_settings.conversion_page_url === ''
                                }) || offer.campaign_tracking_settings.conversion_page_url === undefined ||
                                offer.campaign_tracking_settings.conversion_page_url === ''){
                                outcomeFunnelState !== INCOMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: INCOMPLETED
                                }))
                            } else {
                                outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                                    [OUTCOME_FUNNEL]: COMPLETED
                                }))
                            }
                        }
                    }
                } else {
                    outcomeFunnelState !== COMPLETED && dispatch(setNavState({
                        [OUTCOME_FUNNEL]: COMPLETED
                    }))
                }

            } else {
                outcomeFunnelState !== DISABLED && dispatch(setNavState({
                    [OUTCOME_FUNNEL]: DISABLED
                }))
            }
        }

        // if(offer.type !== 'calls'){
        //     if(
        //         outcomeFunnelState !== INCOMPLETED &&
        //         landAndTrackState === COMPLETED &&
        //         offerInfoState === COMPLETED &&
        //         trackingSystemState === COMPLETED
        //     ) {
        //         dispatch(setNavState({
        //             [OUTCOME_FUNNEL]: INCOMPLETED
        //         }))
        //     } else {
        //         outcomeFunnelState !== DISABLED && dispatch(setNavState({
        //             [OUTCOME_FUNNEL]: DISABLED
        //         }))
        //     }
        // } else {
        //
        // }
    }, [
        offer.direct_linking,
        offer.legacy_tracking,
        redirectlessFeatureFlag,
        dispatch,
        landAndTrackState,
        offer.type,
        offerInfoState,
        trackingSystemState,
        offer.cpa,
        offer.is_revshare,
        offer.revshare,
        offer.trackingSystem,
        offer.upsells,
        offer.max_bid,
        offer.campaign_tracking_settings,
        cpcMainConversionPanelVisibility,
        validation,
        offer.detailed_pricing_model
    ])

    //TRACKING CODES
    useEffect(() => {
        if(
            landAndTrackState === COMPLETED &&
            offerInfoState === COMPLETED &&
            trackingSystemState === COMPLETED
        ) {
            if(offer.detailed_pricing_model === CPS|| offer.detailed_pricing_model === CPL){
                if(offer.tracking_code_placed) {
                    trackingCodesState !== COMPLETED && dispatch(setNavState({
                        [TRACKING_CODE]: COMPLETED
                    }))
                } else {
                    trackingCodesState !== INCOMPLETED && dispatch(setNavState({
                        [TRACKING_CODE]: INCOMPLETED
                    }))
                }
            } else {
                if(offer.tracking_code_placed) {
                    trackingCodesState !== ADJUSTED && dispatch(setNavState({
                        [TRACKING_CODE]: ADJUSTED
                    }))
                } else {
                    trackingCodesState !== INCOMPLETED && dispatch(setNavState({
                        [TRACKING_CODE]: INCOMPLETED
                    }))
                }
            }
        } else {
            trackingCodesState !== DISABLED && dispatch(setNavState({
                [TRACKING_CODE]: DISABLED
            }))
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.tracking_code_placed,
        offer.type,
        offerInfoState,
        trackingSystemState
    ])


    //TESTING
    useEffect(() => {
        if(
            landAndTrackState === COMPLETED &&
            offerInfoState === COMPLETED &&
            trackingSystemState === COMPLETED
        ) {
            if(offer.detailed_pricing_model !== PPC && offer.detailed_pricing_model !== CPC ){
                if(offer.testing !== '') {
                    testingState !== COMPLETED && dispatch(setNavState({
                        [TESTING]: COMPLETED
                    }))
                } else {
                    testingState !== INCOMPLETED && dispatch(setNavState({
                        [TESTING]: INCOMPLETED
                    }))
                }
            } else {
                if(offer.testing !== '') {
                    testingState !== ADJUSTED && dispatch(setNavState({
                        [TESTING]: ADJUSTED
                    }))
                } else {
                    testingState !== INCOMPLETED && dispatch(setNavState({
                        [TESTING]: INCOMPLETED
                    }))
                }
            }
        } else {
            testingState !== DISABLED && dispatch(setNavState({
                [TESTING]: DISABLED
            }))
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.testing,
        offer.type,
        offerInfoState,
        trackingSystemState
    ])

    //CREATIVES AND EMAIL
    useEffect(() => {
        if(offer.detailed_pricing_model !== PPC){
            if(
                landAndTrackState === COMPLETED &&
                offerInfoState === COMPLETED &&
                trackingSystemState === COMPLETED
            ) {
                if(
                    offer.creatives.length > 0 ||
                    offer.is_email ||
                    (offer.campaign_email_setting.from_lines !== '' && offer.campaign_email_setting.from_lines !== null && offer.campaign_email_setting.from_lines !== undefined) ||
                    (offer.campaign_email_setting.subject_lines !== '' && offer.campaign_email_setting.subject_lines !== null && offer.campaign_email_setting.subject_lines !== undefined) ||
                    (offer.campaign_email_setting.supression_list_value1 !== '' && offer.campaign_email_setting.supression_list_value1 !== null && offer.campaign_email_setting.supression_list_value1 !== undefined)
                ) {
                    creativesState !== ADJUSTED && dispatch(setNavState({
                        [CREATIVES]: ADJUSTED
                    }))
                } else {
                    creativesState !== INCOMPLETED && dispatch(setNavState({
                        [CREATIVES]: INCOMPLETED
                    }))
                }

            } else {
                creativesState !== DISABLED && dispatch(setNavState({
                    [CREATIVES]: DISABLED
                }))
            }
        } else {
            if(offerInfoState === COMPLETED){
                if(
                    offer.creatives.length > 0
                ) {
                    creativesState !== ADJUSTED && dispatch(setNavState({
                        [CREATIVES]: ADJUSTED
                    }))
                } else {
                    creativesState !== INCOMPLETED && dispatch(setNavState({
                        [CREATIVES]: INCOMPLETED
                    }))
                }
            } else {
                creativesState !== DISABLED && dispatch(setNavState({
                    [CREATIVES]: DISABLED
                }))
            }
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.campaign_email_setting.from_lines,
        offer.campaign_email_setting.subject_lines,
        offer.campaign_email_setting.supression_list_value1,
        offer.creatives.length,
        offer.is_email,
        offerInfoState,
        trackingSystemState
    ])

    //BUDGET
    useEffect(() => {
        if(offer.detailed_pricing_model !== PPC){
            if(
                landAndTrackState === COMPLETED &&
                offerInfoState === COMPLETED &&
                trackingSystemState === COMPLETED
            ) {
                if(
                    offer.budget_group !== null ||
                    offer.budget_type !== 'unlimited' ||
                    offer.link_test_budget_type?.toLowerCase() !== DISABLED
                ) {
                    budgetState !== ADJUSTED && dispatch(setNavState({
                        [BUDGET]: ADJUSTED
                    }))
                } else {
                    budgetState !== INCOMPLETED && dispatch(setNavState({
                        [BUDGET]: INCOMPLETED
                    }))
                }
            } else {
                budgetState !== DISABLED && dispatch(setNavState({
                    [BUDGET]: DISABLED
                }))
            }
        } else {
            if(offerInfoState === COMPLETED){
                if(
                    offer.budget_group !== null ||
                    offer.budget_type !== 'unlimited' ||
                    offer.link_test_budget_type?.toLowerCase() !== DISABLED
                ) {
                    budgetState !== ADJUSTED && dispatch(setNavState({
                        [BUDGET]: ADJUSTED
                    }))
                } else {
                    budgetState !== INCOMPLETED && dispatch(setNavState({
                        [BUDGET]: INCOMPLETED
                    }))
                }
            } else {
                budgetState !== DISABLED && dispatch(setNavState({
                    [BUDGET]: DISABLED
                }))
            }
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.budget,
        offer.budget_type,
        offer.link_test_budget_type,
        offerInfoState,
        trackingSystemState,
        offer.budget_group
    ])

    const checkOsTargeting = () => {
        if(offer.operating_system_id !== null){
            if(offer.operating_system_id === 2) {
                return Object.keys(offer.os_targeting.tablet).some(key => key !== IOS ? offer.os_targeting.tablet[key] === 1 : offer.os_targeting.tablet[key] === 0) ||
                    Object.keys(offer.os_targeting.mobile).some(key =>  key !== IOS ? offer.os_targeting.mobile[key] === 1 : offer.os_targeting.mobile[key] === 0) ||
                    offer.desktop
            } else {
                return Object.keys(offer.os_targeting.tablet).some(key => key !== ANDROID ? offer.os_targeting.tablet[key] === 1 : offer.os_targeting.tablet[key] === 0) ||
                    Object.keys(offer.os_targeting.mobile).some(key =>  key !== ANDROID ? offer.os_targeting.mobile[key] === 1 : offer.os_targeting.mobile[key] === 0) ||
                    offer.desktop
            }
        } else {
            return  Object.keys(offer.os_targeting.tablet).some(key => offer.os_targeting.tablet[key] === 0) ||
                Object.keys(offer.os_targeting.mobile).some(key => offer.os_targeting.mobile[key] === 0)
        }
    }

    //TARGETING
    useEffect(() => {
        if(
            landAndTrackState === COMPLETED &&
            offerInfoState === COMPLETED &&
            trackingSystemState === COMPLETED
        ) {
            if(
                offer.selected_cities.length > 0 ||
                offer.selected_isp.length > 0 ||
                offer.selected_zipcodes.length > 0 ||
                offer.excluded_locations.length > 0 ||
                offer.targeted_locations.length > 0 ||
                // !offer.desktop ||
                !offer.mobile ||
                !offer.tablet ||
                checkOsTargeting() ||
                offer.traffic_types?.filter(type => type.name !== 'Email').some(type => type.selected === false)
            ) {
                targetingState !== ADJUSTED && dispatch(setNavState({
                    [TARGETING]: ADJUSTED
                }))
            }else {
                targetingState !== INCOMPLETED && dispatch(setNavState({
                    [TARGETING]: INCOMPLETED
                }))
            }
        } else {
            targetingState !== DISABLED && dispatch(setNavState({
                [TARGETING]: DISABLED
            }))
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.desktop,
        offer.excluded_locations.length,
        offer.mobile,
        offer.os_targeting.mobile,
        offer.os_targeting.tablet,
        offer.selected_cities.length,
        offer.selected_isp.length,
        offer.selected_zipcodes.length,
        offer.tablet,
        offer.targeted_locations.length,
        offer.traffic_types,
        offerInfoState,
        trackingSystemState,
        offer.operating_system_id
    ])

    //SCHEDULING
    useEffect(() => {
        if(
            landAndTrackState === COMPLETED &&
            offerInfoState === COMPLETED &&
            trackingSystemState === COMPLETED
        ) {
            if(
                offer.campaign_schedule.length > 0 ||
                (offer.start_date_option !== 0 && offer.start_date !== '') ||
                (offer.end_date_option !== 0 && offer.end_date !== '')
            ) {
                schedulingState !== ADJUSTED && dispatch(setNavState({
                    [SCHEDULING]: ADJUSTED
                }))
            } else {
                schedulingState !== INCOMPLETED && dispatch(setNavState({
                    [SCHEDULING]: INCOMPLETED
                }))
            }
        } else {
            schedulingState !== DISABLED && dispatch(setNavState({
                [SCHEDULING]: DISABLED
            }))
        }
    }, [
        dispatch,
        landAndTrackState,
        offer.campaign_schedule.length,
        offer.end_date_option,
        offer.start_date_option,
        offer.start_date,
        offer.end_date,
        offerInfoState,
        trackingSystemState
    ])
}
