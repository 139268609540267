import { useState } from "react";

export const useWidth = () => {
    const [width, setWidth] = useState(window.outerWidth);

    window.addEventListener('resize', ()=>{
        setWidth(window.outerWidth)
    })

    return width;
}