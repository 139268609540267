import { allPaginationColumnsList } from './table/paginationColumnsList';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IInitialState  {
    sort: {
        direction: 'desc' | 'asc',
        by: string
    },
    pagination: {
        page: number,
        per_page: number,
        from: number,
        to: number,
        last_page: number,
        total: number
    },
    paginationColumnsList: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]
}

const initialState: IInitialState = {
    sort: {
        direction: "desc",
        by: 'pause_risk'
    },
    pagination: {
        page: 1,
        per_page: 50,
        from: 1,
        to: 1,
        last_page: 1,
        total: 1
    },
    paginationColumnsList: allPaginationColumnsList
}

const marketerFundingMonitoringSlice = createSlice({
    name: 'marketerFundingMonitoring',
    initialState,
    reducers: {
        setSort: (state: IInitialState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.sort = action.payload
        },
        setPage: (state: IInitialState, action:PayloadAction<number>) => {
            state.pagination.page = action.payload;
        },
        setPerPage: (state: IInitialState, action:PayloadAction<number>) => {
            state.pagination.per_page = action.payload;
        },
        setPagination: (state: IInitialState, action:PayloadAction<{
            from: number,
            to: number,
            last_page: number,
            total: number
        }>) => {
            state.pagination.from = action.payload.from;
            state.pagination.to = action.payload.to;
            state.pagination.last_page = action.payload.last_page;
            state.pagination.total = action.payload.total
        },
        setPaginationColumnsList: (state: IInitialState, action:PayloadAction<{name: string,
            id: number,
            checked: boolean,
            column_key?: string}[]>) => {
            state.paginationColumnsList = action.payload;
        },
    }
})
export const {
    setSort,
    setPage,
    setPerPage,
    setPagination,
    setPaginationColumnsList
} = marketerFundingMonitoringSlice.actions

export default marketerFundingMonitoringSlice.reducer
