import styled from 'styled-components'

import {DatePickerWrapper} from "../date-picker/DatePicker.style";

export const RangeDatePickerWrapper = styled(DatePickerWrapper)`
  display: flex;
  gap: 22px;
  .react-datepicker-popper{
    position: static !important;
    transform: translate(0, 0) !important;
  }

  .react-datepicker__day, 

  .react-datepicker__month-container, .react-datepicker__year--container{
    box-shadow: none !important;
    border: none !important;
    background-color: inherit;
  }
  
  .react-datepicker__day--in-range{
    background-color: ${({theme}) => theme.colors.performBlue[1]} !important;
    border-radius: 0 !important;
  }
  
  
  .react-datepicker__day--range-end{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

  }
  .react-datepicker__day--range-start{
    //border-top-left-radius: 4px !important;
    //border-bottom-left-radius: 4px !important;
  }
  .react-datepicker__day--selected{
      border-radius: 4px !important;
    background-color: ${({theme}) => theme.colors.performBlue[5]} !important;
  }

  .react-datepicker__month--in-range{
    background-color: inherit !important;
  }

  .react-datepicker__month--selected{
    background-color: ${({theme}) => theme.colors.performBlue[5]} !important;
  }
  
  .react-datepicker__month-container, .react-datepicker__year--container{
    padding: 0 !important;
  }

  @media screen and (max-width: 699px) {
     flex-direction: column;
     gap: 15px;
  }
`