import styled, { css } from "styled-components";

export const LeapAccountTypeWrapper = styled.div<{$isChild: boolean}>`
    ${({$isChild}) => $isChild && css `
        display: flex;
        gap: 10px;
        & > div {
            width: calc(50% - 10px);
        }
    `}
`;

export const DomainInputPills = styled.div`
    margin-top: 11px;
`;