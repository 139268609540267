import styled from "styled-components";

export const StyledFooter = styled.div`
    z-index: 1;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    padding: 16px 40px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    border-top: 1px solid #E7ECEE;
    .btn-orange-outlined{
        span{
            display: flex;
            align-items: center;
        }
    }
    .btn-discover-results{
        margin-left: 8px;
        display: initial;
    }

   
`

export const NotificationsModalContent = styled.div`
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    font-size: 14px;
    text-align: left;
    .notifications{
        margin-bottom: 16px;
        div{
            margin-bottom: 8px;

        }
        li{
            list-style: inside;
            margin-left: 12px;
        }
    }
`
