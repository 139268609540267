import styled from "styled-components";

export const AddTransactionWrapper = styled.div`
    width: 500px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    .amount-wrapper{
        margin-bottom: 0;
    }

    @media screen and (max-width: 900px){
        width: 400px;
    }

    @media screen and (max-width: 520px){
        width: 320px;
    }
`;