export const allPaginationColumnsList:{
    name: string,
    id: number,
    checked: boolean,
    column_key?: string}[] = [
        {
            name: "Marketer ID",
            id: 1,
            checked: true,
            column_key: "id"
        },
        {
            name: "Marketer Company Name",
            id: 2,
            checked: true,
            column_key: "company"
        },
        {
            name: "Marketer Name",
            id: 3,
            checked: true,
            column_key: "name"
        },
        {
            name: "Engine Growth Manager",
            id: 4,
            checked: true,
            column_key: "account_manager_name"
        },
        {
            name: "Pause Risk",
            id: 5,
            checked: true,
            column_key: "pause_risk"
        },
        {
            name: "Marketer Total Revenue",
            id: 6,
            checked: true,
            column_key: "marketer_total_revenue"
        },
        {
            name: "Funding Avaible",
            id: 7,
            checked: true,
            column_key: "balance"
        },
        {
            name: "Funding Days Remaining",
            id: 8,
            checked: true,
            column_key: "funding_days_remaining"
        },
        {
            name: "Funding & Stay Live Days Remaining",
            id: 9,
            checked: true,
            column_key: "funding_stay_live_days_remaining"
        },
        {
            name: "Auto-Funding",
            id: 10,
            checked: true,
            column_key: "auto_funding"
        },
        {
            name: "Credit Deposit",
            id: 11,
            checked: true,
            column_key: "intacct_credit_deposit"
        },
        {
            name: "Credit Limit",
            id: 12,
            checked: true,
            column_key: "intacct_credit_limit"
        },
        {
            name: "Stay Live Limit",
            id: 13,
            checked: true,
            column_key: "credit"
        },
        {
            name: "Total Credit Limit",
            id: 14,
            checked: true,
            column_key: "total_credit_limit"
        },
        {
            name: "Credit Used",
            id: 15,
            checked: true,
            column_key: "credit_used"
        },
        {
            name: "Recent Invoices",
            id: 16,
            checked: true,
            column_key: "intacct_invoices"
        },
        {
            name: "Days Until Payment Due",
            id: 17,
            checked: true,
            column_key: "days_until_payment"
        },
        {
            name: "Invoicing Cycle",
            id: 18,
            checked: true,
            column_key: "intacct_invoicing_cycle"
        },
        {
            name: "Payment Terms",
            id: 19,
            checked: true,
            column_key: "intacct_payment_term"
        },
        {
            name: "Billing Notes",
            id: 21,
            checked: true,
            column_key: "billing_notes"
        },
        {
            name: "Latest Reassessment Status",
            id: 23,
            checked: true,
            column_key: "credit_request_status"
        },
        {
            name: "Vertical",
            id: 20,
            checked: true,
            column_key: "niche_id"
        },
        {
            name: "pinned",
            id: 22,
            checked: true,
            column_key: "pinned"
        },
    ]