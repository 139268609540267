import axios, {AxiosResponse} from "axios";
import {api} from "./api";

class TagsListService {
    static fetchSelectedTags = async (token: string,
                              id: number | undefined,
                              type: string | undefined): Promise<AxiosResponse> => {
        try {
            return await api.get([process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_ASSIGN_TAGS].join("/"),
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'property_id': id,
                        'type': type
                    }
                }
            );
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
    static fetchAllTags = async (token: string): Promise<AxiosResponse> => {
        try {
            return await api.get([process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_TAGS].join("/"),
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'paginate_by': 99990
                    }
                }
            );
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }

    static removeTag = async (token: string,
                              id: number | undefined,
                              type: string | undefined,
                              tag_id: number): Promise<AxiosResponse> => {
        try{
            return await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_ASSIGN_TAGS,
                "[object%20Object]"].join("/"),
                {
                    "_method": "DELETE"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        'property_id': id,
                        'type': type,
                        'tag_id': tag_id
                    }
                }
            );
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
    static addTag = async (token: string,
                              ids: number[] | undefined,
                              type: string | undefined,
                              tag_ids: number[]): Promise<AxiosResponse> => {
        try{
            return await api.post([process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_ASSIGN_TAGS].join("/"),
                {
                    "property_ids": ids,
                    "tag_ids": tag_ids,
                    "type" : type
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e: any) {
            if (axios.isAxiosError(e)) {
                console.log(e.message, "axios error");
            }
            return e.message;
        }
    }
}

export default TagsListService