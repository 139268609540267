import {useAppSelector} from "../../../../../common/hooks";
import {useCallback} from "react";
import {CPE, CPI} from "../interfaces/interfaces";


export const useTestingLink = () => {
    const {
        config
    } = useAppSelector(state => state.auth)
    
    return useCallback((pricing_model : string, offer_id : number, url :string) => {
        return config.network_configs[(pricing_model === CPI || pricing_model === CPE) ? 2 : 1].testPubLink +
            offer_id + "&rurl=" +  encodeURIComponent(url.slice(0,4) !== "http" ? "http://" + url :  url)
        
    },[])
}