import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 40px 40px 80px 40px;
    background-color: white;
    
    //.summarize-checkbox-label{
    //    width: fit-content;
    //    font-size: 15px;
    //}
    //
    //.summarize-checkbox{
    //    width: 15px;
    //    height: 15px;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    i{
    //        position: relative;
    //        transform: translate(-1px, -1px);
    //    }
    //}
`

export const TopSection = styled.div`
    display: flex;
    gap: 8px;
    .actions-header {
        min-height: 32px;
        height: 32px;
    }

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 15px;

        > div:last-child {
            display: flex;
        }

        > button:last-child {
            display: flex;
        }
    }
`

export const FiltersPaginationSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
    
    .btn-blue-outlined {
        width: 96px;
    }

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 12px;
    }
`;

export const BottomSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
`
