import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IInitialState {
    per_page: number,
    leap_partners_sort: {
        direction: 'asc' | 'desc',
        by: string
    },
    searchValue: string
}

const initialState: IInitialState = {
    per_page: 50,
    leap_partners_sort: {
        direction: 'desc',
        by: 'created_at'
    },
    searchValue: ""
}

const leapPartnersSlice = createSlice({
    name: 'leapPartnersSlice',
    initialState,
    reducers: {
        setPerPage: (state: IInitialState, action:PayloadAction<number>) => {
            state.per_page = action.payload
        },
        setLeapPartnersSort: (state: IInitialState, action:PayloadAction<{direction: 'asc' | 'desc', by: string}>) => {
            state.leap_partners_sort = action.payload
        },
        setSearchValue: (state: IInitialState, action:PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
    }
})
export const {setPerPage, setLeapPartnersSort, setSearchValue} = leapPartnersSlice.actions
export default leapPartnersSlice.reducer