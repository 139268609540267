import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {
    fetchAccountBalance,
    fetchAllNiches,
    fetchBudgetGroups,
    fetchCustomDomains,
    fetchEmptyOffer,
    fetchGeoData,
    fetchIsps,
    fetchOffer,
    fetchPixelGroups,
    fetchTrackingSystems,
    fetchTrafficGroups,
    setAppsSelected,
    setInitialEditOffer,
    setLastSaved,
    setOffer,
    setSaveDraftOffer
} from "../create-offer.slice";
import CreateOfferService from "../CreateOffer.service";
import {
    ADJUST,
    ANDROID,
    APPSFLYER,
    BRANCH,
    BUDGET,
    CPA,
    CPC,
    CPE,
    CPI,
    CPS,
    CREATIVES,
    GOOGLE_ANALYTICS,
    IDelayedActionsSections,
    IDelayedActionsType,
    IOS,
    KOCHAVA,
    LAND_AND_TRACK,
    NONE,
    OFFER_INFO,
    OUTCOME_FUNNEL,
    SCHEDULING,
    SINGULAR,
    TARGETING,
    TESTING,
    TRACKING_CODE,
    TRACKING_SYSTEM
} from "../interfaces/interfaces";
import {
    AdjustEventMacro,
    AppsFlyerEventMacro,
    BranchEventMacro,
    FIELD_NAME_ADVANCED_BUDGET,
    FIELD_NAME_ADVANCED_SCHEDULING,
    FIELD_NAME_BRAND_NAME,
    FIELD_NAME_BUDGET,
    FIELD_NAME_BUDGET_GROUP_ID,
    FIELD_NAME_CITY,
    FIELD_NAME_CPA,
    FIELD_NAME_CREATIVE,
    FIELD_NAME_CREATIVE_NAME,
    FIELD_NAME_CREATIVE_TEXT,
    FIELD_NAME_CREATIVE_TYPE,
    FIELD_NAME_DESCRIPTION,
    FIELD_NAME_DEVICE_OS_ENABLED,
    FIELD_NAME_DEVICE_TYPE_DESKTOP,
    FIELD_NAME_DEVICE_TYPE_MOBILE,
    FIELD_NAME_DEVICE_TYPE_TABLET,
    FIELD_NAME_ISP,
    FIELD_NAME_LOCATIONS,
    FIELD_NAME_MAX_BID,
    FIELD_NAME_NICHE,
    FIELD_NAME_PAYOUT,
    FIELD_NAME_PAYOUT_REVSHARE,
    FIELD_NAME_TRAFFIC_TYPE,
    FIELD_NAME_UPSELL,
    FIELD_NAME_ZIP,
    KochavaEventMacro,
    SingularEventMacro
} from "../shared-components/consts";
import {isNullOrUndefined} from "../../../../../common/utils";
import {useTestingLink} from "./useTestingLink";

export const useInitialDataFetch = (id?: string, replicate?: boolean, draft?: boolean) => {

    const {offer} = useAppSelector(state => state.createOffer)
    const {token, config} = useAppSelector(state => state.auth)

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    
    const getTestingLink = useTestingLink()
    
    const parseDelayedActions = ( groupedActions : IDelayedActionsType) => {
        let delayedActions : IDelayedActionsSections = {
            [OFFER_INFO] : { compliance_delayed: [], time_delayed :[]},
            [TARGETING] : { compliance_delayed: [], time_delayed :[]},
            [TRACKING_SYSTEM] :  { compliance_delayed: [], time_delayed :[]},
            [LAND_AND_TRACK] :  { compliance_delayed: [], time_delayed :[]},
            [OUTCOME_FUNNEL] :  { compliance_delayed: [], time_delayed :[]},
            [TRACKING_CODE] :  { compliance_delayed: [], time_delayed :[]},
            [TESTING] :  { compliance_delayed: [], time_delayed :[]},
            [CREATIVES] :  { compliance_delayed: [], time_delayed :[]},
            [BUDGET] :  { compliance_delayed: [], time_delayed :[]},
            [SCHEDULING] :  { compliance_delayed: [], time_delayed :[]},
        }
        groupedActions.compliance_delayed?.forEach((action, index) => {
            switch (action.field_name){
                case FIELD_NAME_BRAND_NAME:
                    delayedActions[OFFER_INFO]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_LOCATIONS:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CPA:
                    delayedActions[OUTCOME_FUNNEL]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DEVICE_TYPE_TABLET:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case  FIELD_NAME_DEVICE_TYPE_MOBILE:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case  FIELD_NAME_DEVICE_TYPE_DESKTOP:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ADVANCED_SCHEDULING:
                    delayedActions[SCHEDULING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_TRAFFIC_TYPE:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DEVICE_OS_ENABLED:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_PAYOUT:
                    delayedActions[OUTCOME_FUNNEL]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_MAX_BID:
                    delayedActions[OUTCOME_FUNNEL]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_PAYOUT_REVSHARE:
                    delayedActions[OUTCOME_FUNNEL]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_BUDGET:
                    delayedActions[BUDGET]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ADVANCED_BUDGET:
                    delayedActions[BUDGET]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_BUDGET_GROUP_ID:
                    delayedActions[BUDGET]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DESCRIPTION:
                    delayedActions[OFFER_INFO]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_NICHE:
                    delayedActions[OFFER_INFO]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                    case FIELD_NAME_CITY:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ZIP:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ISP:
                    delayedActions[TARGETING]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_UPSELL:
                    delayedActions[OUTCOME_FUNNEL]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_NAME:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_TEXT:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_TYPE:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
            }
        })
        groupedActions.time_delayed?.forEach((action, index) => {
            switch (action.field_name){
                case FIELD_NAME_BRAND_NAME:
                    delayedActions[OFFER_INFO]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_LOCATIONS:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CPA:
                    delayedActions[OUTCOME_FUNNEL]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DEVICE_TYPE_TABLET:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case  FIELD_NAME_DEVICE_TYPE_MOBILE:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case  FIELD_NAME_DEVICE_TYPE_DESKTOP:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_TRAFFIC_TYPE:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DEVICE_OS_ENABLED:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_PAYOUT:
                    delayedActions[OUTCOME_FUNNEL]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_MAX_BID:
                    delayedActions[OUTCOME_FUNNEL]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_PAYOUT_REVSHARE:
                    delayedActions[OUTCOME_FUNNEL]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_BUDGET:
                    delayedActions[BUDGET]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ADVANCED_BUDGET:
                    delayedActions[BUDGET]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_BUDGET_GROUP_ID:
                    delayedActions[BUDGET]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_DESCRIPTION:
                    delayedActions[OFFER_INFO]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_NICHE:
                    delayedActions[OFFER_INFO]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CITY:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ADVANCED_SCHEDULING:
                    delayedActions[SCHEDULING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ZIP:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_ISP:
                    delayedActions[TARGETING]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_UPSELL:
                    delayedActions[OUTCOME_FUNNEL]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE:
                    delayedActions[CREATIVES]?.time_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_NAME:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_TEXT:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
                case FIELD_NAME_CREATIVE_TYPE:
                    delayedActions[CREATIVES]?.compliance_delayed?.push({    field_name: action.field_name,
                        description: action.description,
                        model: action.model})
                    break;
            }
        })
        return delayedActions
    }

    const fetchDraft = async () => {
        try {
            if(id){
                const {data} = await CreateOfferService.fetchDraft(token, id)
                dispatch(setLastSaved(new Date(data.draft.created_at.replaceAll('-', '/'))))
                return data
            }
        } catch (e) {
            console.log(e)
        }

    }

    const fetchCampaign = useCallback(async () => {
        try {
            if(id) {
                if(replicate){

                    const [
                        empty,
                        edit
                    ] = await Promise.all([
                        dispatch(fetchEmptyOffer({
                            detailed_pricing_model: offer.detailed_pricing_model,
                            pricing_model: offer.pricing_model
                        })),
                        dispatch(fetchOffer(id))
                    ])

                    const [replicateCreatives, replicateUpsells] = await Promise.all([
                        CreateOfferService.replicateCreatives(token, edit.payload.id, empty.payload.id),
                        CreateOfferService.replicateUpsells(token, edit.payload.id, empty.payload.id)
                    ])
                    return {...edit.payload, upsells: replicateUpsells.data.map((upsell: any) => ({...upsell})), id: empty.payload.id}
                } else {
                    const data = await dispatch(fetchOffer(id))
                    return data.payload
                }

            } else {
                const data = await dispatch(fetchEmptyOffer({
                    detailed_pricing_model: offer.detailed_pricing_model,
                    pricing_model: offer.pricing_model
                }))
                return data.payload
            }
        } catch (e) {
            console.log(e)
        }
    }, [id, offer.detailed_pricing_model])

    const fetch = useCallback(async () => {
        setLoading(true)

       try {
            const [
                geoData,
                niches,
                trafficTypes,
                budgetGroups,
                domains,
                pixelGroups,
                currencies,
                campaignResponse
            ] = await Promise.all([
                dispatch(fetchGeoData({})),
                dispatch(fetchAllNiches({})),
                dispatch(fetchTrafficGroups({})),
                dispatch(fetchBudgetGroups({})),
                dispatch(fetchCustomDomains({})),
                dispatch(fetchPixelGroups({})),
                CreateOfferService.fetchCurrencies(token),
                draft ? fetchDraft() : fetchCampaign()
            ])

           const nicheNetworkId = niches.payload.filter((niche: any) => niche.id === campaignResponse.niche_id)[0]?.network_id

           let detailedPricingModel = ''

           if(draft) {
               detailedPricingModel = (campaignResponse.draft.detailed_pricing_model === null && offer.detailed_pricing_model === null) ?
                   nicheNetworkId === 1 ? CPS : CPI :
                   campaignResponse.draft.detailed_pricing_model ? campaignResponse.draft.detailed_pricing_model : offer.detailed_pricing_model
           } else {
               detailedPricingModel = (campaignResponse.detailed_pricing_model === null && offer.detailed_pricing_model === null) ?
                   nicheNetworkId === 1 ? CPS : CPI :
                   campaignResponse.detailed_pricing_model ? campaignResponse.detailed_pricing_model : offer.detailed_pricing_model
           }


           const [
               ispResponse,
               accountBalanceResponse,
               trackingSystems,
           ] = await Promise.all([
               dispatch(fetchIsps(draft ? campaignResponse.draft.id : campaignResponse.id)),
               dispatch(fetchAccountBalance(draft ? campaignResponse.draft.id : campaignResponse.id)),
               dispatch(fetchTrackingSystems(campaignResponse.pricing_model === CPC ? CPC : detailedPricingModel)),
           ])




           let traffic_types = [...trafficTypes.payload[0].types, ...trafficTypes.payload[1].types]
           if(campaignResponse.campaign_traffic_value){
               traffic_types = [...traffic_types.map((type: any) => {
                   if(campaignResponse.campaign_traffic_value.some((traffic: any) => traffic.traffic_type_id === type.id)){
                       return {
                           ...type,
                           selected: true
                       }
                   }
                   return {
                       ...type,
                       selected: false
                   }
               })]
           } else {
               traffic_types = traffic_types.map((type: any) => {
                   if(type.name === 'Email'){
                       return {...type, selected: false}
                   } else {
                       return {...type, selected: true}
                   }

               })
           }

           const checkIfEmailTrafficIsOn = () => {
                const emailTrafficType = traffic_types.filter((type: any) => type.name === 'Email')[0]
                if(emailTrafficType){
                    return emailTrafficType.selected
                } else {
                    return false
                }
           }

           let os_targeting = {...offer.os_targeting}

           if(campaignResponse.campaign_device_setting){
               campaignResponse.campaign_device_setting.forEach((setting: any) => {
                   const alias = config.availableOperatingSystems.filter((system: any) => {
                       return setting.device_type === system.device_type && setting.operating_system_id === system.operating_system_id
                   })[0].alias
                   os_targeting[setting.device_type as 'tablet' | 'mobile'] = {
                       ...os_targeting[setting.device_type as 'tablet' | 'mobile'],
                       [alias]: setting.enabled
                   }
               })
           } else {
               if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE){
                   if(offer.operating_system_id !== 0 || !isNullOrUndefined(offer.operating_system_id)){
                       os_targeting = {
                           tablet: {
                               android: offer.operating_system_id === 1 ? 1 : 0,
                               ios: offer.operating_system_id === 2 ? 1 : 0,
                               windows: 0,
                               mac: 0,
                               linux: 0,
                               undefined: 0
                           },
                           mobile: {
                               android: offer.operating_system_id === 1 ? 1 : 0,
                               ios: offer.operating_system_id === 2 ? 1 : 0,
                               windows: 0,
                               mac: 0,
                               linux: 0,
                               undefined: 0
                           }
                       }
                   }
               }
           }
           
           if(detailedPricingModel === CPI || detailedPricingModel === CPE) {
               dispatch(setAppsSelected(true))
           }

           let campaign_budget_group_info: unknown[] = []

           if(campaignResponse.budget_group_id){
               const {data} = await CreateOfferService.getCampaignsInfo(token, campaignResponse.budget_group_id)
               campaign_budget_group_info = data ? [...data] : []
           }
           
           const newOffer = draft ? {...campaignResponse.draft, creatives: campaignResponse.creatives} : {
               ...campaignResponse,
               impression_tracking_url: isNullOrUndefined(campaignResponse.impression_tracking_url) ? '' : campaignResponse.impression_tracking_url,
               testing: id ? 'no' : '',
               is_email: checkIfEmailTrafficIsOn(),
               legacy_tracking: !isNullOrUndefined(campaignResponse.tracking_method) ? !campaignResponse.tracking_method : null,
               detailed_pricing_model: campaignResponse.pricing_model === CPC ? CPC : detailedPricingModel,
               brand_name: campaignResponse.brand_name ? campaignResponse.brand_name : '',
               description: campaignResponse.description ? campaignResponse.description : '',
               custom_domain: campaignResponse.domain_id ? domains.payload.data.filter((domain: any) => campaignResponse.domain_id === domain.id)[0] : null,
               custom_domain_id: campaignResponse.domain_id ? domains.payload.data.filter((domain: any) => campaignResponse.domain_id === domain.id)[0].id : null,
               niche: campaignResponse.niche_id ? niches.payload.filter((niche: any) => campaignResponse.niche_id === niche.id)[0] : null,
               cpa: campaignResponse.cpa ? campaignResponse.cpa : null,
               mobile_platform: isNullOrUndefined(campaignResponse.operating_system_id) ? '' : campaignResponse.operating_system_id === 2 ? IOS : ANDROID,
               revshare: campaignResponse.campaign_revshare_setting ?
                   [...campaignResponse.campaign_revshare_setting?.settings
                       .map((revshare: any) => ({id: _.uniqueId('MAIN_REVSHARE_'), ...revshare}))] :
                   [{id: _.uniqueId('MAIN_REVSHARE_'), name: '', value: ''}],
               is_revshare: campaignResponse.campaign_revshare_setting?.is_enabled,
               upsells: campaignResponse.upsells ? [
                   ...campaignResponse.upsells?.map((upsell: any) => {
                       return {
                           ...upsell,
                           payout: upsell.payout,
                           revshare: upsell.upsell_revshare_setting ? [...upsell.upsell_revshare_setting?.settings?.map((revshare: any) => (
                               {
                                   ...revshare,
                                   id: _.uniqueId('UPSELL_REVSHARE_'),
                                   value: revshare.value
                               }))] : [],
                           is_revshare: upsell.upsell_revshare_setting !== null ? upsell.upsell_revshare_setting?.is_enabled : false,
                           pixel_group: upsell.pixel_group_id ? pixelGroups.payload.upsell.filter((group: any) => group.id === upsell.pixel_group_id)[0] : null,
                           payout_modifiers: upsell.upsell_payout_modifiers ? [...upsell.upsell_payout_modifiers] : []
                       }
                   })
               ] : [],
               pixel_group: campaignResponse.pixel_group_id ? pixelGroups.payload.offer.filter((group: any) => group.id === campaignResponse.pixel_group_id)[0] : null,
               end_date_option: campaignResponse.end_date ? 1 : 0,
               start_date_option: campaignResponse.start_date ? 1 : 0,
               pricing_model: !isNullOrUndefined(campaignResponse.pricing_model) ? campaignResponse.pricing_model : CPA,
               preview_url: campaignResponse.preview_url ? campaignResponse.preview_url : '',
               targeted_locations: [...geoData.payload.countriesState.locations.filter((location: any) => {
                   let found = false
                   campaignResponse.campaign_location?.forEach((loc: any) => {

                       if(loc.location_id === location.id && loc.status === 'target') {
                           found = true
                       }

                   })
                   return found
               })],
               excluded_locations: [...geoData.payload.countriesState.locations.filter((location: any) => {
                   let found = false
                   campaignResponse.campaign_location?.forEach((loc: any) => {
                       if(loc.location_id === location.id && loc.status === 'exclude') {
                           found = true
                       }
                   })
                   return found
               })],
               traffic_types: traffic_types,
               desktop: campaignResponse.desktop !== undefined ? campaignResponse.desktop === 1 : (!(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE)),
               mobile: campaignResponse.mobile !== undefined ? campaignResponse.mobile === 1 : true,
               tablet: campaignResponse.tablet !== undefined ? campaignResponse.tablet === 1 : true,
               os_targeting: os_targeting,
               old_cpa: id ? campaignResponse.cpa : null,
               creatives: campaignResponse.campaign_creatives ? [...campaignResponse.campaign_creatives] : [],
               additionalCreative: {
                   text_creative:  campaignResponse.campaign_creatives?.some((creative: any) => creative.name === 'text_creative') ?
                       campaignResponse.campaign_creatives.find((creative: any) => creative.name === 'text_creative').text_creative : ''
               },
               // thumbnail: thumbnailInfoResponse.data,
               payout_modifiers: campaignResponse.campaign_payout_modifiers ? [...campaignResponse.campaign_payout_modifiers] : [],
               orig_budget_value_type: campaignResponse.budget_value_type !== undefined ? campaignResponse.budget_value_type : null,
               orig_budget_group_id: campaignResponse.budget_group_id ? campaignResponse.budget_group_id : null,
               campaign_budget_group_info: [...campaign_budget_group_info],
               budget_group: campaignResponse.budget_group_id ? budgetGroups.payload.filter((group: any) => group.id === campaignResponse.budget_group_id)[0] : null,
               campaign_tracking_settings: campaignResponse.campaign_tracking_settings ? {
                   ...campaignResponse.campaign_tracking_settings,
                   parallel_tracking_enabled: campaignResponse.campaign_tracking_settings?.parallel_tracking_enabled !== null ?
                       campaignResponse.campaign_tracking_settings?.parallel_tracking_enabled :
                       false,
                   auto_populate_domain: campaignResponse.campaign_tracking_settings?.auto_populate_domain !== null ?
                       campaignResponse.campaign_tracking_settings?.auto_populate_domain :
                       true,
               } : offer.campaign_tracking_settings,
               campaign_email_setting: campaignResponse.campaign_email_setting ? {
                   ...offer.campaign_email_setting,
                   ...campaignResponse.campaign_email_setting
               } : {
                   campaign_id: campaignResponse.id,
                   unsubscribe_type: 'url',
                   unsubscribe_type_aff: 'url',
                   supression_list: 'optizmo',
                   supression_list_value1: '',
                   from_lines: '',
                   subject_lines: ''
               },
               // legacy_tracking: !isNullOrUndefined(campaignResponse.legacy_tracking) ? campaignResponse.legacy_tracking === 1 : null,
               tracking_code_placed: !!id || null,
               trackingSystem: (
                   campaignResponse.tracking_system_id === null ||
                   campaignResponse.tracking_system_id === undefined ||
                   campaignResponse.tracking_system_id === 0
               ) ?
                   null :
                   trackingSystems.payload.find((system: any) => system.tracking_system_id === campaignResponse.tracking_system_id)?.slug,
               tracking_system_id:(
                   campaignResponse.tracking_system_id === null ||
                   campaignResponse.tracking_system_id === undefined ||
                   campaignResponse.tracking_system_id === 0
               ) ?
                   null :
                   campaignResponse.tracking_system_id,
    
               delayedActions: campaignResponse._grouped_delayed_notifications ?
                   parseDelayedActions(campaignResponse._grouped_delayed_notifications) : null,
               
           }
           
           if(campaignResponse.status && campaignResponse.status !== 'empty') {
               dispatch(setLastSaved(new Date(campaignResponse.updated_at.replaceAll('-', '/'))))
           }

           if(newOffer.trackingSystem === GOOGLE_ANALYTICS || newOffer.trackingSystem === NONE){
               if(newOffer.destination_url === '' || isNullOrUndefined(newOffer.destination_url)){
                   newOffer.destination_url = newOffer.preview_url
               }

               if(newOffer.preview_url === '' || isNullOrUndefined(newOffer.preview_url)){
                   newOffer.preview_url = newOffer.destination_url
               }
           }
           
           if (campaignResponse.destination_url) {
               let dest_url_parts = campaignResponse.destination_url.split("?")
               
               newOffer.destination_url_copy = dest_url_parts[0] + '?'
               let url_macros = dest_url_parts[1]
               
               let event_macros = ""
               if (campaignResponse.upsells.length > 0 && !isNullOrUndefined(url_macros)) {
                   campaignResponse.upsells.map((upsell: any, index: number) => {
                       url_macros = newOffer.trackingSystem === APPSFLYER ? url_macros.replace(AppsFlyerEventMacro(index + 1, upsell.id), "")
                           : newOffer.trackingSystem === BRANCH ? url_macros.replace(BranchEventMacro(upsell.id), "")
                               : newOffer.trackingSystem === KOCHAVA ? url_macros.replace(KochavaEventMacro(index + 1, upsell.id), "")
                                   : newOffer.trackingSystem === SINGULAR ? url_macros.replace(SingularEventMacro(index + 1, upsell.id), "")
                                       : newOffer.trackingSystem === ADJUST ? url_macros.replace(AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id), "")
                                           : ''
                       url_macros = newOffer.trackingSystem === APPSFLYER ? url_macros.replace(AppsFlyerEventMacro(index + 1, upsell.id).replace('&', ''), "")
                           : newOffer.trackingSystem === BRANCH ? url_macros.replace(BranchEventMacro(upsell.id).replace('&', ''), "")
                               : newOffer.trackingSystem === KOCHAVA ? url_macros.replace(KochavaEventMacro(index + 1, upsell.id).replace('&', ''), "")
                                   : newOffer.trackingSystem === SINGULAR ? url_macros.replace(SingularEventMacro(index + 1, upsell.id).replace('&', ''), "")
                                       : newOffer.trackingSystem === ADJUST ? url_macros.replace(AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id).replace('&', ''), "")
                                           : ''
                       event_macros += newOffer.trackingSystem === APPSFLYER ? AppsFlyerEventMacro(index + 1, upsell.id)
                           : newOffer.trackingSystem === BRANCH ? BranchEventMacro(upsell.id)
                               : newOffer.trackingSystem === KOCHAVA ? KochavaEventMacro(index + 1, upsell.id)
                                   : newOffer.trackingSystem === SINGULAR ? SingularEventMacro(index + 1, upsell.id)
                                       : newOffer.trackingSystem === ADJUST ? AdjustEventMacro(upsell.upsell_tracking_settings.event_tracking_goal_id, upsell.id)
                                           : ''
                   })
                   if (dest_url_parts[1]) {
                       newOffer.destination_url_ts_macros = url_macros
                       newOffer.destination_url_event_macros = event_macros
                   }
               }
               // this is for custom parameters on edit
               // newOffer.destination_custom_macros = url_macros
        
           }
    
           /**
            * set testing link for draft and empty offer
            */
           if (draft){
                   if (campaignResponse.draft.direct_linking && !isNullOrUndefined(campaignResponse.draft.tracking_method)){
                       if (campaignResponse.draft.draft.preview_url)
                       {
                           if (campaignResponse.draft.preview_url.includes("?")){
                               newOffer.testing_link = campaignResponse.draft.preview_url + "&oid=" + offer.id + "&testing=1"
                           } else {
                               newOffer.testing_link = campaignResponse.draft.preview_url + "?oid=" + offer.id + "&testing=1"
                           }
                       }
                       else if (campaignResponse.draft.detailed_pricing_model) {
                           newOffer.testing_link = getTestingLink(campaignResponse.draft.detailed_pricing_model,
                               campaignResponse.draft.id,
                               campaignResponse.draft.destination_url)
                       }
                   }
           }
           else if (campaignResponse.status !== "empty"){
               if (campaignResponse.direct_linking && !isNullOrUndefined(campaignResponse.tracking_method)){
                   if (campaignResponse.preview_url.includes("?")){
                       newOffer.testing_link = campaignResponse.preview_url + "&oid=" + offer.id + "&testing=1"
                   } else {
                       newOffer.testing_link = campaignResponse.preview_url + "?oid=" + offer.id + "&testing=1"
                   }
               } else {
                   newOffer.testing_link = getTestingLink(campaignResponse.detailed_pricing_model!, campaignResponse.id, campaignResponse.destination_url)
               }
            }
    
           /**
            * end
            */
    
    
    
           dispatch(setOffer({
              ...newOffer
           }))

           if(!draft && !replicate){
               dispatch(setInitialEditOffer({...offer, ...newOffer}))
           }

           if(draft){
               dispatch(setSaveDraftOffer(JSON.stringify(newOffer)))
           }

           if(
               (id && !draft && !replicate) &&
               (campaignResponse.detailed_pricing_model === CPI || campaignResponse.detailed_pricing_model === CPE) &&
               campaignResponse.operating_system_id === null)
           {
               navigate('type/apps/mobile')
           }

       } catch (e) {
           console.log(e)
       }finally {
           setLoading(false)
       }
    }, [dispatch, fetchCampaign, token, offer.detailed_pricing_model, offer.operating_system_id])

    useEffect(() => {
        (async () => {
            if(id){
                await fetch()
            }
        })()

    }, [id])

    return {
        loading,
        fetch
    }
}