import React, {useEffect, useState, useCallback, useRef} from "react";
import {useParams} from "react-router-dom";

import DatePicker from "../../../../../common/components/date-picker/DatePicker";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
import InputNumber from "../../../../../common/components/input-number/InputNumber";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";
import { FirstSectionInfoTable, SecondSectionInfoTable } from "./info-tables/InfoTables";

import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import Modal from '../../../../../common/components/modal/Modal'
import AddTrackingPlatformModal from "./add-tracking-platform-modal/AddTrackingPlatformModal";

import {Wrapper, Label, Hidden, TextInput ,GoBackButton, Button, Footer} from "./Home.style";

import AdvertisersEditService, {
    IUser,
    IParentUser,
    ISalesforceParent,
    IPlatformAffluent
} from "../AdvertisersEdit.service";
import {closeAlert, getEditingUser, saveUser, setUser, openAlert} from "../AdvertisersEdit.slice";
import {useAppSelector, useAppDispatch} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import TextareaWysiwyg from "../../../../../common/components/textarea-wysiwyg/TextareaWysiwyg";
import { AccountManagerInput, CompanyNameInput, MarketerSubtypeInput, DomainsInput, LeapAccountTypeInput, MarketingSensitivityInput, MarketingSpendInput, NetworkInput, NicheInput, SalesExecutiveInput, SoftRejectConversionInput, StatusInput, TierInput } from "./inputs/Inputs";
import FiltersService from "../../../../../common/services/filters.service";
import InputMultiLine from "../../../../../common/components/input-multi-line/InputMultiLine";
import TagsList from "../../../../../common/components/tags-list/TagsList";
import TrackingPlatform from "./tracking-platform/TrackingPlatform";
import InfoSection from "../../../../../common/components/info-section/InfoSection";
import Alert from "../../../../../common/components/alert/Alert";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import _ from "lodash";
import {format} from "date-fns";
import FetchLoading from "../../../../../common/components/fetch-loading/FetchLoading";
import {getStatus} from "../../../../../common/utils";


const Home: React.FC = (): JSX.Element => {
    const token = useAppSelector(tokenStore)
    const {id} = useParams<{id :string}>()
    const {editingUser, error, alertModal, saveUserError, loading} = useAppSelector(state => state.advertisersEdit)
    const [tempEditingUser, setTempEditingUser] = useState<any>();
    const dispatch = useAppDispatch()
    const [loadingData, setLoadingData] = useState(false)

    const [parentUsers, setParentUsers] = useState<IParentUser[] | []>([])

    /*--------------Referred by information-------------*/

    //Referred By
    const [referredBy, setReferredBy] = useState<IUser | string>()
    const [referredByOpened, setReferredByOpened] = useState(false)
    const [allUsers, setAllUsers] = useState<IUser[] | []>([])
    const [userSearchResults, setUserSearchResults] = useState<IUser[] | []>([])
    const [usersDisplayed, setUsersDisplayed] = useState<IUser[] | []>([])
    const [referredBySearchValue, setReferredBySearchValue] = useState<string>('')

    //Referral start date
    const [referralStartDate, setReferralStartDate] = useState<Date | null | undefined | string>(null)

    //Referral commission end
    const [referralCommissionEndSelected, setReferralCommissionEndSelected] = useState('Default')
    const [referralCommissionEndOpened, setReferralCommissionEndOpened] = useState(false)
    const [referralCommissionEndCustomValue, setReferralCommissionEndCustomValue] = useState(0)
    const [referralCommissionEndDefaultValue, setReferralCommissionEndDefaultValue] = useState(0)

    //Referral commission
    const [referralCommissionSelected, setReferralCommissionSelected] = useState('Default')
    const [referralCommissionOpened, setReferralCommissionOpened] = useState(false)
    const [referralCommissionCustomValue, setReferralCommissionCustomValue] = useState(1)
    const [referralCommissionDefaultValue, setReferralCommissionDefaultValue] = useState(1)

    /*--------------Referral information-------------*/

    //Commission Term
    const [commissionTermSelected, setCommissionTermSelected] = useState('Default')
    const [commissionTermOpened, setCommissionTermOpened] = useState(false)
    const [commissionTermValue, setCommissionTermValue] = useState(0)

    //Referral commission based
    const [referralCommissionBasedSelected, setReferralCommissionBasedSelected] = useState("Default")
    const [referralCommissionBasedOpened, setReferralCommissionBaseOpened] = useState(false)
    const [referralCommissionBasedValue, setReferralCommissionBasedValue] = useState(1)

    //Tracking platform modal
    const [trackingPlatformModalOpened, setTrackingPlatformModalOpened] = useState(false)
    const [platforms, setPlatforms] = useState<IPlatformAffluent[] | []>([]);

    /*--------------Data Sources-------------*/

    //Salesforce ID
    const [salesforceID, setSalesforceID] = useState('')
    const [salesforceParent, setSalesforceParent] = useState<ISalesforceParent | string>('')
    const [salesforceParentChecked, setSalesforceParentChecked] = useState(false)

     // Filters
    //status
    const [activeStatus, setActiveStatus] = useState<any>();
    const [activeCpc, setActiveCpc] = useState<any>();
    //tier
    const [tier, setTier] = useState<any>();
    //account manager
    const [accountManager, setAccountManager] = useState<any>();
    const [accountManagerId, setAccountManagerId] = useState<any>();
    const [allAccountManagers, setAllAccountManagers] = useState<any>([]);
    //sales executive
    const [salesExecutive, setSalesExecutive] = useState<any>();
    const [salesExecutiveId, setSalesExecutiveId] = useState<any>();
    //company name
    const [companyName, setCompanyName] = useState<any>();
    //network
    const [networkId, setNetworkId] = useState<any>();
    //ignite
    const[isIgnite, setIsIgnite] = useState<any>();
    //gross numbers
    const [gorssNumbers, setGorssNumbers] = useState<any>();
    //marketing sensitivity
    const [marketingSensitivity, setMarketingSensitivity] = useState<any>();
    //websites
    const [websites, setWebsites] = useState<any>();
    //vertical - niche
    const [niche, setNiche] = useState<any>();
    //leap account type
    const [leapAccountType, setLeapAccountType] = useState("");
    const [parentOrChild, setParentOrChild] = useState<any>();
    const [leapParent, setLeapParent] = useState<any>(null);
    //notes
    const [notes, setNotes] = useState<any>();
    //promotion method
    const [promotionMethod, setPromotionMethod] = useState<any>();
    //marketig spend
    const [marketingSpend, setMarketingSpend] = useState<any>();
    //conversion behavior
    const [softRejectConversion, setSoftRejectConversion] = useState<any>();
    // Enable leap
    const [enableLeap, setEnableLeap] = useState<any>();
    // Offer restrictions
    const [offerRestrictionsHtml, setOfferRestrictionsHtml] = useState<any>();
    // offers balance
    const [isNetworkSwitchable, setIsNetworkSwitchable] = useState<any>(false);

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true)
    //Fetch initial data
    const fetchInitialData = useCallback(async () => {
        try{
            setLoadingData(true)

            let [parentUsers, users, platforms, accountManagers] = await Promise.all([
                AdvertisersEditService.fetchParentUsers(token),
                AdvertisersEditService.fetchAllUsers(token),
                id && AdvertisersEditService.getPlatforms(token, parseInt(id)),
                FiltersService.fetchAccountManagers(token),
            ])
            if(users.data){
                setParentUsers(parentUsers.data)
                setAllUsers(users.data)
                setUserSearchResults(users.data)
                setUsersDisplayed(users.data.slice(0, 50))
                setAllAccountManagers(accountManagers ? accountManagers.data : [])
            }
            platforms && setPlatforms(platforms.data)
            setLoadingData(false)
        } catch (e){
            console.log(e)
        }
    }, [id, token, dispatch])

    useEffect(() => {
        if(editingUser){
            setTempEditingUser(editingUser);
            setAccountManager(editingUser.executive);
            setAccountManagerId(editingUser.executive?.id);
            setActiveStatus(editingUser.status);
            setActiveCpc(editingUser.cpc_status);
            setTier(editingUser.advertiser_tier);
            setSalesExecutive(editingUser.sales_executive);
            setSalesExecutiveId(editingUser.sales_executive_id);
            setCompanyName(editingUser.company)
            setNetworkId(editingUser.network_id);
            setIsIgnite(editingUser.is_ignite);
            setGorssNumbers(editingUser.gross_numbers);
            setMarketingSensitivity(editingUser.sensitivity);
            setWebsites(editingUser.registration_info?.websites);
            setNiche(editingUser.niche_id);
            setParentOrChild(editingUser.parent_or_child);
            setNotes(editingUser.notes);
            setPromotionMethod(editingUser.registration_info?.description);
            setMarketingSpend(editingUser.marketing_spend);
            setSoftRejectConversion(editingUser.soft_reject_conversions);
            setEnableLeap(editingUser.leap_plus_enabled);
            setOfferRestrictionsHtml(editingUser.offer_restrictions);
            setIsNetworkSwitchable(editingUser.is_network_switchable);
            setSalesforceParentChecked(editingUser.salesforce_parent);
            if(editingUser.referralinfo.self_commission_term){
                setReferralCommissionEndSelected('Custom')
                setReferralCommissionEndCustomValue(editingUser.referralinfo.self_commission_term)
            } else {
                setReferralCommissionEndSelected('Default')
                setReferralCommissionEndCustomValue(editingUser.referee?.referralinfo.comission_term ? editingUser.referee?.referralinfo.comission_term : 0)
            }
            if(editingUser.referralinfo.self_commission){
                setReferralCommissionSelected('Custom')
                setReferralCommissionCustomValue(editingUser.referralinfo.self_commission)
            } else {
                setReferralCommissionSelected('Default')
                setReferralCommissionCustomValue(editingUser.referee?.referralinfo.comission ? editingUser.referee?.referralinfo.comission : 0)
            }
        }
    }, [editingUser])

    useEffect(() => {
        token && fetchInitialData()
    }, [token, fetchInitialData])

    const fetchPlatforms = async () => {
        if(id){
            const {data} = await AdvertisersEditService.getPlatforms(token, parseInt(id))
            setPlatforms(data)
        }

    }

    //Set initial state
    useEffect(() => {
        if(editingUser && editingUser.referee_id){
            setReferredBy(allUsers.find(user => user.id === editingUser.referee_id));
            editingUser.referralinfo.start_date !== null && setReferralStartDate(new Date(editingUser.referralinfo.start_date.replace(/-/g, "/")));
        }

        if(editingUser && editingUser.referralinfo?.self_commission_term){
            setReferralCommissionEndSelected('Custom')
            setReferralCommissionEndCustomValue(editingUser.referralinfo?.self_commission_term)
        }

        if(editingUser && editingUser.referralinfo?.self_commission !== null){
            if ((editingUser.referralinfo?.self_commission !==1 && editingUser.referee?.user_type !== 'affiliate' ) || (editingUser.referee?.user_type !== 'advertiser' && editingUser.referralinfo?.self_commission!==5)) {
                setReferralCommissionSelected('Custom')
                setReferralCommissionCustomValue(editingUser.referralinfo.self_commission)
            } else{
                setReferralCommissionSelected('Default')
                setReferralCommissionDefaultValue(editingUser.referralinfo?.self_commission)
                // setReferralCommissionCustomValue(editingUser.referralinfo?.self_commission)
            }
        }

        if(editingUser && editingUser.referralinfo?.comission_term){
            setCommissionTermSelected('Custom')
            setCommissionTermValue(editingUser.referralinfo.comission_term)
        }

        if(editingUser && editingUser.referralinfo?.comission !== null ){
            if (editingUser.referralinfo?.comission  !== 1)
            {
                setReferralCommissionBasedSelected('Custom')
                setReferralCommissionBasedValue(editingUser.referralinfo.comission)
            } else {
                setReferralCommissionBasedSelected('Default')
                setReferralCommissionBasedValue(1)
            }
        }

        if(editingUser && editingUser.salesforce_id){
            setSalesforceID(editingUser.salesforce_id)
        }
    }, [allUsers, editingUser]);

    useEffect(() => {
        if(referredBy && referredBy !== 'remove'){
            setReferralCommissionEndDefaultValue((referredBy as IUser).comission_term)
            setReferralCommissionDefaultValue((referredBy as IUser).comission as  number)
        }

    }, [referredBy])

    const getReferredByValue = useCallback(() => {
        if(!referredBy) return ''
        if(referredBy === 'remove') return 'Remove Referral'
        return `(${(referredBy as IUser)?.id}) ${(referredBy as IUser)?.name} ${(referredBy as IUser)?.lastname}`
    }, [referredBy])

    //Search referred by
    const searchReferredByHandler = useCallback((searchValue: string) => {
        const result = allUsers.filter(user => `${user.name} ${user.lastname}`.toLowerCase().includes(searchValue.toLowerCase()) || user.id.toString().includes(searchValue.toLowerCase()))
        setUserSearchResults(result)
        setUsersDisplayed(result.slice(0, 50))
    }, [allUsers])


    useEffect(() => {
        searchReferredByHandler(referredBySearchValue)
    }, [referredBySearchValue])

    const isSalesforceCheckboxDisabled = (): boolean => {
        return !!(salesforceParent && salesforceParent !== '');

    }

    //Dropdown select handlers
    const handleReferredBySelect = useCallback((user: IUser) => {
        setReferredBy(user)
        setReferralStartDate(new Date())
        setReferredByOpened(false)
        setReferralCommissionEndDefaultValue(user.comission_term as number)
        setReferralCommissionDefaultValue(user.comission as number)
    }, [])

    const handleRemoveReferral = () => {
        setReferredBy('remove')
        setReferredByOpened(false)
    }

    const handleCommissionEndSelect = useCallback((type: 'Default' | 'Custom') => {
        setReferralCommissionEndSelected(type)
        setReferralCommissionEndOpened(false)

    }, [])

    const handleReferralCommissionSelect = useCallback((type: 'Default' | 'Custom') => {
        setReferralCommissionSelected(type)
        setReferralCommissionOpened(false)

    }, [])

    const handleCommissionTermSelect = useCallback((type: 'Default' | 'Custom') => {
        setCommissionTermSelected(type)
        setCommissionTermOpened(false)
        setCommissionTermValue(type === "Default" ? 12 : 0)
    }, [])

    const handleCommissionBasedSelect = useCallback((type: 'Default' | 'Custom') => {
        setReferralCommissionBasedSelected(type)
        setReferralCommissionBaseOpened(false)
        setReferralCommissionBasedValue(type === "Default" ? 1 : 0);
    }, [])

    const goBack = () => {
        const targetOrigin = process.env.REACT_APP_API_BASE_URL
        targetOrigin && window.parent.postMessage({id: 7, goBack: true}, targetOrigin)
    }

    useEffect(()=>{
        if(editingUser && tempEditingUser){
            setTempEditingUser(
                {
                    ...editingUser, 
                    status: activeStatus,
                    cpc_status: activeCpc,
                    executive: accountManager,
                    executive_id: accountManagerId,
                    advertiser_tier: tier,
                    sales_executive: salesExecutive,
                    sales_executive_id: salesExecutiveId,
                    company: companyName,
                    network_id: networkId,
                    is_ignite: isIgnite,
                    gross_numbers: gorssNumbers,
                    sensitivity: marketingSensitivity,
                    registration_info: {
                        ...editingUser.registration_info, 
                        websites: websites, 
                        description: promotionMethod
                    },
                    niche_id: niche,
                    parent_or_child: leapParent && leapAccountType === "Child" ? leapParent.id : null,
                    notes: notes,
                    marketing_spend: marketingSpend,
                    soft_reject_conversions: softRejectConversion,
                    leap_plus_enabled: enableLeap,
                    offer_restrictions: offerRestrictionsHtml,
                    all_niches: [],
                    referee_id: referredBy === 'remove' || !referredBy ? null : (referredBy as IUser).id,
                    referralinfo: {
                        ...editingUser.referralinfo,
                        self_commission_term: referralCommissionEndSelected === 'Custom' ? referralCommissionEndCustomValue : null,
                        self_commission: referralCommissionSelected === 'Custom' ? referralCommissionCustomValue : referralCommissionDefaultValue,
                        start_date: referralStartDate && format(new Date(referralStartDate), 'yyyy-MM-dd HH:mm:ss'),
                        comission_term: commissionTermSelected === 'Default' ? null : commissionTermValue,
                        comission: referralCommissionBasedSelected === 'Default' ? 1 : referralCommissionBasedValue
                    },
                    salesforce_id: salesforceID,
                    salesforce_parent: salesforceParentChecked
                }
            );
        }
        }, 
        [   
            offerRestrictionsHtml, activeStatus, accountManager, accountManagerId, tier, salesExecutive, salesExecutiveId, companyName, 
            networkId, isIgnite, gorssNumbers, marketingSensitivity, websites, promotionMethod, niche, 
            leapParent, leapAccountType, notes, marketingSpend, softRejectConversion, enableLeap, referredBy, referralCommissionEndCustomValue,
            referralCommissionCustomValue, referralStartDate, commissionTermValue, referralCommissionBasedValue,
            salesforceID, salesforceParentChecked, commissionTermSelected, referralCommissionBasedSelected, referralCommissionEndSelected,referralCommissionSelected
        ]
    );

    useEffect(() => {
        const editingUserForCompare = {
            ...editingUser,
            all_niches: []
        }

        setIsSaveButtonDisabled(_.isEqual(editingUserForCompare, tempEditingUser));
    }, [tempEditingUser, editingUser])

    useEffect(() => {
        setIsSaveButtonDisabled(false);
    }, [    referralCommissionEndSelected, referralCommissionSelected, commissionTermSelected, 
        referralCommissionBasedSelected
    ])

    const handleSaveUser = async () => {
        if(tempEditingUser && id && editingUser) {
            setIsSaveButtonDisabled(true);
            const respond = await dispatch(saveUser({
                id: parseInt(id),
                token: token,
                user: tempEditingUser
            }))
        };
    }

    const checkStatusAndTier = () : boolean =>{
        return tempEditingUser ?
            (tempEditingUser.advertiser_tier == null &&
                getStatus(tempEditingUser, true) == 'active') : false
    }

    useEffect(()=> {
        const fetchSalesforceParent = async () => {
            if (token && id) {
                const data = await AdvertisersEditService.salesforceParentCheck(token, salesforceID, parseInt(id as string))
                setSalesforceParent(data.data);
                if (editingUser) {
                    setSalesforceParentChecked(editingUser?.salesforce_parent);
                }

                if (data.data !== '') {
                    setSalesforceParentChecked(false);
                }
            }
        }
        fetchSalesforceParent()
    }, [salesforceID, token, id]);


    if(loadingData || loading) return <>
        <Wrapper >
            <div className="loading-dots">
                <div className={'dot'} /><div className={'dot'}/><div className={'dot'}/>
            </div>
        </Wrapper>
    </>

    return (
      <>
    <Wrapper>
        {error ? <Alert alertType={'error'}>{error}</Alert> : (
        <>
            {saveUserError && <Alert alertType={'error'}>{saveUserError}</Alert>}
        <div className={'section--title'}>Marketer Info</div>
            <div className="sections">
                <div className="section">
        {/*-------------REFEREED BY INFORMATION-----------------*/}
                    <div className="row">
                        <Label>Status</Label>
                        <StatusInput
                            editingUser={editingUser}
                            activeStatus={activeStatus}
                            setActiveStatus={setActiveStatus}
                            activeCpc={activeCpc}
                            setActiveCpc={setActiveCpc}
                        />
                    </div>
                    <div className="row">
                        <Label>Marketer Tier</Label>
                        <Tooltip position="bottom" width={200}>
                            <div className="tooltip-text">
                                This will alter the Marketer's view of their UI when directly logged into LEAP. Enterprise marketers will not be able to create/edit offers, budgets and payouts. As an Admin, you will have all create/edit functionality while if virtually logged in.
                            </div>
                        </Tooltip>
                        <TierInput
                            tier={tier}
                            setTier={setTier}
                            activeTier={editingUser ? editingUser.advertiser_tier : ""}
                            tempEditingUser={tempEditingUser}
                        />
                    </div>
                    <div className="row">
                        <Label>Account Manager</Label>
                        <AccountManagerInput
                            accountManager={accountManager}
                            setAccountManager={setAccountManager}
                            accountManagerId={accountManagerId ? accountManagerId : null}
                            setAccountManagerId={setAccountManagerId}
                            activeAccountManager={ editingUser ? editingUser?.executive : null}
                            allAccountManagers={allAccountManagers}
                        />
                    </div>
                    <div className="row">
                        <Label>Sales Executive</Label>
                        <SalesExecutiveInput
                            salesExecutive={salesExecutive}
                            setSalesExecutive={setSalesExecutive}
                            salesExecutiveId={salesExecutiveId}
                            setSalesExecutiveId={setSalesExecutiveId}
                            activeSalesExecutive={editingUser ? editingUser.sales_executive : null}
                            // activeSalesExecutiveId={editingUser ? editingUser.sales_executive_id : null}
                            allAccountManagers={allAccountManagers}
                        />
                    </div>
                    <div className="row">
                        <Label>Company Name</Label>
                        <CompanyNameInput
                            companyName={companyName}
                            setCompanyName={setCompanyName}
                        />
                    </div>
                    <div className="row">
                        <Label>Network</Label>
                        <NetworkInput
                            networkId={networkId}
                            setNetworkId={setNetworkId}
                            isNetworkSwitchable={isNetworkSwitchable}
                        />
                    </div>
                    <div className="row">
                        <LeapAccountTypeInput
                            leapAccountType={leapAccountType}
                            setLeapAccountType={setLeapAccountType}
                            parentOrChild={parentOrChild}
                            setParentOrChild={setParentOrChild}
                            parent={leapParent}
                            setParent={setLeapParent}
                            parentUsers={parentUsers}
                        />
                    </div>
                    {editingUser && editingUser.network.alias === "adperio" &&
                        <div className="row">
                            <Checkbox
                                onChange={(e) => setIsIgnite(!isIgnite)}
                                checked={isIgnite ? isIgnite : false}
                                label={"Ignite"}
                            />
                        </div>
                    }
                    <div className="row">
                        <Checkbox
                            onChange={(e)=> setGorssNumbers(!gorssNumbers)}
                            checked={gorssNumbers ? gorssNumbers : false}
                            label={"Include Gross Numbers"}
                        />
                    </div>
                    <div className="row">
                        <InfoSection>
                            When checked, Gross Numbers will be recorded for future
                            Gross Conversions only. If then unchecked, all previously
                            recorded conversions will stay as is. Future Gross Conversions will not be recorded.
                        </InfoSection>
                    </div>
                    <div className="row">
                        <Label>Offer Landing Page</Label>
                        <DomainsInput
                            websites={websites}
                            setWebsites={setWebsites}
                        />
                    </div>
                    {/* CAKE ID section */}
                    <div className="row">
                        <FirstSectionInfoTable editingUser={editingUser} />
                    </div>
                    {/*-------------REFEREED BY INFORMATION-----------------*/}
                    <h2 className={'section--title'}>Referred by Information</h2>
                    <p className={'section--description'}>
                        This is where you can set the account that referred this account. This other account will receive commission per settings in that other account.
                    </p>

                    {/*----------Referred By-----------*/}
                    <div className={'row'}>
                        <div>
                            <Label>Referred by</Label>
                            <Dropdown
                                search={{
                                    value: referredBySearchValue,
                                    onChange: setReferredBySearchValue,
                                    placeholder: referredBy ? getReferredByValue() : 'Select who referred this account'
                                }}
                                itemsNotDisplayed={userSearchResults.length - usersDisplayed.length}
                                dropdownHeightLimit={6}
                                text={getReferredByValue()}
                                placeholder={'Select who referred this account'}
                                isOpened={referredByOpened} setOpened={setReferredByOpened}
                                disabled={false}
                                optionSelect={true}
                            >
                                <DropdownItem onClick={() => handleRemoveReferral()}>Remove Referral</DropdownItem>
                                {userSearchResults && userSearchResults.slice(0, 50).map(user => (
                                    <DropdownItem
                                        selected={user.id === (referredBy as IUser)?.id}
                                        key={user.id}
                                        onClick={() => handleReferredBySelect(user)}
                                    >
                                        ({user.user_type === 'advertiser' ? 'Mar' : 'Par'} {user.id}) {user.name} {user.lastname}
                                    </DropdownItem>
                                ))}

                            </Dropdown>
                        </div>
                    </div>

                    {/*------------Referral Start Date--------------*/}
                    <div className={'row'}>
                        <div>
                            <Label>Referral Start Date</Label>
                            <DatePicker date={referralStartDate} setDate={setReferralStartDate}/>
                        </div>
                    </div>

                    {/*------------Referral Commission End-----------*/}
                    <div style={{
                        display: `${referredBy && referredBy !== 'remove' ? 'block' : 'none'}`
                    }}>
                    <div className={'row'}>
                        <div className={'commission'}>
                            <div style={{width: '100%'}}>
                                <Label>Referral Commission Ends in {referralCommissionEndSelected === 'Default' ? (referralCommissionEndDefaultValue ? referralCommissionEndDefaultValue : 12) + ' months' : referralCommissionEndCustomValue + ' months'}</Label>
                                <Dropdown
                                    text={referralCommissionEndSelected}
                                    placeholder={'Select referral commission end'}
                                    isOpened={referralCommissionEndOpened}
                                    setOpened={setReferralCommissionEndOpened}
                                    disabled={false}
                                    optionSelect={true}
                                >
                                    <DropdownItem
                                        onClick={() => handleCommissionEndSelect('Default')}
                                        selected={referralCommissionEndSelected === 'Default'}
                                    >
                                        Default
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleCommissionEndSelect('Custom')}
                                        selected={referralCommissionEndSelected === 'Custom'}
                                    >
                                        Custom
                                    </DropdownItem>
                                </Dropdown>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                <Hidden $hidden={referralCommissionEndSelected === 'Default'}>
                                    <InputNumber
                                        height={'40px'}
                                        minValue={0}
                                        onChange={setReferralCommissionEndCustomValue}
                                        value={referralCommissionEndCustomValue}
                                        width={'75px'}
                                    />
                                </Hidden>
                            </div>
                        </div>
                    </div>

                    {/*------------Referral Commission-----------*/}
                    <div className={'row'}>
                        <div className={'commission'}>
                            <div style={{width: '100%'}}>
                                <Label>Referral Commission: {referralCommissionSelected === 'Default' ? (referralCommissionDefaultValue != null ? referralCommissionDefaultValue : 1) : referralCommissionCustomValue}%</Label>
                                <Dropdown
                                    text={referralCommissionSelected}
                                    placeholder={'Select referral commission'}
                                    isOpened={referralCommissionOpened}
                                    setOpened={setReferralCommissionOpened}
                                    disabled={false}
                                    optionSelect={true}
                                >
                                    <DropdownItem
                                        onClick={() => handleReferralCommissionSelect('Default')}
                                        selected={referralCommissionSelected === 'Default'}
                                    >
                                        Default
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleReferralCommissionSelect('Custom')}
                                        selected={referralCommissionSelected === 'Custom'}
                                    >
                                        Custom
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                <Hidden $hidden={referralCommissionSelected === 'Default'}>
                                    <InputNumber
                                        height={'40px'}
                                        minValue={0}
                                        maxValue={100}
                                        onChange={setReferralCommissionCustomValue}
                                        width={'75px'}
                                        value={referralCommissionCustomValue}
                                    />
                                </Hidden>
                            </div>
                        </div>
                    </div>
                    </div>

    {/*-    ------------REFERRAL INFORMATION-----------------*/}

                    <h2 className={'section--title'}>Referral Information</h2>
                    <p className={'section--description'}>
                        This is where you can configure commissions that this account will get if they refer anyone to us.
                    </p>

                    {/*------------Commission Term-----------*/}
                    <div className="row">
                        <div className={'commission'}>
                            <div style={{width: '100%'}}>
                             <Label>Commission Term (months): {commissionTermSelected === "Default" ? 12 : commissionTermValue}</Label>
                                <Dropdown
                                    text={commissionTermSelected === 'Default' ? '12m (Default)' : 'Custom'}
                                    placeholder={'Select referral commission term'}
                                    isOpened={commissionTermOpened}
                                    setOpened={setCommissionTermOpened}
                                    disabled={false}
                                    optionSelect={true}
                                >
                                    <DropdownItem
                                        onClick={() => handleCommissionTermSelect('Default')}
                                        selected={commissionTermSelected === 'Default'}
                                    >
                                        Default
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleCommissionTermSelect('Custom')}
                                        selected={commissionTermSelected === 'Custom'}
                                    >
                                        Custom
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                    <Hidden $hidden={commissionTermSelected === 'Default'}>
                                        <InputNumber
                                            height={'40px'}
                                            width={'75px'}
                                            minValue={0}
                                            onChange={setCommissionTermValue}
                                            value={commissionTermValue}
                                        />
                                    </Hidden>
                                </div>
                        </div>
                    </div>

                    {/*------------Referral Commission Based-----------*/}
                    <div className="row">
                        <div className="commission">
                            <div style={{width: '100%'}}>
                                <Label>Referral commission will be based on {referralCommissionBasedSelected === "Default" ? 1 : referralCommissionBasedValue}% of revenue (changes are not retroactive):</Label>
                                <Dropdown
                                    text={referralCommissionBasedSelected === 'Default' ? '1% (Default)' : 'Custom'}
                                    placeholder={'Select referral commission based'}
                                    isOpened={referralCommissionBasedOpened}
                                    setOpened={setReferralCommissionBaseOpened}
                                    disabled={false}
                                    optionSelect={true}
                                >
                                    <DropdownItem
                                        onClick={() => handleCommissionBasedSelect('Default')}
                                        selected={referralCommissionBasedSelected === 'Default'}
                                    >
                                        Default
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleCommissionBasedSelect('Custom')}
                                        selected={referralCommissionBasedSelected === 'Custom'}
                                    >
                                        Custom
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                <Hidden $hidden={referralCommissionBasedSelected === 'Default'}>
                                    <InputNumber
                                        height={'40px'}
                                        minValue={0}
                                        maxValue={100}
                                        onChange={setReferralCommissionBasedValue}
                                        value={referralCommissionBasedValue}
                                        width={'75px'}
                                    />
                                </Hidden>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row">
                        <Label>Notes(not visible to the Marketer)</Label>
                        <InputMultiLine
                            value={notes ? notes : ""}
                            onChange={setNotes}
                            placeholder=""
                        />
                    </div>
                    <div className="row">
                        <Label>Describe Promotion Method</Label>
                        <InputMultiLine
                            value={promotionMethod ? promotionMethod : ""}
                            onChange={setPromotionMethod}
                            placeholder=""
                            readOnly
                        />
                    </div>
                    {/* RESTRICTIONS */}
                    <div className="row">
                        <Label>Restrictions</Label>
                        <TextareaWysiwyg
                            html={offerRestrictionsHtml}
                            setHtml={setOfferRestrictionsHtml}
                        />
                    </div>
                    {/* registration info table */}
                    <div className="row">
                        <SecondSectionInfoTable editingUser={editingUser} />
                    </div>
                </div>
                <div className="section">
                    <div className="row">
                        <Label>Vertical</Label>
                        <NicheInput
                            niche={niche}
                            setNiche={setNiche}
                        />
                    </div>
                    <div className="row">
                        <Label>Current monthly total marketing spend</Label>
                        <MarketingSpendInput
                            marketingSpend={marketingSpend}
                            setMarketingSpend={setMarketingSpend}
                        />
                    </div>
                    <div className="row">
                        <Label>Conversion Behavior</Label>
                        <SoftRejectConversionInput
                            softRejectConversion={softRejectConversion}
                            setSoftRejectConversion={setSoftRejectConversion}
                        />
                    </div>
                    <div className="row">
                        <Label>Marketer subtype</Label>
                        <MarketerSubtypeInput activeMarketerSubtype={ editingUser ? editingUser.advertiser_subtype : "network"} />
                    </div>
                    {/*-------------DATA SOURCE-----------------*/}
                    <h2 className="section--title">Data Sources</h2>
                    <div className="row">
                        <Label>Salesforce ID</Label>
                        <TextInput value={salesforceID}
                                   onChange={(e) => setSalesforceID(e.target.value)}
                                   placeholder={"Salesforce ID"}/>
                        {salesforceParent !== '' && salesforceParent &&
                        <span id={'salesforce-marketer-label'}>
                            ({(salesforceParent as ISalesforceParent).id})
                            {(salesforceParent as ISalesforceParent).full_name}
                        </span>}
                    </div>
                    <div className={'row'}>
                        <Checkbox disabled={isSalesforceCheckboxDisabled()}
                                  checked={salesforceParentChecked}
                                  label={'Salesforce parent or Non-AOR Client'}
                                  onChange={() => setSalesforceParentChecked(!salesforceParentChecked)}/>
                    </div>
                    <div className="row">
                        <Label>
                            Tracking Platforms
                            <span id={'tracking-platforms-tooltip'}>
                                <Tooltip width={230} position={'top'} >
                                    Select the tracking platform(s) for the marketer. LEAP will always exist as a tracking platform for any marketer.
                                </Tooltip>
                            </span>
                        </Label>
                        <div id={'tracking-platforms--leap'}>
                            <div id={'leap-input'}>LEAP</div>
                                <span onClick={() => setTrackingPlatformModalOpened(true)} className="material-icons-outlined add-platform">
                                    add_circle
                                </span>
                        </div>
                        <div>
                            {platforms && platforms.map((platform: any, index: any) => (
                                <TrackingPlatform fetchPlatforms={fetchPlatforms} key={index} platform={platform}/>))}
                            </div>
                        </div>
                    </div></div>
                <Footer>
                    <GoBackButton onClick={() => goBack()}>
                        {isSaveButtonDisabled ? "Go back" : "Discard changes and go back"}
                    </GoBackButton>
                    <Button disabled={isSaveButtonDisabled} onClick={() => handleSaveUser()} >Save</Button>
                </Footer>
                <Modal opened={trackingPlatformModalOpened} title={"Add Tracking Platform"}
                       closeModal={() => setTrackingPlatformModalOpened(false)}>
                    <AddTrackingPlatformModal fetchPlatforms={fetchPlatforms}
                                              closeModal={() => setTrackingPlatformModalOpened(false)}/>
                </Modal>
            </>
            )}
            </Wrapper>
            <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => dispatch(closeAlert())}>
                {alertModal.message}
            </AlertModal>
        </>
    )
}

export default Home