import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";

interface Props {}

const OfferName: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(offer.name.length <= 0) return null

    return (
        <>
            <><Subtitle> Campaign Name </Subtitle><Item>{offer.name}</Item></>
        </>
    )
}

export default OfferName