import React, {useCallback, useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'

import RangeDatePicker from "../../../../../../common/components/range-date-picker/RangeDatePicker";
import DropdownPreset from "../../../../../../common/components/range-date-picker/dropdown-preset/DropdownPreset";
import DateRangeInput from "../../../../../../common/components/daterange-input/DateRangeInput";

import {
    Wrapper,
    FilterWrapper,
    FilterContainer,
    FilterByWrapper,
    FilterByDropdown,
    MarketerPartnersList,
    DropdownWrapper,
    FilterByContainer,
    FilterByItem,
    DateFilterWrapper,
    DatePresetWrapper,
    ClearFilter
} from './Filters.style'
import {InputSingle} from "../../../../../../common/components/input-single-line/InputSingleLine.style";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import {ItemsNotDisplayed} from "../../../../../../common/components/dropdown/Dropdown.style";
import {IFetchAllMarketersResponse, IFetchAllPartnersResponse} from "../AgencyView.service";
import {useAppDispatch, useAppSelector, useOnClickOutside, useSimulateMouseClick} from "../../../../../../common/hooks";
import {IPreset} from "../../../../../../common/components/range-date-picker/presets/presets.props";
import {format} from "date-fns";
import {
    setAnalyzePerformanceDateFrom,
    setAnalyzePerformanceDateTo,
    setAnalyzePerformanceDateRangePreset,
    setFilterBy,
    setMarketer,
    setPartner
} from "../AgencyView.slice";

interface Props {
    marketers: IFetchAllMarketersResponse[]
    partners: IFetchAllPartnersResponse[]
    fetchData: () => void,
    setLocalSelectedMarketer: (marketer: IFetchAllMarketersResponse | null) => void,
    setLocalSelectedPartner: (partner: IFetchAllPartnersResponse | null) => void
}

const Filters: React.FC<Props> = ({
                                      marketers,
                                      partners,
                                      fetchData,
                                      setLocalSelectedMarketer,
                                      setLocalSelectedPartner
}) => {
    const [dateFilterOpened, setDateFilterOpened] = useState(false)
    const [marketerFilterOpened, setMarketerFilterOpened] = useState(false)
    const [filterByOpened, setFilterByOpened] = useState(false)
    const [presetDropdownOpened, setPresetDropdownOpened] = useState(false)

    const [clearFilterToggle, setClearFilterToggle] = useState(false)


    const [filterBySearchValue, setFilterBySearchValue] = useState('')
    const [partnersSearchResult, setPartnersSearchResult] = useState<IFetchAllPartnersResponse[] | []>(partners)
    const [marketersSearchResult, setMarketersSearchResult] = useState<IFetchAllMarketersResponse[] | []>(marketers)

    const dateFilterRef = useRef<HTMLDivElement | null>(null)
    const marketerFilterRef = useRef<HTMLDivElement | null>(null)
    const filterByDropdownRef = useRef<HTMLDivElement | null>(null)
    const filterByRef = useRef<HTMLDivElement | null>(null)
    const presetContainerRef = useRef<HTMLDivElement | null>(null)

    const tempMarketer = useRef<IFetchAllMarketersResponse | null>(null)
    const tempPartner = useRef<IFetchAllPartnersResponse | null>(null)
    const tempDateFrom = useRef<Date | null | undefined>(null)
    const tempDateTo = useRef<Date | null | undefined>(null)

    const {
        date_from,
        date_to,
        date_range_preset,
        filterBy,
        selectedMarketer,
        selectedPartner
    } = useAppSelector(state => state.agencyView)

    useEffect(() => {
        tempMarketer.current = selectedMarketer
        tempPartner.current = selectedPartner
        tempDateFrom.current = date_from
        tempDateTo.current = date_to
    }, [])


    const dispatch = useAppDispatch()

    const handlePartnerMarketerSearch = useCallback((searchValue: string) => {
        if(filterBy === 'partner'){
            const result = partners.filter(
                partner => {
                    return partner.platform_partner_company?.toLowerCase().includes(searchValue.toLowerCase())
                            ||
                           partner.platform_partner_id?.toString().toLowerCase().includes(searchValue.toLowerCase())
                }
            )
            setPartnersSearchResult(result)
        }
        if(filterBy === 'marketer'){
            const result = marketers.filter(
                marketer => {
                    return marketer.company?.toLowerCase().includes(searchValue.toLowerCase())
                        ||
                        marketer.leap_marketer_id?.toString().toLowerCase().includes(searchValue.toLowerCase())
                }
            )
            setMarketersSearchResult(result)
        }
    }, [filterBy, partners, marketers])

    useEffect(() => {
        handlePartnerMarketerSearch(filterBySearchValue)
    }, [filterBySearchValue, handlePartnerMarketerSearch])

    useOnClickOutside(dateFilterRef, (e) => {
        if(presetContainerRef.current?.contains(e.target as Node)){
            return
        }
        if(dateFilterOpened){
            if(tempDateFrom || tempDateTo){
                if (tempDateFrom.current !== date_from || tempDateTo.current !== date_to) {
                    fetchData()
                    tempDateFrom.current = date_from
                    tempDateTo.current = date_to
                }
            }
        }
        setPresetDropdownOpened(false)
        setDateFilterOpened(false)
    })

    useOnClickOutside(marketerFilterRef, (e) => {
        setLocalSelectedMarketer(selectedMarketer)
        setLocalSelectedPartner(selectedPartner)
        if(filterByRef.current?.contains(e.target as Node)){
            return
        }

        if(marketerFilterOpened) {
            if(tempPartner || tempMarketer){
                if (tempPartner.current?.platform_partner_id !== selectedPartner?.platform_partner_id || tempMarketer.current?.leap_marketer_id !== selectedMarketer?.leap_marketer_id) {
                    fetchData()
                    tempMarketer.current = selectedMarketer
                    tempPartner.current = selectedPartner
                }
            }

        }
        setFilterByOpened(false)
        setMarketerFilterOpened(false)
    })

    useOnClickOutside(filterByDropdownRef, (e) => {
        if(filterByRef.current?.contains(e.target as Node)){
            return
        }
        setFilterByOpened(false)
    })



    const handleFilterTypeSelect = (type: 'none' | 'partner' | 'marketer') => {
        dispatch(setFilterBy(type))
        setFilterByOpened(false)
    }

    const handleMarketerSelect = (marketer: IFetchAllMarketersResponse | null) => {
        dispatch(setMarketer(marketer))
        dispatch(setPartner(null))
    }

    const handlePartnerSelect = (partner: IFetchAllPartnersResponse | null) => {
        dispatch(setPartner(partner))
        dispatch(setMarketer(null))
    }

    const handleClearFilter = () => {
        setLocalSelectedMarketer(null)
        setLocalSelectedPartner(null)
        dispatch(setPartner(null))
        dispatch(setMarketer(null))
        dispatch(setFilterBy('none'))
        setClearFilterToggle(!clearFilterToggle)

    }

    useEffect(() => {
        fetchData()
    }, [clearFilterToggle])

    const getFilterByDropdownValue = useCallback(() => {
        switch (filterBy) {
            case "marketer":
                if(selectedMarketer){
                    return <span className={'value-selected'}>{selectedMarketer?.leap_marketer_id}&nbsp;-&nbsp;{selectedMarketer?.company}</span>
                }
                return <span className={'value-selected'}>All Marketers</span>
            case 'partner':
                if(selectedPartner){
                    return <><span className={'value-selected'}>{selectedPartner?.platform_partner_id}&nbsp;-&nbsp;{selectedPartner?.platform_partner_company}</span>{selectedPartner.merged_partner_id !== null && <i className={'ico-Connected'}/>}</>
                }
                return <span className={'value-selected'}>All Partners</span>
            default:
                return <span className={'value-selected none'}>None</span>
        }
    }, [selectedMarketer, selectedPartner, filterBy])

    useSimulateMouseClick(document.getElementById('filter-container'), [date_from, date_to])

    const setDateFrom = (date: Date | null | undefined) => {
        dispatch(setAnalyzePerformanceDateFrom(date))
    }

    const setDateTo = (date: Date | null | undefined) => {
        dispatch(setAnalyzePerformanceDateTo(date))
    }

    const setDateRangePreset = (preset: IPreset) => {
        dispatch(setAnalyzePerformanceDateRangePreset(preset))
    }


    return <Wrapper>
        {/* -----------Date filters---------- */}
        <DropdownWrapper ref={dateFilterRef}>
            <FilterWrapper
                onClick={() => setDateFilterOpened(!dateFilterOpened)}
                $opened={dateFilterOpened}

            >
                <i className={'ico-Calendar'}/><span>{format(date_from ? typeof date_from === 'string' ? new Date(date_from) : date_from : new Date(), 'MMM dd, y')} - {format(date_to ? typeof date_to === 'string' ? new Date(date_to) : date_to : new Date(), 'MMM dd, y')}</span>

            </FilterWrapper>
            <FilterContainer id={"filter-container"}  opened={dateFilterOpened} >
                <DateFilterWrapper>
                    <DatePresetWrapper>
                        <h3>Date Presets</h3>
                        <DropdownPreset
                            containerRef={presetContainerRef}
                            preset={date_range_preset}
                            setPreset={setDateRangePreset}
                            opened={presetDropdownOpened}
                            setOpened={setPresetDropdownOpened}
                        />
                        <div className="date-range">
                            <DateRangeInput startDate={date_from} setStartDate={setDateFrom} endDate={date_to} setEndDate={setDateTo}/>
                        </div>
                    </DatePresetWrapper>
                    <RangeDatePicker
                        startDate={typeof date_from === 'string' ? new Date(date_from) : date_from}
                        setStartDate={setDateFrom}
                        endDate={typeof date_to === 'string' ? new Date(date_to) : date_to}
                        setEndDate={setDateTo}
                        preset={date_range_preset.value}
                        setPreset={setDateRangePreset}
                    />
                </DateFilterWrapper>
            </FilterContainer>
        </DropdownWrapper>

        {/* -----------Partner/Marketer filters---------- */}
        <DropdownWrapper ref={marketerFilterRef}>
            <div>
            <FilterWrapper
                onClick={() => setMarketerFilterOpened(!marketerFilterOpened)}
                $opened={marketerFilterOpened}

            >
                <i className={'ico-FiltersOutlined'}/>
                <span style={{whiteSpace: "nowrap"}}>Filter by {filterBy === 'marketer' ? 'Marketer' : filterBy === 'partner' ? 'Partner' : ''}: </span>
                &nbsp;
                {getFilterByDropdownValue()}
                {marketerFilterOpened ? <i className='ico-AngleUp'/> : <i className='ico-AngleDown'/>}

            </FilterWrapper>
            <FilterContainer opened={marketerFilterOpened}>
                <FilterByWrapper $noneSelected={filterBy === 'none'}>
                    <h4>Filter by</h4>
                    <FilterByDropdown
                        onClick={() => setFilterByOpened(!filterByOpened)}
                        $opened={filterByOpened}
                        ref={filterByDropdownRef}
                    >
                        {filterBy === 'partner' ? 'Partners' : filterBy === 'marketer' ? 'Marketers' : 'None'}
                        {filterByOpened ? <i className='ico-AngleUp'/> : <i className='ico-AngleDown'/>}
                    </FilterByDropdown>
                    {filterByOpened && <DropdownPortal>
                        <FilterByContainer
                            $top={filterByDropdownRef && filterByDropdownRef.current?.getBoundingClientRect().top ? filterByDropdownRef.current?.getBoundingClientRect().top + window.scrollY + 25 : 0}
                            $left={filterByDropdownRef && filterByDropdownRef.current?.getBoundingClientRect().left}
                            opened={filterByOpened}
                            ref={filterByRef}
                        >
                            <DropdownItem
                                selected={filterBy === 'none'}
                                onClick={() => handleFilterTypeSelect('none')}
                            >
                                None
                            </DropdownItem>
                            <DropdownItem
                                selected={filterBy === 'partner'}
                                onClick={() => handleFilterTypeSelect('partner')}
                            >
                                Partners
                            </DropdownItem>
                            <DropdownItem
                                selected={filterBy === 'marketer'}
                                onClick={() => handleFilterTypeSelect('marketer')}
                            >
                                Marketers
                            </DropdownItem>
                        </FilterByContainer>
                    </DropdownPortal>}
                </FilterByWrapper>
                {filterBy === 'marketer' ?
                    <>
                        <InputSingle placeholder={'Search'} onChange={(e) =>
                            setFilterBySearchValue(e.target.value)}/>
                        <MarketerPartnersList>
                            <DropdownItem
                                selected={selectedMarketer === null}
                                onClick={() => handleMarketerSelect(null)}
                            >
                                All Marketers
                            </DropdownItem>
                            {marketersSearchResult && marketersSearchResult.slice(0, 50).map(marketer => {
                                return <FilterByItem
                                    key={marketer.leap_marketer_id}
                                    onClick={() => handleMarketerSelect(marketer)}
                                    selected={marketer.leap_marketer_id === selectedMarketer?.leap_marketer_id}
                                >
                                    <span>{marketer.leap_marketer_id}&nbsp;-&nbsp;
                                    </span>
                                    <span>{marketer.company} {marketer.full_name && ` (${marketer.full_name})`}</span>
                                </FilterByItem>
                            })}
                            {marketersSearchResult.length > 50 && <ItemsNotDisplayed>{marketersSearchResult.length - 50}
                            partners not displayed</ItemsNotDisplayed>}
                        </MarketerPartnersList> </> :
                    filterBy === 'partner' ?
                    <>
                        <InputSingle placeholder={'Search'} onChange={(e) =>
                            setFilterBySearchValue(e.target.value)}/>
                        <MarketerPartnersList>
                        <DropdownItem
                            selected={selectedPartner === null}
                            onClick={() => handlePartnerSelect(null)}
                        >All Partners</DropdownItem>
                            {partnersSearchResult && partnersSearchResult.slice(0, 50).map(partner => {
                                return <FilterByItem
                                    key={partner.platform_partner_id}
                                    onClick={() => handlePartnerSelect(partner)}
                                    selected={partner.platform_partner_id === selectedPartner?.platform_partner_id}
                                >
                                    <span>{partner.platform_partner_id}&nbsp;-&nbsp;</span> <span>{partner.platform_partner_company}</span>{partner.merged_partner_id !== null ? <i className={'ico-Connected'}/> : null}
                                </FilterByItem>
                            })}
                            {partnersSearchResult.length > 50 && <ItemsNotDisplayed>{partnersSearchResult.length - 50} partners not displayed</ItemsNotDisplayed>}
                        </MarketerPartnersList>
                    </> : null
                }

            </FilterContainer>
            </div>
        </DropdownWrapper>
        {(selectedPartner || selectedMarketer || filterBy !== 'none') && <ClearFilter onClick={handleClearFilter}>Clear filter</ClearFilter>}
    </Wrapper>
}

const DropdownPortal: React.FC = ({children}) => {
    return createPortal(children, document.body)
}

export default Filters