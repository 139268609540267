import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import {isNullOrUndefined} from "../../../../../../../common/utils";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";

interface Props {}

const TrackingUrl: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(offer.destination_url === '' || isNullOrUndefined(offer.destination_url)  || offer.detailed_pricing_model === 'ppc') return null

    return (
        <>
            <Subtitle> Campaign Tracking URL</Subtitle>
            <TruncateContent
                wrapperClassName={'truncate-wrapper'}
                numberOfLines={3}
            >
                {offer.destination_url}
            </TruncateContent>
        </>
    )
}

export default TrackingUrl