import { format } from "date-fns";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import DatePicker from "../../../../../../common/components/date-picker/DatePicker";
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import { DropdownItem } from "../../../../../../common/components/dropdown/Dropdown.style";
import InputMultiLine from "../../../../../../common/components/input-multi-line/InputMultiLine";
import Input from "../../../../../../common/components/input/Input";
import { useAppSelector } from "../../../../../../common/hooks";
import { BtnLoadingSpinner, ButtonStyled } from "../../../../../../common/styled-components";
import { formatNumberToCurrency } from "../../../../../../common/utils";
import { ModalFooter } from "../../../advertisers-edit/transparency/add-modal/AddModal.style";
import { IFetchAffiliatesResponse } from "../../partner-payments/PartnerPayment.service";
import PartnerTransactionseService from "../PartnerTransactions.service";
import { AddTransactionWrapper } from "./AddTransactionModal.style";

interface IProps {
    affiliates: IFetchAffiliatesResponse[],
    fetchData: () => void
    setAlertModal: (alert: {
        message: string,
        type: 'error' | 'success',
        opened: boolean
    }) => void,
    closeModal: () => void
}

const AddTransactionModal = ({affiliates, fetchData, setAlertModal, closeModal}: IProps) => {

    const {token} = useAppSelector(state => state.auth);

    const [affiliatesDropdownOpened, setAffiliatesDropdownOpened] = useState(false)
    const [filteredAffiliates, setFilteredAffiliates] = useState<IFetchAffiliatesResponse[]>(affiliates)
    const [affiliatesSearchValue, setAffiliatesSearchValue] = useState('')
    const [selectedAffiliate, setSelectedAffiliate] = useState<IFetchAffiliatesResponse>()

    const [actionDropdownOpened, setActionDropdownOpened] = useState(false)
    const [selectedAction, setSelectedAction] = useState<string>("")

    const [selectedType, setSelectedType] = useState<{id: number | null, value: string}>({id: null, value: ""})

    const [transactionDate, setTransactionDate] = useState<Date | null | string | undefined>()

    const [amountValue, setAmountValue] = useState('')

    const [note, setNote] = useState("")

    const [loading, setLoading] = useState(false)

    const filterAffiliates = useCallback(() => {
        setFilteredAffiliates(affiliates.filter(affiliate => {
            return (affiliate.name.toLowerCase() + affiliate.lastname.toLowerCase()).includes(affiliatesSearchValue.toLowerCase()) ||
                affiliate.id.toString().includes(affiliatesSearchValue.toLowerCase())
        }))
    }, [affiliatesSearchValue, affiliates])

    useEffect(() => {
        filterAffiliates()
    }, [affiliatesSearchValue])

    const handleAffiliateSelect = (affiliate: IFetchAffiliatesResponse) => {
        setSelectedAffiliate(affiliate)
        setAffiliatesDropdownOpened(false)
    }

    const handleActionSelect = (action: string) => {
        setSelectedAction(action)
        setSelectedType({id: null, value: ""})
        setActionDropdownOpened(false)
    }

    const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid){
            setAmountValue(e.target.value)
        }
    }

    const handleAmountBlur = () => {
        setAmountValue(formatNumberToCurrency(parseFloat(amountValue.replace(/[^0-9.]+/g, ''))))
    }


    const checkIfSaveDisabled = () => {
        return amountValue === '' || !transactionDate || selectedAction === '' || selectedType.id === null || !selectedAffiliate || loading
    }

    const addTransaction = async () => {
        try {
            let params:  {
                date: string,
                amount: string,
                credit: string,
                action: string,
                note?: string,
                type: number | null,
                user_id?: number
            } = {
                date: format(new Date(transactionDate ? transactionDate : new Date()), "y-MM-dd"),
                amount: amountValue.slice(1),
                credit: amountValue.slice(1),
                action: selectedAction,
                note: note,
                type: selectedType.id,
            }

            if(note){
                params.note = note
            }

            if(selectedAffiliate && selectedAffiliate.id){
                params.user_id = selectedAffiliate.id
            }

            const response = await PartnerTransactionseService.addTransaction(token, params);
            console.log(response);
            
            setAlertModal({
                message: 'Transaction successfully added!',
                opened: true,
                type: "success"
            })
            closeModal()
            fetchData()
        } catch (e) {
            closeModal()
            setAlertModal({
                message: 'Something went wrong!',
                opened: true,
                type: "error"
            })
        }
    }

    return (
        <AddTransactionWrapper>
            <Dropdown
                search={
                    {
                        value: affiliatesSearchValue,
                        onChange: setAffiliatesSearchValue,
                        placeholder: 'Search...'
                    }
                }
                text={selectedAffiliate ? `(${selectedAffiliate.id}) ${selectedAffiliate.name} ${selectedAffiliate.lastname}` : 'Select Partner'}
                isOpened={affiliatesDropdownOpened}
                setOpened={setAffiliatesDropdownOpened}
                label={'Partner'}
                dropdownHeightLimit={7}
                itemsNotDisplayed={filteredAffiliates.length - 50}
            >
                {
                    filteredAffiliates.slice(0, 50).map(affiliate => {
                        return <DropdownItem
                            selected={selectedAffiliate?.id === affiliate.id}
                            key={affiliate.id}
                            onClick={() => handleAffiliateSelect(affiliate)}
                        >
                            ({affiliate.id}) - {affiliate.name} {affiliate.lastname} - {affiliate.company}
                        </DropdownItem>
                    })
                }
            </Dropdown>
            <Dropdown
                text={selectedAction === "" ? "Select action" : selectedAction === "credit" ? "Credit" : "Debit"}
                isOpened={actionDropdownOpened}
                setOpened={setActionDropdownOpened}
                label={'Action'}
                dropdownHeightLimit={3}
            >
                <DropdownItem
                    selected={selectedAction === "credit"}
                    key={"credit"}
                    onClick={() => handleActionSelect("credit")}
                >
                    Credit
                </DropdownItem>
                <DropdownItem
                    selected={selectedAction === "debit"}
                    key={"debit"}
                    onClick={() => handleActionSelect("debit")}
                >
                    Debit
                </DropdownItem>
            </Dropdown>
            {
                selectedAction !== "" ? 
                    selectedAction === "credit" ? 
                    <Type setSelectedType={setSelectedType} selectedType={selectedType} type='credit' />
                    :
                    <Type  setSelectedType={setSelectedType} selectedType={selectedType} type='debit'  />
                    :
                    null
            }
            <DatePicker
                label={'Date'}
                date={transactionDate?.toString().replaceAll('-', '/')}
                setDate={setTransactionDate}
                inputWidth={'100%'}

            />
            <Input
                pattern={"[0-9, $, .]*"}
                placeholder={'$0.00'}
                value={amountValue}
                onChange={(e) => handleAmountChange(e)}
                onBlur={handleAmountBlur}
                label={'Amount'}
                wrapperClassName={'amount-wrapper'}
            />
            <Input placeholder="Note..." value={note} onChange={(e) => setNote(e.target.value)} label={'Note'} wrapperClassName={'amount-input-wrapper'} />
            <ModalFooter style={{
                marginTop: 0
            }}>
            <ButtonStyled onClick={closeModal} className={'btn-cancel'}>Cancel</ButtonStyled>
            <ButtonStyled disabled={checkIfSaveDisabled()} className={'btn-orange-filled'} onClick={() => addTransaction()}>
                {
                    loading && <BtnLoadingSpinner style={{marginRight: '10px'}}/>
                }
                Save Changes
            </ButtonStyled>
        </ModalFooter>
        </AddTransactionWrapper>
    )
}

export default AddTransactionModal;

const Type = (
    {
        selectedType, 
        setSelectedType,
        type
    }: 
    {
        selectedType: {id: number | null, value: string}, 
        setSelectedType: (s: {id: number | null, value: string}) => void,
        type: string
    }
    ) => {

    const creditTypes = [
        {
            value: "Valid Clicks",
            id: 1
        },
        {
            value: "Valid Conversions",
            id: 2
        },
        {
            value: "Valid Traffic",
            id: 7
        },
        {
            value: "Test Transaction",
            id: 8
        },
        {
            value: "Make Good",
            id: 3
        },
    ]

    const debitTypes = [
        {
            value: "Invalid Clicks",
            id: 4
        },
        {
            value: "Invalid Conversions",
            id: 5
        },
        {
            value: "Test Transaction",
            id: 9
        },
        {
            value: "Make Good",
            id: 6
        },
    ]

    const [actionDropdownOpened, setActionDropdownOpened] = useState(false)

    const handleTypeSelect = (action: {id: number, value: string}) => {
        setSelectedType(action)
        setActionDropdownOpened(false)
    }

    return (
        <Dropdown
            text={selectedType.value === "" ? "Select type" : selectedType.value}
            isOpened={actionDropdownOpened}
            setOpened={setActionDropdownOpened}
            label={'Type'}
            dropdownHeightLimit={6}
        >
            {type === "credit" ? 
            creditTypes.map(type => {
               return (
                <DropdownItem
                    selected={selectedType.value === type.value}
                    key={type.value}
                    onClick={() => handleTypeSelect(type)}
                >
                    {type.value}
                </DropdownItem>
               )
            })
            :
            debitTypes.map(type => {
                return (
                 <DropdownItem
                     selected={selectedType.value === type.value}
                     key={type.value}
                     onClick={() => handleTypeSelect(type)}
                 >
                     {type.value}
                 </DropdownItem>
                )
             })
            }
        </Dropdown>
    )
};