import styled from 'styled-components'
import {InputSingle} from "../../../../../../../../common/components/input-single-line/InputSingleLine.style";


export const Wrapper = styled.div`
  width: 560px;
  .row{
    margin-bottom: 30px;

    .input-error{
      font-size: 10px;
      color: ${({theme}) => theme.colors.critical[3]};
    }
  }

  @media screen and (max-width: 640px) {
    width: 300px;
  }

  @media screen and (max-width: 370px) {
    width: 100%;
  }
`

export const InputSingleStyled = styled(InputSingle)`
  width: 100%;
  height: 40px;
`

export const Fee = styled.div`
  display: flex;
  position: relative;

  .p-icon {
    background: #0496AA;
    line-height: 16px;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 16px;
    border-radius: 16px;
    font-size: 12px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-weight: 700;
    margin-left: 5px;
    pointer-events: none;
  }
`

export const FeeType = styled.div`
  padding-left: 20px;
  font-size: 10px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  span{
    color: #EF4A24;
  }
`

export const Label = styled.div`
  display: flex;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  font-size: 14px;
  margin-bottom: 16px;
  align-items: center;
  gap: 7px;
  
  .optional {
    font-family: ${({theme}) => theme.fonts.AvenirHeavyItalic};
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
  
  .include-fees-tip{
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
`