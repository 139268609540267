import {api} from "../../../../common/services/api";
import {IPagination} from "../../../../common/models/pagination.type";
import {IUser} from "../../../../common/models/user.type";
import {TSortType} from "../../../../common/utils/consts";

export interface IUsersFetchParams {
    with_paymentinfos: boolean
    sort_by: string,
    order: TSortType
    status?: string
    user_roles?: number
    q?: string
    paginate_by?: number
    page?: number
}

export interface ICheckDelete {
    can_delete: boolean
    is_account_manager: boolean
    success: boolean
    other_managers: IUser[]
}

export interface ICreateUser {
    name: string,
    lastname: string,
    new_password: string,
    email: string,
    role_id: number,
    phone?: string,
    qf_visibility: {
        is_selected: boolean,
        name: string, //Read-Only permission
        value: string //readonly
    }
    shield_visibility: {
        is_selected: boolean,
        name: string, //Full permission
        value: string //full
    }
    approve_sector?: {
        media: number | null,
        compliance: number | null
    }
    paymentinfo?: {
        imType: string,
        im: string,
    }
    profile_image?: string,
    full_profile_image?: string,
    compliance_checked?: boolean
}

class UsersService {
    static fetchUsers = async (token: string, params: IUsersFetchParams) => {
        return await api.get<IPagination<IUser>>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
        ].join('/'), {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }
    static createUser = async (token: string, user: ICreateUser) => {
        return await api.post<IUser>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
            ].join('/'),
            {...user}
            , {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
    }
    static updateUser = async (token: string, user: IUser) => {
        return await api.post<IUser>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                user.id
            ].join('/'),
            {...user},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        )
    }

    static uploadImage = async (token: string, userId : number, file : File) =>{
        const formData = new FormData()
        formData.append('file',file)
        userId && formData.append('user_id', userId.toString())
        return await api.post([ process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            userId ? userId : "undefined",
            'photo_upload'].join("/"),
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': file.type
                },
            }
            )
    }

    static checkDelete = async (token: string, userId: number) => {
        return await api.post<ICheckDelete>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                "check_delete"
            ].join('/'), {
                user_id: userId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
    }

    static disableOrDeleteOrAssignNew = async (token: string, user: IUser) => {
        return await api.post<IUser>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                user.id.toString()].join('/'),
            {...user, _method: 'PATCH'},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static fetchReferralLink = async (token: string, id: number) => {
        return await api.get<string>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                process.env.REACT_APP_API_REFERRAL_TOKEN,
                id.toString()
            ].join('/'),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }
}

export default UsersService;