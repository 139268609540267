import React, {useEffect, useRef, useState} from 'react'
import {FilterID} from "filters";
import {addFilterChip, clearAllFilterChips, IPagePresetsID} from "../../../../../store/userPreferences.slice";
import {
    Wrapper,
    DropdownWrapper,
    SelectFilterContainer,
    FilterSearch,
    FilterList
} from './FilterSelect.style'
import {DropdownItem} from "../../../dropdown/Dropdown.style";
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../hooks";
import {ButtonStyled} from "../../../../styled-components";
import ClickAgainToConfirmTooltip from "../../../ClickAgainToConfirmTooltip.tsx/ClickAgainToConfirmTooltip";

interface Props {
    allFilters: {
        id: FilterID,
        value: string
    }[],
    pageId: IPagePresetsID,
    setDiscoverDisplayed?: (b: boolean) => void
}

const FilterSelect: React.FC<Props> = (
    {
        allFilters,
        pageId,
        setDiscoverDisplayed,
        children
    }) => {

    const {filters} = useAppSelector(state => state.userPreferences[pageId])
    const [search, setSearch] = useState('')
    const [opened, setOpened] = useState(false)
    const [filteredFilters, setFilteredFilters] = useState(allFilters)

    const [displayClearFilterTooltip, setDisplayClearFilterTooltip] = useState(false)
    const clearFilterButtonRef = useRef<HTMLButtonElement | null>(null)
    const clearFilterClickCount = useRef(0)
    const focusSearch = useRef<HTMLInputElement | null>(null)
    const dispatch = useAppDispatch()

    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const addFilter = (filter: any) => {
        setDiscoverDisplayed && setDiscoverDisplayed(true)

        dispatch(addFilterChip({
            pageId: pageId,
            filterId: filter.id
        }))
        setOpened(false)
    }

    const filterSearch = (value: string) => {
        const filtered = allFilters.filter(filter => {
            return filter.value.toLowerCase().includes(value.toLocaleLowerCase())
        })
        setSearch(value)
        setFilteredFilters(filtered)
    }

    useOnClickOutside(clearFilterButtonRef, () => {
        clearFilterClickCount.current = 0
        setDisplayClearFilterTooltip(false)
    })

    useOnClickOutside(wrapperRef, () => {
        setOpened(false)
    })

    const handleClearFilters = () => {
        clearFilterClickCount.current++
        if (clearFilterClickCount.current === 1) {
            setDisplayClearFilterTooltip(true)
        }
        if (clearFilterClickCount.current > 1) {
            clearFilterClickCount.current = 0
            setDisplayClearFilterTooltip(false)
            dispatch(clearAllFilterChips({pageId}))
            // additionally line for clear filter on admin/users page
            setDiscoverDisplayed && setDiscoverDisplayed(true)
        }
    }
    useEffect(()=>{
        focusSearch.current?.focus()
    }, [opened])

    return <Wrapper>
        <i className={'ico-Filters'}/>
        {children}
        <DropdownWrapper ref={wrapperRef} $opened={opened} >
            <div className={'open-dropdown-icon'} onClick={() => setOpened(!opened)}><i className={'ico-plus'}/></div>
            <SelectFilterContainer opened={opened}>
                <FilterSearch ref={focusSearch} placeholder={'Search'} value={search} onChange={(e) => filterSearch(e.target.value)}/>
                <FilterList>
                    {filteredFilters.map(filter => (
                        <DropdownItem
                            key={filter.id}
                            disabled={Object.keys(filters).some(selectedFilter => selectedFilter === filter.id)}
                            onClick={() => addFilter(filter)}
                        >
                            {filter.value}
                        </DropdownItem>
                    ))}
                </FilterList>
            </SelectFilterContainer>
        </DropdownWrapper>
        {Object.keys(filters).length > 1 &&
            <ButtonStyled ref={clearFilterButtonRef}
                          className={'clear-filters'}
                          onClick={handleClearFilters}>
                {
                    displayClearFilterTooltip &&
                    <ClickAgainToConfirmTooltip onClick={() => setDiscoverDisplayed && setDiscoverDisplayed(true)}/>
                }
                Clear All Filters
            </ButtonStyled>
        }
    </Wrapper>
}

export default FilterSelect