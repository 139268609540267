import React from 'react';
import {Item, Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";

interface Props {}

const TestTracking: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(navState['testing'] === 'disabled'  || offer.detailed_pricing_model === 'ppc') return null

    return (
        <>
            <Subtitle>Test tracking</Subtitle>
            <Item>{offer.testing === 'yes' ? 'Yes' : offer.testing === 'no' ? 'No' : '-'}</Item>
        </>
    )
}

export default TestTracking