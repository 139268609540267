import React, {useEffect, useRef, useState} from "react";

import {DropdownContainer, Wrapper, DropdownToggleButton, ItemsNotDisplayed, DropdownSearchInput, HelpBlock, LabelStyled} from "./Dropdown.style";

import {IDropdownProps} from "./dropdown.props";

import {useOnClickOutside} from "../../hooks";


const Dropdown: React.FC<IDropdownProps> = ({
                                                text,
                                                isOpened,
                                                setOpened,
                                                disabled,
                                                placeholder,
                                                dropdownHeightLimit,
                                                itemsNotDisplayed,
                                                search,
                                                optionSelect,
                                                readOnly = false,
                                                headerClassName,
                                                containerClassName,
                                                overflow = true,
                                                onClickOutside,
                                                label,
                                                helpBlock,
                                                labelClassName,
                                                wrapperClassName,
                                                children
}) => {

    const ref = useRef<HTMLDivElement | null>(null)

    const [isOpenedLocal, setOpenedLocal] = useState(false)

    useOnClickOutside(ref, () => {
        onClickOutside && onClickOutside()
        setOpened ? setOpened(false) : setOpenedLocal(false)
    })

    useEffect(() => {
        search && search.onChange('')
    }, [isOpened, isOpenedLocal])

    return (
        <Wrapper ref={ref} className={wrapperClassName}>
            {label &&
                <LabelStyled className={labelClassName} active={isOpened ? isOpened : isOpenedLocal} disabled={disabled}>{label}</LabelStyled>
            }
            {(isOpened ? isOpened : isOpenedLocal) && search ?
                <DropdownSearchInput
                    autoFocus={true}
                    height={`${ref.current?.clientHeight}px`}
                    value={search.value}
                    placeholder={search.placeholder}
                    onChange={(e) => search.onChange(e.target.value)}
                    readOnly={readOnly}
                /> :
                <DropdownToggleButton
                    active={isOpened ? isOpened : isOpenedLocal}
                    disabled={disabled}
                    onClick={() => setOpened ? setOpened(!isOpened) : setOpenedLocal(!isOpenedLocal)}
                    $optionSelect={optionSelect}
                    className={headerClassName}
                    $hasLabel={!!label}
                >
                    {text === '' ? <span className={'placeholder'}>{placeholder}</span> : <span>{text}</span>}
                   <i className='ico-AngleDown'/>
                </DropdownToggleButton>
            }
                <DropdownContainer
                    heightLimit={dropdownHeightLimit}
                    topOffset={ref.current?.clientHeight}
                    width={ref.current?.clientWidth}
                    opened={isOpened !== undefined ? isOpened : isOpenedLocal}
                    $overflow={overflow}
                    className={containerClassName}
                    onClick={() => setOpened ? null : setOpenedLocal(false)}
                >
                    {children}
                    {itemsNotDisplayed && itemsNotDisplayed > 0 ? <ItemsNotDisplayed>{itemsNotDisplayed} items not displayed</ItemsNotDisplayed> : null }
                </DropdownContainer>
            {
                helpBlock && <HelpBlock>
                    {helpBlock}
                </HelpBlock>
            }
        </Wrapper>
    )

}

export default Dropdown