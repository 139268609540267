import React, {useEffect, useMemo, useState} from "react";
import {ColDef} from "ag-grid-community";
import SwitchToggleButton from "../../../../../../../common/components/switch-toggle-button/SwitchToggleButton";
import {
    InfoBox,
    Optional,
    TablePanel,
    TablePanelBody,
    TablePanelHeader,
    ModalHeaderContent
} from "./MergePartnersModal.style";
import MergingService, {
    IMergedPartner,
    IMergedPreview,
    INonMergedPartner,
    IPartnerTrafficType,
    ITagNonMerged,
    IUserForMerging,
    IUsersForMerging,
    PLATFORMS
} from "../../Merging.service";
import {Tag} from "../NonMerged.style";
import {
    AlertStyled, BtnLoadingSpinner,
    ButtonStyled,
    InputStyledContainer,
} from "../../../../../../../common/styled-components";
import Dropdown from "../../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../../common/components/dropdown/Dropdown.style";
import {ITag} from "../../../../../../../common/models/tag.type";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import SelectionPills from "../../../../../../../common/components/selection-pills/SelectionPills";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import Grid from "../../../../../../../common/components/grid/Grid";
import {CellDetailsItem, CellToggle, CellDetails} from "../../merged/Merged.style";
import {useAppSelector} from "../../../../../../../common/hooks";
import {tokenStore} from "../../../../../../../store/auth.slice";
import Input from "../../../../../../../common/components/input/Input";
import {CloseButton, Header, Body, Footer} from "../../../../../../../common/components/modalWindow/container/Container.style";

interface IMergePartnersModalProps {
    selectedPartners: INonMergedPartner[],
    tagsFromPartners: ITag[],
    tags: ITag[],
    trafficTypeFromPartners: IPartnerTrafficType
    closeModal: () => void,
    fetchData: () => void
}


const MergePartnersModal: React.FC<IMergePartnersModalProps> = ({
                                                                    selectedPartners,
                                                                    tags,
                                                                    tagsFromPartners,
                                                                    trafficTypeFromPartners,
                                                                    closeModal,
                                                                    fetchData
                                                                }) => {
    const token = useAppSelector(tokenStore)

    const [name, setName] = useState<string>("")
    const [nameInputError, setNameInputError] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)

    const [mergePreview, setMergePreview] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(true);
    const [mergedPartners, setMergedPartners] = useState<IMergedPreview>({
        ids: [],
        names: [],
        platforms: [],
        tags: [],
        trafficType: {} as IPartnerTrafficType
    })

    // <---------- TAGS START --------->
    const [openTags, setOpenTags] = useState<boolean>(false);
    const [searchTag, setSearchTag] = useState<string>("");
    const [filteredTags, setFilteredTags] = useState<ITag[]>(tags);
    const [selectedTags, setSelectedTags] = useState<ITag[]>(tagsFromPartners);
    const filterTags = () => {
        setFilteredTags(tags.filter(tag => {
                return tag.name.toLowerCase().includes(searchTag.toLowerCase()) ||
                    tag.id.toString().includes(searchTag.toLowerCase())
            })
        )
    }
    const checkIfTagIsSelected = (selectedTag: ITag) => {
        return selectedTags.some(tag => tag.id === selectedTag.id)
    }
    const handleTagSelect = (tag: ITag) => {
        if (checkIfTagIsSelected(tag)) {
            const newTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id)
            setSelectedTags(newTags)
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }
    // <---------- TAGS END --------->

    // <---------- TRAFFIC TYPE START --------->
    const [openTrafficType, setOpenTrafficType] = useState<boolean>(false)
    const [trafficTypes, setTrafficTypes] = useState<IPartnerTrafficType[]>([])
    const [selectedTrafficType, setSelectedTrafficType] = useState<IPartnerTrafficType>(trafficTypeFromPartners)

    const isSelected = (id: number) => selectedTrafficType.id === id;
    const fetchPartnerTrafficTypes = async () => {
        let {data} = await MergingService.fetchTrafficTypes(token)
        data.unshift({
            id: 0,
            name: "- Select traffic type -"
        })
        setTrafficTypes(data)
    }
    // <---------- TRAFFIC TYPE END --------->

    useEffect(() => {
        filterTags()
    }, [searchTag])

    useEffect(() => {
        fetchPartnerTrafficTypes();
    }, [])


    useEffect(() => {
        let temp: IMergedPreview = {
            ids: [],
            names: [],
            platforms: [],
            tags: [],
            trafficType: {} as IPartnerTrafficType
        }
        selectedPartners.forEach((partner: INonMergedPartner) => {
            temp.ids.push(partner.platform_partner_id)
            temp.names.push(partner.platform_partner_name ? partner.platform_partner_name : "")
            temp.platforms.push(PLATFORMS[partner.platform_id])
        });
        temp.trafficType = selectedTrafficType
        temp.tags.push(...selectedTags)
        setMergedPartners(temp)
    }, [selectedTags, selectedTrafficType])

    // <---------- TABLES START --------->
    const columnDefsNonMerged = useMemo<ColDef[]>(() => [
        {
            field: "id", headerName: "PARTNER ID", width: 110,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_id}</span>
            }
        },
        {
            field: "name", width: 200, headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_name}</span>
            },
        },
        {
            field: "company", headerName: "PARTNER COMPANY NAME", width: 200,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return <span>{data.platform_partner_company}</span>
            }
        },
        {
            field: "platform", headerName: "PLATFORM", width: 150,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                let id: number = data.platform_id
                if (id) {
                    return <span key={Math.random()}>{PLATFORMS[id]}</span>
                } else
                    return <span key={Math.random()}>{PLATFORMS[0]}</span>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                if (data.partner_traffic_type) {
                    return <span key={data.partner_traffic_type[0].id + Math.random()}>
                    {data.partner_traffic_type[0].name}</span>
                } else
                    return <span></span>
            }
        },
        {
            field: "tags", headerName: "TAGS", width: 300,
            cellRenderer: ({data}: { data: INonMergedPartner }) => {
                return data.tags && data.tags.map((tag: ITagNonMerged) => {
                    return <Tag key={tag.tag_id + tag.name}>{tag.name}</Tag>
                })
            }
        }
    ], [])
    const columnDefsMerged = useMemo<ColDef[]>(() => [
        {
            field: "partner_id", headerName: "PARTNER ID", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return (
                    <div className={'cell-item'}>
                        <CellToggle>Multiple</CellToggle>
                        {expanded &&
                            <CellDetails style={{marginBottom: 14}}>
                                {data.ids.map((id: number) => {
                                    return <CellDetailsItem key={id}>{id}</CellDetailsItem>
                                })}
                            </CellDetails>
                        }
                    </div>
                )
            }
        },
        {
            field: "partner_name", width: 200, headerName: "PARTNER NAME",
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'}>
                    <CellToggle onClick={()=> setExpanded(!expanded)}>
                        <i className={`dropdown ${expanded ? "ico-ChevronUp" : "ico-ChevronDown"}`}/> {name} <i className={"ico-Connected"}/>
                    </CellToggle>
                    {expanded &&
                        <CellDetails>
                            {data.names &&
                                data.names.map((item) => {
                                    return <CellDetailsItem style={{paddingLeft: 20}}
                                                            key={item + Math.random()}>
                                        {item}
                                    </CellDetailsItem>
                                })
                            }
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "platform",
            headerName: "PLATFORM",
            width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'}>
                    <CellToggle>Multiple</CellToggle>
                    {expanded &&
                        <CellDetails>
                            {data.platforms.map((name : string) =>{
                                return <CellDetailsItem key={name + Math.random()}>{name}</CellDetailsItem>
                            })}
                        </CellDetails>
                    }
                </div>
            }
        },
        {
            field: "number_of_partners", headerName: "# OF PARTNERS", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'}>{selectedPartners.length}</div>
            }
        },
        {
            field: "type", headerName: "TRAFFIC TYPE", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return <div className={'cell-item'} key={data.trafficType.name + Math.random()}>
                    {data.trafficType.id === 0 ? "" : data.trafficType.name}
                </div>
            }
        },
        {
            field: "tags", headerName: "TAGS", width: 150,
            cellRenderer: ({data}: { data: IMergedPreview }) => {
                return data.tags && data.tags.map((tag: ITag) => {
                    return <div className={'cell-item'} key={tag.id + tag.name + Math.random()}>
                        <Tag>{tag.name}</Tag>
                    </div>
                })
            }
        }
    ], [name, expanded])

    const defaultColumnDefs = {
        resizable: true,
        minWidth: 50,
        autoHeight: true,
    }

    // <---------- TABLES END --------->
    const saveMergedPartners =  async () => {
        let mergedPartners: IUsersForMerging = {} as IUsersForMerging
        mergedPartners.name = name
        mergedPartners.count = selectedPartners.length
        mergedPartners.tags = selectedTags
        mergedPartners.traffic_types = selectedTrafficType.id !== 0 ? [selectedTrafficType.id] : null
        mergedPartners.users = []
        selectedPartners.forEach((partner: INonMergedPartner) => {
            let mergedUser: IUserForMerging = {
                name: partner.platform_partner_name ? partner.platform_partner_name : "",
                company_name: partner.platform_partner_company ? partner.platform_partner_company : "",
                id: partner.platform_partner_id,
                model_id: partner.id,
                platform_id: partner.platform_id,
                tags: partner.tags.map((tag: ITagNonMerged) => {
                    return {id: tag.tag_id, name: tag.name}
                })
            }
            mergedPartners.users.push(mergedUser)
        })
        try {
            setLoading(true)
            await MergingService.mergePartners(token, mergedPartners)
            fetchData()
            setLoading(false)
            closeModal()
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            <Header>
                <div className={'title'}>
                    Merge Partners <CloseButton onClick={closeModal}><i className={'ico-Exit-outlined'}/></CloseButton>
                </div>
                <div className={'content'}>
                    <ModalHeaderContent>
                        <p>
                            Merging partners is a global change and will affect all
                            users in the platform. All reports and filters related to these partners will be
                            updated immediately upon merging
                        </p>
                        <p>
                            This action can be undone from the merged partners tab on this page at any time.
                        </p>
                        <p><strong>Partners to Merge: <span>{selectedPartners.length}</span></strong></p>
                    </ModalHeaderContent>
                </div>
            </Header>

            <Body>
                <AlertStyled type={'info'} border={false} style={{margin: '0 0 32px'}}>
                    <InfoBox>
                        <i className={'ico-Information'}/>
                        <span>The traffic type and tags applied here will overwrite any that were previously applied to an individual partner.</span>
                    </InfoBox>
                </AlertStyled>

                <Input error={nameInputError}
                       placeholder={'Type a name for the merged partner'}
                    label={'Merged Partner Name'}
                    onChange={(event) => {
                       if(event.target.value.length === 0) {
                           setNameInputError('The name field is required.')
                       } else {
                           setNameInputError(undefined)
                       }
                       setName(event.target.value)
                       if (event.target.value === "") {
                           setMergePreview(false)
                       }
                }}/>

                <InputStyledContainer style={{paddingBottom: 32}}>
                    <Dropdown text={selectedTrafficType.name}
                              placeholder={"Select traffic type"}
                              dropdownHeightLimit={6}
                              isOpened={openTrafficType}
                              disabled={false}
                              optionSelect={true}
                              setOpened={setOpenTrafficType}
                              label={<>Merged Partner Traffic Type <Optional>(optional)</Optional></>}>
                        {
                            trafficTypes.map((type: IPartnerTrafficType) => (
                                <DropdownItem
                                    onClick={() => {
                                        setSelectedTrafficType(type)
                                        setOpenTrafficType(false)
                                    }}
                                    selected={isSelected(type.id)}
                                    key={type.id}>
                                    {type.name}
                                </DropdownItem>
                            ))
                        }
                    </Dropdown>
                </InputStyledContainer>
                <InputStyledContainer>
                    <Dropdown text={"Type a tag to add"}
                              isOpened={openTags}
                              setOpened={setOpenTags}
                              dropdownHeightLimit={6}
                              helpBlock={"Using the field above, type out a tag you would like to use."}
                              search={{
                                  value: searchTag,
                                  onChange: setSearchTag,
                                  placeholder: "Type a tag to add"
                              }}
                              containerClassName={searchTag.length < 1 ? 'hidden' : ''}
                              headerClassName={'hide-caret'}
                              label={<>Merged Partner Tags <Optional>(optional)</Optional></>}>
                        {
                            filteredTags.map(tag => (
                                <DropdownItem
                                    onClick={()=> {
                                        handleTagSelect(tag)
                                        setOpenTags(false)
                                    }}
                                    disabled={selectedTags.some(selectedTag => tag.id === selectedTag.id)}
                                    key={tag.id}>
                                    {tag.name}
                                </DropdownItem>
                            ))
                        }
                    </Dropdown>
                    <div style={{marginTop: 32, marginBottom: 10}}>
                        {selectedTags.map(tag => <SelectionPills key={tag.id}
                                                                 text={tag.name}
                                                                 onRemove={() => handleTagSelect(tag)}/>)}
                    </div>

                </InputStyledContainer>

                <TablePanel>
                    <TablePanelHeader>
                        <div className={"headerTitle"}>Merge Partners</div>
                        <SwitchToggleButton label={"Merged Preview"}
                                            labelFirst
                                            active={mergePreview}
                                            disabled={name === ""}
                                            setActive={() => setMergePreview(!mergePreview)}/>
                    </TablePanelHeader>
                    <TablePanelBody>
                        <Grid
                            columnDefs={columnDefsMerged}
                            defaultColDef={defaultColumnDefs}
                            rowData={[mergedPartners]}
                            rowHeight={40}
                            domLayout='autoHeight'
                            className={`ag-grid-align-items-baseline ${mergePreview ? "" : "hidden"}`}
                            loading={false}
                        />
                        <Grid
                            columnDefs={columnDefsNonMerged}
                            defaultColDef={defaultColumnDefs}
                            rowData={selectedPartners}
                            rowHeight={40}
                            domLayout='autoHeight'
                            className={`${!mergePreview ? "" : "hidden"}`}
                            loading={false}
                        />
                    </TablePanelBody>
                </TablePanel>
            </Body>

            <Footer>
                <ButtonStyled onClick={closeModal} className={"btn-cancel"}>Cancel</ButtonStyled>
                <ButtonStyled
                    onClick={saveMergedPartners}
                    disabled={loading || name.length < 1}
                    className={"btn-orange-filled"}>
                    <span className={"btn-label"}>{loading && <BtnLoadingSpinner style={{marginRight: 5}} />}Merge Partners</span>
                </ButtonStyled>
            </Footer>
        </>
    )
}

export default MergePartnersModal
