import styled from 'styled-components'
import {DropdownContainer} from "../../../../../../../common/components/dropdown/Dropdown.style";

export const Wrapper = styled.div`
  width: 465px;
  max-width: 100%;
`

export const TagSearchWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  
  .search-tags-input {
    height: 40px;
  }
`

export const TagSearchLabel = styled.p`
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  font-size: 12px;
  margin-bottom: 16px;
`

export const TagsDropdownContainer = styled(DropdownContainer)`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  top: 40px;
  .tag{
    height: 25px;
  }
`