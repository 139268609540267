import React from 'react'

import {Wrapper, Image, Footer, CloseButton} from './ImagePreviewModalContent.style'

interface Props {
    imageSource: string,
    imageName: string,
    closeModal: () => void
    wrapperClassName?: string
    imageClassName?: string
}

const ImagePreviewModalContent: React.FC<Props> = ({
                                                       imageSource,
                                                       imageClassName,
                                                       imageName,
                                                       wrapperClassName,
                                                       closeModal
                                                   }) => {
    return <Wrapper className={wrapperClassName}>
        <CloseButton onClick={closeModal}>
            <i className={'ico-Exit-outlined'}/>
        </CloseButton>
        <Image src={imageSource} className={imageClassName}/>
        <Footer>{imageName}</Footer>
    </Wrapper>
}

export default ImagePreviewModalContent