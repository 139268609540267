import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'

import {Wrapper, Label, InputSingleStyled, Fee, FeeType} from './CreateNewFeeGroupModal.style'

import Checkbox from "../../../../../../../../common/components/checkbox/Checkbox";
import InputMultiLine from "../../../../../../../../common/components/input-multi-line/InputMultiLine";
import Dropdown from '../../../../../../../../common/components/dropdown/Dropdown'
import {DropdownItem} from "../../../../../../../../common/components/dropdown/Dropdown.style";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../../../common/hooks";
import {tokenStore} from "../../../../../../../../store/auth.slice";
import BillingService, {IFee, ISaveFeeGroupParams, ITier} from "../../../Billing.service";
import {InputStyled, Tier, TierInput} from '../../create-new-fee/create-new-fee-modal/traffic-based/TrafficBased.style'
import {formatNumberToCurrency} from "../../../../../../../../common/utils";
import Tooltip from "../../../../../../../../common/components/tooltip/Tooltip";
import {Button, Cancel} from "../../../../home/add-tracking-platform-modal/AddTrackingPlatformModal.style";
import {ModalFooter} from "../../../../../../../../common/components/modal/container/Container.style";

interface ICreateNewFeeGroupModalProps {
    group: any,
    closeModal: () => void
    setAlertModal: (modal: {type: 'success' | 'error', message: string, opened: boolean}) => void
    fetchFeesTable: () => void
}

const CreateNewFeeGroupModal: React.FC<ICreateNewFeeGroupModalProps> = ({group, closeModal, setAlertModal, fetchFeesTable}) => {
    const {id} = useParams()
    const token = useAppSelector(tokenStore)
    const [description, setDescription] = useState('')
    const [feeName, setFeeName] = useState('')
    const [selectedFees, setSelectedFees] = useState<number[]>([])
    const [feeType, setFeeType] = useState<number | undefined>(undefined)
    const [feeVisibility, setFeeVisibility] = useState<number | undefined>(undefined)
    const [fees, setFees] = useState<IFee[]>([])
    const [monthlyMinimum, setMonthlyMinimum] = useState('')
    const [tiers, setTiers] = useState<ITier[]>([{
        order: 1,
        from: '',
        to: '',
        percentage: ''
    }])

    // const [tierErrors, setTierErrors] = useState<ITierError[]>([
    //     {from: false, to: false},
    //     {from: false, to: false},
    //     {from: false, to: false},
    // ])

    const [feeTypeDropdownOpened, setFeeTypeDropdownOpened] = useState(false)
    const [visibilityDropdownOpened, setVisibilityDropdownOpened] = useState(false)

    const [loading, setLoading] = useState(false)
    const [feeNameExists, setFeeNameExists] = useState(false)

    const handleSaveFeeGroup = async () => {
        let params: ISaveFeeGroupParams = {
            name: feeName,
            description: description,
            type: feeType,
            fees: selectedFees,
            visibility: feeVisibility,
            monthly_charges: monthlyMinimum,
            tiers: tiers
        }
        if(group){
            delete group.isGroup
            delete group.expanded
            params = {...group, ...params}

        }
        if(id && token){
            try{
                setLoading(true)
                await BillingService.saveFeeGroup(token, id, params)
                setLoading(false)
                fetchFeesTable()
                setAlertModal({
                    opened: true,
                    message: `Success! ${feeName} has been ${group ? 'edited' : 'created'}.`,
                    type: 'success'
                })
            } catch (e) {
                setLoading(false)
                setAlertModal({
                    opened: true,
                    message: `Fail! Fee group has not been ${group ? 'edited' : 'created'}.`,
                    type: 'error'
                })
            }
        }
        closeModal()
    }

    const checkFeeName = async (name: string) => {
        if(group && group.name === name) return
        if(id){
            const {data} = await BillingService.checkIfFeeNameExists(token, id, name, 'group')
            setFeeNameExists(data.exists)
        }
    }

    const checkIfButtonShouldBeDisabled = () => {
        if(
            loading ||
            feeName === '' ||
            feeNameExists ||
            selectedFees.length < 2 ||
            feeType === undefined ||
            feeVisibility === undefined
        ) return true

        if(tiers.some((tier, index) => parseFloat(tier.to.toString().replace(/[^0-9.]+/g, '')) <= parseFloat(tier.from.toString().replace(/[^0-9.]+/g, '')))){
            return true
        }

        return tiers.some((tier, index) => {
            if(tiers[index - 1]){
                return parseFloat(tier.from.toString().replace(/[^0-9.]+/g, '')) <= parseFloat(tiers[index - 1].to.toString().replace(/[^0-9.]+/g, ''))
            }
            return false
        })
    }

    const handleFeeTypeSelect = (type: number) => {
        setFeeType(type)
        setFeeTypeDropdownOpened(false)
    }

    const fetchFees = useCallback(async () => {
        if(token && id){
            const {data} = await BillingService.getFeesForGroupModal(token, id, group?.id ? group.id : null, feeType === 2 ? 1 : null)
            setFees(data.data)
        }
    }, [feeType, id, token])

    useEffect(() => {
        if(group){
            setFeeName(group.name)
            setDescription(group.description)
            setFeeType(group.type)
            setFeeVisibility(group.visibility)
            group.tiers.length > 0 && setTiers(group.tiers)
            setMonthlyMinimum(group.monthly_charges === 0 ? '' : formatNumberToCurrency(group.monthly_charges))
            setSelectedFees(group.fees.map((fee: any) => fee.id))
            if(group.tiers.length > 0){
                const formatedTiers = group.tiers.map((tier: any) => {
                    return {
                        ...tier,
                        from: formatNumberToCurrency(parseFloat(tier.from)),
                        to: tier.to === 0 ? '' : formatNumberToCurrency(parseFloat(tier.to)),
                        percentage: tier.percentage + '%'
                    }
                })

                setTiers(formatedTiers)
            }
        }
    }, [group])

    useEffect(() => {
        feeType && fetchFees()
    }, [feeType, fetchFees])

    const handleFeeCheck = (id: number) => {
        if(selectedFees.some(fee => fee === id)){
            setSelectedFees(selectedFees.filter(fee => fee !== id))
        } else {
            setSelectedFees([...selectedFees, id])
        }
    }

    const handleVisibilitySelect = useCallback((type: number) => {
        setFeeVisibility(type)
        setVisibilityDropdownOpened(false)
    }, [])


    const handleAddTier = useCallback(() => {

            if(tiers[tiers.length - 1].from !== '' && tiers[tiers.length - 1].to !== '' && tiers[tiers.length - 1].percentage !== '' ){
                setTiers([...tiers, {
                    order: tiers.length + 1,
                    from: formatNumberToCurrency(parseFloat(tiers[tiers.length - 1].to.toString().replace(/[^0-9.]+/g, '')) + 1),
                    to: '',
                    percentage: ''
                }])
                // setTierErrors([...tierErrors, {from: false, to:false}])
            }

    }, [tiers, setTiers])

    const checkTierFromError = (index: number): boolean => {
        if(tiers[index - 1]){
            if(
                parseFloat(tiers[index].from.toString().replace(/[^0-9.]+/g, ''))
                <=
                parseFloat(tiers[index - 1].to.toString().replace(/[^0-9.]+/g, '')))
            {

                return true
            }
        }
        return false
    }

    const checkTierToError = (index: number): boolean => {
        if(
            parseFloat(tiers[index].to.toString().replace(/[^0-9.]+/g, ''))
            <=
            parseFloat(tiers[index].from.toString().replace(/[^0-9.]+/g, '')))
        {
            return true
        }

        return false
    }

    const handleTierRemove = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers.splice(index, 1)
        setTiers(newTiers)
    }, [tiers, setTiers])

    const handleTierFromChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid){
            const newTiers = [...tiers]
            newTiers[index].from = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleTierToChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newTiers = [...tiers]
            newTiers[index].to = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleTierPercentageChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newTiers = [...tiers]
            newTiers[index].percentage = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleTierFromBlur = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers[index].from = formatNumberToCurrency(parseFloat(newTiers[index].from.toString().replace(/[^0-9.]+/g, '')));
        setTiers(newTiers)
    }, [tiers, setTiers])

    const handleTierToBlur = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers[index].to = formatNumberToCurrency(parseFloat(newTiers[index].to.toString().replace(/[^0-9.]+/g, '')));
        if(tiers[index + 1]){
            newTiers[index + 1].from = formatNumberToCurrency(parseFloat(tiers[index].to.toString().replace(/[^0-9.]+/g, '')) + 1)
        }
        if(tiers[index].to === ''){
            newTiers.splice(index + 1)
        }
        setTiers(newTiers)
    }, [tiers, setTiers])

    const handleTierPercentageBlur = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers[index].percentage =
            isNaN(parseFloat(newTiers[index].percentage.toString().replace(/[^0-9.]+/g, ''))) ? '' :
                parseFloat(newTiers[index].percentage.toString().replace(/[^0-9.]+/g, '')) + '%';
        setTiers(newTiers)
    }, [tiers, setTiers])

    const handleMonthlyMinimumChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid) {
            setMonthlyMinimum(e.target.value)
        }
    }, [setMonthlyMinimum])

    const handleMonthlyMinimumBlur = useCallback(() => {
        setMonthlyMinimum(formatNumberToCurrency(parseFloat(monthlyMinimum.toString().replace(/[^0-9.]+/g, ''))))
    }, [monthlyMinimum, setMonthlyMinimum])

    return <Wrapper>
        <div className="row">
            <Label>Fee Group Name</Label>
            {feeNameExists && <span className={'input-error'}>A fee with that name already exists, please use a different name.</span>}
            <InputSingleStyled onBlur={(e) => checkFeeName(e.target.value)} value={feeName} onChange={(e) => setFeeName(e.target.value)} placeholder={'Fee Group Name'}/>
        </div>
        <div className="row">
            <Label>Description for internal use</Label>
            <InputMultiLine onChange={(e) => setDescription(e)} value={description} placeholder={'Type a description for what this fee group means'}/>
        </div>
        <div className={'row'}>
            <Label>
                Fee Group Type
                <Tooltip position={'top'}>
                    <div style={{
                        width: '243px'
                    }}>
                        <p>This option sets the type of fee group, either standard or tiered.</p>
                        <br/>
                        <p>
                            Standard Fee Group: Most common group type, used to mostly add fees appearing as one line item to the marketer in billing such as Agency Fees.
                        </p>
                        <br/>
                        <p>
                            Tiered Fee Group: In cases where you have nontiered fees that need to be combined together and calculated in tiers. For example: Nontiered Traffic Based Fee & Paid Media combined together to be calculated in tiers.
                        </p>
                    </div>
                </Tooltip>
            </Label>
            <Dropdown text={`${feeType === 1 ? 'Standard' : feeType === 2 ? 'Tiered' : ''}`} isOpened={feeTypeDropdownOpened} setOpened={setFeeTypeDropdownOpened} placeholder={'Fee Type'}>
                <DropdownItem onClick={() => handleFeeTypeSelect(1)}>Standard</DropdownItem>
                <DropdownItem onClick={() => handleFeeTypeSelect(2)}>Tiered</DropdownItem>
            </Dropdown>
        </div>
        <div className={'row'}>
            <Label>
                Individual Fee Visibility
                <Tooltip position={'top'}>
                    <div style={{
                        width: '243px'
                    }}>
                        <p>This option sets the visibility of the individual fees inside the group to the marketer.</p>
                        <br/>
                        <p>
                            Admin only: Only admin users can see the individual fees when the group is expanded. Marketers will see the group fee name as one line item with the fees combined.
                        </p>
                        <br/>
                        <p>
                            Admin & marketer: Both admin and marketers can see the individual fees inside the group.
                        </p>
                    </div>
                </Tooltip>
            </Label>
            <Dropdown text={feeVisibility === 0 ? 'Admin Only' : feeVisibility === 1 ? 'Admin & Marketer' : ''} isOpened={visibilityDropdownOpened} setOpened={setVisibilityDropdownOpened} placeholder={'Select Individual Fee Visibility'}>
                <DropdownItem onClick={() => handleVisibilitySelect(0)}>Admin Only</DropdownItem>
                <DropdownItem onClick={() => handleVisibilitySelect(1)}>Admin & Marketer</DropdownItem>
            </Dropdown>
        </div>
        {fees.length > 0 && (
            <div className={'row'}>
                <Label>
                    Included Fees <span className={'include-fees-tip'}>({feeType === 2 ? 'Nontiered Fees Only' : 'Any Fee Type'})</span>
                    <Tooltip position={'top'}>
                        <div style={{
                            width: '130px'
                        }}>
                            <p>Select which fees should be included in the group.</p>

                        </div>
                    </Tooltip>
                </Label>
                {fees.map(fee => {
                    return <div key={fee.id}>
                        <Fee >
                        <div>
                            <Checkbox checked={selectedFees.some(selectedFee => selectedFee === fee.id)} label={`${fee.name} `} onChange={() => handleFeeCheck(fee.id)}/>
                        </div>
                        {fee.pass_through && <span className="p-icon">P</span>}
                        </Fee>
                        <FeeType><span>Fee Type: </span>{fee.type_string}</FeeType>
                    </div>
                })}
            </div>
        )}
    <div className="row">
        {feeType === 2 && <Label>
            Fee Group Tiers
            <Tooltip position={"top"}>
                <div style={{
                    width: '253px'
                }}>
                    <p>Add the tier(s) to calculate the fees by. For example:</p>
                    <br/>
                    <p>$0 to $50,000 is 10%</p>
                    <p>$50,001 to $100,000 is 5%</p>
                    <p>$100,001 to “Empty for Unlimited” is 2%</p>

                </div>
            </Tooltip>
        </Label>}
        {feeType === 2 && tiers.map((tier, index) => {
            return <Tier key={index}>
                    <span style={{
                        whiteSpace: 'nowrap'
                    }}>Tier {index + 1} </span>
                <TierInput
                    pattern={"[0-9, $, .]*"}
                    value={tier.from.toString()}
                    onChange={(e) => handleTierFromChange(e, index)}
                    placeholder={'$'}
                    onBlur={() => handleTierFromBlur(index)}
                    error={checkTierFromError(index)}
                />
                to
                <TierInput
                    pattern={"[0-9, $, .]*"}
                    value={tier.to.toString()}
                    onChange={(e) => handleTierToChange(e, index)}
                    placeholder={'$ or leave empty for Unlimited'}
                    onBlur={() => handleTierToBlur(index)}
                    error={checkTierToError(index)}
                />
                is
                <TierInput
                    pattern={"[0-9,%.]*"}
                    value={tier.percentage.toString()}
                    onChange={(e) => handleTierPercentageChange(e, index)}
                    style={{width: '58px'}}
                    placeholder={'%'}
                    onBlur={() => handleTierPercentageBlur(index)}
                />
                {index === 0 ?
                    <span onClick={handleAddTier} className="material-icons-outlined">
                        add_circle
                    </span> :
                    <span onClick={() => handleTierRemove(index)} className="material-icons">
                        cancel
                    </span>
                }

            </Tier>
        })}
    </div>
        {tiers[0].from !== '' && feeType === 2 &&
            <div className="row">
                <Label>
                    Monthly Minimum Charges
                    <span className="optional">
                            (Optional)
                        </span>
                    <Tooltip position={'top'}>
                        <div style={{
                            width: '158px'
                        }}>
                            <p>Set an optional monthly minimum charge to the marketer if the traffic doesn’t reach the set tier amount.</p>
                        </div>
                    </Tooltip>
                </Label>
                <InputStyled
                    placeholder={'$'}
                    value={monthlyMinimum}
                    onChange={(e) => handleMonthlyMinimumChange(e)}
                    pattern={"[0-9, $, .]*"}
                    onBlur={handleMonthlyMinimumBlur}
                />
            </div>
        }
        <ModalFooter>
            <Cancel onClick={() => closeModal()}>Cancel</Cancel>
            <Button disabled={checkIfButtonShouldBeDisabled()} onClick={handleSaveFeeGroup}>Save</Button>
        </ModalFooter>
    </Wrapper>
}

export default CreateNewFeeGroupModal