import React from 'react'

import {CreativesInfoWrapper} from '../CreativesAndEmail.style'
import {AlertStyled} from "../../../../../../../../common/styled-components";
import {useAppSelector} from "../../../../../../../../common/hooks";

const CreativesInfo: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    return <AlertStyled type={"info"} style={{marginBottom: 36}}>
        <CreativesInfoWrapper>
            <i className={'ico-Information'}/>
            <div>
                When uploading visual creatives the following sizes are accepted:
                <br/><br/>
                <span className="label">Product Images:</span> 300x300 (Min) / 100MB (Max) <br/>
                <span className="label">Banners:</span> 120x20, 120x60, 120x600, 160x600, 168x28, 216x36, 300x50, 300x250, 320x50, 468x60, 728x90
            </div>
        </CreativesInfoWrapper>
    </AlertStyled>
}

export default CreativesInfo