import React, {FormEvent, useEffect, useRef, useState} from 'react'

import logo from '../../../../assets/images/logo-home.svg'

import {Wrapper, SubmitButton} from '../TwoFactorAuthentication.style'
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../common/styled-components";
import Input from "../../../../common/components/input/Input";
import Checkbox from "../../../../common/components/checkbox/Checkbox";
import TwoFactorAuthenticationService, {FetchUsersDevicesResponse} from "../../../../common/services/2fa.service";
import {useAppSelector} from "../../../../common/hooks";
import {DropdownItem} from "../../../../common/components/dropdown/Dropdown.style";
import _ from "lodash";

interface SendCodeProps {
    setSelectedPhoneNumber: any,
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void,
    handleLostMyDevice: () => void,
    selectedPhoneNumber: any,
    sendOtpSms: () => void,
    loading: boolean,
    devices: FetchUsersDevicesResponse[]
}

interface VerifyCodeProps {
    code: string,
    setCode: (value: string) => void,
    formattedPhoneNumber: string | undefined,
    rememberDevice: boolean,
    setRememberDevice: (remember: boolean) => void,
    setCodeSent: (value: boolean) => void
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void,
    sendCode: () => void,
    loading: boolean,
    error: string | undefined,
    setInvalidCodeError: (e: string | undefined) => void
}

const targetOrigin = process.env.REACT_APP_API_BASE_URL

const Verify: React.FC = () => {
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<FetchUsersDevicesResponse | undefined>()
    const [codeSent, setCodeSent] = useState(false)
    const [code, setCode] = useState('')
    const [invalidCodeError, setInvalidCodeError] = useState<string | undefined>()
    const [rememberDevice, setRememberDevice] = useState(false)
    const [devices, setDevices] = useState<FetchUsersDevicesResponse[]>([])
    const [loadingSendOtpSms, setLoadingSendOtpSms] = useState(false)
    const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false)
    const [lostDevice, setLostDevice] = useState(false)

    const {token} = useAppSelector(state => state.auth)

    const handleLostMyDevice = async () => {
        await TwoFactorAuthenticationService.lostDevice(token)
        setLostDevice(true)
    }

    const fetchDevices = async () => {
        try {
            const {data} = await TwoFactorAuthenticationService.fetchUsersDevices(token)
           setDevices(data)
        } catch (e) {
            console.error(e)
        }

    }

    useEffect(() => {
        if(token){
            void fetchDevices()
        }
    }, [token])

    const sendOtpSms = async () => {
        setLoadingSendOtpSms(true)
        try {
            if(selectedPhoneNumber){
                const {data} = await TwoFactorAuthenticationService.sendOtpSms(token, {
                        id: selectedPhoneNumber.id,
                    }
                )
                setCode(data.sensitive_data)
                setCodeSent(true)
            }
        } catch (e) {
            setCodeSent(false)
        } finally {
            setLoadingSendOtpSms(false)
        }
    }

    const verifyOtp = async () => {
        setLoadingVerifyOtp(true)

        try {
            if(selectedPhoneNumber) {
                const {data} = await TwoFactorAuthenticationService.verifyOtp(token, {
                    otp: code,
                    id: selectedPhoneNumber.id,
                    remember_device: rememberDevice
                })

                if(data.data){
                    targetOrigin && window.parent.postMessage({id: 10, data: data.data}, targetOrigin)
                }

                if(data.token){
                    targetOrigin && window.parent.postMessage({id: 9, data: data.token}, targetOrigin)
                }

                setInvalidCodeError(undefined)
            }
        } catch (e) {
            setInvalidCodeError('The code entered is invalid, try again or send a new code.')
        } finally {
            setLoadingVerifyOtp(false)
        }
    }

    const handleSubmitSendOtpSms = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        void sendOtpSms()
    }

    const handleSubmitVerifyOtp = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        void verifyOtp()
    }

    return <Wrapper>
        <div>
            <img className={'perform-logo'} src={logo} alt=""/>
        </div>
        <div className={'form-container'}>
            {lostDevice ? <LostMyDevice/> : <>

                {!codeSent ?
                    <SendCode
                        handleLostMyDevice={handleLostMyDevice}
                        selectedPhoneNumber={selectedPhoneNumber}
                        setSelectedPhoneNumber={setSelectedPhoneNumber}
                        handleSubmit={handleSubmitSendOtpSms}
                        sendOtpSms={sendOtpSms}
                        loading={loadingSendOtpSms}
                        devices={devices}
                    /> :
                    <VerifyCode
                        setCodeSent={setCodeSent}
                        formattedPhoneNumber={selectedPhoneNumber?.phone_number}
                        code={code}
                        setCode={setCode}
                        rememberDevice={rememberDevice}
                        setRememberDevice={setRememberDevice}
                        handleSubmit={handleSubmitVerifyOtp}
                        sendCode={sendOtpSms}
                        loading={loadingVerifyOtp}
                        error={invalidCodeError}
                        setInvalidCodeError={setInvalidCodeError}
                    />
                }

            </>}

        </div>
    </Wrapper>
}

const SendCode: React.FC<SendCodeProps> = ({
    setSelectedPhoneNumber,
    handleSubmit,
    handleLostMyDevice,
    selectedPhoneNumber,
    loading,
    devices
                  }) => {
    const [opened, setOpened] = useState(false)

    const handlePhoneSelect = (phone: FetchUsersDevicesResponse) => {
        setSelectedPhoneNumber(phone)
        setOpened(false)
    }

    return <form onSubmit={handleSubmit}>
        <div className="row">
            <Dropdown
                label={'PHONE'}
                text={selectedPhoneNumber ? selectedPhoneNumber.phone_number : ''}
                placeholder={'Choose a phone number'}
                isOpened={opened}
                setOpened={setOpened}
            >
                {devices.map(device => {
                    return <DropdownItem
                        style={{textAlign: 'left'}}
                        key={_.uniqueId(selectedPhoneNumber?.id)}
                        selected={selectedPhoneNumber?.id === device?.id}
                        onClick={() => handlePhoneSelect(device)}
                    >
                        {device.phone_number}
                    </DropdownItem>
                })}
            </Dropdown>
        </div>
        <div className="row submit-section">
            <SubmitButton
                disabled={!selectedPhoneNumber || loading}
            >
                {loading && <BtnLoadingSpinner/>}SEND CODE
            </SubmitButton>
            {!selectedPhoneNumber ?
                <ButtonStyled
                    className={'btn-cancel'}
                    onClick={handleLostMyDevice}
                >
                    I lost access to my device
                </ButtonStyled> :
                null
            }
        </div>
    </form>
}

const VerifyCode: React.FC<VerifyCodeProps> = ({
                            code,
                            setCode,
                            formattedPhoneNumber,
                            rememberDevice,
                            setRememberDevice,
                            setCodeSent,
                            loading,
                            handleSubmit,
                            sendCode,
                            error,
                                                   setInvalidCodeError
}) => {
    const [resendCodeEnabled, setResetCodeEnabled] = useState(true)
    const [counter, setCounter] = useState(0)
    const timer = useRef<NodeJS.Timer>()

    const handleResendCode = () => {
        setCounter(30)
        sendCode()
        setResetCodeEnabled(false)
    }

    useEffect(() => {
        if(counter > 0){
            timer.current = setInterval(() => {
                setCounter(prev => prev - 1)
            }, 1000)
        }
        if(counter === 0){
            setResetCodeEnabled(true)
            timer.current && clearInterval(timer.current)
        }

        return () => {
            timer.current && clearInterval(timer.current)
        }
    }, [counter])

    const handleChooseDifferentDevice = () => {
        setCodeSent(false)
        setInvalidCodeError('')
    }

    return <>
        <div className="row" style={{textAlign: 'center'}}>
            <p>A text message has been sent with your code to: </p>
            <p>{formattedPhoneNumber}</p>
        </div>
        <div className="row">

        </div>
        <form onSubmit={handleSubmit}>
            <div className="row">
                <p className="label">6-DIGIT CODE</p>
                <Input
                    wrapperClassName={'input-styled'}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder={'Enter code'}
                    error={error}
                    leftAlignErrorMessage={true}
                />
                <div className="remember-device-wrapper" style={{marginTop: error ? '18px' : '9px'}}>
                    <Checkbox
                        checked={rememberDevice}
                        label={'Remember this device'}
                        onChange={() => setRememberDevice(!rememberDevice)}
                    />
                </div>

            </div>
            <div className={'row'} style={{textAlign: 'center'}}>
                <p>It may take a minute to receive your code. <br/>
                    Haven’t received it yet? {resendCodeEnabled ?
                        <span className={'resend-code'} onClick={handleResendCode}>Resend Code</span> :
                        <span className={'resend-code-again'}>
                            <span className={'resend-code-disabled'}>Resend code</span> again in {counter} seconds</span>}
                </p>
            </div>
            <div className="row submit-section">
                <SubmitButton
                    disabled={!code || loading}
                >
                    {loading && <BtnLoadingSpinner/>}VERIFY CODE
                </SubmitButton>
                {!code ?
                    <ButtonStyled
                        className={'btn-cancel'}
                        onClick={handleChooseDifferentDevice}
                    >
                        I need to choose a different phone number
                    </ButtonStyled> :
                    null
                }
            </div>
        </form>
    </>
}

const LostMyDevice: React.FC = () => {
    const redirectToLogin = () => {
        targetOrigin && window.parent.postMessage({id: 8}, targetOrigin)
    }

    return <>
        <h3 className={'authentication-setup-title'}>We’re on it! </h3>
        <div className="lost-device-content-wrapper">
            <p>Your account manager will be in contact with you about unlocking your account.</p>
            <p  className='return-to-login' >Return to <span onClick={redirectToLogin}>log in</span></p>
        </div>
    </>
}

export default Verify