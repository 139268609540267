import React from 'react'

import {Wrapper} from "./Loading.style";

interface ILoadingProps {
    size?: number
}

const Loading: React.FC<ILoadingProps> = ({size = 8}) => {
    return <Wrapper $size={size}>
        <span></span><span></span><span></span>
    </Wrapper>
}

export default Loading