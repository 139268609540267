import styled, {css} from 'styled-components'

interface IOption {
    $selected: boolean
}

export const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    display: inline-flex;
    padding: 4px;
    height: 40px;
`

export const Option = styled.div<IOption>`
      border-radius: 4px;
      flex-grow: 1;
      border: none;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      justify-content: center;
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      color: ${({theme}) => theme.colors.deepBlue[4]};
      background-color: ${({theme}) => theme.colors.pureWhite};
      :hover{
        background-color: ${({theme}) => theme.colors.performBlue[1]};
        cursor: pointer;
      }
      
      ${props => props.$selected && css`
        background-color: ${({theme}) => theme.colors.performBlue[5]};
        color: white;
    
        :hover{
          background-color: ${({theme}) => theme.colors.performBlue[5]};
          cursor: default;
        }
      `}
`
