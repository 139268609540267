import React, {useEffect} from "react";
import ReactTooltip from "react-tooltip";
import {Link, Outlet, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../common/hooks";
import {BackButton, Container, ExitButton} from "./CreateOffers.style";
import {BackIcon, ExitIcon} from "../../../../assets/svgs/SvgIcons";
import {
    setShowExit,
} from "./create-offer.slice";
import {useInitialDataFetch} from "./hooks/useInitialDataFetch";
import {Loading} from "../../../../common/hoc/withUserPreferences/withUserPreferences.style";


const targetOrigin = process.env.REACT_APP_API_BASE_URL

interface Props {
    replicate?: boolean,
    draft?: boolean
}

const CreateOffers: React.FC<Props> = ({replicate = false, draft = false}) => {
    const { prevPage, showExit, showBack } = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    const exit = () => {
        targetOrigin && window.parent.postMessage({id: 1}, targetOrigin)
    }

    const {id} = useParams()
    
   useEffect(()=>{
       dispatch(setShowExit(true))
   },[])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const {loading} = useInitialDataFetch(id, replicate, draft)

    if(loading) return <Loading/>

    return (
        <Container>
            <ReactTooltip
                class={"react-tooltip-container"}
                arrowColor={"white"}
                effect="solid"
                type={'light'}
                multiline={true}/>
            {showBack && <Link to={prevPage}>
                <BackButton>
                    <BackIcon/>
                </BackButton>
            </Link> }
            { showExit && <ExitButton onClick={exit}>
                <ExitIcon/>
                </ExitButton>
            }
            <Outlet/>
        </Container>
    );
};

export default CreateOffers