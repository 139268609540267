import React, {useEffect, useState} from 'react';
import ReactTooltip from "react-tooltip";
import {CheckboxesGroup, TrackingSystemBox, TrackingSystemHolder} from "../TrackingSystem.style";
import {CheckedIcon} from "../../../../../../../../assets/svgs/SvgIcons";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {
    ADJUST,
    APPSFLYER,
    BRANCH,
    KOCHAVA,
    IFetchTrackingSystems,
    TRACKING_SYSTEMS,
    SINGULAR, OTHER
} from "../../../../interfaces/interfaces";
import {setOffer} from "../../../../create-offer.slice";
import adjustImage from "../../../../../../../../assets/images/tracking-system/adjust.svg";
import appsflyerImage from "../../../../../../../../assets/images/tracking-system/appsflyer.svg";
import branchImage from "../../../../../../../../assets/images/tracking-system/branch.svg";
import kochavaImage from "../../../../../../../../assets/images/tracking-system/kochava.png";
import singularImage from "../../../../../../../../assets/images/tracking-system/singular.svg";
import {TrackingUrl} from "../../../../components/TrackingUrl";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {DestinationParamsSelect} from "../../../../components/DestinationParamsSelect";
import {Expandable} from "../../../../components/Expandable";
import {ImpressionTrackingUrl} from "../../../../components/ImpressionTrackingUrl";
import Checkbox from "../../../../../../../../common/components/checkbox/Checkbox";
import {Tooltip} from "../../../../shared-components/Shared";
import {ENABLE_VIEW, REQUIRE_IMPRESSION_TRACKING} from "../../../../shared-components/consts";
import {
    ADJUST_INSTRUCTIONS,
    ANDROID_NETWORK_ID,
    APPSFLYER_INSTRUCTIONS, BRANCH_INSTRUCTIONS,
    IOS_NETWORK_ID, KOCHAVA_INSTRUCTIONS
} from "../../../../../../../../common/utils/consts";


export const TrackingSystemMobile: React.FC = () => {
    const dispatch = useAppDispatch()
    const {offer, trackingSystems} = useAppSelector(state => state.createOffer)
    const [instructions, setInstructions] = useState<string>("")
    const findTrackingSystem = (slug : TRACKING_SYSTEMS) : number | null =>{
        let ts : IFetchTrackingSystems | undefined = trackingSystems
            .find((ts : IFetchTrackingSystems) => ts.slug === slug)
        return ts ? ts.tracking_system_id : null
    }
    const handleOnClick = (type: TRACKING_SYSTEMS) => {
        type !== offer.trackingSystem && dispatch(setOffer({
            trackingSystem: type,
            preview_url: '',
            tracking_system_id : findTrackingSystem(type)
        }))
    }
    const handleAddMacro = (param: string) => {
        dispatch(setOffer({
            destination_url: offer.destination_url + param,
            destination_url_copy: offer.destination_url + param
        }))
    }
    const handleRequireImpressionTracking = () => {
        dispatch(setOffer({impression_tracking_required: !offer.impression_tracking_required}))
    }
    const handleEnableView = () => {
        dispatch(setOffer({convert_impression: !offer.convert_impression}))
    }
    useEffect(() => {
        ReactTooltip.rebuild()
    })
    
    useEffect((
    ) => {
        switch (offer.trackingSystem){
            case ADJUST:
                setInstructions(ADJUST_INSTRUCTIONS)
                 break;
            case APPSFLYER:
                setInstructions(APPSFLYER_INSTRUCTIONS)
                break;
            case BRANCH:
                setInstructions(BRANCH_INSTRUCTIONS)
                break;
            case KOCHAVA:
                setInstructions(KOCHAVA_INSTRUCTIONS)
                break;
        }
    }, [offer.trackingSystem])
    const appendMarcos = () => {
        /**
         * @todo
         * fix when we get response from Brittany regarding number of events
         */
        let macrosToAdd = trackingSystems.find(system => system.slug === offer.trackingSystem)?.tracking_params_and_macros
    
        if (offer.trackingSystem === KOCHAVA){
            switch (offer.operating_system_id){
                case 2:
                    macrosToAdd += "&network_id=" + IOS_NETWORK_ID
                    break;
                case 1:
                    macrosToAdd += "&network_id=" + ANDROID_NETWORK_ID
                    break;
            }
        }
        if (offer.trackingSystem === SINGULAR){
            macrosToAdd = "&_smtype=3"
        }
        dispatch(setOffer({
            destination_url: (offer.destination_url_copy + offer.destination_custom_macros + (macrosToAdd ? macrosToAdd : '') + offer.destination_url_event_macros).replace("?&", "?"),
            destination_url_copy: offer.destination_url.split("?")[0] + "?",
            destination_url_ts_macros: macrosToAdd
        }))
    }
    return (
        <>
            <TrackingSystemHolder>
                <TrackingSystemBox active={offer.trackingSystem === ADJUST}
                                   onClick={() => handleOnClick(ADJUST)}>
                    <div>
                        <img src={adjustImage} style={{maxWidth: 150}} alt=""/>
                        <CheckedIcon display={offer.trackingSystem === ADJUST ? 'block' : 'none'}
                                     className={"checked-icon"}/>
                    </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === APPSFLYER}
                                   onClick={() => handleOnClick(APPSFLYER)}>
                    <div>
                        <img src={appsflyerImage} style={{maxWidth: 153}} alt=""/>
                        <CheckedIcon display={offer.trackingSystem === APPSFLYER ? 'block' : 'none'}
                                     className={"checked-icon"}/>
                    </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === BRANCH}
                                   onClick={() => handleOnClick(BRANCH)}>
                    <div>
                        <img src={branchImage} style={{maxWidth: 130}} alt=""/>
                        <CheckedIcon display={offer.trackingSystem === BRANCH ? 'block' : 'none'}
                                     className={"checked-icon"}/>
                    </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === KOCHAVA}
                                   onClick={() => handleOnClick(KOCHAVA)}>
                    <div>
                        <img src={kochavaImage} style={{maxWidth: 153}} alt=""/>
                        <CheckedIcon display={offer.trackingSystem === KOCHAVA ? 'block' : 'none'}
                                     className={"checked-icon"}/>
                    </div>
                </TrackingSystemBox>
                <TrackingSystemBox active={offer.trackingSystem === SINGULAR}
                                   onClick={() => handleOnClick(SINGULAR)}>
                    <div>
                        <img src={singularImage} style={{maxWidth: 163}} alt=""/>
                        <CheckedIcon display={offer.trackingSystem === SINGULAR ? 'block' : 'none'}
                                     className={"checked-icon"}/>
                    </div>
                </TrackingSystemBox>
            </TrackingSystemHolder>
            {
                offer.trackingSystem &&
                <div className={offer.trackingSystem}>
                    <ol>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>If this is your first time running an campaign, ensure
                                    you have added Perform[cb] as a partner in
                                    <span className="text-capitalize"> {offer.trackingSystem} </span>. If you have not
                                    completed this step please&nbsp;
                                    { offer.trackingSystem !== SINGULAR && <>follow
                                        <a className={"link"} href={instructions} target={"_blank"}>
                                            &nbsp;these setup instructions </a> or &nbsp;</> }
                                     speak with your account manager to learn more
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>
                                    Visit <span className="text-capitalize"> {offer.trackingSystem}</span> and find your
                                    tracking link for this campaign and copy it to
                                    your clipboard
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>Using the field below paste the link
                                    from <span className="text-capitalize"> {offer.trackingSystem}</span>
                                </div>
                                <TrackingUrl/>
                                {offer.trackingSystem === APPSFLYER ? <>
                                    <div className={"list-item-label"}>
                                        <div  style={{marginBottom: '12px'}}>
                                            <strong>
                                                Event parameters and macros will be added automatically to your
                                                Campaign Tracking URL in the outcome funnel section
                                            </strong>
                                        </div>
                                        <DestinationParamsSelect onSelect={(param) => handleAddMacro(param)} mobileTS={true}/>
                                    </div>
                                </> : null}
                            </div>
                        </li>
                        {offer.trackingSystem !== APPSFLYER ? <li>
                            <div className="list-item">
                                {offer.trackingSystem !== OTHER ? <div style={{marginBottom: 16}}>
                                        <div className={"list-item-label"}>
                                            Use the button below specifically made for
                                            <span className={"tracking-system-span"}> {offer.trackingSystem} </span>
                                            after pasting your URL above to add the
                                            parameters and macros needed to successfully track your clicks & conversions
                                            or enter your own parameters and use the other buttons to place the
                                            associated macros for them
                                            <br/> <br/>
                                            <strong>
                                                Event parameters and macros will be added automatically to your
                                                Campaign Tracking URL in the outcome funnel section
                                            </strong>
                                        </div>
                                        <ButtonStyled
                                            onClick={() => appendMarcos()}
                                            className={"btn-blue-outlined btn-block btn-size-lg full-radius tracking-system-btn btn-uppercase"}>
                                            add {offer.trackingSystem} parameters & macros</ButtonStyled>
                                    </div> :
                                    <div className={"list-item-label"}>
                                        After pasting your URL above enter your own parameters and use the other
                                        buttons to place the associated macros for them
                                    </div>
                                }
                                <DestinationParamsSelect onSelect={(param) => handleAddMacro(param)} mobileTS={true}/>
                            </div>
                        </li> : null}
    
    
                        { offer.trackingSystem === ADJUST && <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>
                                    If a campaign name has not been added in the tracking URL you will need to add one
                                    after the parameter “campaign=” above
                                </div>
                            </div>
                        </li> }
                        { offer.trackingSystem === APPSFLYER && <li>
                            <div className={"list-item"}>
                                <div className={"list-item-label"}>
                                    If a campaign name has not been added in the tracking URL you will need to add one
                                    after the parameter "c=" above
                                </div>
                            </div>
                        </li> }
             
                        
                    </ol>
                    <Expandable title={"Impression Tracking"} locked={false}>
                        <ImpressionTrackingUrl/>
                        <CheckboxesGroup>
                            <div className={"checkbox-with-external-tooltip"}>
                                <Checkbox checked={offer.impression_tracking_required}
                                          disabled={offer.impression_tracking_url?.length === 0}
                                          label={"Require Tracking Impression"}
                                          onChange={handleRequireImpressionTracking}/>
                                {Tooltip(178, REQUIRE_IMPRESSION_TRACKING)}
                            </div>
                            <div className={"checkbox-with-external-tooltip"}>
                            <Checkbox checked={offer.convert_impression}
                                      disabled={offer.impression_tracking_url?.length === 0}
                                      label={"Enable View Through Attribution"}
                                      onChange={handleEnableView}/>
                                {Tooltip(178, ENABLE_VIEW)}
                            </div>
                        </CheckboxesGroup>
                    </Expandable>
                </div>
            }
        </>
    );
};