import React from "react";

import {Wrapper} from "./Alert.style";

import {IAlertProps} from "./alert.props";

const Alert: React.FC<IAlertProps> = ({alertType, wrapperClassName, children}) => {
    return <Wrapper type={alertType} className={wrapperClassName}>
        {children}
    </Wrapper>
}

export default Alert