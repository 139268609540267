import {SelectOption} from "../../../../../common/components/select/Select";

export const ACTIVE = "active"
export const DISABLED = "disabled"
export const COMPLETED = "completed"
export const INCOMPLETED = "incompleted"
export const ADJUSTED = "adjusted"

export const navItemsProps = [
    ACTIVE,
    DISABLED,
    COMPLETED,
    INCOMPLETED,
    ADJUSTED,
] as const
export const OFFER_INFO = "offer-info"
export const TRACKING_SYSTEM = "tracking-system"
export const LAND_AND_TRACK = "land-and-track"
export const OUTCOME_FUNNEL = "outcome-funnel"
export const TRACKING_CODE = "tracking-codes"
export const TESTING = "testing"
export const CREATIVES = "creatives-and-email"
export const BUDGET = "budget"
export const TARGETING = "targeting"
export const SCHEDULING = "scheduling"
export const BLANK = ""

export const SALES = "sales"
export const LEADS = "leads"
export const APPS = "apps"
export const CLICKS = "clicks"
export const CALLS = "calls"

export type OFFER_TYPE = typeof BLANK | typeof SALES | typeof LEADS | typeof APPS | typeof CLICKS | typeof CALLS

export const TUNE = "tune"
export const IMPACT = "impact"
export const CAKE = "cake"
export const EVERFLOW = "everflow"
export const GOOGLE_ANALYTICS = "google-analytics"
export const OTHER = "other"
export const NONE = "none"
export const ADJUST = "adjust"
export const APPSFLYER = "appsflyer"
export const BRANCH = "branch"
export const KOCHAVA = "kochava"
export const SINGULAR = "singular"


export type TRACKING_SYSTEMS = typeof TUNE |
    typeof IMPACT |
    typeof CAKE |
    typeof EVERFLOW |
    typeof GOOGLE_ANALYTICS |
    typeof OTHER |
    typeof NONE |
    typeof ADJUST |
    typeof APPSFLYER |
    typeof BRANCH |
    typeof KOCHAVA |
    typeof SINGULAR |
    typeof BLANK

export const ENGAGEMENT = "appEngagement"
export const INSTALL = "appInstall"
export type MOBILE_TYPE =  typeof BLANK |  typeof ENGAGEMENT |  typeof INSTALL

export const IOS = "ios"
export const ANDROID = "android"
export const TABLET = "tablet"
export const MOBILE = "mobile"

export type MOBILE_PLATFORM = typeof BLANK |  typeof IOS |  typeof ANDROID

export const CPC = "cpc"
export const CPA = "cpa"
export const CPS = "cps"
export const CPL = "cpl"
export const CPI = "cpi"
export const CPE = "cpe"
export const PPC = "ppc"


export type PRICING_MODEL = typeof CPA | typeof CPC

export type DETAILED_PRICING_MODEL = typeof CPS | typeof CPL | typeof CPI | typeof CPE | typeof PPC | typeof CPC

export const navigationList = [
    OFFER_INFO,
    TRACKING_SYSTEM,
    LAND_AND_TRACK,
    OUTCOME_FUNNEL,
    TRACKING_CODE,
    TESTING,
    CREATIVES,
    BUDGET,
    TARGETING,
    SCHEDULING
] as const

type ObjectFromList<T extends ReadonlyArray<string>, V = string | undefined> = {
    [K in (T extends ReadonlyArray<infer U> ? U : never)]: V
};

export type NavState = ObjectFromList<typeof navigationList, typeof navItemsProps[number]>

export interface IDelayedActionsFields {
    field_name: string,
    description: string,
    model?: string
}
export interface IDelayedActionsType {
    compliance_delayed?: IDelayedActionsFields[] | null,
    time_delayed?:  IDelayedActionsFields[] | null
}

export type IDelayedActionsSections = ObjectFromList<typeof navigationList, IDelayedActionsType | null>

export interface ICustomDomain {
    is_default: number;
    domain: string;
    id: number;
    name_servers: string;
    status: string;
    user_id: number
}

export interface ICustomDomainParams {
    user_id: number;
    status : string;
}

export interface IRevShare {
    id: string,
    name: string,
    value: string | undefined,
    description?: any,
}

type TrafficType = {selected: boolean} & ITrafficType

type OsTargetingType = {
    tablet: Record<string, 0 | 1>
    mobile: Record<string, 0 | 1>
}

export interface IZipcodeFile {
    data: string[],
    name: string,
    error: string | boolean,
    uploaded: boolean
    loading: boolean
}

export interface IOfferSchedule {
    id: string,
    campaign_id: string | number,
    from_day: number | string,
    from_hour: number | string,
    from_minute: number | string,
    to_hour: number | string,
    to_minute: number | string,
}

export interface IEventValidate {
   [key: string]: string | undefined
}

export interface IFileCreative {
    id: number,
    name: string,
    extension: string,
    width?: number,
    height?: number,
    size?: number,
    created_at?: string,
}

export const PENDING = "pending"

export type CreativeStatus = typeof PENDING | typeof ACTIVE | typeof DISABLED

export const PRODUCT_IMAGE = "product_image"
export const PRODUCT_VIDEO = "product_video"
export const BANNER = "banner"

export type CreativeType = typeof PRODUCT_IMAGE | typeof PRODUCT_VIDEO | null

export interface ICreative {
    id: number,
    campaign_id: number,
    type: CreativeType | typeof BANNER,
    status?:   typeof PENDING | typeof ACTIVE | typeof DISABLED,
    name: string,
    files: IFileCreative[],
    upsell_id?: null | number,
    text_creative?: string,
    is_available?: boolean,
    is_email?: boolean
}

export interface IOffer {
    max_bid: string | undefined;
    id: number,
    status: string,
    type: OFFER_TYPE
    name: string,
    brand_name: string,
    description: string,
    niche: INiche | null,
    niche_id: number,
    operating_system_id: null | number,
    custom_domain: ICustomDomain | null,
    custom_domain_id: number,
    domain_id: number | null,
    trackingSystem: TRACKING_SYSTEMS | null,
    tracking_system_id: number | null,
    destination_url: string,
    destination_url_copy: string,
    destination_url_event_macros: string,
    destination_url_ts_macros: string,
    destination_custom_macros : string,
    direct_linking: null | boolean
    legacy_tracking: null | boolean
    domain: string,
    auto_populate_domain: boolean,
    domain_parameter_id: string
    mobile_platform: MOBILE_PLATFORM
    mobile_type : MOBILE_TYPE
    landing_page_url: string
    impression_tracking_url: string,
    impression_tracking_required: boolean,
    convert_impression: boolean,
    preview_url: string,
    testing : string,
    testing_link: string,
    app_url: string,
    play_store_url: string,
    upsells : any[],
    tracking_code_placed: boolean | null,
    budget: number,
    budget_value_type: number,
    budget_type: string | null,
    budget_group_id: number | null,
    budget_group: IBudgetGroups | null,
    orig_budget_value_type: number | null,
    orig_budget_group_id: number | null,
    campaign_budget_group_info: any[] | null,
    pixel_group_id: null | number,
    pixel_group: null | IFetchPixelGroupsResponse
    link_test_budget_type: string | null,
    link_test_budget_value: number,
    link_test_budget: number,
    creatives: ICreative[],
    additionalCreative: any,
    is_email : boolean,
    traffic_types: TrafficType[] | null,
    desktop: boolean,
    mobile: boolean,
    tablet: boolean,
    os_targeting: OsTargetingType,
    targeted_locations: ILocation[]
    excluded_locations: ILocation[]
    selected_cities: IFetchCitiesResponse[]
    cities_type: string
    selected_isp: IFetchIspListResponse[]
    isp_type: string,
    selected_zipcodes: string[],
    zipcode_files: IZipcodeFile[],
    zipcode_type: string,
    selected_zipcode: string,
    start_date_option: 0 | 1,
    end_date_option: 0 | 1,
    start_date: string | undefined | null
    end_date: string | undefined | null
    campaign_schedule: IOfferSchedule[]
    pricing_model : PRICING_MODEL | null,
    detailed_pricing_model : DETAILED_PRICING_MODEL | null,
    campaign_tracking_settings: {
        campaign_id: null | number,
        conversion_page_url: string | null,
        parallel_tracking_enabled: boolean,
        click_tracking_url: null | string,
        tracking_domain: null | string,
        auto_populate_domain: boolean,
        tracking_clickid_parameter: null | string,
        conversion_tracking_url: null | string,
        click_tracking_url_copy: string
    },
    cpa: any,
    is_revshare: boolean,
    revshare: IRevShare[],

    payout_modifiers: any[],
    campaign_email_setting: {
        id: number | null,
        campaign_id: number | null,
        from_lines: string,
        subject_lines: string,
        supression_list?: string,
        supression_list_value1?: string,
        supression_list_value2?: string,
        unsubscribe_type?: string,
        unsubscribe_type_value: string,
        created_at?: string,
        updated_at?: string,
        suppression_list_value1_aff?: string,
        suppression_list_value2_aff?: string,
        suppression_list_aff?: string,
        unsubscribe_type_aff?: string,
        unsubscribe_type_value_aff?: string,
        from_lines_aff?: string,
        subject_lines_aff?: string,
        campaign?: null,
    }
    delayedActions: IDelayedActionsSections | null
}


export interface ICreateOffer {
    offer : IOffer,
    createOfferState: {
        loading: boolean,
        error: null | string
    },
    emptyOffer: any,
    appsSelected: boolean,
    accountBalance: IFetchAccountBalanceResponse | null
    fieldValidation: string,
    initialEditOffer: string,
    
    saveDraftOffer: string,
    validation: {
        validateOfferName : string | undefined,
        validateConversionPage: string | undefined,
        validateOfferTrackingUrl : string | undefined,
        validateEvent: IEventValidate,
        validateEventPostbackUrl: IEventValidate,
        validateLandingPageUrl : string | undefined,
        validateAppOrGoogleStoreUrl : string | undefined,
        validateConversionPostbackUrl: string | undefined,
        validatePreviewUrl: string | undefined,
        validateImpressionTrackingUrl: string | undefined,
    }
    navLocked: boolean,
    navWasLocked: boolean,
    niches : INiche[],
    customDomains: ICustomDomain[],
    budgetGroups: IBudgetGroups[],
    nextPage : string,
    prevPage : string,
    showExit: boolean,
    showBack: boolean,
    advancedTargetingExpanded: boolean
    advancedSchedulingExpanded: boolean
    pixelGroups: {
        upsell:  IFetchPixelGroupsResponse[],
        offer:  IFetchPixelGroupsResponse[],
        loading: boolean
    },
    trafficGroups: {
        loading: boolean,
        data: IFetchTrafficGroupsResponse[] | null
        error: string | null
    },
    trafficTypes : ITrafficType[],
    geoData: {
        loading: boolean,
        data: {
            cities: IFetchCitiesResponse[] | null,
            continents: IFetchContinentsResponse[] | null,
            regions: IFetchRegionsResponse[] | null
            countriesState: IFetchCountryResponse | null
        },
        error: string | null
    },
    isps: {
        loading: boolean,
        error: null | string
        data: {
            isps: IFetchIspListResponse[]
        }
    }
    selectedBudgetAmountType : SelectOption,
    selectedBudgetGroupAmountType : SelectOption,
    trackingSystems : IFetchTrackingSystems[],
    cpcMainConversionPanelVisibility: boolean,
    navState: NavState,
    requiredToSubmit: typeof navigationList[number][],
    optionalToSubmit: typeof navigationList[number][],
    lastCreativeId: number,
    lastSaved: Date | null
}

// export type TRACKING_SYSTEMS = "" | "tune" | "impact" | "cake" | "everflow" | "google-analytics" | "other" | "none" | "adjust" |
//     "appsflyer" | "branch" | "kochava" | "singular"



// export type NAVIGATION_ITEM = "offerInfo" | "trackingSystem" | "landAndTrack" | "outcomeFunnel" | "trackingCodes" |
//     "testing" | "creativesAndEmails" | "budget" | "targeting" | "scheduling"


// export type NAVIGATION_ITEM = new Set<string>(["offerInfo" , "trackingSystem" , "landAndTrack" , "outcomeFunnel" ,
//     "trackingCodes" , "testing" , "creativesAndEmail" , "budget" , "targeting" , "scheduling"])

export interface ISubcategory {
    id: number;
    name: string;
    parent_id: number;
    created_at: string;
    updated_at: string;
    description: string;
    status: string;
    min_cpc_bid: number;
    restrictions: string;
    first_n_clicks_count: number;
    after_n_clicks_count: number;
    campaign_name: string;
    campaign_url: string;
    brand_name: string;
    brand_campaign_url: string;
    default_conversion_rate: number;
    access: number;
    test_click_threshold?: number;
    shipping_value: string;
    currency_id?: number;
    network_id: number;
    forensiq_score?: number;
    financial_group: number;
    big_niche_id: number;
}

export interface INiche {
    id: number;
    name: string;
    parent_id: number;
    created_at: string;
    updated_at: string;
    description: string;
    status: string;
    min_cpc_bid: number;
    restrictions: string;
    first_n_clicks_count?: number;
    after_n_clicks_count: number;
    campaign_name: string;
    campaign_url: string;
    brand_name: string;
    brand_campaign_url: string;
    default_conversion_rate: number;
    access: number;
    test_click_threshold?: number;
    shipping_value: string;
    currency_id?: number;
    network_id: number;
    forensiq_score?: any;
    financial_group: number;
    big_niche_id: number;
    min_bid: string;
    max_bid: string;
    shipping_and_handling_available: boolean;
    fields: any[];
    subcategories: ISubcategory[];
}

export interface IClickInfo {
    "User-Agent": string;
    click_id: string;
    date: Date;
    host: string;
    referer?: any;
    ts: number;
    "utc-date": Date;
}

export interface ITestTrackingPixelItems {
    ip: string;
    _id: {
        "$oid": string
    };
    tid: string;
    type: string;
    device_type: string;
    click_info: IClickInfo;
}

export interface ITestTrackingPixel {
    items: ITestTrackingPixelItems[],
    time: number
}

export interface IBudgetGroups {
    id: number;
    advertiser_id: number;
    name: string;
    budget_type: string;
    budget: number;
    max_daily_spike?: any;
    approaching_daily_spike: boolean;
    paused_daily_spike_reached: number;
    budget_value_type: number;
    test_budget_link_type: string;
    test_budget_link: number;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
    budget_spent: boolean;
    budget_spent_until?: any;
    approaching_25_percent: boolean;
    delayed_actions?: any;
    meta_data?: any;
    campaigns: any[];
}

export interface IFetchAccountBalanceResponse {
    balance: boolean,
    card: boolean
}

export interface IFetchCurrenciesResponse {
    id: number;
    code: string;
    sign: string;
    pretty_name: string;
}

export interface ICreateBudgetGroupParams {
    budget_type: string;
    test_budget_link_type: string;
    budget_value_type: number;
    name: string;
    budget: string;
    test_budget_link: string;
}

export interface IFetchPixelGroupsResponse {
    id: number;
    advertiser_id: number;
    name: string;
    group_type: string;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
    offers_upsells: number;
    upsells: any[];
    offers: any[];
    postback_whitelist_ips: any[];
}

export interface ICreatePixelGroupParams {
    campaign_id: number,
    group_type: 'upsell' | 'offer'
    name: string,
    only_upsell?: boolean
    only_offer?: boolean
}

export interface ICreateUpsellResponse {
    id: number;
    campaign_id: number;
    name: string;
    payout: number;
    created_at: string;
    updated_at: string;
    network_campaign_group_id?: any;
    edit_locked: number;
    pixel_group_id?: any;
    delayed_pixel_group_id?: any;
    deleted_at?: any;
    rev?: any;
}

export interface ITrafficType {
    id: number;
    traffic_group_id: number;
    name: string;
    created_at: string;
    updated_at: string;
    selected: boolean
}


export interface IFetchTrafficGroupsResponse {
    id: number;
    name: string;
    user_type: string;
    created_at: string;
    updated_at: string;
    types: ITrafficType[];
}

export interface ICountry {
    id: number;
    code: string;
    code_3c: string;
    name: string;
    order: number;
}

export interface IState {
    id: number;
    code: string;
    name: string;
    country_id: number;
}

export interface IPivot {
    location_id: number;
    region_id: number;
}

export interface Region {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    long_name: string;
    pivot: IPivot;
}

export interface ILocation {
    id: number;
    country_id: number;
    state_id?: number;
    country_code: string;
    state_code: string;
    name: string;
    continent_code: string;
    code: string;
    type: string;
    simple_name: string;
    country: ICountry;
    state: IState;
    regions: Region[];
    pivot: IPivot;
}

export interface IFetchCountryResponse {
    countries: ICountry[];
    states: IState[];
    locations: ILocation[];
}

export interface IFetchContinentsResponse {
    id: number;
    name: string;
    code: string;
    long_name: string;
    locations: ILocation[];
}

export interface IFetchRegionsResponse extends IFetchContinentsResponse {

}

export interface IFetchCitiesResponse {
    id: number;
    name: string;
    state_name: string;
    maxmind_id: number;
    metro_code: string;
    state: string;
}

export interface IFetchGeoDataResponse {
    cities: IFetchCitiesResponse[],
    countriesState: IFetchCountryResponse,
    continents: IFetchContinentsResponse[],
    regions: IFetchRegionsResponse[],
}

export interface IFetchIspListResponse {
    isp: string,
    isp_id: string
}

export interface IFetchTrackingSystems {
    tracking_system_id: number,
    name: string,
    slug: string,
    tracking_params_and_macros: string,
    direct_linking_click_id: string,
    page_and_postback_params_and_macros: string
}

