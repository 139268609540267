import React, {useState} from 'react'
import {setOffer} from "../../../../create-offer.slice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {
    SelectedCountries,
    SelectedCountryPill,
    TargetingSection,
} from "../Targeting.style";
import Input from "../../../../../../../../common/components/input/Input";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import UploadZipCode from "../upload-zip-code/UploadZipCode";
import Modal from "../../../../../../../../common/components/modal/Modal";
import MultiOptionSwitch, {
    OptionType
} from "../../../../../../../../common/components/multi-option-switch/MultiOptionSwitch";

const switchOptions: OptionType[] = [
    {
        value: 'All',
        id: 'all'
    },
    {
        value: 'Include',
        id: 'include'
    },
    {
        value: 'Exclude',
        id: 'exclude'
    }
]

const ZipcodeTargeting: React.FC = () => {
    // const {campaign} = useAppSelector(state => state.advertiserOffersCpa)
    const dispatch = useAppDispatch()
    const {offer} = useAppSelector(state => state.createOffer)

    const [uploadZipCodeModalOpened, setUploadZipCodeModalOpened] = useState(false)

    const handleZipCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid){
            dispatch(setOffer({selected_zipcode: e.target.value}))
        }
    }

    const handleZipRemove = (zip: string) => {
        const newZips = [...offer.selected_zipcodes.filter((zipCurrent: any) => zipCurrent !== zip)]
        dispatch(setOffer({
            selected_zipcodes: [...newZips]
        }))
    }

    return <>
        <div>
            <TargetingSection>
                <h3>ZIP Code Targeting</h3>
                <MultiOptionSwitch
                    options={switchOptions}
                    selected={offer.zipcode_type}
                    onSelect={(option) => dispatch(setOffer({zipcode_type: option.id as string}))}
                    wrapperClassName={'targeting-select'}
                />
            </TargetingSection>
            {offer.zipcode_type !== 'all' &&
                <>
                    <Input
                        wrapperClassName={'zip-code-input-wrapper'}
                        placeholder={'Enter ZIP Code'}
                        value={offer.selected_zipcode}
                        onChange={(e) => handleZipCodeInput(e)}
                        onKeyUp={(e) => {
                            if(e.key === 'Enter'){
                                dispatch(setOffer({
                                    selected_zipcodes: [...offer.selected_zipcodes, e.currentTarget.value],
                                    selected_zipcode: ''
                                }))
                            }
                        }
                        }
                        pattern={"[0-9]*"}
                    />
                    {offer.selected_zipcodes.length > 0 &&
                        <SelectedCountries>
                            {offer.selected_zipcodes.map((zip: any, index) => {
                                return <SelectedCountryPill  style={{border: index === offer.selected_zipcodes.length - 1 ? 'none' : ''}} key={zip}>
                                    {zip}
                                    <i className={'ico-Exit'} onClick={() => handleZipRemove(zip)}/>
                                </SelectedCountryPill>
                            })}
                        </SelectedCountries>
                    }
                    <div style={{textAlign: "right", marginTop: '8px'}}>
                        <ButtonStyled
                            className={'btn-orange-filled'}
                            onClick={() => setUploadZipCodeModalOpened(true)}
                        >
                            Upload ZIP Codes
                        </ButtonStyled>
                        <ButtonStyled
                            className={'btn-default-outlined'}
                            onClick={() => dispatch(setOffer({selected_zipcodes: []}))}
                        >
                            Clear All
                        </ButtonStyled>
                    </div>
                </>
            }
            <Modal title={'Upload Zipcodes'} opened={uploadZipCodeModalOpened} closeModal={() => setUploadZipCodeModalOpened(false)}>
                <UploadZipCode closeModal={() => setUploadZipCodeModalOpened(false)}/>
            </Modal>
        </div>
    </>
}

export default ZipcodeTargeting