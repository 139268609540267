export const buildUrl = (url: any, query: any, remove_params?: any) =>  {

    let parser = document.createElement('a');

    parser.href = (url.includes('http://') || url.includes('https://')) ? url : 'http://' +  url

    let queryData = parser.search.split('?');

    if (queryData && queryData[0] == "") {
        queryData.shift();
    }

    if(queryData.length && remove_params && remove_params.length) {
        let queryDataParams: any[] = [];
        queryData[0].split('&').forEach(function(item) {
            let queryArg = item.split('=');

            if(remove_params.indexOf(queryArg[0]) == -1) {
                queryDataParams.push(item);
            }
        });

        queryData = queryDataParams;

    }

    if(query) {
        for(let key in query) {
            queryData.push(key + "=" + query[key]);
        }
    }

    parser.search = queryData.join("&");

    return parser.href;
}