import React, {useEffect, useMemo, useState} from "react";
import ReferralsService, {IReferralParams, IReferralReportData} from "../../Referrals.service";
import {useAppSelector} from "../../../../../../common/hooks";
import {tokenStore} from "../../../../../../store/auth.slice";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import {
    StatsTableContainer,
    StatsTableTitle,
    StatsWidgetContainer,
    StatsWidgetItem,
    StatsWidgetLoading
} from "./StatsWidget.style";
import Grid from "../../../../../../common/components/grid/Grid";
import {
    FiltersRowLeft,
    FiltersRowRight
} from "../../../../../../common/components/filters/filters-row/FiltersRow.style";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import FiltersRow from "../../../../../../common/components/filters/filters-row/FiltersRow";
import {format} from "date-fns";

const StatsWidget = () => {

    const[isLoading, setIsLoading] = useState(true);
    const[lifetimeCommission, setLifetimeCommission] = useState('');
    const[lastMonthsCommission, setLastMonthsCommission] = useState('');
    const[thisMonthsCommission, setThisMonthsCommission] = useState('');
    const[activeReferrals, setActiveReferrals] = useState('');
    const[totalReferrals, setTotalReferrals] = useState('');

    // Active Referrals Table
    const [tableData, setTableData] = useState<IReferralReportData[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [perPage, setPerPage] = useState<number>(10)
    const [lastPage, setLastPage] = useState<number>(0)

    const token = useAppSelector(tokenStore)

    const columnDefs = useMemo(() => [
        {
            field: "id", headerName: "ID", width: 66,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.id}</span>
            }
        },
        {
            field: "name", headerName: "NAME", width: 176,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.name + " " + data.lastname}</span>
            }
        },
        {
            field: "user_type", headerName: "REFERRAL TYPE", width: 176,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.user_type === 'affiliate' ? "Partner" : "Marketer"}</span>
            }
        },
        {
            field: "start_date", headerName: "START DATE", width: 176,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{format(new Date(data.start_date), 'yyyy-MM-dd')}</span>
            }
        },
        {
            field: "end_date", headerName: "END DATE", width: 176,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{format(new Date(data.end_date), 'yyyy-MM-dd')}</span>
            }
        },
        {
            field: "revenue", headerName: "LIFETIME COMMISSION", width: 193,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{(formatNumberToCurrency(Number(data.revenue)))}</span>
            }
        }
    ], [])

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const fetchReferralsTableData = async () => {
        setLoading(true)
        let params:IReferralParams = {
            active_referrals: true
        }
        if (currentPage !== 1 ) params.page = currentPage
        if (perPage !== 50) params.paginate_by = perPage

        let {data} = await ReferralsService.fetchReferralReportTable(token, params)

        setTableData(data.user_referees.data)
        setFrom(data.user_referees.from)
        setLastPage(data.user_referees.last_page)
        setTo(data.user_referees.to)
        setTotal(data.user_referees.total)
        setPerPage(parseInt(data.user_referees.per_page))

        if (perPage > data.user_referees.total)
            setCurrentPage(1)
        else
            setCurrentPage(data.user_referees.current_page)
        setLoading(false)
    }


    const getReferralStats = async () => {
        let stats = await ReferralsService.fetchReferralOverviewSummary(token)
        setLastMonthsCommission(formatNumberToCurrency(stats.data.last_months_commission));
        setThisMonthsCommission(formatNumberToCurrency(stats.data.this_months_commission));
        setActiveReferrals(stats.data.active_referrals);
        setLifetimeCommission(formatNumberToCurrency(stats.data.total_lifetime_commission));
        setTotalReferrals(stats.data.total_referrals);
        setIsLoading(false);
    };

    useEffect(() => {
        token && getReferralStats();
        token && fetchReferralsTableData();
    },[token, perPage, currentPage]);

    const NoActiveReferrals = () => {
        return (
            <div className="no-active-referrals-message">No current active referrals, start inviting to earn even more money!<i
                className="material-icons-outlined">paid</i>
            </div>
        )
    }

    return (
        <>
            <StatsWidgetContainer>
                {isLoading ?
                <StatsWidgetLoading>
                    <div className="loading-dots">
                        <div className={'dot'} /><div className={'dot'}/><div className={'dot'}/>
                    </div>
                </StatsWidgetLoading> : <>
                <StatsWidgetItem>
                    <div className="title">
                        Last Month’s Commission
                        <Tooltip position="top" width={255}>
                            Total commission you earned last month from your referral’s revenue.
                            <br /><br />
                            <strong>Note: </strong>Your earned commission will be included in your first payment of each month.
                        </Tooltip>
                    </div>
                    <div className="data">
                        <div className="icon" style={{background: '#DFFFEC'}}>
                            <i className="ico-Cost" style={{color: '#05C76A'}} />
                        </div>
                        <div className="value">{lastMonthsCommission}</div>
                    </div>
                </StatsWidgetItem>

                <StatsWidgetItem>
                    <div className="title">
                        This Month’s Commission
                        <Tooltip position="top" width={255}>
                            Total commission you have currently earned this month from your referral’s revenue.
                            <br /><br />
                            <strong>Note: </strong>Your earned commission will be included in your first payment of each month.
                        </Tooltip>
                    </div>
                    <div className="data">
                        <div className="icon" style={{background: '#D6FAFF'}}>
                            <i className="ico-Cost" style={{color: '#00CCE8'}} />
                        </div>
                        <div className="value">{thisMonthsCommission}</div>
                    </div>
                </StatsWidgetItem>

                <StatsWidgetItem>
                    <div className="title">
                        Active Referrals
                        <Tooltip position="top" width={170}>
                            Any approved referral that has joined PerformLEAP twelve months from the current date.
                        </Tooltip>
                    </div>
                    <div className="data">
                        <div className="icon" style={{background: '#FFEBEE'}}>
                            <i className="ico-Partners" style={{color: '#F41E5E'}} />
                        </div>
                        <div className="value">{activeReferrals}</div>
                    </div>
                </StatsWidgetItem>

                <StatsWidgetItem>
                    <div className="stacked-data">
                        <div>
                            Total Lifetime Commission
                            <strong>{lifetimeCommission}</strong>
                        </div>
                        <div>
                            Total Lifetime Referrals
                            <strong>{totalReferrals}</strong>
                        </div>
                    </div>
                </StatsWidgetItem>
                </>}
            </StatsWidgetContainer>
            <StatsTableContainer>
                <div style={{marginBottom: 20}}>
                    <FiltersRow>
                        <FiltersRowLeft>
                            <StatsTableTitle>
                                Active Referrals
                            </StatsTableTitle>
                        </FiltersRowLeft>
                        <FiltersRowRight>
                            <Pagination
                                current_page={currentPage}
                                setCurrentPage={setCurrentPage}
                                from={from}
                                to={to}
                                total={total}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                last_page={lastPage}
                                loading={loading} />
                        </FiltersRowRight>
                    </FiltersRow>
                </div>
                <Grid columnDefs={columnDefs}
                      defaultColDef={defaultColumnDefs}
                      rowData={tableData}
                      rowHeight={40}
                      className={`ag-active-referrals ${tableData.length ? "" : "no-active-referrals"}`}
                      domLayout='autoHeight'
                      loading={loading}
                      noDataOpacity={0.5}
                      noDataComponent={<NoActiveReferrals />}
                />
            </StatsTableContainer>
        </>
    )
}

export default StatsWidget
