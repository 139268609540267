import styled from "styled-components";

export const FiltersButtonWrapper = styled.div`
    .filters_button {
        padding: 10px 12px;
        background: ${({theme}) => theme.colors.pureWhite};
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        color: ${({theme}) => theme.colors.deepBlue[5]};
        cursor: pointer;
        border-radius: 8px;
        transition: 0.2s;
        height: 39px;

        &:hover {
            background-color: #F3F7F8;
        }

        &:active {
            border-color: ${({theme}) => theme.colors.coolGray[4]};
            color: ${({theme}) => theme.colors.deepBlue[5]};
        }
        span {
            font-family:${({theme}) => theme.fonts.AvenirLight};
            font-weight: 400;
            font-size: 12px;
        }
    }
`;