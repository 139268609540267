import * as React from 'react';
import {Outlet} from "react-router-dom";
import {AlertStyledContent, Container, OutletContainer, ServerErrorAlert} from "./Main.style";
import {Navigation} from "../navigation/Navigation";
import {Summary} from "../summary/Summary";
import PanelFooter from "./footer/Footer";
import {Footer} from '../footer/Footer'
import {usePageNavigate} from "../../hooks/usePageNavigate";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import Alert from "../../../../../../common/components/alert/Alert";
import {hideServerError} from "../../create-offer.slice";


interface Props {
    replicate?: boolean,
    draft?: boolean
}

const Main: React.FC<Props> = ({replicate = false, draft = false}) => {
    /**
     * for editing offer solution with event listener could be used to dispatch all states
     * if here is not possible to add event listener then it can be added to app.tsx
     */

    const {createOfferState} = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()

    const {handleNextPage, handlePrevPage, nextButtonState, prevButtonState} = usePageNavigate()

    return (
        <>
            <Container>
                <Navigation/>
                <OutletContainer>
                    <Outlet/>
                    <PanelFooter
                        showBack={prevButtonState !== 'hidden'}
                        disabled={nextButtonState === 'disabled'}
                        onNext={handleNextPage}
                        onBack={handlePrevPage}
                        showNext={nextButtonState !== 'hidden'}
                    />


                </OutletContainer>

                <Summary/>
            </Container>
            {createOfferState.error ? <ServerErrorAlert>
                <Alert
                    wrapperClassName={'server-error-alert-wrapper'}
                    alertType={'error'}
                >
                    <AlertStyledContent>
                        {createOfferState.error}
                    </AlertStyledContent>
                    <i className="ico-Exit" onClick={() => dispatch(hideServerError())}/>
                </Alert>
            </ServerErrorAlert> : null}
            <Footer draft={draft} replicate={replicate}/>
        </>
    );
};

export default Main;