import styled from "styled-components";


export const FooterWrapper = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 100px;
    .need-help {
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
        color: ${({theme}) => theme.colors.deepBlue[7]};
        margin-left: 40px;

        a {
            color: #0093D3;
            cursor: pointer;
        }
    }
    .footer-btn-group{
        display: flex;
        gap: 8px;
    }
`