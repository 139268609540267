import * as React from 'react';
import {Container, NavigationMenu, NavigationSection, NavigationToggleButton} from "./Navigation.style";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../../common/hooks";
import {setOptionalToSubmit, setRequiredToSubmit} from "../../create-offer.slice";
import {
    navigationList,
    OFFER_INFO,
    TRACKING_SYSTEM,
    LAND_AND_TRACK,
    OUTCOME_FUNNEL,
    TESTING,
    TRACKING_CODE,
    CREATIVES,
    BUDGET,
    TARGETING,
    SCHEDULING,
    CPC, CPI, CPE, PPC
} from "../../interfaces/interfaces";

import {useLocation} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import NavigationItem from "./NavigationItem";
import {useNavigationItems} from "../../hooks/useNavigationItems";

export interface Props {

}


export const Navigation: React.FC<Props> = ({}) => {
    const {
        offer
    }
        = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    
    const [navOpened, setNavOpened] = useState(false)

    const schedulingFlag = useFeatureFlags('offer-scheduler')
    

    useNavigationItems()

    const requiredToSubmit: typeof navigationList[number][] = useMemo(() => {
        if(offer.detailed_pricing_model === CPC){
            return [
                OFFER_INFO,
                TRACKING_SYSTEM,
                LAND_AND_TRACK,
                OUTCOME_FUNNEL
            ]
        }

        if(offer.detailed_pricing_model === PPC){
            return [
                OFFER_INFO,
                OUTCOME_FUNNEL
            ]
        }

        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE){
            return [
                OFFER_INFO,
                TRACKING_SYSTEM,
                LAND_AND_TRACK,
                OUTCOME_FUNNEL,
                TESTING
            ]
        }

        return [
            OFFER_INFO,
            TRACKING_SYSTEM,
            LAND_AND_TRACK,
            OUTCOME_FUNNEL,
            TRACKING_CODE,
            TESTING
        ]
    }, [offer.detailed_pricing_model])

    const optionalToSubmit: typeof navigationList[number][] = useMemo(() => {
        if(offer.detailed_pricing_model === CPC){
            return [
                TRACKING_CODE,
                TESTING,
                CREATIVES,
                BUDGET,
                TARGETING,
                SCHEDULING
            ]
        }

        if(offer.detailed_pricing_model === PPC){
            return [
                CREATIVES,
                BUDGET,
            ]
        }

        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE){
            return [
                CREATIVES,
                BUDGET,
                TARGETING,
                SCHEDULING
            ]
        }

        return [
            CREATIVES,
            BUDGET,
            TARGETING,
            SCHEDULING
        ]
    }, [offer.detailed_pricing_model])

    useEffect(() => {
        dispatch(setRequiredToSubmit(requiredToSubmit))
        dispatch(setOptionalToSubmit(optionalToSubmit))
    }, [requiredToSubmit, optionalToSubmit])
    
    const location = useLocation()

    return (
        <Container>
            <NavigationToggleButton displayed={!navOpened} onClick={()=> setNavOpened(prev => !prev)}>
                {navOpened ? 
                    <div className={'selected-title'}>
                        Section Menu
                    </div> : 
                    <div className={'selected-title'}>
                        <i className="material-symbols-outlined">arrow_forward</i>
                        <div className={'title'}>
                            {location.pathname.split('/').pop()?.split('-').join(' ')}
                            {/*@ts-ignore*/}
                            {requiredToSubmit.includes(location.pathname.split('/').pop()) 
                                ? <span className={'label'}>(REQUIRED)</span> 
                                : <span className={'label'}>(OPTIONAL)</span>
                            }
                        </div>
                    </div>
                }
                <i className={!navOpened ? 'ico-ChevronDown' : 'ico-ChevronUp'}/>
            </NavigationToggleButton>
            <NavigationMenu displayed={!navOpened} onClick={()=> setNavOpened(prev => !prev)}>
                <NavigationSection className={'custom-scrollbar'}>
                    <div className={"title"}><span>required to submit</span></div>
                    {requiredToSubmit.map(nav => {
                        return <NavigationItem key={nav} nav={nav}>
                            {(nav === OFFER_INFO) ?
                                <span>
                                    Campaign Info
                                </span> :
                                <span>
                                    {nav.split('-').join(' ')}
                                </span>
                            }
                        </NavigationItem>
                    })}
                    <div className={"title"}><span>optional to submit</span></div>
                    {optionalToSubmit.map(nav => {
                        if(!schedulingFlag && nav === SCHEDULING){
                            return null
                        }
                        return <NavigationItem key={nav} nav={nav}>
                            {(offer.detailed_pricing_model === PPC && nav === CREATIVES) ?
                                <span>
                                    Creatives
                                </span> :
                                <span>
                                    {nav.split('-').join(' ')}
                                </span>
                            }
                        </NavigationItem>
                    })}
                </NavigationSection>
            </NavigationMenu>
        </Container>
    );
};