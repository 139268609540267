import React from "react";

import {Wrapper, Header, CloseButton} from "./Container.style";

import {IContainerProps} from "./container.props";

const Container: React.FC<IContainerProps> = React.forwardRef<HTMLDivElement, IContainerProps>((
    {
        title,
        onClose, noHeaderUnderline,
        modalHeaderClassName,
        marginTop,
        children
    },
    ref) => {
    return <Wrapper ref={ref} style={{marginTop}}>
        <Header noHeaderUnderline={!!noHeaderUnderline}
                className={modalHeaderClassName}>{title} <CloseButton onClick={() => onClose()}><i  className={'ico-Exit-outlined'}/></CloseButton></Header>
        {children}
    </Wrapper>
})

export default Container