import React, {ReactElement, useEffect, useRef, useState} from "react";
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import {DatePickerWrapper} from "./DatePicker.style";

import InputButton from "./input-button/InputButton";
import Header from "./header/Header";
import {format} from "date-fns";
import placeholder from "lodash/fp/placeholder";
interface DatePickerProps {
    date: Date | null | undefined | string,
    setDate: (date: Date | null | string | undefined) => void,
    minDate?: Date | null | undefined
    maxDate?: Date | null | undefined
    inputWidth?: string
    placeholder?: string
    label?: string | ReactElement
}

const DatePicker: React.FC<DatePickerProps> = ({
                                                   date,
                                                   setDate,
                                                   minDate = null,
                                                   maxDate = null,
                                                   inputWidth = '215px',
                                                   placeholder,
                                                   label
}) => {
    
    const [isOpened, setIsOpened] = useState(false) 
    const [datePickerType, setDatePickerType] = useState<'day' | 'month' | 'year'>('day')

    let datepickerRef = useRef(null)

    const handleChange = (date: any) => {
        if(datePickerType === 'day') setDate(format(typeof date === 'string' ? new Date(date) : date,  'yyyy-MM-dd HH:mm:ss').replace(/-/g, "/"))
        if(datePickerType === 'month'){
            setDatePickerType('day')
        }
        if(datePickerType === 'year'){
            setDatePickerType('month')
        }
    }

    const handleOnCalendarClose = () => {
        setIsOpened(false)
        setDatePickerType('day')
    }

    return <DatePickerWrapper >
        <ReactDatePicker
            ref={datepickerRef}
            onChange={(date) => handleChange(date)}
            customInput={<InputButton label={label} datepickerRef={datepickerRef} opened={isOpened} width={inputWidth} placeholder={placeholder}/>}
            onCalendarOpen={() => setIsOpened(true)}
            placeholderText={placeholder ? placeholder : '--/--/----'}
            onCalendarClose={handleOnCalendarClose}
            calendarStartDay={1}
            startDate={date ? new Date(date) : null}
            selected={date ? new Date(date) : null}
            disabledKeyboardNavigation
            showFourColumnMonthYearPicker={true}
            shouldCloseOnSelect={datePickerType === 'day'}
            showYearPicker={datePickerType === 'year'}
            yearItemNumber={12}
            showTwoColumnMonthYearPicker={false}
            showMonthYearPicker={datePickerType === 'month'}
            dayClassName={(today) =>  today.getDate() === new Date().getDate() && today.getMonth() === new Date().getMonth() && today.getFullYear() === new Date().getFullYear() ? "today" : null}
            adjustDateOnChange={false}
            minDate={minDate ? minDate : null}
            maxDate={maxDate ? maxDate : null}
            renderCustomHeader={
                ({
                     date,
                     changeMonth,
                     changeYear,
                     decreaseMonth,
                     increaseMonth,
                     prevMonthButtonDisabled,
                     nextMonthButtonDisabled,
                     increaseYear,
                     decreaseYear
                }) => <Header
                    date={date}
                    changeMonth={changeMonth}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    changeYear={changeYear}
                    decreaseYear={decreaseYear}
                    increaseYear={increaseYear}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                    setDatepickerType = {setDatePickerType}
                    datepickerType={datePickerType}
                />
            }
        />
    </DatePickerWrapper>
}

export default DatePicker