import React, {useEffect, useState} from 'react'
import {useAppSelector} from "../../../../../../common/hooks";
import {tokenStore} from "../../../../../../store/auth.slice";
import AgencyViewService, {IFetchAllMarketersResponse, IFetchAllPartnersResponse, IFetchOverviewStatsResponse} from "../AgencyView.service";
import {OverviewContentRow, OverviewContentWrapper, OverviewTile, OverviewTileContent} from "./Overview.style";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import {formatNumberToCommaSeparated} from "../../../../../../common/utils/formatNumberToCommaSeparated";
import {formatNumberToCurrency} from "../../../../../../common/utils";

interface Props {
    overviewData: IFetchOverviewStatsResponse,
    selectedMarketer: IFetchAllMarketersResponse | null,
    selectedPartner: IFetchAllPartnersResponse | null
}

const Overview: React.FC<Props> = ({overviewData, selectedMarketer, selectedPartner}) => {
    return <OverviewContentWrapper>
        <OverviewContentRow>
            {
            selectedMarketer === null &&
            <OverviewTile>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Active Marketers | Productive Marketers</h3>
                    <Tooltip position={'top'} width={171}>
                        <p><strong>Active Marketers</strong> - Number of marketers who received at least one click during the selected date range.</p>
                        <br/>
                        <p><strong>Productive Marketers</strong> - Number of marketers who received at least one sale during the selected date range.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCommaSeparated(overviewData?.marketers.active)} | {overviewData && formatNumberToCommaSeparated(overviewData?.marketers.productive)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            }
            <OverviewTile>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Sale/Revenue Amount</h3>
                    <Tooltip position={'top'} width={171}>
                        <p>How much the marketer has made from consumers.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCurrency(overviewData.sale)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            <OverviewTile>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Cost</h3>
                    <Tooltip position={'top'} width={171}>
                        <p>How much has been spent by the marketer on publisher commissions.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCurrency(overviewData.cost)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            {selectedPartner === null &&
            <OverviewTile>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Total Fees (Year to Previous Month)</h3>
                    <Tooltip position={'top'} width={171}>
                        <p>How much Perform[cb] has received in total fees from marketers this year to the previous full month.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCurrency(overviewData.fees)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            }
        </OverviewContentRow>
        <OverviewContentRow second>
            {selectedPartner === null &&
            <OverviewTile second>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Active Partners | Productive Partners</h3>
                    <Tooltip position={'top'} width={171}>
                        <p><strong>Active Partners</strong> - Number of partners who received at least one click during the selected date range.</p>
                        <br/>
                        <p><strong>Productive Partners</strong> - Number of partners who received at least one sale during the selected date range.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCommaSeparated(overviewData?.partners.active)} | {overviewData && formatNumberToCommaSeparated(overviewData?.partners.productive)}</h1>
                </OverviewTileContent>
            </OverviewTile>}
            <OverviewTile second>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Clicks</h3>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCommaSeparated(overviewData.clicks)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            <OverviewTile second>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">Conversions</h3>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCommaSeparated(overviewData.conversions)}</h1>
                </OverviewTileContent>
            </OverviewTile>
            <OverviewTile second>
                <OverviewTileContent>
                    <h3 className="overview-cell--title">eCPA</h3>
                    <Tooltip position={'top'} width={171}>
                        <p>How much has been spent per conversion by the marketer on publisher commissions.</p>
                    </Tooltip>
                    <h1 className={"overview-cell--value"}>{overviewData && formatNumberToCurrency(overviewData.ecpa)}</h1>
                </OverviewTileContent>
            </OverviewTile>
        </OverviewContentRow>
    </OverviewContentWrapper>
}

export default Overview