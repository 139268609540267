import {useCallback, useEffect, useState} from "react";

import {filterApi} from "../../../services/api";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";

export const useInvoicingCycleCacheFilter = (pageId: IPagePresetsID, displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<any[]>([])
    const [invoicingCycles, setInvoicingCycles] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID ='invoicing-cycle'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<any[]>(
                'intacct-invoicing-cycle',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )

            setLoading(false)
            setInvoicingCycles(data)
            setFetchedData(data)
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredInvoicingCycles = fetchedData.filter(manager => (
            manager.toLowerCase()).includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedCycles = [...filteredInvoicingCycles].slice(0, displayCount)
            if(filteredInvoicingCycles.length > displayCount){
                setNotDisplayed(filteredInvoicingCycles.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setInvoicingCycles(displayedCycles)
        } else {
            setInvoicingCycles(
                filteredInvoicingCycles
            )
        }
    }, [searchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId: pageId, filterId: id})

    const setSelected = (cycle: any) => {
        if(filter.selected.some((filterCycle) => filterCycle === cycle)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected !== cycle)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, cycle]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    // const clearSelectedFilter = () => {
    //     dispatch(clearSelected({pageId: pageId, filterId: id}))
    // }

    return {
        id: id,
        options: invoicingCycles,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed,
        search: {
            value: searchValue,
            setSearch: setSearchValue
        },
        loading
    }
}

