import styled, { css } from "styled-components";

export const TopPerformersWrapper = styled.div`
    .title {
        .heading {
            color: ${({theme}) => theme.colors.deepBlue[4]};
        }
    }
`;

export const TopPerformersTable = styled.div`
`;

export const TopPerformersAnalyzeBy = styled.div<{active: boolean}>`
    position: relative;
    .analyze-by-main {
        cursor: pointer;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        border-radius: 8px;
        padding: 4px 12px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 216px;
        /* transition: .2s ease-in-out; */

        .top {
            display: flex;
            flex-direction: column;
            .analyze-by {
                color:  ${({theme}) => theme.colors.deepBlue[5]};
                margin-right: 12px;
                /* margin-left: 5px; */
            }
            @media screen and (max-width: 725px) {
                /* flex-direction: row; */
                gap: 5px;
            }
        }

        .ico-ico-AngleUp, .ico-ico-Angledown {
            &::before {
                color: ${({theme}) => theme.colors.deepBlue[4]};
            }
        } 

        ${({active}) => !active && css`
            &:hover {
                background-color: #F7F9FA;
            }
        `}

        ${({active}) => active && css`
            background-color: #D0D5D7;
        `}

        @media screen and (max-width: 725px) {
          width: 100%;
        }
    }

    .analyze-by-dropdown {
        position: absolute;
        left: 0;
        width: 200px;
        top: calc(100% + 2px);
        z-index: 101111111;
        background-color: ${({theme}) => theme.colors.pureWhite};
        border: 1px solid #E9ECED;
        border-radius: 8px;
        box-shadow: 0px 3px 6px -4px rgb(0 0 0 / 12%), 0px 6px 16px rgb(0 0 0 / 8%), 0px 9px 28px 8px rgb(0 0 0 / 5%);
        padding: 6px 12px;
        
        @media screen and (max-width: 725px) {
          width: 100%;
        }
    }

    @media screen and (max-width: 685px) {
        width: 100%;
    }
`;

export const AnalyzeByDropdownItem = styled.div<{active: boolean}>`
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 8px;

    &:hover {
        background-color: ${({active}) => !active && "#E7F9FB"};
    }

    ${({active}) => active && css`
        background-color: #2D4548;
        color: ${({theme}) => theme.colors.pureWhite};
    `}
`;

export const TopPerformersSortableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: all;

    div {
        display: none;
    }

    &:hover {
        div {
            display: block;
        }
    }
`;

export const NetworkViewSortIconDown = styled.div<{hoverEffect?: boolean}>`
    position: relative;
    &:after {
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 0;
        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }

    ${({hoverEffect}) => hoverEffect && css`
        &:after {
            display: none;
        }

        &:hover:after {
            display: inline-block;
        }
    `}
`;

export const NetworkViewSortIconUp = styled.div<{hoverEffect?: boolean}>`
    position: relative;
    
    &:after {
        display: block;
        position: absolute; 
        top: -10px; 
        left: 0; 
        transform: rotate(180deg);

        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }

    ${({hoverEffect}) => hoverEffect && css`
        &:after {
            display: none;
        }

        &:hover:after {
            display: inline-block;
        }
    `}
`;