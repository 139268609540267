import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IPreset} from "../../../../../common/components/range-date-picker/presets/presets.props";
import {TDateSelector} from "../../dashboard/agency-view/AgencyView.slice";

export interface IInitialState {
    sort_by?: string,
    sort_direction: 'asc' | 'desc',
    paginate_by: number,
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset,
    date_selector: TDateSelector,
    summarize: boolean
}

const initialState: IInitialState = {
    paginate_by: 50,
    date_selector: 'day',
    date_from: new Date(),
    date_to: new Date(),
    date_range_preset: {
        value: "today",
        name: 'Today'
    },
    sort_direction: 'asc',
    summarize: false
}

const marketersTransactions = createSlice({
    name: 'marketersTransactions',
    initialState,
    reducers: {
        setSortBy: (state, action:PayloadAction<string>) => {
            state.sort_by = action.payload
        },
        setSortDirection: (state, action:PayloadAction<'asc' | 'desc'>) => {
            state.sort_direction = action.payload
        },
        setPaginateBy: (state, action:PayloadAction<number>) => {
            state.paginate_by = action.payload
        },
        setDateSelector: (state: IInitialState, action: PayloadAction<TDateSelector>) => {
            state.date_selector = action.payload
        },
        setDateFrom: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo: (state: IInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setDateRangePreset: (state: IInitialState, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setSummarize: (state: IInitialState, action:PayloadAction<boolean>) => {
            state.summarize = action.payload
        }
    }
})

export const {
    setSortBy,
    setSortDirection,
    setPaginateBy,
    setDateFrom,
    setDateRangePreset,
    setDateSelector,
    setDateTo,
    setSummarize
} = marketersTransactions.actions

export default marketersTransactions.reducer
