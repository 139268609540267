import React, {useEffect, useMemo, useState} from 'react'
import Highcharts, {SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {Wrapper, LegendSection, ChartSection, Chart, LoadingOverlay} from './ProfitByVertical.style'
import {IMtdNiche} from "../NetworkView.service";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import Loading from "../../../../../../common/components/loading/Loading";
import LegendItem from "./LegendItem/LegendItem";

interface Props {
    data: IMtdNiche[] | undefined,
    loading: boolean
}

const ProfitByVertical: React.FC<Props> = ({data, loading}) => {
    const [chartData, setChartData] = useState<any[] | undefined>()
    const [profitSum, setProfitSum] = useState(0)

    //Add color property to data series
    useEffect(() => {
        let chartSeries: any[] | undefined = data && data.map(niche => {
            let color = {}
            switch (niche.name) {
                case "Downloads & Software":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#9563FF"
                            ],
                            [
                                1,
                                "#0B05AC"
                            ],

                        ]
                    }
                    break
                case "Entertainment & Lifestyle":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#002482"
                            ],
                            [
                                1,
                                "#0038FF"
                            ],

                        ]
                    }
                    break
                case "Financial":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#006A97"
                            ],
                            [
                                1,
                                "#00E0FF"
                            ],

                        ]
                    }
                    break
                case "Healthy & Beauty":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#009718"
                            ],
                            [
                                1,
                                "#BFF103"
                            ],

                        ]
                    }
                    break
                case "E-Commerce":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#A84000"
                            ],
                            [
                                1,
                                "#FFA800"
                            ],

                        ]
                    }
                    break
                case "Other":
                    color = {
                        linearGradient: {
                            "x1": 0,
                            "x2": 0,
                            "y1": 0,
                            "y2": 1
                        },
                        stops: [
                            [
                                0,
                                "#616E6F"
                            ],
                            [
                                1,
                                "#B5C1C2"
                            ],

                        ]
                    }
                    break
            }

            return {...niche, color}
        })

        data && setProfitSum(data.reduce((accumulator, object) => {
            return accumulator + object.y;
        }, 0))

        setChartData(chartSeries)
    }, [data])


    const getTooltipMarkerColor = (name: string) => {
        switch (name) {
            case "Downloads & Software":
                return '#5F35FF'
                break
            case "Entertainment & Lifestyle":
                return '#0048C6'
                break
            case "Financial":
                return '#0098C8'
                break
            case "Healthy & Beauty":
                return '#3AAD00'
                break
            case "E-Commerce":
                return '#D96800'
                break
            case "Other":
                return '#536162'
                break
            default:
                return 'black'
        }
    }


    const chartOptions: Highcharts.Options = useMemo(() => (
        {
            chart: {
                plotShadow: false,
                type: 'pie',
                margin: 10,
                height: 260,
                backgroundColor: "transparent"
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },


            plotOptions: {
                pie: {
                    slicedOffset: 0,
                    borderWidth: 8,
                    states: {
                        hover: {
                            enabled: !(profitSum === 0)
                        }
                    },
                    allowPointSelect: false,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false
                },
                series: {
                    states: {
                        hover: {
                            enabled: !(profitSum === 0)
                        }
                    }
                },
            },
            series:[{
                type: 'pie',
                innerSize: '60%',
                name: 'Profit By Vertical',
                colorByPoint: true,
                data: profitSum === 0 ? [
                        {
                            y: 1,
                            color: '#DFE7EB'
                        }
                    ] : chartData
            }],
            tooltip: {
                enabled: !(profitSum === 0),
                shared: true,
                borderWidth: 0,
                backgroundColor: '#ffffff',
                padding: 0,
                shadow: false,
                borderRadius: 16,
                useHTML: true,
                formatter: function (): any {
                    // @ts-ignore
                    return `<div class="hc-tooltip-wrapper">
                            <div class="hc-tooltip-inner">
                                <div class="tooltip-value-item"><span class="marker" style="background-color: ${getTooltipMarkerColor(this.point.name)}"></span><strong>${this.point && this.point.name}</strong></div>
                                <div class="tooltip-value-item tooltip-value">${this.point.y && formatNumberToCurrency(this.point.y)}</div>
                            </div>
                        </div>`
                }
            },

        }
    ), [chartData, profitSum])

    return <Wrapper>
        {loading && <LoadingOverlay><Loading/></LoadingOverlay>}
        <h3 className={'title'}>Profit by Vertical</h3>
        <ChartSection>
            <Chart >
                {chartData && <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />}
            </Chart>
            <LegendSection>
                {data && data.map(niche => {
                    return <LegendItem key={niche.name} name={niche.name} value={niche.y} sum={profitSum} boxColor={getTooltipMarkerColor(niche.name)}/>
                })}

            </LegendSection>
        </ChartSection>


    </Wrapper>
}

export default ProfitByVertical