import styled, { css } from "styled-components";

export const CardsWrapper = styled.div`
    .pagination {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
    }
`;

export const CardsContainer = styled.div`
    margin: 25px;
    border: 1px solid rgb(233, 236, 237);
    border-radius: 8px;
    padding: 26px;
    background-color: white;
`;

export const ExpandCardsRow = styled.div<{$disabled?: boolean}>`
    width: 100%;
    padding-left: 25px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
    ${({$disabled}) => $disabled && css`
        cursor: default;
        .ico-ChevronDown {
            color: rgb(135, 153, 162);
        }
    `}
    /* .tooltip {
        position: absolute;
        display: none;
        top: 0;
        left: 5px;
    } */

    /* &:hover {
        .tooltip {
            display: block;
        }
    } */
    
`;
