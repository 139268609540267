import React, {useRef, useState} from "react";
import ReactDOM from "react-dom";
import { CardsTooltipInfo } from "./CardsTooltip.style";

type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

export interface ITooltipProps {
    position: TooltipPosition,
    triggerOnClick?: boolean,
    width?: number
}

const CardsTooltip: React.FC<ITooltipProps> = ({position = 'left', triggerOnClick = false, width = 300, children}) => {
    const [tooltipDisplayed, setTooltipDisplayed] = useState(false)
    const [infoPosition, setInfoPosition] = useState({
        left: 0,
        top: 0
    })
    const tooltipRef = useRef<HTMLDivElement | null>(null)

    const handleMouseEnter = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(tooltipRef.current){
            const {top, left} = tooltipRef.current.getBoundingClientRect()
           setInfoPosition({
                top: top + window.scrollY,
                left: left + 25
            })
        }
        setTooltipDisplayed(true)
    }

    return <div
        style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}
        ref={tooltipRef}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={() => setTooltipDisplayed(false)}
    >
        <Info width={width} opened={tooltipDisplayed} position={position} left={infoPosition.left} top={infoPosition.top}>
            {children}
        </Info>
    </div>
}

const Info: React.FC<{opened: boolean, position: string, left: number, top: number, width: number}> = ({opened, position, left, top,width, children}) => {

    return ReactDOM.createPortal(
        <CardsTooltipInfo $width={width} opened={opened} position={position} $left={left} $top={top}>
            {children}
        </CardsTooltipInfo>,
        document.body
    )
}
export default CardsTooltip;
