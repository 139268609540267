import React, {useState, useMemo} from 'react';
import {StyledFooter} from "./Footer.style";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../../common/styled-components";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import ReactDOMServer from "react-dom/server";
import {useCreateOfferParams} from "../../hooks/useCreateOfferParams";
import {createOffer, setLastSaved, setSaveDraftOffer, setServerError} from "../../create-offer.slice";
import CreateOfferService from "../../CreateOffer.service";
import {useParams} from "react-router-dom";
import {navigationList} from "../../interfaces/interfaces";
import {TooltipContainer} from '../../CreateOffers.style'
import {useCheckMacro} from "../../hooks/useCheckMacro";
import {ENCRYPTED_COST_MACRO} from "../../../../../../common/utils/consts";
import SaveChangesModal from "../../modals/SaveChangesModal";
import SaveOfferModal from "../../modals/SaveOfferModal";
import SaveDraftSuccessModal from "../../modals/SaveDraftSuccessModal";
import NotificationsModal from "../../modals/NotificationsModal";
import EditSuccessModal from "../../modals/EditSuccessModal";

const targetOrigin = process.env.REACT_APP_API_BASE_URL

interface Props {
    draft?: boolean
    replicate?: boolean
}

export const Footer: React.FC<Props> = ({draft = false, replicate = false}) => {
    const {
        offer,
        createOfferState,
        initialEditOffer,
        navState,
        saveDraftOffer
    } = useAppSelector(state => state.createOffer)
    const [exitOfferModalOpened, setExitOfferModalOpened] = useState<boolean>(false)
    const [saveOfferModalOpened, setSaveOfferModalOpened] = useState<boolean>(false)
    const [successModalOpened, setSuccessModalOpened] = useState<boolean>(false)
    const [successEditModalOpened, setSuccessEditModalOpened] = useState(false)
    const checkMacro = useCheckMacro()
    const params = useCreateOfferParams()
    const dispatch = useAppDispatch()
    const {token} = useAppSelector(state => state.auth)
    const [saveDraftTooltipDisplayed, setSaveDraftTooltipDisplayed] = useState(false)
    const [saveDraftLoading, setSaveDraftLoading] = useState(false)
    const [changesModalState, setChangesModalState] = useState<{
        opened: boolean,
        actions: {
            time_delayed: string[],
            compliance_delayed: string[],
            immediately_applied: string[]
        } | null
    }>({
        opened: false,
        actions: null
    })

    const {id} = useParams()
    
    const exit = () => {
        if(initialEditOffer === JSON.stringify(offer) || saveDraftOffer === JSON.stringify(offer)){
            targetOrigin && window.parent.postMessage({id: 1}, targetOrigin)
        } else {
            setExitOfferModalOpened(true)
        }

    }

    const requiredToSubmit: typeof navigationList[number][] = useMemo(() => {
        if(offer.detailed_pricing_model === 'cpc'){
            return [
                'offer-info',
                'tracking-system',
                'land-and-track',
                'outcome-funnel'
            ]
        }

        if(offer.detailed_pricing_model === 'ppc'){
            return [
                'offer-info',
                'outcome-funnel'
            ]
        }

        if(offer.detailed_pricing_model === 'cpi' || offer.detailed_pricing_model === 'cpe'){
            return [
                'offer-info',
                'tracking-system',
                'land-and-track',
                'outcome-funnel',
                'testing'
            ]
        }

        return [
            'offer-info',
            'tracking-system',
            'land-and-track',
            'outcome-funnel',
            'tracking-codes',
            'testing'
        ]
    }, [offer.detailed_pricing_model])

    const redirectToOffersPage = () => {
        targetOrigin && window.parent.postMessage({id: 1}, targetOrigin)
    }

    const submitOffer = async () => {
        dispatch(createOffer(params))
            .unwrap()
            .then((data) => {
                if(data._notifications && (
                    data._notifications.time_delayed.length > 0 ||
                    data._notifications.compliance_delayed.length > 0 ||
                    data._notifications.immediately_applied.length > 0
                )){
                    setChangesModalState({
                        opened: true,
                        actions: {...data._notifications}
                    })
                } else {
                    if(!id || draft || replicate){
                        setSaveOfferModalOpened(true)
                    } else {
                        setSuccessEditModalOpened(true)
                    }
                    setTimeout(() => {
                        redirectToOffersPage()
                    }, 4000)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const saveDraft = async () => {
        try{
            setSaveDraftLoading(true)
            const {data} = await CreateOfferService.saveDraft(token, {...offer, status: 'draft'})
            dispatch(setLastSaved(data.data.created_at ? new Date(data.data.created_at) : null))
            dispatch(setSaveDraftOffer(JSON.stringify(offer)))
            setSaveDraftTooltipDisplayed(true)
            setTimeout(() => {
                setSaveDraftTooltipDisplayed(false)
            }, 2500)
        } catch (e) {
            console.log(e)
        } finally {
            setSaveDraftLoading(false)
        }
    }

    const saveDraftChanges = async () => {
        try{
            setSaveDraftLoading(true)
            await CreateOfferService.saveDraft(token, {...offer, status: 'draft'})
            setSuccessModalOpened(true)
            setExitOfferModalOpened(false)
            setTimeout(() => {
                redirectToOffersPage()
            }, 2500)
        } catch (e: any) {
            setExitOfferModalOpened(false)
            dispatch(setServerError(e.response?.data?.message))
        }

    }

    const isSubmitDisabled = (): boolean => {
        if(createOfferState.loading) return true
        if(requiredToSubmit.some(req => navState[req] !== 'completed')){
            return true
        }
        if(checkMacro(ENCRYPTED_COST_MACRO, offer.destination_url)) return true
        if(id && !draft){
            if(JSON.stringify(offer) === initialEditOffer) return true
        }
        return false
    }

    const isDraftDisabled = () => {
        return offer.name.length === 0 || saveDraftLoading || saveDraftOffer === JSON.stringify(offer)
    }

    return (
       <>
           <StyledFooter>
               <ButtonStyled className={"btn-orange-outlined full-radius"} onClick={exit}>
               <span>
                   <i className={"ico-Exit-outlined"} style={{marginRight: "10px"}}/>
                   Exit Campaign
               </span>
               </ButtonStyled>
               <div style={{
                   display: "flex",
                   alignItems: "center"
               }}>
                   {(id && !draft && !replicate) ? null : <ButtonStyled
                       style={{position: 'relative'}}
                       className={"btn-blue-outlined"}
                       disabled={isDraftDisabled()}
                       onClick={saveDraft}
                   >
                       {saveDraftLoading && <BtnLoadingSpinner/>}
                       Save Draft {saveDraftTooltipDisplayed && <TooltipContainer $left={37} $top={-80} opened={true}>
                       <i className={"material-icons"}
                          style={{color: "#3FDE7F"}}>
                           check_circle
                       </i>
                       Draft Saved
                   </TooltipContainer>}
                   </ButtonStyled>}
                   <div data-html={true}
                        data-tip-disable={false}
                        data-tip={ReactDOMServer.renderToStaticMarkup(
                            <div style={{maxWidth: 125, fontSize: 12}}>
                                Fill out all required sections to submit your offer for approval.
                            </div>)}>
                       <ButtonStyled
                           className={"btn-discover-results"}
                           onClick={submitOffer}
                           disabled={isSubmitDisabled()}
                       >
                           {(id && !draft && !replicate) ? 'Save / Submit Changes' : 'Submit Campaign'}
                       </ButtonStyled>
                   </div>
               </div>
           </StyledFooter>

           <SaveChangesModal
               opened={exitOfferModalOpened}
               closeModal={() => setExitOfferModalOpened(false)}
               title={!id || draft || replicate ? 'Save or Discard Draft Changes' : 'Save or Discard Changes'}
               onSave={!id || draft || replicate ? saveDraftChanges : submitOffer}
               submitButtonContent={!id || draft || replicate ? 'Save Changes' : 'Save / Submit Changes'}
               discardChanges={redirectToOffersPage}
               isSaveDisabled={!id || draft || replicate ? isDraftDisabled() : isSubmitDisabled()}
               loading={!id || draft || replicate ? saveDraftLoading : createOfferState.loading}
           />

           <SaveOfferModal opened={saveOfferModalOpened}/>

           <SaveDraftSuccessModal opened={successModalOpened}/>

           <NotificationsModal
               opened={changesModalState.opened}
               actions={changesModalState.actions}
               redirectToOffersPage={redirectToOffersPage}
           />

           <EditSuccessModal opened={successEditModalOpened}/>

       </>
    );
};



