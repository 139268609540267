import React, {ChangeEvent} from 'react'
import TextArea from "../../../../../../../../common/components/textarea/TextArea";
import ReactDOMServer from "react-dom/server";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {setOffer} from "../../../../create-offer.slice";
import {ICreative} from "../../../../interfaces/interfaces";

const tooltip = <i
    className={'ico-Information-outlined'}
    data-html={true}
    // data-multiline={true}
    data-tip={ReactDOMServer.renderToString(
        <>
            <p>If you have any text you wish </p>
            <p>to provide for our partners to use, you</p>
            <p>can enter it here.</p>
            <br/>
            <p>This is primarily relevant for SMS</p>
            <p>Text and/or Contextual Push Traffic.</p>
        </>)}
    />

const TextCreative: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleTextCreativeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const indexOfTextCreative = offer.creatives.findIndex((creative: any) => creative.name === 'text_creative')
        if(indexOfTextCreative >= 0){
            const newCreative = {...offer.creatives[indexOfTextCreative]}
            newCreative.text_creative = e.target.value

            let newCreatives = [...offer.creatives]
            newCreatives[indexOfTextCreative] = {...newCreative}
            dispatch(setOffer({
                creatives: [...newCreatives],
                additionalCreative: {
                    text_creative: e.target.value
                }
            }))
        } else {
            let newCreative: ICreative = {
                campaign_id: offer.id,
                files: [{
                    id: 0,
                    name: 'text_creative',
                    extension: 'txt'
                }],
                id: Math.min(...offer.creatives.map((creative: any) => creative.id)) > 0 ?
                    -1 :
                    Math.min(...offer.creatives.map((creative: any) => creative.id)) - 1,
                is_available: true,
                name: 'text_creative',
                text_creative: e.target.value,
                type: null
            }
            const newCreatives = [...offer.creatives, newCreative]
            dispatch(setOffer({
                creatives: [...newCreatives],
                additionalCreative: {
                    text_creative: e.target.value
                }
            }))
        }
    }

    return <>
        <TextArea
            style={{
                resize: 'vertical'
            }}
            label={<>Text Creative <span className="input-label-optional">(optional)</span> {tooltip}</>}
            placeholder={'Enter any text based creatives'}
            value={offer.additionalCreative.text_creative}
            onChange={(e) => handleTextCreativeChange(e)}
        />
    </>
}

export default TextCreative