import styled, {css} from 'styled-components'

interface ISwitchDropdownStyleProps{
    $opened: boolean
}

export const Wrapper = styled.div<ISwitchDropdownStyleProps>`
  .view-switch-header{
    height: 32px;
    min-height: 32px;
    background-color: white;
    
    .ico-Eye{
      margin-right: 8px;
      transform: translateY(3px);
    }
    
      :hover{
        background-color: #F3F7F8;
        transition: all .2s ease-in-out;
        cursor: pointer;
        border-color: ${({theme}) => theme.colors.coolGray[4]};
        color: ${({theme}) => theme.colors.deepBlue[4]};
      }

    ${(props) => props.$opened && css`
      background-color: ${props.theme.colors.deepBlue[2]};
      border: 1px solid ${props.theme.colors.deepBlue[3]};
      color: ${props.theme.colors.deepBlue[6]};
      
      :hover {
        background-color: ${props.theme.colors.deepBlue[2]};
        border: 1px solid ${props.theme.colors.deepBlue[3]};
        color: ${props.theme.colors.deepBlue[6]};
      }
    `}
    }
  
`