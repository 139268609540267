import styled from 'styled-components'

export const Wrapper = styled.div`
    .partner-field {
        display: flex;
        gap: 10px;
        align-items: center;
        pointer-events: all;
        width: calc(100% );
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //padding-right: 17px;

        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            //width: 100%;
        }
        .tooltip {
            display: none;
            cursor: pointer;
            i {
                display: block;
                transform: rotate(-90deg);
                color: ${({theme}) => theme.colors.performOrange[3]};
            }
        }

        &:hover{
            /* width: calc(100% - 20px); */
            .tooltip {
                display: inline-block;
            }
        }

        //&::before{
        //    content: "";
        //    position: absolute;
        //    width: 100%;
        //    height: 100%;
        //    left: 0;
        //}

    }
`

export const TagPill = styled.div`
  padding: 4px 8px;
  line-height: 16px;
  background: ${({theme}) => theme.colors.coolGray[2]};
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 16px;
  margin-right: 8px;
`

export const Tag = styled.span`
  padding: 4px 8px;
  margin: 9px 5px 0 0;
  display: inline-flex;
  /* Cool Gray/200 */

  background: ${({theme}) => theme.colors.coolGray[2]};
  /* Cool Gray/300 */

  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  border-radius: 16px;
  line-height: 1;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
`

export const RatingStar=styled.span`
  color: ${({theme}) => theme.colors.coolGray[3]};
  font-size: 17px;
`

export const RatingStarFiled = styled.span`
  color: ${({theme}) => theme.colors.performBlue[5]};
  font-size: 17px;
`

export const CloneModalWrapper = styled.div`
  width: 500px;
`

export const ErrorMsg = styled.label`
  position: absolute;
  bottom: -5px;
  font-size: 10px;
  right: 0;
  color: ${({theme})=>theme.colors.performOrange[4]};
`