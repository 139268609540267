import styled from 'styled-components'

export const NotesModalHeader = styled.div`
  h2{
    font-size: 20px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 12px;
    width: 472px;
  }
  
  p{
    color: ${({theme}) => theme.colors.deepBlue[6]};
    font-size: 12px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    
    .notes-header-value{
      color: ${({theme}) => theme.colors.performBlue[6]};
    }
  }
  
  padding-bottom: 5px;
`