import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import {isNullOrUndefined} from "../../../../../../../common/utils";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";

interface Props {}

const PlayStoreUrl: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(
        offer.destination_url === '' ||
        isNullOrUndefined(offer.destination_url) ||
        offer.preview_url === '' ||
        isNullOrUndefined(offer.preview_url) ||
        offer.operating_system_id !== 1
    ) return null

    return (
        <>
            <Subtitle> Google Play Store URL </Subtitle>
            <TruncateContent
                wrapperClassName={'truncate-wrapper'}
                numberOfLines={3}
            >
                {offer.preview_url}
            </TruncateContent>
        </>
    )
}

export default PlayStoreUrl