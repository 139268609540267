import styled, {css} from 'styled-components'

interface IInputButtonStyleProps {
    $opened: boolean
    $width: string
    $withLabel: boolean
    $disabled?: boolean | undefined
}

export const Wrapper = styled.div<IInputButtonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: ${props => props.$width};
  color: ${({theme}) => theme.colors.deepBlue[4]};
  position: relative;
  ${(props) => props.$opened && css`
    pointer-events: none;
  `}
  
  .placeholder {
    color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  :hover{
    cursor: pointer !important;
  }
  .date-value{
    height: 100%;
    width: calc(100% - 40px);
    padding-left: 12px;
    display: flex;
    align-items: center;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: none;
    font-size: 12px;
  }


  .calendar-icon-container{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  :hover{
    .ico-Calendar{
      color: ${({theme}) => theme.colors.deepBlue[5]};
    }
    
    .calendar-icon-container{
      background-color: ${({theme}) => theme.colors.coolGray[2]};
      border: 1px solid ${({theme}) => theme.colors.coolGray[4]};
    }
  }

  :hover:active{
    .ico-Calendar{
      color: ${({theme}) => theme.colors.performBlue[5]};
    }
    .calendar-icon-container{
      background-color: ${({theme}) => theme.colors.performBlue[1]};
      border-color: ${({theme}) => theme.colors.performBlue[5]};
    }
  }
  
  ${(props) => props.$opened && css`
    ${props.$withLabel && css`
      .date-value{
        border-color: ${({theme}) => theme.colors.performBlue[5]};
      }
    `}
    
    .ico-Calendar{
      color: ${({theme}) => theme.colors.performBlue[5]};
    }
    .calendar-icon-container{
      background-color: ${({theme}) => theme.colors.performBlue[1]};
      border-color: ${({theme}) => theme.colors.performBlue[5]};
    }
  `}

    ${({theme, $disabled}) => $disabled && css`
        pointer-events: none !important;
        background-color: ${theme.colors.coolGray[5]} !important;
    `}
`

export const LabelStyledFloatable = styled.label<{$disabled?: boolean, $focused: boolean}>`
  position: absolute;
  font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  line-height: 16px;
  color: ${({theme, $disabled, $focused}) => !$disabled ? ($focused ? theme.colors.performBlue[5] : theme.colors.deepBlue[5]) : theme.colors.coolGray[4]};
  padding: 0 4px;
  top: -8px;
  left: 8px;
  z-index: 1;
  transition: color .2s ease;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 8px;
        height: 1px;
        background: #ffffff;
        z-index: -1;
    }
`