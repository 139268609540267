import { IPagination } from './../../../../../../common/models/pagination.type';


import qs from "qs";
import { api } from "../../../../../../common/services/api";

export interface IBillingTransactionsResponse {
    created_at: string;
    description: string;
    status: string;
    credit: number;
    debit: number;
    funding_available: number;
    type_name: string;
    currency_type_name: string;
    status_name: string;
    payment_processed: boolean
}

class BillingTransactionsService {

    static fetchTransactionsData = async (token: string, params: {
        paginate_by: number,
        sort_by: string,
        order: "desc" | "asc",
        page: number
    }, id: number) => {        
        return await api.get<IPagination<IBillingTransactionsResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'billing-transactions',
                id
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params);
                }
            }
        )
    }

    static exportToCsv = (
        params: {
            paginate_by: number,
            sort_by: string,
            order: "desc" | "asc",
            page: number,
            from: string,
            to: string,
            transaction_type?: {
                ids: string[],
                include: boolean
            },
            currency_type?: {
                ids: string[],
                include: boolean
            },
            transaction_status?: {
                ids: string[],
                include: boolean
            },
        }, 
        id: number
    ) => {
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            'billing-transactions',
            id
        ].join('/') + "?" + qs.stringify(params) + "&" + "action=export_csv", "_blank");
    }

}

export default BillingTransactionsService