import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {FilterID} from "filters";
import {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../useAppDispatch";
import FiltersService from "../../../services/filters.service";
import {IRole} from "../../../models/user.type";
import {useFilterCacheSelector} from "../useFilterCacheSelector";


export const useUserRolesCacheFilter = (pageId: IPagePresetsID, displayCount?: number) =>{
    const id : FilterID = "user_role"
    const token = useAppSelector(tokenStore)
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)
    const [fetchedData, setFetchedData] = useState<IRole[]>([])
    const [roles, setRoles] = useState<IRole[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(0);

    const fetch = useCallback( async () =>{
        try {
            setLoading(true)
            const {data} = await FiltersService.fetchUserRoles(token)
            setFetchedData(data)
            setLoading(false)
        }catch (e) {
            setLoading(false)
            console.log(e)
        }
    }, [token])

    useEffect(() => {
        fetch()
    }, [token])

    useEffect(()=>{
        let filteredRoles = fetchedData.filter(role => (
            role.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                role.id.toString().includes(searchValue.toLowerCase())
        ))
        if (displayCount){
            const displayedRoles = [...filteredRoles].slice(0, displayCount)
            if (filteredRoles.length > displayCount){
                setNotDisplayed(filteredRoles.length - displayCount)
            }else {
                setNotDisplayed(0)
            }
            setRoles(displayedRoles)
        }else{
            setRoles(filteredRoles)
        }
    },[fetchedData, displayCount,searchValue])

    const filter = useFilterCacheSelector({pageId, filterId : id})

    const setSelected = (role : IRole) => {

        dispatch(setFilterValue({
            pageId: pageId,
            filterId: id,
            filter : { selected: [role] }
        }))
        // if (filter.selected.some(selected => selected.id === role.id)){
        //     dispatch(setFilterValue({
        //         pageId: pageId,
        //         filterId: id,
        //         filter : {...filter, selected: filter.selected.filter(selected => selected.id !== role.id)}
        //     }))
        // } else{
        //     dispatch(setFilterValue({
        //         pageId: pageId,
        //         filterId: id,
        //         filter : {selected: [role] }
        //     }))
        // }
    }

    return {
        id: id,
        options: roles,
        loading: loading,
        notDisplayed: notDisplayed,
        search: {
            value: searchValue,
            setSearch : setSearchValue,
        },
        selected: {
            value: filter.selected,
            setSelected: setSelected,
        }
    }
}