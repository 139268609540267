import React, {useEffect, useMemo} from 'react';

import {Panel} from "../Main.style";
import {
    setNextPage,
    setPrevPage,
} from "../../../create-offer.slice";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";
import {LandingPageUrlInput} from "../../../components/LandingPageUrlInput";
import {TrackingMethodSelect} from "../../../components/TrackingMethodSelect";
import {DomainSection} from "../../../components/DomainSection";
import {RedirectTypeSelect} from "../../../components/RedirectTypeSelect";
import {GoogleAnalyticsSection} from '../../../components/GoogleAnalyticsSection'
import {AppUrlInput} from "../../../components/AppUrlInput";
import ReactTooltip from "react-tooltip";
import {
    CPC, CPE,
    CPI,
    CPL,
    CPS,
    GOOGLE_ANALYTICS,
    NONE,
    OUTCOME_FUNNEL,
    TRACKING_SYSTEM
} from "../../../interfaces/interfaces";

const LandAndTrack : React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    const redirectlessFeatureFlag = useFeatureFlags('domain-tracking-code')
    const dispatch = useAppDispatch()

    useEffect(()=>{
        dispatch(setPrevPage("../" + TRACKING_SYSTEM))
        dispatch(setNextPage("../" + OUTCOME_FUNNEL))
    },[dispatch])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const contentBasedOnTrackingSystem = useMemo(() => {
        if(!redirectlessFeatureFlag){
            return <>
                <LandingPageUrlInput />
            </>
        }
        if(
            offer.trackingSystem !== NONE &&
            offer.trackingSystem !== GOOGLE_ANALYTICS
        ) {
            return <ol>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define the page customers should land on for this campaign
                        </div>
                        <LandingPageUrlInput />
                    </div>
                </li>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define which method to use to send customers to this campaign's landing page
                        </div>
                        <RedirectTypeSelect />
                    </div>
                </li>

                {offer.direct_linking === true ? <>
                    <li>
                        <div className={"list-item"}>
                            <div className={"list-item-label"}>
                                Define the domain for this campaign and the parameter that your server
                                uses to receive Transaction ID’s from
                                <span className={"tracking-system-span"}> {offer.trackingSystem} </span>
                            </div>
                            <DomainSection/>
                        </div>
                    </li>
                </> : offer.direct_linking === false ? <>
                    <li>
                        <div className={"list-item"}>
                            <div className={"list-item-label"}>
                                Define the method used to track the outcomes of your campaign
                            </div>
                            <TrackingMethodSelect/>
                        </div>
                    </li>
                </> : null}
            </ol>
        } else if (offer.trackingSystem === GOOGLE_ANALYTICS) {
            return <ol>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define the page customers should land on for this campaign
                        </div>
                        <LandingPageUrlInput />
                    </div>
                </li>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Use the button below specifically made for
                            <span className={"tracking-system-span"}> {offer.trackingSystem} </span> after pasting your URL above
                            to add the parameters and macros needed to successfully track your clicks & conversions or
                            enter your own parameters and use the other buttons to place the associated macros for them
                        </div>
                        <GoogleAnalyticsSection/>
                    </div>
                </li>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define which method to use to send customers to this campaign's landing page
                        </div>
                        <RedirectTypeSelect />
                    </div>
                </li>
            </ol>
        } else {
            return <ol>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define the page customers should land on for this campaign
                        </div>
                        <LandingPageUrlInput />
                    </div>
                </li>
                <li>
                    <div className={"list-item"}>
                        <div className={"list-item-label"}>
                            Define which method to use to send customers to this campaign's landing page
                        </div>
                        <RedirectTypeSelect />
                    </div>
                </li>

                {offer.direct_linking === null || offer.direct_linking === true ? null :
                    <li>
                        <div className={"list-item"}>
                            <div className={"list-item-label"}>
                                Define the method used to track the outcomes of your campaign
                            </div>
                            <TrackingMethodSelect/>
                        </div>
                    </li>}
            </ol>
        }
    }, [offer.direct_linking, offer.trackingSystem, redirectlessFeatureFlag])


    const contentBasedOnOfferType = useMemo(() => {
        //CPS && CPL
        if(offer.detailed_pricing_model === CPS || offer.detailed_pricing_model === CPL) {
            return <>
                <div className={'description'}>
                    {!redirectlessFeatureFlag ? <>
                        Define the URL that customers should land on for this campaign.
                    </> : <>
                    {offer.trackingSystem === GOOGLE_ANALYTICS ? <>
                        Define the URL that users should land on for this campaign and the method used to send them there.
                        If you are choosing standard redirects, the URL should be the final point customers are sent to.
                    </> : <>
                        Define the URL that customers should land on for this campaign,
                        the method used to send them there, and how we should track them once they’re there.
                        If you are choosing standard redirects, the URL should be the final point customers are sent to.
                    </>}

                    </>}

                </div>
                <div className={offer.trackingSystem ? offer.trackingSystem : ''}>

                    {contentBasedOnTrackingSystem}

                </div>
            </>
        }

        //CPC
        if(offer.detailed_pricing_model === CPC){
            return <>
                <div className="description">
                    Define the URL that users should land on for this campaign.
                    The URL should be the final point customers are sent to after any redirects.
                </div>
                {offer.trackingSystem !== GOOGLE_ANALYTICS ? <>
                    <LandingPageUrlInput/>
                </> : <>
                    <ol>
                        <li>
                            <div className={"list-item"} style={{marginBottom: '18px'}}>
                                <LandingPageUrlInput />
                            </div>
                        </li>
                        <li>
                            <div className={"list-item google-analytics"}>
                                <div className={"list-item-label"}>
                                    Use the button below specifically made for
                                    <span className={"tracking-system-span"}> {offer.trackingSystem} </span> after pasting your URL above
                                    to add the parameters and macros needed to successfully track your clicks & conversions or
                                    enter your own parameters and use the other buttons to place the associated macros for them
                                </div>
                                <GoogleAnalyticsSection/>
                            </div>
                        </li>
                    </ol>
                </>}
            </>
        }

        //CPI && CPE
        if(offer.detailed_pricing_model === CPI || offer.detailed_pricing_model === CPE) {
            return <>
                <div className="description">
                    Provide the unique URL for your app on the app store below.
                </div>

                <AppUrlInput/>
            </>
        }

        return null
    }, [
        contentBasedOnTrackingSystem,
        offer.trackingSystem,
        offer.detailed_pricing_model,
        redirectlessFeatureFlag
    ])



    return (
        <>
            <Panel>
                <div className={"title"}>Land & Track</div>
                {contentBasedOnOfferType}
            </Panel>
        </>
    );
};

export default LandAndTrack