import styled from 'styled-components'

interface ILoadingStyleProps {
    $size: number
}

export const Wrapper = styled.div<ILoadingStyleProps>`
  display: inline-block;
  text-align: center;
  span {
    animation: loading-dots 1s infinite;
    width: ${props => props.$size}px;
    height: ${props => props.$size}px;
    background: #EF4A24;
    margin: 0 3px;
    display: inline-block;
    border-radius: 100%;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.3s;
  }

  @keyframes loading-dots {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
`