import React from 'react';
import {Subtitle} from "../Summary.style";
import {useAppSelector} from "../../../../../../../common/hooks";
import {isNullOrUndefined} from "../../../../../../../common/utils";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";

interface Props {
}

const Domain: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if (
        offer.campaign_tracking_settings?.tracking_domain === '' ||
        isNullOrUndefined(offer.campaign_tracking_settings?.tracking_domain) ||
        isNullOrUndefined(offer.direct_linking) ||
        !offer.direct_linking ||
        navState['land-and-track'] === 'disabled'
    ) return null

    return (
        <>
            <Subtitle>Domain</Subtitle>
            <TruncateContent
                wrapperClassName={'truncate-wrapper'}
                numberOfLines={3}
            >
                {offer.campaign_tracking_settings?.tracking_domain}
            </TruncateContent>
        </>
    )
}

export default Domain