import styled from "styled-components";

interface IselectDropdownWrapperProps {
    open?: boolean
}

interface IoptionProps {
    multiselect?: boolean,
    isActive: boolean,
    $loading?: boolean
}

export const SelectDropdownWrapper = styled.div<IselectDropdownWrapperProps>`
    position: relative;
    margin: 5px;
    width: 100%;
    line-height: 1.42857143;
`;

export const SelectDropdownContainer = styled.div<IselectDropdownWrapperProps>`
        width: 100%;
        box-sizing: border-box;
        position: relative;
        border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]};
        transition: border-color ease-in-out .15s,
            -webkit-box-shadow ease-in-out .15s;
        &:hover {
                border-bottom-color: #97abb5;
        }
`;

export const OptionsLabel = styled.span`
        width: 100%;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirMedium};
`;

export const SelectDropdownTextWrapp = styled.div<IselectDropdownWrapperProps>`
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 10px 10px 0;
`;

export const SelectedDropdownText = styled.span<IselectDropdownWrapperProps>`
    color: ${props => props.open ? "#919191" : "#777"};
    font-size: 13px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    box-sizing: border-box;
    margin: 10px 0;
    .arrow-down {
        position: absolute;
        right: 8px;
        height: 50%;
        display: flex;
        align-items: center;
        top: 25%;
    }  
`;

export const Options = styled.div`
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: ${({theme}) => theme.colors.pureWhite};
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};;
    border-radius: 0 0 8px 8px;
    z-index: 1060;
    overflow-x: hidden;
    padding: 8px 16px 8px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`;

export const OptionsList = styled.div`
    text-align: left;
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const OptionsSearch = styled.div`
    padding: 8px 0;
    position: sticky;
    z-index: 10;
    background-color: ${({theme}) => theme.colors.pureWhite};
    top: 0;
    .input {
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
        font-size: 12px;
        border: 1px solid ${({theme}) => theme.colors.coolGray[3]};;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        outline: none;
        ::placeholder {
            color: #D0D5D7;
        }
    }
`;

export const Option = styled.div<IoptionProps>`
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 6px;
    font-size: 13px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    cursor: ${({$loading})=> $loading ? "default" : "pointer"};
    background-color: ${props => props.isActive && !props.multiselect ? props.theme.colors.performBlue[8] : ""};
    color: ${props => props.isActive && !props.multiselect ? props.theme.colors.pureWhite : props.theme.colors.deepBlue[5]};
    transition: all .2s ease-in-out;
    &:hover{
        background: ${props => !props.$loading && !props.multiselect  && !props.isActive && props.theme.colors.performBlue[1]};
        color: ${props => props.multiselect && props.theme.colors.performBlue[5]};
        .checkbox {
            border-color: ${props => props.multiselect ? props.theme.colors.performBlue[5] : props.theme.colors.deepBlue[5]};
        }
    }

    .checkbox{
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        border: 1px solid ${props => props.isActive && props.multiselect ? props.theme.colors.performBlue[5] : props.theme.colors.deepBlue[4]};
        color: ${props => props.isActive ? props.theme.colors.performBlue[5] : "#8799A1"};
        width: 13.3px;
        height: 13.3px;
        .check {
            position: absolute;
            font-size: 8px;
            color: ${({theme}) => theme.colors.performBlue[5]};
        }
    }
`;

export const OptionName = styled.span`
    padding-left: 10px;
    display: flex;
    align-items: center;
`;

export const SubOptions = styled.div`
    padding-left: 20px;
`;

export const SubOption = styled.div<IoptionProps>`
    border-radius: 8px;
    display: flex;
    padding: 8px 6px;
    font-size: 13px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    cursor: pointer;
    background-color: ${props => props.isActive && !props.multiselect ? props.theme.colors.performBlue[8] : ""};
    color: ${props => props.isActive && !props.multiselect ? props.theme.colors.pureWhite : props.theme.colors.deepBlue[5]};
    &:hover{
        background: ${props => !props.multiselect  && !props.isActive && props.theme.colors.performBlue[1]};
        color: ${props => props.multiselect && props.theme.colors.performBlue[5]};
        .checkbox {
            border-color: ${props => props.multiselect ? props.theme.colors.performBlue[5] : props.theme.colors.deepBlue[5]};
        }
    }

    .checkbox{
        cursor: pointer;
        position: relative;
        border: 1px solid ${props => props.isActive && props.multiselect ? props.theme.colors.performBlue[5] : props.theme.colors.deepBlue[4]};
        color: ${props => props.isActive ? props.theme.colors.performBlue[5] : "#8799A1"};
        width: 15px;
        height: 15px;
        .check {
            position: absolute;
            color: ${({theme}) => theme.colors.performBlue[5]};
        }
    }
`;

export const OptionsFooter = styled.div`
    background-color: ${({theme}) => theme.colors.pureWhite};
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    border-top: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    font-size: 12px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    color: ${({theme}) => theme.colors.deepBlue[4]};
    padding: 22px 0px 16px;
    cursor: pointer;
`;

export const ItemsNotDisplayed = styled.div`
  padding: 4px 8px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.coolGray[3]};
  color: ${({theme}) => theme.colors.coolGray[5]};
  font-size: 10px;
  line-height: 13px;
  margin-top: 10px;
`
