import React, {useEffect, useRef, useState} from 'react'

import {Wrapper, TagSearchLabel, TagSearchWrapper, TagsDropdownContainer} from "./AddTagsModal.style";
import {InputSingle} from "../../../../../../../common/components/input-single-line/InputSingleLine.style";
import OffersService, {IFetchAllTagsResponse, IFetchCampaignsResponse, IMap} from "../../Offers.service";
import Checkbox from "../../../../../../../common/components/checkbox/Checkbox";
import {ITag} from "../../../../../../../common/models/tag.type";
import {IAdvertiserTag} from "../../../../../../../common/models/advertiser.type";
import SelectionPills from "../../../../../../../common/components/selection-pills/SelectionPills";
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../../../../common/hooks";
import {ModalFooter} from "../../../../../../../common/components/modal/container/Container.style";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../../../common/styled-components";
import {IAssignTagsParams} from "../../../../advertisers/Advertisers.service";
import {tokenStore} from "../../../../../../../store/auth.slice";

interface Props {
    tags: IFetchAllTagsResponse[],
    closeModal: () => void,
    fetchData: (direction?: string | null, sort_by?: string | null, selectNodes? : boolean  ) => void,
    setAlert: (params: {
                   opened: boolean,
                   message: string,
                   type: 'success' | 'error'
               }
    ) => void,
    selected_offers: IFetchCampaignsResponse[]
    deselectRows: ()=> void,
}

const AddTagsModal: React.FC<Props> = ({
                                           tags,
                                           closeModal,
                                           fetchData,
                                           setAlert,
                                           selected_offers,
                                           deselectRows}) => {
    const token = useAppSelector(tokenStore)
    // const {selected_offers} = useAppSelector(state => state.offers)
    const [tagsSearchValue, setTagsSearchValue] = useState('')
    const [isTagDropdownOpened, setIsTagDropdownOpened] = useState(false)
    const [filteredTags, setFilteredTags] = useState<IFetchAllTagsResponse[]>([])
    const [tagsToAdd, setTagsToAdd] = useState<IFetchAllTagsResponse[]>([])
    const [loading, setLoading] = useState(false)
    const tagRef = useRef(null)

    const dispatch = useAppDispatch()

    const checkIfTagIsSelected = (selectedTag: ITag | IAdvertiserTag) => {
        return tagsToAdd.some(tag => tag.id === selectedTag.id)
    }

    const handleTagSelect = (tag: ITag | IAdvertiserTag) => {
        if(checkIfTagIsSelected(tag)){
            const newTags = tagsToAdd.filter(selectedTag => selectedTag.id !== tag.id)
            setTagsToAdd(newTags)
        } else {
            setTagsToAdd([...tagsToAdd, tag])
        }
    }

    const handleSubmit = async () => {
        deselectRows()
        const params: IAssignTagsParams = {
            property_ids: selected_offers.map(offer => offer.id),
            type: 'offer',
            tag_ids: tagsToAdd.map(tag => tag.id)
        }
        try{
            setLoading(true)
            await OffersService.addTags(token, params)

            setLoading(false)
            setAlert({
                opened: true,
                message: 'Tags successfully added!',
                type: 'success'
            })

        } catch (e) {
            setLoading(false)
            setAlert({
                opened: true,
                message: 'Something went wrong',
                type: 'error'
            })
        }
            fetchData(null, null, false)
        closeModal()
    }

    useEffect(() => {
        if(tags){
            setFilteredTags(
                tags.filter(tag => tag.name.toLowerCase().includes(tagsSearchValue.toLowerCase()))
            )
        }
    }, [tagsSearchValue, tags])

    useOnClickOutside(tagRef, () => setIsTagDropdownOpened(false))

    return <Wrapper>
        <TagSearchLabel>Using the field below, type out a tag you would like to use.</TagSearchLabel>
        <TagSearchWrapper ref={tagRef}>
            <InputSingle
                className={'search-tags-input'}
                onChange={(e) => setTagsSearchValue(e.target.value)}
                placeholder={'Type a tag to add'}
                value={tagsSearchValue}
                onFocus={() => setIsTagDropdownOpened(true)}
            />
            <TagsDropdownContainer opened={isTagDropdownOpened} >
                {filteredTags && filteredTags.map(tag => <div key={`${tag.id}-${tag.name}`} className={'tag'}>
                    <Checkbox onChange={() => handleTagSelect(tag)}
                              checked={tagsToAdd.some(selectedTag => tag.id === selectedTag.id)}
                              label={tag.name}/>
                    </div>)}
            </TagsDropdownContainer>
        </TagSearchWrapper>
        {tagsToAdd.map(tag => <SelectionPills key={tag.id} text={tag.name} onRemove={() => handleTagSelect(tag)} />)}
        <ModalFooter>
            <ButtonStyled className='btn-cancel' onClick={closeModal}>Cancel</ButtonStyled>
            <ButtonStyled
                className='btn-orange-filled'
                disabled={tagsToAdd.length < 1 || loading}
                onClick={handleSubmit}
            >
                <span>{loading && <BtnLoadingSpinner/>}Add Tags</span>
            </ButtonStyled>
        </ModalFooter>
    </Wrapper>
}

export default AddTagsModal