import styled, {css} from 'styled-components'

interface IPresetsStyleProps {
    active: boolean
}

export const Wrapper = styled.div`
  width: fit-content;
  display: inline;
  .presets-title{
    margin-left: 8px;
    margin-bottom: 4px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[5]};
  }
`

export const Option = styled.div<IPresetsStyleProps>`
  width: 90px;
  border-radius: 4px;
  border: 1px solid white;
  height: 24px;
  padding: 4px 8px;
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  display: flex;
  align-items: center;
  
  :hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    border: 1px solid ${({theme}) => theme.colors.performBlue[2]};
    color: ${({theme}) => theme.colors.deepBlue[5]};
  }
  
  ${props => props.active && css`
    background-color: ${({theme}) => theme.colors.performBlue[1]};
    border: 1px solid ${({theme}) => theme.colors.performBlue[2]};
    color: ${({theme}) => theme.colors.deepBlue[5]};
  `}
`