import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IAccountManagerFilter, IFilterHookReturn} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";

export const usePartnerAccountManagerFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<IAccountManagerFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IAccountManagerFilter[]>([])
    const [accountManagers, setAccountManagers] = useState<IAccountManagerFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [accountManagerFilterSearchValue, setAccountManagerFilterSearchValue] = useState('')
    const [accountManagersFilterNotDisplayed, setAccountManagersFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id ='partner-account-manager'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IAccountManagerFilter[]>(
                'account-managers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data.sort((a: IAccountManagerFilter, b: IAccountManagerFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredAccManagers = fetchedData.filter(manager => (
                manager.name.toLowerCase() + ' ' + manager.lastname.toLowerCase()).includes(accountManagerFilterSearchValue.toLowerCase())
            ||
            manager.id.toString().includes(accountManagerFilterSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredAccManagers].slice(0, displayCount)
            if(filteredAccManagers.length > displayCount){
                setAccountManagersFilterNotDisplayed(filteredAccManagers.length - displayCount)
            } else {
                setAccountManagersFilterNotDisplayed(0)
            }
            setAccountManagers(displayedAcc)
        } else {
            setAccountManagers(
                filteredAccManagers
            )
        }
    }, [accountManagerFilterSearchValue, fetchedData, displayCount])

    const accountManagerFilter = useFilterSelector<IAccountManagerFilter>(pageId, id)

    const setSelectedAccountManagers = (manager: IAccountManagerFilter) => {
        if(accountManagerFilter.selected.some((filterManager)  => filterManager.id === manager.id)){
            dispatch(setFilter<IAccountManagerFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: accountManagerFilter.exclude,
                    selected: accountManagerFilter.selected.filter(accManager => accManager.id !== manager.id)
                }
            }))
        } else {
            dispatch(setFilter<IAccountManagerFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: accountManagerFilter.exclude,
                    selected: [...accountManagerFilter.selected, manager]
                }

            }))
        }
    }

    const setExcludeAccountManager = (exclude: boolean) => {
        dispatch(setFilter<IAccountManagerFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: accountManagerFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: accountManagers,
        setSearchValue: setAccountManagerFilterSearchValue,
        notDisplayed: accountManagersFilterNotDisplayed,
        selectedData: accountManagerFilter.selected,
        exclude: accountManagerFilter.exclude,
        setSelectedData: setSelectedAccountManagers,
        setExclude: setExcludeAccountManager,
        clearSelected: clearSelectedFilter
    }
}

