import React, {useEffect, useMemo, useState} from 'react'
import {Expandable} from '../../../../components/Expandable'
import {StyledSelect} from "../../../../../../../../common/components/select/Select";
import styled from "styled-components";
import {Tooltip} from "../../../../shared-components/Shared";
import {MAIN_CONVERSION_PIXEL_GROUP_TOOLTIP} from "../../../../shared-components/consts";
import ReactTooltip from "react-tooltip";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import Modal from "../../../../../../../../common/components/modal/Modal";
import CreatePixelGroup from "./CreatePixelGroup";
import EditPixelGroup from "./EditPixelGroup";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {setOffer} from "../../../../create-offer.slice";
import {setCampaign} from "../../../../../offers/cpa/Cpa.slice";

export const PixelGroups: React.FC = () => {

    const {offer, pixelGroups} = useAppSelector(state => state.createOffer)

    const [createPixelGroupModal, setCreatePixelGroupModal] = useState<{opened: boolean, type: 'upsell' | 'offer', eventId?: number}>({
        opened: false,
        type: 'offer'
    })
    const [editPixelGroupModal, setEditPixelGroupModal] = useState<{opened: boolean, type: 'upsell' | 'offer', groupId?: number, eventId?: number}>({
        opened: false,
        type: 'offer'
    })

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const dispatch = useAppDispatch()

    const mainConversionPixelGroupOptions = useMemo(() => {
        return [...pixelGroups.offer.map(group => {
            return {
                label: group.name,
                value: group
            }
        })]
    }, [pixelGroups.offer])

    const eventPixelGroupOptions = useMemo(() => {
        return [...pixelGroups.upsell.map(group => {
            return {
                label: group.name,
                value: group,
                disabled: offer.upsells.some(upsell => upsell.pixel_group_id === group.id)
            }
        })]
    }, [offer.upsells, pixelGroups.upsell])

    const handleSelectMainConversionPixelGroup = (e: any) => {
        if(e.value === 0){
            setCreatePixelGroupModal({
                opened: true,
                type: "offer"
            })
            dispatch(setOffer({
                pixel_group_id: null,
                pixel_group: null
            }))
        } else if (e.value === null) {
            dispatch(setOffer({
                pixel_group_id: null,
                pixel_group: null
            }))
        } else {
            dispatch(setOffer({
                pixel_group_id: e.value.id,
                pixel_group: e.value
            }))
        }
    }

    const handleSelectEventPixelGroup = (e: any, eventId: number, index: number) => {
        if(e.value === 0){
            setCreatePixelGroupModal({
                opened: true,
                type: "upsell",
                eventId
            })
            // dispatch(setOffer({
            //     pixel_group_id: null,
            //     pixel_group: null
            // }))
        } else if (e.value === null) {
            const newEvents = [...offer.upsells]
            newEvents[index] = {...newEvents[index], pixel_group: null, pixel_group_id: null}
            dispatch(setOffer({
                upsells: [...newEvents]
            }))
        } else {
            const newEvents = [...offer.upsells]
            newEvents[index] = {...newEvents[index], pixel_group: e.value, pixel_group_id: e.value?.id}
            dispatch(setOffer({
                upsells: [...newEvents]
            }))
        }
    }

    return <Expandable title={'Pixel Groups'} locked={false}>
        <ReactTooltip
            class={"react-tooltip-container"}
            arrowColor={"white"}
            place={"top"}
            effect="solid"
            type={'light'}
            multiline={true}
        />
        <Wrapper>
            <p className="text-description">
                Assign the main conversion and events to a pixel group to utilize a shared pixel. By default conversions
                and events won’t be assigned to a pixel group. If assigned to a group, the pixels above will be updated
                to reflect a new group id instead of campaign id.
            </p>
            <div className={'pixel-group-row'}>
                <StyledSelect
                    selectWrapperClassname={'select-wrapper'}
                    selectLabel={
                        <>
                            Main Conversion Pixel Group <span className={'optional'}>(optional)</span> {Tooltip(212, MAIN_CONVERSION_PIXEL_GROUP_TOOLTIP)}
                        </>
                    }
                    onChange={handleSelectMainConversionPixelGroup}
                    placeholder={'Choose a pixel group to assign this conversion to'}
                    options={[
                        {
                            label: 'None',
                            value: null,
                        },
                        {
                            label: 'Create Pixel Group',
                            value: 0
                        },
                        ...mainConversionPixelGroupOptions
                    ]}
                    isOptionDisabled={option => option.disabled ? option.disabled : false}
                    value={{
                        label: offer.pixel_group ? offer.pixel_group.name : 'None',
                        value: offer.pixel_group ? offer.pixel_group : null
                    }}
                />
                {offer.pixel_group &&
                    <ButtonStyled
                        style={{
                            marginLeft: '8px'
                        }}
                        onClick={() => setEditPixelGroupModal({
                            opened: true,
                            type: "offer",
                            groupId: offer.pixel_group?.id
                        })}
                    >
                        Edit
                    </ButtonStyled>}
            </div>
            {offer.upsells.map((upsell: any, index) => {
                return <div className={'pixel-group-row'} key={upsell.id}>
                            <StyledSelect
                                selectWrapperClassname={'select-wrapper'}
                                selectLabel={
                                    <>
                                        Event #{index + 1} Pixel Group <span className={'optional'}>(optional)</span>
                                    </>
                                }
                                onChange={(e) => handleSelectEventPixelGroup(e, upsell.id, index)}
                                placeholder={'Choose a pixel group to assign this event to'}
                                value={{
                                    label: upsell.pixel_group ? upsell.pixel_group.name : 'None',
                                    value: upsell.pixel_group ? upsell.pixel_group : null
                                }}
                                isOptionDisabled={option => option.disabled ? (option.disabled && upsell.pixel_group_id !== option.value.id) : false}
                                options={[
                                    {
                                        label: 'None',
                                        value: null,
                                    },
                                    {
                                        label: 'Create Pixel Group',
                                        value: 0
                                    },
                                    ...eventPixelGroupOptions
                                        ]}
                            />
                        {upsell.pixel_group &&
                        <ButtonStyled
                            style={{
                                marginLeft: '8px'
                            }}
                            onClick={() => setEditPixelGroupModal({
                                opened: true,
                                type: "upsell",
                                groupId: upsell.pixel_group?.id,
                                eventId: upsell.id
                            })}
                        >
                            Edit
                        </ButtonStyled>}
                      </div>
            })}
        </Wrapper>
        <Modal
            opened={createPixelGroupModal.opened}
            title={'Create Pixel Group'}
            closeModal={() => setCreatePixelGroupModal({opened: false, type: 'offer'})}
        >
            <CreatePixelGroup
                eventId={createPixelGroupModal.eventId}
                type={createPixelGroupModal.type}
                closeModal={() => setCreatePixelGroupModal({opened: false, type: 'offer'})}
            />
        </Modal>

        <Modal
            opened={editPixelGroupModal.opened}
            title={'Edit Pixel Group'}
            closeModal={() => setEditPixelGroupModal({opened: false, type: 'offer'})}
        >
            <EditPixelGroup
                groupId={editPixelGroupModal.groupId}
                type={editPixelGroupModal.type}
                closeModal={() => setEditPixelGroupModal({opened: false, type: 'offer'})}
                eventId={editPixelGroupModal.eventId}
            />
        </Modal>
    </Expandable>
}

const Wrapper = styled.div`
    .pixel-group-row{
        margin-top: 32px;
        display: flex;
    }
    .select-wrapper{
        margin-bottom: 0;
        width: 100%;
    }
`