import React, {ChangeEvent, useCallback, useEffect, useState} from "react";

import Dropdown from "../../../../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem, ItemsNotDisplayed} from "../../../../../../../../../common/components/dropdown/Dropdown.style";
import Tooltip from "../../../../../../../../../common/components/tooltip/Tooltip";
import DatePicker from "../../../../../../../../../common/components/date-picker/DatePicker";

import {
    Wrapper,
    Label,
    SearchPartnersInput,
    ClearAllPartner,
    AffiliatesListDropdownWrapper,
    AffiliatesListItem,
    Tier,
    TierInput,
    InputStyled,
    SearchWrapper
} from './TrafficBased.style'

import {ITrafficBasedProps} from "./trafficBased.props";
import Checkbox from "../../../../../../../../../common/components/checkbox/Checkbox";
import {IFetchAffiliatesResponse} from "../../../../Billing.service";
import {formatNumberToCurrency} from "../../../../../../../../../common/utils";

interface ITierError {
    from: boolean,
    to: boolean
}

const TrafficBased:React.FC<ITrafficBasedProps> = ({
                                                       trafficSource,
                                                       partnersIncluded,
                                                       tiers,
                                                       setTiers,
                                                       setTrafficSource,
                                                       setPartnersIncluded,
                                                       allAffiliates,
                                                       selectedAffiliates,
                                                       setSelectedAffiliates,
                                                       platforms,
                                                       startDate,
                                                       setStartDate,
                                                       endDate,
                                                       setEndDate,
                                                       monthlyMinimum,
                                                       setMonthlyMinimum,
                                                       passThrough,
                                                       calculateBy,
                                                       setCalculateBy
}) => {

    const [trafficBasedOpened, setTrafficBasedOpened] = useState(false)
    const [partnersOpened, setPartnersOpened] = useState(false)
    const [partnersToExcludeOpened, setPartnersToExcludeOpened] = useState(false)
    const [filteredPartners, setFilteredPartners] = useState(allAffiliates)
    const [affiliateSearchValue, setAffiliateSearchValue] = useState('')
    const [calculateByOpened, setCalculateByOpened] = useState(false)

    const [tierErrors, setTierErrors] = useState<ITierError[]>([
        {from: false, to: false},
        {from: false, to: false},
        {from: false, to: false},
    ])

    const handleTrafficSourceSelect = useCallback((source: number) => {
        setTrafficSource(source)
        setTrafficBasedOpened(false)
    }, [setTrafficSource])

    const handlePartnersIncExcSelect = useCallback((inc: number) => {
        setPartnersOpened(false)
        setPartnersIncluded(inc)
    }, [setPartnersIncluded])

    const checkIfAffiliateIsSelected = useCallback((id: number): boolean => {
        return selectedAffiliates.some(affiliate => affiliate.id === id)
    }, [selectedAffiliates])

    const handleAffiliateCheck = useCallback((affiliate: IFetchAffiliatesResponse) => {
        if(checkIfAffiliateIsSelected(affiliate.id)){
            setSelectedAffiliates(selectedAffiliates.filter(affiliateSelected => affiliateSelected.id !== affiliate.id))
        } else {
            setSelectedAffiliates([...selectedAffiliates, affiliate])
        }
    }, [checkIfAffiliateIsSelected, setSelectedAffiliates, selectedAffiliates])

    const handleClearAllSelectedAffiliates = useCallback(() => {
        setSelectedAffiliates([])
    }, [setSelectedAffiliates])

    const handleCalculateBySelect = useCallback((type: string) => {
        setCalculateBy(type)
        setCalculateByOpened(false)
    }, [])

    const filterAffiliates = useCallback(() => {
        setFilteredPartners(allAffiliates.filter(affiliate => {
            return (affiliate.name.toLowerCase() + affiliate.lastname.toLowerCase()).includes(affiliateSearchValue.toLowerCase()) ||
                affiliate.id.toString().includes(affiliateSearchValue.toLowerCase())
        }))
    }, [affiliateSearchValue, allAffiliates])

    useEffect(() => {
        filterAffiliates()
    }, [affiliateSearchValue])

    const handleAddTier = useCallback(() => {

            if(tiers[tiers.length - 1].from !== '' && tiers[tiers.length - 1].to !== '' && tiers[tiers.length - 1].percentage !== '' ){
                setTiers([...tiers, {
                    order: tiers.length + 1,
                    from: formatNumberToCurrency(parseFloat(tiers[tiers.length - 1].to.toString().replace(/[^0-9.]+/g, '')) + 1),
                    to: '',
                    percentage: ''
                }])
                setTierErrors([...tierErrors, {from: false, to:false}])
            }

    }, [tiers, setTiers])

    const handleTierRemove = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers.splice(index, 1)
        setTiers(newTiers)
    }, [tiers, setTiers])

    const handleTierFromChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid){
            const newTiers = [...tiers]
            newTiers[index].from = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleTierToChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newTiers = [...tiers]
            newTiers[index].to = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleTierPercentageChange = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newTiers = [...tiers]
            newTiers[index].percentage = e.target.value
            setTiers(newTiers)
        }
    }, [tiers, setTiers])

    const handleMonthlyMinimumChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid) {
            setMonthlyMinimum(e.target.value)
        }
    }, [setMonthlyMinimum])

    const handleMonthlyMinimumBlur = useCallback(() => {
        setMonthlyMinimum(formatNumberToCurrency(parseFloat(monthlyMinimum.toString().replace(/[^0-9.]+/g, ''))))
    }, [monthlyMinimum, setMonthlyMinimum])

    const handleTierFromBlur = useCallback((index: number) => {
            const newTiers = [...tiers]
            newTiers[index].from = formatNumberToCurrency(parseFloat(newTiers[index].from.toString().replace(/[^0-9.]+/g, '')));
            setTiers(newTiers)

        //If tier from is less then previous tier to value
        if(tiers[index - 1]){
            const newTiersErrors = [...tierErrors]
            if(
                parseFloat(newTiers[index].from.toString().replace(/[^0-9.]+/g, ''))
                <=
                parseFloat(newTiers[index - 1].to.toString().replace(/[^0-9.]+/g, '')))
            {

                newTiersErrors[index] = {from: true, to: newTiersErrors[index].to}
                setTierErrors(newTiersErrors)
            } else {
                newTiersErrors[index] = {from: false, to: newTiersErrors[index].to}
                setTierErrors(newTiersErrors)
            }
        }
    }, [tiers, setTiers, tierErrors])

    const handleTierToBlur = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers[index].to = formatNumberToCurrency(parseFloat(newTiers[index].to.toString().replace(/[^0-9.]+/g, '')));
        if(tiers[index + 1]){
            newTiers[index + 1].from = formatNumberToCurrency(parseFloat(tiers[index].to.toString().replace(/[^0-9.]+/g, '')) + 1)
        }
        if(tiers[index].to === ''){
            newTiers.splice(index + 1)
        }
        setTiers(newTiers)
        //If tier to is less then tier from value
        const newTiersErrors = [...tierErrors]
        if(
            parseFloat(newTiers[index].to.toString().replace(/[^0-9.]+/g, ''))
            <=
            parseFloat(newTiers[index].from.toString().replace(/[^0-9.]+/g, '')))
        {
            newTiersErrors[index] = {to: true, from: newTiersErrors[index].from}
            setTierErrors(newTiersErrors)
        } else {
            newTiersErrors[index] = {to: false, from: newTiersErrors[index].from}
            setTierErrors(newTiersErrors)
        }
    }, [tiers, setTiers, tierErrors])

    const handleTierPercentageBlur = useCallback((index: number) => {
        const newTiers = [...tiers]
        newTiers[index].percentage =
            isNaN(parseFloat(newTiers[index].percentage.toString().replace(/[^0-9.]+/g, ''))) ? '' :
                parseFloat(newTiers[index].percentage.toString().replace(/[^0-9.]+/g, '')) + '%';
        setTiers(newTiers)
    }, [tiers, setTiers])

    const getPlatformDisplayValue = useCallback(() => {
        if(trafficSource === 0) return 'LEAP'
        if(!trafficSource) return ''

        const plat = platforms.filter(platform => platform.id === trafficSource)[0]

        if(plat) return `Affluent (${plat.affluent_advertiser_id})`

        return ''
    }, [trafficSource, platforms])

    const checkTierFromError = (index: number): boolean => {
        if(tiers[index - 1]){
            if(
                parseFloat(tiers[index].from.toString().replace(/[^0-9.]+/g, ''))
                <=
                parseFloat(tiers[index - 1].to.toString().replace(/[^0-9.]+/g, '')))
            {
                return true
            }
        }
        return false
    }

    const checkTierToError = (index: number): boolean => {
        if(
            parseFloat(tiers[index].to.toString().replace(/[^0-9.]+/g, ''))
            <=
            parseFloat(tiers[index].from.toString().replace(/[^0-9.]+/g, '')))
        {
            return true
        }

        return false
    }

    return <Wrapper>
        <div className="row">
            <Label>
                <span>Traffic Source</span>
                <Tooltip position={"top"}>
                    <div style={{
                        width: '166px'
                    }}>
                        <p>Select where the marketer's traffic data should be pulled from.</p>
                        <br/>
                        <p><strong>Note:</strong> If the traffic source that is required isn’t visible here, visit the home tab under edit marketer and add it in the data sources section.</p>
                    </div>
                </Tooltip>
            </Label>
            <div>
                <Dropdown
                    text={getPlatformDisplayValue()}
                    placeholder={'Select traffic source'}
                    isOpened={trafficBasedOpened}
                    setOpened={setTrafficBasedOpened}
                >
                    <DropdownItem
                        onClick={() => handleTrafficSourceSelect(0)}
                        selected={trafficSource === 0}
                    >
                        LEAP
                    </DropdownItem>
                    {platforms.map(platform => {
                        return <DropdownItem key={platform.id} onClick={() => handleTrafficSourceSelect(platform.id)}>
                            {`Affluent (${platform.affluent_advertiser_id})`}</DropdownItem>
                    })}
                </Dropdown>
            </div>
        </div>
        <div className="row">
            <Label>
                <span>Partners Inclusion/Exclusion</span>
                <Tooltip position={'top'}>
                    <div style={{
                    width: '204px'
                    }}>
                        <p>
                            By default all partners will be included in the fee calculations. If needed, select which partner(s) to include or exclude from the traffic data being pulled in from the traffic source selected above.
                        </p>
                    </div>
                </Tooltip>
            </Label>
            <div style={{
                display: 'flex',
                gap: '10px'
            }}>
                <Dropdown text={partnersIncluded === 1 ? 'All partners included' : 'Exclude specific partners'} isOpened={partnersOpened} setOpened={setPartnersOpened}>
                    <DropdownItem
                        onClick={() => handlePartnersIncExcSelect(1)}
                        selected={partnersIncluded === 1}
                    >
                        All Partners Included
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => handlePartnersIncExcSelect(0)}
                        selected={partnersIncluded === 0}
                    >
                        Exclude Specific Partners
                    </DropdownItem>
                </Dropdown>
                {partnersIncluded === 0 &&
                    <Dropdown
                        text={`${selectedAffiliates ? selectedAffiliates.length : 0} partners excluded`}
                        isOpened={partnersToExcludeOpened}
                        setOpened={setPartnersToExcludeOpened}
                        dropdownHeightLimit={10}
                        // overflow={false}
                    >
                        <SearchWrapper>
                        <SearchPartnersInput value={affiliateSearchValue} onChange={(e) => setAffiliateSearchValue(e.target.value)} placeholder={'Search partners'}/>
                        </SearchWrapper>
                        <AffiliatesListDropdownWrapper>
                            {filteredPartners.slice(0, 50).map(affiliate => {
                                return <AffiliatesListItem key={affiliate.id} >
                                    <Checkbox
                                        checked={checkIfAffiliateIsSelected(affiliate.id)}
                                        onChange={() => handleAffiliateCheck(affiliate)}
                                        label={`${affiliate.name} ${affiliate.lastname} (${affiliate.id})`}
                                    />
                                </AffiliatesListItem>
                            })}
                            {filteredPartners.length > 50 && <ItemsNotDisplayed>{filteredPartners.length - 50} affiliates not displayed</ItemsNotDisplayed>}
                        </AffiliatesListDropdownWrapper>
                        <ClearAllPartner>
                            <span onClick={() => handleClearAllSelectedAffiliates()} className={'clear-all-button'}>Clear All</span>
                        </ClearAllPartner>
                    </Dropdown>
                }
            </div>
        </div>
        <div className="row">
            <Label>
                Fee Tiers 
                <span className="optional">
                    (Optional)
                </span>
                <Tooltip position={"top"}>
                    <div style={{
                        width: '253px'
                    }}>
                        <p>Add the tier(s) to calculate the traffic by. For example:</p>
                        <br/>
                        <p>$0 to $50,000 is 10%</p>
                        <p>$50,001 to $100,000 is 5%</p>
                        <p>$100,001 to “Empty for Unlimited” is 2%</p>
                        <br/>
                        <p>
                            Note: If the marketer’s traffic is being combined with another fee such as paid media, leave this section blank to be combined with the other fee(s) in a tiered group.
                        </p>
                    </div>
                </Tooltip>
            </Label>
            {tiers.map((tier, index) => {
                return <Tier key={index}>
                    <span style={{
                        whiteSpace: 'nowrap'
                    }}>Tier {index + 1} </span>
                    <TierInput
                        pattern={"[0-9, $, .]*"}
                        value={tier.from.toString()}
                        onChange={(e) => handleTierFromChange(e, index)}
                        placeholder={'$'}
                        onBlur={() => handleTierFromBlur(index)}
                        error={checkTierFromError(index)}
                    />
                    to
                    <TierInput
                        pattern={"[0-9, $, .]*"}
                        value={tier.to.toString()}
                        onChange={(e) => handleTierToChange(e, index)}
                        placeholder={'$ or leave empty for Unlimited'}
                        onBlur={() => handleTierToBlur(index)}
                        error={checkTierToError(index)}
                    />
                    is
                    <TierInput
                        pattern={"[0-9,%.]*"}
                        value={tier.percentage.toString()}
                        onChange={(e) => handleTierPercentageChange(e, index)}
                        style={{width: '58px'}}
                        placeholder={'%'}
                        onBlur={() => handleTierPercentageBlur(index)}
                        error={false}
                    />
                    {index === 0 ?
                        <span onClick={handleAddTier} className="material-icons-outlined">
                            add_circle
                        </span> :
                        <span onClick={() => handleTierRemove(index)} className="material-icons">
                            cancel
                        </span>
                    }

                </Tier>
            })}
        </div>
        {tiers[0].from !== '' && !passThrough &&
            <div className="row">
                <Label>
                    Monthly Minimum Charges
                    <span className="optional">
                        (Optional)
                    </span>
                    <Tooltip position={'top'}>
                        <div style={{
                            width: '158px'
                        }}>
                            <p>Set an optional monthly minimum charge to the marketer if the traffic doesn’t reach the set tier amount.</p>
                        </div>
                    </Tooltip>
                </Label>
                <InputStyled
                    placeholder={'$'}
                    value={monthlyMinimum}
                    onChange={(e) => handleMonthlyMinimumChange(e)}
                    pattern={"[0-9, $, .]*"}
                    onBlur={handleMonthlyMinimumBlur}
                />
            </div>
        }

        <div className="row">
            <Label>
                <span>Calculate By</span>
                <Tooltip position={'top'}>
                    <div style={{
                        width: '204px'
                    }}>
                        <p>
                            Set the amount to calculate the fee by:
                        </p>
                        <br/>
                        <p>
                            Total cost for marketer = how much has been paid out to partners
                        </p>
                        <br/>
                        <p>
                            Total sale/revenue amount to marketer = how much the marketer has made from consumers
                        </p>
                    </div>
                </Tooltip>
            </Label>
            <Dropdown
                placeholder={'Select value to calculate by'}
                text={`${calculateBy === 'cost' ? 'Total cost for marketer' : calculateBy === 'revenue' ? 'Total revenue for marketer' : ''}`}
                isOpened={calculateByOpened}
                setOpened={setCalculateByOpened}
            >
                <DropdownItem
                    selected={calculateBy === 'cost'}
                    onClick={() => handleCalculateBySelect('cost')}
                >
                    Total cost for marketer
                </DropdownItem>
                <DropdownItem
                    selected={calculateBy === 'revenue'}
                    onClick={() => handleCalculateBySelect('revenue')}
                >
                    Total revenue for marketer
                </DropdownItem>
            </Dropdown>
        </div>
        <div className="row">
            <Label>Fee date - Recurring Monthly</Label>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px'
            }}>
                <div style={{
                    width: '100%'
                }}>
                    <span className={'fee-date--label'}>Start date</span>
                    <DatePicker
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        date={startDate}
                        setDate={setStartDate}
                        inputWidth={'100%'}
                        placeholder={'Add start date'}
                    />
                </div>
                <div style={{
                    width: '100%'
                }}>
                    <span className={'fee-date--label'}>End date <span className={'optional'}>(Optional)</span></span>
                    <DatePicker
                        minDate={startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1)) : new Date(new Date().setDate(new Date().getDate() + 1))}
                        date={endDate}
                        setDate={setEndDate}
                        inputWidth={'100%'}
                        placeholder={'Add end date'}
                    />
                </div>
            </div>
        </div>
    </Wrapper>
}

export default TrafficBased