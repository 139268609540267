import React from 'react';
import { format } from "date-fns";
import { FilterID } from "filters";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DateRangeInput from "../../../../../../common/components/daterange-input/DateRangeInput";
import Grid from "../../../../../../common/components/grid/Grid";
import Icon from "../../../../../../common/components/icon/Icon";
import DropdownPreset from "../../../../../../common/components/range-date-picker/dropdown-preset/DropdownPreset";
import { IPreset } from "../../../../../../common/components/range-date-picker/presets/presets.props";
import RangeDatePicker from "../../../../../../common/components/range-date-picker/RangeDatePicker";
import { useColumnsOptionHook } from "../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import Pagination from "../../../../../../common/components/table/pagination/Pagination";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import { useAppDispatch, useAppSelector, useOnClickOutside } from "../../../../../../common/hooks";
import { useFilterSelector } from "../../../../../../common/hooks/useFilterSelector";
import { ButtonStyled } from "../../../../../../common/styled-components";
import { formatNumberToCurrency } from "../../../../../../common/utils";
import { PageID } from "../../../../../../store/filters.slice";
import BillingTransactionsService, { IBillingTransactionsResponse } from "./BillingTransactions.service";
import { setDateFrom, setDateRangePreset, setDateTo, setPage, setPagination, setPaginationColumnsList, setPerPage, setBillingTransactionsSort } from "./BillingTransactions.slice";

import { 
    BillingTransactionsWrapper, 
    DateFilterWrapper, 
    DatePresetWrapper, 
    DropdownWrapper, 
    FilterWrapper, 
    RangeWrapper,
    FiltersPaginationSection
} from "./BillingTransactions.style";
import { allPaginationColumnsList } from "./columnDefs";
import Filters from "./filters/Filters";

interface Props {
    triggerFetch: boolean;
}

const BillingTransactions: React.FC<Props> = ({triggerFetch}) => {

    const {
        date_from,
        date_to,
        date_range_preset,
        pagination,
        billingSort,
        paginationColumnsList
    } = useAppSelector(state => state.billingTransactions);
    
    const {token} =  useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const {id} = useParams<{ id: string }>()

    const presetContainerRef = useRef<HTMLDivElement | null>(null)
    const dateRangeRef = useRef<HTMLDivElement | null>(null)

    const [presetDropdownOpened, setPresetDropdownOpened] = useState(false)
    const [dateFilterOpened, setDateFilterOpened] = useState(false)
    const [tableData, setTableData] = useState<IBillingTransactionsResponse[]>([])
    const [loading, setLoading] = useState(false);
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);

    const sortHandler = (sort: {
        direction: 'desc' | 'asc',
        by: string
    }) => {
        dispatch(setBillingTransactionsSort(sort))
    }

    const allColumnDefs:any = [
        {   
            width: 95,
            colId: 1,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data;
                return <div>{format(new Date(transaction.created_at.replace(/-/g, "/")), "MM/dd/y")}</div>
            },
            // headerName: "DATE"
            headerComponent: (data: any ) => {

                return <SortableHeaderV2 
                    onClick={() => sortHandler({
                        by: "created_at",
                        direction: billingSort.direction === "desc" ? "asc" : "desc"
                    })} 
                    keyId="created_at" 
                    sortedBy={billingSort.by} 
                    sortDirection={billingSort.direction}
                    >
                        DATE
                    </SortableHeaderV2>
            }
        },
        {   
            width: 95,
            colId: 2,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{format(new Date(transaction.created_at.replace(/-/g, "/")), "p")}</div>
            },
            headerName: "TIME"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //     onClick={() => sortHandler({
            //         by: "time",
            //         direction: sort.by === "time" && sort.direction === "desc" ? "asc" : "desc"
            //     })}  
            //     keyId="time" 
            //     sortedBy={sort.by} 
            //     sortDirection={sort.direction}
            //     >
            //         TIME
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 3,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div></div>
            },
            headerName: "TRANSACTION ID"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "id",
            //             direction: sort.by === "id" && sort.direction === "desc" ? "asc" : "desc"
            //         })}  
            //         keyId="id" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         TRANSACTION ID
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 4,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{transaction.description}</div>
            },
            headerName: "DESCRIPTION"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "description",
            //             direction: sort.by === "description" && sort.direction === "desc" ? "asc" : "desc"
            //         })}   
            //         keyId="description" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         DESCRIPTION
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 5,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{transaction.type_name}</div>
            },
            headerName: "TRANSACTION TYPE"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "type_name",
            //             direction: sort.by === "type_name" && sort.direction === "desc" ? "asc" : "desc"
            //         })}  
            //         keyId="type_name" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         TRANSACTION TYPE
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 6,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{transaction.currency_type_name}</div>
            },
            headerName: "CURRENCY TYPE"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "currency_type_name",
            //             direction: sort.by === "currency_type_name" && sort.direction === "desc" ? "asc" : "desc"
            //         })}   
            //         keyId="currency_type_name" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         CURRENCY TYPE
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 7,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data        
                return <div>{transaction.status_name}</div>
            },
            headerName: "STATUS"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "status",
            //             direction: sort.by === "status" && sort.direction === "desc" ? "asc" : "desc"
            //         })}  
            //         keyId="status" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         STATUS
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 8,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                if(transaction.credit > 0) return <div>{formatNumberToCurrency(transaction.credit-transaction.debit)}</div>
                if(transaction.debit > 0) return <div>-{formatNumberToCurrency(transaction.debit)}</div>
                return <div>{formatNumberToCurrency(0)}</div>
            },
            headerName: "CHANGE"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "change",
            //             direction: sort.by === "change" && sort.direction === "desc" ? "asc" : "desc"
            //         })}  
            //         keyId="change" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         CHANGE
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 9,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{formatNumberToCurrency(transaction.funding_available)}</div>
            },
            headerName: "FUNDING AVAILABLE"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "funding_available",
            //             direction: sort.by === "funding_available" && sort.direction === "desc" ? "asc" : "desc"
            //         })}   
            //         keyId="funding_available" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         FUNDING AVAILABLE
            //     </SortableHeaderV2>
            // }
        },
        {
            colId: 10,
            cellRenderer: (data: any) => {
                const transaction: IBillingTransactionsResponse = data.data
                return <div>{transaction.payment_processed ? "Yes" : "No"}</div>
            },
            headerName: "PROCESSED?"
            // headerComponent: (data: any ) => {
            //     return <SortableHeaderV2 
            //         onClick={() => sortHandler({
            //             by: "precessed",
            //             direction: sort.by === "precessed" && sort.direction === "desc" ? "asc" : "desc"
            //         })}  
            //         keyId="processed" 
            //         sortedBy={sort.by} 
            //         sortDirection={sort.direction}
            //     >
            //         PROCESSED?
            //     </SortableHeaderV2>
            // }
        },
    ]

    const setPaginationColumnsListHandler = (list:{
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setPaginationColumnsList(list))
    }

    const [columnDefs, setColumnDefs] = useState<any[]>([...allColumnDefs]);
    const [colWidths, setColWidths] = useState(allColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId}
    }));

    const [enableAllHandler, disableAllHandler, enableDisableColumnHandler]: any = useColumnsOptionHook(
        allColumnDefs, 
        columnDefs, 
        setColumnDefs,
        allPaginationColumnsList,
        paginationColumnsList, 
        setPaginationColumnsListHandler
    );

    useEffect(()=> {
        const checkedColumns = paginationColumnsList.filter((item: any) => item.checked);
            if(paginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                })
                setColumnDefs(newDefs);
            } else {
                setColumnDefs([]);
            }
    }, [paginationColumnsList, billingSort]);

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allPaginationColumnsList.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });
        
        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })
        
        setColWidths(widths);
        setColumnDefs(widthToCol);
        
        const rest = paginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        setPaginationColumnsListHandler([...newPaginCol, ...rest]);
    }

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50,
        autoHeight: true
    }), []);

    // const gridOptions = useMemo(() => ({
    //     columnDefs: columnDefs,
    //     defaultColumnDefs: defaultColumnDefs,
    // }), []);

    const tempDateFrom = useRef<Date | null | undefined>(null)
    const tempDateTo = useRef<Date | null | undefined>(null)

    useEffect(() => {
        tempDateFrom.current = date_from
        tempDateTo.current = date_to
    }, [])
    

    useOnClickOutside(dateRangeRef, (e) => {
        const dateFormater = (date: string | Date | undefined | null) => {
            return format(new Date(date ? date : new Date()), "E..EEE LLL dd")
        }
        if(presetContainerRef.current?.contains(e.target as Node)){
            return
        }
        if(dateFilterOpened){
            if(tempDateFrom || tempDateTo){
                if (dateFormater(tempDateFrom.current) !== dateFormater(date_from) || 
                    dateFormater(tempDateTo.current) !== dateFormater(date_to)) {
                    fetchTransactionsData()
                    tempDateFrom.current = date_from
                    tempDateTo.current = date_to
                }
            }
        }
        setPresetDropdownOpened(false)
        setDateFilterOpened(false)
    });

    const {
        filters
    } = useAppSelector(state => state.filters);

    const readFilter = (page: PageID, filterId: FilterID) => {
        return filters?.find(el => 
            el.pageID === page)?.
            filters.find(filter => filter.id === filterId) || null
    }

    // const transactionTypeFilter = useFilterSelector<any>('admin/users/marketers/id/credit', 'transaction-type');
    const transactionTypeFilter = readFilter('admin/users/marketers/id/credit', 'transaction-type');
    const currencyTypeFilter = readFilter('admin/users/marketers/id/credit', 'currency-type');
    const transactionStatusesFilter = readFilter('admin/users/marketers/id/credit', 'transaction-statuses');


    const fetchTransactionsData = async () => {
        setLoading(true)
        setDiscoverDisplayed(false)
        let params: {
            paginate_by: number,
            sort_by: string,
            order: "desc" | "asc",
            page: number,
            from: string,
            to: string,
            transaction_type?: {
                ids: string[],
                include: boolean
            },
            currency_type?: {
                ids: string[],
                include: boolean
            },
            transaction_status?: {
                ids: string[],
                include: boolean
            },
        } = {
            paginate_by: pagination.per_page,
            sort_by: billingSort.by,
            order: billingSort.direction,
            page: pagination.page,
            from: format(new Date(date_from ? date_from : new Date()), "y/MM/d"),
            to: format(new Date(date_to ? date_to : new Date()), "y/MM/d"),
        }

        if(transactionTypeFilter?.selected.length) {
            params.transaction_type = {
                ids: transactionTypeFilter.selected.map(selected => selected.id),
                include: !transactionTypeFilter.exclude
            };
        }

        if(currencyTypeFilter?.selected.length) {            
            params.currency_type = {
                ids: currencyTypeFilter.selected.map(selected => selected.id),
                include: !currencyTypeFilter.exclude
            };
        }
        
        if(transactionStatusesFilter?.selected.length) {
            params.transaction_status = {
                ids: transactionStatusesFilter.selected.map(selected => selected.id),
                include: !transactionStatusesFilter.exclude
            };
        }
        try {
            const response = await BillingTransactionsService.fetchTransactionsData(token, params, Number(id))
            setTableData(response.data.data)
            dispatch(setPagination({
                from: response.data.from,
                to: response.data.to,
                last_page: response.data.last_page,
                total: response.data.total
            }))
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const setPreset = (preset: IPreset) => {
        dispatch(setDateRangePreset(preset));
    };

    useEffect(()=>{        
        setDiscoverDisplayed(true)
    }, [
        transactionTypeFilter, transactionTypeFilter,
        currencyTypeFilter, currencyTypeFilter,
        transactionStatusesFilter, transactionStatusesFilter
    ])

    useEffect(() => {
        token && fetchTransactionsData();
    }, [token, pagination.per_page, pagination.page, billingSort, triggerFetch]);

    useEffect(() => {        
        setDiscoverDisplayed(false)
    }, [])

    const exportCsv = () => {
        let params: {
            paginate_by: number,
            sort_by: string,
            order: "desc" | "asc",
            page: number,
            from: string,
            to: string,
            transaction_type?: {
                ids: string[],
                include: boolean
            },
            currency_type?: {
                ids: string[],
                include: boolean
            },
            transaction_status?: {
                ids: string[],
                include: boolean
            },
            columns?: any[]
        } = {
            paginate_by: pagination.per_page,
            sort_by: billingSort.by,
            order: billingSort.direction,
            page: pagination.page,
            from: format(new Date(date_from ? date_from : new Date()), "y-MM-d"),
            to: format(new Date(date_to ? date_to : new Date()), "y-MM-d"),
            columns: paginationColumnsList.filter(item => item.checked).map(column => column.column_key)
        }

        if(transactionTypeFilter?.selected.length) {
            params.transaction_type = {
                ids: transactionTypeFilter.selected.map(selected => selected.id),
                include: !transactionTypeFilter.exclude
            };
        }

        if(currencyTypeFilter?.selected.length) {            
            params.currency_type = {
                ids: currencyTypeFilter.selected.map(selected => selected.id),
                include: !currencyTypeFilter.exclude
            };
        }
        
        if(transactionStatusesFilter?.selected.length) {
            params.transaction_status = {
                ids: transactionStatusesFilter.selected.map(selected => selected.id),
                include: !transactionStatusesFilter.exclude
            };
        }

        BillingTransactionsService.exportToCsv(params, Number(id))
    }

    return(
        <BillingTransactionsWrapper >
            <FiltersPaginationSection>
                {discoverDisplayed && <div className="discover-results-button-container">
                    <ButtonStyled onClick={fetchTransactionsData} className={'btn-discover-results'}>Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/></ButtonStyled>
                </div>}
            </FiltersPaginationSection>
            <DropdownWrapper ref={dateRangeRef}>
                <FilterWrapper
                    onClick={(e) => setDateFilterOpened(!dateFilterOpened)}
                    $opened={dateFilterOpened}
                >
                    <i className={'ico-Calendar'}/><span>{format(date_from ? typeof date_from === 'string' ? new Date(date_from) : date_from : new Date(), 'MMM dd, y')} - {format(date_to ? typeof date_to === 'string' ? new Date(date_to) : date_to : new Date(), 'MMM dd, y')}</span>
                </FilterWrapper>
                {dateFilterOpened && 
                    <RangeWrapper>
                        <DateFilterWrapper>
                            <DatePresetWrapper>
                                <h3>Date Presets</h3>
                                <DropdownPreset
                                    containerRef={presetContainerRef}
                                    preset={date_range_preset}
                                    setPreset={setPreset}
                                    opened={presetDropdownOpened}
                                    setOpened={setPresetDropdownOpened}
                                />
                                <div className="date-range">
                                    <DateRangeInput 
                                        startDate={date_from} 
                                        setStartDate={(date: Date | null | undefined) => dispatch(setDateFrom(date))} 
                                        endDate={date_to} 
                                        setEndDate={(date: Date | null | undefined) => dispatch(setDateTo(date))}/>
                                </div>
                            </DatePresetWrapper>
                            <RangeDatePicker
                                startDate={typeof date_from === 'string' ? new Date(date_from) : date_from}
                                setStartDate={(date: Date | null | undefined) => dispatch(setDateFrom(date))} 
                                endDate={typeof date_to === 'string' ? new Date(date_to) : date_to}
                                setEndDate={(date: Date | null | undefined) => dispatch(setDateTo(date))}
                                preset={date_range_preset.value}
                                setPreset={setPreset}
                            />
                        </DateFilterWrapper>
                    </RangeWrapper>
                }
            </DropdownWrapper>
            <FiltersPaginationSection>
                <Filters setDiscoverDisplayed={setDiscoverDisplayed} />
                <Pagination 
                    current_page={pagination.page}
                    from={pagination.from}
                    to={pagination.to}
                    total={pagination.total}
                    setCurrentPage={(n: number) => dispatch(setPage(n))}
                    setPerPage={(n: number) => dispatch(setPerPage(n))}
                    last_page={pagination.last_page}
                    perPage={pagination.per_page}
                    loading={loading}
                    onRefresh={() => fetchTransactionsData()} 
                    columnsOptionProps={
                        {
                            allPaginationColumns: allPaginationColumnsList,
                            enableDisableColumnHandler: (column: any) => enableDisableColumnHandler(column),
                            enableAllHandler: () => enableAllHandler(),
                            paginationColumnsList: paginationColumnsList,
                            setPaginationColumnsList: (paginationColumnsList: any) => setPaginationColumnsList(paginationColumnsList),
                            disableAllHandler: () => disableAllHandler()
                        }                  
                    }
                    perPageCustomList={[5,10,20,50]}
                    exportCsv={exportCsv}
                />
            </FiltersPaginationSection>
            <Grid
                fixedHeader={false}
                rowData={tableData}  
                defaultColDef={defaultColumnDefs} 
                columnDefs={columnDefs}
                rowHeight={40}
                domLayout='autoHeight'
                // gridOptions={gridOptions}
                loading={loading}
                onDragStopped={onColumnDrop}
                enableAllColumns={enableAllHandler}
            />
        </BillingTransactionsWrapper>
    )
}

export default BillingTransactions;