import React, {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {SelectOption, StyledSelect} from "../../../../../common/components/select/Select";
import {IBudgetGroups} from "../interfaces/interfaces";
import {setOffer} from "../create-offer.slice";
import Modal from "../../../../../common/components/modal/Modal";
import OfferBudgetGroupModal from "./OfferBudgetGroupModal";
import Alert from "../../../../../common/components/alert/Alert";

const OfferBudgetGroup: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        config
    } = useAppSelector(state => state.auth)
    const {
        offer
    } = useAppSelector(state => state.createOffer)
    const {budgetGroups} = useAppSelector(state => state.createOffer)
    const [createBudgetGroupModal, setCreateBudgetGroupModal] = useState(false)
    const [options, setOptions] = useState<SelectOption[]>([])
    
    useEffect(()=>{
        let opt = budgetGroups.map(group => ({
            label: group.name,
            value: group
        }))
        opt.unshift({
            label: "No budget group",
            value: {
                id: 0,
                name: "No budget group"
            } as IBudgetGroups
        }, {
            label: "Create a new budget group",
            value: {
                id: -1
            } as IBudgetGroups
        })
        setOptions(opt)
    },[budgetGroups])

    
    
    const handleOnChange = (e: any) => {
        if (e.value.id === -1) {
            setCreateBudgetGroupModal(true)
        }else {
            dispatch(setOffer({
                ...offer,
                budget_group_id: e.value.id,
                budget_group : e.value
            }))
        }
    }
    return (
       <>
           <StyledSelect
               value={offer.budget_group ? {
                       label: offer.budget_group?.name,
                       value: offer.budget_group
                   } as SelectOption : null}
               classNamePrefix={'styled-select'}
               placeholder={"No Budget Group"}
               selectLabel={<>Budget Group <span className={"optional"}>(optional)</span></>}
               options={options}
               onChange={handleOnChange}
           />
           {(offer.budget_group !== null && offer.budget_group.budget_value_type !== offer.budget_value_type) &&
               <div className={'row'} style={{marginBottom: '24px'}}>
                   <Alert alertType={'warning'}>
                       Selected budget group has budget type set to { offer.budget_group.budget_value_type === 1 ? 'Conversions' : 'US $' },
                       therefore, this campaign will have a budget in { offer.budget_group.budget_value_type === 1 ? 'Conversions' : 'US $' }.
                   </Alert>
               </div>
           }
           <Modal opened={createBudgetGroupModal}
                  title={'Create New Shared Budget group'}
                  closeModal={() => setCreateBudgetGroupModal(false)}>
              <OfferBudgetGroupModal
                  closeModal={() => setCreateBudgetGroupModal(false)}/>
           </Modal>
       </>
    );
};

export default OfferBudgetGroup