import styled from "styled-components";

export const TableWrapper = styled.div`
    .login-field {
        display: flex;
        gap: 10px;
        align-items: center;
        pointer-events: all;
        width: calc(100% );
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .tooltip {
            display: none;
            cursor: pointer;
            line-height: 1;
            i {
                display: block;
                transform: rotate(-90deg);
                color: ${({theme}) => theme.colors.performOrange[3]};
            }
        }
        &:hover{
            /* width: calc(100% - 20px); */
            .tooltip {
                display: inline-block;
            }
        }
    }
`

export const TableToolbarTop= styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;
`

export const TableToolbarTopUpperRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .table-search {
        input{
            height: 32px;
        }
        .search-button {
            height: 100%;
            width: 32px;
            right: 0;
        }
    }
    @media (max-width: 567px) {
        flex-direction: column;
        .table-search {
            input{
                width: 100%;
            }
        }
    }
`
export const TableToolbarTopBottomRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 8px;
`

export const TableToolbarBottom= styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 24px;
`

export const Tag = styled.span`
    padding: 4px 8px;
    margin: 0 5px 0 0;
    display: inline-flex;
    /* Cool Gray/200 */
    background: ${({theme}) => theme.colors.coolGray[2]};
    /* Cool Gray/300 */
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    border-radius: 16px;
    line-height: 1;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
`