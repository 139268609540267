import styled from "styled-components";

export const AddDomainModalBody = styled.div`
    min-width: 520px;
    max-width: 520px;
    p {
        font-size: 12px;
        font-family: ${({theme})=>theme.fonts.AvenirRoman};
        color: ${({theme})=>theme.colors.deepBlue[4]};
    }
    .row {
        margin-bottom: 30px;
        .domain-input-container {
            button {
                position: absolute;
                right: 8px;
                top: 9px;
                font-size: 11px;
            }
        }
    }
    .domain-details {
        padding-bottom: 20px;
        p { 
            margin-bottom: 25px;
            strong{
                font-family: ${({theme})=>theme.fonts.AvenirHeavy};
            }
           }
        .table-wrapper {
            background: ${({theme})=>theme.colors.coolGray[2]};
            border: 1px solid ${({theme})=>theme.colors.coolGray[3]};
            border-radius: 8px;
            padding: 4px 14px ;
            table {
                width: 100%;
                tr {
                    td, th {
                        text-align: left;
                        font-size: 10px;
                        padding: 10px 0;
                        color: ${({theme})=>theme.colors.deepBlue[5]};
                    }
                    th {
                        font-family: ${({theme})=>theme.fonts.AvenirHeavy};
                        border-bottom: 1px solid ${({theme})=>theme.colors.coolGray[3]};
                        .name-server-header {
                            display: flex;
                            align-items: center;
                            svg {
                                margin-left: 5px;
                                cursor: pointer;
                            }
                            > div {
                                display: flex;
                                align-items: center;
                            }
                            :active {
                                svg {
                                    transform: scale(1.2);
                                    color: ${({theme})=>theme.colors.performBlue[5]};
                                }
                            }
                        }
                    }
                    td {
                        font-family: ${({theme})=>theme.fonts.AvenirRoman};
                        &.domain-column {
                            width: 130px;
                            padding-right: 8px;
                        }
                        .name-server {
                            margin-bottom: 4px;
                            display: flex;
                            justify-content: space-between;
                            max-width: 55%;
                            position: relative;
                            > div {
                                padding-right: 5px;
                                word-wrap: break-word;
                                white-space: pre-wrap;
                                width: auto;
                            }
                            svg {
                                margin-top: 3px;
                                margin-left: auto;
                                min-width: 10px;
                                min-height: 10px;
                                cursor: pointer;
                                transform: scale(1);
                                transition: transform .15s ease;
                            }
                            :active {
                                svg {
                                    transform: scale(1.2);
                                    color: ${({theme})=>theme.colors.performBlue[5]};
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
