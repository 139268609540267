import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";
import {isNullOrUndefined} from "../../../../../../../common/utils";

interface Props {}

const CustomDomain: React.FC<Props> = () => {
    const {offer} = useAppSelector(state => state.createOffer)

    if(isNullOrUndefined(offer.custom_domain)) return null

    return (
        <>
            <Subtitle> Domain </Subtitle>
            <Item>{offer.custom_domain.domain}</Item>
        </>
    )
}

export default CustomDomain