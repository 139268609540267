import React, {useEffect, useMemo, useState} from "react";
import FiltersRow from "../../../../../common/components/filters/filters-row/FiltersRow";
import {FiltersRowLeft, FiltersRowRight} from "../../../../../common/components/filters/filters-row/FiltersRow.style";
import FiltersButton from "../../../../../common/components/filters/filters-button/FiltersButton";
import Search from "../../../../../common/components/search/Search";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import UiFilter from "../../../../../common/components/filters/filters-ui/UiFilter";
import {Wrapper} from "./ReferralReport.style";
import Filters from "../../../../../common/components/filters/filters-container/Filters";
import FiltersListItem from "../../../../../common/components/filters/filters-list-item/FiltersListItem";
import SelectDropdown from "../../../../../common/components/select-dropdown/SelectDropdown";
import {ReferralUserTypes, user_types} from "../../../../../common/components/filters/user_types";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import RangeDatePicker from "../../../../../common/components/range-date-picker/RangeDatePicker";
import {IPreset} from "../../../../../common/components/range-date-picker/presets/presets.props";
import {ReferralsResponsiveLine} from "./ReferralChart";
import Presets from "../../../../../common/components/range-date-picker/presets/Presets";
import Grid from "../../../../../common/components/grid/Grid";
import ReferralsService, {IReferralParams, IReferralReportData, IChartData} from "../Referrals.service";
import {useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import {format, startOfMonth} from "date-fns";
import SelectionPills from "../../../../../common/components/selection-pills/SelectionPills";
import {ChartData, ChartValues} from "./ReferralChart.props";
import {formatNumberToCurrency} from "../../../../../common/utils";

const ReferralReport = () => {
    const token = useAppSelector(tokenStore)
    const [openFilters, setOpenFilters] = useState(false);
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [perPage, setPerPage] = useState<number>(50)
    const [lastPage, setLastPage] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true)
    const [open, setOpened] = useState<boolean>(false)

    // Filters data
    const [typeSelection, setTypeSelection] = useState<ReferralUserTypes[]>([{ id: "all", name: "ALL"}]);
    const [openType, setOpenType] = useState(false);
    const [showExpired, setShowExpired] = useState<boolean>(false)
    const [preset, setPreset] = useState<IPreset>({value: "this_month", name: "This Month"})

    const [startDate, setStartDate] = useState<Date | null | undefined>(startOfMonth(new Date()))
    const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())

    const [chartShown, setChartShown] = useState<boolean>(false)
    const [tableData, setTableData] = useState<IReferralReportData[]>([])
    const [chartData, setChartData] = useState<ChartData[]>([{id : "",color: "hsl(27, 98%, 57%)", data: []}])
    const [daysBetween, setDaysBetween] = useState<string>("every day")

    const countDaysBetween = (startDate : Date, endDate : Date) => {
        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;
        // Calculating the time difference between two dates
        const diffInTime = endDate.getTime() - startDate.getTime();
        // Calculating the no. of days between two dates
        const diffInDays : number = Math.round(diffInTime / oneDay);
        if (diffInDays <= 15)
            return "every day"
        if (diffInDays > 15 && diffInDays < 100)
            return "every week"
        else
            return "every month"
    }

    const getDates = () => {
        let tempStart : string
        let tempEnd : string
        tempStart =  (startDate && startDate? format(startDate, "MM-dd-yyyy") :  format(new Date(), "MM-dd-yyyy"))
        tempEnd =  (endDate && endDate? format(endDate, "MM-dd-yyyy") :  format(new Date(), "MM-dd-yyyy"))
        return  "Date range: ".concat(tempStart).concat(" - ").concat(tempEnd)
    }
    const [selectedFilters, setSelectedFilter] = useState<string[]>([getDates(), "all",""])

    // Function for processing chart data for chart display
    // function iterates through time interval [startDate, endDate] and
    // checks for match in chartData and copies sum value
    // following date format is used because of the timezones and different timestamps in that case
    // ** day.getFullYear() + "-" + (day.getMonth()+1) + "-" + day.getDate() **
    const processChartData = (chartData : IChartData[], startDate: string, endDate :string) =>{
        let values :ChartValues[] = []
        for(let day = new Date(startDate); day <= new Date(endDate); day.setDate(day.getDate()+1))
        {
            let fullDate = day.getFullYear() + "-" + (day.getMonth()+1) + "-" + day.getDate()
            let tempVal : ChartValues
            if (chartData.some(item =>{
                let itemDate = new Date(item.ts*1000)
                let itemFullDate = itemDate.getFullYear() + "-" + (itemDate.getMonth()+1) + "-" + itemDate.getDate()
                return itemFullDate === fullDate
            })){
                let tempItem = chartData.find(item =>{
                    let itemDate = new Date(item.ts*1000)
                    let itemFullDate = itemDate.getFullYear() + "-" + (itemDate.getMonth()+1) + "-" + itemDate.getDate()
                    return itemFullDate === fullDate
                })
                tempVal =  {
                    x : format(day,  "yyyy-MM-dd"),
                    y : tempItem ? tempItem.referral_sum : 0
                }
            }else {
                tempVal =  {
                    x :  format(day,  "yyyy-MM-dd"),
                    y : 0
                }
            }
            values.push(tempVal)
        }
        setChartData([{ id: "revenue", color: "hsl(27, 98%, 57%)", data: values}])
        // chartData.length && setChartMax(Math.max.apply(Math, chartData.map(function(o) { return o.referral_sum; })))
    }

    const fetchReferralsTableData = async () => {
        setLoading(true)
        let params : IReferralParams = {
            from: startDate && format(startDate, "yyyy-MM-dd"),
            to: endDate&& format(endDate, "yyyy-MM-dd"),
        }
        if (showExpired) params.show_expired = 1
        if (typeSelection[0].id !== "all")  params.user_type = typeSelection[0].id

        if (currentPage !== 1 ) params.page = currentPage
        if (perPage !== 50) params.paginate_by = perPage
        params.search = search

        let [tableData, chartDataR] = await Promise.all([ReferralsService.fetchReferralReportTable(token, params),
            ReferralsService.fetchReferralReportChart(token, params)])
        params.from && params.to && processChartData(chartDataR.data, params.from, params.to)

        setTableData(tableData.data.user_referees.data)
        setFrom(tableData.data.user_referees.from)
        setLastPage(tableData.data.user_referees.last_page)
        setTo(tableData.data.user_referees.to)
        setTotal(tableData.data.user_referees.total)
        setPerPage(parseInt(tableData.data.user_referees.per_page))
        if (perPage > tableData.data.user_referees.total)
            setCurrentPage(1)
        else
            setCurrentPage(tableData.data.user_referees.current_page)
        setLoading(false)
    }

    useEffect(()=>{
        token && fetchReferralsTableData();
        let filters : string[] = []
        filters.push(getDates())
        showExpired && filters.push("Expired Referrals")
        if (typeSelection[0].id !== user_types[0].id)
        {
            filters.push(typeSelection[0].name)
        }
        setSelectedFilter(filters)
        startDate && endDate && setDaysBetween(countDaysBetween(startDate, endDate))
    }, [token, typeSelection, startDate, endDate, currentPage, perPage, showExpired, search])

    const setCheck = () => {
        setShowExpired(!showExpired)
    }

    const exportCsv = () => {
        let url = `${[process.env.REACT_APP_API_BASE_URL, 
                process.env.REACT_APP_API_URL, 
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_REFERRALS, 
                "referral_report_table"].join("/")}?action=export_csv` +
                `&user_type=${typeSelection[0].id === user_types[0].id ? "" : typeSelection[0].id}`+
                `&from=${format(startDate ? startDate : new Date(), "yyyy-MM-dd")}`+
                `&to=${format(endDate ? endDate : new Date(), "yyyy-MM-dd")}`+
                `&show_expired=${ showExpired ? 1 : 0}`+
                `&search=${search}`
        window.open(url)
    }

    const showChart = () => {
        setChartShown(!chartShown)
    }
    const removeFilter = (item : string) => {
        if (item === "Marketer" || item === "Partner")
            setTypeSelection([user_types[0]])
        if (item === "Expired Referrals")
            setShowExpired(false)
        item.includes("Date range") && setStartDate(new Date()) &&
        setEndDate(new Date())
        setSelectedFilter(selectedFilters.filter(elem => elem !== item))
    }

    const columnDefs = useMemo(() => [
        {
            field: "id", headerName: "ID", width: 150,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.id}</span>
            }
        },
        {
            field: "name", headerName: "NAME", width: 300,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.name + " " + data.lastname}</span>
            }
        },
        {
            field: "user_type", headerName: "REFERRAL TYPE", width: 300,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{data.user_type === 'affiliate' ? "Partner" : "Marketer"}</span>
            }
        },
        {
            field: "start_date", headerName: "START DATE", width: 300,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{format(new Date(data.start_date), 'yyyy-MM-dd')}</span>
            }
        },
        {
            field: "end_date", headerName: "END DATE", width: 300,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{format(new Date(data.end_date), 'yyyy-MM-dd')}</span>
            }
        },
        {
            field: "revenue", headerName: "COMMISSION", width: 300,
            cellRenderer: ({data}: { data: IReferralReportData }) => {
                return <span>{(formatNumberToCurrency(Number(data.revenue)))}</span>
            }
        }
    ], [])

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    return (
        <Wrapper>
                {chartShown &&
                <div style={{height: "400px"}}>
                    <ReferralsResponsiveLine data={chartData} days={daysBetween}/>
                </div>
                }
            <FiltersRow>
                <FiltersRowLeft>
                    <FiltersButton onClick={setOpenFilters} isOpen={openFilters}/>
                    <Search onChange={setSearch} initialValue="" placeholder="Search by ID"/>
                </FiltersRowLeft>
                <FiltersRowRight>
                    <Pagination current_page={currentPage}
                                setCurrentPage={setCurrentPage}
                                from={from}
                                to={to}
                                total={total}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                last_page={lastPage}
                                loading={loading}
                                onRefresh={fetchReferralsTableData}
                                exportCsv={exportCsv}
                                showChart={showChart}/>
                </FiltersRowRight>
            </FiltersRow>
            <div style={{display: openFilters ? 'block' : 'none'}}>
                {/*<button onClick={showChart}>click</button>*/}
                <UiFilter setOpenFilters={setOpenFilters}>
                    <Filters>
                        <Presets preset={preset}
                                 setPreset={setPreset}/>
                        <RangeDatePicker preset={preset.value}
                                         setPreset={setPreset}
                                         endDate={endDate}
                                         setEndDate={setEndDate}
                                         startDate={startDate}
                                         setStartDate={setStartDate}/>

                        <FiltersListItem>
                            <SelectDropdown open={openType}
                                            setOpen={setOpenType}
                                            setSelection={setTypeSelection}
                                            selection={typeSelection}
                                            options={user_types}
                                            labelText={"Account type"}
                                            loading={loading}
                                            isSearchable={false}/>
                        </FiltersListItem>
                        <FiltersListItem>
                            <div style={{margin: '30px 0'}}>
                                <Checkbox checked={showExpired} onChange={setCheck} label={"Show Expired Referrals"}/>
                            </div>
                        </FiltersListItem>
                    </Filters>
                </UiFilter>
            </div>
            <div>
                { selectedFilters.map(item => <SelectionPills key={item} text={item}
                                                              onRemove={() => removeFilter(item)} />) }
            </div>
            <Grid columnDefs={columnDefs}
                  defaultColDef={defaultColumnDefs}
                  rowData={tableData}
                  rowHeight={40}
                  domLayout='autoHeight'
                  loading={loading}
            >
            </Grid>
        </Wrapper>
    )
}

export default ReferralReport;
