import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {SectionTitle, TrafficPauseRiskPill, RecentInvoicesTooltipWrapper, PauseRiskTooltip} from "../Billing.style";
import Input from "../../../../../../common/components/input/Input";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import TextArea from "../../../../../../common/components/textarea/TextArea";
import {
    formatNumberToCurrency,
    formatNumberToPercentage,
    getBigNicheValue,
    roundNumber
} from "../../../../../../common/utils";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../../common/styled-components";
import Tooltip from "../../../../../../common/components/tooltip/Tooltip";
import BillingService, {IFetchDetailsResponse} from "../Billing.service";
import {useAppSelector} from "../../../../../../common/hooks";
import {useParams} from "react-router-dom";
import Invoices from "./invoices/Invoices";

import {Overlay, Wrapper} from "./Details.style";
import Loading from "../../../../../../common/components/loading/Loading";
import { format } from 'date-fns';
import PauseRisk from './pause-risk/PauseRisk';

interface Props {
    triggerBillingFetch: (trigger: boolean) => void
    billingFetch: boolean
}

const Details: React.FC<Props> = ({triggerBillingFetch, billingFetch}) => {
    const {id} = useParams()
    const {token} = useAppSelector(state => state.auth)
    const {editingUser, loading} = useAppSelector(state => state.advertisersEdit)
    const {user} = useAppSelector(state => state.auth)

    const [data, setData] = useState<IFetchDetailsResponse>({} as IFetchDetailsResponse)
    const [unlimitedStayLive, setUnlimitedStayLive] = useState(false)
    const [stayLiveLimitValue, setStayLiveLimitValue] = useState('')
    const [textAreaValue, setTextAreaValue] = useState<string>('')
    const [loadingData, setLoading] = useState(false)

    const stayLiveInitialValue = useRef<string>('')
    const unlimitedInitialValue = useRef<boolean>(false)
    const textAreaInitialValue = useRef<string>('')

    const fetchDetails = async () => {
        if(id){
            setLoading(true)
            try {
                const response = await BillingService.fetchDetails(token, parseInt(id))
                setData(response.data)
                textAreaInitialValue.current = response.data.billing_notes
                setTextAreaValue(response.data.billing_notes)
                if(response.data.credit !== null ){
                    stayLiveInitialValue.current = formatNumberToCurrency(
                        parseFloat(
                            response.data.credit.toString().replace(/[^0-9.]+/g, '')
                        )
                    )
                    setStayLiveLimitValue(formatNumberToCurrency(
                        parseFloat(
                            response.data.credit.toString().replace(/[^0-9.]+/g, '')
                        )
                    ))
                    setUnlimitedStayLive(false)
                    unlimitedInitialValue.current = false
                } else {
                    setUnlimitedStayLive(true)
                    unlimitedInitialValue.current = true
                }
                setTextAreaValue(response.data.billing_notes)
                textAreaInitialValue.current = response.data.billing_notes
            } catch (err) {
                console.log(err)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    const updateDetails = async () => {
        if(id){
            setLoading(true)
            try {
                /**
                 * delayIncrement = 5000 added for executing credit change since it goes through queue and more time is
                 * needed to set credit to new value
                 */
                const delayIncrement = 5000;
                let delay = 0;
                const p1 = new Promise(resolve => setTimeout(resolve, delay)).then(() =>
                    BillingService.updateBillingNotes(token, {
                    user_id: parseInt(id),
                    billing_notes: textAreaValue
                }));
                const p2 = new Promise(resolve => setTimeout(resolve, delay)).then(() =>
                    BillingService.editStayLiveLimit(token, {
                    user_id: parseInt(id),
                    credit: unlimitedStayLive ? 'unlimited' :
                        stayLiveLimitValue === '' ? 0 :
                            parseFloat(stayLiveLimitValue.replace(/\$|,/g, ''))
                }));
                delay += delayIncrement;
                const p3 = new Promise(resolve => setTimeout(resolve, delay)).then(()=> fetchDetails());
                await Promise.all([p1,p2,p3]);
                triggerBillingFetch(!billingFetch)
            } catch (e) {
                console.log(e)
            }
            setLoading(false)
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.validity.valid){
            setStayLiveLimitValue(e.target.value)
        }
    }

    const handleInputBlur = () => {
        const formatedInput = formatNumberToCurrency(
            parseFloat(
                stayLiveLimitValue.toString().replace(/[^0-9.]+/g, '')
            )
        );
        setStayLiveLimitValue(formatedInput)
    }

    const checkIfThereAreChanges = () => {
        return stayLiveInitialValue.current !== stayLiveLimitValue ||
            unlimitedInitialValue.current !== unlimitedStayLive ||
            textAreaInitialValue.current !== textAreaValue
    }

    const discardChanges = () => {
        setStayLiveLimitValue(stayLiveInitialValue.current)
        setUnlimitedStayLive(unlimitedInitialValue.current)
        setTextAreaValue(textAreaInitialValue.current)
    }

    return <Wrapper>
        {(loadingData || loading) && <Overlay ><Loading/></Overlay>}
        <SectionTitle>Account Details</SectionTitle>
        <div className={'row'}>
            <label className={'label'}>
                Status
            </label>
            <label style={{textTransform: 'capitalize'}} className={'value'}>{editingUser && editingUser.status}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>Account Created</label>
            <label className={'value'}>{editingUser && format(new Date(editingUser.created_at.replace(/-/g, "/")), "yyyy/MM/dd") }</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>Account Manager</label>
            <label className={'value'}>{editingUser && editingUser.executive.full_name}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>Vertical</label>
            <label className={'value'}>{editingUser && getBigNicheValue(editingUser.niche_id)}</label>
        </div>

        <SectionTitle>Funding Overview</SectionTitle>
        <div className={'row'}>
            <label className={'label'}>
                Traffic Pause Risk
                <Tooltip  className={'details-tooltip'} position={'right'} width={400}>
                    <PauseRiskTooltip>
                        <div className="column">
                            <div className="info" style={{background: "#740F15"}}>PAUSED</div>
                            <div className="text">
                                No Funding Available for 1-7 days
                            </div>
                        </div>
                        <div className="column">
                            <div className="info" style={{background: "#CE1E29"}}>VERY HIGH</div>
                            <div className="text">
                                1-2 Funding & Stay Live Days Remaining
                            </div>
                        </div>
                        <div className="column">
                            <div className="info" style={{background: "#FE9800"}}>HIGH</div>
                            <div className="text">
                                3-5 Funding & Stay Live Days Remaining
                            </div>
                        </div>
                        <div className="column">
                            <div className="info" style={{background: "#F3C315"}}>MEDIUM</div>
                            <div className="text">
                                6-9 Funding & Stay Live Days Remaining
                            </div>
                        </div>
                        <div className="column">
                            <div className="info" style={{background: "#0CAB5F"}}>LOW</div>
                            <div className="text">
                                10+ Funding & Stay Live Days Remaining
                            </div>
                        </div>
                        <div className="column">
                            <div className="info" style={{background: "#8799A2"}}>NO RISK</div>
                            <div className="text">
                                No Funding Available (8+ Days) / No Revenue (4+ Days)
                            </div>
                        </div>
                    </PauseRiskTooltip>
                </Tooltip>
            </label>
            <PauseRisk risk={data.pause_risk} />
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Marketer Total Revenue (Daily Avg Last 3 Days)
                <Tooltip  className={'details-tooltip'} position={'right'} width={207}>
                    The daily average revenue or spend a marketer has generated over the last 3 days (including QF & rejected).
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(data.marketer_total_revenue)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Funding Available
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The current funding a marketer has available to spend. This includes any deposits made to the account. If a marketer has depleted their funds and has stay live available it will be reflected below with a “-” next to the dollar value based on how much stay live they have spent.
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(data.balance)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Funding Days Remaining
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The days remaining until a marketer runs out of funding based on their daily average total revenue of the past 3 days.
                    <br/>
                    <br/>
                    The calculation used to determine this:
                    (Funding Available) / (Marketer Total Revenue (Daily Avg Last 3 Days))
                </Tooltip>
            </label>
            <label className={'value'}>{roundNumber(data.funding_days_remaining, 1)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Funding & Stay Live Days Remaining
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The days remaining until a marketer runs out of funding based on their daily average total revenue of the past 3 days, this includes any stay live added to the account.
                    <br/>
                    <br/>
                    The calculation used to determine this:
                    (Funding Available + Stay Live Limit) / (Marketer Total Revenue (Daily Avg Last 3 Days))
                </Tooltip>
            </label>
            <label className={'value'}>{roundNumber(data.funding_stay_live_days_remaining, 1)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Processing Fee Percentage
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The percent used to calculate the additional processing fee added to every credit card transaction for this marketer. This processing fee is collected by Perform[cb] to pay our credit card processor. <br/>
                    Processing Fee = Processing Fee Percentage * Funding Amount.
                </Tooltip>
            </label>
            <label className={'value'}>{data.processing_fee_percentage}%</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Auto-Funding
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    A feature, which if enabled will automatically add funds to the marketer’s account when their Funding Available reaches 10% of the entered auto-funding amount.
                    <br/>
                    <br/>
                    For example if a marketer decides to enable auto-funding and enters an amount of $1,000 their credit card will be charged the $1,000 when their Funding Available reaches 10% of that amount ($100 in this example).
                </Tooltip>
            </label>
            <label className={'value'}>{data.auto_funding ?
                <span>Enabled / {formatNumberToCurrency(data.auto_funding_amount)} at {formatNumberToCurrency(data.auto_funding_amount / 10)} Funding Remaining</span> : 'Disabled'
            }</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Credit Deposit
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    A deposit, usually made when the marketer signs up with Perform[cb] as a way to be given a line of credit. This amount is included in the marketer’s Funding Available.
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(data.intacct_credit_deposit)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Credit Limit
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The amount of credit a marketer is extended from Perform[cb].
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(parseInt(data.intacct_credit_limit))}</label>
        </div>
        <div style={{marginTop: '24px', marginBottom: '24px'}}>
            <Input
                wrapperClassName={'stay-live-input-wrapper'}
                disabled={unlimitedStayLive || !(user?.role.other.change_credit_amount === 'all' || (user?.role.other.change_credit_amount === 'managed' && user.id === data.executive_id))}
                value={stayLiveLimitValue}
                onChange={(e) => handleInputChange(e)}
                pattern={"[0-9, $, .]*"}
                label={'Stay Live Limit'}
                onBlur={handleInputBlur}
                placeholder={'$0.00'}
                style={{marginBottom: '12px'}}
            />
            <Checkbox
                checked={unlimitedStayLive}
                onChange={() => setUnlimitedStayLive(!unlimitedStayLive)}
                label={'Unlimited Stay Live'}
                disabled={!(user?.role.other.change_credit_amount === 'all' || (user?.role.other.change_credit_amount === 'managed' && user.id === data.executive_id))}
            />
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Total Credit Limit
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The total amount of credit a marketer is extended from Perform[cb] including both their base credit limit and stay live.
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(data.total_credit_limit)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Credit Used
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The amount of credit a marketer currently has in-use from Perform[cb], including both base credit and stay live.
                </Tooltip>
            </label>
            <label className={'value'}>{formatNumberToCurrency(data.credit_used)}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Recent Invoices
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    <RecentInvoicesTooltipWrapper>
                        <div className="above-text">
                            Once an invoice has been posted to the account it will be blue until it reaches 1+ days past due.
                            At 1+ days past due it will become red meaning it is overdue.
                            A payment will only be marked as paid and green if it has been fully paid off.
                        </div>
                        <div className="invoice-states">
                            <div className="heading">Invoices States</div>
                            <div className="invoices">
                                <div className="invoice">
                                    <div className="box" style={{background: "#E7ECEE"}}></div>
                                    <div className="text">No Invoice</div>
                                </div>
                                <div className="invoice">
                                    <div className="box" style={{background: "#1AA6F0"}}></div>
                                    <div className="text">Posted</div>
                                </div>
                                <div className="invoice">
                                    <div className="box" style={{background: "#F23842"}}></div>
                                    <div className="text">Overdue</div>
                                </div>
                                <div className="invoice">
                                    <div className="box" style={{background: "#3FDE7F"}}></div>
                                    <div className="text">Paid</div>
                                </div>
                                <div className="invoice">
                                    <div className="box" style={{background: "#F3C215"}}></div>
                                    <div className="text">Paid Late</div>
                                </div>
                            </div>
                        </div>
                    </RecentInvoicesTooltipWrapper>
                </Tooltip>
            </label>
            {data.intacct_invoices && <label className={'value'}><Invoices invoices={data.intacct_invoices}/></label>}
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Days Until Payment Due
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The days until a marketer has a payment due. The number of days listed is reflective of the oldest unpaid invoice.
                </Tooltip>
            </label>
            <label className={'value'}>{data.days_until_payment}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Invoicing Cycle
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The frequency that a marketer will receive an invoice.
                </Tooltip>
            </label>
            <label className={'value'}>{data.intacct_invoicing_cycle}</label>
        </div>
        <div className={'row'}>
            <label className={'label'}>
                Payment Terms
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    The amount of days a marketer has to pay an invoice from the time it is received.
                </Tooltip>
            </label>
            <label className={'value'}>{data.intacct_payment_term}</label>
        </div>
        <div style={{marginTop: '24px'}}></div>
        <TextArea
            label={
                <>
                Billing Note
                <Tooltip className={'details-tooltip'} position={'right'} width={262}>
                    Any notes added by a Perform[cb] employee related to the billing of this marketer.
                </Tooltip>
                </>
            }
            value={textAreaValue ? textAreaValue : ''}
            onChange={(e) => setTextAreaValue(e.target.value)}
            placeholder={'Enter any notes related to the marketer’s billing'}
            rows={2}
            style={{resize: 'vertical'}}
        />
        {checkIfThereAreChanges() && <div style={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <ButtonStyled
                onClick={discardChanges}
            >Discard Changes</ButtonStyled>
            <ButtonStyled
                disabled={loadingData}
                onClick={updateDetails}
                className={'btn-orange-filled'}
                style={{width:'70%'}}
            >
                {loadingData && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}Save Changes
            </ButtonStyled>
        </div>}
    </Wrapper>
}

export default Details