import styled from 'styled-components'

export const Wrapper = styled.div`
  .top-section{
    display: flex;
    margin-bottom: 36px;
    justify-content: space-between;
    align-items: center;
  }
`

export const AddContactButton = styled.div`
  padding: 9px 16px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.performBlue[6]};
  color: white;
  width: fit-content;
  
  :hover {
    background-color: ${({theme}) => theme.colors.performBlue[7]};
    cursor: pointer;
  }
`