import React, {useMemo} from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Item, Subtitle} from "../Summary.style";


interface Props {}

const DeviceTypes: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    const displayDeviceTypes: boolean = useMemo(() => {

        if(offer.operating_system_id !== null){
            if(offer.operating_system_id === 2) {
                return Object.keys(offer.os_targeting.tablet).some(key => key !== 'ios' ? offer.os_targeting.tablet[key] === 1 : offer.os_targeting.tablet[key] === 0) ||
                    Object.keys(offer.os_targeting.mobile).some(key =>  key !== 'ios' ? offer.os_targeting.mobile[key] === 1 : offer.os_targeting.mobile[key] === 0) ||
                    offer.desktop
            } else {
                return Object.keys(offer.os_targeting.tablet).some(key => key !== 'android' ? offer.os_targeting.tablet[key] === 1 : offer.os_targeting.tablet[key] === 0) ||
                    Object.keys(offer.os_targeting.mobile).some(key =>  key !== 'android' ? offer.os_targeting.mobile[key] === 1 : offer.os_targeting.mobile[key] === 0) ||
                    offer.desktop
            }

        } else if(!offer.desktop || !offer.mobile || !offer.tablet) {
            return true
        } else {
            return  Object.keys(offer.os_targeting.tablet).some(key => offer.os_targeting.tablet[key] === 0) ||
                Object.keys(offer.os_targeting.mobile).some(key => offer.os_targeting.mobile[key] === 0)
        }


    }, [
        offer.desktop,
        offer.mobile,
        offer.operating_system_id,
        offer.os_targeting.mobile,
        offer.os_targeting.tablet,
        offer.tablet
    ])

    const deviceTypeItemValue: string = useMemo(() => {
        let value = ''

        if(offer.desktop){
            value = value + 'Desktop' + ((offer.tablet || offer.mobile) ? ', ' : ' ')
        }

        if(offer.tablet){
            value = value + 'Tablet '
            if(Object.keys(offer.os_targeting.tablet).some(key => offer.os_targeting.tablet[key] === 1)){
                value = value + `(${Object.keys(offer.os_targeting.tablet).filter(key =>
                    offer.os_targeting.tablet[key] === 1).join(', ')})`
            }

            if(offer.mobile){
                value = value + ', '
            }
        }

        if(offer.mobile){
            value = value + 'Mobile '
            if(Object.keys(offer.os_targeting.mobile).some(key => offer.os_targeting.mobile[key] === 1)){
                value = value + `(${Object.keys(offer.os_targeting.mobile).filter(key =>
                    offer.os_targeting.mobile[key] === 1).join(', ')})`
            }
        }

        return value
    }, [
        offer.desktop,
        offer.os_targeting.mobile,
        offer.os_targeting.tablet,
        offer.tablet,
        offer.mobile
    ])

    if(!displayDeviceTypes || navState['targeting'] === 'disabled') return null

    return (
        <>
            <Subtitle>Device Types</Subtitle>
            <Item>{deviceTypeItemValue}</Item>
        </>
    )
}

export default DeviceTypes