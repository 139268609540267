import {api} from '../../../../common/services/api';
import axios, {AxiosResponse} from "axios";
import {IAdvertiserEdit} from "../../../../common/models/advertiser-edit.type";

export interface IParentUser {
    id: number,
    name: string,
    lastname: string,
    display_name: string
}

export interface IUser {
    id: number;
    name: string;
    lastname: string;
    user_type: string;
    comission?: number;
    comission_term: number;
    self_commission?: number;
    self_commission_term?: number;
}

interface StatusName {
    active: string;
    inactive: string;
    terminated: string;
    deleted: string;
    incomplete: string;
}

export interface ISalesforceParent {
    id: number;
    name: string;
    lastname: string;
    full_name: string;
    full_profile_image: string;
    status_name: StatusName;
    display_name: string;
    accepted_by_admin_name?: any;
    tags: any[];
}

export interface ITrackingPlatformProperties{
    personal_key?: string,
    timezone?: string,
    access_token?: string,
    api_secret_key?: string,
    api_token?: string,
    auth_key?: string,
    api_key?: string,
    api_version?: string,
    url?: string
}

export interface ITrackingPlatformParams {
    affluent_advertiser_id: string,
    created_at?: string,
    id: number,
    is_active?: number,
    updated_at?: string,
    user_id: number,
}

type TrackingPlatformType = 'AvantLink' | 'Awin' | 'Commission Junction' | 'Impact' | 'PepperJam' | 'Rakuten' | 'ShareASale'

export interface IPlatform {
    id: number;
    user_id: number;
    platform_name: TrackingPlatformType | '';
    platform_id?: any;
    platform_advertiser_name: string;
    platform_advertiser_id: number | string;
    properties?: ITrackingPlatformProperties;
    created_at: string;
    updated_at: string;
    platform_count: number;
}

export interface IPlatformAffluent {
    affluent_advertiser_id: string,
    id: number,
    user_id: number,
}

class AdvertisersEditService {
    static fetchParentUsers = async (token: string): Promise<AxiosResponse> => {
        try{
            return await api.get<IParentUser>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                process.env.REACT_APP_API_PARENT_USERS
            ].join('/'),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        } catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static fetchAllUsers = async (token: string): Promise<AxiosResponse> => {
        try {
            return await axios.get<IUser>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                process.env.REACT_APP_API_ALL_AFFILIATES_AND_ADVERTISERS
            ].join('/'),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        }catch (e: any){
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static fetchEditingUser = async (token : string, id: number) : Promise<AxiosResponse<any>> =>{
            return await api.get<IAdvertiserEdit>([
                    process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params: {
                        id: id,
                        user_type: "advertiser",
                        with_credit_edit_status: "true",
                        advertiser_edit_tab : 'contacts'
                    }
                });
    }

    static salesforceParentCheck = async (token: string,
                                          salesforce_id: string,
                                          user_id: number): Promise<AxiosResponse<any>> => {
        try {
            return await api.get<ISalesforceParent>([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USER,
                process.env.REACT_APP_API_SALESFORCE_PARENT_CHECK
            ].join("/"),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params: {
                        id: user_id,
                        salesforce_id: salesforce_id
                    }
                }
            )
        } catch (e: any) {
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static addTrackingPlatform = async (token: string,
                                        params: ITrackingPlatformParams): Promise<AxiosResponse<any>> => {
        try{
            return await api.post([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                process.env.REACT_APP_API_TRACKING_PLATFORM
            ].join('/'),
                params,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        } catch (e: any) {
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static getPlatforms = async (token: string, id: number): Promise<AxiosResponse<any>> => {
        try{
            return await api.get<IPlatformAffluent>([
                    process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    process.env.REACT_APP_API_GET_PLATFORMS
                ].join('/'),
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                    params: {
                        id: id
                    }
                })
        } catch (e: any) {
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static deleteTrackingPlatform = async (token: string, platform: IPlatformAffluent) => {
        try{
            return await api.post([
                    process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    process.env.REACT_APP_API_DELETE_PLATFORM
                ].join('/'),
                platform,
                {

                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        } catch (e: any) {
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static updatePlatform = async (token: string,
                                   id: number,
                                   params: ITrackingPlatformParams): Promise<AxiosResponse<any>> => {
        try{
            return await api.post([
                    process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    process.env.REACT_APP_API_UPDATE_PLATFORM
                ].join('/'),
                {...params, id: id},
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        } catch (e: any) {
            if(axios.isAxiosError(e)){
                console.log(e.message, "axios error")
            }
            return e.message
        }
    }

    static saveUser = async (token: string, user: any, id: number): Promise<AxiosResponse<any>> => {

            return await api.post([
                    process.env.REACT_APP_LOCAL_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_USERS,
                    id
                ].join('/'),
                {...user, _method: 'PATCH'},
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
    }

    static fetchCards = async (token: string, id: number): Promise<AxiosResponse<any>> => {        
        return await api.get([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ADMIN_URL,
                "cards"
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    user_id: id
                }
            })
    }
    static exportCardPdf = (id: number, type: string) => {
        const url = [
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_ADMIN_URL,
            "cards"
        ].join('/');
        
        window.open(`${url}/${type}/${id}`, "_blank");
    }

    static fetchPartners = async (token: string): Promise<AxiosResponse> => {
        return await api.post([
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_FILTERS,
                process.env.REACT_APP_API_AFFILIATES
            ].join("/"),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static addPartner = async (token: string,
                               user_id: string,
                               partner_id: number,
                               notes: string): Promise<AxiosResponse> => {
        return await api.post([
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ADMIN_URL,
                process.env.REACT_APP_API_AA_VISIBILITY,
                "add"
            ].join("/"),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    "advertiser_id": user_id,
                    "affiliate_id": partner_id,
                    "note": notes === "" ? "-" : notes
                }
            })
    }

    static fetchAddedPartners = async (token: string,
                                       marketer_id: string,
                                       paginate_by : number,
                                       page : number): Promise<AxiosResponse> => {
        return await api.get([
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_ADMIN_URL,
            process.env.REACT_APP_API_AA_VISIBILITY,
            ].join("/"),
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    "advertiser_id": marketer_id,
                    "paginate_by": paginate_by,
                    "page": page
                }
            }
        )
    }

    static removePartner = async (token: string,
                                  marketer_id: string,
                               partner_id: string): Promise<AxiosResponse> => {
        return await api.post([
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ADMIN_URL,
                process.env.REACT_APP_API_AA_VISIBILITY,
                "remove"
            ].join("/"),
            {
                _method: "DELETE"
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    "advertiser_id": marketer_id,
                    "affiliate_id": partner_id,
                }
            })
    }



}

export default AdvertisersEditService