import React, {useEffect, useState} from "react";

import Dropdown from "../../../../../../common/components/dropdown/Dropdown";

import {Wrapper, Label, TextInput, Button, Cancel} from "./AddTrackingPlatformModal.style";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import {ModalFooter} from "../../../../../../common/components/modal/container/Container.style";

import {IAddTrackingPlatformModalProps} from "./addTrackingPlatformModal.props";
import {ITrackingPlatformParams} from "../../AdvertisersEdit.service";

import {useAppSelector, useAppDispatch} from "../../../../../../common/hooks";

import AdvertisersEditService from "../../AdvertisersEdit.service";
import {useParams} from "react-router-dom";
import {openAlert} from "../../AdvertisersEdit.slice";

const AddTrackingPlatformModal: React.FC<IAddTrackingPlatformModalProps> = ({
                                                                                closeModal,
                                                                                platform,
                                                                                fetchPlatforms
                                                                            }): JSX.Element => {
    const {token} = useAppSelector(state => state.auth)
    const {id} = useParams()
    const dispatch = useAppDispatch()

    const [trackingPlatformDropdownOpened, setTrackingPlatformDropdownOpened] = useState(false)
    const [affluentMarketerId, setAffluentMarketerId] = useState<string>(platform ? platform.affluent_advertiser_id : '')

    const handleSubmit = async () => {
        let params: ITrackingPlatformParams = {
            affluent_advertiser_id: affluentMarketerId ? affluentMarketerId : '',
            id: id ? parseInt(id) : 0,
            user_id: id ? parseInt(id) : 0
        };
        let response;
        if(platform){
            response = await AdvertisersEditService.updatePlatform(token, platform.id, params)
        } else {
            response = await AdvertisersEditService.addTrackingPlatform(token, params)
        }
        if(response.data === -1){
            dispatch(openAlert({
                opened: true,
                type: 'error',
                message: "Connection unsuccessful!"
            }))
            closeModal()

        } else if(response.data === -2) {
            dispatch(openAlert({
                opened: true,
                type: 'error',
                message: "Advertiser already exists!"
            }))
            closeModal()
        } else {
            await fetchPlatforms()
            dispatch(openAlert({
                opened: true,
                type: 'success',
                message: "Tracking platform added!"
            }))
            closeModal()
        }

    }

    return (
        <Wrapper >
            <div className="row">
                <Label>Tracking Platform</Label>
                <Dropdown
                    text={'Affluent'}
                    isOpened={trackingPlatformDropdownOpened}
                    setOpened={setTrackingPlatformDropdownOpened}
                    placeholder={'Affluent'}
                    disabled={true}
                >
                </Dropdown>
            </div>
            <div className={'row'}>
                <Label>Marketer ID</Label>
                <TextInput
                    value={affluentMarketerId}
                    onChange={(e) => setAffluentMarketerId(e.target.value)}
                    placeholder={'Enter Marketer ID'}
                />
            </div>
            <ModalFooter>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Cancel onClick={closeModal}>
                        Cancel
                    </Cancel>
                    <Button onClick={handleSubmit}
                            disabled={affluentMarketerId === ''}>
                        Verify & Save
                    </Button>
                </div>
            </ModalFooter>
        </Wrapper>
    )
}

export default AddTrackingPlatformModal