import React, {useState, useEffect} from 'react';
import {useAppSelector, useAppDispatch} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {setOffer} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {BRAND_NAME_TOOLTIP} from "../shared-components/consts";

export const BrandName: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer
    } = useAppSelector(state => state.createOffer)

    return (
        <Input
            label={<>Brand Name <span className={"optional"}>(optional)</span> {Tooltip(174, BRAND_NAME_TOOLTIP)}</>}
            placeholder={"Enter the brand name this campaign represents"}
            value={offer.brand_name}
            onChange={(event) =>
                dispatch(setOffer({
                    brand_name : event.target.value}))}
        />
    );
};