import styled from "styled-components";

export const WysiwygWrapper = styled.div<{isPlaceholderFocus: boolean}>`
        ul {
            list-style: disc;
            padding-left: 20px;   
        }

        ol {
            list-style: auto;
            padding-left: 20px;    
        }

        blockquote {
            quotes: auto;
            margin: 40px;
        }

        h1 {
            font-size: 32px;
            font-weight: 700;
            margin: 22px 0;
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            margin: 20px 0;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin: 18px 0;
        }
        .rdw-left-aligned-block{
            text-align: left;
        }

        .rdw-center-aligned-block{
            text-align: center;
        }
    
        .rdw-right-aligned-block{
            text-align: right;
        }

        .rdw-option-wrapper {
            width: 31px;
            height: 30px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border: 1px solid #D0D5D7;
            background-color: #FFFFFF;
            img{
                /* width: 20px; */
            }
        }

        .rdw-option-active {
            position: relative;
            background: #E7F9FB;
            border: 1px solid #C0F3FA;
            border-radius: 4px;
        }
        
        .rdw-editor-wrapper {
            /* border: 1px solid red; */
            .rdw-editor-toolbar {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                align-items: center;
                & > div {
                    /* height: 30px;
                    width: 31px; */
                    margin-bottom: 7px;
                    display: flex;
                    & > div{ 
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
                .rdw-inline-wrapper{
                   gap: 3px;
                   margin-right: 12px;
                }

                .rdw-block-wrapper {
                    margin-right: 3px;
                    width: 115px;
                    /* height: 30px; */
                    align-items: center;
                    position: relative;
                    border: 1px solid #D0D5D7;
                    border-radius: 4px;
                    
                    .rdw-block-dropdown{
                        width: 100%;
                        
                        a{
                            width: 100%;
                            display: block;
                            background-color: #FFFFFF;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            padding-left: 10px;
                        }

                        &::after{
                            content: '';
                            position: absolute;
                            right: 5px;
                            top: 18px;
                            display: block;
                            /* border-right: 1px solid red; */
                            /* border-bottom: 1px solid red; */
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-bottom: 4px solid #8799A2;
                            width: 9px;
                            height: 9px;
                            pointer-events: none;
                            transform: translate(-50%, -50%) rotate(180deg);
                        }

                        .rdw-dropdown-optionwrapper {
                            background: #FFFFFF;
                            border: 1px solid #E9ECED;
                            box-sizing: border-box;
                            border-radius: 0px 0px 8px 8px;
                            box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                            z-index: 2;
                            width: 115px;
                            top: calc(100% + 4px) !important;
                            padding: 16px;

                            li {
                                padding: 8px 0 8px 8px;
                                color: #5A686F;
                                border-radius: 8px;
                                transition: .2 ease-in-out;
                                &:hover {
                                    background: #E7F9FB;
                                }
                            }

                            .rdw-dropdownoption-active {
                                background: #272C2F;
                                color: #FFFFFF;
                                transition: .2 ease-in-out;
                                border: 1px solid #E9ECED;
                                &:hover {
                                    background: #272C2F;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                    
                    .rdw-dropdown-wrapper {
                        position: relative;
                        .rdw-dropdown-optionwrapper{
                            position: absolute;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            top: 100%;
                            left: 0;
                            list-style: none;
                            /* padding: 0px 5px; */
                            background-color: #FFFFFF;
                        }
                    }
                }

                .rdw-fontsize-wrapper{
                    margin-right: 12px;
                    .rdw-dropdown-wrapper {
                        position: relative;
                        a{
                            span {
                                padding: 7px 19px 7px 5px;
                                background-color: #FFFFFF;
                                border: 1px solid #D0D5D7;
                                border-radius: 4px;

                                &::after{
                                    content: '';
                                    position: absolute;
                                    right: 0px;
                                    top: 12px;
                                    display: block;
                                    /* border-right: 1px solid red; */
                                    /* border-bottom: 1px solid red; */
                                    border-left: 4px solid transparent;
                                    border-right: 4px solid transparent;
                                    border-bottom: 4px solid #8799A2;
                                    width: 9px;
                                    height: 9px;
                                    pointer-events: none;
                                    transform: translate(-50%, -50%) rotate(180deg);
                                }
                            }
                        }
                        .rdw-dropdown-optionwrapper{
                            position: absolute;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            top: calc(100% + 8px);
                            width: 100%;
                            list-style: none;
                            background-color: #FFFFFF;
                            border: 1px solid #E9ECED;
                            box-sizing: border-box;
                            box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                            z-index: 2;
                            padding: 6px;
                            li {
                                padding: 5px;
                                color: #5A686F;
                                border-radius: 8px;
                                transition: .2 ease-in-out;
                                &:hover {
                                    background: #E7F9FB;
                                }
                            }

                            .rdw-dropdownoption-active{
                                background: #272C2F;
                                color: #FFFFFF;
                                transition: .2 ease-in-out;
                                border: 1px solid #E9ECED;
                                &:hover {
                                    background: #272C2F;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }

                .rdw-list-wrapper {
                    position: relative;
                    gap: 3px;
                    margin-right: 12px;
                }

                .rdw-text-align-wrapper {
                    /* margin-left: 80px; */
                    gap: 3px;
                    margin-right: 12px;
                }

                .rdw-link-wrapper {
                    position: relative;
                    background-color: #FFFFFF;
                    gap: 3px;
                    margin-right: 12px;
                    .rdw-link-modal{
                        position: absolute;
                        top: calc(100% + 4px);
                        background-color: #FFFFFF;
                        display: block;
                        width: max-content;
                        background: #FFFFFF;
                        border: 1px solid #E9ECED;
                        box-sizing: border-box;
                        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
                        z-index: 2;
                        display: flex;
                        flex-direction: column;
                        padding: 23px 24px;
                        gap: 8px;

                        input {
                            padding: 8px;
                            background: #FFFFFF;
                            border: 1px solid #D0D5D7;
                            border-radius: 8px;
                            color: #8799A2;
                            font-size: 12px;
                            outline: none;
                        }

                        #linkTitle {
                            margin-bottom: 14px;
                            &:focus {
                                border-color: #25C0D5;
                            }
                        }

                        #linkTarget {
                            margin-bottom: 16px;
                            &:focus {
                                border-color: #25C0D5;
                            }
                        }

                        .rdw-link-modal-target-option{
                            display: flex;
                            align-items: center;
                            gap: 9px;
                            margin-bottom: 28px;
                            #openLinkInNewWindow {
                                margin: 0;
                            }
                        }

                        .rdw-link-modal-buttonsection {
                            display: flex;
                            flex-direction: row-reverse;
                            
                            button:last-child {
                                color: #8799A2;
                                border: 0;
                                background-color: #FFFFFF;
                                font-size: 12px;
                            }

                            button:first-child {
                                color: #FFFFFF;
                                background: #FE7E60;
                                border-radius: 8px;
                                padding: 8px 16px;
                                border: 0;
                                font-size: 12px;
                            }

                            button[disabled] {
                                background: #E9ECED;
                                color: #BEC1C2;
                            }
                        }
                    }
                }

                .rdw-history-wrapper {
                    gap: 3px;
                }
            }
            .rdw-editor-main {
                width: 100%;
                max-width: 702px;
                min-height: 151px;
                margin-top: 10px;
                height: 111px;
                overflow-y: auto;
                border-radius: 8px;
                background: #FFFFFF;
                padding: 10px 12px;
                font-weight: unset;
                border: 1px solid;
                border-color: ${({isPlaceholderFocus}) => isPlaceholderFocus ? "#25C0D5" : "#D0D5D7"};
                resize: vertical;
            }
        }

    
`;