import React, {useEffect, useMemo, useState} from "react";
import Highcharts, {SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {format} from 'date-fns'
import {DaySelectorOption, DaysSelector, PendingRegistrations, Wrapper} from "./PendingPartnerRegistration.style";
import {ButtonStyled} from "../../../../../../common/styled-components";
import {IFetchPendingPartnerRegistration, IPendingPartnersChartData} from "../NetworkView.service";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import {setPendingPartnerRegistrationsDays} from '../NetworkView.slice';

interface Props {
    pendingPartners: IFetchPendingPartnerRegistration,
}

const PendingPartnerRegistration: React.FC<Props> = ({pendingPartners}) => {
    const target = process.env.REACT_APP_API_BASE_URL
    const {pendingPartnerRegistrationsDays} = useAppSelector(state => state.networkView)
    const dispatch = useAppDispatch()

    const [registrationsPerDay, setRegistrationsPerDay] = useState<number[]>([])
    const [days, setDays] = useState<string[]>([])

    const {filterType, includeOrExclude, selectedManagers} = useAppSelector(state => state.networkView)

    const handlePendingPartnerRegistrationsDays = (days: number) => {
        dispatch(setPendingPartnerRegistrationsDays(days))
    }

    const openPartnerRegistrationPage = () => {
        if (filterType === 'partner-account-manager') {
            target && window.parent.postMessage({
                id: 8,
                fromReact: true,
                partners: selectedManagers.length > 0 ? selectedManagers : [{
                    id: 0,
                    is_selected: true,
                    name: "All Account Managers"
                }]
            }, target)
        } else {
            target && window.parent.postMessage({
                id: 8,
                fromReact: true,
            }, target)
        }
    }

    const formatDateTh = (date: string | undefined | number | Date) => {
        return format(date ?
            typeof date === 'string' ?
                new Date(date) : date
            : new Date(), 'MMM do, y')
    }
    const formatDate = (date: string | undefined | number | Date) => {
        return format(date ?
            typeof date === 'string' ?
                new Date(date) : date
            : new Date(), 'MMM d, y')
    }

    const weekEnd = (d: string | undefined | number | Date) => {
        if (d) {
            const date = new Date(d);
            date.setDate(date.getDate() + 6);
            return date
        } else
            return new Date()
    }

    useEffect(() => {
        if (pendingPartners.chart_days) {
            setRegistrationsPerDay(pendingPartners.chart_days.map((item: IPendingPartnersChartData) => {
                return parseInt(item.partners)
            }))
            setDays(pendingPartners.chart_days.map((item: IPendingPartnersChartData) => {
                return item.created_at
            }))
        }
    }, [pendingPartners])

    const series: SeriesOptionsType[] = useMemo(() => [
        {
            type: "areaspline",
            data: registrationsPerDay,
            title: undefined,
            "color": "#25C0D5",
            "fillColor": {
                "linearGradient": {
                    "x1": 0,
                    "x2": 0,
                    "y1": 0,
                    "y2": 1
                },
                // gradient from top to bottom
                "stops": [
                    [
                        0,
                        "rgba(37, 192, 213, 0.1)"
                    ],
                    [
                        1,
                        "rgba(37, 192, 213, 0)"
                    ]
                ]
            },
        }
    ], [pendingPartners, pendingPartnerRegistrationsDays, registrationsPerDay, days])

    const options: Highcharts.Options = useMemo(() => ({
        chart: {
            height: 210,
        },
        xAxis: {
            categories: days,
            title: {
                text: null
            },
            // hides X labels
            visible: false,
            tickmarkPlacement: "on",
            tickInterval: 1,
            minPadding: 0,
            maxPadding: 0,
            startOnTick: true,
            endOnTick: true,
        },
        // removes hyperlink highcharts.com
        credits: {
            enabled: false
        },
        title: {
            text: undefined
        },
        yAxis: {
            title: {
                text: null
            },
            // hides Y labels
            visible: false
        },
        // hides series labels
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                lineWidth: 1,
                pointPlacement: "on",
                marker: {
                    // hides dots on chart
                    enabled: false,
                    states: {
                        hover: {
                            // show dots on mouse hover
                            enabled: true
                        }
                    }
                }
            },
        },
        series: series,
        tooltip: {
            enabled: true,
            shared: true,
            borderWidth: 1,
            borderColor: '#E7ECEE',
            backgroundColor: '#ffffff',
            padding: 16,
            shadow: true,
            borderRadius: 16,
            useHTML: true,
            formatter: function (): any {
                let date = this.points && this.points[0].x
                let value = this.points && this.points[0].y
                return `<div class="hc-tooltip-wrapper">
                            <div class="hc-tooltip-inner">
                                <div class="tooltip-value-item">
                                ${pendingPartnerRegistrationsDays <= 30 ?
                    formatDateTh(date)
                    :
                    formatDate(date) + ' - ' + formatDate(weekEnd(date))
                }
                </div>
                                <div class="tooltip-value-item tooltip-value">
                                 <span class="circle-tooltip"></span>Pending Registrations: <span class="bold">${value}</span>
                                </div>
                            </div>
                        </div>`
            }
        },
    }), [pendingPartners, pendingPartnerRegistrationsDays, registrationsPerDay, days])

    return (
        <Wrapper>
            <h3 className={'title'}>
                Pending Partner Registration
                <ButtonStyled className={'open-report-button'} onClick={() => openPartnerRegistrationPage()}>
                    <i className={'ico-OpenReport'}/>
                </ButtonStyled>
            </h3>
            <div>
                <div className={'pending'}>Currently Pending</div>
                <PendingRegistrations>{pendingPartners.total}</PendingRegistrations>
            </div>
            <HighchartsReact highcharts={Highcharts} options={options}/>
            <DaysSelector>
                <DaySelectorOption $selected={pendingPartnerRegistrationsDays === 7}
                                   onClick={() => handlePendingPartnerRegistrationsDays(7)}>
                    7 Days
                </DaySelectorOption>
                <DaySelectorOption $selected={pendingPartnerRegistrationsDays === 30}
                                   onClick={() => handlePendingPartnerRegistrationsDays(30)}>
                    30 Days
                </DaySelectorOption>
                <DaySelectorOption $selected={pendingPartnerRegistrationsDays === 90}
                                   onClick={() => handlePendingPartnerRegistrationsDays(90)}>
                    90 Days
                </DaySelectorOption>
                <DaySelectorOption $selected={pendingPartnerRegistrationsDays === 180}
                                   onClick={() => handlePendingPartnerRegistrationsDays(180)}>
                    180 Days
                </DaySelectorOption>
            </DaysSelector>
        </Wrapper>
    )
}

export default PendingPartnerRegistration