import { RecentInvoicesWrapper } from "./RecentInvoicesTooltip.style";

const RecentInvoicesTooltip = () => {
    return(
        <RecentInvoicesWrapper>
            <div className="above-text">
                Once an invoice has been posted to the
                account it will be blue until it reaches 1+
                days past due. At 1+ days past due it will become
                red meaning it is overdue. An invoice will only
                be marked as paid and green if it has been
                fully paid off on or before the due date. If the
                invoice total is paid after the clue date it will
                be marked paid late and yellow.
            </div>
            <div className="invoice-states">
                <div className="heading">Invoices States</div>
                <div className="invoices">
                    <div className="invoice">
                        <div className="box" style={{background: "#E7ECEE"}} />
                        <div className="text">No Invoice</div>
                    </div>
                    <div className="invoice">
                        <div className="box" style={{background: "#1AA6F0"}} />
                        <div className="text">Posted</div>
                    </div>
                    <div className="invoice">
                        <div className="box" style={{background: "#3FDE7F"}} />
                        <div className="text">Paid</div>
                    </div>
                    <div className="invoice">
                        <div className="box" style={{background: "#F23842"}} />
                        <div className="text">Overdue</div>
                    </div>
                    <div className="invoice">
                        <div className="box" style={{background: "#F3C215"}}></div>
                        <div className="text">Paid Late</div>
                    </div>
                </div>
            </div>
        </RecentInvoicesWrapper>
    )
}

export default RecentInvoicesTooltip;
