import styled from "styled-components";

export const MarketerBalanceWrapper = styled.div`
    padding: 40px 40px 88px 40px;
    background-color: white;

    @media screen and (max-width: 897px) {
        padding: 40px 15px 88px 15px;
    }

    .date-range_discover-button {
        display: flex;
        gap: 8px;
    }

    .filters-pagination {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }
`;