import React, {ReactElement} from "react";
import Modal from "../../../../../../common/components/modal/Modal";
import {CustomModalFooter, DeleteDomainModalBody} from "../PartnerCustomDomain.style";
import {ButtonStyled} from "../../../../../../common/styled-components";
import AlertModal from "../../../../../../common/components/alert-modal/AlertModal";


interface Props {
    deleteModal: {
        opened: boolean
        data: any
    }
    setDeleteModal: (modal: {
        opened: boolean
        data: any
    }) => void
    deleteDomain: (domainId: number, partnerId: number) => void
    deleteSuccessAlert: {
        opened: boolean
        type: 'success' | 'error' | 'info'
        message: string | ReactElement
    }
    setDeleteSuccessAlert: (alert: {
        opened: boolean
        type: 'success' | 'error'
        message: string
    }) => void
}

const DeleteDomainModal: React.FC<Props> = (props) => {
    return (
        <>
            <Modal
                modalHeaderClassName={"custom-modal-header"}
                opened={props.deleteModal.opened}
                title={"Delete Domain?"}
                closeModal={() => props.setDeleteModal({opened: false, data: ""})}>
                <DeleteDomainModalBody>
                    <p>Are you sure you want to delete this domain?</p>
                    <br/>
                    <p>This action cannot be undone.</p>
                </DeleteDomainModalBody>
                <CustomModalFooter>
                    <ButtonStyled
                        className={'btn-cancel'}
                        onClick={() => props.setDeleteModal({opened: false, data: ""})}>
                        Cancel
                    </ButtonStyled>
                    <ButtonStyled
                        className={'btn-red-filled'}
                        onClick={() => props.deleteDomain(props.deleteModal.data.id, props.deleteModal.data.user_id)}
                        style={{marginLeft: 30}}>
                        Yes, delete this domain.
                    </ButtonStyled>
                </CustomModalFooter>
            </Modal>

            <AlertModal
                isOpen={props.deleteSuccessAlert.opened}
                type={props.deleteSuccessAlert.type}
                closeModal={() => props.setDeleteSuccessAlert({opened: false, type: 'success', message: ''})}>
                <div>
                    {props.deleteSuccessAlert.message}
                </div>
            </AlertModal>
        </>
    )
}

export default DeleteDomainModal