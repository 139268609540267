import styled, {css} from 'styled-components'

export const NoteWrapper = styled.div`
  margin: 0 0 18px;
  padding: 20px;
  background: ${({theme}) => theme.colors.warning[1]};
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  color: ${({theme}) => theme.colors.deepBlue[7]} ;
  border-radius: 6px ;
  border: 1px solid ${({theme}) => theme.colors.warning[3]};
  strong {
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
  font-size: 13px;
`

export const Wrapper = styled.div`
  width: 460px;
  min-width: 100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .row{
    margin-top: 32px;
  }
`

export const Cancel = styled.div`
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue['4']};
  :hover{
    cursor: pointer;
    color: ${({theme}) => theme.colors.deepBlue['7']};
  }
  
  :hover:active{
    color: ${({theme}) => theme.colors.deepBlue['9']};
  }

`

export const ModalFooter = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.coolGray['3']};
  padding-top: 34px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`



export const RemoveButton = styled.div`
  padding: 8px 16px;
  color: white;
  font-size: 12px;
  background-color: ${({theme}) => theme.colors.critical[3]};
  border-radius: 8px;
  font-family: ${({theme}) => theme.fonts.AvenirLight};
  line-height: 1.55;
  
  :hover{
    background-color: ${({theme}) => theme.colors.performOrange['4']};
    cursor: pointer;
  }
  
  :hover:active{
    background-color: ${({theme}) => theme.colors.performOrange['7']};
  }
`
