import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";

import BillingFees from "./billing-fees/BillingFees";
import BillingService, {IFetchAffiliatesResponse, IFetchBillingParams} from "./Billing.service";
import AdvertisersEditService, {IPlatformAffluent} from "../AdvertisersEdit.service";


import {WidgetWrapper, Wrapper, RightSection, LeftSection, SectionTitle} from "./Billing.style"
import Details from "./details/Details";
import BillingTransactions from "./billing-transactions/BillingTransactions";


const Billing: React.FC = (): JSX.Element => {
    const {id} = useParams<{ id: string }>()
    const token = useAppSelector(tokenStore)
    const {editingUser} = useAppSelector(state => state.advertisersEdit)
    const [triggerBillingFetch, setTriggerBillingFetch] = useState(false)


    const [affiliates, setAffiliates] = useState<IFetchAffiliatesResponse[]>([])
    const [platforms, setPlatforms] = useState<IPlatformAffluent[]>([])

    const fetchInitialData = async () => {
        if(id){
            const [affiliates, platforms] = await Promise.all([
                BillingService.fetchAffiliates(token),
                AdvertisersEditService.getPlatforms(token, parseInt(id))
            ])
            setAffiliates(affiliates.data)
            setPlatforms(platforms.data)
        }
    }

    useEffect(() => {
        fetchInitialData()
    }, [token])


    //---------------------- NEW BILLING -------------------//


    return (
        <Wrapper>
            <LeftSection>
                <WidgetWrapper>
                    <Details triggerBillingFetch={setTriggerBillingFetch} billingFetch={triggerBillingFetch}/>
                </WidgetWrapper>
            </LeftSection>
            <RightSection>
                <WidgetWrapper>
                    <SectionTitle>Transactions</SectionTitle>
                    <BillingTransactions triggerFetch={triggerBillingFetch}/>
                </WidgetWrapper>
                <WidgetWrapper>
                    <SectionTitle>Fee Management</SectionTitle>
                    <BillingFees affiliates={affiliates} platforms={platforms} editingUser={editingUser}/>
                </WidgetWrapper>
            </RightSection>
        </Wrapper>
    )
}

export default Billing