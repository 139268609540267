import styled, {css} from 'styled-components'

import {DropdownContainer, DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

interface IFilterByWrapperProps {
    $noneSelected: boolean
}

interface IFilterStyleProps {
    $opened?: boolean,
    $top?: number,
    $left?: number
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 897px) {
     flex-direction: column;
     align-items: flex-start;
     gap: 15px;
  }
`

export const DropdownWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 897px) {
     width: 100%;
  }
`

export const FilterWrapper = styled.div<IFilterStyleProps>`
  border-radius: 8px;
  height: 32px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  background-color: white;
  /* display: inline-flex; */
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  
  .ico-Connected{
    margin-left: 10px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
  
  i{
    font-size: 15px;
    margin-right: 10px;
    transform: translateY(-1px);
  }
  
  .ico-AngleDown, .ico-AngleUp{
    margin-right: 0;
    margin-left: 10px;
  }
  
  .value-selected {
    color: ${({theme}) => theme.colors.performBlue[6]};
    /* width: 50px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .none{
    color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  :hover{
    background-color: ${({theme, $opened}) => !$opened && theme.colors.coolGray[1]};
    cursor: pointer;
  }
  
  :active:hover{
    color: ${({theme}) => theme.colors.deepBlue[8]};
    background-color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  ${props => props.$opened && css `
      background: ${({theme}) => theme.colors.deepBlue[2]};
      border-color: ${({theme}) => theme.colors.deepBlue[3]};
      color: ${({theme}) => theme.colors.deepBlue[8]};
  `}
  @media screen and (max-width: 897px) {
     margin-right: 0;
     /* justify-content: space-between; */
     width: 100%;
  }
`

export const FilterContainer = styled(DropdownContainer)`
  top: 35px;
  left: 0;
  padding: 25px;
  min-width: 220px;
  z-index: 200;
`

export const DateFilterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const DatePresetWrapper = styled.div`
  width: 206px;
  display: flex;
  flex-direction: column;
    h3 {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        color: ${({theme}) => theme.colors.deepBlue[5]};
    }
  
  .date-range{
    margin-top: 12px;
  }
`

export const FilterByWrapper = styled.div<IFilterByWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: ${({$noneSelected}) => $noneSelected ? '220' : '270'}px;
  margin-bottom: 10px;
  h4{
    font-size: 16px;
    line-height: 22px;
    color: ${({theme}) => theme.colors.deepBlue[7]};
  }
`

export const FilterByDropdown = styled.div<IFilterStyleProps>`
  height: 24px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 4px 12px;
  color: ${({theme}) => theme.colors.deepBlue[5]};
  display: flex;
  align-items: center;
  border-radius: 6px;
  
  .ico-AngleDown, .ico-AngleUp {
    font-size: 12px;
    margin-right: 0;
    margin-left: 10px;
  }

  :hover{
    background-color: ${({theme, $opened}) => !$opened && theme.colors.coolGray[1]};
    cursor: pointer;
  }

  :active:hover{
    color: ${({theme}) => theme.colors.deepBlue[8]};
    background-color: ${({theme}) => theme.colors.coolGray[4]};
  }

  ${props => props.$opened && css `
    color: ${({theme}) => theme.colors.deepBlue[8]};
    background-color: ${({theme}) => theme.colors.coolGray[4]};
  `}
`

export const MarketerPartnersList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  width: 270px;
`

export const FilterByContainer = styled(DropdownContainer)<IFilterStyleProps>`
  position: absolute;
  width: fit-content;
  z-index: 300;
  top: ${props => props.$top}px;
  left: ${props => props.$left}px;
  //transform: translate(-25px, 25px);
`

export const FilterByItem = styled(DropdownItem)`
  display: flex;
  align-items: center;
  white-space: normal;
  height: fit-content;
  
  .ico-Connected{
    font-size: 15px;
    margin-left: 10px;
  }
  
`

export const ClearFilter = styled.div`
  color: ${({theme}) => theme.colors.performOrange[3]};
  display: inline-block;
  :hover{
    cursor: pointer;
    color: ${({theme}) => theme.colors.performOrange[5]};
  }
`