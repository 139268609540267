import styled from "styled-components";

interface IFetchLoadingStyleProps {
    $noData?: boolean | undefined,
    $noDataOpacity?: number | undefined
}

export const FetchLoadingWrapper = styled.div<IFetchLoadingStyleProps>`
    background: ${({$noData, $noDataOpacity}) => $noData ? `rgba(255,255,255, ${$noDataOpacity ? $noDataOpacity : 1})` : 'rgba(255,255,255,.85)'} ;
    width: 100%;
    height: 100%;
    z-index: 11;
    text-align: center;
    margin: 1px auto auto;
    padding-top: 27px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .loading-dots {
        text-align: center;
        span {
            animation: loading-dots 1s infinite;
            width: 8px;
            height: 8px;
            background: #EF4A24;
            margin: 0 3px;
            display: inline-block;
            border-radius: 100%;
        }

        span:nth-child(2) {
            animation-delay: 0.1s;
        }

        span:nth-child(3) {
            animation-delay: 0.3s;
        }

        @keyframes loading-dots {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
`;
