import {api, filterApi} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";
import qs from "qs";
import {IIFetchMarketerFundingDataResponse} from "../marketer-funding-monitoring/MarketerFundingMonitoring.service";
import {IAdvertisersFilter} from "filters";
import {AxiosResponse} from "axios";

export interface IFetchTransactionsParams {
    page: number
    paginate_by?: number
    order?: 'desc' | 'asc'
    sort_by?: string
    advertiser_id?: number
    type?: string
    status?: string
    from?: string | Date,
    to?: string | Date,
    action?: string,
    group?: number
    summarize?: number
    concat?: number
}

export interface IFetchTransactionsResponse {
    user_id: number;
    created_at: string;
    description: string;
    type_name: string;
    currency_type_name: string;
    transaction_created: string;
    status: string;
    status_name: string;
    credit: number;
    debit: number;
    sum_credit: number;
    sum_debit: number;
    user: {
        name: string;
        lastname: string;
        company: string;
        full_name: string;
        display_name: string;
        executive: {
            id: number,
            full_name: string;
            display_name: string;
            name: string;
            lastname: string;
        }
    };
    processing_payment_fee: number
}

export interface IResponse extends IPagination<IFetchTransactionsResponse> {
    sums: {
        credit: number,
        debit: number
    }
}

export interface IAddTransactionParams {
    action: 'debit' | 'credit'
    amount: string
    card_id: string | number,
    created_at: string
    currency_type: string,
    maxDate: string,
    note?: string,
    user_id: number,
    type: string
}

export interface IFetchCardsResponse {
    [key: string]: {
        id: number;
        user_id: number;
        alias: string;
        friendly_number: string;
        type: string;
    }[]
}

class MarketersTransactionsService {
    static fetchTransactions = async (token: string, params: IFetchTransactionsParams)=> {
        return await api.get<IResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'billing'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params: {
                    ...params
                }
            }
        )
    }

    static exportCsv = async ( token: string,params: IFetchTransactionsParams) => {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'billing'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params: {
                    group: 0,
                    ...params,
                    action: 'export_csv'
                }
            }
        )
    }

    static updateCosts = async (token: string)=> {
        return await api.get([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_ADMIN_URL,
                'updatecosts'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                }
            }
        )
    }

    static addTransaction = async (token: string, id: number, params: IAddTransactionParams) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                id,
                'billing/pay',
            ].join('/'),
            {
                    ...params
                },
            {
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Content-Type' : 'application/json'
                    }
                }
        )
    }

    static fetchAdvertisers = async (token: string) => {
         return await filterApi.post<IAdvertisersFilter[]>(
                'advertisers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )

    }

    static fetchCards = async (token: string): Promise<AxiosResponse<any>> => {
        return await api.get([
                process.env.REACT_APP_LOCAL_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_ADMIN_URL,
                "cards"
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    for_filter: 1
                }
            })
    }
}

export default MarketersTransactionsService