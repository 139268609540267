import React from 'react'
import Modal from "../../../modal/Modal";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../styled-components";
import {DeleteDeviceFooter, DeleteDeviceContent, DeleteDeviceModalContainer} from "../../2fa.style";

interface Props {
    closeModal: () => void
    opened: boolean,
    deleteDevice: () => void,
    deleteDeviceLoading: boolean
}

const DeleteDeviceModal: React.FC<Props> = ({closeModal, opened, deleteDevice, deleteDeviceLoading}) => {

    const handleCancel = () => {
        closeModal()
    }

    return <Modal
        modalCustomClass={'delete-device-modal'}
        opened={opened}
        title={'Remove Device?'}
        closeModal={closeModal}
        noHeaderUnderline={true}
        marginTop={'150px'}
    >
        <DeleteDeviceModalContainer>
            <DeleteDeviceContent>
                <p>Are you sure you want to remove this device? This can not be undone.</p>
            </DeleteDeviceContent>
            <DeleteDeviceFooter>
                <ButtonStyled className='btn-cancel' onClick={handleCancel}>Cancel</ButtonStyled>
                <ButtonStyled
                    className='btn-red-filled'
                    onClick={deleteDevice}
                    disabled={deleteDeviceLoading}
                >
                    {deleteDeviceLoading && <BtnLoadingSpinner/>}Yes, remove device.
                </ButtonStyled>
            </DeleteDeviceFooter>
        </DeleteDeviceModalContainer>
    </Modal>
}

export default DeleteDeviceModal