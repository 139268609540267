import React, {useCallback, useEffect, useState} from 'react'

import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import InputSingleLine from "../../../../../../common/components/input-single-line/InputSingleLine";
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import {ModalFooter, Cancel, Button} from "../../../../../../common/components/modal/container/Container.style";

import {Wrapper, Label} from "./ViewEditModal.style";

import {useAppSelector, useAppDispatch} from "../../../../../../common/hooks";
import {editContact, getEditingUser, openAlert, saveUser, setUser} from "../../AdvertisersEdit.slice";

import {IAdvertiserEdit, IContacts} from "../../../../../../common/models/advertiser-edit.type";
import {tokenStore} from "../../../../../../store/auth.slice";
import {validateEmail} from "../../../../../../common/utils/validateEmail";
import Alert from "../../../../../../common/components/alert/Alert";
import ContactsService from "../Contacts.service";
import AdvertisersEditService from "../../AdvertisersEdit.service";

interface Props {
    contact: IContacts | null,
    type: 'view' | 'edit' | 'add' | 'delete',
    closeModal: () => void
}

const contactTypes = ['Billing', 'Technical', 'Other']
const IMTypes = ['Facebook', 'Twitter', 'Skype', 'Hangout', 'Other']


const ViewEditModal: React.FC<Props> = ({contact, type, closeModal}) => {
    const {editingUser, saveUserError} = useAppSelector(state => state.advertisersEdit)
    const [loading, setLoading] = useState(false)
    const token = useAppSelector(tokenStore)
    const [errors, setErrors] = useState<{name: string[], email: string[]}>()
    const [editContactError, setEditContactError] = useState(false)

    const [firstName, setFirstName] = useState(contact ? contact.name : '')
    const [lastName, setLastName] = useState(contact ? contact.lastname : '')
    const [email, setEmail] = useState(contact ? contact.email : '')
    const [phone, setPhone] = useState(contact ? contact.cell_phone : '')
    const [workPhone, setWorkPhone] = useState(contact ? contact.work_phone : '')
    const [fax, setFax] = useState(contact ? contact.fax : '')

    const [companyName, setCompanyName] = useState(contact ? contact.company_name : '')
    const [jobTitle, setJobTitle] = useState(contact ? contact.job_title : '')

    const [contactType, setContactType] = useState(contact?.contact_type ? contact.contact_type : 'other')
    const [contactTypeOpened, setContactTypeOpened] = useState(false)
    const [imType, setImType] = useState(contact?.im_type ? contact.im_type : 'other')
    const [imTypeOpened, setImTypeOpened] = useState(false)

    const [im, setIm] = useState(contact ? contact.im : '')

    const [newsletter, setNewsletter] = useState(contact ? contact.newsletter === 1 : false)

    const dispatch = useAppDispatch()

    //ADD ERROR HANDLING
    const handleSubmit = async () => {
        //Edit editing user info
        if(editingUser){
            //edit editing user
            if(editingUser?.id !== contact?.user_id && type === 'edit'){
                const newUser: IAdvertiserEdit =
                    {
                        ...editingUser,
                        email: email,
                        name: firstName,
                        lastname: lastName,
                        phone: phone
                    }
                const params = {
                    token: token,
                    user: newUser,
                    id: editingUser.id
                }
                try {
                    setLoading(true)
                    const response = await AdvertisersEditService.saveUser(token, newUser, editingUser.id)
                    dispatch(setUser({...response.data,
                        contacts: [
                            {
                                company_name: response.data.company,
                                name: response.data.name,
                                contact_type: 'Account Owner',
                                im_type: 'other',
                                work_phone: '',
                                fax: '',
                                job_title: '',
                                lastname: response.data.lastname,
                                email: response.data.email,
                                im: '',
                                cell_phone: response.data.phone,
                                newsletter: 1,
                                include_compliance_emails: true
                            }
                            ,...response.data.contacts
                        ]}))
                    dispatch(openAlert({
                        opened: true,
                        type: 'success',
                        message: 'User successfully edited!'
                    }))
                    setLoading(false)
                    closeModal()
                } catch (e){
                    setEditContactError(true)
                    setLoading(false)
                }

            }

            //Adding contact
            if(type === 'add'){
                const newContact: IContacts = {
                    cell_phone: phone,
                    work_phone: workPhone,
                    include_compliance_emails: false,
                    company_name: companyName,
                    contact_type: contactType,
                    email: email,
                    fax: fax,
                    im: im,
                    im_type: imType,
                    job_title: jobTitle,
                    lastname: lastName,
                    name: firstName,
                    newsletter: newsletter ? 1 : 0,
                    user_id: editingUser?.id,
                }
                try{
                    setLoading(true)
                    await ContactsService.addContact(token, newContact)
                    await dispatch(getEditingUser({token, id: editingUser.id}))
                    dispatch(openAlert({
                        opened: true,
                        type: 'success',
                        message: 'Contact successfully added!'
                    }))
                    setLoading(false)
                    closeModal()
                } catch (err){
                    // @ts-ignore
                    setErrors(err.response.data)
                    setLoading(false)
                }

            }
            if(editingUser?.id === contact?.user_id && type === 'edit'){
                const newContact: IContacts = {
                    id: contact?.id,
                    cell_phone: phone,
                    work_phone: workPhone,
                    include_compliance_emails: contact?.include_compliance_emails,
                    company_name: companyName,
                    contact_type: contactType,
                    email: email,
                    fax: fax,
                    im: im,
                    im_type: imType,
                    job_title: jobTitle,
                    lastname: lastName,
                    name: firstName,
                    newsletter: newsletter ? 1 : 0,
                    user_id: editingUser?.id,
                }

                try {
                    setLoading(true)
                    const {data} = await ContactsService.editContact(token, newContact)
                    dispatch(editContact(data))
                    dispatch(openAlert({
                        opened: true,
                        type: 'success',
                        message: 'Contact successfully edited!'
                    }))
                    setLoading(false)
                    closeModal()
                } catch (e){
                    // @ts-ignore
                    setErrors(e.response.data)
                    setLoading(false)
                }
            }

            if(type === 'view'){
                closeModal()
            }
        }
    }

    const isSubmitButtonDisabled = () => {
        if(editingUser && editingUser?.id !== contact?.user_id && type === 'edit'){
           if(editingUser.name === firstName && editingUser.lastname === lastName && editingUser.phone === phone && editingUser.email === email){
               return true
           }
           if(firstName === '' || lastName === '' || email === '') return true
            if(!validateEmail(email)) return true
        }
        if(loading) return true
    }

    const handleContactTypeSelect = (type: string) => {
        setContactType(type.toLowerCase())
        setContactTypeOpened(false)
    }

    const handleIMTypeSelect = (type: string) => {
        setImType(type.toLowerCase())
        setImTypeOpened(false)
    }

    return <Wrapper>
        {editContactError && <div style={{marginBottom: '20px'}}><Alert alertType={'error'}>User with that email already exists</Alert></div>}
        {errors && <div style={{marginBottom: '20px'}}><Alert alertType={'error'}>
            {errors.name && <div>{errors.name[0]}</div>}
            {errors.email && <div>{errors.email[0]}</div>}
        </Alert></div>}
        {(editingUser?.id !== contact?.user_id && contact) && (
            <>
                <div  className={'row'}>
                    <Label>First Name</Label>
                    <InputSingleLine readonly={type === 'view'} onChange={setFirstName} placeholder={'Enter first name'} value={firstName} width={'100%'} height={'40px'}/>
                </div>
                <div  className={'row'}>
                    <Label>Last Name</Label>
                    <InputSingleLine readonly={type === 'view'} onChange={setLastName} placeholder={'Enter last name'} value={lastName} width={'100%'} height={'40px'}/>
                </div>
                <div  className={'row'}>
                    <Label>Email</Label>
                    <InputSingleLine readonly={type === 'view'} onChange={setEmail} placeholder={'Enter email'} value={email} width={'100%'} height={'40px'}/>
                </div>
                <div  className={'row'}>
                    <Label>Phone</Label>
                    <InputSingleLine readonly={type === 'view'} onChange={setPhone} placeholder={'Enter phone number'} value={phone} width={'100%'} height={'40px'}/>
                </div>
            </>
        )}

        {(editingUser?.id === contact?.user_id || !contact) && (
            <>
                <div style={{marginBottom: '30px'}}>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>Company Name</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={'100%'} onChange={setCompanyName} placeholder={'Enter Company Name'} value={companyName} />
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Label>Job Title</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={"100%"} onChange={setJobTitle} placeholder={'Enter Job Title'} value={jobTitle} />
                    </div>
                </div>
                <div style={{marginBottom: '30px'}}>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>First Name</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={'100%'} onChange={setFirstName} placeholder={'Enter First Name'} value={firstName} />
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Label>Last Name</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={"100%"} onChange={setLastName} placeholder={'Enter Job Title'} value={lastName} />
                    </div>
                </div>
                <div style={{marginBottom: '30px'}}>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>Contact Type</Label>
                        <Dropdown text={contactType.charAt(0).toUpperCase() + contactType.slice(1)} isOpened={contactTypeOpened} setOpened={setContactTypeOpened}>
                            {contactTypes.map(type => {
                                return <DropdownItem key={type} selected={type.toLowerCase() === contactType} onClick={() => handleContactTypeSelect(type)}>{type}</DropdownItem>
                            })}
                        </Dropdown>
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Label>Email</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={"100%"} onChange={setEmail} placeholder={'Enter Job Title'} value={email} />
                    </div>
                </div>
                <div style={{marginBottom: '30px'}}>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>IM Type</Label>
                        <Dropdown  text={imType.charAt(0).toUpperCase() + imType.slice(1)} isOpened={imTypeOpened} setOpened={setImTypeOpened}>
                            {IMTypes.map(type => {
                                return <DropdownItem key={type} selected={type.toLowerCase() === imType} onClick={() => handleIMTypeSelect(type)}>{type}</DropdownItem>
                            })}
                        </Dropdown>
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Label>IM</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={"100%"} onChange={setIm} placeholder={'Enter IM'} value={im} />
                    </div>
                </div>
                <div style={{marginBottom: '30px'}}>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>Work Phone</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={'100%'} onChange={setWorkPhone} placeholder={'Enter Work Phone'} value={workPhone} />
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Label>Cell Phone</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={"100%"} onChange={setPhone} placeholder={'Enter Cell Phone'} value={phone} />
                    </div>
                </div>
                <div>
                    <div style={{display: 'inline-block', width: '50%', paddingRight: '15px'}}>
                        <Label>Fax</Label>
                        <InputSingleLine readonly={type==='view'} height={"40px"} width={'100%'} onChange={setFax} placeholder={'Enter Fax'} value={fax} />
                    </div>
                    <div style={{display: 'inline-block', width: '50%', paddingLeft: '15px'}}>
                        <Checkbox checked={newsletter} onChange={() => setNewsletter(!newsletter)} label={'Receive Perform[cb] newsletter'}/>
                    </div>
                </div>
            </>
        )}
        <ModalFooter>
            {type !== 'view' ? <Cancel onClick={() => closeModal()}>Cancel</Cancel> : <div/>}
            <Button disabled={isSubmitButtonDisabled()} onClick={handleSubmit}>{type === 'view' ? 'Close' : type === 'edit' ? 'Save Changes' : 'Create Contact'}</Button>
        </ModalFooter>
    </Wrapper>
}

export default ViewEditModal