import React, {useEffect, useRef, useState} from 'react'
import {FilterID} from "filters";
import {IFilter, PageID, setFilter} from "../../../../store/filters.slice";
import {DropdownItem} from "../../dropdown/Dropdown.style";

import {Wrapper, SelectFilterContainer, FilterSearch, FilterList} from './SelectFilterDropdown.style'

import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../hooks";

interface Props {
    allFilters: {
        id: FilterID,
        value: string
    }[],
    pageId: PageID,
    setDiscoverDisplayed?: (b: boolean) => void
}

const SelectFilterDropdown: React.FC<Props> = ({allFilters, pageId, setDiscoverDisplayed}) => {
    const {filters} = useAppSelector(state => state.filters)
    const [selectedFilters, setSelectedFilters] = useState<IFilter<any>[]>([])
    const [search, setSearch] = useState('')
    const [opened, setOpened] = useState(false)
    const [filteredFilters, setFilteredFilters] = useState(allFilters)

    const dispatch = useAppDispatch()

    const wrapperRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if(filters.some(filter => filter.pageID === pageId)){
            setSelectedFilters(filters.filter(filter => filter.pageID === pageId)[0].filters)
        }
    }, [filters])

    useOnClickOutside(wrapperRef, () => {
        setOpened(false)
    })

    const addFilter = (filter: any) => {
        setDiscoverDisplayed && setDiscoverDisplayed(true)
        
        dispatch(setFilter({
            pageID: pageId,
            filter: {
                id: filter.id,
                exclude: false,
                selected: []
            }
        }))
        setOpened(false)
    }

    const filterSearch = (value: string) => {
        const filtered = allFilters.filter(filter => {
            return filter.value.toLowerCase().includes(value.toLocaleLowerCase())
        })
        setSearch(value)
        setFilteredFilters(filtered)
    }

    return <Wrapper ref={wrapperRef} $opened={opened} >
        <div className={'open-dropdown-icon'} onClick={() => setOpened(!opened)}><i className={'material-icons'}>add</i></div>
        <SelectFilterContainer opened={opened}>
            <FilterSearch placeholder={'Search'} value={search} onChange={(e) => filterSearch(e.target.value)}/>
            <FilterList>
                {filteredFilters.map(filter => (
                    <DropdownItem
                        key={filter.id}
                        disabled={selectedFilters.some(selectedFilter => selectedFilter.id === filter.id)}
                        onClick={() => addFilter(filter)}
                    >
                        {filter.value}
                    </DropdownItem>
                ))}
            </FilterList>
        </SelectFilterContainer>
    </Wrapper>
}

export default SelectFilterDropdown