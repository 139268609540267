import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {useCallback, useEffect, useState} from "react";
import {FilterID, ISalesExecutiveFilter} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {filterApi} from "../../../services/api";

import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

export const useSalesExecutiveCacheFilter = (pageId: IPagePresetsID, displayCount?: number) => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<ISalesExecutiveFilter[]>([])
    const [salesExecutives, setSalesExecutives] = useState<ISalesExecutiveFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [salesExecutiveSearchValue, setSalesExecutiveSearchValue] = useState('')
    const [salesExecutivesFilterNotDisplayed, setSalesExecutivesFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID ='sales-executive'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<ISalesExecutiveFilter[]>(
                'account-managers',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data.sort((a: ISalesExecutiveFilter, b: ISalesExecutiveFilter) => (
                    a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())
                )
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token, fetch])

    useEffect(() => {
        let filteredSalesExecutives = fetchedData.filter(executive => (
                executive.name.toLowerCase() + ' ' + executive.lastname.toLowerCase()).includes(salesExecutiveSearchValue.toLowerCase())
            ||
            executive.id.toString().includes(salesExecutiveSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedExecutives = [...filteredSalesExecutives].slice(0, displayCount)
            if(filteredSalesExecutives.length > displayCount){
                setSalesExecutivesFilterNotDisplayed(filteredSalesExecutives.length - displayCount)
            } else {
                setSalesExecutivesFilterNotDisplayed(0)
            }
            setSalesExecutives(displayedExecutives)
        } else {
            setSalesExecutives(
                filteredSalesExecutives
            )
        }
    }, [salesExecutiveSearchValue, fetchedData, displayCount])

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (executive: ISalesExecutiveFilter) => {
        if(filter.selected.some((selected)  => selected.id === executive.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== executive.id)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, executive]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }


    return {
        id: id,
        options: salesExecutives,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        },
        notDisplayed: salesExecutivesFilterNotDisplayed,
        search: {
            value: salesExecutiveSearchValue,
            setSearch: setSalesExecutiveSearchValue
        },
        loading
    }
}