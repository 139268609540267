import React from "react";
import {ModalBody, ModalFooter} from "./UnMergeModal.style";
import {ButtonStyled} from "../../../../../../common/styled-components";

interface IProps {
    unMergePartners : () => void,
    closeModal: () => void,
    fetchData: () => void
}

const UnMergeModal: React.FC<IProps> = ({unMergePartners, closeModal, fetchData}) => {
    const remove = () => {
        unMergePartners()
        fetchData()
        closeModal()
    }
    return (
        <>
            <ModalBody>
                <p>
                    Are you sure you want to un-merge the selected partner(s)? This is a global change and will affect all users in the platform. All reports and filters related to these partners will updated immadiately upon merging.
                </p>
                <p>
                    The partners can be merged again from the non-merged partners tab on this page at any time.
                </p>
            </ModalBody>
            <ModalFooter>
                <ButtonStyled className={'btn-cancel'} onClick={closeModal}>Cancel</ButtonStyled>
                <ButtonStyled style={{marginLeft: 20}} className={'btn-red-filled'} onClick={remove}>Yes, un-merge partner(s)</ButtonStyled>
            </ModalFooter>
        </>
    )
}
export default UnMergeModal
