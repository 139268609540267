import styled, { css } from "styled-components";

export const AgencyViewTableWrapper = styled.div`
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .left {
            display: flex;
            align-items: center;
            gap: 16px;
            .heading {
                color: #31383B;
                padding-right: 28px;
                line-height: 21px;
                font-family: ${({theme}) => theme.fonts.AvenirHeavy};
                font-size: 16px;
            }
            .full-report {
                .full-report-btn {
                    color: #8799A1;
                    border: 1px solid #E9ECED;
                    border-radius: 8px;
                    padding: 4px 8px;
                    font-size: 12px;
                    color: #8799A2;
                    cursor: pointer;
                }
            }
            @media screen and (max-width: 769px) {
              justify-content: space-between;
              width: 100%;
            }
        }

        @media screen and (max-width: 769px) {
          flex-direction: column;
          gap: 20px;
        }
    }
`;

export const ColumnOptionsBody = styled.div`
    .heading {
        color: #31383B;
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        margin-bottom: 10px;
    }

    .search {
        input {
            border: none;
            border-bottom: 1px solid #E9ECED;
            outline: 0;
            width: 100%;
            padding: 4px 0;
            height: 30px;
            font-size: 12px;
            ::placeholder {
                color: ${({theme}) => theme.colors.coolGray[4]};
            }
        }
    }

    .list {
        max-height: 330px;
        overflow-y: auto;
    }

    .footer {
        border-top: 1px solid #E9ECED;
        padding: 18px 0;
        .buttons {
            display: flex;
            gap: 15px;
            .button {
                font-size: 12px;
                color: #8799A2;
                font-family: ${({theme}) => theme.fonts.AvenirLight};
                :hover{
                    color: #5A686F;
                }
            }
        }
    }
`;

export const ColumnItem = styled.div`
    display: flex;
    align-items: center;
`;

export const AnalyzeHeading = styled.div`
    padding: 8px;
`;

export const AgencyViewSortIconDown = styled.div`
    position: relative;
    &:after {
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 0;
        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }
`;

export const AgencyViewSortIconUp = styled.div`
    position: relative;
    &:after {
        display: block;
        position: absolute; 
        top: -10px; 
        left: 0; 
        transform: rotate(180deg);

        content: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.00063 0.000976562C5.13324 0.000976563 5.26042 0.0461232 5.35419 0.126485C5.44795 0.206846 5.50063 0.31584 5.50063 0.429489V10.5364L8.64663 7.83932C8.74052 7.75886 8.86786 7.71365 9.00063 7.71365C9.13341 7.71365 9.26075 7.75886 9.35463 7.83932C9.44852 7.91978 9.50126 8.02892 9.50126 8.14271C9.50126 8.2565 9.44852 8.36563 9.35463 8.44609L5.35463 11.8742C5.30819 11.9141 5.25301 11.9458 5.19226 11.9674C5.13152 11.989 5.0664 12.0001 5.00063 12.0001C4.93486 12.0001 4.86974 11.989 4.809 11.9674C4.74825 11.9458 4.69308 11.9141 4.64663 11.8742L0.646631 8.44609C0.552745 8.36563 0.5 8.2565 0.5 8.14271C0.5 8.02892 0.552745 7.91978 0.646631 7.83932C0.740518 7.75886 0.867856 7.71365 1.00063 7.71365C1.13341 7.71365 1.26075 7.75886 1.35463 7.83932L4.50063 10.5364V0.429489C4.50063 0.31584 4.55331 0.206846 4.64708 0.126485C4.74085 0.0461232 4.86802 0.000976563 5.00063 0.000976562Z' fill='%23FF5E38'/%3E%3C/svg%3E%0A");
    }
`;