import React, {useMemo, useState} from 'react'
import {CreativeWrapper} from "../CreativesAndEmail.style";
import {StyledSelect} from "../../../../../../../../common/components/select/Select";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import ModalWindow from "../../../../../../../../common/components/modalWindow/ModalWindow";
import VideoPreviewModalContent
    from "../../../../../../../../common/components/video-preview-modal-content/VideoPreviewModalContent";
import {CreativeStatus, CreativeType, ICreative} from "../../../../interfaces/interfaces";
import ImagePreviewModalContent
    from "../../../../../../../../common/components/image-preview-modal-content/ImagePreviewModalContent";

import {setOffer} from "../../../../create-offer.slice";
import {PlayFilled} from "../../../../../../../../assets/svgs/SvgIcons";
import {format} from "date-fns";

interface Props {
    creative: ICreative
    index: number
}

const VisualCreative: React.FC<Props> = ({creative, index}) => {

    const {config} = useAppSelector(state => state.auth)
    const {offer} = useAppSelector(state => state.createOffer)

    console.log('creative', creative)
    console.log('config', config)

    const [previewModal, setPreviewModal] = useState({
        opened: false,
        imgSource: ''
    })

    const dispatch = useAppDispatch()

    const isBannerDisabled = useMemo(() => {
        console.log(config.creativesConfig.banner_sizes.map((banner: number[]) => {
            return banner.join('x')
        }))
        console.log(`${creative.files[0].width}x${creative.files[0].height}`)
        return config.creativesConfig.banner_sizes.map((banner: number[]) => {
            return banner.join('x')
        })
            .includes(`${creative.files[0].width}x${creative.files[0].height}`)
    }, [
        config.creativesConfig.banner_sizes,
        creative.files
    ])

    const statusOptions = useMemo(() => {
        return [...Object.keys(config.creativesConfig.statuses)
            .filter(key => key !== 'pending')
            .map(key => {
                return {
                    label: config.creativesConfig.statuses[key],
                    value: key,
                    disabled: key === 'active' && creative.status === 'disabled'
                }
        })]
    }, [config.creativesConfig.statuses, creative.status])

    const creativeTypeOptions = useMemo(() => {
        const isBannerDisabled = config.creativesConfig.banner_sizes.map((banner: number[]) => {
            return banner.join('x')
        })
            .includes(`${creative.files[0].width}x${creative.files[0].height}`)
        return [...Object.keys(config.creativesConfig.types)
            .map(key => {
                return {
                    label: config.creativesConfig.types[key],
                    value: key,
                    disabled: key === 'banner' && !isBannerDisabled
                }
        })]
    }, [
        config.creativesConfig.banner_sizes,
        config.creativesConfig.types,
        creative.files
    ])

    const createPreviewUrl = useMemo(() : string => {
        const extension = creative.files[0]?.name.split('.').pop()
        let src = encodeURI(
            config.apiBase +
            'file/creative/' +
            (creative.id > 0 ?
                creative.files[0]?.id :
                `temp/${offer.id}?creative_id=${creative.id}&name=${creative.files[0]?.name}`
            ))

        if (extension && ['mp4', 'mov','m4v','wmv', 'avi', 'mpg', 'ogv', '3gp', '3g2'].includes(extension)){
            src = src + '#t=10'
        }

        return src
    }, [
        config.apiBase,
        creative,
        offer.id
    ])

    const handlePreview = () => {
        setPreviewModal({
            opened: true,
            imgSource: creative.type === 'product_video' ? createPreviewUrl.split('#')[0] : createPreviewUrl
        })
    }

    const handleCreativeStatusSelect = (status: CreativeStatus, index: number) => {
        const newCreatives = [...offer.creatives]
        const newCreative = {...newCreatives[index]}
        newCreative.status = status
        newCreatives[index] = newCreative
        dispatch(setOffer({
            creatives: [...newCreatives ]
        }))
    }

    const handleCreativeTypeSelect = (type: CreativeType, index: number) => {
        const newCreatives = [...offer.creatives]
        const newCreative = {...newCreatives[index]}
        newCreative.type = type
        newCreatives[index] = newCreative
        dispatch(setOffer({
            creatives: [...newCreatives ]
        }))
    }

    const statusValue = useMemo(() => {
        const value = Object.keys(config.creativesConfig.statuses).filter(key => {
            return key === creative.status
        })[0]
        return value ? {
            label: config.creativesConfig.statuses[value],
            value: value
        } : null
    }, [
        config.creativesConfig.statuses,
        creative.status
    ])

    const typeValue = useMemo(() => {
        const value = Object.keys(config.creativesConfig.types).filter(key => {
            return key === creative.type
        })[0]
        return value ? {
            label: config.creativesConfig.types[value],
            value: value
        } : null
    }, [config.creativesConfig.types, creative.type])

    const removeCreative = () => {
        const newCreatives = [...offer.creatives]
        newCreatives.splice(index, 1)
        dispatch(setOffer({
            creatives: [...newCreatives ]
        }))
    }

    return <CreativeWrapper>
        <div className="header">
            Visual Creative {creative.id > 0 ? `(${creative.id})` : null}
            <div className="creative-toolbar">

                {creative.id > 0 ?
                    <button className="download-icon-wrapper">
                        <a href={`${config.apiBase}offer/${creative.id}/creative`} target={'_blank'} rel={'noreferrer'}>
                            <i className="ico-Download" />
                        </a>
                    </button>
                : null}
                {creative.status !== 'active' ? <button className="delete-icon-wrapper" onClick={removeCreative}>
                    <i className="ico-Delete">  </i>
                </button> : null}
            </div>
        </div>
        <div className="body">
            <div className="visual-creative-details-wrapper">
                <div className="image-thumbnail" onClick={handlePreview}>
                    {(creative.type === 'product_image' || creative.type === 'banner') ? <div
                        className="image"
                        style={{
                            backgroundImage: `url(${createPreviewUrl})`
                        }}
                    /> :
                        <video className={'image'}>
                            <source src={createPreviewUrl}/>
                        </video>
                    }
                    <div className={'hover-overlay'}>
                        {creative.type === 'product_video' ? <PlayFilled/> : <i className="ico-EyeFilled" />}
                    </div>
                </div>
                <div className="creative-details">
                    <p className="file-name">{creative.name}</p>
                    <p className="details">{creative.files[0]?.size && (creative.files[0]?.size > 1000 ? `${Math.round((creative.files[0]?.size / 1000) * 10) / 10}MB` : `${creative.files[0]?.size}KB`)} <span className={'dot-separator'} /> {creative.files[0]?.width}x{creative.files[0]?.height}</p>
                    <p className="details">{creative.files[0]?.created_at ? format(new Date(creative.files[0]?.created_at), "MM/dd/yyyy 'at' h:m b 'EST'") : ''}</p>
                </div>
            </div>
            <StyledSelect
                selectWrapperClassname={'creative-select-wrapper'}
                selectLabel={'Status'}
                options={statusOptions}
                value={statusValue}
                isOptionDisabled={(option) => option.disabled ? option.disabled : false}
                // @ts-ignore
                // @ts-ignore
                onChange={(newValue, actionMeta) => handleCreativeStatusSelect(newValue?.value, index)}
            />
            <StyledSelect
                selectWrapperClassname={'creative-select-wrapper'}
                selectLabel={'Creative Type'}
                value={typeValue}
                isOptionDisabled={(option) => option.disabled ? option.disabled : false}
                // @ts-ignore
                onChange={(newValue, actionMeta) => handleCreativeTypeSelect(newValue?.value, index)}
                options={creativeTypeOptions}
            />
        </div>
        <ModalWindow
            enableScroll={false}
            opened={previewModal.opened}
            modalCustomClass={'image-video-preview-modal'}
            contentElement={
            creative.type === 'product_video' ?
            <VideoPreviewModalContent
                videoSource={previewModal.imgSource}
                videoName={creative.name}
                closeModal={() => setPreviewModal({
                    imgSource: '',
                    opened: false
                })}
            /> :
            <ImagePreviewModalContent
                imageSource={previewModal.imgSource}
                imageName={creative.name}
                closeModal={() => setPreviewModal({
                    imgSource: '',
                    opened: false
                })}
            />
            }
        />
    </CreativeWrapper>
}

export default VisualCreative