import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 16px 27px 16px 21px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .tooltip{
        padding: 8px 12px !important;
        font-size: 12px !important;
        line-height: 16px !important;
    }
    
    .value{
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
    
    .label{
        margin-bottom: 8px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    
    .material-symbols-outlined{
        font-size: 22px;
        color: ${({theme}) => theme.colors.deepBlue[3]};
    }
    
    .ico-Delete{
        cursor: pointer;
        color: ${({theme}) => theme.colors.deepBlue[4]};
        font-size: 16px;
    }
    
    .disabled {
        pointer-events: none;
        color: ${({theme}) => theme.colors.coolGray[3]};
    }

    @media (max-width: 576px) {
        font-size: 9px;
        padding: 12px 12px 12px 12px;
        
        .ico-Delete{
            font-size: 14px;
        }

        .material-symbols-outlined{
            font-size: 18px;
        }
    }
`