import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    padding: 40px;
    border-radius: 24px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
    background-color: white;
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;
    outline: 0;
    pointer-events: auto;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
        padding: 30px
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
    max-height: 100%;
`

export const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({theme}) => theme.colors.coolGray['3']};
    color: ${({theme}) => theme.colors.deepBlue['7']};
    .title {
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
    }
`

export const Body = styled.div`
    margin-top: 24px;
    margin-bottom: 20px;
    flex: 1 1 auto;
    overflow: hidden auto;
`

export const Footer = styled.div`
    border-top: 1px solid ${({theme}) => theme.colors.coolGray['3']};
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
`

export const CloseButton = styled.div`
    color: ${({theme}) => theme.colors.deepBlue['4']};
    font-size: 16px;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: all .2s ease-in-out;
    :hover{
        cursor: pointer;
        color: ${({theme}) => theme.colors.deepBlue['5']};
        background-color: ${({theme}) => theme.colors.deepBlue['1']};
    }
    :hover:active{
        color: ${({theme}) => theme.colors.deepBlue['7']};
        background-color: ${({theme}) => theme.colors.deepBlue['2']};
    }
`

