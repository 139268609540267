import React, {useState, useEffect} from 'react';
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";
import styled from "styled-components";

const ClickPayoutInputWrapper = styled.div`
    .form-control {
        margin-bottom: 0;
    }
`
export interface Props {
    
}

const ClickPayoutInput: React.FC<Props> = ({}) => {
    const dispatch = useAppDispatch()
    
    const {offer} = useAppSelector(state => state.createOffer)
    
    const handleClickPayoutChange = (e: string | undefined) => {
        dispatch(setOffer({
            max_bid: e
        }))
    }
    
    return (
        <ClickPayoutInputWrapper>
            <CurrencyInput
                style={{marginBottom: 0}}
                wrapperClassName={'form-control form-control-payout'}
                prefix={'$'}
                value={offer.max_bid}
                onValueChange={(e) => handleClickPayoutChange(e)}
                placeholder={'$0.00'}
                label={<>Payout</>}
                decimalScale={2}
            />
        </ClickPayoutInputWrapper>
    );
};

export default ClickPayoutInput