import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useAppSelector} from "../../useAppSelector";
import {tokenStore} from "../../../../store/auth.slice";
import {useAppDispatch} from "../../useAppDispatch";
import {FilterID, IAdminUserStatus} from "filters";
import {useCallback, useEffect, useState} from "react";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IReferrerFilter} from "./useReferrerCacheFilter";
import {ADMIN_USER_STATUSES} from "../../../utils/consts";

export const useAdminUserStatusCacheFilter  = (pageId: IPagePresetsID,displayCount?: number) => {
    const dispatch = useAppDispatch()
    const [notDisplayed, setNotDisplayed] = useState(0);
    const [loading, setLoading] = useState(false)
    const [statuses, setStatuses] = useState<IAdminUserStatus[]>(ADMIN_USER_STATUSES)

    const id: FilterID = 'user_status'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (status: IAdminUserStatus) => {
        dispatch(setFilterValue({
            pageId: pageId,
            filterId: id,
            filter: { selected : [status]}
        }))
        /**
         * add functionality for selecting and deselecting filters
         * check previous filter
         */
    }

    return{
        id,
        loading,
        notDisplayed,
        options: statuses,
        selected: {
            value: filter.selected,
            setSelected: setSelected
        }
    }
};

