import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import CpaService from "./Cpa.service";
import {RootState} from "../../../../../store/store";


interface IInitialState {

    campaign: {
        [key: string]: any
    },

    validation: {
        [key: string]: string | undefined
    }

    sections: {
        [key: string] : any
    }

    estimated_traffic: null | number,

    pixelGroups: {
        upsell: any[],
        offer: any[],
        loading: boolean
    }
}

const initialState: IInitialState = {
    campaign: {
        name: '',
        brand_name: '',
        destination_url: '',
        niche: null,
        niche_id: null,
        custom_domain: null,
        custom_domain_id: null,
        direct_linking: false,
        campaign_pcb_id: '',
        campaign_tracking_settings: {
            campaign_id: null,
            conversion_page_url: null,
            parallel_tracking_enabled: false,
            click_tracking_url: null,
            tracking_domain: null,
            auto_populate_domain: true,
            tracking_clickid_parameter: null,
            conversion_tracking_url: null
        },
        cpa: null,
        is_revshare: false,
        postback_url: '',
        iframe_pixel: '',
        image_pixel: '',
        revshare: [
            {
                id: _.uniqueId('MAIN_REVSHARE_'),
                name: '',
                value: ''
            }
        ],
        upsells: [],
        budget: 1,
        budget_value_type: 0,
        budget_type: 'unlimited',
        budget_group_id: null,
        budget_group: null,
        link_test_budget_type: 'disabled',
        link_test_budget_value: 1,
        end_date: null,
        end_date_option: 0,
        start_date: null,
        start_date_option: 0,
        campaign_schedule: [],
        max_bid: null,
        additionalCreative: {text_creative: ''},
        preview_url: '',
        description: '',
        package_id: '',
        targeted_locations: [],
        excluded_locations: [],
        cities_type: 'all',
        selected_cities: [],
        selected_zipcode: '',
        selected_zipcodes: [],
        zipcode_files: [],
        zipcode_type: 'all',
        isp_type: 'all',
        selected_isp: [],
        traffic_types: [],
        os_targeting: {
            tablet: {
                android: 1,
                ios: 1,
                windows: 1,
                mac: 1,
                linux: 1,
                undefined: 1
            },
            mobile: {
                android: 1,
                ios: 1,
                windows: 1,
                mac: 1,
                linux: 1,
                undefined: 1
            }
        },
        desktop: true,
        mobile: true,
        tablet: true,
        creatives: [],
        public_app_name: '',
        public_app_subtitle: '',
        payout_modifiers: []
    },

    validation: {},

    sections: {
        tracking_and_conversions: {
            expanded: false,
            locked: true,
            legacy_expanded: false,
            advanced_pixels_expanded: false
        },
        info_and_creatives: {
            expanded: false,
            locked: true,
        },
        budget: {
            expanded: false,
            locked: true,
            advanced_expanded: false
        },
        targeting: {
            expanded: false,
            locked: true,
            advanced_expanded: false
        },
        scheduling: {
            expanded: false,
            locked: true,
            advanced_expanded: false
        },

    },

    estimated_traffic: null,

    pixelGroups: {
        upsell: [],
        offer: [],
        loading: false
    }
}

export const fetchPixelGroups = createAsyncThunk<any, any, {state: RootState}>(
    'cpa/fetchPixelGroups',
    async (_, {getState}) => {
        const state = getState()
        const token = state.auth.token
        const [upsell, offer] = await Promise.all([
            CpaService.fetchPixelGroups(token, 'upsell'),
            CpaService.fetchPixelGroups(token, 'offer')
        ])
        return {
            upsell: upsell.data,
            offer: offer.data
        }
    }
    )

const cpaSlice = createSlice({
    name: 'advertiser-offers-cpa',
    initialState: initialState,
    reducers: {

        setCampaign: (state, action:PayloadAction<{[key: string]: any}>) => {
            state.campaign = {...state.campaign, ...action.payload}
        },

        setValidation: (state, action:PayloadAction<{[key: string]: string | undefined}>) => {
            state.validation = action.payload
        },

        setSections: (state, action:PayloadAction<{[key: string]: any}>) => {
            state.sections = action.payload
        },

        setAllSectionsUnlocked: (state) => {
            const sectionKeys = Object.keys(state.sections)

            sectionKeys.forEach(key => {
                state.sections[key] = {...state.sections[key], locked: false}
            })
        },

        setAllSectionsLocked: (state) => {
            const sectionKeys = Object.keys(state.sections)

            sectionKeys.forEach(key => {
                state.sections[key] = {...state.sections[key], locked: true, expanded: false}
            })
        },

        toggleSectionExpand: (state, action: PayloadAction<string>) => {
            state.sections[action.payload] = {
                ...state.sections[action.payload],
                expanded: state.sections[action.payload].locked ?
                    false :
                    !state.sections[action.payload].expanded
            }
        },

        //CAMPAIGN

        setEstimatedTraffic: (state, action: PayloadAction<number | null>) => {
            state.estimated_traffic = action.payload
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(
                fetchPixelGroups.pending,
                (state, action) => {
                    state.pixelGroups = {
                        ...state.pixelGroups,
                        loading: true,
                    }
                }
            )
            .addCase(
                fetchPixelGroups.fulfilled,
                (state, action) => {
                    state.pixelGroups = {
                        loading: false,
                        offer: action.payload.offer,
                        upsell: action.payload.upsell
                    }
                },
            )
    }
})

export default cpaSlice.reducer
export const {
    setEstimatedTraffic,
    setCampaign,
    setValidation,
    setSections,
    setAllSectionsLocked,
    setAllSectionsUnlocked,
    toggleSectionExpand
} = cpaSlice.actions