import React, {useRef} from 'react';
import {ColumnApi, ColumnState} from "ag-grid-community";
import {TableColumnItem, TableColumnsPinButton, TableColumnItemCheckboxWrapper} from "./TableColumnListItem.style";
import Checkbox from "../../../../checkbox/Checkbox";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {Draggable, DraggableProvided, DraggableStateSnapshot} from "react-beautiful-dnd";

interface Props {
    provided:  DraggableProvided
    snapshot:  DraggableStateSnapshot
    column: ColumnState,
    handlePinColumn: (column: ColumnState) => void,
    handleHideColumn: (column: ColumnState) => void
    label: string | undefined,

}


const TableColumnListItem: React.FC<Props> = ({
    column,
    handleHideColumn,
    handlePinColumn,
    label,
    provided,
    snapshot
                                              }) => {


    return <TableColumnItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
        >
                {!snapshot.isDragging && (
                    <TableColumnsPinButton
                        style={{
                            pointerEvents: !column.hide ? 'all' : 'none',
                            visibility: !column.hide ? 'visible' : 'hidden'
                        }}
                        className={column.pinned === 'left' ? "pin" : "unpin"}
                        onClick={() => handlePinColumn(column)}>
                        <i className={column.pinned === 'left' ? "ico-Unpin" : "ico-Pin" }/>
                    </TableColumnsPinButton>
                )}

                <TableColumnItemCheckboxWrapper isDragging={snapshot.isDragging}>

                    <Checkbox
                        wrapperClassName={"checkbox-filter-wrapper"}
                        checked={!column.hide}
                        label={label}
                        onChange={() => handleHideColumn(column)}
                    />
                    <i className={'ico-Hamburger-Menu'}/>
                </TableColumnItemCheckboxWrapper>
    </TableColumnItem>

}

export default TableColumnListItem