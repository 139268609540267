import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IFilterHookReturn} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";

export const useInvoicingCycleFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<any> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<any[]>([])
    const [invoicingCycles, setInvoicingCycles] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [notDisplayed, setNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id ='invoicing-cycle'

    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<any[]>(
                'intacct-invoicing-cycle',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            
            setLoading(false)
            setInvoicingCycles(data)
            setFetchedData(data)
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredInvoicingCycles = fetchedData.filter(manager => (
                manager.toLowerCase()).includes(searchValue.toLowerCase())
        )

        if(displayCount){
            const displayedCycles = [...filteredInvoicingCycles].slice(0, displayCount)
            if(filteredInvoicingCycles.length > displayCount){
                setNotDisplayed(filteredInvoicingCycles.length - displayCount)
            } else {
                setNotDisplayed(0)
            }
            setInvoicingCycles(displayedCycles)
        } else {
            setInvoicingCycles(
                filteredInvoicingCycles
            )
        }
    }, [searchValue, fetchedData, displayCount])

    const invoicingCycleFilter = useFilterSelector<any>(pageId, id)

    const setSelected = (cycle: any) => {
        if(invoicingCycleFilter.selected.some((filterCycle)  => filterCycle === cycle)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: invoicingCycleFilter.exclude,
                    selected: invoicingCycleFilter.selected.filter(filterCycle => filterCycle !== cycle)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: invoicingCycleFilter.exclude,
                    selected: [...invoicingCycleFilter.selected, cycle]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: invoicingCycleFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: invoicingCycles,
        setSearchValue: setSearchValue,
        notDisplayed: notDisplayed,
        selectedData: invoicingCycleFilter.selected,
        exclude: invoicingCycleFilter.exclude,
        setSelectedData: setSelected,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}

