import React from 'react'
import {DomainInput} from "./DomainInput";
import {DomainIdParameterInput} from "./DomainIdParameterInput";

export const DomainSection: React.FC = () => {
    return <>
        <DomainInput/>
        <div style={{
            marginTop: '32px'
        }}>
            <DomainIdParameterInput/>
        </div>
    </>
}