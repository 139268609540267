import styled from "styled-components";

export const RecentInvoicesWrapper = styled.div`
    .above-text {
        padding-bottom: 8px;
        border-bottom: 1px solid #E7ECEE;
        margin-bottom: 8px;
    }

    .invoice-states {
        .heading {
            font-family: "Avenir LT W05_85 Heavy", sans-serif;
            margin-bottom: 10px;
        }

        .invoices{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .invoice {
                display: flex;
                gap: 8px;
                .box {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                }

                .text {

                }
        }
        }
    }
`;