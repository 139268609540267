import {Column, ColumnApi, ColumnState, Grid, GridApi} from "ag-grid-community";
import TableColumnListItem from "./TableColumnListItem/TableColumnListItem";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector, useOnClickOutside} from "../../../../hooks";
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

import {
    TableColumnItem,
    TableColumns,
    TableColumnsButton,
    TableColumnsListDropdown,
    TableColumnsSearch,
    TableColumnsListBody,
    TableColumnsListFooter
} from "./TableColumnsList.style";
import {InputStyled} from "../../../../styled-components";
import {IPagePresetsID, saveUserTablePreferences} from "../../../../../store/userPreferences.slice";

interface Props {
    gridColumnApi: ColumnApi,
    id?: IPagePresetsID,
    gridApi : GridApi
}

const TableColumnsList: React.FC<Props> = ({gridColumnApi, id,gridApi}) => {
    const userPreferences = useAppSelector(state => state.userPreferences)
    const dispatch = useAppDispatch();
    const columnDropdownSelectRef = useRef<HTMLDivElement | null>(null)
    const [tableColumnsListOpened, setTableColumnsListOpened] = useState<boolean>(false);
    useOnClickOutside(columnDropdownSelectRef, () => {
        setTableColumnsListOpened(false);
    });

    const [colStateCopy, setColStateCopy] = useState<ColumnState[] | undefined>([])
    const [colStateFullList, setColStateFullList] = useState<ColumnState[] | undefined>([])

    const [search, setSearch] = useState<string>("")
    const searchRef = useRef(null)

    const orderColumns = (columns: ColumnState[]): ColumnState[] => {
        const newColState = [] as ColumnState[]
        if(columns){
            columns.forEach(column => {
                if(column.pinned === 'left') {
                    newColState.push(column)
                }
            })
            columns.forEach(column => {
                if(column.pinned !== 'left') {
                    newColState.push(column)
                }
            })
        }
        return newColState
    }

    useEffect(() => {
        if(id && userPreferences[id]){
            setColStateCopy([...orderColumns(userPreferences[id].table)])
            setColStateFullList([...orderColumns(userPreferences[id].table)])
        }

    }, [userPreferences, id ])

    /**
     * search
     */
    useEffect(()=> {
        const filteredList : ColumnState[] | undefined= colStateFullList?.
        filter((item : ColumnState) =>
            item.colId !== 'check' && item.colId !== 'option').
        filter((item : ColumnState) => {
            let temp : Column | null = gridColumnApi.getColumn(item.colId)
            return temp?.getColDef().headerName?.toLowerCase().includes(search.toLowerCase())
        })
        setColStateCopy(filteredList)
    }, [search, colStateFullList, gridColumnApi])

    const handleHideColumn = (column: ColumnState) => {
        gridApi &&gridApi.hideOverlay()
        if (gridColumnApi?.getAllDisplayedColumns().length === 0){
            gridColumnApi.setColumnsVisible(['check', 'option'], true)
            gridApi &&gridApi.hideOverlay()
        }
        if (id && userPreferences[id].table) {
            const colIndex = userPreferences[id].table.findIndex(col => col.colId === column.colId);

            if (column.colId && gridColumnApi) {
                if (!userPreferences[id].table[colIndex].hide) {
                    gridColumnApi.setColumnVisible(column.colId, false)
                } else {
                    gridColumnApi.setColumnVisible(column.colId, true)
                }
                dispatch(saveUserTablePreferences({pageId: id, data: gridColumnApi.getColumnState()}))
            }
        }
        if (gridColumnApi?.getAllDisplayedColumns().filter(item =>
                item.getColId() !== 'check' && item.getColId() !== 'option').length === 0) {
            gridColumnApi.setColumnsVisible(['check', 'option'], false)
            gridApi && gridApi.showLoadingOverlay()
        }
    }

    const handlePinColumn = (column: ColumnState) => {
        if (id) {
            if (column.pinned === "left") {
                gridColumnApi && gridColumnApi.applyColumnState({
                    state: [{
                        colId: column.colId,
                        pinned: false
                    }]
                })
            } else {
                gridColumnApi && gridColumnApi.applyColumnState({
                    state: [{
                        colId: column.colId,
                        pinned: 'left'
                    }]
                })
            }
            gridColumnApi && dispatch(saveUserTablePreferences({pageId: id, data: gridColumnApi.getColumnState()}))
        }
    }

    const handleReset = ()=> {
       if ( id && gridColumnApi ){
           gridColumnApi.resetColumnState()
            dispatch(saveUserTablePreferences({pageId: id, data: gridColumnApi.getColumnState()}))
        }
    }

    const handleDisable = () => {
        if ( id && gridColumnApi ){
           gridColumnApi.setColumnsVisible(gridColumnApi.getAllColumns() as Column[], false)
            dispatch(saveUserTablePreferences({pageId: id, data: gridColumnApi.getColumnState()}))
            if (gridColumnApi.getAllDisplayedColumns().length === 0){
                gridApi && gridApi.showLoadingOverlay()
            }
        }
    }
    const handleEnable = () => {
        if ( id && gridColumnApi ){
            gridColumnApi.setColumnsVisible(gridColumnApi.getAllColumns() as Column[], true)
            dispatch(saveUserTablePreferences({pageId: id, data: gridColumnApi.getColumnState()}))
        }
        gridApi &&gridApi.hideOverlay()
    }

    //Beautiful DnD
    const onDragEnd = (result: DropResult) => {

        if(!result.destination){
            return
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        if(colStateFullList) {
            let newItems = [...colStateFullList];
            const removed = {...newItems.splice(result.source.index, 1)[0]};
            if(newItems[result.destination.index].pinned === 'left'){
                removed.pinned = 'left'
            } else {
                removed.pinned = null
            }
            newItems.splice(result.destination.index, 0, removed);
            gridColumnApi.applyColumnState({state: newItems})
            newItems && gridColumnApi.moveColumns(newItems.map(column => column.colId) as string[], 0)
            id && dispatch(saveUserTablePreferences({pageId: id, data: [...newItems]}))

            setColStateCopy([...newItems]);
            setColStateFullList([...newItems]);
        }

    }


    return (
        <TableColumns ref={columnDropdownSelectRef}>
            <TableColumnsButton className={tableColumnsListOpened ? "active" : ""}
                                onClick={() => {
                                    setTableColumnsListOpened(!tableColumnsListOpened)}}>
                <i className="ico-Columns"/>
            </TableColumnsButton>
                {tableColumnsListOpened &&
                <TableColumnsListDropdown>
                    <TableColumnsSearch>
                        <InputStyled autoFocus={true}
                                     ref={searchRef}
                                     value={search}
                                     onChange={(event)=> setSearch(event.target.value)}
                                     placeholder={"Search"}/>
                    </TableColumnsSearch>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={'droppable'}>
                            {provided => (
                                <TableColumnsListBody ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        (colStateCopy && colStateFullList) && colStateCopy.map((col, index) => {
                                            if (col.colId === 'check' || col.colId === 'option') return ''
                                            return (
                                                <Draggable draggableId={col.colId as string}
                                                           index={colStateFullList?.findIndex((item) => item.colId === col.colId)}
                                                           key={col.colId}>
                                                    {(provided, snapshot) => (
                                                            <TableColumnListItem
                                                                provided={provided}
                                                                snapshot={snapshot}
                                                                // key={index}
                                                                column={col}
                                                                handleHideColumn={handleHideColumn}
                                                                handlePinColumn={handlePinColumn}
                                                                label={gridColumnApi.getColumn(col.colId)?.getColDef().headerName}
                                                            />
                                                    )}

                                                </Draggable>
                                            )
                                        })

                                    }
                                    {provided.placeholder}
                                </TableColumnsListBody>
                            )}

                        </Droppable>
                    </DragDropContext>
                    <TableColumnsListFooter>
                        <button onClick={handleReset}>
                            Reset
                        </button>
                        <button onClick={handleDisable}>
                            Disable All
                        </button>
                        <button onClick={handleEnable}>
                            Enable All
                        </button>
                    </TableColumnsListFooter>
                </TableColumnsListDropdown>

                }

        </TableColumns>
    )
}

export default TableColumnsList