import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";

const allOptions: any[] = [
    {name: 'Enabled', id: true},
    {name: 'Disabled', id: false}
];

export const useAutoFundingFilter = (pageId: PageID): IFilterHookReturn<any> => {
    const dispatch = useAppDispatch()

    const id = 'auto-funding'

    const autoFundingFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (status: any) => {
        // if(autoFundingFilter.selected.some((stat)  => stat.id === status.id)){
        //     return;
        // } else {
        //     dispatch(setFilter<any>({
        //         pageID: pageId,
        //         filter: {
        //             id,
        //             exclude: autoFundingFilter.exclude,
        //             selected: [status]
        //         }

        //     }))
        // }
        if(autoFundingFilter.selected.some((funding)  => funding.id === status.id)){
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: autoFundingFilter.exclude,
                    selected: autoFundingFilter.selected.filter(funding => funding.id !== status.id)
                }
            }))
        } else {
            dispatch(setFilter<any>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: autoFundingFilter.exclude,
                    selected: [...autoFundingFilter.selected, status]
                }

            }))
        }
    }

    // const setExclude = (exclude: boolean) => {
    //     dispatch(setFilter<any>({
    //         pageID: pageId,
    //         filter: {
    //             id,
    //             exclude: exclude,
    //             selected: autoFundingFilter.selected
    //         }
    //     }))
    // }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allOptions,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: autoFundingFilter.selected,
        exclude: autoFundingFilter.exclude,
        setSelectedData: setSelectedData,
        // setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}