import { allMarketersPaginationColumns, allPartnersPaginationColumns, allOffersPaginationColumns, allAccountManagersPaginationColumns } from './top-performers/topPerformersLists';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IFetchAllAccountManagersResponse} from "./NetworkView.service";
import {allPaginationColumnsList} from "./marketer-funding-monitoring/table/paginationColumnsList";

type TFilterType = 'none' | 'account-manager' | 'sales-executive' | 'partner-account-manager'

type TTopPerformersAnalyzeBy = 'advertisers' | 'offers' | 'affiliates' | 'top_marketer_acc_managers' | 'top_partner_acc_managers'
type TPaginationColumnsList = {
    name: string,
    id: number,
    checked: boolean,
    column_key?: string
}[]

type TPagination = {
    from: number,
    to: number,
    total: number,
    last_page: number
}
export interface INetworkViewInitialState {
    topPerformersAnalyzeBy: TTopPerformersAnalyzeBy,
    marketersPaginationColumnsList:  TPaginationColumnsList,
    marketersPerPage: number,
    marketersCurrentPage: number,
    marketersSort: {
        direction: "asc" | "desc",
        by: string
    }
    partnersPaginationColumnsList:  TPaginationColumnsList,
    partnersPerPage: number,
    partnersCurrentPage: number,
    partnersSort: {
        direction: "asc" | "desc",
        by: string
    }
    offersPaginationColumnsList:  TPaginationColumnsList,
    offersPerPage: number,
    offersCurrentPage: number,
    offersSort: {
        direction: "asc" | "desc",
        by: string
    }
    accountManagersMarketersPaginationColumnsList:  TPaginationColumnsList,
    accountManagersMarketersPerPage: number,
    accountManagersMarketersCurrentPage: number,
    accountManagersMarketersSort: {
        direction: "asc" | "desc",
        by: string
    }
    accountManagersPartnersPaginationColumnsList:  TPaginationColumnsList,
    accountManagersPartnersPerPage: number,
    accountManagersPartnersCurrentPage: number,
    accountManagersPartnersSort: {
        direction: "asc" | "desc",
        by: string
    }
    pagination: TPagination,
    topPerformersLoading: boolean,
    topPerformersTableData: any[],
    topPerformersTableSum: any[],
    filterType: TFilterType,
    selectedManagers: IFetchAllAccountManagersResponse[],
    includeOrExclude: 'incl' | 'excl'

    fundingMonitoringSort: {
        direction: 'desc' | 'asc',
        by: string
    },
    fundingMonitoringPagination: {
        page: number,
        per_page: number,
        from: number,
        to: number,
        last_page: number,
        total: number
    },
    fundingMonitoringPaginationColumnsList: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[],
    pendingPartnerRegistrationsDays : number
}

const initialState: INetworkViewInitialState = {
    topPerformersAnalyzeBy: "advertisers",
    marketersPaginationColumnsList: allMarketersPaginationColumns,
    marketersPerPage: 5,
    marketersCurrentPage: 1,
    marketersSort: {
        direction: "desc",
        by: "revenue"
    },
    partnersPaginationColumnsList: allPartnersPaginationColumns,
    partnersPerPage: 5,
    partnersCurrentPage: 1,
    partnersSort: {
        direction: "desc",
        by: "profit"
    },
    offersPaginationColumnsList: allOffersPaginationColumns,
    offersPerPage: 5,
    offersCurrentPage: 1,
    offersSort: {
        direction: "desc",
        by: "revenue"
    },
    accountManagersMarketersPaginationColumnsList: allAccountManagersPaginationColumns,
    accountManagersMarketersPerPage: 5,
    accountManagersMarketersCurrentPage: 1,
    accountManagersMarketersSort: {
        direction: "desc",
        by: "clicks"
    },
    accountManagersPartnersPaginationColumnsList: allAccountManagersPaginationColumns,
    accountManagersPartnersPerPage: 5,
    accountManagersPartnersCurrentPage: 1,
    accountManagersPartnersSort: {
        direction: "desc",
        by: "clicks"
    },
    pagination: {
        from: 1,
        to: 1,
        total: 1,
        last_page: 1
    },
    topPerformersTableData: [],
    topPerformersTableSum: [],
    topPerformersLoading: false,
    filterType: 'none',
    selectedManagers: [],
    includeOrExclude: 'incl',

    fundingMonitoringSort: {
        direction: "desc",
        by: 'pause_risk'
    },
    fundingMonitoringPagination: {
        page: 1,
        per_page: 10,
        from: 1,
        to: 1,
        last_page: 1,
        total: 1
    },
    fundingMonitoringPaginationColumnsList: allPaginationColumnsList,
    pendingPartnerRegistrationsDays: 7
}

const networkViewSlice = createSlice({
    name: 'networkViewSlice',
    initialState,
    reducers: {
        setTopPerformersAnalyzeBy: (state: INetworkViewInitialState, action: PayloadAction<TTopPerformersAnalyzeBy>) => {
            state.topPerformersAnalyzeBy = action.payload; 
        },
        setMarketersPaginationColumnsList: (state: INetworkViewInitialState, action: PayloadAction<TPaginationColumnsList>) => {
            state.marketersPaginationColumnsList = action.payload; 
        },
        setMarketersPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.marketersPerPage = action.payload;
        },
        setMarketersCurrentPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {            
            state.marketersCurrentPage = action.payload;
        },
        setMarketersSortDirection: (state: INetworkViewInitialState, action:PayloadAction<{direction: "asc" | "desc", by: string}>) => {
            state.marketersSort = action.payload;
        },
        setPartnersPaginationColumnsList: (state: INetworkViewInitialState, action: PayloadAction<TPaginationColumnsList>) => {
            state.partnersPaginationColumnsList = action.payload; 
        },
        setPartnersPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.partnersPerPage = action.payload;
        },
        setPartnersCurrentPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.partnersCurrentPage = action.payload;
        },
        setPartnersSortDirection: (state: INetworkViewInitialState, action:PayloadAction<{direction: "asc" | "desc", by: string}>) => {
            state.partnersSort = action.payload;
        },
        setOffersPaginationColumnsList: (state: INetworkViewInitialState, action: PayloadAction<TPaginationColumnsList>) => {
            state.offersPaginationColumnsList = action.payload; 
        },
        setOffersPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.offersPerPage = action.payload;
        },
        setOffersCurrentPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {            
            state.offersCurrentPage = action.payload;
        },
        setOffersSortDirection: (state: INetworkViewInitialState, action:PayloadAction<{direction: "asc" | "desc", by: string}>) => {
            state.offersSort = action.payload;
        },
        setAccountManagersMarketersPaginationColumnsList: (state: INetworkViewInitialState, action: PayloadAction<TPaginationColumnsList>) => {
            state.accountManagersMarketersPaginationColumnsList = action.payload; 
        },
        setAccountManagersMarketersPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.accountManagersMarketersPerPage = action.payload;
        },
        setAccountManagersMarketersCurrentPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {            
            state.accountManagersMarketersCurrentPage = action.payload;
        },
        setAccountManagersMarketersSortDirection: (state: INetworkViewInitialState, action:PayloadAction<{direction: "asc" | "desc", by: string}>) => {
            state.accountManagersMarketersSort = action.payload;
        },
        setAccountManagersPartnersPaginationColumnsList: (state: INetworkViewInitialState, action: PayloadAction<TPaginationColumnsList>) => {
            state.accountManagersPartnersPaginationColumnsList = action.payload; 
        },
        setAccountManagersPartnersPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.accountManagersPartnersPerPage = action.payload;
        },
        setAccountManagersPartnersCurrentPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {            
            state.accountManagersPartnersCurrentPage = action.payload;
        },
        setAccountManagersPartnersSortDirection: (state: INetworkViewInitialState, action:PayloadAction<{direction: "asc" | "desc", by: string}>) => {
            state.accountManagersPartnersSort = action.payload;
        },
        setPagination: (state: INetworkViewInitialState, action:PayloadAction<TPagination>) => {
            state.pagination = action.payload;
        },
        setTopPerformersLoading: (state: INetworkViewInitialState, action:PayloadAction<boolean>) => {
            state.topPerformersLoading = action.payload;
        },
        setTopPerformersTableData: (state: INetworkViewInitialState, action:PayloadAction<any[]>) => {
            state.topPerformersTableData = action.payload;
        },
        setTopPerformersTableSum: (state: INetworkViewInitialState, action:PayloadAction<any[]>) => {
            state.topPerformersTableSum = action.payload;
        },
        setFilterType: (state: INetworkViewInitialState, action: PayloadAction<TFilterType>) => {
            state.filterType = action.payload
        },
        setIncludeExclude: (state: INetworkViewInitialState, action: PayloadAction<'incl' | 'excl'>) => {
            state.includeOrExclude = action.payload
        },
        addOrRemoveFilterManager: (state: INetworkViewInitialState, action: PayloadAction<IFetchAllAccountManagersResponse>) => {
            if(state.selectedManagers.some(manager => manager.id === action.payload.id)){
                state.selectedManagers = state.selectedManagers.filter(manager => manager.id !== action.payload.id)
            } else {
                state.selectedManagers.push(action.payload)
            }
        },
        clearSelectedManagers: (state: INetworkViewInitialState) => {
            state.selectedManagers = []
        },

        setFundingMonitoringSort: (state: INetworkViewInitialState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {
            state.fundingMonitoringSort = action.payload
        },
        setFundingMonitoringPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.fundingMonitoringPagination.page = action.payload;
        },
        setFundingMonitoringPerPage: (state: INetworkViewInitialState, action:PayloadAction<number>) => {
            state.fundingMonitoringPagination.per_page = action.payload;
        },
        setFundingMonitoringPagination: (state: INetworkViewInitialState, action:PayloadAction<{
            from: number,
            to: number,
            last_page: number,
            total: number
        }>) => {
            state.fundingMonitoringPagination.from = action.payload.from;
            state.fundingMonitoringPagination.to = action.payload.to;
            state.fundingMonitoringPagination.last_page = action.payload.last_page;
            state.fundingMonitoringPagination.total = action.payload.total
        },
        setFundingMonitoringPaginationColumnsList: (state: INetworkViewInitialState, action:PayloadAction<{name: string,
            id: number,
            checked: boolean,
            column_key?: string}[]>) => {
            state.fundingMonitoringPaginationColumnsList = action.payload;
        },
        setPendingPartnerRegistrationsDays: ( state : INetworkViewInitialState, action: PayloadAction<number>) =>{
            state.pendingPartnerRegistrationsDays = action.payload
        }
    }
})

export const {
    setTopPerformersAnalyzeBy,
    setMarketersPaginationColumnsList,
    setMarketersPerPage,
    setMarketersCurrentPage,
    setMarketersSortDirection,
    setPartnersPaginationColumnsList,
    setPartnersPerPage,
    setPartnersCurrentPage,
    setPartnersSortDirection,
    setOffersPaginationColumnsList,
    setOffersPerPage,
    setOffersCurrentPage,
    setOffersSortDirection,
    setAccountManagersMarketersPaginationColumnsList,
    setAccountManagersMarketersPerPage,
    setAccountManagersMarketersCurrentPage,
    setAccountManagersMarketersSortDirection,
    setAccountManagersPartnersPaginationColumnsList,
    setAccountManagersPartnersPerPage,
    setAccountManagersPartnersCurrentPage,
    setAccountManagersPartnersSortDirection,
    setPagination,
    setTopPerformersLoading,
    setTopPerformersTableData,
    setTopPerformersTableSum,
    setFilterType, 
    setIncludeExclude, 
    addOrRemoveFilterManager, 
    clearSelectedManagers, 
    setFundingMonitoringPage,
    setFundingMonitoringPagination,
    setFundingMonitoringPaginationColumnsList,
    setFundingMonitoringPerPage,
    setFundingMonitoringSort,
    setPendingPartnerRegistrationsDays
} = networkViewSlice.actions



export default networkViewSlice.reducer
