import React from 'react'

import {TNicheName} from "../../NetworkView.service";

import {Wrapper} from "./LegendItem.style";
import {formatNumberToCurrency} from "../../../../../../../common/utils";

interface Props {
    name: TNicheName,
    value: number,
    sum: number,
    boxColor: string
}

const LegendItem: React.FC<Props> = ({name, value, sum, boxColor}) => {

    return <Wrapper $type={name} $value={value}>
        <div className={'box'}/>
        <div>
            <div className="legend-name">{name}</div>
            <div className={'legend-value'} style={{color: value === 0 ? '#8799A1' : boxColor}}>{formatNumberToCurrency(value)}</div>
            <div className="legend-percentage">{value === 0 ? '0.00' : Math.round(((value/sum) * 100) * 100)  / 100}%</div>
        </div>
    </Wrapper>

}

export default LegendItem