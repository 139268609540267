import React from "react";
import {Wrapper} from "./RemoveTagsModal.style";
import {Button, Cancel, DeleteFooter} from "../add-tags-modal/AddTagsModal.style";


interface IProps {
    content: string
    removeTags : () => void,
    fetchData?: () => void,
    closeModal: () => void,
    className?: string
}

const RemoveTagsModal: React.FC<IProps> = ({content, removeTags, fetchData, closeModal,className}) => {
    const remove = () => {
        removeTags()
        closeModal()
    }
    return (
        <Wrapper>
            <p className={className}>{content}</p>
            <DeleteFooter>
                <Cancel onClick={closeModal}>Cancel</Cancel>
                <Button onClick={remove}>Yes, remove tags</Button>
            </DeleteFooter>
        </Wrapper>
    )
}

export default RemoveTagsModal