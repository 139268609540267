import styled from 'styled-components'

interface INoDataOverlayProps {
    $noDataOpacity?: number | undefined
}

export const NoDataOverlay = styled.div<INoDataOverlayProps>`
    background: ${({$noDataOpacity}) => `rgba(255,255,255, ${$noDataOpacity ? $noDataOpacity : 1})`};
    width: 100%;
    height: 100%;
    z-index: 11;
    text-align: center;
    margin: 1px auto auto;
    padding-top: 27px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;
