import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 550px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  padding-top: 20px;
  .affiliate-dropdown-container{
    display: none;
  }
  
  .amount-input-wrapper{
    margin: 0;
  }
`