import styled from "styled-components";
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";

export const Wrapper = styled.div`
    padding: 40px 40px 88px 40px;

    @media screen and (max-width: 897px) {
        padding: 40px 15px 88px 15px;
    }
`

export const FiltersPaginationSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 12px;
    }
`;


export const DeleteModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`

export const DeleteDomainModalBody = styled.div`
    min-width: 420px;
    max-width: 420px;

    p {
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`

export const CustomModalFooter = styled(ModalFooter)`
    justify-content: flex-end;
    border-top: none;
`
export const TopSection = styled.div`
    display: flex;
    gap: 8px;
    .actions-header {
        min-height: 32px;
        height: 32px;
    }

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 15px;

        > div:last-child {
            display: flex;
        }

        > button:last-child {
            display: flex;
        }
    }
`