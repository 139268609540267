import React, {useEffect, useState} from 'react'
import query from 'query-string'

import {Wrapper, FiltersPaginationSection, DeleteModalFooter, CustomModalFooter, DeleteDomainModalBody, TopSection} from "./PartnerPayments.style";
import Table from './table/Table'
import PartnerPaymentsService, {
    IFetchAffiliatesResponse,
    IFetchPartnerPaymentsParams,
    IFetchPartnerPaymentsResponse
} from "./PartnerPayment.service";
import {useAppDispatch, useAppSelector, useFilter} from "../../../../../common/hooks";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import {setPaginateBy} from "./PartnerPayments.slice";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import {ButtonStyled, BtnLoadingSpinner} from "../../../../../common/styled-components";
import Modal from "../../../../../common/components/modal/Modal";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import AddEditPaymentModal from "./add-edit-payment-modal/AddEditPaymentModal";
import DateRangeFilter from "./DateRangeFilter/DateRangeFilter";
import Filters from "./filters/Filters";
import {IAffiliatesFilter, IVendorIdFilter} from "filters";
import {format} from "date-fns";
import Icon from "../../../../../common/components/icon/Icon";


const targetOrigin = process.env.REACT_APP_API_BASE_URL

const PartnerPayments: React.FC = () => {
    const [tableData, setTableData] = useState<IFetchPartnerPaymentsResponse[]>([])
    const [loading, setLoading] = useState(false)
    const [loadingDeletePayment, setLoadingDeletePayment] = useState(false)
    const [selectedRows, setSelectedRows] = useState<IFetchPartnerPaymentsResponse[]>([])
    const [actionsOpened, setActionsOpened] = useState(false)
    const [affiliates, setAffiliates] = useState<IFetchAffiliatesResponse[]>([])
    const [discoverResultDisplayed, setDiscoverResultDisplayed] = useState(false)
    const [addEditPaymentModal, setAddEditPaymentModal] = useState<{
        opened: boolean,
        data: null | IFetchPartnerPaymentsResponse
    }>({
        opened: false,
        data: null
    })

    const [deleteSelectedModal, setDeleteSelectedModal] = useState(false)
    const [alertModal, setAlertModal] = useState<{
        message: string,
        type: 'error' | 'success',
        opened: boolean
    }>({
        message: '',
        type: 'success',
        opened: false
    })

    //Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [total, setTotal] = useState(0)
    const [lastPage, setLastPage] = useState(0)

    const {token} = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const {sort_by, sort_direction, paginate_by, date_from, date_to} = useAppSelector(state => state.partnerPayment)

    const filter = useFilter('admin/billing/partner-payments')

    const affiliateFilter = filter<IAffiliatesFilter>('affiliate')
    const vendorFilter = filter<IVendorIdFilter>('vendor_id')

    useEffect(()=>{
            setDiscoverResultDisplayed(true)
        },[
            affiliateFilter,
            vendorFilter
        ]
    )

    const fetchPartnerPayments = async () => {
        setDiscoverResultDisplayed(false)
        const params: IFetchPartnerPaymentsParams = {
            sort_by: sort_by,
            order: sort_direction,
            page: currentPage,
            paginate_by: paginate_by,
            from: date_from ? format(new Date(date_from), 'yyyy-MM-dd') : new Date(),
            to: date_to ? format(new Date(date_to), 'yyyy-MM-dd') : new Date(),
        }

        if(vendorFilter && vendorFilter.selected.length > 0){
            params.vendor_id = vendorFilter.selected[0].vendor_id
        }

        if(affiliateFilter && affiliateFilter.selected.length > 0){
            params.affiliate_id = affiliateFilter.selected[0].id
        }

        setSelectedRows([])
        try {
            setLoading(true)
            const {data} = await PartnerPaymentsService.fetchTableData(token, params)
            setLoading(false)
            setTableData(data.data)
            setCurrentPage(data.current_page)
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLastPage(data.last_page)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchAffiliates = async () => {
        try{
            const {data} = await PartnerPaymentsService.fetchAffiliates(token)
            setAffiliates(data)
        } catch (e) {
            console.log(e)
        }
    }

    const deleteSelected = async () => {
        try {
            setLoadingDeletePayment(true)
            await PartnerPaymentsService.deleteSelected(token, selectedRows.map(row => row.payment_id))
            setDeleteSelectedModal(false)
            setLoadingDeletePayment(false)
            setAlertModal({
                message: 'Payments successfully deleted!',
                type: 'success',
                opened: true
            })
            fetchPartnerPayments()
        } catch (e) {
            setAlertModal({
                message: 'Something went wrong!',
                type: 'error',
                opened: true
            })
            console.log(e)
            setLoadingDeletePayment(false)
        }
    }

    const exportToCsv = () => {
        const params: IFetchPartnerPaymentsParams = {
            sort_by: sort_by,
            order: sort_direction,
            page: currentPage,
            paginate_by: paginate_by,
            from: date_from ? format(new Date(date_from), 'yyyy-MM-dd') : new Date(),
            to: date_to ? format(new Date(date_to), 'yyyy-MM-dd') : new Date(),
            action: 'export_csv'
        }

        if(vendorFilter && vendorFilter.selected.length > 0){
            params.vendor_id = vendorFilter.selected[0].vendor_id
        }

        if(affiliateFilter && affiliateFilter.selected.length > 0){
            params.affiliate_id = affiliateFilter.selected[0].id
        }

        const urlQuery = query.stringify(params)

        window.open(`${targetOrigin}/api/v1/users/partner_payments?${urlQuery}`, '_blank')
    }

    const handlePaginateBy = (perPage: number) => {
        dispatch(setPaginateBy(perPage))
    }

    const handleDeleteSelected = () => {
        setDeleteSelectedModal(true)
        setActionsOpened(false)
    }

    useEffect(() => {
        fetchPartnerPayments()
    }, [sort_direction, sort_by, paginate_by, currentPage])

    useEffect(() => {
        fetchAffiliates()
    }, [])

    const handleDiscoverResultsClick = () => {
        fetchPartnerPayments()
        setDiscoverResultDisplayed(false)
    }

    return <Wrapper>
        <TopSection>
            <DateRangeFilter fetchData={fetchPartnerPayments}/>
            <div>
                <Dropdown headerClassName={'actions-header'} disabled={selectedRows.length === 0} text={'Actions'} isOpened={actionsOpened} setOpened={setActionsOpened}>
                    <DropdownItem onClick={handleDeleteSelected} isDelete={true}>Delete selected</DropdownItem>
                </Dropdown>
            </div>
            {discoverResultDisplayed && <ButtonStyled onClick={handleDiscoverResultsClick} className={'btn-discover-results'}>Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/></ButtonStyled>}
        </TopSection>
        <FiltersPaginationSection>
            <Filters setDiscoverDisplayed={setDiscoverResultDisplayed}/>
            <div></div>
            <div style={{display: 'flex', gap: '10px'}}>
                <Pagination
                    current_page={currentPage}
                    from={from} to={to}
                    total={total}
                    setCurrentPage={setCurrentPage}
                    setPerPage={handlePaginateBy}
                    last_page={lastPage}
                    perPage={paginate_by}
                    loading={loading}
                    perPageCustomList={[3, 50, 100, 250, 500]}
                    onRefresh={fetchPartnerPayments}
                    exportCsv={exportToCsv}
                />
                <ButtonStyled className={'btn-blue-filled'} onClick={() => setAddEditPaymentModal({opened: true, data: null})}>Add Payment</ButtonStyled>
            </div>
        </FiltersPaginationSection>
        <Table
            data={tableData}
            loading={loading}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            fetchData={fetchPartnerPayments}
            setEditModal={setAddEditPaymentModal}
        />
        <Modal opened={deleteSelectedModal} title={'Delete selected Payments?'} closeModal={() => setDeleteSelectedModal(false)}>
            <DeleteDomainModalBody>
                <p>Are you sure you want to delete selected payments?</p>
            </DeleteDomainModalBody>
            <CustomModalFooter>
                <ButtonStyled
                    className={'btn-cancel'}
                    onClick={() => setDeleteSelectedModal(false)}>
                    Cancel
                </ButtonStyled>
                <ButtonStyled
                    disabled={loadingDeletePayment}
                    className={'btn-red-filled'}
                    onClick={deleteSelected}
                    style={{marginLeft: 30}}>
                    {loadingDeletePayment && <BtnLoadingSpinner style={{marginRight: '10px'}}/>}
                    Yes, delete payments.
                </ButtonStyled>
            </CustomModalFooter>
        </Modal>
        <AlertModal isOpen={alertModal.opened} type={alertModal.type} closeModal={() => setAlertModal({...alertModal, opened: false})}>
            {alertModal.message}
        </AlertModal>
        <Modal opened={addEditPaymentModal.opened} closeModal={() => setAddEditPaymentModal({data: null, opened: false})} title={addEditPaymentModal.data ? 'Edit Payment' : 'Add Payment'}>
            <AddEditPaymentModal editModal={setAddEditPaymentModal} alertModal={setAlertModal} fetchData={fetchPartnerPayments} affiliates={affiliates} payment={addEditPaymentModal.data}/>
        </Modal>
    </Wrapper>
}

export default PartnerPayments