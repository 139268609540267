import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {DeleteDropdownItem, DomainUrl, Wrapper} from "./MarketerCustomDomain.style"
import FiltersRow from "../../../../../common/components/filters/filters-row/FiltersRow";
import {FiltersRowLeft, FiltersRowRight} from "../../../../../common/components/filters/filters-row/FiltersRow.style";
import FiltersButton from "../../../../../common/components/filters/filters-button/FiltersButton";
import Search from "../../../../../common/components/search/Search";
import Pagination from "../../../../../common/components/table/pagination/Pagination";
import UiFilter from "../../../../../common/components/filters/filters-ui/UiFilter";
import Filters from "../../../../../common/components/filters/filters-container/Filters";
import FiltersListItem from "../../../../../common/components/filters/filters-list-item/FiltersListItem";
import SelectDropdown from "../../../../../common/components/select-dropdown/SelectDropdown";
import {useAppSelector} from "../../../../../common/hooks";
import {tokenStore} from "../../../../../store/auth.slice";
import GridRowOptions from "../../../../../common/components/grid/grid-row-options/GridRowOptions";
import {customDomainStatuses, ICustomDomainStatuses} from "../../../../../common/components/filters/statuses";
import Grid from "../../../../../common/components/grid/Grid";
import {
    IAddCustomDomainResponse,
    ICustomDomainsDataParams,
    IMarketer,
    IMarketerDomain,
    MarketerCustomDomainService
} from "./MarketerCustomDomain.service";
import {ButtonStyled} from "../../../../../common/styled-components";
import DeleteDomainModal from "./delete-domain-modal/DeleteDomainModal";
import AddDomainModal from "./add-domain-modal/AddDomainModal";
import PreviewDomainModal from "./preview-domain-modal/PreviewDomainModal";
import SelectionPills from "../../../../../common/components/selection-pills/SelectionPills";
import {SelectionPillsContainer} from "../CustomDomain.style";


interface IModal {
    opened: boolean,
    data: any
}

interface IFeedbackModal {
    opened: boolean;
    type: 'success' | 'error' | 'info',
    message: string | ReactElement
}


const MarketerCustomDomain: React.FC = () => {
    const token = useAppSelector(tokenStore);

    // Pagination
    const perPageCustomList: number[] = [5, 10, 20, 50];
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [lastPage, setLastPage] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMarketers, setLoadingMarketers] = useState<boolean>(false);

    // Filters
    const [openFilters, setOpenFilters] = useState(false);
    const [openMarketers, setOpenMarketers] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [marketerSelection, setMarketerSelection] = useState<IMarketer[]>([]);
    const [selectedMarketers, setSelectedMarketers] = useState<IMarketer[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<ICustomDomainStatuses[]>([customDomainStatuses[0]]);
    const [selectedFilters, setSelectedFilter] = useState<string[]>(["all",""])

    // Table data start
    const [marketerDomains, setMarketerDomains] = useState<IMarketerDomain[]>([{
        domain: "",
        user_id: 0,
        status: "",
        default: 0,
        name_servers: "",
        id: 0,
        user_name: "",
        user_type: ""
    }]);

    const defaultColumnDefs = useMemo(() => ({
        resizable: true,
        minWidth: 50
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "domain", headerName: "DOMAIN", width: 300,
            cellRenderer: ({data}: { data: IMarketerDomain }) => {
                return <DomainUrl onClick={() => setPreviewDomainModal({opened: true, data: data})}>{data.domain}</DomainUrl>
            }
        },
        {
            field: "status", headerName: "STATUS", width: 200,
            cellRenderer: ({data}: { data: IMarketerDomain }) => {
                return <span>{data.status}</span>
            }
        },
        {
            field: "user_name", headerName: "MARKETER NAME", width: 300,
            cellRenderer: ({data}: { data: IMarketerDomain }) => {
                return <span>{data.user_name}</span>
            }
        },
        {
            field: "user_id", headerName: "MARKETER ID", width: 300,
            cellRenderer: ({data}: { data: IMarketerDomain }) => {
                return <span>{data.user_id}</span>
            }
        },
        {
            pinned: 'right',
            lockVisible: true,
            width: 50,
            suppressMovable: true,
            resizable: false,
            cellRenderer: ({data}: { data: IMarketerDomain }) => {
                return (
                    <GridRowOptions>
                        <DeleteDropdownItem
                            onClick={() => setDeleteModal({opened: true, data: data})}
                            className={`${data.default === 1 ? "disabled" : ""}`}>
                            Delete Domain
                        </DeleteDropdownItem>
                    </GridRowOptions>
                )
            },
        }
    ], []);
    // Table data end


    // Delete Modal start
    const [deleteModal, setDeleteModal] = useState<IModal>({
        opened: false,
        data: ""
    });

    const [deleteSuccessAlert, setDeleteSuccessAlert] = useState<IFeedbackModal>({
        opened: false,
        type: 'success',
        message: ''
    });

    const deleteDomain = async (domainId: number, marketerID: number) => {
        setLoading(true)
        setDeleteModal({opened: false, data: ""})
        try {
            await MarketerCustomDomainService.deleteDomain(token, domainId, marketerID)
            setDeleteSuccessAlert({opened: true, type: 'success', message: 'The domain has been deleted.'})
        } catch (err) {
            console.log(err)
            setDeleteSuccessAlert({opened: true, type: 'error', message: 'Something went wrong'})
        }
        await fetchMarketerDomainTableData();
    }
    // Delete Modal end

    // Add domain start
    const [addDomainModal, setAddDomainModal] = useState<{opened: boolean}>({
        opened: false,
    });

    const [addDomainAlert, setAddDomainAlert] = useState<IFeedbackModal>({
        opened: false,
        type: 'info',
        message: ''
    });

    const [domain, setDomain] = useState<string>("");
    const REGEX_DOMAIN_VALIDATION = /^(www\.)?[\w-]{1,63}((\.[\w-]{1,63}){1,3})?\.[a-zA-Z]{2,}$/;
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [domainDetails, setDomainDetails] = useState<IAddCustomDomainResponse>();

    const AddDomainAlertBody = () => {
        return (
            <>
                Your new domain has been added. Please complete your DNS setup to make this domain verified and active.
                Reference our <a target={'_blank'} className={"link-orange"} href="https://support.performcb.com/support/solutions/articles/4000183700-change-nameservers-with-your-dns-provider-marketers">How To Guide </a> to learn more.
            </>
        )
    }

    const handleSubmitNewDomain = async (domainValue: string, marketerId: number) => {
        try {
            setSubmitLoading(true);
            const {data} = await MarketerCustomDomainService.addCustomDomain(token, domainValue, marketerId);
            setDomainDetails(data);
            setSubmitLoading(false);
        } catch (err) {
            console.log('Error add new domain', err)
        }
    }

    const handleSaveAddNewDomainModal = () => {
        setAddDomainModal({opened: false})
        setDomain('')
        setDomainDetails(undefined)
        setAddDomainAlert({
            opened: true,
            type: 'info',
            message: <AddDomainAlertBody/>
        })
        fetchMarketerDomainTableData();
    }
    const deleteNewlyDomain = async (id: number, marketerId: number) => {
        try {
            await MarketerCustomDomainService.deleteDomain(token, id, marketerId);
        } catch (err) {
            console.log(err);
        }
    }

    const handleCloseAddNewDomainModal = (id: number | undefined, marketerId: number) => {
        id && deleteNewlyDomain(id, marketerId);
        setDomain('');
        setDomainDetails(undefined);
        setAddDomainModal({opened: false});
    }
    const [previewDomainModal, setPreviewDomainModal] = useState<{ opened: boolean, data: IMarketerDomain | undefined }>({
        opened: false,
        data: undefined
    });

    const fetchMarketerDomainTableData = async () => {
        try {
            setLoading(true);
            const params: ICustomDomainsDataParams = {
                search,
                current_page_marketers: currentPage,
                paginate_by_marketers: perPage,
                user_type: 'advertiser'
            }
            if (currentPage !== 1) params.current_page_marketers = currentPage;
            if (perPage !== 50) params.paginate_by_marketers = perPage;
            if (selectedStatus[0].id !== customDomainStatuses[0].id) params.status = selectedStatus[0].name;

            let selectedIDs:number[] = [];
            selectedMarketers.forEach(marketer => {
                selectedIDs.push(marketer.id)
            })
            if (selectedMarketers.length >= 1) params.user_id= selectedIDs;


            let {data} = await MarketerCustomDomainService.fetchMarketerCustomDomains(token, params);
            setMarketerDomains(data.data);
            setFrom(data.from);
            setLastPage(data.last_page);
            setTo(data.to);
            setTotal(data.total);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }
    const fetchMarketers = async () => {
        setLoadingMarketers(true)
        const {data} = await MarketerCustomDomainService.fetchMarketers(token);
        setMarketerSelection(data);
        setLoadingMarketers(false)
    }

    const removeFilter = () => {
        setSelectedStatus([customDomainStatuses[0]])
    }

    const removeMarketer = (id: number) => {
        setSelectedMarketers([...selectedMarketers.filter(marketer => marketer.id !== id)])
    }

    const exportCsv = async () => {
        try{
            let params: {
                current_page_marketers?: string,
                paginate_by_marketers?: string,
                search?: string,
                status?: string,
                user_id?: string,
                export_csv?: string,
                user_type?: string,
            } = {
                search : search,
                current_page_marketers: currentPage.toString(),
                paginate_by_marketers: perPage.toString(),
                user_type: 'advertiser',
                export_csv: true.toString()
            }
            if (currentPage !== 1) params.current_page_marketers = currentPage.toString();
            if (perPage !== 50) params.paginate_by_marketers = perPage.toString();
            if (selectedStatus[0].id !== customDomainStatuses[0].id) params.status = selectedStatus[0].name;

            let selectedIDs:string[] = [];
            selectedMarketers.forEach(marketer => {
                selectedIDs.push(marketer.id.toString())
            })
            if (selectedMarketers.length >= 1) params.user_id= selectedIDs.toString();

            let url = new URLSearchParams(params);
            window.open(
                [process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V1_URL,
                    process.env.REACT_APP_API_DOMAINS].join('/') + '?' + url
            )
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (token) {
            fetchMarketerDomainTableData();
            let filters : string[] = []
            if(selectedStatus[0].id !== customDomainStatuses[0].id) {
                filters.push(selectedStatus[0].name)
            }
            setSelectedFilter(filters)
        }
    }, [token, currentPage, perPage, search, selectedStatus, selectedMarketers]);

    useEffect(() => {
        fetchMarketers();
    }, [])

    return (
        <Wrapper>
            <FiltersRow>
                <FiltersRowLeft>
                    <FiltersButton onClick={setOpenFilters} isOpen={openFilters}/>
                    <Search onChange={setSearch} initialValue="" placeholder="Search by Domain"/>
                </FiltersRowLeft>
                <FiltersRowRight>
                    <Pagination
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        setCurrentPage={setCurrentPage}
                        setPerPage={setPerPage}
                        last_page={lastPage}
                        perPage={perPage}
                        loading={loading}
                        perPageCustomList={perPageCustomList}
                        exportCsv={exportCsv}
                        onRefresh={fetchMarketerDomainTableData}/>
                    <ButtonStyled
                        className={'btn-blue-filled'}
                        onClick={() => setAddDomainModal({opened: true})}
                        style={{marginLeft: 20}}>
                        Add Domain
                    </ButtonStyled>
                </FiltersRowRight>
            </FiltersRow>
            {openFilters && <UiFilter uiFilterClassName={'domain-filter'} setOpenFilters={setOpenFilters}>
                <Filters>
                    <FiltersListItem>
                        <SelectDropdown
                            setOpen={setOpenMarketers}
                            setSelection={setSelectedMarketers}
                            selection={selectedMarketers}
                            open={openMarketers}
                            options={marketerSelection}
                            labelText={"Marketers"}
                            loading={loadingMarketers}
                            isSearchable={true}
                            isMultiselect={true}
                            hasClearSelectionButton={true}/>
                    </FiltersListItem>
                    <FiltersListItem>
                        <SelectDropdown
                            open={openStatus}
                            setOpen={setOpenStatus}
                            setSelection={setSelectedStatus}
                            selection={selectedStatus}
                            options={customDomainStatuses}
                            labelText={"Status"}
                            loading={loading}/>
                    </FiltersListItem>
                </Filters>
            </UiFilter>}

            <SelectionPillsContainer style={{margin: 0}}>
                { selectedMarketers.map(item =>
                    <SelectionPills key={item.id}
                                    text={<><label>Marketer:</label> {item.name + ' ' + item.lastname}</>}
                                    onRemove={() => removeMarketer(item.id)} />

                )}
                { selectedFilters.map(item =>
                    <SelectionPills key={item}
                                    text={<><label>Status:</label> {item}</>}
                                    onRemove={() => removeFilter()} />

                )}
            </SelectionPillsContainer>

            <Grid columnDefs={columnDefs}
                  defaultColDef={defaultColumnDefs}
                  rowData={marketerDomains}
                  rowHeight={40}
                  domLayout='autoHeight'
                  loading={loading}/>
            <AddDomainModal
                addDomainModal={addDomainModal}
                handleSaveAddNewDomainModal={handleSaveAddNewDomainModal}
                handleCloseAddNewDomainModal={handleCloseAddNewDomainModal}
                domainDetails={domainDetails}
                setDomain={setDomain}
                domain={domain}
                handleSubmitNewDomain={handleSubmitNewDomain}
                submitLoading={submitLoading}
                addDomainAlert={addDomainAlert}
                setAddDomainAlert={setAddDomainAlert}
                REGEX_DOMAIN_VALIDATION={REGEX_DOMAIN_VALIDATION}
                marketers={marketerSelection}/>

            <DeleteDomainModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteDomain={deleteDomain}
                deleteSuccessAlert={deleteSuccessAlert}
                setDeleteSuccessAlert={setDeleteSuccessAlert}/>

            <PreviewDomainModal
                setPreviewDomainModal={setPreviewDomainModal}
                previewDomainModal={previewDomainModal}/>
        </Wrapper>
    )
}

export default MarketerCustomDomain
