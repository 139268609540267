import React, { useMemo, useState } from 'react'
import {format, isValid} from 'date-fns'

import Grid from "../../../../../../common/components/grid/Grid";

import {Wrapper} from './Table.style'
import {IFetchTransactionsResponse} from "../MarketersTransactions.service";
import {ColDef} from "ag-grid-community";
import SortableHeaderV2 from "../../../../../../common/components/table/sortable-header-v2/SortableHeaderV2";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hooks";
import {setSortBy, setSortDirection} from "../MarketersTransactions.slice";
import {formatNumberToCurrency} from "../../../../../../common/utils";

interface Props {
    loading: boolean,
    data: IFetchTransactionsResponse[],
    sums: {credit: number, debit: number}
}

const Table: React.FC<Props> = ({
                                    loading,
                                    data,
                                    sums
                                }) => {

    const {sort_direction, sort_by, summarize} = useAppSelector(state => state.marketersTransactions)
    const dispatch = useAppDispatch()
    const reformatTransactionType = (data: IFetchTransactionsResponse) => {
        let type = data.type_name;
        if ((data.type_name == 'Auto funding' || data.type_name == 'Manual Funds') && data.processing_payment_fee) {
            type = data.type_name + ' (Processing Fee: '+ data.processing_payment_fee + '%)';
        }

        return type;
    }

    const colDefs = useMemo<ColDef[]>(() => [
        {
            headerComponent: () => {
                return <SortableHeaderV2
                    keyId={'created_at'}
                    sortedBy={sort_by ? sort_by : ''}
                    sortDirection={sort_direction}
                    onClick={
                        () => {
                            dispatch(setSortBy('created_at'))
                            dispatch(
                                setSortDirection(sort_direction === 'desc' && sort_by === "created_at"  ? 'asc' : 'desc')
                            )
                        }
                    }
                >
                    DATE
                </SortableHeaderV2>
            },
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>
                    {data.transaction_created ?
                        format(new Date(data.transaction_created.replace(/-/g, '/')), 'MM/dd/yyyy') :
                        '--/--/----'}
                </span>
            },
            hide: summarize
        },
        {
            headerName: 'MARKETER ID',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.user_id}</span>
            }
        },
        {
            headerName: 'MARKETER NAME',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.user.display_name}</span>
            }
        },
        {
            headerName: 'COMPANY NAME',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.user.company}</span>
            }
        },
        {
            headerName: 'ACCOUNT MANAGER',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>

                return <span className={'truncate-text'}>{data.user.executive?.full_name}</span>
            }
        },
        {
            headerName: 'TRANSACTION ID',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}></span>
            }
        },
        {
            headerName: 'DESCRIPTION',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.description}</span>
            }
        },
        {
            headerName: 'TYPE',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{reformatTransactionType(data)}</span>
            }
        },
        {
            headerName: 'STATUS',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.status_name}</span>
            }
        },
        {
            headerName: 'CURRENCY TYPE',
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span></span>
                return <span className={'truncate-text'}>{data.currency_type_name}</span>
            }
        },
        {
            headerName: 'CREDIT',
            headerComponent: () => {
                return <SortableHeaderV2
                    keyId={'credit'}
                    sortedBy={sort_by ? sort_by : ''}
                    sortDirection={sort_direction}
                    onClick={
                        () => {
                            dispatch(setSortBy('credit'))
                            dispatch(
                                setSortDirection(sort_direction === 'desc' && sort_by === "credit"  ? 'asc' : 'desc')
                            )
                        }
                    }
                >
                    CREDIT
                </SortableHeaderV2>
            },
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span>{formatNumberToCurrency(sums.credit)}</span>
                return summarize ? <span className={'truncate-text'}>{formatNumberToCurrency(data.sum_credit)}</span> : <span className={'truncate-text'}>{formatNumberToCurrency(data.credit)}</span>
            },

        },
        {
            headerName: 'DEBIT',
            headerComponent: () => {
                return <SortableHeaderV2
                    keyId={'debit'}
                    sortedBy={sort_by ? sort_by : ''}
                    sortDirection={sort_direction}
                    onClick={
                        () => {
                            dispatch(setSortBy('debit'))
                            dispatch(
                                setSortDirection(sort_direction === 'desc' && sort_by === "debit"  ? 'asc' : 'desc')
                            )
                        }
                    }
                >
                    DEBIT
                </SortableHeaderV2>
            },
            cellRenderer: ({data, node}: {data: IFetchTransactionsResponse, node: any}) => {
                if(node.rowPinned === "bottom") return <span>{formatNumberToCurrency(sums.debit)}</span>
                return summarize ? <span className={'truncate-text'}>{formatNumberToCurrency(data.sum_debit)}</span> : <span className={'truncate-text'}>{formatNumberToCurrency(data.debit)}</span>
            }
        },
    ], [sort_direction, sort_by, dispatch, sums, summarize])

    return <Wrapper>
        <Grid
            loading={loading}
            rowData={data}
            rowHeight={40}
            domLayout='autoHeight'
            fixedScroll={true}
            columnDefs={colDefs}
            pinnedBottomRowData={loading || data.length === 0 ? undefined : [sums]}
            // groupIncludeTotalFooter={true}
        />
    </Wrapper>
}

export default Table