import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";
import {useFilterCacheSelector} from "../useFilterCacheSelector";

const paymentPreferences: {id: number, value: string }[] = [
    {
        id: 1,
        value: "ACH"
    },
    {
        id: 2,
        value: "Wire"
    },
    {
        id: 3,
        value: "Paxum"
    },
    {
        id: 4,
        value: "Capitalist"
    },
    {
        id: 5,
        value: "PayPal"
    },
    {
        id: 6,
        value: "Check"
    },
    {
        id: 7,
        value: "Other"
    }
]

export const usePaymentPreferenceCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'payment-preference'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (item: any) => {
        if(filter.selected.some((selected)  => selected.value === item.value)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.value !== item.value)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, item]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }

    return {
        id: id,
        options: paymentPreferences,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        }
    }

}