import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @media screen and (max-widht: 700px) {
    padding-bottom: 100px;
  }
`

export const ToolbarWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   @media screen and (max-width: 897px) {
     flex-direction: column-reverse;
     gap: 15px;
   }
`;

export const WidgetWrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 24px;
  position: relative;
  
  .title {
    line-height: 21px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 24px;
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 51;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.85);
  border-radius: 16px;
`

export const FilterWrapper = styled.div`
  display: flex;
  
`