import React, {ChangeEvent} from 'react';
import {setOffer,setValidation} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {INVALID_URL_MSG, LANDING_PAGE_URL, URL_PATTERN} from "../shared-components/consts";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import {DestinationParamsSelect} from "./DestinationParamsSelect";
import styled from "styled-components";
import {CPE, CPI, GOOGLE_ANALYTICS, NONE} from "../interfaces/interfaces";
import {useTestingLink} from "../hooks/useTestingLink";

export const LandingPageUrlInput: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        validation
    } = useAppSelector(state => state.createOffer)
    
    const getTestingLink = useTestingLink()
    
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(offer.trackingSystem !== GOOGLE_ANALYTICS && offer.trackingSystem !== NONE){
            let testing_link = ""
            if (offer.direct_linking && !offer.legacy_tracking){
                if (e.target.value.includes("?")){
                    testing_link = e.target.value + "&oid=" + offer.id + "&testing=1"
                } else {
                    testing_link = e.target.value + "?oid=" + offer.id + "&testing=1"
                }
            } else
            {
                testing_link = getTestingLink(offer.detailed_pricing_model!, offer.id, offer.destination_url)
            }
            dispatch(setOffer({
                preview_url: e.target.value,
                testing_link: testing_link
            }))
        } else {
            dispatch(setOffer({
                preview_url: e.target.value,
                destination_url: e.target.value,
                testing_link: getTestingLink(offer.detailed_pricing_model!, offer.id, offer.destination_url)
            }))
        }

        let value = e.target.value
        if (value){
            if (value.length <= 0) {
                dispatch(setValidation({validateLandingPageUrl : "Landing Page Url is required"}))
            } else {
                if (!URL_PATTERN.test(value) && !validation.validateLandingPageUrl){
                    dispatch(setValidation({validateLandingPageUrl : INVALID_URL_MSG}))
                }
                else if (URL_PATTERN.test(value) && validation.validateLandingPageUrl){
                    dispatch(setValidation({validateLandingPageUrl : undefined}))
                }
            }
        }
        
    }

    const handleAddParam = (param: string) => {
        if(offer.trackingSystem !== GOOGLE_ANALYTICS && offer.trackingSystem !== NONE){
            dispatch(setOffer({
                ...offer,
                preview_url: offer.preview_url + param
            }))
        } else {
            dispatch(setOffer({
                ...offer,
                preview_url: offer.preview_url + param,
                destination_url: offer.destination_url + param
            }))
        }

    }

    return (
        <Wrapper>
            <Input
                wrapperClassName={"input-wrapper"}
                label={<>Landing Page URL {Tooltip(196, LANDING_PAGE_URL)}</>}
                placeholder={"Enter the URL users land on presenting your campaign"}
                value={offer.preview_url}
                onChange={(event) => handleOnChange(event)}
                error={validation.validateLandingPageUrl}
            />

            {offer.trackingSystem === NONE ?
                <>
                    <p>When choosing standard redirects the {'{clickid}'} macro is needed to successfully pass click info to your server.
                        Additionally you can use the other macros below to pass additional data points from Perform[cb] to your server.</p>
                    <DestinationParamsSelect onSelect={(param) => handleAddParam(param)}/>
                </> : null
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
    
    p{
        font-size: 12px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        margin: 8px 0;
        font-family: ${({theme}) => theme.fonts.AvenirLight};
    }
    
    .input-wrapper {
        margin-bottom: 0px;
    }

`