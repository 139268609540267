import React, {useEffect} from 'react';
import ReactTooltip from "react-tooltip";
import {useAppDispatch, useAppSelector, useFeatureFlags} from "../../../../../../../common/hooks";
import {Panel} from "../Main.style";
import {OfferName} from "../../../components/OfferName";
import {BrandName} from "../../../components/BrandName";
import {OfferDescription} from "../../../components/OfferDescription";
import {setShowExit, setShowBack} from "../../../create-offer.slice";
import {Vertical} from "../../../components/Vertical";
import {CustomDomain} from "../../../components/CustomDomain";
import BrandUrl from "../../../components/BrandUrl";
import {PPC} from "../../../interfaces/interfaces";



const OfferInfo: React.FC = () => {
    const {
        offer,
        customDomains
    } = useAppSelector(state => state.createOffer)
    const dispatch = useAppDispatch()
    const brandNameFlag = useFeatureFlags('brand-name')
    const customDomainFlag = useFeatureFlags('custom-domains')


    
    useEffect(()=>{
        dispatch(setShowExit(false))
        dispatch(setShowBack(false))
       
    },[])
    


    useEffect(() => {
        ReactTooltip.rebuild()
    })


    return (
        <>
        <Panel>
            <div className={"title"}>campaign info</div>
            <div className={'description'}>Let's start with the basics!</div>
            <OfferName/>
            {brandNameFlag && <BrandName/>}
            {offer.detailed_pricing_model === PPC && <BrandUrl/>}
            <OfferDescription/>
            <Vertical/>
            {(customDomainFlag && customDomains.length > 0 && offer.detailed_pricing_model !== PPC ) &&
                <CustomDomain/>
            }
        </Panel>

        </>
    );
};

export default OfferInfo