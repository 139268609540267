const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

export const formatNumberToCurrency = (value: number, float: boolean = true): string => {
    if(isNaN(value)) return ''
    if(float){
        return formatter.format(value)
    }
    const arrValue = formatter.format(value).split('.')
    arrValue.pop()
    return arrValue.join('')
}