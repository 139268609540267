import {buildUrl} from "../../../../../../common/utils/buildUrl";
import {useAppSelector} from "../../../../../../common/hooks";


export const useGetTrackingUrls = () => {
    const {config} = useAppSelector(state => state.auth)

    return (campaign: any ,upsell?: any) => {
        if(campaign.niche){
            let network_config = config.network_configs[campaign.niche.network_id]

            let domain_base = campaign.custom_domain != null ? campaign.custom_domain.domain : campaign.domains?.id != null ? 'http://'+campaign.domains?.domain : network_config.defaultDomainConversion;
            let domain = domain_base + network_config.pubCampaignSuffix;

            let domain_base_secure = campaign.custom_domain != null ? campaign.custom_domain.domain?.replace('http://', 'https://') :
                campaign.domains?.id != null ? 'https://'+campaign.domains?.domain : network_config.defaultDomainConversionSecure || network_config.defaultDomainConversion;

            let domain_secure = domain_base_secure + network_config.pubCampaignSuffix;

            let clickIdValue = ''
            let cbtidValue = ''

            switch (campaign.trackingSystem) {
                case 'tune':
                    clickIdValue = '{aff_sub2}'
                    cbtidValue = '{transaction_id}'
                    break;
                case 'impact':
                    clickIdValue = '{SubId2}'
                    cbtidValue = '{ActionId}'
                    break;
                case 'cake':
                    clickIdValue = "#s2#"
                    cbtidValue = '#tid#'
                    break;
                case 'everflow':
                    clickIdValue = '{sub3}'
                    cbtidValue = '{transaction_id}'
                    break;
            }

            if(upsell) {
                let p = {
                    'clickid': clickIdValue,
                    'cbtid': cbtidValue,
                    'saleamount': ''
                } as any;

                if (upsell.pixel_group_id === null) {
                    p.oid = campaign.id;
                }

                let iframe = {...p}
                iframe.cbiframe = '1';

                let postback = {...p}

                if (upsell.pixel_group !== null) {
                    p.cbguid = upsell.pixel_group?.id;
                    iframe.cbguid = upsell.pixel_group?.id;
                    postback.cbguid = upsell.pixel_group?.id;
                } else {
                    p.cbuid = upsell.id;
                    iframe.cbuid = upsell.id;
                    postback.cbuid = upsell.id;
                }

                p.type = 'image';

                return {
                    iframe_pixel: '<iframe src="' + buildUrl(domain_secure, iframe).replaceAll("%23", "#") + (upsell.is_revshare ? '&payout=' : "") +
                        '" scrolling="no" frameborder="0" width="1" height="1" loading="eager"></iframe>',
                    image_pixel: '<img src="' + buildUrl(domain_secure, p).replaceAll("%23", "#") + (upsell.is_revshare ? '&payout=' : "") + '" height="1" width="1" border="0" loading="eager">',
                    postback_url: buildUrl(domain, postback).replaceAll("%23", "#") + (upsell.is_revshare ? '&payout=' : "")
                }
            } else {

                let ogid = 0;


                if (campaign.pixel_group) {
                    ogid = campaign.pixel_group.id;
                }

                if(ogid > 0){
                    return{
                        postback_url: buildUrl(domain,{
                            'ogid': ogid,
                            'clickid': clickIdValue,
                            'cbtid': cbtidValue,
                            'saleamount': ''
                        }).replaceAll("%23", "#") + ((campaign.is_revshare) ? '&payout=' : ''),
                        iframe_pixel: '<iframe src="' + buildUrl(domain_secure, {
                            'cbiframe': '1',
                            'ogid': ogid,
                            'clickid': clickIdValue,
                            'cbtid': cbtidValue,
                            'saleamount': ''
                        }) + ((campaign.is_revshare) ? '&payout=' : '') + '" scrolling="no" frameborder="0" width="1" height="1" loading="eager"></iframe>',
                        image_pixel: '<img src="' + buildUrl(domain_secure, {
                            'ogid': ogid,
                            'type':'image',
                            'clickid': clickIdValue,
                            'cbtid': cbtidValue,
                            'saleamount': ''
                        }).replaceAll("%23", "#") + ((campaign.is_revshare) ? '&payout=' : '') + '" height="1" width="1" border="0" loading="eager">'
                    }
                } else {
                    var iframe_params = {
                        'cbiframe': '1',
                        'oid': campaign.id,
                        'clickid': clickIdValue,
                        'cbtid': cbtidValue,
                        'saleamount': ''
                    };
                    var postback_params = {
                        'oid': campaign.id,
                        'type':'image',
                        'clickid': clickIdValue,
                        'cbtid': cbtidValue,
                        'saleamount': ''

                    };
                    var image_url = {
                        'oid': campaign.id,
                        'clickid': clickIdValue,
                        'cbtid': cbtidValue,
                        'saleamount': ''
                    }

                    return {
                        iframe_pixel: '<iframe src="' + buildUrl(domain_secure, iframe_params).replaceAll("%23", "#") + ((campaign.is_revshare) ? '&payout=' : '') +
                            '" scrolling="no" frameborder="0" width="1" height="1" loading="eager"></iframe>',
                        image_pixel: '<img src="' + buildUrl(domain_secure, postback_params).replaceAll("%23", "#") + ((campaign.is_revshare) ? '&payout=' : '') +
                            '" height="1" width="1" border="0" loading="eager">',
                        postback_url: buildUrl(domain, image_url).replaceAll("%23", "#") + ((campaign.is_revshare) ? '&payout=' : '')
                    }
                }
            }
            }

            return {
                iframe_pixel: '',
                image_pixel: '',
                postback_url: ''
            }
        }
}