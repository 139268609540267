import React, {useState, useEffect} from 'react';
import {useAppSelector, useAppDispatch} from "../../../../../common/hooks";
import {StyledSelect} from "../../../../../common/components/select/Select";
import {CUSTOM_DOMAIN_TOOLTIP} from "../shared-components/consts";
import {Tooltip} from "../shared-components/Shared";
import {setOffer} from "../create-offer.slice";

export const CustomDomain: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer,
        customDomains
    } = useAppSelector(state => state.createOffer)
    const handleOnChange = (e: any) => {
        dispatch(setOffer({
            ...offer,
            custom_domain: e.value,
            domain_id: e.value.id
        }))
    }
    return (
        <StyledSelect
            value={offer.custom_domain ? {
                value: offer.custom_domain ,
                label: offer.custom_domain.domain
            } : null}
            selectLabel={<>Custom Domain {Tooltip(198, CUSTOM_DOMAIN_TOOLTIP)}</>}
            classNamePrefix={'styled-select'}
            placeholder={"Choose a custom domain"}
            onChange={handleOnChange}
            options={customDomains.map(domain => ({
                label: domain.domain,
                value: domain
            }))}
        />
    );
};