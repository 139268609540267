import React, {useEffect, useRef, useState} from "react";

import {IPaginationProps} from "./pagination.props";

import {Wrapper, PerPage, PerPageSelect, PerPageDropdown, PageSelect, Toolbar, ColumnOptionDropdown} from "./Pagination.style";
import {useOnClickOutside} from "../../../hooks";
import ColumnsOption from "./ColumnsOption/ColumnsOption";
import Icon from "../../icon/Icon";
import TableColumnsList from "./TableColumnsList/TableColumnsList";
import {ColumnState} from "ag-grid-community";

const perPageOptions: number[] = [1, 10, 50, 100, 250, 500]

const Pagination: React.FC<IPaginationProps> = (
    {
        current_page,
        from,
        to,
        total,
        last_page,
        setCurrentPage,
        setPerPage,
        exportCsv,
        columnsOptionProps,
        perPage,
        onRefresh,
        loading,
        perPageCustomList,
        showChart,
        hideRows,
        gridColumnApi,
        id,
        gridApi,
    }) => {

    const [perPageSelected, setPerPageSelected] = useState<number>(perPage)
    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false)
    const [columnDropdownOpened, setColumnDropdownOpened] = useState<boolean>(false);

    const perPageSelectRef = useRef<HTMLDivElement | null>(null)
    const columnDropdownSelectRef = useRef<HTMLDivElement | null>(null)

    useOnClickOutside(perPageSelectRef, () => {
        setDropdownOpened(false)
    })

    useOnClickOutside(columnDropdownSelectRef, () => {
        setColumnDropdownOpened(false);
    });



    useEffect(() => {
        setPerPage(perPageSelected)
    }, [perPageSelected])

    const handlePrevPage = () => {
        if(current_page > 1) setCurrentPage(current_page - 1)

    }

    const handleNextPage = () => {
        if(current_page < last_page) setCurrentPage(current_page + 1)
    }

    const handleRefresh = () => {
        onRefresh && onRefresh()
    }
    const handleShowChart = () => {
        showChart && showChart()
    }

    return <Wrapper>
        <Toolbar $loading={loading}>
            {exportCsv && <div onClick={() => exportCsv()} className={'item'}><i className="ico-Download"/></div>}
            {showChart && <div onClick={handleShowChart} className={'item'}><Icon icon="graph-outlined" size={15} /></div>}
            {columnsOptionProps &&
                <div className={'item'} ref={columnDropdownSelectRef} >
                    <i className="ico-Columns" onClick={() => setColumnDropdownOpened(!columnDropdownOpened)}/>
                    {columnDropdownOpened &&
                        <ColumnOptionDropdown>
                            <ColumnsOption
                                allPaginationColumns={columnsOptionProps.allPaginationColumns}
                                enableDisableColumnHandler={columnsOptionProps.enableDisableColumnHandler}
                                enableAllHandler={columnsOptionProps.enableAllHandler}
                                paginationColumnsList={columnsOptionProps.paginationColumnsList}
                                setPaginationColumnsList={columnsOptionProps.setPaginationColumnsList}
                                disableAllHandler={columnsOptionProps.disableAllHandler}
                                setColumnDropdownOpened={setColumnDropdownOpened}
                            />
                        </ColumnOptionDropdown>
                    }
                </div>
            }
            {
                gridColumnApi && gridApi &&
                    <TableColumnsList gridColumnApi={gridColumnApi}
                                      gridApi={gridApi}
                                      id={id}
                                     />


            }
            {onRefresh && <div className={'item'}><i onClick={handleRefresh} className="ico-Refresh"/></div>}
        </Toolbar>
        {!hideRows &&
            <PerPage>
                Rows
                <PerPageSelect active={dropdownOpened} ref={perPageSelectRef} onClick={() => setDropdownOpened(!dropdownOpened)}>
                    {perPage}
                    {!dropdownOpened ? <i className={'ico-AngleDown'} /> :<i className={'ico-AngleUp'} />}
                    <PerPageDropdown opened={dropdownOpened}>
                        {perPageCustomList?.length ?
                            perPageCustomList.map((option, index) => (
                                <div onClick={() => setPerPageSelected(perPageCustomList[index])} className={`option ${option === perPage ? 'selected' : ''}`} key={option}>{option}</div>
                            ))
                            :
                            perPageOptions.map((option, index) => (
                                <div onClick={() => setPerPageSelected(perPageOptions[index])} className={`option ${option === perPage ? 'selected' : ''}`} key={option}>{option}</div>
                            ))
                        }

                    </PerPageDropdown>
                </PerPageSelect>
            </PerPage>
        }
        <PageSelect>
            <span style={{whiteSpace: 'nowrap'}}>
                {from} - {to} of {total}
            </span>

            <div onClick={handlePrevPage} className={'item'} style={{
                pointerEvents: current_page === 1 ? 'none' : 'all',
            }}>
                <i style={{
                    pointerEvents: current_page === 1 ? 'none' : 'all',
                    opacity: current_page === 1 ? 0.4 : 1
                }}
                 className='ico-ChevronLeft' />
            </div>
            <div onClick={handleNextPage} className={'item'} style={{
                    pointerEvents: current_page === last_page ? 'none' : 'all',
                }}>
                <i style={{
                    pointerEvents: current_page === last_page ? 'none' : 'all',
                    opacity: current_page === last_page ? 0.4 : 1
                }}
               className='ico-ChevronRight'/>
            </div>
        </PageSelect>
    </Wrapper>
}

export default Pagination
