import React, {useCallback, useEffect, useState} from 'react';
import Input from '../../../../../../common/components/input/Input'
import Dropdown from "../../../../../../common/components/dropdown/Dropdown";
import {startOfTomorrow} from "date-fns";
import {Wrapper, Row} from "./AddTransactionModal.style";

import {
    Header,
    Body,
    Footer,
    CloseButton
} from "../../../../../../common/components/modalWindow/container/Container.style";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../../common/styled-components";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";
import DatePicker from "../../../../../../common/components/date-picker/DatePicker";
import TextArea from "../../../../../../common/components/textarea/TextArea";
import MarketersTransactionsService, {
    IAddTransactionParams,
    IFetchCardsResponse
} from "../MarketersTransactions.service";
import {useAppSelector} from "../../../../../../common/hooks";
import {format} from "date-fns";
import {IAdvertisersFilter} from "filters";
import {formatNumberToCurrency} from "../../../../../../common/utils";
import Alert from "../../../../../../common/components/alert/Alert";

interface Props {
    closeModal: () => void;
    advertisers: IAdvertisersFilter[]
    cards: IFetchCardsResponse
    fetchTransactions: () => void
}

const actions: {id: "debit" | "credit", value: string}[] = [
    {
        id: 'credit',
        value: 'Credit'
    },
    {
        id: 'debit',
        value: 'Debit'
    },
]

interface IFormData {
    date: Date | string | null | undefined,
    amount: number | string | null,
    note: string,
    selectedAdvertiser: IAdvertisersFilter | null
    selectedAction: {
        id: "debit" | "credit",
        value: string
    } | null,
    selectedPayment: {
        id: number;
        user_id: number;
        alias: string;
        friendly_number: string;
        type: string;
    } | null | 'manual_adjustment',
    selectedType: string | null,
    selectedCurrency: string | null
}

const transactionTypes: { [type: string]: {[type: string]: string} } = {
    credit: {
        PREPAYMENT: "Prepayment",
        INVALID_CLICKS: "Invalid Clicks",
        BONUS: "Bonus",
        COURTESY_CREDIT: "Courtesy Credit",
        LINE_OF_CREDIT: "Line of Credit",
        OTHER: "Other",
        CAKE_FUNDS: "Cake Funds"
    },
    debit: {
        ADMIN_VALID_CLICKS: "Valid Clicks",
        VALID_CONVERSIONS: "Valid Conversions",
        VALID_TRAFFIC: "Valid traffic",
        REFUND: "Refund",
        LINE_OF_CREDIT: "Line of Credit",
        OTHER: "Other",
        CAKE_FUNDS: "Cake Funds"
    }
}

const currencyTypes: {[type: string]: string} = {
    ADMIN_ADJUSTMENT: "Admin Adjustment",
    BANK_TRANSFER: "Bank Transfer",
    BILLED_CLICK: "Billed Click",
    BILLED_TRAFFIC: "Billed Traffic",
    CHECK: "Check",
    CREDIT_CARD: "Credit Card",
    PAYPAL: "PayPal"
}

const AddTransactionModal: React.FC<Props> = ({
                                                  closeModal,
                                                  advertisers,
                                                  cards,
                                                  fetchTransactions
                                            }) => {
    const {token} = useAppSelector(state => state.auth)
    const [marketerDropdownOpened, setMarketerDropdownOpened] = useState(false)
    const [actionDropdownOpened, setActionDropdownOpened] = useState(false)
    const [currencyDropdownOpened, setCurrencyDropdownOpened] = useState(false)
    const [typeDropdownOpened, setTypeDropdownOpened] = useState(false)
    const [paymentDropdownOpened, setPaymentDropdownOpened] = useState(false)

    const [filteredAdvertisers, setFilteredAdvertisers] = useState<IAdvertisersFilter[]>([])
    const [advertisersSearchValue, setAdvertisersSearchValue] = useState('')

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const [formData, setFormData] = useState<IFormData>({
        note: '',
        amount: null,
        selectedAdvertiser: null,
        selectedAction: null,
        selectedPayment: null,
        selectedType: null,
        selectedCurrency: null,
        date: new Date()
    })

    useEffect(() => {
        setFilteredAdvertisers(advertisers)
    }, [])

    // const filterAffiliates = useCallback(() => {
    //     setFilteredAdvertisers(advertisers.filter(advertiser => {
    //         return (advertiser.name.toLowerCase() + advertiser.lastname.toLowerCase()).includes(advertisersSearchValue.toLowerCase()) ||
    //             advertiser.id.toString().includes(advertisersSearchValue.toLowerCase())
    //     }))
    // }, [advertisersSearchValue, advertisers])

    useEffect(() => {})

    const handleAdvertisersSearch = (value: string) => {
        setFilteredAdvertisers(advertisers.filter(advertiser => {
            return (advertiser.name?.toLowerCase() + advertiser.lastname?.toLowerCase()).includes(value.toLowerCase()) ||
                advertiser.id.toString().includes(value.toLowerCase())
        }))
        setAdvertisersSearchValue(value)
    }

    const addTransaction = async () => {
        const params: IAddTransactionParams = {
            created_at: formData.date ? format(new Date(formData.date), 'yyyy-MM-dd') : new Date().toString(),
            user_id: formData.selectedAdvertiser ? formData.selectedAdvertiser.id : 0,
            card_id: formData.selectedPayment ?
                formData.selectedPayment === 'manual_adjustment' ?
                    'manual_adjustment' :
                    formData.selectedPayment.id :
                '',
            currency_type: formData.selectedCurrency ? formData.selectedCurrency : '',
            action: formData.selectedAction ? formData.selectedAction.id : 'debit',
            maxDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            amount: formData.amount ?
                formData.amount.toString()
                    .replaceAll('$', '')
                    .replaceAll(',', '')
                : '',
            type: formData.selectedType ? formData.selectedType : '',
        }

        if(formData.note !== ''){
            params.note = formData.note
        }

        setLoading(true)
        try {
            if(formData.selectedAdvertiser){
                await MarketersTransactionsService.addTransaction(
                    token,
                    formData.selectedAdvertiser.id,
                    params
                )
                fetchTransactions()
                closeModal()
            }

        } catch (err: any) {
            setErrorMessage(err.response.data.message);
        }
        setLoading(false)
    }

    const isChargeButtonDisabled = (): boolean => {
        return !(
            formData.amount &&
            formData.selectedPayment &&
            formData.selectedAction &&
            formData.selectedAdvertiser &&
            formData.selectedType &&
            formData.selectedCurrency &&
            formData.date
        );
    }

    return <>
        <Header>
            <div className={'title'}>
                Add Transaction <CloseButton onClick={closeModal}><i className={'ico-Exit-outlined'}/></CloseButton>
            </div>
        </Header>
        <Body>
            {errorMessage && <Alert alertType={'error'}>{errorMessage}</Alert>}
            <Row>
                <Dropdown
                    text={formData.selectedAdvertiser ?
                        `${formData.selectedAdvertiser.display_name} - (${formData.selectedAdvertiser.id})` :
                        'Select Marketer'
                    }
                    isOpened={marketerDropdownOpened}
                    setOpened={setMarketerDropdownOpened}
                    label={'Marketer'}
                    search={{
                        value: advertisersSearchValue,
                        onChange: (e) => handleAdvertisersSearch(e),
                        placeholder: 'Search advertisers by name or ID'
                    }}
                    dropdownHeightLimit={5}
                    itemsNotDisplayed={filteredAdvertisers.length - 50}
                >
                    {filteredAdvertisers.slice(0, 50).map((advertiser) => {
                        return <DropdownItem
                            key={advertiser.id}
                            onClick={
                                () => {
                                    setFormData({...formData, selectedAdvertiser: advertiser})
                                    setMarketerDropdownOpened(false)
                                }
                            }
                            selected={formData.selectedAdvertiser?.id === advertiser.id}
                        >
                            {advertiser.display_name} ({advertiser.id})
                        </DropdownItem>
                    })}
                </Dropdown>
            </Row>
            <Row>
                <Dropdown
                    text={formData.selectedAction ? formData.selectedAction.value :'Select Action'}
                    isOpened={actionDropdownOpened}
                    setOpened={setActionDropdownOpened}
                    label={'Action'}
                    disabled={false}
                    optionSelect={true}
                >
                    {
                        actions.map(action =>
                            <DropdownItem
                                key={action.id}
                                selected={action.id === formData.selectedAction?.id}
                                onClick={
                                    () => {
                                        setFormData(
                                        {
                                            ...formData,
                                            selectedAction: {
                                                value: action.value,
                                                id: action.id
                                            },
                                            selectedType: null
                                        }
                                        )
                                        setActionDropdownOpened(false)
                                    }
                                }
                            >
                                {action.value}
                            </DropdownItem>)
                    }
                </Dropdown>
            </Row>
            {formData.selectedAction && <Row>
                <Dropdown
                    text={
                        formData.selectedType ?
                            formData.selectedAction.id === 'credit' ?
                                transactionTypes.credit[formData.selectedType] :
                                transactionTypes.debit[formData.selectedType] :
                            'Select Type'
                    }
                    isOpened={typeDropdownOpened}
                    setOpened={setTypeDropdownOpened}
                    label={'Type'}
                    disabled={false}
                    optionSelect={true}
                >
                    {formData.selectedAction.id === 'credit' &&
                        Object.keys(transactionTypes.credit).map((key) => {
                            return <DropdownItem
                                onClick={
                                    () => {
                                        setFormData(
                                            {
                                                ...formData,
                                                selectedType: key
                                            }
                                        )
                                        setTypeDropdownOpened(false)
                                    }
                                }
                                selected={formData.selectedType === key}
                            >
                                {transactionTypes.credit[key]}
                            </DropdownItem>
                        })
                    }
                    {formData.selectedAction.id === 'debit' &&
                        Object.keys(transactionTypes.debit).map((key) => {
                            return <DropdownItem
                                key={key}
                                onClick={
                                    () => {
                                        setFormData(
                                            {
                                                ...formData,
                                                selectedType: key
                                            }
                                        )
                                        setTypeDropdownOpened(false)
                                    }
                                }
                                selected={formData.selectedType === key}
                            >
                                {transactionTypes.debit[key]}
                            </DropdownItem>
                        })
                    }
                </Dropdown>
            </Row>}
            {formData.selectedAdvertiser && <Row>
                <Dropdown
                    text={
                        formData.selectedPayment ? formData.selectedPayment !== 'manual_adjustment' ?
                        `${formData.selectedPayment.alias} - ${formData.selectedPayment.friendly_number} - ${formData.selectedPayment.type}` : 'Manual Adjustment' :'Select Payment'
                    }
                    isOpened={paymentDropdownOpened}
                    setOpened={setPaymentDropdownOpened}
                    label={'Payment'}
                    dropdownHeightLimit={5}
                    disabled={false}
                    optionSelect={true}
                >
                    {
                        (formData.selectedAdvertiser.id.toString() in cards) &&
                            cards[formData.selectedAdvertiser.id.toString()].map(card => {
                                return <DropdownItem
                                    key={card.id}
                                    onClick={() => {
                                            setFormData({
                                                ...formData,
                                                selectedPayment: card
                                            })
                                            setPaymentDropdownOpened(false)
                                        }
                                    }
                                    selected={formData.selectedPayment !== 'manual_adjustment' && formData.selectedPayment?.id === card.id}
                                >
                                    {card.alias} - {card.friendly_number} - {card.type}
                                </DropdownItem>
                        })
                    }
                    <DropdownItem
                        onClick={() => {
                            setFormData({
                                ...formData,
                                selectedPayment: 'manual_adjustment'
                            })
                            setPaymentDropdownOpened(false)
                        }
                        }
                        selected={formData.selectedPayment === 'manual_adjustment'}
                    >
                        Manual Adjustment
                    </DropdownItem>
                </Dropdown>
            </Row>}

            <Row>
                <Dropdown
                    text={formData.selectedCurrency ? `${currencyTypes[formData.selectedCurrency]}` : 'Select currency'}
                    isOpened={currencyDropdownOpened}
                    setOpened={setCurrencyDropdownOpened}
                    label={'Currency'}
                    disabled={false}
                    optionSelect={true}
                >
                    {Object.keys(currencyTypes).map(key => {
                        return <DropdownItem
                            key={key}
                            selected={formData.selectedCurrency === key}
                            onClick={
                                () => {
                                    setFormData({
                                        ...formData,
                                        selectedCurrency: key
                                    })
                                    setCurrencyDropdownOpened(false)
                                }
                            }
                        >
                            {currencyTypes[key]}
                        </DropdownItem>
                    })}
                </Dropdown>
            </Row>
            <Row>
                <DatePicker
                    inputWidth={'100%'}
                    date={formData.date ? formData.date : new Date()}
                    setDate={(date) => setFormData({...formData, date: date})}
                    label={'Date'}
                    maxDate={new Date()}
                />
            </Row>
            <Row>
                <Input
                    placeholder={'$0.00'}
                    value={formData.amount ? formData.amount : ''}
                    onChange={
                        (e) => {
                            e.target.validity.valid && setFormData({
                                ...formData,
                                amount: e.target.value
                                }
                            )
                        }
                    }
                    pattern={"[0-9, $, .]*"}
                    onBlur={
                        () => {
                            setFormData({
                                ...formData,
                                amount: formData.amount &&
                                    formatNumberToCurrency(
                                        parseFloat(
                                            formData.amount.toString().replace(/[^0-9.]+/g, '')
                                        )
                                    )
                            })
                        }
                    }
                />
            </Row>
            <Row>
                <TextArea
                    label={'Note'}
                    value={formData.note}
                    onChange={(e) => setFormData({...formData, note: e.target.value})}
                />
            </Row>
        </Body>
        <Footer>
            <ButtonStyled onClick={closeModal} className={"btn-cancel"}>Cancel</ButtonStyled>
            <ButtonStyled
                onClick={addTransaction}
                disabled={loading || isChargeButtonDisabled()}
                className={"btn-orange-filled"}>
                {loading && <BtnLoadingSpinner/>}
                <span className={"btn-label"}>Charge</span>
            </ButtonStyled>
        </Footer>
    </>
}

export default AddTransactionModal