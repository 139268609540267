import { useEffect, useMemo, useState } from "react";
import Grid from "../../../../../../../common/components/grid/Grid"
import { useColumnsOptionHook } from "../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import Pagination from "../../../../../../../common/components/table/pagination/Pagination"
import SortableHeader from "../../../../../../../common/components/table/sortable-header/SortableHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/hooks";
import { formatNumberToCurrency } from "../../../../../../../common/utils";
import { AgencyViewSortIconDown, AgencyViewSortIconUp } from "../../../agency-view/agency-view-tables/styles/AgencyViewTable.style";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../NetworkView.service";
import { setMarketersCurrentPage, setMarketersPaginationColumnsList, setMarketersPerPage, setMarketersSortDirection, setPagination, setTopPerformersAnalyzeBy, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../NetworkView.slice";
import { NetworkViewSortIconDown, NetworkViewSortIconUp, TopPerformersAnalyzeBy, TopPerformersTable } from "../TopPerformers.style";
import { allMarketersPaginationColumns } from "../topPerformersLists";

const TopPerformersMarketer = ({loading} : any) => {

    const {
        marketersPaginationColumnsList,
        marketersPerPage,
        marketersCurrentPage,
        marketersSort,
        topPerformersLoading,
        topPerformersTableData,
        topPerformersTableSum,
        selectedManagers,
        includeOrExclude,
        filterType
    } = useAppSelector(state => state.networkView)

    const {token} = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch();

    const sortHandler = (key: string) => {
        
        dispatch(setMarketersSortDirection({
            direction: marketersSort.by !== key ? "asc" : marketersSort.direction === "desc" ? "asc" : "desc",
            by: key
        }))
    }

    const commaTransform = (value: string) => {
        return Number(value).toLocaleString('en-US')
    }

    const allMarketersColumnDefs: any = [
        {
            colId: 1, 
            width: 200,
            minWidth: 200,
            resizable: true,
            headerComponent: (data: any) =>  {
               return <SortableHeader 
                            keyId={'id'} 
                            sortDirection={marketersSort.direction} 
                            sortedBy={marketersSort.by} 
                            onClick={() => !data.column.isMoving() && sortHandler("id")}
                            icon={marketersSort.by === "id"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconDown /> : <NetworkViewSortIconUp  /> : <span></span> }
                        >MARKETER ID</SortableHeader>
            },
            cellRenderer: (data:any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.id}</span>
            }
        },
        {
            colId: 2,
            width: 250,
            minWidth: 240,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.company_name}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'company_name'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("company_name")}
                             icon={marketersSort.by === "company_name"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >MARKETER COMPANY NAME</SortableHeader>
             },
        
        },
        {
            colId: 3, 
            width: 200,
            minWidth: 200,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){     
                    return <span>{commaTransform(data.data.clicks)}</span>
                }
                return <span>{commaTransform(data.data.clicks)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'clicks'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("clicks")}
                             icon={marketersSort.by === "clicks"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CLICKS</SortableHeader>
             },
        
        },
        {
            colId: 4,
            width: 200,
            minWidth: 200,
            cellRenderer: (data: any) => {
                let n = Number(data.data.conversions)
                if(data.node.rowPinned === "bottom"){
                    return <span>{commaTransform(data.data.conversions)}</span>;
                }
                return <span>{commaTransform(data.data.conversions)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'conversions'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("conversions")}
                             icon={marketersSort.by === "conversions"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CONVERSIONS</SortableHeader>
             },
        
        },
        {
            colId: 5,
            width: 200,
            minWidth: 200,
            cellRenderer: (data: any) => {                
                if(data.node.rowPinned === "bottom"){
                    return <span>{data.data.cvr.replaceAll('.', ',')}%</span>;
                }
                return <span>{data.data.cvr.replaceAll('.', ',')}%</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'cvr'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("cvr")}
                             icon={marketersSort.by === "cvr"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CVR</SortableHeader>
             },
        
        },
        {
            colId: 6,
            width: 200,
            minWidth: 200,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.revenue)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.revenue)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'revenue'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("revenue")}
                             icon={marketersSort.by === "revenue"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >REVENUE</SortableHeader>
             },
        
        },
        {
            colId: 7,
            width: 200,
            minWidth: 200,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.profit)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.profit)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'profit'} 
                             sortDirection={marketersSort.direction} 
                             sortedBy={marketersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("profit")}
                             icon={marketersSort.by === "profit"  ? marketersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >PROFIT</SortableHeader>
             },
        
        },
        
    ];

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "marketers",
            page: marketersCurrentPage,
            per_page: marketersPerPage,
            sort_by: marketersSort.by,
            order: marketersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_sale_exec'] = true
            }
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setMarketersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setMarketersPerPage(parseInt(response.data.per_page)))
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    const marketerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setMarketersPaginationColumnsList(list));
    }

    const [marketersColumnDefs, setMarketersColumnDefs] = useState(allMarketersColumnDefs);
    const [colWidths, setColWidths] = useState(allMarketersColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId}
    }));

    const [enableAllMarketersHandler, disableAllMarketersHandler, enableDisableMarketersColumnHandler]: any = useColumnsOptionHook(
        allMarketersColumnDefs, 
        marketersColumnDefs, 
        setMarketersColumnDefs,
        allMarketersPaginationColumns,
        marketersPaginationColumnsList, 
        marketerPaginationColumnsListHandler
    );

    useEffect(() => {
        const checkedColumns = marketersPaginationColumnsList.filter((item: any) => item.checked);
        if (marketersPaginationColumnsList.length === 7) {
            if(marketersPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allMarketersColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                })
                setMarketersColumnDefs(newDefs);
            } else {
                setMarketersColumnDefs([]);
            }
        }
        
    }, [marketersPaginationColumnsList, marketersSort])

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allMarketersColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allMarketersPaginationColumns.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });
        
        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })
        
        setColWidths(widths);
        setMarketersColumnDefs(widthToCol);
        
        const rest = marketersPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        marketerPaginationColumnsListHandler([...newPaginCol, ...rest]);
    }

    const defaultColDef = useMemo(() => ({
        resizable: true
    }), []);

    useEffect( () => {
        token && fetchData();
    }, [marketersPerPage, marketersCurrentPage, marketersSort]);

    return(
        <TopPerformersTable>
            <Grid 
                loading={topPerformersLoading}
                columnDefs={marketersColumnDefs}
                defaultColDef={defaultColDef}
                rowData={topPerformersTableData}
                rowHeight={40}
                domLayout='autoHeight'
                enableAllColumns={enableAllMarketersHandler}
                pinnedBottomRowData={topPerformersLoading || topPerformersTableData.length === 0 ? undefined : topPerformersTableSum}
                fixedHeader={false}
                onDragStopped={onColumnDrop}
            />
        </TopPerformersTable>
    )
}

export default TopPerformersMarketer