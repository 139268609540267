import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPreset} from "../../../../../common/components/range-date-picker/presets/presets.props";
import {IFetchAllMarketersResponse, IFetchAllPartnersResponse} from "./AgencyView.service";

type TAnalyzeByValue = 'clicks' | 'sale_amount' | 'cvr' | 'conversions' | 'roas' | 'cost' | 'epc' | 'aov' | 'ecpa' | 'cac'
type TAnalyzeByName = 'Clicks' | 'Sale/Revenue Amount' | 'CVR' | 'Conversions' | 'ROAS' | 'Cost' | 'EPC' | 'AOV' | 'eCPA' | 'CAC'
export type TDateSelector = 'day' | 'week' | 'month'
type TFilterBy = 'none' | 'marketer' | 'partner'

export interface IAnalyzeBy {
    value: TAnalyzeByValue,
    name: TAnalyzeByName
}

export interface IAgencyViewInitialState {
    analyze_performance_analyze_by: IAnalyzeBy,
    analyze_performance_analyze_by_vs: IAnalyzeBy,
    analyze_performance_date_selector: TDateSelector,
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset,
    filterBy: TFilterBy,
    selectedMarketer: IFetchAllMarketersResponse | null,
    selectedPartner: IFetchAllPartnersResponse | null,
    dateReportPerPage: number,
    dateReportCurrentPage: number,
    dateReportSortDirectionLs: 'asc' | 'desc',
    dateReportSortByLs: string,
    marketerReportPerPage: number,
    marketerReportCurrentPage: number,
    marketerReportSortDirectionLs: 'asc' | 'desc',
    marketerReportSortByLs: string,
    partnerReportPerPage: number,
    partnerReportCurrentPage: number,
    partnerReportSortDirectionLs: 'asc' | 'desc',
    partnerReportSortByLs: string,
    eventReportPerPage: number,
    eventReportCurrentPage: number,
    eventReportSortDirectionLs: 'asc' | 'desc',
    eventReportSortByLs: string
}

const initialState: IAgencyViewInitialState = {
    analyze_performance_analyze_by: {
        value: 'clicks',
        name: 'Clicks'
    },
    analyze_performance_analyze_by_vs:{
        value: 'sale_amount',
        name: 'Sale/Revenue Amount'
    },
    analyze_performance_date_selector: 'week',
    date_from: new Date(),
    date_to: new Date(),
    date_range_preset: {
        value: "last_7_days",
        name: 'Last 7 days'
    },
    filterBy: 'none',
    selectedMarketer: null,
    selectedPartner: null,
    dateReportPerPage: 10,
    dateReportCurrentPage: 1,
    dateReportSortDirectionLs: 'desc',
    dateReportSortByLs: 'date',
    marketerReportPerPage: 5,
    marketerReportCurrentPage: 1,
    marketerReportSortDirectionLs: 'desc',
    marketerReportSortByLs: 'sale_amount',
    partnerReportPerPage: 5,
    partnerReportCurrentPage: 1,
    partnerReportSortDirectionLs: 'desc',
    partnerReportSortByLs: 'sale_amount',
    eventReportPerPage: 5,
    eventReportCurrentPage: 1,
    eventReportSortDirectionLs: 'desc',
    eventReportSortByLs: 'sale_amount'
}

const agencyViewSlice = createSlice({
    name: 'agencyViewSlice',
    initialState,
    reducers: {
        setAnalyzePerformanceAnalyzeBy: (state, action: PayloadAction<IAnalyzeBy>) => {
            state.analyze_performance_analyze_by = action.payload
        },
        setAnalyzePerformanceAnalyzeByVs: (state, action: PayloadAction<IAnalyzeBy>) => {
            state.analyze_performance_analyze_by_vs = action.payload
        },
        setAnalyzePerformanceDateSelector: (state, action: PayloadAction<TDateSelector>) => {
            state.analyze_performance_date_selector = action.payload
        },
        setAnalyzePerformanceDateFrom: (state, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setAnalyzePerformanceDateTo: (state, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setAnalyzePerformanceDateRangePreset: (state, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
        setFilterBy: (state, action:PayloadAction<TFilterBy>) => {
            state.filterBy = action.payload
        },
        setMarketer: (state, action:PayloadAction<IFetchAllMarketersResponse | null>) => {
            state.selectedMarketer = action.payload
        },
        setPartner: (state, action:PayloadAction<IFetchAllPartnersResponse | null>) => {
            state.selectedPartner = action.payload
        },
        setDateReportPerPage: (state, action:PayloadAction<number>) => {            
            state.dateReportPerPage = action.payload
        },
        setDateReportCurrentPage: (state, action:PayloadAction<number>) => {            
            state.dateReportCurrentPage = action.payload
        },
        setDateReportSortDirection: (state, action:PayloadAction<"asc" | "desc">) => {
            state.dateReportSortDirectionLs = action.payload;
        },
        setDateReportSortBy: (state, action: PayloadAction<string>) =>{
          state.dateReportSortByLs = action.payload;
        },
        setMarketerReportPerPage: (state, action:PayloadAction<number>) => {
            state.marketerReportPerPage = action.payload
        },
        setMarketerReportCurrentPage: (state, action:PayloadAction<number>) => {            
            state.marketerReportCurrentPage = action.payload
        },
        setMarketerReportSortDirection: (state, action:PayloadAction<"asc" | "desc">) => {
            state.marketerReportSortDirectionLs = action.payload;
        },
        setMarketerReportSortBy: (state, action: PayloadAction<string>) => {
          state.marketerReportSortByLs = action.payload;
        },
        setPartnerReportPerPage: (state, action:PayloadAction<number>) => {
            state.partnerReportPerPage = action.payload
        },
        setPartnerReportCurrentPage: (state, action:PayloadAction<number>) => {            
            state.partnerReportCurrentPage = action.payload
        },
        setPartnerReportSortDirection: (state, action:PayloadAction<"asc" | "desc">) => {
            state.partnerReportSortDirectionLs = action.payload;
        },
        setPartnerReportSortBy: (state, action : PayloadAction<string>) => {
            state.partnerReportSortByLs = action.payload;
        },
        setEventReportPerPage: (state, action:PayloadAction<number>) => {
            state.eventReportPerPage = action.payload
        },
        setEventReportCurrentPage: (state, action:PayloadAction<number>) => {            
            state.eventReportCurrentPage = action.payload
        },
        setEventReportSortDirection: (state, action:PayloadAction<"asc" | "desc">) => {
            state.eventReportSortDirectionLs = action.payload;
        },
        setEventReportSortBy: (state, action : PayloadAction<string>) => {
            state.eventReportSortByLs = action.payload
        }
    }
})

export default agencyViewSlice.reducer

export const {
    setAnalyzePerformanceAnalyzeBy,
    setAnalyzePerformanceAnalyzeByVs,
    setAnalyzePerformanceDateSelector,
    setAnalyzePerformanceDateFrom,
    setAnalyzePerformanceDateRangePreset,
    setAnalyzePerformanceDateTo,
    setFilterBy,
    setMarketer,
    setPartner,
    setDateReportPerPage,
    setDateReportCurrentPage,
    setDateReportSortDirection,
    setDateReportSortBy,
    setMarketerReportPerPage,
    setMarketerReportCurrentPage,
    setMarketerReportSortDirection,
    setMarketerReportSortBy,
    setPartnerReportPerPage,
    setPartnerReportCurrentPage,
    setPartnerReportSortDirection,
    setPartnerReportSortBy,
    setEventReportPerPage,
    setEventReportCurrentPage,
    setEventReportSortDirection,
    setEventReportSortBy
} = agencyViewSlice.actions