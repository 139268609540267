import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 24px;
  position: relative;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.03);
  margin-top: 24px;
  
  .title {
    line-height: 21px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    color: ${({theme}) => theme.colors.deepBlue[7]};
    margin-bottom: 24px;
  }
`

export const RevenueRow = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 997px) {
    flex-wrap: wrap;
  }
`

export const TileContent = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  padding: 16px;
  width: 25%;
  margin-bottom: 16px;
  
  .cell-title{
    line-height: 22px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[5]};
    margin-bottom: 8px;
    display: inline-block;
    margin-right: 10px;
  }
  
  .cell-value{
    line-height: 46px;
    font-size: 34px;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    color: ${({theme}) => theme.colors.deepBlue[7]};
  }
  
  @media screen and (max-width: 997px) {
    width: calc(50% - 8px);
  }

  @media screen and (max-width: 620px) {
    width: 100%;
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 51;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.85);
  border-radius: 16px;
`