import {api} from '../../../../../common/services/api'
import {IPagination} from "../../../../../common/models/pagination.type";
import qs from "qs";


export interface IMarketerBalanceResponse {
    id: number;
    status: string;
    name: string;
    lastname: string;
    network_name: string;
    company: string;
    network_id: number;
    sum_credit: number;
    sum_debit: number;
    start_balance: number;
    ending_balance: number;
    full_name: string;
    full_profile_image: string;
    status_name: string;
    display_name: string;
    accepted_by_admin_name?: any;
    tags: any[];
}

class MarketerBalanceService {

    static fetchMarketerBalanceData = async (token: string, params: {
        paginate_by?: number,
        sort_by?: string,
        order?: "desc" | "asc",
        page?: number,
        from: string,
        to: string
    }) => {        
        return await api.get<IPagination<IMarketerBalanceResponse>>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                process.env.REACT_APP_API_USERS,
                'billing?advertiser_balance=1'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/json'
                },
                params,
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            }
        )
    }

    static exportToCsv = (params: {
        paginate_by?: number,
        sort_by?: string,
        order?: "desc" | "asc",
        page?: number,
        from: string,
        to: string
        }
    ) => {    
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_USERS,
            'billing?'
        ].join('/') + "advertiser_balance=1"+ "&" + qs.stringify(params) + "&" + "action=export_csv", "_blank");
    }
}

export default MarketerBalanceService;