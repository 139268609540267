import * as React from 'react';
import {
    SummaryContainer,
    Description,
    Subtitle,
    Item,
    SummaryToggleButton, 
    SummaryBackdrop, 
    SummaryHeader, 
    SummaryBody, 
    Container
} from "./Summary.style";
import {useAppSelector, useFeatureFlags} from "../../../../../../common/hooks";
import {
    AndroidIcon,
    AppEngageIcon,
    AppInstallIcon,
    CallsIcon,
    ClicksIcon, IosIcon,
    LeadsIcon,
    SalesIcon
} from "../../../../../../assets/svgs/SvgIcons";
import {useState} from "react";
import {format} from "date-fns";
import OfferName from "./components/OfferName";
import BrandName from "./components/BrandName";
import BrandUrl from "./components/BrandUrl";
import Niche from "./components/Niche";
import CustomDomain from "./components/CustomDomain";
import TrackingSystem from "./components/TrackingSystem";
import OfferDescription from "./components/OfferDescription";
import TrackingUrl from "./components/TrackingUrl";
import LandingPageUrl from "./components/LandingPageUrl";
import AppStoreUrl from "./components/AppStoreUrl";
import PlayStoreUrl from "./components/PlayStoreUrl";
import MainConversion from "./components/MainConversion";
import Events from "./components/Events";
import RedirectType from "./components/RedirectType";
import TrackingMethod from "./components/TrackingMethod";
import TrackingCodePlaced from "./components/TrackingCodePlaced";
import TestTracking from "./components/TestTracking";
import Domain from "./components/Domain";
import DomainIdParameter from "./components/DomainIdParameter";
import VisualCreatives from "./components/VisualCreatives";
import EmailTraffic from "./components/EmailTraffic";
import Budget from "./components/Budget";
import TrafficTypes from "./components/TrafficTypes";
import DeviceTypes from "./components/DeviceTypes";
import TargetedLocations from "./components/TargetedLocations";
import ExcludedLocations from "./components/ExcludedLocations";
import SelectedCities from "./components/SelectedCities";
import SelectedZipCodes from "./components/SelectedZipCodes";
import SelectedIsps from "./components/SelectedIsps";
import Scheduling from "./components/Scheduling";

export interface Props {

};

export const Summary : React.FC<Props> = ({}) => {
    
    const [summaryOpened, setSummaryOpened] = useState(false)
    
    const {
        offer,
        validation,
        lastSaved,

    } = useAppSelector(state => state.createOffer)
    
    const domainTrackingFlag = useFeatureFlags('domain-tracking-code', offer.niche ? offer.niche.network_id : undefined)
    
    const getOfferType = () => {
        switch (offer.detailed_pricing_model) {
            case "cps":
                return <Description><SalesIcon/>Sales / Subscription</Description>
            case "cpl":
                return <Description><LeadsIcon/>Leads / Form Submissions</Description>
            case "cpi":
                return <Description className={'apps-offer-type'}>
                    <div>
                        <AppInstallIcon/>App Install
                    </div>
                    <div>
                        {offer.operating_system_id === 2 ? <><IosIcon height={27}/> iOS</> : <><AndroidIcon height={27}/> Android</>}
                    </div>
                </Description>
            case 'cpe':
                return <Description className={'apps-offer-type'}>
                    <div>
                        <AppEngageIcon/>App Engagements
                    </div>
                    <div>
                        {offer.operating_system_id === 2 ? <><IosIcon height={27}/> iOS</> : <><AndroidIcon height={27}/> Android</>}
                    </div>
                </Description>
            case "cpc":
                return <Description><ClicksIcon/>Clicks</Description>
            case "ppc":
                return <Description><CallsIcon/>Customer Calls</Description>
        }
    }
    
    return (
        <Container>
            <SummaryToggleButton
                displayed={summaryOpened}
                onClick={()=> setSummaryOpened(prev => !prev)}>
                <i className={summaryOpened ? 'ico-Exit-outlined' : 'ico-Hamburger-Menu'} />
            </SummaryToggleButton>
            <SummaryBackdrop
                displayed={!summaryOpened}
                onClick={()=> setSummaryOpened(prev => !prev)}/>
            <SummaryContainer displayed={!summaryOpened}>
                <SummaryHeader>
                    <div className={'title'}>
                        Campaign Summary
                    </div>
                    <div className={'apps-offer-type'}>
                        {getOfferType()}
                    </div>
                    <div className="flex-row">
                        <div>
                            <Subtitle> Campaign Status </Subtitle>
                            <Item style={{ textTransform: "capitalize"}}>
                                {(lastSaved && offer.status === 'empty') ? 'Draft' 
                                    : offer.status === 'empty' ? 'New'
                                    : offer.status
                                }
                            </Item>
                        </div>
                        <div>
                            <Subtitle> Last Saved </Subtitle>
                            <Item style={{whiteSpace: 'nowrap'}}>
                                {lastSaved ? format(lastSaved, "MM/dd/yyyy 'at' h:m b 'EST'") : '-'}
                            </Item>
                        </div> 
                    </div>
                </SummaryHeader>
                <SummaryBody className={'custom-scrollbar'}>

                    <OfferName/>

                    <BrandName/>

                    <BrandUrl/>

                    <OfferDescription/>

                    <Niche/>

                    <CustomDomain/>

                    <TrackingSystem/>

                    <TrackingUrl/>

                    <LandingPageUrl/>

                    <AppStoreUrl/>

                    <PlayStoreUrl/>


                    {/*{*/}
                    {/*    ((offer.detailed_pricing_model === 'cpc'*/}
                    {/*            && offer.destination_url !== ''*/}
                    {/*            && validation.validateLandingPageUrl === undefined*/}
                    {/*            && offer.preview_url !== '')*/}
                    {/*        || (offer.detailed_pricing_model === 'ppc' && offer.niche_id !== 0)) &&*/}
                    {/*    <>*/}
                    {/*        <MainConversion/>*/}
                    {/*        <Events/>*/}
                    {/*    </>*/}
                    {/*}*/}
                    
                    {/*{(*/}
                    {/*    !domainTrackingFlag */}
                    {/*        && validation.validateLandingPageUrl === undefined */}
                    {/*        && offer.preview_url !== '' */}
                    {/*        && offer.destination_url !== '') &&*/}
                    {/*    <>*/}
                    {/*        <MainConversion/>*/}
                    {/*        <Events/>*/}
                    {/*    </>*/}
                    {/*}*/}
                    
                    {/*<RedirectType/>*/}
                    
                    {/*{offer.legacy_tracking !== null && offer.direct_linking !== null && offer.trackingSystem !== 'none' ?*/}
                    {/*    <>*/}
                    {/*        {offer.direct_linking === true ?*/}
                    {/*            <>*/}
                    {/*                <TrackingMethod/>*/}
                    {/*            </> :*/}
                    {/*            <>*/}
                    {/*                <TrackingMethod/>*/}
                    {/*                <MainConversion/>*/}
                    {/*                <Events/>*/}
                    {/*                <TrackingCodePlaced/>*/}
                    {/*                <TestTracking/>*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*    </> :*/}
                    {/*    null*/}
                    {/*}*/}
                    
                    {/*{(offer.trackingSystem === 'google-analytics' || offer.trackingSystem === 'none')*/}
                    {/*&& offer.landing_page_url && offer.direct_linking !== null ? <>*/}
                    {/*    <TrackingMethod/>*/}

                    {/*    <MainConversion/>*/}
                    {/*    <Events/>*/}

                    {/*    <TrackingCodePlaced/>*/}
                    {/*    <TestTracking/>*/}
                    
                    {/*</> : null}*/}
                    
                    {/*{(offer.direct_linking === true && offer.trackingSystem !== 'google-analytics'*/}
                    {/*    && offer.trackingSystem !== 'none') ? <>*/}
                    {/*    <Domain/>*/}
                    {/*    <DomainIdParameter/>*/}
                    {/*    <MainConversion/>*/}
                    {/*    <Events/>*/}


                    {/*</> : null}*/}

                    <RedirectType/>

                    <TrackingMethod/>

                    <Domain/>

                    <DomainIdParameter/>

                    <MainConversion/>

                    <Events/>

                    <TrackingCodePlaced/>

                    <TestTracking/>
                    
                    <VisualCreatives/>

                    <EmailTraffic/>

                    <Budget/>

                    <TrafficTypes/>

                    <DeviceTypes/>

                    <TargetedLocations/>

                    <ExcludedLocations/>
                    
                    <SelectedCities/>
                    
                    <SelectedZipCodes/>
                    
                    <SelectedIsps/>

                    <Scheduling/>

                </SummaryBody>
            </SummaryContainer>
        </Container>
    );
};