import React, {useMemo, useState} from 'react'
import {IFetchCitiesResponse} from "../../../../interfaces/interfaces";
import {useAppDispatch, useAppSelector} from '../../../../../../../../common/hooks'
import {setOffer} from "../../../../create-offer.slice";
import {
    SelectedCountries,
    SelectedCountryPill,
    TargetingSection,
    TargetingSelector,
    TargetingSelectorOption
} from "../Targeting.style";
import {ButtonStyled} from "../../../../../../../../common/styled-components";
import {StyledSelect} from "../../../../../../../../common/components/select/Select";
import MultiOptionSwitch, {
    OptionType
} from "../../../../../../../../common/components/multi-option-switch/MultiOptionSwitch";

const switchOptions: OptionType[] = [
    {
        value: 'All',
        id: 'all'
    },
    {
        value: 'Include',
        id: 'include'
    },
    {
        value: 'Exclude',
        id: 'exclude'
    }
    ]

const CityTargeting: React.FC = () => {
    const {offer} = useAppSelector(state => state.createOffer)
    const {cities} = useAppSelector(state => state.createOffer.geoData.data)
    const dispatch = useAppDispatch()
    const [citiesSearchValue, setCitiesSearchValue] = useState('')


    const handleCitySelect = (city: IFetchCitiesResponse) => {
        if(offer.selected_cities.some((selected: any) => selected.id === city.id)){
            dispatch(setOffer({selected_cities: [...offer.selected_cities.filter((selected: any) => selected.id !== city.id)]}))

        } else {
            dispatch(setOffer({selected_cities: [...offer.selected_cities, city]}))

        }
    }

    const options = useMemo(() => {
        return cities && [...cities
            .filter(city => city.name?.toLowerCase().includes(citiesSearchValue.toLowerCase()) ||
                city.metro_code?.toLowerCase().includes(citiesSearchValue.toLowerCase()) ||
                city.state_name?.toLowerCase().includes(citiesSearchValue.toLowerCase())
            )
        ]
    }, [cities, citiesSearchValue])


    return <>
        <TargetingSection>
            <h3>City Targeting</h3>
            <MultiOptionSwitch
                options={switchOptions}
                selected={offer.cities_type}
                onSelect={(option) => dispatch(setOffer({cities_type: option.id as string}))}
                wrapperClassName={'targeting-select'}
            />
        </TargetingSection>
        {offer.cities_type !== 'all' &&
            <>
                <StyledSelect
                    filterOption={() => true}
                    value={null}
                    selectWrapperClassname={'select-wrapper'}
                    onInputChange={(newValue) => { setCitiesSearchValue(newValue) }}
                    // @ts-ignore
                    onChange={(value) => value && handleCitySelect(value.value)}
                    options={options ?
                        options
                            .slice(0, 50)
                            .filter(city => !offer.selected_cities.some(reg => reg.id === city.id))
                            .map(city => ({
                                    label: `${city.name} (${city.metro_code}), ${city.state}`,
                                    value: city
                                })
                            ) : []
                    }
                    placeholder={`Choose a city to ${offer.cities_type}`}
                />
                {offer.selected_cities.length > 0 &&
                    <SelectedCountries>
                        {offer.selected_cities.map((city: any, index) => {
                            return <SelectedCountryPill  style={{border: index === offer.selected_cities.length - 1 ? 'none' : ''}} key={city.id}>
                                {city.name}
                                <i className={'ico-Exit'} onClick={() => handleCitySelect(city)}/>
                            </SelectedCountryPill>
                        })}
                    </SelectedCountries>
                }
                {offer.selected_cities.length > 0 &&
                    <div style={{textAlign: "right", marginTop: '8px'}}>
                        <ButtonStyled
                            className={'btn-default-outlined'}
                            onClick={() => dispatch(setOffer({selected_cities: []}))}
                        >
                            Clear All
                        </ButtonStyled>
                    </div>
                }
            </>
        }

    </>
}

export default CityTargeting