import styled, {css} from 'styled-components'

interface Props {
    numberOfLines: number,
    showMore: boolean
}

export const Wrapper = styled.div<Props>`
    width: 100%;
    color: ${({theme}) => theme.colors.deepBlue[4]};
    overflow-wrap: break-word;
    position: relative;
    .truncate{
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${(props) => props.numberOfLines};
        -webkit-box-orient: vertical;
    }
    
    button{
        position: ${(props) => props.showMore ? 'relative' : 'absolute'};
        bottom: 0;
        right: 0;
        display: inline;
        background: white;
        color: ${({theme}) => theme.colors.performBlue[6]};
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        
        :hover{
            color: ${({theme}) => theme.colors.performBlue[7]};
        }

        :active{
            color: ${({theme}) => theme.colors.performBlue[8]};
        }
    }
    
`