import React, {ChangeEvent, useState} from 'react'
import {capitalizeFirstLetter, formatNumberToCurrency} from "../../../../../common/utils";
import Input from "../../../../../common/components/input/Input";
import {gender, income} from "../shared-components/consts";
import Modal from "../../../../../common/components/modal/Modal";
import AddPayoutModifierModal from "./AddPayoutModifierModal";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import styled from "styled-components";
import {ButtonStyled} from "../../../../../common/styled-components";
import {StyledSelect} from "../../../../../common/components/select/Select";

const AddModifierButton = styled(ButtonStyled).attrs({className: 'btn-blue-outlined',})`
    display: flex;
    align-items: center;
    gap: 5px;
    span {
        font-size: 18px;
    }
`

const Wrapper = styled.div `
    margin-top: 24px;
    .ico-Exit {
        font-size: 16px;
        line-height: 1;
        margin-top: 15px;
        color: ${({theme}) => theme.colors.negative[5]};
        :hover{
            cursor: pointer;
        }
    }
    
    .payout-input {
        width: 200px;
    }
    
    .modifier-label {
        margin-bottom: 16px;
    }
    
    .modifier-input {
        margin: 0;
        flex-basis: 100%;
        width: 100%;
    }
    
    .payout-info {
        font-size: 10px;
        color: ${({theme}) => theme.colors.deepBlue[4]};
    }
    .form-group {
        align-items: baseline!important;
    }
`

type Props = {
    modifiers: any[]
    setModifiers: (modifiers: any[]) => void
    payout: any
}

export const PayoutModifiers: React.FC<Props> = (
    {
        modifiers,
        setModifiers,
        payout
    }) => {
    
    const [payoutModifierModalOpened, setPayoutModifierModalOpened] = useState(false)
    
    const handleAgeFromChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if(e.target.validity.valid) {
            const newModifiers = [...modifiers]
            const newSetting = {...newModifiers[index].settings}
            newSetting.from = e.target.value
            newModifiers[index] = {...newModifiers[index], settings: {...newSetting}}
            setModifiers([...newModifiers])
        }
    }

    const handleAgeToChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (e.target.validity.valid) {
            const newModifiers = [...modifiers]
            const newSetting = {...newModifiers[index].settings}
            newSetting.to = e.target.value
            newModifiers[index] = {...newModifiers[index], settings: {...newSetting}}
            setModifiers([...newModifiers])
        }
    }

    const handleRemoveModifier = (index: number) => {
        const newModifiers = [...modifiers]
        newModifiers.splice(index, 1)
        setModifiers([...newModifiers])
    }

    const handlePayoutModifierInput = (e: string | undefined, index: number) => {
        const newModifiers = [...modifiers]
        newModifiers[index] = {...newModifiers[index], payout: e}
        setModifiers([...newModifiers])
    }
    
    const handleIncomeSelect = (setting: {from: number, to: number | null}, incomeId: number, index: number) => {
        const newModifiers = [...modifiers]
        newModifiers[index] = {...newModifiers[index], settings: {...setting}, income_id: incomeId}
        console.log(newModifiers)
        setModifiers([...newModifiers])
    }

    const handleGenderSelect = (index: number, gender: string) => {
        const newModifiers = [...modifiers]
        newModifiers[index] = {...newModifiers[index], settings: {gender: gender}}
        setModifiers([...newModifiers])
    }
    
    return ( 
        <Wrapper>
            {modifiers.filter((modifier: any) => modifier.type === 'age').length > 0 && 
                <div className={'modifier-label'}><strong>Age</strong></div>
            }
            {modifiers.map((modifier: any, index: number)=> {
                return modifier.type === 'age' ? <div key={'age_' + index}>
                    <div className={'form-group'} style={{alignItems: 'baseline'}}>
                        <div className={'form-control'}>
                            <Input
                                wrapperClassName={'modifier-input'}
                                value={modifier.settings.from ? modifier.settings.from : ''}
                                onChange={(e) => handleAgeFromChange(e, index)}
                                label={'From'}
                                pattern={"[0-9]*"}
                            />
                        </div>
                        <div className={'form-control'}>
                            <Input
                                wrapperClassName={'modifier-input'}
                                label={'To'}
                                onChange={(e) => handleAgeToChange(e, index)}
                                value={modifier.settings.to ? modifier.settings.to : ''}
                                pattern={"[0-9]*"}
                            />
                            <span className={'payout-info'}>(empty for unlimited)</span>
                        </div>
                        <div className={'form-control'}>
                            <CurrencyInput
                                prefix={'$'}
                                label={'Payout Modifier'}
                                value={modifier.payout ? modifier.payout : ''}
                                onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                wrapperClassName={'modifier-input payout-input'}
                                placeholder={'$0.00'}
                                decimalScale={2}
                            />
                            <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                        </div>
                        <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                    </div>
                </div> : null
            })}
            
            {modifiers.filter((modifier: any) => modifier.type === 'income').length > 0 && 
                <div className={'modifier-label'}><strong>Income</strong></div>
            }

            {modifiers.map((modifier: any, index: number) =>{
                return modifier.type === 'income' ? <div key={'income_' + index}>
                    <div className={'form-group'}>
                        <div className={'form-control'}>
                            <StyledSelect
                                selectLabel={'Income'}
                                placeholder={'Select Income'}
                                selectWrapperClassname={'modifier-input'}
                                isSearchable={false}
                                value={{
                                    label: formatNumberToCurrency(modifier.settings.from) + ' - ' + formatNumberToCurrency(modifier.settings.to),
                                    value: modifier
                                }}
                                options={income.settings.map((setting, incomeId) => {
                                    return {
                                        label: (formatNumberToCurrency(setting.from) 
                                            + ' - ' + formatNumberToCurrency(setting.to!)),
                                        value: {from: setting.from, to: setting.to}
                                    }
                                })}
                                isOptionDisabled={(option) => 
                                    modifiers.some((mod: any) => mod.income_id === option.value)}
                                //@ts-ignore
                                onChange={(option) => handleIncomeSelect(option.value, option.value, index)}
                                classNamePrefix={'styled-select'}
                            />
                        </div>
                        <div className={'form-control'}>
                            <CurrencyInput
                                prefix={'$'}
                                label={'Payout Modifier'}
                                value={modifier.payout ? modifier.payout : ''}
                                onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                wrapperClassName={'modifier-input'}
                                placeholder={'$0.00'}
                                decimalScale={2}
                            />
                            <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                        </div>
                        <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                    </div>
                </div> : null
            })}
            
            {modifiers.filter((modifier: any) => modifier.type === 'gender').length > 0 && 
                <div className={'modifier-label'}><strong>Gender</strong></div>
            }
            {modifiers.map((modifier: any, index: number) => {
                return modifier.type === 'gender' ? <div key={'gender_' + index}>
                    <div className="form-group">
                        <div className={'form-control'}>
                            <StyledSelect
                                selectLabel={'Gender'}
                                placeholder={'Select Gender'}
                                selectWrapperClassname={'modifier-input'}
                                isSearchable={false}
                                value={{
                                    label: capitalizeFirstLetter(modifier.settings.gender),
                                    value: modifier.settings.gender
                                }}
                                options={gender.settings.map((setting) => {
                                    return {
                                        label: setting.gender[0].toUpperCase() 
                                            + setting.gender.slice(1).toLowerCase(),
                                        value: setting.gender
                                    }
                                })}
                                //@ts-ignore
                                onChange={(e) => handleGenderSelect(index, e.value)}
                                classNamePrefix={'styled-select'}
                            />
                        </div>
                        <div className={'form-control'}>
                            <CurrencyInput
                                prefix={'$'}
                                label={'Payout Modifier'}
                                value={modifier.payout ? modifier.payout : ''}
                                onValueChange={(e) => handlePayoutModifierInput(e, index)}
                                wrapperClassName={'modifier-input'}
                                placeholder={'$0.00'}
                                decimalScale={2}
                            /> 
                            <span className={'payout-info'}>(in addition to the {payout} base payout)</span>
                        </div>
                        <i className="ico-Exit" onClick={() => handleRemoveModifier(index)}/>
                    </div>
                </div> : null
            })}
            
            
            <AddModifierButton onClick={() => setPayoutModifierModalOpened(true)}>
                Add Payout Modifier
            </AddModifierButton>
            <Modal 
                opened={payoutModifierModalOpened} 
                title={'Create a Payout Modifier'} 
                closeModal={() => setPayoutModifierModalOpened(false)}>
                <AddPayoutModifierModal 
                    closeModal={() => setPayoutModifierModalOpened(false)}
                    addModifiers={(mod) => setModifiers([...mod, ...modifiers])}
                    payout={payout}
                />
            </Modal>
        </Wrapper>
    );
};

export default PayoutModifiers
