import React, {useMemo, useState} from 'react'

import {SelectedCountries, SelectedCountryPill} from '../Targeting.style'
import {IGroupOptions, StyledSelect} from "../../../../../../../../common/components/select/Select";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hooks";
import {IFetchContinentsResponse, IFetchRegionsResponse} from "../../../../interfaces/interfaces";
import {ILocation} from "../../../../../offers/cpa/Cpa.service";
import {setCampaign} from "../../../../../offers/cpa/Cpa.slice";
import {setOffer} from "../../../../create-offer.slice";
import {ButtonStyled} from "../../../../../../../../common/styled-components";

const LocationTargeting: React.FC = () => {
    const {loading, error, data} = useAppSelector(state => state.createOffer.geoData)
    const {offer} = useAppSelector(state => state.createOffer)

    const {continents, countriesState, regions} = data

    const dispatch = useAppDispatch()

    const [selectedContinents, setSelectedContinents] = useState<IFetchContinentsResponse[]>([])
    const [selectedRegions, setSelectedRegions] = useState<IFetchRegionsResponse[]>([])

    const [selectedContinentsExclude, setSelectedContinentsExclude] = useState<IFetchContinentsResponse[]>([])
    const [selectedRegionsExclude, setSelectedRegionsExclude] = useState<IFetchRegionsResponse[]>([])


    const handleLocationInclude = (location: ILocation) => {
        if(offer.targeted_locations.some((loc) => loc.id === location.id)){
            dispatch(setOffer({targeted_locations: [...offer.targeted_locations.filter((loc) => loc.id !== location.id)]}))
        } else {
            dispatch(setOffer({targeted_locations: [...offer.targeted_locations, location]}))
        }
    }

    const handleContinentIncludeSelect = (continent: IFetchContinentsResponse) => {
        if(selectedContinents.some(con => con.id === continent.id)){
            setSelectedContinents([...selectedContinents.filter(con => con.id !== continent.id)])
            const newLocations = offer.targeted_locations.filter((loc) => loc.continent_code !== continent.code)
            dispatch(
                setOffer({
                        targeted_locations:
                            [
                                ...newLocations
                                    .filter((loc) => !offer.excluded_locations
                                        .some((location) => location.id === loc.id))
                            ]
                    }
                ))
        } else {
            setSelectedContinents(prev => [...prev, continent])
            const newLocations = [...offer.targeted_locations.filter((loc) => loc.continent_code !== continent.code), ...continent.locations]
            dispatch(setOffer({
                    targeted_locations:
                        [
                            ...newLocations
                                .filter((loc) => !offer.excluded_locations
                                    .some((location) => location.id === loc.id))
                        ]
                }
            ))
        }
    }

    const handleRegionIncludeSelect = (region: IFetchRegionsResponse) => {
        if(selectedRegions.some(reg => reg.id === region.id)){
            setSelectedRegions([...selectedRegions.filter(con => con.id !== region.id)])
            const newLocations = offer.targeted_locations.filter((loc) => loc.pivot?.region_id !== region.id)
            dispatch(setOffer({targeted_locations: [...newLocations]}))
        } else {
            setSelectedRegions(prev => [...prev, region])
            const newLocations = offer.targeted_locations.filter((loc) => loc.pivot?.region_id === region.id)
            dispatch(setOffer({
                targeted_locations: [
                    ...newLocations.filter((loc) => !offer.excluded_locations
                        .some((location) => location.id === loc.id)),
                    ...region.locations.filter(loc => !offer.excluded_locations
                        .some((location) => location.id === loc.id))
                ]
            }))
        }
    }

    const handleLocationExclude = (location: ILocation) => {
        if(offer.excluded_locations.some((loc) => loc.id === location.id)){
            dispatch(setOffer({excluded_locations: [...offer.excluded_locations.filter((loc) => loc.id !== location.id)]}))
        } else {
            dispatch(setOffer({excluded_locations: [...offer.excluded_locations, location]}))
        }
    }

    const handleContinentExcludeSelect = (continent: IFetchContinentsResponse) => {
        if(selectedContinentsExclude.some(con => con.id === continent.id)){
            setSelectedContinentsExclude([...selectedContinentsExclude.filter(con => con.id !== continent.id)])
            const newLocations = offer.excluded_locations.filter((loc) => loc.continent_code !== continent.code)
            dispatch(
                setOffer({
                        excluded_locations: [
                            ...newLocations
                                .filter((loc) => !offer.targeted_locations
                                    .some((location) => location.id === loc.id))
                        ]
                    }
                ))
        } else {
            setSelectedContinentsExclude(prev => [...prev, continent])
            const newLocations = [...offer.excluded_locations.filter((loc) => loc.continent_code !== continent.code), ...continent.locations]
            dispatch(setOffer({
                excluded_locations: [
                    ...newLocations
                        .filter(loc => !offer.targeted_locations
                            .some((location) => location.id === loc.id))
                ]
            }))
        }
    }

    const handleRegionExcludeSelect = (region: IFetchRegionsResponse) => {
        if(selectedRegionsExclude.some(reg => reg.id === region.id)){
            setSelectedRegionsExclude([...selectedRegionsExclude.filter(con => con.id !== region.id)])
            const newLocations = offer.excluded_locations.filter((loc) => loc.pivot?.region_id !== region.id)
            dispatch(setOffer({excluded_locations: [...newLocations]}))
        } else {
            setSelectedRegionsExclude(prev => [...prev, region])
            const newLocations = offer.excluded_locations.filter((loc) => loc.pivot?.region_id === region.id)
            dispatch(setOffer({
                excluded_locations: [
                    ...newLocations.filter((loc) => !offer.targeted_locations
                        .some((location) => location.id === loc.id)),
                    ...region.locations.filter(loc => !offer.targeted_locations
                        .some((location) => location.id === loc.id))
                ]
            }))
        }
    }

    const selectGroupOptions: IGroupOptions[] = useMemo(() => {
        const regionGroup = regions ? [
            ...regions
                .filter(region => (
                    !selectedRegions
                        .some(reg => reg.id === region.id) &&
                    !selectedRegionsExclude
                        .some(reg => reg.id === region.id)
                ))
        ] : []

        const locationGroup = countriesState ? [
            ...countriesState.locations
                .filter(location => (
                    !offer.targeted_locations
                        .some((loc) => loc.id === location.id) &&
                    !offer.excluded_locations
                        .some((loc) => loc.id === location.id))
                )
        ] : []

        const continentGroup = continents ? [
            ...continents
                .filter(continent => (
                    !selectedContinents
                        .some(con => con.id === continent.id) &&
                    !selectedContinentsExclude
                        .some(con => con.id === continent.id))
                )
        ] : []

      return [
          {
              label: 'Continents',
              group_id: 'continents',
              options: [...continentGroup.map(continent => {
                  return {
                      label: continent.name,
                      value: continent,
                      group_id: 'continents'
                  }
              })]
          },
          {
              label: 'Regions',
              group_id: 'regions',
              options: [...regionGroup.map(region => {
                  return {
                      label: region.name,
                      value: region,
                      group_id: 'regions'
                  }
              })]
          },
          {
              label: 'Countries and States',
              group_id: 'locations',
              options: [...locationGroup.map(location => {
                  return {
                      label: location.name,
                      value: location,
                      group_id: 'locations'
                  }
              })]
          }
      ]
    }, [
        continents,
        countriesState,
        offer.excluded_locations,
        offer.targeted_locations,
        regions,
        selectedContinents,
        selectedContinentsExclude,
        selectedRegions,
        selectedRegionsExclude
    ])

    const handleSelectOnChange = (groupId: string, value: any, include: boolean) => {

        switch (groupId){
            case 'locations':
                include ? handleLocationInclude(value) : handleLocationExclude(value)
                break
            case 'continents':
                include ? handleContinentIncludeSelect(value) : handleContinentExcludeSelect(value)
                break
            case 'regions':
                include ? handleRegionIncludeSelect(value) : handleRegionExcludeSelect(value)
        }

    }

    const handleClearAll = () => {
        setSelectedRegions([])
        setSelectedContinents([])
        dispatch(setOffer({targeted_locations: []}))
    }

    const handleExcludeClearAll = () => {
        setSelectedRegionsExclude([])
        setSelectedContinentsExclude([])
        dispatch(setOffer({excluded_locations: []}))
    }

    return <>
        <p className={'description'}>
            Define the countries and territories you would like to target with this campaign, by default all are targeted. <br/>
        </p>
        <div className="include-exclude-section">
            <div>
                <StyledSelect
                    selectLabel={'Targeted Locations'}
                    placeholder={'Choose a state or country to include'}
                    selectWrapperClassname={'select-wrapper'}
                    value={null}
                    onChange={(value, actionMeta) => {
                        // @ts-ignore
                        value && handleSelectOnChange(value.group_id, value.value, true)
                    }}
                    // @ts-ignore
                    options={selectGroupOptions}
                />
                {offer.targeted_locations.length === 0 ?
                    <SelectedCountries>
                        <SelectedCountryPill style={{border: 'none'}}>
                            All Countries & Territories
                        </SelectedCountryPill>
                    </SelectedCountries>
                    : <SelectedCountries style={{overflowY: offer.targeted_locations.length > 5 ? 'auto' : 'hidden'}}>
                    {offer.targeted_locations.map((location: any, index) => {
                        return <SelectedCountryPill style={{border: index === offer.targeted_locations.length - 1 ? 'none' : ''}} key={location.id}>
                            {location.name}
                            <i className={'ico-Exit'} onClick={() => handleLocationInclude(location)}/>
                        </SelectedCountryPill>
                    })}
                </SelectedCountries>}

                {offer.targeted_locations.length > 1 &&
                    <div style={{textAlign: "right", marginTop: '8px'}}>
                        <ButtonStyled
                            className={'btn-default-outlined'}
                            onClick={handleClearAll}
                        >
                            Clear All
                        </ButtonStyled>
                    </div>
                }
            </div>
            <div>
                <StyledSelect
                    selectLabel={'Excluded Locations'}
                    selectWrapperClassname={'select-wrapper'}
                    placeholder={'Choose a state or country to exclude'}
                    value={null}
                    onChange={(value, actionMeta) => {
                        // @ts-ignore
                        value && handleSelectOnChange(value.group_id, value.value, false)
                    }}
                    // @ts-ignore
                    options={selectGroupOptions}
                />
                {offer.excluded_locations.length > 0 &&
                    <>
                        <>
                            <SelectedCountries style={{overflowY: offer.excluded_locations.length > 5 ? 'auto' : 'hidden'}}>
                                {offer.excluded_locations.map((location, index) => {
                                    return <SelectedCountryPill style={{border: index === offer.excluded_locations.length - 1 ? 'none' : ''}} key={location.id}>
                                        {location.name}
                                        <i className={'ico-Exit'} onClick={() => handleLocationExclude(location)}/>
                                    </SelectedCountryPill>
                                })}
                            </SelectedCountries>
                        </>
                        {offer.excluded_locations.length > 1 &&
                            <div style={{textAlign: "right", marginTop: '8px'}}>
                                <ButtonStyled
                                    className={'btn-default-outlined'}
                                    onClick={handleExcludeClearAll}
                                >
                                    Clear All
                                </ButtonStyled>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    </>
}

export default LocationTargeting