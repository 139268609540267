import React, {useRef, useState, useEffect} from "react";
import _ from "lodash";

import Dropdown from '../../../../../../common/components/dropdown/Dropdown'
import Checkbox from "../../../../../../common/components/checkbox/Checkbox";
import {DropdownItem} from "../../../../../../common/components/dropdown/Dropdown.style";

import {
    Wrapper,
    FilterTypeHeader,
    FilterTypeContainer,
    FilterTypeWrapper,
    AccManagerSearchInput,
    ManagerListContainer,
    IncludeExcludeWrapper,
    IncludeExcludeItem,
    ClearSelected,
    StickyFilterWrapper,
    AccountManagersNotShown,
    AccountManagerListTooltipWrapper,
    ClearFiltersButton,
    FilterButtonWrapper
} from './Filters.style'

import {useAppSelector, useAppDispatch, useOnClickOutside} from "../../../../../../common/hooks";

import {setFilterType, setIncludeExclude, addOrRemoveFilterManager, clearSelectedManagers} from "../NetworkView.slice";
import NetworkViewService, {IFetchAllAccountManagersResponse} from "../NetworkView.service";
import {tokenStore} from "../../../../../../store/auth.slice";

interface Props {
    fetchData: () => void
}

const Filters: React.FC<Props> = ({fetchData}) => {
    const [isFilterOpened, setIsFilterOpened] = useState(false)
    const [filterTypeDropdownOpened, setFilterTypeDropdownOpened] = useState(false)
    const [allAccountManagers, setAllAccountManagers] = useState<IFetchAllAccountManagersResponse[]>([])
    const [loadingAccManagers, setLoadingAccManagers] = useState(false)
    const [filterSearchValue, setFilterSearchValue] = useState('')
    const [accountManagerSearchResult, setAccountManagerSearchResult] = useState<IFetchAllAccountManagersResponse[] | []>(allAccountManagers)
    const [clearToggle, setClearToggle] = useState(false)

    const [filterHovered, setFilterHovered] = useState(false)

    const {filterType, includeOrExclude, selectedManagers} = useAppSelector(state => state.networkView)
    const token = useAppSelector(tokenStore)

    const dispatch = useAppDispatch()

    const filterTypeHeaderRef = useRef<HTMLDivElement | null>(null)
    const filterTypeContainerRef = useRef<HTMLDivElement | null>(null)
    const filterRef = useRef<HTMLDivElement | null>(null)
    const filterTypeWrapperRef = useRef<HTMLDivElement | null>(null)

    const filterHeaderHoverTimerRef = useRef<any>()

    const selectedManagersIds = useRef<number[]>()
    const filterTypeRef = useRef<string>()
    const includeOrExcludeRef = useRef<string>()

    useEffect(() => {
        selectedManagersIds.current = selectedManagers.map((managers: IFetchAllAccountManagersResponse) => managers.id)
        filterTypeRef.current = filterType
        includeOrExcludeRef.current = includeOrExclude
    }, [isFilterOpened])

    const fetchAccountManagers = async () => {
        try{
            setLoadingAccManagers(true)
            const {data} = await NetworkViewService.fetchAllAccountManagers(token)
            setLoadingAccManagers(false)
            setAllAccountManagers(data)
            setAccountManagerSearchResult(data)
        } catch (e) {
            setLoadingAccManagers(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchAccountManagers()
    }, [])

    useEffect(() => {
        handleSearch(filterSearchValue)
    }, [filterSearchValue])

    const handleSearch = (value: string) => {
        const result = allAccountManagers.filter(
            accManager => {
                return (accManager.name + accManager.lastname).toLocaleLowerCase().includes(value.toLocaleLowerCase())
                        ||
                        accManager.id.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
            }
        )
        setAccountManagerSearchResult(result)
    }

    const handleFilterTypeSelect = (type: 'none' | 'account-manager' | 'sales-executive' | 'partner-account-manager') => {
        dispatch(setFilterType(type))
        setFilterTypeDropdownOpened(false)
    }

    const handleIncludeExcludeSelect = (type: 'incl' | 'excl') => {
        dispatch(setIncludeExclude(type))
    }

    useOnClickOutside(filterTypeWrapperRef, (e) => {
        if(filterTypeContainerRef.current?.contains(e.target as Node)){
            return
        }

        setFilterTypeDropdownOpened(false)
    })

    const onFilterClickOutside = () => {
        if(isFilterOpened){
            if(
                _.isEqual(selectedManagersIds.current, selectedManagers.map((manager: IFetchAllAccountManagersResponse) => manager.id)) &&
                filterType === filterTypeRef.current &&
                includeOrExclude === includeOrExcludeRef.current
            ){

            } else {
                fetchData()
            }
        }
    }

    const handleManagerSelect = (manager: IFetchAllAccountManagersResponse) => {
        dispatch(addOrRemoveFilterManager(manager))
    }

    const handleFilterHeaderHover = (): void => {
        filterHeaderHoverTimerRef.current = setTimeout(() => {
            setFilterHovered(true)
        }, 750)
    }

    const handleHeaderMouseLeave = () => {
        filterHeaderHoverTimerRef && clearTimeout(filterHeaderHoverTimerRef.current)
        setFilterHovered(false)
    }

    const handleClearFilters = () => {
        dispatch(clearSelectedManagers())
        setClearToggle(!clearToggle)
    }

    useEffect(() => {
        fetchData()
    }, [clearToggle])

    const filterButtonText = () => {
            return (
                <FilterButtonWrapper onMouseEnter={handleFilterHeaderHover} onMouseLeave={handleHeaderMouseLeave}>
                    <i className={'ico-FiltersOutlined'}/>
                    {((filterType === 'account-manager' ||
                        filterType === 'sales-executive' ||
                        filterType === 'partner-account-manager') &&
                        selectedManagers.length > 0) && <>
                        <span
                            style={{whiteSpace: "nowrap"}}
                            className={'acc-manager'}>
                            {filterType === 'account-manager' ? 'Engine Growth Manager: ' :
                                filterType === 'partner-account-manager' ? 'Partner Account Manager: ' :
                                filterType === 'sales-executive' ? 'Sales Executive: ' : ''} </span>
                        {selectedManagers.length > 0 &&
                        <>{includeOrExclude === 'incl' ?
                            <span  className={'include-exclude--filter'}>
                                Include
                            </span>
                            :
                            <span className={'include-exclude--filter'}>
                                Exclude
                            </span>}
                            </>}
                        {selectedManagers.length > 1 &&
                        <span className={'selected--manager'}>
                            {selectedManagers.length} selected
                        </span>}
                        {selectedManagers.length === 1 &&
                        <span className={'selected--manager'}>
                            {selectedManagers[0].name} {selectedManagers[0].lastname}
                        </span>}
                    </>
                    }

                </FilterButtonWrapper>
            )
    }

    return <Wrapper
        $noneSelected={filterType === 'none'}
        $disabled={loadingAccManagers}
        $opened={isFilterOpened}
        ref={filterRef}
        $overflow={filterType === 'none'}>
        {(filterHovered && selectedManagers.length > 1) &&
        <AccountManagerListTooltipWrapper>
            {selectedManagers.map((manager: IFetchAllAccountManagersResponse) => {
                return <div key={manager.id} className="item">
                    {manager.name} {manager.lastname}
                </div>
            })}
        </AccountManagerListTooltipWrapper>}
        <Dropdown
            text={filterButtonText()}
            isOpened={isFilterOpened}
            setOpened={setIsFilterOpened}
            headerClassName={'filter-header'}
            containerClassName={'filter-container'}
            overflow={filterType === 'none'}
            disabled={loadingAccManagers}
            onClickOutside={onFilterClickOutside}
        >

            <StickyFilterWrapper>
                <div className={'filter-container--filter-by'}>
                    <h3>Filter By</h3>
                    <FilterTypeWrapper ref={filterTypeWrapperRef}>
                        <FilterTypeHeader
                            $opened={filterTypeDropdownOpened}
                            ref={filterTypeHeaderRef}
                            onClick={() => setFilterTypeDropdownOpened(!filterTypeDropdownOpened)}
                        >
                            <span style={{whiteSpace: "nowrap"}}>{filterType === 'account-manager' ? 'Engine Growth Manager' :
                                filterType === 'partner-account-manager' ? 'Partner Account Manager' :
                                filterType === 'sales-executive' ? 'Sales Executive' :  'None'}</span>
                            {filterTypeDropdownOpened ? <i className='ico-AngleUp'/> : <i className='ico-AngleDown'/>}
                        </FilterTypeHeader>

                        <FilterTypeContainer
                            ref={filterTypeContainerRef}
                            opened={filterTypeDropdownOpened}
                            $top={filterTypeHeaderRef && filterTypeHeaderRef.current?.getBoundingClientRect().top ?
                                filterTypeHeaderRef.current?.getBoundingClientRect().top + window.scrollY + 25 : 0}
                            $left={filterTypeHeaderRef && filterTypeHeaderRef.current?.getBoundingClientRect().left ?
                                filterTypeHeaderRef.current?.getBoundingClientRect().left -
                                filterTypeHeaderRef.current?.getBoundingClientRect().width + 8 : 0}
                        >
                            <DropdownItem
                                onClick={() => handleFilterTypeSelect('none')}
                                selected={filterType === 'none'}
                            >
                                None
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handleFilterTypeSelect('account-manager')}
                                selected={filterType === 'account-manager'}
                            >
                                Engine Growth Manager
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handleFilterTypeSelect('partner-account-manager')}
                                selected={filterType === 'partner-account-manager'}
                            >
                                Partner Account Manager
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handleFilterTypeSelect('sales-executive')}
                                selected={filterType === 'sales-executive'}
                            >
                                Sales Executive
                            </DropdownItem>
                        </FilterTypeContainer>
                    </FilterTypeWrapper>
                </div>
                {(filterType === 'account-manager' || filterType === 'sales-executive' || filterType === 'partner-account-manager') && (
                    <div style={{position: 'relative'}}>
                        <AccManagerSearchInput
                            placeholder={'Search'}
                            value={filterSearchValue}
                            onChange={(e) => setFilterSearchValue(e.target.value)}/>
                        {filterSearchValue.length > 0 &&
                        <i onClick={() => setFilterSearchValue('')} className={'ico-Exit'}/>}
                        <IncludeExcludeWrapper>
                            <IncludeExcludeItem $active={includeOrExclude === 'incl'} onClick={() => handleIncludeExcludeSelect('incl')}>Include</IncludeExcludeItem>
                            <IncludeExcludeItem $active={includeOrExclude === 'excl'} onClick={() => handleIncludeExcludeSelect('excl')}>Exclude</IncludeExcludeItem>
                        </IncludeExcludeWrapper>
                    </div>
                )}
            </StickyFilterWrapper>

            {filterType !== 'none' && (
                <>
                    <ManagerListContainer>
                        {accountManagerSearchResult.slice(0, 50).sort((a, b) => (a.name.toLowerCase() + a.lastname.toLowerCase()).localeCompare(b.name.toLowerCase() + b.lastname.toLowerCase())).map(manager => {
                            return <Checkbox
                                key={manager.id}
                                checked={selectedManagers.some((selectedManager: IFetchAllAccountManagersResponse) => manager.id === selectedManager.id)}
                                onChange={() => handleManagerSelect(manager)}
                                label={`${manager.name} ${manager.lastname}`}
                                wrapperClassName={'manager-checkbox-wrapper'}
                            />
                        })}
                        {accountManagerSearchResult.length > 50 && <AccountManagersNotShown>{accountManagerSearchResult.length - 50} not shown, use search.</AccountManagersNotShown>}
                    </ManagerListContainer>
                    {selectedManagers.length > 0 && <ClearSelected>
                        <span onClick={() => dispatch(clearSelectedManagers())}>Clear Selected</span>
                    </ClearSelected>}
                </>
            )}

        </Dropdown>
        {selectedManagers.length > 0 && <ClearFiltersButton onClick={handleClearFilters}>Clear Filters</ClearFiltersButton>}
    </Wrapper>
}

export default Filters