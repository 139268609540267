import styled from "styled-components";
import {DropdownItem} from "../../../../common/components/dropdown/Dropdown.style";
import {ModalFooter} from "../../../../common/components/modal/container/Container.style";

export const Wrapper = styled.div`
    background: #ffffff;
    height: 100%;
`

export const ContentWrapper = styled.div`
    background: #ffffff;
    padding: 40px 40px 25px;
`

export const PageTitle = styled.h1 `
    font-size: 32px;
    color: ${({theme}) => theme.colors.deepBlue[7]};
    letter-spacing: 0.04em;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
    margin-bottom: 20px;
`
export const DomainUrl = styled.span `
    color: ${({theme}) => theme.colors.performBlue[5]};
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    cursor: pointer;
`

export const DeleteDropdownItem = styled(DropdownItem) `
    color: ${({theme}) => theme.colors.performOrange[3]};
    user-select: none;
    :hover {
        background: ${({theme}) => theme.colors.performOrange[1]};
    }
    :hover:active{
        background-color: ${({theme}) => theme.colors.performOrange[2]};
        color: ${({theme}) => theme.colors.performOrange[7]};
    }
    &.disabled {
        pointer-events: none;
        opacity: .3;
    }
`

export const SelectionPillsContainer = styled.div`
    margin: 15px 0 20px;
`

export const DeleteDomainModalBody = styled.div`
    min-width: 420px;
    max-width: 420px;
    p {
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[7]};
    }
`

export const CustomModalFooter = styled(ModalFooter)`
    justify-content: flex-end;
    border-top: none;
`



