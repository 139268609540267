import styled from "styled-components";

export const ColumnsOptionsBody = styled.div`
    .heading {
        color: #31383B;
        font-size: 12px;
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        margin-bottom: 10px;
    }

    .search {
        input {
            border: none;
            border-bottom: 1px solid #E9ECED;
            outline: 0;
            width: 100%;
            padding: 4px 0;
            height: 30px;
            font-size: 12px;
            ::placeholder {
                color: ${({theme}) => theme.colors.coolGray[4]};
            }
        }
    }

    .list {
        max-height: 330px;
        overflow-y: auto;
    }

    .footer {
        border-top: 1px solid #E9ECED;
        padding: 18px 0;
        .buttons {
            display: flex;
            gap: 15px;
            .button {
                font-size: 12px;
                color: #8799A2;
                font-family: ${({theme}) => theme.fonts.AvenirLight};
                :hover{
                    color: #5A686F;
                }
            }
        }
    }
`;

export const ColumnItem = styled.div`
    display: flex;
    align-items: center;
`;