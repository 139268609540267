import styled from 'styled-components'
import {DropdownToggleButton, DropdownContainer} from "../../dropdown/Dropdown.style";

export const PresetDropdownToggleButton = styled(DropdownToggleButton)`
  width: 100%;
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  min-height: 24px;
  font-family: ${({theme}) => theme.fonts.AvenirRoman};
  font-size: 12px;
  margin-top: 12px;
`

export const PresetsContainer = styled(DropdownContainer)`
    position: absolute;
    h4{
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      font-size: 12px;
      color: ${({theme}) => theme.colors.deepBlue[7]};
      margin: 8px 0;
    }
  z-index: 500;
  
  .break{
    border-bottom: 1px solid ${({theme}) => theme.colors.coolGray[3]} ;
    margin: 7px 0;
  }
`