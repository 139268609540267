import styled, { css } from "styled-components";

interface IFilterStyleProps {
    $opened?: boolean,
    $top?: number,
    $left?: number
  }

export const DropdownWrapper = styled.div`
  position: relative;
  width: max-content;
`;

export const FilterWrapper = styled.div<IFilterStyleProps>`
  border-radius: 8px;
  height: 32px;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({theme}) => theme.colors.deepBlue[4]};
  background-color: white;
  /* position: relative; */
  width: max-content;
  /* display: inline-flex; */
  border: 1px solid ${({theme}) => theme.colors.coolGray[3]};
  .ico-Connected{
    margin-left: 10px;
    color: ${({theme}) => theme.colors.deepBlue[4]};
  }
  
  i{
    font-size: 15px;
    margin-right: 10px;
    transform: translateY(-1px);
  }
  
  .ico-AngleDown, .ico-AngleUp{
    margin-right: 0;
    margin-left: 10px;
  }
  
  .value-selected {
    color: ${({theme}) => theme.colors.performBlue[6]};
    /* width: 50px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .none{
    color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  :hover{
    background-color: ${({theme, $opened}) => !$opened && theme.colors.coolGray[1]};
    cursor: pointer;
  }
  
  :active:hover{
    color: ${({theme}) => theme.colors.deepBlue[8]};
    background-color: ${({theme}) => theme.colors.coolGray[4]};
  }
  
  ${props => props.$opened && css `
    color: ${({theme}) => theme.colors.deepBlue[8]};
    background-color: ${({theme}) => theme.colors.coolGray[4]};
  `}

  @media screen and (max-width: 897px) {
     margin-right: 0;
     /* justify-content: space-between; */
     width: 100%;
  }
`

export const RangeWrapper = styled.div`
  position: absolute;
  top: 35px;
  background-color: #fff;
  z-index: 50;
  padding: 25px;
  border: 1px solid rgb(233, 236, 237);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px;
`;

export const DateFilterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const DatePresetWrapper = styled.div`
  width: 206px;
  display: flex;
  flex-direction: column;
  
  .date-range{
    margin-top: 12px;
  }
`
