import React from "react";
import {useAppSelector} from "../../../../../common/hooks";
import {FilterID} from "filters";

import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import {
    useAccountManagerCacheFilter,
    useAutoFundingCacheFilter,
    useInvoicingCycleCacheFilter,
    useMarketerStatusMultiselectCacheFilter,
    useMarketerTypeCacheFilter,
    useMarketerVerticalCacheFilter,
    usePaymentTermsCacheFilter,
    useSalesExecutiveCacheFilter,
    useSensitivityCacheFilter
} from "../../../../../common/hooks/filters/cache-filters";
import Filter from "../../../../../common/components/filters/filter/Filter";
import FilterSelect from "../../../../../common/components/filters/filter/filter-select/FilterSelect";
import {useReferrerCacheFilter} from "../../../../../common/hooks/filters/cache-filters/useReferrerCacheFilter";
import {useTagsCacheFilter} from "../../../../../common/hooks/filters/cache-filters/useTagsCacheFilter";

interface Props {
    setDiscoverDisplayed: (displayed: boolean) => void
}

const filterList: { id: FilterID, value: string }[] = [
    {
        id: 'auto-funding',
        value: 'Auto-Funding'
    },
    {
        id: "invoicing-cycle",
        value: "Invoicing Cycle"
    },
    {
        id: "account-manager",
        value: "Engine Growth Manager"
    },
    {
        id: "marketer-status-multiselect",
        value: "Marketer Status"
    },
    {
        id: "tags",
        value: "Marketer Tags"
    },
    {
        id: "marketer-type",
        value: "Marketer Type"
    },
    {
        id: "payment-terms",
        value: "Payment Terms"
    },
    {
        id: "referrer",
        value: "Referrer"
    },
    {
        id: 'sales-executive',
        value: 'Sales Executive'
    },
    {
        id: 'sensitivity',
        value: 'Sensitivity'
    },
    {
        id: 'marketer-vertical',
        value: 'Vertical'
    }
]

const Filters: React.FC<Props> = ({setDiscoverDisplayed}) => {
    const pageId = 'admin/users/marketers'
    const {filters} = useAppSelector((state) => state.userPreferences[pageId])


    const autoFundingCacheFilter = useAutoFundingCacheFilter(pageId)
    const invoiceCycleCacheFilter = useInvoicingCycleCacheFilter(pageId, 50)
    const accountManagerCacheFilter = useAccountManagerCacheFilter(pageId, 50)
    const marketerStatusCacheFilter = useMarketerStatusMultiselectCacheFilter(pageId)
    const marketerTypeCacheFilter = useMarketerTypeCacheFilter(pageId);
    const marketerVerticalCacheFilter = useMarketerVerticalCacheFilter(pageId);
    const paymentTermsCacheFilter = usePaymentTermsCacheFilter(pageId, 50);
    const referrerCacheFilter = useReferrerCacheFilter(pageId, 50);
    const salesExecutiveCacheFilter = useSalesExecutiveCacheFilter(pageId, 50);
    const sensitivityCacheFilter = useSensitivityCacheFilter(pageId);
    const tagsCacheFilter = useTagsCacheFilter(pageId, 50);

    const renderFilter = (id: FilterID) => {
        switch (id) {
            case 'auto-funding':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={autoFundingCacheFilter.id}
                        notDisplayed={0}
                        selected={autoFundingCacheFilter.selected.value}
                        filterTitle={'Auto Funding'}
                        hasTopStickyContainer={false}
                        selectedFilterText={autoFundingCacheFilter.selected.value[0] ? autoFundingCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {autoFundingCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.name}
                                checked={autoFundingCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    autoFundingCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
            case 'invoicing-cycle':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={invoiceCycleCacheFilter.id}
                        notDisplayed={invoiceCycleCacheFilter.notDisplayed}
                        selected={invoiceCycleCacheFilter.selected.value}
                        filterTitle={'Invoice Cycle'}
                        hasTopStickyContainer={true}
                        selectedFilterText={invoiceCycleCacheFilter.selected.value[0] ? invoiceCycleCacheFilter.selected.value[0] : ''}
                        include={invoiceCycleCacheFilter.include}
                        search={invoiceCycleCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {invoiceCycleCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option}
                                checked={invoiceCycleCacheFilter.selected.value.some(selected => option === selected)}
                                onChange={() => {
                                    invoiceCycleCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option}
                            />
                        })}
                    </Filter>
                )
            case 'account-manager':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={accountManagerCacheFilter.id}
                        notDisplayed={accountManagerCacheFilter.notDisplayed}
                        selected={accountManagerCacheFilter.selected.value}
                        filterTitle={'Engine Growth Manager'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                        accountManagerCacheFilter.selected.value[0] ?
                            accountManagerCacheFilter.selected.value[0].name + ' ' + accountManagerCacheFilter.selected.value[0].lastname :
                            ''}
                        include={accountManagerCacheFilter.include}
                        search={accountManagerCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {accountManagerCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={accountManagerCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    accountManagerCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                )
            case 'marketer-status-multiselect':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={marketerStatusCacheFilter.id}
                        notDisplayed={0}
                        selected={marketerStatusCacheFilter.selected.value}
                        filterTitle={'Marketer Status'}
                        hasTopStickyContainer={true}
                        include={marketerStatusCacheFilter.include}
                        selectedFilterText={marketerStatusCacheFilter.selected.value[0] ? marketerStatusCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {marketerStatusCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={marketerStatusCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    marketerStatusCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
            case 'marketer-type':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={marketerTypeCacheFilter.id}
                        notDisplayed={0}
                        selected={marketerTypeCacheFilter.selected.value}
                        filterTitle={'Marketer Type'}
                        hasTopStickyContainer={true}
                        include={marketerTypeCacheFilter.include}
                        selectedFilterText={marketerTypeCacheFilter.selected.value[0] ? marketerTypeCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {marketerTypeCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.name}
                                checked={marketerTypeCacheFilter.selected.value.some(selected => option.name === selected.name)}
                                onChange={() => {
                                    marketerTypeCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
            case 'marketer-vertical':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={marketerVerticalCacheFilter.id}
                        notDisplayed={0}
                        selected={marketerVerticalCacheFilter.selected.value}
                        filterTitle={'Vertical'}
                        hasTopStickyContainer={true}
                        include={marketerVerticalCacheFilter.include}
                        selectedFilterText={marketerVerticalCacheFilter.selected.value[0] ? marketerVerticalCacheFilter.selected.value[0].name : ''}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {marketerVerticalCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={marketerVerticalCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    marketerVerticalCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
            case 'payment-terms':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={paymentTermsCacheFilter.id}
                        notDisplayed={paymentTermsCacheFilter.notDisplayed}
                        selected={paymentTermsCacheFilter.selected.value}
                        filterTitle={'Payment Terms'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            paymentTermsCacheFilter.selected.value[0] ?
                                paymentTermsCacheFilter.selected.value[0] :
                                ''}
                        include={paymentTermsCacheFilter.include}
                        search={paymentTermsCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {paymentTermsCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option}
                                checked={paymentTermsCacheFilter.selected.value.some(selected => option === selected)}
                                onChange={() => {
                                    paymentTermsCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option}
                            />
                        })}
                    </Filter>
                )
            case "referrer":
                return (
                    <Filter
                        pageId={pageId}
                        filterId={referrerCacheFilter.id}
                        notDisplayed={referrerCacheFilter.notDisplayed}
                        selected={referrerCacheFilter.selected.value}
                        filterTitle={'Referrer'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            referrerCacheFilter.selected.value[0] ?
                                referrerCacheFilter.selected.value[0].name + ' ' + referrerCacheFilter.selected.value[0].lastname :
                                ''}
                        include={referrerCacheFilter.include}
                        search={referrerCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {referrerCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={referrerCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    referrerCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                )
            case 'sales-executive':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={salesExecutiveCacheFilter.id}
                        notDisplayed={salesExecutiveCacheFilter.notDisplayed}
                        selected={salesExecutiveCacheFilter.selected.value}
                        filterTitle={'Sales Executive'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            salesExecutiveCacheFilter.selected.value[0] ?
                                salesExecutiveCacheFilter.selected.value[0].name + ' ' + salesExecutiveCacheFilter.selected.value[0].lastname :
                                ''}
                        include={salesExecutiveCacheFilter.include}
                        search={salesExecutiveCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {salesExecutiveCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={salesExecutiveCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    salesExecutiveCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={`${option.name} ${option.lastname}`}
                            />
                        })}
                    </Filter>
                )
            case 'sensitivity':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={sensitivityCacheFilter.id}
                        notDisplayed={0}
                        selected={sensitivityCacheFilter.selected.value}
                        filterTitle={'Sensitivity'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            sensitivityCacheFilter.selected.value[0] ?
                                sensitivityCacheFilter.selected.value[0].name :
                                ''}
                        include={sensitivityCacheFilter.include}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {sensitivityCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id + option.name}
                                checked={sensitivityCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    sensitivityCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
            case 'tags':
                return (
                    <Filter
                        pageId={pageId}
                        filterId={tagsCacheFilter.id}
                        notDisplayed={tagsCacheFilter.notDisplayed}
                        selected={tagsCacheFilter.selected.value}
                        filterTitle={'Marketer Tags'}
                        hasTopStickyContainer={true}
                        selectedFilterText={
                            tagsCacheFilter.selected.value[0] ?
                                tagsCacheFilter.selected.value[0].name :
                                ''}
                        include={tagsCacheFilter.include}
                        search={tagsCacheFilter.search}
                        setDisplayDiscoverResults={setDiscoverDisplayed}
                    >
                        {tagsCacheFilter.options.map(option => {
                            return <Checkbox
                                wrapperClassName={'checkbox-filter-wrapper'}
                                key={option.id}
                                checked={tagsCacheFilter.selected.value.some(selected => option.id === selected.id)}
                                onChange={() => {
                                    tagsCacheFilter.selected.setSelected(option)
                                    setDiscoverDisplayed(true)
                                }}
                                label={option.name}
                            />
                        })}
                    </Filter>
                )
        }
    }




    return (
        <FilterSelect allFilters={filterList} pageId={pageId}>
            {Object.keys(filters).map((filterKey: any) => {
                return  <div key={filterKey}>{renderFilter(filterKey)}</div>
            })}
        </FilterSelect>
    )
}
export default Filters