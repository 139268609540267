import React from 'react'
import styled from 'styled-components'
import Checkbox from "../../../../../common/components/checkbox/Checkbox";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {setOffer} from "../create-offer.slice";

export const DomainTrackingCodePlacedCheckbox: React.FC = () => {

    const {offer} = useAppSelector(state => state.createOffer)

    const dispatch = useAppDispatch()

    const handleOnChange = () => {
        dispatch(setOffer({
            ...offer,
            tracking_code_placed: !offer.tracking_code_placed
        }))
    }

    return <WrapperBorderBox>
        <Wrapper checked={offer.tracking_code_placed !== null ? offer.tracking_code_placed : false}>
            <Checkbox
                checked={offer.tracking_code_placed !== null ? offer.tracking_code_placed : false}
                label={offer.tracking_code_placed ? 'I have followed the instructions above and placed the code ' +
                    'required to track my campaign' :
                    'I have placed the code required to track my campaign'}
                wrapperClassName={'checkbox-wrapper'}
                labelClassName={'checkbox-label'}
                checkboxClassName={'checkbox'}
                onChange={handleOnChange}
            />
        </Wrapper>
    </WrapperBorderBox>
}

const Wrapper = styled.div<{checked: boolean}>`
    .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({theme}) => theme.colors.performBlue[1]};
        width: 100%;
        padding: 25px 18px;
        border-radius: 7px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
        
        :hover {
            .checkbox {
                border-color: ${({theme, checked}) => checked ? theme.colors.performBlue[6] : theme.colors.performBlue[4]};
            }
            .ico-Check{
                color: ${({theme}) => theme.colors.performBlue[6]};
            }
        }
        
        :active {
            .checkbox {
                border-color: ${({theme, checked}) => checked ? theme.colors.performBlue[7] : theme.colors.performBlue[5]};
            }
            .ico-Check{
                color: ${({theme}) => theme.colors.performBlue[7]};
            }
        }
    }
    
    .checkbox-label{
        font-size: 16px;
        margin-left: 16px;
        color: ${({theme, checked}) => checked ? theme.colors.performBlue[5] : theme.colors.deepBlue[5]};
    }
    
    .checkbox {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border-color: ${({theme, checked}) => checked ? theme.colors.performBlue[5] : theme.colors.deepBlue[5]};
        
        .ico-Check{
            color: ${({theme}) => theme.colors.performBlue[5]};
            top: 2px;
            left: 2px;
            font-size: 14px;
        }
    }

`

const WrapperBorderBox = styled.div`
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(63, 191, 199, 1) 0%, rgba(29, 114, 141, 1) 100%);
    width: 100%;
    padding: 1px 1px;
    align-items: center;
    
    :hover {
        background: linear-gradient(90deg, rgba(64, 231, 241, 1) 0%, rgba(29, 114, 141, 1) 100%);
    }
    
    :active{
        background: linear-gradient(90deg, rgba(0, 240, 255, 1) 0%, rgba(4, 90, 102, 1) 100%);
        
    }
`

