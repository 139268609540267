import styled from "styled-components";

export const Wrapper = styled.div`
  width: 420px;
  p{
    font-size: 14px;
    color: #31383B;
    font-family: ${({theme}) => theme.fonts.AvenirLight};
  }
  .linebreak{
    white-space: pre-line
  }
`