import React from 'react';
import {useAppSelector} from "../../../../../../../common/hooks";
import {Subtitle} from "../Summary.style";
import TruncateContent from "../../../../../../../common/components/truncate-content/TruncateContent";


interface Props {}

const SelectedIsps: React.FC<Props> = () => {
    const {offer, navState} = useAppSelector(state => state.createOffer)

    if(offer.selected_isp.length === 0 || navState['targeting'] === 'disabled') return null

    return (
        <>
            <Subtitle>{offer.isp_type === 'include' && 'Include'}{offer.isp_type === 'exclude' && 'Exclude'}
                ISPs</Subtitle>
            <TruncateContent wrapperClassName={'truncate-wrapper'}
                             numberOfLines={3}>{offer.selected_isp.map((loc, index) => {
                return <span key={loc.toString()}>{loc.isp}{index !== offer.selected_isp!.length - 1 ? ', ' : ''}</span>
            })}
            </TruncateContent>
        </>
    )
}

export default SelectedIsps