import React, {ReactElement} from "react";
import styled, {css} from 'styled-components'
import {CheckedIcon} from "../../../../../assets/svgs/SvgIcons";

interface Props {
    active: boolean
    onClick: () => void
    disabled: boolean
    wrapperClassName?: string
    children: ReactElement
}

export const SelectBox: React.FC<Props> = (
    {
        active,
        onClick,
        disabled,
        wrapperClassName,
        children
    }) => {

    return <Wrapper
        className={wrapperClassName}
        active={active}
        onClick={onClick}
        disabled={disabled}
    >
        <CheckedIcon display={active ? 'block' : 'none'} className={'checked-icon'}/>
        {children}
    </Wrapper>
}

interface IWrapper{
    active: boolean
    disabled: boolean
}

const Wrapper = styled.div<IWrapper>`
    //width: 100%;
    //min-height: 100%;
    flex: 1;
    border-radius: 24px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 16px;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    flex-direction: column;
    cursor: pointer;
    transition: .2s ease-in-out all;
    overflow: hidden;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    padding: 32px;
    > div {
        max-width: 255px;
    }
    
    .select-box-title{
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 14px;
        margin-bottom: 8px;
    }
    
    .select-box-content{
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        font-size: 12px;
    }

    .checked-icon {
        position: absolute;
        top: 15px;
        right: 18px;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        box-shadow: inset 0 0 0 1px #E7ECEE;
        border-radius: 24px;
        transition: .2s ease-in-out all;
    }

    ${props => props.active ?
            css`
        box-shadow: 0 0 20px rgba(99, 231, 249, 0.34);
        &:before {
            box-shadow: inset 0 0 0 2px ${({theme}) => theme.colors.performBlue[4]};
        }
    `

            :
            css`  :hover {
                &:before {
                    box-shadow: inset 0 0 0 1px ${({theme}) => theme.colors.performBlue[3]};
                }
                box-shadow: 0 0 16px rgba(156, 243, 255, 0.29);
            }`
    }
    
    .recommended{
        color: ${({theme}) => theme.colors.performBlue[5]};
        font-size: 14px;
        margin-top: 8px;
    }
    
    
    ${props => props.disabled && css`
        pointer-events: none;
        border-color: ${({theme}) => theme.colors.coolGray[2]};
        background: ${({theme}) => theme.colors.coolGray[1]};
        
        .title{
            color: ${({theme}) => theme.colors.coolGray[5]};
        }

        color: ${({theme}) => theme.colors.coolGray[5]};
        
        svg{
            filter: grayscale(0.8);
        }
        
    `}
    
    svg{
        margin-bottom: 28px;
        max-width: 100%;
        height: auto;
    }
`