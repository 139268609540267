import React, {ReactElement, useEffect, useState} from "react";
import Modal from "../../../../../common/components/modal/Modal";
import {InputStyled, InputStyledContainer, InputStyledLabel} from "../../../../../common/styled-components";
import {ButtonStyled} from "../../../../../common/styled-components";
import {BtnLoadingSpinner} from "../../../../../common/styled-components";
import {ModalFooter} from "../../../../../common/components/modal/container/Container.style";
import AlertModal from "../../../../../common/components/alert-modal/AlertModal";
import {IAddCustomDomainResponse} from "../CustomDomain.service";
import {useCopy} from "../../../../../common/hooks";
import {AddDomainModalBody} from "./AddDomainModal.style";
import Icon from "../../../../../common/components/icon/Icon";
import Tooltip from "../../../../../common/components/tooltip/Tooltip";

interface Props {
    addDomainModal: {
        opened: boolean
    }
    handleSaveAddNewDomainModal: () => void
    handleCloseAddNewDomainModal: (id: number | undefined) => void
    domainDetails: IAddCustomDomainResponse | undefined
    setDomain: (domain: string) => void
    domain: string
    handleSubmitNewDomain: (domainValue: string) => void
    submitLoading: boolean
    addDomainAlert: {
        opened: boolean
        type: 'success'|'info'
        message: string | ReactElement
    }
    setAddDomainAlert: (alert: {
        opened: boolean
        type: 'success'|'info'
        message: string | ReactElement
    }) => void

    REGEX_DOMAIN_VALIDATION: RegExp
}

const AddDomainModal:React.FC<Props> = (props) => {

    const [nameServerCopy, setNameServerCopy] = useState('')
    const [copied, copy, setCopied] = useCopy(nameServerCopy);

    useEffect(()=> {
        nameServerCopy !== '' && copy();
    },[nameServerCopy])

    return (
        <>
            <Modal opened={props.addDomainModal.opened}
                   title={'Add Domain'}
                   closeModal={() => (props.handleCloseAddNewDomainModal(props.domainDetails?.id))}>
                <AddDomainModalBody>
                    <div className={"row"}>
                        <InputStyledLabel $size={'large'}>Type</InputStyledLabel>
                        <p>Name Server</p>
                    </div>
                    <div className={'row'}>
                        <InputStyledLabel $size={'large'}>
                            Domain
                        </InputStyledLabel>
                        <InputStyledContainer
                            className={`domain-input-container ${props.domain.length < 1 || props.REGEX_DOMAIN_VALIDATION.test(props.domain) ? '' : 'has-error'}`}>
                            <InputStyled
                                onChange={(domain) => props.setDomain(domain.target.value)}
                                value={props.domain}
                                disabled={props.domainDetails?.success}
                                placeholder={'Enter Domain'} />
                            {props.domain.length > 0 &&
                                <ButtonStyled disabled={!props.REGEX_DOMAIN_VALIDATION.test(props.domain) || props.submitLoading} onClick={()=> props.handleSubmitNewDomain(props.domain)}
                                              className={`btn-blue-filled btn-size-sm ${props.domainDetails && props.domainDetails?.success ? "hidden" : ""}`}>
                                    <span className={"btn-label"}>{props.submitLoading && <BtnLoadingSpinner style={{marginRight: 5}} />}Submit</span>
                                </ButtonStyled>
                            }
                            {props.REGEX_DOMAIN_VALIDATION.test(props.domain) || props.domain.length < 1 ? '' :
                                <div className={'help-block'}>Domain name is not valid. Do not include the https
                                    protocol or file path. Valid domains: mydomain.com, www.mydomain.com</div>}
                        </InputStyledContainer>
                    </div>
                    {props.domainDetails?.success &&
                        <div className={'domain-details'}>
                            <p>
                                Add the following records to the DNS configuration of your domain directly in your domain registrar <strong>(Do not use Cloudfare)</strong>. Each DNS service
                                provider will have a different procedure for adding these records. Reference our
                                <a className={'link-orange'} target={'_blank'} href="https://support.performcb.com/support/solutions/articles/4000183699-change-nameservers-with-your-dns-provider-partners"> How To guide</a> to learn more.
                            </p>
                            <div className={'table-wrapper'}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Host</th>
                                        <th>
                                            <label className={'name-server-header'} onClick={()=> props.domainDetails && setNameServerCopy(props.domainDetails.name_servers)}>
                                                Name Servers
                                                <Tooltip width={90} position={'right'} icon={<Icon icon="copy" color="#8799A2" size={10} />}>
                                                    Copy to clipboard
                                                </Tooltip>
                                            </label>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'domain-column'}>
                                            <div>{props.domain}</div>
                                        </td>
                                        <td>
                                            {props.domainDetails.success && props.domainDetails.name_servers.split(',').map((elem, index) => (
                                                <label onClick={()=> setNameServerCopy(elem)} className={'name-server'} key={index}>
                                                    <div>{elem}</div>
                                                    <Tooltip width={90} position={'right'} icon={<Icon icon="copy" color="#8799A2" size={10} />}>
                                                        Copy to clipboard
                                                    </Tooltip>
                                                </label>
                                            ))}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {props.domainDetails && !props.domainDetails?.success &&
                        <div>Domain name is wrong or may be already added. Try again</div>
                    }
                </AddDomainModalBody>
                <ModalFooter>
                    <ButtonStyled
                        className={'btn-cancel'}
                        onClick={() => props.handleCloseAddNewDomainModal(props.domainDetails?.id)}>
                        Cancel
                    </ButtonStyled>
                    <ButtonStyled
                        disabled={!props.domainDetails?.success}
                        className={'btn-orange-filled'}
                        onClick={() => props.handleSaveAddNewDomainModal()}>
                        Save
                    </ButtonStyled>
                </ModalFooter>
            </Modal>
            <AlertModal
                isOpen={props.addDomainAlert.opened}
                type={props.addDomainAlert.type}
                closeModal={() => props.setAddDomainAlert({opened: false, type: 'info', message: ''})}>
                <div>
                    {props.addDomainAlert.message}
                </div>
            </AlertModal>
        </>
    )
}

export default AddDomainModal
