import styled from 'styled-components'

export const Wrapper = styled.div`
  background: white;
  padding: 40px;

  .button-dicover-result{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .leap-partners-search-box {
    overflow: hidden;

    .search-button {
      height: 30px;
    }

    .ico-Exit {
        top: 10px;
      }
  }

  .actions-search {
    display: flex;
    gap: 8px;
  }
`

export const FiltersPaginationSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
    align-items: flex-start;

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 16px;
    }
`;

export const RatingStar=styled.span`
  color: ${({theme}) => theme.colors.coolGray[3]};
  font-size: 17px;
`

export const RatingStarFiled = styled.span`
  color: ${({theme}) => theme.colors.performBlue[5]};
  font-size: 17px;
`

export const CloneModalWrapper = styled.div`
  width: 500px;
`

export const ErrorMsg = styled.label`
  position: absolute;
  bottom: -5px;
  font-size: 10px;
  right: 0;
  color: ${({theme})=>theme.colors.performOrange[4]};
`

export const LeapPartnersWrapper =  styled.div`
    .partner-field {
        display: flex;
        gap: 10px;
        align-items: center;
        pointer-events: all;
        width: calc(100% );
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //padding-right: 17px;

        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            //width: 100%;
        }
        .tooltip {
            display: none;
            cursor: pointer;
            i {
                display: block;
                transform: rotate(-90deg);
                color: ${({theme}) => theme.colors.performOrange[3]};
            }
        }

        &:hover{
            /* width: calc(100% - 20px); */
            .tooltip {
                display: inline-block;
            }
        }

        //&::before{
        //    content: "";
        //    position: absolute;
        //    width: 100%;
        //    height: 100%;
        //    left: 0;
        //}

    }
`