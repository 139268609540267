import React, {useState} from 'react';
import {Footer} from "../../../../../common/components/modalWindow/container/Container.style";
import styled from "styled-components";
import {SelectOption, StyledSelect} from "../../../../../common/components/select/Select";
import {BUDGET_TYPES, BUDGET_AMOUNT_TYPES, TEST_BUDGET_TYPES, DOLLAR_CURRENCY} from "../shared-components/consts";
import Input from "../../../../../common/components/input/Input";
import CurrencyInput from "../../../../../common/components/currency-input/CurrencyInput";
import {IBudgetGroups, ICreateBudgetGroupParams} from "../interfaces/interfaces";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {BtnLoadingSpinner, ButtonStyled} from "../../../../../common/styled-components";
import {Tooltip} from "../shared-components/Shared";
import CreateOfferService from "../CreateOffer.service";
import {fetchBudgetGroups, setOffer, setSelectedBudgetGroupAmountType} from "../create-offer.slice";


export const Wrapper = styled.div`
    .budget-input {
        width: 100%;
        margin: 0;
    }

    .row {
        margin-top: 24px;
    }

    h3 {
        font-family: ${({theme}) => theme.fonts.AvenirHeavy};
        font-size: 14px;
        color: ${({theme}) => theme.colors.deepBlue[5]};
    }

    p {
        color: #8799A1;
    }

    .budget-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        > div {
            flex: 1;
        }
    }

    .tooltip {
        text-align: left !important;

        &.show {
            opacity: 1;
        }

        font-size: 12px;
        padding: 8px 12px;
        border: 1px solid #E9ECED;
        min-width: fit-content;
        border-radius: 8px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

        .multi-line {
            text-align: left;
            padding: 0;
            line-height: 16px;
        }
    }
`

interface Props {
    closeModal: () => void,
    setSelectedBudgetGroup?: (group: IBudgetGroups) => void,
}

const OfferBudgetGroupModal: React.FC<Props> = ({closeModal, setSelectedBudgetGroup}) => {
    const dispatch = useAppDispatch()
    const {token} = useAppSelector(state => state.auth)
    const {offer, selectedBudgetGroupAmountType} = useAppSelector(state => state.createOffer)
    const [loading, setLoading] = useState(false)
    const [groupName, setGroupName] = useState<string>("")
    const [budgetType, setBudgetType] = useState<SelectOption>(BUDGET_TYPES[3])
    const [testBudgetType, setTestBudgetType] = useState<SelectOption>(TEST_BUDGET_TYPES[3])
    const [budget, setBudget] = useState<string>('0')
    const [testBudget, setTestBudget] = useState<string>('0')
    
    // handle budget
    
    const handleBudgetIncrease = () => {
        if (selectedBudgetGroupAmountType.value === 0) {
            setBudget((prevState) => {
                return (parseFloat(prevState) + .01).toFixed(2)
            })
        } else {
            setBudget((prevState) => {
                return (parseFloat(prevState) + 1).toString()
            })
        }
    }
    
    const handleBudgetDecrease = () => {
        if (selectedBudgetGroupAmountType.value === 0) {
            if (parseFloat(budget) > 0) {
                setBudget((prevState) => {
                    return (parseFloat(prevState) - .01).toFixed(2)
                })
            }
        } else {
            if (parseFloat(budget) > 0) {
                setBudget((prevState)=> {
                    return (parseFloat(prevState) - 1).toString()
                })
            }
        }
    }
    
    const handleBudgetChange = (e: string | undefined) => {
        setBudget(e!)
    }
    const handleBudgetTypeChange = (option: SelectOption) => {
        if (option.value === 0) {
            setBudget((parseFloat(budget)).toFixed(2))
        } else {
            setBudget(Math.floor(parseFloat(budget)).toString())
        }
        dispatch(setSelectedBudgetGroupAmountType(option))
    }
    
    // handle test budget
    
    
    const handleTestBudgetIncrease = () => {
        if (selectedBudgetGroupAmountType.value === 0) {
            setTestBudget((prevState)=> {
                return (parseFloat(prevState) + .01).toFixed(2)
            })
        } else {
            setTestBudget((prevState) =>{
                return (parseFloat(prevState) + 1).toString()
            })
        }
    }
    
    const handleTestBudgetDecrease = () => {
        if (selectedBudgetGroupAmountType.value === 0) {
            if (parseFloat(testBudget) > 0) {
                setTestBudget((prevState)=>{
                    return (parseFloat(prevState) - .01).toFixed(2)
                })
            }
        } else {
            if (parseFloat(testBudget) > 0) {
                setTestBudget((prevState) => {
                    return (parseFloat(prevState) - 1).toString()
                })
            }
        }
    }
    
    const handleTestBudgetChange = (e: string | undefined) => {
        setTestBudget(e!)
    }
    
    const handleTestBudgetTypeChange = (option: SelectOption) => {
        if (option.value === 0) {
            setTestBudget((parseFloat(testBudget)).toFixed(2))
        } else {
            setTestBudget(Math.floor(parseFloat(testBudget)).toString())
        }
        dispatch(setSelectedBudgetGroupAmountType(option))
    }
    
    const createBudgetGroup = async () => {
        let params : ICreateBudgetGroupParams = {
            budget: budget,
            budget_type : budgetType.value,
            budget_value_type : selectedBudgetGroupAmountType.value,
            name : groupName,
            test_budget_link: testBudget,
            test_budget_link_type : testBudgetType.value
        }
        setLoading(true)
        try{
            const {data} = await CreateOfferService.createBudgetGroup(token, params)
            
            dispatch(setOffer({
                ...offer,
                budget_group: data,
                budget_group_id : data.id
            }))
            dispatch(fetchBudgetGroups({}))
            closeModal()
            setLoading(false)
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }
    }
    
    return (
        <Wrapper>
            <Input
                label={"Group Name"}
                placeholder={"Enter group name"}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}/>
            <div className="row">
                <h3>Budget Information</h3>
                <p>Define how much would you like to spend. This defines the overall budget for all Offers inside of
                    this Group.</p>
            </div>
            <div className="row budget-select">
                <StyledSelect options={BUDGET_TYPES}
                              selectLabel={"Budget"}
                              classNamePrefix={'styled-select'}
                              onChange={(e) => setBudgetType(e as SelectOption)}
                              value={budgetType}/>
                {
                    budgetType.value !== 'unlimited' &&
                    <>
                        <CurrencyInput
                            groupSeparator={","}
                            decimalSeparator={"."}
                            decimalsLimit={2}
                            step={selectedBudgetGroupAmountType.value === 0 ? .01 : 1}
                            placeholder={selectedBudgetGroupAmountType.value === 0 ? '0.00' : '0'}
                            prefix={selectedBudgetGroupAmountType.value === 0 ? '$' : ''}
                            value={budget}
                            allowDecimals={selectedBudgetGroupAmountType.value === 0}
                            allowNegativeValue={false}
                            decimalScale={selectedBudgetGroupAmountType.value === 0 ? 2 : 0}
                            inputControl={{up: handleBudgetIncrease, down: handleBudgetDecrease}}
                            onValueChange={(e) => handleBudgetChange(e)}
                        />
                        <StyledSelect options={BUDGET_AMOUNT_TYPES}
                                      placeholder={DOLLAR_CURRENCY}
                                      onChange={(e) => handleBudgetTypeChange(e as SelectOption)}
                        />
                    </>
                }
            </div>
            <div className={"budget-select"}>
                <StyledSelect options={TEST_BUDGET_TYPES}
                              value={testBudgetType}
                              onChange={(e) => setTestBudgetType(e as SelectOption)}
                              selectLabel={<>Test Budget for new Links {Tooltip(150, "nestse")} </>}/>
                {
                    testBudgetType.value !== 'disabled' &&
                    <>
                        <CurrencyInput
                            groupSeparator={","}
                            decimalSeparator={"."}
                            decimalsLimit={2}
                            step={selectedBudgetGroupAmountType.value === 0 ? .01 : 1}
                            placeholder={selectedBudgetGroupAmountType.value === 0 ? '0.00' : '0'}
                            prefix={selectedBudgetGroupAmountType.value === 0 ? '$' : ''}
                            value={testBudget}
                            allowDecimals={selectedBudgetGroupAmountType.value === 0}
                            allowNegativeValue={false}
                            inputControl={{up: handleTestBudgetIncrease, down: handleTestBudgetDecrease}}
                            onValueChange={(e)=> handleTestBudgetChange(e)}
                        />
                        {
                            budgetType.value === 'unlimited' &&
                            <StyledSelect options={BUDGET_AMOUNT_TYPES}
                                          placeholder={DOLLAR_CURRENCY}
                                          onChange={(e) => handleTestBudgetTypeChange(e as SelectOption)}/>
                        }
                    </>
                }
            </div>
            <Footer>
                <ButtonStyled onClick={closeModal} className={"btn-cancel"}>Cancel</ButtonStyled>
                <ButtonStyled onClick={createBudgetGroup}
                              className={"btn-orange-filled"}
                              disabled={loading || groupName === ''}>
                    {loading && <BtnLoadingSpinner/>}
                    <span className={"btn-label"}>Create</span>
                </ButtonStyled>
            </Footer>
        </Wrapper>
    );
};

export default OfferBudgetGroupModal