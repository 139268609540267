import {api} from './api'

interface AddPhoneNumberParams {
    phone_number: string,
    dial_code: string
}

interface SendOtpSmsParams {
    id?: number,
    phone_number?: string,
    dial_code?: string
}

interface VerifyPhoneNumberParams {
    phone_number: string,
    totp: string
}

interface VerifyOtpParams {
    id?: number
    phone_number?: string,
    dial_code?: string,
    otp: string,
    logged_in?: boolean,
    remember_device?: boolean
}

interface DeletePhoneNumberParams {
    user_id: number,
    id: number
}

interface TwoFactorAuthenticationResponse {
    message: string,
    success: boolean,
    code: number,
    sensitive_data: string,
    token?: string,
    data?: any
}

export interface FetchUsersDevicesResponse {
    id: number,
    phone_number: string,
    user_id: number,
    created_at: Date,
    method: string
}


class TwoFactorAuthenticationService {
    static addPhoneNumber = async (token: string, params: AddPhoneNumberParams) => {
        return await api.post<TwoFactorAuthenticationResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'add-destination-phone-number'
            ].join('/'),
            {
                ...params,
                method: 'SMS'
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static sendOtpSms = async (token: string, params: SendOtpSmsParams) => {
        return await api.post<TwoFactorAuthenticationResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'send-sms'
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static verifyPhoneNumber = async (token: string, params: VerifyPhoneNumberParams) => {
        return await api.post<TwoFactorAuthenticationResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'verify-phone-number'
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static deletePhoneNumber = async (token: string, params: DeletePhoneNumberParams) => {
        return await api.delete<TwoFactorAuthenticationResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'delete-phone-number'
            ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                        'Content-Type': 'application/json'
                },
                data: {
                    ...params
                }
            },


        )
    }

    static verifyOtp = async (token: string, params: VerifyOtpParams) => {
        return await api.post<TwoFactorAuthenticationResponse>([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'verify-otp'
            ].join('/'),
            {
                ...params
            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    static fetchUsersDevices = async (token: string) => {
        return await api.get<FetchUsersDevicesResponse[]>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            'get-verified-devices'
        ].join('/'),
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    }

    static lostDevice = async (token: string) => {
        return await api.post([
                process.env.REACT_APP_API_BASE_URL,
                process.env.REACT_APP_API_URL,
                process.env.REACT_APP_API_V1_URL,
                'lost-device'
            ].join('/'),
            {

            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
}

export default TwoFactorAuthenticationService