import React, {useEffect, useRef, useState} from "react";
import {TagAddButton, TagAddButtonWrapper, TagLabel, TagsListWrapper} from './TagsList.style';
import {useAppSelector, useOnClickOutside} from "../../hooks";
import TagsListService from '../../services/tags-list.service'
import SelectionPills from "../selection-pills/SelectionPills";
import {ITags, ITags2} from "../../models/tag.type";
import DropdownMultiOptions from "../dropdown-multi-options/DropdownMultiOptions";
import AdvertisersService, {IAssignTagsParams} from "../../../modules/admin/pages/advertisers/Advertisers.service";
import {format} from "date-fns";

interface IAdvertiserEditTag {
    id: number | undefined,
    type: string | undefined
}

const TagsList: React.FC<IAdvertiserEditTag> = ({id, type}: IAdvertiserEditTag) => {
    const [selectedTags, setSelectedTags] = useState<ITags[]>([])
    const [allTags, setAllTags] = useState<ITags2[]>([])
    const [openTags, setOpenTags] = useState(false)
    const {token} = useAppSelector(state => state.auth)
    const [loadingTags, setLoadingTags] = useState(true)
    const tagsRef = useRef(null);

    //Component dismount cleanup
    useEffect(() => {
        return () => {
            setSelectedTags([])
            setAllTags([])
        }
    }, [])

    const fetchTags = async () => {
        setLoadingTags(true)
        let [tagsSelectedResponse, tagsAllResponse] = await Promise.all([
            TagsListService.fetchSelectedTags(token, id, type),
            TagsListService.fetchAllTags(token)
        ]);
        setSelectedTags(tagsSelectedResponse.data.data)
        setAllTags(tagsAllResponse.data.data)
        setLoadingTags(false)
    }
    const addTag = async (item: any) => {
        let tempTag: ITags = {
            id: item[0].id,
            tag_id: item[0].id,
            property_id: id,
            type: "advertiser",
            created_at: format(new Date(),"yyyy-mm-dd hh:mm:ss"),
            description: item[0].name,
            name: item[0].name,
            updated_at: format(new Date(),"yyyy-mm-dd hh:mm:ss"),
        }
        let newTag: IAssignTagsParams = {
            property_ids: [tempTag.property_id],
            type: tempTag.type,
            tag_ids: [tempTag.tag_id]
        }
        try {
            // await TagsListService.addTag(token, [tempTag.property_id], tempTag.type, [tempTag.tag_id])
            await AdvertisersService.assignTags(token, newTag)
            setSelectedTags([...selectedTags, tempTag])
        } catch (e) {
            return e
        }
    }

    const removeTag = async (tag: ITags) => {
        await TagsListService.removeTag(token, id, tag.type, tag.tag_id)
        await fetchTags()
    }

    useEffect(() => {
        token !== "" && id && fetchTags();
    }, [token, id]);
    useOnClickOutside(tagsRef, () => setOpenTags(false))
    return (
        <TagsListWrapper>
                <TagLabel>Tags:</TagLabel>
                    {loadingTags ?  <div className="loading-dots">
                        <span></span><span></span><span></span>
                    </div> :
                <>
                    {selectedTags.map(tag => <SelectionPills key={tag.id} text={tag.name} onRemove={() => removeTag(tag)}/>)}
                    <TagAddButtonWrapper ref={tagsRef}>
                        <TagAddButton className={"ico-PlusCircle"}
                                      onClick={() => setOpenTags(!openTags)}
                                      $open={openTags}/>
                        {openTags && <DropdownMultiOptions isSearchable={true}
                                                           setOpen={setOpenTags}
                                                           setSelection={addTag}
                                                           selection={selectedTags}
                                                           open={openTags}
                                                           options={allTags}
                                                           width={"200px"}
                                                           isActiveOptionDisabled={true}
                        />}
                    </TagAddButtonWrapper>
                </>
            }

        </TagsListWrapper>
    )
}

export default TagsList