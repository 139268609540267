import styled from "styled-components";

export const Wrapper = styled.div`
    //background: white;
    //padding: 40px;
    /* height: 100%; */

    //.bottom-pagination {
    //  display: flex;
    //  flex-direction: row-reverse;
    //}
    .blue-text {
        color: ${({theme}) => theme.colors.performBlue[5]};
        cursor: pointer;
        text-decoration: none;
        &:hover{
            color: ${({theme}) => theme.colors.performOrange[6]};
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const TopSection = styled.div`
    display: flex;
    //gap: 8px;
    .search{
        margin-right: 8px;
        input{
            height: 30px;
            padding-left: 10px;
        }

        .search-button{
            height: 28px;
            width: 31px;
            font-size: 10px;
            right: 0;
        }

        .ico-Exit{
            top: 8px;
            right: 33px;
            font-size: 14px;
        }
    }

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 15px;

        .search{
            margin-right: 0;
            display: inline-flex;
        }

        >div:last-child{
            display: flex;
        }
        >button:last-child{
            display: flex;
        }
    }
`

export const FilterWrapper = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;

    @media screen and (max-width: 897px) {
        flex-direction: column;
        gap: 15px;
    }
`