import React, {useRef, useState} from 'react'
import {
    DateFilterWrapper,
    DatePresetWrapper,
    DropdownWrapper,
    FilterContainer,
    FilterWrapper
} from "./DateRangeFilter.style";
import {format} from "date-fns";
import DropdownPreset from "../../../../../../common/components/range-date-picker/dropdown-preset/DropdownPreset";
import DateRangeInput from "../../../../../../common/components/daterange-input/DateRangeInput";
import RangeDatePicker from "../../../../../../common/components/range-date-picker/RangeDatePicker";
import {useAppDispatch, useAppSelector, useOnClickOutside, useSimulateMouseClick} from "../../../../../../common/hooks";
import {IPreset} from "../../../../../../common/components/range-date-picker/presets/presets.props";

import {setOffersDateFrom, setOffersDateRangePreset, setOffersDateTo} from "../Offers.slice";
import {ColumnApi, GridApi} from "ag-grid-community";

interface Props {
    fetchData: (direction?: string | null, sort_by?: string | null) => void
    gridColumnApi? : ColumnApi | undefined,
    gridApi? : GridApi | undefined,
}

const DateRangeFilter: React.FC<Props> = ({fetchData, gridColumnApi, gridApi}) => {
    const [dateFilterOpened, setDateFilterOpened] = useState(false)
    const [presetDropdownOpened, setPresetDropdownOpened] = useState(false)

    const dateFilterRef = useRef<HTMLDivElement | null>(null)
    const presetContainerRef = useRef<HTMLDivElement | null>(null)

    const tempDateFrom = useRef<Date | null | undefined>(null)
    const tempDateTo = useRef<Date | null | undefined>(null)

    const {date_from, date_to, date_range_preset} = useAppSelector(state => state.offers)
    const dispatch = useAppDispatch()

    useOnClickOutside(dateFilterRef, (e) => {
        if(presetContainerRef.current?.contains(e.target as Node)){
            return
        }
        if(dateFilterOpened){
            if(tempDateFrom || tempDateTo){
                if (tempDateFrom.current !== date_from || tempDateTo.current !== date_to) {
                    const direction = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].sort
                    const sort_by = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].colId
                    fetchData(direction, sort_by)
                    tempDateFrom.current = date_from
                    tempDateTo.current = date_to
                }
            }
        }
        setPresetDropdownOpened(false)
        setDateFilterOpened(false)
    })

    useSimulateMouseClick(document.getElementById('filter-container'), [date_from, date_to])

    const setDateRangePreset = (preset: IPreset) => {
        dispatch(setOffersDateRangePreset(preset))
    }

    const setDateFrom = (date: Date | null | undefined) => {
        dispatch(setOffersDateFrom(date))
    }

    const setDateTo = (date: Date | null | undefined) => {
        dispatch(setOffersDateTo(date))
    }

    return (
        <DropdownWrapper ref={dateFilterRef}>
            <FilterWrapper
                onClick={() => setDateFilterOpened(!dateFilterOpened)}
                $opened={dateFilterOpened}

            >
                <i className={'ico-Calendar'}/><span>{format(date_from ? typeof date_from === 'string' ? new Date(date_from) : date_from : new Date(), 'MMM dd, y')} - {format(date_to ? typeof date_to === 'string' ? new Date(date_to) : date_to : new Date(), 'MMM dd, y')}</span>

            </FilterWrapper>
            <FilterContainer id={"filter-container"}  opened={dateFilterOpened} >
                <DateFilterWrapper>
                    <DatePresetWrapper>
                        <h3>Date Presets</h3>
                        <DropdownPreset
                            containerRef={presetContainerRef}
                            preset={date_range_preset}
                            setPreset={setDateRangePreset}
                            opened={presetDropdownOpened}
                            setOpened={setPresetDropdownOpened}
                        />
                        <div className="date-range">
                            <DateRangeInput startDate={date_from} setStartDate={setDateFrom} endDate={date_to} setEndDate={setDateTo}/>
                        </div>
                    </DatePresetWrapper>
                    <RangeDatePicker
                        startDate={typeof date_from === 'string' ? new Date(date_from) : date_from}
                        setStartDate={setDateFrom}
                        endDate={typeof date_to === 'string' ? new Date(date_to) : date_to}
                        setEndDate={setDateTo}
                        preset={date_range_preset.value}
                        setPreset={setDateRangePreset}
                    />
                </DateFilterWrapper>
            </FilterContainer>
        </DropdownWrapper>
    )
}

export default DateRangeFilter