import React, {useEffect, useMemo, useRef, useState} from "react";
import qs from 'qs'
import ReactDOMServer from "react-dom/server";
import axios, {CancelTokenSource} from "axios";
import {format} from "date-fns";
import {Wrapper} from "./Offers.style";
import DateRangeFilter from "./DateRangeFilter/DateRangeFilter";
import Search from "../../../../../common/components/search/Search";
import Actions from './Actions/Actions'
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import {
    setOffersDateFrom,
    setOffersDateRangePreset,
    setOffersDateTo,
    setOffersSearchValue,
    setPerPage
} from "./Offers.slice";
import OffersService, {
    IFetchAllTagsResponse,
    IFetchCampaignsParams,
    IFetchCampaignsResponse,
    IMap
} from "./Offers.service";
import {tokenStore, userStore} from "../../../../../store/auth.slice";
import Pagination from "../../../../../common/components/data-grid/pagination/Pagination";
import {
    ButtonStyled,
    TableToolbarBottom,
    TableToolbarTop,
    TableToolbarTopBottomRow,
    TableToolbarTopUpperRow,
    TableWrapper
} from '../../../../../common/styled-components'
import {withPageContainer} from "../../../../../common/hoc/withPageContainer/withPageContainer";
import Filters from "./Filters/Filters";
import Icon from "../../../../../common/components/icon/Icon";

import {
    IPagePresetsID,
    saveUserDatePreferences,
    saveUserPreferences,
    saveUserTablePreferences
} from "../../../../../store/userPreferences.slice";
import {
    ColDef,
    Column,
    ColumnApi,
    ColumnState,
    DragStoppedEvent,
    GridApi,
    GridReadyEvent,
    IHeaderParams,
    RowClickedEvent
} from "ag-grid-community";
import DataGrid from "../../../../../common/components/data-grid/DataGrid";
import GridSortableHeader from "../../../../../common/components/data-grid/grid-sortable-header/GridSortableHeader";
import {useMediaRepFilter} from "../../../../../common/hooks/filters/useMediaRepFilter";
import {formatNumberToCurrency, formatNumberToPercentage} from "../../../../../common/utils";
import GridRowOptions from "../../../../../common/components/data-grid/grid-row-options/GridRowOptions";
import {DropdownItem} from "../../../../../common/components/dropdown/Dropdown.style";
import {
    IWithUserPreferencesProps,
    withUserPreferences
} from "../../../../../common/hoc/withUserPreferences/withUserPreferences";

const pricing_models: { value: undefined | string, name: string }[] = [
    {value: undefined, name: "All Types"},
    {value: "cpa", name: "CPA"},
    {value: "cpc", name: "CPC Bid"},
];

const targetOrigin = process.env.REACT_APP_API_BASE_URL
const pageId: IPagePresetsID = 'admin/offers'

const Offers: React.FC<IWithUserPreferencesProps> = ({
                                                         filterPreferences,
                                                         tablePreferences,
                                                         datePreferences,
                                                         saveTablePreferences
                                                     }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenStore)
    const user = useAppSelector(userStore);
    const {offers_search_value, date_from, date_to, per_page, date_range_preset} = useAppSelector(state => state.offers)
    const [loading, setLoading] = useState<boolean>(true)
    const [discoverDisplayed, setDiscoverDisplayed] = useState(false);

    //Pagination data
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [from, setFrom] = useState<number>(0)
    const [to, setTo] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [lastPage, setLastPage] = useState<number>(0)


    // table api
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
    const [gridApi, setGridApi] = useState<GridApi>()

    const [campaigns, setCampaigns] = useState<IFetchCampaignsResponse[]>([])
    const [allTags, setAllTags] = useState<IFetchAllTagsResponse[]>([])

    // selected checkboxes
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState<IMap>({})
    const setSearchValue = (value: string) => {
        cancelTokenSource.current?.cancel();
        dispatch(setOffersSearchValue(value))
    }
    const media_rep_users = useMediaRepFilter(pageId).data;
    const filterRef = useRef(filterPreferences)
    const cancelTokenSource = useRef<CancelTokenSource | undefined>();

    useEffect(()=>{
       if (datePreferences){
           datePreferences.dateTo && dispatch(setOffersDateTo(datePreferences.dateTo))
           datePreferences.dateFrom && dispatch(setOffersDateFrom(datePreferences.dateFrom))
           datePreferences.datePreset && dispatch(setOffersDateRangePreset(datePreferences.datePreset))
       }
    },[])

    useEffect(() => {
        // stupid checks for displaying discover button
        let filterPrefValues = Object.values(filterPreferences)
        let filterRefValues = Object.values(filterRef.current)
        // display button on filter chip delete.
        if (Object.keys(filterPreferences).length < Object.keys(filterRef.current).length) {
            setDiscoverDisplayed(true)
        }
        // hide button in case adding new filter chip and selecting something from dropdown and then
        // unchecking selected (in same iteration)
        else if (Object.keys(filterPreferences).length !== Object.keys(filterRef.current).length) {
            let diff = filterPrefValues.filter(({ selected: selected1 }) =>
                !filterRefValues.some(({ selected: selected2 }) => selected2 === selected1));
            let dd = diff.filter(item => item.selected.length === 0)
            if (diff.length === dd.length){
                setDiscoverDisplayed(false)
            }
        }
    }, [
        filterPreferences
    ])

    useEffect(() =>{
        dispatch(saveUserDatePreferences({pageId : pageId,date: {
                dateTo : date_to,
                dateFrom: date_from,
                datePreset: date_range_preset
            } }))
    },[date_from, date_to, per_page, date_range_preset])

    const setFilterParams = (direction?: string | null, sort_by?: string | null) => {
        let params: IFetchCampaignsParams = {
            with_upsells: true,
            requested_by_page: 'admin_offers',
            page: currentPage,
            sort_by: sort_by ? sort_by : 'id',
            order: direction ? direction : 'desc',
            from: date_from ? format(new Date(date_from), 'yyyy-MM-dd') : new Date(),
            to: date_to ? format(new Date(date_to), 'yyyy-MM-dd') : new Date(),
            original_match: 1,
            search: offers_search_value,
            paginate_by: per_page
        }
        if (filterPreferences['country-availability'] && filterPreferences['country-availability'].selected.length > 0){
            params = {
                ...params,
                country: {
                    ids: [...filterPreferences['country-availability'].selected.map(selected => {
                        return (!('code_3c' in selected) ? 'US-' : '') + selected.code
                    })],
                    include: filterPreferences['country-availability'].include
                }
            }
        }
        if (filterPreferences['offer-status'] && filterPreferences['offer-status'].selected.length > 0) {
            params = {
                ...params,
                status: {
                    ids: [
                        ...filterPreferences['offer-status'].selected.map(status => {
                            return {
                                type: status.id,
                                rep: status.rep,
                                media: status.media,
                                compliance: status.compliance
                            }
                        })
                    ],
                    include: filterPreferences['offer-status'].include,
                }
            }

        }
        if (filterPreferences['offer-visibility'] && filterPreferences['offer-visibility'].selected.length > 0) {
            params = {
                ...params,
                offer_visibility: {
                    ids: [...filterPreferences['offer-visibility'].selected.map(((offer: any) => {
                        return {
                            star: !offer.id ? offer.star : null,
                            visibility: !offer.id ? offer.visibility.id : null,
                            visibility_preset_id: offer.id ? offer.id : null
                        }
                    }))
                    ],
                    include: filterPreferences['offer-visibility'].include
                }
            }
        }
        if (filterPreferences['offer-type'] && filterPreferences['offer-type'].selected.length > 0) {
            params = {
                ...params,
                offer_type: [...filterPreferences['offer-type'].selected.map(selected => selected.id)
                    .filter(type => type === 'cpa' || type === 'cpc')],
                offerTypesInclude: filterPreferences['offer-type'].include,
                detailed_campaign_type: [...filterPreferences['offer-type'].selected.map(selected => selected.id)
                    .filter(type => type !== 'cpa' && type !== 'cpc')]
            }
        }

        // if (filterPreferences['tracking-system'] && filterPreferences['tracking-system'].selected.length > 0) {
        //     params = {
        //         ...params,
        //         tracking_systems: {
        //             ids: [...filterPreferences['tracking-system'].selected.map(selected => selected.name)],
        //             include: filterPreferences['tracking-system'].include
        //         }
        //     }
        // }

        if (filterPreferences['detailed-statuses'] && filterPreferences['detailed-statuses'].selected.length > 0) {
            params = {
                ...params,
                other_statuses: {
                    ids: [...filterPreferences['detailed-statuses'].selected.map(selected => selected.id)],
                    include: filterPreferences['detailed-statuses'].include
                }
            }
        }
        if (filterPreferences['tags'] && filterPreferences['tags'].selected.length > 0) {
            console.log(filterPreferences['tags'])
            params = {
                ...params,
                tags: {
                    ids: filterPreferences['tags'].match !== 'empty' ? [...filterPreferences['tags']
                        .selected.map(selected => selected.id)] : null,
                    include: filterPreferences['tags'].include,
                    match: filterPreferences['tags'].match
                }
            }
        }
        if (filterPreferences['niche'] && filterPreferences['niche'].selected.length > 0) {
            params = {
                ...params,
                niche: {
                    ids: [...filterPreferences['niche'].selected.map(selected => selected.id)],
                    include: filterPreferences['niche'].include
                }
            }
        }
        if (filterPreferences['niche-group'] && filterPreferences['niche-group'].selected.length > 0) {
            params = {
                ...params,
                niche_group: {
                    ids: [...filterPreferences['niche-group'].selected.map(selected => selected.id)],
                    include: filterPreferences['niche-group'].include
                }
            }
        }

        if (filterPreferences['creative-type'] && filterPreferences['creative-type'].selected.length > 0) {
            params = {
                ...params,
                creative_type: {
                    ids: [...filterPreferences['creative-type'].selected.map(selected => selected.id)],
                    include: filterPreferences['creative-type'].include
                }
            }
        }
        if (filterPreferences['operating-system'] && filterPreferences['operating-system'].selected.length > 0) {
            params = {
                ...params,
                operating_system: {
                    ids: [...filterPreferences['operating-system'].selected.map(selected => selected.id)],
                    include: filterPreferences['operating-system'].include
                }
            }
        }
        if (filterPreferences['device-type'] && filterPreferences['device-type'].selected.length > 0) {
            params = {
                ...params,
                device: {
                    ids: [...filterPreferences['device-type'].selected.map(selected => selected.id)],
                    include: filterPreferences['device-type'].include
                }
            }
        }

        if (filterPreferences['compliance-rep'] && filterPreferences['compliance-rep'].selected.length > 0) {
            params = {
                ...params,
                compliance_rep: {
                    ids: [...filterPreferences['compliance-rep'].selected.map(selected => selected.id)],
                    include: filterPreferences['compliance-rep'].include
                }
            }
        }
        if (filterPreferences['media-rep'] && filterPreferences['media-rep'].selected.length > 0) {
            params = {
                ...params,
                media_rep: {
                    ids: [...filterPreferences['media-rep'].selected.map(selected => selected.id)],
                    include: filterPreferences['media-rep'].include
                }
            }
        }
        if (filterPreferences['account-manager'] && filterPreferences['account-manager'].selected.length > 0) {
            params = {
                ...params,
                account_manager: {
                    ids: [...filterPreferences['account-manager'].selected.map(selected => selected.id)],
                    include: filterPreferences['account-manager'].include
                }
            }
        }
        if (filterPreferences['sales-executive'] && filterPreferences['sales-executive'].selected.length > 0) {
            params = {
                ...params,
                sales_executives: {
                    ids: [...filterPreferences['sales-executive'].selected.map(selected => selected.id)],
                    include: filterPreferences['sales-executive'].include
                }
            }
        }
        if (filterPreferences['advertiser'] && filterPreferences['advertiser'].selected.length > 0) {
            params = {
                ...params,
                advertiser_id: {
                    ids: [...filterPreferences['advertiser'].selected.map(selected => selected.id)],
                    include: filterPreferences['advertiser'].include
                }
            }
        }
        if (currentPage > 1) {
            params.page = currentPage;
        }
        return params
    }

    const fetchAllTags = async () => {
        try {
            const {data} = await OffersService.fetchAllTags(token)
            setAllTags(data)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchCampaigns = async (direction?: string | null, sort_by?: string | null, selectNodes : boolean = true ) => {
        try {
            setLoading(true)
            setDiscoverDisplayed(false)
            gridApi?.showLoadingOverlay()
            const params = setFilterParams(direction, sort_by)
            cancelTokenSource.current = axios.CancelToken.source()
            const {data} = await OffersService.fetchCampaigns(params, token, cancelTokenSource.current)
            dispatch(setPerPage(data.per_page))
            setCurrentPage(data.current_page)
            gridApi?.setRowData(data.data)
            setFrom(data.from)
            setTo(data.to)
            setTotal(data.total)
            setLastPage(data.last_page)
            if (selectNodes){
                gridApi?.forEachNode((node) => {
                    node.setSelected(getSelectedRows().some((row: IFetchCampaignsResponse) => {
                            return node.data.id === row.id
                        }
                    ))
                })
            }
            setLoading(false);
        } catch (e) {
            // silent catch because axios cancel request throws exception
        }
    }

    useEffect(() => {
        if (token && gridColumnApi) {
            cancelTokenSource.current?.cancel();
            fetchAllTags()
            const direction = gridColumnApi.getColumnState().filter(column => column.sort !== null)[0].sort
            const sort_by = gridColumnApi.getColumnState().filter(column => column.sort !== null)[0].colId
            fetchCampaigns(direction, sort_by)
        }
    }, [token, gridColumnApi, per_page, currentPage])

    useEffect(() => {
        cancelTokenSource.current?.cancel();
        if (currentPage === 1) {
            fetchCampaigns()
        } else {
            setCurrentPage(1)
        }
    }, [offers_search_value])

    const exportCsv = () => {
        const params = setFilterParams()
        const api = [process.env.REACT_APP_API_BASE_URL,
                    process.env.REACT_APP_API_URL,
                    process.env.REACT_APP_API_V3_URL].join("/");
        const paramsUrl = new URLSearchParams(qs.stringify(params));
        window.open(api + "/campaigns?" + paramsUrl + "&output=csv", "_blank")
    }

    const handleDiscover = () => {
        if (currentPage === 1) {
            cancelTokenSource.current?.cancel();
            const direction = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].sort
            const sort_by = gridColumnApi?.getColumnState().filter(column => column.sort !== null)[0].colId
            fetchCampaigns(direction, sort_by)
        } else
            setCurrentPage(1)
        dispatch(saveUserPreferences(pageId))
        filterRef.current = filterPreferences
        setDiscoverDisplayed(false)
    }

    const getMediaRepFromCampaign = (campaign: IFetchCampaignsResponse, media_rep_users: any[]) => {
        if (campaign.media_operator == null) {
            return "-";
        }
        let media_name = '';
        if (typeof media_rep_users != 'undefined') {
            media_rep_users.forEach((value: any, key: any) => {
                if (value.id == campaign.media_operator) {
                    media_name = value.name + " " + value.lastname;
                }
            });
        }
        return media_name;
    }

    const getNameByValue = function (model: { value: undefined | string, name: string }[], value: string) {
        if (value && value !== "") {
            for (let i = 0; i <= model.length; i++) {
                if (model[i].value == value && model[i].name) {
                    return model[i].name;
                }
            }
        }
    };

    const visitUrl = (destination_url: string) => {
        if (!destination_url) {
            return;
        }
        const arr = ['{linkid}', '{clickid}', '{subid1}', '{subid2}', '{subid3}', '{subid4}', '{subid5}', '{subid6}',
            '{creative_id}', '{source_id}', '{pid}'];
        arr.forEach(function (a) {
            const count = a.length;
            const offset = destination_url.indexOf(a);
            if (offset > -1) {
                destination_url = destination_url.substring(0, offset) + destination_url.substr(offset + count);
                //edge case
                if (destination_url.indexOf(a) != -1) {
                    destination_url = destination_url.substring(0, destination_url.length - count);
                }
            }
        });
        return destination_url;
    };

    const angularAction = (id: number, campaign: IFetchCampaignsResponse) => {
        targetOrigin && window.parent.postMessage(
            {
                id: id,
                params: {campaign}
            }, targetOrigin);
    }

    //Define columns for the Advertisers table
    const defaultColumnDefs = useMemo<ColDef>(() => ({
        resizable: true,
        minWidth: 50
    }), [])

    const columnDefs = useMemo<ColDef[]>(() => [
        {
            colId: "check",
            resizable: false,
            pinned: "left",
            lockPinned: true,
            lockPosition: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 50,
        },
        {
            pinned: 'left',
            colId: 'id',
            field: 'id',
            headerName: 'Offer ID',
            width: 100,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The unique identification number assigned to an offer.
                                </div>
                            )}>
                    <GridSortableHeader headerParams={data}>
                    OFFER ID
                </GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                return <span>{data.id}</span>
            },
        },
        {
            pinned: 'left',
            colId: 'name',
            field: 'name',
            headerName: 'Offer Name',
            width: 293,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                     data-html={true}
                     data-padding={'30px'}
                     data-tip={ReactDOMServer.renderToStaticMarkup(
                         <div style={{maxWidth: 170}}>
                             The offer name will show through to partners.
                         </div>
                     )}>
                <GridSortableHeader
                    headerParams={data}
                >OFFER NAME</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                // let data: IFetchCampaignsResponse = row.data;
                return <span onClick={() => {
                    targetOrigin && window.parent.postMessage({id: 7, data: data}, targetOrigin)
                }} className="blue-text">{data.long_name}</span>
            }
        },
        {
            colId: 'created_at',
            field: 'created_at',
            headerName: 'Date Created',
            width: 95,
            minWidth: 50,
            sortable: true,
            sort: 'desc',
            comparator: () => 0,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The day this offer was created.
                                </div>
                            )}>
                <GridSortableHeader
                    headerParams={data}
                >DATE CREATED</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                let date = data.created_at.replace(/\-/g, "/");
                return <span>{data.created_at ? format(new Date(date), "MM/dd/yyyy") : ""}</span>
            }
        },
        {
            colId: 'detailed_col',
            field: 'detailed_col',
            headerName: 'Offer Status',
            width: 145,
            minWidth: 50,
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.other_statuses.detailed && !data.archived) {
                    return <span>{data.other_statuses.detailed}</span>
                } else if (!data.other_statuses.detailed && !data.archived) {
                    return <span>{data.status}</span>
                }
                return <span>Archived</span>
            },
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    Current status of the offer. Pending, Inactive, Denied, and Archived offers are not visible to partners.
                                </div>
                            )}>
                    <span>OFFER STATUS</span>
                </div>
            }
        },
        {
            colId: 'company',
            field: 'company',
            headerName: 'Marketer Company Name',
            width: 215,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The company name that this marketer is associated with.
                                </div>
                            )}>
                    <GridSortableHeader
                    headerParams={data}
                >MARKETER COMPANY NAME</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.company) return <span>{data.company}</span>
                return <span>{data.user_name} {data.user_lastname}</span>
            }
        },
        {
            colId: 'user_name',
            field: 'user_name',
            headerName: 'Marketer Name',
            width: 194,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The First/Last Name of the marketer that this account belongs to.
                                </div>
                            )}><GridSortableHeader
                    headerParams={data}
                >MARKETER NAME</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                const name = data.user_name + " " + data.user_lastname;
                return <div className="login-field">
                    <span className="name">{name}</span>
                    <div className={"tooltip"}
                         data-html={true}
                         data-offset="{'right': 6}"
                         data-tip={ReactDOMServer.renderToStaticMarkup(
                             <div style={{maxWidth: 200}}>
                                 Log in as Admin
                             </div>)}>
                            <span className={'icon'} style={{fontSize: "16px"}}>
                                <i className="ico-Download" onClick={() => angularAction(10, data)}/>
                            </span>
                    </div>
                </div>
            }
        },
        {
            colId: 'executive_name',
            field: 'executive_name',
            headerName: 'Engine Growth Manager',
            width: 240,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The Perform[cb] employee associated with managing this marketer's account.
                                </div>)}>
                        <GridSortableHeader
                    headerParams={data}
                >ENGINE GROWTH MANAGER</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                return <span>{data.executive_name} {data.executive_lastname}</span>
            }
        },
        {
            colId: 'pricing_model',
            field: 'pricing_model',
            headerName: 'Offer type',
            width: 132,
            minWidth: 50,
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.detailed_pricing_model != null) {
                    return `${data.subtype === 'mailer' ? 'Email ' : ''}${data.detailed_pricing_model.toUpperCase()}`;

                } else if (data.pricing_model == 'cpa' && data.is_default_pricing_model == 1) {
                    return `${data.subtype === 'mailer' ? 'Email ' : ''}CPA (Not Defined)`;

                } else {
                    return `${data.subtype === 'mailer' ? 'Email ' : ''}` +
                        getNameByValue(pricing_models, data.pricing_model);
                }
            },
            headerComponent: () => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The outcome the marketer is paying for.
                                </div>)}>
                    <span>OFFER TYPE</span>
                    </div>
            }
        },
        // {
        //     colId: 'tracking_system',
        //     headerName: 'Tracking System',
        //     width: 132,
        //     minWidth: 50,
        //     cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
        //         return <span>{data.tracking_system}</span>
        //     },
        //     headerComponent: () => {
        //         return <div className='header-custom'>
        //             <span>TRACKING SYSTEM</span>
        //         </div>
        //     }
        // },
        {
            colId: 'niche_id',
            field: 'niche_id',
            headerName: 'Vertical ID',
            width: 115,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                     data-html={true}
                     data-padding={'30px'}
                     data-tip={ReactDOMServer.renderToStaticMarkup(
                         <div style={{maxWidth: 170}}>
                             The unique identification number assigned to a vertical.
                         </div>)}>
                    <GridSortableHeader
                        headerParams={data}
                    >VERTICAL ID</GridSortableHeader>
                </div>
            },
        },
        {
            colId: 'niche_name',
            field: 'niche_name',
            headerName: 'Vertical',
            width: 148,
            minWidth: 50,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The industry channel that the offer provides goods and services within.
                                </div>)}><GridSortableHeader
                    headerParams={data}
                >VERTICAL</GridSortableHeader>
                </div>
            },
        },
        {
            colId: 'budget',
            field: 'budget',
            headerName: 'Budget',
            minWidth: 50,
            width: 147,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The maximum amount of traffic we can send to the offer.
                                </div>)}>
                    <GridSortableHeader
                    headerParams={data}
                >BUDGET</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.pricing_model != pricing_models[1].value) {
                    if (data.budget_type !== "unlimited") {
                        return <span>{formatNumberToCurrency(data.budget)}</span>
                    } else {
                        return <span><small>{data.budget_type}</small></span>
                    }
                } else if (data.cap_type == 'conversion' && data.pricing_model == pricing_models[1].value) {
                    return <span>{data.cap_value} conv{data.cap_interval == "daily" &&
                        <small>{data.cap_interval}</small>}</span>
                } else if (data.pricing_model == pricing_models[1].value && data.cap_type != 'conversion' && data.budget_type === 'unlimited') {
                    return <span><small>-</small></span>
                } else if (data.pricing_model == pricing_models[1].value && data.cap_type != 'conversion' && data.budget_type !== 'unlimited') {
                    if (data.budget_value_type == 0) {
                        return <span>{formatNumberToCurrency(data.budget)} <>{data.budget_type?.toUpperCase()}</></span>
                    } else {
                        return <span>{data.budget} <small>{data.budget_type}</small></span>
                    }
                }
                return <span> - </span>
            }
        },
        {
            colId: 'cid_rep',
            field: 'cid_rep',
            headerName: 'Approval Status',
            width: 130,
            minWidth: 50,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    When an offer is created, it is in a pending status until approved or denied by our internal team. The offer cannot launch until fully approved.
                                </div>)}>
                    <span>APPROVAL STATUS</span>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.cid_rep == 0) {
                    return <span>Pending Rep</span>
                } else if (data.cid_rep == -1) {
                    return <span>Rep Denied/Pending Compliance</span>
                } else if (data.cid_rep == 1) {
                    if (data.cid_media == 0 && data.cid_compliance == 0) return <span>Pending Media/Pending Compliance</span>;
                    if (data.cid_media == 0 && data.cid_compliance == 1) return <span>Pending Media</span>;
                    if (data.cid_media == 1 && data.cid_compliance == 0) return <span>Pending Compliance</span>;
                    if (data.cid_media == 0 && data.cid_compliance == -1) return <span>Pending Media/Compliance Denied</span>;
                    if (data.cid_media == -1 && data.cid_compliance == 0) return <span>Media Denied/Pending Compliance</span>;
                    if (data.cid_media == -1 && data.cid_compliance == 1) return <span>Media Denied</span>;
                    if (data.cid_media == 1 && data.cid_compliance == -1) return <span>Compliance Denied</span>;
                    if (data.cid_media == 1 && data.cid_compliance == 1) return <span>Fully Approved</span>;
                    return;
                }
            },
        },
        {
            colId: 'reports.impressions',
            field: 'reports.impressions',
            headerName: 'Impressions',
            width: 150,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The number of times the offer was displayed to a user.
                                </div>)}><GridSortableHeader
                    headerParams={data}
                >IMPRESSIONS</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.reports_impressions) {
                    return <span>{data.reports_impressions}</span>
                } else {
                    return <span>0</span>
                }
            }
        },
        {
            colId: 'reports.clicks',
            field: 'reports.clicks',
            headerName: 'Valid Clicks',
            width: 150,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The number of times a user clicked on an ad and was directed to this offer.
                                </div>)}><GridSortableHeader
                    headerParams={data}
                >VALID CLICKS</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.reports_clicks) {
                    return <span>{data.reports_clicks}</span>
                } else {
                    return <span>0</span>
                }
            }
        },
        {
            colId: 'reports.return',
            field: 'reports.return',
            headerName: 'Return',
            width: 150,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The amount the marketer pays Perform[cb].
                                </div>)}>
                    <GridSortableHeader
                    headerParams={data}
                >RETURN</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.reports_return) {
                    return <span>{formatNumberToCurrency(data.reports_return)}</span>
                } else {
                    return <span>{formatNumberToCurrency(0)}</span>
                }
            }
        },
        {
            colId: 'reports.margin',
            field: 'reports.margin',
            headerName: 'Margin',
            width: 150,
            minWidth: 50,
            sortable: true,
            comparator: () => 0,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The percent Perform[cb] keeps before paying out the Partners. Margin = Approved Profit / Return
                                </div>)}>
                <GridSortableHeader
                    headerParams={data}
                >MARGIN</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.reports_margin) {
                    return <span>{formatNumberToPercentage(data.reports_margin)}</span>
                } else {
                    return <span>{formatNumberToPercentage(0)}</span>
                }
            }
        },
        {
            colId: 'reports.profit',
            field: 'reports.profit',
            headerName: 'Profit',
            width: 150,
            minWidth: 50,
            headerComponent: (data: IHeaderParams) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The amount Perform[cb] keeps. Approved Profit = Return - Cost
                                </div>)}>
                    <GridSortableHeader
                    headerParams={data}
                >PROFIT</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                if (data.reports_profit) {
                    return <span>{formatNumberToCurrency(data.reports_profit)}</span>
                } else {
                    return <span>{formatNumberToCurrency(0)}</span>
                }
            }
        },
        {
            colId: 'preview_url',
            field: 'preview_url',
            headerName: 'Landing Page URL',
            width: 155,
            minWidth: 50,
            headerComponent: (data: any) => {
                return <div className='header-custom'
                            data-html={true}
                            data-padding={'30px'}
                            data-tip={ReactDOMServer.renderToStaticMarkup(
                                <div style={{maxWidth: 170}}>
                                    The URL for the landing page of this offer.
                                </div>)}>
                    <GridSortableHeader
                    headerParams={data}
                >LANDING PAGE URL</GridSortableHeader>
                </div>
            },
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                return <a
                    className="blue-text"
                    target="_blank"
                    href={data.preview_url}>
                    {data.preview_url}
                </a>
            }
        },
        {
            colId: 'right',
            pinned: 'right',
            width: 50,
            resizable: false,
            suppressMovable: true,
            cellRenderer: ({data}: { data: IFetchCampaignsResponse }) => {
                return <GridRowOptions>
                    <DropdownItem onClick={() => angularAction(1, data)}>Report</DropdownItem>
                    <DropdownItem
                        onClick={() => window.open(visitUrl(data.preview_url ?
                            data.preview_url : data.destination_url), "_blank")}>
                        Visit URL
                    </DropdownItem>
                    <DropdownItem onClick={() => angularAction(2, data)}>View Info</DropdownItem>
                    <DropdownItem onClick={() => angularAction(8, data)}>Approve / Deny</DropdownItem>
                    <DropdownItem onClick={() => angularAction(3, data)}>Event Payouts</DropdownItem>
                    <DropdownItem onClick={() => angularAction(4, data)}>Margins</DropdownItem>
                    <DropdownItem onClick={() => angularAction(9, data)}>Creatives</DropdownItem>
                </GridRowOptions>
            }
        }
    ], [])

    const onRowSelected = (e: RowClickedEvent) => {
        /**
         * this function will fire on every checkbox selection (even on select all checkbox)
         * logic below checks for  selected rows through pages including selected rows from search
         * filter function remove duplicated rows per page.
         * for large number of selection it has small delay on actions button (where number of selected rows is
         * displayed)
         * TODO: optimization
         */
        if (selectedRowsPerPage) {
            let rowSelected = e.node.isSelected()
            let selectedRowId = e.data.id
            let selectedRows = selectedRowsPerPage[currentPage.toString()]
            if (!selectedRows) {
                selectedRows = []
            }
            if (rowSelected) {
                selectedRows.push(e.data)
                selectedRows = selectedRows.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                )
            } else {
                selectedRows = selectedRows.filter((item) => {
                    return item.id !== selectedRowId
                })
            }
            let tempRows = {...selectedRowsPerPage}
            tempRows[currentPage] = selectedRows
            setSelectedRowsPerPage(tempRows)
        } else {
            setSelectedRowsPerPage({
                [currentPage]: e.api.getSelectedRows()
            })
        }
    }

    const getSelectedRows = () => {
        if (selectedRowsPerPage && Object.keys(selectedRowsPerPage).length >= 1) {
            const selectedValues = Object.values(selectedRowsPerPage)
            return selectedValues.reduce(function (prev, next) {
                return prev.concat(next);
            });
        } else {
            return []
        }
    }

    const orderColumns = (columns: ColumnState[]): ColumnState[] => {
        const newColState = [] as ColumnState[]
        if (columns) {

            columns.forEach(column => {
                if (column.pinned === 'left') {
                    newColState.push(column)
                }
            })
            columns.forEach(column => {
                if (column.pinned !== 'left') {
                    newColState.push(column)
                }
            })
        }
        return newColState
    }

    const onGridReady = (api: GridReadyEvent) => {
        setGridApi(api.api)
        setGridColumnApi(api.columnApi)
        if (tablePreferences.length > 0) {
            api.columnApi.applyColumnState({state: orderColumns(tablePreferences)})
            api.columnApi.moveColumns(orderColumns(tablePreferences).map(column => column.colId) as string[], 0)
        } else {
            saveTablePreferences(api.columnApi.getColumnState())
        }
    }

    const handleEnableAllColumns = () => {
        if (gridColumnApi) {
            gridColumnApi.setColumnsVisible(gridColumnApi.getAllColumns() as Column[], true)
            dispatch(saveUserTablePreferences({pageId: pageId, data: gridColumnApi.getColumnState()}))
        }
        gridApi && gridApi.hideOverlay()
    }

    const deselectRows = () => {
        setSelectedRowsPerPage({})
        if (gridApi){
            gridApi.deselectAll()
        }
    }

    return (
        <Wrapper>
            <TableWrapper>
                <TableToolbarTop>
                    <TableToolbarTopUpperRow>
                        <DateRangeFilter fetchData={fetchCampaigns}
                                         gridApi={gridApi}
                                         gridColumnApi={gridColumnApi}/>
                        <Actions selected_offers={getSelectedRows()}
                                 fetchData={fetchCampaigns}
                                 tags={allTags}
                                 deselectRows={deselectRows}
                        />
                        <Search width={30}
                                wrapperClassName={'table-search'}
                                onChange={setSearchValue}
                                initialValue={offers_search_value}/>
                        {
                            discoverDisplayed &&
                            <ButtonStyled onClick={() => handleDiscover()} className={'btn-discover-results'}>
                                Discover Result<Icon size={13} style={{marginLeft: '10px'}} icon={'Rocket'}/>
                            </ButtonStyled>
                        }
                    </TableToolbarTopUpperRow>
                    <TableToolbarTopBottomRow>
                        <Filters setDiscoverDisplayed={setDiscoverDisplayed}/>
                        <Pagination
                            exportCsv={exportCsv}
                            onRefresh={fetchCampaigns}
                            loading={loading}
                            current_page={currentPage}
                            from={from}
                            to={to}
                            total={total}
                            last_page={lastPage}
                            setCurrentPage={setCurrentPage}
                            perPage={per_page}
                            setPerPage={(n: number) => dispatch(setPerPage(n))}
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            id={pageId}
                        />
                    </TableToolbarTopBottomRow>
                </TableToolbarTop>
                <DataGrid
                    tooltipDelay={750}
                    loading={loading}
                    fixedScroll={true}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDefs}
                    rowHeight={40}
                    domLayout={'autoHeight'}
                    suppressRowClickSelection={true}
                    rowSelection={'multiple'}
                    onGridReady={onGridReady}
                    onRowSelected={(e) => onRowSelected(e)}
                    handleEnableAllColumns={handleEnableAllColumns}
                    allColumnsDisabled={tablePreferences.some(column => {
                    return column.hide === false
                })}
                    onSortChanged={(event) => {
                        const direction = event.columnApi.getColumnState().filter(column => column.sort !== null)[0].sort
                        const sort_by = event.columnApi.getColumnState().filter(column => column.sort !== null)[0].colId
                        cancelTokenSource.current?.cancel();
                        fetchCampaigns(direction, sort_by)
                        saveTablePreferences(event.columnApi.getColumnState())
                    }}
                    onDragStopped={(api : DragStoppedEvent) =>{
                        saveTablePreferences(api.columnApi.getColumnState())
                    }}
                />
                <TableToolbarBottom>
                    <Pagination
                        openUp={true}
                        loading={loading}
                        current_page={currentPage}
                        from={from}
                        to={to}
                        total={total}
                        last_page={lastPage}
                        setCurrentPage={setCurrentPage}
                        perPage={per_page}
                        setPerPage={(n: number) => dispatch(setPerPage(n))}
                    />
                </TableToolbarBottom>
            </TableWrapper>
        </Wrapper>
    )
}

export default withUserPreferences(pageId, withPageContainer(Offers))