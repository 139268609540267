import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
    background-color: ${({theme}) => theme.colors.coolGray[1]};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    //@media (max-width: 1320px) {
    //    height: auto;
    //    padding: 50px 20px;
    //}

    .react-tooltip-container {
        max-width: 350px;
        padding: 16px;
        font-size: 12px !important;
        font-family: ${({theme}) => theme.fonts.AvenirRoman};
        .tooltip-list {
                margin-top: 8px;
                margin-bottom: 8px;
            li {
                list-style-type: disc;
                margin-left: 12px;
                line-height: 1.35;
                overflow-wrap: break-word;
            }
        }
        .delayed-action-tooltip {
            max-width: 210px;
        }
        .offer-type-tooltip {
            max-width: 168px;
            p {
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .common-tooltip {
            p {
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .dest-param-pill-tooltip {
            max-width: 200px;
        }
    }
`

interface IOfferTypeBox {
    active: boolean;
    disabled?: boolean
}

export const OfferTitle = styled.div`
    text-align: center;
    margin-bottom: 40px;
    font-size: 34px;
    font-family: ${({theme}) => theme.fonts.AvenirRoman};
    color: ${({theme}) => theme.colors.deepBlue[6]};
`

export const OfferTypeBox = styled.div<IOfferTypeBox>`
    width: 240px;
    height: 240px;
    border-radius: 24px;
    background-color: ${({theme}) => theme.colors.pureWhite};
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
    flex-direction: column;
    margin: 0 8px 16px;
    cursor: pointer;
    transition: .2s ease-in-out all;
    overflow: hidden;
    color: ${({theme}) => theme.colors.deepBlue[6]};
    
    pointer-events: ${({disabled}) => disabled ? 'none' : 'all'};

    .checked-icon {
        position: absolute;
        top: 15px;
        right: 18px;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        box-shadow: inset 0 0 0 1px #E7ECEE;
        border-radius: 24px;
        transition: .2s ease-in-out all;
    }

    ${props => props.active ?
            css`
                box-shadow: 0 0 20px rgba(99, 231, 249, 0.34);
                &:before {
                    box-shadow: inset 0 0 0 2px ${({theme}) => theme.colors.performBlue[4]};
                }
            `

            :
            css`  :hover {
                &:before {
                    box-shadow: inset 0 0 0 1px ${({theme}) => theme.colors.performBlue[3]};
                }
                box-shadow: 0 0 16px rgba(156, 243, 255, 0.29);
            }`
    }
    svg {
        margin-bottom: 14px;
    }

    i {
        margin-top: 14px;
        display: inline-block;
        color: ${({theme}) => theme.colors.deepBlue[3]};
    }
`

export const ButtonHolder = styled.div`
    width: 100%;
    max-width: 512px;
    margin: 20px auto 0;
    min-height: 36px;
`

export const OfferTypeHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`

export const BackButton = styled.button`
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 50px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    line-height: 1;
    @media (max-width: 1320px) {
        top: 10px;
        left: 10px;
    }
`

export const ExitButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 40px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    line-height: 1;
    @media (max-width: 1320px) {
        top: 10px;
        right: 10px;
    }
    :hover {
        svg{
            .circle-bg {
                fill: ${({theme}) => theme.colors.performOrange[1]};
            }
            .circle-stroke {
                stroke: ${({theme}) => theme.colors.performOrange[3]}
            }
        }
    }
    :active{
        svg{
            .circle-bg {
                fill: ${({theme}) => theme.colors.performOrange[2]};
            }
            .circle-stroke {
                stroke: ${({theme}) => theme.colors.performOrange[6]}
            }
        }
    }
`

export const LinkButton = styled(Link)`
    border-radius: 100px;
    //defaults
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    padding: 12px 16px;
    line-height: 1;
    user-select: none;
    font-family: ${({theme}) => theme.fonts.AvenirMedium};
    color: ${({theme}) => theme.colors.pureWhite};
    transition: all .2s ease-in-out;
    width: 100%;
    font-size: 16px;
    background-color: ${({theme}) => theme.colors.performBlue[6]};
    border-color: ${({theme}) => theme.colors.performBlue[6]};

    &:hover {
        background-color: ${({theme}) => theme.colors.performBlue[7]};
        border-color: ${({theme}) => theme.colors.performBlue[7]};
    }

    &:active {
        background-color: ${({theme}) => theme.colors.performBlue[8]};
        border-color: ${({theme}) => theme.colors.performBlue[8]};
    }

`

export const TooltipContainer = styled.div<{opened: boolean, $top: number, $left: number}>`
    opacity: ${(props) => props.opened ? '1' : '0'};
    visibility: ${(props) => props.opened ? 'visible' : 'hidden'};
    align-items: center;
    display: flex;
    padding: 16px;
    border: 1px solid #E9ECED;
    min-width: fit-content;
    left: ${props => props.$left}px;
    top: ${props => props.$top}px;
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    position: absolute;
    background-color: white;
    z-index: 10000;
    color: ${({theme}) => theme.colors.deepBlue[5]};
    transition: opacity .2s ease-in-out;
    transform: translate(calc(-50% + 7px), 20px);
    font-size: 12px;
    line-height: 16px;
    pointer-events: none;

    :after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }
    
    .material-icons{
        margin-right: 9px;
        font-size: 16px;
    }
    
`