import { useEffect } from "react";
import { IInputNumberProps } from "./InputNumber.props";
import { InputNumberWrapper, NumberInputField } from "./InputNumber.style"

const InputNumber = ({minValue = 1, onChange, value, width = "100px", height, maxValue}: IInputNumberProps) => {

    const inputHandler = (val: number) => {
        !isNaN(val) && onChange(val);
    };

    useEffect(()=>{
        if(value < minValue) onChange(minValue);
        if(maxValue && value > maxValue) onChange(maxValue);
    }, [value]);

    const incrementHandler = () => {
        if(value === maxValue) {
            return;
        }
        else{
            onChange(value + 1);
        };
    };

    const decrementHandler = () => {
        if(value === minValue) {
            return;
        }
        else{
            onChange(value - 1);
        };
    };

    return(
        <InputNumberWrapper $height={height} $width={width}>
            <NumberInputField
                type="text"
                value={value.toString()}
                onChange={(e:any) => inputHandler(Number(e.target.value))}
            />
            <div className="arrows">
                <i className="ico-AngleUp" onClick={()=>incrementHandler()}></i>
                <i className="ico-AngleDown" onClick={()=>decrementHandler()}></i>
            </div>
        </InputNumberWrapper>
    );
}

export default InputNumber;