import { useEffect, useMemo, useState } from "react";
import Grid from "../../../../../../../common/components/grid/Grid"
import { useColumnsOptionHook } from "../../../../../../../common/components/table/pagination/ColumnsOption/columnsOptionHook";
import SortableHeader from "../../../../../../../common/components/table/sortable-header/SortableHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/hooks";
import { formatNumberToCurrency } from "../../../../../../../common/utils";
import NetworkViewService, { IFetchAllAccountManagersResponse } from "../../NetworkView.service";
import { setPagination, setOffersPaginationColumnsList, setOffersSortDirection, setOffersCurrentPage, setOffersPerPage, setTopPerformersLoading, setTopPerformersTableData, setTopPerformersTableSum } from "../../NetworkView.slice";
import { NetworkViewSortIconDown, NetworkViewSortIconUp, TopPerformersTable } from "../TopPerformers.style";
import { allOffersPaginationColumns } from "../topPerformersLists";

const TopPerformersOffer = () => {

    const {
        offersPaginationColumnsList,
        offersPerPage,
        offersCurrentPage,
        offersSort,
        pagination,
        topPerformersLoading,
        topPerformersTableData,
        topPerformersTableSum,
        selectedManagers,
        includeOrExclude, filterType
    } = useAppSelector(state => state.networkView)

    const {token} = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch();

    const sortHandler = (key: string) => { 
        dispatch(setOffersSortDirection({
            direction:  offersSort.by !== key ? "asc" : offersSort.direction === "desc" ? "asc" : "desc",
            by: key
        }));
    };

    const commaTransform = (value: string) => {
        return Number(value).toLocaleString('en-US')
    }

    const allOffersColumnDefs: any = [
        {
            colId: 1, 
            headerComponent: (data: any) =>  {
               return <SortableHeader 
                            keyId={'id'} 
                            sortDirection={offersSort.direction} 
                            sortedBy={offersSort.by} 
                            onClick={() => !data.column.isMoving() && sortHandler("id")}
                            icon={offersSort.by === "id"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                        >OFFER ID</SortableHeader>
            },
            cellRenderer: (data:any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                
                return <span>{data.data.id}</span>
            }
        },
        {
            colId: 2,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return "";
                }
                return <span>{data.data.name}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'name'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("name")}
                             icon={offersSort.by === "name"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >OFFER NAME</SortableHeader>
             },
        
        },
        {
            colId: 3, 
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){            
                    return <span>{commaTransform(data.data.clicks)}</span>;
                }
                return <span>{commaTransform(data.data.clicks)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'clicks'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("clicks")}
                             icon={offersSort.by === "clicks"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CLICKS</SortableHeader>
             },
        
        },
        {
            colId: 4,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{commaTransform(data.data.conversions)}</span>;
                }
                return <span>{commaTransform(data.data.conversions)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'conversions'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("conversions")}
                             icon={offersSort.by === "conversions"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CONVERSIONS</SortableHeader>
             },
        
        },
        {
            colId: 5,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{data.data.cvr.replaceAll('.', ',')}%</span>;
                }
                return <span>{data.data.cvr.replaceAll('.', ',')}%</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'cvr'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("cvr")}
                             icon={offersSort.by === "cvr"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >CVR</SortableHeader>
             },
        
        },
        {
            colId: 6,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.revenue)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.revenue)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'revenue'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("revenue")}
                             icon={offersSort.by === "revenue"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >REVENUE</SortableHeader>
             },
        
        },
        {
            colId: 7,
            cellRenderer: (data: any) => {
                if(data.node.rowPinned === "bottom"){
                    return <span>{formatNumberToCurrency(data.data.profit)}</span>;
                }
                return <span>{formatNumberToCurrency(data.data.profit)}</span>
            },
            headerComponent: (data: any) =>  {
                return <SortableHeader 
                             keyId={'profit'} 
                             sortDirection={offersSort.direction} 
                             sortedBy={offersSort.by} 
                             onClick={() => !data.column.isMoving() && sortHandler("profit")}
                             icon={offersSort.by === "profit"  ? offersSort.direction === "asc" ? <NetworkViewSortIconUp /> : <NetworkViewSortIconDown /> : <span></span> }
                         >PROFIT</SortableHeader>
             },
        
        },
        
    ];

    const fetchData = async () => {
        dispatch(setTopPerformersLoading(true));

        const params = {
            analyze_by: "offers",
            page: offersCurrentPage,
            per_page: offersPerPage,
            sort_by: offersSort.by,
            order: offersSort.direction,
        } as any

        if(filterType === 'account-manager'){
            params['AccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'partner-account-manager'){
            params['PartnerAccountManager'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_acc_mngr'] = true
            }

        }

        if(filterType === 'sales-executive'){
            params['SalesExecutive'] = selectedManagers.map((item: IFetchAllAccountManagersResponse) => {
                return item.id;
            })
            if(includeOrExclude === 'excl'){
                params['exclude_sale_exec'] = true
            }
        }

        const [response, responseSum] = await Promise.all([NetworkViewService.fetchTopPerformersData(params, token), NetworkViewService.fetchTopPerformersData(params, token, true)]);
        
        if(response.data) {
            let pagination: {
                from: number,
                to: number,
                total: number,
                last_page: number
            } = {
                from: parseInt(response.data.from ? response.data.from : 0),
                to: parseInt(response.data.to ? response.data.to : 0),
                total: parseInt(response.data.total),
                last_page: parseInt(response.data.last_page)
            }
            dispatch(setTopPerformersTableData(response.data.data));
            dispatch(setPagination(pagination));
            dispatch(setOffersCurrentPage(parseInt(response.data.current_page)));
            dispatch(setOffersPerPage(parseInt(response.data.per_page)));
        }

        if(responseSum.data) {
            dispatch(setTopPerformersTableSum(responseSum.data))
        }
        dispatch(setTopPerformersLoading(false));
    }

    const offerPaginationColumnsListHandler = (list: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        dispatch(setOffersPaginationColumnsList(list));
    }

    const [offersColumnDefs, setOffersColumnDefs] = useState(allOffersColumnDefs);
    const [colWidths, setColWidths] = useState(allOffersColumnDefs.map((item: any) => {
        return {width: item.width, id: item.colId}
    }));

    const [enableAllOffersHandler, disableAllOffersHandler, enableDisableOffersColumnHandler]: any = useColumnsOptionHook(
        allOffersColumnDefs, 
        offersColumnDefs, 
        setOffersColumnDefs,
        allOffersPaginationColumns,
        offersPaginationColumnsList, 
        offerPaginationColumnsListHandler
    );

    useEffect(() => {
        const checkedColumns = offersPaginationColumnsList.filter((item: any) => item.checked);
        if (offersPaginationColumnsList.length === 7) {
            if(offersPaginationColumnsList.filter((item: any) => item.checked && item.name !== "pinned").length >= 1) {
                let newDefs:any = [];
                checkedColumns.forEach((it: any, i: number) => {
                    const colDef = allOffersColumnDefs.find((item:any, i: number) => item.colId === it.id);
                    newDefs.push({...colDef, width: colWidths.find((item: any) => item.id === it.id )?.width || 220});
                })
                setOffersColumnDefs(newDefs);
            } else {
                setOffersColumnDefs([]);
            }
        }
        
    }, [offersPaginationColumnsList, offersSort])

    const onColumnDrop = (columns: any) => {
        const newColumnOrder = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return col.userProvidedColDef.colId;
        })
        const widths = columns.api.columnModel.displayedTreeCentre.map((col: any) => {
            return {width: col.actualWidth, id: col.colDef.colId};
        })

        let newColDefs:any = [];
        let newPaginCol:any = [];
        newColumnOrder.forEach((it: number) => {
            const colDef = allOffersColumnDefs.find((item:any) => item.colId === it);
            newColDefs.push(colDef);
            const pagCol = allOffersPaginationColumns.find((item: any) => item.id === it);
            newPaginCol.push(pagCol);
        });
        
        const widthToCol = newColDefs.map((item:any, index: number) => {
            return {...item, width: widths.find((it: any) => it.id === item.colId) || 220}
        })
        
        setColWidths(widths);
        setOffersColumnDefs(widthToCol);
        
        const rest = offersPaginationColumnsList.filter((item: any) => !newColumnOrder.includes(item.id));
        offerPaginationColumnsListHandler([...newPaginCol, ...rest]);
    }

    const defaultColDef = useMemo(() => ({
        resizable: true,
        suppressSizeToFit: true
    }), []);

    useEffect( () => {
        token && fetchData();
    }, [offersPerPage, offersCurrentPage, offersSort]);

    return(
        <TopPerformersTable>
            <Grid 
                loading={topPerformersLoading}
                columnDefs={offersColumnDefs}
                defaultColDef={defaultColDef}
                rowData={topPerformersTableData}
                rowHeight={40}
                domLayout='autoHeight'
                // loading={false}
                enableAllColumns={enableAllOffersHandler}
                pinnedBottomRowData={topPerformersLoading || topPerformersTableData.length === 0 ? undefined : topPerformersTableSum}
                fixedHeader={false}
                onDragStopped={onColumnDrop}
            />
        </TopPerformersTable>
    )
};

export default TopPerformersOffer;