import React, {useState} from "react";
import {
    FaqAccordion, FaqAccordionItemContent,
    FaqAccordionItemInner,
    FaqAccordionItemTitle,
    FaqWidgetContainer,
    FaqWidgetTitle
} from "./FaqWidget.style";
import {faqData} from "./faqData";

const FaqWidget = () => {
    const[isCollapsed, setIsCollapsed] = useState(false);

    const FaqAccordionItem = ({ title, content }:any) => {
        const [isActive, setIsActive] = useState(false);
        return (
            <FaqAccordionItemInner>
                <FaqAccordionItemTitle onClick={() => setIsActive(!isActive)}>
                    {title}
                    <i className={`dropdown ${isActive ? "ico-ChevronUp" : "ico-ChevronDown"}`} />
                </FaqAccordionItemTitle>
                {isActive &&
                <FaqAccordionItemContent>{content}</FaqAccordionItemContent>
                }
            </FaqAccordionItemInner>

        );
    };

    return  (
        <FaqWidgetContainer>
            <FaqWidgetTitle onClick={() => setIsCollapsed(!isCollapsed)}>
                Frequently Asked Questions
                <i className={`dropdown ${isCollapsed ? "ico-ChevronUp" : "ico-ChevronDown"}`} />
            </FaqWidgetTitle>
            {isCollapsed &&
            <FaqAccordion>
                {faqData.map(({ title, content }, index) => (
                    <FaqAccordionItem key={index} title={title} content={content} />
                ))}
            </FaqAccordion>
            }
        </FaqWidgetContainer>
    )
}

export default FaqWidget
