import styled from "styled-components";


export const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`
export const SmallWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-left: 15px;
  align-items: flex-start;
`