import styled, {css} from "styled-components";
import {AlertType} from "./alert.props";

interface IAlertStyleProps {
    type: AlertType
}

export const Wrapper = styled.div<IAlertStyleProps>`
  padding: 16px;
  color: ${({theme}) => theme.colors.deepBlue[7]};
  font-size: 12px;
  border-radius: 8px;
  width: 100%;
  ${props => props.type === 'error' && css`
    background-color: ${props => props.theme.colors.critical[1]};
    border: 1px solid ${props => props.theme.colors.critical[2]};
  `}

  ${props => props.type === 'info' && css`
    background-color: ${props => props.theme.colors.performBlue[1]};
    border: 1px solid ${props => props.theme.colors.performBlue[3]};
  `}

  ${props => props.type === 'warning' && css`
    background-color: ${props => props.theme.colors.warning[1]};
    border: 1px solid ${props => props.theme.colors.warning[3]};
  `}
  strong {
    font-family: ${({theme}) => theme.fonts.AvenirHeavy};
  }
`