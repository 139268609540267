import React, {useEffect, useMemo} from 'react';
import {AlertStyledContent, Panel} from "../Main.style";
import {Wrapper} from './CreativesAndEmail.style'
import CreativesInfo from "./components/CreativesInfo";
import TextCreative from "./components/TextCreative";
import VisualCreatives from "./visual-creatives/VisualCreatives";
import ReactTooltip from "react-tooltip";
import EmailTraffic from "./email-traffic/EmailTraffic";
import {setNextPage, setPrevPage} from "../../../create-offer.slice";
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hooks";
import {BUDGET, TESTING} from "../../../interfaces/interfaces";
import {AlertStyled} from "../../../../../../../common/styled-components";
export interface Props {

}

const CreativesAndEmail : React.FC<Props> = () => {
    const dispatch = useAppDispatch()
    const {
        offer
    } = useAppSelector(state => state.createOffer)

    useEffect(() => {
        ReactTooltip.rebuild()
    })
    
    useEffect(()=>{
        dispatch(setPrevPage("../" + TESTING))
        dispatch(setNextPage("../" + BUDGET))
    },[])

    return <Wrapper>
        <Panel>
            <div className={"title"}>Creatives {offer.detailed_pricing_model !== 'ppc' ? ' & Email' : ''}</div>
            <div className="description">
                {offer.detailed_pricing_model !== 'ppc' ? <>
                    The first set of fields below can be used to provide both visual and text creatives you would like
                    partners to utilize when presenting your campaigns to consumers. In addition you have the ability to
                    enable partners to run email traffic to this campaign; by default email traffic is turned off.
                </> : <>
                    The fields below can be used to provide both visual and text creatives you would like partners
                    to utilize when presenting your campaigns to consumers.
                </>}

            </div>
            <CreativesInfo/>
            <TextCreative/>
            <VisualCreatives/>
            {offer.detailed_pricing_model !== 'ppc' ? <EmailTraffic/> : null}
        </Panel>
        </Wrapper>
};

export default CreativesAndEmail