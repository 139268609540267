import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useOnClickOutside } from "../../../../../../../common/hooks";
import { setTopPerformersAnalyzeBy, setTopPerformersTableData, setTopPerformersTableSum } from "../../NetworkView.slice";
import { AnalyzeByDropdownItem, TopPerformersAnalyzeBy } from "../TopPerformers.style";
import AccountsManagersMarketersPagination from "./paginations/AccountManagersMarketersPagination";
import AccountsManagersPartnersPagination from "./paginations/AccountManagersPartnersPagination";
import MarketersPagination from "./paginations/MarketersPagination";
import OffersPagination from "./paginations/OffersPagination";
import PartnersPagination from "./paginations/PartnersPagination";
import { TopPerformersToolbarWrapper } from "./TopPerformersToolbar.style";

const analyzeByList: {name: string, id:  'advertisers' | 'offers' | 'affiliates' | 'top_marketer_acc_managers' | 'top_partner_acc_managers' }[] = [
    {
        name: "Marketers",
        id: "advertisers"
    },
    {
        name: "Partners",
        id: "affiliates"
    },
    {
        name: "Offers",
        id: "offers"
    },
    {
        name: "Engine Growth Managers",
        id: "top_marketer_acc_managers"
    },
    {
        name: "Partner Account Managers",
        id: "top_partner_acc_managers"
    }
]

const TopPerformersToolbar = () => {

    const {
        topPerformersAnalyzeBy,
    } = useAppSelector(state => state.networkView);

    const [analyzeByOpened, setAnalyzeByOpened] = useState(false);

    const analyzeByRef = useRef(null);
    const dispatch = useAppDispatch();

    useOnClickOutside(analyzeByRef, () => {
        setAnalyzeByOpened(false);
    })

    return (
        <TopPerformersToolbarWrapper>
            <TopPerformersAnalyzeBy active={analyzeByOpened} ref={analyzeByRef}>
                <div className="analyze-by-main"  onClick={(e) => setAnalyzeByOpened(!analyzeByOpened)}>
                    <div className="top">
                        Analyze by: 
                            <span className="analyze-by">
                                {
                                   (topPerformersAnalyzeBy === "advertisers") && "Marketers" ||
                                   (topPerformersAnalyzeBy === "affiliates") && "Partners" ||
                                   (topPerformersAnalyzeBy === "offers") && "Offers" ||
                                   (topPerformersAnalyzeBy === "top_marketer_acc_managers") && "Engine Growth Managers" ||
                                   (topPerformersAnalyzeBy === "top_partner_acc_managers") && "Partner Account Managers"
                                }
                            </span>
                    </div>
                    {analyzeByOpened ? <span><i className="ico-AngleUp"></i></span> : <span><i className="ico-AngleDown"></i></span>}
                </div>
                {
                    analyzeByOpened && 
                    <div className="analyze-by-dropdown">
                        {analyzeByList.map(item => <AnalyzeByDropdownItem key={item.id} active={item.id === topPerformersAnalyzeBy} onClick={() => {                            
                            item.id !== topPerformersAnalyzeBy && dispatch(setTopPerformersTableData([]))
                            item.id !== topPerformersAnalyzeBy && dispatch(setTopPerformersTableSum([]))
                            item.id !== topPerformersAnalyzeBy && dispatch(setTopPerformersAnalyzeBy(item.id)) 
                            item.id !== topPerformersAnalyzeBy && setAnalyzeByOpened(false)
                        }}>{item.name}</AnalyzeByDropdownItem> )}
                    </div>
                }
            </TopPerformersAnalyzeBy>
                {
                    topPerformersAnalyzeBy === "advertisers" &&
                    <MarketersPagination />
                }
                {
                    topPerformersAnalyzeBy === "affiliates" &&
                    <PartnersPagination />
                }
                {
                    topPerformersAnalyzeBy === "offers" &&
                    <OffersPagination />
                }
                {
                    topPerformersAnalyzeBy === "top_marketer_acc_managers" &&
                    <AccountsManagersMarketersPagination />
                }
                {
                    topPerformersAnalyzeBy === "top_partner_acc_managers" &&
                    <AccountsManagersPartnersPagination />
                }
        </TopPerformersToolbarWrapper>
    )
}

export default TopPerformersToolbar;