import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IPreset } from "../../../../../../common/components/range-date-picker/presets/presets.props";
import { allPaginationColumnsList } from "./columnDefs";

export interface IBillingTransactionsInitialState  {
    billingSort: {
        direction: 'desc' | 'asc',
        by: string
    },
    pagination: {
        page: number,
        per_page: number,
        from: number,
        to: number,
        last_page: number,
        total: number
    },
    paginationColumnsList: {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[],
    date_from: Date | null | undefined,
    date_to: Date | null | undefined,
    date_range_preset: IPreset
}

const initialState: IBillingTransactionsInitialState = {
    billingSort: {
        direction: "desc",
        by: 'created_at'
    },
    pagination: {
        page: 1,
        per_page: 10,
        from: 1,
        to: 1,
        last_page: 1,
        total: 1
    },
    paginationColumnsList: allPaginationColumnsList,
    date_from: new Date(),
    date_to: new Date(),
    date_range_preset: {
        value: "last_7_days",
        name: 'Last 7 days'
    }
}

const billingTransactionsSlice = createSlice({
    name: 'billingTransactionsSlice',
    initialState,
    reducers: {
        setBillingTransactionsSort: (state: IBillingTransactionsInitialState, action:PayloadAction<{
            direction: 'desc' | 'asc',
            by: string
        }>) => {  
            state.billingSort = action.payload

        },
        setPage: (state: IBillingTransactionsInitialState, action:PayloadAction<number>) => {
            state.pagination.page = action.payload;
        },
        setPerPage: (state: IBillingTransactionsInitialState, action:PayloadAction<number>) => {            
            state.pagination.per_page = action.payload;
        },
        setPagination: (state: IBillingTransactionsInitialState, action:PayloadAction<{
            from: number,
            to: number,
            last_page: number,
            total: number
        }>) => {
            state.pagination.from = action.payload.from;
            state.pagination.to = action.payload.to;
            state.pagination.last_page = action.payload.last_page;
            state.pagination.total = action.payload.total
        },
        setPaginationColumnsList: (state: IBillingTransactionsInitialState, action:PayloadAction<{name: string,
            id: number,
            checked: boolean,
            column_key?: string}[]>) => {
            state.paginationColumnsList = action.payload;
        },
        setDateFrom: (state: IBillingTransactionsInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_from = action.payload
        },
        setDateTo: (state: IBillingTransactionsInitialState, action: PayloadAction<Date | null | undefined>) => {
            state.date_to = action.payload
        },
        setDateRangePreset: (state: IBillingTransactionsInitialState, action:PayloadAction<IPreset>) => {
            state.date_range_preset = action.payload
        },
    }
})

export const {
    setBillingTransactionsSort,
    setPage,
    setPerPage,
    setPagination,
    setPaginationColumnsList,
    setDateFrom,
    setDateTo,
    setDateRangePreset
} = billingTransactionsSlice.actions

export default billingTransactionsSlice.reducer
