import React, {useEffect, useRef, useState} from 'react'
import {
    DateFilterWrapper,
    DatePresetWrapper, DropdownWrapper,
    FilterContainer,
    FilterWrapper
} from "../../../dashboard/agency-view/filters/Filters.style";
import {format} from "date-fns";
import DropdownPreset from "../../../../../../common/components/range-date-picker/dropdown-preset/DropdownPreset";
import DateRangeInput from "../../../../../../common/components/daterange-input/DateRangeInput";
import RangeDatePicker from "../../../../../../common/components/range-date-picker/RangeDatePicker";
import {useAppDispatch, useAppSelector, useOnClickOutside, useSimulateMouseClick} from "../../../../../../common/hooks";
import {IPreset} from "../../../../../../common/components/range-date-picker/presets/presets.props";

import {setDateFrom, setDateTo, setDateRangePreset} from '../MarketersTransactions.slice'

interface Props {
    fetchData: () => void
}

const DateRangeFilter: React.FC<Props> = ({fetchData}) => {
    const [dateFilterOpened, setDateFilterOpened] = useState(false)
    const [presetDropdownOpened, setPresetDropdownOpened] = useState(false)

    const dateFilterRef = useRef<HTMLDivElement | null>(null)
    const presetContainerRef = useRef<HTMLDivElement | null>(null)

    const tempDateFrom = useRef<Date | null | undefined>(null)
    const tempDateTo = useRef<Date | null | undefined>(null)

    const {date_from, date_to, date_range_preset} = useAppSelector(state => state.marketersTransactions)
    const dispatch = useAppDispatch()

    useOnClickOutside(dateFilterRef, (e) => {
        if(presetContainerRef.current?.contains(e.target as Node)){
            return
        }
        if(dateFilterOpened){
            if(tempDateFrom || tempDateTo){
                if (tempDateFrom.current !== date_from || tempDateTo.current !== date_to) {
                    fetchData()
                    tempDateFrom.current = date_from
                    tempDateTo.current = date_to
                }
            }
        }
        setPresetDropdownOpened(false)
        setDateFilterOpened(false)
    })

    useSimulateMouseClick(document.getElementById('filter-container'), [date_from, date_to])

    const setRangePreset = (preset: IPreset) => {
        dispatch(setDateRangePreset(preset))
    }

    const setFrom = (date: Date | null | undefined) => {
        dispatch(setDateFrom(date))
    }

    const setTo = (date: Date | null | undefined) => {
        dispatch(setDateTo(date))
    }


    return (
        <DropdownWrapper ref={dateFilterRef}>
            <FilterWrapper
                onClick={() => setDateFilterOpened(!dateFilterOpened)}
                $opened={dateFilterOpened}
            >
                <i className={'ico-Calendar'}/>
                <span>
                    {
                        format(
                            date_from ?
                                typeof date_from === 'string' ?
                                    new Date(date_from) :
                                    date_from :
                                new Date(), 'MMM dd, y')
                    } - {
                    format(date_to ?
                        typeof date_to === 'string' ?
                            new Date(date_to) :
                            date_to :
                        new Date(), 'MMM dd, y')
                }
                </span>

            </FilterWrapper>
            <FilterContainer id={"filter-container"}  opened={dateFilterOpened} >
                <DateFilterWrapper>
                    <DatePresetWrapper>
                        <h3>Date Presets</h3>
                        <DropdownPreset
                            containerRef={presetContainerRef}
                            preset={date_range_preset}
                            setPreset={setRangePreset}
                            opened={presetDropdownOpened}
                            setOpened={setPresetDropdownOpened}
                        />
                        <div className="date-range">
                            <DateRangeInput startDate={date_from} setStartDate={setFrom} endDate={date_to} setEndDate={setTo}/>
                        </div>
                    </DatePresetWrapper>
                    <RangeDatePicker
                        startDate={typeof date_from === 'string' ? new Date(date_from) : date_from}
                        setStartDate={setFrom}
                        endDate={typeof date_to === 'string' ? new Date(date_to) : date_to}
                        setEndDate={setTo}
                        preset={date_range_preset.value}
                        setPreset={setRangePreset}
                    />
                </DateFilterWrapper>
            </FilterContainer>
        </DropdownWrapper>
    )
}

export default DateRangeFilter
