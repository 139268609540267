import React, {ChangeEvent, useEffect} from 'react';
import styled from 'styled-components'
import {setOffer} from "../create-offer.slice";
import {Tooltip} from "../shared-components/Shared";
import {DOMAIN} from "../shared-components/consts";
import {useAppDispatch, useAppSelector} from "../../../../../common/hooks";
import Input from "../../../../../common/components/input/Input";
import Checkbox from "../../../../../common/components/checkbox/Checkbox";

export const DomainInput: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        offer
    } = useAppSelector(state => state.createOffer)

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setOffer({
            ...offer,
            campaign_tracking_settings: {
                ...offer.campaign_tracking_settings,
                tracking_domain: e.target.value
            }
        }))
    }

    const handleAutoPopulateChange = () => {
        dispatch(setOffer({
            ...offer,
            campaign_tracking_settings: {
                ...offer.campaign_tracking_settings,
                auto_populate_domain: !offer.campaign_tracking_settings.auto_populate_domain,
                tracking_domain: offer.destination_url
            }
        }))
    }

    useEffect(() => {
        if(offer.campaign_tracking_settings.auto_populate_domain){
            dispatch(setOffer({
                ...offer,
                campaign_tracking_settings: {
                    ...offer.campaign_tracking_settings,
                    tracking_domain: offer.destination_url
                }
            }))
        }
    }, [offer.campaign_tracking_settings.auto_populate_domain, offer.landing_page_url])

    return (
        <Wrapper>
            <div>
                <Input
                    wrapperClassName={"input-wrapper"}
                    label={<>Domain {Tooltip(166, DOMAIN)}</>}
                    placeholder={"Enter the URL users land on presenting your campaign"}
                    value={
                    offer.campaign_tracking_settings.auto_populate_domain ?
                        offer.destination_url :
                        (offer.campaign_tracking_settings.tracking_domain ? offer.campaign_tracking_settings.tracking_domain : '')}
                    onChange={(event) => handleOnChange(event)}
                    disabled={offer.campaign_tracking_settings.auto_populate_domain}
                />
            </div>
            <div className={'checkbox-wrapper'}>
                <Checkbox
                    checked={offer.campaign_tracking_settings.auto_populate_domain}
                    onChange={handleAutoPopulateChange}
                    label={'Auto populate'}
                    wrapperClassName={'checkbox-label'}
                />
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    > div {
        flex: 1;
        &.checkbox-wrapper {
            max-width: 110px;
        }
    }
`