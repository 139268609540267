import {ColumnState} from "ag-grid-community";

export const orderColumns = (columns: ColumnState[]): ColumnState[] => {
    const newColState = [] as ColumnState[]
    if (columns) {
        columns.forEach(column => {
            if (column.pinned === 'left') {
                newColState.push(column)
            }
        })
        columns.forEach(column => {
            if (column.pinned !== 'left') {
                newColState.push(column)
            }
        })
    }
    return newColState
}