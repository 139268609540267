import {useEffect, useState, useCallback} from "react";

import {filterApi} from "../../services/api";
import {useAppSelector} from "../useAppSelector";
import {tokenStore} from "../../../store/auth.slice";
import {FilterID, IAffiliatesFilter, IFilterHookReturn} from "filters";
import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {useFilterSelector} from "../useFilterSelector";
import {useAppDispatch} from "../useAppDispatch";

export const useAffiliatesFilter = (pageId: PageID, displayCount?: number): IFilterHookReturn<IAffiliatesFilter> => {
    const token = useAppSelector(tokenStore)
    const [fetchedData, setFetchedData] = useState<IAffiliatesFilter[]>([])
    const [accountManagers, setAffiliates] = useState<IAffiliatesFilter[]>([])
    const [loading, setLoading] = useState(false)
    const [affiliatesSearchValue, setAffiliatesSearchValue] = useState('')
    const [affiliatesNotDisplayed, setAffiliatesFilterNotDisplayed] = useState(0)

    const dispatch = useAppDispatch()

    const id: FilterID ='affiliate'


    const fetch = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await filterApi.post<IAffiliatesFilter[]>(
                'affiliates',
                null,
                {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    },
                }
            )
            setLoading(false)
            setFetchedData(
                data
            )
        } catch (e) {
            setLoading(false)
        }
    }, [token])

    useEffect(() => {
        if(token) {
            fetch()
        }
    }, [token])

    useEffect(() => {
        let filteredAffiliates = fetchedData.filter(affiliate => (
                affiliate.name.toLowerCase() + ' ' + affiliate.lastname.toLowerCase()).includes(affiliatesSearchValue.toLowerCase())
            ||
            affiliate.id.toString().includes(affiliatesSearchValue.toLowerCase())
        )

        if(displayCount){
            const displayedAcc = [...filteredAffiliates].slice(0, displayCount)
            if(filteredAffiliates.length > displayCount){
                setAffiliatesFilterNotDisplayed(filteredAffiliates.length - displayCount)
            } else {
                setAffiliatesFilterNotDisplayed(0)
            }
            setAffiliates(displayedAcc)
        } else {
            setAffiliates(
                filteredAffiliates
            )
        }
    }, [affiliatesSearchValue, fetchedData, displayCount])

    const affiliateFilter = useFilterSelector<IAffiliatesFilter>(pageId, id)

    const setSelecteAffliates = (manager: IAffiliatesFilter) => {
        if(affiliateFilter.selected.some((filterManager)  => filterManager.id === manager.id)){
            dispatch(setFilter<IAffiliatesFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: affiliateFilter.exclude,
                    selected: affiliateFilter.selected.filter(accManager => accManager.id !== manager.id)
                }
            }))
        } else {
            dispatch(setFilter<IAffiliatesFilter>({
                pageID: pageId,
                filter: {
                    id,
                    exclude: affiliateFilter.exclude,
                    selected: [...affiliateFilter.selected, manager]
                }

            }))
        }
    }

    const setExcludeAffiliates = (exclude: boolean) => {
        dispatch(setFilter<IAffiliatesFilter>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: affiliateFilter.selected
            }
        }))
    }

    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    const addMultiple = (selected: any) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: affiliateFilter.exclude,
                selected: selected
            }
        }))
    }

    return {
        id,
        pageId: pageId,
        loading,
        data: accountManagers,
        setSearchValue: setAffiliatesSearchValue,
        notDisplayed: affiliatesNotDisplayed,
        selectedData: affiliateFilter.selected,
        exclude: affiliateFilter.exclude,
        setSelectedData: setSelecteAffliates,
        setExclude: setExcludeAffiliates,
        clearSelected: clearSelectedFilter,
        addMultiple: addMultiple
    }
}

