import {clearSelected, PageID, setFilter} from "../../../store/filters.slice";
import {IFilterHookReturn} from "filters";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../useAppDispatch";
import {useFilterSelector} from "../useFilterSelector";
export {};

const allRisks = [
    {
        id: 0,
        name: "No Risk"
    },
    {
        id: 1,
        name: "Low"
    },
    {
        id: 2,
        name: "Medium"
    },
    {
        id: 3,
        name: "High"
    },
    {
        id: 4,
        name: "Very High"
    },
    {
        id: 5,
        name: "Paused"
    }
];

export const usePauseRiskFilter = (pageId: PageID): IFilterHookReturn<any> => {

    const dispatch = useAppDispatch()

    const id = 'pause-risk'

    const pauseRiskFilter = useFilterSelector<any>(pageId, id)

    const setSelectedData = (risk: any, exclude?: boolean) => {        
        if(pauseRiskFilter.selected.some((ri)  => ri.id === risk.id)){            
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: exclude !== undefined ? exclude : pauseRiskFilter.exclude,
                    selected: pauseRiskFilter.selected.filter(ri => ri.id !== risk.id)
                }
            }))
        } else {
            dispatch(setFilter({
                pageID: pageId,
                filter: {
                    id,
                    exclude: exclude !== undefined ? exclude : pauseRiskFilter.exclude,
                    selected: [...pauseRiskFilter.selected, risk]
                }

            }))
        }
    }

    const setExclude = (exclude: boolean) => {
        dispatch(setFilter<any>({
            pageID: pageId,
            filter: {
                id,
                exclude: exclude,
                selected: pauseRiskFilter.selected
            }
        }))
    }


    const clearSelectedFilter = () => {
        dispatch(clearSelected({pageId: pageId, filterId: id}))
    }

    return {
        id,
        pageId: pageId,
        loading: false,
        data: allRisks,
        // setSearchValue: setSearchValue,
        notDisplayed: 0,
        selectedData: pauseRiskFilter.selected,
        exclude: pauseRiskFilter.exclude,
        setSelectedData: setSelectedData,
        setExclude: setExclude,
        clearSelected: clearSelectedFilter
    }
}