import React, {useEffect, useState} from "react";

import {Wrapper} from './Device.style'
import DeleteDeviceModal from "../delete-device-modal/DeleteDeviceModal";
import TwoFactorAuthenticationService, {FetchUsersDevicesResponse} from "../../../../services/2fa.service";
import {format} from "date-fns";
import {useAppSelector} from "../../../../hooks";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";

interface Props {
    device: FetchUsersDevicesResponse,
    fetchDevices: () => void,
    deleteDisabled: boolean,
    manageShowTooltip?: boolean
}

const Device: React.FC<Props> = ({device, fetchDevices, deleteDisabled,manageShowTooltip}) => {
    const [deleteModalOpened, setDeleteModalOpened] = useState(false)
    const [deleteDeviceLoading, setDeleteDeviceLoading] = useState(false)
    const {token, user} = useAppSelector(state => state.auth)

    const deleteDevice = async () => {
        setDeleteDeviceLoading(true)
        try {
            await TwoFactorAuthenticationService.deletePhoneNumber(token, {
                id: device.id,
                user_id: user.id
            })
            await fetchDevices()
        } catch (e) {
            console.error(e)
        } finally {
            setDeleteDeviceLoading(false)
            setDeleteModalOpened(false)
        }

    }

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return <Wrapper>
        {manageShowTooltip ? <ReactTooltip
            class={"react-tooltip-container tooltip"}
            effect="solid"
            type={'light'}
            multiline={true}
            html={true}
        /> : <></>}
        <div style={{display: 'flex'}}>
            <i className="material-symbols-outlined">
                phone_android
            </i>
        </div>
        <div className={'info-wrapper'}>
            <p className={'label'}>Phone Number</p>
            <p className={'value'}>{device.phone_number}</p>
        </div>
        <div className={'info-wrapper'}>
            <p className={'label'}>Method</p>
            <p className={'value'}>{device.method ? device.method : 'SMS'}</p>
        </div>
        <div className={'info-wrapper'}>
            <p className={'label'}>Date Added</p>
            <p className={'value'}>{format(new Date(device.created_at), 'MM/dd/yyyy')}</p>
        </div>
        <div>
            {!deleteDisabled ?
                <i
                    className="ico-Delete"
                    onClick={() => setDeleteModalOpened(true)}
                /> :
                <span
                    data-html={true}
                    data-tip={ReactDOMServer.renderToString(<>
                        <p>Atleast one device must be </p>
                        <p> attached to the account. Add</p>
                        <p>another device to remove</p>
                        <p> this one.</p>
                    </>)}>
                    <i
                        className="ico-Delete disabled"
                    />
                </span>
            }

        </div>
        <DeleteDeviceModal
            deleteDevice={deleteDevice}
            deleteDeviceLoading={deleteDeviceLoading}
            closeModal={() => setDeleteModalOpened(false)}
            opened={deleteModalOpened}
        />
    </Wrapper>
}

export default Device