import styled from 'styled-components'
import {DropdownContainer} from "../../dropdown/Dropdown.style";
import {InputSingle} from "../../input-single-line/InputSingleLine.style";

export const Wrapper = styled.div<{$opened: boolean}>`
  position: relative;
  /* display: inline-block; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  .open-dropdown-icon{
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid ${({theme, $opened}) => !$opened ?  theme.colors.coolGray[3] : theme.colors.deepBlue[3]};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-family: ${({theme}) => theme.fonts.AvenirHeavy};
      color: ${({theme, $opened}) => !$opened ? theme.colors.deepBlue[4] : theme.colors.deepBlue[6]};
      background: ${({theme, $opened}) => !$opened ? 'none' : theme.colors.deepBlue[2]};
      i{
          font-size: 18px;
      }
      
      :hover{
        border: 1px solid ${({theme, $opened}) => !$opened ? theme.colors.deepBlue[2] : theme.colors.deepBlue[3]};
        background: ${({theme, $opened}) => !$opened ? theme.colors.coolGray[2] : theme.colors.deepBlue[2]};
        cursor: pointer;
      }
  }
`

export const FilterSearch = styled(InputSingle)`
  height: 32px;
  width: 173px;
  margin-bottom: 8px;
`

export const SelectFilterContainer = styled(DropdownContainer)`
  top: 28px;
`

export const FilterList = styled.div`
  max-height: 320px;
  overflow-y: auto;
`