import {FilterID} from "filters";
import {useAppDispatch} from "../../useAppDispatch";
import { leapPartnersStatuses } from "../../../components/filters/statuses";
import {useFilterCacheSelector} from "../useFilterCacheSelector";
import {IPagePresetsID, setFilterValue} from "../../../../store/userPreferences.slice";

const allPartnerStatuses = leapPartnersStatuses;

export const usePartnerStatusMultiselectCacheFilter = (pageId: IPagePresetsID) => {

    const dispatch = useAppDispatch()

    const id: FilterID = 'partner-status-multiselect'

    const filter = useFilterCacheSelector({pageId, filterId: id})

    const setSelected = (status: any) => {
        if(filter.selected.some((selected)  => selected.id === status.id)){
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: filter.selected.filter(selected => selected.id !== status.id)}
            }))
        } else {
            dispatch(setFilterValue({
                pageId,
                filterId: id,
                filter: {...filter, selected: [...filter.selected, status]}
            }))
        }
    }

    const setInclude = (include: boolean) => {
        dispatch(setFilterValue({
            pageId,
            filterId: id,
            filter: {...filter, include: include}
        }))
    }


    return {
        id: id,
        options: allPartnerStatuses,
        selected: {
            value: filter.selected,
            setSelected
        },
        include: {
            value: filter.include,
            setInclude
        }
    }
}