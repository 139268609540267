import {api} from '../../../../../common/services/api';
import qs from "qs";

export interface IFetchAllAccountManagersResponse {
    id: number;
    name: string;
    lastname: string;
    company: string | null;
    network_id: number;
}

export type TNicheName = 'Downloads & Software' | 'Entertainment & Lifestyle' | 'Financial' | 'Healthy & Beauty' | 'E-Commerce' | 'Other'

export interface IMtdNiche {
    y: number;
    name: TNicheName;
}

export interface IFetchDashboardStats {
    profit_ytd: number;
    profit_mtd: number;
    profit_td: number;
    profit_yestd: number;
    monthly_profit: number[][];
    revenue_ytd: number;
    revenue_mtd: number;
    revenue_td: number;
    revenue_yestd: number;
    last_year_profit: number[][];
    big_niches: IMtdNiche[];
}

export interface IFetchDashboardStatsProps {
    exclude_acc_mngr?: boolean,
    AccountManager?: number[],
    SalesExecutive?: number[],
    exclude_sale_exec?: boolean,
    PartnerAccountManager?: number[]
    // exclude_par_acc_mngr?: boolean
}

export interface IPendingPartnersChartData {
    acc_manager: number,
    created_at: string
    partners: string
}

export interface IFetchPendingPartnerRegistration {
    total: number
    chart_days: IPendingPartnersChartData[]
}

export interface IPendingPartnerRegistrationParams{
    date_range: number,
    exclude_acc_mngr?: boolean,
    PartnerAccountManager?: number[],
    group_by_week?: boolean
}


class NetworkViewService {
    static fetchAllAccountManagers = async (token: string) => {
        return await api.post<IFetchAllAccountManagersResponse[]>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_FILTERS,
            process.env.REACT_APP_API_ACCOUNT_MANAGERS
        ].join('/'),
            {

            },
            {
                headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'application/x-www-form-urlencoded'
                }
            }
        )
    }

    static fetchDashboardStats = async (props: IFetchDashboardStatsProps, token: string) => {
        return await api.get<IFetchDashboardStats>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_REPORTS_URL,
            process.env.REACT_APP_API_ADMIN_URL
        ].join('/'),{
            params: {
                network_id: 0,
                type: 'dashboard',
                section: 'profit',
                ...props
            },
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        })
    }

    static fetchTopPerformersData = async (props: {
        analyze_by: string,
        page: number,
        per_page: number,
        AccountManager?: number[],
        SalesExecutive?: number[],
        order: "asc" | "desc",
        sort_by: string,
        exclude_acc_mngr?: boolean,
        exclude_sale_exec?: boolean,
        PartnerAccountManager?: number[]
        // exclude_par_acc_mngr?: boolean
    }, token: string, sum?: boolean) => {

        // let accMngs = props.accountManagers.map((el: number) => {
        //     let column = 'AccountManager[]=' + el
        //     return column;
        // }).join('&');
        
        let params: any = {
            network_id: 0,
            type: "dashboard",
            section: "performers",
            analyze_by: props.analyze_by,
            order: props.order,
            sort_by: props.sort_by,
        }

        if(props.SalesExecutive){
            params['SalesExecutive'] = props.SalesExecutive
        }

        if(props.AccountManager){
            params['AccountManager'] = props.AccountManager
        }

        if(props.PartnerAccountManager){
            params['PartnerAccountManager'] = props.PartnerAccountManager
        }

        if(props.page > 1) {
            params.page = props.page
        }

        if(props.per_page > 5) {
            params.paginate_by = props.per_page
        }

        if(sum) {
            params.sums = sum
        }

        if(props.exclude_acc_mngr) {
            params.exclude_acc_mngr = props.exclude_acc_mngr;
        }

        if(props.exclude_sale_exec) {
            params.exclude_sale_exec = props.exclude_sale_exec;
        }
        // if(props.exclude_par_acc_mngr){
        //     params.exclude_par_acc_mngr = props.exclude_par_acc_mngr;
        // }

        const paramsUrl = new URLSearchParams(params);

        return await api.get<any>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_REPORTS_URL,
            process.env.REACT_APP_API_ADMIN_URL
        ].join('/'),
        {
            params: {
                ...params,
            },
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        })
    }

    static exportCsvTopPerformersData = async (props: {
        analyze_by: string,
        page: number,
        per_page: number,
        order: "asc" | "desc",
        sort_by: string,
        exclude_acc_mngr: boolean,
        AccountManager?: number[],
        SalesExecutive?: number[],
        exclude_sale_exec?: boolean,
        PartnerAccountManager?: number[]
    }, paginationColumnlist : {
        name: string,
        id: number,
        checked: boolean,
        column_key?: string
    }[]) => {
        const filteredColumns = paginationColumnlist.filter((item: {
            name: string,
            id: number,
            checked: boolean
        }) => item.checked === true);

        const selectedColumns = filteredColumns.map((column: {
            name: string,
            id: number,
            checked: boolean,
            column_key?: string
        }) => {
            return column.column_key;
        })

        // let columns = selectedColumns.map(function(el, idx) {
        //     return el;
        // });
        
        let params: any = {
            network_id: 0,
            type: "dashboard",
            section: "performers",
            analyze_by: props.analyze_by,
            order: props.order,
            sort_by: props.sort_by,
            columns: selectedColumns
        }

        if(props.SalesExecutive){
            params['SalesExecutive'] = props.SalesExecutive
        }

        if(props.AccountManager){
            params['AccountManager'] = props.AccountManager
        }
        if(props.PartnerAccountManager){
            params['PartnerAccountManager'] = props.PartnerAccountManager
        }

        if(props.page > 1) {
            params.page = props.page
        }

        if(props.per_page > 5) {
            params.paginate_by = props.per_page
        }

        if(props.exclude_acc_mngr) {
            params.exclude_acc_mngr = props.exclude_acc_mngr;
        }

        if(props.exclude_sale_exec){
            params.exclude_sale_exec = props.exclude_sale_exec
        }

        const paramsUrl = qs.stringify(params)
        
        window.open([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_REPORTS_URL,
            process.env.REACT_APP_API_ADMIN_URL
        ].join('/') + "?" + paramsUrl + "&export_csv=true", "_blank");
    }
    
    static fetchPendingPartnersRegistration = async (token: string, params : IPendingPartnerRegistrationParams) => {
        return await api.get<IFetchPendingPartnerRegistration>([
            process.env.REACT_APP_API_BASE_URL,
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_API_V1_URL,
            process.env.REACT_APP_API_REPORTS_URL,
            process.env.REACT_APP_API_ADMIN_URL,
            'pending-partners'
            ].join("/"),
            {
            params: {
                ...params,
            },
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        })
    }
}

export default NetworkViewService